sessionService.$inject = ['constants', 'ajaxService'];

function sessionService(constants, ajaxService) {
	var service = {
		getSessionIdle : function() {
			var request = ajaxService.getConfigurationValue(constants.get('USER_SESSION_TIMEOUT_MINUTES_KEY'));
			return request.then(function(config) {
				if(config != null) {
					return config.data *60;
				}
			}, function() {
				return constants.get('DEFAULT_IDLE_SECONDS');
			});
		},
		sessionKeepAlive : function() {
			var request = ajaxService.sessionKeepAlive();
			return request.then(function() {
				return true;
			},function() {
				return false;
			});
		}
	};
	return service;
}