folderpermissionsService.$inject = ['$q', 'ajaxService'];

function folderpermissionsService($q, ajaxService) {

	var service = {
			getFolderPermissionsReport: function(postData, restUrl){
				var request = ajaxService.getFolderPermissionsReport(postData, restUrl);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			}
			
	}
	return service;
}