angular
	.module('dialogControllers.service')
	.controller('changePasswordController', changePasswordController);

changePasswordController.$inject = [ '$scope', 'localizedMessages', 'homeService', 'context', 'bvDialog'];

function changePasswordController($scope, localizedMessages, homeService, context, bvDialog) {

	$scope.popupTitle 	= localizedMessages.get('CHANGE_PASSWORD');
	$scope.actionBtnTxt = localizedMessages.get('OK');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.currentPwd 	= localizedMessages.get('CURRENT_PASSWORD');
	$scope.newPwd 		= localizedMessages.get('NEW_PASSWORD');
	$scope.confirmPwd 	= localizedMessages.get('CONFIRM_NEW_PASSWORD');
	$scope.password 	= localizedMessages.get('PASSWORD');
	$scope.errorTitle   = localizedMessages.get("THE_PASSWORD_YOU_ARE_CREATING_MUST_FULFILL_THE_FOLLOWING_REQUIREMENTS");

	$scope.error = {};
	$scope.user = {};
	var bvModal = $scope.bvModalControllerInputs.bvModal;

	$scope.action = function()
	{
		$scope.error.authError = null;
		$scope.showErrors = false;
		$scope.errorObjectsList = [];
	    var passwordValid = false;
	    
	    if(context.homeModel== null || context.homeModel.passwordPreferences == null || context.homeModel.passwordPreferences == undefined)
		{
	    	homeService.getPasswordPreferences().then(function(data){
	    		if ( data != null) 
	    		{
	    			context.homeModel.passwordPreferences = data;
	    		}
	    	});
		}
	    var passwordPreferences = context.homeModel.passwordPreferences;
	    if (!$scope.user.currentpwd || !$scope.user.newpwd || !$scope.user.confpwd)
	    {
	    	$scope.error.authError = localizedMessages.get('ERR_REQUIRED_FIELD');
	    	return;
	    }
	    else if($scope.user.newpwd !== $scope.user.confpwd)
	    {
	    	$scope.error.authError = localizedMessages.get('ERR_DIFFERENT_PASSWORD');
	    	return;
	    }
	    else if($scope.user.newpwd.indexOf('<')> -1 || $scope.user.newpwd.indexOf('>')> -1)
	    {
	    	$scope.error.authError = localizedMessages.get('ERR_INVALID_PASSWORD_CHARS');
	    	return;
	    }
	    else if(passwordPreferences && passwordPreferences.isPasswordPolicyEnaled)
		{
	    	passwordValid = StringUtils.passwordValidation(passwordPreferences, $scope.user.newpwd);
		}
	    else if(passwordPreferences && !passwordPreferences.isPasswordPolicyEnaled)
		{
	    	passwordValid = StringUtils.passwordGlobalValidation(passwordPreferences, $scope.user.newpwd);
		}
	    if(!passwordValid)
    	{
	    	$scope.error.validation = $scope.getErrorLabelForChangePassword();
	    	if($scope.getErrorLabelForChangePassword())
    		{
	    		$scope.showErrors = true;
    		}
	    	return;
    	}
	    else 
    	{
	    	var currentpasswd = $scope.user.currentpwd;
    		var newpasswd = $scope.user.newpwd;
    		if(context && context.getModel() && context.getModel().cryptoVO != null && context.getModel().cryptoVO != undefined
	    			&& context.getModel().cryptoVO.ssl2 == true && context.getModel().cryptoVO.chpass == true)
    		{
    			currentpasswd = CryptoHelper.asymmetricEncrypt($scope.user.currentpwd, context.homeModel.serverPublicKey);
    			newpasswd = CryptoHelper.asymmetricEncrypt($scope.user.newpwd, context.homeModel.serverPublicKey);
    		}
	    	homeService.changeUserPassword($scope.user.currentpwd, $scope.user.newpwd).then(function(data) 
    		{
	    		if(data == "true")
	    		{
	    			bvModal.close();
	    			bvDialog.showMessage(localizedMessages.get("PASSWORD_CHANGE_SUCCESS"));
    			}
	    		else
    			{
	    			if(data.message && data.message.indexOf("Old password is not valid") != -1)
	    				$scope.error.authError = localizedMessages.get('OLD_PASSWORD_IS_NOT_VALID');
	    			else if(data.message && data.message.indexOf("Old and New password should not be same.") != -1)
	    				$scope.error.authError = localizedMessages.get("OLD_AND_NEW_PASSWORD_SHOULD_NOT_BE_SAME");
	    			else if(data.message && data.message.indexOf("Maximum attempts to change password have reached.") != -1)
	    				$scope.error.authError = localizedMessages.get("MAX_ATTEMPTS_TO_CHANGE_PASSWORD_REACHED");
	    			else if(data.message && data.message.indexOf("PasswordValidationException:") != -1)
	    				$scope.error.authError = data.message.substring(data.message.lastIndexOf(":") + 1);
	    			else if(data.message && data.message.indexOf("PasswordChangeRestrictedException:") != -1)
	    				$scope.error.authError = data.message.substring(data.message.lastIndexOf(":") + 1);
	    			else
	    				$scope.error.authError = localizedMessages.get('UNABLE_TO_CHANGE_PWD');
    			}
		    });	
    	}
		
		
	 }
	
	$scope.getErrorLabelForChangePassword = function()
	{
		var flag = false;
		var i = 1;
		var passwordPreferences = context.homeModel.passwordPreferences;
		$scope.errorObjectsList = []
		$scope.policeNotEnabed = {};
		if(!passwordPreferences.isPasswordPolicyEnaled)
		{
			if(passwordPreferences.passwordSystemMinLength)
			{
				var errorObject = {};
				errorObject.index = i;
				errorObject.part1 = localizedMessages.get("THE_PASSWORD_YOU_ARE_CREATING_MUST_BE_AT_LEAST");
				errorObject.part2 = passwordPreferences.passwordSystemMinLength;
				errorObject.part3 = localizedMessages.get("CHARACTERS_LONG");
				$scope.errorObjectsList.push(errorObject);
				flag = true;
				$scope.policeNotEnabed.val = true; 
			}
		}
		else
		{
			  $scope.policeNotEnabed.val = true;
			  if(passwordPreferences.passwordMinLength)
			  {
				  var errorObject = {};
				  errorObject.index = i;
				  errorObject.part1 = localizedMessages.get("AT_LEAST");
			  errorObject.part2 = passwordPreferences.passwordMinLength;
			  errorObject.part3 = localizedMessages.get("CHARACTERS_LONG");
				  $scope.errorObjectsList.push(errorObject); 
				  flag = true; 
				  i = i+1;
			  }
			  if(passwordPreferences.passwordMinAlphaCharacters && parseInt(passwordPreferences.passwordMinAlphaCharacters) > 0)
			  {
				  var errorObject = {};
				  errorObject.index = i;
				  errorObject.part1 = localizedMessages.get("CONTAIN_AT_LEAST");
			  errorObject.part2 = passwordPreferences.passwordMinAlphaCharacters ;
			  errorObject.part3 = localizedMessages.get("LETTERS");
				  $scope.errorObjectsList.push(errorObject);
				  flag = true;
				  i = i+1;
			  }
			  if(passwordPreferences.passwordMinNumbers && parseInt(passwordPreferences.passwordMinNumbers) > 0)
			  {
				  var errorObject = {};
				  errorObject.index = i;
				  errorObject.part1 = localizedMessages.get("CONTAIN_AT_LEAST");
			  errorObject.part2 = passwordPreferences.passwordMinNumbers;
			  errorObject.part3 = localizedMessages.get("NUMBERS");
				  $scope.errorObjectsList.push(errorObject);
				  flag = true;
				  i = i+1;
			  }
			  if(passwordPreferences.passwordMinNonAlphaCharacters && parseInt(passwordPreferences.passwordMinNonAlphaCharacters) > 0)
			  {
				  var errorObject = {};
				  errorObject.index = i;
				  errorObject.part1 = localizedMessages.get("CONTAIN_AT_LEAST");
			  errorObject.part2 = passwordPreferences.passwordMinNonAlphaCharacters;
			  errorObject.part3 = localizedMessages.get("SPECIAL_CHARACTERS_WITH_EXAMPLE");
				  $scope.errorObjectsList.push(errorObject);
				  flag = true; 
				  i = i+1;
			  }
			  if(passwordPreferences.isUpperAndLowerEnabled)
			  {
				  var errorObject = {};
				  errorObject.index = i;
				  errorObject.part1 = localizedMessages.get("CONTAIN_AT_LEAST_ONE_UPPER_CASE_AND_ONE_LOWER_CASE_CHARACTER");
			  errorObject.part2 = "";
			  errorObject.part3 = "";
				  $scope.errorObjectsList.push(errorObject);
				  flag = true;
				  i = i+1;
			  }
			  if(parseInt(passwordPreferences.passwordHistoryLength)>0)
			  {
				  var errorObject = {};
				  errorObject.index = i;
				  errorObject.part1 = localizedMessages.get("PASSWORD_MUST_BE_DIFFERENT_FROM_THE_LAST");
			  errorObject.part2 = passwordPreferences.passwordHistoryLength;
			  errorObject.part3 = localizedMessages.get("PASWORDS_USED_LAST");
				  $scope.errorObjectsList.push(errorObject);
				  flag = true;
				  i = i+1; 
			  }
		}
		return flag;
	};
}