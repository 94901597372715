function configMessagesController($stateProvider, $locationProvider){
}

MessagesController.$inject = ['$scope', '$state', '$stateParams', '$sce', 'messagesService', 'commonService', 'localizedMessages','imageService','bvDialogService', '$modal', 'context','homeContext','repositoryService', 'homeService', 'homeModel', 'templatesService','bvSliderService','$timeout', 'bvDialog','$filter','bvDialogMobile', '$element', 'bvSliderInstanceService', '$q','approvalsService','surveysService'];

function MessagesController($scope, $state, $stateParams,$sce, messagesService, commonService, localizedMessages, imageService, bvDialogService, modal, context,homeContext, repositoryService, homeService, homeModel, templatesService, bvSliderService, $timeout, bvDialog, $filter, bvDialogMobile, $element, bvSliderInstanceService, $q, approvalsService, surveysService){
	//$scope.$parent.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
	var defaultTitle = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
	$scope.title = defaultTitle;
	$scope.middleBarObject.title = defaultTitle;
	$scope.middleBarObject.showBack = false;
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.atInboxTab = true;
	$scope.inboxTab = true;
	$scope.endOflist = false;
	$scope.onlineStatus = homeContext.onlineStatus;
	
	$scope.attachmentIcon = 'resources/assets/svgs/attachments_icon.svg';
	$scope.settingIcon = 'resources/assets/svgs/settings_icon_@2X.svg';
	$scope.svgFill = "#000";
	$scope.arrowIcon = 'resources/assets/svgs/grey_arrow_meeting_in_progress.svg';

	$scope.showDropDown = {}

	$scope.scrollConfigForMessageList = {
    	autoHideScrollbar: true,
        theme: 'minimal-dark',
        callbacks: {
			onScroll: function () {
				if(this.mcs.topPct >= 96)
					shouldLoadMore();
			}
			// ,
			// whileScrolling: function() {
			// 	console.log(this.mcs);
			// }
		}
    };
	$scope.scrollConfig = {
        autoHideScrollbar: true,
        theme: 'minimal-dark'
    };

	$scope.lblText = {
			subject: localizedMessages.get("SUBJECT"),
			from: localizedMessages.get("FROM"),
			to: localizedMessages.get("TO"),
			date: localizedMessages.get("DATE"),
			more:  localizedMessages.get("MORE"),
			messages: localizedMessages.get("MESSAGES"),
			reply : localizedMessages.get("REPLY"),
			replyAll : localizedMessages.get("REPLY_ALL"),
			deletelbl : localizedMessages.get("DELETE"),
			pullbacklbl : localizedMessages.get("RECALL"),
			inbox : localizedMessages.get("INBOX"),
			received : localizedMessages.get("RECEIVED"),
			sent : localizedMessages.get("SENT"),
			recall : localizedMessages.get("RECALL"),
			forward : localizedMessages.get("FORWARD"),
			attachments : localizedMessages.get("ATTACHMENTS"),
			loading: localizedMessages.get("LOADING")
	}

	$scope.inbox = {
		pageNo: 0,
		hasMore: false,
		messages: [],
		selectedMessage: {},
		messageSelected: false
	};

	$scope.sent = {
		pageNo: 0,
		hasMore: false,
		messages: [],
		selectedMessage: {},
		messageSelected: false
	};

	function bvSliderCreateInstance(objectBaseUuid){

		if(!objectBaseUuid)
			return;

		var bvSliderServ;
		var sliderRenderCallback = function(){
			bvSliderServ = bvSliderInstanceService.instances[sliderOptions.id];
			bvSliderServ.currentIndex = 0;
		}

		var sliderOptions = {
				id : objectBaseUuid,
				items: [],
				slideThrough: 1,
				shownItems: 3,
				smallButtons: true,
				fixQuantity: true,
				renderCallback: sliderRenderCallback
		}

		return sliderOptions;
	}

	function bvSliderGetInstance(objectBaseUuid){

		if(!objectBaseUuid)
			return;

		var bvSliderServ = bvSliderInstanceService.instances[objectBaseUuid];

		return bvSliderServ;

	}

	$scope.inboxSliderOptions = {
			items: [],
			slideThrough: 1,
			shownItems: 3,
			smallButtons: true,
			fixQuantity: true
	}

	$scope.$watch('expandedMenu', function(newValue, oldValue) {
    	bvSliderService.fixQuantity(newValue);
    	bvSliderService.fixScroll();
    	// $scope.$apply();
	});
	$scope.selectedBox = {};
	$scope.pageSize;
	$scope.message = {};
//	$scope.messageSelected = false;

	$scope.showMessageDetails = false;

	$scope.svgFill = '#000';

	var inboxOrSentFlag = 0;

	var switchToInbox = function() {
		$scope.selectedBox = $scope.inbox;
		$scope.title = localizedMessages.get("INBOX");
		$scope.handleLeftIcon();
		$scope.atInboxTab = true;
		selectFirstUnreadMessage();
		$scope.inboxTab = true;
	}

	var switchToSent = function() {
		$scope.selectedBox = $scope.sent;
		$scope.title = localizedMessages.get("SENT");
		$scope.handleLeftIcon();
		$scope.atInboxTab = false;
		selectFirstUnreadMessage();
		$scope.inboxTab = false;
	}


	var getInboxUnreadCount = function(){
		homeService.getInboxUnreadCount().then(function(data){
			$scope.inboxItems.value = data;
			if($scope.inboxItems.value > 0 && !$scope.expandedMenu)
	    	{
	    		$scope.showBadge.value = true + "";
	    	} else {
	    		$scope.showBadge.value = false + "";
	    	}
		});
	}

	$scope.getItemDetails = function(selectedItem)
	{
		$scope.selectedBox.messageSelected = true;
		$scope.selectedMsgUuid = selectedItem.objectBaseUuid;
		bvSliderService.currentIndex = 0;
		$scope.threadTransformStyle = {'transform': "translateY(-45px)"};

		messagesService.getSharedContainerWithAttachmentsJSON(selectedItem.objectBaseUuid)
		.then(function(response) {
			$scope.selectedBox.message = response.data;

			if(getItemDetailsPromise) getItemDetailsPromise.resolve();
			if($scope.selectedBox.message.parentShareContainer == null || $scope.selectedBox.message.parentShareContainer == undefined || $scope.selectedBox.message.parentShareContainer.length == 0)
			{
				$scope.showThreads = false;

				$scope.inboxSliderOptions.items = new Array();
				$timeout(function(){
					bvSliderService.fixScroll();
			    	bvSliderService.fixQuantity();
			    	bvSliderService.fixButtons();
				});
				
				var attachmentArray = [];
				
				if(StringUtils.isApprovalShareContainer($scope.selectedBox.message.objectBaseUuid) || StringUtils.isSurveyShareContainer($scope.selectedBox.message.objectBaseUuid))
				{
					attachmentArray = $scope.selectedBox.message.attachments;
				}
				else
				{
					attachmentArray = attachmentArray.concat($scope.selectedBox.message.folderAttachments);
					attachmentArray = attachmentArray.concat($scope.selectedBox.message.fileAttachments);
				}
				
				$scope.inboxSliderOptions.items.push.apply($scope.inboxSliderOptions.items,attachmentArray);
			}
			else{
				$scope.selectedBox.message.parentShareContainer.forEach(function(item)
				{
					item.items = new Array();
					item.items.push.apply(item.items, item.attachments);

					item.inboxSliderOptions = bvSliderCreateInstance(item.objectBaseUuid);
					
					if(StringUtils.isApprovalShareContainer(item.objectBaseUuid) || StringUtils.isSurveyShareContainer(item.objectBaseUuid))
					{
						item.inboxSliderOptions.items = item.attachments;
					} else {
						item.inboxSliderOptions.items = item.fileAttachments;
					}

					$timeout(function(){
                        if(bvSliderGetInstance(item.objectBaseUuid)){
                            bvSliderGetInstance(item.objectBaseUuid).fixQuantity();
                            bvSliderGetInstance(item.objectBaseUuid).fixButtons();
                        }
					});
				});
				$scope.selectedBox.message.parentShareContainer[0].open = true;
				$scope.detailHeight = $('.inboxItemsList').height();
			    MobileUtil.isMobileClient() ? $scope.translateVal = 60 : $scope.translateVal = 45;
				$scope.showThreads = true;
			}
		});
	};

	var closePanel = function(index) {
		var targetId = 'thread' + index;
		var targetPanel = document.getElementById(targetId);
		targetPanel.style.height="50px";
		targetPanel.style.transition = "1s";
	}
	var getItemDetailsPromise = null;

	$scope.openMessage = function(msg)
	{
		getItemDetailsPromise = $q.defer();
		$scope.getItemDetails(msg);

		if(msg.unRead == true && $scope.atInboxTab)
		{
			messagesService.markItemRead(msg.objectBaseUuid).then(function(data){
				$scope.inboxItems.value = data.data;
				if($scope.inboxItems.value > 0 && !$scope.expandedMenu)
		    	{
		    		$scope.showBadge.value = true + "";
		    	} else {
		    		$scope.showBadge.value = false + "";
		    	}
			});
			$scope.selectedBox.messages[$scope.selectedBox.messages.indexOf(msg)].unRead = false;
		}

		$scope.showMessageDetails = true;

		getItemDetailsPromise.promise.then(function() {
			if(MobileUtil.isMobileClient() || MobileUtil.isSurface()){
				bvDialogMobile.mobileOpenMessage($scope.selectedBox.message);
				$scope.selectedMsgUuid = "";
			}
		});
	};

	var recallMessageConfirm = function(msg){
		if(msg !== undefined) $scope.selectedBox.message = msg;
		bvDialog.confirm( localizedMessages.get('MSG_DELETE_SENT_CONFIRMATION'), recallMessage);
	}
	
	var recallMessage = function() {
		messagesService.recallSentItemObjectJSON($scope.selectedBox.message.objectBaseUuid).then(function(response)
		{
			$scope.getSentJSON(true, false, true);
			getInboxUnreadCount();
		});
	}

	$scope.openSentMessage = function(msg){

		 getItemDetailsPromise = $q.defer();
		 $scope.getItemDetails(msg);

		 $timeout(function(){
			 msg.showDelete = false;
		 })

		 getItemDetailsPromise.promise.then(function () {
			 $scope.showMessageDetails = true;
			 //bvDialogMobile.mobileOpenMessage($scope.selectedBox.message);

			var bvModalInputs = {}
			bvModalInputs.windowClass = 'bv-mobile-compose-message';
			bvModalInputs.inputs = {};
			bvModalInputs.inputs.messageType = "sent";

			bvModalInputs.inputs.businessObjects = null;
			bvModalInputs.controller = 'ComposeMessageController';
			bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessageMobile');
			bvModalInputs.inputs.selectedMessage = $scope.selectedBox.message;
			bvModalInputs.inputs.secure = $scope.secure;
			bvModalInputs.inputs.mobileActionButton = true;
			bvModalInputs.inputs.mobileActionButtonImagePath = 'resources/assets/images/android_icons_send_white_2x.png';
			bvModalInputs.inputs.popupTitle = $scope.selectedBox.message.name;
			bvDialogService.showBvModal(bvModalInputs);

	     });

	}

	$scope.closeOthers = function(msg){
		angular.forEach($scope.selectedBox.messages, function(item){
			if(msg.objectBaseUuid != item.objectBaseUuid){
				item.showDelete = false;
			}
		});
	}
	
	$scope.ieHoldingFix = function(){
		if(StringUtils.getBrowser() == 'MSIE'){
			$scope.ieHold = true;
			$timeout(function(){
				$scope.ieHold = false;
			}, 50);
		}
		else{
			$scope.ieHold = false;
		}
	}
	
	$scope.handleLeftIcon = function()
	{
		$scope.showMessageDetails = false;
		$scope.middleBarObject.showBack = false;
		$scope.middleBarObject.title = $scope.title;
	}

	var selectFirstUnreadMessage = function() {
		if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
			return;
		}

		if($scope.selectedBox.messages.length == 0) {
			return;
		}
		if($scope.tabs[1].active) {
			$scope.selectedBox.message = $scope.selectedBox.messages[0];
			$scope.selectedBox.messageSelected = true;
			$scope.getItemDetails($scope.selectedBox.messages[0]);
			return;
		}
		for(var i=0; i < $scope.selectedBox.messages.length; i++) {
			if($scope.selectedBox.messages[i].unRead == false) {
				$scope.getItemDetails($scope.selectedBox.messages[i]);
				return;
			}
		}
		// Select first message if all displayed messages are unread.
		if($scope.selectedBox.messages.length>0) {
			$scope.selectedBox.message = $scope.selectedBox.messages[0];
			$scope.selectedBox.messageSelected = true;
			$scope.getItemDetails($scope.selectedBox.messages[0]);
			if($scope.selectedBox.message.unRead == true)
			{
				messagesService.markItemRead($scope.selectedBox.message.objectBaseUuid);
				$scope.selectedBox.messages[$scope.selectedBox.messages.indexOf($scope.selectedBox.message)].unRead = false;
			}
		} else {
			$scope.selectedBox.message = null;
			$scope.selectedBox.messageSelected = false;
		}
	}
	$scope.inProgress = false;
	$scope.getInboxJSON = function(getMore, fromPollingProcessor, fromDeleteProcess) {

		if(inboxOrSentFlag == 1 && (getMore == false || getMore == undefined || $scope.inProgress))
		{
			return;
		}

		if(inboxOrSentFlag != 1)
		{
			switchToInbox();
		}

//		if($scope.selectedBox.messages.length == 0 || getMore == true)
//		{
			if(fromPollingProcessor || fromDeleteProcess || getMore == undefined)
			{
				$scope.inbox.pageNo = 0;
			}
			$scope.inProgress = true;
			messagesService.getInboxJSON($scope.pageSize * $scope.inbox.pageNo).then(function(response)
			{
				if(response && response.data.length == 0){
					$timeout(function(){
						$scope.endOflist = true;
						$timeout(function(){
							$element.find('.inboxItemsGrid')[0].scrollTop = $element.find('.inboxItemsGrid')[0].scrollTop - 1;
						})
					})
				}

				if($scope.pageSize == 10 && $scope.inbox.pageNo == 0)
				{
					$scope.heightRecEnabled = true;
				} else {
					$scope.heightRecEnabled = false;
				}
//				$scope.selectedBox = $scope.inbox;
				$scope.inbox.messages.push.apply($scope.inbox.messages, response.data);
				if(fromPollingProcessor || fromDeleteProcess || getMore == undefined)
				{
					$scope.selectedBox.messages = new Array();
					$scope.selectedBox.messages.push.apply($scope.selectedBox.messages,response.data);
				}
				else
				{
					var temp = $scope.inbox.messages.slice(0);
					$scope.selectedBox.messages = new Array();
					$scope.selectedBox.messages.push.apply($scope.selectedBox.messages,temp);
				}

				if(!StringUtils.isNullEmpty(homeModel.plinkObjUuid)) {
					openMessageFromPLink();
					homeModel.plinkObjUuid = "";
				}
				else if($scope.inbox.messageSelected == false || fromDeleteProcess || fromPollingProcessor)
				{
					selectFirstUnreadMessage();
				}

				$scope.inbox.hasMore = response.data.length < $scope.pageSize ? false : true;

				$scope.noInboxMessages = $scope.selectedBox.messages.length == 0 ? true : false;
				$scope.msg_no_inbox_messages = localizedMessages.get('MSG_NO_INBOX_MESSAGES');
				if(MobileUtil.isMobileClient()){
					$timeout(function(){
						$scope.inProgress = false;
					},1000)
				}
				else{
					$scope.inProgress = false;
				}
				document.querySelector('body > spinner').style.display = 'inherit';
			});

//		}
		inboxOrSentFlag = 1;
	};

	var getInboxJSON = $scope.getInboxJSON;

	$scope.getSentJSON = function(getMore, fromNotify, fromDeleteProcess) {

		if(inboxOrSentFlag == 2 && (getMore == false || getMore == undefined || $scope.inProgress))
		{
			return;
		}

		if(inboxOrSentFlag != 2)
		{
			switchToSent();
		}

//		if($scope.selectedBox.messages.length == 0 || getMore == true)
//		{
			if(fromNotify || fromDeleteProcess || getMore == undefined)
			{
				$scope.sent.pageNo = 0;
			}
			$scope.inProgress = true;
			messagesService.getSentJSON($scope.pageSize * $scope.sent.pageNo).then(function(response)
			{

				if(response && response.data.length == 0){
					$timeout(function(){
						$scope.endOflist = true;
						$timeout(function(){
							$element.find('.inboxItemsGrid')[0].scrollTop = $element.find('.inboxItemsGrid')[0].scrollTop - 1;
						})
					})
				}

				if($scope.pageSize == 10 && $scope.sent.pageNo == 0)
				{
					$scope.heightRecEnabled = true;
				} else{
					$scope.heightRecEnabled = false;
				}

				$scope.sent.messages.push.apply($scope.sent.messages, response.data);
				if(fromNotify || fromDeleteProcess || getMore == undefined)
				{
					$scope.selectedBox.messages = new Array();
					$scope.selectedBox.messages.push.apply($scope.selectedBox.messages,response.data);
				}
				else
				{
					var temp = $scope.sent.messages.slice(0);
					$scope.selectedBox.messages = new Array();
					$scope.selectedBox.messages.push.apply($scope.selectedBox.messages,temp);
				}

				if($scope.sent.messageSelected == false || fromDeleteProcess || fromNotify)
				{
					selectFirstUnreadMessage();
				}

				$scope.sent.hasMore = response.data.length < $scope.pageSize ? false : true;

				$scope.noSentMessages = $scope.selectedBox.messages.length == 0 ? true : false;
				$scope.msg_no_sent_messages = localizedMessages.get('MSG_NO_SENT_MESSAGES');
				if($scope.noSentMessages) {
					$scope.sent.messageSelected = false
				}
				$timeout(function(){
					$scope.inProgress = false;
				},1000)
				document.querySelector('body > spinner').style.display = 'inherit';
			});
//		}
		inboxOrSentFlag = 2;
	};

	var composeMessage = function(messageType) {

		if(!$scope.onlineStatus){
			return;
		}
		
		var bvModalInputs = {}
		bvModalInputs.windowClass = 'bv-modal-window-compose-new-message';
		bvModalInputs.inputs = {};
		bvModalInputs.buttons = [{},{}];
		bvModalInputs.inputs.secure = $scope.secure;
		bvModalInputs.inputs.alwaysSecure = context.getModel().companyVO.alwaysSendSecureEmail;
		if(messageType != null || messageType != undefined) {
			bvModalInputs.inputs.messageType = messageType;
			if($scope.selectedBox.message != null || $scope.selectedBox.message != undefined) {
				bvModalInputs.inputs.selectedMessage = angular.copy($scope.selectedBox.message);
				bvModalInputs.inputs.parentShareContainer = angular.copy($scope.selectedBox.message.parentShareContainer);
			}

		} else {
			bvModalInputs.inputs.messageType = "compose";
		}

		if(bvModalInputs.inputs.messageType == "compose") {
			bvModalInputs.inputs.popupTitle = localizedMessages.get("COMPOSE_NEW_MESSAGE");
			bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');
		} else if(bvModalInputs.inputs.messageType == "forward") {
			bvModalInputs.inputs.popupTitle = localizedMessages.get("FORWARD_MESSAGE");
			bvModalInputs.bodyTemplate = templatesService.getUrl('ForwardMessage');
		} else {
			bvModalInputs.inputs.popupTitle = bvModalInputs.inputs.selectedMessage.name;

			if(bvModalInputs.inputs.popupTitle.substring(0,5) == localizedMessages.get("FWD")) {
				bvModalInputs.inputs.popupTitle = bvModalInputs.inputs.popupTitle.slice(5,bvModalInputs.inputs.popupTitle.length);
			}

			if(bvModalInputs.inputs.popupTitle.substring(0,4) != localizedMessages.get("RE")) {
				bvModalInputs.inputs.popupTitle = localizedMessages.get("RE") + bvModalInputs.inputs.popupTitle;
    		}

			bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');
		}

		bvModalInputs.inputs.businessObjects = null;
		bvModalInputs.controller = 'ComposeMessageController';
		if(bvModalInputs.inputs.parentShareContainer == null || bvModalInputs.inputs.parentShareContainer == undefined)
		{
			bvModalInputs.inputs.parentShareContainer = [];
			bvModalInputs.inputs.parentShareContainer.push($scope.selectedBox.message);
		}

		bvDialogService.showBvModal(bvModalInputs);

	};

	var deleteMessageConfirm = function(msg) {
		if(msg !== undefined) $scope.selectedBox.message = msg;
		bvDialog.confirm( localizedMessages.get('MSG_DELETE_INBOX_CONFIRMATION'), deleteMessage);
	}
	var deleteMessage = function() {
		if(MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface()){
			if($scope.inboxActive) {
				messagesService.deleteInboxItemObjectJSON($scope.selectedBox.message.objectBaseUuid).then(function(response)
				{
					$scope.getInboxJSON(true, false, true);
				});
			} else {
				messagesService.deleteSentItemObjectJSON($scope.selectedBox.message.objectBaseUuid).then(function(response)
				{
					$scope.getSentJSON(true, false, true);
					getInboxUnreadCount();
				});
			}
		}
		else{
			if($scope.tabs[0].active) {
				messagesService.deleteInboxItemObjectJSON($scope.selectedBox.message.objectBaseUuid).then(function(response)
				{
					$scope.getInboxJSON(true, false, true);
				});
			} else {
				messagesService.deleteSentItemObjectJSON($scope.selectedBox.message.objectBaseUuid).then(function(response)
				{
					$scope.getSentJSON(true, false, true);
					getInboxUnreadCount();
				});
			}
		}

	}
	$scope.markAllReadConfirm = function() {
		bvDialog.confirm( localizedMessages.get('MSG_MARK_ALL_READ'), markAllInboxItemsAsRead);
	}
	var markAllInboxItemsAsRead = function() {
		messagesService.markAllInboxItemsAsRead().then(function(response)
		{
			$scope.getInboxJSON(true, false, true);
			getInboxUnreadCount();
		});
	}

	$scope.replyMessage = function(messageType) {
		composeMessage(messageType);
	};

	$scope.setting = function(type, msg) {
		if(type=="delete") {
			deleteMessageConfirm(msg);
		}
		else if(type=="recall") {
			recallMessageConfirm(msg);
		}
		else {
			composeMessage(type);
		}
	}

	var rightMenuListForInbox = [
	                    { title:localizedMessages.get("MARK_ALL_READ"), click: $scope.markAllReadConfirm, iconUrl: '', svgFill: '#fff'},
	 		            { title:localizedMessages.get("COMPOSE"), click: composeMessage, iconUrl: '', svgFill: '#fff', stateName: 'InboxCompose'},
	 		            ];
	var rightMenuListForSent = [
		 		            { title:localizedMessages.get("COMPOSE"), click: composeMessage, iconUrl: '', svgFill: '#fff', stateName: 'InboxCompose'},
		 		            ];
	$scope.tabs = [
        { title:localizedMessages.get("INBOX_UPPERCASE"), content:'inbox', func: $scope.getInboxJSON, rightMenuList:rightMenuListForInbox},
        { title:localizedMessages.get("SENT_UPPERCASE"), content:'sent', func: $scope.getSentJSON, rightMenuList:rightMenuListForSent }
	];


	$scope.selectMessage = function(msg) {
		$scope.getItemDetails(msg);
	}


    $scope.getDocumentThumbnail = function(attachment) {
		return imageService.getThumbnailForObject(attachment);
	}

	$scope.getMoreMessages = function() {
		$scope.selectedBox.pageNo = $scope.selectedBox.pageNo + 1;
		if(($scope.tabs[0].active && !MobileUtil.isMobileClient()) ||
			($scope.inboxActive && MobileUtil.isMobileClient())) {
			$scope.getInboxJSON(true);
		} else if(($scope.tabs[1].active && !MobileUtil.isMobileClient()) ||
			(!$scope.inboxActive && MobileUtil.isMobileClient())) {
			$scope.getSentJSON(true);
		}
	}
	
	$scope.openAttachment = function (attachment) {
		if(StringUtils.isApprovalPollQuestion(attachment.objectBaseUuid)){
			approvalsService.setApprovalOpenFromInbox(attachment);
			$state.go('home.approvals');		
		}
		else if(StringUtils.isSurvey(attachment.objectBaseUuid)){
			surveysService.setSurveyOpenFromInbox(attachment);
			$state.go('home.surveys');		
		}
		else if(attachment.boName.toLowerCase() =="folderbo") {
			bvDialogService.openFolderView(attachment);
		} else {
			repositoryService.openPdfViewer(attachment);
		}
	}

	$scope.shouldRenderAsPdf = function(attachment) {
		return imageService.shouldRenderAsPdf(attachment.extensionType);
	}
	var getSearchLimit = function() {
		messagesService.getSearchLimit().then(function(response)
				{
					$scope.pageSize = response.data;
					$scope.getInboxJSON();
				});
	}
	var isSecureEnabled = function() {
		messagesService.isSecureEnabled().then(function(response)
		{
			$scope.secure = response.data;
		});
	}

	getSearchLimit();
	isSecureEnabled();

	var openMessageFromPLink = function()
	{
			$scope.selectedBox.messageSelected = true;

			messagesService.getSharedContainerWithAttachmentsJSON(homeModel.plinkObjUuid)
			.then(function(response) {
				$scope.selectedBox.message = response.data;

				if($scope.selectedBox.message.unRead == true)
				{
					messagesService.markItemRead($scope.selectedBox.message.objectBaseUuid);
					$scope.selectedBox.messages[$scope.selectedBox.messages.indexOf($scope.selectedBox.message)].unRead = false;
				}

				$scope.showMessageDetails = true;
				$scope.middleBarObject.showBack = false;

				$scope.middleBarObject.title = $scope.selectedBox.message.name.substr(0, 30);
				$scope.selectedMsgUuid = $scope.selectedBox.message.objectBaseUuid;
				if($scope.selectedBox.message.name.length > 30) $scope.middleBarObject.title += "...";

				getInboxUnreadCount();
				$scope.inboxSliderOptions.items = new Array();
				$scope.inboxSliderOptions.items.push.apply($scope.inboxSliderOptions.items,$scope.selectedBox.message.attachments);
				if(MobileUtil.isSurface() || MobileUtil.isAndroid())
				{
					$scope.openMessage(response.data);
				}
			});
	};

	$scope.getLocalizedDate = function(date) {
		return moment($filter('date')(date, 'medium')).format('lll');
	}

	var shouldLoadMore = function()
	{
		// console.log(this.mcs.top);
		if(!$scope.inProgress) {
			document.querySelector('body > spinner').style.display = 'none';
			$scope.getMoreMessages();
		}
	}

	$timeout(function(){
		if($element.find('.inboxItemsGrid')[0])
			$element.find('.inboxItemsGrid')[0].addEventListener('scroll', function(e){
				if((e.target.offsetHeight + e.target.scrollTop) >= (e.target.scrollHeight)){
					if(!$scope.inProgress) {
						document.querySelector('body > spinner').style.display = 'none';
						$scope.getMoreMessages();
					}
				}
			})
	},1000)


	//For Mobile
	$scope.inboxActive = true;
	$scope.mobileInboxClicked = function(){
		$scope.getInboxJSON();
		$scope.inboxActive = true;
		$scope.endOflist = false;
//		$(".inboxTextDivMobile").css('color', '#282828');
//		$(".sentTextDivMobile").css('color', '#D8D8D8');
//		$(".inboxTextDivMobile").css('text-decoration', 'underline');
//		$(".sentTextDivMobile").css('text-decoration', 'none');
	};

	$scope.mobileSentClicked = function(){
		$scope.getSentJSON();
		$scope.inboxActive = false;
		$scope.endOflist = false;
//		$(".inboxTextDivMobile").css('color', '#D8D8D8');
//		$(".sentTextDivMobile").css('color', '#282828');
//		$(".sentTextDivMobile").css('text-decoration', 'underline');
//		$(".inboxTextDivMobile").css('text-decoration', 'none');

	};

	$scope.threadTransformStyle = {'transform': "translateY(-45px)"};

	$scope.open = function(item, index){
	    item.open = !item.open;
	    $scope.inboxSliderOptions.items = new Array();
	    if(item.open) {
			$scope.inboxSliderOptions.items.push.apply($scope.inboxSliderOptions.items,item.items);
	    }
	    $scope.translateVal = index*40 + index*5 + 40;
	    !item.open ? $scope.translateVal = 0 : null;
	    $scope.threadTransformStyle = {'transform': "translateY(-" + $scope.translateVal + "px)", '-ms-transform': "translateY(-" + $scope.translateVal + "px)"};
	    $scope.translateStyle = {'transform': 'translateY(-' + $scope.translateVal + 'px)', '-ms-transform': 'translateY(-' + $scope.translateVal + 'px)'};
	    for(var i=0; i<$scope.selectedBox.message.parentShareContainer.length; i++) {
	    	if(i != index) {
	    		$scope.selectedBox.message.parentShareContainer[i].open = false;
	    	}
	    }

	    $timeout(function(){
		    bvSliderGetInstance(item.objectBaseUuid).fixQuantity();
		    bvSliderGetInstance(item.objectBaseUuid).fixButtons();
	    });
	}

	$scope.topMessagesPanel = {
        templateUrl: 'modules/messages/toppanel/toppanel.tpl.html',
        events : [
            {
                name: 'selectTab',
                value : function(tab){
                    if (tab.func != undefined) {
                        tab.func();
                    }
                }
            },
            { name: 'tabs', value : $scope.tabs}
        ]
    }


}

angular
	.module('messages', ['ngGrid','messages.service','common.service','localization.service','context.service','repository.service', 'ngTouch'])
	.config(configMessagesController)
	.controller('MessagesController', MessagesController)
	.filter('unsafe',function($sce) {
		return function(text) {
			return $sce.trustAsHtml(text);
		};
	});
