monthviewController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'calendarService', 'localizedMessages', 'modelService', 'commonService', 'context', 'bvLoadingSpinner', 'bvDialog', 'bvSliderService', 'bvDialogMobile', 'calendarServiceContext', 'homeContext', '$timeout', 'toppanelService', 'monthviewService', 'eventsdialogService'];

function monthviewController($scope, $rootScope, $state, $stateParams, calendarService, localizedMessages, modelService, commonService, context, bvLoadingSpinner, bvDialog, bvSliderService, bvDialogMobile, calendarServiceContext,homeContext, $timeout, toppanelService, monthviewService, eventsdialogService){

    $scope.monthview = monthviewService.createMonth();
    $scope.events = calendarService.getAllCalendarEvents();

    var toppanel = toppanelService.getInstance('MEETINGS');

    var next = function(){
        var nextMonth = $scope.monthview.getMomentDate().add(1, 'months');
        $scope.monthview = monthviewService.createMonth(nextMonth);
        toppanel.addEvent('monthTitle', $scope.monthview.monthInfo);
    }

    var prev = function(){
        var prevMonth = $scope.monthview.getMomentDate().add(-1, 'months');
        $scope.monthview = monthviewService.createMonth(prevMonth);
        toppanel.addEvent('monthTitle', $scope.monthview.monthInfo);
    }

    var today = function(){
        $scope.monthview = monthviewService.createMonth();
        toppanel.addEvent('monthTitle', $scope.monthview.monthInfo);
    }

    toppanel.addEvent('monthToday', today);
    toppanel.addEvent('prev', prev);
    toppanel.addEvent('next', next);
    toppanel.addEvent('monthTitle', $scope.monthview.monthInfo);

    $scope.monthviewService = monthviewService;

    $scope.MobileUtil = MobileUtil;

    $scope.clickEvent = function(day, browserEvent){
        if($scope.events.listEventsByDay[day.day] && $scope.events.listEventsByDay[day.day].length > 0){
            eventsdialogService.showDialog($scope.events.listEventsByDay[day.day], day, browserEvent);
            day.clicked = true;
        }
    }
    
	$(document).click(function(e){
	    // Check if click was triggered on or within #menu_content
	    if( !$(e.target).parents(".nasdaq .bv-scroll-list > .scroll-list-items .scroll-list > li").length > 0 && !$(e.target).parents(".meeting-details-react .modal-dialog").length > 0  && !$(e.target).parents(".events-dialog .modal-dialog .bv-modal-body>div>ul.left-dialog li").length > 0 && !$(e.target).parents("month-calendar").length > 0) {
	    	$('.meeting-details-react').remove();
			$scope.currentMeetingId = '';
	    }else{
	    	
	    	}	
	});

    $scope.clickNewEvent = function(day, browserEvent){
        if(!calendarService.isCurrentUserAllowedToCreateModule()){
            return;
        }

        calendarService.tabs[0].rightMenuList[0].click(day);
    }
}