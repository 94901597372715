angular
    .module('dialogControllers.service')
	.controller('createFolderController', createFolderController)
	.directive('focusMe', function($timeout, $parse) {
		return {
	    link: function(scope, element, attrs) {
	      var model = $parse(attrs.focusMe);
	      scope.$watch(model, function(value) {
	        if(value === true) {
	          $timeout(function() {
	            element[0].focus();
	          });
	        }
	      });
	    }
	  };
	});

createFolderController.$inject =  ['$scope', 'bvDialog', 'localizedMessages', 'repositoryService', 'context',
        'templatesService', 'companyUserService', 'permissionsService', 'constants', '$filter'];

function createFolderController($scope, bvDialog, localizedMessages, repositoryService, context, templatesService,
        companyUserService, permissionsService, constants, $filter) {

    $scope.enableFolderPermissionPrompt = context.getModel().companyVO.enableFolderPermissionPrompt;

	$scope.popupTitle 	= localizedMessages.get('CREATE_FOLDER');
	$scope.actionBtnTxt = localizedMessages.get('OK');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.addFolderPlaceholder = localizedMessages.get('CREATE_FOLDER_PLACEHOLDER');

	var objectBaseUuid = $scope.bvModalControllerInputs.objectBaseUuid;
	var actionTrue = $scope.bvModalControllerInputs.actionTrue;
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.enableActionBtn = ($scope.bvModalControllerInputs.enableActionValidation) ? !$scope.bvModalControllerInputs.enableActionValidation : true;
	$scope.addFolderName = {}

	$scope.action = function() {
		var folderName = $scope.addFolderName.val;
		if(StringUtils.isNullEmpty(folderName))
    		return;
		
//		var reg=/\+|:|"|<|>|>|\?|\//;
//    	if(reg.test(folderName))
//    	{
//    		console.log("Special Characters are not allowed");
//    		bvDialog.showMessage(localizedMessages.get("SPECIAL_CHARACTERS_NOT_ALLOWED"));
//
//    		return;
//    	}
    	
    	var newName = StringUtils.replaceNotSupportCharWithDash(folderName);

		repositoryService.createFolder(objectBaseUuid, newName, $scope.enableFolderPermissionPrompt, $scope.listOfPermissions)
		.then(function(data)
    	{
			if(data.data == "FOLDER_NAME_EXISTS")
			{
				bvDialog.showMessage(localizedMessages.get("FOLDER_NAME_EXISTS"));
			}
			else
			{
				actionTrue();
				bvModal.close();
			}
    	});

    };
    $scope.$watch('addFolderName.val', function(folderName) {
		if(folderName && folderName != '') {
			$scope.enableActionBtn = true;
		} else
		{
			$scope.enableActionBtn = false;
		}
	});

	//PERMISSIONS:

	$scope.ADD = localizedMessages.get('ADD');
    $scope.REMOVE = localizedMessages.get('REMOVE');
    $scope.PERMISSIONS = localizedMessages.get('PERMISSIONS');
    $scope.VIEW_PERMISSIONS = localizedMessages.get('VIEW_PERMISSIONS');
    $scope.SEARCH_TO_ADD_USERS = localizedMessages.get('SEARCH_TO_ADD_USERS');

    $scope.closeIcon = templatesService.getUrl('closeBtn');
    $scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';

    $scope.dialogTitle = localizedMessages.get('PERMISSIONS');
    $scope.inheritedPermissionsFromParentFolderLabel = localizedMessages.get('INHERITED_PERMISSIONS_FROM_PARENT_FOLDER');
    $scope.explicitPermissionsLabel = localizedMessages.get('EXPLICIT_PERMISSIONS');
    $scope.nameLabel = localizedMessages.get('NAME');
    $scope.descriptionLabel = localizedMessages.get('DESCRIPTION');
    $scope.saveButtonLabel = localizedMessages.get('SAVE');
    $scope.colorLabel = localizedMessages.get('COLOR');
    $scope.typeLabel = localizedMessages.get('TYPE');
    $scope.nameLabel = localizedMessages.get('NAME');
    $scope.permissionLabel = localizedMessages.get('PERMISSION');
    $scope.actionBtnTxt = localizedMessages.get('SAVE');
    $scope.closeBtnTxt = localizedMessages.get('CANCEL');
    $scope.folder = localizedMessages.get('FOLDER');
    $scope.users = localizedMessages.get('USERS');
    $scope.addLabel = localizedMessages.get('ADD');

    $scope.tooltipOwner = localizedMessages.get('PERMISSION_TOOLTIP_OWN');
    $scope.tooltipShare = localizedMessages.get('PERMISSION_TOOLTIP_COLLABORATE');
    $scope.tooltipPrint = localizedMessages.get('PERMISSION_TOOLTIP_PRINT');
    $scope.tooltipRead = localizedMessages.get('PERMISSION_TOOLTIP_VIEW');
    $scope.tooltipDeny = localizedMessages.get('TOOLTIP_DENY');

    var teamspaceTitle = localizedMessages.get('TEAMSPACE');
    var companyTitle = localizedMessages.get('COMPANY');

    $scope.folderHierarchyLabel = localizedMessages.get('FOLDER_HIERARCHY');
    $scope.applyToSubfoldersAndFilesLabel = localizedMessages.get('APPLY_TO_SUBFOLDERS_AND_FILES');
    $scope.dragDropLabel = localizedMessages.get('DRAG_AND_DROP_USER_TO_ADD');

    $scope.listOfPermissions = [];

    $scope.showApplyToSubFoldersOption = context.getModel().enableApplyToSubFolders; //HTML-2171
    $scope.modelCheckbox = {
        //                  showInherit: true,
        //                  showExplicit: true,
        applyToSub: false //HTML-2171
    }
    $scope.allCompanyUsers = angular.copy(companyUserService.getPortalGroupsAndUsers());

    var options = []
    for (var i in context.getModel().permissionDisplayNames) {
        options.push(context.getModel().permissionDisplayNames[i]);
    }
    $scope.selectOptions = [];

    $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['owner'].toUpperCase()), value: context.getModel().permissionDisplayNames['owner'], title: $scope.tooltipOwner });
    $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['share'].toUpperCase()), value: context.getModel().permissionDisplayNames['share'], title: $scope.tooltipShare });
    $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['read'].toUpperCase()), value: context.getModel().permissionDisplayNames['read'], title: $scope.tooltipRead });
    $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['print'].toUpperCase()), value: context.getModel().permissionDisplayNames['print'], title: $scope.tooltipPrint });
    $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['deny'].toUpperCase()), value: context.getModel().permissionDisplayNames['deny'], title: $scope.tooltipDeny });

    //              if(StringUtils.isFolder($scope.itemObjectBaseUuid))
    //                  $scope.selectOptions.push( { label: localizedMessages.get(context.getModel().permissionDisplayNames['deny'].toUpperCase()), value: context.getModel().permissionDisplayNames['deny'], title:  $scope.tooltipDeny  } );

    var selectOptions_ = angular.copy($scope.selectOptions);

    $scope.tabsUsersList = [
        { title: teamspaceTitle, content: teamspaceTitle },
        { title: companyTitle, content: companyTitle }
    ]

    $scope.showUserList = false;
    $scope.showFolderList = false;
    $scope.grouping = true;

    $scope.permission = {};
    $scope.permissionOpen = false;
    $scope.showUserFiltering = false;
    $scope.filteredUserList = [];

    // $timeout(function () {
    //  $timeout(function () {
    //      commonService.getUsers().then(function (data) {
    //          $scope.allUsers = data;
    //      });
    //  }, 0);
    // }, 1000);


    $scope.decodeUserName=function (name)
    {
        if(name && name!=null)
        {
            return decodeURIComponent(name);
        }
        else
        {
            return name;
        }

    }

    $scope.openPermissions = function()
    {
        if (!$scope.permissionOpen) {
            var createFolderModal = document.getElementsByClassName("bv-create-folder")[0];
            createFolderModal.classList.add("show-permissions");

            $scope.permissionOpen = true;
        }
    }

    $scope.closePermissions = function()
    {
        var createFolderModal = document.getElementsByClassName("bv-create-folder")[0];
        createFolderModal.classList.remove("show-permissions")

        $scope.permissionOpen = false;
        $scope.showUserFiltering = false;
    }

    $scope.isUser = function(user)
    {
         return user.objectBaseUuid == context.getModel().userVO.objectBaseUuid;
    }

    $scope.isUserSelected = function(user)
    {
        var result = false;
        $scope.listOfPermissions.forEach(function (userSelected, i) {
            if (userSelected.objectBaseUuid == user.objectBaseUuid) {
                result = true;
            }
        });

        return result
    }

    $scope.removeUser = function(user)
    {
        $scope.listOfPermissions.forEach(function (userSelected, i) {
            if (userSelected.objectBaseUuid == user.objectBaseUuid) {
                $scope.listOfPermissions.splice(i, 1)
            }
        });
    }

    $scope.addUser = function(user)
    {
        $scope.listOfPermissions.push({
            name: encodeURIComponent(user.name),
            type: user.type,
            objectBaseUuid: user.objectBaseUuid,
            permission: context.getModel().permissionDisplayNames[context.getModel().defaultDocumentPermission]
        });
    }

    var getPermissionsByObjectBaseUuid = function (objectBaseUuid) {
        permissionsService.getPermissionFromProperties(objectBaseUuid).then(function (data) {
            $scope.listOfPermissions = data;
            $scope.listOfPermissions = getUpperCaseName($scope.listOfPermissions);

            $scope.userListEnable = true;
            var hasCurrentUser = false;
            $scope.listOfPermissions.forEach(function (item, i) {
                if ($scope.isUser(item)) {
                    ($scope.listOfPermissions.splice(0, 0, $scope.listOfPermissions[i]), $scope.listOfPermissions.splice(i + 1, 1));
                    hasCurrentUser = true;
                }
            });

            //add current user if he is not present on the folder permissions
            if (!hasCurrentUser) {
                var currentUser = context.getModel().userVO
                $scope.listOfPermissions.unshift({
                    name: encodeURIComponent(currentUser.name),
                    type: currentUser.type,
                    objectBaseUuid: currentUser.objectBaseUuid,
                    permission: context.getModel().permissionDisplayNames['owner']
                });
            }
        });
    }

    $scope.toggleDropdown = function ($event, user) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.selectOptions = selectOptions_;

        user.isopen = !user.isopen;
    };

    $scope.toggled = function (isopen) {
        if (!isopen) {
            $scope.selectOptions = selectOptions_;
        }
    }

    getPermissionsByObjectBaseUuid(objectBaseUuid);

    function getUpperCaseName(array) {
        if (array) {
            for (var i = 0; i < array.length; i++) {
                array[i].name=encodeURIComponent(array[i].name);
                var type = '';
                for (var k = 0; k < array[i].type.length; k++) {
                    if (array[i].type[k] == array[i].type[k].toUpperCase()) {
                        type = type + array[i].type[k];
                    }
                }
                type = constants.get('GET_SHORT_OBJECT_NAME_' + type);
                if (array[i].typeM != type) {
                    array[i].typeM = type;
                }
            }
        }
        return array;
    }

    $scope.checkFilter = function(filterValue)
    {
        console.log('checkFilter > ' + filterValue);
        if(filterValue && filterValue.trim() !=="")
        {
            $scope.showUserFiltering = true;
            $scope.filteredUserList = $filter('filter')($scope.allCompanyUsers, filterValue);;
        } else {
            $scope.showUserFiltering = false;
            $scope.filteredUserList = [];
        }
    }

    //END PERMISSIONS
}
