function EventPresentationController($scope, $state, modal, context, homeContext, modelService, conferenceService, presentationService, imageService, localizedMessages, repositoryService, bvDialog, bvDialogService, bvDialogMobile, bvSliderService, calendarService, $timeout)
{
	// presentationService.showingConfDialog();
	$scope.currentUserVO = context.getModel().userVO;

	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.conf = presentationService.getConf();
	$scope.$on('conf:updated', function(event,conf)
	{
    	$scope.conf = conf;
    	// host might have left and got stopped conference, so joined conf is
		// blank.
 		// home controller handles this.
    	if($scope.conf == undefined || Object.keys($scope.conf).length == 0)
    		$scope.close();
    });
    $scope.$on('confParticipants:updated', function(event, conf)
    {
    	$scope.conf = conf;
    	updateParticipantsName();
    	updatePresentorAndHost();
   		getYourRole();
    });
    $scope.$on('confSyncKeyVal:updated', function(event, conf)
    {
    	$scope.conf = conf;
    	updateParticipantsName()
    });

    $scope.close = function () {
		presentationService.closingConfDialog();
 		bvModal.close();
	}

	$scope.confEvent = $scope.conf.conf_event;

	var updateConfGuestsAndAttachments = function()
	{
		calendarService.loadGuestandResponseForEvent($scope.confEvent.objectBaseUuid).then(function(data)
		{
			if(data)
			{
				$scope.confEvent.guests = data.data.guests;

				var eventResponseList = data.data.eventResponseList;
				$scope.confEvent.guests.forEach(guest => {
          eventResponseList.forEach(event => {
            if(guest.objectBaseUuid == event.createdByBaseUuid)
							guest.response = event.response.toLowerCase();
          })
        })

				$scope.confEvent.documentVOList = data.data.documentVOList;
				presentationService.setConfEvent($scope.conf.conf_event);

				attachmentsUpdated();

				$scope.conf.conf_event.guests = $scope.confEvent.guests;
				$scope.conf.conf_event.documentVOList = $scope.confEvent.documentVOList;
			}
		});
	}

	// when user is made presentor - event doesn't have all details - we need to
	// update
	if(($scope.confEvent != null && $scope.confEvent != undefined) &&
		( $scope.confEvent.guests == undefined || $scope.confEvent.guests.length == 0))
	{
		updateConfGuestsAndAttachments();
	}

	var dtStr = $scope.confEvent.lastModifiedOnGMT + " GMT";
	var lcDate = new Date(dtStr);

	// MEETX-53353
	var dateHelper = new DateHelper($scope.currentUserVO);
	var lastUpdatedVal = dateHelper.getForrmattedAbbrDate(lcDate);

	$scope.confEvent.lastUpdatedUiVal = lastUpdatedVal;

	$scope.popupTitle  =   localizedMessages.get('MEETING_IN_PROGRESS');

	$scope.UI_PRESENT_TXT = localizedMessages.get('PRESENT');
	$scope.UI_LAST_UPDATED_TXT = localizedMessages.get('LAST_UPDATED');
	$scope.UI_YOU_ARE_TXT  	   = localizedMessages.get('YOU_ARE');
	$scope.UI_HOST_TXT = localizedMessages.get('HOST');
	$scope.UI_CURRENT_PRESENTER = localizedMessages.get("CURRENT_PRESENTER");
	$scope.UI_MAKE_PRESENTER = localizedMessages.get("MAKE_PRESENTER")
	$scope.UI_AND_TXT = localizedMessages.get("AND");
	$scope.UI_PRESENTER_TXT = localizedMessages.get("PRESENTER");
	$scope.UI_JOINED_TXT = localizedMessages.get("JOINED");
	$scope.UI_NOT_RESPONDED_TXT = localizedMessages.get("NOT_RESPONDED");
	$scope.UI_TENTATIVE_TXT = localizedMessages.get("TENTATIVE");
	$scope.UI_DECLINED_TXT = localizedMessages.get("DECLINED");
	$scope.UI_ACCEPTED_TXT = localizedMessages.get("ACCEPTED");
	$scope.UI_END_TXT = localizedMessages.get("END");
	$scope.UI_LEAVE_TXT = localizedMessages.get("LEAVE");
	$scope.UI_DETAILS_TXT  	   = localizedMessages.get('DETAILS');
	$scope.UI_ATTENDEES_TXT    = localizedMessages.get('ATTENDEES');

	$scope.hidePanelFlag = {
		joined: false,
		accepted: true,
		tentative: true,
		declined: true,
		not_responded: true
	};

	$scope.arrowIcon = 'resources/assets/svgs/grey_arrow_meeting_in_progress.svg';

	var updatePresentorAndHost = function()
	{
		for(var i in $scope.conf.conf_participants)
		{
			if($scope.conf.conf_participants[i].isHost == true)
			{
				$scope.conf.conf_participants[i].name = $scope.conf.conf_participants[i].name.replace(" (Host)", "");
				$scope.host = $scope.conf.conf_participants[i];
			}

			if($scope.conf.conf_participants[i].isPresenter == true)
			{
				$scope.currentPresentor = $scope.conf.conf_participants[i];
				$scope.conf.currentPresentor = $scope.currentPresentor;
			}
		}

		// // TODO: Need to be removed and make sure meeting creation has host
		// assigning.
		if($scope.host == undefined || $scope.host == null)
			$scope.host = {objectBaseUuid: $scope.conf.conf_event.host};

		if($scope.currentPresentor == undefined || $scope.currentPresentor == null) {
			$scope.currentPresentor = {objectBaseUuid: $scope.conf.conf_event.host};
		}

		// presentor is changed and I am not hosting then should see running
		// confs
		if($scope.currentPresentor.objectBaseUuid != $scope.currentUserVO.objectBaseUuid &&
			$scope.host.objectBaseUuid != $scope.currentUserVO.objectBaseUuid)
		{
				$scope.close();
		 		presentationService.showRunningConfsListDialog($scope.conf.conf_event);
		}
	}

	updatePresentorAndHost();

	$scope.role = "";
	var getYourRole = function()
	{
		if($scope.host != undefined && $scope.currentPresentor != undefined && $scope.currentUserVO.objectBaseUuid == $scope.host.objectBaseUuid && $scope.currentUserVO.objectBaseUuid == $scope.currentPresentor.objectBaseUuid)
			$scope.role = $scope.UI_HOST_TXT + ", " + $scope.UI_PRESENTER_TXT;
		else if($scope.currentUserVO.objectBaseUuid == $scope.host.objectBaseUuid)
			$scope.role = $scope.UI_HOST_TXT;
		else if($scope.currentPresentor != undefined && $scope.currentUserVO.objectBaseUuid == $scope.currentPresentor.objectBaseUuid)
			$scope.role = $scope.UI_PRESENTER_TXT;
	}

	getYourRole();

	$scope.getDisplayTime = function (calEvent)
	{
		if(calEvent!=undefined)
		{

			// MEETX-53353
			var dateHelper = new DateHelper($scope.currentUserVO);

			var startTime = dateHelper.getFormattedTimeUTC(parseInt(calEvent.startDateGMTMillis));
			var endTime = dateHelper.getFormattedTimeUTC(parseInt(calEvent.endDateGMTMillis));

			return startTime + " - " + endTime;
		}
	}

	$scope.metadetailsHidden = false;
	$scope.toggleMetadetails = function() {
		$scope.metadetailsHidden = !$scope.metadetailsHidden;
	}

	/* Attachments Start */
	$scope.msg_no_meeting_attachments = localizedMessages.get('MSG_NO_MEETING_ATTACHMENTS');

	var sliderRenderCallback = function(){
		bvSliderService.currentIndex = 0;
		$timeout(function(){
			bvSliderService.fixButtons();
		})
	}

	$scope.sliderOptions = {
		items: angular.copy($scope.confEvent.documentVOList),
		slideThrough: 1,
		shownItems: 2,
		renderCallback : sliderRenderCallback
	};

	var attachmentsUpdated = function()
	{
		$scope.sliderOptions.items = $scope.confEvent.documentVOList;
		$scope.noMeetingAttachments = $scope.confEvent.documentVOList == null || $scope.confEvent.documentVOList.length == 0 ? true : false;
		bvSliderService.currentIndex = 0;
		bvSliderService.fixScroll();
	}
	attachmentsUpdated();
	/* Attachments End */

	$scope.getDocumentThumbnail = function(attachment)
 	{
 		return imageService.getThumbnailForObject(attachment, "large");
	}

	var updateParticipantsName = function()
	{
		if($scope.conf.conf_sync_key_val != undefined)
		{
			$scope.conf.conf_sync_key_val.forEach(confSyncKey => {
				$scope.conf.conf_participants.forEach(confParticipants => {
					if(confSyncKey.userId == confParticipants.objectBaseUuid)
					{
						confSyncKey.name = confParticipants.name
					}
				})
			})
		}
	}

	updateParticipantsName();

	$scope.leavePresentation = function()
	{
		// if presenting but not hosting - so make host a presentor and then
		// leave
		$scope.endPresentation().then(function(data){
			if($scope.currentUserVO.objectBaseUuid != $scope.host.objectBaseUuid) {
	 			$scope.makePresentor({userId: $scope.host.objectBaseUuid});
			}
			if(MobileUtil.isAndroid() ||  MobileUtil.isIPhone()){
				 bvModal.close();
			}
		});
	}

	$scope.endPresentation = function()
	{
 		return presentationService.leaveConf($scope.currentUserVO.objectBaseUuid).then(function(daa){
 			$scope.close();
 			homeContext.confCurrentPageNumber = 1;
 			
 			if(!$state.is('home.dashboard')){
 				$state.go('home.meetings');
 			}
 		});
	}

	$scope.presentDoc = function(attachment) {
		var syncCommVO = {
			"name":"SHARE_DOCUMENT",
			"objectBaseUuid": $scope.conf.conf_event.objectBaseUuid,
			"documentBaseUuid": attachment.objectBaseUuid,
			"pageNumber": homeContext.confCurrentPageNumber,
			"value":"START_SHARING"
		};
 		conferenceService.shareDocument(angular.toJson(syncCommVO))
 		.then(function(data){

 			conferenceService.getSynchronizedCommunicationVOJSON($scope.conf.conf_event.objectBaseUuid).then(function(data)
			{
				presentationService.updateConfSyncVO(data.data);
			});

 			var confObject = syncCommVO;
 			confObject.isPresentMode = true;
 			confObject.isPresenter = true;
 			repositoryService.openPdfViewer(attachment, confObject);
 			// console.log(data);
 		});
	}

	$scope.makePresentor = function(user)
	{

		if($scope.confEvent == undefined || Object.keys($scope.confEvent).length == 0)
			return;

		var confId = $scope.confEvent.objectBaseUuid;

		var userId = user.userId;
		if(userId == undefined) userId = user.objectBaseUuid;
		conferenceService.grantshareDesktop(userId, confId)
		.then(function(data)
	 	{
	 		// making presentor to someone but it hosting then should see the
			// same screen
	 		// otherwise runing conf list
	 		if($scope.currentUserVO.objectBaseUuid != $scope.host.objectBaseUuid)
		 	{
		 		$scope.close();
		 		presentationService.showRunningConfsListDialog($scope.conf.conf_event);
			}
		});
	}

	$scope.manageBackDrop = function() {
		presentationService.manageBackDrop();
	}
	
	 $scope.showMobileConfAttendees = function()
	 {
		 bvDialogMobile.showMobileEventAttendees();
	 }
	 
	 $scope.showMobileConfDetails = function()
	 {
		 bvDialogMobile.showMeetingDetails($scope.confEvent);
	 } 
	 
}

angular
	.module('conference.module')
	.controller('EventPresentationController', ['$scope', '$state', '$modal', 'context', 'homeContext', 'modelService', 'conferenceService', 'presentationService', 'imageService', 'localizedMessages','repositoryService', 'bvDialog', 'bvDialogService','bvDialogMobile','bvSliderService', 'calendarService', '$timeout', EventPresentationController ])
	;
