IsOnlineCheckServiceForJup.$inject = ['$rootScope','sessionTimeoutService','$timeout','context', 'pollingService','bvPolling','$state','homeContext', 'Idle'];

function IsOnlineCheckServiceForJup($rootScope, sessionTimeoutService, $timeout, context, pollingService, bvPolling, $state, homeContext, Idle)
{
	var sendPing = false;

	this.isStarted = function()
	{
		return sendPing;
	}

	this.startStatusCheck = function()
	{
		sendPing = true;
		getStatus();
    }

	var stopStatusCheck = function()
	{
		sendPing = false;
	}

	var getStatus = function()
    {
    	homeContext.isApp = true;
    	pollingService.getJupOnlineStatus().then(function(data)
    	{
    		try
        	{
	    		var result = data.data;
	    		//console.log("JUP STATUS RESULT:",result);
	    		if(result === undefined || result === false || result === "false")
	    		{
//	    			if(bvPolling.isPollingStarted())
//	    			{
//	    				bvPolling.stopPolling();
//	    			}
	    			MobileUtil.setJupOnlineStatus(true);
    				homeContext.jupOnline = false;
	    			homeContext.onlineStatus.val = false;
	    			document.querySelector('body').classList.add('jup-offline-mode');
	    			if(MobileUtil.isDesktopClient()) {
	                    document.querySelector('body').classList.add('jup-dc-offline-mode');
	                } else {
	                    document.querySelector('body').classList.add('jup-other-offline-mode');
	                }
	    			var currentState = $state.current.name;
	    			//console.log("CurrentState:",currentState);
	    			var allowedOfflineStates = "";
					//This will determine what modules will be accessible offline on the desktop client
					if(MobileUtil.isDesktopClient()) {
	    				//allowedOfflineStates = "#home.meetings#home.calendar#home.briefcase#home.newitems#home.meetingDetails#home.signatures#home.inbox#home.surveys#home.openSurvey#home.approvals#home.home";
                        allowedOfflineStates = "#home.briefcase#home.newitems#home.inbox#home.home";
	    			} else {
	    				allowedOfflineStates = "#home.meetings#home.calendar#home.briefcase#home.newitems#home.meetingDetails#home.signatures#home.inbox#home.home";
	    			}

	    			if(currentState!=undefined && currentState!="home.nomodule" && allowedOfflineStates.indexOf(currentState)== -1 && context && context.isReady())
	    			{
	    				if(MobileUtil.isApp()){
	    					$state.go("home.briefcase");
	    				}
	    				else
	    					$state.go("home.nomodule");
	    			}
	    			if(MobileUtil.isSurface() && sessionTimeoutService.isIdle())
    				{
	    				sessionIdle = true;
	    				$rootScope.$emit('PreemptLogoutWarn');
    				}
	    		}
	    		else
	    		{
	    		    //Was offline and now it's online - Desktop app needs to trigger sync again when that happens
	    		    if (!homeContext.jupOnline && MobileUtil.isDesktopClient()) {
	    		        startSyncForJup();
	    		    }

	    			MobileUtil.setJupOnlineStatus(false);
    				homeContext.jupOnline = true;
	    			homeContext.onlineStatus.val = true;
//	    			if(!bvPolling.isPollingStarted())
//	    			{
//	    				bvPolling.restart();
//	    			}
	    			document.querySelector('body').classList.remove('jup-offline-mode');
	    			document.querySelector('body').classList.remove('jup-other-offline-mode');
	    			//TODO maybe this if is not needed, since removing this class can be done no matter what
	    			if(MobileUtil.isDesktopClient())
	                {
	                    document.querySelector('body').classList.remove('jup-dc-offline-mode');
	                }
	    			else {
	                    document.querySelector('body').classList.remove('jup-other-offline-mode');
	                }
	    		}
			}
        	catch(err)
	    	{
	    		console.log("ERROR:"+err);
	    	}
	    	finally
	    	{
	    		if(sendPing)
	    			checkStatus();
	    	}
	    }).catch(function(error){
			console.log("ERROR WHEN REQUESTING JUP ONLINE STATUS: " + error);

			if(sendPing)
	    		checkStatus();
		});
    }
    var checkStatus = function()
    {
    	$timeout(getStatus, 5000);
    }

    var startSyncForJup = function() {
        var homeController = angular.element(document.querySelector('[home-controler]')).scope();
        if(homeController)
        {
            homeContext.startSync = true;
            homeController.startSyncForJup();
        }
    }
}