
angular
    .module('dialogControllers.service')
	.controller('copySurveyController', copySurveyController);

copySurveyController.$inject =  [ '$scope','$state', 'localizedMessages', 'commonService','context', 'homeService','bvDialog', 'bvDialogService','surveysService'];

function copySurveyController($scope, $state, localizedMessages, commonService, context, homeService,bvDialog, bvDialogService, surveysService){
	$scope.popupTitle = localizedMessages.get('COPY_SURVEY');
	var bvModal;
	bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.ui = {
			label : {
				in_this_case_all_the_survey_questions_and_participants_are_copied: localizedMessages.get('IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_ARE_COPIED'),
            	title_of_survey : localizedMessages.get('TITLE_OF_SURVEY'),
            	name_of_survey : localizedMessages.get('NAME_OF_SURVEY'),
            	copy_of_options : localizedMessages.get('COPY_OF_OPTIONS'),
            	copy_of_questions_and_participants : localizedMessages.get('COPY_OF_QUESTIONS_AND_PARTICIPANTS'),
            	copy_of_questions_and_participants_and_responses : localizedMessages.get('COPY_OF_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES'),
            	in_this_case_all_the_survey_questions_and_participants_and_responses_are_copied: localizedMessages.get('IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES_ARE_COPIED'),
            	copy_of_questions_only : localizedMessages.get('COPY_OF_QUESTIONS_ONLY'),
            	in_this_case_all_the_questions_are_copied : localizedMessages.get('IN_THIS_CASE_ALL_THE_QUESTIONS_ARE_COPIED'),
            	copy: localizedMessages.get('COPY'),
            	cancel : localizedMessages.get('CANCEL')
			}
	}
	$scope.copySurveyTitle = angular.copy($scope.bvModalControllerInputs.copySurveyTitle);
	$scope.surveyObjectBaseUuid = $scope.bvModalControllerInputs.copySurveyObjectBaseUuid;
	$scope.copySurveyType = function(value) {
		$scope.copySurveyRadio =value;
	}
	$scope.cancel = function(){
		bvModal.close();
	}
	$scope.changeCopySurveyName = function(name){
		$scope.copySurveyTitle = name;
	}
	$scope.copySurvey = function(){
		surveysService.copySurveyJSON($scope.surveyObjectBaseUuid, $scope.copySurveyTitle, $scope.copySurveyRadio).then(function(data){
			if(data.boName == "SuccessBO"){
				bvModal.close();
				bvDialog.showMessage(localizedMessages.get('SURVEY_COPIED_SUCCESSFULLY'));
				$state.reload();
			}else{
				bvModal.close();
				bvDialog.showMessage(localizedMessages.get("YOUR_NEW_SURVEY_HAS_NOT_BEEN_COPIED"));
			}
		});
	}
}