function adSearchQueryBoxService(){
    var element = {}

    this.searchString = {
        val: '',
        callback: null
    }

    this.setString = function(string){
        this.searchString.val = string;
    }
    this.getArrayOfKeywords = function(){
        return this.searchString.val.split(' ');
    }
    this.setElement = function(el){
        element = el;
    }
    this.getElement = function(){
        return element;
    }
}