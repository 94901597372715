function userPanelService(bvDialogService){

    this.showUserPanel = function(selectedUser, followUnFollow) {
        bvDialogService.showBvModal({
            windowClass : 'user-panel',
            controller : 'userPanelController',
            bodyTemplate : 'modules/dialogs/userpanel/userpanel.tpl.html',
            templateCustomButtonsUrl : 'modules/dialogs/userpanel/header.tpl.html',
            inputs : {
                selectedUser: selectedUser,
                followUnFollow: followUnFollow
            },
            buttons : null
        });
    }
}