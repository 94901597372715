function adSearchService(ajaxService, $timeout, advancedSearchService, $http, adSearchQueryBoxService){
    var instances = [];
    var uniq = null;

    var AdSearch = function(){
        this.searchString;
        this.lastDataCount = 0;
        this.items = [];
        this.showLimit = undefined;
        this.showSpinner = false;
        this.advanced = false;
        this.filterPanel = {
            enable : false,
            show : true
        }
        this.filterPanelInstance = {}
        this.searchFolder = {}
    }

    var addType = function(data){
        return _.map(data, function(o){
            var arr = o.name.split('.');
            o.type = arr[arr.length - 1];
            return o;
        });
    }

    AdSearch.prototype = {
        constructor: AdSearch,
        setItems: function(items){
            this.items = items;
        },
        getItems: function(){
            return this.items;
        },
        getDocuments: function(){
            return _.filter(this.items, function(item){
                return !StringUtils.isFolder(item.objectBaseUuid);
            })
        },
        getFolders: function(){
            return _.filter(this.items, function(item){
                return item ? StringUtils.isFolder(item.objectBaseUuid) : null;
            })
        },
        setLimit: function(showLimit){
            this.showLimit = showLimit;
        },
        parseData: function(data){
            if(!_.isEmpty(data) && data.boName !== 'FailureBO'){
                this.items = addType(data);
                this.lastDataCount = data.length;
            }
            else{
                this.items = [];
            }
        },
        addData: function(data){
            if(!_.isEmpty(data)){
                this.items = this.items.concat(addType(data));
                this.lastDataCount = data.length;
            }
        },
        spinnerShow: function(){
            this.showSpinner = true;
        },
        spinnerHide: function(){
            this.showSpinner = false;
        },
        setFilterPanel: function(filterPanel){
            this.filterPanelInstance = filterPanel;
        },
        getFilterPanel: function(){
            return this.filterPanelInstance;
        },
        setSearchFolder: function(searchFolder){
            this.searchFolder = searchFolder;
        }
    }

    this.createInstance = function(name){
        if(name != ''){
            var adSearch = new AdSearch();
            var existingInstance = _.find(instances, { name : name });
            if(!existingInstance){
                var instance = {
                    name : name,
                    searchObject : adSearch
                };
                instances.push(instance);

                return instance;
            }
            else{
                return existingInstance;
            }
        }

        return null;
    }

    this.getInstance = function(name){
        var instance = _.find(instances, { name : name });
        if(instance && 'searchObject' in instance && instance.searchObject instanceof AdSearch){
            return instance.searchObject;
        }

        return null;
    }

    this.getDefaultInstance = function(){
        var instance = _.find(instances, { name : 'DEFAULT' });
        if(!instance){
            instance = this.createInstance('DEFAULT');
        }

        return instance.searchObject;
    }

    var setFilters = function(sfo){
        //sfo.advancedSearchData.offset               = 0;
        sfo.advancedSearchData.lastVisit            = sfo.rangeSelection.lastVisit;
        sfo.advancedSearchData.startDateMillis      = sfo.rangeSelection.from;
        sfo.advancedSearchData.endDateMillis        = sfo.rangeSelection.to;
        sfo.advancedSearchData.portalBaseUuidList   = _.map(_.filter(sfo.teamspaceSelection, { selected : true}), 'objectBaseUuid').join('||');
        sfo.advancedSearchData.phraseSearchText     = sfo.getAllPhrases().join('||');
        sfo.advancedSearchData.exculdeSearchText    = sfo.getAllExcludes().join('||');
        sfo.advancedSearchData.fileTypes            = _.map(_.filter(sfo.fileSelection, { value: true }), 'name').join('||');
    }

    this.recallServer = function(){
        var adSearch = this.getInstance('ADVANCED');
        var searchString = adSearchQueryBoxService.searchString.val;
        if(adSearch)
            this.getAdvancedSearchResult(adSearch, searchString, 0);
    }

    this.getAdvancedSearchResult = function(adSearch, searchString, offset, lastVisit, startDateMillis, endDateMillis, portalBaseUuidList, phraseSearchText, exculdeSearchText, fileTypes){

        if('advancedSearchData' in adSearch.filterPanelInstance){
            setFilters(adSearch.filterPanelInstance);
            var advancedSearchData = adSearch.filterPanelInstance.advancedSearchData;

            if(offset !== undefined) advancedSearchData.offset = offset;
            else offset = advancedSearchData.offset;

            if(lastVisit) advancedSearchData.lastVisit = lastVisit;
            else lastVisit = advancedSearchData.lastVisit;

            if(startDateMillis) advancedSearchData.startDateMillis = startDateMillis;
            else startDateMillis = advancedSearchData.startDateMillis;

            if(endDateMillis) advancedSearchData.endDateMillis = endDateMillis;
            else endDateMillis = advancedSearchData.endDateMillis;

            if(portalBaseUuidList) advancedSearchData.portalBaseUuidList = portalBaseUuidList;
            else portalBaseUuidList = advancedSearchData.portalBaseUuidList;

            if(phraseSearchText) advancedSearchData.phraseSearchText = phraseSearchText;
            else phraseSearchText = advancedSearchData.phraseSearchText;

            if(exculdeSearchText) advancedSearchData.exculdeSearchText = exculdeSearchText;
            else exculdeSearchText = advancedSearchData.exculdeSearchText;

            if(fileTypes) advancedSearchData.fileTypes = fileTypes;
            else fileTypes = advancedSearchData.fileTypes;
        }

        offset              = offset                || 0;
        lastVisit           = lastVisit             || false;
        startDateMillis     = startDateMillis       || null;
        endDateMillis       = endDateMillis         || null;
        portalBaseUuidList  = portalBaseUuidList    || null;
        phraseSearchText    = phraseSearchText      || null;
        exculdeSearchText   = exculdeSearchText     || null;
        fileTypes           = fileTypes             || null;

        if(searchString != ''){
            if(offset == 0)
                adSearch.items = [];
            adSearch.searchString = searchString;
            uniq = moment().unix();

            var request = ajaxService.getAdvancedDocumentAndFolderSearchList(offset, searchString, lastVisit, startDateMillis, endDateMillis, portalBaseUuidList, phraseSearchText, exculdeSearchText, fileTypes, uniq);

            return request.then(function(data){
                if(data && data.data){
                    if(adSearch instanceof AdSearch){
                        if(offset == 0){
                            if(uniq == data.config.uniq)
                                adSearch.parseData(data.data);
                        }
                        else
                            adSearch.addData(data.data);

                        advancedSearchService.updateMiddleBar(adSearch);
                    }
                    return data.data;
                }

                return null;
            });
        }
    }

    this.getSearchResult = function(adSearch, searchString){
        if(searchString != ''){
            adSearch.items = [];
            adSearch.searchString = searchString;
            adSearch.spinnerShow();
            uniq = moment().unix();

            var request = ajaxService.getDocumentAndFolderSearchList(searchString, uniq);
            var response = function(data){
                if(data && data.data){
                    if(adSearch instanceof AdSearch){
                        if(uniq == data.config.uniq){
                            adSearch.parseData(data.data);
                            adSearch.spinnerHide();
                        }
                    }
                    return data.data;
                }

                return null;
            }

            return request.then(response);
        }
    }

}