function dashboardService($q, ajaxService, responseValidatorService) {
	var service = {
			
		getOpenApprovals : function() 
		{
			var request = ajaxService.getMyOpenApprovalsJSON();
			return request.then(function(data)
					{
				if(data)
				{
					return data;
				}
				
				return null;
					}
			);
		},
		
		getMyOpenSurveys : function()
		{
			var request = ajaxService.getMySurveys()
			return request.then(function(data)
					{
				if(data)
				{
					return data;
				}
				
				return null;
					}
			);
		},
		getDocumentsForSignature : function()
		{
			var request = ajaxService.getDocumentsForSignature()
			return request.then(function(data)
					{
				if(data)
				{
					return data;
				}
				
				return null;
					}
			);
		},
		getAllCalendarEventListFullJSON : function(dateFrom, dateTo)
		{
			var request = ajaxService.getAllCalendarEventListFullJSON(dateFrom, dateTo)
			return request.then(function(data)
					{
				if(data)
				{
					return data;
				}
				
				return null;
					}
			);
		},
		getOpenInvitations : function()
		{
			var request = ajaxService.getOpenInvitations()
			return request.then(function(data)
					{
				if(data)
				{
					return data;
				}
				
				return null;
					}
			);
		},
		getAllOpenItems : function()
		{
			var request = ajaxService.getAllOpenItems()
			return request.then(function(data)
					{
				if(data)
				{
					return data;
				}
				
				return null;
					}
			);
		}
		
	}

	return service;
}
angular
	.module('dashboard.service', [ 'ajax.service' ])
	.factory('dashboardService',[ '$q', 'ajaxService', 'responseValidatorService', dashboardService ]);