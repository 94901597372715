function homeContext(){
	this.isApp = false;
	this.MobileUtil = MobileUtil;
	//this.isJupAndroidClient = false;
	this.jupOnline = true
	this.modules = {}
	this.modules.sidebarMenuModulesForAdmin = []
	this.modules.sidebarMenuModules = []
	this.moduleApply = {}
	this.currentDefault = {}
	this.currentDefault.landingModule = ''
	this.currentDefault.jupLandingModule = ''
	this.searchData = []
	this.expandMenu = {}
	this.expandedMenu = {}
	this.onlineStatus = {val:false}
	this.lastSync = {}
	this.startSync = false;
	this.sessionTimeout = null;
	this.confPresenterName = '';
	this.confCurrentPageNumber = 1;
	this.moduleStates = []
}
