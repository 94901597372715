saveSurveyTemplateService.$inject = ['bvDialogService', 'localizedMessages'];

function saveSurveyTemplateFromContextMenuService(bvDialogService, localizedMessages){

    this.dialog = {}
    
    this.saveSurveyTemplate = function (surveyBaseUuid, templateName){
        this.dialog = bvDialogService.showBvModal({
        	windowClass : 'bv-copy-survey-modal',
			controller : 'saveSurveyTemplateFromContextMenuController',
			bodyTemplate : 'modules/surveys/dialogs/SaveTemplateFromContextMenu/SaveSurveyTemplateFromContextMenu.tpl.html',
			inputs : {
				surveyBaseUuid : surveyBaseUuid,
				templateName : templateName
			 },
			buttons: null
        });
    }
}