meetingMobileDetailsController.$inject = ['$scope', 'localizedMessages','context', 'presentationService', 'bvSliderInstanceService', 'calendarService', 'imageService', 'repositoryService', 'bvDialog', 'bvDialogMobile', 'legacyUserService', 'reactInterface'];
function meetingMobileDetailsController($scope, localizedMessages, context, presentationService, bvSliderInstanceService, calendarService, imageService, repositoryService, bvDialog, bvDialogMobile, legacyUserService, reactInterface){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.popupTitle 	= localizedMessages.get('');
	
	$scope.bvModalControllerInputs.events.forEach(function(item){
		
		if(item.objectBaseUuid == $scope.bvModalControllerInputs.selectedUuid){
			$scope.calEvent = item;
		}
		
	})
  
  if(!legacyUserService.isNasdaqUI)
	  $scope.popupTitle = $scope.calEvent.name;
  else
    $scope.popupTitle = localizedMessages.get('MEETING_DETAILS');

	$scope.getDisplayTime = function (calEvent)
	{
		if(calEvent!=undefined)
		{
			var startTime = moment(parseInt(calEvent.startDateGMTMillis)).format('LT');
			var endTime = moment(parseInt(calEvent.endDateGMTMillis)).format('LT');
			return startTime + " - " + endTime;
		}
	}
	
	var bvSliderService;
	var sliderRenderCallback = function(){
		bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptions.id]
		bvSliderService.currentIndex = 0;
	}
	
	$scope.sliderOptions = {
		id: 'MEETING_DETAILS',
		items: [],
		slideThrough: 1,
		shownItems: 2,
		renderCallback: sliderRenderCallback
	};

	$scope.getDocumentThumbnail = function(attachment) 
 	{
 		return imageService.getThumbnailForObject(attachment, "large");
	}
	
	$scope.UI_LAST_UPDATED_TXT = localizedMessages.get('LAST_UPDATED');
	$scope.UI_ATTENDEE_TXT     = localizedMessages.get('ATTENDEE');
	$scope.UI_OWNER_TXT        = localizedMessages.get('OWNER');
	$scope.UI_YOU_ARE_TXT  	   = localizedMessages.get('YOU_ARE');
	$scope.UI_DETAILS_TXT  	   = localizedMessages.get('DETAILS');
	$scope.UI_ATTENDEES_TXT    = localizedMessages.get('ATTENDEES');
	$scope.UI_JOIN_TXT = localizedMessages.get('JOIN');
	
	var eventDtTxt = $scope.UI_ATTENDEE_TXT;
	if($scope.calEvent.isEventOwner)
	{
		eventDtTxt = $scope.UI_OWNER_TXT;
	}
	$scope.EVENT_DT_UI_TXT = $scope.UI_YOU_ARE_TXT+": "+eventDtTxt;
	
	calendarService.loadGuestandResponseForEvent($scope.calEvent.objectBaseUuid).then(function(data)
	{
		if(data)
		{
			var item = data.data;
			var guests = data.data.guests;
			var eventResponseList = data.data.eventResponseList;

			bvSliderService.currentIndex = 0;

			for(var i in guests)
			{
				for(var k in eventResponseList)
				{
					if(guests[i].objectBaseUuid == eventResponseList[k].createdByBaseUuid)
					{
						guests[i].response = eventResponseList[k].response.toLowerCase();
					}
				 }
			}
			item.guests = guests;
			$scope.sliderOptions.items = item.documentVOList;
			$scope.noMeetingAttachments = item.documentVOList == null || item.documentVOList.length == 0 ? true : false;
			$scope.msg_no_meeting_attachments = localizedMessages.get('MSG_NO_MEETING_ATTACHMENTS');
			bvSliderService.fixScroll();
			bvSliderService.currentIndex = 0;

			try
			{
				var dtStr = item.lastModifiedOnGMT + " GMT";
				var lcDate = new Date(dtStr);
				var lastUpdatedVal = moment(lcDate).format('ll');//lcDate.toLocaleString();
				item.lastUpdatedUiVal = lastUpdatedVal;
			}
			catch(err)
			{
				item.lastUpdatedUiVal =  moment(item.lastModified).format('ll');
			}
			$scope.SELECTED_EVENT = item;
			
		}
	});
	
	$scope.openAttachmentFile = function (attachment) 
	{
		repositoryService.openPdfViewer(attachment);
	}
	
	$scope.showConfAttendee = function()
    {
    	if(!legacyUserService.isNasdaqUI)
            bvDialogMobile.showMeetingAttendees($scope.SELECTED_EVENT);
        else
            bvDialog.showMeetingDetailsPanel($scope.SELECTED_EVENT, false);
    }

    $scope.showConfDetails = function()
    {
        if(!legacyUserService.isNasdaqUI)
            bvDialogMobile.showMeetingDetails($scope.SELECTED_EVENT);
        else
            bvDialog.showMeetingDetailsPanel($scope.SELECTED_EVENT, true);
    }
    
    $scope.notJoinedAnyOtherConf = function() {
    	return Object.keys(presentationService.getConf()).length == 0;
    }
    
    $scope.joinConf = function(calEvent) {
    	bvModal.close();
    	presentationService.showRunningConfsListDialog(calEvent);
    }

    $scope.reactInterface = reactInterface;
    $scope.eventUuid = $scope.bvModalControllerInputs.selectedUuid

    $scope.closeDialog = function(){
      bvModal.close();
    }
}

angular
    .module('dialogControllers.service')
	.controller('meetingMobileDetailsController', meetingMobileDetailsController);
