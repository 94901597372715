function configBriefcaseController($stateProvider, $locationProvider){
	
}

function BriefcaseController($scope, $state, $stateParams, briefcaseService, localizedMessages){
	
}

angular
	.module('briefcase', ['briefcase.service', 'localization.service'])
	.config(configBriefcaseController)
	.controller('BriefcaseController', ['$scope', '$state', '$stateParams', 'briefcaseService', 'localizedMessages', BriefcaseController])