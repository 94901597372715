bvSortingPanelController.$inject = ['$scope', '$element', '$attrs', 'context'];

function bvSortingPanelController($scope, $element, $attrs, context){

	var sorting = function(data, key, direction, unsortFirstItem, checkbox){

		if(unsortFirstItem === true){
			var unsortetItem = data[0];
			data.splice(0, 1);
		}

		var sort = function(a,b)
		{
			var r;
			var p;

			if(checkbox){
    			r = a[key];
        		p = b[key];
    		}
			else{
				r = a[key].toLowerCase();
	    		p = b[key].toLowerCase();
			}

    		if(key === 'lastMod'){
    			r = new Date(a[key]).getTime();
        		p = new Date(b[key]).getTime();
    		}

    		if(p>r)
    			return -1;
    		else if(r>p)
    			return 1;
    		else
    			return 0;
		}
		if(direction){
			return unsortFirstItem === true ? data.sort(sort).splice(0, 0, unsortetItem) : data.sort(sort);
		}
		else{
			return unsortFirstItem === true ? data.sort(sort).reverse().splice(0, 0, unsortetItem) : data.sort(sort).reverse();
		}
	}

	$scope.direction = {}
	for(var i = 0 ; i < $scope.data.columns.length ; i++){
		$scope.direction[$scope.data.columns[i].name] = { sorted : false, active : false }
	}

	$scope.sortClick = function(column){
		if(column.sorting === true){
			for(var i in $scope.direction){
				$scope.direction[i].active = false;
			}
			$scope.direction[column.name].active = true;
			sorting($scope.data.data, column.name, $scope.direction[column.name].sorted, column.unsortFirstItem, column.type == 'checkbox' || column.type == 'radio');
			$scope.direction[column.name].sorted = !$scope.direction[column.name].sorted;

		}
	}

	$scope.checkedAll = function(item){
		for(var i = 0 ; i < $scope.data.data.listOfDocuments.length ; i++){
			$scope.data.data.listOfDocuments[i].checked = item.checked;
		}
	}
}