adSearchController.$inject = ['$scope', 'localizedMessages', 'adSearchService', 'contextMenuHandler', 'adSearchDialogService', 'toppanelService', 'advancedSearchService', 'contextMenuModel', 'treeService', '$timeout', 'repositoryService', '$state', 'context', 'homeService', 'adSearchQueryBoxService'];

function adSearchController($scope, localizedMessages, adSearchService, contextMenuHandler, adSearchDialogService, toppanelService, advancedSearchService, contextMenuModel, treeService, $timeout, repositoryService, $state, context, homeService, adSearchQueryBoxService){

    var pageLimitItems = 100;

    $scope.topPanelEnable = $scope.topPanel === undefined ? true : $scope.topPanel;
    $scope.title = {
        documents: localizedMessages.get('DOCUMENTS'),
        folders: localizedMessages.get('FOLDERS')
    }

    if(!$scope.searchObject.advanced){
        $scope.topSearchPanel = {
            templateUrl: 'modules/advancedsearch/adsearch/toppanel/toppanel.tpl.html',
            name: 'BASIC_SEARCH',
            events : [
                        { name: 'name',             value : localizedMessages.get('NAME_UPPERCASE')},
                        { name: 'type',             value : localizedMessages.get('TYPE_UPPERCASE')},
                        { name: 'teamspace',        value : localizedMessages.get('TEAMSPACE_UPPERCASE')},
                        { name: 'modified',         value : localizedMessages.get('MODIFIED_UPPERCASE')},
                    ]
        }
    }
    else{
        var nameSorting = function(nameSrt){
            var toppanel = toppanelService.getInstance('ADSEARCH');

            nameSrt === undefined ? nameSrt = true : nameSrt = !nameSrt;
            toppanel.addEvent('nameSrt', nameSrt);
            $scope.searchObject.items = StringUtils.sorting($scope.searchObject.items, 'name', nameSrt);

            toppanel.addEvent('typeSrt', undefined);
            toppanel.addEvent('teamspaceSrt', undefined);
            toppanel.addEvent('folderSrt', undefined);
            toppanel.addEvent('lastModifiedSrt', undefined);

            toppanel.addEvent('sorted', true);
        }

        var typeSorting = function(typeSrt){
            var toppanel = toppanelService.getInstance('ADSEARCH');

            typeSrt === undefined ? typeSrt = true : typeSrt = !typeSrt;
            toppanel.addEvent('typeSrt', typeSrt);
            $scope.searchObject.items = StringUtils.sorting($scope.searchObject.items, 'type', typeSrt);

            toppanel.addEvent('nameSrt', undefined);
            toppanel.addEvent('teamspaceSrt', undefined);
            toppanel.addEvent('folderSrt', undefined);
            toppanel.addEvent('lastModifiedSrt', undefined);

            toppanel.addEvent('sorted', true);
        }

        var teamspaceSorting = function(teamspaceSrt){
            var toppanel = toppanelService.getInstance('ADSEARCH');

            teamspaceSrt === undefined ? teamspaceSrt = true : teamspaceSrt = !teamspaceSrt;
            toppanel.addEvent('teamspaceSrt', teamspaceSrt);
            $scope.searchObject.items = StringUtils.sorting($scope.searchObject.items, 'portalName', teamspaceSrt);

            toppanel.addEvent('nameSrt', undefined);
            toppanel.addEvent('typeSrt', undefined);
            toppanel.addEvent('folderSrt', undefined);
            toppanel.addEvent('lastModifiedSrt', undefined);

            toppanel.addEvent('sorted', true);
        }

        var folderSorting = function(folderSrt){
            var toppanel = toppanelService.getInstance('ADSEARCH');

            folderSrt === undefined ? folderSrt = true : folderSrt = !folderSrt;
            toppanel.addEvent('folderSrt', folderSrt);
            $scope.searchObject.items = StringUtils.sorting($scope.searchObject.items, 'parentFolderName', folderSrt);

            toppanel.addEvent('typeSrt', undefined);
            toppanel.addEvent('teamspaceSrt', undefined);
            toppanel.addEvent('nameSrt', undefined);
            toppanel.addEvent('lastModifiedSrt', undefined);

            toppanel.addEvent('sorted', true);
        }

        var lastModifiedSorting = function(lastModifiedSrt){
            var toppanel = toppanelService.getInstance('ADSEARCH');

            lastModifiedSrt === undefined ? lastModifiedSrt = true : lastModifiedSrt = !lastModifiedSrt;
            toppanel.addEvent('lastModifiedSrt', lastModifiedSrt);
            $scope.searchObject.items = StringUtils.sorting($scope.searchObject.items, 'lastModifiedOnGMTMillis', lastModifiedSrt);

            toppanel.addEvent('typeSrt', undefined);
            toppanel.addEvent('teamspaceSrt', undefined);
            toppanel.addEvent('folderSrt', undefined);
            toppanel.addEvent('nameSrt', undefined);

            toppanel.addEvent('sorted', true);
        }

        var resortByRelevancy = function(){
            var toppanel = toppanelService.getInstance('ADSEARCH');

            adSearchService.recallServer();

            toppanel.addEvent('typeSrt', undefined);
            toppanel.addEvent('teamspaceSrt', undefined);
            toppanel.addEvent('nameSrt', undefined);
            toppanel.addEvent('folderSrt', undefined);
            toppanel.addEvent('lastModifiedSrt', undefined);

            toppanel.addEvent('sorted', false);
        }

        $scope.topSearchPanel = {
            templateUrl: 'modules/advancedsearch/adsearch/toppanel/toppanel.tpl.html',
            name: 'ADSEARCH',
            events : [
                        { name: 'name',             value : localizedMessages.get('NAME_UPPERCASE')},
                        { name: 'type',             value : localizedMessages.get('TYPE_UPPERCASE')},
                        { name: 'teamspace',        value : localizedMessages.get('TEAMSPACE_UPPERCASE')},
                        { name: 'modified',         value : localizedMessages.get('MODIFIED_UPPERCASE')},
                        { name: 'sortedByRelevancy',value : localizedMessages.get('SORTED_BY_RELEVANCY')},
                        { name: 'resortByRelevancy',value : localizedMessages.get('RESORT_BY_RELEVANCY')},
                        { name: 'folder',           value : localizedMessages.get('FOLDER_UPPERCASE')},
                        { name: 'sorted',           value : false },
                        { name: 'advanced',         value : $scope.searchObject.advanced },
                        { name: 'nameClk',          value : nameSorting },
                        { name: 'typeClk',          value : typeSorting },
                        { name: 'teamspaceClk',     value : teamspaceSorting },
                        { name: 'folderClk',        value : folderSorting },
                        { name: 'lastModifiedClk',  value : lastModifiedSorting },
                        { name: 'resortClk',        value : resortByRelevancy }
                    ]
        }
    }

    $scope.getLocalizedDate = function(gmt){
        return moment(gmt).format('lll');
    }

    $scope.openFile = function(document){
        if('close' in adSearchDialogService.dialog){
            adSearchDialogService.dialog.close();
        }

        adSearchQueryBoxService.getElement().blur();

        if(StringUtils.isFolder(document.objectBaseUuid)){
            if(context.getModel().portalVO.objectBaseUuid != document.portalBaseUuid){
                homeService.switchTeamSpace({ objectBaseUuid : document.portalBaseUuid }, function(){
                    $state.go('home.documents', { folderBaseUuid: document.objectBaseUuid, open: true }, { reload: true });
                });
            }
            else
                $state.go('home.documents', { folderBaseUuid: document.objectBaseUuid, open: true });
        }
        else
            contextMenuHandler.openFile(document);
    }

    $scope.$on('HOME_CONTROLLER_CLICK', function(event){
        if($scope.showRightClick)
            $scope.showRightClick = false;
    });

    $scope.menuCallback = function(type, param){
    	switch (type)
    	{
            case 'MENU_STYLE':
            	$scope.menuStyle = {'top': param[0].y + "px",'left': param[0].x + "px"};
                break;
            case 'MENU_ARROW_STYLE':
                $scope.menuLeftArrowStyle = {'top': param.y + "px", 'left': param.x + "px"};
                break;
            case 'OPEN_DOCUMENT':
                $scope.openFile(param[0]);
                break;
			default:
				break;
		}
    }

    $scope.handleRightClick = function (xPos, yPos, selectedContextItem, event)
    {
        repositoryService.getDocumentOrFolderParentObjectIds(selectedContextItem.objectBaseUuid).then(function(data){
            if(data){
                $scope.searchObject.setSearchFolder(data);
            }

            $scope.rightClickDispatcher(xPos, yPos, selectedContextItem, event);
        });
    }

    $scope.rightClickDispatcher = function (xPos, yPos, selectedContextItem, event)
    {
        $scope.showRightClick = false;

        contextMenuModel.xPos = xPos;
        contextMenuModel.yPos = yPos;
        contextMenuModel.event = event;
        contextMenuModel.currentInstance = "ADSEARCH";
        //contextMenuModel.parentFolder = 'ADVANCED_SEARCH';
        contextMenuModel.parentObject = "ADVANCED_SEARCH";
        contextMenuModel.selectedObject = selectedContextItem

        $timeout(function(){
            $scope.showRightClick = true;
        })
    };

    var offset = 0;
    var enableRequest = true;
    $scope.scrollBarsOption = {
        autoHideScrollbar : true,
        callbacks:{
            onScroll:function(){
                if(this.mcs.topPct == 100 && enableRequest && $scope.searchObject.lastDataCount >= 10){//pageLimitItems){
                    if('filterPanelInstance' in $scope.searchObject && 'advancedSearchData' in $scope.searchObject.filterPanelInstance){
                        $scope.searchObject.filterPanelInstance.advancedSearchData.offset++;
                        //offset = $scope.searchObject.filterPanelInstance.advancedSearchData.offset;
                    }
                    enableRequest = false;
                    adSearchService.getAdvancedSearchResult($scope.searchObject, $scope.searchObject.searchString).then(function(){
                        enableRequest = true;
                    });
                }
            }
        }
    }
}