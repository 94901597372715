function rawpadd(src)
{
	return src.toByteArray();
}
		
function rawpade(src)
{
	return src;
}

function asymmetricEncrypt(inputStr, serverPublicKey)
{ // for transport encryption
	
//	var encryptKey = new JSEncrypt();
//	encryptKey.setKey(serverPublicKey);
//	var encryptedValue = encryptKey.encrypt(inputStr);
	
	var rsa = new RSAKey();
	rsa.setPublic(serverPublicKey.n,serverPublicKey.e);
    var encryptedValue = rsa.encrypt(inputStr);
	return encryptedValue;
}

function decryptPreLogin(inputStr, clientPreLoginDecryptKeyStr) 
{ // for transport encryption

    var decrypter = new JSEncrypt();
    decrypter.setKey(clientPreLoginDecryptKeyStr);
    var decrypted = decrypter.decrypt(atob(inputStr));
    
//    var rawData = atob(inputStr);
//    var iv = btoa(rawData.substring(0,16));
//    var crypttext = btoa(rawData.substring(16));
//    var decrypted = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.parse(crypttext),  CryptoJS.enc.Hex.parse(clientPreLoginDecryptKeyStr),CryptoJS.enc.Base64.parse(iv));
    
	return String(decrypted);
}

function generateRandomString(newLengthNumber)
{
	var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	var alphabet = a.split("");
	var randomString = "";
	for (var i = 0; i < newLengthNumber; i++){
		randomString += alphabet[Math.floor(Math.random() * alphabet.length)];
	}
	return randomString;
}

function hexEncode(str){
    var hex, i;

    var result = "";
    for (i=0; i<str.length; i++) {
        hex = str.charCodeAt(i).toString(16);
        result += ("000"+hex).slice(-4);
    }

    return result
}

var CryptoHelper = {
    asymmetricEncrypt: asymmetricEncrypt,
    generateRandomString: generateRandomString
}
