angular
    .module('dialogControllers.service')
	.controller('surveyAdminPreviewController', surveyAdminPreviewController);

surveyAdminPreviewController.$inject =  [ '$scope', '$filter', '$state', 'localizedMessages', 'commonService','context', 'homeService','bvDialog', 'bvDialogService','teamService', 'surveysService','surveysAlertService','$sce'];


function surveyAdminPreviewController($scope,$filter, $state, localizedMessages, commonService, context, homeService,bvDialog, bvDialogService, teamService, surveysService, surveysAlertService, $sce){
	$scope.popupTitle = localizedMessages.get('STATUS');
	$scope.ui = {
	        label : {
	        	anonymous : localizedMessages.get('ANONYMOUS'),
	        	headingUsers : localizedMessages.get('USERS'),
	        	respond : localizedMessages.get('RESPOND'),
	        	survey_description : localizedMessages.get('DESCRIPTION'),
	        	named_responses : localizedMessages.get('NAMED_RESPONSES'),
	        	due : localizedMessages.get('DUE'),
	        	date: localizedMessages.get('DATE'),
	        	response_type : localizedMessages.get('RESPONSE_TYPE'),
	        	are_you_sure_you_want_to_move_survey_to_closed_category : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_MOVE_SURVEY_TO_CLOSED_CATEGORY'),
	        	are_you_sure_you_want_to_remove_survey_from_closed_category : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SURVEY_FROM_CLOSED_CATEGORY'),
	        	close_survey : localizedMessages.get('CLOSE_SURVEY'),
	        	remove_from_close_survey : localizedMessages.get('REMOVE_FROM_CLOSED_CATEGORY'),
	        	participants :localizedMessages.get('PARTICIPANTS'),
	        	add:localizedMessages.get('ADD'),
	        	move_survey_to_closed_category:localizedMessages.get('MOVE_SURVEY_TO_CLOSED_CATEGORY'),
	        	name:localizedMessages.get('NAME'),
	        	status:localizedMessages.get('STATUS'),
	        	progress:localizedMessages.get('PROGRESS'),
	        	remove_participant : localizedMessages.get('REMOVE_PARTICIPANT'),
	        	lock_user : localizedMessages.get('LOCK_USER'),
	        	unlock_user : localizedMessages.get('UNLOCK_USER'),
	        	lock_all:localizedMessages.get('LOCK_ALL'),
	        	are_you_sure_you_want_to_remove_participant : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_PARTICIPANT'),
	        	are_you_sure_you_want_to_lock_user : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_LOCK_USER'),
	        	are_you_sure_you_want_to_unlock_user : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_UNLOCK_USER'),
	        	cancel : localizedMessages.get('CANCEL'),
	        	sendAlert : localizedMessages.get('SEND_ALERT'),
	        	resume : localizedMessages.get('RESUME'),
	        	dueDate : localizedMessages.get('DUE_DATE')
	        	}
	};
	$scope.scrollbarConfig = {
			autoHideScrollbar : true
		}
	$scope.uiTextMapping = {
			notResponded:localizedMessages.get('NOT_RESPONDED'),
			responded : localizedMessages.get('RESPONDED'),
			inProgress : localizedMessages.get('IN_PROGRESS')
	}
	var bvModal;
	var survey;
	bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';
	$scope.selectedContextItem = $scope.bvModalControllerInputs.selectedContextItem;
	$scope.myOpenSurveysCallback = $scope.bvModalControllerInputs.getMyOpenSurveys;
	$scope.myClosedSurveysCallback = $scope.bvModalControllerInputs.getMyClosedSurveys;
	$scope.myOwnedSurveysCallback = $scope.bvModalControllerInputs.getMyOwnedSurveys;
	$scope.activeTab = $scope.bvModalControllerInputs.activeTab;
	$scope.currentPage = $scope.bvModalControllerInputs.currentPage;
	$scope.isSurveyClosed = $scope.selectedContextItem.isClosed;
	$scope.description = $scope.selectedContextItem.description != undefined? $scope.selectedContextItem.description : '';
	$scope.participantsTableData = $scope.selectedContextItem.participantBaseUuidAndResponseList;
	$scope.showClearDueDateButton = $scope.selectedContextItem.dueDateVO == null ? false : true;
	
	function setFlagLockAll () {
	  var allParticipantsLocked = true;
		for (var i = 0; i < $scope.participantsTableData.length; i++) {
			if(!$scope.participantsTableData[i].isLocked){
            	allParticipantsLocked = false;
            	break;
            }
        }
	  $scope.flagLockAll = allParticipantsLocked;
    }
   
	setFlagLockAll();
	
	$scope.getMyOpenSurveys = function(){
		surveysService.getMySurveyListJSON().then(function(data){
			sessionStorage.removeItem('surveyBaseUuid');
			$scope.allSurveysList = data;
			if($scope.allSurveysList != undefined){
				//Sort and group by portalBaseUuid to display surveys grouped by portals;
				surveysService.sortOn($scope.allSurveysList,'portalBaseUuid');
				$scope.groups = surveysService.groupBy( 'portalBaseUuid', 'portalName', $scope.allSurveysList );
			}
		});
	}
	$scope.close = function(){
		bvModal.close();
	}
	$scope.alertSurvey = function() {
		surveysAlertService.survey = $scope.selectedContextItem;
		surveysAlertService.openAlertDialog();
	}
	$scope.respondFromStatus = function(){
		surveysService.getSurveyDetailsJSON($scope.selectedContextItem.objectBaseUuid).then(function(data) {
			surveysService.setSurvey(data);
		    survey = surveysService.getSurvey();
		    bvDialogService.closeAllOpenDialogs();
			$state.go('home.openSurvey', { surveyBaseUuid: $scope.selectedContextItem.objectBaseUuid });
			});
	}
	var currentUserVO = context.getModel().userVO;
	$scope.currentUserStatus;
	$scope.isCurrentUserRespondent = false;
	$scope.isCurrentUserLocked = false;
	for(var i=0;i<$scope.selectedContextItem.participantBaseUuidAndResponseList.length;i++){
		if($scope.selectedContextItem.participantBaseUuidAndResponseList[i].objectBaseUuid == currentUserVO.objectBaseUuid){
			$scope.currentUserStatus = $scope.selectedContextItem.participantBaseUuidAndResponseList[i].value;
			$scope.isCurrentUserRespondent = true;
			$scope.isCurrentUserLocked = $scope.selectedContextItem.participantBaseUuidAndResponseList[i].isLocked;
			break;
		}
	}
	$scope.getMyClosedSurveys = function() {
		surveysService.getMyClosedSurveyListJSON().then(function(data){
			$scope.closedSurveys = data;
		});
	}
	$scope.getMyOwnedSurveys = function() {
		$scope.noOwnedByMeSurveys = false;
		surveysService.getMyOwnedSurveyListJSON().then(function(data){
			$scope.ownedByMe = data;
		});
	}
    $scope.getShortLocalizedDate = function(date) { 	
   		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
	}
    $scope.getLocalizedDate = function(date) {
   		return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
	}

    $scope.dueDateModel = {
		startingDate: $scope.selectedContextItem.dueDateVO == null ? null : $scope.getShortLocalizedDate($scope.selectedContextItem.dueDateVO.gmtMilliSeconds),
		startingDateLocal: $scope.selectedContextItem.dueDateVO == null ? null : StringUtils.momentL($scope.selectedContextItem.dueDateVO.gmtMilliSeconds).format('ll')
    }

	$scope.previousDueDateMS = $scope.selectedContextItem.dueDateVO == null ? null : $scope.selectedContextItem.dueDateVO.gmtMilliSeconds;

	$scope.updatePreviousDueDateMS = function () {
		if ($scope.dueDateModel.startingDate != null) {
			$scope.previousDueDateMS = moment($scope.dueDateModel.startingDate, $scope.dueDateModel.instance.format).valueOf();
		}
		else {
			$scope.previousDueDateMS = null;
		}
	}

    $scope.confirmPopup = function(){
    	bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SURVEY_DUE_DATE") + "?", $scope.proceedChangeDate1, $scope.cancelChangeDate1);
    }
    
	$scope.updateSurveyList = function () {
		if ($scope.activeTab != null) {
			if ($scope.activeTab == 0) {
				$scope.myOpenSurveysCallback('open', $scope.currentPage);
			}
			else if ($scope.activeTab == 1) {
				$scope.myClosedSurveysCallback('closed', $scope.currentPage);
			}
			else {
				$scope.myOwnedSurveysCallback('owned', $scope.currentPage);
			}
		}
		else {
			$scope.myOpenSurveysCallback();
			$scope.myClosedSurveysCallback();
			$scope.myOwnedSurveysCallback();
		}
	}

	$scope.clearDueDate = function () {
		bvDialog.confirm(
			localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SURVEY_DUE_DATE") + "?", 
			function () {
				surveysService.changeSurveyDueDateJSON($scope.selectedContextItem.objectBaseUuid, "-").then(function (data) {
					if (data.boName == "SuccessBO") {
						$scope.dueDateModel.startingDate = null;
						$scope.dueDateModel.startingDateLocal = null;
						$scope.dueDateModel.dateToShow = '';

						$scope.updatePreviousDueDateMS();

						$scope.showClearDueDateButton = false;

						$scope.updateSurveyList();
					}
					else {
						$scope.cancelChangeDate1();
					}
				});
			}, 
			$scope.cancelChangeDate1);
		
	}

    $scope.proceedChangeDate1 = function(){
		surveysService.changeSurveyDueDateJSON($scope.selectedContextItem.objectBaseUuid, StringUtils.momentL($scope.dueDateModel.startingDateLocal).format("ll")).then(function(data){
			if(data.boName == "SuccessBO"){
				$scope.showClearDueDateButton = true;
				$scope.updatePreviousDueDateMS();
				$scope.updateSurveyList();
			}
			else{
				$scope.cancelChangeDate1();
			}
		});
	};
	var languageFromLocalStore = localStorage.getItem('USER_LANG_PREF');
    var languageWithoutRegionCode = languageFromLocalStore.toLowerCase().split(/[_-]+/)[0];
    
	$scope.cancelChangeDate1 = function() {
		if ($scope.previousDueDateMS != null) {
			$scope.dueDateModel.dateToShow = moment($scope.previousDueDateMS).format($scope.dueDateModel.instance.format)
		}
		else {
			$scope.dueDateModel.dateToShow = '';
		}
	};
    
//	$scope.showDueDateCalender = function() {
//		var dateFormat = StringUtils.getLocaleDateFormat();
//		$("#datepickerDueDateForAdmin").datepicker({
//			showPanelButton : true,
//			beforeShow : function() {
//				$('.ui-datepicker').css('font-size', 11);
//			},
//			showAnim : 'slideDown',
//			dateFormat : dateFormat,
//			minDate : 0,
//            onSelect: function (dateText) {
//            	$scope.proceedChangeDate = function(){
//            		surveysService.changeSurveyDueDateJSON($scope.selectedContextItem.objectBaseUuid,dateText).then(function(data){
//            			if(data.boName == "SuccessBO"){
//            				$scope.dueDateModel = dateText;
//            			}
//            		});
//            		};
//            	$scope.cancelChangeDate = function(){
//            		$("#datepickerDueDateForAdmin").val($scope.dueDateModel);
//            	};
//            	bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SURVEY_DUE_DATE") + "?", $scope.proceedChangeDate, $scope.cancelChangeDate);	
//            }
//		});
//		$("#datepickerDueDateForAdmin").show();
//		$("#datepickerDueDateForAdmin").focus();
//		$("#datepickerDueDateForAdmin").datepicker();
//	};
	$scope.toggleMoveToClose = function(){
		$scope.isSurveyClosed = !$scope.isSurveyClosed;
    	$scope.proceed = function(){
			surveysService.closeOrReopenSurveyJSON($scope.selectedContextItem.objectBaseUuid, !$scope.selectedContextItem.isClosed).then(function(data){
				if(data.boName=="SuccessBO"){
					$scope.selectedContextItem.isClosed = !$scope.selectedContextItem.isClosed;
	    				$scope.getMyOpenSurveys();
		    			$scope.getMyClosedSurveys();
		    			$scope.getMyOwnedSurveys();
		    			$scope.myOpenSurveysCallback();
		    			$scope.myClosedSurveysCallback();
		    			$scope.myOwnedSurveysCallback();
	    			bvDialogService.closeAllOpenDialogs();
				 }	
			});
    	}
    	$scope.cancel = function(){
    		if($scope.isSurveyClosed == true || $scope.isSurveyClosed == "true"){
    			$scope.isSurveyClosed = !$scope.isSurveyClosed;
    		}
    		else{
    			$scope.isSurveyClosed = !$scope.isSurveyClosed;
    		}
    	}
		if(!$scope.selectedContextItem.isClosed){
			bvDialog.confirm($scope.ui.label.close_survey, $scope.proceed, $scope.cancel);
		}else{
			bvDialog.confirm($scope.ui.label.remove_from_close_survey,$scope.proceed, $scope.cancel);
		}
	};
	$scope.removeParticipant = function(index,objectBaseUuid, name){
		$scope.toDeleteParticipantIndex = index;
		var surveyBaseUuid = surveysService.getSurvey().objectBaseUuid;
    	$scope.proceed = function(){
			surveysService.deleteSurveyParticipantJSON(surveyBaseUuid, objectBaseUuid).then(function(data){
				if(data.boName == "SuccessBO"){
					var index = $scope.toDeleteParticipantIndex;
                    $scope.participantsTableData.splice(index, 1);
                    if(objectBaseUuid == currentUserVO.objectBaseUuid)
                    	$scope.isCurrentUserRespondent = false;
				}
			});
		};
		bvDialog.confirm($scope.ui.label.remove_participant + " " + name + "?", $scope.proceed);
	};
	$scope.addParticipants = function(){
		$scope.selectUsersLbl = localizedMessages.get("ADD_PARTICIPANTS");
		$scope.multiSelect = true;
		$scope.participantsToAddCopy =  angular.copy($scope.participantsTableData);
		$scope.userObjeUuids = [];
		for(var i=0;i<$scope.participantsToAddCopy.length;i++){
			$scope.userObjeUuids.push($scope.participantsToAddCopy[i].objectBaseUuid);
		}
		$scope.onUserSelection = function(selectedUsers) {
			var userBaseUuids = [];
			if(selectedUsers != false)
			{
				for(var i in selectedUsers)
				{
					if($scope.userObjeUuids.indexOf(selectedUsers[i].objectBaseUuid) == -1){
						var newParticipant = {};
						newParticipant['objectBaseUuid'] = selectedUsers[i].objectBaseUuid;
						newParticipant['name'] = selectedUsers[i].name;
						newParticipant['isLocked'] = false;
						newParticipant['isSigned'] = false;
						newParticipant['respondedStatus'] = "0.0";
						newParticipant['value'] = 'notResponded';
						$scope.participantsToAddCopy.push(newParticipant);
						userBaseUuids.push(selectedUsers[i].objectBaseUuid);
					}
				}

				surveysService.addSurveyParticipantsJSON($scope.selectedContextItem.objectBaseUuid, angular.toJson(userBaseUuids)).then(function(data){
					if(data.boName=="SuccessBO"){
	                    if(userBaseUuids.indexOf(currentUserVO.objectBaseUuid) != -1)
	                    	$scope.isCurrentUserRespondent = true;
	                    $scope.participantsTableData = angular.copy($scope.participantsToAddCopy);
	                    $scope.selectedContextItem.participantBaseUuidAndResponseList = $scope.participantsTableData;
					}
				});
			}
		}
		$scope.existingCompanyUsers = [];
		$scope.getCompanyUsersJSON = function()
		{
			if($scope.existingCompanyUsers.length!=0) return;
			teamService.getCompanyUsersJSON()
			.then(function(response)
			{
				$scope.existingCompanyUsers = response;
				bvDialog.inviteUserSelectionSurvey($scope.selectUsersLbl, $scope.existingCompanyUsers, $scope.onUserSelection,$scope.multiSelect, true);
			});
		}
		$scope.getCompanyUsersJSON();
	};
	
    $scope.toggleCheckerAll = function(checked) {
    	$scope.cancelLockingUnlockingAllUsers = function(){
	    	var scope = angular.element($("#lockAllParticipants")).scope();
	        scope.lockAllParticipants = !checked;
			for (var i = 0; i < $scope.participantsTableData.length; i++) {
	            $scope.participantsTableData[i].isLocked = !checked;
	        }
	    };
		var uuidList = [];
    	for (var i = 0; i < $scope.participantsTableData.length; i++) {
            $scope.participantsTableData[i].isLocked = checked;
            uuidList.push($scope.participantsTableData[i].objectBaseUuid);
        }
    	if(checked){
    		bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_LOCK_ALL_USERS"), function(){
    	    	surveysService.lockParticipantsJSON($scope.selectedContextItem.objectBaseUuid, uuidList);
    	    }, $scope.cancelLockingUnlockingAllUsers);
    	}
    	else{
    		bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_UNLOCK_ALL_USERS"), function(){
    	    	surveysService.unLockParticipantsJSON($scope.selectedContextItem.objectBaseUuid, uuidList);
    	    }, $scope.cancelLockingUnlockingAllUsers);
    	}
    };

		$scope.lockAll=function()
		{
			bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_LOCK_ALL_USERS"), $scope.lockAllParticipants, $scope.cancel);
		}
		$scope.unlockAll=function()
		{
			bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_UNLOCK_ALL_USERS"), $scope.unlockAllPartipants, $scope.cancel);
		}
		
	$scope.lockAllParticipants=function()
	{
		$scope.flagLockAll=true;
		var particpantsList=[];
		
		for(var i=0;i<$scope.selectedContextItem.participantBaseUuidAndResponseList.length;i++)
		{
			
			particpantsList.push($scope.selectedContextItem.participantBaseUuidAndResponseList[i].objectBaseUuid);
		}
		$scope.participantsTableData = $scope.selectedContextItem.participantBaseUuidAndResponseList;
		surveysService.lockParticipantsJSON($scope.selectedContextItem.objectBaseUuid, angular.toJson(particpantsList)).then(function(data){
			if(data.boName=="SuccessBO")
			{
				if(particpantsList.indexOf(currentUserVO.objectBaseUuid) != -1)
					$scope.isCurrentUserLocked = true;
				for(var i=0;i<$scope.selectedContextItem.participantBaseUuidAndResponseList.length;i++)
				{
					$scope.selectedContextItem.participantBaseUuidAndResponseList[i].isLocked = true;
					
				}
			}
		});
	}
	$scope.unlockAllPartipants=function()
	{
		$scope.flagLockAll=false;
		var particpantsList=[];
		
		for(var i=0;i<$scope.selectedContextItem.participantBaseUuidAndResponseList.length;i++)
		{
			
			particpantsList.push($scope.selectedContextItem.participantBaseUuidAndResponseList[i].objectBaseUuid);
		}
		$scope.participantsTableData = $scope.selectedContextItem.participantBaseUuidAndResponseList;
		surveysService.unLockParticipantsJSON($scope.selectedContextItem.objectBaseUuid, angular.toJson(particpantsList)).then(function(data){
			
			if(data.boName=="SuccessBO"){
				if(particpantsList.indexOf(currentUserVO.objectBaseUuid) != -1)
					$scope.isCurrentUserLocked = false;
	
				for(var i=0;i<$scope.selectedContextItem.participantBaseUuidAndResponseList.length;i++)
				{
					$scope.selectedContextItem.participantBaseUuidAndResponseList[i].isLocked = false;
					
				}
				
			}
		});
	}
		
	$scope.lockOrUnLockParticipant = function(participant){
		console.log(participant);
		$scope.selectedContextItem.objectBaseUuid;
		
		$scope.cancel = function(){
//			participant.isLocked = !participant.isLocked;
		}
		if(!participant.isLocked){
			$scope.proceed = function(){
				var list=[];
				console.log($scope.selectedContextItem);
				console.log(context.getModel().userVO);
				list.push(participant.objectBaseUuid);
				surveysService.lockParticipantsJSON($scope.selectedContextItem.objectBaseUuid, angular.toJson(list)).then(function(data){
					if(data.boName=="SuccessBO"){
						if(list.indexOf(currentUserVO.objectBaseUuid) != -1)
							$scope.isCurrentUserLocked = true;
						for(var i=0;i<$scope.selectedContextItem.participantBaseUuidAndResponseList.length;i++){
							if($scope.selectedContextItem.participantBaseUuidAndResponseList[i].objectBaseUuid == participant.objectBaseUuid){
								$scope.selectedContextItem.participantBaseUuidAndResponseList[i].isLocked = true;
								break;
							}
						}
						$scope.participantsTableData = $scope.selectedContextItem.participantBaseUuidAndResponseList;
						setFlagLockAll();
					}
				});
			}
			bvDialog.confirm($scope.ui.label.lock_user, $scope.proceed, $scope.cancel);
		}else if(participant.isLocked){
				$scope.proceed = function(){
					var list=[];
					list.push(participant.objectBaseUuid);
				surveysService.unLockParticipantsJSON($scope.selectedContextItem.objectBaseUuid, angular.toJson(list)).then(function(data){
					if(data.boName=="SuccessBO"){
						if(list.indexOf(currentUserVO.objectBaseUuid) != -1)
							$scope.isCurrentUserLocked = false;
						for(var i=0;i<$scope.selectedContextItem.participantBaseUuidAndResponseList.length;i++){
							if($scope.selectedContextItem.participantBaseUuidAndResponseList[i].objectBaseUuid == participant.objectBaseUuid){
								$scope.selectedContextItem.participantBaseUuidAndResponseList[i].isLocked = false;
								break;
							}
						}
						$scope.participantsTableData = $scope.selectedContextItem.participantBaseUuidAndResponseList;
						setFlagLockAll();
					}
				});
			}
				bvDialog.confirm($scope.ui.label.unlock_user, $scope.proceed, $scope.cancel);
		}
	};
	$scope.openUserPreview = function(objBaseUuid,userName){
			if(($scope.selectedContextItem.isAnonymous == true || $scope.selectedContextItem.isAnonymous == 'true') && objBaseUuid != context.getModel().userVO.objectBaseUuid){
				return;
			}
			$scope.selectedContextItem.attachments = $scope.selectedContextItem.attachments || $scope.selectedContextItem.attachedObjectsList;
			$scope.description = $scope.selectedContextItem.description;
			surveysService.setQuestionBaseUuidList($scope.selectedContextItem);
			surveysService.getSurveyResponseForUserJSON($scope.selectedContextItem.objectBaseUuid, angular.toJson(surveysService.getQuestionBaseUuidList()), objBaseUuid).then(function(data){
				$scope.userResponses={};
				$scope.sectionResponseCount = {};
				surveysService.setAllQuestionsList(surveysService.getSurvey());
				var allSurveyQuestionsList = surveysService.getAllQuestionsList();
				if(data.surveyQuestionResponseList && data.surveyQuestionResponseList.length > 0){
					 for(var i=0;i<data.surveyQuestionResponseList.length;i++){
						 //$scope.qType = '';
						 for(var j=0;j<allSurveyQuestionsList.length;j++){
							 if(data.surveyQuestionResponseList[i].questionBaseUuid == allSurveyQuestionsList[j].objectBaseUuid){
								 data.surveyQuestionResponseList[i].questionType = allSurveyQuestionsList[j].questionType;
								 break;
							 }
						 }
						 if(data.surveyQuestionResponseList[i].questionType == 'likertscale'){
						  $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = allSurveyQuestionsList[j].likertMapping[data.surveyQuestionResponseList[i].response];
             }
             else if(data.surveyQuestionResponseList[i].questionType == 'multiyesno'){

              function stringToBoolean(val){
                switch (val) {
                  case 'true':
                    return true;
                  case 'false':
                    return false;
                  case 'null':
                    return null;
                }
              }

              $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = allSurveyQuestionsList[i].multipleYesNoChoiceValues.map(response => {
                let val = null

                try{
                  val = stringToBoolean(data.surveyQuestionResponseList[i].responseList.find(item => item.split('##')[0] == response.name).split('##')[1])
                }
                catch(e){}

                return {
                  name: response.name,
                  response: val != null ? val : ''
                }
              })
             }
             else if(data.surveyQuestionResponseList[i].questionType == 'tabularQuestion'){
              $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = allSurveyQuestionsList[i].tabularQuestionChoiceValues.map(response => {
                let val = null

                try{
                  Object.values(data.surveyQuestionResponseList[i].responseMap).forEach(row => {
                    val = row.find(item => item.split('##')[0] == response.name).split('##')[1]
                  })
                }
                catch(e){}

                return {
                  name: response.name,
                  response: val ? val : ''
                }
              })
             }
						 else if(data.surveyQuestionResponseList[i].questionType == 'yesno' || data.surveyQuestionResponseList[i].questionType == 'meetsornot' || data.surveyQuestionResponseList[i].questionType == 'truefalse'){
							 var locResponse = data.surveyQuestionResponseList[i].response;
							 if(locResponse == 'does not meet' || locResponse == 'do not meet'){
								 locResponse = localizedMessages.get('DOES_NOT_MEET');
                   }
               else if(locResponse == 'meet' || locResponse == 'meets'){
                locResponse = localizedMessages.get('MEETS');
               }
               else{
								 locResponse = localizedMessages.get(locResponse.toUpperCase());
							 }
							 $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = locResponse;
						 }
						 else{
							 $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = data.surveyQuestionResponseList[i].response;
						 }
					 }
					}
					 var canEditResponse  = false;
					 if(objBaseUuid == context.getModel().userVO.objectBaseUuid){
						 canEditResponse = true;
					 } 
						//Setting questions respond count, sequential response count and attachments
						for(var i=0;i<$scope.selectedContextItem.sections.length;i++){
							$scope.selectedContextItem.sections[i].attachments = $scope.selectedContextItem.sections[i].attachments || $scope.selectedContextItem.sections[i].attachedObjectsList;
							var count = 0;
							var secCount = 0;
							for(var j=0; j<$scope.selectedContextItem.sections[i].questions.length; j++){
								$scope.selectedContextItem.sections[i].questionsResponded = count;
								$scope.selectedContextItem.sections[i].questionsCount = $scope.selectedContextItem.sections[i].questions.length;
								$scope.selectedContextItem.sections[i].questions[j].attachments = $scope.selectedContextItem.sections[i].questions[j].attachments || $scope.selectedContextItem.sections[i].questions[j].attachedObjectsList;
								$scope.selectedContextItem.sections[i].questions[j].sequentialSecCount = secCount + 1;
								//&& $scope.userResponses[$scope.selectedContextItem.sections[i].questions[j].objectBaseUuid] != ''
								if($scope.userResponses[$scope.selectedContextItem.sections[i].questions[j].objectBaseUuid] != undefined && $scope.userResponses[$scope.selectedContextItem.sections[i].questions[j].objectBaseUuid] != ''){
									$scope.selectedContextItem.sections[i].questionsResponded = ++count;
								}
							}
						}
					bvDialog.userAnswersPreview($scope.userResponses, $scope.selectedContextItem, canEditResponse && !$scope.isCurrentUserLocked, objBaseUuid,userName);
			});
		}
	}