angular
    .module('dialogControllers.service')
    .controller('rulesController', rulesController);

rulesController.$inject = ['homeService', '$scope', 'localizedMessages', 'bvDialogService', 'commonService', 'imageService', 'repositoryService', 'bvDialog'];

function rulesController(homeService, $scope, localizedMessages, bvDialogService, commonService, imageService, repositoryService, bvDialog)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.SELECTED_OBJ = $scope.bvModalControllerInputs.selectedObject;
   	$scope.popupTitle = localizedMessages.get("RULES_FOR") + ": " + $scope.SELECTED_OBJ.name; //  Rules for: {{businessObject.name | limitTo: 25}}
   	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';

	$scope.ui =
    {
         label :
         {
        	 OK : localizedMessages.get('OK'),
        	 CANCEL : localizedMessages.get('CANCEL'),
        	 DAYS : localizedMessages.get('DAYS'),
        	 DELETE_AFTER : localizedMessages.get('DELETE_AFTER'),
        	 SET_RULES_MSG : localizedMessages.get('SET_RULES_MSG'),
         }
    };

	$scope.saveRulesChange = function()
    {

    	if($scope.documentRules.deleteDocument
    		&& ($scope.documentRules.deleteDocumentDays == undefined || $scope.documentRules.deleteDocumentDays == "")) {
    			bvDialog.showMessage(localizedMessages.get("MSG_DAYS_NOT_ENTERED"));
    			return;
    	}
    	
    	if($scope.documentRules.documentRetentionDays !== '' && ($scope.documentRules.documentRetentionDays < $scope.documentRules.deleteDocumentDays)) {
        			bvDialog.showMessage(localizedMessages.get("MSG_DAYS_GREATER_THAN_RENTENTION") + "(" + $scope.documentRules.documentRetentionDays + " days).");
        			return;
        }
    

    	if($scope.documentRules.deleteDocumentDays != undefined)
    	{
    		if($scope.documentRules.deleteDocument && isNaN($scope.documentRules.deleteDocumentDays))
    		{
    			bvDialog.showMessage(localizedMessages.get('MSG_INPUT_CONTAINS_INVALID_CHARS'));
	    		$scope.documentRules.deleteDocument = false;
    			$scope.documentRules.deleteDocumentDays = '';
    			return;
    		}

    		if(($scope.documentRules.deleteDocumentDays != undefined  && $scope.documentRules.deleteDocumentDays != "")
    		&& $scope.documentRules.deleteDocumentDays <= 0 ) {
    			bvDialog.showMessage(localizedMessages.get('INVALID_DATA_FOUND'));
    			return;
    		}

    		var alertNewVersionUsersUuids = [];
	    	var alertSecurityChangeUsersUuids = [];
	    	var alertNewVersionUsers = angular.toJson(alertNewVersionUsersUuids);
	    	var alertSecurityChangeUsers = angular.toJson(alertSecurityChangeUsersUuids);
	    	repositoryService.createDocumentRules($scope.SELECTED_OBJ.objectBaseUuid, alertNewVersionUsers, alertSecurityChangeUsers,
	    											$scope.documentRules.isFolder, false,
	    											$scope.documentRules.false, $scope.documentRules.deleteDocument,
	    											$scope.documentRules.deleteDocumentDays).then(function(data)
	    											{
	    												$scope.close();
	    											});
    	}
    	else
    	{
    		$scope.close();
    	}

    }

    $scope.selectDedeleteDocSelect = function() {
    	$scope.documentRules.deleteDocument = !$scope.documentRules.deleteDocument;
    }

    $scope.cancelRulesDialog = function()
    {
    	bvModal.close();
    }

    $scope.close = function()
	{
		$scope.cancelRulesDialog();
	}

    var initRules = function()
    {
    	repositoryService.loadRulesForDocumentOrFolder($scope.SELECTED_OBJ.objectBaseUuid).then(function(data)
    	{
    		$scope.documentRules = data.data;
    		console.log("INITIAL_DOC_RULES:",data.data);
    		// if($scope.documentRules.documentAlertNewVersionUsers)
    		// {
    		// 	documentAlertNewVersionUsers = $scope.documentRules.documentAlertNewVersionUsers;
    		// }
    		// if($scope.documentRules.documentAlertSecurityChangeUsers)
    		// {
    		// 	documentAlertSecurityChangeUsers = $scope.documentRules.documentAlertSecurityChangeUsers;
    		// }
    	});
    }

    initRules();
}
