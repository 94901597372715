angular
    .module('dialogControllers.service')
	.controller('searchResultsMobileController', searchResultsMobileController);

searchResultsMobileController.$inject = ['$scope', 'localizedMessages', 'homeService', 'homeContext', 'backUtiltiyService', 'repositoryModelService', 'repositoryService', 'bvDialog'];

function searchResultsMobileController($scope, localizedMessages, homeService, homeContext, backUtiltiyService, repositoryModelService, repositoryService, bvDialog){
	
	$scope.currentInstance = "SEARCH";
	$scope.emptyMsg=localizedMessages.get("SEARCH_RESULTS_EMPTY");
	$scope.searchResultForTS = localizedMessages.get("SEARCH_RESULTS_FOR_TS");
	$scope.searchStringIn = {}
	
	$scope.isMobileClient = MobileUtil.isMobileClient();
  $scope.searchFieldOffline = function() {
    if(MobileUtil.isMobileClient() && !homeContext.onlineStatus.val){
      bvDialog.showMessage(localizedMessages.get('MSG_THIS_FEATURE_IS_ONLY_AVAILABLE_ONLINE'));
    }
  }
    
  $scope.showList = false;
  $scope.searchStringIn.val = '';
	$scope.$watch('searchStringIn.val', function(val){
		if(val){
      $scope.showList = false;
			homeService.getAllSearchList(val).then(function(data){
        if(data && data.length > 0){
          var list = []
          data.forEach(function(item){
            list.push(item.searchItem);
          })

          homeContext.searchData = list;
          $scope.showList = val ? true : false;
        }
        else{
          $scope.showList = false;
        }
			})
		}
		else{
      $scope.showList = false;
    }
  })
  
	$scope.localizedMessages = localizedMessages;
	$scope.showTree = false;

    var backUtiltiy  = backUtiltiyService.getInstance("SEARCH");
    var repositoryModel = repositoryModelService.getInstance("SEARCH");

    $scope.getDocumentsForParentBaseUuid = function (baseUuid)
    {
        repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
        {
            repositoryModel.repositoryList(data.data);
        });
    };

    $scope.middleBarObject = backUtiltiy;
    $scope.middleBarObject.showBack = false;

    $scope.backBtnAction = function()
    {
        var folderObject = backUtiltiy.popBackStack();

        if(folderObject)
        {
            $scope.getDocumentsForParentBaseUuid(folderObject.objectBaseUuid);
        }
    }
}