angular
	.module('team.service',['ajax.service'])
	.service('teamService', teamService);

teamService.$inject = ['$q', 'ajaxService', 'responseValidatorService'];

function teamService($q, ajaxService, responseValidatorService) {

	var service = {
		getTeamSpaceUsersJSON: getTeamSpaceUsersJSON,
		getGroupsAndTeamspaces : getGroupsAndTeamspaces,
		getUserProfileConfigurationsJSON:getUserProfileConfigurationsJSON,
		inviteUser: inviteUser,
		getUserProfileImage: getUserProfileImage,
		getUserDataBybaseUuid: getUserDataBybaseUuid,
		subscribe: subscribe,
		unsubscribe: unsubscribe,
		removeUserFromPortal: removeUserFromPortal,
		loadMySubscriptions: loadMySubscriptions,
		makeSpaceOwner: makeSpaceOwner,
		removeSpaceOwner: removeSpaceOwner,
		getSpaceOwners:  getSpaceOwners,
		getCountryNameValueListJSON: getCountryNameValueListJSON,
		getLicenseTypeDetails: getLicenseTypeDetails,
		createUser: createUser,
		editProfile: editProfile,
		editProfileLanguage: editProfileLanguage,
		enableSmsNotifications: enableSmsNotifications,
		getUserSignatureAndInitials: getUserSignatureAndInitials,
		createUserSignatureAndInitials: createUserSignatureAndInitials,
		updateUserProfileImage: updateUserProfileImage,
		isPortalOwner: isPortalOwner,
		getAllTSGroupsForPortal: getAllTSGroupsForPortal,
		getAllGroups: getAllGroups,
		getAllGroupsOfPortal: getAllGroupsOfPortal,
		getAllGroupsOfUser: getAllGroupsOfUser,
		createGroup: createGroup,
		removeUserFromGroup: removeUserFromGroup,
		addGroupToTeamSpace: addGroupToTeamSpace,
		removeGroupFromTeamSpace: removeGroupFromTeamSpace,
		addUserToGroup: addUserToGroup,
		// isGroupManager: isGroupManager,
		getCompanyUsersJSON: getCompanyUsersJSON,
		isUserAlreadySubscribed: isUserAlreadySubscribed,
		subscribeUser:  subscribeUser,
		unSubscribeUser: unSubscribeUser,
		getUserProfileImageByObjectBaseUuid: getUserProfileImageByObjectBaseUuid,
		getUserProfileImages: getUserProfileImages,
		initEmailToken:initEmailToken,
		completeEmailVerification:completeEmailVerification,
		getDateAndTimeOptions:getDateAndTimeOptions
	};

	return service;

	function completeEmailVerification(requestPayload) {
		var request = ajaxService.completeEmailVerification(requestPayload);
		return request
			.then(function(response) {
				return response.data;
			}, function(response) {
				return null;
			});
	}

	function getDateAndTimeOptions() {
		var request = ajaxService.getDateAndTimeOptions();
		return request
			.then(function(response) {
				return response.data;
			}, function(response) {
				return null;
			});
	}

	function initEmailToken(emailAddress) {
		var request = ajaxService.initEmailToken(emailAddress);
		return request
			.then(function(response) {
				return response.data;
			}, function(response) {
				return null;
			});
	}

	////

	function getTeamSpaceUsersJSON(portalBaseUuid) {
		var request=ajaxService.getTeamSpaceUsersJSON(portalBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}
	
	function getGroupsAndTeamspaces(userBaseUuid)
	{
		var request=ajaxService.getGroupsAndTeamspaces(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});	
	}
	
	function getUserProfileConfigurationsJSON() {
		var request=ajaxService.getUserProfileConfigurationsJSON();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}
	
	function inviteUser(emailAddress) {
		var request=ajaxService.inviteUser(emailAddress);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getUserProfileImage(objectBaseUuid, objectVersionUuid) {
		var request=ajaxService.getUserProfileImage(objectBaseUuid, objectVersionUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getUserProfileImages(input) {
		var request=ajaxService.getUserProfileImages(input);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}
	
	function getUserProfileImageByObjectBaseUuid(objectBaseUuid) {
		var request=ajaxService.getUserProfileImageByObjectBaseUuid(objectBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getUserDataBybaseUuid(userBaseUuid) {
		var request=ajaxService.getUserDataBybaseUuid(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function subscribe(userBaseUuid) {
		var request=ajaxService.subscribe(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function unsubscribe(userBaseUuid) {
		var request=ajaxService.unsubscribe(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function isUserAlreadySubscribed(userBaseUuid) {
		var request=ajaxService.isUserAlreadySubscribed(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function subscribeUser(userBaseUuid) {
		var request=ajaxService.subscribeUser(userBaseUuid);
		//var request=ajaxService.subscribe(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function unSubscribeUser(userBaseUuid) {
		var request=ajaxService.unSubscribeUser(userBaseUuid);
		//var request=ajaxService.unsubscribe(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function removeUserFromPortal(userBaseUuid) {
		var request=ajaxService.removeUserFromPortal(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function loadMySubscriptions() {
		var request=ajaxService.loadMySubscriptions();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function makeSpaceOwner(userBaseUuid) {
		var request=ajaxService.makeSpaceOwner(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function removeSpaceOwner(userBaseUuid) {
		var request=ajaxService.removeSpaceOwner(userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getSpaceOwners() {
		var request=ajaxService.getSpaceOwners();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getCountryNameValueListJSON() {
		var request = ajaxService.getCountryNameValueList();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getLicenseTypeDetails() {
		var request = ajaxService.getLicenseTypeDetails();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function createUser(firstName,lastName,loginID,password,emailAddress,licenseType) {
		var request = ajaxService.createUser(firstName,lastName,loginID,password,emailAddress,licenseType);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function editProfile(userVOJson, bio) {
		var request = ajaxService.editProfile(userVOJson, bio);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function editProfileLanguage(langValue) {
		var request = ajaxService.editProfileLanguage(langValue);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function enableSmsNotifications(enableSms) {
	    var request = ajaxService.enableSmsNotifications(enableSms);
        return request
        .then(function(response) {
            return response.data;
        }, function(response) {
            return null;
        });
	}

	function getUserSignatureAndInitials() {
		var request = ajaxService.getUserSignatureAndInitials();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function createUserSignatureAndInitials(signature, initials) {
		var request = ajaxService.createUserSignatureAndInitials(signature, initials);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function updateUserProfileImage(img, thumbnailType) {
		var request = ajaxService.updateUserProfileImage(img, thumbnailType);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function isPortalOwner() {
		var request = ajaxService.isPortalOwner();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getAllTSGroupsForPortal(portalBaseUuid) {
		var request = ajaxService.getAllTSGroupsForPortal(portalBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getAllGroups() {
		var request = ajaxService.getAllGroups();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function getAllGroupsOfPortal(portalBaseUuid) {
		var request = ajaxService.getAllGroupsOfPortal(portalBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}
	function getAllGroupsOfUser(portalBaseUuid) {
		var request = ajaxService.getAllGroupsOfUser(portalBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function createGroup(groupName) {
		var request = ajaxService.createGroup(groupName);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function removeUserFromGroup(groupBaseuuid, userBaseUuid) {
		var request = ajaxService.removeUserFromGroup(groupBaseuuid, userBaseUuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function addGroupToTeamSpace(groupBaseuuid) {
		var request = ajaxService.addGroupToTeamSpace(groupBaseuuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function removeGroupFromTeamSpace(groupBaseuuid) {
		var request = ajaxService.removeGroupFromTeamSpace(groupBaseuuid);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	function addUserToGroup(groupBaseuuid, usersUuidJson) {
		var request = ajaxService.addUserToGroup(groupBaseuuid, usersUuidJson);
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}

	// function isGroupManager() {
	// 	var request = ajaxService.isGroupManager();
	// 	return request
	// 	.then(function(response) {
	// 		return response.data;
	// 	}, function(response) {
	// 		return null;
	// 	});
	// }

	function getCompanyUsersJSON() {
		var request = ajaxService.getCompanyUsersJSON();
		return request
		.then(function(response) {
			return response.data;
		}, function(response) {
			return null;
		});
	}
}
