function legacyUserProvider(){

    this.isNasdaqUI = {
        enable: false
    }

    this.$get = function legacyUserProvider() {
        var self = this;

        return {
            isNasdaqUI: self.isNasdaqUI.enable,
            getNasdaqUI: function(){
                return self.isNasdaqUI;
            },
            setNasdaqUI: function(isNasdaqUI){
                self.isNasdaqUI.enable = isNasdaqUI;
                this.isNasdaqUI = isNasdaqUI;
            }
        }
    }

}