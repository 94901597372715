brandingController.$inject = ['$scope', '$state', 'localizedMessages', 'brand', 'homeService', 'context', 'fileReader', 'bvDialogService', 'boardbookService', 'bvDialog'];

function brandingController(scope, $state, localizedMessages, brand, homeService, context, fileReader, bvDialogService, boardbookService, bvDialog){

	scope.popupTitle 	= localizedMessages.get('TEAM_SPACE_BRANDING');

	scope.ui = {
				label : {
	        		brandingColor : localizedMessages.get('BRANDING_COLOR'),
	        		primaryColorAndFontColorExample	: localizedMessages.get('PRIMARY_COLOR_AND_FONT_COLOR_EXAMPLE'),
	        		primaryColor : localizedMessages.get('PRIMARY_COLOR'),
	        		fontColor : localizedMessages.get('FONT_COLOR'),
	        		moduleColor	: localizedMessages.get('MODULE_COLOR'),
	        		uploadLogo : localizedMessages.get('UPLOAD_LOGO'),
	        		logo : localizedMessages.get('LOGO'),
	        		banner	: localizedMessages.get('BANNER'),
	        		currentLogo : localizedMessages.get('CURRENT_LOGO'),
	        		uploadNewLogo : localizedMessages.get('UPLOAD_NEW_LOGO'),
	        		reset : localizedMessages.get('RESET'),
	        		save : localizedMessages.get('SAVE'),
	        		useBrandingImage : localizedMessages.get('USE_BRANDING_IMAGE'),
	        		brandImageRotation : localizedMessages.get('BRAND_IMAGE_ROTATION'),
	        		brandImageMargin : localizedMessages.get('BRAND_IMAGE_MARGIN')
					}
				};

	var portalVO = context.getModel();
	var bvModal = scope.bvModalControllerInputs.bvModal;
	scope.companyLogo = homeService.getTSLogo(true, false);
	scope.rotationDisabled = {};
	scope.rotationDisabled.val = false;
	scope.rotationOptions = [
	                         {value:0,   name:localizedMessages.get('NO_ROTATION')},
	                         {value:90,  name:localizedMessages.get('CLOCKWISE')},
	                         {value:-90, name:localizedMessages.get('COUNTER_CLOCKWISE')}
	                         ];
	scope.selectedRotationOption = {};
	scope.useBrandingImageOptions = [
	                                 {name:localizedMessages.get('TRUE'), value:true},
	                                 {name:localizedMessages.get('FALSE'), value:false}
	                                 ];
	scope.selectedUseBrandingImage = {};
	scope.selectedUseBrandingImage.val = {};

	scope.angle = '0';
	scope.margin='0';
	scope.maxHeight = 100;
	scope.maxWidth = 204;
	scope.brandingMargin = {};
	scope.brandingPlaceholder="0-50";

	scope.SHOW_UPLOAD_LOGO = true;

	if(MobileUtil.isApp())
	{
		scope.SHOW_UPLOAD_LOGO = false;
	}

	function setRotationSettings()
	{
		homeService.getBrandingSettings(portalVO.portalVO.objectBaseUuid).then(function(data) {
			var settings = data.data;
			scope.angle = settings.htmlLogoRatation;
			scope.margin = settings.htmlLogoMargin;
			scope.brandingMargin.val = settings.htmlLogoMargin;
			if(settings.htmlUseLogo && scope.companyLogo)
			{
				scope.selectedUseBrandingImage.val = scope.useBrandingImageOptions[0];
				scope.rotationDisabled.val = false;
				if(scope.angle == "0")
				{
					scope.selectedRotationOption.val = scope.rotationOptions[0];
				}
				else if(scope.angle == "90")
				{
					scope.selectedRotationOption.val = scope.rotationOptions[1];
				}
				else
				{
					scope.selectedRotationOption.val = scope.rotationOptions[2];
				}
			}
			else
			{
				scope.selectedUseBrandingImage.val = scope.useBrandingImageOptions[1];
				scope.rotationDisabled.val = true;
				scope.selectedRotationOption.val = scope.rotationOptions[0];
				scope.SHOW_UPLOAD_LOGO = false;
				scope.companyLogo = homeService.getCompanyLogo();
			}
		});
	}

	function getSettingsForCompany()
	{
		homeService.getBrandingSettings("").then(function(data) {
			var settings = data.data;
			scope.defaultBackGround = settings.html5BackgroundColor;
			scope.textColor = settings.html5TextColor;
			scope.modulesColor = settings.html5ModulesColor;
		});
	}

	setRotationSettings();
	getSettingsForCompany();

	scope.getFile = function (file) {
		var img = document.querySelector('.bv-branding-preload img');

		if(StringUtils.isNullEmpty(img))
			return;

		var reader = new FileReader();
		reader.onload = (function(aImg) {
			return function(e) {
				var binary = '';
			    var bytes2 = new Uint8Array( e.target.result );
			    var len = bytes2.byteLength;
			    for (var i = 0; i < len; i++) {
			        binary += String.fromCharCode( bytes2[ i ] );
			    }
				aImg.src = "data:image/jpeg;base64," + window.btoa( binary );
				aImg.width = 206;
				aImg.height = 100;
				aImg.parentNode.style.display = 'block';
				var bytes = new Uint8Array(e.target.result);
    			var byteArray = [];
    			if(bytes != null) {
    				angular.forEach(bytes, function(value , key) {
            			byteArray.push(value);
        			})
    			}
    			homeService.setPreloadCompanyLogo(file, byteArray);
			};
		})(img);

		reader.readAsArrayBuffer(file);
    };

	scope.action = function(){
			var backgroundColor =  $('input#leftColor').val();
			var textColor = $('input#rightColor').val();
			var modulesColor = $('input#middleColor').val();

			context.getModel().html5BackgroundColor = "#" + backgroundColor;
			context.getModel().html5TextColor = "#" + textColor;
			context.getModel().html5ModulesColor = "#" + modulesColor;
			context.getModel().htmlLogoRatation = scope.angle;
			context.getModel().htmlLogoMargin = scope.margin;

			var refreshBoardbookLogosCallback = function () {
			    boardbookService.refreshLogos();
			}

			var useTSLogoOld = context.getModel().htmlUseLogo;
			var useTSLogoNew = scope.selectedUseBrandingImage.val.value;
			if(useTSLogoNew != useTSLogoOld && !useTSLogoNew)
			{
				homeService.getCompanyLogo(refreshBoardbookLogosCallback);
			}
			else if(useTSLogoNew != useTSLogoOld && useTSLogoNew)
			{
				homeService.getCompanyTeamspaceLogo(true, false, refreshBoardbookLogosCallback);
			}
			context.getModel().htmlUseLogo = useTSLogoNew;

			var portal = context.getModel().portalVO;
			var portalVO = {};
			portalVO.html5TextColor = "#" + textColor;
			portalVO.html5BackgroundColor = "#" + backgroundColor;
			portalVO.html5ModulesColor = "#" + modulesColor;
			portalVO.htmlLogoRatation = scope.angle;
			portalVO.htmlLogoMargin = scope.margin == "" ? 0 : scope.margin;
			portalVO.htmlUseLogo = scope.selectedUseBrandingImage.val.value;
			homeService.updatePortalBranding(portal.objectBaseUuid, angular.toJson(portalVO));
			homeService.setCompanyLogo(refreshBoardbookLogosCallback);
			brand.color(backgroundColor, textColor);
			brand.colorMenu(modulesColor);
			bvModal.close();
	};

	scope.close = function(e){
		var target = e.toElement || e.target;
		if(target.className.toString().indexOf('bv-input-btn') != -1){

			var backgroundColor = scope.defaultBackGround;
			var textColor = scope.textColor;
			var modulesColor = scope.modulesColor;
			var useLogo = true;
			var angle = '0';
			var margin = '0';

			context.getModel().html5BackgroundColor = backgroundColor;
			context.getModel().html5TextColor = textColor;
			context.getModel().html5ModulesColor = modulesColor;
			context.getModel().htmlLogoRatation = angle;
			context.getModel().htmlLogoMargin = margin;
			context.getModel().htmlUseLogo = useLogo;
			var portal = context.getModel().portalVO;
			var portalVO = {};
			portalVO.html5TextColor = textColor;
			portalVO.html5BackgroundColor = backgroundColor;
			portalVO.html5ModulesColor = modulesColor;
		    portalVO.htmlLogoRatation = angle;
			portalVO.htmlLogoMargin = margin;
			portalVO.htmlUseLogo = useLogo;
			homeService.updatePortalBranding(portal.objectBaseUuid, angular.toJson(portalVO));
			brand.color(backgroundColor, textColor);
			brand.colorMenu(context.getModel().html5ModulesColor);
			homeService.getCompanyTeamspaceLogo(true, false);
		}
		else{
			scope.revert();
		}
		bvModal.close();
	};

	bvModal.result.then(
			function () {},
			function () { scope.revert();}

	);


	scope.revert = function(){

		homeService.getBrandingSettings(portalVO.portalVO.objectBaseUuid).then(function(data) {
			context.getModel().html5BackgroundColor = data.data.html5BackgroundColor;
			context.getModel().html5TextColor = data.data.html5TextColor;
			context.getModel().html5ModulesColor = data.data.html5ModulesColor;
			context.getModel().htmlLogoRatation = data.data.htmlLogoRatation;
			context.getModel().htmlLogoMargin = data.data.htmlLogoMargin;
			context.getModel().htmlUseLogo = data.data.htmlUseLogo;
			homeService.getCompanyTeamspaceLogo(false, true);
			brand.color(context.getModel().html5BackgroundColor, context.getModel().html5TextColor);
			brand.colorMenu(context.getModel().html5ModulesColor);
		});
	};

	scope.actionBtnTxt 	= scope.ui.label.save;
	scope.closeBtnTxt 	= scope.ui.label.reset;

	scope.changeRotation = function(option)
	{

			scope.angle = option.value;
			scope.selectedRotationOption.val = option;
			context.getModel().htmlLogoRatation = scope.angle;
	}

	scope.setUseTeamspaceLogo = function(option)
	{
		scope.selectedUseBrandingImage.val = option
		if(option.value)
		{
			scope.rotationDisabled.val = false;
			if(!MobileUtil.isApp())
			{
				scope.SHOW_UPLOAD_LOGO = true;
			}
			homeService.getCompanyTeamspaceLogo(true, false);
			scope.selectedRotationOption.val = scope.rotationOptions[0];
		}
		else
		{
			scope.rotationDisabled.val = true;
			scope.SHOW_UPLOAD_LOGO = false;
			scope.companyLogo = homeService.getCompanyLogo();
			scope.brandingMargin.val=0;
			scope.margin=0;
			context.getModel().htmlLogoMargin = 0;
			scope.angle=0;
			context.getModel().htmlLogoRatation = 0;
			scope.selectedRotationOption.val = scope.rotationOptions[0];
		}
	}

    function setCharAt(str,index,chr) {
	    if(index > str.length-1) return str;
	    return str.substr(0,index) + chr + str.substr(index+1);
	}
    scope.changeMargin=function()
	{
		scope.margin=50;
		if(angular.isUndefined(scope.brandingMargin.val))
		{
			scope.brandingMargin.val=0;
			scope.margin=0;
		}
		var regExpr=/(^[1-9][0-9]{0,2}$)|(^[0]$)/;
		var matches=regExpr.test(scope.brandingMargin.val);

		var newStr="";
		if(!matches)
		{
			var charsArrays=scope.brandingMargin.val.split('');
			for(var i=0;i<charsArrays.length;i++)
			{
				newStr=newStr+charsArrays[i];
				if(!regExpr.test(newStr))
				{
					newStr=setCharAt(newStr,i,'');
				}
			}
			scope.brandingMargin.val=newStr;
		}

		if(scope.brandingMargin.val <= 50)
		{
			scope.margin = scope.brandingMargin.val;
		}
		else
		{
			scope.brandingMargin.val = 50;
			bvDialog.showMessage(localizedMessages.get('MAXIMUM_MARGIN_FOR_LOGO_IS') + " 50.");
		}
        context.getModel().htmlLogoMargin = scope.margin;
	}

}