angular
    .module('dialogControllers.service')
	.controller('briefcaseInstallationsController', briefcaseInstallationsController);

briefcaseInstallationsController.$inject = ['homeService', '$scope', 'context', 'localizedMessages', 'bvDialog'];

function briefcaseInstallationsController(homeService, $scope, context, localizedMessages, bvDialog)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.activeInstallations = $scope.bvModalControllerInputs.activeInstallations;
	$scope.purgedInstallations = $scope.bvModalControllerInputs.purgedInstallations;
	$scope.activeInstallationsSort = "none";
	$scope.purgedInstallationsSort = "none";

	$scope.init = function() 
	{
		$scope.CURRENT_OPTION = 'ACTIVATED';
		$scope.popupTitle = localizedMessages.get('MANAGE_BRIEFCASE_INSTALLATIONS');
		$scope.UI_ACTIVATED_TXT = localizedMessages.get('ACTIVATED');
		$scope.UI_PURGED_TXT = localizedMessages.get('PURGED');
		$scope.UI_REMOTE_ID_TXT = localizedMessages.get('REMOTE_ID');
		$scope.UI_HOST_ADDRESS_TXT = localizedMessages.get('HOST_ADDRESS');
		$scope.UI_HOST_NAME_TXT = localizedMessages.get('HOST_NAME');
		$scope.UI_OS_TXT = localizedMessages.get('OS');
		$scope.UI_OS_USER_TXT = localizedMessages.get('OS_USER');
		$scope.UI_INSTALL_DATE_TXT = localizedMessages.get('INSTALL_DATE');
		$scope.UI_PURGE_TXT = localizedMessages.get('PURGE');
		$scope.UI_PURGE_STATUS_TXT = localizedMessages.get('PURGE_STATUS');
		$scope.purgeCheckMsg = localizedMessages.get('MSG_PURGE_BRIEFCASE_CHECK');
		$scope.PENDING_LABEL = localizedMessages.get('PENDING');
		$scope.COMPLETE_LABEL = localizedMessages.get('COMPLETE');
		$scope.activeSortImage = "resources/assets/images/time_picker_arrow_@2x.png";
		$scope.purgedSortImage = "resources/assets/images/time_picker_arrow_@2x.png";

		formatInstallationData();
	}

	$scope.changePropOption = function (optionVal)
	{
		$scope.CURRENT_OPTION = optionVal;
	}

	$scope.removeInstallation = function(installation)
	{
		bvDialog.confirmNoBackdrop($scope.purgeCheckMsg, function()
        {
            $scope.activeInstallations.splice($scope.activeInstallations.indexOf(installation), 1);
			homeService.purgeRemoteBriefcase(installation.companyBaseUuid, installation.createdByBaseUuid, installation.objectBaseUuid).then(function()
			{
				homeService.getUserVaultInstallations().then(function(data)
				{
					$scope.activeInstallations = data.data.installations;
					$scope.purgedInstallations = data.data.removedInstallations;

					formatInstallationData(true);
				});
			});
        });
	}

	$scope.sortActiveInstallations = function(sortCurrent)
	{
		if($scope.activeInstallationsSort === "none" || $scope.activeInstallationsSort === "dsc" || (sortCurrent && $scope.activeInstallationsSort === "asc"))
		{
			$scope.activeInstallations.sort(function(a, b)
		    {
		    	var aId = a.simpleId.substring(2);
		    	var bId = b.simpleId.substring(2);
		    	return aId - bId;
		    });	

		    $scope.activeInstallationsSort = "asc";
		    //$scope.activeSortImage = "resources/assets/images/time_picker_arrow_@2x.png";
		}
		else
		{
			$scope.activeInstallations.sort(function(a, b)
		    {
		    	var aId = a.simpleId.substring(2);
		    	var bId = b.simpleId.substring(2);
		    	return bId - aId;
		    });

			$scope.activeInstallationsSort = "dsc";
			//$scope.activeSortImage = "resources/assets/images/small_black_up_arrow_@2x.png";
		}
	}

	$scope.sortPurgedInstallations = function(sortCurrent)
	{
		if($scope.purgedInstallationsSort === "none" || $scope.purgedInstallationsSort === "dsc" || (sortCurrent && $scope.purgedInstallationsSort === "asc"))
		{
			$scope.purgedInstallations.sort(function(a, b)
		    {
		    	var aId = a.simpleId.substring(2);
		    	var bId = b.simpleId.substring(2);
		    	return aId - bId;
		    });	

		    $scope.purgedInstallationsSort = "asc";
		    //$scope.purgedSortImage = "resources/assets/images/time_picker_arrow_@2x.png";
		}
		else
		{
			$scope.purgedInstallations.sort(function(a, b)
		    {
		    	var aId = a.simpleId.substring(2);
		    	var bId = b.simpleId.substring(2);
		    	return bId - aId;
		    });

			$scope.purgedInstallationsSort = "dsc";
			//$scope.purgedSortImage = "resources/assets/images/small_black_up_arrow_@2x.png";
		}
	}

	var formatInstallationData = function(sortCurrent)
	{
		for(var i=0; i<$scope.activeInstallations.length; i++)
		{
			$scope.activeInstallations[i].createdOn = moment($scope.activeInstallations[i].createdOn).format("lll");
		}
		$scope.sortActiveInstallations(sortCurrent);

		for(var i=0; i<$scope.purgedInstallations.length; i++)
		{
			$scope.purgedInstallations[i].createdOn = moment($scope.purgedInstallations[i].createdOn).format("lll");
			var purgeStatus = $scope.purgedInstallations[i].purgeStatus;
			if(purgeStatus == "Pending")
			{
				$scope.purgedInstallations[i].purgeStatus = $scope.PENDING_LABEL;
			}
			else if(purgeStatus == "Complete")
			{
				$scope.purgedInstallations[i].purgeStatus = $scope.Complete_LABEL;
			}
		}
		$scope.sortPurgedInstallations(sortCurrent);
	};

	$scope.init();
}