function bvFocus(){
	return {
		restrict: 'A',
		scope: {
			bvFocusDisabled : '='
		},
		link: function($scope, $el, $attr){
			if($scope.bvFocusDisabled != true){
				setTimeout(function(){
					$el[0].focus();
				},50)
			}
		}
	}
}

