function middleBar(legacyUserService){
    return {
        restrict: 'A',
        transclude: true,
        scope: false,
        templateUrl: !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/MiddleBar.tpl.html' : 'modules/home/htmlStructure/middlebar/middleBar.template.html',
        link: function (scope, el, attrs) {
        	scope.selectTab = function( tab ) {
			    if (tab.func != undefined) {
			        tab.func();
			    }
			}
	    }
    }
}