function SurveyDirective(){
    return {
        restrict: 'CE',
        templateUrl: "modules/surveys/OpenSurveyDirective.tpl.html",
        controller: ['$scope', '$state', '$stateParams', 'surveysService', 'repositoryService', 'imageService', 'localizedMessages', 'context', '$modal', '$modalStack', 
        function OpenSurveyController($scope, $state, $stateParams, surveysService, repositoryService, imageService, localizedMessages, context, $modal, $modalStack) {
        	$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name;
        	var currentUserVO = context.getModel().userVO;
        	$scope.ui = {
                    label : {
                    	complete : localizedMessages.get('COMPLETE'),
                    	due : localizedMessages.get('DUE'),
                    	priority : localizedMessages.get('PRIORITY'),
                    	previous : localizedMessages.get('PREVIOUS'),
                    	resume : localizedMessages.get('RESUME'),
                    	next : localizedMessages.get('NEXT'),
                    	back:localizedMessages.get('BACK'),
                    	finish_later : localizedMessages.get('FINISH_LATER'),
                    	question_attachment : localizedMessages.get('QUESTION_ATTACHMENT'),
                    	caps_complete: localizedMessages.get('CAPS_COMPLETE'),
                    	completed : localizedMessages.get('COMPLETED'),
                    	section : localizedMessages.get('SECTION'),
                    	sections : localizedMessages.get('SECTIONS'),
                    	questions : localizedMessages.get('QUESTIONS'),
                    	attachments : localizedMessages.get('ATTACHMENTS'),
                    	start : localizedMessages.get('START'),
                    	commit : localizedMessages.get('COMMIT'),
                    	no_survey_attachments : localizedMessages.get('NO_SURVEY_ATTACHMENTS'),
                    	send_alert : localizedMessages.get('SEND_ALERT'),
                    	reopen : localizedMessages.get('REOPEN'),
                    	copy: localizedMessages.get('COPY'),
                    	preview : localizedMessages.get('PREVIEW'),
                    	report : localizedMessages.get('REPORT'),
                    	no_section_attachments : localizedMessages.get('NO_SECTION_ATTACHMENTS'),
                    	mandatory : localizedMessages.get('MANDATORY'),
                    	response: localizedMessages.get('SELECT_A_RESPONSE'),
                    	no_question_attachments : localizedMessages.get('NO_QUESTION_ATTACHMENTS'),
                    	notify : localizedMessages.get('NOTIFY'),
                    	send_secure_email : localizedMessages.get('SEND_SECURE_EMAIL'),
                    	please_respond_to_the_survey : localizedMessages.get('PLEASE_RESPOND_TO_THE_SURVEY'),
                    	subject : localizedMessages.get('SUBJECT'),
                    	to : localizedMessages.get('TO'),
                    	alert_for_survey_participants : localizedMessages.get('ALERT_FOR_SURVEY_PARTICIPANTS'),
                    	select_all : localizedMessages.get('SELECT_ALL'),
                    	in_this_case_all_the_survey_questions_and_participants_are_copied: localizedMessages.get('IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_ARE_COPIED'),
                    	in_this_case_all_the_survey_questions_and_participants_and_responses_are_copied: localizedMessages.get('IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES_ARE_COPIED'),
                    	title_of_survey : localizedMessages.get('TITLE_OF_SURVEY'),
                    	copy_of_options : localizedMessages.get('COPY_OF_OPTIONS'),
                    	copy_of_questions_and_participants : localizedMessages.get('COPY_OF_QUESTIONS_AND_PARTICIPANTS'),
                    	copy_of_questions_and_participants_and_responses : localizedMessages.get('COPY_OF_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES'),
                    	copy_of_questions_only : localizedMessages.get('COPY_OF_QUESTIONS_ONLY'),
                    	in_this_case_all_the_questions_are_copied : localizedMessages.get('IN_THIS_CASE_ALL_THE_QUESTIONS_ARE_COPIED'),
                    	printable_reports : localizedMessages.get('PRINTABLE_REPORTS'),
                    	attachment : localizedMessages.get('ATTACHMENT'),
                    	survey_title : localizedMessages.get('SURVEY_TITLE'),
                    	description : localizedMessages.get('DESCRIPTION'),
                    	date : localizedMessages.get('DATE'),
                    	created_by : localizedMessages.get('CREATED_BY'),
                    	section_number : localizedMessages.get('SECTION_NUMBER'),
                    	title : localizedMessages.get('TITLE'),
                    	instructions : localizedMessages.get('INSTRUCTIONS'),
                    	save : localizedMessages.get('SAVE'),
                    	print : localizedMessages.get('PRINT'),
                    	text : localizedMessages.get('TEXT'),
                    	bar : localizedMessages.get('BAR'),
                    	pie : localizedMessages.get('PIE'),
                    	open_surveys : localizedMessages.get('OPEN_SURVEYS'),
                    	created_by_me : localizedMessages.get('CREATED_BY_ME'),
                    	owned_by_me : localizedMessages.get('OWNED_BY_ME'),
                    	closed_surveys : localizedMessages.get('CLOSED_SURVEYS'),
                    	bar_chart : localizedMessages.get('BAR_CHART'),
                    	pie_chart : localizedMessages.get('PIE_CHART'),
                    	submit : localizedMessages.get('SUBMIT'),
                    	cancel : localizedMessages.get('CANCEL'),
                    	signature : localizedMessages.get('SIGNATURE'),
                    	view : localizedMessages.get('VIEW'),
                    	respond : localizedMessages.get('RESPOND'),
                    	resubmit : localizedMessages.get('RESUBMIT'),
                    	survey: localizedMessages.get('SURVEY'),
                    	question: localizedMessages.get('QUESTION'),
                    	of:localizedMessages.get('OF')
                    }
                };
        	
        	$scope.showSurveyOverview = true;
        	$scope.respondedMap = {};
        	$scope.surveySectionResponsesMap = {}
        	
        	$scope.isLoggedInUserOwner = function(surveyOwnersList){
        		for(var i=0;i<surveyOwnersList.length;i++){
        			sessionStorage.setItem('isLoggedInUserOwnerOfSurvey',false);
        			if(currentUserVO.objectBaseUuid == surveyOwnersList[i].objectBaseUuid){
        				sessionStorage.setItem('isLoggedInUserOwnerOfSurvey', true);
        				return true;
        			}
        		}
        	}
        	$scope.hasLoggedInUserResponded = function(surveyDetails){
        		$scope.userSurveyResponseStatus = false;
        		var stat = '';
        		for(var i=0;i<surveyDetails.participantBaseUuidAndResponseList.length;i++){
        			if(surveyDetails.participantBaseUuidAndResponseList[i].objectBaseUuid == currentUserVO.objectBaseUuid){
        				stat = surveyDetails.participantBaseUuidAndResponseList[i].value;
        				break;
        			}
        		}
        		if(stat == 'responded'){
        			$scope.userSurveyResponseStatus = true;
        		}else{
        			$scope.userSurveyResponseStatus = false;
        		}
        	};
        	var currentSelectedSurveyFromList = surveysService.getCurrentSelectedSurveyFromList();
        	$scope.middleBarObject.title=currentSelectedSurveyFromList.name;
        	if(sessionStorage.getItem('isLoggedInUserOwnerOfSurvey')==undefined || sessionStorage.getItem('isLoggedInUserOwnerOfSurvey')==''){
        		$scope.isLoggedInUserOwner(currentSelectedSurveyFromList.owners);
        	}
        	
        	var showSurveyPreview = function() {
        		var surveyPreview = true;
        		$scope.resumeSurvey(surveyPreview);
        	};
        	var showSurveyReport = function() {
        		$scope.reportModal = $modal.open({
        	        templateUrl: 'modules/surveys/SurveyReport.tpl.html',
        	        size: 'lg',
        	        scope:$scope,
        	        windowClass: 'bv-report-modal'
        	      });
        		surveysService.getSurveyResponseForUsersJSON(sessionStorage.getItem("surveyBaseUuid"), angular.toJson(surveysService.getParticipantBaseUuidList())).then(function(data) {
        			//Prepare list
        			var dataForChart = [];
        			for(var key in data.userResponseStatusMap){
        				for(var j=0;j<data.surveyQuestionResponseWrapperList.length;j++){
        					if(key == data.surveyQuestionResponseWrapperList[j].userBaseUuid){
        					}
        				}
        			}
        			
        			var allSurveyQuestionsList = surveysService.getAllQuestionsList();
        				for(var j=0;j<allSurveyQuestionsList.length;j++){
        					var options=[];
        						if(allSurveyQuestionsList[j].questionType=="multiplechoice"){
        							for(var i=0;i<allSurveyQuestionsList[j].multipleChoiceValues.length;i++){
        								options[i] = allSurveyQuestionsList[j].multipleChoiceValues[i].name;
        							}
        							allSurveyQuestionsList[j].options = options;
        						}
        						if(allSurveyQuestionsList[j].questionType=="yesno"){
        							options.push('yes');
        							options.push('no');
        							allSurveyQuestionsList[j].options = options;
        						}
        						if(allSurveyQuestionsList[j].questionType=="openanswer"){
        							options.push('answered');
        							options.push('not answered');
        							allSurveyQuestionsList[j].options = options;
        						}
        						if(allSurveyQuestionsList[j].questionType=="likertscale"){
        							for(var i=0;i<allSurveyQuestionsList[j].likertScaleValues.length;i++){
        								options[i] = allSurveyQuestionsList[j].likertScaleValues[i].name;
        							}
        							allSurveyQuestionsList[j].options = options;
        						}
        						if(allSurveyQuestionsList[j].questionType=="truefalse"){
        							options.push('true');
        							options.push('false');
        							allSurveyQuestionsList[j].options = options;
        						}
        						if(allSurveyQuestionsList[j].questionType=="meetsornot"){
        							options.push('meets');
        							options.push('does not meet');
        							allSurveyQuestionsList[j].options = options;
        						}
        				}
        				
        				$scope.pieData={};
        				
        				for(var i=0;i<data.surveyQuestionResponseWrapperList.length;i++){
        					for(var j=0;j<allSurveyQuestionsList.length;j++){
        						$scope.examplePieData = [];
        						if(data.surveyQuestionResponseWrapperList[i].responseVOs[0].questionBaseUuid == allSurveyQuestionsList[j].objectBaseUuid){
        							if(allSurveyQuestionsList[j].options && allSurveyQuestionsList[j].options.length>0){
        								for(var l=0;l<allSurveyQuestionsList[j].options.length;l++){
        									var count=0;
        									var q = data.surveyQuestionResponseWrapperList[i].responseVOs[0].questionBaseUuid;
        									$scope.pieData[q] = [];
        									var arr=[];
        									for(var k=0;k<data.surveyQuestionResponseWrapperList[i].responseVOs.length;k++){
        										var obj ={};
//        										if(allSurveyQuestionsList[j].questionType=="openanswer"){
//        											if(allSurveyQuestionsList[j].response && allSurveyQuestionsList[j].response!=''){
//        												obj['key'] = 'answered';
//        												count++;
//        												obj['y'] =count;
//        												$scope.examplePieData.push(obj);
//        												$scope.pieData[q].push($scope.examplePieData);
//        											}else if(allSurveyQuestionsList[j].response==''){
//        												count = 0;
//        												obj['key'] = 'not answered';
//        												count++;
//        												obj['y'] =count;
//        												$scope.examplePieData.push(obj);
//        												$scope.pieData[q].push($scope.examplePieData);
//        											}
//        										}
//        										else{
        											if(allSurveyQuestionsList[j].options[l] == data.surveyQuestionResponseWrapperList[i].responseVOs[k].response){
        												obj['key'] = (data.surveyQuestionResponseWrapperList[i].responseVOs[k].response);
        												count++;
        												obj['y'] = count;
        												$scope.examplePieData.push(obj);
        												$scope.pieData[q].push($scope.examplePieData);
        											}
        											else{
        												if(!(k > 0)){
        													count = 0;
        													obj['key'] = allSurveyQuestionsList[j].options[l];
        													obj['y'] = count;
        													$scope.examplePieData.push(obj);
        													$scope.pieData[q].push($scope.examplePieData);
        												}
        											}
//        										}
        									}
        								}
        							}
        							break;
        						}
        					}
        				}
        				$scope.barData = {};
        				for (var key in $scope.pieData) {
        					var q=key;
        					var main = [];
        					var vals = [];
        					var key1 = $scope.pieData[key];
        					for( var i=0;i<key1[0].length;i++){
        						$scope.barData[q] = [];
        						var chartArray = [];
        						var arrayObj = key1[0];
        						var key2 = arrayObj[i].key;
        						var value = arrayObj[i].y;
        						chartArray.push(key2);
        						chartArray.push(value);
        						main.push(chartArray);
        					}
        					var valObj = {'values':main};
        					vals.push(valObj);
        					$scope.barData[q].push(vals);
        				}
//        				EXAMPLE DATA FORAMT FOR REPORTS
        			
//        			$scope.exampleBarData = [
//        			                                       {
//        			                                           "values": [ [ "Strongly Agree",3],[ "Agree",1],[ "Disagree",5],[ "Strongly Disagree",6],[ "No Opinion",2] ]
//        			                                       }
//        			                                  ];
        			
//        			  $scope.examplePieData = [
//        			                  {
//        			                    key: "Strongly Agree",
//        			                    y: 3
//        			                  },
//        			                  {
//        			                    key: "Agree",
//        			                    y: 1
//        			                  },
//        			                  {
//        			                    key: "Disagree",
//        			                    y: 5
//        			                  },
//        			                  {
//        			                    key: "Strongly Disagree",
//        			                    y: 6
//        			                  },
//        			                  {
//        			                    key: "No Opinion",
//        			                    y: 2
//        			                  }
//        			                ];
        			  
        		    	$scope.xFunction = function () {
        		            return function (d) {
        		                return d.key;
        		            };
        		      	};

        		        $scope.yFunction = function(){
        		            return function(d){
        		                return d.y;
        		            };
        		        }
        		});
        	};
        	$scope.selectedAll = false;
        	$scope.sendAlertUsersList=[];
        	var sendSurveyAlert = function() {
        		$scope.participantsListModal = $modal.open({
        	        templateUrl: 'modules/surveys/ParticipantsList.tpl.html',
        	        size: 'sm',
        	        scope:$scope,
        	        windowClass: 'bv-participantsList-modal'
        	      });
        		$scope.participants=$scope.surveyDetails.participantBaseUuidAndResponseList; 
        		$scope.checkAll = function () {
        	        if ($scope.selectedAll == false) {
        	            $scope.selectedAll = true;
        	            $scope.sendAlertUsersList = angular.copy($scope.participants);
        	        } else {
        	            $scope.selectedAll = false;
        	            $scope.sendAlertUsersList = [];
        	        }
        	    };
        	}
        	
            $scope.addOrRemoveUser = function(participant){
           	 $scope.selectedAll = false;
           	 if($scope.sendAlertUsersList && $scope.sendAlertUsersList.length>0){
           		 for(var i=0;i<$scope.sendAlertUsersList.length;i++){
           	    	 if($scope.sendAlertUsersList[i].objectBaseUuid == participant.objectBaseUuid){
           	    		 $scope.sendAlertUsersList.splice(i,1);
           	    		 break;
           	    	 }
           	    	 else{
           	    		 $scope.sendAlertUsersList.push(participant);
           	    		 break;
           	    	 }
           		 }
           	 }
           	 else{
           		 $scope.sendAlertUsersList.push(participant);
           	 }
            };
            
        	$scope.openComposeMail = function() {
        		$scope.surveyAlertSubject = $scope.ui.label.please_respond_to_the_survey;
        	    $scope.surveyAlertComments = $scope.ui.label.please_respond_to_the_survey + '-'+ $scope.surveyDetails.name;
        		$scope.toListString='';
        		$scope.toSecurityObjectList = [];
        		$scope.participantsListModal.dismiss('cancel');
        	    for(var i=0;i<$scope.sendAlertUsersList.length;i++){
        	    	$scope.toListString += $scope.sendAlertUsersList[i].name+';';
        	    	$scope.toSecurityObjectList.push($scope.sendAlertUsersList[i].objectBaseUuid);
        	    }
        		$scope.sendAlerttModal = $modal.open({
        	        templateUrl: 'modules/surveys/ComposeMail.tpl.html',
        	        size: 'lg',
        	        scope:$scope,
        	        windowClass: 'bv-send-alert-modal'
        	      });
        		$scope.participants=$scope.surveyDetails.participantBaseUuidAndResponseList;
        		$scope.sendSecureEmail=true;
        	}
        	$scope.sendEmail = function() {
        		surveysService.sendSurveyAlertJSON(angular.toJson($scope.toSecurityObjectList), $scope.surveyAlertSubject, $scope.surveyAlertComments,$scope.surveyDetails.objectBaseUuid,'nothing', $scope.sendSecureEmail).then(function(data){
        			if(data.boName == "SuccessBO"){
        				$scope.sendAlerttModal.dismiss('cancel');
        			}
        		});
        	};
        	
        	var copySurvey = function(){
        		$scope.copySurveyModal = $modal.open({
        	        templateUrl: 'modules/surveys/CopySurvey.tpl.html',
        	        size: 'lg',
        	        scope:$scope,
        	        windowClass: 'bv-copy-survey-modal'
        	      });
        		
        		$scope.copySurveyTitle=surveysService.getSurvey().name+"-" + $scope.ui.label.copy;
        		$scope.copySurveyRadio='copyQuestionParticipant';
        		
        		$scope.copySurvey = function(){
        			surveysService.copySurveyJSON(surveysService.getSurvey().objectBaseUuid, $scope.copySurveyTitle, $scope.copySurveyRadio).then(function(data){
        				if(data.boName == "SuccessBO"){
        					$scope.copySurveyModal.dismiss('cancel');
        				}
        			});
        		}
        	};
        	
        	var reopenSurvey = function(){
        	};
        	
        	var rightMenuList=[];
        	if(sessionStorage.getItem('isLoggedInUserOwnerOfSurvey')==true || sessionStorage.getItem('isLoggedInUserOwnerOfSurvey')=='true'){
        		rightMenuList = [ 
        		     {
        		    	title : $scope.ui.label.send_alert,
        		    	click : sendSurveyAlert, 
        		    	iconUrl: '', 
        		    	svgFill: '#fff'
        		    },
        		    {
        			    title : $scope.ui.label.preview,
        				click : showSurveyPreview,
        				iconUrl: '', 
        				svgFill: '#fff'
        			},
        			{
        				title : $scope.ui.label.report,
        				click : showSurveyReport, 
        				iconUrl: '', 
        				svgFill: '#fff'
        			},
        			{
        				title : $scope.ui.label.copy,
        				click : copySurvey, 
        				iconUrl: '', 
        				svgFill: '#fff'
        			},
        			{
        				title : $scope.ui.label.reopen,
        				click : reopenSurvey, 
        				iconUrl: '', 
        				svgFill: '#fff'
        			}
        		];
        	}
        	
        	$scope.$parent.tabs = [ {
        		title : $scope.ui.label.survey,
        		content : 'openSurveys',
        		rightMenuList:rightMenuList
        	} ];
        	
            $scope.reportTypes = [
                             {name: $scope.ui.label.bar_chart, value:"barChart"},
                             {name:$scope.ui.label.pie_chart, value:"pieChart"}
                           ];
            $scope.reportSelected={};
            $scope.reportSelected = $scope.reportTypes[0];

        	var surveyQuestionResponseList;
        	$scope.surveyDetails = surveysService.getSurvey();
        	$scope.questionsCompletedInSection = {};
        	if($scope.surveyDetails === undefined){
        		surveysService.getSurveyDetailsJSON(sessionStorage.getItem('surveyBaseUuid')).then(function(data) {
        			surveysService.setSurvey(data);
        			$scope.surveyDetails = surveysService.getSurvey();
        		    $scope.surveyTotalQuestions = 0;
        		    $scope.surveyAnsweredQuestions = 0;
        			for(var i=0;i<$scope.surveyDetails.sections.length;i++){
        				$scope.surveyTotalQuestions+=$scope.surveyDetails.sections[i].questionsCount;
        				$scope.surveyAnsweredQuestions+=$scope.surveyDetails.sections[i].questionsResponded;
        				$scope.questionsCompletedInSection[$scope.surveyDetails.sections[i].objectBaseUuid] = Math.round(($scope.surveyDetails.sections[i].questionsResponded/$scope.surveyDetails.sections[i].questionsCount)*100);
        			}
        				$scope.surveyPercentage=($scope.surveyAnsweredQuestions/$scope.surveyTotalQuestions)*100;
        			})
        	} 
        	if($scope.surveyDetails != undefined){
        	    $scope.surveyTotalQuestions = 0;
        	    $scope.surveyAnsweredQuestions = 0;
        		for(var i=0;i<$scope.surveyDetails.sections.length;i++){
        			$scope.surveyTotalQuestions+=$scope.surveyDetails.sections[i].questionsCount;
        			$scope.surveyAnsweredQuestions+=$scope.surveyDetails.sections[i].questionsResponded;
        			$scope.questionsCompletedInSection[$scope.surveyDetails.sections[i].objectBaseUuid] = Math.round(($scope.surveyDetails.sections[i].questionsResponded/$scope.surveyDetails.sections[i].questionsCount)*100);
        		}
        			$scope.surveyPercentage=($scope.surveyAnsweredQuestions/$scope.surveyTotalQuestions)*100;
        			if($scope.surveyDetails.sections != undefined){
        		    	var allQsList = surveysService.getQuestionBaseUuidList();
        		    	for(var k=0;k<$scope.surveyDetails.sections.length;k++){
        		    		surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetails.objectBaseUuid, $scope.surveyDetails.sections[k].objectBaseUuid,currentUserVO.objectBaseUuid).then(function(data){
        		    			$scope.surveySectionResponsesMap[data.sectionObjectBaseUuid] = data.surveyQuestionResponseList;
        		    			surveyQuestionResponseList = data.surveyQuestionResponseList;
        		    			if(surveyQuestionResponseList != undefined){
        			    			for(var i=0;i<surveyQuestionResponseList.length;i++){
        			    				$scope.respondedMap[surveyQuestionResponseList[i].questionBaseUuid] = surveyQuestionResponseList[i].response;
        			    			}
        			        			for(var i=0;i<allQsList.length;i++){
        			        				if($scope.respondedMap[allQsList[i]] == null || $scope.respondedMap[allQsList[i]] == undefined){
        			        					$scope.respondedMap[allQsList[i]] = '';
        			        				}
        			        			}
        		    				}
        		    			});
        				}
        			}
        			$scope.hasLoggedInUserResponded($scope.surveyDetails);
        	}
        	//When section is clicked.
        	$scope.showSectionDetails = function(section, surveyPreviewFlag, secIndex)
        	{
//        		event.stopPropagation();
//        		event.preventDefault();
        		$('.surveyOverviewAccordion').removeClass("highlight");
        		$('.surveyOverviewAccordion').find('p').removeClass("highlight");
        		$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name;
        		$scope.currentSectionIndex = secIndex;
        		$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name + ' > ' +section.sectionName;
        		$scope.showSectionDetailsDIV = true;
        		$scope.showSurveyOverview = false;
        		$scope.showSurveyQuestionDIV =false;
        		$scope.surveyPreview = surveyPreviewFlag;
        		surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetails.objectBaseUuid, section.objectBaseUuid,currentUserVO.objectBaseUuid).then(function(data){
        		surveyQuestionResponseList = data.surveyQuestionResponseList;	
        		});
        		$scope.section = section;
        	};
        	
    		$scope.showPreviousSectionQuestions = function() {
    			$scope.currentSectionIndex = $scope.currentSectionIndex-1;
    			if($scope.currentSectionIndex < 0){
    				$scope.currentSectionIndex = $scope.surveyDetails.sections.length-1;
    			}
    			$scope.showSectionQuestions($scope.surveyDetails.sections[$scope.currentSectionIndex], ($scope.surveyDetails.sections[$scope.currentSectionIndex].questions.length)-1, $scope.currentSectionIndex,false);
    		};
    		
    	    $scope.finishSectionLater = function() {
    	    	$('.surveyOverviewAccordion').removeClass("highlight");
        		$('.surveyOverviewAccordion').find('p').removeClass("highlight");
    	    	$scope.showSurveyOverview=true;
    	    	$scope.showSectionDetailsDIV = false;
    	    	$state.go('home.openSurvey');
    	    	$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name;
    			};
    			
        	//question response is saved only if there is a change in input.
        	$scope.responseChanged = function(){
        		$scope.reponseChangedValue = true;
        	};
        	//When next is clicked on section details screen for questions
        	$scope.showSectionQuestions = function(section, resumeQuestionIndex, secIndex, isQuestionClicked)
        	{
        		if(isQuestionClicked){
        			$scope.currentSectionIndex = secIndex;
        		}
        		$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name + ' > ' +section.sectionName;
        		$scope.showSurveyQuestionDIV = true;
        		$scope.showSectionDetailsDIV = false;
        		$scope.showSurveyOverview = false;
        		$scope.reponseChangedValue = false;
        		$scope.section = section;
        		$scope.question = section.questions[resumeQuestionIndex];
        		surveyQuestionResponseList = $scope.surveySectionResponsesMap[section.objectBaseUuid];
        		setResponseInScopeFromResponseList(surveyQuestionResponseList); //If response exists put it in scope
        	    $scope.currentQuestionIndex = resumeQuestionIndex;
        	    $('.surveyOverviewAccordion').removeClass("highlight");
        		$('.surveyOverviewAccordion').find('p').removeClass("highlight");
        		$('#index'+ $scope.currentSectionIndex +$scope.currentQuestionIndex).addClass("highlight");
        		$('#index'+ $scope.currentSectionIndex +$scope.currentQuestionIndex).find('p').addClass("highlight");
        	    
        	    $scope.showNextQuestion = function() {
        	    	if(validMandatoryResponseInputs() == "false" || validMandatoryResponseInputs() == false){
        	    		return;
        	    	}
        	    	if(++$scope.currentQuestionIndex < section.questions.length)
        	    	{
        	    		if($scope.reponseChangedValue){ //first save previous question response.
        	    			saveSurveyQuestionResponse();
        	    			$scope.reponseChangedValue = false; //Resetting
        	    		}
        	    		$scope.question = section.questions[$scope.currentQuestionIndex];//Next show next question
        	    		setResponseInScopeFromResponseList(surveyQuestionResponseList); //Update response object with newly answered question
        	    		$('.surveyOverviewAccordion').removeClass("highlight");
        	    		$('.surveyOverviewAccordion').find('p').removeClass("highlight");
        	    		$('#index'+$scope.currentSectionIndex+$scope.currentQuestionIndex).addClass("highlight");
        	    		$('#index'+$scope.currentSectionIndex+$scope.currentQuestionIndex).find('p').addClass("highlight");
        	    	}
        	    }
        	    
        	    $scope.showPreviousQuestion = function() {
        	    	$('.questionDetail').removeClass('surveyQuestionSectionsError');
        	    	if(--$scope.currentQuestionIndex < section.questions.length && $scope.currentQuestionIndex >=0)
        	    	{
        	    		$scope.question = section.questions[$scope.currentQuestionIndex];
        	    		setResponseInScopeFromResponseList(surveyQuestionResponseList); //To get the response of previous question find in responseList
        	    		$scope.reponseChangedValue = false;
        	    		$('.surveyOverviewAccordion').removeClass("highlight");
        	    		$('.surveyOverviewAccordion').find('p').removeClass("highlight");
        	    		$('#index'+$scope.currentSectionIndex+$scope.currentQuestionIndex).addClass("highlight");
        	    		$('#index'+$scope.currentSectionIndex+$scope.currentQuestionIndex).find('p').addClass("highlight");
        	    	}
        	    }
        	    
        		$scope.completeQuestions = function() {
        	    	if(validMandatoryResponseInputs() == "false" || validMandatoryResponseInputs() == false){
        	    		return;
        	    	}
        			if($scope.reponseChangedValue){
        				saveSurveyQuestionResponse();
        				$scope.reponseChangedValue = false;
        	    	}
        			$('.surveyOverviewAccordion').removeClass("highlight");
    	    		$('.surveyOverviewAccordion').find('p').removeClass("highlight");	
        			$scope.showSurveyQuestionDIV = false;
        			$scope.showSectionDetailsDIV = true;
        			$scope.showSurveyOverview = false;
        			if($scope.currentSectionIndex==$scope.surveyDetails.sections.length-1){
        				$scope.currentSectionIndex = 0;
        				$scope.showSectionDetails($scope.surveyDetails.sections[$scope.currentSectionIndex],$scope.surveyPreview, $scope.currentSectionIndex);
        				return;
        			}
        			if($scope.currentSectionIndex<$scope.surveyDetails.sections.length-1){
        				//If resume section show next section on completion of questions
        				$scope.currentSectionIndex = $scope.currentSectionIndex+1;
        				surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetails.objectBaseUuid, $scope.surveyDetails.sections[$scope.currentSectionIndex].objectBaseUuid,currentUserVO.objectBaseUuid).then(function(data){
        					surveyQuestionResponseList = data.surveyQuestionResponseList;
        					$scope.showSectionDetails($scope.surveyDetails.sections[$scope.currentSectionIndex],$scope.surveyPreview, $scope.currentSectionIndex);
        					});
        			}
        		}
        		
        	    $scope.finishQuestionLater = function() {
        	    	$scope.currentSectionIndex = secIndex;
        	    	$('.surveyOverviewAccordion').removeClass("highlight");
    	    		$('.surveyOverviewAccordion').find('p').removeClass("highlight");
        	    	$scope.showSurveyQuestionDIV = false;
        			$scope.showSectionDetailsDIV = true;
        			$scope.showSurveyOverview = false;
        			};
        			
        		function setResponseInScopeFromResponseList(surveyQuestionResponseList) {
        			if (surveyQuestionResponseList != undefined
        					&& surveyQuestionResponseList.length > 0) {
        				for ( var i = 0; i < surveyQuestionResponseList.length; i++) {
        					if ($scope.question.objectBaseUuid == surveyQuestionResponseList[i].questionBaseUuid) {
        						$scope.response = surveyQuestionResponseList[i];
        						return;
        					}
        				}
        			}
        			$scope.response={};
            		$scope.response.response="";
            		$scope.response.comments="";
            		$scope.response.responseList=[];
        		}
        		
        	    function saveSurveyQuestionResponse(){
            		var questionString = {
            				'surveyBaseUuid'  	 : $scope.surveyDetails.objectBaseUuid,	
            				'questionBaseUuid'	 : $scope.question.objectBaseUuid,
            				'comments'		  	 : $scope.response.comments,
            				'surveyResponseList' : angular.isUndefined(angular.toJson($scope.response.responseList)) ? "null" : angular.toJson($scope.response.responseList),
            				'surveyResponse'	 : $scope.response.response,
            				'userBaseUuid'	  	 : currentUserVO.objectBaseUuid,
            				'objectBaseUuid'  	 : $scope.question.objectBaseUuid
            		};
        			surveysService.saveSurveyResponseJSON($scope.surveyDetails.objectBaseUuid, questionString.questionBaseUuid, questionString.userBaseUuid, questionString.surveyResponse, questionString.surveyResponseList, questionString.comments).then(function(data){
        				if(!data.boName)
        				{
        					var updateProgressBar = true;
        					for(var i =0;i<surveyQuestionResponseList.length;i++){
        						if(surveyQuestionResponseList[i].questionBaseUuid == questionString.questionBaseUuid){
        							updateProgressBar = false;
        							break;
        						}
        					}
        					if(updateProgressBar == "true" || updateProgressBar == true){
        						$scope.surveyAnsweredQuestions++;
        						$scope.surveyPercentage=($scope.surveyAnsweredQuestions/$scope.surveyTotalQuestions)*100;
        						$scope.section.questionsResponded = $scope.section.questionsResponded+1 ;
        						//Updating progress bar of each section.
                				$scope.questionsCompletedInSection[$scope.section.objectBaseUuid] = Math.round(($scope.section.questionsResponded/$scope.section.questionsCount)*100);
                				$scope.respondedMap[$scope.question.objectBaseUuid] = $scope.response.response;
        					}
        					surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetails.objectBaseUuid, section.objectBaseUuid,currentUserVO.objectBaseUuid).then(function(data){
        					surveyQuestionResponseList = data.surveyQuestionResponseList;
        					$scope.surveySectionResponsesMap[section.objectBaseUuid] = data.surveyQuestionResponseList;
        					$scope.respondedMap[questionString.questionBaseUuid] = questionString.surveyResponse;
        					});
        				}
        			});
        	    }
        	    
        	    function validMandatoryResponseInputs(){
        	    	if(($scope.response.response == undefined || $scope.response.response == "") && ($scope.question.isResponseMandatory == "true" ||$scope.question.isResponseMandatory == true)){
        	    		$('.surveyQuestionSections').addClass('surveyQuestionSectionsError');
        	    		if($('.openAnswerClass').length){
        	    			$('.openAnswerClass').addClass('surveyQuestionSectionsError');
        	    		}
        	    		return false;
        	    	}
        	    	else{
        	    		$('.surveyQuestionSections').removeClass('surveyQuestionSectionsError');
        	    		if($('.openAnswerClass').length){
        	    			$('.openAnswerClass').removeClass('surveyQuestionSectionsError');
        	    		}
        	    	}
        	    	
        	    	if(($scope.response.comments == undefined || $scope.response.comments == "") && ($scope.question.areCommentsMandatory == "true" ||$scope.question.areCommentsMandatory == true)){
        	    		$('.responseComments').addClass('responseCommentsError');
        	    		return false;
        	    	}
        	    	else{
        	    		$('.responseComments').removeClass('responseCommentsError');
        	    	}
        	    }
        	}
        	
        	$scope.getDocumentThumbnail = function(index) {
        		var survey = surveysService.getSurvey();
        		var attachment = survey.attachments[index];
        		if (attachment.documentThumbnail != undefined) {
        			return "data:image/png;base64," + attachment.documentThumbnail;
        		} else {
        			return imageService.getImageByExtension(attachment.extensionType);
        		}
        	}
        	
        	$scope.showSurveyAttachment = function(documentVO) 
            {
                repositoryService.openPdfViewer(documentVO);
        	}
        	
            $scope.resumeSurvey = function(showPreview){
        		$scope.surveyPreview = showPreview;
        		surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetails.objectBaseUuid, $scope.surveyDetails.sections[0].objectBaseUuid,currentUserVO.objectBaseUuid).then(function(data){
        		surveyQuestionResponseList = data.surveyQuestionResponseList;
        			var	activeSection=0;
        			$scope.showSectionDetails($scope.surveyDetails.sections[activeSection], $scope.surveyPreview, activeSection);
        		});
        	};
        	
        	$scope.saveSurvey = function(){
        		if($scope.surveyDetails.isSignatureRequired == true || $scope.surveyDetails.isSignatureRequired == "true"){
        			$scope.signatureModal = $modal.open({
        		        template: '<div class="surveys-signature-outer" ng-click="signIt()">'+
                        '<img name="Pen" class="signature-pen" src="resources/assets/images/BVLiveryInkWell.png">'+
                        '<div class="surveys-signature-inside">'+
                        '<img class="surveys-signature-image" ng-show="isSigned" src="data:image/png;base64,{{signature.value}}" alt="{{ui.label.signature}}">'+
                        '</div>'+
                        '</div>'+
                        '<span><button type="button" class="bv-input-btn bv-default-background-color-blue" ng-click="cancelSignature()">{{ui.label.cancel}}</button></span>'+
                        '<span> <button type="button" class="bv-input-btn bv-default-background-color-blue" ng-click="submitSurvey()">{{ui.label.submit}}</button></span>',
        		        scope:$scope,
        		        windowClass: 'bv-signature-modal'
        		      });
        		}
        		else{
        			$scope.submitSurvey();
        		}
        	}
        	$scope.cancelSignature  = function(){
        		$scope.signatureModal.dismiss('cancel');
        	}
        	$scope.submitSurvey = function() {
        		surveysService.submitSurveyResponseJSON(
        				$scope.surveyDetails.objectBaseUuid, $scope.surveyDetails.isSignatureRequired).then(
        				function(data) {
        					if(data.boName == "SuccessBO"){
        						$scope.userSurveyResponseStatus = true;
        					}
        					if($scope.$parent.fromOpenItems)
		        			{
			        			$scope.$parent.fromOpenItems = false;
			        			$scope.$parent.countItems.value = $scope.$parent.countItems.value -1;
		        				$scope.$parent.compileHtml = null;
		        				for(var i=0; i < $scope.$parent.dashboardList.length; i++)
		        				{
		        					if($scope.$parent.dashboardList[i].objectBaseUuid == $scope.surveyDetails.objectBaseUuid)
		        					{
		        						$scope.$parent.dashboardList.splice(i, 1);
		        					}
		        				}
		        				for(var i=0; i < $scope.$parent.surveyList.length; i++)
		        				{
		        					if($scope.$parent.surveyList[i].objectBaseUuid == $scope.surveyDetails.objectBaseUuid)
		        					{
		        						$scope.$parent.surveyList.splice(i, 1);
		        					}
		        				}
		        				
		        			}
        					if($scope.$parent.fromOpenItems)
		        			{
			        			$scope.$parent.fromOpenItems = false;
			        			$scope.$parent.countItems = $scope.$parent.countItems -1;
		        				$scope.$parent.compileHtml = null;
		        				for(var i=0; i < $scope.$parent.dashboardList.length; i++)
		        				{
		        					if($scope.$parent.dashboardList[i].objectBaseUuid == $scope.surveyDetails.objectBaseUuid)
		        					{
		        						$scope.$parent.dashboardList.splice(i, 1);
		        					}
		        				}
		        				for(var i=0; i < $scope.$parent.surveyList.length; i++)
		        				{
		        					if($scope.$parent.surveyList[i].objectBaseUuid == $scope.surveyDetails.objectBaseUuid)
		        					{
		        						$scope.$parent.surveyList.splice(i, 1);
		        					}
		        				}
		        				
		        			}
        				});
        	}
        	//Printable Reports
        	$scope.printableReports = function(surveyDetails){
        		surveysService.getSurveyResponseForUserJSON($scope.surveyDetails.objectBaseUuid, angular.toJson(surveysService.getQuestionBaseUuidList()), currentUserVO.objectBaseUuid).then(function(data){
        			$scope.userResponsesForPrintableReports={};
        			 for(var i=0;i<data.surveyQuestionResponseList.length;i++){
        				 $scope.userResponsesForPrintableReports[data.surveyQuestionResponseList[i].questionBaseUuid] = data.surveyQuestionResponseList[i].response;
        			 }
        		});
        		$scope.currentUserVO = currentUserVO;
        		$scope.printableReportsModal = $modal.open({
        	        templateUrl: 'modules/surveys/PrintableReports.tpl.html',
        	        size: 'lg',
        	        scope:$scope,
        	        windowClass: 'bv-printable-reports-modal'
        	      });
        		$scope.saveCurrentReport = function(){
        			$scope.forSurveyReport = true;
                	$scope.formType = "FileChooser";
                	$scope.folderName = "Folder";
                	 $scope.attachTitle = "FileChooser";
                	ngDialog.open({
                	    template: 'modules/dialogs/Attachments.tpl.html',
                	    controller: 'AttachmentController',
                	    scope:$scope
                	});
                	$scope.downloadedReportName = surveysService.getSurvey().name;
                $scope.attach = function(selectedNodes){
                		var folderBaseUuid = selectedNodes[0].objectBaseUuid;
                		var buffer = encodeURIComponent('Hello');
                		surveysService.saveSurveyReportJSON(buffer, surveysService.getSurvey().objectBaseUuid, folderBaseUuid, $scope.downloadedReportName).then(function(data){
                			if(data.boName=="SuccessBO" || data.boName=="FailureBO"){
                				ngDialog.close();
                			}
                		}
                	);
                	};
        		};
        		$scope.printCurrentReport = function(){
//        			var printContents = document.getElementsByClassName('printableReportsDIV')[0].innerHTML;
//        		    var mywindow = window.open('', 'Survey Report', 'height=700,width=900');
//        		    mywindow.document.write('<html><head><title>Survey Report</title>');
//        		    mywindow.document.write('</head><body>');
//        		    mywindow.document.write(printContents);
//        		    mywindow.document.write('</body></html>');
//        		    mywindow.print();
//        		    mywindow.close();
//        		    return true;

        		    $(".printableReportsDIV").print(
        		    		{
        		    	globalStyles : false, // Use Global styles
        		    	mediaPrint : true, // Add link with attrbute media=print
//        		    	stylesheet : "resources/css/surveys.css", //Custom stylesheet
        		    	iframe : false //Print in a hidden iframe
//        		    	noPrintSelector : ".avoid-this", // Don't print this
//        		    	styleToAdd:'position:absolute;width:0px;overflow: hidden;height:0px;bottom:0px;text-align:justify;float: none;'
        		    }
        		    		);
        		};
        		$scope.showTextReportBoolean = true;
        		$scope.showTextReport = function(){
        			$scope.showTextReportBoolean = true;
        			$scope.showBarChartReportBoolean = false;
        			$scope.showPieChartReportBoolean = false;
        		};
        		$scope.showBarChartReport = function(){
        			$scope.showBarChartReportBoolean = true;
        			$scope.showTextReportBoolean = false;
        			$scope.showPieChartReportBoolean = false;
        		};
        		$scope.showPieChartReport = function(){
        			$scope.showPieChartReportBoolean = true;
        			$scope.showBarChartReportBoolean = false;
        			$scope.showTextReportBoolean = false;
        		};
        	};
        
        }]
    }
}
angular
	.module('bvSurveyDirective', ['surveys.service', 'localization.service','ui.bootstrap.tpls','ui.bootstrap.carousel','ui.bootstrap.accordion', 'ui.bootstrap.tooltip', 'ui.bootstrap.modal','ui.bootstrap.dropdown','nvd3ChartDirectives', 'ngGrid'])
    .directive('bvOpenSurveyDirective', [SurveyDirective]);