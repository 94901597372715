function bvUserSelector(imageService){
	return {
		restrict: 'A',
		scope: {
		  items: '=',
		  selectionDone: '=',
		  columns: '=',
		  multiSelectFlag: '=',
		  addItem: '=',
		  deleteItem: '=',
		  type: '='
		},
		templateUrl: 'directives/bvuserselector/bvUserSelector.template.html',
		controller: function($scope, localizedMessages) {

			$scope.ui =
		    {
		         label :
		         {
		        	 OK : localizedMessages.get('OK'),
		        	 CANCEL : localizedMessages.get('CANCEL'),
		        	 SELECTED_USERS : localizedMessages.get('SELECTED_USERS'),
		        	 SELECT : localizedMessages.get('SELECT'),
					 	FILTER: localizedMessages.get('FILTER')
		         }
		    };

     		$scope.gridOptions = {
		        enableSorting: true,
		        multiSelect: $scope.multiSelectFlag,
		        selectedItems: [],
 				columnDefs: $scope.columns,
	        	data: 'items',
	        	filterOptions: {filterText: "", userExternalFilter: true}
    			// showFilter: false
		    };
     		console.log($scope);
		    $scope.add = function(item) {
		    	$scope.addItem(item);
		    }

		    $scope.addMulti = function() {
		    	$scope.addItem($scope.gridOptions.selectedItems);
		    	$scope.gridOptions.selectAll(false);
		    }

		    $scope.save = function() {
		    	$scope.selectionDone('Save');
		    }

		    $scope.cancel = function() {
		    	$scope.selectionDone('Cancel');
		    }

		    $scope.deleteUser = function(item) {
		    	$scope.deleteItem(item);
		    }

			$scope.getIconByType = function(item) {
				return imageService.getImageByExtension(item.type);
			}
		}
	}
}