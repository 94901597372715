angular
    .module('dialogControllers.service')
	.controller('OpenMessageMobileController', OpenMessageMobileController);

OpenMessageMobileController.$inject =  ['$scope','$state', 'bvSliderService','$timeout','messagesService','localizedMessages','imageService','$filter','templatesService', 'bvDialogService','repositoryService','homeContext', '$element', 'bvSliderInstanceService','approvalsService', 'surveysService'];

function OpenMessageMobileController($scope, $state, bvSliderService, $timeout, messagesService, localizedMessages, imageService, $filter, templatesService, bvDialogService, repositoryService,homeContext, $element, bvSliderInstanceService, approvalsService, surveysService) {
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.selectedMessage = $scope.bvModalControllerInputs.selectedMessage;
	$scope.popupTitle = $scope.selectedMessage.name;
	$scope.onlineStatus = homeContext.onlineStatus;
	$scope.replyIcon = 'resources/assets/svgs/reply_icon.svg'; 
	$scope.replyallIcon = 'resources/assets/svgs/reply_all_icon.svg';
	$scope.forwardIcon = 'resources/assets/svgs/forward_icon.svg';
	$scope.disableForOffline = MobileUtil.isApp() && MobileUtil.isJupOffline();
	$scope.showMobileMenu = $scope.bvModalControllerInputs.showMobileMenu;
	$scope.mobileMenu = [
  		{ name: localizedMessages.get('REPLY'), click: function(){ composeMessage('reply') }, value:"reply"},
  		{ name: localizedMessages.get('REPLY_ALL'), click: function(){ composeMessage('replyAll') }, value:"replyAll"},
	    { name: localizedMessages.get('FORWARD'), click: function(){ composeMessage('forward') }, value:"forward"} 
    ];

	$scope.ui = { 
					label : {
						attachments : localizedMessages.get("ATTACHMENTS"),
						subject : localizedMessages.get("SUBJECT"),
						to : localizedMessages.get("TO"),
						from: localizedMessages.get("FROM"),
						date: localizedMessages.get("DATE"),
						no_attachments : localizedMessages.get("NO_ATTACHMENTS")
					}
				};

	$scope.getLocalizedDate = function(date) {
		return moment($filter('date')(date, 'medium')).format('lll');
	}

    function bvSliderCreateInstance(objectBaseUuid){

		if(!objectBaseUuid)
			return;

		var bvSliderServ;
		var sliderRenderCallback = function(){
			bvSliderServ = bvSliderInstanceService.instances[sliderOptions.id];
			bvSliderServ.currentIndex = 0;
		}

		var sliderOptions = {
				id : objectBaseUuid,
				items: [],
				slideThrough: 1,
				shownItems: 3,
				smallButtons: false,
				fixQuantity: true,
				renderCallback: sliderRenderCallback
		}

		return sliderOptions;
	}

	function bvSliderGetInstance(objectBaseUuid){

		if(!objectBaseUuid)
			return;

		var bvSliderServ = bvSliderInstanceService.instances[objectBaseUuid];

		return bvSliderServ;

	}

	$scope.inboxSliderOptions = {
			items: [],
			slideThrough: 1,
			shownItems: 3,
			smallButtons: false,
			fixQuantity: true
	}
	
	$scope.inboxSliderOptions.items = $scope.selectedMessage.attachments;

    $scope.getDocumentThumbnail = function(attachment) {
		return imageService.getThumbnailForObject(attachment);
	};
    
    $scope.selectedBox = {};
    
    $scope.getItemDetails = function(selectedItem) 
	{
    	$scope.selectedBox.messageSelected = true;
		$scope.selectedMsgUuid = selectedItem.objectBaseUuid;
		bvSliderService.currentIndex = 0;
		//$scope.threadTransformStyle = {'transform': "translateY(-45px)"};
		
		messagesService.getSharedContainerWithAttachmentsJSON(selectedItem.objectBaseUuid)
		.then(function(response) {
			$scope.selectedBox.message = response.data;
			$scope.inboxSliderOptions.items = new Array();
			$timeout(function(){
				bvSliderService.fixScroll();
		    	bvSliderService.fixQuantity();
		    	bvSliderService.fixButtons();
			});

			if($scope.selectedBox.message.parentShareContainer == null || $scope.selectedBox.message.parentShareContainer == undefined || $scope.selectedBox.message.parentShareContainer.length == 0)
			{
				$scope.showThreads = false;
				$scope.inboxSliderOptions.items.push.apply($scope.inboxSliderOptions.items,$scope.selectedBox.message.attachments);
			} 
			else{
				$scope.selectedBox.message.parentShareContainer.forEach(function(item)
                {
                    item.items = new Array();
                    item.items.push.apply(item.items, item.attachments);

                    item.inboxSliderOptions = bvSliderCreateInstance(item.objectBaseUuid);
                    item.inboxSliderOptions.items = item.attachments;

                    $timeout(function(){
                        if(bvSliderGetInstance(item.objectBaseUuid)){
                            bvSliderGetInstance(item.objectBaseUuid).fixQuantity();
                            bvSliderGetInstance(item.objectBaseUuid).fixButtons();
                        }
                    },100);

                });

				$scope.selectedBox.message.parentShareContainer[0].open = true;
				//$scope.detailHeight = $('.inboxItemsList').height();
				$scope.detailHeight = $element.height() - 60;
				$scope.inboxSliderOptions.items = new Array();
				$scope.inboxSliderOptions.items.push.apply($scope.inboxSliderOptions.items,$scope.selectedBox.message.parentShareContainer[0].items);
//			    $scope.translateVal = 45;
				$scope.showThreads = true;
			}
		});
	};
	$scope.getItemDetails($scope.selectedMessage);
	

	var composeMessage = function(messageType) {
		var bvModalInputs = {}
		bvModalInputs.windowClass = 'bv-mobile-compose-message';
//		bvModalInputs.backdropClass = 'bv-mobile-compose-message-backdrop';
		bvModalInputs.inputs = {backdrop:false};
		bvModalInputs.buttons = null;
		if(messageType != null || messageType != undefined) {
			bvModalInputs.inputs.messageType = messageType;
			if($scope.selectedBox.message != null || $scope.selectedBox.message != undefined) {
				bvModalInputs.inputs.selectedMessage = $scope.selectedBox.message;
				bvModalInputs.inputs.parentShareContainer = $scope.selectedBox.message.parentShareContainer;
			}
		
		} else {
			bvModalInputs.inputs.messageType = "compose";
		}
		
		bvModalInputs.inputs.businessObjects = null;
		bvModalInputs.controller = 'ComposeMessageController';
		bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessageMobile');
		bvModalInputs.inputs.selectedMessage = $scope.selectedMessage;
		bvModalInputs.inputs.secure = $scope.secure;
		bvModalInputs.inputs.mobileActionButton = true;
		bvModalInputs.inputs.mobileActionButtonImagePath = 'resources/assets/images/android_icons_send_white_2x.png';
		if(bvModalInputs.inputs.messageType == "compose") {
			bvModalInputs.inputs.popupTitle = localizedMessages.get("COMPOSE_NEW_MESSAGE");
		}
		else if(bvModalInputs.inputs.messageType == "forward") {
			bvModalInputs.inputs.popupTitle = localizedMessages.get("FORWARD_MESSAGE");
			bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessageMobile');
		}
		else {
			bvModalInputs.inputs.popupTitle =  bvModalInputs.inputs.selectedMessage.name;
			if(bvModalInputs.inputs.popupTitle.substring(0,5) == localizedMessages.get("FWD")) {
				bvModalInputs.inputs.popupTitle = bvModalInputs.inputs.popupTitle.slice(5,bvModalInputs.inputs.popupTitle.length);
			}
			
			if(bvModalInputs.inputs.popupTitle.substring(0,4) != localizedMessages.get("RE")) {
				bvModalInputs.inputs.popupTitle = localizedMessages.get("RE") + bvModalInputs.inputs.popupTitle;
    		}
			//bvModalInputs.inputs.popupTitle = localizedMessages.get("RE") + bvModalInputs.inputs.selectedMessage.name;
		}
		bvDialogService.showBvModal(bvModalInputs);
		bvModal.close();
	};

	$scope.composeMessage = composeMessage;
	$scope.openAttachment = function (attachment,openFromDialog) {
		if(StringUtils.isApprovalPollQuestion(attachment.objectBaseUuid)){
			bvModal.close();
			approvalsService.setApprovalOpenFromInbox(attachment);
			$state.go('home.approvals');					
		}
		else if(StringUtils.isSurvey(attachment.objectBaseUuid)){
			bvModal.close();
			surveysService.setSurveyOpenFromInbox(attachment);
			$state.go('home.surveys');		
		}
		else if(attachment.boName.toLowerCase() == "folderbo") {
			bvDialogService.openFolderView(attachment);
		} else {
			repositoryService.openPdfViewer(attachment,null,openFromDialog);
		}
	}
	
	//$scope.threadTransformStyle = {'transform': "translateY(-45px)"};

	var titleHeight = 65;

	if(MobileUtil.isMobileClient())
        titleHeight = 40;

	$scope.translateVal = titleHeight;

	$scope.open = function(item, index){
	    item.open = !item.open;
	    $scope.inboxSliderOptions.items = new Array();
	    if(item.open) {
			$scope.inboxSliderOptions.items.push.apply($scope.inboxSliderOptions.items,item.items);
	    }
	  //  $scope.translateVal = index*40 + index*5 + 60;
	    $scope.translateVal = (index + 1) * titleHeight;
	    !item.open ? $scope.translateVal = 0 : null;
//	    $scope.threadTransformStyle = {'transform': "translateY(-" + $scope.translateVal + "px)", '-ms-transform': "translateY(-" + $scope.translateVal + "px)"};
//	    $scope.translateStyle = {'transform': 'translateY(-' + $scope.translateVal + 'px)', '-ms-transform': 'translateY(-' + $scope.translateVal + 'px)'};
	    $scope.threadTransformStyle = {'top': '-' + $scope.translateVal + 'px'};
	    $scope.translateStyle = {'top': '-' + $scope.translateVal + 'px'};
	    for(var i=0; i<$scope.selectedBox.message.parentShareContainer.length; i++) {
	    	if(i != index) {
	    		$scope.selectedBox.message.parentShareContainer[i].open = false;
	    	} 
	    }

        $timeout(function(){
            if(bvSliderGetInstance(item.objectBaseUuid)){
                bvSliderGetInstance(item.objectBaseUuid).fixQuantity();
                bvSliderGetInstance(item.objectBaseUuid).fixButtons();
            }
        },100);

	  }
}