configHomeController.$inject = ['$stateProvider', '$locationProvider', 'constantsList', 'ScrollBarsProvider', 'legacyUserServiceProvider'];

function configHomeController($stateProvider, $locationProvider, constantsList, ScrollBarsProvider, legacyUserServiceProvider){
	//Following can be replaced by iterating ViewsEnum map
	//$locationProvider.html5Mode(true);
	//$locationProvider.hashPrefix('!');

	var mobileMenuSidebar = function(homeService){
	
		if(MobileUtil.isPhone()){
			setTimeout(function(){
				if(homeService.homeContext.expandedMenu != undefined && !homeService.homeContext.expandedMenu) homeService.homeContext.expandedMenu = true;
			},0)
		}
	};
	
	var authorize = function($q, $state, $timeout, homeService){

		var isFound = false;
		
		if( homeService.homeContext.modules == undefined ){
			isFound = true;
		}
		else{
		//write the authorization code here
			
		  homeService.setAllStatesOfModules();
		  
		  if(homeService.homeContext.moduleStates == undefined || 
				  $state.transition == undefined || 
				  $state.transition._targetState._identifier == undefined){
			  isFound = true;
		  }
		  else if (homeService.homeContext.moduleStates.includes($state.transition._targetState._identifier) ||
							homeService.homeContext.moduleStates.includes($state.transition._targetState._identifier.name)) {
				isFound = true;
			}
		}
		
	      if (!isFound){
	          $timeout(function() {
	         //  $state.go('home')
	        	  window.location.reload();
	        })
	        return $q.reject()
	      }
	};
	
	var checkGrantCode = function($state, $stateParams) {
		if($stateParams.code != undefined){
			$state.go('home.virtualMeetingConfiguration', {
				zoomAuthCode: $stateParams.code
			});
		}
	}

	var redirectFromTeamsAuth = function($state, $stateParams) {
			$state.go('home.virtualMeetingConfiguration', {
	
			});
	}

 ScrollBarsProvider.defaults = {
		scrollButtons: {
			scrollAmount: 'auto',
			enable: false
		},
		axis: 'y',
		autoHideScrollbar: false,
		theme: 'dark',
		advanced:{
			updateOnContentResize: true
		},
		scrollInertia: 0,
		scrollbarPosition: "outside"
	};

	$stateProvider
//		.state('home.preferences', {
//			url: '/preference',
//			views: {
//			      'content': {
//			    	  templateUrl: 'modules/home/Preferences.tpl.html',
//			    	  controller  : 'HomeController'
//			      }
//			}
//		})
		.state('home.signatures', {
			url: '/signatures',
		 
			 resolve: {
				   mobileMenuSidebar : mobileMenuSidebar,
				   authorize : authorize
				  },
		     
			views: {
			      'content': {
			    	  templateUrl: function(){
			    	 
                        var template = '';
                        
                        if(!legacyUserServiceProvider.isNasdaqUI.enable || MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface()){
                        	template = 'modules/legacyuser/templates/Signatures.tpl.html';
                        }else{
                        	template = 'modules/signatures/Signatures.tpl.html';
                        }
                        return template;
			    	  },
			    	  controller  : 'SignaturesController'
			      }
			}
		})
		.state('home.inbox', {
			url: '/inbox',
			resolve: { mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize },
			
			views: {
			      'content': {
			    	  templateUrl: function(){
                        var template = '';

                        if(MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface()){
                            if(!legacyUserServiceProvider.isNasdaqUI.enable)
                                template = 'modules/legacyuser/mobile/templates/MessagesMobile.tpl.html';
                            else
                                template = 'modules/messages/MessagesMobile.tpl.html';
                        }
                        else{
                            if(!legacyUserServiceProvider.isNasdaqUI.enable)
                                template = 'modules/legacyuser/templates/Messages.tpl.html';
                            else
                                template = 'modules/messages/Messages.tpl.html';
                        }

                        return template;
			    	  },
			    	  controller  : 'MessagesController'
			      }
			}
		})
		.state('home.newitems', {
			url: '/newitems',
			resolve: { mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views: {
			      'content': {
			    	  templateUrl: 'modules/newitems/NewItems.tpl.html',
			    	  controller  : 'NewItemsController'
			      }
			}
		})
		.state('home.calendar', {
			url: '/calendar',
			resolve: { mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views: {
			      'content': {
			    	  //templateUrl: 'modules/calendar/Calendar.tpl.html',
			    	  //controller  : 'CalendarController'
			    	  templateUrl: 'modules/calendar/CalendarLight.tpl.html',
			    	  controller  : 'CalendarControllerLight'
			      }
			}
		})
		.state('home.timeline', {
			url: '/timeline',
			resolve: { mobileMenuSidebar : mobileMenuSidebar ,
				 authorize : authorize},
			
			views: {
			      'content': {
			    	  templateUrl: 'modules/calendar/Timeline.tpl.html',
			    	  controller  : 'CalendarController'
			      }
			}
		})
		.state('home.briefcase', {
			url: '/briefcase',
			resolve: { mobileMenuSidebar : mobileMenuSidebar ,
				 authorize : authorize},
			
			views: {
			      'content': {
			    	  templateUrl: 'modules/repository/Repository.tpl.html',
			    	  controller  : 'FavoritesController'
			      }
			}
		})
		.state('home.newitems_old', {
			url: '/newitems_old',
			resolve: { mobileMenuSidebar : mobileMenuSidebar ,
				 authorize : authorize},
			
			views: {
			      'content': {
			    	  templateUrl: 'modules/repository/Repository.tpl.html',
			    	  controller  : 'RepositoryController'
			      }
			}
		})
		.state('home.documents', {
			url: '/documents/:folderBaseUuid?open',
			params: { folderBaseUuid: null , open: null },
			resolve: {
			    mobileMenuSidebar : mobileMenuSidebar,
			    authorize : authorize,
                openFolder : ['$stateParams', 'repositoryService', 'context', function($stateParams, repositoryService, context) {
                    if($stateParams.folderBaseUuid && context.isReady()){
                        repositoryService.setOpenFolder($stateParams.folderBaseUuid, $stateParams.open);
                    }
                }]
            },
			views: {
			      'content': {
			    	  templateUrl: 'modules/repository/Repository.tpl.html',
			    	  controller  : 'RepositoryController'
			      }
			}
		})
		.state('home.nomodule', {
			url: '/nomodule',
			resolve: { mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views: {
			      'content': {
			      	  //templateUrl: 'modules/home/NoModule.tpl.html',
			    	  //controller  : 'HomeController'
			    	  templateUrl: 'modules/home/NoModule.tpl.html'
			      }
			}
		})
		.state('home.openitems',{
			url:'/openitems',
			resolve: { mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views:{
					'content': {
						templateUrl: 'modules/dashboard/Dashboard.tpl.html',
						controller  : 'DashboardController'
					}
				}
		})
		.state('home.taskmanagement',{
			url: '/taskmanagement',
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views: {
				  'content': {
					  templateUrl: 'modules/taskmanagement/TaskManagement.tpl.html',
					  controller : 'TaskManagementController'
				  }
			}
		})
		.state('home.email',{
			url: '/email',
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views: {
				  'content': {
					  templateUrl: 'modules/email/Email.tpl.html',
					  controller : 'EmailController'
				  }
			}
		})
		.state('home.home',{
			url: '/',
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views: {
				  'content': {
					  templateUrl: 'modules/push/PushDashboard.tpl.html',
					  controller : 'PushController'
				  }
			}
		})
		.state('home.meetingCreation',{
      url: '/meetings/meetingCreation',
      params: {
          objectBaseUuid: null,
          operation: null,
		  day: null,
		  minutesSelected: 'false',
		  loggedUserRole: null
      },
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			
			views: {
				  'content': {
					  templateUrl: 'modules/meetings/creation/creation.tpl.html',
					  controller : 'meetingCreationController'
				  }
			}
		})
		.state('home.manageTemplates',{
			url: '/meetings/manageTemplates',
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
				  views: {
	                  'content': {
                          templateUrl: 'modules/meetings/managetemplates/managetemplates.tpl.html',
                          controller  : 'manageTemplatesController'
	                  }
	            }
		})
		.state('home.createAgendaTemplate',{
			url: '/meetings/createAgendaTemplate',
			params: {operation: null, templateName: "", templateID: null},
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
				  views: {
	                  'content': {
                          templateUrl: 'modules/meetings/createagendatemplate/createagendatemplate.tpl.html',
                          controller  : 'createAgendaTemplateController'
	                  }
	            }
		})
		.state('home.virtualMeetingConfiguration',{
			url: '/meetings/virtualMeetingConfiguration',
			params: {zoomAuthCode: ""},
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				authorize : authorize
			},
			views: {
				'content': {
					templateUrl: 'modules/meetings/virtualMeetings/virtualmeetingsconfig.tpl.html',
					controller  : 'virtualMeetingsConfigController'
				}
			}
		})
		.state('home.zoom', {
			url : '/oauth2/zoom/callback/?code',
			resolve: {mobileMenuSidebar : mobileMenuSidebar, checkGrantCode : checkGrantCode}
		})    
		.state('home.teams', {
			url : '/oauth2/teams/redirect',
			resolve: {mobileMenuSidebar : mobileMenuSidebar, redirectFromTeamsAuth : redirectFromTeamsAuth}
		})
		.state('home.minutesLandingPage',{
			url: '/meetings/minutes',
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				authorize : authorize},
			
			views: {
				'content': {
					templateUrl: 'modules/meetings/minutes/landingpage.tpl.html',
					controller : 'landingPageController'
				}
			}
		})
		.state('home.minutesSettings',{
			url: '/meetings/minutes/settings',
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				authorize : authorize},
			
			views: {
				'content': {
					templateUrl: 'modules/meetings/minutesSettings/settings.tpl.html',
					controller : 'minutesSettingsController'
				}
			}
		})
		.state('home.openMeeting',{
      url: '/meetings/openMeeting',
      params: {
        objectBaseUuid: null,
      },
			resolve: {mobileMenuSidebar : mobileMenuSidebar,
				 authorize : authorize},
			views: {
				  'content': {
					  templateUrl: 'modules/calendar/MeetingCards.tpl.html',
					  controller : 'CalendarController'
				  }
      }
		})
}
