angular.module('meetingAlertService.factory', [])
		.factory('meetingAlertService', meetingAlertService);

meetingAlertService.$inject = ['localizedMessages','bvDialog', 'templatesService', 'bvDialogService', 'calendarService'];

function meetingAlertService(localizedMessages, bvDialog, templatesService, bvDialogService, calendarService) 
{
	var meeting = null;
	var selectedUsersToAlert = [];
	var alertDlg = null;
	var showComposeDialog = false;

	var service = {
		openAlertDialog : openAlertDialog,
		openComposeAlertWindow : openComposeAlertWindow,
		meeting: meeting,
		alertDlg : alertDlg,
		userSelectionDone: userSelectionDone,
		selectedUsersToAlert : selectedUsersToAlert,
		cancelAlertAction : cancelAlertAction,
		closeAlertDlg : closeAlertDlg,
		showComposeDialog : showComposeDialog
	}

	return service;

	function openAlertDialog() {
		var name = service.meeting.name;
		var users = service.meeting.guests;
		console.log(service.meeting);
	    
	    var cancelSelectionForAlertMsg = localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION');
	    	service.alertDlg = bvDialog.showAlertUsers(
	    			localizedMessages.get('ALERT') + ": " + name,
	    			localizedMessages.get('USERS'), 
	    			users, 
	    			service.userSelectionDone, 
	    			localizedMessages.get('CANCEL'), 
	    			localizedMessages.get('SEND'), 
	    			cancelSelectionForAlertMsg, 
	    			service.openComposeAlertWindow, 
	    			service.cancelAlertAction
	    		); 
	}

	function closeAlertDlg() {
		service.alertDlg.close();
	}

	function userSelectionDone (selectedUsersToAlert)
    {
    	service.selectedUsersToAlert = selectedUsersToAlert;
    }

	function cancelAlertAction()
	{
		if(service.selectedUsersToAlert.length > 0)
		{
			bvDialog.confirm(localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION'), service.closeAlertDlg, null);
		}
		else
		{
			service.closeAlertDlg();
		}
	}

	function openComposeAlertWindow() 
	{
		var selectedUsers = service.selectedUsersToAlert;
		if(service.showComposeDialog == false){    // If compose message dialog need not be opened.
			var userIds = [];
			var name = service.meeting.title != undefined ? service.meeting.title : service.meeting.name;
			if(selectedUsers && selectedUsers.length !=0 && selectedUsers != undefined && selectedUsers != null) {
				for(var i=0;i<selectedUsers.length;i++){
					userIds.push(selectedUsers[i].objectBaseUuid);
				}
			}
			else {
				bvDialog.showMessage(localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER'));
			}      
			calendarService.sendEventAlert(service.meeting.objectBaseUuid,angular.toJson(userIds));
		}
		else if(service.showComposeDialog == true){  // If compose message dialog has to be shown.
			if(selectedUsers && selectedUsers.length !=0 && selectedUsers != undefined && selectedUsers != null) {
				service.closeAlertDlg();
				var bvModalInputs = {}
				bvModalInputs.windowClass = 'bv-modal-window-compose-new-message';
				bvModalInputs.inputs = {};
				bvModalInputs.buttons = [{},{}];
				bvModalInputs.inputs.messageType = 'alert_meeting';
				bvModalInputs.inputs.popupTitle = localizedMessages.get("ALERT");
				bvModalInputs.inputs.sharedWithList = selectedUsers;
				bvModalInputs.inputs.name = localizedMessages.get("MEETING_ALERT_SUBJECT");
				bvModalInputs.inputs.comments = localizedMessages.get("MEETING_ALERT_COMMENTS") + " - " + name;
				bvModalInputs.inputs.secure = true;
				bvModalInputs.inputs.alwaysSecure = true;
				bvModalInputs.controller = 'ComposeMessageController';
				bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');
				bvDialogService.showBvModal(bvModalInputs);
				console.log(bvModalInputs.buttons);
			}
			else {
				bvDialog.showMessage(localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER'));
			}
		}
	};
}