function bvScrollListService(){
	var objectData = {}

	var service = {
		setData : function(data){
			objectData = data;
		},
		getData : function(){
			var left = 0;
			for(var i = 0 ; i < objectData.columns.length ; i ++){
				if(i != 0){
					left = left + objectData.columns[i - 1].width;
					objectData.columns[i].left = left;
				}
			}
			return objectData;
		}
	}

	return service;
}