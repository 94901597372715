angular
	.module('approvals.service',['ajax.service', 'localization.service', 'approvalAlertService.factory'])
	.factory('approvalsService', approvalsService)
	
approvalsService.$inject = ['$q', 'ajaxService', 'responseValidatorService'];

function approvalsService($q, ajaxService, responseValidatorService) {
	var templateUrl = {};
	var selectedApprovalUuid;
	var fromOpenItems = false;
	var currentSelectedApprovalFromList;
	var approvalOptions;
	var isApprovalResponseDirty = false;
	var selectedTab = 0;
	var isCurrentUserAllowedToApprovals = false;
	var approvalOpenFromInbox;

	var service = {
		templateUrl: function(update) {
			if(update){
				templateUrl.val = update;
			}
			return templateUrl.val;
		},
		setIsCurrentUserAllowedToCreateModule : function(isCurrentUserAllowedToCreateModule){
			this.isCurrentUserAllowedToApprovals = isCurrentUserAllowedToCreateModule;
		},
		setApprovalOpenFromInbox : function(approvalOpenFromInbox){
			this.approvalOpenFromInbox = approvalOpenFromInbox;
		},
		
		getApprovalOpenFromInbox : function(){
			return this.approvalOpenFromInbox;
		},

		isCurrentUserAllowedToCreateModule : function(){
			return this.isCurrentUserAllowedToApprovals;
		},
		getMyOpenApprovalsJSON: function() {
			var request=ajaxService.getMyOpenApprovalsJSON();
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		getMyClosedApprovalsJSON: function() {
			var request=ajaxService.getMyClosedApprovalsJSON();
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		getMyOwnedApprovalsJSON: function() {
			var request=ajaxService.getMyOwnedApprovalsJSON();
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},getMyApprovalsJSON: function(approvalInput,page,pageSize,sortColumn,sortDirection) {
			var request=ajaxService.getMyApprovalsJSON(approvalInput,page,pageSize,sortColumn,sortDirection);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		
		getApprovalDetailJSON :function(baseUuid) {
			var request=ajaxService.getApprovalDetailJSON(baseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		getApprovalCatagoriesJSON: function(baseUuid) {
			var request=ajaxService.getApprovalCatagoriesJSON(baseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});

		},
		getMySignature: function() {
			var request=ajaxService.getMySignature();
			return request
			.then(function(data) {
				return data.data;
			}, function(data) {
				return null;
			});
		},
		createApprovalResponse: function(approvalObjectBaseUuid,responseObjectBaseUuid,choice,comments,signed) {
			var request=ajaxService.createApprovalResponse(approvalObjectBaseUuid,responseObjectBaseUuid,choice,comments,signed);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		createNewApproval : function(approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList){
			var request = ajaxService.createNewApproval(approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList);
			return request.then(function(data){
				return data.data;
			});
			return "";
		},
		updateApproval : function(approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList){
			var request = ajaxService.updateApproval(approval, participants, approvalOwners, approvalViewers, dueDate, attachmentUuidList);
			return request.then(function(data){
				return data.data;
			});
			return "";
		},
		setCurrentSelectedApprovalFromList : function(approval) {
			currentSelectedApprovalFromList = approval;
		},
		getCurrentSelectedApprovalFromList : function() {
			return currentSelectedApprovalFromList;
		},
		setSelectedTab : function(tab){
			selectedTab = tab;
		},
		getSelectedTab : function() {
			return selectedTab;
		},
		deleteApproval: function(approvalObjectBaseUuid) {
			var request=ajaxService.deleteApproval(approvalObjectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		closeApproval : function(approvalObjectBaseUuid){
			var request=ajaxService.closeApproval(approvalObjectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		reopenApproval : function(approvalObjectBaseUuid){
			var request=ajaxService.reopenApproval(approvalObjectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		
		saveApprovalsChanges: function(approvalsIdsForAddition, userIdsForAddition, approvalUserListForRemoval, inactiveApprovals, activeApprovals){
			var request = ajaxService.saveApprovalsChanges(approvalsIdsForAddition, userIdsForAddition, approvalUserListForRemoval, inactiveApprovals, activeApprovals);
			return request.then(function(data){
				if(data){
					return data;
				}
				return null;
			});
		},
		createAttendeeList : function(approval, approvalDetails){
			var attendeeList = [];
			if(approval.attendeeList && approval.attendeeList.length)
				attendeeList = approval.attendeeList;
			
			var Attendee = function(objectBaseUuid, name, isRespondent, isOwner, isViewer){
				return {
					objectBaseUuid: objectBaseUuid,
					name: name,
					isRespondent : isRespondent,
					isOwner : isOwner,
					isViewer : isViewer
				}
			}
			
			if(approvalDetails){
				if(approvalDetails.ownerList && approvalDetails.ownerList.length){
					_.each(approvalDetails.ownerList, function(item){
						var existingAttendee = _.find(attendeeList, { objectBaseUuid : item.objectBaseUuid });
						if(existingAttendee){
							existingAttendee.isOwner = true;
						}
						else{
							attendeeList.push(new Attendee(item.objectBaseUuid, item.name, false, true, false));
						}
					});
				}
				if(approvalDetails.respondentList && approvalDetails.respondentList.length){
					_.each(approvalDetails.respondentList, function(item){
						var existingAttendee = _.find(attendeeList, { objectBaseUuid : item.objectBaseUuid });
						if(existingAttendee){
							existingAttendee.isRespondent = true;
						}
						else{
							attendeeList.push(new Attendee(item.objectBaseUuid, item.name, true, false, false));
						}
					});
				}
				if(approvalDetails.viewerList && approvalDetails.viewerList.length){
					_.each(approvalDetails.viewerList, function(item){
						var existingAttendee = _.find(attendeeList, { objectBaseUuid : item.objectBaseUuid });
						if(existingAttendee){
							existingAttendee.isViewer = true;
						}
						else{
							attendeeList.push(new Attendee(item.objectBaseUuid, item.name, false, false, true));
						}
					});
				}
			}
			
			approval.attendeeList = attendeeList;
			
			return approval;
		}
	
	}
	return service;
}
