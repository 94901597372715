bvScrollListController.$inject = ['$scope', '$element', '$attrs', 'bvScrollListService'];

function bvScrollListController($scope, $element, $attrs, bvScrollListService){

	bvScrollListService.setData($scope.data);
	bvScrollListService.getData();

	$scope.data.checkBoxClick = function(column, item){
		$scope.data.data.forEach(function(tableItem){
			if(!tableItem[column.rows.map + 'Disabled']){
				tableItem[column.rows.map] = item.checked;
			}
		});

		if('click' in column){
			column.click(item);
		}
	}

}