angular
    .module('dialogControllers.service')
	.controller('annotationsListController',  annotationsListController);

annotationsListController.$inject = ['$scope', 'localizedMessages','repositoryService', 'bvDialogMobile','bvDialog','context'];

function annotationsListController($scope, localizedMessages, repositoryService, bvDialogMobile, bvDialog, context){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.popupTitle = localizedMessages.get("ANNOTATIONS");
	$scope.rightArrow = "resources/assets/svgs/arrow.svg";
	$scope.pinAnnotationImage = "resources/assets/images/android_icons_pin-annotation_2x.png";
	$scope.commentAnnotationImage = "resources/assets/images/android_icons_comment-annotation_2x.png";
	$scope.drawingAnnotationImage = "resources/assets/images/android_icons_edit-comment_2x.png";
	
	$scope.pdf = $scope.bvModalControllerInputs.pdfDetails;
	$scope.documentDrawingAnnotationMap = $scope.bvModalControllerInputs.documentDrawingAnnotationMap;
	$scope.documentTextAnnotationMap = $scope.bvModalControllerInputs.documentTextAnnotationMap;
	$scope.documentPinAnnotationMap = $scope.bvModalControllerInputs.documentPinAnnotationMap;

	$scope.byPageLabel = localizedMessages.get("BY_PAGE");
	$scope.byUserLabel = localizedMessages.get("BY_USER");
	$scope.pageLabel = localizedMessages.get("PAGE");
	$scope.dropPinLabel = localizedMessages.get("DROP_PIN");
	$scope.privateLabel = localizedMessages.get("PRIVATE");
	$scope.sharedLabel = localizedMessages.get("SHARED");
	$scope.drawingLabel = localizedMessages.get("DRAWING");

	$scope.showByPage = false;
	$scope.showByUser = false;

	$scope.forPages = function() {
		return new Array($scope.pdf.numPages+1)
	}

	$scope.byPageClicked = function() {
		$scope.showByPage = true;
		$scope.showByUser = false;

	}

	$scope.byUserClicked = function() {
		$scope.showByUser = true;
		$scope.showByPage = false;

	}

	$scope.back = function() {
		if($scope.showByPage || $scope.showByUser) {
			$scope.showByPage = false;
			$scope.showByUser = false;
		}
		else 
		{
			if($scope.pdf.isAndroid)
			{
				setTimeout(function(){
					Android.showHideViewerToolbar(true);
				}, 50);
			}
			bvModal.close();
		}
	}

//for showing by user
// As of now we have only private annotations so its going to be for only one user, so not creating unique user-annotation map
	var totalAnnotations = 0;
	var user = "";
	for (var i = 0; i <= $scope.pdf.numPages; i++) {
		if($scope.documentDrawingAnnotationMap[i+1])
			totalAnnotations += Object.keys($scope.documentDrawingAnnotationMap[i+1]).length;

		if($scope.documentTextAnnotationMap[i+1])
			totalAnnotations += Object.keys($scope.documentTextAnnotationMap[i+1]).length;

		if($scope.documentPinAnnotationMap[i+1])
			totalAnnotations += Object.keys($scope.documentPinAnnotationMap[i+1]).length;
	}

	$scope.uniqueUsersAnnotations = [{"user" : context.getModel().userVO.displayName, "count" : totalAnnotations}];
	
	$scope.getLocalizedDate = function(gmtMilli) {
		return moment(gmtMilli).format('ll');
	}
}