virtualMeetingsConfigController.$inject = ['$scope', '$state', '$transitions', 'homeService','context','reactInterface', 'bvDialog', '$stateParams', 'localizedMessages', 'virtualMeetingCongifService'];

function virtualMeetingsConfigController($scope, $state, $transitions, homeService, context, reactInterface, bvDialog, $stateParams, localizedMessages, virtualMeetingCongifService){
    $scope.isVirtualMeetingEnabled = context.getModel().companyVO.enableVirtualMeetings;
	$scope.isTeamsVirtualMeetingEnabled = context.getModel().companyVO.enableTeamsVirtualMeetings;
    
    var zoomRedirectURL = window.location.origin+"/html/%23/home/oauth2/zoom/callback/";
    var zoomOauthURL = "https://zoom.us/oauth/authorize?response_type=code&client_id="+context.getModel().companyVO.zoomOauthClientId+"&redirect_uri="+zoomRedirectURL;

    $scope.msGraphOauthClientId = context.getModel().companyVO.msGraphOauthClientId;
    $scope.msGraphOauthScope = context.getModel().companyVO.msGraphOauthScope;
    $scope.msGraphConnectionStatus = false;

    $scope.zoomUserInfo = "";
    $scope.msGraphUserInfo = "";
    $scope.zoomConnectionStatus = false;
    
    if($stateParams.zoomAuthCode && $stateParams.zoomAuthCode != ""){
        virtualMeetingCongifService.requestZoomOauthAccessToken($stateParams.zoomAuthCode, zoomRedirectURL).then(function(response) {
            if(response){
                $scope.zoomUserInfo = response;
                $scope.zoomConnectionStatus = response.status === "active" ? true : false;
            }
        });
    }else{
        virtualMeetingCongifService.getZoomUserInfo().then(function(response) {
            if(response){
                $scope.zoomUserInfo = response;
                $scope.zoomConnectionStatus = response.status === "active" ? true : false;
            }
        });
    }

    virtualMeetingCongifService.getMsGraphUserInfo().then(function(response) {
        if (response) {
            $scope.msGraphConnectionStatus = response.status === 'active';
            $scope.msGraphUserInfo = response;
        }
    });

    $scope.middleBarObject.showBack = true;
    $scope.middleBarObject.title = localizedMessages.get("VIRTUAL_MEETING_CONFIGURATION")
	$scope.tabs = [{
        title : localizedMessages.get("MANAGE_TEMPLATES"),
        content : 'manageTemplates',
        active: true,
        rightMenuList:[]
    }];
    $scope.handleLeftIcon = function(){
        $state.go('home.meetings');
	}
	$scope.connectZoom = function(){
        window.location.href = zoomOauthURL;
    }
    $scope.disconnectZoom = function(){
    // Disconnect Zoom code go here
        virtualMeetingCongifService.disconnectZoom().then(function(response) {
            if(response){
                $scope.zoomUserInfo = "";
                $scope.zoomConnectionStatus = response.status === "active" ? true : false;
            }});
    }
    $scope.connectTeams = function() {
         //TODO: Change to the appropriate server endpoint after integrating
        var msRedirectURL = window.location.origin + "/login/teamsOauthCallback";
        //var msRedirectURL = "http://localhost:8080/login/teamsOauthCallback"; // for localhost usage
        var msOauthURL = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${$scope.msGraphOauthClientId}&scope=${$scope.msGraphOauthScope}&response_type=code&redirect_uri=${msRedirectURL}`;
        window.location.href = msOauthURL;
    }
    $scope.disconnectGraph = function() {
        virtualMeetingCongifService.disconnectGraph().then(function(response) {
            if (response) {
                $scope.msGraphConnectionStatus = response.status === 'active';
            }
        });
    }
}

