UrlService.$inject = ['$location', 'context'];

function UrlService($location, context)
{

	this.getBaseURL = function()
	{
		return $location.protocol() + "://" + $location.host() + ":" + $location.port() + "/html";
	}

	this.getResourceLinkURL = function(resource)
	{
		return (resource)? this.getBaseURL() + "/rh?resourceid="+btoa(resource) : "";
	}

	this.geCopyLinkURL = function(resource)
	{
		//console.log("CONTEXT:",context.getModel());
		var companyPermaLink = context.getModel().companyPermaLink;
		companyPermaLink = (companyPermaLink) ? (companyPermaLink.replace("portlet/home.jsp?html5=true&plink=","html/#/rh")) : "" ;
		//console.log("COMPANY PERMALINK: ",companyPermaLink);
		return companyPermaLink + "?resourceid=" + btoa(resource);
		// return (resource)? this.getBaseURL() + "/rh?resourceid="+btoa(resource) : "";
	}
	
	this.geCopyLinkOriginalURL = function(resource)
	{
		//console.log("CONTEXT:",context.getModel());
		var companyPermaLink = context.getModel().companyPermaLink;
		companyPermaLink = (companyPermaLink) ? (companyPermaLink.replace("portlet/home.jsp?html5=true&plink=","portlet/home.jsp")) : "" ;
		//console.log("COMPANY PERMALINK: ",companyPermaLink);
		return companyPermaLink + "?resourceid=" + btoa(resource);
		// return (resource)? this.getBaseURL() + "/rh?resourceid="+btoa(resource) : "";
	}

}