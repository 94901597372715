bvselectionModel.$inject = ['selectionStack','$parse'];

function bvselectionModel (selectionStack,$parse) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {

	    	element.on('click', handleClick);

	    	var currentSelectedIndex = scope.$eval(attrs.selectedIndex);

	    	var dataModel = scope.$eval(attrs.bvselectionModel);

	    	var enableElement = attrs.selectionEnableElement;

	    	selectionStack.setLastObject(undefined);

	    	function handleClick(event)
	    	{
	    		scope.$apply(function()
	    		{
	    			  var checkBoxClickID = event.target.id;
	    			  var checkBoxClick = false;

	    			  if(!StringUtils.isNullEmpty(checkBoxClickID) && checkBoxClickID.indexOf('bv-repository-checkbox') != -1)
    				  {
	    				  checkBoxClick = true;
    				  }
	    			  else if(event.target.parentNode != null && event.target.parentNode.parentElement != null && event.target.parentNode.parentElement.id.indexOf('bv-repository-checkbox') != -1)
    				  {
	    				  checkBoxClick = true;
    				  }

		    		  var isCtrlKeyDown = event.ctrlKey || event.metaKey || checkBoxClick;
		              var isShiftKeyDown = event.shiftKey;
		              var selectedObject =  scope[attrs.selectedItem];

		              var parentSelected = scope.$parent.contextMenuSelectedIndex;

		  	    	  if(!StringUtils.isNullEmpty(parentSelected) && parentSelected != -1)
		      		  {
		  	    		 selectionStack.setLastObject(parentSelected);
		      		  }

		              var lastIndex = selectionStack.getLastSelectedObject();

		              // Select multiple allows for ranges - use shift key
		              if(isShiftKeyDown)
		              {
		            	  selectionStack.setLastObject(currentSelectedIndex);
		            	  deselectAllItemsExcept();
		            	  selectItemsBetween(lastIndex);
		              }
		              else if(isCtrlKeyDown)
		        	  {
		            	  scope.$parent.contextMenuSelectedIndex = -1;
		            	  selectedObject.selected = !selectedObject.selected;
								selectionStack.updateSelectedItems(selectedObject);
		            	  selectionStack.setLastObject(currentSelectedIndex);
		            	 // selectedItemsList.push(scope.document);
		        	  }
//		        	  else
//	        		  {
//		        		  deselectAllItemsExcept(dataModel[currentSelectedIndex]);
//	        		  }
	    		})
	    	};


		    var selectItemsBetween = function (lastSelectedIndex)
	        {
		    	dataModel = scope.$eval(attrs.bvselectionModel);

	        	if(lastSelectedIndex > currentSelectedIndex)
	    		{
	        		var i = lastSelectedIndex;

	        		while( i  >= currentSelectedIndex)
	    			{
	        			console.log(dataModel[i].name);
	        			dataModel[i].selected = true;
						selectionStack.updateSelectedItems(dataModel[i]);
	        			i--;
	    			}
	    		}
	    		else
	    		{
		        	for (var i = lastSelectedIndex; i < currentSelectedIndex+1; i++)
		        	{
		        		dataModel[i].selected = true;
						selectionStack.updateSelectedItems(dataModel[i]);
		        		console.log(dataModel[i].name);
		        	}
		        }

	        };

	        var deselectAllItemsExcept = function(except)
	        {
        		angular.forEach(dataModel, function(item, i)
        		{
        			if(except == null || except.objectBaseUuid !=  item.objectBaseUuid)
        				item.selected = false;
						selectionStack.updateSelectedItems(dataModel[i]);

                });
	        };

//	        var is = function(except)
//	        {
//        		angular.forEach(dataModel, function(item)
//        		{
//        			if(except == null || except[objectBaseUuid] !=  item[objectBaseUuid])
//        				item.selected = false;
//						selectionStack.updateSelectedItems(dataModel[item]);
//
//                });
//	        };


    	}
    }
}
