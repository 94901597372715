function addFolderDialog() {
    return {
        restrict: 'A',
        scope:
        {
              selectionDone 		: '=',
              cancelDone			: '=',
              addFolderTitle		: '@',
              addFolderPlaceholder	: '@'
        },
        templateUrl: 'directives/addfolder/addfolder.template.html',
        controller: 'AddFolderController'
    }
}
