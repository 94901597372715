angular.module('bvconfpdfview.module', [])
.directive('bvConfPdfView', function() {

	var canvas = null;
	var instance_id = null;

	return {
		restrict: 'E',
		scope: 
		{
	      	onPageLoad: '&',
			loadProgress: '&',
			src: '@',
			id: '='
	    },
		
		template: '<canvas></canvas>',
		
		controller: function($scope) 
		{
			$scope.pageNum = 1;
			$scope.pdfDoc = null;
			$scope.scale = 1.0;
			
			$scope.documentProgress = function(progressData) 
			{
				if ($scope.loadProgress) {
					$scope.loadProgress({state: "loading", loaded: progressData.loaded, total: progressData.total});
				}
			};

			$scope.loadPDF = function(path)
			{
				//console.log('loadPDF ', path);
				$scope.pageNum = 1;
				$scope.pdfDoc = null;
				$scope.scale = 1.0;
				PDFJS.getDocument(path, null, null, $scope.documentProgress).then(function(_pdfDoc) 
				{
					$scope.pdfDoc = _pdfDoc;
					$scope.renderPage($scope.pageNum, function(success) {
						if ($scope.loadProgress) {
							$scope.loadProgress({state: "finished", loaded: 0, total: 0});
						}
					});
				}, function(message, exception)
				{
					console.log("PDF load error: " + message);
					if ($scope.loadProgress) {
						$scope.loadProgress({state: "error", loaded: 0, total: 0});
					}
				});
			};

			$scope.renderPage = function(num, callback) 
			{
				//console.log('renderPage ', num);
				$scope.pdfDoc.getPage(num).then(function(page) 
				{
					var viewport = page.getViewport($scope.scale);
					var ctx = canvas.getContext('2d');
					canvas.height = viewport.height;
					canvas.width = viewport.width;
					page.render({ canvasContext: ctx, viewport: viewport }).promise.then(
						function()
						{
							if (callback) 
							{
								callback(true);
							}
							$scope.$apply(function() 
							{
								$scope.onPageLoad({ page: $scope.pageNum, total: $scope.pdfDoc.numPages });
							});
						},
						function() {
							if (callback) {
								callback(false);
							}
							console.log('page.render failed');
						}
					);
				});
			};

			$scope.$on('bvConfPdfView.loadPdfForPresentation', function(evt, id, path) 
			{
				if (id !== instance_id) 
				{
					return;
				}
				else if (path!=null && path!=undefined && path!="") 
				{
					$scope.loadPDF(path);
				}
				else
				{
					return;
				}
			});

			$scope.$on('bvConfPdfView.nextPage', function(evt, id) 
			{
				if (id !== instance_id) 
				{
					return;
				}
				if ($scope.pageNum < $scope.pdfDoc.numPages) 
				{
					$scope.pageNum++;
					//console.log('renderPage ', $scope.pageNum);
					$scope.renderPage($scope.pageNum);
				}
			});
			
			$scope.$on('bvConfPdfView.prevPage', function(evt, id) {
				if (id !== instance_id) 
				{
					return;
				}
				if ($scope.pageNum > 1) 
				{
					$scope.pageNum--;
					$scope.renderPage($scope.pageNum);
				}
			});
		
			$scope.$on('bvConfPdfView.gotoPage', function(evt, id, page) 
			{
				if (id !== instance_id) {
					return;
				}
				if (page >= 1 && page <= $scope.pdfDoc.numPages) {
					$scope.pageNum = page;
					$scope.renderPage($scope.pageNum);
				}
			});
		  
		},
		
		link: function(scope, iElement, iAttr) 
		{
			canvas = iElement.find('canvas')[0];
			instance_id = iAttr.id;
			iAttr.$observe('src', function(v) 
			{
				//console.log('src attribute changed, new value is', v);
				if (v !== undefined && v !== null && v !== '') 
				{
					scope.pageNum = 1;
					scope.loadPDF(scope.src);
				}
			});
		}
	};
})
.service("BvConfPdfViewerService", [ '$rootScope', function($rootScope) {

	var bvConfPdfViewerService = { };
	
	// bvConfPdfViewerService.nextPage = function() 
	// {
	// 	$rootScope.$broadcast('bvConfPdfView.nextPage');
	// };
	
	// bvConfPdfViewerService.prevPage = function() 
	// {
	// 	$rootScope.$broadcast('bvConfPdfView.prevPage');
	// };

	bvConfPdfViewerService.Instance = function(id) 
	{
		var instance_id = id;
		return {
			prevPage: function() 
			{
				$rootScope.$broadcast('bvConfPdfView.prevPage', instance_id);
			},
			nextPage: function() 
			{
				$rootScope.$broadcast('bvConfPdfView.nextPage', instance_id);
			},
			gotoPage: function(page) 
			{
				$rootScope.$broadcast('bvConfPdfView.gotoPage', instance_id, page);
			},
			loadPdfForPresentation: function(path) 
			{
				$rootScope.$broadcast('bvConfPdfView.loadPdfForPresentation', instance_id, path);
			}
		};
	};

	return bvConfPdfViewerService;
}]);