function RepositoryController($rootScope, $scope, repositoryService, localizedMessages, ipadService, bvDialogService, $state, SortUtility, modal, treeService, context, documentContextMenu, permissionService, bvPollingProcessor, registerStatusCategory, repositoryModelService, CopyPasteService, backUtiltiyService, collateModelService, bvDialog, contextMenuHandler, homeModel, legacyUserService)
{
	repositoryModelService.getInstance("DOCUMENTS").repositoryList([]);
	$scope.mobileRepositoryClicked = true;
	$scope.localizedMessages = localizedMessages;
	ipadService.touchContent();
	$scope.svgFill = '#FFF';
	$scope.currentInstance = "DOCUMENTS";
	$scope.repositoryDisplayObjects = {showTree:false, HIDE_CARET:false};
	$scope.reverseSort = false;
	$scope.nameToggle = false;
	$scope.typeToggle = false;
	$scope.modifiedToggle = false;

	var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
	var userTreeViewPref = localStorage.getItem(userTreeViewPrefKey);
	//console.log("USER TREE PREF:",userTreeViewPref);

	if(!userTreeViewPref)
	{
		userTreeViewPref = "MEDIUM";
		localStorage.setItem( userTreeViewPrefKey, userTreeViewPref);
	}

	$scope.repositoryDisplayObjects.displayType = userTreeViewPref;

	var backUtiltiy  = backUtiltiyService.getInstance($scope.currentInstance);
	if($scope.$parent.currentState)
		$scope.$parent.currentState.value = $state.$current.name;

	var treeSelectionModel = treeService.getInstance($scope.currentInstance);
	var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

	var showTreeValue = localStorage.getItem('SHOW_TREE_FOR_REPOSITORY');

    if(!$scope.userAgentMobileClient && (StringUtils.isNullEmpty(showTreeValue) || showTreeValue == "true"))
    {
    	$scope.repositoryDisplayObjects.showTree = true;
    }
    else
    {
    	$scope.repositoryDisplayObjects.HIDE_CARET = true;
    }

	


    var getDisplayOptionsForRepository = function()
	{
		/*if($scope.repositoryDisplayObjects.showTree == undefined)
			$scope.repositoryDisplayObjects.showTree = true;

		if($scope.repositoryDisplayObjects.showTree == true)
			return [{name:"HideTree", value:"hideTreeOption"},{name:"Small", value:"small"},{name:"Medium", value:"medium"},{name:"Large", value:"large"}];
		else
			return [{name:"ShowTree", value:"showTreeOption"},{name:"Small", value:"small"},{name:"Medium", value:"medium"},{name:"Large", value:"large"}];
		*/
		/*
		Small Text, Medium Text, Large Text, "Sort by Name", "Sort by Date")
		*/
		var options = [];
		if($scope.repositoryDisplayObjects.showTree) {
			options.push({name:localizedMessages.get("HIDE_TREE"), value: "hideTreeOption"});
		} else {
			options.push({name:localizedMessages.get("SHOW_TREE"), value: "showTreeOption"});
		}
		options.push({name:localizedMessages.get("SMALL_TEXT"), value: "small"});
		options.push({name:localizedMessages.get("MEDIUM_TEXT"), value: "medium"});
		options.push({name:localizedMessages.get("LARGE_TEXT"), value: "large"});
		options.push({name:localizedMessages.get("SORT_BY_NAME"), value: "name"});
		options.push({name:localizedMessages.get("SORT_BY_TYPE"), value: "extensionType"});
		options.push({name:localizedMessages.get("SORT_BY_DATE_MODIFIED"), value: "lastModifiedOnGMTMillis"});
		options.push({name:localizedMessages.get("SORT_BY_MODIFIED_BY"), value: "createdBy"});
		
		if(context.getModel().enableIncrementalFetchOnUI){
			if(!context.getModel().incrementalFetchEnabledForUser){
				options.push({name:localizedMessages.get("ENABLE_INCREMENTAL_FETCH"), value: "enableincrementalFetch"});
			} else {
				options.push({name:localizedMessages.get("DISABLE_INCREMENTAL_FETCH"), value: "disableincrementalFetch"});
			}
		}

		return options;

		//var defaultSortByItem = [{name:localizedMessages.get('NAME'), value:"name"},{name:localizedMessages.get('DATE_MODIFIED'), value:"lastModifiedOnGMTMillis"}, {name:localizedMessages.get('TYPE'), value:"extensionType"}];
	}

	var uploadFile = function()
    {
    	$scope.listofUploadFiles = [];
    	if(!legacyUserService.isNasdaqUI) {
    		openDialogForUpload();
    	}
    };

    var uploadFolder = function()
    {
    	contextMenuHandler.uploadFolder(treeSelectionModel.getSelectedNode().objectBaseUuid);
    };

    $scope.callParentController = function(type, args)
    {
    	switch (type)
    	{
			case 'COLLATE':
				if( (Object.prototype.toString.call( args[0] )=== "[object Array]"))
					createCollate(args[0]);
				else
					createCollate([args[0]]);
				break;
			case 'EDIT_COLLATE':
				editCollate(args[0], "collate");
				break;
            case 'BOARDBOOK':
                createBoardbook(args[0]);
                break;
            case 'EDIT_BOARDBOOK':
                editCollate(args[0], "boardbook");
                break;
            case 'SHOW_HIDE_FILE_FOLDER':
                showHideFileFolder(args[0]);
                break;
			default:
				break;
		}

		//$scope.clearSelection(args[0]);
    }

    var editCollate = function(collate, type)
    {
    	var collateDisplayObject = collateModelService.getInstance();
    	collateDisplayObject.parentFolder = treeSelectionModel.getSelectedNode();

    	collateDisplayObject.businessObject = collate;
    	collateDisplayObject.isCollate = true;
    	collateDisplayObject.action = 'EDIT';

        var rightMenuList = [];
        $scope.tabs = [{title:localizedMessages.get("COLLATE"), content:'all',rightMenuList:rightMenuList}];
        $scope.isFileUploadAllowed = false;
    	$scope.hideRepositoryList = true;
    	$scope.showCollate = true;
        if(type=="boardbook")
            $scope.middleBarObject.title = localizedMessages.get("EDIT_BOARDBOOK");
        else
            $scope.middleBarObject.title = localizedMessages.get("EDIT_COLLATE");
        $scope.middleBarObject.showBack = false;
    }

    var createCollate = function(documentList){

    	if(!StringUtils.isNullEmpty(documentList))
		{
    		var collateDisplayObject = collateModelService.getInstance();
    		collateDisplayObject.parentFolder = treeSelectionModel.getSelectedNode();
    		collateDisplayObject.isCollate = true;
        	collateDisplayObject.action = 'CREATE';

            var rightMenuList = [];
            $scope.tabs = [{title:localizedMessages.get("COLLATE"), content:'all',rightMenuList:rightMenuList}];

            if(documentList.length == 1 && StringUtils.isFolder(documentList[0].objectBaseUuid))
        	{
        		collateDisplayObject.collateList = [];
        		collateDisplayObject.isDocumentCollate = false;
        		collateDisplayObject.businessObject = documentList[0];
        	}
            else
        	{
	        	collateDisplayObject.collateList = documentList;
	    		collateDisplayObject.isDocumentCollate = true;
        	}

        	$scope.hideRepositoryList = true;
        	$scope.showCollate = true;
            $scope.middleBarObject.title = localizedMessages.get("CREATE_COLLATE");
            $scope.isUploadAllowed = false;
			$scope.isFileUploadAllowed = false;

        	$scope.middleBarObject.showBack = false;
		}

    }

    var createBoardbook = function(businessObject){

        if(!StringUtils.isNullEmpty(businessObject))
        {
            var collateDisplayObject = collateModelService.getInstance();
            collateDisplayObject.parentFolder = treeSelectionModel.getSelectedNode();
            collateDisplayObject.isCollate = false;
            collateDisplayObject.isDocumentCollate = false;
            collateDisplayObject.action = 'CREATE';
            collateDisplayObject.collateList = [];

            var rightMenuList = [];
            $scope.tabs = [{title:localizedMessages.get("BOARDBOOK"), content:'all',rightMenuList:rightMenuList}];


            collateDisplayObject.businessObject = businessObject
            $scope.hideRepositoryList = true;
            $scope.showCollate = true;
            $scope.middleBarObject.title = localizedMessages.get("CREATE_BOARDBOOK");
            $scope.middleBarObject.showBack = false;
        }

    }

    $scope.callBackCollateSave = function()
    {
        $scope.showCollate = false;
        $scope.hideRepositoryList = false;
        showHideFileFolder(treeSelectionModel.getSelectedNode());
        $scope.getDocumentsForParentBaseUuid(treeSelectionModel.getSelectedNode().objectBaseUuid);
        $scope.getSelectedTreeNode(treeSelectionModel.getSelectedNode());
        $scope.middleBarObject.title = backUtiltiy.title;
        $scope.middleBarObject.showBack = true;

    }

    $scope.callBackCollateCancel = function(){
        $scope.showCollate = false;
        $scope.hideRepositoryList = false;
        showHideFileFolder(treeSelectionModel.getSelectedNode());
        $scope.middleBarObject.title = backUtiltiy.title;
        $scope.middleBarObject.showBack = true;
    }

    $scope.selectMenuItem = function(ob)
	{
		var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
		sessionStorage.setItem("documentsSortOrder", JSON.stringify(ob));

		if(ob.value == "hideTreeOption")
		{
    		$scope.repositoryDisplayObjects.showTree = false;
    		setChangesForTree();
		}
		else if(ob.value == "showTreeOption")
		{
			$scope.repositoryDisplayObjects.showTree = true;
			setChangesForTree();
		}
		else if(ob.value == "small")
		{
			$scope.repositoryDisplayObjects.displayType = "SMALL";
			localStorage.setItem( userTreeViewPrefKey, "SMALL");
		}
		else if(ob.value == "medium")
		{
			$scope.repositoryDisplayObjects.displayType = "MEDIUM";
			localStorage.setItem( userTreeViewPrefKey, "MEDIUM");
		}
		else if(ob.value == "large")
		{
			//context.getModel().REPOSITORY_DISPLAY_TYPE = "LARGE";
			$scope.repositoryDisplayObjects.displayType = "LARGE";
			localStorage.setItem( userTreeViewPrefKey, "LARGE");
		}
		else if(ob.value == "name")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['name', 'extensionType', 'lastModifiedOnGMTMillis'], $scope.reverseSort);
			$scope.reverseSort = !$scope.reverseSort;
				document.getElementById("nameToggle").classList.add('add-icon');
				document.getElementById("typeToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('add-icon');
				document.getElementById("nameToggle").classList.remove('asc-rotate');  
                document.getElementById("nameToggle").classList.remove('desc-rotate');
				if($scope.reverseSort == true){
					document.getElementById("nameToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("nameToggle").classList.add('desc-rotate');
				}
		}
		else if(ob.value == "extensionType")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['extensionType', 'name', 'lastModifiedOnGMTMillis'], $scope.reverseSort);
			$scope.reverseSort = !$scope.reverseSort;
				document.getElementById("typeToggle").classList.add('add-icon');
				document.getElementById("nameToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('add-icon');
				document.getElementById("typeToggle").classList.remove('asc-rotate');  
                document.getElementById("typeToggle").classList.remove('desc-rotate');
				if($scope.reverseSort == true){
					document.getElementById("typeToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("typeToggle").classList.add('desc-rotate');
				}
		}
		else if(ob.value == "originalCreatedBy")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['originalCreatedBy', 'extensionType', 'name'], $scope.reverseSort);
			$scope.reverseSort = !$scope.reverseSort;
		}
		else if(ob.value == "createdOnGMTMillis")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['createdOnGMTMillis', 'extensionType', 'name'], $scope.reverseSort);
			$scope.reverseSort = !$scope.reverseSort;
		}
		else if(ob.value == "createdBy")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['createdBy', 'extensionType', 'name'], $scope.reverseSort);
			$scope.reverseSort = !$scope.reverseSort;
				document.getElementById("modifiedToggle").classList.add('add-icon');
				document.getElementById("typeToggle").classList.remove('add-icon');
				document.getElementById("nameToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('asc-rotate');  
                document.getElementById("modifiedToggle").classList.remove('desc-rotate');
			    if($scope.reverseSort == true){
					document.getElementById("modifiedToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("modifiedToggle").classList.add('desc-rotate');
				}
		}
		else if(ob.value == "lastModifiedOnGMTMillis")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['lastModifiedOnGMTMillis', 'extensionType', 'name'], $scope.reverseSort);
			$scope.reverseSort = !$scope.reverseSort;
				document.getElementById("modifiedToggle").classList.add('add-icon');
				document.getElementById("typeToggle").classList.remove('add-icon');
				document.getElementById("nameToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('asc-rotate');  
                document.getElementById("modifiedToggle").classList.remove('desc-rotate');
				if($scope.reverseSort == true){
					document.getElementById("modifiedToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("modifiedToggle").classList.add('desc-rotate');
				}
		}
		else if(ob.value == "enableincrementalFetch")
		{
			repositoryService.toggleIncrementalFetchConfig(true).then(function(data)
			{
//				if(data.boName == "SuccessBO"){
					context.getModel().incrementalFetchEnabledForUser = true;
					context.getModel().incrementalHtmlViewer = true;
					setChangesForTree();
//				}
	    	});
		}
		else if(ob.value == "disableincrementalFetch")
		{
			repositoryService.toggleIncrementalFetchConfig(false).then(function(data)
			{
//				if(data.boName == "SuccessBO"){
					context.getModel().incrementalFetchEnabledForUser = false;
					context.getModel().incrementalHtmlViewer = false;
					setChangesForTree();
//				}
	    	});
		}
		else
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ob.value);
	}

	var setChangesForTree = function()
	{
		localStorage.setItem('SHOW_TREE_FOR_REPOSITORY', $scope.repositoryDisplayObjects.showTree);
		var len = $scope.tabs[0].rightMenuList.length;

		$scope.tabs[0].rightMenuList[len-1].dropDownMenu = getDisplayOptionsForRepository();
	}

    var callBackFromCreateFolder = function(){
    	$scope.getDocumentsForParentBaseUuid(treeSelectionModel.getSelectedNode().objectBaseUuid);
		$scope.getSelectedTreeNode(treeSelectionModel.getSelectedNode());
		if ( document.getElementById("selectAllCheckBox") && document.getElementById("selectAllCheckBox").classList.contains('deSelectAllLbl') ){
			document.getElementById("selectAllCheckBox").classList.remove('deSelectAllLbl');
			$rootScope.selectAll = false;
		}
    }

    var createNewFolder = function()
    {
    	var objectBaseUuid = treeSelectionModel.getSelectedNode().objectBaseUuid;
    	bvDialog.createFolder(objectBaseUuid, callBackFromCreateFolder);
    };

    $scope.onUploadComplete = function()
    {
    	console.log("Success Upload Complete");
    	//ngDialog.close();
    }

    var openDialogForUpload = function()
    {
	    var uploadFileObjects = {};

		uploadFileObjects.action = "upload";
		uploadFileObjects.parentId = treeSelectionModel.getSelectedNode().objectBaseUuid;
		uploadFileObjects.onUploadComplete = $scope.onUploadComplete;

		bvDialogService.uploadFile(uploadFileObjects);
    };

    $scope.handleLeftIcon = function()
	{
    	var folderObject = backUtiltiy.popBackStack();
		$scope.middleBarObject.showBack = backUtiltiy.showBack;
		$scope.middleBarObject.title = backUtiltiy.title;

		if(folderObject)
		{
			showHideFileFolder(folderObject);

			$scope.getDocumentsForParentBaseUuid(folderObject.objectBaseUuid);
			getFolderTreeData(folderObject);
			sessionStorage.setItem($scope.currentInstance +"-lastSelectedFolder-"+ $scope.$root.SELECTED_PORTAL_BASEUUID, JSON.stringify(folderObject));
		}
		if ( document.getElementById("selectAllCheckBox") && document.getElementById("selectAllCheckBox").classList.contains('deSelectAllLbl') ){
			document.getElementById("selectAllCheckBox").classList.remove('deSelectAllLbl');
			$rootScope.selectAll = false;
		}
	}

	var getFolderTreeData = function(folderObject)
    {
		treeSelectionModel.setSelectedNode(folderObject);
		$scope.getSelectedTreeNode(folderObject);
    };

	$scope.getSelectedTreeNode = function(selectedNode)
    {
    	repositoryService.findFoldersAndSearchObjectsForParentUuid(selectedNode.objectBaseUuid).then(function(data)
    	{
    		selectedNode.children = [];

    		selectedNode.children = data.data;

    	});
    };

	$scope.getDocumentsForParentBaseUuid = function (baseUuid)
    {
    	repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
    	{
    		repositoryModel.repositoryList(data.data);
    	});
    };

    var showHideFileFolder = function(folderObject)
    {
        var show;
    	if(permissionService.isOwner(folderObject) || permissionService.isEdit(folderObject) || permissionService.isShareAllowed(folderObject))
    		{show = true;
    		$scope.isUploadAllowed = true;
    		}
		else{
			show = false;
			$scope.isUploadAllowed = false;
		}
    	var rightMenuList = [];

    	if(show)
		{
			if(MobileUtil.isApp())
	    		rightMenuList = [folderOption, settingOption];
	    	else {
	    		$scope.isRepository = true;
	    		$scope.isFileUploadAllowed = true;
	    		$scope.fileUploadLabel = localizedMessages.get('UPLOAD_FILE');
	    		if(context.getModel().showUploadFolder)
	        		rightMenuList = [fileOption, folderUpload, folderOption, settingOption];
	    	    else
	       	    	rightMenuList = [fileOption, folderOption, settingOption];
	    	}
		}
    	else
		{
    		rightMenuList = [settingOption];
		}

		$scope.tabs = [
			{ title:localizedMessages.get('DOCUMENTS'), content:'all' ,rightMenuList:rightMenuList},
			{ title:localizedMessages.get('TRASH'), func: goToTrash, content:'all' ,rightMenuList:rightMenuList}
		];

        if(context.getModel().folderAgendaBuilderEnabled)
            $scope.tabs.push({ title: localizedMessages.get('AGENDA_TEMPLATES'), func: goToAgendaTemplates, content: 'all', rightMenuList: rightMenuList});
    }


/*	$scope.clearSelection = function(documentList)
	{
		if( !(Object.prototype.toString.call( documentList ) === "[object Array]"))
			documentList = [documentList]

		angular.forEach(documentList, function(document)
		{
			if (document.selected && document.selected == true)
			{
				document.selected = false;
			}
		});
	}
*/

    var fileOption = { title:localizedMessages.get('UPLOAD_FILE'), click: uploadFile, iconUrl: '', svgFill: '#fff'};
    var folderUpload = { title:localizedMessages.get('UPLOAD_FOLDER'), click: uploadFolder, iconUrl: '', svgFill: '#fff'};
	var folderOption = { title:localizedMessages.get('CREATE_FOLDER'),click:createNewFolder, iconUrl: '', svgFill: '#fff'};
	//var sortOption = { title:localizedMessages.get('SORT'),click:'', iconUrl: '', svgFill: '#fff', dropDownMenu:SortUtility.getDefaultSortByItem()};
	var settingOption = { title:localizedMessages.get('OPTIONS'),click:() => {}, iconUrl: '', svgFill: '#fff', dropDownMenu:getDisplayOptionsForRepository()};

	var goToTrash = function(){
		$scope.middleBarObject.showBack = false;
		$state.go('home.trash');
	}

	var goToAgendaTemplates = function(){
		$scope.middleBarObject.showBack = false;
		$state.go('home.agendatemplates');
	}

	homeModel.briefcaseMobileMenu.list = [
                                	{ name: localizedMessages.get('SORT_BY_NAME'), click: function(){ }, value:"name"},
                                	{ name: localizedMessages.get('SORT_BY_TYPE'), click: function(){ }, value:"extensionType"},
                                	{ name: localizedMessages.get('SORT_BY_DATE_MODIFIED'), click: function(){ }, value:"lastModifiedOnGMTMillis"},
                                	{ name: localizedMessages.get('SORT_BY_MODIFIED_BY'), click: function(){ }, value:"createdBy"}
                                ]

/*    $scope.renderMenuBarForRepository = function(){
    	var rightMenuList = [settingOption];

    	$scope.tabs = [
    		        { title:localizedMessages.get('DOCUMENTS'), content:'all' ,rightMenuList:rightMenuList},
    		        { title:localizedMessages.get('TRASH'), func: goToTrash, content:'all' ,rightMenuList:rightMenuList}
				   ];

        if(context.getModel().folderAgendaBuilderEnabled)
            $scope.tabs.push({ title: localizedMessages.get('AGENDA_TEMPLATES'), content: 'all', func: goToAgendaTemplates, rightMenuList: rightMenuList });
    };
    $scope.renderMenuBarForRepository();

    */

    $scope.openFolder = repositoryService.getOpenFolder();
}

function FavoritesController($scope, context, treeService, repositoryModelService, $state, localizedMessages, SortUtility, homeModel, repositoryService)
{
	$scope.mobileRepositoryClicked = true;
	$scope.svgFill = '#FFF';
	$scope.currentInstance = "FAVORITES";
	$scope.repositoryDisplayObjects = {HIDE_CARET:true};
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.middleBarObject.showBack = false;
	var filterData;
	var briefCaseData = [];

	//added event for  remove from briefcase menu button click 
	document.addEventListener("removeFromBriefCaseEvent", () => removeFromBriefCaseEventUpdate());

	var removeFromBriefCaseEventUpdate = function() {
		getDocumentsJSONForBriefCase();
    };

	var selectAll = function()
    {
		homeModel.selectedBriefCaseOption = "ALL";

		filterData = briefCaseData;
		$scope.repositoryDisplayObjects.noFavorites = filterData.length == 0 ? true : false;
		repositoryModel.repositoryList(filterData);
		setTitle();
    };


    $scope.callParentController = function(type, args)
    {
    	switch (type)
    	{
			case 'REMOVE_FROM_FAVORITES':
				getDocumentsJSONForBriefCase();
				break;

			default:
				break;
		}
    }

    var selectCurrent = function()
    {
		homeModel.selectedBriefCaseOption = "CURRENT";

		filterData = StringUtils.filterBOForSelectedObjectBaseUuid(briefCaseData, context.getModel().portalVO.objectBaseUuid);
		$scope.repositoryDisplayObjects.noFavorites = filterData.length == 0 ? true : false;
		repositoryModel.repositoryList(filterData);
		setTitle();
    };

	var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
	var userTreeViewPref = localStorage.getItem(userTreeViewPrefKey);
	console.log("USER TREE PREF:",userTreeViewPref);

	if(!userTreeViewPref)
	{
		userTreeViewPref = "MEDIUM";
		localStorage.setItem(userTreeViewPrefKey, userTreeViewPref);
	}
	$scope.repositoryDisplayObjects.displayType = userTreeViewPref;

	var treeSelectionModel = treeService.getInstance($scope.currentInstance);
	var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

	$scope.showTree = false;

	  $scope.selectMenuItem = function(ob)
		{
			var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
		
			if(ob.value == "small")
			{
				$scope.repositoryDisplayObjects.displayType = "SMALL";
				localStorage.setItem( userTreeViewPrefKey, "SMALL");
			}
			else if(ob.value == "medium")
			{
				$scope.repositoryDisplayObjects.displayType = "MEDIUM";
				localStorage.setItem( userTreeViewPrefKey, "MEDIUM");
			}
			else if(ob.value == "large")
			{
				//context.getModel().REPOSITORY_DISPLAY_TYPE = "LARGE";
				$scope.repositoryDisplayObjects.displayType = "LARGE";
				localStorage.setItem( userTreeViewPrefKey, "LARGE");
			}
			else if(ob.value == "name")
			{
				repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['name', 'extensionType', 'lastModifiedOnGMTMillis'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
				$scope.nameToggle = !$scope.nameToggle;  
				document.getElementById("nameToggle").classList.add('add-icon');
				document.getElementById("typeToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('add-icon');
				document.getElementById("nameToggle").classList.remove('asc-rotate');  
                document.getElementById("nameToggle").classList.remove('desc-rotate');
				if($scope.reverseSort == true){
					document.getElementById("nameToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("nameToggle").classList.add('desc-rotate');
				} 
			}
			else if(ob.value == "extensionType")
			{
				repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['extensionType', 'name', 'lastModifiedOnGMTMillis'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
				document.getElementById("typeToggle").classList.add('add-icon');
				document.getElementById("nameToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('add-icon');
				document.getElementById("typeToggle").classList.remove('asc-rotate');  
                document.getElementById("typeToggle").classList.remove('desc-rotate');
				if($scope.reverseSort == true){
					document.getElementById("typeToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("typeToggle").classList.add('desc-rotate');
				}
			}
			else if(ob.value == "showAll")
			{
				selectAll();
			}
			else if(ob.value == "currentTS")
			{
				selectCurrent();
			}
			else if(ob.value == "originalCreatedBy")
			{
				repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['originalCreatedBy', 'extensionType', 'name'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
			}
			else if(ob.value == "createdOnGMTMillis")
			{
				repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['createdOnGMTMillis', 'extensionType', 'name'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
			}
			else if(ob.value == "createdBy")
			{
				repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['createdBy', 'extensionType', 'name'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
				document.getElementById("modifiedToggle").classList.add('add-icon');
			    document.getElementById("typeToggle").classList.remove('add-icon');
				document.getElementById("nameToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('asc-rotate');  
                document.getElementById("modifiedToggle").classList.remove('desc-rotate');
				if($scope.reverseSort == true){
					document.getElementById("modifiedToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("modifiedToggle").classList.add('desc-rotate');
				}
			}
			else if(ob.value == "lastModifiedOnGMTMillis")
			{
				repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['lastModifiedOnGMTMillis', 'extensionType', 'name'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
				document.getElementById("modifiedToggle").classList.add('add-icon');
			    document.getElementById("typeToggle").classList.remove('add-icon');
				document.getElementById("nameToggle").classList.remove('add-icon');
				document.getElementById("modifiedToggle").classList.remove('asc-rotate');  
                document.getElementById("modifiedToggle").classList.remove('desc-rotate');
				if($scope.reverseSort == true){
					document.getElementById("modifiedToggle").classList.add('asc-rotate');  
				}           
				else{
					document.getElementById("modifiedToggle").classList.add('desc-rotate');
				}
			}
			else
				repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ob.value);
		}
	  
	  var getDisplayOptionsForRepository = function()
		{
			var options = [];

			options.push({name:localizedMessages.get("SMALL_TEXT"), value: "small"});
			options.push({name:localizedMessages.get("MEDIUM_TEXT"), value: "medium"});
			options.push({name:localizedMessages.get("LARGE_TEXT"), value: "large"});
			options.push({name:localizedMessages.get("SORT_BY_NAME"), value: "name"});
			options.push({name:localizedMessages.get("SORT_BY_TYPE"), value: "extensionType"});
			options.push({name:localizedMessages.get("SORT_BY_DATE_MODIFIED"), value: "lastModifiedOnGMTMillis"});
			options.push({name:localizedMessages.get("SORT_BY_MODIFIED_BY"), value: "createdBy"});
			
			if(context.getModel().enableIncrementalFetchOnUI){
				if(!context.getModel().incrementalFetchEnabledForUser){
					options.push({name:localizedMessages.get("ENABLE_INCREMENTAL_FETCH"), value: "enableincrementalFetch"});
				} else {
					options.push({name:localizedMessages.get("DISABLE_INCREMENTAL_FETCH"), value: "disableincrementalFetch"});
				}
			}

			return options;
		}


	  	var createMenuForFavoritesController = function(){
	  		var options = [];

	  		options.push({name:localizedMessages.get("CURRENT_TEAMSPACE"), value: "currentTS"});
	  		options.push({name:localizedMessages.get("ALL_TEAMSPACES"), value: "showAll"});

	  		var settingOption = { title:localizedMessages.get('SHOW'),click:()=>{}, iconUrl: '', svgFill: '#fff', dropDownMenu:options};
	  		var sortOptions = {title:localizedMessages.get('OPTIONS'),click:() => {}, iconUrl: '', svgFill: '#fff', dropDownMenu:getDisplayOptionsForRepository()};

	  		var rightMenuList = [settingOption , sortOptions];

	  		$scope.tabs = [
	  		  				{title : localizedMessages.get('BRIEFCASE'), content : 'all', active: true, rightMenuList:rightMenuList}
	  		  			];

	  		homeModel.briefcaseMobileMenu.list = [
	  		                                      	{ name: localizedMessages.get('CURRENT_TEAMSPACE'), click: function(){ }, value:"currentTS"},
	  		                                      	{ name: localizedMessages.get('ALL_TEAMSPACES'), click: function(){ }, value:"showAll"},
		                                        	{ name: localizedMessages.get('SORT_BY_NAME'), click: function(){ }, value:"name"},
		                                        	{ name: localizedMessages.get('SORT_BY_TYPE'), click: function(){ }, value:"extensionType"},     	
		                                        	{ name: localizedMessages.get('SORT_BY_DATE_MODIFIED'), click: function(){ }, value:"lastModifiedOnGMTMillis"},
		                                        	{ name: localizedMessages.get('SORT_BY_MODIFIED_BY'), click: function(){ }, value:"createdBy"}
		                                        ]
	    };

	    /**
         * Loading Documents for BriefCase
         *
         */

        var getDocumentsJSONForBriefCase = function ()
        {
        	repositoryService.getDocumentsJSONForBriefCase().then(function(data)
        	{
        		briefCaseData = data.data;

        		if(StringUtils.isNullEmpty(homeModel.selectedBriefCaseOption) || homeModel.selectedBriefCaseOption == "ALL")
    			{
            		filterData = briefCaseData;
    			}
        		else
    			{
        			filterData = StringUtils.filterBOForSelectedObjectBaseUuid(briefCaseData, context.getModel().portalVO.objectBaseUuid);
    			}

            	$scope.repositoryDisplayObjects.noFavorites = filterData.length == 0 ? true : false;
        		repositoryModel.repositoryList(filterData);
        	});
        };

        var setTitle = function() {
        	var filterData	= [];
        	if(StringUtils.isNullEmpty(homeModel.selectedBriefCaseOption) || homeModel.selectedBriefCaseOption == "ALL")
			{
        		if(MobileUtil.isMobileClient())
        			$scope.middleBarObject.title = localizedMessages.get('BRIEFCASE')+": "+localizedMessages.get('ALL_TEAMSPACES');
        		else
        			$scope.middleBarObject.title = localizedMessages.get('ALL_TEAMSPACES');

        		$scope.repositoryDisplayObjects.showPortalName = true;
			}
    		else
			{
    			if(MobileUtil.isMobileClient())
    				$scope.middleBarObject.title = localizedMessages.get('BRIEFCASE')+": "+localizedMessages.get('CURRENT_TEAMSPACE');
    			else
    				$scope.middleBarObject.title = localizedMessages.get('CURRENT_TEAMSPACE');

    			$scope.repositoryDisplayObjects.showPortalName = false;
			}
        }

        setTitle();
        getDocumentsJSONForBriefCase();
	    createMenuForFavoritesController();
}

function AuditTrailController($scope, context, repositoryService, localizedMessages)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var businessObject = $scope.bvModalControllerInputs.businessObject;

	$scope.popupTitle = localizedMessages.get('AUDIT_TRAIL_FOR') + " " +$scope.bvModalControllerInputs.businessObject.name;
	$scope.UI_USER = localizedMessages.get('USER');
	$scope.UI_ACTION = localizedMessages.get('ACTION');
	$scope.UI_DATE = localizedMessages.get('DATE');

	var getAuditTrailListJSON = function(objectVersionUuid)
	{
		repositoryService.getAuditTrailListJSON(objectVersionUuid).then(function(data)
    	{
			$scope.auditTrailList = data;
			for(var i=0; i<$scope.auditTrailList.length; i++)
			{
				var dtStr = $scope.auditTrailList[i].date + " GMT";
				var atDate = new Date(dtStr);
				$scope.auditTrailList[i].date = moment(atDate).format('lll');
			}


			$scope.auditTrailList.sort(function(a, b){
				var dateAStr=moment(a.date).toDate();
				var dateBStr=moment(b.date).toDate();
				var dateA=new Date(dateAStr);
				var dateB=new Date(dateBStr);
				return dateA-dateB //sort by date ascending
			});


    	});
	}
	getAuditTrailListJSON(businessObject.objectVersionUuid);
	//MEETX-23919
	$scope.getLocalizedActivityForAuditTrail=function(activity){
	            var localizedActivity=activity;
			    if(activity==="Modified Permissions")
			    	localizedActivity= localizedMessages.get('MODIFIED_PERMISSIONS');
			    else if(activity==="Opened as PDF")
			    	localizedActivity= localizedMessages.get('OPENED_AS_PDF');
			    else 
			    localizedActivity= localizedMessages.get(activity.toUpperCase());
			    return localizedActivity;
	}
}
function UserFavoritesController($scope, context, repositoryService, localizedMessages, bvDialogService, bvDialog, companyUserService, imageService)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var businessObject = $scope.bvModalControllerInputs.businessObject;

	$scope.popupTitle = localizedMessages.get('ADD_TO_USER_BRIEFCASE');
	$scope.UI_USER = localizedMessages.get('USERS');
	$scope.UI_ATTACHMENTS = localizedMessages.get('ATTACHMENTS');

	$scope.selectedDocuments = [];
	$scope.selectedUsers = [];

	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';

	$scope.scrollConfig = {
        autoHideScrollbar: true,
    };

	if(businessObject instanceof Array) {
		for(var i=0; i<businessObject.length; i++) {
			$scope.selectedDocuments.push(businessObject[i]);
		}
	}
	else {
		$scope.selectedDocuments.push(businessObject);
	}

	var cancelEdit = function(){
		bvModal.close();
	}

	$scope.close = function(result) {
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), cancelEdit);
    };

	$scope.getDocIcon = function(attachment){
		return imageService.getImageForObject(attachment);
	};

	$scope.documentSortableOptions = {
		handle: '> .drag-handle',
        update: function(e, ui)
        {
        	//console.log(ui.item);
        }
    };

	$scope.previewAttachment = function(docId,docObject) {
   		bvDialog.showDocumentPreview(docId,docObject);
    }

	var onSelectUsers = function(selectedUsers){
        for(var i=0; i<selectedUsers.length; i++){
        	if(selectedUsers[i].type == "User") {
        		addToSelectedUsers(selectedUsers[i]);
        	} else {
        		var users = companyUserService.getGroupUsers(selectedUsers[i].objectBaseUuid);
        		addToSelectedUsers(users);
			}
        }
	}

	var addToSelectedUsers = function(susers) {
		if(susers instanceof Array) {
			for(var i=0; i<susers.length; i++){
	        	var selected = false;
	        	for(var j=0; j<$scope.selectedUsers.length; j++) {
	        		if(susers[i].objectBaseUuid == $scope.selectedUsers[j].objectBaseUuid) {
	        			selected = true;
	        			break;
	        		}
	        	}
	        	if(!selected) {
	        		$scope.selectedUsers.push(susers[i]);
	        	}
	        }
		} else {
			var selected = false;
    		for(var j=0; j<$scope.selectedUsers.length; j++) {
        		if(susers.objectBaseUuid == $scope.selectedUsers[j].objectBaseUuid) {
        			selected = true;
        			break;
        		}
        	}
        	if(!selected) {
        		$scope.selectedUsers.push(susers);
        	}
		}

	}

	var onSelectDocuments = function(selectedDocuments){
		for(var i=0; i<selectedDocuments.length; i++){
			var selected = false;
        	for(var j=0; j<$scope.selectedDocuments.length; j++) {
        		if(selectedDocuments[i].objectBaseUuid == $scope.selectedDocuments[j].objectBaseUuid) {
        			selected = true;
        			break;
        		}
        	}
        	if(!selected) {
        		$scope.selectedDocuments.push(selectedDocuments[i]);
        	}
        }
	}

	$scope.removeDocument = function(index) {
		$scope.selectedDocuments.splice(index,1);
    };
    $scope.removeUser = function(index) {
    	$scope.selectedUsers.splice(index,1);
    };

    $scope.openAttachmentChooser=function()
    {
    	bvDialog.attachDocument(onSelectDocuments, true, "", "", "own");
    };
    $scope.showUsers=function()
    {
    	$scope.usersPopupTitle = localizedMessages.get("USERS");
	    bvDialog.itemListSelection($scope.usersPopupTitle, true, onSelectUsers);
    };


	var buttons = $scope.bvModalControllerInputs.buttons;

	buttons[0].name = localizedMessages.get('CANCEL');

	buttons[1].name = localizedMessages.get('SAVE');

	buttons[0].action = function(){
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), cancelEdit);
	}


	buttons[1].action = function(){
		if($scope.selectedUsers.length==0 && $scope.selectedDocuments.length==0) {
			var alertMsg = localizedMessages.get('MSG_NO_ATTACHMENT_AND_USER');
    	    bvDialog.showMessage(alertMsg);
    	    return;
		}
		if($scope.selectedUsers.length==0) {
			var alertMsg = localizedMessages.get('MSG_NO_USER');
    	    bvDialog.showMessage(alertMsg);
    	    return;
		}
		if($scope.selectedDocuments.length==0) {
			var alertMsg = localizedMessages.get('MSG_NO_ATTACHMENT');
    	    bvDialog.showMessage(alertMsg);
    	    return;
		}




		var userBaseUuids = [];
		var documentBaseUuids = [];
		for(var i=0; i<$scope.selectedUsers.length; i++) {
			userBaseUuids.push($scope.selectedUsers[i].objectBaseUuid)
    	}
		for(var i=0; i<$scope.selectedDocuments.length; i++) {
			documentBaseUuids.push($scope.selectedDocuments[i].objectBaseUuid)
    	}
		buttons.splice(1,1);
		repositoryService.addToMultipleFavoritesJSON(angular.toJson(userBaseUuids), angular.toJson(documentBaseUuids));
		bvModal.close();
	}
}

function CopyPasteService()
{
	this.pasteTarget;
	this.copiedObjects = [];
	this.copiedParentObejectVO;
	this.isContentCopied;
	this.targetObject;
	this.isPasteShortCut;
	this.currentAction;
	this.COPY = "copy";
	this.CUT = "cut";
	this.PASTE = "paste";
	this.PASTE_AS_SHORTCUT = "pasteasshortcut";

//	this.setCopiedObjects = function(name)
//	{
//		_copiedObjects=value;
//	}
//
//	this.getCopiedObjects = function()
//	{
//		return _copiedObjects;
//	}
//
//	var getClipboardText = function()
//	{
//		var str = "";
//		angular.forEach(_copiedObjects, function(businessObjectVO)
//    	{
//    		str=str+URLUtils.getResourceLinkURL(object)+"\n";
//    	});
//
//		return str;
//	}
//
//	this.getResourceLinkURL = function (object:BusinessObjectVO):String
//	{
//    	var objStr:String=Base64.encode(object.objectBaseUuid);
//     	return getBaseURL() + "/portlet/rh?resourceid="+objStr;
//    }

}

function RepositoryModelService(selectionStack, $state)
{
	var _instances = {};

	function RepositoryModel()
	{
		var repositoryList = {}
		// Change for MEETX-11798
		var newItemsLoadedFull=false;
		var moduleClicked;
		var updateAlreadySelectedItems = function(allItems) {

			var selectedItems = selectionStack.getAllSelectedItems();
			var selectedItemsLength = selectedItems.length;

			var allItemsLength = allItems.length;

			for(var i = 0; i < selectedItemsLength; i++) {
				for(var j = 0; j < allItemsLength; j++) {
					if(allItems[j].objectBaseUuid === selectedItems[i].objectBaseUuid) {
						allItems[j].selected = true;
						break;
					}
				}
			}

			return allItems;

		}
		this.repositoryList = function(update, currentInstance)
		{
			
			repositoryList.noDocumentsInFolder = false;
			repositoryList.noFavorites = false;
			repositoryList.noAgendaTemplates = false;
			//Reverted for MEETX-24898
			//if($state.current && $state.current.name && $state.current.name === 'home.dashboard')
				//repositoryList.browseList = [];

			if(update)
			{
				if(currentInstance === 'FILE_CHOOSER') {
					update = updateAlreadySelectedItems(update);
				}
				repositoryList.browseList = update;
        		if(repositoryList.browseList.length == 0)
        		{
        			repositoryList.noDocumentsInFolder = true;
        			if(currentInstance == 'FAVORITES') {
        				repositoryList.noFavorites = true;
        			}
					else if(currentInstance == 'AGENDA_TEMPLATES')
					{
						repositoryList.noAgendaTemplates = true;
					}
        		}

			}
			return repositoryList;
		}
		
		this.newItemsLoadedFull = function(update)
		{
			if(update!==undefined)
			{newItemsLoadedFull=update;}
			
			return newItemsLoadedFull;
		}
		
		this.moduleClicked = function(update)
		{
			if(update!==undefined)
			{moduleClicked=update;}
			
			return moduleClicked;
		}
	}

	RepositoryModel.prototype = new RepositoryModel;

	this.getInstance = function(name)
	{
		if(StringUtils.isNullEmpty(name))
			name="default";

		if( _instances[name] == null )
		{
			_instances[name] = new RepositoryModel();
		}

		return _instances[name];
	}
};

function BackUtiltiyService()
{
	var _instances = [];

	function BackUtiltiy()
	{
		var backButtonStack = [];

		this.showBack = false;
		this.title = "";

		this.reloadBackButtonStack = function(defaultObject)
		{
			backButtonStack = [];
			backButtonStack.push(defaultObject);
			this.title = defaultObject.name;
		}

		this.popBackStack = function()
		{
			if(backButtonStack.length > 1)
			{
				backButtonStack.pop();
			}

			if(backButtonStack.length == 1)
			{
				this.showBack = false;
			}

			var poppedObject;

			if(backButtonStack.length > 0)
				poppedObject = backButtonStack[backButtonStack.length - 1];

			this.title = poppedObject.name;

			return poppedObject;
		}

		this.pushButtonStack = function (document)
	    {
			var lastObject = backButtonStack[backButtonStack.length -1];

			if(lastObject != undefined && lastObject.objectBaseUuid == document.objectBaseUuid)
			{
				return;
			}

		    this.showBack = true;

		    backButtonStack.push(document);
		    this.title = document.name;
	    }
	}

	this.getInstance = function(name)
	{
		if(name == null || name === undefined)
			name="default";

		if( _instances[name] == null )
		{
			_instances[name] = new BackUtiltiy();
		}

		return _instances[name];
	}
}

function DocumentListingController($scope, backUtiltiyService, repositoryService, repositoryModelService)
{
	$scope.iconNextToTitle = 'resources/assets/svgs/backarrow.svg';
	$scope.svgFill = '#FFF';
	$scope.currentInstance = "FOLDER_LISTING"+$scope.$parent.module.portlet.objectBaseUuid;
	$scope.showTree = false;
	//$scope.folderBaseUuid = $scope.folderBaseUuid;
	$scope.middleBarObject = {};
	var backUtiltiy  = backUtiltiyService.getInstance($scope.currentInstance);
	var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

	$scope.getDocumentsForParentBaseUuid = function (baseUuid)
    {
    	repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
    	{
    		repositoryModel.repositoryList(data.data);
    	});
    };

	 $scope.handleLeftIcon = function()
	{
		var folderObject = backUtiltiy.popBackStack();
		$scope.middleBarObject.showBack = backUtiltiy.showBack;
		$scope.middleBarObject.title = backUtiltiy.title;

		if(folderObject)
		{
			$scope.getDocumentsForParentBaseUuid(folderObject.objectBaseUuid);
		}
	}
}

angular
	.module('repository',['repository.service','localization.service','bvdialog.service','common.service','sortUtility.service','bvselectionModel','ngFileUpload','context.service', 'followers.module','conference.module', 'ipad.service','documentMenu.module', 'agendaTemplates'])
	.service('repositoryModelService', ['selectionStack','$state', RepositoryModelService])
	.service('copyPasteService', [CopyPasteService])
	.service('backUtiltiyService', [BackUtiltiyService])
    .controller('documentListingController', ['$scope', 'backUtiltiyService', 'repositoryService', 'repositoryModelService', DocumentListingController])
	.controller('RepositoryController', ['$rootScope','$scope','repositoryService','localizedMessages', 'ipadService', 'bvDialogService', '$state','SortUtility', '$modal','treeService','context','documentContextMenu','permissionService','bvPollingProcessor','registerStatusCategory','repositoryModelService','copyPasteService','backUtiltiyService','collateModelService', 'bvDialog', 'contextMenuHandler','homeModel', 'legacyUserService', RepositoryController])
	.controller('FavoritesController', ['$scope','context','treeService','repositoryModelService','$state', 'localizedMessages','SortUtility','homeModel','repositoryService', FavoritesController])
    .controller('AuditTrailController', ['$scope','context','repositoryService','localizedMessages', AuditTrailController])
    .controller('UserFavoritesController', ['$scope','context','repositoryService','localizedMessages','bvDialogService','bvDialog','companyUserService','imageService', UserFavoritesController]);
