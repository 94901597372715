angular
    .module('dialogControllers.service')
	.controller('userAnswersPreviewController', userAnswersPreviewController);

userAnswersPreviewController.$inject =  [ '$scope', '$filter', '$state', 'localizedMessages', 'commonService','context', 'homeService','bvDialog', 'bvDialogService', 'surveysService', 'repositoryService', 'imageService','documentViewerInvokeService', '$sce', 'bvSliderInstanceService', '$timeout'];


function userAnswersPreviewController($scope, $filter, $state, localizedMessages, commonService, context, homeService,bvDialog, bvDialogService, surveysService, repositoryService, imageService, documentViewerInvokeService, $sce, bvSliderInstanceService, $timeout){
	var bvModal;
	bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.popupTitle = localizedMessages.get('OVERVIEW');
	$scope.ui = {
	  label: {
	    multiple_choice: localizedMessages.get('MULTIPLE_CHOICE'),
	    open_answer: localizedMessages.get('OPEN_ANSWER'),
	    likert_scale: localizedMessages.get('LIKERT_SCALE'),
	    true_false: localizedMessages.get('TRUE_FALSE'),
	    meets_does_not_meet: localizedMessages.get('MEETS_DOES_NOT_MEET'),
	    multiple_choice_multi_response: localizedMessages.get('MULTIPLE_CHOICE_MULTI_RESPONSE'),
	    yes_no: localizedMessages.get('YES_NO'),
	    multiple_yes_no: localizedMessages.get('MULTIPLE_YES_NO'),
	    tabular_question: localizedMessages.get('TABLE_SUPPORT'),
	    response: localizedMessages.get('RESPONSE'),
	    by: localizedMessages.get('BY'),
	    complete: localizedMessages.get('COMPLETE'),
	    due: localizedMessages.get('DUE'),
	    priority: localizedMessages.get('PRIORITY'),
	    instructions: localizedMessages.get('INSTRUCTIONS'),
	    description: localizedMessages.get('DESCRIPTION'),
	    dueDate: localizedMessages.get('DUE_DATE'),
	    question: localizedMessages.get('QUESTION'),
	    overview: localizedMessages.get('OVERVIEW'),
	    section: localizedMessages.get('SECTION'),
	    of: localizedMessages.get('OF'),
	    responded: localizedMessages.get('RESPONDED'),
	    editResponse: localizedMessages.get('EDIT_RESPONSE'),
	    completed: localizedMessages.get('COMPLETED'),
	    mandatory: localizedMessages.get('MANDATORY')
	  }
	}
	$scope.questionDisplayNames = {
			'multiplechoice':$scope.ui.label.multiple_choice,
			'yesno':$scope.ui.label.yes_no,
			'openanswer':$scope.ui.label.open_answer,
			'likertscale':$scope.ui.label.likert_scale,
			'truefalse':$scope.ui.label.true_false,
			'meetsornot':$scope.ui.label.meets_does_not_meet,
      'multiplechoicemultiresponse':$scope.ui.label.multiple_choice_multi_response,
      'multiyesno':$scope.ui.label.multiple_yes_no,
			'tabularQuestion':$scope.ui.label.tabular_question
	};
	$scope.scrollbarConfig = {
			autoHideScrollbar : true
		}
    $scope.getShortLocalizedDate = function(date) {
   		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
	}
	$scope.userResponses = $scope.bvModalControllerInputs.userResponses;
	$scope.userObjectBaseUuid =  $scope.bvModalControllerInputs.objBaseUuid;
	$scope.surveyDetailsForPreview = $scope.bvModalControllerInputs.surveyDetailsForPreview;
	$scope.surveyDetailsForPreview.attachments = $scope.surveyDetailsForPreview.attachments || $scope.surveyDetailsForPreview.attachedObjectsList;
	$scope.description = $scope.surveyDetailsForPreview.objectDescription || $scope.surveyDetailsForPreview.description;
	$scope.canEditResponse = $scope.bvModalControllerInputs.canEditResponse;
	$scope.userName=$scope.bvModalControllerInputs.userName;
	$scope.openQuestionAreaForPreview = false;
	$scope.svgImgRightArrow = "resources/assets/svgs/rightArrow.svg";
  	$scope.svgImgDropDown = "resources/assets/svgs/downarrow.svg";
  	var currentUserVO = context.getModel().userVO;
  	
	$scope.toggleSectionQuestionAreaForPreview = function(section, previewIndex){
		console.log(section);
  		if($scope.clickedSummaryIndex == undefined){
  			$scope.clickedSummaryIndex = previewIndex;
  			$scope.surveyDetailsForPreview.sections[$scope.clickedSummaryIndex].isDisplayOpen = true;
  		} else if($scope.clickedSummaryIndex == previewIndex){
  			$scope.surveyDetailsForPreview.sections[$scope.clickedSummaryIndex].isDisplayOpen = !$scope.surveyDetailsForPreview.sections[$scope.clickedSummaryIndex].isDisplayOpen;
  		} else if($scope.clickedSummaryIndex != previewIndex){
  			$scope.surveyDetailsForPreview.sections[$scope.clickedSummaryIndex].isDisplayOpen = false;
  			$scope.clickedSummaryIndex = previewIndex;
  			$scope.surveyDetailsForPreview.sections[$scope.clickedSummaryIndex].isDisplayOpen = true;
  		}
  		$scope.surveySectionAttachmentsSliderOptions.items=section.attachments;
	}
	
    $scope.getShortLocalizedDate = function(date) {
   		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
	}
    $scope.getLocalizedDate = function(date) {
   		return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
	}
	$scope.getDocIcon = function(attachment) {
		return imageService.getImageForObject(attachment);
	};
	$scope.openAttachmentForPreview = function(attachment) {
		bvDialog.showDocumentPreview(attachment.objectBaseUuid, attachment);
	};
	var bvSliderServiceForAttachments;
	var sliderRenderCallbackForAttachments = function(){
		bvSliderServiceForAttachments = bvSliderInstanceService.instances[$scope.surveyAttachmentsSliderOptions.id];
		bvSliderServiceForAttachments.currentIndex = 0;
		$timeout(function(){
			bvSliderServiceForAttachments.fixButtons();
		});
	}
	var bvSliderServiceForSectionAttachments;
	var sliderRenderCallbackForSectionAttachments=function()
	{
		bvSliderServiceForSectionAttachments = bvSliderInstanceService.instances[$scope.surveySectionAttachmentsSliderOptions.id];
		bvSliderServiceForSectionAttachments.currentIndex = 0;
		$timeout(function(){
			bvSliderServiceForSectionAttachments.fixButtons();
		});
	}
	var bvSliderServiceForQuestionAttachments;
	var sliderRenderCallbackForQuestionAttachments=function()
	{
		bvSliderServiceForQuestionAttachments = bvSliderInstanceService.instances[$scope.surveyQuestionAttachmentsSliderOptions.id];
		bvSliderServiceForQuestionAttachments.currentIndex = 0;
		$timeout(function(){
			bvSliderServiceForQuestionAttachments.fixButtons();
		});
	}
	
	$scope.surveyAttachmentsSliderOptions = {
			id: 'SURVEYS_ATTACHMENTS_OVERVIEW',
			items: [],
			slideThrough: 1,
			shownItems: 3,
			smallButtons: true,
			renderCallback : sliderRenderCallbackForAttachments
		};
	$scope.surveySectionAttachmentsSliderOptions=
	{
			id:'SURVEYS_SECTIONS_ATTACHMENTS_OVERVIEW',
			items:[],
			slideThrough: 1,
			shownItems: 3,
			smallButtons: true,
			renderCallback : sliderRenderCallbackForSectionAttachments
	}
	$scope.surveyQuestionAttachmentsSliderOptions=
	{
			id:'SURVEYS_QUESTIONS_ATTACHMENTS_OVERVIEW',
			items:[],
			slideThrough: 1,
			shownItems: 3,
			smallButtons: true,
			renderCallback : sliderRenderCallbackForQuestionAttachments
	}
	
	surveysService.setQuestionBaseUuidList($scope.surveyDetailsForPreview);
	$scope.surveyAttachmentsSliderOptions.items=$scope.surveyDetailsForPreview.attachments;
	
	$scope.goToQuestion = function (surveyDetailsForPreview, sectionForSummary, questionIndex, sectionIndex, event) {
		console.log("go to question");
		var previewDetails = {};
		$scope.respondedMap = [];
		surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetailsForPreview.objectBaseUuid, sectionForSummary.objectBaseUuid, currentUserVO.objectBaseUuid).then(function (data) {
			if (data.surveyQuestionResponseList != undefined) {
				$scope.respondedMap = data.surveyQuestionResponseList;
			}
			previewDetails.surveyDetailsForPreview = surveyDetailsForPreview;
			previewDetails.sectionForSummary = sectionForSummary;
			previewDetails.questionIndex = questionIndex;
			previewDetails.sectionIndex = sectionIndex;
			previewDetails.respondedMap = $scope.respondedMap;
			previewDetails.sequentialSecCount = sectionForSummary.questions[questionIndex].sequentialSecCount;
			$scope.surveyQuestionAttachmentsSliderOptions.items = sectionForSummary.questions[questionIndex].attachments;
			surveysService.setDetailsForSurveyPreview(previewDetails);
			$scope.surveyAttachmentsSliderOptions.items = $scope.surveyDetailsForPreview.attachments;
			if ($state.current.name == 'home.openSurvey') {
				var surveysController = angular.element('#respondSurveyMain').scope() || angular.element('#surveyConsumerSectionDIV').scope() || angular.element('#surveyConsumerQuestionDIV').scope();
				surveysController.respondedMap = $scope.respondedMap;
				surveysController.showSectionQuestions(previewDetails.sectionForSummary, previewDetails.questionIndex, previewDetails.sectionIndex, true, $scope.respondedMap);
				surveysService.setDetailsForSurveyPreview(null);
			}
			bvDialogService.closeAllOpenDialogs();

			const surveyDataObj = {
				surveyBaseUuid: $scope.surveyDetailsForPreview.objectBaseUuid
			}

			if(currentUserVO.objectBaseUuid != $scope.bvModalControllerInputs.objBaseUuid)
				surveyDataObj.userBaseUuid = $scope.bvModalControllerInputs.objBaseUuid;
			
			$state.go('home.openSurvey', surveyDataObj);
		});
	}
}