n3aAuthService.$inject = ['bvDialog'];

function n3aAuthService(bvDialog){

    var n3aMap = {}

    this.setN3aMap = function(update) {
        if(update){
            n3aMap.n3aUserMigrationStatus = update;
        }
        return n3aMap;
    },

    this.getN3aMap = function(){
        if(n3aMap && 'n3aUserMigrationStatus' in n3aMap && 'MigrationStatusCode' in n3aMap.n3aUserMigrationStatus)
            return n3aMap;
        else
            return null;
    }

    this.getStatusCode = function(){
        var n3aMap = this.getN3aMap();
        if(n3aMap)
            return n3aMap.n3aUserMigrationStatus.MigrationStatusCode;
        else
            return null;
    }

    this.clearMap = function(){
        n3aMap = {}
    }

    this.putMapToSessionStorage = function(){
        if(!this.n3aMapIsEmpty()){
            sessionStorage.setItem('n3aMap', JSON.stringify(n3aMap));
        }
    }

    this.invokeMapFromSessionStorage = function(){
        var n3aMap_ = JSON.parse(sessionStorage.getItem('n3aMap'));
        if(n3aMap_){
            n3aMap = n3aMap_;
            sessionStorage.removeItem('n3aMap');
        }
    }

    this.n3aMapIsEmpty = function(){
        if(typeof n3aMap == 'object' && 'n3aUserMigrationStatus' in n3aMap)
            return false;
        else
            return true;
    }

    this.startN3aPage = function(gotoLanding){
        var n3aMap = this.getN3aMap();
        if(n3aMap){
            switch(n3aMap.n3aUserMigrationStatus.MigrationStatusCode) {
                case '0':
                    return false;
                    break;
                case '1':
                    bvDialog.n3aAuthDialog(n3aMap.n3aUserMigrationStatus, gotoLanding);
                    break;
                case '2':
                    bvDialog.n3aAuthDialog(n3aMap.n3aUserMigrationStatus, gotoLanding);
                    break;
                case '3':
                    bvDialog.n3aAuthDialog(n3aMap.n3aUserMigrationStatus, gotoLanding);
                    break;
                case '4':
//                	bvDialog.n3aAuthDialog(n3aMap.n3aUserMigrationStatus, gotoLanding);
                	window.location.href = 'https://boardvantage.nasdaq.com';
                    break;
                default:
//                	window.location.href = 'https://account.nasdaq.com';
                	window.location.href = 'https://boardvantage.nasdaq.com';
                    return false;
            }
        }
        else{
           return false;
        }
    }

}