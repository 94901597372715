var surveysModule = angular.module('surveys');

surveysModule
		.directive(
				'bvYesNo',["homeService","localizedMessages", "surveysService",
				function(homeService,localizedMessages, surveysService) {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&",
							disabled  : "=disabled"
						},
						template : '<div class="bv-question-center-yesno-div">'
								+  '<span class="bv-float-left bv-survey-question-yes-item">'
								+ '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-click="checkOption(\'yes\')" ng-if="!(qResponse.response==\'Yes\' || qResponse.response==\'yes\')"></div>' 
								+ '<div class="bv-default-fill-color-blue bv-svg-option-false bv-float-left "  ng-include="bvoptiontrue" ng-if="qResponse.response==\'Yes\' || qResponse.response==\'yes\'"></div>' 
								+ '<span class="bv-survey-yesno-span">{{labelYes}}</span>'
								+ '</span>'
								+  '<span class="bv-float-left">'
								+ '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-click="checkOption(\'no\')" ng-if="!(qResponse.response==\'No\' || qResponse.response==\'no\')"></div>' 
								+'<div class="bv-default-fill-color-blue bv-svg-option-true bv-float-left "  ng-include="bvoptiontrue" ng-if="qResponse.response==\'No\' || qResponse.response==\'no\'"></div>' 
								+ '<span class="bv-survey-yesno-span">{{labelNo}}</span>' 
								+ '</span>'
								+ '</div>',
								link: function(scope, element, attrs) {
						        	
						        	//<input type="radio" value="yes" id="yes" name="yesno" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" ng-checked="{{qResponse.response==\'Yes\' || qResponse.response==\'yes\'}}">'
									//<input type="radio" value="no" id="no" name="yesno" ng-model="qResponse.response" ng-change="action()" ng-disabled="disabled" ng-checked="{{qResponse.response==\'No\' || qResponse.response==\'no\'}}">'
									//var ctrlName="OpenSurveyController";
									scope.labelYes = localizedMessages.get("YES");
									scope.labelNo = localizedMessages.get("NO");
									
						        	scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg'; 
						        	scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg'; 
						        	homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
						        	surveysService.isSurveyResponseDirty = false;
						        	
						        	console.log(scope.qResponse);
						        	
						        	scope.checkOption=function(name)
						        	{
						        		if(scope.disabled == true)
						        			return;
						        		
						        		scope.action();
						        		console.log(name);
						        		homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
						        		surveysService.isSurveyResponseDirty = true;
						        		if(name=="yes")
						            	{	
						            		//scope.qResponse.response="yes";
						            		scope.qResponse.response = 'yes';
						            	}
						        		else
						        		{
						        			scope.qResponse.response = 'no';
						        		}
						        		
						        	}
//						        	scope.deselectOption=function(name)
//						        	{
//						        		//scope.action();
//						        		//homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
//						        		if(name=='no')
//						        		{
//						        			scope.qResponse.response=null;
//						        		}
//						        		else
//						        		{
//						        			scope.qResponse.response=null;
//						        		}
//						        	}
//						            scope.populateSurveyCheckList = function(name){
//						            	console.log(name);
//						            	if(name=="yes")
//						            	{	
//						            		scope.qResponse.response="\"yes\"";
//						            	}
//						            	else
//						            	{
//						            		scope.qResponse.response="no";
//						            	}
//						            };
//						            scope.isChecked = function(name){
//						            	if(scope.qResponse.responseList && scope.qResponse.responseList.length > 0){
//						            		return scope.qResponse.responseList.indexOf(name) > -1;
//						            	}
//						            };
						        }			
						
					}
				}]);
surveysModule
		.directive(
				'bvTrueFalse',["homeService","localizedMessages", "surveysService",
				function(homeService, localizedMessages, surveysService) {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&",
							disabled  : "=disabled"
						},
						template : '<div class="bv-question-center-truefalse-div">'
							+ '<span class="bv-float-left bv-survey-question-yes-item">'
							+ '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-click="checkOption(\'true\')" ng-if="!(qResponse.response==\'True\' || qResponse.response==\'true\')"></div>' 
							+ '<div class="bv-default-fill-color-blue bv-svg-option-false bv-float-left "  ng-include="bvoptiontrue" ng-if="qResponse.response==\'True\' || qResponse.response==\'true\'"></div>' 
							+ '<span class="bv-float-left bv-survey-question-yesno-label ">{{labelTrue}}</span>'
							+ '</span>'
							+  '<span class="bv-float-left">'
							+ '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-click="checkOption(\'false\')" ng-if="!(qResponse.response==\'False\' || qResponse.response==\'false\')"></div>' 
							+'<div class="bv-default-fill-color-blue bv-svg-option-true bv-float-left "  ng-include="bvoptiontrue" ng-if="qResponse.response==\'false\' || qResponse.response==\'False\'"></div>' 
							+ '<span class="bv-float-left bv-survey-question-yesno-label">{{labelFalse}}</span>' 
							+ '</span>'
							+'</div>',
								link: function(scope, element, attrs) {
									scope.labelTrue = localizedMessages.get("SURVEY_TRUE");
									scope.labelFalse = localizedMessages.get("SURVEY_FALSE");
						        	
						        	//<input type="radio" value="yes" id="yes" name="yesno" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" ng-checked="{{qResponse.response==\'Yes\' || qResponse.response==\'yes\'}}">'
									//<input type="radio" value="no" id="no" name="yesno" ng-model="qResponse.response" ng-change="action()" ng-disabled="disabled" ng-checked="{{qResponse.response==\'No\' || qResponse.response==\'no\'}}">'
									//<input type="radio" value="meets" id="meets" name="meetsornot" ng-model="qResponse.response" ng-change="action()" ng-disabled="disabled" ng-checked="{{qResponse.response==\'Meets\' || qResponse.response==\'meets\'}}">'
									//<input type="radio" value="doesNotMeet" id="doesNotMeet" name="meetsornot" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" ng-checked="{{qResponse.response==\'Does not meet\' || qResponse.response==\'does not meet\'}}">'
									//<input type="radio" value="true" id="true" name="truefalse" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" ng-checked="{{qResponse.response==\'True\' || qResponse.response==\'true\'}}">
									//<input type="radio" value="false" id="false" name="truefalse" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" ng-checked="{{qResponse.response==\'False\' || qResponse.response==\'false\'}}">'
									
									
						        	scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg'; 
						        	scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg'; 
						        	console.log(scope.qResponse);
						        	
						        	scope.checkOption=function(name)
						        	{
						        		if(scope.disabled == true)
						        			return;
						        		scope.action();
						        		console.log(name);
						        		homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
						        		surveysService.isSurveyResponseDirty = true;
						        		
						        		if(name=="true")
						            	{	
						            		scope.qResponse.response="true";
//						            		scope.qResponse.responseList = [];
//						        			scope.qResponse.responseList[0] = "true";
						            	}
						        		else
						        		{
						        			scope.qResponse.response="false";
//						        			scope.qResponse.responseList = [];
//						        			scope.qResponse.responseList[0] = "false";
						        		}
						        		
						        	}
//						        	scope.deselectOption=function(name)
//						        	{
//						        		//scope.action();
//						        		
//						        			scope.qResponse.response=null;
//						        			//homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
//						        		
//						        		
//						        	}
//						            scope.populateSurveyCheckList = function(name){
//						            	console.log(name);
//						            	if(name=="yes")
//						            	{	
//						            		scope.qResponse.response="\"yes\"";
//						            	}
//						            	else
//						            	{
//						            		scope.qResponse.response="no";
//						            	}
//						            };
//						            scope.isChecked = function(name){
//						            	if(scope.qResponse.responseList && scope.qResponse.responseList.length > 0){
//						            		return scope.qResponse.responseList.indexOf(name) > -1;
//						            	}
//						            };
						        }
					}
				}]);
surveysModule
.directive(
		'bvMeetsOrNot',["homeService","localizedMessages", "surveysService",
		function(homeService, localizedMessages, surveysService) {
			return {
				restrict : 'E',
				scope : {
					qOptions : "=options",
					qResponse : "=response",
					action	  : "&",
					disabled  : "=disabled"
				},
				template : '<div class="bv-question-center-meetsdoesnotmeet-div">'
					+  '<span class="bv-float-left bv-survey-question-yes-item">'
					+ '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-click="checkOption(\'meets\')" ng-if="!(qResponse.response==\'meets\' || qResponse.response==\'Meets\')"></div>' 
					+ '<div class="bv-default-fill-color-blue bv-svg-option-false bv-float-left "  ng-include="bvoptiontrue" ng-if="qResponse.response==\'meets\' || qResponse.response==\'Meets\'"></div>' 
					+ '<span class="bv-float-left bv-survey-question-yesno-label ">{{labelMeets}}</span>'
					+ '</span>'
					+  '<span class="bv-float-left">'
					+ '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-click="checkOption(\'does not meet\')" ng-if="!(qResponse.response==\'Does Not Meet\' || qResponse.response==\'does not meet\' || qResponse.response==\'does_not_meet\')"></div>' 
					+'<div class="bv-default-fill-color-blue bv-svg-option-true bv-float-left "  ng-include="bvoptiontrue" ng-if="qResponse.response==\'Does Not Meet\' || qResponse.response==\'does not meet\' || qResponse.response==\'does_not_meet\'"></div>' 
					+ '<span class="bv-float-left bv-survey-question-yesno-label">{{labelDoesNotMeet}}</span>' 
					+ '</span>'
					+ '</div>',
						link: function(scope, element, attrs) {
							scope.labelMeets = localizedMessages.get("MEETS");
							scope.labelDoesNotMeet = localizedMessages.get("DOES_NOT_MEET");
				        	
				        	//<input type="radio" value="yes" id="yes" name="yesno" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" ng-checked="{{qResponse.response==\'Yes\' || qResponse.response==\'yes\'}}">'
							//<input type="radio" value="no" id="no" name="yesno" ng-model="qResponse.response" ng-change="action()" ng-disabled="disabled" ng-checked="{{qResponse.response==\'No\' || qResponse.response==\'no\'}}">'
							//<input type="radio" value="meets" id="meets" name="meetsornot" ng-model="qResponse.response" ng-change="action()" ng-disabled="disabled" ng-checked="{{qResponse.response==\'Meets\' || qResponse.response==\'meets\'}}">'
							//<input type="radio" value="doesNotMeet" id="doesNotMeet" name="meetsornot" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" ng-checked="{{qResponse.response==\'Does not meet\' || qResponse.response==\'does not meet\'}}">'
							
							
				        	scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg'; 
				        	scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg'; 
				        	console.log(scope.qResponse);
				        	
				        	scope.checkOption=function(name)
				        	{
				        		if(scope.disabled == true)
				        			return;
				        		scope.action();
				        		console.log(name);
				        		
				        		if(name=="meets")
				            	{	
				            		scope.qResponse.response="meets";
//				            		scope.qResponse.responseList = [];
//				        			scope.qResponse.responseList[0] = "meets";
				            		
				            	}
				        		else
				        		{
				        			scope.qResponse.response="does not meet";
//				        			scope.qResponse.responseList = [];
//				        			scope.qResponse.responseList[0] = "does not meet";
				        		}
				        		homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
				        		surveysService.isSurveyResponseDirty = true;
				        		
				        	}
//				        	scope.deselectOption=function(name)
//				        	{
//				        		//scope.action();
//				        		
//				        			scope.qResponse.response=null;
//				        			//homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
//				        		
//				        		
//				        		
//				        	}
//				            scope.populateSurveyCheckList = function(name){
//				            	console.log(name);
//				            	if(name=="yes")
//				            	{	
//				            		scope.qResponse.response="\"yes\"";
//				            	}
//				            	else
//				            	{
//				            		scope.qResponse.response="no";
//				            	}
//				            };
//				            scope.isChecked = function(name){
//				            	if(scope.qResponse.responseList && scope.qResponse.responseList.length > 0){
//				            		return scope.qResponse.responseList.indexOf(name) > -1;
//				            	}
//				            };
				        }
			}
		}]);
surveysModule
		.directive(
				'bvCommentsBox',
				function() {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&",
							disabled  : "=disabled"
						},
						template : '<textarea ta-bind="text" ng-disabled="disabled" ng-model="qResponse.response" ng-change="action()" style="width:100%;height:200px;"></textarea>'
					}
				});
surveysModule
		.directive(
				'bvOpenAnswer',["homeService","localizedMessages", "surveysService",
				function(homeService, localizedMessages, surveysService) {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&",
							disabled  : "=disabled"
						},
						template : /*'<textarea class="openAnswerClass" ta-bind="text" ta-readonly="disabled" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" style="width:100%;height:200px;"></textarea>'
									*/
							'<div class="bv-survey-openAnswer-div" stop-event="touchend">'
							+'<textarea ng-trim="false" placeholder="{{placeHolderValue}}" name="surveyDesc" id="instructions" rows="5" class="bv-input-text survey-textarea bv-default-background-color-gray-light" maxlength="600" ng-change="action()" ng-model="qResponse.response" style="font-weight:normal;height:140px;"></textarea>'
							+'</div>',
							link: function(scope, element, attrs) {
								scope.placeHolderValue = localizedMessages.get("ENTER_RESPONSE_HERE");
								homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
								surveysService.isSurveyResponseDirty = true;
					}
				}
			}]);

surveysModule
		.directive(
				'bvMultiChoice',["homeService","localizedMessages","surveysService",
				function(homeService, localizedMessages, surveysService) {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&",
							disabled  : "=disabled"
						},
						template :'<div class="bv-survey-question-center-div bv-survey-question-multichoice-div bv-question-detail-div scroll-list-items" ng-scrollbars ng-scrollbars-config="scrollbarConfig">'
							+ '<ul><li ng-repeat="item in qOptions.multipleChoiceValues" class="bv-width-100 bv-float-left bv-survey-multipleChoice-list-item">'
							+  '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-if="!(qResponse.response == item.name)" ng-click="selectedOption(item.name)"></div>' 
							+  '<div class="bv-default-fill-color-blue bv-svg-option-true bv-float-left"  ng-include="bvoptiontrue" ng-if="qResponse.response == item.name"></div>' 
							+ '<span class="bv-survey-multipleChoice-span">{{item.name}}</span>'
							+ '</li></ul>'
							+ '</div>',	
							
								link: function(scope, element, attrs) {
						        	
						        	//'<input type="checkbox" value="{{item.name}}" id="{{item.name}}" name="multichoicemultivalue" ng-disabled="disabled" ng-change="populateSurveyCheckList(item.name)" ng-model="surveyCheckboxResponse" ng-checked="isChecked(item.name)">'
									//'<input type="radio" value="{{item.name}}" id="{{item.name}}" ng-model="qResponse.response" ng-disabled="disabled" ng-change="action()" name="multichoice" ng-checked="{{qResponse.response == item.name}}">'
									
						        	scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg'; 
						        	scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg'; 
						        	
						        	scope.selectedOption=function(name)
						        	{
						        		if(scope.disabled == true)
						        			return;
						        		scope.action();
						        		homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
						        		surveysService.isSurveyResponseDirty = true;
						        		scope.qResponse.response=name;
//						        		scope.qResponse.responseList = [];
//					        			scope.qResponse.responseList[0] = name;
//						        		console.log(item.name);
						        	}
//						        	scope.dselectedOption=function(name)
//						        	{
//						        		scope.qResponse.response=null;
//						        		//homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
//						        	}
						        	
//						            scope.populateSurveyCheckList = function(name){
//						            	scope.action();
//						            	if(scope.qResponse.responseList.indexOf(name) == -1){
//						            		scope.qResponse.responseList.push(name);
////						            		scope.qResponse.response = scope.qResponse.responseList.join(",");
//						            		scope.qResponse.response = scope.qResponse.response.join(",");
//						            	}else{
//						            		var index = scope.qResponse.responseList.indexOf(name);
//						            		if(index>-1){
//						            			scope.qResponse.responseList.splice(index,1);
//						            			//scope.qResponse.response = scope.qResponse.responseList.join(",");
//						            			scope.qResponse.response = scope.qResponse.response.join(",");
//						            		}
//						            	}
//						            };
//						            scope.isChecked = function(name){
//						            	if(scope.qResponse.responseList && scope.qResponse.responseList.length > 0){
//						            		return scope.qResponse.responseList.indexOf(name) > -1;
//						            	}
//						            };
						        }
								
					}
				}]);

surveysModule
		.directive(
				'bvMultiplechoiceMultiresponse',["homeService","localizedMessages", "surveysService",
				function(homeService, localizedMessages, surveysService) {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&",
							disabled  : "=disabled"
						},
						template : '<div class="bv-survey-question-center-div bv-survey-question-multichoice-div bv-question-detail-div scroll-list-items" ng-scrollbars ng-scrollbars-config="scrollbarConfig">'
								+ '<ul><li ng-repeat="item in qOptions.multipleChoiceValues" class="bv-width-100 bv-float-left bv-survey-multipleChoice-list-item">'
								+  '<div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-if="!(isChecked(item.name))" ng-click="populateSurveyCheckList(item.name)"></div>' 
								+  '<div class="bv-default-fill-color-blue bv-svg-option-true bv-float-left"  ng-include="bvoptiontrue" ng-if="isChecked(item.name)" ng-click="populateSurveyCheckList(item.name)"></div>' 
								+ '<span class="bv-survey-multipleChoice-span">{{item.name}}</span>'
								+ '</li></ul>'
								+ '</div>',
						        link: function(scope, element, attrs) {
						        	
						        	scope.scrollbarConfig = {
					        			autoHideScrollbar : true
					        		}
						        	homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
						        	surveysService.isSurveyResponseDirty = false;
						        	//'<input type="checkbox" value="{{item.name}}" id="{{item.name}}" name="multichoicemultivalue" ng-disabled="disabled" ng-change="populateSurveyCheckList(item.name)" ng-model="surveyCheckboxResponse" ng-checked="isChecked(item.name)">'
						        	scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg'; 
						        	scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg'; 
						            scope.populateSurveyCheckList = function(name){
						        		if(scope.disabled == true)
						        			return;
						            	scope.action();
						            	console.log(scope.qResponse.responseList);
						            	if(scope.qResponse.responseList.indexOf(name) == -1){
						            		scope.qResponse.responseList.push(name);
						            		scope.qResponse.response = scope.qResponse.responseList.join(",");
						            	}else{
						            		var index = scope.qResponse.responseList.indexOf(name);
						            		if(index>-1){
						            			scope.qResponse.responseList.splice(index,1);
						            			scope.qResponse.response = scope.qResponse.responseList.join(",");
						            		}
						            	}
						            	if(scope.qResponse.responseList.length>0)
						            	{
						            		homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
						            		surveysService.isSurveyResponseDirty = true;
						            	}
						            	else
						            	{
						            		homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
						            		surveysService.isSurveyResponseDirty = false;
						            	}
						            };
						            scope.isChecked = function(name){
						            	if(scope.qResponse.responseList && scope.qResponse.responseList.length > 0){
						            		return scope.qResponse.responseList.indexOf(name) > -1;
						            	}
						            };
						        }
					}
				}]);

surveysModule
		.directive(
				'bvLikertScale',["homeService","localizedMessages", "surveysService",
				function(homeService, localizedMessages, surveysService) {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&",
							disabled  : "=disabled"
						},
						template : '<div class="bv-survey-question-likert-scale-div scroll-list-items" ng-scrollbars ng-scrollbars-config="scrollbarConfig">'
								+ '<ul><li ng-repeat="item in qOptions.likertScaleValues" class="bv-width-100 bv-float-left bv-survey-multipleChoice-list-item">'
								+'<span><div class="bv-default-fill-color-black bv-svg-option-false bv-float-left "  ng-include="bvoptionfalse" ng-click="selectedOption(item.value)" ng-if="!(qResponse.response == item.value)"></div></span>'
								+'<span><div class="bv-default-fill-color-blue bv-svg-option-true bv-float-left "  ng-include="bvoptiontrue" ng-if="(qResponse.response == item.value)"></div></span>'
								+'<span class="bv-float-right">{{item.value}}</span>'
								+ '<span class="bv-survey-multipleChoice-span">{{item.name}}</span>'
								+'<hr style="width:100%;padding: 0px; margin: 0px; position:relative;top:3px;">'
								+ '</li></ul>'
								+'</div>',
							     link: function(scope, element, attrs) {
							        	
							    	 scope.scrollbarConfig = {
							        			autoHideScrollbar : true
							        		}
							    	 //<input type="radio" value="{{item.name}}" id="{{item.name}}" name="likertscale" ng-change="action()" ng-disabled="disabled" ng-model="qResponse.response" ng-checked="{{qResponse.response == item.name}}">'
							        	scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg'; 
							        	scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg'; 
							        	
							        	scope.selectedOption=function(value)
							        	{
							        		if(scope.disabled == true)
							        			return;
							        		homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
							        		surveysService.isSurveyResponseDirty = true;
							        		scope.action();
							        		scope.qResponse.response=value;
//							        		scope.qResponse.responseList = [];
//						        			scope.qResponse.responseList[0] = name;
							        	};
//							        	scope.deselectOption=function(name)
//							        	{
//							        		//scope.action();
//							        		//homeService.set_current_module_has_unsaved_changes(false, "OpenSurveyController");
//							        		scope.qResponse.response=null;
//							        	}
							        	
//							            scope.populateSurveyCheckList = function(name){
//							            	console.log(scope.qOptions);
//							            	
//							            	scope.action();
//							            	if(scope.qResponse.responseList.indexOf(name) == -1){
//							            		scope.qResponse.responseList.push(name);
//							            		scope.qResponse.response = scope.qResponse.responseList.join(",");
//							            	}else{
//							            		var index = scope.qResponse.responseList.indexOf(name);
//							            		if(index>-1){
//							            			scope.qResponse.responseList.splice(index,1);
//							            			scope.qResponse.response = scope.qResponse.responseList.join(",");
//							            		}
//							            	}
//							            };
//							            scope.isChecked = function(name){
//							            	if(scope.qResponse.responseList && scope.qResponse.responseList.length > 0){
//							            		return scope.qResponse.responseList.indexOf(name) > -1;
//							            	}
//							            };
							        }

					}
				}]);
surveysModule
		.directive(
				'bvSurveyQuestion',
				function() {
					return {
						restrict : 'E',
						scope : {
							qOptions : "=options",
							qResponse : "=response",
							action	  : "&"
						},
						template : '<div ng-switch="qOptions.questionType">'
								+ '<bv-yes-no ng-switch-when="yesno"></bv-yes-no>'
								+ '<bv-multi-choice ng-switch-when="multiplechoice"></bv-multi-choice>'
								+ '<bv-open-answer ng-switch-when="openanswer"></bv-open-answer>'
								+ '<bv-likert-scale options="question" ng-switch-when="likertscale"></bv-likert-scale>'
								+ '<bv-true-false ng-switch-when="truefalse"></bv-true-false>'
								+ '<bv-multiplechoice-multiresponse options="question" ng-switch-when="multiplechoicemultiresponse"></bv-multiplechoice-multiresponse>'
								+ '</div>'
					}
				});

createSurveyModule.directive('surveyQuestionDiv', function(){
    return {
      restrict: 'E',
      scope: false,
      templateUrl: 'modules/surveys/QuestionCreateDiv.tpl.html'
    }
  });
createSurveyModule.directive('surveySectionDiv', function(){
    return {
      restrict: 'E',
      scope: false,
      templateUrl: 'modules/surveys/SectionCreateDiv.tpl.html'
    }
  });
createSurveyModule.directive('surveyUsersList', function(){
    return {
      restrict: 'E',
      scope: false,
      templateUrl: 'modules/surveys/UsersListDisplay.tpl.html'
    }
  });

createSurveyModule.directive('bvSectionScrollFix', function(){
    return {
      restrict: 'A',
      link: function($scope, el, $attr){
    	  if($scope.section !== undefined)
    		  $scope.section.offsetTop = el[0].offsetTop;
      }
    }
  });

