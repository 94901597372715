homeService.$inject = ['$q', 'ajaxService', 'responseValidatorService', 'context', 'brand', 'Upload', 'bvDialogService', 'localizedMessages', 'bvDialog', '$rootScope', 'companyUserService', 'bvSliderInstanceService', '$state', 'legacyUserService'];

function homeService($q, ajaxService, responseValidatorService, context, brand, Upload, bvDialogService, localizedMessages, bvDialog, $rootScope, companyUserService, bvSliderInstanceService, $state, legacyUserService) {
 	// The public API of the service

 	var templateUrl = {}
 	var homeContext = {}
 	var countItems = {}
 	var companyLogo = {}
 	var teamSpaceLogo = {}
 	var preloadedLogo = {}
 	var brandingSettings = {}
    	homeContext.modules = []
 	homeContext.moduleStates  = []
 	var isCurrentUserAllowedToCreateSignatureInDocument = false;

 	var current_module_has_unsaved_changes = false;
 	var module_with_unsaved_changes = '';
 	var getTeamSpaceLogoPromiseMap = {};
    let uniq;

    let emailUnreadCountIntervalId = -1;
    let emailUnreadCount = 0;

 	var service = {

 		set_current_module_has_unsaved_changes : set_current_module_has_unsaved_changes,
 		get_current_module_has_unsaved_changes : get_current_module_has_unsaved_changes,

 		setIsCurrentUserAllowedToCreateSignature : function(isCurrentUserAllowedToCreateSignatureInDocument){
            this.isCurrentUserAllowedToCreateSignatureInDocument = isCurrentUserAllowedToCreateSignatureInDocument;
        },

        isCurrentUserAllowedToCreateSignature : function(){
            return this.isCurrentUserAllowedToCreateSignatureInDocument;
        },
// 		getCurrentUserData : function(userBaseUuid) {
// 			var servmethod = bvadminServiceMethodStr + "getCurrentUserData";
// 			var params="";
// 			var request = httpService.httpServicePost(servmethod + params);
// 			return request.then(function(response) {
// 				if(responseValidator.isSuccess(response.data)) {
// 					return response.data;
// 				}
// 				return null;
// 			});
// 		},
 		redirectIfHtmlNotEnabled :function()
 		{
 			var request=ajaxService.redirectIfHtmlNotEnabled();
 			return request.then(function(data) {
 					if(data != null && !data.hasOwnProperty("exceptionName") && !data.hasOwnProperty("exceptionCode" )) {
 						return data;
 					}
 					return null;
 			});
 		},
 		getPortals :function(companyBaseUuid)
 		{
 			var request=ajaxService.getPortals(companyBaseUuid);
 			return request
 				.then(function(data) {
 					return data;
 				}, function(data) {
 					return null;
 			});
 		},
 		getPortalsForHTML :function(companyBaseUuid)
 		{
 			var request=ajaxService.getPortalsForHTML(companyBaseUuid);
 			return request
 				.then(function(data) {
 					return data;
 				}, function(data) {
 					return null;
 			});
 		},
 		getLinkedCompanies :function()
 		{
 			var request=ajaxService.getLinkedCompanies();
 			return request
 				.then(function(data) {
 					return data;
 				}, function(data) {
 					return null;
 			});
 		},
 		getPortalData :function()
 		{
 			var request=ajaxService.getPortalData();
 			return request
 				.then(function(data) {
 					return data;
 				}, function(data) {
 					return null;
 			});
 		},
 		changeTeamSpace :function(companybaseuuid,portalBaseUuid, setdeafult)
 		{
 			var request=ajaxService.changeTeamSpace(companybaseuuid,portalBaseUuid,setdeafult);
 			return request
 				.then(function(data) {

 					return data;
 				}, function(data) {
 					return null;
 			});
 		},
 		getSecurePassThroughRefIDJson : function()
 		{
 			var request=ajaxService.getSecurePassThroughRefIDJson();
 			return request
 				.then(function(data) {

 					return data;
 				}, function(data) {
 					return null;
 			});
 		},
 		templateUrl: function(update) {
 			if(update){
 				templateUrl.val = update;
 			}
 			return templateUrl.val;
 		},
 		homeContext: function(update) {
 			if(update){
 				homeContext = update;
 			}
 			return homeContext;
 		},
 		countItems: function(update) {
 			if(update){
 				countItems.val = update;
 			}
 			return countItems.val;
 		},
 		getOpenItemsCount:function(){
 			var request=ajaxService.getOpenItemsCount();
 			return request.then(function(data)
 					{
 						if(data)
 						{
 							return data;
 						}

 						return null;
 					});
 		},
 		setModuleSettingsForPortal: function(moduleMapping, defaultLandingModule, defaultJupLandingModule) {
 			var request=ajaxService.setModuleSettingsForPortal(moduleMapping, defaultLandingModule, defaultJupLandingModule);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		startMergingCompany: function() {
 			var request=ajaxService.startMergingCompany();
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		getStausValueForMerge: function(state) {
 			var request=ajaxService.getStausValueForMerge(state);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		getMergeStatusForCompanyUsingStateandTime: function(state, lastProcessedTime) {
 			var request=ajaxService.getMergeStatusForCompanyUsingStateandTime(state, lastProcessedTime);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		registerDeviceForDirectorDesk: function(userEmailId) {
 			var request=ajaxService.registerDeviceForDirectorDesk(userEmailId);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		loginToDirectorDesk: function(loginId, password) {
 			var request=ajaxService.loginToDirectorDesk(loginId, password);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		isDeviceRegisteredForMerge: function() {
 			var request=ajaxService.isDeviceRegisteredForMerge();
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		generateRegistratonCode: function(emailId) {
 			var request=ajaxService.generateRegistratonCode(emailId);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}
 				return null;
 			});
 		},
 		getBusinessObjectVOJSONByBaseuuid:function(objectBaseUuid)
 		{
 			var request=ajaxService.getBusinessObjectVOJSONByBaseuuid(objectBaseUuid);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}

 				return null;
 			});
 		},
 		getDocPropJSONByBaseUuid:function(objectBaseUuid)
 		{
 			var request=ajaxService.getDocPropJSONByBaseUuid(objectBaseUuid);
 			return request.then(function(data)
 			{
 				if(data)
 				{
 					return data;
 				}

 				return null;
 			});
 		},
 		updatePortalBranding:function(portalBaseUuid, portalVO)
 		{
 			var request=ajaxService.updatePortalBranding(portalBaseUuid, portalVO);
 			return request.then(function(data)
 					{
 						if(data)
 						{
 							return data;
 						}

 						return null;
 					});
 		},
 		updateTeamspaceLogo:function(fileTransferVO)
 		{
 			var request=ajaxService.updateTeamspaceLogo(fileTransferVO);
 			return request.then(function(data)
 					{
 						if(data)
 						{
 							return data;
 						}

 						return null;
 					});
 		},

 		getCompanyTSLogo:function(checkCompanyLogo)
 		{
 			var request = ajaxService.getCompanyTSLogo(checkCompanyLogo);
 			return request.then(function(data)
 					{
 				if(data)
 				{
 					return data;
 				}

 				return null;
 					}
 			);
 		},
 		getCompanyLogoOnly:function(checkCompanyLogo)
 		{
 			var request = ajaxService.getCompanyLogo(checkCompanyLogo);
 			return request.then(function(data)
 					{
 				if(data)
 				{
 					return data;
 				}

 				return null;
 					}
 			);
 		},
 		getBrandingSettings:function(portalBaseUuid)
 		{
 			var request = ajaxService.getBrandingSettings(portalBaseUuid);
 			return request.then(function(data)
 				{
 					if(data)
 					{
 						return data;
 					}

 					return null;
 				}
 			);
 		},
 		pinSetupDummy: function() {
 			var request = ajaxService.pinSetupDummy();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		zoomInDummy: function() {
 			var request = ajaxService.zoomInDummy();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		zoomOutDummy: function() {
 			var request = ajaxService.zoomOutDummy();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		actualSizeDummy: function() {
 			var request = ajaxService.actualSizeDummy();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getPasswordPreferences: function() {
 			var request = ajaxService.getPasswordPreferences();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getPreferencesMenuOptions: function() {
 			var request = ajaxService.getPreferencesMenuOptions();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		loadMySubscriptions: function() {
 			var request = ajaxService.loadMySubscriptions();
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		loadSubscriptionsToMe: function() {
 			var request = ajaxService.loadSubscriptionsToMe();
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		unsubscribeFromContext: function(subscriberUuid, objectToWatchBaseUuid, subscriptionType) {
 			var request = ajaxService.unsubscribeFromContext(subscriberUuid, objectToWatchBaseUuid, subscriptionType);
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		getUsersPurgedVaultInstallations: function(userBaseUuid) {
 			var request = ajaxService.getUsersPurgedVaultInstallations(userBaseUuid);
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		getUserVaultInstallations: function(userBaseUuid) {
 			var request = ajaxService.getUserVaultInstallations(userBaseUuid);
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		deleteAllAnnotationsByCreator: function() {
 			var request = ajaxService.deleteAllAnnotationsByCreator();
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		deleteAllAnnotationsInPortal: function() {
 			var request = ajaxService.deleteAllAnnotationsInPortal();
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		assignModuleCreators: function(moduleCreatorsObjectString,toDelModuleCreatorsObjectString) {
 			var request = ajaxService.assignModuleCreators(moduleCreatorsObjectString,toDelModuleCreatorsObjectString);
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 		getModuleCreatorsJSON: function(){
 			var request = ajaxService.getModuleCreatorsJSON();
 			return request.then(function(data) {
 				if (data) {
 					return data;
 				}
 				return null;
 			});
 		},
 //		removeUserFromCreatorListJSON : function(id, userObjectBaseUuid){
 //			var request = ajaxService.removeUserFromCreatorListJSON(id, userObjectBaseUuid);
 //			return request.then(function(data) {
 //				if (data) {
 //					return data;
 //				}
 //				return null;
 //			});
 //		},
 		changeUserPassword: function(currentpwd, newpwd) {
 			var request = ajaxService.changeUserPasswordFromPreferences(currentpwd, newpwd);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		ipadDetection: function() {
 			var iOS = MobileUtil.isIOS();
 			//console.log("iOS:",iOS);
 			//iOS = true;
 //			if(iOS) {
 //				var element = document.createElement('link');
 //				element.setAttribute('rel','stylesheet');
 //				element.setAttribute('type','text/css');
 //				element.setAttribute('href','resources/css/iPhone.css');
 //
 //				document.getElementsByTagName('head')[0].appendChild(element);
 //			}
 			//else{
 			//	console.log('false')
 			//}
 			return iOS;
 		},
 		getAllSearchList : function(searchString)
 		{
      uniq = moment().valueOf();
 			var request = ajaxService.getAllSearchList(searchString, uniq);
 			return request.then(function(data) {
 				if(responseValidatorService.isSuccess(data) && data.config.uniq == uniq)
 				{
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getSyncProgress : function()
 		{
 			var request = ajaxService.getSyncProgress();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		setTeamSidebarVisibility : function(visibility)
 		{
 			var request = ajaxService.setTeamSidebarVisibility(visibility);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		isTeamSidebarVisible : function()
 		{
 			var request = ajaxService.isTeamSidebarVisible();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getMyUnreadMessageCount : function() {
 			var request = ajaxService.getMyUnreadMessageCount();
 			return request.then(function(response) {
 				if(response) {
 					return response.data;
 				}
 				return null;
 			});
 		},
 		getEmailUnreadCountValue: function() {
 		    return emailUnreadCount;
 		},
 		getEmailUnreadCountFromApi : function() {
            var request = ajaxService.getEmailUnreadCount();
            return request.then(function(response) {
                if(response) {
                    emailUnreadCount = response.data;
                    return response.data;
                }
                return null;
            });
        },
        clearEmailUnreadCountInterval: function () {
            clearInterval(emailUnreadCountIntervalId);
        },
        startEmailUnreadCountInterval: function () {
            emailUnreadCountIntervalId = setInterval(() => {
              this.getEmailUnreadCountFromApi();
            }, 60000);
        },
 		getInboxUnreadCount : function() {
 			var request = ajaxService.getInboxUnreadCount();
 			return request.then(function(response) {
 				if(response) {
 					return response.data;
 				}
 				return null;
 			});
 		},
 		getInboxUnreadCountForPolling : function() {
 			var request = ajaxService.getInboxUnreadCountForPolling();
 			return request.then(function(response) {
 				if(response) {
 					return response.data;
 				}
 				return null;
 			});
 		},
 		getSentToMePushCount : function() {
 			var request = ajaxService.getSentToMePushCount();
 			return request.then(function(response) {
 				if(response) {
 					return response.data;
 				}
 				return null;
 			});
 		},
 		getDocumentsForSignaturesCount : function()
 		{
 			var request=ajaxService.getDocumentsForSignaturesCount();
 			return request.then(function(data)
 			{
 				if(responseValidatorService.isSuccess(data))
 				{
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getApprovalsCount : function()
 		{
 			var request=ajaxService.getCountOfNotRespondedOpenApprovalsForCurrentUser();
 			return request.then(function(data)
 			{
 				if(responseValidatorService.isSuccess(data))
 				{
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getSurveysCount : function()
 		{
 			var request=ajaxService.getCountOfNotRespondedOpenSurveysForCurrentUser();
 			return request.then(function(data)
 			{
 				if(responseValidatorService.isSuccess(data))
 				{
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getNewItemsCountForAllTeamspaces : function()
 		{
 			var request=ajaxService.getNewItemsCountForAllTeamspaces();
 			return request.then(function(data)
 			{
 				if(responseValidatorService.isSuccess(data))
 				{
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getBriefcaseCount : function()
 		{
 			var request=ajaxService.getBriefcaseCount();
 			return request.then(function(data)
 			{
 				if(responseValidatorService.isSuccess(data))
 				{
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getMeetingsCount : function()
 		{
 			var startDate = StringUtils.momentL().subtract(context.getModel().htmlCalendarRangeBackward,'month').set('date',1).set('hour',0).set('minute',0).set('second',0).format('MMM D, YYYY hh:mm:ss A');
 			var endDate = StringUtils.momentL().add(context.getModel().htmlCalendarRangeForward,'month').set('date',0).set('hour',23).set('minute',59).set('second',59).format('MMM D, YYYY hh:mm:ss A');
 			var request=ajaxService.getAllCalendarCountJSON(startDate, endDate);
 			return request.then(function(data)
 			{
 				if(responseValidatorService.isSuccess(data))
 				{
 					return data.data;
 				}
 				return null;
 			});
 		},
 		purgeRemoteBriefcase : function(companyBaseUuid, userBaseUuid, remoteBaseUuid)
 		{
 			var request = ajaxService.purgeRemoteBriefcase(companyBaseUuid, userBaseUuid, remoteBaseUuid);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		editChallengeQuestions : function(questionAndAnswerList, portalId)
 		{
 			var request = ajaxService.editChallengeQuestions(questionAndAnswerList, portalId);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getPermalinkOrResourceVersionId : function(plink, resourceId)
 		{
 			console.log("Inside Home service getPermalinkOrResourceVersionId "+resourceId );
 			var request = ajaxService.getPermalinkOrResourceVersionId(plink, resourceId);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getTeamSpaceLogo : function(teamSpaceBaseUuid) {
 		    var boardbookTeamSpaceLogo = {};
 		    var logoRequest = null;
 		    if(getTeamSpaceLogoPromiseMap[teamSpaceBaseUuid] != undefined && getTeamSpaceLogoPromiseMap[teamSpaceBaseUuid] != null) {
 		    	logoRequest = getTeamSpaceLogoPromiseMap[teamSpaceBaseUuid];
 		    }
 		    else {
	 		    logoRequest = ajaxService.getTeamSpaceLogo(teamSpaceBaseUuid);
	 		    getTeamSpaceLogoPromiseMap[teamSpaceBaseUuid] = logoRequest;
 		    }
 		    var returnPromise = logoRequest.then(function(data){
                if (data.data == null || data.data == "" || data.data.boName == "FailureBO") {
                    boardbookTeamSpaceLogo.url = "";
                }
                else
                {
                    boardbookTeamSpaceLogo.url = "data:image/png;base64," + data.data;

                    var defer = $q.defer();
                    StringUtils.resizeImage(boardbookTeamSpaceLogo.url, 1, 200, defer);
                    defer.promise.then(function(newLogo){
                        boardbookTeamSpaceLogo.url = newLogo;
                    });
                }
                return boardbookTeamSpaceLogo;
            });
            return returnPromise;
        },
 		getCompanyTeamspaceLogo : function(refresh, checkCompanyLogo, callback) {
 			if(refresh){
 				service.getCompanyTSLogo(checkCompanyLogo).then(function(data){
 					if(data.data == null || data.data =="" || data.data.boName == "FailureBO"){
 						companyLogo.url = "";
 					}
 					else
 					{
 						companyLogo.url = "data:image/png;base64," + data.data;

 						var defer = $q.defer();
 						StringUtils.resizeImage(companyLogo.url, 1, 200, defer);
 						defer.promise.then(function(newLogo){
 						    companyLogo.url = newLogo;
 						});
 					}

 					if(typeof callback == 'function' ) callback(companyLogo);
 				})
 				companyLogo.isLogo = context.getModel().html5isLogo + "";
 			}

 			return companyLogo;
 		},
 		getCompanyLogo : function(callback) {
 			service.getCompanyLogoOnly().then(function(data)
 			{
 				if(data.data == null || data.data =="" || data.data.boName == "FailureBO")
 				{
 					companyLogo.url = "";
 				}
 				else
 				{
 					companyLogo.url = "data:image/png;base64," + data.data;
 				}

 				if(typeof callback == 'function' ) callback(companyLogo);
 			})
 			companyLogo.isLogo = context.getModel().html5isLogo + "";
 			return companyLogo;
 		},
 		getTSLogo : function(refresh, checkCompanyLogo) {
 			if(refresh){
 				teamSpaceLogo.url = "";
 				service.getCompanyTSLogo(checkCompanyLogo).then(function(data){
 					if(data.data == null || data.data =="" || data.data.boName == "FailureBO"){
 						teamSpaceLogo.url = "";
 					}
 					else
 					{
 						teamSpaceLogo.url = "data:image/png;base64," + data.data;
 					}
 				})
 			}

 			return teamSpaceLogo;
 		},
 		setCompanyLogo : function(callback){

 			var preloadedCompanyLogo = service.getPreloadCompanyLogo();
 			var csrfToken = context.getModel().csrfToken;

 			if(preloadedCompanyLogo.bytesArr != null && preloadedCompanyLogo.bytesArr != 'undefined')
 			{
 				Upload.upload({
                     url : '/services/fileupload',
                     file : preloadedLogo.file,
                     fields : {
                         csrfToken : context.getModel().csrfToken,
                         action : 'teamspaceLogo',
                         totalFiles : 1,
                         objectbaseuuid : '',
                         uploadNumber : 1,
                         parentbaseuuid : ''},
                         })
                     .success(function(data, status, headers, config)
                     		{
                     			service.getCompanyTeamspaceLogo(true, true);
                     			service.setPreloadCompanyLogo(null,null);
                     			if(typeof callback == 'function')
                     			    callback();
                     		})
             		.error(function(data, status, headers, config)
                            {
                            	bvDialog.showMessage(localizedMessages.get('ERROR_UPLOADING_LOGO'));
                            });
 			}
 		},
 		setPreloadCompanyLogo : function(file, bytesArr){
 			preloadedLogo.file = file;
 			preloadedLogo.bytesArr = bytesArr;
 		},
 		getPreloadCompanyLogo : function(){
 			return preloadedLogo;
 		},
 		applyBrandingColors : function(){
 			if(context.getModel().html5BackgroundColor.indexOf("#") < 0){
 				context.getModel().html5BackgroundColor = "#" + context.getModel().html5BackgroundColor;
 			}
 			if(context.getModel().html5TextColor.indexOf("#") < 0)
 			{
 				context.getModel().html5TextColor = "#" + context.getModel().html5TextColor
 			}
 			if(context.getModel().html5ModulesColor.indexOf("#") < 0)
 			{
 				context.getModel().html5ModulesColor = "#" + context.getModel().html5ModulesColor
 			}
 			if(!context.getModel().htmlLogoRatation)
 			{
 				context.getModel().htmlLogoRatation = 0;
 			}
 			if(!context.getModel().htmlLogoMargin)
 			{
 				context.getModel().htmlLogoMargin = 0;
 			}

 			if(MobileUtil.isMobileClient() && legacyUserService.isNasdaqUI){
 			    if(context.getModel().html5ModulesColor == '#004570')
                    context.getModel().html5ModulesColor = '#0094b3';
                if(context.getModel().html5BackgroundColor == '#004570')
                    context.getModel().html5BackgroundColor = '#1E1F24';

                context.getModel().html5TextColor = '#FFFFFF';
            }
                    
 			brand.color(context.getModel().html5BackgroundColor, context.getModel().html5TextColor);
 			brand.colorMenu(context.getModel().html5ModulesColor);
 		},
 		getQuestionList : function(objectBaseuuid, locale)
 		{
 			var request = ajaxService.getQuestionList(objectBaseuuid, locale);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getUserQuestions : function(objectBaseuuid)
 		{
 			var request = ajaxService.getUserQuestions(objectBaseuuid);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		isAdminAllowed : function()
 		{
 			var request = ajaxService.isAdminAllowed();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		sendSecondFactorAuthCode : function(mode)
 		{
 			var request = ajaxService.sendSecondFactorAuthCode(mode);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		isSecondFactorCookieSetAfterVerification : function(authCode)
 		{
 			var request = ajaxService.isSecondFactorCookieSetAfterVerification(authCode);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		setModules : function(update){
 			homeContext.modules = update;
 		},
 		getModules : function(){
 			return homeContext.modules;
 		},
 		getLastSync : function(){
 			var request = ajaxService.getLastSyncJSON();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		getSubscriptions : function()
 		{
 			var request = ajaxService.getSubscriptions();
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		isSecureEmailModuleEnabled : function()
 		{
 			if(context.getModel().enableSecureEmailModule){
 				return true;
 			}
 			else{
 				return false;
 			}
 		},
 		setPlinkValForCS: function(plinkVal){
 			context.getModel().plinkValue = plinkVal;
 		},
 		changeCompany: function(companyBaseUuid){
 			var request = ajaxService.changeCompany(companyBaseUuid);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		changeCompanyForNBV: function(companyBaseUuid, loginID){
 			var request = ajaxService.changeCompanyForNBV(companyBaseUuid, loginID);
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
 		},
 		 setAllStatesOfModules : function () {
 		    
 	    	 if(this.homeContext.modules.sidebarMenuModules != undefined && this.homeContext.modules.sidebarMenuModules.length > 0 ){
 	    		 
 	    			this.homeContext.moduleStates  = [];
 	    		  for (var m in this.homeContext.modules.sidebarMenuModules) {
 	    				var menu = this.homeContext.modules.sidebarMenuModules[m];
 	    				 if(menu.state == 'home.dashboard'){
 	    					 this.homeContext.moduleStates.push('home.dashboard');
 	    					 this.homeContext.moduleStates.push('home.openitems');
 	    				 }
 	    				 else if(menu.state == 'home.meetings'){
 	    					 this.homeContext.moduleStates.push('home.meetings');
 	    					 this.homeContext.moduleStates.push('home.meetingCreation');
 	    					 this.homeContext.moduleStates.push('home.openMeeting');
 	    					 this.homeContext.moduleStates.push('home.viewfullagenda');
 	    					 this.homeContext.moduleStates.push('home.manageTemplates');
 	    					  this.homeContext.moduleStates.push('home.createAgendaTemplate');
							  this.homeContext.moduleStates.push('home.meetingDetails');
							  this.homeContext.moduleStates.push('home.minutesLandingPage');
							  this.homeContext.moduleStates.push('home.minutesSettings');
							  this.homeContext.moduleStates.push('home.minutesPlink');
							  this.homeContext.moduleStates.push('home.virtualMeetingConfiguration');
 	    				 }
 	    				 else if(menu.state == 'home.surveys'){
 	    					 this.homeContext.moduleStates.push('home.surveys');
 	    					 this.homeContext.moduleStates.push('home.createSurvey');
 	    					 this.homeContext.moduleStates.push('home.createCBEQuestionnaire');
 	    					 this.homeContext.moduleStates.push('home.openSurvey');
 	    				 }
 	    				 else if(menu.state == 'home.reports'){
 	    					 this.homeContext.moduleStates.push('home.reports');
 	    					 this.homeContext.moduleStates.push('home.reports.useractivity');
 	    					 this.homeContext.moduleStates.push('home.reports.userchanges');
 	    					 this.homeContext.moduleStates.push('home.reports.documentlist');
 	    					 this.homeContext.moduleStates.push('home.reports.documentactivity');
 	    					 this.homeContext.moduleStates.push('home.reports.documentpermissions');
 	    					 this.homeContext.moduleStates.push('home.reports.meetinglist');
 	    					 this.homeContext.moduleStates.push('home.reports.retentionpolicies');
 	    					 this.homeContext.moduleStates.push('home.reports.memberattendancetracking');
 	    					 this.homeContext.moduleStates.push('home.reports.groupattendancetracking');
 	    					 this.homeContext.moduleStates.push('home.reports.userbriefcasereport');
 	    					 this.homeContext.moduleStates.push('home.reports.syncedinformationreport'),
							 this.homeContext.moduleStates.push('home.reports.passwordexpiry');
 	    					 this.homeContext.moduleStates.push('home.reports.folderpermissions');}
 	    				 else{
 	    					 this.homeContext.moduleStates.push(menu.state);
 	    				 }
 	    				 
 	    			} 
 	    		  
 	    	 }
 	          return  this.homeContext.moduleStates;
 	     },	
 		
 		switchTeamSpace : function (teamSpace, callback) {
            var companyVO = context.getModel().companyVO;
            var oldTs = context.getModel().portalVO.objectBaseUuid;
            $rootScope.SELECTED_PORTAL_BASEUUID = teamSpace.objectBaseUuid;
            var defaultLandingModule = context.getModel().html5LandingModule.toLowerCase().replace(" ", "");
			var defaultJupLandingModule = context.getModel().jupHtml5LandingModule.toLowerCase().replace(" ", "");
            this.changeTeamSpace(companyVO.objectBaseUuid, teamSpace.objectBaseUuid, "true").then(function(data)
            {
                companyUserService.clearAll();
                SYNC_STARTED = false;
                if(StringUtils.isNullEmpty(data))
                {
                    $rootScope.SELECTED_PORTAL_BASEUUID = oldTs;
                }
                else
                {
                    bvSliderInstanceService.clear();
                    context.clearContext();
                    companyUserService.clearCurrent();
                    $state.go('home.' + (MobileUtil.isApp() ? defaultJupLandingModule : defaultLandingModule), null, { reload: true });
                    //window.stop();
                }
            },function (error){
                $rootScope.SELECTED_PORTAL_BASEUUID = oldTs;
            });
        },
        
        logUserAgent: function(){
        	var useragentJson = {} ;
        	useragentJson["msg"]="UserLog";
        	useragentJson["user-agent"]=navigator.userAgent;
        	var request = ajaxService.logMessage("INFO", JSON.stringify(useragentJson));
 			return request.then(function(data) {
 				if (data) {
 					return data.data;
 				}
 				return null;
 			});
        }

 	}
 	return service;


 	function set_current_module_has_unsaved_changes(status, ctrl) {
 		this.current_module_has_unsaved_changes = status;
 		this.module_with_unsaved_changes = ctrl;
 		// console.log("set by " + this.module_with_unsaved_changes + " to " + this.current_module_has_unsaved_changes);
 	}

     function get_current_module_has_unsaved_changes() {
         return this.current_module_has_unsaved_changes;
     }
     
    
}