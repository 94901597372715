
function MeetingsController($scope, $state, $stateParams, meetingsService, localizedMessages,$timeout,repositoryService,modal,imageService, calendarService, meetingAlertService, $dateParser, bvDialogService, bvDialog,context, companyUserService, homeService, constants, meetingDateRangeService, legacyUserService){
	//$scope.middleBarObject.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
	var ctrlName = 'MeetingsController';
	$scope.ui = { label : {
							title				: localizedMessages.get("TITLE"),
							startDate			: localizedMessages.get("DATE"),
					        active 				: localizedMessages.get("ACTIVE"),
					        publish				: localizedMessages.get("PUBLISH"),
					        startTime				: localizedMessages.get("START_TIME"),
					        endTime				 	: localizedMessages.get("END_TIME"),
					        location			: localizedMessages.get("ADDRESS"),
					        callInNumber 		: localizedMessages.get("DIAL_IN"),
					        notes				: localizedMessages.get("NOTES"),
					        attachments			: localizedMessages.get("ATTACHMENTS_TITLE"),
					        people				: localizedMessages.get("PEOPLE"),
					        attachOnlyAgendaMsg : localizedMessages.get("ONE_AGENDA_ATTACH_MESSAGE"),
					        meetingTitleError	: localizedMessages.get("MEETINGS_TITLE_ERROR"),
					    	meetingTimeGreaterError	:localizedMessages.get("MEETINGS_TIME_GREATER_ERROR"),
					    	meetingTimeEqualError	:localizedMessages.get("MEETINGS_TIME_EQUAL_ERROR"),
					    	meetingAttendeeError	:localizedMessages.get("MEETINGS_ATTENDEE_ERROR"),
					    	btnCancel				:localizedMessages.get("CANCEL"),
					    	btnAttachments			:localizedMessages.get("ATTACHMENTS_TITLE"),
					    	btnAttendees			:localizedMessages.get("ATTENDEES"),
					    	btnSave					:localizedMessages.get("SAVE"),
					    	presenter				:localizedMessages.get("PRESENTER"),
					    	host 					:localizedMessages.get("HOST"),
					    	owner					:localizedMessages.get("OWNER"),
					    	headingDetails			:localizedMessages.get("DETAILS"),
					    	headingAttachments		:localizedMessages.get("ATTACHMENTS"),
					    	headingAttendees		:localizedMessages.get("ATTENDEES"),
					    	meetingRoom 			:localizedMessages.get("MEETING_ROOM"),
					    	cancelConfirmMsg        :localizedMessages.get("MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED"),
					    	code					:localizedMessages.get("CODE"),
					    	indicates_req_field		:localizedMessages.get("INDICATES_REQUIRED_FIELD"),
					    	allDay					:localizedMessages.get("ALL_DAY"),
					    	acceptOnBehalf			:localizedMessages.get("ACCEPT_ON_BEHALF"),
							htmlTagError			:localizedMessages.get("HTML_TAG_ERROR"),
							dialogTitle             :localizedMessages.get("EXIT") + " " + localizedMessages.get("MEETING")
					      }
				};

	$scope.allDay = true;
	$scope.titleLimit=100;
	$scope.isAgendaDialog = false;
	$scope.selectedIndex = 0;
	$scope.previewAttachment = null;
	$scope.errorMessage = "";
	$scope.isNasdaqUI = legacyUserService.isNasdaqUI
	
	
	$scope.timeInterval = (context.getModel() == null || context.getModel() == undefined) ? "15" : context.getModel().calendarEventTimeIncrementValue;

	if($scope.timeInterval == "5")
		$scope.timeInterval = "05";

	var tInterval = parseInt($scope.timeInterval);

	//$scope.maxTime1 = "23:30";
	$scope.maxTime1 = "23:" + (60 - tInterval);
	$scope.maxTime2 = "23:59";

	$scope.startingTime = '';
	$scope.endingTime	= '';

	$scope.bvchecktrue 		= 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvcheckfalse 	= 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';

	//console.log("TIME:",$scope.meetingCenterObject.endingTime);
	//$scope.meetingCenterObject.endingTime = $scope.meetingCenterObject.endingTime;
	//$scope.meetingCenterObject.startingTime = $scope.meetingCenterObject.startingTime;

	$scope.meetingSaveInProgress = false;

	$scope.documentSortableOptions = {
		handle: '> .drag-handle',
        update: function(e, ui)
        {
        	//console.log(ui.item);
        }
    };

	$scope.scrollbarConfig = {
		autoHideScrollbar: true
	}
	
	var filterAttendeeList = function(eventAttendees)
	{
		for(var i=0; i<eventAttendees.length; i++) {
			eventAttendees[i].name = "";
    	}
		return eventAttendees;
	};

	$scope.chkTitle = function(t)
	{
		//If blank replace the text with Meetings
		if(t == ''){
			$scope.$parent.middleBarObject.title = localizedMessages.get('MEETINGS');
		}
		else{
			$scope.$parent.middleBarObject.title = t;
		}
	};

	$scope.timeOptions 	= calendarService.getTimeOptions();


	$scope.meetingDateObject = meetingDateRangeService.getMeetingRangeObject('L');

	$scope.dateChangeFrom = function()
	{
        var rangeSelection = $scope.meetingDateObject.rangeSelection;
        if(moment($scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal).unix() > moment($scope.meetingDateObject.rangeSelection.datePickTo.startingDateLocal).unix())
        {
        	$scope.meetingDateObject.rangeSelection.datePickTo.startingDate = $scope.meetingDateObject.rangeSelection.datePickFrom.startingDate;
        	$scope.meetingDateObject.rangeSelection.datePickTo.startingDateLocal = $scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal;
        }
    }
    $scope.dateChangeTo = function(){
        var rangeSelection = $scope.meetingDateObject.rangeSelection;
        if(moment($scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal).unix() > moment($scope.meetingDateObject.rangeSelection.datePickTo.startingDateLocal).unix())
        {
        	$scope.meetingDateObject.rangeSelection.datePickFrom.startingDate = $scope.meetingDateObject.rangeSelection.datePickTo.startingDate;
        	$scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal = $scope.meetingDateObject.rangeSelection.datePickTo.startingDateLocal;
        }
    }
    
	$scope.openAttachmentChooser = function()
    {
		var request = bvDialog.attachDocument(onSelection);
    };

    $scope.time1 = new Date();

    $scope.columns =[
                     {displayName: '', field:'', width: 27,
                         cellTemplate: "<div> <img name='Document_Icon' style='width:23px; height:25px; margin-left:2px; vertical-align: -webkit-baseline-middle;' ng-src='{{getIconByType(row.entity)}}'></div>"
                       },
                       {displayName:'Name', field:'name'}
                     ];

	$scope.openAttachment = function(scope)
	{
		repositoryService.openPdfViewer(($scope.isAgendaDialog)?scope.$modelValue.attachment:scope.$modelValue);
	};

	var onSelection = function(selectedNodes)
	{
		try{
			var unsupportedFile = null;
			if(selectedNodes != undefined && selectedNodes.length>0)
			{
				var index = 0;
				var i = 0;
				for(index in selectedNodes){
					var attachment = selectedNodes[index];
					var badFile = false;

					if(StringUtils.isMultiMediaVO(attachment.objectBaseUuid))
					{
						badFile = true

						unsupportedFile =  unsupportedFile + (i+1)+" : "+attachment.name + "\n\r" ;
					}

					if(!$scope.checkFileAlreadyExists(attachment.objectBaseUuid)){
						//True: Attachment is not a folder.
						if(!StringUtils.isFolder(attachment.objectBaseUuid) && !badFile){
							$scope.meetingCenterObject.attachmentsList.push(attachment);
						}
					}
				}

				if(!StringUtils.isNullEmpty(unsupportedFile))
				{
					var fileSizeString = localizedMessages.get('MSG_INVALID_FILE')
	    			bvDialog.showMessage(fileSizeString+ "\n\n\n"+ unsupportedFile);
				}
			}
		}
		catch(err){
			console.log(err.message);
		}
	};

	$scope.onChangeStartingTime = function()
	{
		$scope.meetingCenterObject.startingTimeLocal = $scope.meetingCenterObject.startingTime;
		
		var date = new Date($scope.meetingCenterObject.startingTime);
		var currTime = moment(date).format('hh:mm A')
		//console.log("STARTING-TIME:",currTime);
		if(currTime == "11:45 PM")
			date.setMinutes(date.getMinutes() + 15);
		else
			date.setMinutes(date.getMinutes() + 30);
		$scope.meetingCenterObject.endingTime = date;
		
		$scope.meetingCenterObject.endingTimeLocal = $scope.meetingCenterObject.endingTime;
	};

	$scope.onChangeEndingTime = function()
	{
		
		$scope.meetingCenterObject.endingTimeLocal = $scope.meetingCenterObject.endingTime;
		
		/*var date = new Date($scope.meetingCenterObject.endingTime);
		var currTime = moment(date).format('hh:mm A')
		//console.log("ENDING-TIME:",currTime);
		if(currTime == "12:15 AM")
			date.setMinutes(date.getMinutes() - 15);
		else
			date.setMinutes(date.getMinutes() - 30);
		$scope.meetingCenterObject.startingTime = date;*/
	};

	$scope.getDocIcon = function(attachment){
		return imageService.getImageForObject(attachment);
	};

	$scope.checkFileAlreadyExists = function(uuid){
		try{
			for(var i in $scope.meetingCenterObject.attachmentsList){
				var attachment = $scope.meetingCenterObject.attachmentsList[i];
				if(attachment.objectBaseUuid == uuid)
					return true;
			}
		}
		catch(err){

		}

		return false;
	};

	$scope.checkAgendaAlreadyExists = function(){
		try{
			for(var i in $scope.meetingCenterObject.attachmentsList){
				var attachment = $scope.meetingCenterObject.attachmentsList[i];
				if(attachment.extensionType.indexOf("ona")>-1)
					return true;
			}
		}
		catch(err){

		}

		return false;
	};

	$scope.showAttendeesOptionDialog = function(){
 		$scope.popupTitle = localizedMessages.get("ATTENDEES");
 		bvDialog.showUserManagementToolkitMeetings($scope.meetingCenterObject);
		//bvDialog.showAttendeesOption($scope.meetingCenterObject);
	};


	/*$scope.addSelectedUser = function(selectedItems){
		$scope.selectedUsers = selectedItems;
		console.log($scope.selectedUsers);
		// $scope.userListModal.dismiss('close');
		if($scope.selectedUsers!=undefined && $scope.selectedUsers.length >0 )
		{
			for(i in $scope.selectedUsers) {

				if($scope.selectedUsers[i].type == 'TeamSpaceGroup'){
					console.log("TeamSpaceGroupId: "+$scope.selectedUsers[i].objectBaseUuid);
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
				}
				else if($scope.selectedUsers[i].type == 'Group'){
					console.log("GroupId:"+$scope.selectedUsers[i].objectBaseUuid);
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
				}
				else if($scope.selectedUsers[i].type == 'Portal'){
					console.log("PortalId:"+$scope.selectedUsers[i].objectBaseUuid);
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
				}
				else{
					$scope.addUserInAttendeesList($scope.selectedUsers[i].objectBaseUuid, $scope.selectedUsers[i].name);
				}


			}
		}
	};


	$scope.addGroupUsersInAttendeesList = function(groupId){
		var users = companyUserService.getGroupUsers(groupId);
		if(users && users.length> 0)
		{
			for(k in users){
				$scope.addUserInAttendeesList(users[k].objectBaseUuid, users.name);
			}
		}
		//commonService.getGroupUsers(groupId).then(function(data){
		// 	if(data.data.groupUsers != undefined && data.data.groupUsers.length>0){
		// 		for(k in data.data.groupUsers){
		// 			$scope.addUserInAttendeesList(data.data.groupUsers[k].objectBaseUuid,data.data.groupUsers[k].displayName);
		// 		}
		// 	}
		// });
	};*/

	$scope.addUserInAttendeesList = function(uuid,name){
		//var profileImage = $scope.$parent.userImages[$scope.selectedUsers[i].objectBaseUuid];
		var attendeeObj = {
				objectBaseUuid		:uuid,
				name				:name,
				isPresenter			:false,
				isOwner				:false,
				isOnBehalf			:false,
				response			:'not_responded',
				//encodedUserThumbnail:profileImage
			};

		if($scope.checkAttendeeAlreadyExists(attendeeObj.objectBaseUuid) == false)
		{
			if($scope.meetingCenterObject) {
				$scope.meetingCenterObject.attendeesList.push(attendeeObj);
			}
		}
	};

	$scope.removeAttendee = function(index){
		var from 	= index;
		var to		= 0;
		var rest = $scope.meetingCenterObject.attendeesList.slice((to || from) + 1 || $scope.meetingCenterObject.attendeesList.length);
		$scope.meetingCenterObject.attendeesList.length = from < 0 ? $scope.meetingCenterObject.attendeesList.length + from : from;
		$scope.meetingCenterObject.attendeesList.push.apply($scope.meetingCenterObject.attendeesList, rest);
	};

	$scope.checkAttendeeAlreadyExists = function(objectBaseUuid){
		var existsFlag = false;
		if($scope.meetingCenterObject && $scope.meetingCenterObject.attendeesList && $scope.meetingCenterObject.attendeesList.length != 0)
		{
			for(var index in $scope.meetingCenterObject.attendeesList){
				if($scope.meetingCenterObject.attendeesList[index].objectBaseUuid == objectBaseUuid){
					existsFlag = true;
					break;
				}
			}
		}
		else
			existsFlag = false;

		return existsFlag;
	};

	$scope.showEndingCalender = function()
	{
		$( "#datepickerEnding" ).datepicker({showPanelButton:true,beforeShow:function(){$('.ui-datepicker').css('font-size',11);},showAnim: "slideDown",dateFormat:'mm.dd.yy'});
		$( "#datepickerEnding" ).show();
		$( "#datepickerEnding" ).focus();
		$( "#datepickerEnding" ).datepicker().datepicker('setDate', 'today');
	};

//	$scope.showStartingCalender = function()
//	{
//		var dateFormat = StringUtils.getLocaleDateFormat();
//		var monthNamesShort = moment.localeData()._monthsShort;
//
//		$( "#datepickerStarting" ).datepicker({
//			showPanelButton:true,
//			beforeShow:function(){
//				$('.ui-datepicker').css('font-size',11);
//				$("#ui-datepicker-div").addClass("meeting-datepicker");
//				//$('.ui-state-default').css('background:none;');
//				//$('.meeting-datepicker .ui-state-default').addClass('meeting-datepicker-ui-state-default');
//
//			},
//			showAnim: 'slideDown',
//			monthNamesShort: monthNamesShort,
//			dateFormat: dateFormat,
//			onSelect: function(dateText, inst) {
//				var parcedDate = $.datepicker.parseDate(inst.settings.dateFormat || $.datepicker._defaults.dateFormat, dateText, inst.settings);
//				$scope.meetingCenterObject.startingDate = moment(parcedDate).format('ll');
//				$scope.meetingCenterObject.startingDateLocal = StringUtils.momentL(parcedDate).format('ll');
//			}
//		});
//
//		$( "#datepickerStarting" ).show();
//		$( "#datepickerStarting" ).focus();
		
//		meetingsService.initializeDatepicker($scope.meetingCenterObject);
//	};

	$scope.showEndingOnCalender = function()
	{
		$( "#datepickerEndingOn" ).datepicker({showPanelButton:true,beforeShow:function(){$('.ui-datepicker').css('font-size',11);},showAnim: "slideDown",dateFormat:'mm.dd.yy'});
		$( "#datepickerEndingOn" ).show();
		if($( "#datepickerEndingOn" ).is(':focus')){
			$( "#datepickerEnding" ).focusout();
		}
		else{
			$( "#datepickerEndingOn" ).focus();
		}

	};

	var makeSureHostAdded = function()
	{
		if($scope.meetingCenterObject.host == undefined || $scope.meetingCenterObject.host == null)
		 	makeCreatorAsHost();

		var isHostAttending = false;
		for(var i in $scope.meetingCenterObject.attendeesList) {
			if($scope.meetingCenterObject.attendeesList[i].objectBaseUuid == $scope.meetingCenterObject.host) {
				isHostAttending = true;
				break;
			}
		}

		if(!isHostAttending) {
			$scope.addUserInAttendeesList($scope.meetingCenterObject.host);
		}
	}
	
	var confirmForSendingAlert = function(savedMeeting) {
		homeService.set_current_module_has_unsaved_changes(false, ctrlName);
		var alert_msg = localizedMessages.get("SEND_MEETING_ALERT");
		bvDialog.confirm(alert_msg, function() {
			meetingAlertService.meeting= savedMeeting;
			meetingAlertService.showComposeDialog = false; // change to true to view compose message window
			meetingAlertService.openAlertDialog()
		});
	}
	
	$scope.editorOptions = {
	           language: 'en',
	           resize_enabled : true,
	           enterMode : CKEDITOR.ENTER_BR,
	           height:"70px",
	           toolbarCanCollapse:false,
	           toolbarStartupExpanded:false,
	           removePlugins: 'elementspath',
	           toolbar : [
				               { name: 'clipboard', 	items: [ 'Undo', 'Redo' ] },
				               { name: 'basicstyles', 	items: [ 'Bold', 'Italic','Underline' ] },
				               { name: 'color', 		items: [ 'TextColor' ] },
				               { name: 'paragraph',     items: [ 'NumberedList', 'BulletedList'] },
				               { name: 'justify', 		items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ] },
				               { name: 'styles', 		items: [ 'Format', 'Font', 'FontSize' ] }
				           ],
			   extraPlugins : 'colorbutton,colordialog,font,link,basicstyles,toolbar,justify,wordcount',
			   wordcount: {
	               showParagraphs: false,
	               showWordCount: false,
	               showCharCount: true,
	               countSpacesAsChars:true,
	               countHTML:false,
	               maxWordCount: -1,
	               maxCharCount: 600
	           },
	    };

		// Set focus and blur listeners for all editors to be created.
		CKEDITOR.on('instanceReady', function(e) {
			var editor = e.editor;
		    editor.on('focus', function() {
		        console.log('editor focused');
		    });

		    editor.on('change', function() {
		    	console.log('editor changed');
		    	$scope.editorWordCount = editor.getData().replace(/<[^>]*>|\s/g, '').length;
		    });
		    e.stop(); // Stop the event in case the listener is inserted twice
		});
	
	$scope.save = function()
	{
		if($scope.meetingSaveInProgress)
			return;

//		if(StringUtils.isNullEmpty($scope.startingDateISO) == false){
//			$scope.meetingCenterObject.startingDate = StringUtils.momentL($scope.startingDateISO).format('ll');
//		}
//		else{
//			var date = moment($scope.meetingCenterObject.startingDate, moment().localeData()._longDateFormat.ll);
//			$scope.meetingCenterObject.startingDate = StringUtils.momentL(date._d).format('ll');
//		}
		
		if($scope.isNasdaqUI){
			$scope.meetingCenterObject.endingDate = $scope.meetingDateObject.rangeSelection.datePickTo.startingDate;
			$scope.meetingCenterObject.endingDateLocal = $scope.meetingDateObject.rangeSelection.datePickTo.startingDateLocal;
		}
		else{
			$scope.meetingCenterObject.endingDate = $scope.meetingDateObject.rangeSelection.datePickFrom.startingDate;
			$scope.meetingCenterObject.endingDateLocal = $scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal;
		}
			
		
//		$scope.meetingCenterObject.endingDate = $scope.meetingCenterObject.datePickFrom.startingDate;
//		$scope.meetingCenterObject.endingDateLocal = $scope.meetingCenterObject.datePickFrom.startingDateLocal;
//		$scope.meetingCenterObject.endingDate = $scope.meetingDateObject.rangeSelection.datePickTo.startingDate;
//		$scope.meetingCenterObject.endingDateLocal = $scope.meetingDateObject.rangeSelection.datePickTo.startingDateLocal;
		//meetingDateObject.rangeSelection.datePickTo
		//$scope.meetingCenterObject.startingTimeLocal = $scope.meetingCenterObject.startingTime;
		//$scope.meetingCenterObject.endingTimeLocal = $scope.meetingCenterObject.endingTime;
		
		//True: New Meeting Creation
		if($scope.meetingCenterObject.editFlag == false){
			if($scope.checkValidation()){
				$scope.meetingSaveInProgress = true;
				//var startingDateTime = $scope.meetingCenterObject.startingDate + ' ' + ($scope.meetingCenterObject.startingTime instanceof Date ? moment($scope.meetingCenterObject.startingTime).format("hh:mm A") : $scope.meetingCenterObject.startingTime);
				//var endingDateTime	 = $scope.meetingCenterObject.endingDate + ' ' + ($scope.meetingCenterObject.endingTime instanceof Date ? moment($scope.meetingCenterObject.endingTime).format("hh:mm A") : $scope.meetingCenterObject.endingTime);
				
				var startingDateTimeLocal = $scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal + ' ' + ($scope.meetingCenterObject.startingTimeLocal instanceof Date ? StringUtils.momentL($scope.meetingCenterObject.startingTimeLocal).format("hh:mm A") : $scope.meetingCenterObject.startingTimeLocal);
				var endingDateTimeLocal	 = $scope.meetingCenterObject.endingDateLocal + ' ' + ($scope.meetingCenterObject.endingTimeLocal instanceof Date ? StringUtils.momentL($scope.meetingCenterObject.endingTimeLocal).format("hh:mm A") : $scope.meetingCenterObject.endingTimeLocal);

				var startingDate = StringUtils.momentL(startingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('ll');
				var endingDate = StringUtils.momentL(endingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('ll');

				var	startingTime = 	StringUtils.momentL(startingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('hh:mm A');
				var	endingTime = StringUtils.momentL(endingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('hh:mm A');
				var eventTimeZone = moment.tz.guess();
				var conferenceLink ='';
				// if creator removes him self
				addCreatorAsAttendees();
				makeSureHostAdded();
				var eventAttendees = angular.copy($scope.meetingCenterObject.attendeesList);
				eventAttendees = filterAttendeeList(eventAttendees);
				meetingsService.createCalendarEvent($scope.meetingCenterObject.meetingTitle,
						$scope.meetingCenterObject.meetingDetails,
						$scope.meetingCenterObject.meetingAddress,
						$scope.meetingCenterObject.meetingCallInNumber,
													startingDate + " " + startingTime,
													endingDate + " " + endingTime,
													angular.toJson($scope.meetingCenterObject.attachmentsList),
													//angular.toJson($scope.meetingCenterObject.attendeesList),
													angular.toJson(eventAttendees),
													$scope.meetingCenterObject.isActive,
													$scope.meetingCenterObject.codeNumber,
													$scope.meetingCenterObject.location,
													$scope.meetingCenterObject.allDay,
													$scope.meetingCenterObject.host,conferenceLink,eventTimeZone).then(function(data){
														$scope.meetingSaveInProgress = false;
														if(data.data.message == undefined){
															//angular.element('#idSuccess').fadeIn();
															//angular.element('#idError').fadeOut();
															homeService.set_current_module_has_unsaved_changes(false, ctrlName);
															$scope.onSaveDone(data.data);
															if(data.data.isActive){
																confirmForSendingAlert(data.data);
																if($scope.isNasdaqUI){
																	$state.go('home.meetings');
																}
															}
														}
														else{
															$scope.errorMessage = data.data.message;
															if($scope.errorMessage.indexOf('invalid input') > -1){
																bvDialog.showMessage(localizedMessages.get("INVALID_CHARACTERS"));
															}
															else{
																bvDialog.showMessage($scope.errorMessage);
															}
															//angular.element('#idError').fadeIn();
														}
														if($scope.isNasdaqUI){
															$state.go('home.meetings');
														}
//														var homeController = angular.element(document.querySelector('[home-controler]')).scope();
//												   	 	if(homeController)
//												   	 		homeController.getMeetingsCount();
														//calendarService.getAllCalendarEvents(true, calendarService.getList);

				});
			}else{
				bvDialog.showMessage($scope.errorMessage);
			}
		}
		else{
			if($scope.checkValidation()){
				$scope.meetingSaveInProgress = true;
//				var startingDateTime = $scope.meetingCenterObject.startingDate + ' ' + ($scope.meetingCenterObject.startingTime instanceof Date ? moment($scope.meetingCenterObject.startingTime).format("hh:mm A") : $scope.meetingCenterObject.startingTime);
//				var endingDateTime	 = $scope.meetingCenterObject.endingDate + ' ' + ($scope.meetingCenterObject.endingTime instanceof Date ? moment($scope.meetingCenterObject.endingTime).format("hh:mm A") : $scope.meetingCenterObject.endingTime);
//
//				var startingDate = StringUtils.momentL(startingDateTime).utcOffset(moment().utcOffset()).utc().format('ll');
//				var endingDate = StringUtils.momentL(endingDateTime).utcOffset(moment().utcOffset()).utc().format('ll');
//
//				var	startingTime = 	moment(startingDateTime).utcOffset(moment().utcOffset()).utc().format('hh:mm A');
//				var	endingTime = moment(endingDateTime).utcOffset(moment().utcOffset()).utc().format('hh:mm A');
				var eventTimeZone = moment.tz.guess();
				var conferenceLink ='';
				var startingDateTimeLocal = $scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal + ' ' + ($scope.meetingCenterObject.startingTimeLocal instanceof Date ? StringUtils.momentL($scope.meetingCenterObject.startingTimeLocal).format("hh:mm A") : $scope.meetingCenterObject.startingTimeLocal);
				var endingDateTimeLocal	 = $scope.meetingCenterObject.endingDateLocal + ' ' + ($scope.meetingCenterObject.endingTimeLocal instanceof Date ? StringUtils.momentL($scope.meetingCenterObject.endingTimeLocal).format("hh:mm A") : $scope.meetingCenterObject.endingTimeLocal);

				var startingDate = StringUtils.momentL(startingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('ll');
				var endingDate = StringUtils.momentL(endingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('ll');

				var	startingTime = 	StringUtils.momentL(startingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('hh:mm A');
				var	endingTime = StringUtils.momentL(endingDateTimeLocal).utcOffset(moment().utcOffset()).utc().format('hh:mm A');

				addCreatorAsAttendees();
				makeSureHostAdded();
				var eventAttendees = angular.copy($scope.meetingCenterObject.attendeesList);
				eventAttendees = filterAttendeeList(eventAttendees);
				meetingsService.editAndNotifyCalendarEvent( $scope.meetingCenterObject.meetingObjectBaseUuid,
						$scope.meetingCenterObject.meetingTitle,
						$scope.meetingCenterObject.meetingDetails,
						$scope.meetingCenterObject.meetingAddress,
						$scope.meetingCenterObject.meetingCallInNumber,
						startingDate + " " + startingTime,
						endingDate + " " + endingTime,
						angular.toJson($scope.meetingCenterObject.attachmentsList),
						//angular.toJson($scope.meetingCenterObject.attendeesList),
						angular.toJson(eventAttendees),
						$scope.meetingCenterObject.isActive,
						$scope.meetingCenterObject.codeNumber,
						$scope.meetingCenterObject.location,
						$scope.meetingCenterObject.allDay,
						$scope.meetingCenterObject.host,conferenceLink,eventTimeZone).then(function(data){
							$scope.meetingSaveInProgress = false;
							if(data.data.message == undefined){
								//angular.element('#idSuccess').fadeIn();
								//angular.element('#idError').fadeOut();
								homeService.set_current_module_has_unsaved_changes(false, ctrlName);
								$scope.onSaveDone(data.data);
								if(data.data.isActive){
									confirmForSendingAlert(data.data);
									if($scope.isNasdaqUI){
										$state.go('home.meetings');
									}
								}
							}
							else{
								$scope.errorMessage = data.data.message;
								if($scope.errorMessage.indexOf('invalid input') > -1){
									bvDialog.showMessage(localizedMessages.get("INVALID_CHARACTERS"));
								}
								else{
									bvDialog.showMessage($scope.errorMessage);
								}
							}
							if($scope.isNasdaqUI){
								$state.go('home.meetings');
							}
							calendarService.getAllCalendarEvents(true);
//							homeService.getMeetingsCount()
//							.then(function(data)
//							{
//								$scope.meetingItems.value = data;
//							});
						});
			}
			else{
				bvDialog.showMessage($scope.errorMessage);

			}
		}
		$scope.$parent.tileCheckBox.active = false;

	};

	$scope.isContainAgenda = function(){
		try{
			for(var index in $scope.meetingCenterObject.attachmentsList){
				if($scope.meetingCenterObject.attachmentsList[index].extensionType.indexOf('ona') > -1)
					return true;
			}
		}
		catch(err){
			alert("Error:"+err.message);
			return false;
		}

		return false;
	};

	$scope.cancel = function(){
		bvDialog.confirm($scope.ui.label.cancelConfirmMsg, $scope.cancelEdit, undefined, $scope.ui.label.dialogTitle);
	};

	$scope.cancelEdit = function()
	{
		homeService.set_current_module_has_unsaved_changes(false, ctrlName);

		$scope.onCancelDone();
		$state.go('home.meetings');
	};

	$scope.openAttachmentForPreview = function (attachment) {
		//repositoryService.openPdfViewer(attachment);
		bvDialog.showDocumentPreview(attachment.objectBaseUuid,attachment);
	};

	$scope.closePreview = function()
	{
		document.getElementById('idPreviewDiv').style.width = '0px';

	};

	$scope.closeDialog = function()
	{
		//$modalInstance.dismiss('cancel');
	};

	$scope.checkValidation = function(){


		if($scope.meetingCenterObject.meetingTitle == undefined || $scope.meetingCenterObject.meetingTitle == ""){
			$scope.errorMessage = $scope.ui.label.meetingTitleError;
			return false;
		}

		if($scope.meetingCenterObject.attendeesList == undefined || $scope.meetingCenterObject.attendeesList.length<1){
			$scope.errorMessage = $scope.ui.label.meetingAttendeeError;
			return false;
		}
			
		return $scope.checkTimeValidation();
	};

	$scope.checkTimeValidation = function(){

		var startingTime = "";
		var endingTime = "";

		if($scope.meetingCenterObject.startingTimeLocal  instanceof Date)
			startingTime = 	StringUtils.momentL($scope.meetingCenterObject.startingTimeLocal).format('hh:mm A');
		else
			startingTime = 	$scope.meetingCenterObject.startingTimeLocal;


		if($scope.meetingCenterObject.endingTimeLocal  instanceof Date)
			endingTime = 	StringUtils.momentL($scope.meetingCenterObject.endingTimeLocal).format('hh:mm A');
		else
			endingTime = 	$scope.meetingCenterObject.endingTimeLocal;

		var startingTimeTokens = startingTime.split(" ");
		var endingTimeTokens = endingTime.split(" ");

		var startingHour 	= Number(startingTimeTokens[0].split(":")[0]);
		var endingHour		= Number(endingTimeTokens[0].split(":")[0]);

		var startingMinute  = Number(startingTimeTokens[0].split(":")[1]);
		var endingMinute	= Number(endingTimeTokens[0].split(":")[1]);

		var startingAlias   = startingTimeTokens[1];
		var endingAlias		= endingTimeTokens[1];

		var hourMatch 	=  	startingHour	== endingHour ;
		var minuteMatch =  	startingMinute 	== endingMinute ;
		var amPmMatch	= 	startingAlias 	== endingAlias;

		if(hourMatch && minuteMatch && amPmMatch ){
			$scope.errorMessage = $scope.ui.label.meetingTimeEqualError;
			return false;
		}

		if(moment($scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal + ' ' + startingTime).unix() > moment($scope.meetingCenterObject.endingDateLocal + ' ' + endingTime).unix()){
			$scope.errorMessage = $scope.ui.label.meetingTimeGreaterError;
			return false;
		}

		return true;

	};

	$scope.deleteAttachment = function(index){
		var from 	= index;
		var to		= 0;
		var rest = $scope.meetingCenterObject.attachmentsList.slice((to || from) + 1 || $scope.meetingCenterObject.attachmentsList.length);
		$scope.meetingCenterObject.attachmentsList.length = from < 0 ? $scope.meetingCenterObject.attachmentsList.length + from : from;
		$scope.meetingCenterObject.attachmentsList.push.apply($scope.meetingCenterObject.attachmentsList, rest);
	};



    $scope.spanFromClicked = false;
    $scope.spanFromClick = function(){
    	$scope.spanFromClicked = true;
    };

    $scope.spanToClicked = false;
    $scope.spanToClick = function(){
    	$scope.spanToClicked = true;
    };

    $scope.toggleOwner = function(item, booleanVal){
    	item.isOwner = booleanVal;
    };

    $scope.toggleOnBehalf = function(item, booleanVal){
    	item.isOnBehalf = booleanVal;
    }

    $scope.oldFrom 	= "";
   	$scope.oldTo	= "";

   	$scope.toggleActive = function()
   	{
   		$scope.meetingCenterObject.isActive = !$scope.meetingCenterObject.isActive

   		$scope.$parent.tileCheckBox.active = $scope.meetingCenterObject.isActive;
   	}

   	$scope.toggleAllDay = function(item, isAllDayEvent)
    {
    	if(item.allDay)
    	{
    		if($scope.oldFrom =="")
    		{
	    		var timeNow = new Date();
				timeNow.setMilliseconds(Math.round(timeNow.getMilliseconds() / 1000) * 1000);
	    		timeNow.setSeconds(Math.round(timeNow.getSeconds() / 60) * 60);
	    		timeNow.setMinutes(Math.round(timeNow.getMinutes() / 30) * 30);
				$scope.oldFrom = moment(timeNow).format('hh:mm A');
				timeNow.setMinutes(timeNow.getMinutes() + 30);
				$scope.oldTo = moment(timeNow).format('hh:mm A');
			}
    	}

    	item.allDay = isAllDayEvent;
   		//Set From:08:00AM, To:05:00PM
    	if(isAllDayEvent)
    	{
    		$scope.oldFrom 	= $scope.meetingCenterObject.startingTime;
   			$scope.oldTo	= $scope.meetingCenterObject.endingTime;
    		var date = new Date();
    		date.setHours(8);
    		date.setMinutes(0);
    		$scope.meetingCenterObject.startingTime = date;
    		var date1 = new Date();
    		date1.setHours(17);
    		date1.setMinutes(0);
    		$scope.meetingCenterObject.endingTime = date1;
    		
    		var gmtFromTime = moment(date).format('hh:mm A')
    		$scope.meetingCenterObject.startingTimeLocal=gmtFromTime;
    		var gmtToTime = moment(date1).format('hh:mm A')
    		$scope.meetingCenterObject.endingTimeLocal=gmtToTime;
    	}
    	else
    	{
    		$scope.meetingCenterObject.startingTime = $scope.oldFrom;
    		$scope.meetingCenterObject.endingTime	= $scope.oldTo;
    	}
    };

    var addCreatorAsAttendees = function() {
    	var creator = context.getModel().userVO;
    	creator.isOwner = true;
		//$scope.addSelectedUser([creator]);
		$scope.addUserInAttendeesList(creator.objectBaseUuid, creator.displayName);
	};

	var makeCreatorAsHost = function() {
		$scope.meetingCenterObject.host = context.getModel().userVO.objectBaseUuid;
	}
	addCreatorAsAttendees();
	makeSureHostAdded();

	$scope.meetingCenterObject.customClass = 'meeting-datepicker';
	$scope.meetingDateObject.rangeSelection.datePickFrom.startingDateLocal = StringUtils.momentL($scope.meetingCenterObject.startingDateLocal).format('L');
	$scope.meetingDateObject.rangeSelection.datePickTo.startingDateLocal = StringUtils.momentL($scope.meetingCenterObject.endingDateLocal).format('L');

}

angular
	.module('meetings', ['meetings.service', 'localization.service','ui.tree','repository.service','calendar.service', 'dnTimepicker','dateParser','ui.sortable','constants.service', 'meetings.listview', 'meetings.detailsPanel', 'meetings.weekview', 'meetings.eventsdialog', 'meetings.dayview','meetingDateRange', 'meetings.reactlistview', 'meetings.details.react', 'meetings.creation', 'viewfullagenda', 'meetings.minutes','meetings.minutesSettings','meetings.manageTemplates', 'meetings.createAgendaTemplate', 'meetings.virtualMeetingsConfig'])
	.directive('bvNewMeeting',function(){
		return {
			restrict:'E',
			templateUrl:'modules/meetings/BvNewMeeting.tpl.html',
			scope:{
				onSaveDone			: '=',
				onCancelDone		: '=',
				meetingCenterObject	: '='
			},
			controller: ['$scope', '$state', '$stateParams', 'meetingsService', 'localizedMessages','$timeout','repositoryService','$modal','imageService','calendarService', 'meetingAlertService', '$dateParser','bvDialogService','bvDialog','context', 'companyUserService', 'homeService', 'constants', 'meetingDateRangeService','legacyUserService', MeetingsController]

		};
	})
	.filter('cutAttachmentTitle', function(){
		return function(value,wordwise,tail,max){
			if (!value) return '';

            max = parseInt(max, 10);
            if (!max) return value;
            if (value.length <= max) return value;

            value = value.substr(0, max);
            if (wordwise) {
                var lastspace = value.lastIndexOf(' ');
                if (lastspace != -1) {
                    value = value.substr(0, lastspace);
                }
            }

            return value + (tail || ' ...');
		};
	}); ;
