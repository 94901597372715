function attachmentListing(){
    return {
        restrict: 'A',
        scope:
        {
              selectionDone: '=',
              attachmentObject: '='
        },
        templateUrl: 'directives/attachment/attachmentListing.template.html',
        controller: 'attachmentListingController'
    }
}
