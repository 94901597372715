SelectionStack.$inject = ['$rootScope'];

function SelectionStack($rootScope)
{
	var lastSelectedObject;
	var allSelectedItems = [];

	this.setLastObject = function(selectedObject)
	{
		lastSelectedObject = selectedObject;
	}

	this.getLastSelectedObject = function()
	{
		return lastSelectedObject;
	}

	var findElement = function(selectedObject) {
		var totalSelectedItems = allSelectedItems.length;
		var foundAtIndex = -1;
		for(var i = 0; i < totalSelectedItems; i++) {
			if(allSelectedItems[i].objectBaseUuid === selectedObject.objectBaseUuid) {
				foundAtIndex = i;
				break;
			}
		}

		return foundAtIndex;
	}

	var addSelectedItem = function(selectedObject) {
		var foundInSelectedItems = findElement(selectedObject);
		if( foundInSelectedItems == -1) allSelectedItems.push(selectedObject);
	}


	var removeSelectedItem = function(selectedObject) {
		var foundInSelectedItems = findElement(selectedObject);
		if( foundInSelectedItems != -1) allSelectedItems.splice(foundInSelectedItems, 1);
	}

	this.clearSelectedItems = function() {
		allSelectedItems = [];
	}

	this.updateSelectedItems = function(selectedObject) {
		if(selectedObject.selected) {
			addSelectedItem(selectedObject);
		} else {
			removeSelectedItem(selectedObject);
		}
		$rootScope.$broadcast('selection:updated', allSelectedItems);
	}

	this.getAllSelectedItems = function() {
		return allSelectedItems;
	}
}