function ApprovalDirective(){
	return {
		restrict: 'CE',
		templateUrl: (MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface()) ? 'modules/approvals/ApprovalDetailsDirectiveMobile.tpl.html' : "modules/approvals/ApprovalDetailsDirective.tpl.html",
		controller: 'ApprovalDetailsDirectiveController'
	}
}

angular
	.module('bvApprovalDirective', ['approvals.service', 'localization.service','nvd3ChartDirectives','image.service','repository.service','bvdialog.service', 'ApprovalDetailsDirectiveController.directive'])
    .directive('bvApprovalDirective', [ApprovalDirective]);
