bvSplitterController.$inject = ['$scope', '$element', '$attrs', '$document', 'bvSplitterService', '$timeout'];

function bvSplitterController($scope, $element, $attrs, $document, bvSplitterService, $timeout){

	var pane = []
	var paneNames = [];
	var instancePath = StringUtils.cssPath($element[0]);

	bvSplitterService.page.x = localStorage.getItem('bvDocumentTreeBar_' + StringUtils.hashCode(instancePath)) || 0;

	$timeout(function(){
		paneNames = $scope.panes.replace(/ /g, '').split(',');

		pane = []
		bvSplitterService.pane.forEach(function(item, i){
			paneNames.forEach(function(item_, j){
				if(item.name == item_){
					pane.push(item)
				}
			})
		})

		var sort = function(a,b){
			var r = b.left;
			var p = a.left;
			if(p<r){return -1;}
			else if(r<p){return 1;}
			return 0;
		}
		pane.sort(sort);

		bvSplitterService.page.x != 0 ? setBar() : null;
		$element[0].style.opacity = 1;
	},100);

	function setBar(){
		if(!MobileUtil.isMobileClient()){
			var gap = pane[0].el.getBoundingClientRect().left;

			(bvSplitterService.page.x - gap < pane[0].min) ?
					bvSplitterService.page.x = gap + parseInt(pane[0].min)  : null;
			(pane[1].right - bvSplitterService.page.x < pane[1].min) ?
					bvSplitterService.page.x = pane[1].right - pane[1].min  : null;

			pane.forEach(function(item, i){
				var rule = item.rule.replace(/{x}/g, parseInt(bvSplitterService.page.x - gap))
				item.el.setAttribute('style', rule);
			})
		}
	}

	$element.on('mousedown', function(event) {
		event.preventDefault();

        $document.on('mousemove', mousemove);
        $document.on('mouseup', mouseup);
    });

	function mousemove(event) {
		$scope.page = bvSplitterService.page;
		bvSplitterService.page.x = event.pageX

		setBar();
    }

	function mouseup() {
		 localStorage.setItem('bvDocumentTreeBar_' + StringUtils.hashCode(instancePath), bvSplitterService.page.x);
		 $document.unbind('mousemove', mousemove);
		 $document.unbind('mouseup', mouseup);
	}
}