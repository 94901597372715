img.$inject = ['localizedMessages'];

function img(localizedMessages)
{
	return {
		restrict: 'E',
		link: function(scope, element, attrs){
			var name = attrs.name;
			var alt = attrs.alt;

			if((name && name != "") && (alt === undefined || alt === ""))
			{
				name = localizedMessages.get(name.toUpperCase());
				attrs.$set("alt", name);
			}
		}
	}
}
