draggable.$inject = ['$parse', 'dragDropService'];

function draggable($parse, dragDropService) {
    return {
        link: function(scope, element, attrs) {
            // this gives us the native JS object
            var el = element[0];

            var isSurface = scope.MobileUtil.isSurface();
            var isMobileClient = scope.MobileUtil.isMobileClient();

            if(isSurface || isMobileClient)
            {
                el.draggable = false;
                return false;
            }

            el.draggable = true;

            el.addEventListener(
                'dragstart',
                function(e)
                {
                    dragDropService.resetDragDropService();

                    var fn =  $parse(scope[attrs.dragStartHandler]);

                    dragDropService.allDraggedItems.push(scope[attrs.selectedItem]);
                    dragDropService.dragInitiator = this;
                    e.dataTransfer.effectAllowed = 'copy';

                    if (fn && (typeof fn === 'function'))
                    {
                        fn(e, element, dragDropService);
                    }
                    else
                    {
                        e.dataTransfer.setData('Text', this.attributes.id.value);
                    }

                    this.classList.add('drag');
                    return false;
                },
                false
            );

            el.addEventListener(
                'dragend',
                function(e)
                {
                    /*var crt = document.getElementById("drag-drop-coveredup");
                    crt.style.position = "absolute";crt.style.height = "0px";crt.style.width = "0px";
    */
                    var crt = document.getElementById("drag-drop-coveredup");
                    if(crt)
                    {
                        //crt.remove();
                    }

                    this.classList.remove('drag');
                    return false;
                },
                false
            );
        }
    }
}