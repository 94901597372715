function configDashboardController($stateProvider, $locationProvider){
	
}
function DashboardController($scope, $state, $stateParams, dashboardService, localizedMessages, context, SortUtility, bvPollingProcessor, registerStatusCategory, surveysService, repositoryService, calendarService, homeService, approvalsService){
	$scope.ui = {
			label : {
    			all : localizedMessages.get('ALL'),
    			signatures : localizedMessages.get('SIGNATURES'),
    			events : localizedMessages.get('EVENTS'),
    			approvals : localizedMessages.get('APPROVALS'),
    			surveys : localizedMessages.get('SURVEYS'),
    			openItems : localizedMessages.get('OPEN_ITEMS'),
    			priority : localizedMessages.get('PRIORITY'),
    			due : localizedMessages.get('DUE'),
    			portal : localizedMessages.get('PORTAL'),
    			responseType : localizedMessages.get('RESPONSE_TYPE'),
    			anonymous : localizedMessages.get('ANONYMOUS'),
    			namedResponses : localizedMessages.get('NAMED_RESPONSES'),
    			edit : localizedMessages.get('EDIT'),
    			complete : localizedMessages.get('COMPLETE'),
    			review : localizedMessages.get('REVIEW'),
    			start : localizedMessages.get('START'),
    			resume : localizedMessages.get('RESUME'),
    			modifiedBy : localizedMessages.get('MODIFIED_BY'),
    			dateModified : localizedMessages.get('DATE_MODIFIED'),
    			fileSize : localizedMessages.get('FILE_SIZE'),
    			sign : localizedMessages.get('SIGN'),
    			startDate : localizedMessages.get('START_DATE'),
    			open : localizedMessages.get('OPEN'),
    			notYetResponded : localizedMessages.get('NOT_YET_RESPONDED'),
    			createdBy : localizedMessages.get('CREATED_BY'),
    			by : localizedMessages.get('BY'),
    			
			}
		};
	
	$scope.middleBarObject.title = $scope.ui.label.openItems;
	$scope.$parent.currentState.value = $state.$current.name;
	var rightMenuList = [
// 	                { title:'Sort',click:'', iconUrl: 'resources/assets/svgs/RightMenuSort.svg', svgFill: '#fff', dropDownMenu:SortUtility.getDefaultSortByItem()}
 	              ];
	var clearDirective = function()
	{
		$scope.compileHtml = null;
	};
	
	$scope.tabs = [
	               	{title : $scope.ui.label.all, content : 'all', active: true, func:clearDirective},
	               	{title : $scope.ui.label.signatures, content : 'signatures',func:clearDirective},
	               	{title : $scope.ui.label.events, content : 'events', func:clearDirective},
	               	{title : $scope.ui.label.approvals, content : 'approvals', func:clearDirective},
	               	{title : $scope.ui.label.surveys, content : 'surveys', func:clearDirective},
	               ];
//	               { title:'All', content:'all' ,rightMenuList:rightMenuList}
//	 			];

	var currentUserVO = context.getModel().userVO;
	var getDashbordList = function()
	{
		$scope.dashboardList = [];
		$scope.signatureList = [];
		$scope.approvalList = [];
		$scope.eventList = [];
		$scope.surveyList = [];
		$scope.favoritesList = [];
		$scope.newItemsList = [];
		$scope.compileHtml = null;
		var tasks = [];
		dashboardService.getAllOpenItems()
		.then(function(data) {
			var map = data.data;
			var approvalArr = map.approvals 
			if(approvalArr != null)
			{
				for (var i in approvalArr) {
					var approval = {};
					approval.type = "approval";
					approval.currentApprovalPollQuestionResponse = approvalArr[i].currentApprovalPollQuestionResponse;
					approval.createdBy = approvalArr[i].createdBy;
					approval.name = approvalArr[i].name;
					approval.ownerList = approvalArr[i].ownerList;
					approval.objectBaseUuid = approvalArr[i].objectBaseUuid;
					approval.date = approvalArr[i].dueDate;
					approval.sortDate = moment(approvalArr[i].lastModified);
					approval.objectVO = approvalArr[i];
					tasks.push(approval);
					$scope.approvalList.push(approval);
				}
			}
			var surveysArr = map.surveys;
			if(surveysArr != null)
			{
				for (var i in surveysArr) {
					var survey = {};
					survey.type = "survey";
					survey.title = surveysArr[i].name;
					survey.sortDate = moment(surveysArr[i].lastModified);
					survey.date = surveysArr[i].dueDate;
					survey.objectVO = surveysArr[i];
					survey.objectBaseUuid = surveysArr[i].objectBaseUuid;
					tasks.push(survey);
					$scope.surveyList.push(survey);
				}
			}
			var signatureArr = map.signatures;
			if(signatureArr != null)
			{
				for (var i in signatureArr) {
					var signature = {};
					signature.type = "signature";
					signature.encodedThumbnail = signatureArr[i].encodedThumbnail;
					signature.name = signatureArr[i].name;
					signature.sortDate = moment(signatureArr[i].lastModified);
					signature.lastModifiedBy = signatureArr[i].lastModifiedBy;
					signature.lastModified = signatureArr[i].lastModified;
					signature.fileSize = signatureArr[i].fileSize;
					signature.objectBaseUuid = signatureArr[i].objectBaseUuid;
					signature.objectVO = signatureArr[i];
					tasks.push(signature);
					$scope.signatureList.push(signature);
				}
			}
			var calEventsArr = map.calendars;
			if(calEventsArr != null)
			{
				for (var i in calEventsArr) {
					var calEvent = {};
					calEvent.type = "calendarEvent";
					calEvent.sortDate = moment(calEventsArr[i].lastModified);
					calEvent.name = calEventsArr[i].name;
					calEvent.startDate = moment(parseInt(calEventsArr[i].startDateGMTMillis)).format('MMM D, YYYY hh:mm A');
					calEvent.objectVO = calEventsArr[i];
					calEvent.objectBaseUuid = calEventsArr[i].objectBaseUuid;
					tasks.push(calEvent);
					$scope.eventList.push(calEvent);
				}
			}
//			var favoritesArr = map.favorites;
//			if(favoritesArr != null)
//			{
//				for (i in favoritesArr) {
//					var favorite = {};
//					favorite.type = "favorite";
//					favorite.sortDate = moment(favoritesArr[i].lastModified);
//					favorite.name = favoritesArr[i].name;
//					favorite.createdBy = favoritesArr[i].createdBy;
//					favorite.objectVO = favoritesArr[i];
//					favorite.objectBaseUuid = favoritesArr[i].objectBaseUuid;
//					tasks.push(favorite);
//					$scope.favoritesList.push(favorite);
//				}
//			}
//			var newItemsArr = map.newitems;
//			if(newItemsArr != null)
//			{
//				for (i in newItemsArr) {
//					var newItem = {};
//					newItem.type = "newitem";
//					newItem.sortDate = moment(newItemsArr[i].lastModified);
//					newItem.name = newItemsArr[i].name;
//					newItem.createdBy = newItemsArr[i].createdBy;
//					newItem.objectVO = newItemsArr[i];
//					newItem.objectBaseUuid = newItemsArr[i].objectBaseUuid;
//					tasks.push(newItem);
//					$scope.newItemsList.push(newItem);
//				}
//			}
			var sort = function(a,b){
		    	var r = a.sortDate;
		    	var p = b.sortDate;
		    	if(p<r){return -1;}
		    	else if(r<p){return 1;}
		    	return 0;
			}
			homeService.countItems(tasks.length);
			tasks.sort(sort);
			$scope.dashboardList.push.apply($scope.dashboardList, tasks);
		});
		
	};
	
	getDashbordList();
	
	
	$scope.loggedInUserSurveyResponseStatus = function(survey){
    	for(var i=0;i<survey.participantBaseUuidAndResponseList.length;i++){
    		if(survey.participantBaseUuidAndResponseList[i].objectBaseUuid == currentUserVO.objectBaseUuid){
    			return survey.participantBaseUuidAndResponseList[i].respondedStatus;
    		}
    	}
    };
    
    $scope.showSurvey = function(survey)
	{
		surveysService.setCurrentSelectedSurveyFromList(survey); //For survey report
		surveysService.setParticipantBaseUuidList(survey);		//For survey report
		surveysService.getSurveyDetailsJSON(survey.objectBaseUuid).then(function(data) {
		// $state.go('home.openSurvey');
			
		surveysService.setSurvey(data);
	    survey = surveysService.getSurvey();
		surveysService.setQuestionBaseUuidList(survey);
		surveysService.setAllQuestionsList(survey);
	    sessionStorage.setItem('surveyBaseUuid',survey.objectBaseUuid);
	    $scope.fromOpenItems = true;
	    $scope.compileHtml = '<bv-open-survey-directive></bv-open-survey-directive>';
		});
	};
	
	
	$scope.clickEvent = function(eventObj)
    {
		var homeScope = angular.element(document.querySelector('[ui-view="content"]')).scope();
		$scope.fromOpenItems = true;
		homeScope.showEventDetails(eventObj.objectBaseUuid, eventObj.objectVersionUuid);
    }
	
//	$scope.sendEventResponse = function(){
//		var objectVersionUuid = this.event.data.objectVersionUuid;
//		var objectBaseUuid = this.event.data.objectBaseUuid;
//		var response = this.response;
//		
////		calendarService.setCalendarResponse(objectVersionUuid, objectBaseUuid, response);
//		$scope.showSubmit = false;
//		if(!$scope.showEdit){
//			console.log($scope.compileHtml);
//		}
//	}
	
	
//	$scope.clickEvent = function(eventObj){
//		event.stopPropagation();
//		event.preventDefault();
//		$scope.event = eventObj;
//		$scope.showSubmit = true;
//		$scope.showEdit = false;
//		$scope.event.data = {};
//		$scope.event.data.createdBy = eventObj.createdBy;
//
//		$scope.event.from = moment(parseInt(eventObj.startDateGMTMillis)).format('MMM D, YYYY hh:mm:ss A');
//		$scope.event.to = moment(parseInt(eventObj.endDateGMTMillis)).format('MMM D, YYYY hh:mm:ss A');
//		
//		 calendarService.getCalendarEventAttachments(eventObj.data.objectBaseUuid).then(function(data){
//			 if(data){
//				 $scope.attachments = data.data;
//			 }
//		 });
//		 calendarService.loadGuestandResponseForEvent(eventObj.objectVersionUuid).then(function(data){
//			 if(data){
//				 var guests = data.data.guests;
//				 var eventResponseList = data.data.eventResponseList;
//				 
//				 for(i in guests){
//					 for(k in eventResponseList){
//						 if(guests[i].objectBaseUuid == eventResponseList[k].createdByBaseUuid){
//							 if(eventResponseList[k].response == "NOT_RESPONDED"){
//								 guests[i].response = "tentative";
//							 }
//							 else{
//								 guests[i].response = eventResponseList[k].response.toLowerCase();
//							 }
//						 }
//					 }
//				 }
//				 
//				 $scope.guests = guests;
//			 }
//		 });
//		 
//		ngDialog.open({
//      	    template: 'modules/calendar/EventDialog.tpl.html',
//      	    className: 'ngdialog-theme-default calendar-dialog',
//      	    scope: $scope
//		  });
//		
//		$scope.sendEventResponse = function(){
//			var objectVersionUuid = this.event.objectVersionUuid;
//			var objectBaseUuid = this.event.objectBaseUuid;
//			var response = this.response;
//			for(var i = $scope.dashboardList.length - 1; i >= 0; i--)
//			{
//				if($scope.dashboardList[i].objectBaseUuid == objectBaseUuid)
//				{
//					$scope.dashboardList.splice(i, 1);
//				}
//			}
////			calendarService.setCalendarResponse(objectVersionUuid, objectBaseUuid, response);
//			$scope.showSubmit = false;
//			
//		}
//	}
	
	$scope.openApproval = function(objectBaseUuid) {
		approvalsService.selectedApprovalUuid = objectBaseUuid;
		approvalsService.fromOpenItems = true;
		$scope.fromOpenItems = true;
		$scope.compileHtml = '<bv-approval-directive></bv-approval-directive>';
	};
	
	$scope.openPdfViewer = function(documentVO)
    {
    	repositoryService.openPdfViewer(documentVO);
    };
}

angular
.module('openitems', ['dashboard.service', 'localization.service', 'context.service', 'sortUtility.service', 'scrollUtility.service'])
.config(configDashboardController)
.controller('DashboardController', ['$scope', '$state', '$stateParams', 'dashboardService', 'localizedMessages','context','SortUtility','bvPollingProcessor','registerStatusCategory', 'surveysService', 'repositoryService', 'calendarService', 'homeService','approvalsService', DashboardController])
.directive('compile', ['$compile', function ($compile) {
    return function(scope, element, attrs) {
        scope.$watch(
            function(scope) {
                return scope.$eval(attrs.compile);
            },
            function(value) {
                element.html(value);
                $compile(element.contents())(scope);
            }
        );
    };
}])
