function newItemsService($q, ajaxService, responseValidatorService, localizedMessages) {

	var expiryOptions = {
		"NEVER"    : localizedMessages.get("NEVER"),
		"1_MONTH"  : "1 "+localizedMessages.get('MONTH'),
		"2_MONTHS" : "2 "+localizedMessages.get('MONTHS'),
		"3_MONTHS" : "3 "+localizedMessages.get('MONTHS'),
		"4_MONTHS" : "4 "+localizedMessages.get('MONTHS'),
		"5_MONTHS" : "5 "+localizedMessages.get('MONTHS'),
		"6_MONTHS" : "6 "+localizedMessages.get('MONTHS')
	};
	
	var expiryOptionsNameValue =[
	    {name: localizedMessages.get('NEVER'), value:"NEVER"},
		{name:"1 "+localizedMessages.get('MONTH'), value:"1_MONTH"}, 
		{name:"2 "+localizedMessages.get('MONTHS'), value:"2_MONTHS"}, 
		{name:"3 "+localizedMessages.get('MONTHS'), value:"3_MONTHS"}, 
		{name:"4 "+localizedMessages.get('MONTHS'), value:"4_MONTHS"}, 
		{name:"5 "+localizedMessages.get('MONTHS'), value:"5_MONTHS"}, 
		{name:"6 "+localizedMessages.get('MONTHS'), value:"6_MONTHS"}
	];

	
	
	var service = {
		getNewItems: function(selectedNewItemsOption, page, pageSize, isThumbNailRequired) {
			//The portalBaseUuid parameter is set to empty string, this is currently only used from React modules.
			var request=ajaxService.getAllNewItemsForMeetXClient(selectedNewItemsOption, page, pageSize, isThumbNailRequired, '');
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		addToNewItems: function(listofBaseUuids)
		{
			var request=ajaxService.addToNewItems(listofBaseUuids);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		removeFromNewItems: function(documentBaseUuids)
		{
			var request=ajaxService.removeFromNewItems(documentBaseUuids);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		getConfiguredTimeLimitForNewItemsToExpire: function() 
		{
			var request=ajaxService.getConfiguredTimeLimitForNewItemsToExpire();
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
			
		},
		getExpiryOptions: function()
		{
		   return expiryOptionsNameValue;
		},

		getExpiryString: function(key) {
			return expiryOptions[key];
		}
	}
	return service;
}
angular
	.module('newitems.service', [ 'ajax.service' ])
	.factory('newItemsService',[ '$q', 'ajaxService', 'responseValidatorService','localizedMessages', newItemsService ]);