meetingDetailsReactController.$inject = [ '$scope', 'reactInterface' ];

function meetingDetailsReactController($scope, reactInterface) {
	const bvModal = $scope.bvModalControllerInputs.bvModal;

	if ($scope.bvModalControllerInputs.onMeetingDeleted
			&& $scope.bvModalControllerInputs.onMeetingDeleted !== null) {
		reactInterface.onMeetingDeleted = $scope.bvModalControllerInputs.onMeetingDeleted
	}

	$scope.reactInterface = reactInterface
	$scope.eventUuid = $scope.bvModalControllerInputs.eventUuid
	$scope.startDate = $scope.bvModalControllerInputs.startDate
	$scope.endDate = $scope.bvModalControllerInputs.endDate
	$scope.uniqDate = $scope.bvModalControllerInputs.uniqDate

	$scope.closeDialog = function() {
		bvModal.close();
	}
}