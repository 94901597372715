function reactlistviewDirective(){
    return {
        restrict: 'E',
        scope: {
            callbacks: '=',
            openEvent: '='
        },
        templateUrl: 'modules/meetings/reactlistview/reactlistview.tpl.html',
        controller: 'reactlistviewController'
    }
}