/**
 * Acts as a wrapper for drawImage ensuring that if the canvas's
 * backing store pixel ratio is different to the device pixel
 * ratio that the canvas is scaled appropriately.
 * 
 * @version 0.1
 * @author Paul Lewis
 */
var HDPICanvas = (function() {

/**
 * Writes an image into a canvas taking into account the backing store pixel
 * ratio and the device pixel ratio.
 * 
 * @author Paul Lewis
 * @param {Object} opts The params for drawing an image to the canvas
 */
function drawImage(opts) {

	if(!opts.canvas) {
		throw("A canvas is required");
	}
	if(!opts.image) {
		throw("Image is required");
	}

	// get the canvas and context
	var canvas = opts.canvas,
		context = canvas.getContext('2d'),
		image = opts.image,

	// now default all the dimension info
		srcx = opts.srcx || 0,
		srcy = opts.srcy || 0,
		srcw = opts.srcw || image.naturalWidth,
		srch = opts.srch || image.naturalHeight,
		desx = opts.desx || srcx,
		desy = opts.desy || srcy,
		desw = opts.desw || srcw,
		desh = opts.desh || srch,
		auto = opts.auto,

	// finally query the various pixel ratios
		devicePixelRatio = window.devicePixelRatio || 1,
		backingStoreRatio = context.webkitBackingStorePixelRatio ||
												context.mozBackingStorePixelRatio ||
												context.msBackingStorePixelRatio ||
												context.oBackingStorePixelRatio ||
												context.backingStorePixelRatio || 1,

		ratio = devicePixelRatio / backingStoreRatio;

	// ensure we have a value set for auto.
	// If auto is set to false then we
	// will simply not upscale the canvas
	// and the default behaviour will be maintained
	if (typeof auto === 'undefined') {
		auto = true;
	}

	// upscale the canvas if the two ratios don't match
	if (auto && devicePixelRatio !== backingStoreRatio) {

		var oldWidth = canvas.width;
		var oldHeight = canvas.height;

		canvas.width = oldWidth * ratio;
		canvas.height = oldHeight * ratio;

		canvas.style.width = oldWidth + 'px';
		canvas.style.height = oldHeight + 'px';

		// now scale the context to counter
		// the fact that we've manually scaled
		// our canvas element
		context.scale(ratio, ratio);
	}

	context.drawImage(pic, srcx, srcy, srcw, srch, desx, desy, desw, desh);
}

	return {
		drawImage: drawImage
	};

})();






function calendarService($q, ajaxService, responseValidatorService, calendarCache, $timeout, localizedMessages, bvLoadingSpinner, bvSliderService, context, $compile, calendarServiceContext, bvDialog, bvDialogService, templatesService) {
	var service = {};
	var calendarEvents 	= {};
	var rightMenu 		= {};
	var timeOptions 	= [];
	var events = {};
	var buildingCalendar = {};
	var lastState = "";
	var isCurrentUserAllowedToCreateMeetings = false;
  var tabs = {}
  var showMonthView = {
    val: true
  }

    service.tabs = tabs;
	service.getLastState = function()
	{
		return lastState;
	}
	service.setLastState = function(lastStateVal){
		return lastState = lastStateVal;
	}

	service.setIsCurrentUserAllowedToCreateModule = function(isCurrentUserAllowedToCreateModule){
		this.isCurrentUserAllowedToCreateMeetings = isCurrentUserAllowedToCreateModule;
	}
	service.isCurrentUserAllowedToCreateModule = function(){
		return this.isCurrentUserAllowedToCreateMeetings;
	}

	service.generateTimeOptions = function(){
		var i=0;
		var seed = 0;
		var initial = 0;
		var reset = true;
		var term = "AM";
		for(i=0;i<92;i++){

			if(i % 4 == 0){
				reset=true;
				initial++;
			}

			if(reset)
			{
				seed = 0;
				reset = false;
			}
			var option = {name:(initial<10?"0"+initial:initial)+":"+(seed<10?"0"+seed:seed)+" "+term,id:i};
			timeOptions.push(option);
			seed+=15;

			if(i==43)
			{
				term = "PM";
				// initial=-1;
			}

			if(i==47){
				initial=0;
			}
		}
	}();

	service.nextMonth = function(number, localized){
		var currentDate;
		localized ? currentDate = moment() : currentDate = StringUtils.momentL();
		var date = moment(currentDate).add(number, 'month');

		return date;
	}

	service.addNew = function(number, events){
	    var cells = [];
	    var dayNow = moment().format('MM/DD/YYYY');
	    var date = service.nextMonth(number);
	    var dateLocalized = service.nextMonth(number, true);
	    var year = moment(date).year();
	    var month = moment(date).month();
	    var monthString = moment(dateLocalized).format('MMMM');
		var title = moment(dateLocalized).format('MMMM YYYY');
	    var monthN = moment(date).month();

	    var weekDays =[
	                   		localizedMessages.get('SUN'),
	                   		localizedMessages.get('MON'),
	                   		localizedMessages.get('TUE'),
	                   		localizedMessages.get('WED'),
	                   		localizedMessages.get('THU'),
	                   		localizedMessages.get('FRI'),
	                   		localizedMessages.get('SAT')
	                  ];
	    var j = 0;

	    for(var i = 0 ; i <= 5 ; i++){
	    	cells[i] = [];
	    	for(var k = 0 ; k <= 6 ; k++){
	    		const day = moment(date).startOf('month').month(month).day(j);
	    		const dayL = StringUtils.momentL(date).startOf('month').month(month).day(j);

	    		cells[i].push({
	    			name 	: day.format('DD'),
	    			date 	: day.unix() * 1000,
	    			current : day.startOf('month').month() == moment(date).month(),
	    			currentDay : moment(date).startOf('month').month(month).day(j).format('MM/DD/YYYY') == dayNow,
	    			weekEnd : moment(date).startOf('month').month(month).day(j).format('e') == '0' || moment(date).startOf('month').month(month).day(j).format('e') == '6',
	    			events 	: events.listObjects && events.listObjects.length > 0 ? events.listEventsByDay[dayL.format('ll')] : []
	    		});
	    		j++;
	    	}
	    }

	    var rowNumbers = 5;
	    var rowPlus = false;
	    if(moment(cells[5][0].date).month() == moment(cells[4][0].date).month()){
	    	rowNumbers = 6;
	    	rowPlus = true;
	    }
	    var rows = [];
	    for(var i = 0 ; i < rowNumbers ; i++){
	      rows.push({ cells : cells[i] });
	    }

	    var slide = { name : 'test', rows : rows, title: title, titleMonth : monthString, titleYear : year, weekDays : weekDays, rowPlus : rowPlus }

	    // calendarServiceContext.list.slides = []
	    if(number == 0){
	    	calendarServiceContext.list.slides[bvSliderService.currentIndex] = slide;
	    }
	    else{
	    	calendarServiceContext.list.slides[bvSliderService.currentIndex + number] = slide;
	    }
	    calendarServiceContext.list.slides[bvSliderService.currentIndex].showCanvas = true;
	}

	service.addNext = function(events){
		var lastIndex = 0;
		for(var i = 0 ; i < calendarServiceContext.list.slides.length ; i++){
			if('name' in calendarServiceContext.list.slides[i]){
				lastIndex = i;
			}
		}
		if(lastIndex + 1 < calendarServiceContext.list.slides.length){
		// console.log('next')
			service.addNew(lastIndex - bvSliderService.currentIndex + 1, events);
		}
	}

	service.addPrev = function(events){
		var lastIndex = 0;
		for(var i = calendarServiceContext.list.slides.length - 1 ; i >= 0 ; i--){
			if('name' in calendarServiceContext.list.slides[i]){
				lastIndex = i;
			}
		}
		if(lastIndex - 1 >= 0){
			// console.log('prev')
			service.addNew(lastIndex - bvSliderService.currentIndex - 1, events);
		}
	}

	service.scrollOut = function(){
		var window = document.querySelector('.parent');
		for(var i = 0 ; i < 1000 ; i++){
			setTimeout(function(){
				window.scrollLeft = i;
			},1000)
		}
	}

	service.getAllCalendarEventListLite = function(dateFrom, dateTo, selectedMeetingOption)
	{
		var request=ajaxService.getAllCalendarEventListLiteJSON(selectedMeetingOption, dateFrom, dateTo);
		return request.then(function(data)
		{
			var list=[];
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.getAllCalendarEventListFull = function(dateFrom, dateTo)
	{
		var request=ajaxService.getAllCalendarEventListFullJSON(dateFrom, dateTo);
		return request.then(function(data)
		{
			var list=[];
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.getUserCalendarList = function()
	{
		var request=ajaxService.getUserCalendarListJSON();
		return request.then(function(data)
		{
			var list=[];
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.calendarEvents = function(update) {
		if(update){
			calendarEvents = update;
		}
		return calendarEvents;
	}
	service.getCalendarEventAttachments = function(objectBaseUuid)
	{
		var request=ajaxService.getCalendarEventAttachmentsJSON(objectBaseUuid);
		return request.then(function(data)
		{
			var list=[];
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.loadGuestandResponseForEvent = function(objectBaseUuid)
	{
		var request=ajaxService.loadGuestandResponseForEventJSON(objectBaseUuid);
		return request.then(function(data)
		{
			var list=[];
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.setCalendarResponse = function(objectVersionUuid, objectBaseUuid, eventResponse)
	{
		var request=ajaxService.setCalendarResponseJSON(objectVersionUuid, objectBaseUuid, eventResponse);
		return request.then(function(data)
		{
			var list=[];
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	
	service.recycleIntive = function(objectBaseUuid)
	{
		var request=ajaxService.recycleIntive(objectBaseUuid);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.getCalendarEventsForMeetingCenterInSyncCommunicationJSON = function()
	{
		var request=ajaxService.getCalendarEventsForMeetingCenterInSyncCommunicationJSON();
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.getRunningHostedCalendarEventsForMeetingCenterInSyncCommunicationJSON = function()
	{
		var request=ajaxService.getRunningHostedCalendarEventsForMeetingCenterInSyncCommunicationJSON();
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.rightMenu = function(update)
	{
		if(update){
			rightMenu = update;
		}
		return rightMenu;
	}
	service.getTimeOptions = function(){
		return timeOptions;
	}
	service.getTimeOptionByText = function(text){
		for(var i in timeOptions)
			if(timeOptions[i].name == text)
				return timeOptions[i];
	}
	service.addRemoveParticipantsToEvents = function (isAdd, usersObjectBaseUuidList, eventsObjectBaseUuidList)
	{
		var request=ajaxService.addRemoveParticipantsToEvents(isAdd, usersObjectBaseUuidList, eventsObjectBaseUuidList);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.changeTimeForEvents = function(eventsObjectBaseUuidList, changeTime, day)
	{
		var request=ajaxService.changeTimeForEvents(eventsObjectBaseUuidList, changeTime, day);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.saveMeetingsChanges = function(eventsIdsForTime, changeTime, eventDay, eventsIdsForAddtion, userIdsForAddition, eventUserListForRemoval, inactiveEvents, activeEvents, eventsForCopy, detailsForCopy)
	{
		var request=ajaxService.saveMeetingsChanges(eventsIdsForTime, changeTime, eventDay, eventsIdsForAddtion, userIdsForAddition, eventUserListForRemoval, inactiveEvents, activeEvents, eventsForCopy, detailsForCopy);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.sendEventAlert = function(calendarEventBaseUuid, usersObjectBaseUuidList)
	{
		var request=ajaxService.sendEventAlert(calendarEventBaseUuid, usersObjectBaseUuidList);
		return request.then(function(data)
		{
			if(data)
			{
				const updateMeetingAlertDate = new CustomEvent('updateMeetingAlertDate', {
					bubbles: true,
					detail: data
				});
				document.dispatchEvent(updateMeetingAlertDate);
				return data;
			}
			return null;
		});
	}
	service.sendCustomizedEventAlert = function(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded)
	{
		var request=ajaxService.sendCustomizedEventAlert(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded);
		return request.then(function(data)
		{
			if(data)
			{
				const updateMeetingAlertDate = new CustomEvent('updateMeetingAlertDate', {
					bubbles: true,
					detail: data
				});
				document.dispatchEvent(updateMeetingAlertDate);
				return data;
			}
			return null;
		});
	}
	service.deleteAndSendCustomizedAlertForMeetingCenterCalendarEvent = function(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded)
	{
		var request=ajaxService.deleteAndSendCustomizedAlertForMeetingCenterCalendarEvent(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.deleteAndSendAlertForMeetingCenterCalendarEvent = function(calendarEventBaseUuid, usersObjectBaseUuidList)
	{
		var request=ajaxService.deleteAndSendAlertForMeetingCenterCalendarEvent(calendarEventBaseUuid, usersObjectBaseUuidList);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.getEventsForTheDayForPlink = function (calendarEventBaseUuid)
	{
		var request=ajaxService.getEventsForTheDayForPlink(calendarEventBaseUuid);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}
	service.getAllCalendarEvents = function(refresh, callback, selectedMeetingOption)
	{
		var loc = moment.locale();
        moment.locale('en-us');
	    var m = StringUtils.momentL;

		// if(calendarCache.get('events')){
		// return calendarCache.get('events');
		// }
		// if(clear){
		// events = {};
		// return events;
		// }
		// if(sync == true){
		// $timeout(function(){
		// angular.injector(['ng','calendar.service']).get('calendarService').getAllCalendarEvents(true);
		// },1000);
		// return events;
		// }

		if('list' in events && refresh != true){
			typeof callback !== "undefined" ? callback(events) : null;
			moment.locale(loc);
			  return events;

		}
		
		var date = new Date();
		date = moment(date).format('YYYY-MM-DD');
		var currentYear = moment(date).format('YYYY');;

		var selectedMeetingOption = selectedMeetingOption ? selectedMeetingOption : "ALL";
		var startDate = StringUtils.momentL().subtract(context.getModel().htmlCalendarRangeBackward,'month').set('date',1).set('hour',0).set('minute',0).set('second',0).format('MMM D, YYYY hh:mm:ss A');
		var endDate = StringUtils.momentL().add(context.getModel().htmlCalendarRangeForward,'month').set('date',0).set('hour',23).set('minute',59).set('second',59).format('MMM D, YYYY hh:mm:ss A');
		// var startDate = StringUtils.momentL(currentYear + '-01-01 00:00:00').format('MMM D, YYYY hh:mm:ss A');
		// var endDate = StringUtils.momentL(StringUtils.momentL((parseInt(currentYear) + 1) + '-01-01 00:00:00') - 1).format('MMM D, YYYY hh:mm:ss A');

		ajaxService.getAllCalendarEventListLiteJSON(selectedMeetingOption, startDate, endDate).then(function(data)
		{
			var calEvtList = [];
			var listObjects = [];
			var listEventsByDay = {};
			var EVENT_DAY_NO = null;
			var EVENT_DAY_VALUE = null;
			var listCalendarEvents = [];
			var listDayCalendarEvents = [];
			if(data)
			{
				for(var i in data.data)
				{
					var startTime = parseInt(data.data[i].startDateGMTMillis);
					var startTimeUnix = moment('01/01/0000 ' + moment(parseInt(startTime)).format('hh:mm A')).unix();
					var endTime = data.data[i].endDateGMTMillis;
					var endTimeUnix = moment('01/01/0000 ' + moment(parseInt(endTime)).format('hh:mm A')).unix();
					var createdOnTime = parseInt(data.data[i].createdOnGMTMillis);
					var dayOfYear = StringUtils.momentL(parseInt(startTime)).format('DDDD');
					var isEventOwner = (data.data[i].isEventOwner=="true") ? true : false;
					var portal =  data.data[i].portal;

					var startMonth = StringUtils.momentL(parseInt(startTime)).format('MM');
					var startHour = StringUtils.momentL(parseInt(startTime)).format('HH'); 
					var startMinute = StringUtils.momentL(parseInt(startTime)).format('mm'); 
					var startDay = StringUtils.momentL(parseInt(startTime)).format('DD'); 
					var startYear = StringUtils.momentL(parseInt(startTime)).format('YYYY'); 
					
					var endMonth = StringUtils.momentL(parseInt(endTime)).format('MM');
					var endHour = StringUtils.momentL(parseInt(endTime)).format('HH'); 
					var endMinute = StringUtils.momentL(parseInt(endTime)).format('mm'); 
					var endDay = StringUtils.momentL(parseInt(endTime)).format('DD'); 
					var endYear = StringUtils.momentL(parseInt(endTime)).format('YYYY'); 
					var endDayOfYear = StringUtils.momentL(parseInt(endTime)).format('DDDD');
					
					var intDayOfYear = parseInt(dayOfYear);
					var intEndDayOfYear = parseInt(endDayOfYear);
					
					// listCalendarEvents.push({
					// title: data.data[i].name,
					// start: new Date(startYear, startMonth-1, startDay, startHour, startMinute),
					// end: new Date(endYear, endMonth-1, endDay, endHour, endMinute),
					// day: dayOfYear
					// });
					if(intDayOfYear === intEndDayOfYear){
						if(listCalendarEvents[m(startTime).format('ll')]==null)
						{
						  listCalendarEvents[m(startTime).format('ll')] = [];
						}
						listCalendarEvents[m(startTime).format('ll')].push({
							name: data.data[i].name,
							title: data.data[i].name,
							objectBaseUuid		: data.data[i].objectBaseUuid,
							isEventOwner		: data.data[i].isEventOwner,
							portal              : data.data[i].portal,
							startDateGMTMillis	: startTime,
							endDateGMTMillis	: endTime,
						    start: new Date(startYear, startMonth-1, startDay, startHour, startMinute),
						    end: new Date(endYear, endMonth-1, endDay, endHour, endMinute),
						    startDate: moment(parseInt(startTime)).format('hh:mm A'),
						    endDate: moment(parseInt(endTime)).format('hh:mm A')
						});
					}
					else if(intDayOfYear < intEndDayOfYear){
						  var currDay = m(startTime);
						  for (var st = intDayOfYear; st <= intEndDayOfYear; st++) { 
							  if(listCalendarEvents[currDay.format('ll')]==null)
								{
								  listCalendarEvents[currDay.format('ll')] = [];
								}
							  listCalendarEvents[currDay.format('ll')].push({
								    name: data.data[i].name,
								    title: data.data[i].name,
								    objectBaseUuid		: data.data[i].objectBaseUuid,
									isEventOwner		: data.data[i].isEventOwner,
									portal              : data.data[i].portal,
								    startDateGMTMillis	: startTime,
								    endDateGMTMillis	: endTime,
								    start: new Date(startYear, startMonth-1, startDay, startHour, startMinute),
								    end: new Date(endYear, endMonth-1, endDay, endHour, endMinute),
								    startDate: moment(parseInt(startTime)).format('hh:mm A'),
								    endDate: moment(parseInt(endTime)).format('hh:mm A')
								});
							  currDay = currDay.add(1,'d');
						  }
					  }else if(intDayOfYear > intEndDayOfYear && intDayOfYear != intEndDayOfYear){
						  var aDay = m(startTime);
						  for (var st = intDayOfYear; st <=365; st++) { 
							  if(listCalendarEvents[aDay.format('ll')]==null)
								{
								  listCalendarEvents[aDay.format('ll')] = [];
								}
							  listCalendarEvents[aDay.format('ll')].push({
								  	name: data.data[i].name,
								  	title: data.data[i].name,
								  	objectBaseUuid		: data.data[i].objectBaseUuid,
									isEventOwner		: data.data[i].isEventOwner,
									portal              : data.data[i].portal,
								  	startDateGMTMillis	: startTime,
								  	endDateGMTMillis	: endTime,
								    start: new Date(startYear, startMonth-1, startDay, startHour, startMinute),
								    end: new Date(endYear, endMonth-1, endDay, endHour, endMinute),
								    startDate: moment(parseInt(startTime)).format('hh:mm A'),
								    endDate: moment(parseInt(endTime)).format('hh:mm A')
								});
							  aDay = aDay.add(1,'d');
						  }
						  
						  for (var st = 1; st <=intEndDayOfYear; st++) { 
							  if(listCalendarEvents[aDay.format('ll')]==null)
								{
								  listCalendarEvents[aDay.format('ll')] = [];
								}
							  listCalendarEvents[aDay.format('ll')].push({
								  	name: data.data[i].name,
								  	title: data.data[i].name,
								  	objectBaseUuid		: data.data[i].objectBaseUuid,
									isEventOwner		: data.data[i].isEventOwner,
									portal              : data.data[i].portal,
								  	startDateGMTMillis	: startTime,
								  	endDateGMTMillis	: endTime,
								    start: new Date(startYear, startMonth-1, startDay, startHour, startMinute),
								    end: new Date(endYear, endMonth-1, endDay, endHour, endMinute),
								    startDate: moment(parseInt(startTime)).format('hh:mm A'),
								    endDate: moment(parseInt(endTime)).format('hh:mm A')
								});
							  aDay = aDay.add(1,'d');
						  }
					  }
					
					
					if(listDayCalendarEvents[intDayOfYear]==null)
					{
						listDayCalendarEvents[intDayOfYear] = [];
					}
					
					listDayCalendarEvents[intDayOfYear].push({
						title: data.data[i].name,
					    start: new Date(startYear, startMonth-1, startDay, startHour, startMinute),
					    end: new Date(endYear, endMonth-1, endDay, endHour, endMinute),
					});
					
					if(isEventOwner || data.data[i].isActive== "true")
					{
						listObjects.push({
							objectBaseUuid		: data.data[i].objectBaseUuid,
							objectVersionUuid	: data.data[i].objectVersionUuid,
							name                : data.data[i].name,
							isEventOwner		: isEventOwner,
							portal				: data.data[i].portal,
							startDateGMTMillis	: startTime,
							endDateGMTMillis	: endTime,
							createdOnGMTMillis	: createdOnTime,
							data                : data.data[i],
							isActive            : (data.data[i].isActive== "true")?true:false,
							startDate           : moment(parseInt(startTime)).format('MMM D, YYYY hh:mm A'),
							endDate             : moment(parseInt(endTime)).format('MMM D, YYYY hh:mm A')
						});

						if(calEvtList[dayOfYear]==null)
						{
							calEvtList[dayOfYear] = [];
						}
						calEvtList[dayOfYear].push({
							objectBaseUuid		: data.data[i].objectBaseUuid,
							objectVersionUuid	: data.data[i].objectVersionUuid,
							name 				: data.data[i].name,
							isEventOwner		: isEventOwner,
							portal				: data.data[i].portal,
	 						data                : data.data[i],
	 						startDateGMTMillis	: startTime,
	 	    				endDateGMTMillis	: endTime,
	 	    				createdOnGMTMillis	: createdOnTime,
	 	    				isActive            : (data.data[i].isActive== "true")?true:false,
	 	    				startDate 			: moment(parseInt(startTime)).format('hh:mm A'),
	 	    				endDate 			: moment(parseInt(endTime)).format('hh:mm A')
						});

						if(!listEventsByDay[m(startTime).format('ll')])
						    listEventsByDay[m(startTime).format('ll')] = [];

						listEventsByDay[m(startTime).format('ll')].push({
						    objectBaseUuid		: data.data[i].objectBaseUuid,
                            objectVersionUuid	: data.data[i].objectVersionUuid,
                            name                : data.data[i].name,
                            isEventOwner		: isEventOwner,
                            portal				: data.data[i].portal,
                            data                : data.data[i],
                            startDateGMTMillis	: startTime,
                            endDateGMTMillis	: endTime,
                            createdOnGMTMillis	: createdOnTime,
                            isActive            : (data.data[i].isActive== "true")?true:false,
                            startDate 			: moment(parseInt(startTime)).format('hh:mm A'),
                            endDate 			: moment(parseInt(endTime)).format('hh:mm A'),
                            startTimeUnix       : startTimeUnix,
                            endTimeUnix         : endTimeUnix,
                            combined            : []
						});
					}

				}
			}

			listObjects.sort(BV_SORT_FUNC_MEETING('startDateGMTMillis', {
			    name: 'createdOnGMTMillis',
			    primer: parseInt,
			    reverse: false
			}));

			var FINAL_DIFF = 0;
			var currentTime = new Date().getTime();

			// Following logic and loop is written assuming listObjects array is
			// sorted on startDateGMTMillis
			if(listObjects.length>0)
			{
				var firstEventStartTime = listObjects[0].startDateGMTMillis;
				var firstEventYear = parseInt(StringUtils.momentL(firstEventStartTime).format('YYYY'))*1000;
				var currentTimeYear = parseInt(StringUtils.momentL(currentTime).format('YYYY'))*1000;
				var currentDayNumber = parseInt(StringUtils.momentL(currentTime).format('DDDD'));
				currentTime = currentTimeYear + currentDayNumber
				EVENT_DAY_VALUE = StringUtils.momentL(firstEventStartTime).format('ll');
				EVENT_DAY_NO = StringUtils.momentL(firstEventStartTime).format('DDDD');
				FINAL_DIFF = ((firstEventYear + parseInt(EVENT_DAY_NO)) - currentTime);
			}

			for(var i in listObjects)
			{
				var startTime = listObjects[i].startDateGMTMillis;
				var eventDayOfYear = parseInt(StringUtils.momentL(parseInt(startTime)).format('DDDD'));
				var eventYear = parseInt(StringUtils.momentL(parseInt(startTime)).format('YYYY'))*1000;
				var eventTime = eventYear + eventDayOfYear;
				var currentDiff = eventTime - currentTime;
				if(currentDiff >=0)
				{
					EVENT_DAY_VALUE = StringUtils.momentL(startTime).format('ll');
					EVENT_DAY_NO = eventDayOfYear; // moment(startTime).format('DDDD');
					break;
				}
				else
				{
					if(currentDiff > FINAL_DIFF)
					{
						EVENT_DAY_VALUE = StringUtils.momentL(startTime).format('ll');
						EVENT_DAY_NO = eventDayOfYear; // moment(startTime).format('DDDD');
						FINAL_DIFF = currentDiff;
					}
					else
					{

					}
				}
			}
			events.list = calEvtList;
			events.listDayCalendarEvents = listDayCalendarEvents;
			events.listObjects = listObjects;
            events.listEventsByDay = listEventsByDay;
            events.listCalendarEvents = listCalendarEvents;
			events.EVENT_DAY_NO = EVENT_DAY_NO;
			events.EVENT_DAY_VALUE = EVENT_DAY_VALUE;
			events.uniq_eventDates = service.getCalendarUniqEventDates(events)[0];
			events.eventsMap = service.getCalendarUniqEventDates(events)[1];
			calendarCache.put('events', events);
			typeof callback !== "undefined" ? callback(events) : null;
			moment.locale(loc);
			return;
		});
		 moment.locale(loc);
		return events;
	}
	service.getCalendarUniqEventDates = function(events){
		var uniq_eventDates = [];
      // var titleDateFormat = 'MMM D, YYYY';
        var eventsMap = new Map();
        var loc = moment.locale();
        moment.locale('en-us');
        if('listObjects' in events)
        {
        	for (var i=0;i<events.listObjects.length;i++)
	        {
	        	var start_date = StringUtils.momentL(parseInt(events.listObjects[i].startDateGMTMillis)).format('ll');

	            var end_date=StringUtils.momentL(parseInt(events.listObjects[i].endDateGMTMillis)).format('ll');
	            var key = start_date;
	            var dateArray = [];
	            var currentstartDate = moment(start_date);
	            var stopDate = moment(end_date);
	            
	            while (currentstartDate <= stopDate) {
	        		
	                dateArray.push(moment(currentstartDate).format('MMM D, YYYY'));
	                currentstartDate = moment(currentstartDate).add(1, 'days');
	            }
	           
	            if(dateArray.length>0)
	            	{
	            		dateArray.forEach(function(item)
	            			{
	            				key=item;	            		
	            				if($.inArray(item, uniq_eventDates) == -1)
	            				{
		            			     uniq_eventDates.push(item);
	            				}	         
	            		        if(eventsMap.has(key))
	            		        {
	            		             eventsMap.get(key).push(events.listObjects[i]);
	            		        }
	            		        else
	            		        {
	            		            eventsMap.set(key,new Array());
	            		            eventsMap.get(key).push(events.listObjects[i]);
	            		        }
	            			}
	            	);
	            	
	            	}
	            
	        }
        }
        moment.locale(loc);
        return [uniq_eventDates, eventsMap];
	}
	service.getList = function(withEvents){
		bvSliderService.currentIndex = context.getModel().htmlCalendarRangeBackward;
		bvSliderService.showIcon.showRightIcon = true;
		bvSliderService.showIcon.showLeftIcon = true;

		// date = moment();
		var numberOfSlides = context.getModel().htmlCalendarRangeBackward + context.getModel().htmlCalendarRangeForward;

		calendarServiceContext.list.slides = [];
		for(var i = 0 ; i < numberOfSlides ; i++){
			calendarServiceContext.list.slides.push({});
		}

		bvLoadingSpinner.complete();

		if(!withEvents){
			service.addNew(0, {});
		}
		else{

			service.addNew(0, events);

			setTimeout(function(){

				for(var i = 0 ; i < context.getModel().htmlCalendarRangeBackward ; i++){
					service.addPrev(events);
				}
				for(var i = 0 ; i < context.getModel().htmlCalendarRangeForward ; i++){
					service.addNext(events);
				}

			});

		}
		return calendarServiceContext.list;
	}
	service.getListUpdateEvents = function(){
		service.getAllCalendarEvents(true, function(){
			calendarServiceContext.list.slides.forEach(function(item){
				item.rows.forEach(function(item){
					item.cells.forEach(function(item){
					    const events_ = events.listEventsByDay[StringUtils.momentL(item.date).format('ll')];
						if(events_ && events_.length > 0){
							item.events = events_;
						}
					})
				})
			})
		})
	}
	service.updateEventInvitiesReadOn = function(objectBaseUuid){
		var request=ajaxService.updateEventInvitiesReadOn(objectBaseUuid);
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
	}

	service.sendMeetingAlert = function(activeMeeting, sendEventAlert){
        let SELECTED_USERS_TO_ALERT = [];
        const dialog = bvDialog.showAlertUsers(
            localizedMessages.get('ALERT') + ": " + activeMeeting.name,
            localizedMessages.get('USERS'),
            [...activeMeeting.guests],
            selectedUsers => {
                SELECTED_USERS_TO_ALERT = selectedUsers;
            },
            localizedMessages.get('CANCEL'),
            localizedMessages.get('SEND'),
            localizedMessages.get('MSG_ALERT_MEETINGS_CANCEL_ALERT_AFTER_SELECTION'),
            () => {
                if(sendEventAlert && typeof sendEventAlert == 'function')
                    sendEventAlert(SELECTED_USERS_TO_ALERT, dialog);
                else{
                    if(SELECTED_USERS_TO_ALERT.length <= 0){
                    	bvDialog.showMessage(localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER'));
                    	dialog.open();
                    }
                    else{
                        const userIds = SELECTED_USERS_TO_ALERT.map(user => user.objectBaseUuid);
                        service.sendEventAlert( activeMeeting.objectBaseUuid, angular.toJson(userIds));
                        dialog.close();
                    }
                }
            },
            () => {
                if(SELECTED_USERS_TO_ALERT.length > 0)
                    bvDialog.confirm(localizedMessages.get('MSG_ALERT_MEETINGS_CANCEL_ALERT_AFTER_SELECTION'), () => {}, null);
                else
                    dialog.close();
            }
        );
        function openUserAlert() {
    		console.log('alert');
    	}
	}
	
	service.sendMeetingNotification = function(activeMeeting, sendEventAlert){
        var bvModalInputs = {}
            bvModalInputs.windowClass = 'bv-modal-window-send-meeting-notification';
            bvModalInputs.inputs = {};
            bvModalInputs.buttons = [{},{}];
            bvModalInputs.inputs.activeMeeting = activeMeeting;
			bvModalInputs.inputs.popupTitle = localizedMessages.get("NOTIFY_ATTENDEES");
			bvModalInputs.inputs.eventName = sendEventAlert;
            bvModalInputs.controller = 'MeetingNotificationController';
            bvModalInputs.bodyTemplate = templatesService.getUrl('SendMeetingNotificationDialog');
			bvDialogService.showBvModal(bvModalInputs);
	}
	
	service.setMeetingsTabToLocalStorage = function(){
		const value = service.tabs.findIndex(tab => tab.active);
		let defaultCalanderTab;
		try{
			defaultCalanderTab = JSON.parse(localStorage.getItem("defaultCalanderTab"));
		}
		catch(error){}

		if(!defaultCalanderTab || !(defaultCalanderTab instanceof Object)) defaultCalanderTab = {}
		defaultCalanderTab[context.getModel().userVO.objectBaseUuid] = value;
		localStorage.setItem("defaultCalanderTab", JSON.stringify(defaultCalanderTab));
	}

	service.getMeetingsTabToLocalStorage = function(){
		let defaultCalanderTab = {};
		try{
			defaultCalanderTab = JSON.parse(localStorage.getItem("defaultCalanderTab"));
		}
		catch(error){}
    
		if(defaultCalanderTab instanceof Object && context.getModel().userVO.objectBaseUuid in defaultCalanderTab)
			return defaultCalanderTab[context.getModel().userVO.objectBaseUuid]
	}

// service.buildingCalendar = function(update){
// buildingCalendar.val = update;
// return buildingCalendar;
// }

  service.getShowMonthView = function(events) {
    return showMonthView
  }

  service.refreshMonthView = function() {
    showMonthView.val = false
    $timeout(_=>{
      showMonthView.val = true
      var listViewController = angular.element('.bv-meeting-cards').scope();
  	  if(listViewController)
  		  listViewController.initCalendar();
    },0)
  }

  service.getPortalData = function() {
	var request=ajaxService.getPortalData();
		return request.then(function(data)
		{
			if(data)
			{
				return data;
			}
			return null;
		});
  }

	return service;
}

function calendarCache($cacheFactory){
	return $cacheFactory('cache', {
		capacity: 10 // optional - turns the cache into LRU cache
	});
}

function calendarServiceContext(){
	this.list = {};
}

angular
	.module('calendar.service', [ 'ajax.service' ])
	.factory('calendarService',[ '$q', 'ajaxService', 'responseValidatorService', 'calendarCache', '$timeout', 'localizedMessages', 'bvLoadingSpinner', 'bvSliderService', 'context', '$compile', 'calendarServiceContext', 'bvDialog', 'bvDialogService', 'templatesService', calendarService ])
	.factory('calendarCache', calendarCache)
	.service('calendarServiceContext', calendarServiceContext)
