function annotationHandlingService(bvDialogService){

    this.openDialog = function(uploadFileObject) {
      bvDialogService.showBvModal({
        windowClass : 'annotation-handling-dialog',
        controller : 'annotationHandlingController',
        bodyTemplate : 'modules/dialogs/annotationHandling/annotationHandling.tpl.html',
        inputs : {															
					uploadFileObject : uploadFileObject
        }
      })
    }
}