angular
    .module('dialogControllers.service')
	.controller('settingsMobileController', settingsMobileController);

settingsMobileController.$inject = ['$scope', 'localizedMessages', 'homeService', 'homeContext', 'bvDialog', 'context'];

function settingsMobileController($scope, localizedMessages, homeService, homeContext, bvDialog, context){
	$scope.isSurface = MobileUtil.isSurface();
	$scope.isMobile = true;
	$scope.showPinCode = true;
	$scope.onlineStatus = homeContext.onlineStatus.val;
	$scope.iconNextToTitle = 'resources/assets/svgs/backarrow.svg';
	$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
	$scope.rightArrow = "resources/assets/svgs/arrow.svg";
	$scope.UI_most_recent_user_id = localizedMessages.get("MOST_RECENT_USER_ID");
	$scope.UI_challenge_questions = localizedMessages.get("CHALLENGE_QUESTIONS");
	$scope.UI_pin_code = localizedMessages.get("PIN_CODE_LOGIN");
	$scope.UI_multi_factor_authentication = localizedMessages.get("MULTI_FACTOR_AUTHENTICATION");
	$scope.UI_change = localizedMessages.get("CHANGE");
	$scope.popupTitle = localizedMessages.get("SETTINGS");
	$scope.UI_show = localizedMessages.get("SHOW");
	$scope.UI_hide = localizedMessages.get("HIDE");
  	$scope.showChallengeQuestion = context.getModel().companyVO.secondTierEnabled;
  	$scope.isMFAEnabled = context.getModel().companyVO.n3aMfaConfigUrl && context.getModel().companyVO.n3aMfaConfigUrl !== "";
	$scope.openChallengeQuestions = function(){
		if(!$scope.onlineStatus){
			return;
		}
		bvDialog.changeChallengeQuestions($scope.isMobile)}
	$scope.showUserId = true;
	$scope.hideUserId = true;
	$scope.showOrHideUserId = function(){
		$scope.showUserId = !($scope.showUserId);
		$scope.hideUserId = !($scope.hideUserId);
	}
	
	$scope.openPinCode = function() {
		if(!$scope.onlineStatus){
			return;
		}
		bvDialog.showPinLogin($scope.isMobile) 
	}

	$scope.openMFASettings = () => {
		Android.openMFASettings(context.getModel().companyVO.n3aMfaConfigUrl);
	}
}