AgendaFileChooserController.$inject = ['repositoryService','$scope', 'context', '$modalInstance', 'localizedMessages', 'bvDialog', 'agendaModelService', 'repositoryModelService', 'backUtiltiyService', 'templatesService'];

function AgendaFileChooserController(repositoryService, $scope, context, $modalInstance, localizedMessages, bvDialog, agendaModelService, repositoryModelService, backUtiltiyService, templatesService)
{
    var agendaObject = agendaModelService.getInstance();
    var documentVO = agendaObject.businessObject;
    $scope.sourceUrl = agendaObject.sourceUrl;
    var iframeid = btoa(documentVO.objectBaseUuid);
    $scope.iframeId = "agendaIframe_"+iframeid;
    
    $scope.OK = localizedMessages.get('OK');
    $scope.CANCEL = localizedMessages.get('CANCEL');
    $scope.SAVE = localizedMessages.get('SAVE');
    $scope.CLOSE = localizedMessages.get('CLOSE');

    $scope.closeIcon = templatesService.getUrl('closeBtn');
    $scope.iconNextToTitle = 'resources/assets/svgs/backarrow.svg';
    $scope.iconSave = 'resources/assets/svgs/save_icon.svg';

    $scope.middleBarObject = {showBack: false, title: localizedMessages.get('FOLDER')};

    $scope.repositoryDisplayObjects = {
        showTree:false,
        showFilter:false,
        disableRightClick:true,
        onlyFiles:false,
        multiSelect:false,
        displayType:'SMALL',
        permissionCheck:false
    };
	
	var repositoryModel = repositoryModelService.getInstance("AGENDA_FILE_CHOOSER");
    var repositoryList = repositoryModel.repositoryList();
    var backUtility = backUtiltiyService.getInstance("AGENDA_FILE_CHOOSER");

    $scope.getDocumentsForParentBaseUuid = function (baseUuid)
    {
        repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
        {
            repositoryModel.repositoryList(data.data);
        });
    };

    $scope.handleGoBack = function()
    {
        var folderObject = backUtility.popBackStack();
        $scope.middleBarObject.showBack = backUtility.showBack;
        $scope.middleBarObject.title = backUtility.title;
        
        if(folderObject)
        {
            $scope.getDocumentsForParentBaseUuid(folderObject.objectBaseUuid);
        }
    }

    $scope.saveAgendaLinks = function()
    {
        bvDialog.confirm(localizedMessages.get("MSG_SAVE_AGENDA_LINKS"),function()
        {
            var removedLinks = IDRLinkManipulator.getRemovedLinks();
            var addedLinks = IDRLinkManipulator.getAddedLinks();
           
            var json = {addedLinks: addedLinks, removedLinks: removedLinks};
            var agendaLinksJson = JSON.stringify(json);
            agendaLinksJson = btoa(unescape(encodeURIComponent(agendaLinksJson)));
            openModal();
            repositoryService.saveAgendaLinks(documentVO.objectBaseUuid, agendaLinksJson).then(function(data)
            {
                closeModal();
                $scope.close();
            });    
            
        }, null);
    }

    $scope.close = function(result)
    {
        $modalInstance.dismiss('cancel');
    };
}

function AgendaModelService()
{
    var agendaDocument = {};
    agendaDocument.businessObject = null;
    agendaDocument.sourceUrl = null;

    this.getInstance = function()
    {
        return agendaDocument;
    }
}

angular
	.module('agenda.builder', ['localization.service'])
    .service('agendaModelService', [AgendaModelService])
	.controller('AgendaFileChooserController', AgendaFileChooserController);