angular
	.module('branding',['localization.service'])
	.directive('backgroundColor', backgroundColor)
	.directive('fontColor', fontColor)
	.directive('menuColor', menuColor)
	.directive('colorPickerBackground', colorPickerBackground)
	.directive('colorPickerFont', colorPickerFont)
	.directive('colorPickerMenu', colorPickerMenu)
	.directive('orientable', orientable)
	.factory('brand',factoryBranding)
	.controller('brandingController', brandingController)