function MediaModelService()
{
	var multiMediaObject = {};
	multiMediaObject.businessObject = null;
	multiMediaObject.contextMenuOptions = null;

	this.getInstance = function()
	{
		return multiMediaObject;
	}
}