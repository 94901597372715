
function SharedController($scope, $rootScope, $state, $stateParams,$sce, sharedService, commonService, localizedMessages, imageService, bvDialogService, modal, context, repositoryService, documentContextMenu, messagesService){
	var defaultTitle = localizedMessages.get("NEW_ITEMS");
	$scope.middleBarObject.title = defaultTitle;
	$scope.$parent.currentState.value = $state.$current.name.replace(" ", "");

	$scope.messagesLable = localizedMessages.get("MESSAGES");
	$scope.moreLable = localizedMessages.get("MORE");

	$scope.sharedWithMe = {
		pageNo: 0,
		hasMore: true,
		messages: [],
		selectedMessage: {},
		messageSelected: false
	};

	$scope.sharedByMe = {
		pageNo: 0,
		hasMore: true,
		messages: [],
		selectedMessage: {},
		messageSelected: false
	};

	$scope.pageSize = 25;

	$scope.shared;

	$scope.inboxOrSentFlag = 0;

	$scope.sharedCards = {};
	var seprateItemForCards = function()
	{
		angular.copy($scope.shared, $scope.sharedCards);
		$scope.sharedCards.messages = [];
		for(var i in $scope.shared.messages) {
			var item = {};
			angular.copy($scope.shared.messages[i], item);
			
			for(var index in item.fileAttachments) {
				var tempItem = {};
				angular.copy(item, tempItem);
				tempItem.folderAttachments = [];
				tempItem.fileAttachments = [];
				tempItem.attachments = [];
				
				tempItem.attachments = [item.fileAttachments[index]];
				$scope.sharedCards.messages.push(tempItem);
			}

			for(var index in item.folderAttachments) {
				
				var tempItem = {};
				angular.copy(item, tempItem);
				tempItem.folderAttachments = [];
				tempItem.fileAttachments = [];
				tempItem.attachments = [];
				
				tempItem.attachments = [item.folderAttachments[index]];
				$scope.sharedCards.messages.push(tempItem);
			}
		}
		//console.log("after",$scope.sharedCards);
	}

	var switchToSharedWithMe = function()
	{
		$scope.shared = $scope.sharedWithMe;
		$scope.handleLeftIcon();
	}

	var switchToSharedByMe = function()
	{
		$scope.shared = $scope.sharedByMe;
		$scope.handleLeftIcon();
	}

	var selectFirstUnreadMessage = function()
	{
		for(var i=0; i < $scope.shared.messages.length; i++)
		{
			if($scope.shared.messages[i].unRead == false)
			{
				$scope.getItemDetails($scope.shared.messages[i]);
				return;
			}
		}
	}

	$scope.getInboxJSON = function(getMore)
	{
		if($scope.inboxOrSentFlag == 1 && (getMore == false || getMore == undefined))
		{
			return;
		}

		if($scope.inboxOrSentFlag != 1)
		{
			switchToSharedWithMe();
		}

		if($scope.shared.messages.length == 0 || getMore == true)
		{
			sharedService.getInboxJSON($scope.pageSize * $scope.shared.pageNo)
			.then(function(response)
			{
				$scope.sharedWithMe.messages = $scope.sharedWithMe.messages.concat(response.data);
				$scope.shared = $scope.sharedWithMe;
				seprateItemForCards();
				console.log($scope.sharedWithMe.messages);

				if($scope.shared.messageSelected == false) {
					selectFirstMessage();
				}

				if(response.data.length < $scope.pageSize) {
					$scope.shared.hasMore = false;
				}
			});
		}
		$scope.inboxOrSentFlag = 1;
	};

	$scope.getSentJSON = function(getMore)
	{
		if($scope.inboxOrSentFlag == 2 && (getMore == false || getMore == undefined))
		{
			return;
		}

		if($scope.inboxOrSentFlag != 2)
		{
			switchToSharedByMe();
		}

		if($scope.shared.messages.length == 0 || getMore == true)
		{
			sharedService.getSentJSON($scope.pageSize * $scope.shared.pageNo).then(function(response)
			{

				$scope.sharedByMe.messages = $scope.sharedByMe.messages.concat(response.data);
				$scope.shared = $scope.sharedByMe;
				seprateItemForCards();
//				console.log($scope.sharedByMe.messages);
				if($scope.shared.messageSelected == false) {
					selectFirstMessage();
				}

				if(response.data.length < $scope.pageSize) {
					$scope.shared.hasMore = false;
				}
			});
		}

		$scope.inboxOrSentFlag = 2;
	};

	var selectFirstMessage = function () {
		if($scope.shared.messages.length > 0 )
			$scope.selectMessage($scope.shared.messages[0])
	}

/////

//    var secureChat = function (msg, chatWith) {
//    	$rootScope.initiateExternalChat = true;
//    	if(chatWith != undefined)
//    		$rootScope.externalChatWithUser = chatWith;
//    	$state.go('home.securechat');
//    }

    $scope.pullBackSentItem = function() {
    	sharedService.pullBackSentItem($scope.shared.selectedMessage.objectBaseUuid);
    	var index = $scope.shared.messages.indexOf($scope.shared.selectedMessage);
    	$scope.shared.messages.splice(index, 1);
    	selectFirstMessage();
    	$scope.showMessage = false;
    }

    var secureChatForSelectedMessage = function() {
    //	secureChat($scope.shared.selectedMessage);
    }

//    $scope.secureChatForSelectedUser = function(msg) {
//    	var chatWith = {'displayName': msg.createdBy, 'objectBaseUuid': msg.createdByBaseUuid};
//    	secureChat(msg, chatWith);
//    }

    var pullBackConfirmation = function() {

//		ngDialog.open({
//			template: '<div style="text-align:center">' +   localizedMessages.get("PULL_BACK_SENT_ITEM_CONFIRMATION_MSG") + " - {{shared.selectedMessage.name}}?" +
//			'<hr><button class="yesButton" ng-click="pullBackSentItem();closeThisDialog();">' + localizedMessages.get('YES') + '</button>' +
//			'<button class="noButton" ng-click="closeThisDialog()">' + localizedMessages.get('NO') + '</button></div>',
//			plain: true,
//			scope: $scope
//		});
    }

	var newItemsRightMenuList = [
		{ title:'Secure Messaging', click: secureChatForSelectedMessage, iconUrl: ''}
	];

	var sentItemsRightMenuList = [
		{ title:'Secure Messaging', click: secureChatForSelectedMessage, iconUrl: '', svgFill: '#fff'},
		{ title:'Pull Back', click: pullBackConfirmation, iconUrl: '', svgFill: '#fff'}
	];

////

	$scope.tabs = [
        { title:'New Items', content:'newItems', func: $scope.getInboxJSON, rightMenuList:newItemsRightMenuList},
        { title:'Sent Items', content:'sentItems', func: $scope.getSentJSON, rightMenuList:sentItemsRightMenuList }
	];

	$scope.svgFill = '#FFF';

	$scope.openMessage = function(msg)
	{
		$scope.selectMessage(msg);
		$scope.showMessage = true;
		$scope.middleBarObject.showBack = true;

		$scope.middleBarObject.title = $scope.shared.selectedMessage.name.substr(0, 30);
		if($scope.shared.selectedMessage.name.length > 30) $scope.middleBarObject.title += "...";

	};

	$scope.handleLeftIcon = function()
	{
		$scope.showMessage = false;
		$scope.middleBarObject.showBack = false;
		$scope.middleBarObject.title= defaultTitle
	}

	$scope.selectMessage = function(msg) {
		$scope.messageSelected = false;
		$scope.shared.selectedMessage = msg;
	}

    $scope.getDocumentThumbnail = function(attachment) {
		if (attachment.documentThumbnail != undefined)
		{
			return "data:image/png;base64," + attachment.documentThumbnail;
		} else
		{
			if(attachment.boName == "FolderBO") attachment.extensionType = "folderAttachment";
			return imageService.getImageByExtension(attachment.extensionType);
		}
	}

	$scope.getMoreMessages = function()
	{
		$scope.shared.pageNo = $scope.shared.pageNo + 1;
		if($scope.tabs[0].active)
		{
			$scope.getInboxJSON(true);
		} else if($scope.tabs[1].active)
		{
			$scope.getSentJSON(true);
		}
	}

	$scope.openAttachmentFile = function (attachment) {
		repositoryService.openPdfViewer(attachment);
	}

	$scope.openAttachmentFolder = function(attachment) {
		$scope.folderBaseUuid = attachment.objectBaseUuid;
		$scope.currentInstance = "FOLDER_LISTING";
		$scope.folderBarObject = {showBack: false, title: "Folder Attachment"};

		var folderView = new modal.open({
			windowClass: 'folderViewDialog',
            templateUrl:  	'modules/shared/FolderView.tpl.html',
            scope:      	$scope,
            controller: 'folderViewerController'
		});
	}

	$scope.getExpiry = function(msg) {
		if(msg.expiry == undefined) return localizedMessages.get("EXPIRY_NOT_AVAILABLE");
		return localizedMessages.get("EXPIRES_IN") + " " + messagesService.getExpiryString(msg.expiry);
	}

	$scope.handleRightClick = function (xPos, yPos, selectedContextItem)
    {

    	console.log(selectedContextItem);
    	var contextMenuCheckOptions = {
				alreadySubscribed : false
		}
    	repositoryService.isAlreadySubscribed(selectedContextItem).then(function(data){
    		contextMenuCheckOptions.alreadySubscribed = data.data;
    		$scope.contextMenuOption = documentContextMenu.getContextMenu(false, selectedContextItem, 'Shared', contextMenuCheckOptions);
        	if($scope.contextMenuOption.length == 0)
        		return;

        	$scope.$parent.showRightClick = true;

        	var point = documentContextMenu.getPosition(xPos, yPos, selectedContextItem, $scope.contextMenuOption.length, 33, 160);

            $scope.menuStyle = {'top': point.y + "px",'left': point.x + "px"};
    	});
    };

	$scope.getInboxJSON();
}

function FolderViewerController($scope, backUtiltiyService, repositoryService, repositoryModelService)
{
	$scope.iconNextToTitle = 'resources/assets/svgs/backarrow.svg';
	$scope.svgFill = '#fff';
	$scope.currentInstance = "FOLDER_LISTING";
	$scope.showTree = false;
//	$scope.folderBaseUuid = $scope.folderBaseUuid;
	$scope.middleBarObject = $scope.folderBarObject;
	var backUtiltiy  = backUtiltiyService.getInstance($scope.currentInstance);
	var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

	$scope.getDocumentsForParentBaseUuid = function (baseUuid)
    {
    	repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
    	{
    		repositoryModel.repositoryList(data.data);
    	});
    };

	 $scope.handleLeftIcon = function()
	{
		var folderObject = backUtiltiy.popBackStack();
		$scope.middleBarObject.showBack = backUtiltiy.showBack;
		$scope.middleBarObject.title = backUtiltiy.title;

		if(folderObject)
		{
			$scope.getDocumentsForParentBaseUuid(folderObject.objectBaseUuid);
		}
	}

}

angular
	.module('shared', ['ngGrid','shared.service','common.service','localization.service','context.service','repository.service','messages.service','bvSharedItemsCardsDirective','bvlitepdfviewer.module'])
	.config(configMessagesController)
	.controller('SharedController', ['$scope', '$rootScope','$state', '$stateParams', '$sce', 'sharedService', 'commonService', 'localizedMessages','imageService','bvDialogService', '$modal', 'context', 'repositoryService','documentContextMenu', 'messagesService', SharedController])
	.controller('folderViewerController', ['$scope', 'backUtiltiyService', 'repositoryService', 'repositoryModelService', FolderViewerController])
	.filter('unsafe',function($sce)
	{
		return function(text) {
			return $sce.trustAsHtml(text);
		};
	});
