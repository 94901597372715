previewSurveyController.$inject = ['$scope', 'reactInterface', 'context', 'localizedMessages', 'bvDialogService'];

function previewSurveyController($scope, reactInterface, context, localizedMessages, bvDialogService){

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    var survey = $scope.bvModalControllerInputs.survey;
    var overview = $scope.bvModalControllerInputs.overview;
    var preview = $scope.bvModalControllerInputs.preview;

    if(overview){
        $scope.popupTitle = localizedMessages.get("OVERVIEW")
        $scope.overview = true
        angular.element(document.querySelector("body")).addClass("preview-survey-modal");
    }
    if(preview){
        $scope.popupTitle = localizedMessages.get("PREVIEW")
        $scope.preview = true
    }
    $scope.close =  function (){    	
    	angular.element(document.querySelector("body")).removeClass("preview-survey-modal");
    	bvDialogService.closeAllOpenDialogs();
    }
    $scope.surveyBaseUuid = survey.objectBaseUuid
    $scope.reactInterface = reactInterface
}