scrollUtility.$inject = ['$anchorScroll', '$location'];

function scrollUtility($anchorScroll, $location) {

	function scrollTo(hash) {
		if ($location.hash() !== hash) {
			$location.hash(hash);
		}
		$anchorScroll();
	}

	this.scrollTo = function (hash, extraOffset) {
		$anchorScroll.yOffset = extraOffset;
		return scrollTo(hash);
	};
}