function dragDropService() {
	
	this.allDraggedItems = [];

	this.validItems;

	this.invalidItems;

	this.dropTarget;

	this.dropTargetRenderer;

	this.dropTargetBO;

	this.dragItemBO;

	this.allowDrop = true;

	this.dragInitiator; 

	this.dropTargetNode;

	this.invalidateDefault;
	
	
	this.resetDragDropService = function(){
		this.allDraggedItems = [];
		this.validItems = "";

		this.invalidItems = "";

		this.dropTarget = "";

		this.dropTargetRenderer ="";

		this.dropTargetBO = "";

		this.dragItemBO = "";

		this.allowDrop = true;

		this.dragInitiator =""; 

		this.dropTargetNode = "";

		this.invalidateDefault = "";
	}	
	
}