context.$inject = ['ajaxService'];

function context(ajaxService) {
	// This is the Loggedin User Context which will be instantiated once.
	var symmetricKey = null;
	var clientPreLoginDecryptKey = null;
	var serverPublicKey = null;
	var encryptionKeyError = null;
	var enabledFeatures = null;

	var Context = (function() {

		var instance;


		function init() {
			// private fields
			var _model = null;
			var urlRedirectStr = null;

			return {
				setModel: function(model) {
					_model = model;
				},
				getModel : function() {
					return _model;
				},
				clearContext : function() {
					this.setModel(null);
					urlRedirectStr = '';
				},
				getUrlRedirectStr : function(){
					return urlRedirectStr;
				},
				setUrlRedirectStr : function(str){
					urlRedirectStr = str;
				},
				getEncryptionKeyError : function(){
					return encryptionKeyError;
				},
				setEncryptionKeyError : function(str){
					encryptionKeyError = str;
				},
				getClientPreLoginDecryptKey : function(){
					return clientPreLoginDecryptKey;
				},
				setClientPreLoginDecryptKey : function(str){
					clientPreLoginDecryptKey = str;
				},
				getServerPublicKey : function(){
					return serverPublicKey;
				},
				setServerPublicKey : function(str){
					serverPublicKey = str;
				},
				getSymmetricKey : function(){
					return symmetricKey;
				},
				setSymmetricKey : function(str){
					symmetricKey = str;
				},
				setEnabledFeatures : function(str) {
					enabledFeatures = str;
				},
				getEnabledFeatures: function () {
					return enabledFeatures;
				}
			};
		}

		return {
			getInstance: function() {
				if(!instance) {
					instance = init();
				}
				return instance;
			}
		};
	})();

	var homeModelVal = {}
	var service = {


		clearContext : function() {
			Context.getInstance().clearContext();
			return Context.getInstance();
		},
		getContext : function() {
			return Context.getInstance();
		},
		getModel : function() {
			return Context.getInstance().getModel();
		},
		setModel : function(model) {
			if(Context.getInstance().getModel() === null) {
				Context.getInstance().setModel(model);
			} else {
				if(console) console.log('ERROR: context user can only be set once. !!!');
			}
		},
		isReady : function(){
			return (this.getModel() != null && this.getModel().ecxSessionId != null);
		},
		homeModel : function(name, val){
			typeof(name) == 'string' && name != '' ? homeModelVal[name] = val : null;
			return homeModelVal;
		},
		setUrlRedirectStr : function(str){
			Context.getInstance().setUrlRedirectStr(str);
		},
		getUrlRedirectStr : function(){
			return Context.getInstance().getUrlRedirectStr();
		},
		getEncryptionKeyError : function(){
			return encryptionKeyError;
		},
		setEncryptionKeyError : function(str){
			encryptionKeyError = str;
		},
		getClientPreLoginDecryptKey : function(){
			return clientPreLoginDecryptKey;
		},
		setClientPreLoginDecryptKey : function(str){
			clientPreLoginDecryptKey = str;
		},
		getServerPublicKey : function(){
			return serverPublicKey;
		},
		setServerPublicKey : function(str){
			serverPublicKey = str;
		},
		getSymmetricKey : function(){
			return symmetricKey;
		},
		setSymmetricKey : function(str){
			symmetricKey = str;
		},
		setEnabledFeatures : function(str) {
			if(Context.getInstance().getEnabledFeatures() === null) {
				Context.getInstance().setEnabledFeatures(str);
			} else {
				if(console) console.log('ERROR: loading enabled features not loaded !');
			}
		},
		getEnabledFeatures: function () {
			return Context.getInstance().getEnabledFeatures();
		}

	};
	return service;
}