virtualMeetingCongifService.$inject = ['$q', 'ajaxService'];

function virtualMeetingCongifService($q, ajaxService) {
    var service = {
        requestZoomOauthAccessToken: function(authorizationCode, redirectURL){
            var request = ajaxService.requestZoomOauthAccessToken(authorizationCode, redirectURL);
            return request.then(function(response) {
                if (response) {
                    return response.data;
                }
                return null;
            });
        },
        getZoomUserInfo: function(){
            var request = ajaxService.getZoomUserInfo();
            return request.then(function(response) {
                if (response) {
                    return response.data;
                }
                return null;
            });
        },
        disconnectZoom: function(){
            var request = ajaxService.disconnectZoom();
            return request.then(function(response) {
                if (response) {
                    return response.data;
                }
                return null;
            });
        },
        disconnectGraph: function() {
            var request = ajaxService.disconnectGraph();
            return request.then(function(response) {
                if (response) {
                    return response.data;
                }
                return null;
            });
        },
        getMsGraphUserInfo: function() {
            var request = ajaxService.getMsGraphUserInfo();
            return request.then(function(response) {
                if (response) {
                    return response.data;
                }
                return null;
            });
        }
    }
    return service;
}