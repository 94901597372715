function toppanelService(){
    var instances = [];

    var Toppanel = function(object){
        this.object = {}

        if(object)
            this.object = object;
    }

    Toppanel.prototype = {
        constructor: Toppanel,
        getObject: function(){
            return this.object;
        },
        setObject: function(object){
            if(object)
                this.object = object;
        },
        setScope: function(scope){
            if(scope)
                this.object.scope = scope;
        },
        addEvent: function(name, value){
            var event = _.find(this.object.events, { name : name });
            if(event){
                event.name = name;
                event.value = value;
            }
            else{
                this.object.events.push({
                    name: name,
                    value: value
                });
            }

            this.updateEvents();
        },
        updateEvents: function(){
            var object = this.object;

            object.events.forEach(function(e){
                object.scope[e.name] = e.value;
            });
        }
    }

    this.createInstance = function(name){
        if(name == '')
            name = 'DEFAULT';

        var toppanel = new Toppanel();
        var existingInstance = _.find(instances, { name : name });
        if(!existingInstance){
            var instance = {
                name : name,
                toppanel : toppanel
            };
            instances.push(instance);

            return instance.toppanel;
        }
        else{
            return existingInstance.toppanel;
        }

        return null;
    }

    this.getInstance = function(name){
        if(name == '')
            name = 'DEFAULT';

        var instance = _.find(instances, { name : name });
        if(instance && 'toppanel' in instance && instance.toppanel instanceof Toppanel){
            return instance.toppanel;
        }

        return null;
    }
}