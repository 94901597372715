angular
    .module('dialogControllers.service')
	.controller('showSurveyUsersController', showSurveyUsersController);

showSurveyUsersController.$inject =  ['$scope', 'context', 'localizedMessages', 'teamService', 'bvDialog', 'companyUserService'];

function showSurveyUsersController($scope, context, localizedMessages, teamService, bvDialog, companyUserService)
{
	var bvModal;
	bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.surveyObject = $scope.bvModalControllerInputs.surveyObject;
	$scope.users = angular.copy($scope.bvModalControllerInputs.users);
	$scope.currentUserUuid = context.getModel().userVO.objectBaseUuid;
	
	$scope.ADDED_USERS = [];
	$scope.FINAL_ADDED_USERS = [];
	$scope.ui = { label : {
		addUsers				:localizedMessages.get("ADD_USERS"),
		markAsRespondent		:localizedMessages.get("MARK_AS_RESPONDENT"),
		markAsOwner				:localizedMessages.get("MARK_AS_OWNER"),
    	cancel					:localizedMessages.get("CANCEL"),
    	ok						:localizedMessages.get("OK"),
    	
      }
	};
	
	$scope.popupTitle  =   localizedMessages.get('USERS');
	$scope.CURRENT_OPTION = 'UI_ADD_USERS';
	$scope.bvchecktrue 		= 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvcheckfalse 	= 'resources/assets/svgs/bvcheckfalse.svg';
	
	$scope.UI_MSG_EDIT_CANCEL_CHANGES  = localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED');
	$scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM = localizedMessages.get('MSG_EDIT_MEETINGS_CONFIRM_CHANGES_ON_TAB_CHANGE');
	
	$scope.USERS = angular.copy($scope.bvModalControllerInputs.surveyObject.attendeesList);
	$scope.FINAL_USERS = angular.copy($scope.bvModalControllerInputs.surveyObject.attendeesList);
	
	$scope.saveTempChangesOnTabChange = function ()
	{
		var previousOption = $scope.CURRENT_OPTION;
		$scope.CURRENT_OPTION = $scope.TEMP_TAB_VAL;
		$scope.saveTempChanges(previousOption);
	}
	
	$scope.saveTempChanges = function (tabName, saveFromAttendees)
	{
		switch(tabName)
		{
			case 'UI_ADD_USERS' 			: 	$scope.FINAL_ADDED_USERS = angular.copy($scope.ADDED_USERS); 
												$scope.addSelectedUser($scope.FINAL_ADDED_USERS, saveFromAttendees);
												break;
			case 'UI_MARK_AS_RESPONDENT'	: 	$scope.FINAL_USERS = angular.copy($scope.USERS);
												break;
			case 'UI_MARK_AS_OWNER'			:   $scope.FINAL_USERS = angular.copy($scope.USERS);
												break;
		}
	}
	$scope.removeTempChangesOnTabChange = function ()
	{
		var previousTab = $scope.CURRENT_OPTION;
		$scope.CURRENT_OPTION = $scope.TEMP_TAB_VAL;
		switch(previousTab)
		{
			case 'UI_ADD_USERS' 		: 	$scope.ADDED_USERS = angular.copy($scope.FINAL_ADDED_USERS);
											break;
			case 'UI_MARK_AS_RESPONDENT': 	$scope.USERS = angular.copy($scope.FINAL_USERS);
											$scope.markAsRespondentCheckAll = false;
											break;
			case 'UI_MARK_AS_OWNER'		: 	$scope.USERS = angular.copy($scope.FINAL_USERS);
											$scope.markAsOwnerCheckAll = false;
											break;
		}
	}
	$scope.toggleMarkAsOwner = function(item, booleanVal){
    	item.isOwner = booleanVal;
    };
    $scope.toggleMarkAsOwnerCheckAll = function(booleanVal){
    	$scope.markAsOwnerCheckAll = booleanVal;
    	if(booleanVal) {
    		for(var i=0; i<$scope.USERS.length; i++) {
        		$scope.USERS[i].isOwner = true;
        	}
    	} 
    	else {
    		for(var i=0; i<$scope.USERS.length; i++) {
        		$scope.USERS[i].isOwner = false;
        	}
    	}
    }
	$scope.toggleMarkAsRespondent = function(item, booleanVal){
    	item.isRespondent = booleanVal;
    };
    $scope.toggleMarkAsRespondentCheckAll = function(booleanVal){
    	$scope.markAsRespondentCheckAll = booleanVal;
    	if(booleanVal) {
    		for(var i=0; i<$scope.USERS.length; i++) {
        		$scope.USERS[i].isRespondent = true;
        	}
    	} 
    	else {
    		for(var i=0; i<$scope.USERS.length; i++) {
        		$scope.USERS[i].isRespondent = false;
        	}
    	}
    }
	
    $scope.addSelectedUser = function(selectedItems, saveFromAttendees){
		$scope.selectedUsers = selectedItems;
		if($scope.selectedUsers!=undefined && $scope.selectedUsers.length >0 )
		{
			for(var i in $scope.selectedUsers) {

				if($scope.selectedUsers[i].type == 'TeamSpaceGroup'){
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid, saveFromAttendees);
				}
				else if($scope.selectedUsers[i].type == 'Group'){
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid, saveFromAttendees);
				}
				else if($scope.selectedUsers[i].type == 'Portal'){
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid, saveFromAttendees);
				}
				else{
					$scope.addUserInAttendeesList($scope.selectedUsers[i].objectBaseUuid, $scope.selectedUsers[i].name);
				}
			}
		}
	};
	$scope.addGroupUsersInAttendeesList = function(groupId, saveFromAttendees){
		var users = companyUserService.getGroupUsers(groupId);
		if(users && users.length> 0)
		{
			for(var k in users){
				$scope.addUserInAttendeesList(users[k].objectBaseUuid, users[k].name);
			}
		}
		if(saveFromAttendees) {
			saveAttendees();
		}
	};
	
	$scope.addUserInAttendeesList = function(uuid,name){
		//var profileImage = $scope.$parent.userImages[$scope.selectedUsers[i].objectBaseUuid];
		var attendeeObj = {
				objectBaseUuid		:uuid,
				name				:name,
				isRespondent		:true,
				isOwner				:false,
				response			:'not_responded',
				//encodedUserThumbnail:profileImage
			};

		if($scope.checkAttendeeAlreadyExists(attendeeObj.objectBaseUuid) == false)
		{	
			if($scope.FINAL_USERS) {
				$scope.USERS.push(attendeeObj);
				$scope.FINAL_USERS = angular.copy($scope.USERS);
			}
		}
	};
	$scope.checkAttendeeAlreadyExists = function(objectBaseUuid){
		var existsFlag = false;
		if($scope.FINAL_USERS && $scope.FINAL_USERS.length != 0)
		{	
			for(var index in $scope.FINAL_USERS){
				if($scope.FINAL_USERS[index].objectBaseUuid == objectBaseUuid){
					existsFlag = true;
					break;
				}
			}
		}
		else
			existsFlag = false;
		
		return existsFlag;
	};
	
	$scope.setSelectedUsers = function(selectedItems)
	{
		$scope.ADDED_USERS = selectedItems;
	};
	
	$scope.changeUsersOption = function (tabVal)
	{
		var everythingValid = false;
		$scope.TEMP_TAB_VAL = tabVal;
		var showDlg = false;
		switch($scope.CURRENT_OPTION)
		{
			case 'UI_ADD_USERS' 			: everythingValid = $scope.validateChangesForAddUsers(); 
											  break;
			case 'UI_MARK_AS_RESPONDENT' 	: everythingValid = $scope.validateChangesForMarkAsRespondent(); 
											  break;
			case 'UI_MARK_AS_OWNER'		    : everythingValid = $scope.validateChangesForMarkAsOwner();
										      break;
		}
		if(everythingValid)
		{
			bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveTempChangesOnTabChange, $scope.removeTempChangesOnTabChange);
		}
		else
		{
			$scope.CURRENT_OPTION = $scope.TEMP_TAB_VAL;
		}
	}
	$scope.validateChangesForAddUsers = function()
	{
		if(angular.equals($scope.ADDED_USERS, $scope.FINAL_ADDED_USERS)) {
			return false;
		}
		return true;
	}
	$scope.validateChangesForMarkAsRespondent =  function()
	{
		for(var i=0; i<$scope.USERS.length; i++){
			if($scope.USERS[i].isRespondent != $scope.FINAL_USERS[i].isRespondent){
				return true;
			}
		}
		return false;
	}
	$scope.validateChangesForMarkAsOwner =  function()
	{
		for(var i=0; i<$scope.USERS.length; i++){
			if($scope.USERS[i].isOwner != $scope.FINAL_USERS[i].isOwner){
				return true;
			}
		}
		return false;
	}
    $scope.save = function() {
    	if($scope.CURRENT_OPTION == 'UI_ADD_USERS' && $scope.validateChangesForAddUsers()) {
    		$scope.saveTempChanges($scope.CURRENT_OPTION, true);
    		saveAttendees();
    	}
    	else {
    		$scope.saveTempChanges($scope.CURRENT_OPTION);
    		saveAttendees();
    	}
    	
    }
    
    var saveAttendees = function() {
    	var surveysController = angular.element('#newSurveyCreation').scope();
    	surveysController.surveyObject.attendeesList = angular.copy($scope.FINAL_USERS);
    	exitEditApproval();
    }
	
	var exitEditApproval = function() {
		bvModal.close();
	}
	
    $scope.cancelChanges = function()
	{
		var confirmMsg = $scope.UI_MSG_EDIT_CANCEL_CHANGES;
		bvDialog.confirm(confirmMsg, exitEditApproval, null);
	}
    $scope.close = function()
	{
    	$scope.cancelChanges();
	}
}