minutesSettingsController.$inject = ['$scope', 'reactInterface', 'localizedMessages']

function minutesSettingsController($scope, reactInterface, localizedMessages) {
    $scope.svgFill = '#FFF';
    $scope.middleBarObject.showBack = false;
    $scope.middleBarObject.title = localizedMessages.get("MINUTES_SETINGS_TITLE");
    $scope.reactInterface = reactInterface;

    $scope.tabs = [{
        title : localizedMessages.get("MINUTES_SETINGS_TITLE"),
        content : 'minutesSettings',
        active: true,
        rightMenuList:[]
    }];
}