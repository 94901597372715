function DateHelper(user) {
  var timeFormat = user.timeFormat;
  var dateFormat = user.dateFormat;

  this.getFormattedTime = function(startDateTime, timeZone) {
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat);
  
    return f.setDateTime(startDateTime).setTimezone(timeZone, false).formattedTime();
  }

  this.getFormattedDateAndTime = function(startDateTime, timeZone) {
    var formatter = new DateTimeFormatUtility().setDateTime(startDateTime).setTimezone(timeZone, false).setFormat(dateFormat, timeFormat);
    var longDate = formatter.formattedLongDate();
    var time = formatter.formattedTime();
    var formattedTz = moment().tz(timeZone).format('zz');
  
    return longDate + ' ' + time + ' ' + formattedTz;
  }

  this.getCustomFormattedTime = function(startDateTime, timeZone) {
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat);
  
    var time = f.setDateTime(startDateTime).formattedTime();

    var formattedTz = moment().tz(timeZone).format('zz');

    return time + ' ' + formattedTz;
  }

  this.getFormattedTimeWithTz = function(startDateTime, timeZone) {    
    var time = this.getFormattedTime(startDateTime, timeZone);
  
    var formattedTz = moment().tz(timeZone).format('zz');

    return time + ' ' + formattedTz;
  }

  this.getFormattedTimeUTC = function(startDateTime) {
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat);
  
    return f.setUtcDateTime(startDateTime).formattedTime();
  }
  
  this.getFormattedRangeTime = function(startTime, endTime, timeZone) {  
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat).setTimezone(timeZone);
  
    var formattedStartTime = f.setDateTime(parseInt(startTime)).formattedTime();
    var formattedEndTime = f.setDateTime(parseInt(endTime)).formattedTime();
    
    return formattedStartTime+" - "+formattedEndTime;
  };

  this.getFormattedLongDate = function(dateTime) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setFormat(dateFormat, timeFormat);
  
    return formatter.formattedLongDate();
  }

  this.getFormattedLongDateWithDay = function(dateTime, timeZone) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setTimezone(timeZone, false).setFormat(dateFormat, timeFormat);
    var longDate = formatter.formattedLongDate();
    var time = formatter.formattedTime();

    return formatter.dayLong() + ", " + longDate + " " + time;
  }

  this.getForrmattedAbbrDate = function(dateTime) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setFormat(dateFormat, timeFormat);
  
    return formatter.formattedAbbrDate();
  }

  this.getForrmattedShortDate = function(dateTime) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setFormat(dateFormat, timeFormat);
  
    return formatter.formattedShortDate();
  }

  this.getDateFormats = function() {
    var formatter = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat);

    return formatter.dateFormats();
  }

  this.getStartTimeWithOffset = function(itemStartTime, timeZone) {
    var startTimeTz = itemStartTime.split(' ').pop();
    var timePortion = itemStartTime.slice(0, -startTimeTz.length).trim();
    var tzOffset = moment().locale('en-us').tz(timeZone).format('Z');

    return timePortion + ' ' + tzOffset;
  }

  this.getFormattedTimeInMillis = function(itemStartTime, meetingDateTime, timeZone) {
    var startTimeWithOffset = this.getStartTimeWithOffset(itemStartTime, timeZone)
    var meetingDate = meetingDateTime.substr(0, meetingDateTime.indexOf(","));

    var dateTime = meetingDate + ' ' + startTimeWithOffset;
    var targetDateTime = this.getFormattedDateAndTime(dateTime, timeZone);
    var dateTimeFmt = this.getDateFormats().longDate + ' ' + timeFormat + ' ' + "zz";

    var mills = moment(targetDateTime, dateTimeFmt).locale('en-us').valueOf();

		return mills;
  }
}