function bvTooltip(){
	return {
		restrict: 'E',
		scope:{
			tooltipText:'@',
			// Future enhancement - top, bottom, down, left
			// based on placement arrow need to be changed
			//tooltipPlacement : '@'
		},
		template:
				'<div class="bv-tooltip-container bv-very-small-font">' +
					'<div class="bv-tooltip">{{tooltipText}}</div>' +
					'<div class="bv-tooltip-down-arrow"></div>' +
				'</div>'
	};
}
