function ConfPrsntProducerCntrl($scope, $rootScope, $window, $modalInstance, context, conferenceService, imageService, localizedMessages, bvDialogService) {

	$scope.CONF = $rootScope.CONF;
	$scope.DOC_NAME_IN_VIEW 	 = "";
	$scope.OF = localizedMessages.get('OF');
	$scope.PAGE = localizedMessages.get('PAGE');
	$scope.PRESENTER = localizedMessages.get('PRESENTER');
	$scope.STOP_SHARING = localizedMessages.get('STOP_SHARING');
	$scope.ERR_EVENT_DETAILS   = localizedMessages.get('ERR_PRESENTATION_EVENT_DETIALS_NOT_AVAILABLE');
	$scope.ERR_DOCUMENT_ACCESS_DENIED = localizedMessages.get('ERR_DOCUMENT_ACCESS_DENIED');

	//console.log("---IN ConfPresentDocController-- CONF VALUE:---",$scope.CONF);

	$scope.AgendaTreeParameters = {
			dragEnabled: true,
		    emptyPlaceholderEnabled: false,
		    maxDepth: 10,
		    dragDelay: 0,
		    dragDistance: 0,
		    lockX: false,
		    lockY: false,
		    boundTo: '',
		    spacing: 40,
		    coverage: 50,
		    cancelKey: 'esc',
		    copyKey: 'shift',
		    selectKey: 'ctrl',
		    enableExpandOnHover: true,
		    expandOnHover: 500		    
	};

	if($scope.CONF.CONF_CONTAINS_AGENDA)
	{
		//$scope.AGENDA_TREE_ITEMS = JSON.parse($scope.CONF.CONF_EVENT.agendaJson);
		$scope.AGENDA_TREE_ITEMS = $scope.CONF.CONF_EVENT.agendaModel;
		$scope.AGENDA_TAB_ACTIVE = true;
		$scope.ATTACHMENT_TAB_ACTIVE = false;
	}
	else
	{
		$scope.AGENDA_TAB_ACTIVE = false;
		$scope.ATTACHMENT_TAB_ACTIVE = true;
	}


	var tempHeight = (document.querySelector('body').offsetHeight) ;
	var tempWidth  = (document.querySelector('body').offsetWidth); 
	
	$scope.MAIN_WINDOW_WIDTH     = (tempWidth - 20) + "px";
	$scope.MAIN_CONTAINER_WIDTH_INT = tempWidth - 450;
	$scope.MAIN_CONTAINER_WIDTH  = (tempWidth - 20) + "px";

	$scope.MAIN_CONTAINER_WIDTH_CANVAS = (tempWidth - 60) + "px";

	$scope.MAIN_WINDOW_HEIGHT 	 = (tempHeight - 22)  + "px";
	$scope.MAIN_CONTAINER_HEIGHT = (tempHeight - 57)  + "px";
	$scope.MAIN_CONTAINER_HEIGHT_INT = tempHeight - 57;

	$scope.CANVAS_DIV_HEIGHT     = (tempHeight - 85)  + "px";
	$scope.ATCHMT_GRID_HEIGHT    = (tempHeight - 120) + "px";
	$scope.ATCHMT_GRID_WIDTH     =  "340px";
	$scope.IS_CLOSING_SHARING_AFTER_CHANGE_IN_PRESENTER = true;
	

	$scope.initPresenterWindow = function ()
	{
		//console.log("BEFORE:",$scope.CONF.CONF_PARTICIPANTS);
		$scope.CONF = $rootScope.CONF;
		//console.log("AFTER:",$scope.CONF.CONF_PARTICIPANTS);
		hideThumbnailView();

		if($scope.CONF.CONF_IS_CONF_PRESENTER || $scope.CONF.CONF_IS_CONF_HOST)
		{
			$scope.VERTICAL_CONTAINER1_WIDTH = "360px";
			$scope.VERTICAL_CONTAINER2_WIDTH = (tempWidth - 380) + "px";
			$scope.VERTICAL_CONTAINER2_HEIGHT =	(tempHeight - 66)  + "px";
			$scope.VERTICAL_CONTAINER2_CANVAS_WIDTH = (tempWidth - 410) + "px";
			$scope.VERTICAL_CONTAINER1_DISPLAY	= "block";
			$scope.VERTICAL_CONTAINER2_DISPLAY	= "block";
			$scope.IS_CLOSING_SHARING_AFTER_CHANGE_IN_PRESENTER = true;
		}	
		else
		{
			if(!$scope.CONF.CONF_IS_CONF_HOST)
			{
				$scope.CONF.CONF_UI_PRESENT_EXIT_BTN_ACTION_TXT = $scope.CONF.CONF_UI_LEAVE_BTN_TXT;
			}
			$scope.VERTICAL_CONTAINER1_WIDTH = "0px"
			$scope.VERTICAL_CONTAINER2_WIDTH = $scope.MAIN_CONTAINER_WIDTH;
			$scope.VERTICAL_CONTAINER2_CANVAS_WIDTH = $scope.MAIN_CONTAINER_WIDTH_CANVAS;
			$scope.VERTICAL_CONTAINER1_DISPLAY	= "none";
			$scope.VERTICAL_CONTAINER2_DISPLAY	= "block";

			//TODO:
			//IF already preseting some document then make then shown blank document
			$scope.IS_CLOSING_SHARING_AFTER_CHANGE_IN_PRESENTER = false;
			$scope.stopSharing();
		}
	}

	$scope.showAttendeeTab = function ()
	{
		return ($scope.CONF.CONF_IS_CONF_HOST || $scope.CONF.CONF_IS_CONF_PRESENTER) ? true : false;
	}

	$scope.changePageFromUi = function(event)
	{
		if(event.keyCode==13)
		{	
			var newVal = $scope.CONF.TXT_CURRENT_DOC_PAGE_NUM;
			if(!isNaN(newVal))
			{
				var pageNum = parseInt(newVal);
				if( ($scope.CURRENT_DOC_PAGE_NUM!=pageNum) && (pageNum<=$scope.CONF_PDF_TOTAL_PAGES))
				{
					$scope.gotoPage(pageNum);
				}
				else
				{
					$scope.CONF.TXT_CURRENT_DOC_PAGE_NUM = $scope.CURRENT_DOC_PAGE_NUM;
				}
			}
			else
			{
				$scope.CONF.TXT_CURRENT_DOC_PAGE_NUM = $scope.CURRENT_DOC_PAGE_NUM;
			}
		}
	}

	$scope.isDocInView = function() 
	{
		return ($scope.DOC_NAME_IN_VIEW !="") ? true : false;
	}

	$scope.handlePresentationExitAction = function (presentationExitAction)
 	{
 		switch(presentationExitAction) 
	    {
    		case 'Leave Presentation' : $scope.leavePresentation(); break;
    		case 'Stop Presentation'  : $scope.stopPresentation();  break;
 	    }
 	}

	var CONF_PDF_CANVAS = document.getElementById('bv-conf-pdf-canvas');
	$scope.CURRENT_DOC_PAGE_NUM = 1;
	$scope.CONF.TXT_CURRENT_DOC_PAGE_NUM = $scope.CURRENT_DOC_PAGE_NUM;
	$scope.CONF_PDF_ODC_OBJ = null;
	$scope.CONF_PDF_SCALE = 1.5;
	$scope.CURRENT_DOC_IN_VIEW = "";
	$scope.CONF.CONF_CURRENT_DOC_PDF_URL = "";
	
 	$scope.changePresenter = function(guest)
	{
		var confId = $scope.CONF.CONF_EVENT.objectBaseUuid;
		var userId = guest.objectBaseUuid;
		//console.log("ConfID:",confId);
		//console.log("GuestID:",userId);
		if(userId!=undefined && userId!=null && userId!="" && confId!=undefined && confId!=null && confId!="")
 		{	
 			$scope.CONF.CONF_IS_CONF_PRESENTER = false;
	 		$scope.IS_CLOSING_SHARING_AFTER_CHANGE_IN_PRESENTER = false;
	 		$scope.stopSharing();
	 		conferenceService.grantshareDesktop(userId, confId).then(function(data) 
	 		{
	 			$scope.CONF.CONF_CURRENT_PRESENTER = false;
	 			var confGuests = angular.toJson($scope.CONF.CONF_PARTICIPANTS);
	 			userId = context.getModel().userVO.objectBaseUuid;
				if(confId!=null && userId!=null && confGuests!=null)
		 		{	
		 			conferenceService.synchronizeConferenceJSON(userId, confId, confGuests).then(function(data) 
			 		{
			 			$scope.CONF.CONF_PARTICIPANTS = data.data.rollcall;
			 			$scope.CONF.CONF_SYNC_KEY_VAL = data.data.synckeyval;

			 			if($scope.CONF.CONF_SYNC_KEY_VAL!=undefined && $scope.CONF.CONF_SYNC_KEY_VAL!=null && $scope.CONF.CONF_SYNC_KEY_VAL.length > 0)
			 			for(var i =0;i< $scope.CONF.CONF_SYNC_KEY_VAL.length;i++)
			 			{
			 				if($scope.CONF.CONF_SYNC_KEY_VAL[i].key=="GRANTDESKTOP")
			 				{
			 					$scope.CONF.CONF_CURRENT_PRESENTER = $scope.CONF.CONF_SYNC_KEY_VAL[i].value;
			 					if(userId==$scope.CONF.CONF_CURRENT_PRESENTER)
			 					{
			 					 	$scope.CONF.CONF_IS_CONF_PRESENTER = true;
			 					}
			 					console.log("Current Presenter:"+$scope.CONF.CONF_SYNC_KEY_VAL[i].presenter);
			 				}	
			 			}	
			 			$rootScope.CONF = $scope.CONF;
			 			//console.log("CONF",$scope.CONF);
			 			//console.log("AFTER SYNCHRONZIE CONFERENCE-- ROLLCALL: ",$scope.CONF.CONF_PARTICIPANTS);
			 			//console.log("AFTER SYNCHRONZIE CONFERENCE-- SYNCKEYVAL: ",$scope.CONF.CONF_SYNC_KEY_VAL);
			 		});
			 	}		
	 		});
	 	}
	 	else
	 	{
	 		//Following has to chagne with dialog and localization
	 		bvDialogService.showMcAlertMessage(""+$scope.ERR_EVENT_DETAILS);
	 	}	
	}

	$scope.stopPresentation = function () 
 	{
 		var userId = context.getModel().userVO.objectBaseUuid;
 		var confId = $scope.CONF.CONF_EVENT.objectBaseUuid;
 		if(userId!=undefined && userId!=null && userId!="" && confId!=undefined && confId!=null && confId!="")
 		{	
	 		conferenceService.leftConference(confId, userId).then(function(data) 
	 		{
	 			if($scope.CONF.CONF_IS_CONF_IN_PROGRESS)
		  		{
		  			$scope.CONF.CONF_IS_CONF_IN_PROGRESS = false;
		  		}

		  		$modalInstance.dismiss('cancel')
	 		});
	 	}
	 	else
	 	{
	 		//Following has to chagne with dialog and localization
	 		bvDialogService.showMcAlertMessage(""+$scope.ERR_EVENT_DETAILS);
	 	}	
 	}

 	$scope.stopSharing = function ()
 	{
 		hideThumbnailView();

 		if($scope.IS_CLOSING_SHARING_AFTER_CHANGE_IN_PRESENTER)
 		{
 			if($scope.CONF.CONF_IS_CONF_PRESENTER!=null && $scope.CONF.CONF_IS_CONF_PRESENTER!=undefined && !$scope.CONF.CONF_IS_CONF_PRESENTER) 
 			{
 				return;
 			}
 		}
 		var currentDocInView  = $scope.CURRENT_DOC_IN_VIEW;
 		
 		if(currentDocInView==undefined || currentDocInView=="" || currentDocInView=="BV_BLANK_DOC")
 		{
 			return;
 		}
 		var currentConfID = $scope.CONF.CONF_EVENT.objectBaseUuid ;
 		
 		var syncCommVO = { "name":"SHARE_DOCUMENT", "objectBaseUuid":currentConfID, "documentBaseUuid":currentDocInView, "value":"STOP_SHARING"};
 		conferenceService.shareDocument(angular.toJson(syncCommVO)).then(function(data)
		{
			$scope.CONF.CONF_IS_DOC_ACCESSIBL=false;
			showBlankDocument();	
		});
 	}

 	var showBlankDocument = function ()
 	{
 		if(CONF_PDF_CANVAS==null || CONF_PDF_CANVAS==undefined)
 		{
			CONF_PDF_CANVAS = document.getElementById('bv-conf-pdf-canvas');
		}
		var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/html/resources/lib/Bv_Blank.pdf";
		$scope.CONF.CONF_CURRENT_DOC_PDF_URL = pdfFullUrl;
		$scope.CURRENT_DOC_PAGE_NUM = 1;
		$scope.CONF_PDF_TOTAL_PAGES = 0;
		$scope.CONF_PDF_ODC_OBJ = null;
		$scope.CONF_PDF_SCALE = 1.5;
		$scope.IS_CLOSING_SHARING_AFTER_CHANGE_IN_PRESENTER = true;
		PDFJS.workerSrc = 'component/pdfviewer/lib/pdf.worker.js';
		PDFJS.disableWorker = true;
		PDFJS.getDocument(pdfFullUrl, null, null, $scope.documentProgress).then(function(_pdfDoc) 
		{
			$scope.CONF_PDF_ODC_OBJ = _pdfDoc;
			$scope.renderPage($scope.CURRENT_DOC_PAGE_NUM, function(success) {
				$scope.CURRENT_DOC_IN_VIEW = "BV_BLANK_DOC";
				$scope.CONF_PDF_TOTAL_PAGES = 0;	
				$scope.DOC_NAME_IN_VIEW = "";
			});
		}, function(message, exception){
			console.log("PDF load error: " + message);
			if ($scope.loadProgress) {
				$scope.loadProgress({state: "error", loaded: 0, total: 0});
			}
		});
 	}

 	var hideThumbnailView = function ()
 	{
 		$scope.MAIN_CONTAINER_HEIGHT_ATTACHMENT = ($scope.MAIN_CONTAINER_HEIGHT_INT - 30 ) + "px";
		$scope.MAIN_CONTAINER_HEIGHT_THUMBNAIL = "0px;";
 	}

 	var showThumbnailView = function ()
 	{
 		var tempHeight = ($scope.MAIN_CONTAINER_HEIGHT_INT - 30)/2;
 		$scope.MAIN_CONTAINER_HEIGHT_ATTACHMENT = tempHeight + "px";
		$scope.MAIN_CONTAINER_HEIGHT_THUMBNAIL =  tempHeight + "px";
		$scope.MAIN_CONTAINER_HEIGHT_THUMBNAIL_DIV = tempHeight - 30 + "px";
		$scope.THUMBNAIL_CANVAS_WIDTH = "300px";
		$scope.THUMBNAIL_CANVAS_HEIGHT = tempHeight - 45 + "px"	
		//console.log("In HERE",$scope.MAIN_CONTAINER_WIDTH_INT, $scope.MAIN_CONTAINER_HEIGHT_ATTACHMENT);
 	}

	$scope.presentDoc = function (doc)
 	{
 		if($scope.CONF.CONF_IS_CONF_PRESENTER!=null && $scope.CONF.CONF_IS_CONF_PRESENTER!=undefined && !$scope.CONF.CONF_IS_CONF_PRESENTER)
 		{
 			return;
 		}
 		else
 		{
 			var currentConfID = $scope.CONF.CONF_EVENT.objectBaseUuid ;
 			var currentDocInView  = $scope.CURRENT_DOC_IN_VIEW;
 			
 			//var newDocInView  = doc.objectVersionUuid;
 			var newDocInView  = doc.objectBaseUuid;

 			if(CONF_PDF_CANVAS==null || CONF_PDF_CANVAS==undefined)
 			{
	 			CONF_PDF_CANVAS = document.getElementById('bv-conf-pdf-canvas');
				//console.log("CANVAS",CONF_PDF_CANVAS);
 			}

 			if(currentDocInView==newDocInView)
 			{
 				return;
 			}
 			else
 			{
 				var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/services/html?servicename=BvJsonDocumentService&methodname=getPageAsPdf&versionUuid=" + doc.objectVersionUuid + "&pageNum=-1";
		    	
				$scope.CONF.CONF_CURRENT_DOC_PDF_URL = pdfFullUrl;

		    	//console.log('loadPDF ', path);
				$scope.CURRENT_DOC_PAGE_NUM = 1;
				$scope.CONF.TXT_CURRENT_DOC_PAGE_NUM = 1;
				$scope.CONF_PDF_TOTAL_PAGES = 0;
				$scope.CONF_PDF_ODC_OBJ = null;
				$scope.CONF_PDF_SCALE = 1.5;
				PDFJS.workerSrc = 'component/pdfviewer/lib/pdf.worker.js';
				PDFJS.disableWorker = true;
				PDFJS.getDocument(pdfFullUrl, null, null, $scope.documentProgress).then(function(_pdfDoc) 
				{
					$scope.CONF_PDF_ODC_OBJ = _pdfDoc;
					$scope.renderPage($scope.CURRENT_DOC_PAGE_NUM, function(success) 
					{
						if ($scope.loadProgress) { $scope.loadProgress({state: "finished", loaded: 0, total: 0}); }
						$scope.DOC_NAME_IN_VIEW = doc.name;
						//console.log("LOAD PROGRESS:",$scope.loadProgress);
						$scope.CURRENT_DOC_IN_VIEW = newDocInView;
						if(currentDocInView=="")
						{
							shareDocument(currentConfID,newDocInView,1);
						}
						else
						{
							if(currentDocInView!="BV_BLANK_DOC")
							{	
								//TODO: Current giving two calls. later it can combine into one
								var syncCommVO = { "name":"SHARE_DOCUMENT", "objectBaseUuid":currentConfID, "documentBaseUuid":currentDocInView, "value":"STOP_SHARING"};
								conferenceService.shareDocument(angular.toJson(syncCommVO)).then(function(data)
					        	{
					        		shareDocument(currentConfID, newDocInView, 1);	
			       				});
							}
							else
							{
								shareDocument(currentConfID,newDocInView,1);
							}
						}
					});
				}, function(message, exception) {
					console.log("PDF load error: " + message);
					if ($scope.loadProgress) {
						$scope.loadProgress({state: "error", loaded: 0, total: 0});
					}
				});
			}
		}
 	}

 	var shareDocument = function (confobjectBaseUuid, docBaseUuid, docPageNumber)
 	{
 		var syncCommVO = { "name":"SHARE_DOCUMENT", "objectBaseUuid":confobjectBaseUuid, "documentBaseUuid":docBaseUuid, "pageNumber":docPageNumber,  "value":"START_SHARING"};
 		conferenceService.shareDocument(angular.toJson(syncCommVO)); 
 	}

 	//PDF SPECIFIC

 	////////////////////NON-PRESENTER-SPECIFIC////////////////////////////////////

 	$scope.CONF.CONF_IS_DOC_ACCESSIBLE = true;

 	$scope.loadPDFWithPageNumber = function(path,pageNum)
	{
		console.log('loadPDFWithPageNumber ', path);
		$scope.CURRENT_DOC_PAGE_NUM = pageNum;
		$scope.CONF_PDF_TOTAL_PAGES = 0;
		$scope.CONF_PDF_ODC_OBJ = null;
		$scope.CONF_PDF_SCALE = 1.5;
		if(CONF_PDF_CANVAS==null || CONF_PDF_CANVAS==undefined)
		{
			CONF_PDF_CANVAS = document.getElementById('bv-conf-pdf-canvas');
		}
		PDFJS.workerSrc = 'component/pdfviewer/lib/pdf.worker.js';
		PDFJS.disableWorker = true;
		PDFJS.getDocument(path, null, null, $scope.documentProgress).then(function(_pdfDoc) 
		{
			$scope.CONF_PDF_ODC_OBJ = _pdfDoc;
			$scope.renderPage($scope.CURRENT_DOC_PAGE_NUM, function(success) {
				if ($scope.loadProgress) {
					$scope.loadProgress({state: "finished", loaded: 0, total: 0});
				}
			});
		}, function(message, exception)
		{
			console.log("PDF load error: " + message);
			if ($scope.loadProgress) {
				$scope.loadProgress({state: "error", loaded: 0, total: 0});
			}
		});
	};

	$scope.handleStartDocumentSharingFromPolling = function (isStartSharing)
	{
		if(!isStartSharing)
		{
			showBlankDocument();
		}
		else
		{
			//getSynchronizedCommunicationVO
			var confID = $scope.CONF.CONF_EVENT.objectBaseUuid;
			//console.log("IN handleStartDocumentSharingFromPolling WITH CONFI ID:",confID);
			if(confID!=undefined)
			{	
				conferenceService.getSynchronizedCommunicationVOJSON(confID).then(function(data) 
		 		{
		 			$scope.CURRENT_CONF_SYNC_VO = data.data;
		 			//console.log("CURRENT SYNC VO",$scope.CURRENT_CONF_SYNC_VO);
		 			if($scope.CURRENT_CONF_SYNC_VO!=null && $scope.CURRENT_CONF_SYNC_VO!=undefined)
		 			{
		 				var docId = $scope.CURRENT_CONF_SYNC_VO.documentBaseUuid;
		 				var pageNum = $scope.CURRENT_CONF_SYNC_VO.pageNumber;
		 				if(($scope.CONF.CONF_CURRENT_DOC_PDF_URL == "" || $scope.CONF.CONF_CURRENT_DOC_PDF_URL!=pdfFullUrl))
		 				// if(($scope.CONF.CONF_CURRENT_DOC_PDF_URL == "" || $scope.CONF.CONF_CURRENT_DOC_PDF_URL!=pdfFullUrl) 
			    // 			&& $scope.CONF.CONF_ATTACHMENTS!=null && $scope.CONF.CONF_ATTACHMENTS!=undefined && $scope.CONF.CONF_ATTACHMENTS.length > 0 )
		 				// 
		 				{
		 					$scope.CONF.CONF_IS_DOC_ACCESSIBLE = false;
		 					$scope.DOC_NAME_IN_VIEW = "";
		 					for(var i=0;i< $scope.CONF.CONF_ATTACHMENTS.length;i++)
		 					{
		 						var tempDocId = $scope.CONF.CONF_ATTACHMENTS[i].objectBaseUuid;
		 						//console.log("COMPARE TEMP DOCID:",tempDocId);
		 						//console.log("COMPARE  DOCID:",docId);
		 						if(tempDocId==docId)
		 						{
		 							$scope.DOC_NAME_IN_VIEW =  ""+$scope.CONF.CONF_ATTACHMENTS[i].name;
		 							$scope.CONF.CONF_IS_DOC_ACCESSIBLE = true;
		 							isDocAccessible = true;
		 							break;
		 						}

		 					}
		 					if($scope.CONF.CONF_IS_DOC_ACCESSIBLE)
		 					{	
		 						var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/services/html?servicename=BvJsonDocumentService&methodname=getPageAsPdf&versionUuid=" + docId + "&pageNum=-1";
			    				//console.log("PDF FULL URL: ",pdfFullUrl);
			    				$scope.CONF.CONF_CURRENT_DOC_PDF_URL = pdfFullUrl;
		 						//console.log("CONF.CONF_CURRENT_DOC_PDF_URL:",$scope.CONF.CONF_CURRENT_DOC_PDF_URL);
		 						$scope.loadPDFWithPageNumber(pdfFullUrl,pageNum);
		 					}
		 					else
		 					{
		 						$scope.CONF.CONF_IS_DOC_ACCESSIBLE = false;
		 						// console.log("!!!!!!!!!NOT ACCESSIBLE");
		 						// var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/html/resources/lib/Bv_Blank.pdf";
			    				// $scope.CONF.CONF_CURRENT_DOC_PDF_URL = pdfFullUrl;
		 						// $scope.loadPDFWithPageNumber(pdfFullUrl,1);
		 						showBlankDocument();
		 					}
		 				}
		 				else
		 				{
		 					console.log("Current Doc In View: ",$scope.CONF.CONF_CURRENT_DOC_PDF_URL);
		 					$scope.CONF.CONF_IS_DOC_ACCESSIBLE = true;
		 					$scope.gotoPage(pageNum);	
		 				}
		 			}
		 		});
		 	}
		 }
	}

	$scope.leavePresentation = function () 
	{
		var userId = context.getModel().userVO.objectBaseUuid;
 		var confId = $scope.CONF.CONF_EVENT.objectBaseUuid;
 		//alert("ConfId:"+confId+" UserId:"+userId);
 		if(userId!=undefined && userId!=null && userId!="" && confId!=undefined && confId!=null && confId!="")
 		{	
	 		conferenceService.leftConference(confId, userId).then(function(data) 
	 		{
	 			//$rootScope.IS_UI_SHOWING_PRESENTATION = false;
				$modalInstance.dismiss('cancel');
				if($rootScope.CURR_PRESENT_DLG!= undefined)
				{
					$rootScope.CURR_PRESENT_DLG = undefined;
				}
	 		});
	 	}
	 	else
	 	{
	 		//Following has to chagne with dialog and localization
	 		bvDialogService.showMcAlertMessage(""+$scope.ERR_EVENT_DETAILS);
	 	}	
	}

	$scope.closeOnGoingPresentationForParticipants = function() 
  	{
  		$scope.leavePresentation();
  	}

 	//////////////////END-OF-NON-PRESENTER-SPECIFIC///////////////////////////////

	$scope.documentProgress = function(progressData) 
	{
		if ($scope.loadProgress) {
			$scope.loadProgress({state: "loading", loaded: progressData.loaded, total: progressData.total});
		}
	};

	var renderTask;
	$scope.renderPage = function(num, callback) 
	{
		//console.log('renderPage ', num);
		$scope.CONF_PDF_ODC_OBJ.getPage(num).then(function(page) 
		{
			if(renderTask)
			{
				renderTask.cancel();
			}			
			var viewport = page.getViewport(CONF_PDF_CANVAS.width/page.getViewport(1.0).width);
			$scope.CURRENT_VIEWPORT = viewport;
			var ctx = CONF_PDF_CANVAS.getContext('2d');
			CONF_PDF_CANVAS.height = viewport.height;
			CONF_PDF_CANVAS.width = viewport.width;
			var renderContext = { canvasContext: ctx, viewport: viewport, continueCallback: function(cont) { cont(); }  };
			renderTask = page.render(renderContext);
			$scope.CONF_PDF_TOTAL_PAGES = $scope.CONF_PDF_ODC_OBJ.numPages;
			$scope.CONF.TXT_CURRENT_DOC_PAGE_NUM = $scope.CURRENT_DOC_PAGE_NUM;
			
			if($scope.CONF_PDF_ODC_OBJ.numPages!=null && $scope.CONF_PDF_ODC_OBJ.numPages>1 && ($scope.CONF_PDF_ODC_OBJ.numPages!=num))
			{
				showThumbnailView();
				//thumbnailView.setImage(pageView.canvas);
				//PageView.Canvas
				//PageView(container, pageNum, scale,
                  //                  self.navigateTo.bind(self),
                    //                viewportClone);
				//var promise = PDFView.getPage($scope.CONF.TXT_CURRENT_DOC_PAGE_NUM+1);
				console.log("IS RENDER TASK:",renderTask);
				var thumbnailRenderTask;
				var getNextPagePromise = $scope.CONF_PDF_ODC_OBJ.getPage(num+1);
      			getNextPagePromise.then(function(thumbnailPage) {
      				//var thumbNailViewport = viewport.clone();
      				//thumbNailViewport = pdfPage.getViewport();
			    	//this.setPdfPage(pdfPage);
			        //this.draw(callback);
			        if(thumbnailRenderTask)
					{
						thumbnailRenderTask.cancel();
					}
			        var CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS = document.getElementById('bv-conf-pdf-nextpage-thumbnail-canvas');
					var thumbNailCtxViewport = thumbnailPage.getViewport(CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS.width/thumbnailPage.getViewport(1.0).width);
					//var thumbNailCtxViewport = thumbnailPage.getViewport(CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS.height/thumbnailPage.getViewport(1.0).height);
					var thumbNailCtx = CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS.getContext('2d');
					//CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS.height = thumbNailCtxViewport.height;
					//CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS.width = thumbNailCtxViewport.width;
					thumbNailCtxViewport.height = CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS.height;
					thumbNailCtxViewport.width = CONF_PDF_NEXTPAGE_THUMBNAIL_CANVAS.width;
					
					var thumbNailRenderContext = { canvasContext: thumbNailCtx, viewport: thumbNailCtxViewport, continueCallback: function(cont) { cont(); } };
					//var thumbNailRenderContext = { canvasContext: thumbNailCtx, viewport: thumbNailCtxViewport };
					thumbnailRenderTask = thumbnailPage.render(thumbNailRenderContext);
					thumbnailRenderTask.promise.then( function (){});
				    //var img = $scope.CURRENT_VIEWPORT;
			        //thumbNailCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, thumbNailCtx.canvas.width, thumbNailCtx.canvas.height);
			    });

				//var img = PDFView.getPage($scope.CONF.TXT_CURRENT_DOC_PAGE_NUM+1);
				//this.viewport = pdfPage.getViewport(this.scale * CSS_UNITS, totalRotation);
			}
			else
			{
				hideThumbnailView();
			}
			if (callback) { callback(true);}

			/*page.render({ canvasContext: ctx, viewport: viewport }).promise.then(function()
				{
					$scope.CONF_PDF_TOTAL_PAGES = $scope.CONF_PDF_ODC_OBJ.numPages;
					$scope.CONF.TXT_CURRENT_DOC_PAGE_NUM = $scope.CURRENT_DOC_PAGE_NUM;
					if (callback) { callback(true);}
					$scope.$apply(function() 
					{
						//$scope.onPageLoad({ page: $scope.CURRENT_DOC_PAGE_NUM, total: $scope.CONF_PDF_ODC_OBJ.numPages });
					});
				}, function() {
					if (callback) { callback(false); }
					console.log('page.render failed');
				}
			);*/
		});
	};

	$scope.nextPage = function() 
	{
		if ($scope.CURRENT_DOC_PAGE_NUM < $scope.CONF_PDF_ODC_OBJ.numPages) 
		{
			$scope.CURRENT_DOC_PAGE_NUM++;
			$scope.renderPage($scope.CURRENT_DOC_PAGE_NUM, function(success) 
			{
				if($scope.CONF.CONF_IS_CONF_PRESENTER!=null && $scope.CONF.CONF_IS_CONF_PRESENTER!=undefined && $scope.CONF.CONF_IS_CONF_PRESENTER)
 				{
					var currentConfID = $scope.CONF.CONF_EVENT.objectBaseUuid ;
 					var currentDocInView  = $scope.CURRENT_DOC_IN_VIEW;
 					var pageNo  = $scope.CURRENT_DOC_PAGE_NUM;
					shareDocument(currentConfID, currentDocInView, pageNo);	
				}
			});
		}
	};
	
	$scope.prevPage = function() 
	{
		if ($scope.CURRENT_DOC_PAGE_NUM > 1) 
		{
			$scope.CURRENT_DOC_PAGE_NUM--;
			$scope.renderPage($scope.CURRENT_DOC_PAGE_NUM, function(success) 
			{
				if($scope.CONF.CONF_IS_CONF_PRESENTER!=null && $scope.CONF.CONF_IS_CONF_PRESENTER!=undefined && $scope.CONF.CONF_IS_CONF_PRESENTER)
 				{
 					var currentConfID = $scope.CONF.CONF_EVENT.objectBaseUuid ;
 					var currentDocInView  = $scope.CURRENT_DOC_IN_VIEW;
 					var pageNo  = $scope.CURRENT_DOC_PAGE_NUM;
					shareDocument(currentConfID, currentDocInView, pageNo);	
				}
			});
		}
	};
	
	$scope.gotoPage = function(page) 
	{
		if (page >= 1 && page <= $scope.CONF_PDF_ODC_OBJ.numPages) 
		{
			$scope.CURRENT_DOC_PAGE_NUM = page;
			$scope.renderPage($scope.CURRENT_DOC_PAGE_NUM, function(success) 
			{
				if($scope.CONF.CONF_IS_CONF_PRESENTER!=null && $scope.CONF.CONF_IS_CONF_PRESENTER!=undefined && $scope.CONF.CONF_IS_CONF_PRESENTER)
 				{
 					var currentConfID = $scope.CONF.CONF_EVENT.objectBaseUuid ;
 					var currentDocInView  = $scope.CURRENT_DOC_IN_VIEW;
 					var pageNo  = $scope.CURRENT_DOC_PAGE_NUM;
					shareDocument(currentConfID, currentDocInView, pageNo);	
				}
			});
		}
	};


	$scope.initPresenterWindow();

}

angular
	.module('confprsntproducer.module',['localization.service','conference.service'])
	.controller('ConfPrsntProducerCntrl', ['$scope', '$rootScope', '$window', '$modalInstance', 'context', 'conferenceService',  'imageService', 'localizedMessages', 'bvDialogService', ConfPrsntProducerCntrl ]);
