function bvFocusTouchFix(){
	return {
		restrict: 'A',
		link: function($scope, $el, $attr){
			if(MobileUtil.isMobileClient()){
				$el.bind('touchstart', function(){
					setTimeout(function(){
						$el[0].focus();
					},50);
				})
				$el.bind('click', function(){
					setTimeout(function(){
						$el[0].focus();
					},50);
				})
			}
		}
	}
}