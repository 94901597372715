angular
    .module('dialogControllers.service')
    .controller('showMeetingSettingsController', showMeetingSettingsController);

showMeetingSettingsController.$inject =  ['$scope', 'context', 'localizedMessages', 'calendarService', 'commonService', 'bvDialog', 'meetingsService', '$timeout', '$rootScope', '$state'];

function showMeetingSettingsController($scope, context, localizedMessages, calendarService, commonService, bvDialog, meetingsService, $timeout, $rootScope, $state)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;

	var saveBtnEnableDisable = function(){
	    if($scope.validateChangesForTime() || $scope.validateChangesForActiveInActive() || $scope.validateChangesForAddAttendees()
            || $scope.validateChangesForRemoveAttendees() || $scope.validateChangesForCopyMeetings()){
            $scope.saveConfChangesBtnDisable = false;
        }
        else{
            $scope.saveConfChangesBtnDisable = true;
        }
	}

    var smartSelectionUtils = {}
	var initialComplete = function(smartSelection){
	    smartSelectionUtils = smartSelection.smartSelectionUtils;
	}

	$scope.smartSelectionOptions = {
        initialComplete: initialComplete,
        checkboxDisableFeature: false,
        disableEmptyNodes: true,
//        settings: {
//            disableUpDownSelection : true
//        }
    }

    var getUsersForSelectedEvents = function(){
        $timeout(function(){
            _.forEach($scope.EVENTS_FOR_REMOVE_ATTND, function(event){
                if(event.selected){
                    $scope.removeOwnerAndGetOtherUsers(event);
                }
            });
        });
    }

    $scope.contentBtn = function(){
        $scope.copyMeetings.selection.attachments = !($scope.copyMeetings.selection.attachments && $scope.copyMeetings.selection.details && $scope.copyMeetings.selection.users);
        $scope.copyMeetings.selection.details = !!$scope.copyMeetings.selection.attachments;
        $scope.copyMeetings.selection.users = !!$scope.copyMeetings.selection.attachments;
    }

	$scope.init = function() 
	{
		$scope.ADDED_ATTENDEES = [];
		$scope.FINAL_ADDED_ATTENDEES = [];
		$scope.REMOVED_ATTENDEES = [];
		$scope.MEETING_USERS = {};
		$scope.MEETING_USERS.ITEMS = [];
		$scope.copyMeetings = {}
		$scope.saveConfChangesBtnDisable = true;
		$scope.showRemovingUsers = true;
		
		$scope.isUserAllowedToCreateMeeting = calendarService.isCurrentUserAllowedToCreateModule();

		var EVENTS = $scope.bvModalControllerInputs.events;

		for (var i = 0; i < EVENTS.length; i++) 
		{
		    EVENTS[i].startDateGMTMillis = parseInt(EVENTS[i].startDateGMTMillis);
			EVENTS[i].endDateGMTMillis = parseInt(EVENTS[i].endDateGMTMillis);
		}

		// var tempUsers = $scope.bvModalControllerInputs.users
		// for (var i = 0; i < tempUsers.length; i++) 
		// {
		//     tempUsers[i].selected = false;
		// }

		// $scope.INITIAL_USERS = tempUsers;
		$scope.INITIAL_EVENTS = angular.copy(EVENTS);
		$scope.EVENTS_LIST_DATE = $scope.bvModalControllerInputs.calendarDate;

		$scope.showRemoveInstead = true;
		//$scope.USERS = angular.copy($scope.INITIAL_USERS);
		//$scope.FINAL_USERS = angular.copy($scope.INITIAL_USERS);

		$scope.USERS = [];
		$scope.FINAL_USERS = [];

		$scope.EVENTS_FOR_TIME = angular.copy(EVENTS);
		$scope.EVENTS_FOR_ADD_ATTND = angular.copy(EVENTS);
		$scope.EVENTS_FOR_REMOVE_ATTND = angular.copy(EVENTS);		
		$scope.EVENTS_FOR_COPY_MEETINGS = angular.copy(EVENTS);
		$scope.COPY_MEETINGS_CALENDAR_DATE = $scope.bvModalControllerInputs.calendarDate;

		$scope.EVENTS_FOR_COPY_MEETINGS.forEach(function(event){ event.selected = false; })

		$scope.FINAL_EVENT_FOR_ADD_ATTENDEE = angular.copy(EVENTS);
		$scope.FINAL_EVENT_FOR_REMOVE_ATTENDEE = angular.copy(EVENTS);
		$scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE = angular.copy(EVENTS);
        $scope.FINAL_EVENTS_FOR_COPY_MEETINGS = angular.copy($scope.EVENTS_FOR_COPY_MEETINGS);

		$scope.CURRENT_TAB = $scope.bvModalControllerInputs.currentTab || 'adjustTime';
		
		$scope.popupTitle  =   localizedMessages.get('MANAGE_MEETINGS');
		$scope.UI_SAVE    = localizedMessages.get('SAVE');
		$scope.UI_CANCEL    = localizedMessages.get('CANCEL');
		$scope.UI_ADJUST_TIME_TXT  =   localizedMessages.get('ADJUST_TIME');
		$scope.UI_ADD_ATTENDEES_TXT  =   localizedMessages.get('ADD_ATTENDEES');
		$scope.UI_ACTIVE_INACTIVE  =   localizedMessages.get('PUBLISH_UNPUBLISH');
		$scope.UI_REMOVE_ATTENDEES_TXT  =   localizedMessages.get('REMOVE_ATTENDEES');
		$scope.UI_COPY_MEETINGS_TXT  =   localizedMessages.get('COPY_MEETINGS');
		$scope.UI_ACTIVE  =   localizedMessages.get('PUBLISH');
		$scope.UI_IN_ACTIVE  =   localizedMessages.get('UNPUBLISH');
		$scope.UI_REMOVE_ATTENDEES_TXT  =   localizedMessages.get('REMOVE_ATTENDEES');
		$scope.UI_ERR_EDIT_MEETINGS_MEETING_NOT_SELECTED = localizedMessages.get('ERR_EDIT_MEETINGS_MEETING_NOT_SELECTED');
		$scope.UI_MSG_EDIT_MEETINGS_CHANGES_NO_CHANGES = localizedMessages.get('MSG_EDIT_MEETINGS_CHANGES_NO_CHANGES');
		$scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM = localizedMessages.get('MSG_EDIT_MEETINGS_CONFIRM_CHANGES_ON_TAB_CHANGE');
		$scope.UI_MSG_EDIT_MEETINGS_CANCEL_CHANGES  = localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED');
		$scope.UI_MEETINGS  = localizedMessages.get('MEETINGS');

		$scope.copyMeetings.titles = {
		    date        : localizedMessages.get('DATE'),
		    attachments : localizedMessages.get('ATTACHMENTS'),
		    details     : localizedMessages.get('DETAILS'),
		    users       : localizedMessages.get('USERS'),
		    content     : localizedMessages.get('CONTENT')
		}

        $scope.copyMeetings.selection = {
            attachments : true,
            details     : true,
            users       : true
        }

        $scope.copyMeetings.datepicker = {
            customClass: 'copy-meetings-datepicker'
        }

		$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
		$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
		$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
		$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';

		var TIME_SLOTS = [
	              			{"label":"-3 " + localizedMessages.get("HOUR_SHORT"), "value":-180, selected: false},
	              			{"label":"-2 " + localizedMessages.get("HOUR_SHORT"), "value":-120, selected: false},
	              			{"label":"-60 " + localizedMessages.get("MINUTE_SHORT"), "value":-60,  selected: false},
	              			{"label":"-45 " + localizedMessages.get("MINUTE_SHORT"), "value":-45,  selected: false},
	              			{"label":"-30 " + localizedMessages.get("MINUTE_SHORT"), "value":-30,  selected: false},
	              			{"label":"-15 " + localizedMessages.get("MINUTE_SHORT"), "value":-15,  selected: false},
	              			{"label":"0 ",       "value":0,    selected: true},
	              			{"label":"15 " + localizedMessages.get("MINUTE_SHORT"),  "value":15,   selected: false},
	              			{"label":"30 " + localizedMessages.get("MINUTE_SHORT"),  "value":30,   selected: false},
	              			{"label":"45 " + localizedMessages.get("MINUTE_SHORT"),  "value":45,   selected: false},
	              			{"label":"60 " + localizedMessages.get("MINUTE_SHORT"),  "value":60,   selected: false},
	              			{"label":"2 " + localizedMessages.get("HOUR_SHORT"),  "value":120,  selected: false},
	              			{"label":"3 " + localizedMessages.get("HOUR_SHORT"),  "value":180,  selected: false}]

	  	$scope.timeSlots    = TIME_SLOTS;
	  	$scope.CURRENT_TIMESLOT = TIME_SLOTS[6];
	  	$scope.FINAL_TIMESLOT = angular.copy(TIME_SLOTS[6]);


	  	//$scope.selectedTimeSlot = {"label":"0",       "value":"0"};

	  	getUsersForSelectedEvents();
	}

	$scope.changeTab = function (tabVal)
	{
		var everythingValid = false;
		$scope.TEMP_TAB_VAL = tabVal;
		var showDlg = false;

		if(tabVal)
			$scope.showRemovingUsers = true;
		else
		    $scope.showRemovingUsers = false;

		switch($scope.CURRENT_TAB)
		{
			case 'adjustTime' 		: 	everythingValid = $scope.validateChangesForTime(); 
										break;
			case 'activeInactive' 	: 	everythingValid = $scope.validateChangesForActiveInActive(); 
										break;
			case 'addAttendees'		: 	everythingValid = $scope.validateChangesForAddAttendees();
										break;
			case 'removeAttendees'	: 	everythingValid = $scope.validateChangesForRemoveAttendees();
										break;
			case 'copyMeetings'	: 		everythingValid = $scope.validateChangesForCopyMeetings(); 
										break;
		}
		if(everythingValid)
		{
			bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveTempChangesOnTabChange, $scope.removeTempChangesOnTabChange);
		}
		else
		{
			$scope.CURRENT_TAB = $scope.TEMP_TAB_VAL;
		}
	}

	$scope.saveTempChangesOnTabChange = function ()
	{
		var previousTab = $scope.CURRENT_TAB;
		$scope.CURRENT_TAB = $scope.TEMP_TAB_VAL;
//		$scope.saveTempChanges(previousTab);
	}


	$scope.saveTempChanges = function (tabName)
	{
		switch(tabName)
		{
			case 'adjustTime' 		: 	$scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE = angular.copy($scope.EVENTS_FOR_TIME);
									  	$scope.FINAL_TIMESLOT = angular.copy($scope.CURRENT_TIMESLOT);
									  	for (var i = 0; i < $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE.length; i++) 
										{
										    $scope.EVENTS_FOR_ADD_ATTND[i].startDateGMTMillis    = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].startDateGMTMillis;
											$scope.EVENTS_FOR_ADD_ATTND[i].endDateGMTMillis      = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].endDateGMTMillis;
											$scope.EVENTS_FOR_REMOVE_ATTND[i].startDateGMTMillis = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].startDateGMTMillis;
											$scope.EVENTS_FOR_REMOVE_ATTND[i].endDateGMTMillis   = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].endDateGMTMillis;
											$scope.FINAL_EVENT_FOR_ADD_ATTENDEE[i].startDateGMTMillis    = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].startDateGMTMillis;
											$scope.FINAL_EVENT_FOR_ADD_ATTENDEE[i].endDateGMTMillis      = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].endDateGMTMillis;
											$scope.FINAL_EVENT_FOR_REMOVE_ATTENDEE[i].startDateGMTMillis = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].startDateGMTMillis;
											$scope.FINAL_EVENT_FOR_REMOVE_ATTENDEE[i].endDateGMTMillis   = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].endDateGMTMillis;
											$scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].startDateGMTMillis = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].startDateGMTMillis;
											$scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].endDateGMTMillis   = $scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].endDateGMTMillis;
										}
									  	break;
			case 'addAttendees'		: 	$scope.FINAL_EVENT_FOR_ADD_ATTENDEE = angular.copy($scope.EVENTS_FOR_ADD_ATTND);
										$scope.FINAL_USERS = angular.copy($scope.USERS);
										break;
			case 'activeInactive'	: 	$scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE = angular.copy($scope.EVENTS_FOR_TIME);
										break;
			case 'removeAttendees'	:   $scope.FINAL_EVENT_FOR_REMOVE_ATTENDEE = angular.copy($scope.EVENTS_FOR_REMOVE_ATTND);
										break;
			case 'copyMeetings'	    :   $scope.FINAL_EVENTS_FOR_COPY_MEETINGS = angular.copy($scope.EVENTS_FOR_COPY_MEETINGS);
										break;

		}
	}

	$scope.removeTempChangesOnTabChange = function ()
	{
		var previousTab = $scope.CURRENT_TAB;
		$scope.CURRENT_TAB = $scope.TEMP_TAB_VAL;
		switch(previousTab)
		{
			case 'adjustTime' 		: 	$scope.EVENTS_FOR_TIME = angular.copy($scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE);
										$scope.CURRENT_TIMESLOT.selected = false;
										for(var i=0; i < $scope.timeSlots.length; i++)
										{
											if($scope.timeSlots[i].value == $scope.FINAL_TIMESLOT.value)
											{
												$scope.CURRENT_TIMESLOT = $scope.timeSlots[i];
												$scope.CURRENT_TIMESLOT.selected = true;
												break;
											}
										}
									  	break;
			case 'addAttendees'		: 	$scope.EVENTS_FOR_ADD_ATTND = angular.copy($scope.FINAL_EVENT_FOR_ADD_ATTENDEE);
			                            //$scope.USERS.forEach(function(user){
			                            smartSelectionUtils.selectInList($scope.ADDED_ATTENDEES, false);
			                            //});
										$scope.ADDED_ATTENDEES = angular.copy($scope.FINAL_ADDED_ATTENDEES);
										break;
			case 'activeInactive'	: 	$scope.EVENTS_FOR_TIME = angular.copy($scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE);
										break;
			case 'removeAttendees'	: 	$scope.EVENTS_FOR_REMOVE_ATTND = angular.copy($scope.FINAL_EVENT_FOR_REMOVE_ATTENDEE);
			                            $scope.MEETING_USERS.ITEMS = [];
			                            $scope.REMOVED_ATTENDEES = [];
			                            getUsersForSelectedEvents();
										break;
			case 'copyMeetings'	    :   $scope.EVENTS_FOR_COPY_MEETINGS = angular.copy($scope.FINAL_EVENTS_FOR_COPY_MEETINGS);
            							break;
		}

		saveBtnEnableDisable();
	}

	$scope.changeEventSelection = function (calEvent)
	{
		calEvent.selected = !calEvent.selected;
		if($scope.CURRENT_TAB == 'adjustTime')
		{
			if(calEvent.selected)
			{
				var minsToAdd = $scope.CURRENT_TIMESLOT.value;
				var minsToMilis = minsToAdd * 60000;
				calEvent.startDateGMTMillis = parseInt(calEvent.startDateGMTMillis) + minsToMilis;
				calEvent.endDateGMTMillis = parseInt(calEvent.endDateGMTMillis) + minsToMilis;
			}	
			else
			{
				for (var i = 0; i < $scope.EVENTS_FOR_TIME.length; i++) 
				{
				    if($scope.EVENTS_FOR_TIME[i].objectBaseUuid == calEvent.objectBaseUuid)
					{
						$scope.EVENTS_FOR_TIME[i].startDateGMTMillis = $scope.INITIAL_EVENTS[i].startDateGMTMillis;
						$scope.EVENTS_FOR_TIME[i].endDateGMTMillis = $scope.INITIAL_EVENTS[i].endDateGMTMillis;
						break;
					}
				}
			}
		}

		if($scope.CURRENT_TAB == 'copyMeetings'){
		    var selectedEvents = _.filter($scope.EVENTS_FOR_COPY_MEETINGS, { selected : true });
            if(selectedEvents && selectedEvents.length > 10){
               calEvent.selected = false;
               bvDialog.showMessage(localizedMessages.get("ONLY_10_MEETINGS_CAN_BE_COPIED_AT_A_TIME"));
            }
		}

		if($scope.CURRENT_TAB == 'removeAttendees'){
		    if(calEvent.selected)
		        $scope.removeOwnerAndGetOtherUsers(calEvent);
		    else{
                _.forEach(calEvent.USERS, function(user){
                    var existingUser = _.find($scope.EVENTS_FOR_REMOVE_ATTND, function(event){
                        var existingUser_ = _.find(event.USERS, {objectBaseUuid : user.objectBaseUuid});
                        return existingUser_ && event.selected && event.objectBaseUuid != calEvent.objectBaseUuid;
                    });

                    if(!existingUser)
                        _.remove($scope.MEETING_USERS.ITEMS, {objectBaseUuid : user.objectBaseUuid})
                });
		    }
		}

		saveBtnEnableDisable();
	}
	
	$scope.changeToActive = function (calEvent, isActive)
	{		
		if($scope.CURRENT_TAB == 'activeInactive')
		{
			calEvent.isActive = isActive;			
		}
		
		for (var i = 0; i < $scope.EVENTS_FOR_TIME.length; i++)
		{
		    if($scope.EVENTS_FOR_TIME[i].objectBaseUuid == calEvent.objectBaseUuid)
			{
				$scope.EVENTS_FOR_TIME[i].isActive = isActive;				
				break;
			}
		}

		saveBtnEnableDisable();
	}

	$scope.changeEventOptionSelection = function(calEvent)
	{
		for(var i=0; i < $scope.EVENTS_FOR_REMOVE_ATTND.length; i++)
		{
			if(calEvent.objectBaseUuid == $scope.EVENTS_FOR_REMOVE_ATTND[i].objectBaseUuid)
			{
				$scope.EVENTS_FOR_REMOVE_ATTND[i].selected = true;
				$scope.removeOwnerAndGetOtherUsers($scope.EVENTS_FOR_REMOVE_ATTND[i]);
			}
			else
			{
				$scope.EVENTS_FOR_REMOVE_ATTND[i].selected = false;
			}
		}
	}

	$scope.validateChangesForTime = function()
	{		
	    return $scope.CURRENT_TIMESLOT.value != $scope.FINAL_TIMESLOT.value;
	}
	
	$scope.validateChangesForActiveInActive = function()
	{
		return !angular.equals(_.map($scope.EVENTS_FOR_TIME, 'isActive'), _.map($scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE, 'isActive'));
	}
	

	$scope.validateChangesForAddAttendees = function()
	{
		//console.log("USERS:",$scope.USERS);
		//return angular.equals($scope.ADDED_ATTENDEES, $scope.FINAL_ADDED_ATTENDEES) ? !1 : !0
		return angular.equals($scope.ADDED_ATTENDEES, $scope.FINAL_ADDED_ATTENDEES) ? !1 : !0

	}

	$scope.validateChangesForRemoveAttendees = function()
	{
	    if($scope.REMOVED_ATTENDEES.length > 0)
	        return true;

		return false;
	}
	
	$scope.validateChangesForCopyMeetings = function()
	{
		for(var i=0;i<$scope.EVENTS_FOR_COPY_MEETINGS.length;i++)
		{
			if(!angular.equals($scope.EVENTS_FOR_COPY_MEETINGS, $scope.FINAL_EVENTS_FOR_COPY_MEETINGS))
			{
				return true;
			}
		}
		return false;
	}

	$scope.removeOwnerAndGetOtherUsers = function(calEvent, callback)
	{
        calendarService.loadGuestandResponseForEvent(calEvent.objectBaseUuid).then(function(data)
        {
            if(data)
            {
                var currentUserId = context.getModel().userVO.objectBaseUuid;
                var remainingGuests = [];
                console.log("EVENT REMOVE ATTENDDEE:",data.data);
                var confGuests = data.data.guests;
                if(confGuests!=undefined && confGuests!=null && confGuests.length >0)
                {
                    for(var i in confGuests)
                    {
                        if(currentUserId != confGuests[i].objectBaseUuid)
                        {
                            remainingGuests.push(confGuests[i]);
                        }
                    }
                }
                calEvent.USERS = angular.copy(remainingGuests);

                for(var i=0;i<$scope.EVENTS_FOR_REMOVE_ATTND.length;i++)
                {
                    if(calEvent.objectBaseUuid == $scope.EVENTS_FOR_REMOVE_ATTND[i].objectBaseUuid)
                    {
                        $scope.EVENTS_FOR_REMOVE_ATTND[i].USERS = angular.copy(remainingGuests);
                        //$scope.INITIAL_EVENTS[i].USERS = angular.copy(remainingGuests);
                    }
                }

                _.forEach(calEvent.USERS, function(user){
                  if(!_.find($scope.MEETING_USERS.ITEMS, {objectBaseUuid : user.objectBaseUuid}))
                      $scope.MEETING_USERS.ITEMS.push(user);
                })

                if(typeof callback == 'function'){
                    callback($scope.MEETING_USERS);
                }
            }
        });
	}
	$scope.getDisplayTime = function (calEvent)
	{
		if(calEvent!=undefined)
		{
			
			// MEETX-49265			
			var dateHelper = new DateHelper(context.getModel().userVO);
			
			var startTime = dateHelper.getFormattedTimeUTC(parseInt(calEvent.startDateGMTMillis));
			var endTime = dateHelper.getFormattedTimeUTC(parseInt(calEvent.endDateGMTMillis));
			
			return startTime + " - " + endTime;
		}
	}

	$scope.selectTimeSlot = function (timeSlot)
	{
		if($scope.CURRENT_TIMESLOT.label != timeSlot.label)
		{
			$scope.CURRENT_TIMESLOT.selected = false;
			timeSlot.selected = true;
			//console.log("Making time selection true:");
			//$scope.CHANGES_TEMP_SAVED_FOR_TIME = true;
			var minsToAdd = timeSlot.value;
			var minsToMilis = minsToAdd * 60000;
			for (var i = 0; i < $scope.EVENTS_FOR_TIME.length; i++) 
			{
			    if($scope.EVENTS_FOR_TIME[i].selected)
				{
					$scope.EVENTS_FOR_TIME[i].startDateGMTMillis = parseInt($scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].startDateGMTMillis) + minsToMilis;
					$scope.EVENTS_FOR_TIME[i].endDateGMTMillis = parseInt($scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE[i].endDateGMTMillis) + minsToMilis;
				}
			}
			
		}	
		$scope.CURRENT_TIMESLOT = timeSlot;

		saveBtnEnableDisable();
	}

	$scope.setRemovedUsers = function(selectedItem)
	{
		//$scope.REMOVED_ATTENDEES.push(selectedItem);
//		for(var i=0; i < $scope.EVENTS_FOR_REMOVE_ATTND.length; i++)
//		{
//			if($scope.EVENTS_FOR_REMOVE_ATTND[i].selected)
//			{
//
//				for (var j = 0; j < $scope.EVENTS_FOR_REMOVE_ATTND[i].USERS.length; j++)
//				{
//				   	if($scope.EVENTS_FOR_REMOVE_ATTND[i].USERS[j].objectBaseUuid == selectedItem.objectBaseUuid)
//					{
//						console.log("LENGTH BEFORE:",$scope.EVENTS_FOR_REMOVE_ATTND[i].USERS.length);
//
//						var removedAttendee = _.find($scope.REMOVED_ATTENDEES, {event : $scope.EVENTS_FOR_REMOVE_ATTND[i].objectBaseUuid});
//						if(removedAttendee){
//						    removedAttendee.users.push(selectedItem.objectBaseUuid);
//						}
//						else{
//						    $scope.REMOVED_ATTENDEES.push({ event : $scope.EVENTS_FOR_REMOVE_ATTND[i].objectBaseUuid, users : [selectedItem.objectBaseUuid]});
//						}
//
//						$scope.EVENTS_FOR_REMOVE_ATTND[i].USERS.splice(j,1);
//						console.log("LENGTH AFTER:",$scope.EVENTS_FOR_REMOVE_ATTND[i].USERS.length);
//						//$scope.REMOVED_ATTENDEES.push({'eventId':$scope.EVENTS_FOR_REMOVE_ATTND[i].objectBaseUuid, 'userId':selectedItem.objectBaseUuid});
//						//$scope.REMOVED_ATTENDEES.push($scope.EVENTS_FOR_REMOVE_ATTND[i].objectBaseUuid+"#"+selectedItem.objectBaseUuid});
//						//$scope.CHANGES_TEMP_SAVED_FOR_REMOVE = true;
//						break;
//					}
//				}
//				break;
//			}
//		}

        _.forEach(_.filter($scope.EVENTS_FOR_REMOVE_ATTND, {selected : true}), function(event){
            var removedAttendee = _.find($scope.REMOVED_ATTENDEES, {event : event.objectBaseUuid});
            if(_.find(event.USERS, {objectBaseUuid : selectedItem.objectBaseUuid})){
                if(removedAttendee){
                    removedAttendee.users.push(selectedItem.objectBaseUuid);
                }
                else{
                    $scope.REMOVED_ATTENDEES.push({ event : event.objectBaseUuid, users : [selectedItem.objectBaseUuid]});
                }
            }
        });



		saveBtnEnableDisable();
	}

	$scope.setSelectedUsers = function(selectedItems)
	{
	    _.forEach(selectedItems, function(item){
            if(!item.selected){
                var obj = _.find($scope.ADDED_ATTENDEES, {objectBaseUuid: item.objectBaseUuid});
                if(obj)
                    _.remove($scope.ADDED_ATTENDEES, {objectBaseUuid: item.objectBaseUuid});
         //           smartSelectionUtils.selectInList([item], false);
            }
            else{
                if(_.findIndex($scope.ADDED_ATTENDEES, { objectBaseUuid : item.objectBaseUuid }) != -1)
                    return;
                $scope.ADDED_ATTENDEES.push(item);
            }
	    });

		//$scope.ADDED_ATTENDEES.push(selectedItems);
		//$scope.USERS.push(angular.copy(selectedItems));
		saveBtnEnableDisable();
	};

	$scope.close = function()
	{
		$scope.cancelConfChanges();
	}

	$scope.exitEditMeetings = function ()
	{
		bvModal.close();
	}

	$scope.cancelConfChanges = function()
	{
		var confirmMsg = $scope.UI_MSG_EDIT_MEETINGS_CANCEL_CHANGES;
		var changesMade = $scope.finalCheckForChanges();
		if(changesMade)
		{
			bvDialog.confirm(confirmMsg, $scope.exitEditMeetings, null);
		}
		else
		{
			$scope.exitEditMeetings();
		}
	}

	$scope.saveConfChanges = function()
	{
	    if(!$scope.saveConfChangesBtnDisable){
            //$scope.saveTempChanges($scope.CURRENT_TAB);
            var confirmMsg = $scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM;
            var changesMade = $scope.finalCheckForChanges();
            if(changesMade)
            {
                bvDialog.confirm(confirmMsg, $scope.saveChanges, null);
            }
            else
            {
                $scope.exitEditMeetings();
            }
		}
	}

 	$scope.finalCheckForChanges = function()
 	{
 		var changesMade = false;
 		$scope.INPUT_EVENTS_FOR_TIME = [];
 		$scope.INPUT_EVENTS_FOR_ACTIVE = [];
 		$scope.INPUT_EVENTS_FOR_INACTIVE = [];
 		$scope.INPUT_EVENTS_FOR_ADDITION = [];
 		$scope.INPUT_EVENTS_FOR_COPY = [];
 		$scope.INPUT_DETAILS_FOR_COPY = [];
 		$scope.USER_IDS_FOR_ADDITION =[];
 		$scope.EVENT_USER_LIST_FOR_REMOVAL = [];

        $scope.INPUT_EVENTS_FOR_TIME = _.map(_.filter($scope.EVENTS_FOR_TIME, { selected : true }), 'objectBaseUuid');

        $scope.INPUT_EVENTS_FOR_ADDITION = _.map(_.filter($scope.EVENTS_FOR_ADD_ATTND, { selected : true }), 'objectBaseUuid');
        $scope.USER_IDS_FOR_ADDITION = _.map($scope.ADDED_ATTENDEES, 'objectBaseUuid');

        $scope.INPUT_EVENTS_FOR_ACTIVE = _.map(_.filter($scope.EVENTS_FOR_TIME, function(event){
            var currentEvent = _.find($scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE, {objectBaseUuid : event.objectBaseUuid});
            return currentEvent.isActive != event.isActive && event.isActive;
        }), 'objectBaseUuid');

        $scope.INPUT_EVENTS_FOR_INACTIVE = _.map(_.filter($scope.EVENTS_FOR_TIME, function(event){
            var currentEvent = _.find($scope.FINAL_EVENT_FOR_TIME_TIME_CHANGE, {objectBaseUuid : event.objectBaseUuid});
            return currentEvent.isActive != event.isActive && !event.isActive;
        }), 'objectBaseUuid');

        $scope.EVENT_USER_LIST_FOR_REMOVAL = _.map($scope.REMOVED_ATTENDEES, function(event){
            return event.event + '_' + event.users.join('#');
        });

 		$scope.INPUT_EVENTS_FOR_COPY = _.map(_.filter($scope.EVENTS_FOR_COPY_MEETINGS, { selected : true }), 'objectBaseUuid');
 	    if($scope.INPUT_EVENTS_FOR_COPY.length > 0){
 	        $scope.INPUT_DETAILS_FOR_COPY = [
 	            "DAY#"           + $scope.copyMeetings.datepicker.startingDateLocal,
 	            "ATTACHMENTS#"   + $scope.copyMeetings.selection.attachments,
 	            "DETAILS#"       + $scope.copyMeetings.selection.details,
 	            "USERS#"         + $scope.copyMeetings.selection.users
 	        ]
 	    }

 		return $scope.validateChangesForTime() || $scope.validateChangesForActiveInActive() || $scope.validateChangesForAddAttendees() || $scope.validateChangesForRemoveAttendees() || $scope.validateChangesForCopyMeetings();
 	}


	$scope.saveChanges = function()
	{
		console.log("INPUT_EVENTS_FOR_TIME:",$scope.INPUT_EVENTS_FOR_TIME);
		console.log("INPUT_EVENTS_FOR_ADDITION:",$scope.INPUT_EVENTS_FOR_ADDITION);
		console.log("USER_IDS_FOR_ADDITION:",$scope.USER_IDS_FOR_ADDITION);
		console.log("EVENT_USER_LIST_FOR_REMOVAL:",$scope.EVENT_USER_LIST_FOR_REMOVAL);
		console.log("CURRENT_TIMESLOT:",$scope.CURRENT_TIMESLOT.value);
		console.log("INPUT_EVENTS_FOR_ACTIVE:",$scope.INPUT_EVENTS_FOR_ACTIVE+" "+ $scope.INPUT_EVENTS_FOR_INACTIVE);
		console.log("INPUT_EVENTS_FOR_COPY:",$scope.INPUT_EVENTS_FOR_COPY);
		console.log("INPUT_DETAILS_FOR_COPY:",$scope.INPUT_DETAILS_FOR_COPY);

		console.log("$scope.EVENTS_LIST_DATE", $scope.EVENTS_LIST_DATE);
		var en_moment = moment($scope.EVENTS_LIST_DATE, 'll');
		console.log("en_moment", en_moment);
		calendarService.saveMeetingsChanges(
		    angular.toJson($scope.INPUT_EVENTS_FOR_TIME),
		    $scope.CURRENT_TIMESLOT.value,
            en_moment.locale('en-us').format('ll'),
            angular.toJson($scope.INPUT_EVENTS_FOR_ADDITION),
            angular.toJson($scope.USER_IDS_FOR_ADDITION),
            angular.toJson($scope.EVENT_USER_LIST_FOR_REMOVAL),
            angular.toJson($scope.INPUT_EVENTS_FOR_INACTIVE),
            angular.toJson($scope.INPUT_EVENTS_FOR_ACTIVE),
            angular.toJson($scope.INPUT_EVENTS_FOR_COPY),
            angular.toJson($scope.INPUT_DETAILS_FOR_COPY)
		).then(function(result){

		    var eventsForToday = [];
		    var eventsCopied = [];

            try{
                if(result.data.timeEvents != '')
                    eventsForToday = angular.fromJson(result.data.timeEvents);
                if(result.data.copiedEvents != '')
                    eventsCopied = angular.fromJson(result.data.copiedEvents);
            }
            catch(exception){
                console.log(exception)
            }

			var ownerEventsForToday = [];

			var confCntrl = angular.element('#bv-conference-tpl').scope();
			if(confCntrl!=undefined)
        	{
        		if((($scope.CURRENT_TIMESLOT.value != 0) && ($scope.INPUT_EVENTS_FOR_ADDITION.length!=0)) || $scope.INPUT_EVENTS_FOR_INACTIVE.lenght != 0 || $scope.INPUT_EVENTS_FOR_ACTIVE.lenght != 0)
 				{
        			for(var i=0;i<eventsForToday.length;i++)
					{
						if(eventsForToday[i].isEventOwner)
						{
							ownerEventsForToday.push(eventsForToday[i])
						}
					}

 					confCntrl.$parent.refreshEventsAfterTimeChange(ownerEventsForToday);
 				}
 				else
 				{
 					confCntrl.$parent.refreshSelectedEventAfterAttendeeChange();
 				}
        	}

            $scope.exitEditMeetings();

        	if(eventsCopied.length > 0){
        	    bvDialog.showMessage(localizedMessages.get("MEETINGS_HAVE_BEEN_COPIED"), undefined, true);

                try{
                    $rootScope.EVENTS_LIST.DATE =  $scope.copyMeetings.datepicker.startingDateLocal;
                    $rootScope.EVENTS_LIST.EVENTS = _.map(eventsCopied, function(e){
                        e.isActive = angular.fromJson(e.isActive);
                        e.isEventOwner = angular.fromJson(e.isEventOwner);
                        return e;
                    });

                    $rootScope.EVENTS_LIST.SELECTED_EVENT = eventsCopied[0].objectBaseUuid;
                }
                catch(exception){
                    console.log(exception);
                }
                $state.go('home.meetings', {}, {reload: true});
              
        	}

        	$scope.exitEditMeetings();
        	
        });
	}

	$scope.saveTimeChanges = function()
	{
		//console.log("TODAY's-DATE:",$scope.EVENTS_LIST_DATE);
		calendarService.changeTimeForEvents(angular.toJson($scope.SELECTED_EVENTS_FOR_TIME_CHANGE),$scope.CURRENT_TIMESLOT.value, $scope.EVENTS_LIST_DATE).then(function(result)
		{
			var eventsAfterTimeChange = result.data;
			$scope.INITIAL_EVENTS = angular.copy($scope.EVENTS_FOR_TIME);
			var confCntrl = angular.element('#bv-conference-tpl').scope();
			if(confCntrl!=undefined)
        	{
            	confCntrl.$parent.refreshEventsAfterTimeChange(eventsAfterTimeChange);
        	}
        	$scope.initAdustTime();
		});
	}

	$scope.saveAddRemoveAttendeesChanges = function(isAdd)
	{
		var selectedEvents = [];
        var userIds = [];
		if(isAdd)
        {
        	for (var i = 0; i < $scope.SELECTED_EVENTS_FOR_ADD_ATTENDEES.length; i++) 
			{
		    	selectedEvents.push($scope.SELECTED_EVENTS_FOR_ADD_ATTENDEES[i].objectBaseUuid);
			}
			for (var i = 0; i < $scope.ADDED_ATTENDEES.length; i++) 
			{
		    	userIds.push($scope.ADDED_ATTENDEES[i].objectBaseUuid);
			}
		}
		else
		{
			selectedEvents.push($scope.SELECTED_EVENTS_FOR_REMOVE_ATTENDEES.objectBaseUuid);
			for (var i = 0; i < $scope.REMOVED_ATTENDEES.length; i++) 
			{
		    	userIds.push($scope.REMOVED_ATTENDEES[i].objectBaseUuid);
			}
		}	
		calendarService.addRemoveParticipantsToEvents(isAdd, angular.toJson(userIds), angular.toJson(selectedEvents)).then(function(result)
		{
			var confCntrl = angular.element('#bv-conference-tpl').scope();
			if(confCntrl!=undefined)
        	{
            	confCntrl.$parent.refreshSelectedEventAfterAttendeeChange();
        	}
        	if(isAdd)
        		$scope.initAddAttendee();
        	else
        		$scope.initRemoveAttendee();
        	
		});
	}

	$scope.init();
}
