function checkboxDirective($templateCache, $compile, legacyUserService, $timeout){

    return {
        restrict: 'E',
        scope: {
            on: '=',
            callback: '=',
            watchable: '='
        },
        templateUrl: !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/checkbox.tpl.html' : 'directives/checkbox/checkbox.tpl.html',
        link : function($scope, element, attrs){

            $scope.id = StringUtils.getRandomValue();

            $scope.checked = $scope.on !== undefined ? $scope.on : false;

            if(attrs.ngClick === undefined){
                $scope.click = () => {
                    $timeout(() => {
                        if(!legacyUserService.isNasdaqUI)
                            $scope.checked = !$scope.checked;

                        if($scope.on !== undefined)
                            $scope.on = $scope.checked;

                        if(typeof $scope.callback == 'function'){
                            $scope.callback($scope.on !== undefined ? $scope.on : $scope.checked);
                        }
                    })
                }
            }

            if($scope.watchable){
                $scope.$watch('on', function(on){
                    $scope.checked = on;
                })
            }
        }
    }
}
