dashboardNxtv2Controller.$inject = [ '$scope', '$state', 'reactInterface',
		'context', 'localizedMessages', 'eventBusService', 'commonService' ];

function dashboardNxtv2Controller($scope, $state, reactInterface, context,
		localizedMessages, eventBusService, commonService) {
	$scope.$parent.currentState.value = $state.$current.name;
	var moduleName = $state.$current.name.split("home.")[1];
	$scope.middleBarObject.title = localizedMessages.get("DASHBOARD");
	$scope.middleBarObject.showBack = false;
	$scope.reactInterface = reactInterface;
	$scope.showContext = true;

	var dashboardRightMenuList = [];

	var addCards = function() {
		eventBusService.publish('ADD_CARDS_CLICKED','');
	};

	var manageDashboard = function() {
		eventBusService.publish('MANAGE_DASHBOARDS_CLICKED','');
	}

	var manageDashboards = {title : localizedMessages.get("MANAGE_DASHBOARDS"),click : manageDashboard,iconUrl : ''};
    var menuItemAddCards = {title : localizedMessages.get("ADD_CARDS"),click : addCards,iconUrl : ''};

	var isBrowser = function() {
		return (!MobileUtil.isAndroid() && !MobileUtil.isIPhone() && !MobileUtil.isIOS() && !MobileUtil.isSurface() && !MobileUtil.isDesktopClient());
	}

	if(isBrowser()) {
		commonService.checkModuleCreatorsConfigPresentJSON("DASHBOARD").then(function(data){
			if(data && data.data && data.data.name === "isModuleCreatorsPrefAllowed"){
				var canCurrentUserCreateModule = false;
				var isModuleAllowedCreators = ((data.data.value === "true" || data.data.value === true) ? true :false);
				if(isModuleAllowedCreators){
					commonService.getModuleCreatorsForSpecificModuleJSON("DASHBOARD").then(function(mod){
						if(mod.data){
							var moduleCreators = angular.fromJson(mod.data);
							if(moduleCreators.users != undefined && moduleCreators.users.length>0){
								for(var i=0;i<moduleCreators.users.length;i++){
									if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
										canCurrentUserCreateModule = true;
										break;
									}
								}
								if(canCurrentUserCreateModule){
									dashboardRightMenuList.push(manageDashboards);
									dashboardRightMenuList.push(menuItemAddCards);
								}
							}
						}
					});
				}
			}
		});
	}

	
	// Single view is tab 0
	$scope.tabs = [ {
		title : $scope.middleBarObject.title,
		content : $scope.middleBarObject.title,
		active : true,
		rightMenuList : dashboardRightMenuList
	} ];

}