function surveysService($q, ajaxService, responseValidatorService, imageService, repositoryService, constants, localizedMessages) {
	var setDefaultSection;
	var currentSelectedSurveyFromList;
	var participatBaseUuidList;
	var questionBaseUuidList;
	var allQuestionsInSurveyList;
	var allSectionsInSurveyList;
	var selectedSurvey;
	var showTabs = true;
	var isCurrentLoggedInUserOwner = false;
	var surveySet={};
	var surveySection={};
	var typeOfuser="";
	var surveyAttachmentList=[];
	var selectedQuestion="";
	var isCurrentUserAllowedToCreateSurveys = false;
	var selectedTab = 0;
	var previewDetails = {};
	var toRemoveParticipantsList = {};
	var sectionCards = {};
	var surveyPublished = false;
	var isSurveyResponseDirty = false;
	var preFillingUserBaseUuid="";
	var surveyOpenFromInbox;
//	var hasCurrentLoggedInUserResponded = false;
	
	var service = {
			toRemoveParticipantsList: function(update){
				if(update)
					toRemoveParticipantsList.list = update;
				
				return toRemoveParticipantsList;
			},
			sectionCards: function(list){
				if(list)
					sectionCards.list = list;
				
				return sectionCards;
			},
			
			setSurveyOpenFromInbox : function(surveyOpenFromInbox){
				this.surveyOpenFromInbox = surveyOpenFromInbox;
			},
			
			getSurveyOpenFromInbox : function(){
				return this.surveyOpenFromInbox;
			},	
			
		setIsSurveyPublished : function(surveyPublished){
			this.surveyPublished = surveyPublished;
		},
		getIsSurveyPublished : function(){
			return this.surveyPublished;
		},
		
		setPreFillingUserBaseUuid : function(userBaseUuid){
			this.preFillingUserBaseUuid = userBaseUuid;
		},
		getPreFillingUserBaseUuid : function(){
			return this.preFillingUserBaseUuid;
		},
		
		setIsCurrentUserAllowedToCreateModule : function(isCurrentUserAllowedToCreateModule){
				this.isCurrentUserAllowedToSurveys = isCurrentUserAllowedToCreateModule;
			},
			
		isCurrentUserAllowedToCreateModule : function(){
				return this.isCurrentUserAllowedToSurveys;
			},
			
		getMySurveyListJSON : function() {
			var request = ajaxService.getMySurveys();
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		getMyClosedSurveyListJSON : function() {
			var request = ajaxService.getMyClosedSurveysJson();
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		
		},
		
		getMyOwnedSurveyListJSON : function() {
			var request = ajaxService.getMyOwnedSurveysJson();
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});	
		},
		
		getMyCreatedSurveyListJSON : function() {
			var request = ajaxService.getMyCreatedSurveysJson();
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});	
		},
		
		getSurveyDetailsJSON : function(surveyBaseUuid) {
			var request = ajaxService.getSurveyDetailsJson(surveyBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		getSurveySectionResponsesForUserJSON : function(surveyBaseUuid, sectionBaseUuid, userBaseUuid) {
			var request = ajaxService.getSurveySectionResponsesForUser(surveyBaseUuid, sectionBaseUuid, userBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		saveSurveyResponseJSON : function(surveyBaseUuid, questionBaseUuid, userBaseUuid, surveyResponse, surveyResponseList, comments) {
			
			var request = ajaxService.saveSurveyResponseJson(surveyBaseUuid, questionBaseUuid, userBaseUuid, surveyResponse, surveyResponseList, comments);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		submitSurveyResponseJSON : function(surveyBaseUuid, isSigned) {
			var request = ajaxService.submitSurveyResponseJson(surveyBaseUuid, isSigned);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		deleteSurveyJSON : function(surveyBaseUuid) {
			var request = ajaxService.deleteSurveyJson(surveyBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		getSurveyResponseForUsersJSON : function(surveyBaseUuid){
			var request = ajaxService.getSurveyResponseForUsersJson(surveyBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		
		},
		
		getUsersSignatureJSON : function(businessObjectBaseUuid, objectBaseUuids){
			var request = ajaxService.getUsersSignatureJSON(businessObjectBaseUuid, objectBaseUuids);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		
		},
		getSurveyResponseForUserJSON : function(surveyBaseUuid, questionBaseUuidListString, userBaseUuid){
			var request = ajaxService.getSurveyResponseForUserJson(surveyBaseUuid, questionBaseUuidListString, userBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		getSurveyReportUserResponseJSON : function(surveyBaseUuid){
			var request = ajaxService.getSurveyReportUserResponseJson(surveyBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		
		},
		
		setCurrentSelectedSurveyFromList : function(survey) {
			currentSelectedSurveyFromList = survey;
		},
		
		getCurrentSelectedSurveyFromList : function() {
			return currentSelectedSurveyFromList;
		},
		
		getUserSignature: function(businessObjectBaseUuid, userObjectBaseUuid) {
			var request=ajaxService.getUserSignature(businessObjectBaseUuid, userObjectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
			
		},
		groupBy : function(attribute, name, allSurveysList) {
			var groups = [];
			var groupValue = "_INVALID_GROUP_VALUE_";
			for ( var i = 0; i < allSurveysList.length; i++) {
				var survey = allSurveysList[i];
				if (survey[attribute] !== groupValue) {
					var group = {
						label : survey[attribute],
						name  : survey[name],
						allSurveysList : []
					};
					groupValue = group.label;
					groups.push(group);
				}
				group.allSurveysList.push(survey);
			}
			return groups;
		},
		
		sortOn : function(collection, name) {
			collection.sort(function(a, b) {
				if (a[name] <= b[name]) {
					return (-1);
				}
				return (1);
			});
		},
		
		setQuestionBaseUuidList : function(survey) {
			var list = [];
			if(survey.sections)
				for(var i=0;i<survey.sections.length;i++){
					for(var j=0;j<survey.sections[i].questions.length;j++){
						list.push(survey.sections[i].questions[j].objectBaseUuid);
					}
				}
			questionBaseUuidList = list;
		},
		
		getQuestionBaseUuidList : function() {
			return questionBaseUuidList;
		},
		setParticipantBaseUuidList :function(survey) {
			var list = [];
			for(var i=0;i<survey.participantBaseUuidAndResponseList.length;i++){
				list.push(survey.participantBaseUuidAndResponseList[i].objectBaseUuid);
			} 
			participatBaseUuidList = list;
		},
		
		getParticipantBaseUuidList : function() {
			return participatBaseUuidList
		},
		setAllQuestionsList : function(survey) {
			var list = [];
			if(survey.sections)
				for(var i=0;i<survey.sections.length;i++){
					for(var j=0;j<survey.sections[i].questions.length;j++){
						list.push(survey.sections[i].questions[j]);
					}
				}
			allQuestionsInSurveyList = list;
			if(allQuestionsInSurveyList != undefined && allQuestionsInSurveyList.length>0){
				for(var j=0;j<allQuestionsInSurveyList.length;j++){
					var options=[];
					var likertScaleValuesMapping = {};
						if(allQuestionsInSurveyList[j].questionType=="multiplechoice"){
							for(var i=0;i<allQuestionsInSurveyList[j].multipleChoiceValues.length;i++){
								options[i] = allQuestionsInSurveyList[j].multipleChoiceValues[i].name;
							}
						}
						if(allQuestionsInSurveyList[j].questionType=="multiplechoicemultiresponse"){
							for(var i=0;i<allQuestionsInSurveyList[j].multipleChoiceValues.length;i++){
								options[i] = allQuestionsInSurveyList[j].multipleChoiceValues[i].name;
							}
						}
						if(allQuestionsInSurveyList[j].questionType=="yesno"){
							options.push(localizedMessages.get('YES'));
							options.push(localizedMessages.get('NO'));
						}
						if(allQuestionsInSurveyList[j].questionType=="openanswer"){
							options.push(localizedMessages.get('ANSWERED'));
							options.push(localizedMessages.get('NOT_ANSWERED'));
						}
						if(allQuestionsInSurveyList[j].questionType=="likertscale"){
							for(var i=0;i<allQuestionsInSurveyList[j].likertScaleValues.length;i++){
								options[i] = allQuestionsInSurveyList[j].likertScaleValues[i].name;
								likertScaleValuesMapping[allQuestionsInSurveyList[j].likertScaleValues[i].value] = allQuestionsInSurveyList[j].likertScaleValues[i].name;
								allQuestionsInSurveyList[j].likertMapping = angular.copy(likertScaleValuesMapping);
							}
						}
						if(allQuestionsInSurveyList[j].questionType=="truefalse"){
							options.push(localizedMessages.get('SURVEY_TRUE'));
							options.push(localizedMessages.get('SURVEY_FALSE'));
						}
						if(allQuestionsInSurveyList[j].questionType=="meetsornot"){
							options.push(localizedMessages.get('MEETS'));
							options.push(localizedMessages.get('DOES_NOT_MEET'));
						}
						if(allQuestionsInSurveyList[j].questionType=="multiyesno"){
              allQuestionsInSurveyList[j].multipleYesNoChoiceValues.forEach((choice, i) => {
                options[i] = allQuestionsInSurveyList[j].multipleYesNoChoiceValues[i].name;
              })
						}
						if(allQuestionsInSurveyList[j].questionType=="tabularQuestion"){
              allQuestionsInSurveyList[j].tabularQuestionChoiceValues.forEach((choice, i) => {
                options[i] = allQuestionsInSurveyList[j].tabularQuestionChoiceValues[i].name;
              })
						}
						allQuestionsInSurveyList[j].options = options;
				}
			}
		},
		setAllSectionsList : function(survey) {
			var list = [];
			if(survey.sections)
				for(var i=0;i<survey.sections.length;i++){
					list.push(survey.sections[i].objectBaseUuid);
				}
			allSectionsInSurveyList = list;
		},
		getAllSectionsList : function() {
			return allSectionsInSurveyList;
		},
		getAllQuestionsList : function() {
			return allQuestionsInSurveyList;
		},
		copySurveyJSON : function(surveyBaseUuid, surveyName, copyType){
			var request = ajaxService.copySurveyJson(surveyBaseUuid, surveyName, copyType);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		createSurveyTemplate : function(surveyBaseUuid, surveyName){
			var request = ajaxService.createSurveyTemplate(surveyBaseUuid, surveyName);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		closeOrReopenSurveyJSON : function(surveyBaseUuid, isSurveyClosed){
			var request = ajaxService.closeOrReopenSurvey(surveyBaseUuid, isSurveyClosed);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		deleteSurveyParticipantJSON : function(surveyBaseUuid, userBaseUuid){
			var request = ajaxService.deleteSurveyParticipant(surveyBaseUuid, userBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		lockParticipantsJSON : function(surveyBaseUuid, participantsList){
			var request = ajaxService.lockParticipants(surveyBaseUuid, participantsList);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		unLockParticipantsJSON : function(surveyBaseUuid, participantsList){
			var request = ajaxService.unLockParticipants(surveyBaseUuid, participantsList);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		addParticipantsToSurveys : function(surveyBaseUuidList, participantsList){
			var request = ajaxService.addParticipantsToSurveys(surveyBaseUuidList, participantsList);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		deleteSurveyParticipants : function(surveyBaseUuid, participantsList){
			var request = ajaxService.deleteSurveyParticipants(surveyBaseUuid, participantsList);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		saveSurveyReportJSON : function(byteArrayList, surveyBaseUuid, parentFolderBaseUuid, name){
			var request = ajaxService.saveSurveyReport(byteArrayList, surveyBaseUuid, parentFolderBaseUuid, name);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});	
		},
		
		sendSurveyAlertJSON : function(sendAlertUsersList, surveyAlertSubject, surveyAlertComments, attachmentsList, permissionType, sendSecureEmail){
			var request = ajaxService.sendSurveyAlert(sendAlertUsersList, surveyAlertSubject, surveyAlertComments, attachmentsList, permissionType, sendSecureEmail);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});	

		},
		publishSurveys : function(surveyBaseUuidList){
			var request = ajaxService.publishSurveys(surveyBaseUuidList);
			return request.then(function(response) {
				if (responseValidatorService.isSuccess(response.data)) {
					return response.data;
				}
				return null;
			});	
		},
		createOrUpdateSurvey:function(parentFolderBaseUuid,surveyVO,surveyOwnersList)
		{
			var request = ajaxService.createOrUpdateSurvey(parentFolderBaseUuid,surveyVO,surveyOwnersList);
			return request.then(function(response) {
				if (responseValidatorService.isSuccess(response.data)) {
					return response.data;
				}
				return null;
			});	
		},
		saveSurveySection:function(surveyBaseUuid,sectionVO)
		{
			var request = ajaxService.saveSurveySection(surveyBaseUuid,sectionVO);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});	
		},
		createOrUpdateQuestionForSection:function(surveyBaseUuid,sectionBaseUuid,vo)
		{
			var request = ajaxService.createOrUpdateQuestionForSection(surveyBaseUuid,sectionBaseUuid,vo)
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		getSurveyDetails:function(surveyBaseUuid)
		{
			var request = ajaxService.getSurveyDetails(surveyBaseUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		updateSurveyQuestionsSortOrder:function(surveyBaseUuid,sectionVO)
		{
			var request = ajaxService.updateSurveyQuestionsSortOrder(surveyBaseUuid,sectionVO)
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		deleteQuestion:function(surveyBaseUuid,questionBaseUuid)
		{
			var request = ajaxService.deleteQuestion(surveyBaseUuid,questionBaseUuid)
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		setDetailsForSurveyPreview : function(previewDetails)
		{
			this.previewDetails = previewDetails;
			
		},
		getDetailsForSurveyPreview : function(){
			return this.previewDetails;
		},
		
		deleteSection:function(surveyBaseUuid,sectionBaseUuid)
		{
			var request = ajaxService.deleteSection(surveyBaseUuid,sectionBaseUuid)
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		setSurvey:function(survey)
		{
			surveySet=survey;
		},
		getSurvey:function()
		{
			return surveySet;
		},
		setSurveySection:function(sectionVO)
		{
			surveySection=sectionVO;
		},
		getSurveySection:function()
		{
			return surveySection;
		},
		setSurveySectionQuestion:function(questionVO)
		{
			selectedQuestion=questionVO;
		},
		getSurveySectionQuestion:function()
		{
			return selectedQuestion;
		},
		setTypeOfuser:function(user)
		{
			typeOfuser=user;
		},
		getTypeOfuser:function()
		{
			return typeOfuser;
		},
		setAttachmentList:function(list)
		{
			surveyAttachmentList=list;
		},
		getAttachmentList:function()
		{
			return surveyAttachmentList;

		},
		
		addSurveyParticipantsJSON : function(surveyBaseUuid, userBaseUuids){
			var request = ajaxService.addSurveyParticipantsJson(surveyBaseUuid, userBaseUuids);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		
		changeSurveyDueDateJSON : function(surveyBaseUuid, dueDate){
			var request = ajaxService.changeSurveyDueDate(surveyBaseUuid, dueDate);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});

		},
		
		getDocumentThumbnail : function(attachment){
    		if (attachment.documentThumbnail != undefined) {
    			return "data:image/png;base64," + attachment.documentThumbnail;
    		} else {
    			return imageService.getImageByExtension(attachment.extensionType);
    		}
		},
		
		getSurveyAttachment : function(attachment){
			if(attachment != null){
				repositoryService.openPdfViewer(attachment);
			}
		},
		
		setIsCurrentLoggedInUserOwner : function(isOwner){
			this.isCurrentLoggedInUserOwner = isOwner;
		},
		
		getIsCurrentLoggedInUserOwner : function(){
			return this.isCurrentLoggedInUserOwner;
		},
		
		setSelectedTab : function(tab){
			selectedTab = tab;
		},
		
		getSelectedTab : function() {
			return selectedTab;
		},
		getMySignature: function() {
			var request=ajaxService.getMySignature();
			return request
			.then(function(data) {
				return data.data;
			}, function(data) {
				return null;
			});
		},
		checkHTMLTagErrorInSurveyObj: function(surveyObj) {
			var html_tag_regex = constants.get("HTML_TAG_REGEX");
			if(surveyObj.title != undefined && surveyObj.title.search(html_tag_regex) != -1 ||
					surveyObj.instructions != undefined && surveyObj.instructions.search(html_tag_regex) != -1 ) {
				return true;
			}
			return false;
		},
		checkHTMLTagErrorInSurveySectionObj: function(section) {
			var html_tag_regex = constants.get("HTML_TAG_REGEX");
			if(section.sectionName != undefined && section.sectionName.search(html_tag_regex) != -1 ||
					section.instructions != undefined && section.instructions.search(html_tag_regex) != -1 ) {
				return true;
			}
			return false;
		},
		checkHTMLTagErrorInSurveyQuestionObj: function(question) {
			var html_tag_regex = constants.get("HTML_TAG_REGEX");
			if(question.instructions != undefined && question.instructions.search(html_tag_regex) != -1 ) {
				return true;
			}
			if(question.questionType == 'multiplechoice' || question.questionType == 'multiplechoicemultiresponse'){
				if(question.multipleChoiceValues != undefined){
					for(var i=0;i<question.multipleChoiceValues.length;i++){
						if(question.multipleChoiceValues[i].name.search(html_tag_regex) != -1)
							return true;
					}
				}
			}
			if(question.questionType == 'likertscale'){
				if(question.likertScaleValues != undefined){
					for(var i=0;i<question.likertScaleValues.length;i++){
						if(question.likertScaleValues[i].name.search(html_tag_regex) != -1)
							return true;
					}
				}
			}
			return false;
		},
		publishCBESurvey: function(surveyUuid) {
			var request = ajaxService.publishCBESurvey(surveyUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		sendAletToCBEParticipants: function(surveyUuid) {
			var request = ajaxService.sendAletToCBEParticipants(surveyUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		getSurveyWithPagination: function(category, page, pageSize, sortColumn, sortDirection) {
			var request=ajaxService.getSurveyWithPagination(category, page, pageSize, sortColumn, sortDirection);
			return request.then(function(response) {
				if (response) {
					return response;
				}
				return null;
			});
		},
	}

	return service;
}

angular
    .module('surveys.service', ['ajax.service', 'surveysAlertService.factory'])
    .factory('surveysService',[ '$q', 'ajaxService', 'responseValidatorService', 'imageService', 'repositoryService', 'constants', 'localizedMessages', surveysService ]);
	