bvDialog.$inject = ['bvDialogService', 'templatesService', 'commonService', 'legacyUserService'];

function bvDialog(bvDialogService, templatesService, commonService, legacyUserService){

	var service = {

		support : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-support',
				buttons : null,
				controller : 'supportController',
				bodyTemplate : 'modules/home/Support.tpl.html'
			});
		},
		
		userCreateConfirm : function(continueTxt, cancelTxt, actionTrue, actionFalse, title){
			bvDialogService.showBvModal({
				windowClass : 'submit-selfordering',				
				controller : 'createUserSubmitController',
				bodyTemplate : 'modules/home/CreateUserConfirm.tpl.html',				
				showHeader: true,
				buttons : [{name:cancelTxt,action:actionFalse},{name:continueTxt,action:actionTrue}],
				inputs : {
					actionTrue : actionTrue,
					actionFalse : actionFalse,
					title : title
				 },
			});
		},		

		preferences : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-preferences',
				buttons : null,
				controller : 'preferencesController',
				bodyTemplate : 'modules/home/Preferences.tpl.html'
			});
		},

		openChooseLanguage : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-lang',
				controller : 'languageController',
				bodyTemplate : 'modules/home/Language.tpl.html'
			});	
		},

		changePassword : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-change-password',
				controller : 'changePasswordController',
				bodyTemplate : 'modules/home/ChangePassword.tpl.html'
			});
		},

		followFollowMe : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-follow-followme',
				controller : 'followDialogController',
				bodyTemplate : 'modules/home/FollowFollowMe.tpl.html',
				buttons : null
			});
		},
		
		showSetFollowers : function (docName, docId, subscribedUsers)
		{
			bvDialogService.showBvModal({
				windowClass  : 'bv-set-follower',
				controller   : 'setFollowersController',
				bodyTemplate : 'modules/dialogs/followers/setfollowers.tpl.html',
				inputs       :  {
					docName: docName,
					docId: docId,
					object: subscribedUsers
				}
			});
		},

		changeChallengeQuestions : function(isMobile){
			bvDialogService.showBvModal({
				windowClass : 'bv-challenge-questions',
				controller : 'challengeQuestionController',
				bodyTemplate : 'modules/home/ChallengeQuestions.tpl.html',
				mobileActionButton: isMobile,
				showClose : isMobile,
				inputs : {
					isMobile : isMobile,
					mobileActionButtonImagePath : 'resources/assets/images/icon_save.png'
				}
			});
		},

		selectVirtualMeetingConfiguration : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-virtual-meeting-configuration',
				controller : 'virtualMeetingConfigurationController',
				bodyTemplate : 'modules/dialogs/VirtualMeetingConfiguration.tpl.html',
				buttons : null
			});
		},
		
		showProperties : function(businessObject){
			var showBackdrop = true;
			if(businessObject.isPresentMode)
			{
				showBackdrop = false;
			}
			bvDialogService.showBvModal({
				// windowClass : 'bv-modal-window-properties',
				windowClass : 'bv-doc-prop',
				controller : 'propertiesController',
				bodyTemplate : 'modules/dialogs/Properties.tpl.html',
				inputs : {															
					businessObject:businessObject					
				},
				buttons : null,
				backdrop : showBackdrop
			});
		},

		siteBranding : function(){
			bvDialogService.showBvModal({
				windowClass : 'brandingDialog',
				controller : 'brandingController',
				bodyTemplate : templatesService.getUrl('Branding'),
			});
		},
		
		openManageSurveys : function(surveysList){
			bvDialogService.showBvModal({
				windowClass : 'bv-survey-manage-surveys-modal',
				controller : 'manageSurveysController',
				bodyTemplate : 'modules/surveys/ManageSurveys.tpl.html',
				inputs : {
					surveysList : surveysList
				 },
				buttons: null
			});
		},
		
		surveyAdminPreview : function(selectedContextItem, getMyOpenSurveys, getMyClosedSurveys, getMyOwnedSurveys, activeTab, currentPage){
			bvDialogService.showBvModal({
				windowClass : 'bv-survey-admin-preview-modal',
				controller : 'surveyAdminPreviewController',
				bodyTemplate : 'modules/surveys/AdminPreview.tpl.html',
				inputs : {
					selectedContextItem : selectedContextItem, 
					getMyOpenSurveys : getMyOpenSurveys,
					getMyClosedSurveys : getMyClosedSurveys,
					getMyOwnedSurveys : getMyOwnedSurveys,
					activeTab: activeTab,
					currentPage: currentPage
				 },
				buttons: null
			});
		},
		
		cbeAdminPreview : function(selectedContextItem){
			bvDialogService.showBvModal({
				windowClass : 'bv-cbe-stutas-preview-modal',
				controller : 'cbeAdminPreviewController',
				bodyTemplate : 'modules/surveys/CBEAdminPreview.tpl.html',
				inputs : {
					cbeQuestionnaire : selectedContextItem
				 },
				buttons: null
			});
		},
		
		showRespondentView : function(title, surveyDetails){
			bvDialogService.showBvModal({
				windowClass : 'bv-survey-create-preview-modal',
				controller : 'OpenSurveyController',
				bodyTemplate : 'modules/surveys/ShowRespondentView.tpl.html',
				inputs : {
					surveyDetails : surveyDetails,
					title : title
				 },
				buttons: null
			});
		},
		
		showRespondentCBEView : function(cbeQuestionnaire){
			bvDialogService.showBvModal({
				windowClass : 'bv-cbe-create-preview-modal',
				controller : 'openCBEController',
				bodyTemplate : 'modules/surveys/ShowRespondentCBEView.tpl.html',
				inputs : {
					cbeQuestionnaire : cbeQuestionnaire,
				 },
				buttons: null
			});
		},
		
		userAnswersPreview : function(userResponses, surveyDetailsForPreview, canEditResponse, objBaseUuid,userName){
			bvDialogService.showBvModal({
				windowClass : 'bv-survey-userAnsweres-preview-modal',
				controller : 'userAnswersPreviewController',
				bodyTemplate : 'modules/surveys/UserSurveyPreview.tpl.html',
				inputs : {
					userResponses : userResponses,
					surveyDetailsForPreview : surveyDetailsForPreview,
					canEditResponse : canEditResponse,
					objBaseUuid : objBaseUuid,
					userName : userName
				 },
				buttons: null
			});
		},
		
		sendSurveyAlertTo: function(surveyDetails){
			bvDialogService.showBvModal({
				windowClass : 'bv-survey-alert-to-modal',
				controller : 'sendSurveyAlertToController',
				bodyTemplate : 'modules/surveys/ParticipantsList.tpl.html',
				inputs : {
					surveyDetails : surveyDetails
				 },
				buttons: null
			});
		},
		
		sendSurveyAlertToParticipants : function(surveyDetails, sendAlertUsersList){
			bvDialogService.showBvModal({
				windowClass : 'bv-survey-alert-modal',
				controller : 'sendSurveyAlertController',
				bodyTemplate : 'modules/surveys/ComposeMail.tpl.html',
				inputs : {
					surveyDetails : surveyDetails,
					sendAlertUsersList : sendAlertUsersList
				 },
				buttons: null
			});
		},
		
		copySurvey : function(copySurveyTitle, CopySurveyRadio, copySurveyObjectBaseUuid){
			bvDialogService.showBvModal({
				windowClass : 'bv-copy-survey-modal',
				controller : 'copySurveyController',
				bodyTemplate : 'modules/surveys/CopySurvey.tpl.html',
				inputs : {
					copySurveyTitle : copySurveyTitle,
					CopySurveyRadio : CopySurveyRadio,
					copySurveyObjectBaseUuid : copySurveyObjectBaseUuid
				 },
				buttons: null
			});
		},
		
		selectRecipientsForPrefillSurvey : function(recipients, surveyObjectBaseUuid){
			bvDialogService.showBvModal({
				windowClass : 'prefill-survey',
				controller : 'prefillSurveyController',
				bodyTemplate : 'modules/dialogs/prefillingsurvey/prefillsurvey.tpl.html',
				inputs : {
					recipients : recipients,
					surveyObjectBaseUuid : surveyObjectBaseUuid
				 }
			});
		},
		
		showSurveyReports : function(surveyDetails, data, currentUserVO){
			bvDialogService.showBvModal({
				windowClass : 'bv-report-modal',
				controller : 'surveyReportsController',
				bodyTemplate : !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/SurveyReport.tpl.html' : 'modules/surveys/SurveyReport.tpl.html',
				inputs : {
					surveyDetails : surveyDetails,
					data : data,
					currentUserVO : currentUserVO
				 },
				buttons: null
			});
		},
		
		showPrintableReports : function(surveyDetails, currentUserVO, barData, pieData, respondentsCountForPrint, printOptions, signaturesImg, participantSignatureResponseMap){
			bvDialogService.showBvModal({
				windowClass : 'bv-printable-reports-modal',
				controller : 'surveyPrintableReportsController',
				bodyTemplate : !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/PrintableReports.tpl.html' : 'modules/surveys/PrintableReports.tpl.html',
				inputs : {
					surveyDetails : surveyDetails,
					currentUserVO : currentUserVO,
					barData : barData,
					pieData : pieData,
					respondentsCountForPrint : respondentsCountForPrint,
					printOptions : printOptions,
					signaturesImg : signaturesImg,
					participantSignatureResponseMap: participantSignatureResponseMap
				 },
				buttons: null
			});
		},

		permissions : function(object){
			bvDialogService.showBvModal({
				windowClass : 'bv-permissions',
				showRight : true,
				inputs : { object: object },
				controller : 'permissionsController',
				bodyTemplate : templatesService.getUrl('Permissions')
			});
		},
		
		moduleCreators : function(moduleCreatorsList){
			bvDialogService.showBvModal({
				windowClass : 'bv-approval-user-option',
				showRight : true,
				controller : 'moduleCreatorsController',
				bodyTemplate : 'modules/home/ModuleCreators.tpl.html',
				inputs : {
					moduleCreatorsList : moduleCreatorsList
					},
				buttons : null
			});
		},
		
		confirmNoBackdrop : function(questionText, actionTrue, actionFalse){
			bvDialogService.showBvModal({
				windowClass : 'bv-confirm',
				controller : 'confirmController',
				bodyTemplate : 'modules/dialogs/Confirm.tpl.html',
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				showClose : !legacyUserService.isNasdaqUI ? false : true,
				backdrop: false,
				inputs : {
							questionText : questionText,
							actionTrue : actionTrue,
							actionFalse : actionFalse
						 }
			});
		},

		confirm : function(questionText, actionTrue, actionFalse, confirmBox, popupTitle){
			this.confirmDialog(questionText, actionTrue, actionFalse, confirmBox, popupTitle);
		},

		confirmSupportHTMLTags : function(questionText, actionTrue, actionFalse, confirmBox, popupTitle){
			this.confirmDialog(questionText, actionTrue, actionFalse, confirmBox, popupTitle, true);
		},

		confirmDialog : function(questionText, actionTrue, actionFalse, confirmBox, popupTitle, htmlText){
			bvDialogService.showBvModal({
				windowClass : 'bv-confirm',
				controller : 'confirmController',
				bodyTemplate : 'modules/dialogs/Confirm.tpl.html',
				confirmBox : confirmBox,
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				showClose : !legacyUserService.isNasdaqUI ? false : true,
				inputs : {
							questionText : questionText,
							actionTrue : actionTrue,
							actionFalse : actionFalse,
							popupTitle : popupTitle,
							htmlText: htmlText
						 }
			});
		},
		
		
		confirmDialogForStampedSign : function(questionText, actionTrue,confirmBox ,popupTitle){
			bvDialogService.showBvModal({
				windowClass : 'bv-confirm bv-confirm-custom-button-signature',
				controller : 'confirmController',
				bodyTemplate : 'modules/dialogs/Confirm.tpl.html',
				confirmBox : confirmBox,
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				showClose : !legacyUserService.isNasdaqUI ? false : true,
				inputs : {
							questionText : questionText,
							actionTrue : actionTrue,						
							popupTitle : popupTitle							
						 }
			});
		},
		
		inputDialogForBankId : function(cancelTxt, sendTxt, questionText, actionTrue, actionFalse, confirmBox, popupTitle, htmlText){
			bvDialogService.showBankIdTokenPopup({
				windowClass : 'bv-confirm bv-confirm-custom-button bankid',
				controller : 'confirmBankIdController',
				bodyTemplate : 'modules/dialogs/ConfirmDialogForBankId.tpl.html',
				confirmBox : confirmBox,
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				showClose : !legacyUserService.isNasdaqUI ? false : true,
				actionTrue : actionTrue,
				actionFalse: actionFalse,
				inputs : {
							questionText : questionText,
							popupTitle : popupTitle
						 }
			});
		},

	confirmWithCustomButton : function(cancelTxt, sendTxt, questionText, actionTrue, actionFalse, confirmBox, popupTitle){
		bvDialogService.showBvModal({
			windowClass : 'bv-confirm bv-confirm-custom-button',
			controller : 'confirmController',
			bodyTemplate : 'modules/dialogs/Confirm.tpl.html',
			confirmBox : confirmBox,
			showHeader : !legacyUserService.isNasdaqUI ? false : true,
			showClose : !legacyUserService.isNasdaqUI ? false : true,
			inputs : {
						questionText : questionText,
						popupTitle : popupTitle
					 },
		  buttons : [{name:cancelTxt,action:actionFalse },{name:sendTxt,action:actionTrue}]
		});
    },
    confirmWithCustomButtonSignature: function(cancelTxt, sendTxt, questionText, actionTrue, actionFalse, confirmBox, popupTitle){
      bvDialogService.showBvModal({
				windowClass : 'bv-confirm bv-confirm-custom-button signature-commit-dialog',
				controller : 'confirmController',
				bodyTemplate : 'modules/dialogs/Confirm.tpl.html',
				confirmBox : confirmBox,
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				showClose : !legacyUserService.isNasdaqUI ? false : true,
				inputs : {
							questionText : questionText,
							popupTitle : popupTitle
						 },
			  buttons : [{name:cancelTxt,action:actionFalse },{name:sendTxt,action:actionTrue}]
			});
    },

		openFolderChooser : function(popupTitle, selectionDone, items, parentContainer){
			bvDialogService.showBvModal({
				windowClass : 'bv-item-list-dialog folder-attachment-dialog',
				controller : 'folderChooserController',
				bodyTemplate : 'modules/dialogs/FolderChooser.tpl.html',
				inputs : {
					popupTitle: popupTitle,					
					selectionDone: selectionDone,
					items:items,
					parentContainer:parentContainer
				}
			});
		},
		
		openFolderChooserRestore : function(popupTitle, selectionDone, items, parentContainer){
			bvDialogService.showBvModal({
				windowClass : 'bv-item-list-dialog folder-attachment-dialog',
				controller : 'folderChooserController',
				bodyTemplate : 'modules/dialogs/FolderChooser.tpl.html',
				inputs : {
					popupTitle: popupTitle,					
					selectionDone: selectionDone,
					items:items,
					parentContainer:parentContainer,
					restore: true
				}
			});
		},
		
		showVersionTracker : function(businessObject){
			bvDialogService.showBvModal({
				windowClass : 'bv-version-tracker-dialog',
				controller : 'versionTrackerController',
				bodyTemplate : 'modules/dialogs/VersionTracker.tpl.html',
				inputs : {															
					businessObject:businessObject					
				},
				buttons : null
			});
		},

		showRules : function (selectedObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-rules-dialog',
				controller : 'rulesController',
				buttons      : null,
				bodyTemplate : 'modules/dialogs/Rules.tpl.html',
				inputs : {															
					selectedObject:selectedObject					
				}
			});
		},
		
		showPush : function (selectedObject, objectType, selectedUsers)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-push-dialog',
				controller : 'CreatePushController',
				buttons : [{},{}],
				bodyTemplate : 'modules/push/CreatePush.tpl.html',
				inputs : {															
					selectedObject:selectedObject,
					objectType:objectType,
					selectedUsers:selectedUsers,
				}
			});
		},
		
		showPushDetails : function (selectedObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-push-detail-dialog',
				controller : 'PushDetailController',
				buttons : null,
				bodyTemplate : 'modules/push/PushDetails.tpl.html',
				inputs : {															
					selectedObject:selectedObject					
				}
			});
		},
		showPushDetailsToUser : function (selectedObject, fromRecipient)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-push-detail-user-dialog',
				controller : 'PushDetailController',
				buttons : null,
				bodyTemplate : 'modules/push/PushDetailsToUser.tpl.html',
				inputs : {															
					selectedObject:selectedObject,
					fromRecipient:fromRecipient
				}
			});
		},
		
		showRemovePush : function (selectedObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-remove-push-dialog',
				controller : 'PushRemoveController',
				buttons : [{},{}],
				bodyTemplate : 'modules/push/RemovePush.tpl.html',
				inputs : {															
					selectedObject:selectedObject					
				}
			});
		},

		bookmarkPage: function(pdfDetails, pageNumber){
			bvDialogService.showBvModal({
				windowClass : 'viewer-bookmark',
				controller : 'ViewerBookmarkController',
				bodyTemplate : 'modules/dialogs/viewerbookmark/viewerbookmark.tpl.html',
				inputs : {															
					pdfDetails: pdfDetails,
					pageNumber : pageNumber
				}
			});
		},

		createFileFromBO : function(businessObject,selectionDone){
			bvDialogService.showBvModal({
				windowClass : 'bv-create-folder',
				controller : 'createFileFromBOController',
				bodyTemplate : 'modules/dialogs/CreatNewFileName.tpl.html',
				inputs : {															
					businessObject:businessObject,
					selectionDone: selectionDone
				}
			});
		},
		
		showAlertUsers : function(popupTitle, chkAllHeading, items, selectionDone, cancelTxt, sendTxt, cancelSelectionForAlertMsg, saveAlertAction, cancelAlertAction ){
			return this.commonShowAlert(popupTitle, chkAllHeading, items, selectionDone, cancelTxt, sendTxt, cancelSelectionForAlertMsg, saveAlertAction, cancelAlertAction,"","");
		},
		
		showAlertUsersSurvey : function(popupTitle, chkAllHeading, items, selectionDone, cancelTxt, sendTxt, cancelSelectionForAlertMsg, saveAlertAction, cancelAlertAction,fromModule ,isAnonymous){
			return this.commonShowAlert(popupTitle, chkAllHeading, items, selectionDone, cancelTxt, sendTxt, cancelSelectionForAlertMsg, saveAlertAction, cancelAlertAction, fromModule,isAnonymous);
		},
		commonShowAlert : function(popupTitle, chkAllHeading, items, selectionDone, cancelTxt, sendTxt, cancelSelectionForAlertMsg, saveAlertAction, cancelAlertAction,fromModule,isAnonymous ){
			return bvDialogService.showBvModal({
				// windowClass : 'bv-item-list-dialog',
				windowClass : 'bv-alert-item-list-dialog',
				controller : 'itemListController',
				bodyTemplate : 'directives/bvitemlist/bvItemList.template.html',
				inputs : {
					popupTitle: popupTitle,
					selectionDone: selectionDone,
					showUserOptions: "SHOW_CUSTOM_USERS",
					items: items,
					instantSelection: selectionDone,
					showConfirmBeforeCancel : true,
					showCheckAll : true,
					chkAllHeading : chkAllHeading,
					confirmMessageForCancel : cancelSelectionForAlertMsg,
					fromModule:fromModule,
					isAnonymous:isAnonymous
				},
				blackBtnIndex : 0,
				buttons : [{name:cancelTxt,action:cancelAlertAction },{name:sendTxt,action:saveAlertAction}]
			});
		},


		// showUserOptions:
			// SHOW_CURRENT_TS_USERS : showCurrentTsUsers,
			// SHOW_CURRENT_TS_USERS_AND_GRP : showCurrentTsUsersAndGrp,
			// SHOW_ALL_USERS : showAllUsers,
			// SHOW_CUSTOM_USERS : showCustomUsers

		itemListSelection : function(popupTitle, showTsTreeGrid, selectionDone, showUserOptions, showOptionsButtonsForUsers, items, instantSelection,  actionBtnTxt, showConfirmBeforeCancel, confirmMessageForCancel ){
			bvDialogService.showBvModal({
				windowClass : 'bv-alert-item-list-dialog',
				controller : 'itemListController',
				bodyTemplate : 'directives/bvitemlist/bvItemList.template.html',
				inputs : {
					popupTitle: popupTitle,
					showTsTreeGrid: showTsTreeGrid,
					selectionDone: selectionDone,
					showUserOptions: showUserOptions,
					showOptionsButtonsForUsers: showOptionsButtonsForUsers,
					items: items,
					instantSelection: instantSelection,
					actionBtnTxt: actionBtnTxt,
					showConfirmBeforeCancel : showConfirmBeforeCancel,
					confirmMessageForCancel : confirmMessageForCancel
				}
			});
		},
		
		addAnnotationSharedUserSelection : function(popupTitle, showTsTreeGrid, selectionDone, showUserOptions, smartSelectionOptions, instantSelection, items, noUserPermittedMsg){
			bvDialogService.showBvModal({
				windowClass : 'bv-alert-item-list-dialog',
				controller : 'itemListController',
				bodyTemplate : 'directives/bvitemlist/bvItemList.template.html',
				inputs : {
					popupTitle: popupTitle,
					showTsTreeGrid: showTsTreeGrid,
					selectionDone: selectionDone,
					showUserOptions: showUserOptions,
					smartSelectionOptions: smartSelectionOptions,
					instantSelection: instantSelection,
					items: items,
					noItemsMsg: noUserPermittedMsg,
				},
				buttons : null
			});
		},

		inviteUserSelection : function(popupTitle, items, selectionDone){
			bvDialogService.showBvModal({
				// windowClass : 'bv-alert-item-list-dialog',
				windowClass : 'inviteUserSelectionDialog',
				controller : 'itemListController',
				bodyTemplate : 'directives/bvitemlist/bvItemList.template.html',
				backdrop: false,
				inputs : {
					popupTitle: popupTitle,
					showTsTreeGrid: false,
					selectionDone: selectionDone,
					showUserOptions: "SHOW_CUSTOM_USERS",
					items: items,
				}
			});
		},
		
		inviteUserSelectionSurvey : function(popupTitle, items, selectionDone){
			bvDialogService.showBvModal({
				windowClass : 'inviteUserSelectionDialog survey',
				controller : 'itemListController',
				bodyTemplate : 'directives/bvitemlist/bvItemList.template.html',
				backdrop: false,
				inputs : {
					popupTitle: popupTitle,
					showTsTreeGrid: false,
					selectionDone: selectionDone,
					showUserOptions: "SHOW_CUSTOM_USERS",
					items: items,
				}
			});
		},

		showUserProfile : function(selectedUser, followUnFollow) {
			bvDialogService.showBvModal({
				windowClass : 'userProfileDialog',
				controller : 'UserProfileController',
				bodyTemplate : 'modules/team/UserProfile.tpl.html',
				templateCustomButtonsUrl : 'modules/team/headerButtons.tpl.html',
				inputs : {
					selectedUser: selectedUser,
					followUnFollow: followUnFollow
				},
				buttons : null
			});
		},

		editEmailWizard: function(cancelTxt, cancelAction, nextText, nextAction) {

			return bvDialogService.showBvModal({
				windowClass : 'editEmailWizardDialog',
				controller : 'editEmailWizardController',
				bodyTemplate : 'modules/team/editEmailWizard.tpl.html',
				showClose : true,
				showHeader : true,
				buttons : [{name:cancelTxt,action:cancelAction },{name:nextText,action:nextAction }]
			});
		},



		
		inviteUsers: function() {
			bvDialogService.showBvModal({
				windowClass : 'inviteUserDialog',
				controller : 'InviteUserController',
				bodyTemplate : 'modules/team/InviteUser.tpl.html'
			});

		},

		recycle : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-recycle',
// backdropClass : 'bv-recycle-back',
				controller : 'RecycleController',
				bodyTemplate : 'modules/home/Recycle.tpl.html',
				buttons : [{}],
// backdrop: false
			});
		},
		
		showMessage : function(questionText, windowClass, autoclose, cb, htmlText){
			
			var bvModalWindowClass = (windowClass) ? 'bv-show-message bv-confirm ' + windowClass : 'bv-show-message bv-confirm';

			var bvModal = bvDialogService.showBvModal({
				windowClass : bvModalWindowClass,
				controller : 'showMessageController',
				bodyTemplate : 'modules/dialogs/Confirm.tpl.html',
				buttons : null,
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
        autoclose : autoclose,
        callback: cb,
				inputs : {
          questionText : questionText,
          callback: cb,
          htmlText: htmlText
				}
			});
			return bvModal;
		},
		
		showMessageIdle : function(actions){
			bvDialogService.showBvModal({
				windowClass : 'bv-show-message bv-confirm',
				controller : 'showMessageIdleController',
				bodyTemplate : 'modules/dialogs/ShowMessageIdle.tpl.html',
				buttons : actions,
				showClose : !legacyUserService.isNasdaqUI ? false : true,
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				backdrop: 'static'
			});
		},
		
		createFolder : function(objectBaseUuid, actionTrue){
			bvDialogService.showBvModal({
				windowClass : 'bv-create-folder',
				controller : 'createFolderController',
				bodyTemplate : 'directives/addfolder/addfolder.template.html',
				inputs : { 
					objectBaseUuid : objectBaseUuid,
					actionTrue : actionTrue,
					enableActionValidation : true
				}
			});
		},
		
		createAgenda : function(actionTrue){
			bvDialogService.showBvModal({
				windowClass : 'bv-create-folder',
				controller : 'createAgendaController',
				bodyTemplate : 'directives/addfolder/addfolder.template.html',
				inputs : { 
					actionTrue : actionTrue
				}
			});
		},
		
		addToNewItems: function(fileAttachments) {
			bvDialogService.showBvModal({
				windowClass: 'addToNewItemsDialog',
				controller: 'AddToNewItemsController',
				bodyTemplate : 'modules/newitems/AddToNewItems.tpl.html',
				inputs: {
					fileAttachments: fileAttachments
				},
				buttons : [{},{}]
			});
		},
		
		rename : function(object, actionTrue){
			bvDialogService.showBvModal({
				windowClass : 'bv-rename',
				controller : 'renameController',
				bodyTemplate : 'directives/addfolder/addfolder.template.html',
				inputs : { 
					object : object,
					actionTrue : actionTrue,
					enableActionValidation : true
				}
			});
		},
		
		copyLink : function(link){
			bvDialogService.showBvModal({
				windowClass : 'bv-copy-link',
				controller : 'copyLinkController',
				bodyTemplate : 'modules/dialogs/CopyLink.tpl.html',
				inputs : { 
					link : link,
				},
				buttons : null
			});
		},

		showMeetingDetails : function(selectedEvent){
			bvDialogService.showBvModal({
				windowClass  : 'bv-conf-details',
				buttons      : null,
				controller   : 'showMeetingDetailsController',
				bodyTemplate : 'modules/conference/ConfDetails.tpl.html',
				inputs       :  {
									selectedEvent: selectedEvent
								}
			});
		},

		showMeetingAttendees : function(selectedEvent){
			bvDialogService.showBvModal({
				windowClass  : 'bv-conf-attendee',
				buttons      : null,
				controller   : 'showMeetingAttendeesController',
				bodyTemplate : 'modules/conference/ConfPeople.tpl.html',
				inputs       :  {
									selectedEvent: selectedEvent
								}
			});
		},
		
		showMeetingTrackAttendees : function(selectedEventId){
			bvDialogService.showBvModal({
				windowClass  : 'bv-meeting-track-attendees',
				buttons      : null,
				controller   : 'ShowMeetingTrackAttendeesController',
				bodyTemplate : 'modules/meetings/AttendeesTrackList.tpl.html',
				inputs       :  {
								selectedEvent: selectedEventId
								}
			});
		},

		showMeetingDetailsPanel: function (selectedEvent, selectDetailsTab, showFullEventBtn) {
		  bvDialogService.showBvModal({
		    windowClass: 'bv-conf-details-panel',
		    backdropClass: 'bv-conf-details-panel',
		    controller: 'detailsPanelController',
		    bodyTemplate: 'modules/meetings/detailspanel/detailspanel.tpl.html',
		    buttons: null,
		    backdrop: true,
		    inputs: {
		      selectedEvent: selectedEvent,
		      selectDetailsTab: selectDetailsTab,
		      showFullEventBtn: showFullEventBtn
		    }
		  });
		},

		showUserManagementToolkitMeetings : function(meetingCenterObject){
			bvDialogService.showBvModal({
				windowClass  : 'bv-user-management-toolkit',
				controller   : 'userManagementToolkitControllerMeetings',
				bodyTemplate : 'modules/conference/UserManagementToolkit.tpl.html',
				inputs       :  {
					meetingCenterObject: meetingCenterObject
				}
			});
		},

		showUserManagementToolkitSurveys : function(surveyObject, cb){
			bvDialogService.showBvModal({
				windowClass  : 'bv-user-management-toolkit',
				controller   : 'userManagementToolkitControllerSurveys',
				bodyTemplate : 'modules/conference/UserManagementToolkit.tpl.html',
				inputs       :  {
					surveyObject: surveyObject,
					cb: cb
				}
			});
		},
		showUserManagementToolkitCBEs : function(cbe, cb){
			bvDialogService.showBvModal({
				windowClass  : 'bv-user-management-toolkit',
				controller   : 'userManagementToolkitControllerCBEs',
				bodyTemplate : 'modules/conference/UserManagementToolkit.tpl.html',
				inputs       :  {
					cbeObject: cbe,
					cb: cb
				}
			});
		},
		
		showUserManagementToolkitApprovals : function(approvalObject){
			bvDialogService.showBvModal({
				windowClass  : 'bv-user-management-toolkit',
				controller   : 'userManagementToolkitControllerApprovals',
				bodyTemplate : 'modules/conference/UserManagementToolkit.tpl.html',
				inputs       :  {
					approvalObject: approvalObject
				}
			});
		},

		showMeetingSettings : function(events, calendarDate, currentTab){
			bvDialogService.showBvModal({
				windowClass  : 'bv-conf-settings',
				buttons      : null,
				controller   : 'showMeetingSettingsController',
				bodyTemplate : 'modules/conference/ConfSettings.tpl.html',
				inputs       :  {
									events: events,
									calendarDate: calendarDate,
									currentTab: currentTab
								}
			});
		},
		
		showApprovalSettings : function(approvals, refreshData, promise){
			bvDialogService.showBvModal({
				windowClass  : 'bv-conf-settings bv-conf-approvals-settings',
				buttons      : null,
				controller   : 'showApprovalSettingsController',
				bodyTemplate : 'modules/conference/ConfSettings.tpl.html',
				inputs       :  {
					approvals: approvals,
					refreshData: refreshData,
					promise: promise
				}
			});
		},

		showConfDialog : function() {
			bvDialogService.showBvModal({
				windowClass : 'bv-event-presentation',
				backdropClass: 'bv-event-presentation-overflow',
				buttons : null,
				showClose : false,
				keyboard : false,
				backdrop: 'static',
				controller : 'EventPresentationController',
				bodyTemplate : 'modules/conference/EventPresentation.tpl.html'
			});
		},

		showRunningConfsListDialog : function(calEvent) {
			bvDialogService.showBvModal({
				windowClass : 'bv-running-conf-list-dialog',
				buttons : null,
				showClose : !legacyUserService.isNasdaqUI ? false : true,
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				keyboard : false,
				backdrop : false,
				controller : 'RunningConfListController',
				bodyTemplate : 'modules/conference/RunningConfList.tpl.html',
				inputs : {
					calEvent : calEvent
				}
			});
		},
		
		eventsDialog : function(event, cell, index){
			bvDialogService.showBvModal({
				windowClass  : 'bv-events',
				buttons      : null,
				controller   : 'eventsDialogController',
				bodyTemplate : 'modules/calendar/EventsDialog.tpl.html',
				inputs       :  {
					event: event,
					cell: cell,
					index: index
				},
				backdropClass: 'bv-events-backdrop',
				showHeader : false,
				showClose : false
			});
		},
		
		showAdminAuthentication : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-admin-auth',
				controller : 'adminAuthenticationController',
				bodyTemplate : 'modules/home/AdministratorAuthentication.tpl.html'
			});
		},

		showDocumentPreview : function(docId,docObj){
			// console.log("DOC OBJ:",docObj);
			if (!docObj || (docObj && !docObj.documentThumbnail && !docObj.encodedThumbnail) )
			{
				commonService.getDocumentThumbnail(docId, docId).then(function(data) 
	 			{
	 				docObj.documentThumbnail = data;
	 				bvDialogService.showBvModal({
						windowClass : 'bv-doc-preview-portrait',
						buttons      : null,
						controller : 'bvDocPreviewController',
						bodyTemplate : 'modules/home/DocPreview.tpl.html',			
						inputs       :  {
											docObj: docObj
										}

					});
	 			});
			}
			else 
			{
				bvDialogService.showBvModal({
					windowClass : 'bv-doc-preview-portrait',
					buttons      : null,
					controller : 'bvDocPreviewController',
					bodyTemplate : 'modules/home/DocPreview.tpl.html',			
					inputs       :  {
										docObj: docObj
									}

				});
			}
		},
		
		modulesSettings : function() {
			bvDialogService.showBvModal({
				windowClass : 'bv-modules-settings',
				controller : 'modulesSettingsController',
				bodyTemplate :  !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/modulesSettings.tpl.html' : 'modules/home/modulesSettings.tpl.html'
			});

		},

		openActiveWindows : function(){
			bvDialogService.showBvMinimizeModal({
				windowClass : 'active-windows',
				backdropClass : 'active-windows-back', 
				controller : 'activeWindowsController',
				bodyTemplate : 'modules/home/ActiveWindows.tpl.html',
				buttons : null
			});
		},
		
		settingsMobileDialog : function(){
			bvDialogService.showBvModal({
				windowClass : 'bv-settings-mobile',
				controller : 'settingsMobileController',
				bodyTemplate : 'modules/home/SettingsMobile.tpl.html',
				buttons : null
			})
		},
		
		showAuditTrail : function (businessObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-audit-trail-dialog',
				controller : 'AuditTrailController',
				buttons      : null,
				bodyTemplate : 'modules/repository/AuditTrail.tpl.html',
				inputs : {															
					businessObject:businessObject					
				}
			});
		},
		
		addToUserFavorites : function (businessObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-addToUserFavorites-dialog',
				controller : 'UserFavoritesController',
				buttons      : [{},{}],
				blackBtnIndex : 0,
				bodyTemplate : 'modules/repository/AddToUserFavorites.tpl.html',
				inputs : {															
					businessObject:businessObject					
				},
			});
		},
		
		printApprovalOptions : function(onSave) {
			bvDialogService.showBvModal({
				windowClass : 'bv-print-approval-options',
				controller : 'printApprovalOptionsController',
				bodyTemplate : 'modules/approvals/PrintApprovalOptions.tpl.html',
				showClose : true,
				inputs : {
					onSave: onSave
				}
			});
		},

		showFolderAgendaCreator : function(objectBaseUuid, parentObjectBaseUuid) 
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-modal-window-folder-agenda-creator',
				controller : 'FolderAgendaCreatorController',
				bodyTemplate : 'modules/repository/FolderAgendaCreator.tpl.html',
				showClose : true,
				inputs : {
					objectBaseUuid: objectBaseUuid,
					parentObjectBaseUuid: parentObjectBaseUuid,
				}
			});
		},
		
		printApproval : function (options)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-print-approval',
				controller : 'ApprovalDetailsDirectiveController',
				bodyTemplate : 'modules/approvals/PrintApprovalDetails.tpl.html',
				inputs : {		
					options: options
				},
				buttons: null,
				showClose : true
			});
		},

		showAddComments : function (onSave, comments, limit)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-add-comments',
				controller : 'addCommentsController',
				bodyTemplate : 'modules/approvals/AddComments.tpl.html',
				inputs : {		
					onSave : onSave,													
					comments : comments,
					limit : limit
				},
			});
		},

		showAttendeesOption : function(meetingCenterObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-meeting-attendee-option',
				controller : 'showMeetingAttendeesSettingController',
				buttons      : null,
				bodyTemplate : 'modules/meetings/AttendeesDetails.tpl.html',
				inputs : {															
					meetingCenterObject : meetingCenterObject
				},
			});
		},
		
		showApprovalUsersOption : function(approvalObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-approval-user-option',
				controller : 'showApprovalUsersController',
				buttons      : null,
				bodyTemplate : 'modules/approvals/UsersDetails.tpl.html',
				inputs : {															
					approvalObject : approvalObject
				},
			});
		},
		
		showSurveyUsersOption : function(surveyObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-survey-user-option',
				controller : 'showSurveyUsersController',
				buttons      : null,
				bodyTemplate : 'modules/surveys/UsersDetails.tpl.html',
				inputs : {															
					surveyObject : surveyObject
				},
			});
		},

		showBriefcaseInstallations : function(active, purged)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-briefcase-list',
				controller : 'briefcaseInstallationsController',
				bodyTemplate : 'modules/briefcase/BriefcaseInstallations.tpl.html',
				inputs : {															
					activeInstallations : active,
					purgedInstallations : purged,
				},
				buttons : null
			});
		},
		
		mergeCompanyDialog : function(isDeviceReg)
		{
			bvDialogService.showBvModal({				
				controller : 'mergeCompanyController',
				bodyTemplate : 'modules/mergecompany/MergeCompany.tpl.html',
				inputs : {															
					isDeviceReg : isDeviceReg					
				},
			});
		},
		
		showMergeProgress : function()
		{
			bvDialogService.showBvModal({				
				controller : 'mergeProgressCtrl',
				bodyTemplate : 'modules/mergecompany/MergeProgress.tpl.html',
				buttons : null
			});
		},
		
		restoringFilesProgressDialog : function(targetFolder, selectedObjects)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-restoring-progress',
				controller : 'restoringProgressDialogController',
				bodyTemplate : 'modules/trash/RestoringProgressDialog.tpl.html',
				inputs : {															
					targetFolder : targetFolder,
					selectedObjects : selectedObjects
				}
			});
		},
		
		showSignatureStatus : function (selectedObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-signature-status-dialog',
				controller : 'SignatureStatusController',
				buttons      : null,
				bodyTemplate : !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/SignatureStatus.tpl.html' : 'modules/signatures/SignatureStatus.tpl.html',
				inputs : {															
					businessObject: selectedObject
				}
			});
		},
		
		showSignatureDialog : function (selectedObject)
		{
			bvDialogService.showBvModal({
				windowClass : 'bv-signature-dialog',
				controller : 'signatureDialogController',
				bodyTemplate : 'modules/surveys/SignatureDialog.tpl.html',
				inputs : {															
					businessObject: selectedObject
				}
			});
		},

		editSignatureDialog : function(cb){
			bvDialogService.editSignatureDialog(cb)
		},

		showContinue : function(questionText, btnTitle, actionTrue){
			bvDialogService.showBvModal({
				windowClass : 'bv-confirm',
				controller : 'confirmController',
				bodyTemplate : 'modules/dialogs/Confirm.tpl.html',
				showHeader : !legacyUserService.isNasdaqUI ? false : true,
				showClose : true,
				buttons : [{ name: btnTitle }],
				inputs : {
							questionText : questionText,
							actionTrue : actionTrue,
						 },
			});
		},

		n3aAuthDialog : function(n3aUserMigrationStatus, callback, closeButton){
			bvDialogService.showBvModal({
				windowClass : 'n3a-auth-dialog',
				controller : 'n3aAuthController',
				bodyTemplate : 'modules/dialogs/n3aauth/n3aAuth.tpl.html',
				buttons : null,
                showHeader : !legacyUserService.isNasdaqUI ? false : true,
                closeButton : closeButton,
                inputs: {
                            n3aUserMigrationStatus : n3aUserMigrationStatus,
                            callback : callback
                        }
			});
		},

		attachDocument: function (onSelectedDone, onlyFiles, enableMultiSelect, popupTitle, permissionCheck) {
		  bvDialogService.showBvModal({
		    windowClass: 'bv-modal-window-new-attachment',
		    controller: 'attachmentsController',
		    bodyTemplate: 'modules/dialogs/attachments/attachments.tpl.html',
		    closeButton: function () {},
		    inputs: {
		      onSelectedDone: onSelectedDone,
		      onlyFiles: onlyFiles,
		      enableMultiSelect: enableMultiSelect,
		      popupTitle: popupTitle,
		      permissionCheck: permissionCheck
		    }
		  });
    },
    
    showMeetingDetailsReact: function (eventUuid,startDate,endDate,uniqDate,onMeetingDeleted = null) {
      bvDialogService.showBvModal({
        windowClass: 'meeting-details-react',
        backdropClass: 'meeting-details-react',
        controller: 'meetingDetailsReactController',
        bodyTemplate: 'modules/meetings/dialogs/meetingdetails/meetingdetails.tpl.html',
        buttons: null,
        backdrop: true,
        showHeader: false,
        showClose : false,
        inputs: {
          eventUuid: eventUuid,
          startDate:startDate,
          endDate:endDate,
          uniqDate:uniqDate,
          onMeetingDeleted:onMeetingDeleted
        }
      });
    },

	showFileuploaderNavigatorReact: function (agendaPdfUuid) {
      bvDialogService.showBvModal({
        windowClass: 'fileuploader-navigator-react-overlay',
        backdropClass: 'fileuploader-navigator-react-overlay',
        controller: 'exportDigitalAgendaController',
        bodyTemplate: 'modules/meetings/viewfullagenda/exportDigitalAgenda.tpl.html',
        buttons: null,
        backdrop: false,
        showHeader: false,
        showClose : false,
        inputs: {
          agendaPdfUuid: agendaPdfUuid
        }
      });
    }
  }

	return service;
}

bvDialogService.$inject = ['$modal', '$modalStack', '$window', 'localizedMessages', '$timeout', 'templatesService', 'legacyUserService', 'context', '$rootScope'];

function bvDialogService(modal, $modalStack, $window, localizedMessages, $timeout, templatesService, legacyUserService, context, $rootScope)
{
	var message = {}
	var openWindows = [];
	var openViewerDialogs = [];
	var service =
	{
		addOpenViewerDialog : function(viewerDialog) {
			openViewerDialogs.push({id : viewerDialog, isOpen: true});
		},
		removeOpenViewerDialog : function(viewerDialog) {
			for(var i=0; i<openViewerDialogs.length; i++)
			{
				if(openViewerDialogs[i].id == viewerDialog)
				{
					openViewerDialogs[i].isOpen = false;
					continue;
				}
					
			}
		},
		closeAllOpenViewerDialogs: function() {
			for(var i=0; i<openViewerDialogs.length; i++)
			{
				var viewerDialog = openViewerDialogs[i].id;
				
				if(openViewerDialogs[i].isOpen && $(viewerDialog).dialog('instance') && $(viewerDialog).dialog('isOpen')) $(viewerDialog).dialog('close');
			}
			openViewerDialogs = [];
		},
		addOpenWindows : function(newWindow) {
			openWindows.push(newWindow);
		},
		openNewWindow : function(url, name, specs) {
			if(url) {
				if(!name) name = "_blank";
				if(!specs) specs = "";
				var newWindow = $window.open(url, name, specs);
				service.addOpenWindows(newWindow);
				return newWindow;
			}
			return null;
		},
		closeNewWindow: function(newWindow) {
			if(newWindow && !openWindow.closed) newWindow.close();
			for(var i=openWindows.length-1; i>=0; i--)
			{
				var openWindow = openWindows[i];
				if(newWindow && newWindow == openWindow && !openWindow.closed) {
					openWindow.close();
					openWindows.splice(i,1);
					continue;
				}
			}			
		},
		closeAllOpenWindows : function() {
			for(var i=0; i<openWindows.length; i++)
			{
				var openWindow = openWindows[i];
				if( openWindow && !openWindow.closed) openWindow.close();
			}
			openWindows = [];
		},
		closeAllOpenDialogs : function() {
			$modalStack.dismissAll();
		},
		closeTopOpenDialog : function(){
			if($modalStack && $modalStack.getTop() && $modalStack.getTop().key)
			{	
				$modalStack.getTop().key.close();
			}
		},
		getShowMessageIdle : function(){
		    return message;
		},
		setShowMessageIdle : function(val){
			message.val = val;
		},
		showMessage : function (message) {
			var msgDialogBox = new modal.open({
            	resolve:   {
                    			message:     function () { return message; },
        						OkBtnTxt: function () { return localizedMessages.get('OK'); }
               			   } ,
                template:  '<div class="modal-dialog" style="height:100%;">'+
    					   	'<div class="modal-content">'+
        							'<div class="bv-blue-msg-dialog-body">'+
            							'<p>{{message.val}}</p>'+
            						'</div>'+
        							'<div class="bv-blue-msg-dialog-footer">'+
        								'<button type="button" class="bv-input-btn" ng-click="close()">{{OkBtnTxt}}</button>'+
            						'</div>'+
        						'</div>'+
						   '</div></div>',
                controller: function ($scope, $modalInstance, message, OkBtnTxt, bvDialogService) {
                	bvDialogService.setMessage(message);
                	$scope.message = bvDialogService.getMessage();
                	
                	$scope.OkBtnTxt = OkBtnTxt;

                	$scope.close = function(result) {
    					$modalInstance.close(result);
  					};
                }
          	});
          	return msgDialogBox;
	    },

        showBvModal : function (bvModalInputs) {
        	var bvModal = new modal.open({
                windowClass : 'dialogs ' + bvModalInputs.windowClass,
                backdropClass : bvModalInputs.backdropClass,
                backdrop : !angular.isUndefined(bvModalInputs.backdrop)? bvModalInputs.backdrop : (!angular.isUndefined(bvModalInputs.showClose) &&  bvModalInputs.showClose  === false) ?   true : 'static',
                keyboard : angular.isUndefined(bvModalInputs.keyboard) ?  ((!angular.isUndefined(bvModalInputs.showClose) &&  bvModalInputs.showClose  === false) ?   true : false) : bvModalInputs.keyboard,
                template:	function(){
                				var buttonStr = '';
                				if(bvModalInputs.confirmBox == true && bvModalInputs.confirmBox != null){
                					buttonStr =
                						'<div class="bv-input-btn-box">'
                						+'		<button class="bv-input-btn" ng-click="actionNo($event)"> {{ closeBtnTxt }} </button>'
                						+'		<button class="bv-input-btn bv-default-background-color-blue" ng-disabled="enableActionBtn != undefined && !enableActionBtn" ng-click="action()"> {{ actionBtnTxt }} </button>'
                						+'</div>'
                				}
                				else if(!('buttons' in bvModalInputs)){
                					buttonStr =
                						'<div class="bv-input-btn-box">'
                						+'		<button class="bv-input-btn" ng-click="close($event)"> {{ closeBtnTxt }} </button>'
                						+'		<button class="bv-input-btn bv-default-background-color-blue" ng-disabled="enableActionBtn != undefined && !enableActionBtn" ng-click="action()"> {{ actionBtnTxt }} </button>'
                						+'</div>'
                				}
                				else if(bvModalInputs.buttons != null)
                				{
                					// console.log(bvModalInputs);
                					// console.log(bvModalInputs.buttons[0]['name']);
                					 buttonStr =
                						 '<div class="bv-input-btn-box">'
                						 +'<span ng-repeat="button in buttons track by $index">'
                						 +'		<button  class="bv-input-btn bv-input-btn-{{ $index }} bv-default-background-color-blue" ng-class="{\'bv-default-background-color-black\' : $index == blackBtnIndex}" ng-click="button.action(bvModalControllerInputs) || action(bvModalControllerInputs)"> {{ button.name }} </button>'
                						 +'</span>'
                						 +'</div>'
                				}
                				return bvModalInputs.template ||
                				'<div class="bv-modal-dialog bv-height-100" bv-dialog-directive bv-modal-controller-inputs="bvModalControllerInputs" controller-dialog="' + bvModalInputs.controller + '">'
                				+'	<div ng-if="showHeader || showClose" ng-class="{ \'bv-modal-blue-header\' : showHeader, \'bv-default-background-color-blue\' : showHeader, \'bv-modal-header-small\' : !showHeader,  \'bv-modal-btn-custom\' : templateCustomButtonsUrl }">'
                				+'		<div class="bv-modal-btn-back" ng-if="showHeader && showBack" ng-tap="back($event)">'
                				+'      	<svg ng-include="showHeader && showBack ? backIcon : null"></svg>'
                				+'		</div>'
                				+'		<div class="bv-modal-btn-custom-box" ng-if="showHeader && templateCustomButtonsUrl" ng-include="templateCustomButtonsUrl"></div>'
                				+'		<div ng-if="mobileActionButton" class="bv-modal-mobile-action-button" ng-tap="action()">'
                				+'			<img ng-src="{{mobileActionButtonImagePath}}">'
                				+'		</div>'
	                			+'		<div ng-if="showHeader" class="bv-width-100" ng-class="{ \'bv-margin-left-30\' : showBack || mobileActionButton, \'bv-modal-btn-custom-header\' : templateCustomButtonsUrl }">'
	        					+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
	        					+'		</div>'
	        					+'		<div ng-if="showClose" class="bv-modal-btn-close ' + ((MobileUtil.isMobileClient() && legacyUserService.isNasdaqUI) ? 'ss-icon ss-standard ss-navigateleft' : '') + '" ng-tap="close($event)">'
	        					+'			<svg ng-include="showHeader && showClose ? closeIcon : (showClose ? closeIconSmall : null)"></svg>'
	        					+'		</div>'
	        					+'		<div ng-if="showMobileMenu" class="bv-mobile-menu">'
			 					+'			<div ng-tap="showMenu = !showMenu" feedback-type="1">'
					 			+'				<svg>'
								+'					<circle r="1.93768" cy="4.71386" cx="4.5714"  />'
								+' 					<circle r="1.93768" cy="15.35132" cx="4.66663" />'
								+' 					<circle r="1.93768" cy="26.03003" cx="4.66663" />'
		   						+'				</svg>'
								+' 			</div>'
	   							+'			<ul class="dropdown-menu" ng-if="showMenu">'
								+'				<li ng-repeat="option in mobileMenu">'
	   							+'					<bt class="bv-display-block" href="" ng-click="option.click()">{{option.name}}</bt>'
	   							+'				</li>'
   								+'			</ul>'
		        				+'		</div>'
		        				+'	</div>'
		        				+'	<div class="bv-modal-body bv-height-100">'
    							+'		<div class="bv-height-100 ' + (MobileUtil.isMobileClient() ? 'bv-scrollbar' : '') + '" ng-include="bodyTemplate"></div>'
								+'	</div>'
		        				+ 	buttonStr
			        			+'</div>'
                },
                controller: function ($scope) {

                	$scope.bodyTemplate  		 	= bvModalInputs.bodyTemplate;
        			$scope.template 			 	= bvModalInputs.template;
        			$scope.showHeader  		 		= bvModalInputs.showHeader != null ? bvModalInputs.showHeader : true;
        			$scope.showClose  		 		= bvModalInputs.showClose != null ? bvModalInputs.showClose : true;
        			$scope.showBack 		 		= bvModalInputs.showBack != null ? bvModalInputs.showBack : false;
        			$scope.showLeft 				= bvModalInputs.showLeft != null ? bvModalInputs.showLeft : false;
        			$scope.showRight 				= bvModalInputs.showRight != null ? bvModalInputs.showRight : false;
        			$scope.actionBtnTxt 			= bvModalInputs.actionBtnTxt;
        			$scope.buttons					= bvModalInputs.buttons;
        			$scope.blackBtnIndex			= bvModalInputs.blackBtnIndex != null ? bvModalInputs.blackBtnIndex : -1;
        			$scope.autoclose				= bvModalInputs.autoclose != null ? bvModalInputs.autoclose : false;
        			$scope.templateCustomButtonsUrl	= bvModalInputs.templateCustomButtonsUrl != '' ? bvModalInputs.templateCustomButtonsUrl : '';
        			$scope.callback	= bvModalInputs.callback != null ? bvModalInputs.callback : false;
              
        			if(bvModalInputs.inputs && "popupTitle" in bvModalInputs.inputs)
        			    $scope.popupTitle = bvModalInputs.inputs.popupTitle;

        			$scope.bvModalControllerInputs = {}
        				
                	if('inputs' in bvModalInputs){
	        			$scope.bvModalControllerInputs	= bvModalInputs.inputs;
                	}
        			
        			if($scope.autoclose === true){
        				$timeout(function(){
        					bvModal.close();
        				},5000);
        			}

                    $scope.bvModalControllerInputs.bvModal = bvModal;
                    $scope.bvModalControllerInputs.buttons = bvModalInputs.buttons;

                    $scope.closeIcon = templatesService.getUrl('closeBtn');
                	$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
                	$scope.backIcon = 'resources/assets/svgs/backarrow.svg';

                    $timeout(function(){
                        if($scope.bvModalControllerInputs.closeButton != undefined && typeof $scope.bvModalControllerInputs.closeButton == 'function'){
                            $scope.close = $scope.bvModalControllerInputs.closeButton;
                        }
                        else if($scope.close != undefined && typeof $scope.close == 'function'){}
                        else{
                            $scope.close = function(result) {
                                bvModal.close();
                                StringUtils.ieIframeFix().enable();
                                $rootScope.buttonDisabled  = false;
                                if($scope.callback && typeof $scope.callback == 'function') $scope.callback()
                            };
                        }
  					});

	  				$scope.back = function(result) {
	  					bvModal.close();
	  					StringUtils.ieIframeFix().enable();
	  				};
	  				
	  				StringUtils.ieIframeFix().disable();
                }

        	});
        	return bvModal;
        },

        showBvMinimizeModal : function (bvModalInputs) {
        	var bvModal = new modal.open({
                windowClass : bvModalInputs.windowClass,
                backdropClass : bvModalInputs.backdropClass,
                backdrop : !angular.isUndefined(bvModalInputs.backdrop)? bvModalInputs.backdrop : (!angular.isUndefined(bvModalInputs.showClose) &&  bvModalInputs.showClose  === false) ?   true : 'static',
                keyboard : (!angular.isUndefined(bvModalInputs.showClose) &&  bvModalInputs.showClose  === false) ?   true : false,
                template:	function(){
                				var buttonStr = '';
                				if(!('buttons' in bvModalInputs)){
                					buttonStr =
                						'<div class="bv-input-btn-box">'
                						+'		<button class="bv-input-btn" ng-click="close($event)"> {{ closeBtnTxt }} </button>'
                						+'		<button class="bv-input-btn bv-default-background-color-blue" ng-disabled="enableActionBtn != undefined && !enableActionBtn" ng-click="action()"> {{ actionBtnTxt }} </button>'
                						+'</div>'
                				}
                				else if(bvModalInputs.buttons != null)
                				{
                					 buttonStr =
                						 '<div class="bv-input-btn-box">'
                						 +'		<button ng-repeat="button in buttons track by $index" class="bv-input-btn bv-default-background-color-blue bv-input-btn-{{ $index }}" ng-click="button.action()"> {{ button.name }} </button>'
                						 +'</div>'
                				}
                				return bvModalInputs.template ||
                				'<div class="bv-modal-dialog bv-height-100" bv-dialog-directive bv-modal-controller-inputs="bvModalControllerInputs" controller-dialog="' + bvModalInputs.controller + '">'
                				+'	<div ng-if="showHeader || showClose" ng-class="{ \'bv-modal-blue-header\' : showHeader, \'bv-default-background-color-blue\' : showHeader, \'bv-modal-header-small\' : !showHeader }">'
	                			+'		<div ng-if="showHeader" class="bv-float-left bv-width-100">'
	        					+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
	        					+'		</div>'
	        					+'		<svg class="bv-modal-btn-minimize" ng-click="close($event)" ng-include="showHeader && showClose ? minimizeIcon : null"></svg>'
		        				+'	</div>'
		        				+'	<div class="bv-modal-body">'
    							+'		<div class="bv-height-100" ng-include="bodyTemplate"></div>'
								+'	</div>'
		        				+ 	buttonStr
			        			+'</div>'
                },
                controller: function ($scope) {
                	$scope.bodyTemplate  		 	= bvModalInputs.bodyTemplate;
        			$scope.template 			 	= bvModalInputs.template;
        			$scope.showHeader  		 		= bvModalInputs.showHeader != null ? bvModalInputs.showHeader : true;
        			$scope.showClose  		 		= bvModalInputs.showClose != null ? bvModalInputs.showClose : true;
        			$scope.showLeft 				= bvModalInputs.showLeft != null ? bvModalInputs.showLeft : false;
        			$scope.showRight 				= bvModalInputs.showRight != null ? bvModalInputs.showRight : false;
        			$scope.actionBtnTxt 			= bvModalInputs.actionBtnTxt;
        			$scope.buttons					= bvModalInputs.buttons;

        			$scope.bvModalControllerInputs = {}
        			$scope.activeController = bvModalInputs.controller;
        				
                	if('inputs' in bvModalInputs){
	        			$scope.bvModalControllerInputs	= bvModalInputs.inputs;
                	}

                	$scope.bvModalControllerInputs.bvModal = bvModal;
                	$scope.bvModalControllerInputs.buttons = bvModalInputs.buttons;

                	$scope.minimizeIcon = 'resources/assets/svgs/minimizeDialog.svg';
                	
                	$scope.close = function(result) {
                		bvModal.close();
  					};
                }
        	});
			return bvModal;
        },

        showBvAlertMessage : function (alertMsg)
	    {
	      var showAlertMessageDialogBox = new modal.open({
	              resolve:   {
	                          alertMsg:     function () { return alertMsg; },
	                   } ,
	                backdrop : 'static',
	                keyboard : false,
	                windowClass : 'bv-show-message bv-confirm',		
	                template:  '<div class="bv-modal-dialog">'
	                		+'	<div ng-if="showHeader || showClose" ng-class="{ \'bv-modal-blue-header\' : showHeader, \'bv-default-background-color-blue\' : showHeader, \'bv-modal-header-small\' : !showHeader }">'
	                		+'		<div ng-if="showHeader" class="bv-float-left bv-width-100">'
	        				+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
	        				+'		</div>'
	        				+'		<div class="bv-modal-btn-close" ng-click="close()" ng-include="showHeader && showClose ? closeIcon : (showClose ? closeIconSmall : null)"></div>'
		        			+'	</div>'
		        			+'	<div class="bv-modal-body">'
							+'		<div class="bv-confirm-content">{{alertMsg}}</div>'
							+'	</div>'
    						/*
							 * +'<div class="bv-input-btn-box">' +' <button
							 * class="bv-input-btn
							 * bv-default-background-color-blue"
							 * ng-click="close()"> {{ OkBtnTxt }} </button>' +'</div>'
							 */
        					+'</div>',

	                controller: function ($scope, $modalInstance, alertMsg) {
	                    $scope.alertMsg = alertMsg;
	                    $scope.OkBtnTxt = localizedMessages.get('OK');
	                    $scope.showHeader  = false;
	        			$scope.showClose  = true;
	        			$scope.closeIcon = templatesService.getUrl('closeBtn');
                		$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
	                    $scope.close = function(result) {
	                        $modalInstance.close(result);
	                    };
	                }
	            });
	        return showAlertMessageDialogBox.result;
	    },
	    
	    showBvAlertMessageNoBackdrop : function (alertMsg)
	    {
	      var showAlertMessageDialogBox = new modal.open({
	              resolve:   {
	                          alertMsg:     function () { return alertMsg; },
	                   } ,
	                backdrop : false,
	                keyboard : false,
	                windowClass : 'bv-show-message bv-confirm',		
	                template:  '<div class="bv-modal-dialog">'
	                		+'	<div ng-if="showHeader || showClose" ng-class="{ \'bv-modal-blue-header\' : showHeader, \'bv-default-background-color-blue\' : showHeader, \'bv-modal-header-small\' : !showHeader }">'
	                		+'		<div ng-if="showHeader" class="bv-float-left bv-width-100">'
	        				+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
	        				+'		</div>'
	        				+'		<div class="bv-modal-btn-close" ng-click="close()" ng-include="showHeader && showClose ? closeIcon : (showClose ? closeIconSmall : null)"></div>'
		        			+'	</div>'
		        			+'	<div class="bv-modal-body">'
							+'		<div class="bv-confirm-content">{{alertMsg}}</div>'
							+'	</div>'
    						/*
							 * +'<div class="bv-input-btn-box">' +' <button
							 * class="bv-input-btn
							 * bv-default-background-color-blue"
							 * ng-click="close()"> {{ OkBtnTxt }} </button>' +'</div>'
							 */
        					+'</div>',

	                controller: function ($scope, $modalInstance, alertMsg) {
	                    $scope.alertMsg = alertMsg;
	                    $scope.OkBtnTxt = localizedMessages.get('OK');
	                    $scope.showHeader  = false;
	        			$scope.showClose  = true;
	        			$scope.closeIcon = templatesService.getUrl('closeBtn');
                		$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
	                    $scope.close = function(result) {
	                        $modalInstance.close(result);
	                    };
	                }
	            });
	        return showAlertMessageDialogBox.result;
	    },

	    showBvAlertWithTitle : function (message, popupTitle) {
			var msgDialogBox = new modal.open({
            	resolve:   {
                    			message:     function () { return message; },
                    			popupTitle:     function () { return popupTitle; },
        						OkBtnTxt: function () { return localizedMessages.get('OK'); }
               			   } ,
                template:  '<div class="modal-dialog" style="height:100%;">'+
			                +'<div class="bv-modal-blue-header bv-default-background-color-blue">'
			    			+'	<div style="width:100%" class="bv-float-left">'
							+'		<p class="bv-modal-title"> {{ popupTitle }} </p>'
							+'	</div>'
							+'</div>'
    					   	+'<div class="bv-modal-body">'+
        							'<div>'+
            							'<p>{{message}}</p>'+
            						'</div>'+
        							'<div class="bv-blue-msg-dialog-footer">'+
        								'<button type="button" class="bv-input-btn" ng-click="close()">{{OkBtnTxt}}</button>'+
            						'</div>'+
        						'</div>'+
						   '</div></div>',
                controller: function ($scope, $modalInstance, message, OkBtnTxt, popupTitle) {
                	$scope.message = message;
                	$scope.OkBtnTxt = OkBtnTxt;
                	$scope.popupTitle = popupTitle;

                	$scope.close = function(result) {
    					$modalInstance.close(result);
  					};
                }
          	});
	    },
	    
	   uploadFile : function (uploadFileObject)
	    {
		    var uploadFileModal = new modal.open({
	            windowClass : 'bv-modal-window-repository-upload-file',   
	        	template:'<div class="bv-modal-dialog">'
						+'	<div class="bv-modal-blue-header bv-default-background-color-blue">'
		    			+'		<div class="bv-float-left bv-width-100">'
						+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
						+'		</div>'
						+'		<div class="bv-modal-btn-close" ng-click="close()" ng-include="closeIcon"></div>'
						+'	</div>'
						+'	<div class="bv-modal-body">'
						+'		<div class="bv-height-100" bv-upload-file upload-file-object="uploadFileObject"></div>'
						+'	</div>'						
		    			+'</div>' ,
		    	controller: function ($scope, $modalInstance) {
		    		
    				$scope.popupTitle = localizedMessages.get('UPLOAD_FILE');
    				$scope.showClose  = true;
    				$scope.closeIcon = templatesService.getUrl('closeBtn');
    				var fn = uploadFileObject.onUploadComplete;
        			
        			$scope.cancel = function() 
        			{
                    	$modalInstance.close();
                	};
        			
        			$scope.close = function() 
        			{
                    	$modalInstance.close();
                	};	

                    var uploadComplete = function()
                    {
                    	$modalInstance.close("Complete");
                    	
                    	if (fn && (typeof fn === 'function')) 
					    {
	                    	fn();
					    }
                    }
                    
                    uploadFileObject.cancel = $scope.cancel;
                    uploadFileObject.uploadComplete = uploadComplete;
                    $scope.uploadFileObject = uploadFileObject;
		        }
			});
	    },
	    
	    replaceFile : function (uploadFileObject)
	    {
		    var uploadFileModal = new modal.open({
	            windowClass : 'bv-modal-window-repository-replace-file',   
	        	template:'<div class="bv-modal-dialog">'
						+'	<div class="bv-modal-blue-header bv-default-background-color-blue">'
		    			+'		<div class="bv-float-left bv-width-100">'
						+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
						+'		</div>'
						+'		<div class="bv-modal-btn-close" ng-click="close($event)" ng-include="closeIcon"></div>'
						+'	</div>'
						+'	<div class="bv-modal-body">'
						+'		<div class="bv-height-100" bv-upload-file upload-file-object="uploadFileObject"></div>'
						+'	</div>'						
		    			+'</div>' ,
		    	controller: function ($scope, $modalInstance) {
		    		
		    				$scope.popupTitle = localizedMessages.get('REPLACE');
		    				$scope.showClose  = true;
		    				$scope.closeIcon = templatesService.getUrl('closeBtn');
		    				var fn = uploadFileObject.uploadComplete;
		                    
		                    var uploadComplete = function()
		                    {
		                    	$modalInstance.close("Complete");
		                    	
		                    	if (fn && (typeof fn === 'function')) 
							    {
			                    	fn();
							    }
		                    }
		                    
						    $scope.close = function() 
							{
				            	$modalInstance.close();
				        	};
				        	
				        	uploadFileObject.cancel = $scope.close;
				        	
				        	uploadFileObject.uploadComplete = uploadComplete;
		                    
		                    $scope.uploadFileObject = uploadFileObject;
		    	}
			});
	    },
	    
	    checkinFile : function (uploadFileObject)
	    {
		    var uploadFileModal = new modal.open({
	            windowClass : 'bv-modal-window-repository-replace-file',   
	        	template:'<div class="bv-modal-dialog">'
						+'	<div class="bv-modal-blue-header bv-default-background-color-blue">'
		    			+'		<div class="bv-float-left bv-width-100">'
						+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
						+'		</div>'
						+'		<div class="bv-modal-btn-close" ng-click="close($event)" ng-include="closeIcon"></div>'
						+'	</div>'
						+'	<div class="bv-modal-body">'
						+'		<div class="bv-height-100" bv-upload-file upload-file-object="uploadFileObject"></div>'
						+'	</div>'						
		    			+'</div>' ,
		    	controller: function ($scope, $modalInstance, repositoryService) {
		    		
		    				$scope.popupTitle = localizedMessages.get('CHECK_IN');
		    				$scope.showClose  = true;
		    				$scope.closeIcon = templatesService.getUrl('closeBtn');
		    				var fn = uploadFileObject.uploadComplete;
		                    
		                    var uploadComplete = function()
		                    {
		                    	$modalInstance.close("Complete");
		                    	
		                    	if (fn && (typeof fn === 'function')) 
							    {
			                    	fn();
							    }
		                    	repositoryService.sendDocCheckedInNotification($scope.uploadFileObject.baseId);
		                    }
		                    
						    $scope.close = function() 
							{
				            	$modalInstance.close();
				        	};
				        	
				        	uploadFileObject.cancel = $scope.close;
				        	
				        	uploadFileObject.uploadComplete = uploadComplete;
		                    
		                    $scope.uploadFileObject = uploadFileObject;
		    	}
			});
	    },

	    showRename : function (businessObject)
	    {
	      var showRenameDialogBox = new modal.open({
	              resolve:   {
	            	  renameObject:     function () { return businessObject; },
	                   } ,
	                template:
	                	'<div class="bv-modal-blue-header bv-default-background-color-blue">'+
	                   		'<div style="width:100%" class="bv-float-left">'+
	                   			'<p class="bv-modal-title">{{RenameBtnTxt}}</p>'+
	                   		'</div>'+
	                   	'</div>'+
	                   	'<div class="small-alert-dialog-class bv-padding-10">'+
	                   		'<input type="text" ng-model="objectName" />'+
	                   		'<div class="bv-input-btn-box">'+
	                   		'<button class="bv-input-btn bv-default-background-color-blue" ng-click="action()"> {{RenameBtnTxt}} </button>'+
    						'<button class="bv-input-btn" ng-click="close()"> {{OkBtnTxt}} </button>'+
    						'</div>'+
	                   	'</div>'
	                	,
	                controller: function ($scope, $modalInstance, renameObject, repositoryService) {
	                    $scope.objectName = renameObject.name;
	                    $scope.OkBtnTxt = localizedMessages.get('OK');
	                    $scope.RenameBtnTxt = localizedMessages.get('RENAME');
	                    $scope.close = function(result) {
	                        $modalInstance.dismiss('cancel');
	                    };

	                    $scope.action = function(result) {

	                    	var validName = $scope.objectName;

	                        if(StringUtils.isNullEmpty(validName))
		                		return;

		                	if(!StringUtils.isNullEmpty(renameObject.extensionType))
		                	{
		                		var oldExtension = renameObject.extensionType;
		                		validName = StringUtils.validDocumentExtension(validName, oldExtension);
		                	}

		                    repositoryService.rename(renameObject.objectBaseUuid, validName).then(function(data)
		                    {
		                    	$modalInstance.close(data.data);
		                    });

	                    };
	                }
	            });
	        return showRenameDialogBox.result;
	    },

	    uploadFile1 : function (uploadFile)
	    {
	      var showRenameDialogBox = new modal.open({
	              resolve:   {
	            	  uploadFileObject:     function () { return uploadFile; },
	                   } ,
	                template:
	                	'<div class="bv-modal-blue-header bv-default-background-color-blue">'+
	                   		'<div style="width:100%" class="bv-float-left">'+
	                   			'<p class="bv-modal-title">{{bvinputTitle}}</p>'+
	                   		'</div>'+
	                   	'</div>'+
	                   	'<div class="small-alert-dialog-class bv-padding-10">'+
	                   		'<div bv-upload-file action="{{uploadFileObject.action}}" upload-complete="onUploadComplete" base-id="{{uploadFileObject.baseId}}" upload-parent="{{uploadFileObject.uploadParent}}" ></div>'+
    						'</div>'+
	                   	'</div>'
	                	,
	                controller: function ($scope, $modalInstance, uploadFileObject) {

	                	$scope.uploadFileObject = uploadFileObject;

	                    $scope.close = function(result) {
	                        $modalInstance.dismiss('cancel');
	                    };

	                    $scope.onUploadComplete = function(result) {
	                    	$modalInstance.close();
	                    };
	                }
	            });
	        return showRenameDialogBox.result;
	    },

	    showMcAlertMessage : function (alertMsg)
	    {
	      var showMcAlertMessage = new modal.open({
	              resolve:   {
	                          alertMsg:     function () { return alertMsg; },
	                   } ,
	                template:  '<div class="modal-dialog" style="height:100%;">'+
	                   '<div class="modal-content">'+
	                      '<div class="bv-blue-msg-dialog-body">'+
	                          '<p>{{alertMsg}}</p>'+
	                        '</div>'+
	                      '<div class="bv-blue-msg-dialog-footer">'+
	                        '<button type="button" class="bv-input-btn" ng-click="close()">{{OkBtnTxt}}</button>'+
	                        '</div>'+
	                    '</div>'+
	                '</div>',
	                controller: function ($scope, $modalInstance, alertMsg) {
	                    $scope.alertMsg = alertMsg;
	                    $scope.OkBtnTxt = localizedMessages.get('OK');
	                    $scope.close = function(result) {
	                        $modalInstance.close(result);
	                    };
	                }
	            });
	        return showMcAlertMessage;
	    },

	    showBankIdTokenPopup : function (bvModalInputs){
        	var bvModal = new modal.open({
                windowClass : 'dialogs ' + bvModalInputs.windowClass,
                backdropClass : bvModalInputs.backdropClass,
                backdrop : !angular.isUndefined(bvModalInputs.backdrop)? bvModalInputs.backdrop : (!angular.isUndefined(bvModalInputs.showClose) &&  bvModalInputs.showClose  === false) ?   true : 'static',
                template:	function(){
                				
                				return bvModalInputs.template ||
                				'<div class="bv-modal-dialog bv-height-100" bv-dialog-directive bv-modal-controller-inputs="bvModalControllerInputs" controller-dialog="' + bvModalInputs.controller + '">'
                				+'	<div ng-if="showHeader || showClose" ng-class="{ \'bv-modal-blue-header\' : showHeader, \'bv-default-background-color-blue\' : showHeader, \'bv-modal-header-small\' : !showHeader,  \'bv-modal-btn-custom\' : templateCustomButtonsUrl }">'
                				+'		<div class="bv-modal-btn-custom-box" ng-if="showHeader && templateCustomButtonsUrl" ng-include="templateCustomButtonsUrl"></div>'
                				+'		<div ng-if="showHeader" class="bv-width-100">'
	        					+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
	        					+'		</div>'
	        					+'		<div ng-if="showClose" class="bv-modal-btn-close ' + ((MobileUtil.isMobileClient() && legacyUserService.isNasdaqUI) ? 'ss-icon ss-standard ss-navigateleft' : '') + '" ng-tap="Cancel()">'
	        					+'			<svg ng-include="showHeader && showClose ? closeIcon : (showClose ? closeIconSmall : null)"></svg>'
	        					+'		</div>'
	        					+'	</div>'
		        				+'	<div class="bv-modal-body bv-height-100">'
    							+'		<div class="bv-height-100" ng-include="bodyTemplate"></div>'
								+'	</div>'
		        				+   '<div class="bv-input-btn-box">'
	       						+'<span>'
	    						+'		<button  class="bv-input-btn bv-input-btn-0 bv-default-background-color-blue" ng-class="{\'bv-default-background-color-black\' : 0 == blackBtnIndex}" ng-click="Cancel()"> Cancel </button>'
	    						+'</span>'
	    						+'<span>'
	    						+'		<button  class="bv-input-btn bv-input-btn-1 bv-default-background-color-blue" ng-class="{\'bv-default-background-color-black\' : 1 == blackBtnIndex}" ng-click="Submit()"> Submit </button>'
	    						+'</span>'
	    						+'</div>'
			        			+'</div>'
                },
                controller: function ($scope, $modalInstance, bvDialogService) {

                	$scope.bodyTemplate  		 	= bvModalInputs.bodyTemplate;
        			$scope.showHeader  		 		= bvModalInputs.showHeader != null ? bvModalInputs.showHeader : true;
        			$scope.showClose  		 		= bvModalInputs.showClose != null ? bvModalInputs.showClose : true;
        			$scope.actionBtnTxt 			= bvModalInputs.actionBtnTxt;
        			$scope.blackBtnIndex			= bvModalInputs.blackBtnIndex != null ? bvModalInputs.blackBtnIndex : -1;
        			$scope.callback	= bvModalInputs.callback != null ? bvModalInputs.callback : false;
        			$scope.personalNumber = "";
              
        			if(bvModalInputs.inputs && "popupTitle" in bvModalInputs.inputs)
        			    $scope.popupTitle = bvModalInputs.inputs.popupTitle;

        			$scope.bvModalControllerInputs = {}
        				
                	if('inputs' in bvModalInputs){
	        			$scope.bvModalControllerInputs	= bvModalInputs.inputs;
	        			$scope.bvModalControllerInputs.personalNumber = $scope.personalNumber;
                	}
        			
        			$scope.Submit = function(personalNumber){
						var pin = personalNumber || $('#txtBankIdPin').val();
						var alphaNumbericReg  = /[^a-z\d]/i;
						var isValid = !(alphaNumbericReg.test(pin));
						
						if(!isValid || !pin || pin.length > 50){
							bvDialogService.showBvAlertMessage("Please enter valid Personal ID Number");
							return;
						}
						
						bvModalInputs.actionTrue(pin);
						$modalInstance.close();
						
        			}
        			
        			$scope.Cancel = function(){
        				bvModalInputs.actionFalse();
						$modalInstance.close();
        			}
        		
        			$scope.bvModalControllerInputs.submit = $scope.submit;
        			$scope.bvModalControllerInputs.cancel = $scope.cancel;
        			
                    $scope.bvModalControllerInputs.bvModal = bvModal;
                  
                    $scope.closeIcon = templatesService.getUrl('closeBtn');
                	$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
                	$scope.backIcon = 'resources/assets/svgs/backarrow.svg';

                
	  				
	  				StringUtils.ieIframeFix().disable();
                }
        	});
			    return bvModal;
        
	    },
		showBankIdMessageNoBackdrop : function (alertMsg, callback)
	    {
	      var showAlertMessageDialogBox = new modal.open({
	              resolve:   {
	                          alertMsg:     function () { return alertMsg; },
	                   } ,
	                backdrop : false,
	                keyboard : false,
	                windowClass : 'bv-show-message bv-confirm',		
	                template:  '<div class="bv-modal-dialog">'
	                		+'	<div ng-if="showHeader || showClose" ng-class="{ \'bv-modal-blue-header\' : showHeader, \'bv-default-background-color-blue\' : showHeader, \'bv-modal-header-small\' : !showHeader }">'
	                		+'		<div ng-if="showHeader" class="bv-float-left bv-width-100">'
	        				+'			<p class="bv-modal-title"> {{ popupTitle }} </p>'
	        				+'		</div>'
	        				+'		<div class="bv-modal-btn-close" ng-click="close()" ng-include="showHeader && showClose ? closeIcon : (showClose ? closeIconSmall : null)"></div>'
		        			+'	</div>'
		        			+'	<div class="bv-modal-body">'
							+'		<div class="bv-confirm-content">{{alertMsg}}</div>'
							+'	</div>'
    						+'<div class="bv-input-btn-box">'
    						+'		<button class="bv-input-btn bv-default-background-color-blue" ng-click="close()"> {{ OkBtnTxt }} </button>'
    						+'</div>'
        					+'</div>',

	                controller: function ($scope, $modalInstance, alertMsg) {
	                    $scope.alertMsg = alertMsg;
	                    $scope.OkBtnTxt = localizedMessages.get('OK');
	                    $scope.showHeader  = false;
	        			$scope.showClose  = true;
	        			$scope.closeIcon = templatesService.getUrl('closeBtn');
                		$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
	                    $scope.close = function(result) {
	                    	callback();
	                        $modalInstance.close(result);
	                    };
	                }
	            });
	        return showAlertMessageDialogBox.result;
	    },
	    showBvBtDevices : function (bvBtDevices)
	    {
	      var showBvBtDevicesDialogBox = new modal.open({
	              resolve:   {
	                      bvBtDevices:     function () { return bvBtDevices; }
	                       } ,
	                template:  '<div class="modal-dialog" style="height:100%;margin:0px;">'+
	                '<div class="modal-content">'+
	                  '<div class="bv-modal-header bv-default-background-color-blue">'+
	                    '<button type="button" class="close" ng-click="cancel()" aria-hidden="true">&times;</button>'+
	                    '<h3 class="bv-modal-title">{{popupTitle}}</h3>'+
	                  '</div>'+
	                  '<div class="bv-modal-body" style="overflow:visible">'+
	                    '<div style="height:100px;width:100px;">' +
	                      '<select class="bv-bt-device-select" size="3" ng-model="selectedBvBtDevice" ng-options="bvBtDevice.peripheralName for bvBtDevice in bvBtDevices">'+
	                          '</select>'+
	                    '</div>' +
	                  '</div>'+
	                  '<div class="bv-modal-footer">'+
	                    '<button type="button" class="bv-input-btn" ng-click="cancel()">{{CancelBtnTxt}}</button>'+
	                    '<button type="button" class="bv-input-btn bv-default-background-color-blue" style="margin-left:4%" ng-click="connect()">{{ConnectBtnTxt}}</button>'+
	                  '</div>'+
	                '</div>'+
	              '</div>',
	                controller: function ($scope, $modalInstance, bvBtDevices) {
	                $scope.popupTitle    = "Available Devices";
	                $scope.bvBtDevices   = bvBtDevices;
	                $scope.ConnectBtnTxt = localizedMessages.get('CONNECT');
	                $scope.CancelBtnTxt  = localizedMessages.get('CANCEL');

	                $scope.selectedBvBtDevice = bvBtDevices[0];
	                $scope.cancel = function(result) {
	              $modalInstance.dismiss('cancel');
	            };

	            $scope.connect = function(result) {
	              if($scope.selectedBvBtDevice != null && $scope.selectedBvBtDevice!= undefined )
	              {
	                result = $scope.selectedBvBtDevice;
	                // alert("Result:"+result);
	                $modalInstance.close(result);
	              }
	            };
	                }
	            });
	      return showBvBtDevicesDialogBox.result;
	      },

	      showBvBtUsers : function (bvBtUsers)
	      {
	      var showBvBtUsersDialogBox = new modal.open({
	            resolve:   {
	                    bvBtUsers:     function () { return bvBtUsers; }
	                     } ,
	              template:  '<div class="modal-dialog" style="height:100%;margin:0px;">'+
	                '<div class="modal-content">'+
	                  '<div class="bv-modal-header bv-default-background-color-blue">'+
	                    '<button type="button" class="close" ng-click="cancel()" aria-hidden="true">&times;</button>'+
	                    '<h3 class="bv-modal-title">{{popupTitle}}</h3>'+
	                  '</div>'+
	                  '<div class="bv-modal-body">'+
	                    '<div style="height:100px;width:100px;">' +
	                      '<select class="bv-bt-device-select" size="3" ng-model="selectedBvBtUser" ng-options="bvBtUser.username for bvBtUser in bvBtUsers">'+
	                          '</select>'+
	                    '</div>' +
	                  '</div>'+
	                  '<div class="bv-modal-footer">'+
	                    '<button type="button" class="bv-input-btn" ng-click="cancel()">{{CancelBtnTxt}}</button>'+
	                    '<button type="button" class="bv-input-btn bv-default-background-color-blue" style="margin-left:4%" ng-click="login()">{{LoginBtnTxt}}</button>'+
	                  '</div>'+
	                '</div>'+
	              '</div>',
	              controller: function ($scope, $modalInstance, bvBtUsers) {
	                  $scope.popupTitle   = "Available Users";
	                  $scope.bvBtUsers    = bvBtUsers;
	                  $scope.LoginBtnTxt  = localizedMessages.get('LOG_IN');
	                  $scope.CancelBtnTxt = localizedMessages.get('CANCEL');
	                  $scope.selectedBvBtUser = bvBtUsers[0];

	                  $scope.cancel = function(result)
	                  {
	                    $modalInstance.dismiss('cancel');
	                  };

	                  $scope.login = function(result)
	                  {
	                      result = $scope.selectedBvBtUser;
	                      $modalInstance.close(result);
	                  };
	              }
	        });
	      	return showBvBtUsersDialogBox.result;
	      },

	      showUserSelection : function (bvUsers, popupTitle)
	      {
	          var showUserSelectionDialogBox = new modal.open({
	            resolve: {
	                        bvUsers:     function () { return bvUsers; },
	                        popupTitle:  function () { return popupTitle; }
	                     } ,
	              template:

	                    '<div class="modal-dialog" style="height:100%;margin:0px;">'+
	                      '<div class="modal-content">'+
	                          '<div class="bv-modal-blue-header bv-default-background-color-blue">'+
	                              '<div>'+
	                                '<div style="width:5%" class="bv-float-left">'+
	                                  '<button type="button" class="close bv-float-left" ng-click="close()" aria-hidden="true">&times;</button>'+
	                                '</div>'+
	                                '<div style="width:70%" class="bv-float-left">'+
	                                  '<p class="bv-modal-title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{popupTitle}}</p>'+
	                                '</div>'+
	                                '<div style="width:25%" class="bv-float-left">'+
	                                    '<input class="bv-select-user-blue bv-float-right" type="button" value="{{SelectBtnTxt}}" ng-click="selectUsers()">'+
	                                '</div>'+
	                              '</div>'+
	                          '</div>'+
	                          '<div class="bv-modal-body bv-modal-background" ng-class="modalBodyStyleClass">'+
	                              '<div class="bv-select-user-search">'+
	                                '<input class="bv-select-user-search bv-float-left" ng-model="searchFilter" type="text" placeholder="Search Users"/>'+
	                              '</div>'+
	                              '<div class="bv-select-user bv-width-100" style="margin-top:4px;">'+
	                                '<div ng-repeat="user in bvUsers | filter:searchFilter" class="bv-width-100 bv-float-left" style="padding-left:5px">'+
	                                  '<div ng-if="user.type==\'User\'">'+
	                                    '<div class="bv-left-float bv-left-float-checkbox" ng-class="{\'checked\': user.selected, \'uncheckedClass\': !user.selected}" ng-click="trackUserSelection(user)" />'+
	                                    '<img name="No_Photo" class="bv-left-float bv-select-user-img" src="resources/assets/images/BVLiveryProfileNoPhoto.png"/>'+
	                                    '<label class="bv-left-float bv-select-user-name">{{user.name}}</label>'+
	                                  '</div>'+
	                                '</div>'+
	                              '</div>'+
	                          '</div>'+
	                      '</div>'+
	                    '</div>' ,


	              controller: function ($scope, $modalInstance, bvUsers, popupTitle) {
	                  $scope.popupTitle   = popupTitle;
	                  $scope.bvUsers    = bvUsers;
	                  $scope.SelectBtnTxt  = localizedMessages.get('SELECT');
	                  $scope.CancelBtnTxt = localizedMessages.get('CANCEL');
	                  $scope.selectedBvBtUser = bvUsers[0];

	                  $scope.trackUserSelection = function (user)
	                  {
	                      user.selected =  (user.selected) ? false : true;
	                  }

	                  $scope.close = function(result)
	                  {
	                      $modalInstance.dismiss('cancel');
	                  };

	                  $scope.selectUsers = function(result)
	                  {
	                    $scope.selectedUsers = [];
	                    angular.forEach(bvUsers, function(bvUser) {
	                      if(bvUser.selected)
	                        this.push(bvUser);
	                    }, $scope.selectedUsers);
	                    result = $scope.selectedUsers;
	                    $modalInstance.close(result);
	                  };
	              }
	          });
	          return showUserSelectionDialogBox.result;
	      },

		showCurrentLivePresentations : function (ongoingPresentationVOs)
		{
		  var showCurrentLiveMeeetingsDialogBox = new modal.open({
		      resolve:  {
		                    eventVOs:  function () { return ongoingPresentationVOs; }
		                } ,
		      template:

		            '<div class="modal-dialog" style="height:100%;margin:0px;">'+
		              '<div class="modal-content">'+
		                  '<div class="bv-modal-blue-header bv-default-background-color-blue">'+
		                  		'<div class="bv-float-left">'+
									'<p class="bv-modal-title">{{popupTitle}}</p>'+
								'</div>'+
								'<button class="bv-dialog-close-x bv-float-right"  ng-click="close()">&times;</button>'+
		                      // '<div>'+
		                      // '<div style="width:5%"
								// class="bv-float-left">'+
		                      // '<button type="button" class="close
								// bv-float-left" ng-click="close()"
								// aria-hidden="true">&times;</button>'+
		                      // '</div>'+
		                      // '<div style="width:95%"
								// class="bv-float-left">'+
		                      // '<p
								// class="bv-modal-title">&nbsp;&nbsp;{{popupTitle}}</p>'+
		                      // '</div>'+
		                      // '</div>'+
		                  '</div>'+
		                  '<div class="bv-modal-body" ng-class="modalBodyStyleClass" style="overflow:hidden; padding:10px;">'+
		                  // '<div class="bv-modal-body bv-modal-background"
							// ng-class="modalBodyStyleClass">'+
		                      '<div class="bv-width-100" style="margin-top:4px;">'+
		                        '<table height="100%" width="100%">'+
		                          '<tr ng-repeat="eventVO in eventVOs" >'+
		                            '<td width="2%">'+
		                            '<td width="68%">'+
		                              '{{eventVO.name}}'+
		                            '</td>'+
		                            '<td width="28%">'+
		                              '<button class="bv-input-btn bv-default-background-color-blue" ng-click="joinMeeting(eventVO)" > {{JoinBtnTxt}} </button>'+
		                              // '<input class="bv-select-user-blue
										// bv-float-right" type="button"
										// value="{{JoinBtnTxt}}"
										// ng-click="joinMeeting(eventVO)">'+
		                            '</td>'+
		                            '<td width="2%">'+
		                          '</tr>'+
		                        '</table>'+
		                      '</div>'+
		                  '</div>'+
		              '</div>'+
		            '</div>' ,

		      controller: function ($scope, $modalInstance, eventVOs)
		      {
		          $scope.eventVOs    = eventVOs;
		          $scope.popupTitle  = localizedMessages.get('CURRENT ONGOING_PRESENTATIONS');
		          $scope.JoinBtnTxt  = localizedMessages.get('JOIN');

		          $scope.close = function(result)
		          {
		              $modalInstance.dismiss('cancel');
		          };

		          $scope.joinMeeting = function(eventObj)
		          {
		              console.log("Returning Event VO for Join Meeting",eventObj);
		              $modalInstance.close(eventObj);
		          };
		      }
		  	});
		  return showCurrentLiveMeeetingsDialogBox.result;
		},

		showDocPresentation : function (conf)
		{
			// console.log("############",conf);
			var showDocPresentationDialogBox = new modal.open({
			    windowClass  : 'bv-conf-present-doc-dialog',
			    templateUrl  : 'modules/conference/ConfPresentDoc.tpl.html',
			    controller   : 'ConfPresentDocController',
			});
			return showDocPresentationDialogBox.result;
		},

		addToNewItems: function(businessObjects, users)
		{
			var bvModal = new modal.open({
				resolve:   {
					fileAttachments:     function () { return businessObjects; },
					users:     function () { return users; },
	                   } ,
                windowClass : 	'bv-modal-window-compose-new-message',
                templateUrl:  	'modules/newitems/AddToNewItems.tpl.html',
                controller: 	'AddToNewItemsController',
			});
		},

		attachDocument: function (onSelectedDone, onlyFiles, enableMultiSelect, popupTitle, permissionCheck)
		{
			var showMove = new modal.open({
	            windowClass : 	'bv-modal-window-new-attachment',
	   	        templateUrl:  	'modules/dialogs/Attachments.tpl.html',
	            controller: function ($scope, $modalInstance) {	                	  
	            	
	            	if(StringUtils.isNullEmpty(popupTitle))
	            		popupTitle = localizedMessages.get("ATTACHMENTS_TITLE");
	            	
	            	if(StringUtils.isNullEmpty(onlyFiles))
	            		onlyFiles = true;
	            	
	            	if(StringUtils.isNullEmpty(enableMultiSelect))
	            		enableMultiSelect = true;
	            	
	            	$scope.attachTitle =  popupTitle;
	            	
	                $scope.closeIcon = templatesService.getUrl('closeBtn');
	
	                $scope.close = function(result) {
	                    $modalInstance.dismiss('cancel');
	                };
	                
	                $scope.attachmentObject ={ popupTitle:popupTitle, multiSelect: enableMultiSelect, onlyFiles: onlyFiles, permissionCheck: permissionCheck};
	                
	                $scope.selectedFiles = function(data) {	 
	                	// console.log("files data");
	                	// console.log(data);
	                	var noOfFiles = data.length
	                	// var newData=[];
	                	for (var i = 0; i<noOfFiles;i++) 
	                	{
	                		if(data[i].conversionStatus!="CONVERTED" && data[i].boName != 'FolderBO')
	                		{
// newData.push(data[i]);
	                			data.splice(i,1);
	                			noOfFiles -= 1;
	                			i--;
	                		}
	                	}
	                	// data=newData;
	                	// console.log("files data2");
	                	// console.log(data);
	                	
        		        onSelectedDone(data);
        		        $modalInstance.close(data);
	                };
	            }

			});
			return showMove.result;
		},

		showShareDocuments : function(businessObjects)
		{
			var showShareDocumentsModal = new modal.open({
				resolve:   {
					businessObjects:     function () { return businessObjects; }
   			    } ,
	   			 windowClass : 	'bv-modal-window-compose-new-message',
	             templateUrl:  	'modules/messages/ComposeMessage.tpl.html',
	             controller: 	'ComposeMessageController',
			});
			return showShareDocumentsModal;
		},

		openFolderView : function(folder, folderBarObject, showTree, hideSelectAll) 
		{
			var folderView = new modal.open({
				resolve: {
					folderBaseUuid: function() { return folder.objectBaseUuid},
					folderBarObject: function() { return folderBarObject},
					showTree: function() { return showTree},
					hideSelectAll: function() { return hideSelectAll}
				},
				windowClass: 'folderViewDialog',
	            templateUrl: 'modules/shared/FolderView.tpl.html',
	            controller:  function FolderViewerController($scope, backUtiltiyService, repositoryService, repositoryModelService, $modalInstance, folderBaseUuid, folderBarObject, showTree, hideSelectAll)
				{
					$scope.iconNextToTitle = 'resources/assets/svgs/backarrow.svg';
					$scope.svgFill = '#fff';
					$scope.currentInstance = "FOLDER_LISTING";
					$scope.showTree = showTree || false;
					$scope.folderBarObject = folderBarObject || {showBack: false, title: localizedMessages.get('FOLDER')};
					$scope.folderBaseUuid = folderBaseUuid;
					$scope.middleBarObject = $scope.folderBarObject;
					$scope.hideSelectAll = hideSelectAll?true:false;
					var backUtiltiy  = backUtiltiyService.getInstance($scope.currentInstance);
					var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);
					$scope.closeIcon = templatesService.getUrl('closeBtn');

					$scope.getDocumentsForParentBaseUuid = function (baseUuid)
				    {
				    	repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
				    	{
				    		repositoryModel.repositoryList(data.data);
				    	});
				    };

					 $scope.handleLeftIcon = function()
					{
						var folderObject = backUtiltiy.popBackStack();
						$scope.middleBarObject.showBack = backUtiltiy.showBack;
						$scope.middleBarObject.title = backUtiltiy.title;

						if(folderObject)
						{
							$scope.getDocumentsForParentBaseUuid(folderObject.objectBaseUuid);
						}
					}

					$scope.close = function()
					{
						$modalInstance.dismiss('cancel');
						window.document.querySelector('body').classList.remove('folderViewerListingModalOpen');
					};
				}
			});
		},

	    showEventTimePushOption : function ()
      	{
          var showEventTimePushOptionDialogBox = new modal.open({
            template:

                    '<div class="modal-dialog" style="margin:0px;">'+
                      '<div class="modal-content" style="">'+
                          '<div class="bv-modal-blue-header bv-default-background-color-blue">'+
                              '<div>'+
                                '<div style="width:5%" class="bv-float-left">'+
                                  '<button type="button" class="close bv-float-left" ng-click="close()" aria-hidden="true">&times;</button>'+
                                '</div>'+
                                '<div style="width:70%" class="bv-float-left">'+
                                  '<p class="bv-modal-title">&nbsp;&nbsp;&nbsp;&nbsp;{{popupTitle}}</p>'+
                                '</div>'+
                                '<div style="width:25%" class="bv-float-left">'+
                                    '<input class="bv-select-user-blue bv-float-right" type="button" value="{{SelectBtnTxt}}" ng-click="selectTimeSlot()">'+
                                '</div>'+
                              '</div>'+
                          '</div>'+
                          '<div class="bv-modal-body bv-modal-background" style="height:250px;overflow: hidden;">'+
                              '<div class="bv-select-user bv-width-100" style="margin-top:4px;">'+
                                '<select style="width:160px;margin-left:85px;" size="13" data-ng-options="timeSlot.label for timeSlot in timeSlots track by timeSlot.value" data-ng-model="selectedTimeSlot"></select>'+
                              '</div>'+
                          '</div>'+
                      '</div>'+
                    '</div>' ,


              controller: function ($scope, $modalInstance) {

              	var TIME_SLOTS = [
              			{"label":"-3 hour", "value":"-180"},
              			{"label":"-2 hour", "value":"-120"},
              			{"label":"-60 min", "value":"-60"},
              			{"label":"-45 min", "value":"-45"},
              			{"label":"-30 min", "value":"-30"},
              			{"label":"-15 min", "value":"-15"},
              			{"label":"0",       "value":"0"},
              			{"label":"15 min",  "value":"15"},
              			{"label":"30 min",  "value":"30"},
              			{"label":"45 min",  "value":"45"},
              			{"label":"60 min",  "value":"60"},
              			{"label":"2 hour",  "value":"120"},
              			{"label":"3 hour",  "value":"180"}]

                  $scope.popupTitle   = localizedMessages.get('ADJUST_TIME_FOR_ALL_TOPICS');
                  $scope.timeSlots    = TIME_SLOTS;
                  $scope.selectedTimeSlot = {"label":"0",       "value":"0"};
                  $scope.SelectBtnTxt  = localizedMessages.get('SAVE');
                  $scope.CancelBtnTxt = localizedMessages.get('CANCEL');

                  $scope.close = function(result)
                  {
                      $modalInstance.dismiss('cancel');
                  };

                  $scope.selectTimeSlot = function(result)
                  {
                    result = $scope.selectedTimeSlot.value;
                    $modalInstance.close(result);
                  };
              }
          });
          return showEventTimePushOptionDialogBox.result;
		},
		
		editSignatureDialog: function(cb) {
			var editSignatureModal = new modal.open({
				resolve: {
					selectedUser: function() { return context.getModel().userVO},
					callBack: () => cb
				},
				windowClass : 	'editSignatureDialog',
				templateUrl :  	!MobileUtil.isPhone() ? 'modules/team/CreateSignature.tpl.html' : 'modules/team/CreateSignatureMobile.tpl.html',
				controller : 	'CreateSignatureController2',
				backdrop: 'static',
				keyboard: false
			});
		}
		
	}
	return service;
}

function bvDialogDirective(){
	return{
		restrict: 'A',
		replace: true,
        template: "<div ng-transclude></div>",
        transclude: true,
        controller: "@",
        name:"controllerDialog"
	}
}

angular
	.module('bvdialog.service', ['ui.bootstrap', 'localization.service', 'createSignature'])
	.factory('bvDialogService', bvDialogService)
	.factory('bvDialog', bvDialog)
	.directive('bvDialogDirective', [bvDialogDirective])
	