function bvLitePdfViewer(){

	var canvas = null;

	return {
		restrict: 'A',
		scope:
		{
			documentId: '=',
			scale: '@',

	    },
		controller: function($scope, $element)
		{
			$scope.pageNum = 1;
			$scope.pdfDoc = null;

			// canvas = $element[0];
			var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/services/html?servicename=BvJsonDocumentService&methodname=getFirstPageAsPdf&versionUuid=" + $scope.documentId + "&pageNum=-1";

			// console.log('loadPDF ', pdfFullUrl);
			// console.log('canvas' , canvas);
			$scope.pdfDoc = null;
			if($scope.scale == undefined)
				$scope.scale = 1.0;
			PDFJS.workerSrc = 'component/pdfviewer/lib/pdf.worker.js';
			PDFJS.getDocument(pdfFullUrl, null, null, null).then(function(_pdfDoc)
			{
				$scope.pdfDoc = _pdfDoc;
				// console.log("PDFDOC:",_pdfDoc)
				canvas = $element[0];
				$scope.renderPage(1, canvas);
			}, function(message, exception)
			{
				canvas.innerHTML = "<div>PDF load error</div>";
				console.log("PDF load error: " + message);
			});

			$scope.renderPage = function(num, canvas)
			{
				// console.log(canvas);
				$scope.pdfDoc.getPage(num).then(function(page)
				{
					var viewport = page.getViewport($scope.scale);
					var ctx = canvas.getContext('2d');
					canvas.height = viewport.height;
					canvas.width = viewport.width;
					page.render({ canvasContext: ctx, viewport: viewport }).promise.then(
//						function()
//						{
//							if (callback)
//							{
//								callback(true);
//							}
//							// $scope.$apply(function()
//							// {
//							// 	// $scope.onPageLoad({ page: $scope.pageNum, total: $scope.pdfDoc.numPages });
//							// });
//						},
//						function() {
//							if (callback) {
//								callback(false);
//							}
//							console.log('page.render failed');
//						}
					);
				});
			};
		}
	};
}