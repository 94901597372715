var InternalDiscussController = [
	"$scope", "$location", "objectBaseUuid", "loggedInUser", "token", "discussionsService",
	function InternalDiscussController($scope, $location, objectBaseUuid, loggedInUser, token, discussionsService) {	
		$scope.pdfDetails = {
			objectBaseUuid: objectBaseUuid,
			loggedInUser: loggedInUser 
		};
		
		$location.search("documentId", objectBaseUuid);
		$location.search("userId", loggedInUser);
		$location.search("token", token);
		
		localStorage.setItem("requestCsrfToken", token);
		
		$scope.documentID = objectBaseUuid;
	}
];

angular
 	.module("internalDiscuss", ["discussions-react"])
 	.controller("InternalDiscussController", InternalDiscussController);
