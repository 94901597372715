function SmartSelection(){

    var SmartSelection = function(treeData, portalData, options_){
        var treeData = treeData;
        var portalData = portalData;

        var options = {};
        options.disableUpDownSelection = false;
        options_ ? options = options_ : null;

        this.setTreeData = function(data){
            treeData = data;
        }

        this.setPortalData = function(data){
            portalData = data;
        }

        this.setOptions = function(data){
            options = data;
        }

        this.disableEmptyNodes = function(list){
            var retList = [];

            function go(list){
                _.each(list, function(item){
                    if('children' in item && item.type != 'User' && item.children.length == 0){
                        item.selected = false;
                        item.disabled = !item.selected;
                        item.checkboxDisabled = !item.selected;
                        retList.push(item);
                    }
                    if('children' in item && item.children){
                        go(item.children);
                    }
                });
            }

            go(list);

            return retList;
        }

        this.selectDownward = function(obj, select){
            var retList = [];

            function go(list, obj){
                _.each(list, function(item){
                    if(item.objectBaseUuid == obj.objectBaseUuid){
                        if(!item.checkboxDisabled){
                            item.selected = select;
                            retList.push(item);
                        }

                        if(!options.disableUpDownSelection && 'children' in item && item.children.length > 0){
                            _.each(item.children, function(child){
                                go(treeData, child);
                            });
                        }
                    }
                    else{
                        if('children' in item && item.children.length > 0){
                            go(item.children, obj);
                        }
                    }
                    if('children' in item && item.type != 'User' && item.children.length == 0){
                        item.selected = false;
                        item.disabled = !item.selected;
                    }

                });
            }

            go(treeData, obj);

            return retList;
        }

        this.selectUpward = function(obj){
            var retList = [];

            function go(obj){
                if('children' in obj && obj.children.length > 0 && _.filter(obj.children, { selected : true }).length == _.filter(obj.children, function(item){ return !item.disabled }).length){
                    if(!obj.checkboxDisabled){
                        obj.selected = true;
                        retList.push(obj);
                    }

                }
                else if('children' in obj && obj.children.length > 0 && _.filter(obj.children, { selected : true }).length != _.filter(obj.children, function(item){ return !item.disabled }).length){
                    if(!obj.checkboxDisabled){
                        obj.selected = false;
                        retList.push(obj);
                    }

                }
                if('parent' in obj){
                    go(obj.parent);
                }
            }

            go(obj);

            return retList;
        }

        this.selectTeamSpaceList = function(list){
            _.each(list, function(item){
                var obj = _.find(portalData, { objectBaseUuid : item.objectBaseUuid });
                if(obj){
                    if(!obj.checkboxDisabled){
                        obj.selected = item.selected;
                    }
                    item.disabled ? obj.disabled = item.disabled : null;
                }
            });
        }

        this.selectInList = function(list, select){
            var retlist = [];
            var self = this;

            _.each(list, function(obj){
                var objs = self.selectDownward(obj, select);
                if(!options.disableUpDownSelection){
                    _.each(objs, function(item){
                        self.selectTeamSpaceList(self.selectUpward(item));
                    });
                }
                self.selectTeamSpaceList(objs);
                retlist = _.concat(retlist, objs);
            });

            return self.cleanLinks(retlist);
        }

       this.disableEnableCheckboxDownward = function(obj, disable){
            var retList = [];

            function go(list){
                _.each(list, function(item){
                    if(item.objectBaseUuid == obj.objectBaseUuid && !item.disabled){
                        item.checkboxDisabled = disable;
                        retList.push(item);
                    }
                    if('children' in item && item.children.length > 0){
                        go(item.children);
                    }
                });
            }

            go(treeData);

            return retList;
        }

       this.disableEnableCheckboxUpward = function(obj){
            var retList = [];

            function go(obj){
                if('children' in obj && obj.children.length > 0 && _.filter(obj.children, function(item){ return item.checkboxDisabled == true && !item.disabled }).length == _.filter(obj.children, function(item){ return !item.disabled }).length){
                    obj.checkboxDisabled = true;
                    retList.push(obj);
                }
                else if('children' in obj && obj.children.length > 0 && _.filter(obj.children, function(item){ return item.checkboxDisabled == true && !item.disabled }).length != _.filter(obj.children, function(item){ return !item.disabled }).length){
                    obj.checkboxDisabled = false;
                    retList.push(obj);
                }
                if('parent' in obj){
                    go(obj.parent);
                }
            }

            go(obj);

            return retList;
        }

        this.disableEnableCheckboxTeamSpaceList = function(list){
            _.each(list, function(item){
                var obj = _.find(portalData, { objectBaseUuid : item.objectBaseUuid });
                if(obj){
                    !obj.disabled ? obj.checkboxDisabled = item.checkboxDisabled : null;
                }
            });
        }

        this.disableEnableCheckbox = function(obj, disable){
            var list = this.disableEnableCheckboxDownward(obj, disable);
            var self = this;
            _.each(list, function(item){
                self.disableEnableCheckboxTeamSpaceList(self.disableEnableCheckboxUpward(item));
            });
            self.disableEnableCheckboxTeamSpaceList(list);
        }

        this.cleanLinks = function(list){
            var list = angular.copy(list);
            _.each(list, function(item){
                item.parent = undefined;
                item.children = undefined;
            });

            return list;
        }
    }

    return SmartSelection;
}