AddFolderController.$inject = ['scope'];

function AddFolderController($scope)
{

	$scope.save = function()
    {
		$scope.selectionDone($scope.addFolderName);
    };

    $scope.cancel = function(){
    	$scope.cancelDone();
    }

}