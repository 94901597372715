function configTrashController($stateProvider){

	$stateProvider
		.state('home.trash', {
			url : '/trash',
			views: {
			      'content': {
			    	  templateUrl: 'modules/trash/Trash.tpl.html',
			    	  controller  : 'TrashController'
			      }
			}
		})

}

function TrashController($scope, $state, localizedMessages, loadRecycle, bvDialog, repositoryService, repositoryModelService, context, SortUtility, $timeout){

	$scope.repositoryDisplayObjects = {showTree:false, HIDE_CARET:true};

	$scope.middleBarObject.title = localizedMessages.get('TRASH');
	$scope.ui = {
			restore: localizedMessages.get('RESTORE')
	}

	$scope.currentInstance = "TRASH";
	var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);
	loadRecycle.loadRecyleData();

	var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
	var userTreeViewPref = localStorage.getItem(userTreeViewPrefKey);

	if(!userTreeViewPref)
	{
		userTreeViewPref = "MEDIUM";
		localStorage.setItem(userTreeViewPrefKey, userTreeViewPref);
	}
	$scope.repositoryDisplayObjects.displayType = userTreeViewPref;
	$scope.repositoryDisplayObjects.showTree = false;
	$scope.repositoryDisplayObjects.showFilter = false;
	$scope.repositoryDisplayObjects.disableRightClick = true;
	$scope.repositoryDisplayObjects.disableOpen = true;
	$scope.middleBarObject.showBack = true;

    $scope.svgFill = '#FFF';
	$scope.handleLeftIcon = function(){
	    $state.go('home.documents');
	}

	$scope.callParentController = function(type, args)
    {
    	switch (type)
    	{
//			case 'SHOW_HIDE_FILE_FOLDER':
//				load();
//				break;

			case 'TRASH_CHECKBOX':
				$scope.checkBoxClick();
				break;

			default:
				break;
		}
    }

	var emptyTrash = function(){
		bvDialog.confirm(localizedMessages.get('RECYCLE_BIN_ALERT_ARE_YOU_SURE_TO_DELETE'), function(){
			repositoryService.emptyTrash().then(function(){
				loadRecycle.loadRecyleData();
			});
		});
	}

	var toggleDropdownOptions = function($event) {
		$event.preventDefault();
	    $event.stopPropagation();
	    $scope.status.isopen = !$scope.status.isopen;
  	};


    var getDisplayOptionsForRepository = function()
	{
		var options = [];
 		options.push({name:localizedMessages.get("SMALL_TEXT"), value: "small"});
 		options.push({name:localizedMessages.get("MEDIUM_TEXT"), value: "medium"});
 		options.push({name:localizedMessages.get("LARGE_TEXT"), value: "large"});
		options.push({name:localizedMessages.get("SORT_BY_TYPE"), value: "extensionType"});
		options.push({name:localizedMessages.get("SORT_BY_NAME"), value: "name"});
		options.push({name:localizedMessages.get("SORT_BY_DATE_MODIFIED"), value: "lastModifiedOnGMTMillis"});

		return options;
	}

	var rightMenuList = [
		                    { title:localizedMessages.get('EMPTY_TRASH'), click: emptyTrash, iconUrl: '', svgFill: '#fff'},
		                    { title:localizedMessages.get('OPTIONS'),click: null, iconUrl: '', svgFill: '#fff', dropDownMenu : getDisplayOptionsForRepository()}
	]

	var goToDocuments = function(){
		$state.go('home.documents');
	}

	var goToAgendaTemplates = function(){
		$scope.middleBarObject.showBack = false;
		$state.go('home.agendatemplates');
	}

	$scope.tabs = [
	               	{ title:localizedMessages.get('DOCUMENTS'), content:'all' ,func: goToDocuments,rightMenuList:rightMenuList},
	               	{ title:localizedMessages.get('TRASH'), content:'all' ,rightMenuList:rightMenuList, active: true}
	]

    if(context.getModel().folderAgendaBuilderEnabled)
        $scope.tabs.push({ title: localizedMessages.get('AGENDA_TEMPLATES'), content: 'all', func: goToAgendaTemplates, rightMenuList: rightMenuList});

	var sorting = function(data, key, direction){
		var sort = function(a,b)
		{
    		var r = a[key].toLowerCase();
    		var p = b[key].toLowerCase();

    		if(key === 'lastMod'){
    			r = new Date(a[key]).getTime();
        		p = new Date(b[key]).getTime();
    		}

    		if(p>r){return -1;}
    		else if(r>p){return 1;}
    			return 0;
		}
		if(direction){
			return data.sort(sort);
		}
		else{
			return data.sort(sort).reverse();
		}
	}

	$scope.selectMenuItem = function(ob)
	{
		var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
		sessionStorage.setItem("documentsSortOrder", JSON.stringify(ob));

		if(ob.value == "small")
		{
			$scope.repositoryDisplayObjects.displayType = "SMALL";
			localStorage.setItem( userTreeViewPrefKey, "SMALL");
		}
		else if(ob.value == "medium")
		{
			$scope.repositoryDisplayObjects.displayType = "MEDIUM";
			localStorage.setItem( userTreeViewPrefKey, "MEDIUM");
		}
		else if(ob.value == "large")
		{
			$scope.repositoryDisplayObjects.displayType = "LARGE";
			localStorage.setItem( userTreeViewPrefKey, "LARGE");
		}
		else if(ob.value == "name")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['name', 'extensionType', 'lastModifiedOnGMTMillis']);
		}
		else if(ob.value == "extensionType")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['lastModifiedOnGMTMillis', 'extensionType', 'name']);
		}
		else if(ob.value == "lastModifiedOnGMTMillis")
		{
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['lastModifiedOnGMTMillis', 'extensionType', 'name']);
		}
		else
			repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ob.value);
	}

	var restore = function(targetFolder, selectedObjects, parent){
		rightMenuList[0].title = localizedMessages.get('EMPTY_TRASH');
		rightMenuList[0].click = emptyTrash;
		bvDialog.restoringFilesProgressDialog(targetFolder, selectedObjects);
	}

	var filter = function(item){
		return item.selected == true
	}

	$scope.restoreTo = function(){
		var parentContainer = "DOCUMENTS";
		var businessObjects = []

		businessObjects = repositoryModel.repositoryList().browseList.filter(filter);

		if(businessObjects.length > 0){
			bvDialog.openFolderChooserRestore(localizedMessages.get("RESTORE_TO"), restore, businessObjects, parentContainer);
		}
	}

	$scope.checkBoxClick = function(item){
		$timeout(function(){
			if(repositoryModel.repositoryList().browseList.filter(filter).length > 0){
				rightMenuList[0].title = localizedMessages.get('RESTORE');
				rightMenuList[0].click = $scope.restoreTo;
			}
			else{
				rightMenuList[0].title = localizedMessages.get('EMPTY_TRASH');
				rightMenuList[0].click = emptyTrash;
			}
		})
	}
}

angular
	.module('trash', [])
	.config(configTrashController)
	.controller('TrashController', ['$scope', '$state', 'localizedMessages', 'loadRecycle', 'bvDialog', 'repositoryService', 'repositoryModelService', 'context', 'SortUtility', '$timeout', TrashController])
