angular
.module('dialogControllers.service')
.controller('FolderAgendaCreatorController', FolderAgendaCreatorController);

FolderAgendaCreatorController.$inject = ['$scope', 'bvDialog', 'localizedMessages', 'repositoryService', 'imageService', 'commonService', 'context', '$timeout', 'bvSliderInstanceService'];

function FolderAgendaCreatorController($scope, bvDialog, localizedMessages, repositoryService, imageService, commonService, context, $timeout, bvSliderInstanceService){

    $scope.popupTitle   = localizedMessages.get('CREATE_AGENDA');
    $scope.actionBtnTxt = localizedMessages.get('CREATE');
    $scope.closeBtnTxt  = localizedMessages.get('CANCEL');
    $scope.stylesTxt = localizedMessages.get('STYLES');
    $scope.templatesTxt = localizedMessages.get('TEMPLATES');
    $scope.noTemplatesMsg = localizedMessages.get('NO_FOLDER_AGENDA_TEMPLATES_MSG');

    $scope.bvchecktrue  = 'resources/assets/svgs/bvchecktrue.svg';
    $scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    var objectBaseUuid = $scope.bvModalControllerInputs.objectBaseUuid;
    var parentObjectBaseUuid = $scope.bvModalControllerInputs.parentObjectBaseUuid;

    $scope.agenda_styles = [
        {
            id: 'simple',
            name: 'Simple',
            img: 'resources/assets/images/AgendaStyle_Simple.png',
        },
        {
            id: 'table',
            name: 'Table',
            img: 'resources/assets/images/AgendaStyle_Table1.png',
        },
        {
            id: 'bulleted',
            name: 'Bullet Points',
            img: 'resources/assets/images/AgendaStyle_Bulleted.png',
        },
        {
            id: 'numbered',
            name: 'Numbered',
            img: 'resources/assets/images/AgendaStyle_Numbered.png',
        }
    ];

    $scope.selectedStyle = 'simple';

    $scope.selectStyle = function(style) {
        $scope.selectedStyle = style;
    }

    $scope.action = function()
    {
        repositoryService.createAgendaForFolder(objectBaseUuid, parentObjectBaseUuid, $scope.selectedStyle, $scope.selectedAgendaTemplate.objectBaseUuid).then(function(data){
            console.log(data);
            bvModal.close();
        });
    };

    $scope.getDocumentThumbnail = function(templateDocument)
    {
        if(templateDocument.documentThumbnail) {
            return imageService.getThumbnailForObject(templateDocument, "large");
        }
        else
        {
            commonService.getDocumentThumbnail(templateDocument.objectBaseUuid, templateDocument.objectBaseUuid)
            .then(function(encodedImage)
            {
                templateDocument.documentThumbnail = encodedImage;
                return imageService.getThumbnailForObject($scope.selectedAgendaTemplate, "large");
            });
        }
    };

    $scope.selectedAgendaTemplate = {
        objectBaseUuid : "",
        documentThumbnail : ""
    };

    $scope.getDefaultFolderAgendaTemplate = function() {
        repositoryService.getDefaultAgendaTemplateForCurrentPortal().then(function(objectBaseUuid){
            console.log(objectBaseUuid);
            if(objectBaseUuid) {
                $scope.selectedAgendaTemplate.objectBaseUuid = objectBaseUuid;
                commonService.getDocumentThumbnail(objectBaseUuid, objectBaseUuid).then(function(encodedImage){
                    //console.log(encodedImage);
                    $scope.selectedAgendaTemplate.documentThumbnail = encodedImage;
                    $scope.selectedAgendaTemplate.documentThumbnail = imageService.getThumbnailForObject($scope.selectedAgendaTemplate, "large");
                    for(var i in $scope.agendaTemplates) {
                        if($scope.agendaTemplates[i].objectBaseUuid === $scope.selectedAgendaTemplate.objectBaseUuid) {
                            bvSliderService.currentIndex = i;
                        }
                    }
                });
            }
        });
    }

    var fixScroll = function() {
        $timeout(function(){
            bvSliderService.fixButtons();
        }, 200);
    }

    $scope.selectedTabIndex = 0;
    $scope.selectTab = function(index) {
        $scope.selectedTabIndex = index;
        if(index == 1) {
            fixScroll();
        }
    }

    var bvSliderService;

    var sliderRenderCallback = function(){
        bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptions.id];
        bvSliderService.currentIndex = 0;
    }

    $scope.sliderOptions = {
        id: 'FOLDER_AGENDA',
        items : [],
        shownItems: 1,
        slideThrough: 1,
        renderCallback : sliderRenderCallback
    }

    //
    //
    // $scope.sliderOptions = {
    //     items: [],
    //     slideThrough: 1,
    //     shownItems: 1
    // };

    $scope.agendaTemplates;
    var getAgendaTemplates = function ()
    {
        repositoryService.findAllAgendaTemplatesForCurrentPortal().then(function(data)
        {
            $scope.agendaTemplates = data;
            $scope.sliderOptions.items = $scope.agendaTemplates;
            $scope.getDefaultFolderAgendaTemplate();
        });
    };

    $scope.selectTemplate = function(templateDocument) {
        $scope.selectedAgendaTemplate = templateDocument;
    }

    getAgendaTemplates();
    fixScroll();
}
