function viewerBookmarkService(ajaxService){

    this.bookmarksList = []

    this.getTaggedPagesForBookmarks = function(documentBaseUuid, cb){
        const self = this;
        self.bookmarksList = []
        var request = ajaxService.getTaggedPagesForBookmarks(documentBaseUuid);
        return request.then(function(data) {
            if (data) {
                if(data.data && typeof data.data == "object"){
                    self.bookmarksList = data.data;
                    if(typeof cb == "function") cb(self.bookmarksList);
                }
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }

    this.tagPageForBookmkark = function(jsonString, cb){
        var request = ajaxService.tagPageForBookmkark(jsonString);
        return request.then(function(data) {
            if (data.data) {
                return data.data;
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }
}