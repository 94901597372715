BVPollingProcessor.$inject = ['$rootScope','registerStatusCategory','homeService','calendarService','homeModel','context','homeContext','mergeProgressService','repositoryModelService','repositoryService','eventBusService'];

function BVPollingProcessor($rootScope, RegisterStatusCategory,homeService, calendarService, homeModel, context, homeContext, mergeProgressService,repositoryModelService,repositoryService,eventBusService)
{
	var pollObjects = [];

	var ms = this;
	
	var callBackFunction = null;
	
	this.setIdleCheckCallback = function(funCallback) {
		callBackFunction = funCallback;
	}
	
	var invokeIdleCallback = function(message, value) {
		console.log("Callback fn: "+(callBackFunction==null?"null":"available")+" bool: "+value+" message:"+message);
		if(callBackFunction!=null) {
			callBackFunction(value);
		} 
	}

	this.registerForPolling = function(pollingListener, category)
	{
		pollObjects.push({"scope":pollingListener, "category":category});
	}

    this.unRegisterForPolling = function(pollingListener,category)
    {
        angular.forEach(pollObjects, function(value, key)
        {
            if(value.scope === pollingListener && value.category === category)
            {
                pollObjects.splice(key,1);
                return;
            }
        });
    }

    this.processPollingList = function(pollingMessage)
    {
    	if(pollingMessage != null && pollingMessage.length > 0)
        {
            angular.forEach(pollingMessage, function(sessionPollingVO, key)
            {
                console.log("SessionPollingVO",sessionPollingVO);
            	if(sessionPollingVO.category === RegisterStatusCategory.DOCUMENT || sessionPollingVO.category === RegisterStatusCategory.FOLDER)
        		{
            		handlePollingforRepository(sessionPollingVO);
        		}
            	else if(sessionPollingVO.category === RegisterStatusCategory.REMINDER)
        		{
            		handlePollingforReminder(sessionPollingVO);
        		}
          //   	else if(sessionPollingVO.category === RegisterStatusCategory.SECURE_CHAT)
        		// {
          //   		handlePollingforChatMessages(sessionPollingVO);
        		// }
            	else if(sessionPollingVO.category === RegisterStatusCategory.SHARED_WITH_ME)
        		{
            		handlePollingforInbox(sessionPollingVO);
        		}
            	else if(sessionPollingVO.category === RegisterStatusCategory.OPEN_APPROVAL)
        		{
            		handlePollingforApprovals(sessionPollingVO);
        		}
            	else if(sessionPollingVO.category === RegisterStatusCategory.CLOSE_APPROVAL)
        		{
            		handlePollingforApprovals(sessionPollingVO);
        		}
            	else if(sessionPollingVO.category === RegisterStatusCategory.REOPEN_SURVEY)
        		{
            		handlePollingforSurveys(sessionPollingVO);
        		}
            	else if(sessionPollingVO.category === RegisterStatusCategory.CLOSE_SURVEY)
        		{
            		handlePollingforSurveys(sessionPollingVO);
        		}
            	else if(sessionPollingVO.category === RegisterStatusCategory.COMMIT_SURVEY)
        		{
            		handlePollingforSurveys(sessionPollingVO);
        		}
                else if(sessionPollingVO.category === RegisterStatusCategory.VAULT_SYNC
                    || sessionPollingVO.category === RegisterStatusCategory.SIGNATURE_COMMIT)
                {
                	if("DOCUMENTS_ADDED_TO_FAVOURITES" == sessionPollingVO.message || "DOCUMENT_DELETED_FROM_FAVOURITES" == sessionPollingVO.message)
            		{
                		ms.handlePollingforBriefCase();
            		}

                    handlePollingforSignatures(sessionPollingVO);
                    
                    if(MobileUtil.isApp())
                    {
                    	startSyncForJup();
                    }
                }
				else if(sessionPollingVO.category === RegisterStatusCategory.MEETING_EDIT){
					if(MobileUtil.isApp() && MobileUtil.isJupOffline())
					{
						console.log("Meeting edit functionality is disabled.");
						return;
					}
					if(sessionPollingVO.message == "MEETING_EDIT_IN_PROGRESS"){
						handlePollingforMeetingEdit(sessionPollingVO)
					}
					if(sessionPollingVO.message == "MEETING_EDIT_END"){
						handlePollingforMeetingEdit(sessionPollingVO)
					}
				}
                else if(sessionPollingVO.category === RegisterStatusCategory.CONFERENCE)
                {
                    //COMMENTED FOR PRESENTER. DO NOT REMOVE
                    // console.log("SessionPollingVO",sessionPollingVO);

                	if(MobileUtil.isApp() && MobileUtil.isJupOffline())
                    {
                		console.log("Meeting presenter/passenger functionality is disabled.");
                		return;
                	}

                    if (sessionPollingVO.message=="CONFERENCE_STARTED")
                    {
                        console.log(sessionPollingVO);
                        invokeIdleCallback(sessionPollingVO.message,true);
                        handleStartConferencePolling();
                        //handleStartStopConferencePolling(true, sessionPollingVO);
                    }
                    else if (sessionPollingVO.message=="STOP_CONFERENCE")
                    {
                        console.log("STOP" , sessionPollingVO);
                        invokeIdleCallback(sessionPollingVO.message,false);
                        handleStopConferencePolling(sessionPollingVO);
                    }
                    else if (sessionPollingVO.message=="START_SHARING")
                    {
                        console.log("STARTED GETTING SHARING ALERTS");
                        invokeIdleCallback(sessionPollingVO.message,true);
                        //handleStartStopConferencePolling(false, sessionPollingVO);
                    }
                    else if (sessionPollingVO.message=="STOP_DOCUMENT_SHARING")
                    {
                        console.log("STOPED DOCUMENT SHARING ALERT");
                        invokeIdleCallback(sessionPollingVO.message,true);
                        handleDocumentSharingPolling(false);
                    }
                    else if (sessionPollingVO.message=="START_DOCUMENT_SHARING")
                    {
                        console.log("START DOCUMENT SHARING ALERT");
                        invokeIdleCallback(sessionPollingVO.message,true);
                        handleDocumentSharingPolling(true);
                    }
                    else if (sessionPollingVO.message=="REFRESH")
                    {
                        //console.log("=======STARTED REFRESH======");
//                    	invokeIdleCallback(sessionPollingVO.message,true); Don't need to change the IdleCallback for Refresh as it happens outside conference also
                        handleRefreshConferencePolling();
                    }
                    /*else if (sessionPollingVO.message=="STOP_SHARING")
                    {
                        handleStartStopConferencePolling(false, sessionPollingVO);
                    }*/
                    //handlePollingForConference();
                }
                else if((sessionPollingVO.category === RegisterStatusCategory.PUSH_CREATED) && (context.getModel().pushEnabled))
        		{
                    if(MobileUtil.isApp())
                    {
                        startSyncForJup();
                    }
                    handlePollingforPush(sessionPollingVO);
        		}
                else if((sessionPollingVO.category === RegisterStatusCategory.COMPANY_MERGE_STATUS))
        		{
                    handlePollingforCompanyMergeStatus(sessionPollingVO);
        		}
                else if((sessionPollingVO.category === RegisterStatusCategory.MEETX_DESKTOP_SYNC))
        		{
                	if(sessionPollingVO.message === "CALENDAR_UPDATE")
                	{
                		handlePollingforCalendar(sessionPollingVO);
                	}
                	else if(sessionPollingVO.message === "NEW_ITEMS_UPDATE")
                	{
                		ms.handlePollingforNewItems(sessionPollingVO);
                	}	
                	
                	if(MobileUtil.isApp())
                    {
                    	startSyncForJup();
                    }
                }
            })
        }
    }

    var startSyncForJup = function()
    {
    	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
        if(homeController)
    	{
        	homeContext.startSync = true;
    		homeController.startSyncForJup();
    	}
        return;
    }

    var handleDocumentSharingPolling = function (isStartDocumentSharing)
    {
        // var confPreDocCntrl = angular.element('#conf-prsnt-producer-cntrl').scope();
        // //console.log("##########PREDOCCNTRL",confPreDocCntrl);
        // if(confPreDocCntrl!=undefined)
        // {
        //     confPreDocCntrl.handleStartDocumentSharingFromPolling(isStartDocumentSharing);
        // }
        // return;

        var homeController = angular.element(document.querySelector('[home-controler]')).scope();

        if(isStartDocumentSharing) {
            if(homeController) homeController.handleStartDocumentSharingFromPolling();
        } else {
            if(homeController) homeController.handleStopDocumentSharingFromPolling();
        }
        return;
    }

    var handleRefreshConferencePolling = function ()
    {
        var homeController = angular.element(document.querySelector('[home-controler]')).scope();
        if(homeController) homeController.handleRefreshConferencePolling();
        return;
    }

    // var handlePollingforChatMessages = function ()
    // {
    // 	 var secureChatDetailsController = angular.element(document.querySelector('[securechatdetails-controller]')).scope();
    // 	 if(secureChatDetailsController)
    // 		 secureChatDetailsController.getMyUnReadSecureMessagesForChatGroup();

    // 	 var secureChatController = angular.element(document.querySelector('[securechat-controller]')).scope();
    // 	 if(secureChatController)
    // 		 secureChatController.getMySecureChatGroups();
    // 	 var homeController = angular.element(document.querySelector('[home-controler]')).scope();
    // 	 if(homeController)
    // 	 	 homeController.getNewMessagesCount();
    //      return;
    // }

    var handlePollingforInbox = function ()
    {
    	var messagesController = angular.element(document.querySelector('[messages-controller]')).scope();
    	if(messagesController){
    		messagesController.getInboxUnreadCount();
    		if((!MobileUtil.isMobileClient() && messagesController.tabs[0].active) ||
                        (MobileUtil.isMobileClient() && messagesController.inboxActive)) {
    			messagesController.getInboxJSON(true, true);
    		}
    	}
    	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
   	 	if(homeController)
   	 		homeController.getInboxUnreadCount();
        return;
    }

    var handlePollingforCalendar = function ()
    {
    	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
   	 	if(homeController)
   	 		homeController.getMeetingsCount();
        return;
    }

    var handlePollingforPush = function ()
    {
    	var pushController = angular.element(document.querySelector('[push-controller]')).scope();
    	if(pushController)
    	{
    		pushController.getObjectPushesSentToMe();
    		pushController.tabs[0].active = true;
    		pushController.tabs[1].active = false;
    	}
    	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
   	 	if(homeController)
   	 		homeController.getSentToMePushCount();
        return;
    }


    var handlePollingforSignatures = function (sessionPollingVO)
    {
        if(sessionPollingVO.message === "DOC_ANNOTATED" || sessionPollingVO.message === "SIGNATURE_COMMIT"
            || sessionPollingVO.message === "ANNOTATION_DELETED")
        {
            var signaturesController = angular.element(document.querySelector('[signatures-controller]')).scope();
            if(signaturesController)
            {
                signaturesController.getDocumentsForSignature();
                signaturesController.getDocumentsForSignatureTabs('open');
                signaturesController.getDocumentsForSignatureTabs('own');
            }

            var homeController = angular.element(document.querySelector('[home-controler]')).scope();
            if(homeController)
            {
                homeController.getSignaturesCount();
            }
        }
        return;
    }

    var handlePollingforApprovals = function (sessionPollingVO)
    {
    	var approvalsController = angular.element(document.querySelector('[approval-details-controller]')).scope();
    	if(approvalsController){
    		approvalsController.getMyOpenApprovals();
    	}
    	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
   	 	if(homeController)
   	 		homeController.getApprovalsCount();
        return;
    }

    var handlePollingforSurveys = function (sessionPollingVO)
    {
    	var surveysController = angular.element(document.querySelector('[open-survey-controller]')).scope();
    	if(surveysController){
    		surveysController.getMyOpenSurveys();
    	}
    	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
   	 	if(homeController)
   	 		homeController.getSurveysCount();
        return;
    }

    this.handlePollingforMeetingCenter = function(sessionPollingVO)
    {
    	var mcController = angular.element(document.querySelector('[meeting-controller]')).scope();
    	mcController.initCalendar();
    }

    this.handlePollingForCalendar = function(sessionPollingVO)
    {
    	var calendarLightController = angular.element(document.querySelector('[calendar-light-controller]')).scope();
    	calendarLightController.initCalendar();
    }

// new
    var handleStartConferencePolling = function()
    {
        var homeController = angular.element(document.querySelector('[home-controler]')).scope();
        if(homeController) homeController.showRunningConfList();
        return;
    }

    var handleStopConferencePolling = function(sessionPollingVO)
    {
        var homeController = angular.element(document.querySelector('[home-controler]')).scope();
        if(homeController) homeController.handleStopConferencePolling(sessionPollingVO);
        return;
    }


// old
    var handleStartStopConferencePolling = function (flag, sessionPollingVO)
    {
        var homeController = angular.element(document.querySelector('[home-controler]')).scope();
        if(homeController) homeController.showLivePresentationOptions(flag);
        return;
    }

    this.handlePollingforBriefCase = function(sessionPollingVO)
    {
    	var briefCaseController = angular.element(document.querySelector('[repository-controler]')).scope();
    	if(briefCaseController.currentInstance == "FAVORITES")
		{
    		briefCaseController.getDocumentsJSONForBriefCase();
		}
    }

    this.handlePollingforNewItems = function(sessionPollingVO)
    {
    	var newItemsController = angular.element(document.querySelector('[newitems-controller]')).scope();
    	if(newItemsController)
    	{	
    		newItemsController.getNewItems();
    	}
    	else
    	{
    		console.log("handlePollingforNewItems did not complete as newItemsController is undefined");
    		var newItemModel=repositoryModelService.getInstance("NEW_ITEM")
    		if(newItemModel.moduleClicked()!=="NEW_ITEM"){
    		var niService=repositoryService.getNewItemService();
	    	niService.loadLightWeightNewItemsJson()
	    	.then(function(response)
	    			{
	    				if(response.data.boName == "FailureBO") { return; }
	    				newItemModel.repositoryList(response.data);
	    			}
	    		 )
    		}		 
    	}
    }

	var handlePollingforMeetingEdit = function(sessionPollingVO)
	{
		if(sessionPollingVO.category == "MEETING_EDIT") {
			eventBusService.publish("MEETING_EDIT", sessionPollingVO);
		}
	}

    var handlePollingforRepository = function(sessionPollingVO)
    {
    	var repositoryController = angular.element(document.querySelector('[repository-controler]')).scope();

    	if(repositoryController && sessionPollingVO.category === RegisterStatusCategory.DOCUMENT &&  (sessionPollingVO.message=="DOC_UPDATED" || sessionPollingVO.message=="DOC_NEW_VERSION"))
    	{
	    	angular.forEach(repositoryController.repositoryList.browseList, function(businessObject)
	        {
	        	if(sessionPollingVO.objectUuid === businessObject.objectBaseUuid)
	        	{
	        		homeService.getBusinessObjectVOJSONByBaseuuid(businessObject.objectBaseUuid).then(function(data)
		        	{
		        		var updated = data.data;

		        		replaceBusinessObject(updated);
		        	});
	        		return;
		        }
	        });
	    }
	    else if(repositoryController && sessionPollingVO.category === RegisterStatusCategory.FOLDER)
	    {
	    	repositoryController.reloadFolder(sessionPollingVO.message);
	    }
	    else if(repositoryController && sessionPollingVO.subCategory === "CHECK_OUT")
	    {
	    	var message = sessionPollingVO.message.split("=");
	    	var userid = message[0];
    		var displayName = message[1];
	    	angular.forEach(repositoryController.repositoryList.browseList, function(businessObject)
	    	    	{
	    	    		if(sessionPollingVO.objectUuid === businessObject.objectBaseUuid)
	    	    		{
	    	    			businessObject.checkedOutBy = userid;
	    	    			businessObject.checkedOutByName = displayName;
	    	    			replaceBusinessObject(businessObject);
	    	    			return;
	    	    		}
	    	    	});
	    }
	    else if(repositoryController && (sessionPollingVO.subCategory === "CHECK_IN" || 
	    		sessionPollingVO.subCategory === "CANCEL_CHECK_OUT"))
	    {
	    	angular.forEach(repositoryController.repositoryList.browseList, function(businessObject)
	    	    	{
	    	    		if(sessionPollingVO.objectUuid === businessObject.objectBaseUuid)
	    	    		{
	    	    			businessObject.checkedOutBy = '';
	    	    			// businessObject.lastModifiedOnGMTMillis = sessionPollingVO.notifiedOn;
	    	    			replaceBusinessObject(businessObject);
	    	    			return;
	    	    		}
	    	    	});
	    }
	    else if(sessionPollingVO.subCategory == "DOC_EDIT_BEGIN")
	    {
	    	homeModel.editingDocs.push({"objectUuid":sessionPollingVO.objectUuid, "editingBy":sessionPollingVO.message});
	    	angular.forEach(repositoryController.repositoryList.browseList, function(businessObject)
	    	{
	    		if(sessionPollingVO.objectUuid === businessObject.objectBaseUuid)
	    		{
	    			businessObject.locked = true;
	    			businessObject.lockedCreatedBy = sessionPollingVO.message;
	    			replaceBusinessObject(businessObject);
	    			return;
	    		}
	    	});
	    }
	    else if(sessionPollingVO.subCategory == "DOC_EDIT_END" || sessionPollingVO.subCategory == "DOC_EDIT_CANCELLED")
	    {
			if(sessionPollingVO.subCategory == "DOC_EDIT_END") {
				eventBusService.publish("DOC_EDIT_END", sessionPollingVO.objectUuid);
			}
	    	for(var i=0; i<homeModel.editingDocs.length; i++)
		    {
			    if(sessionPollingVO.objectUuid == homeModel.editingDocs[i].objectUuid)
			    {

			    	homeModel.editingDocs.splice(i, 1);
			    }
		    }
	    	angular.forEach(repositoryController.repositoryList.browseList, function(businessObject)
	    	{
	    		if(sessionPollingVO.objectUuid === businessObject.objectBaseUuid)
	    		{
	    			businessObject.locked = false;
	    			businessObject.lockedCreatedBy = "";
                    if(businessObject.conversionStatus==='CONVERTING')
                    {
                        businessObject.conversionStatus = 'CONVERTED';
                        businessObject.swfConversionStatus = 'CONVERTED';
                    }
                    var obj = angular.copy(businessObject);
	    			replaceBusinessObject(obj);
	    			return;
	    		}
	    	});
	    }
    }
    var handlePollingforReminder = function(sessionPollingVO)
    {
    	var reminderController = angular.element(document.querySelector('[reminder-controler]')).scope();
    	if(sessionPollingVO.category === RegisterStatusCategory.REMINDER)
    	{
//    		if(sessionPollingVO.message=="REMINDER_ADDED")
//    		{
//    			reminderController.remindersList
//    		}
//	    	angular.forEach(reminderController.remindersList, function(businessObject)
//	    	        {
//	    	        	if(sessionPollingVO.objectUuid === businessObject.objectBaseUuid)
//	    	        	{
//	    	        		homeService.getBusinessObjectVOJSONByBaseuuid(businessObject.objectBaseUuid).then(function(data)
//	    		        	{
//	    		        		var updated = data.data;
//
//	    		        		replaceBusinessObject(updated);
//	    		        	});
//	    	        		return;
//	    		        }
//	    	        });
    	}
    }

    var replaceBusinessObject = function (updated)
	{
    	var repositoryController = angular.element(document.querySelector('[repository-controler]')).scope();
    	repositoryController.replaceDocument(updated);
	}

    var handlePollingforCompanyMergeStatus = function(pollingObject)
    {
    	//var mergeProgressController = angular.element(document.querySelector('[merge-progress-controller]')).scope();
    	//mergeProgressController.getLatestProgressStatus(updated);
    	mergeProgressService.getLatestProgressStatus(pollingObject.message);
    }

}