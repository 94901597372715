httpService.$inject = ['$http','$rootScope'];

function httpService($http,$rootScope) {
	// The public API of the service

//	var lruCache = $cacheFactory('lruCache', { capacity: 10 });

	var service = {
		httpLoginPost : function(postData)	{
			var headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			return $http({
				method: 'POST',
				url: '/login/ipadpublic',
				data: postData,
				headers: headers
			}).then(
				function(response) {
					return response;
				},
				function(error) {
					//$location.path('/bvadmin/logout');
				}
			);
		},
		httpRedirectLoginPost : function(postData, redirectUrl)	{
			var newUrl = redirectUrl ? redirectUrl : "";
			var headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			return $http({
				method: 'POST',
				url: newUrl + '/login/ipadpublic',
				data: postData,
				headers: headers,
				withCredentials: true,
			}).then(
				function(response) {
					return response;
				},
				function(error) {
					//$location.path('/bvadmin/logout');
				}
			);
		},
		httpServicePost : function(postData, uniq)	{
			return this.httpServicePostWithURL(postData, uniq, '/services/html');
		},
		httpServicePollPost : function(postData, uniq)	{
			return this.httpServicePostWithURL(postData, uniq, '/services/htmlpoll');
		},
		httpServicePostWithURL : function(postData, uniq, URL)	{
			var headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			if(StringUtils.isNullEmpty($rootScope.SELECTED_PORTAL_BASEUUID))
			{
                return $http({
                    method: 'POST',
                    url: URL,
                    data: postData,
                    headers: headers,
                    cache: true,
                }).then(
                    function(response) {
                        if(response.data  && response.data.requestCsrfToken)
                        {
                                localStorage.requestCsrfToken = response.data.requestCsrfToken;
                        }
                        return response;
                    }
                );
            }
            else
            {
                return $http({
                    method: 'POST',
                    url: URL,
                    data: postData,
                    headers: headers,
                    cache: true,
                    uniq : uniq,
                    params: {
                        ecxportalid : $rootScope.SELECTED_PORTAL_BASEUUID
                    }
                }).then(
                    function(response) {
                        if(response.data  && response.data.requestCsrfToken)
                        {
                                localStorage.requestCsrfToken = response.data.requestCsrfToken;
                        }
                        return response;
                    }
                );
            }
		},
		httpValidateCertificatePost : function()	{
			var headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			return $http({
				method: 'POST',
				url: '/login/cert',
				headers: headers,
				cache: true,
			});
		},
		httpVirtualServicePost : function(postData)	{
			var headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			return $http({
				method: 'POST',
				url: '/bvedit2/virtualEdit',
				data: postData,
				headers: headers,
				cache: true,
			});
		},
		httpVirtualEndServicePost : function(postData)	{
			var headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			return $http({
				method: 'POST',
				url: '/bvedit2/virtualConnection',
				data: postData,
				headers: headers,
				cache: true,
			});
		},
		httpRedirectServicePost : function(postData, redirectUrl)	{
			var newUrl = redirectUrl ? redirectUrl : "";
			var headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			return $http({
				method: 'POST',
				url: newUrl + '/services/html',
				data: postData,
				headers: headers,
				withCredentials: true,
				cache: true
//				cache: lruCache
			});
		},
		httpReportServiceGet : function(resourceUrl)	{
			var headers = {'Accept' : 'application/json', 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};
			return $http({
				method: 'GET',
				url: '/api/reports/' + resourceUrl,
				headers: headers,
				withCredentials: true,
				cache: false
			});
		},
		httpReportServicePost : function(postData, resourceUrl)	{
			var headers = {'Accept' : 'application/json', 'Content-Type': 'application/json;charset=UTF-8'};
			return $http({
				method: 'POST',
				url: '/api/reports/' + resourceUrl,
				data: postData,
				headers: headers,
				withCredentials: true,
				cache: false
			});
		}
	};
	return service;
}