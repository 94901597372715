bvSvgDirective.$inject = ['localizedMessages'];

function bvSvgDirective(localizedMessages){
	return {
		restrict: 'E',
		link: function(scope, element, attrs){
			var name = attrs.name;

			if(name && name != "")
			{
				name = localizedMessages.get(name.toUpperCase());
				attrs.$set("aria-label", name);
			}
		}
	}
}