function configAdvancedSearch($stateProvider){

	$stateProvider
		.state('home.adsearch', {
			url : '/adsearch',
			views: {
			      'content': {
			    	  templateUrl: 'modules/advancedsearch/advancedsearch.tpl.html',
			    	  controller  : 'advancedSearchController'
			      }
			}
		})

}