function bvScrollListTable(){
	return {
		restrict: 'A',
		//replace: true,
		scope: {
			scrollOptions: '=bvScrollListTable'
		},
		templateUrl: 'directives/bvscrolllisttable/bvScrollListTable.template.html',
		link: function(scope, el, attrs){

            scope.checkBoxItemClick = function(column, item){
                if('rows' in column && 'click' in column.rows){
                    column.rows.click(item);
                }
            }

            scope.deleteBtn = function(item){
                if('deleteBtn' in scope.scrollOptions && 'click' in scope.scrollOptions.deleteBtn){
                    scope.scrollOptions.deleteBtn.click(item);
                }

                _.remove(scope.scrollOptions.data, {objectBaseUuid: item.objectBaseUuid});
            }
         }
	}
}
