function disableDoubleClick($timeout){
	var delay = 500;
	return {
		restrict: 'A',
		priority: -1,
    	link: function (scope, elem)
    	{
        	var isDisabled = false;

            function onClick(evt)
            {
            	if (isDisabled) {
                	evt.preventDefault();
                	evt.stopImmediatePropagation();
            	}
            	else
            	{
                	isDisabled = true;
                	$timeout(function () { isDisabled = false; }, delay, false);
            	}
        	}

        	scope.$on('$destroy', function () { elem.off('click', onClick); });
        	elem.on('click', onClick);
    	}
	};
}