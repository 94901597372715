
angular
.module('agendaTemplates', [])
.config(configAgendaTemplatesController)
.controller('AgendaTemplatesController', AgendaTemplatesController)
// .service('uploadFileService', [UploadFileService])

function configAgendaTemplatesController($stateProvider){
    $stateProvider
    .state('home.agendatemplates', {
        url : '/agendatemplates',
        views: {
            'content': {
                templateUrl: 'modules/repository/Repository.tpl.html',
                controller  : 'AgendaTemplatesController'
            }
        }
    })
}

AgendaTemplatesController.$inject = ['$scope', '$state', 'localizedMessages', 'bvDialog', 'repositoryService', 'repositoryModelService', 'context', 'SortUtility', '$timeout', 'homeModel', 'bvDialogService', 'treeService', 'permissionService'];

function AgendaTemplatesController($scope, $state, localizedMessages, bvDialog, repositoryService, repositoryModelService, context, SortUtility, $timeout, homeModel, bvDialogService, treeService, permissionService)
{
    $scope.repositoryDisplayObjects = {showTree:false, HIDE_CARET:true};
    $scope.middleBarObject.title = localizedMessages.get('AGENDA_TEMPLATES');
    $scope.ui = {
    };

    $scope.currentInstance = "AGENDA_TEMPLATES";
    var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

    var agendaTemplates = [];

    var selectAll = function()
    {
        homeModel.selectedAgendaTemplatesOption = "ALL";
        filterData = agendaTemplates;
        $scope.repositoryDisplayObjects.noAgendas = filterData.length == 0 ? true : false;
        repositoryModel.repositoryList(filterData);
        setTitle();
    };

    $scope.callParentController = function(type, args)
    {
        switch (type)
        {
            case "MAKE_DEFAULT":
                getDefaultTemplateForCurrentPortal();
                break;
            default:
                break;
        }
    }

    var selectCurrent = function()
    {
        homeModel.selectedAgendaTemplatesOption = "CURRENT";
        filterData = StringUtils.filterBOForSelectedObjectBaseUuid(agendaTemplates, context.getModel().portalVO.objectBaseUuid);
        $scope.repositoryDisplayObjects.noAgendas = filterData.length == 0 ? true : false;
        repositoryModel.repositoryList(filterData);
        setTitle();
    };

    var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
    var userTreeViewPref = localStorage.getItem(userTreeViewPrefKey);
    console.log("USER TREE PREF:",userTreeViewPref);

    if(!userTreeViewPref)
    {
        userTreeViewPref = "LARGE";
        localStorage.setItem(userTreeViewPrefKey, userTreeViewPref);
    }

    $scope.repositoryDisplayObjects.displayType = userTreeViewPref;

    var treeSelectionModel = treeService.getInstance($scope.currentInstance);
    var repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

    $scope.showTree = false;

    $scope.selectMenuItem = function(ob)
    {
        bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Agenda, bvUsageUtils.const.Entity.Action.Agenda.MenuAction, ob.value);
        var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
        sessionStorage.setItem("documentsSortOrder", JSON.stringify(ob));

        if(ob.value == "small")
        {
            $scope.repositoryDisplayObjects.displayType = "SMALL";
            localStorage.setItem( userTreeViewPrefKey, "SMALL");
        }
        else if(ob.value == "medium")
        {
            $scope.repositoryDisplayObjects.displayType = "MEDIUM";
            localStorage.setItem( userTreeViewPrefKey, "MEDIUM");
        }
        else if(ob.value == "large")
        {
            $scope.repositoryDisplayObjects.displayType = "LARGE";
            localStorage.setItem( userTreeViewPrefKey, "LARGE");
        }
        else if(ob.value == "name")
        {
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['name', 'extensionType', 'lastModifiedOnGMTMillis']);
        }
        else if(ob.value == "extensionType")
        {
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['lastModifiedOnGMTMillis', 'extensionType', 'name']);
        }
        else if(ob.value == "lastModifiedOnGMTMillis")
        {
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['lastModifiedOnGMTMillis', 'extensionType', 'name']);
        }
        else {
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ob.value);
        }
    }

    $scope.onUploadComplete = function()
    {
        console.log("From Agenda Templates : Success Upload Complete");
        getAgendaTemplatesFolder();
        //ngDialog.close();

    }

    var openDialogForUpload = function()
    {
        var uploadFileObjects = {};

        uploadFileObjects.action = "agenda_template";
        uploadFileObjects.parentId = $scope.agendaTemplateFolderUuidForCurrentPortal;
        uploadFileObjects.onUploadComplete = $scope.onUploadComplete;
        bvDialogService.uploadFile(uploadFileObjects);
    };

    var uploadAgendaTemplate = function() {
        $scope.listofUploadFiles = [];
        openDialogForUpload();
    }

    var rightMenuList = [];
    // var options = [];
    // options.push({name:localizedMessages.get("CURRENT_TEAMSPACE"), value: "currentTS"});
    // options.push({name:localizedMessages.get("ALL_TEAMSPACES"), value: "showAll"});
    // var settingOption = { title:localizedMessages.get('SHOW'),click:'', iconUrl: '', svgFill: '#fff', dropDownMenu:options};

    var fileOption = { title:localizedMessages.get('UPLOAD_AGENDA_TEMPLATE'), click: uploadAgendaTemplate, iconUrl: '', svgFill: '#fff'};

    // rightMenuList = [fileOption, settingOption];

    if(context.getModel().isUserPortalAdmin === 'TRUE'|| permissionService.isOwner(context.getModel().companyVO))
        rightMenuList = [fileOption];

    $scope.agendaTemplateFolderUuidForCurrentPortal = "";

    var getAgendaTemplatesFolder = function() {
        repositoryService.getAgendaTemplatesFolderUuidForCurrentPortal().then(function(data){
            $scope.agendaTemplateFolderUuidForCurrentPortal = data;
            getAgendaTemplates();
        });
    }

    var getAgendaTemplates = function ()
    {
        repositoryService.findAllAgendaTemplatesForCurrentPortal().then(function(data)
        {
            agendaTemplates = data;
            if(StringUtils.isNullEmpty(homeModel.selectedAgendaTemplatesOption) || homeModel.selectedAgendaTemplatesOption == "ALL")
            {
                filterData = agendaTemplates;
            }
            else
            {
                filterData = StringUtils.filterBOForSelectedObjectBaseUuid(agendaTemplates, context.getModel().portalVO.objectBaseUuid);
            }
            $scope.repositoryDisplayObjects.noAgendas = filterData.length == 0 ? true : false;
            repositoryModel.repositoryList(filterData);
        });
    };

    var filterData	= [];
    var setTitle = function() {

        // if(StringUtils.isNullEmpty(homeModel.selectedAgendaTemplatesOption) || homeModel.selectedAgendaTemplatesOption == "ALL")
        // {
        //     if(MobileUtil.isMobileClient())
        //         $scope.middleBarObject.title = localizedMessages.get('AGENDA_TEMPLATES')+": "+localizedMessages.get('ALL');
        //     else
        //         $scope.middleBarObject.title = localizedMessages.get('ALL');
        //
        //     $scope.repositoryDisplayObjects.showPortalName = true;
        // }
        // else
        // {
        //     if(MobileUtil.isMobileClient())
        //         $scope.middleBarObject.title = localizedMessages.get('AGENDA_TEMPLATES')+": "+localizedMessages.get('CURRENT');
        //     else
        //         $scope.middleBarObject.title = localizedMessages.get('CURRENT');
        //
        //     $scope.repositoryDisplayObjects.showPortalName = false;
        // }

        $scope.middleBarObject.title = localizedMessages.get('CURRENT');
    }

    var goToDocuments = function(){
        $state.go('home.documents');
    }

    var goToTrash = function(){
        $scope.middleBarObject.showBack = false;
        $state.go('home.trash');
    }

    $scope.tabs = [
        { title: localizedMessages.get('DOCUMENTS'), content: 'all', func: goToDocuments, rightMenuList: rightMenuList },
        { title: localizedMessages.get('TRASH'), content: 'all', func: goToTrash, rightMenuList:rightMenuList },
        { title: localizedMessages.get('AGENDA_TEMPLATES'), content: 'all', rightMenuList: rightMenuList, active: true }
    ]

    var sorting = function(data, key, direction){
        var sort = function(a,b) {
            var r = a[key].toLowerCase();
            var p = b[key].toLowerCase();
            if(key === 'lastMod') {
                r = new Date(a[key]).getTime();
                p = new Date(b[key]).getTime();
            }
            if(p>r) { return -1; }
            else if(r>p) { return 1; }
            return 0;
        }
        if(direction) {
            return data.sort(sort);
        }
        else {
            return data.sort(sort).reverse();
        }
    }

    var getDefaultTemplateForCurrentPortal = function() {
        repositoryService.getDefaultAgendaTemplateForCurrentPortal().then(function(data) {
            if(repositoryService.getDefaultAgendaTemplateBaseUuid() !== "" && data != null && repositoryService.getDefaultAgendaTemplateBaseUuid() !== data) {
                bvDialog.showMessage(localizedMessages.get("DEFAULT_AGENDA_TEMPLATE_SET"));
            }
            repositoryService.setDefaultAgendaTemplateBaseUuid(data);
            console.log(repositoryService.getDefaultAgendaTemplateBaseUuid());
        });
    }

    setTitle();
    getAgendaTemplatesFolder();
    getDefaultTemplateForCurrentPortal();
    // getAgendaTemplates();
    //createMenuForAgendaTemplatesController();
}
