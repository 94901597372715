function configReportsController($stateProvider, $locationProvider){
	
}

ReportsController.$inject = ['$scope', '$state', 'context', 'reportsService', 'localizedMessages'];

function ReportsController($scope, $state, context, reportsService, localizedMessages){
	var getReportsListRestUrl = 'getReports';
	$scope.generateReport = localizedMessages.get('GENERATE_REPORT');
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.middleBarObject.title = localizedMessages.get('REPORTS');
	//$scope.middleBarObject.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
	$scope.middleBarObject.showBack = false;
	$scope.getReports;
	$scope.tabs = [
			{title : $scope.middleBarObject.title, content : $scope.middleBarObject.title, active: true, rightMenuList:''}
		];
	$scope.init = function(){
		reportsService.getReports('', getReportsListRestUrl).then(function(response) {
			if(response){
				$scope.getReports = response;
				$.each($scope.getReports, function (i,report) {
					report.description=localizedMessages.get(report.stateName.toUpperCase()+"_"+"DESCRIPTION");
					report.name=localizedMessages.get(report.stateName.toUpperCase());
			
				});
			}
		});
	}
		
	$scope.init();
	$scope.openReport = function(stateName){
		$state.go('home.reports.'+stateName);
	}
}

