angular
    .module('dialogControllers.service')
	.controller('documentViewerActionsController', documentViewerActionsController);

documentViewerActionsController.$inject = ['$scope', 'localizedMessages', 'repositoryService', 'bvDialogMobile', 'bvDialog'];

function documentViewerActionsController($scope, localizedMessages, repositoryService, bvDialogMobile, bvDialog) {
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var businessObject = $scope.bvModalControllerInputs.businessObject;
	$scope.popupTitle = localizedMessages.get("ACTIONS");
	$scope.addToBriefcaseLabel = localizedMessages.get('ADD_TO_MY_BRIEFCASE');
	$scope.sendLabel = localizedMessages.get("SEND");
	$scope.propertiesLabel = localizedMessages.get("PROPERTIES");
	$scope.addLabel = localizedMessages.get("ADD");
	$scope.rightArrow = "resources/assets/svgs/arrow.svg";
	$scope.documentActionsAddClicked = function(){
    	if(businessObject != null)
    	{
        	var documentDetails = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(businessObject);
        	
        	if(documentDetails != null)
        	{
            	repositoryService.addToBriefCase(documentDetails);
            	this.businessObject = null;
            }
        }
	};
	$scope.documentActionsSendClicked = function(){
		
	};
	$scope.documentActionsPropertiesClicked = function(){
		bvDialog.showProperties(businessObject);
	};
	$scope.close = function(result)
	{
		if(businessObject.isAndroid)
		{
			setTimeout(function(){
				Android.showHideViewerToolbar(true);
			}, 50);
		}
		bvModal.close();
	};
}