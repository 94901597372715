function advancedSearchService(localizedMessages){
    this.middleBarObject = {}

    this.updateMiddleBar = function(adSearch){
        var title = {
            last: localizedMessages.get('LAST'),
            days: localizedMessages.get('DAYS'),
            resultFor: localizedMessages.get('RESULTS_FOR')
        }

        this.middleBarObject.title =  adSearch.items.length + " " + title.resultFor + " " +  adSearch.searchString;
        if('advancedSearchData' in adSearch.filterPanelInstance && adSearch.filterPanelInstance.generateTitle() != '')
            this.middleBarObject.title = this.middleBarObject.title + ' : ' + adSearch.filterPanelInstance.generateTitle();
    }
}