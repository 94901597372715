function ActiveWindowsController($scope, repositoryService, localizedMessages, $state, repositoryModelService, loadActiveWindows, bvDialog) 
{	
		var bvModal = $scope.bvModalControllerInputs.bvModal;
		$scope.popupTitle = localizedMessages.get('ACTIVE_WINDOWS');
		
		loadActiveWindows.loadWindowsData();
		$scope.parentBaseUuid = "activeWindows";
		$scope.hideCheckBox = true;
		$scope.hideBy = true;
		
		$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
		
		$scope.handleItemClick = function(selectedDoc)
		{
			loadActiveWindows.updateWindowsData(selectedDoc.objectBaseUuid, bvModal, false);
			repositoryService.openPdfViewer(selectedDoc.objectBaseUuid);
			bvModal.close();
		}

		$scope.handleRemoveItem = function(selectedDoc)
		{
			loadActiveWindows.updateWindowsData(selectedDoc.objectBaseUuid, bvModal, true);
		}

		$scope.tabs = [];
    	$scope.tabEditable = true;
    	$scope.showTree = false;
    	
    	$scope.titles = [
    	    localizedMessages.get("NAME"),
    	    localizedMessages.get("CLOSE")
    	];
    	
    	$scope.scrollListOptions = {
    		columns : [
	    		        { title : localizedMessages.get("NAME"), width : 84, name : 'name' },
	    		        { title : "", width : 31, name : 'close' }
    		],
    		data : loadActiveWindows.getWindowsData(),
    		showColumns : false
    	}
}

function LoadActiveWindows(repositoryService, repositoryModelService, localizedMessages)
{
	var objectData = {};
	var homeScope = angular.element(document.querySelector('[home-controler]')).scope();

	var service = {
		loadWindowsData : function(){
			objectData.listOfDocuments = homeScope.activeDocumentList;
		},
		getWindowsData : function(){
			return objectData;
		},
		updateWindowsData : function(documentUuid, bvModal, doClose){
			objectData.listOfDocuments = objectData.listOfDocuments
							               .filter(function (el) {
							                    return el.objectBaseUuid !== documentUuid;
							                });

			homeScope.updateActiveDocumentList(objectData.listOfDocuments);

			if(objectData.listOfDocuments.length == 1 && doClose)
			{
				bvModal.close();
			}
		}
	}
		
	return service;
}

angular
.module('activeWindows',[])
.service('loadActiveWindows', ['repositoryService','repositoryModelService', 'localizedMessages', LoadActiveWindows])
.controller('activeWindowsController', ['$scope','repositoryService', 'localizedMessages','$state', 'repositoryModelService','loadActiveWindows', 'bvDialog', ActiveWindowsController]);
	