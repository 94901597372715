function boardbookService(homeService){

    var list = []

    this.getList = function(){
        return list;
    }

    this.addToList = function(obj){
        list.push(obj);
    }

    this.refreshLogos = function(){

        list.forEach(function(object){
            object.render();
        });

    }
}