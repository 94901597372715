bvSliderController.$inject = ['$scope', '$element', '$window', '$timeout', 'bvSliderInstanceService', 'bvSliderService', 'homeService', '$rootScope'];

function bvSliderController($scope, $element, $window, $timeout, bvSliderInstanceService, bvSliderService, homeService, $rootScope) {

	if ('id' in $scope.data && $scope.data.id) {
		bvSliderInstanceService.addInstance($scope.data.id);
		bvSliderService = bvSliderInstanceService.instances[$scope.data.id];
	}

	bvSliderService.element = $element;

	var defaultSlideThrough = 3;
	var defaultSlideTime = 3;
	var defaultShownItems = 3;
	var defaultSmallButtons = false;
	var fixQuantity = false;
	var autoSlideFirst = false;
	
	
	

	$scope.svgFill = "#000";

	'slideThrough' in $scope.data ? null : $scope.data.slideThrough = defaultSlideThrough;
	'slideTime' in $scope.data ? null : $scope.data.slideTime = defaultSlideTime;
	'shownItems' in $scope.data ? null : $scope.data.shownItems = defaultShownItems;
	'smallButtons' in $scope.data ? null : $scope.data.smallButtons = defaultSmallButtons;
	'fixQuantity' in $scope.data ? null : $scope.data.fixQuantity = fixQuantity;
	'autoSlideFirst' in $scope.data ? null : $scope.data.autoSlideFirst = autoSlideFirst;

	if ($scope.data.smallButtons) {
		$scope.leftIcon = 'resources/assets/svgs/leftArrow.svg';
		$scope.rightIcon = 'resources/assets/svgs/rightArrow.svg';
	}
	else {
		$scope.leftIcon = 'resources/assets/svgs/leftCalendarArrow.svg';
		$scope.rightIcon = 'resources/assets/svgs/rightCalendarArrow.svg';
	}

	bvSliderService.showIcon.showLeftIcon = false;
	bvSliderService.showIcon.showRightIcon = false;
	bvSliderService.fixQuantityFlag = $scope.data.fixQuantity;

	bvSliderService.shownItems = $scope.data.shownItems;
	$scope.$watch('data.shownItems', function(newValue, oldValue) {
		if (newValue != oldValue) {
			bvSliderService.shownItems = $scope.data.shownItems;
		}
	});
	





	$scope.$watch('data.items', function(newValue, oldValue) {
		if (newValue != undefined && newValue != oldValue) {
			bvSliderService.numberOfItems = newValue.length;
			if (newValue.length >= oldValue.length) {
				if (newValue.length - oldValue.length > 3) {
					bvSliderService.newItemsShowRightIcon = true;
				}
			}
			else if (bvSliderService.numberOfItems > 3) {
				bvSliderService.newItemsShowRightIcon = true;
			}
			else {
				bvSliderService.newItemsShowRightIcon = false;
			}


			$timeout(function() {
				if ($scope.data.fixQuantity) {
					bvSliderService.fixQuantity();
				}
				bvSliderService.fixScroll();
				bvSliderService.fixButtons();
			}, 100);
		}
	}, true);

	var win = angular.element($window);
	win.bind("resize", function(e) {
		//    	MobileUtil.isPortret() ? homeService.homeContext.expandedMenu = true : homeService.homeContext.expandedMenu = false;
	var currentStateValue = 	document.getElementById('bvContainer').parentNode.getAttribute('state');
	console.log(currentStateValue +' currentStateValue');
		if (MobileUtil.isMobileClient()) {
			$rootScope.isMobile = true;
			if (!MobileUtil.isPhone()) {
				//homeService.homeContext.expandedMenu = (e.target.innerWidth < e.target.innerHeight);
			}
			///    		!MobileUtil.isPhone() ? homeService.homeContext.expandedMenu = false : homeService.homeContext.expandedMenu = true;
		} else {
			$rootScope.isMobile = false;
			if(currentStateValue){
				if(currentStateValue !== 'home.dashboard'){
					homeService.homeContext.expandedMenu = true;
				}
			}else{
				homeService.homeContext.expandedMenu = true;
			}
			
			
		}
		if ($scope.data.fixQuantity) {
			bvSliderService.fixQuantity();
		}
		bvSliderService.fixScroll();
		$scope.$apply();
	})

	$scope.bvSliderService = bvSliderService;
	//    setTimeout(function(){
	//    	 bvSliderService.fixButtons();
	//    },0)

	var hideButtons = function(left, right) {
		if (left != null && bvSliderService.showIcon.showLeftIcon != left) {
			bvSliderService.showIcon.showLeftIcon = left;
			setTimeout(function() {
				$scope.$apply();
			}, 0)
		}
		if (right != null && bvSliderService.showIcon.showRightIcon != right) {
			bvSliderService.showIcon.showRightIcon = right;
			setTimeout(function() {
				$scope.$apply();
			}, 0)
		}
	}

	var stop = {}
	stop.val = false;

	//	$scope.leftBtnClick = function(ttt){
	//		if(MobileUtil.isMobileClient()){

	//			if(!bvSliderService.inprogress){
	//				bvSliderService.numberOfItems =  bvSliderService.element.children()[0].children.length;
	//				bvSliderService.currentIndex--;
	//
	//				bvSliderService.inprogress = true;
	//				var el = $element[0].children[0];
	//				var scrollLeft = el.scrollLeft;
	//				var offsetWidth = bvSliderService.getScrollWidthElement(el); //el.scrollWidth/el.children.length;
	//
	//				setTimeout(function(){
	//					smoothScrollH(el,scrollLeft - (offsetWidth * ($scope.data.slideThrough || defaultSlideThrough)), $scope.data.slideTime || defaultSlideTime, null, true).then(function(){
	//						bvSliderService.fixButtons($scope);
	//
	//						bvSliderService.inprogress = false;
	//					}, function(){
	//						bvSliderService.inprogress = false;
	//					});
	//				},0)
	//			}else{
	//				return;
	//			}
	//		}
	//	}

	//	$scope.rightBtnClick = function(event){
	//		if(MobileUtil.isMobileClient()){

	//			if(!bvSliderService.inprogress){
	//				bvSliderService.numberOfItems =  bvSliderService.element.children()[0].children.length;
	//				bvSliderService.currentIndex++;
	//
	//				bvSliderService.inprogress = true;
	//				var el = $element[0].children[0];
	//				var scrollLeft = el.scrollLeft;
	//				var offsetWidth = bvSliderService.getScrollWidthElement(el); //el.scrollWidth/el.children.length;
	//
	//				setTimeout(function(){
	//					smoothScrollH(el,scrollLeft + (offsetWidth * ($scope.data.slideThrough || defaultSlideThrough)), $scope.data.slideTime || defaultSlideTime, null, true).then(function(){
	//					//smoothScrollH(el, el.scrollWidth,1000, stop).then(function(){
	//						bvSliderService.fixButtons($scope);
	//
	//						bvSliderService.inprogress = false;
	//					}, function(){
	//						bvSliderService.inprogress = false;
	//					});
	//				},0)
	//			}else{
	//				event.stopPropagation()
	//			}
	//		}
	//	}

	var el = $element[0].children[0];

	//if(!MobileUtil.isMobileClient()){
	var prevCurrentIndex;
	$scope.currentIndex=bvSliderService.currentIndex;
	$scope.mouseLeftDown = function() {
		if (!stop.val) {
			SmoothScroll.smoothScrollH(el, 0, 3, stop).then(
				resolve => console.log(),
				reject => console.log()
			);
		}
		prevCurrentIndex = bvSliderService.currentIndex;
		bvSliderService.newItemsShowRightIcon = true;
		bvSliderService.leftClick=true;
	}
	
	/*	$scope.$watch(bvSliderService.currentIndex, function(newValue, oldValue) {
		if (newValue != oldValue) {
			$scope.$emit('sliderMousePressedEvent', bvSliderService.currentIndex);
		}
	});
*/
	$scope.mouseLeftUp = function() {
		stop.val = true;
		bvSliderService.getPositionElement(el);
		if (prevCurrentIndex == bvSliderService.currentIndex) {
			bvSliderService.currentIndex--;
		}

		SmoothScroll.smoothScrollH(el, bvSliderService.getScrollWidthElement(el) * bvSliderService.currentIndex, 3, null, false).then(function() {
			bvSliderService.fixButtons(bvSliderService.scope);
		});

		setTimeout(function() {
			stop.val = false;
		}, 50);
	}

	$scope.mouseRightDown = function() {
		if (!stop.val) {
			SmoothScroll.smoothScrollH(el, el.scrollWidth, 3, stop).then(
				resolve => console.log(),
				reject => console.log()
			);
		}
		prevCurrentIndex = bvSliderService.currentIndex;

	}

	$scope.mouseRightUp = function() {
		stop.val = true;
		bvSliderService.getPositionElement(el);
		$scope.$emit('sliderMousePressedEvent', bvSliderService.currentIndex);
		if (prevCurrentIndex == bvSliderService.currentIndex) {
			bvSliderService.currentIndex++;
		}
		$scope.$emit('sliderNextClick', bvSliderService.currentIndex);

		SmoothScroll.smoothScrollH(el, bvSliderService.getScrollWidthElement(el) * bvSliderService.currentIndex, 3, null, false).then(function() {
			bvSliderService.fixButtons(bvSliderService.scope);
		});

		setTimeout(function() {
			stop.val = false;
		}, 50);
	}
	//}

	bvSliderService.scope = $scope;

	setTimeout(function() {
		var interval;
		var check = false;
		var holded = false;
		var body = document.body;
		var scroll = 0;
		var ele = $element.children()[0];
		bvSliderService.numberOfItems = ele.children.length;
		var scrollLeft = 0;

		if (ele) {
			ele.addEventListener('touchstart', function(e) {
				check = false;
				holded = true;

				if (!body.classList.contains('disable-hover')) {
					body.classList.add('disable-hover')
				}
			})

			//bvSliderService.fixButtons($scope);

			ele.addEventListener('touchend', function(e) {
				holded = false;
				bvSliderService.fixButtons($scope);
			})

			ele.addEventListener('scroll', function() {
				if (!bvSliderService.inprogress) {
					scroll = parseInt(ele.scrollLeft);
					if (ele.scrollLeft == 0 || ele.scrollWidth - ele.scrollLeft <= ele.offsetWidth + 5) {
						bvSliderService.getPositionElement(ele)
						bvSliderService.fixButtons($scope);
					}
					else {
						if (!check) {
							var interval = setInterval(function() {
								if (check && scroll == 0) {
									SmoothScroll.smoothScrollH(ele, bvSliderService.getPositionElement(ele) * bvSliderService.getScrollWidthElement(ele), 3)
										.then(function() {
											bvSliderService.fixButtons($scope);
											bvSliderService.callBack();
										});
									body.classList.remove('disable-hover');
									scroll = 0
									clearInterval(interval)
								}
								scroll = 0
							}, 150);
							check = true;
						}
					}
				}
			})
		}
	}, 300)

	if ('renderCallback' in $scope.data && $scope.data.renderCallback instanceof Function) {
		$scope.data.renderCallback();
	}
}

var SliderService = function() {
	this.currentIndex = 0;
	this.showIcon = {};
	this.element = {};
	this.shownItems = 3;
	this.inprogress = false;
	this.fixQuantityFlag = false;
	this.numberOfItems = 0;
	this.callBack = function() { };
	this.scope = {};

	return {
		currentIndex: this.currentIndex,
		showIcon: this.showIcon,
		element: this.element,
		shownItems: this.shownItems,
		inprogress: this.inprogress,
		fixQuantityFlag: this.fixQuantityFlag,
		numberOfItems: this.numberOfItems,
		callBack: this.callBack,
		scope: this.scope,

		getScrollWidthElement: function(el) {
			var w = el.offsetWidth;
			var sW = el.scrollWidth;
			var nE = el.children.length;

			var pL = parseFloat(window.getComputedStyle(el).paddingLeft);
			var pR = parseFloat(window.getComputedStyle(el).paddingRight);

			return (sW - (pL + pR)) / nE;
		},

		getPositionElement: function(el) {
			var swE = this.getScrollWidthElement(el);
			var p = 0;
			if (swE) {
				var nE = el.children.length;
				var slE = el.scrollLeft;

				for (var i = 0; i <= nE; i++) {
					if (slE > (i * swE) - swE / 2) {
						p = i;
					}
				}
				this.currentIndex = p;
			}
			return p;
		},

		fixScroll: function(smooth) {
			var self = this;
			if (this.element[0]) {
				var el = this.element[0].children[0];
				var offsetWidth = this.getScrollWidthElement(el);
				var currentIndex = this.currentIndex;

				if (smooth) {
					//setTimeout(function(){
					SmoothScroll.smoothScrollH(el, offsetWidth * currentIndex, 3, null, false).then(function() {
						//bvSliderService.fixButtons($scope);
						self.fixButtons(self.scope);
					});
					//},0)
				}
				else {
					this.element[0].children[0].scrollLeft = offsetWidth * this.currentIndex;
				}
				//				.then(function(){
				//					//this.fixButtons();
				//					//bvSliderService.fixButtons($scope);
				//				});

			}
		},

		fixButtons: function(applyScope) {
			var el = this.element[0].children[0];
			this.showIcon.showLeftIcon = this.currentIndex > 0 ? true : false;
			this.showIcon.showRightIcon = this.currentIndex < this.numberOfItems - 1 ? true : false;
			if (el.scrollLeft <= 1) {
				this.showIcon.showLeftIcon = false;
			}
			else {
				this.showIcon.showLeftIcon = true;
			}
			if (el.offsetWidth + el.scrollLeft >= el.scrollWidth - 1) {
				this.showIcon.showRightIcon = false;
			}
			else {
				this.showIcon.showRightIcon = true;
			}
			if (applyScope) { applyScope.$apply() }
		},

		fixQuantity: function(menuExpanded) {
			if (this.element[0] && this.fixQuantityFlag) {
				var itemWidth = 195;
				var el = this.element[0].children[0];
				if (menuExpanded == undefined || menuExpanded == null) {
					this.shownItems = parseInt(this.element[0].offsetWidth / itemWidth);
				} else {
					if (menuExpanded) {
						this.shownItems = parseInt((this.element[0].offsetWidth - 282) / itemWidth);
					} else {
						this.shownItems = parseInt((this.element[0].offsetWidth + 282) / itemWidth);
					}
				}

				for (var i = 0; i < el.children.length; i++) {
					el.children[i].style.width = parseInt(100 / this.shownItems) + '%';
				}
				//			if(this.shownItems > el.children.length){
				//				this.fixButtons();
				//			}
			}
		}
	}

}