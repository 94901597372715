PollingService.$inject = [ 'ajaxService'];

function PollingService(ajaxService)
{
	var lastSetOfPollingMessages = [];

	var service =
	{
		getSessionPollingMessages:function(acknowledgedMessages, accessFlag, logs)
		{
			var request=ajaxService.getSessionPollingMessages(acknowledgedMessages, accessFlag, logs);

			return request.then(function(data)
			{
				if(data)
				{
					return data;
				}
				return null;

			});
		},
		updateSessionPollingForMeetingEditEnd:function(meetingId)
		{
			var request=ajaxService.updateSessionPollingForMeetingEditEnd(meetingId);

			return request.then(function(data)
			{
				if(data)
				{
					return data;
				}
				return null;

			});
		},

		startMeetingEditPolling: function(meetingDetails)
		{
			var request = ajaxService.startMeetingEditPolling(meetingDetails);

			return request.then(function(data)
			{
				if(data)
				{
					return data;
				}
				return null;

			})
		},
		getJupOnlineStatus:function()
		{
			var request=ajaxService.getJupOnlineStatus();

			return request.then(function(data)
			{
				if(data)
				{
					return data;
				}
				return null;

			});
		}
		/*,
		getOnlineStatus:function()
		{
			var request=ajaxService.getOnlineStatusJSON();

			return request.then(function(data)
			{
				if(data)
				{
					return data;
				}
				return null;

			});
		}*/
	}

	return service;
}