CalendarControllerLight.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'calendarService', 'localizedMessages', 'modelService', 'commonService', 'context', 'bvLoadingSpinner', 'bvDialog', 'bvSliderService', 'bvDialogMobile', 'calendarServiceContext','homeContext', '$timeout', '$log'];

function CalendarControllerLight($scope, $rootScope, $state, $stateParams, calendarService, localizedMessages, modelService, commonService, context, bvLoadingSpinner, bvDialog, bvSliderService, bvDialogMobile, calendarServiceContext,homeContext, $timeout, $log){

	var currentState = $state.$current.name;
	$scope.$parent.currentState.value = currentState;
	$rootScope.SELECTED_CALENDAR_STATE_NAME = currentState;

	$scope.leftAtrrowIcon = 'resources/assets/svgs/leftCalendarArrow.svg';
	$scope.rightAtrrowIcon = 'resources/assets/svgs/rightCalendarArrow.svg';
	$scope.middleBarObject.showBack = false;
	var canCurrentUserCreateModule = false;
	var config = "false";
	calendarService.setIsCurrentUserAllowedToCreateModule(false);
//	if(context.getModel().isUserPortalAdmin == "true" || context.getModel().isUserPortalAdmin == true || context.getModel().isUserPortalAdmin == "TRUE"){
//		calendarService.setIsCurrentUserAllowedToCreateModule(true);
//	}
//	else{
		commonService.checkModuleCreatorsConfigPresentJSON("MEETINGS").then(function(data){
			if(data && data.data && data.data.name == "isModuleCreatorsPrefAllowed"){
				config = data.data.value;
				if(config == "true" || config == true){
					commonService.getModuleCreatorsForSpecificModuleJSON("MEETINGS").then(function(mod){
						if(mod.data){
							var moduleCreators = angular.fromJson(mod.data);
							if(moduleCreators.users != undefined && moduleCreators.users.length>0){
								for(var i=0;i<moduleCreators.users.length;i++){
									if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
										canCurrentUserCreateModule = true;
										calendarService.setIsCurrentUserAllowedToCreateModule(canCurrentUserCreateModule);
										break;
									}
									calendarService.setIsCurrentUserAllowedToCreateModule(canCurrentUserCreateModule);
								}
							}
						}
					});
				}
				else{
					calendarService.setIsCurrentUserAllowedToCreateModule(true);
				}
			}
		});
//	}

	$scope.initCalendar = function()
	{
		$scope.showNewMeetingDialog = false;
		var currentState = $state.$current.name;
		$rootScope.SELECTED_CALENDAR_STATE_NAME = currentState;
		$rootScope.SELECTED_CALENDAR_MODULE_TITLE = StringUtils.capitaliseFirstLetter(currentState.split("home.")[1]);
		$scope.middleBarObject.title = $rootScope.SELECTED_CALENDAR_MODULE_TITLE;
		//$scope.events = calendarService.getAllCalendarEvents();
		calendarService.getListUpdateEvents();
		$scope.uintToRgb = StringUtils.uintToRgb;
		$scope.teamSpaceName = context.getModel().portalVO.name;
		//console.log("CURRENT STATE:",$scope.currentState)
		$scope.tabs = getTabs();
		$scope.$parent.currentState.value = currentState;
		console.log("calendarSlider: "+ $scope.calendarSlider);
	}

	$scope.sliderOptionsCalendar = {
			items :  calendarServiceContext.list,
			shownItems: 1,
			slideThrough: 1,
			autoSlideFirst: true
	}

	$scope.tileCheckBox = { title : localizedMessages.get("PUBLISH")  , show : false , active : false };

	$scope.$watch('expandedMenu', function(newValue, oldValue) {
		$scope.sliderOptionsCalendar.shownItems = 1;
		var id;
		if(newValue != oldValue)
		{
			clearTimeout(id);
    		id = setTimeout(function(){
					bvSliderService.fixScroll();
			}, 100);
		}
	});

	var cancelCreateNewMeetingEvent = function()
	{
		$scope.handleLeftIcon();
	}

	var createNewMeetingEvent = function()
	{
		var meetingController = angular.element(document.querySelector('[bvMeetingEditViewDiv]')).scope();
		meetingController.save();
	}

	$scope.meetingSaveCallback = function(meetingObjectBaseUuid)
	{
		$scope.showNewMeetingDialog = false;
		$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
		$scope.meetingObj.editFlag	= false;
		$scope.initCalendar();
		//$state.go($rootScope.SELECTED_CALENDAR_STATE_NAME);
		//$state.go('home.calendar');
	}

	var getTabs = function ()
	{
		var rightMenuAll = [
		                     { title:localizedMessages.get('CURRENT'), click: today, iconUrl: '', svgFill: '#fff'}
						   ];
	    var tabs = [];
	    tabs.push({ title:( $rootScope.SELECTED_CALENDAR_STATE_NAME=='home.calendar' ? localizedMessages.get('MONTHLY_VIEW') : localizedMessages.get('MEETING_VIEW')),   content:'', 	rightMenuList:rightMenuAll});
		return tabs;
	}

	$scope.handleLeftIcon = function()
	{
		$scope.showNewMeetingDialog = false;
		$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
		$scope.tileCheckBox.active = false;
		$scope.tabs = getTabs();
		$timeout(function(){
			bvSliderService.fixScroll();
		});
	}

	$scope.toggleActive = function()
   	{
   		$scope.meetingObj.isActive = !$scope.meetingObj.isActive

   		$scope.tileCheckBox.active = $scope.meetingObj.isActive;
   	}

	var newMeeting = function(cell)
	{
		if($scope.showNewMeetingDialog)
		{
			$scope.middleBarObject.title = localizedMessages.get('MEETINGS');
			$scope.showNewMeetingDialog = false;
		}
		else
		{
			$scope.meetingObj	=	modelService.getMeetingCenterObject();
			var d = moment(cell.date)._d;

			//var currentDateFormat = ((d.getMonth()+1)<10 ? "0"+(d.getMonth()+1):(d.getMonth()+1)) + "." + d.getDate() + "." + d.getFullYear();

			var currentMonth = ((d.getMonth()+1)<10 ? "0"+(d.getMonth()+1):(d.getMonth()+1));
			var currentDate	 = d.getDate() < 10 ? ("0"+d.getDate()) : d.getDate();
			var currentYear  = d.getFullYear();

			var currentDateFormat =  currentMonth + "/" + currentDate  + "/" + currentYear;

			var newStartDateFormat = moment(currentDateFormat).format("ll");
			var newStartDateFormatLocal = StringUtils.momentL(currentDateFormat).format("ll");

			$scope.meetingObj.editFlag			= false;
			$scope.meetingObj.startingDate 		= newStartDateFormat;
			$scope.meetingObj.startingDateLocal	= newStartDateFormatLocal;
			$scope.meetingObj.endingDate		= newStartDateFormat;
			$scope.meetingObj.endingDateLocal	= newStartDateFormatLocal;

			var timeNow = new Date();
			timeNow.setMilliseconds(Math.round(timeNow.getMilliseconds() / 1000) * 1000);
			timeNow.setSeconds(Math.round(timeNow.getSeconds() / 60) * 60);
			timeNow.setMinutes(Math.round(timeNow.getMinutes() / 30) * 30);
			$scope.meetingObj.startingTime          = moment(timeNow).format('hh:mm A');
			$scope.meetingObj.startingTimeLocal     = StringUtils.momentL(timeNow).format('hh:mm A');
			timeNow.setMinutes(timeNow.getMinutes() + 30);
			$scope.meetingObj.endingTime            = moment(timeNow).format('hh:mm A');
			$scope.meetingObj.endingTimeLocal       = StringUtils.momentL(timeNow).format('hh:mm A');

			var attendeeObj = {
				objectBaseUuid		: context.getModel().userVO.objectBaseUuid,
				name				: context.getModel().userVO.name,
				isPresenter			:false,
				isOwner				:true,
				isOnBehalf			:true,
				response			:"accepted"
			};
			$scope.meetingObj.attendeesList.push(attendeeObj);

			$scope.showNewMeetingDialog = true;
			$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
			$scope.svgFill = '#FFF';
			var tabs = [];
			var rightMenuSave =   [ ];
			tabs.push({ title:( $rootScope.SELECTED_CALENDAR_STATE_NAME=='home.calendar' ? localizedMessages.get('MONTHLY_VIEW') : localizedMessages.get('MEETING_VIEW')),   content:'', 	rightMenuList:rightMenuSave   });
			$scope.tabs = tabs;
		}
	}

	var today = function(){
		//var el = document.querySelector('.parent');
		//smoothScrollH(el,(el.offsetWidth - 9) * 6, 500);
		bvSliderService.currentIndex = context.getModel().htmlCalendarRangeBackward;
		bvSliderService.fixScroll(true);
	}

	$scope.showCurrentMeeting = function(){
		today();
	}

	var rightMenuAll = [
	                     { title:localizedMessages.get('CURRENT'), click: today, iconUrl: '', svgFill: '#fff'}
	                   ];

	$scope.middleBarObject.title = localizedMessages.get('CALENDAR');
    var tabs = [];
    tabs.push({ title: ( $rootScope.SELECTED_CALENDAR_STATE_NAME=='home.calendar' ? localizedMessages.get('MONTHLY_VIEW') : localizedMessages.get('MEETING_VIEW')),   content: '', 	rightMenuList:rightMenuAll});

    $scope.tabs = tabs;
	$scope.list = calendarService.getList();
	//$scope.buildingCalendar = calendarService.buildingCalendar(true);
	bvLoadingSpinner.start();

	$scope.left = function(){
		var el = document.querySelector('.parent');
		var scrollLeft = el.scrollLeft;
		var offsetWidth = el.offsetWidth;
		SmoothScroll.smoothScrollH(el,scrollLeft - (offsetWidth + 10), 300).then(function(){
			SmoothScroll.smoothScrollH(el,scrollLeft - (offsetWidth - 9), 300);
		});
	}
	$scope.right = function(){
		var el = document.querySelector('.parent');
		var scrollLeft = el.scrollLeft;
		var offsetWidth = el.offsetWidth;
		SmoothScroll.smoothScrollH(el,scrollLeft + (offsetWidth + 10), 300).then(function(){
			SmoothScroll.smoothScrollH(el,scrollLeft + (offsetWidth - 9), 300);
		});
	}

	$scope.clickEvent = function(event, cell)
	{
		if('events' in cell && cell.events && cell.events.length > 0 && cell.current){
			if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
				bvDialogMobile.eventsDialog(event, cell);
			}
			else{
				bvDialog.eventsDialog(event, cell);
			}
		}
//		var events = [];
//		var eventsForDay = cell.events;
//		if(eventsForDay!=null && eventsForDay!=undefined)
//		{
//			for (var i = 0; i < eventsForDay.length; i++)
//			{
//			    if(eventsForDay[i].data)
//					events.push(eventsForDay[i].data);
//			}
//		}
//		var SELECTED_EVENT = null;
//		var dateValue = moment(cell.date).format('MMMM D, YYYY');
//		if(eventObj!=null && eventObj!=undefined && eventObj.data!=undefined)
//		{
//			SELECTED_EVENT = eventObj.data.objectBaseUuid;
//		}
//		var EVENTS_LIST = { "DATE" : dateValue, "EVENTS" : events || eventObj,  "SELECTED_EVENT" : SELECTED_EVENT};
//		$rootScope.EVENTS_LIST = EVENTS_LIST;
//		EVENTS_LIST.EVENTS.length > 0 ? $state.go('home.meetingDetails') : null;
    }

	$scope.clickNewMeeting = function(cell){
		if(!calendarService.isCurrentUserAllowedToCreateModule()){
			return;
		}
		if(cell.current){
			newMeeting(cell);
		}
	}

	setTimeout(function(){
		calendarService.getList(true);
		$scope.$apply();
	},0);

	$scope.MobileUtil = MobileUtil;
	$scope.isAppOffline=(MobileUtil.isDesktopClient() || MobileUtil.isMobileClient())&&(!homeContext.onlineStatus.val)

    // 3D Carousel
    //===================================

    var vm = this;

	var currentMonth = new Date().getMonth()+1;
	$scope.calendarSlider = calendarServiceContext.list;
	$scope.sliderOptions = {
    		visible: 3,
            perspective: 35,
            startSlide: currentMonth,
            border: 0,
            width: 480,
            height: 480,
            space: 220,
            loop: false,
            controls: true
    }
    vm.selectedClick = selectedClick;
    vm.slideChanged = slideChanged;
    vm.beforeChange = beforeChange;
    vm.lastSlide = lastSlide;


    function lastSlide(index) {
        $log.log('Last Slide Selected callback triggered. \n == Slide index is: ' + index + ' ==');
    }

    function beforeChange(index) {
        $log.log('Before Slide Change callback triggered. \n == Slide index is: ' + index + ' ==');
    }

    function selectedClick(index) {
        $log.log('Selected Slide Clicked callback triggered. \n == Slide index is: ' + index + ' ==');
    }

    function slideChanged(index) {
        $log.log('Slide Changed callback triggered. \n == Slide index is: ' + index + ' ==');
    }
}

angular
	.module('calendarLight', ['calendar.service', 'localization.service', 'angular-carousel-3d' ])
	.controller('CalendarControllerLight', CalendarControllerLight)
