function discussionsPanel(scrollUtility, $timeout) {
	return {
		restrict: 'E',
		templateUrl: 'directives/discussions/discussions.template.html',
		controller: 'discussionsController',
		scope: false,
		link: function (scope, elem, attrs) {

            var tempDiv, theTextArea, addAnnotation, usersList;

            tempDiv = $(elem).find('#temp');
            theTextArea = $(elem).find('#theTextArea');
            addAnnotation = $(elem).find('#add-annotation');
            usersList = $(elem).find('.user-comments-list')[0];

			angular.element(usersList).bind("scroll", function () {
				handleScroll();
			});

			scope.$on("rendering-done", function (event, args) {
				handleScroll();
			});

			angular.element(window).bind('resize',
				function() {
					handleScroll();
				});

			function setMinSizeMode() {
				if (!scope.isFullSize) {
					return;
				}
				theTextArea.animate({ height: '49px' }, 50, function () {
					scope.safeApply(function () {
						scope.isFullSize = false;
					});
				});
			}

			function setFullSizeMode() {
				if (scope.isFullSize) {
					return;
				}
				theTextArea.animate({ height: '100px' }, 50, function () {
					scope.safeApply(function () {
						scope.isFullSize = true;
					});
				});
			}
	
			scope.onFocus = function () {
				setFullSizeMode();
			}

			scope.onBlur = function () {
				if (!isInViewportTop(tempDiv[0])) {
					setMinSizeMode();
				}
			}

			scope.safeApply = function (fn) {
				var phase = this.$root.$$phase;
				if (phase === '$apply' || phase === '$digest') {
					if (fn && (typeof (fn) === 'function')) {
						fn();
					}
				} else {
					this.$apply(fn);
				}
			};

			scope.showTypeahead = function (index) {
				slideDown('#typeahead' + index);
			}

			scope.hideTypeahead = function (index) {
				slideUp('#typeahead' + index);
			}
			scope.showChildTypeahead = function (index) {
				slideDown('#childTypeahead' + index);
			}

			scope.hideChildTypeahead = function (index) {
				slideUp('#childTypeahead' + index);
			}
			scope.$on("replyCommentSubmit", function (event) {
				slideUp('.replyTypeahead');
			});
			scope.$on("initiateReplySubmit", function (event) {
				slideUp('.initiateReplyTypeahead');
			});

			scope.$on("scrollTo", function (event, args) {
				scrollUtility.scrollTo(args.id, args.extraOffset);
			});

			function slideUp(selectror) {
				var el = $(elem).find(selectror);
				if (!el) {
					return;
				}
				el.slideUp("slow", function () {});
			}

			function slideDown(selectror) {
				var el = $(elem).find(selectror);
				if (!el) {
					return;
				}
				el.slideDown("slow", function () {
					scrollIntoView(el[0]);
				});
			}

			function scrollIntoView(element) {
				if (!isInViewport(element)) {
					element.scrollIntoView(false);
				}
			}

			function handleScroll() {
			    if(usersList && usersList.scrollTop){
                    var onBottom = usersList.scrollTop + usersList.clientHeight === usersList.scrollHeight;
                    if (isInViewportTop(tempDiv[0]) || onBottom) {
                        setFullSizeMode();
                    } else {
                        setMinSizeMode();
                    }
				}
			}
			
			function isInViewportTop(element) {
				if (!element) {
					return true;
				}
				var addAnnotationHeight = addAnnotation && addAnnotation[0] ? addAnnotation[0].offsetHeight : 0;
				var rect = element.getBoundingClientRect();
				return (
					rect.top <= (window.innerHeight - addAnnotationHeight));
			}

			function isInViewport(element) {
				if (!element) {
					return true;
				}
				var addAnnotationHeight = addAnnotation && addAnnotation[0] ? addAnnotation[0].offsetHeight : 0;
				var rect = element.getBoundingClientRect();
				return (
					rect.top >= 0 &&
						rect.left >= 0 &&
						rect.bottom <= (window.innerHeight - addAnnotationHeight));
			}
		}
	};
}

function highlightedText() {
	return {
		restrict: 'AE',
		template: '<p ng-bind-html="ngModel|replaceHighlightPattern"></p>',
		scope: {
			ngModel: '='
		}
	};
}
