function bvScrollList(){
	return {
		restrict: 'A',
		transclude: true,
		scope: {
			data: '=bvScrollList'
		},
		replace: true,
		templateUrl: 'directives/bvscrolllist/BvScrollList.template.html',
		controller: 'bvScrollListController'
	}
}