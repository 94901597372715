function MergeCompanyController($scope, $state, $stateParams, localizedMessages, homeService, bvDialog){
	
	$scope.ui = {
			label : {
						userID			: localizedMessages.get('USER_ID'),
						title			: localizedMessages.get("ENTER_USER_ID"),
						code			: localizedMessages.get("ENTER_CODE")
			}
	};
	
	$scope.ddUser = {};
	$scope.error = {};
	$scope.messageObj = {};
	$scope.actionBtnTxt = localizedMessages.get('OK');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.popupTitle 	= "Migrate Director Desk Company";
	
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	
	if($scope.bvModalControllerInputs.isDeviceReg)
	{
		$scope.CURRENT_DISPLAY_STATE = "LOGIN";
	}
	else
	{
		$scope.CURRENT_DISPLAY_STATE = "ENTER_EMAIL";
	}
	
	$scope.action = function()
	{
		if($scope.CURRENT_DISPLAY_STATE == "ENTER_EMAIL")
		{
			homeService.generateRegistratonCode($scope.ddUser.emaild).then(function(data)
			{					
				if(data.data.boName == "FailureBO"){
					
					if(data.message && data.message.indexOf("HTTP response code: 500") != -1)
					{
						bvDialog.showMessage("Invalid user emailid or url");
					}
					else
					{
						bvDialog.showMessage(data.data.message);
					}
					
				}
				else{
					$scope.CURRENT_DISPLAY_STATE = "ENTER_CODE"
				}	
				
			});
		}
		else if($scope.CURRENT_DISPLAY_STATE == "ENTER_CODE"){
			homeService.registerDeviceForDirectorDesk($scope.ddUser.code).then(function(data)
			{
				if(data.data.boName == "FailureBO"){
					bvDialog.showMessage(data.data.message);
				}
				else{
						$scope.CURRENT_DISPLAY_STATE = "LOGIN"
						$scope.actionBtnTxt = "Start Migrate";
						$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
				}
					
			});
		}
		else if($scope.CURRENT_DISPLAY_STATE == "LOGIN"){
			$scope.actionBtnTxt = "Start Migrate";
			$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');	
			homeService.loginToDirectorDesk($scope.ddUser.loginId, $scope.ddUser.password).then(function(data)
			{
				if(data.data.boName == "FailureBO"){
					bvDialog.showMessage(data.data.message);
				}
				else{
						homeService.startMergingCompany().then(function(data)
						{
							var cmd = data.data;
							if(cmd.boName == "FailureBO"){
								if(cmd.message != "CompanyMergeDetails is null")
								{
									bvDialog.showMessage(cmd.message);
								}			
							}
							
						});	
						bvModal.close();
						
						bvDialog.showMergeProgress();
				}				
			});	
		}
	}	
}

function mergeProgressService()
{
	this.getLatestProgressStatus = function(){};
}

function MergeProgressController($scope, $state, $stateParams, localizedMessages, homeService, bvDialog, $timeout, mergeProgressService){
	
	$scope.ui = {
			label : {
						userID			: localizedMessages.get('USER_ID')						
			}
	};
	
//	var progressState = ['/STS/Service.svc/Tokens','/RestServices/Main.svc/UserAccess/','/RestServices/Migration.svc/GetCompany/',
//						'/RestServices/Migration.svc/GetGroups/','/RestServices/Migration.svc/GetMemberships/','/RestServices/Migration.svc/GetUsers/',
//						'/RestServices/Migration.svc/GetDocuments','/RestServices/Migration.svc/GetFile/?fileid'];
	
	var connectionState = {'url':'Tokens','displayName':'Establishing connection'};
	var companyData = {'url':'GetCompany','displayName':'Migrating company data'};
	var boardData = {'url':'GetGroups','displayName':'Migrating groups'};
	var userData = {'url':'GetUsers','displayName':'Migrating users'};
	var calendarData = {'url':'GetCalendarEvents','displayName':'Migrating calendar events'};
	var docState = {'url':'GetFile','displayName':'Migrating documents'};
	
	$scope.mergeProgress = {};
	$scope.mergeProgress.status = [ ];
	
	var stateArray = [connectionState, boardData, userData, calendarData, docState]; 
	
	var stateKeyMap = {};
	
	for (var count = 0, len = stateArray.length; count < len; count++) {
		
		stateKeyMap[stateArray[count].url] = count;
		
		var ob = stateArray[count];
		
		ob.progress = 0;
		
		$scope.mergeProgress.status.push(ob);
	}
	
	$scope.popupTitle 	= "Migration Progress";
	
	var currentState = stateArray[0].url;
	
	$scope.getLatestProgressStatus = function(message) {
		
		if(StringUtils.isNullEmpty(message)){
			return;
		}
		
		var selectedObject = getUpdatedObject(message);
		
		if(StringUtils.isNullEmpty(selectedObject)){
			return;
		}
		
		if(selectedObject.url == "GetUsers")
		{
			message = message.substring(message.lastIndexOf('CREATED_USER')+13,message.length);
			
			selectedObject.progress = calculateProgressVal(message);
		}
		else if(selectedObject.url == "GetCalendarEvents")
		{
			message = message.substring(message.lastIndexOf('CREATED_EVENT')+13,message.length);
			
			selectedObject.progress = calculateProgressVal(message);
		}
		else if(selectedObject.url == "GetGroups")
		{
			selectedObject.progress = 100;
		}
		else if(selectedObject.url == "GetFile")
		{
			selectedObject.progress = getDocumentStatus(message);
		}
//		else
//		{
//			if(StringUtils.isNullEmpty(selectedObject.bvProcessStartedOnlong))
//			{
//				selectedObject.bvProcessStartedOnlong = $scope.mergeProgress.status[0].bvProcessStartedOnlong;
//				selectedObject.bvProcessStartedOn = $scope.mergeProgress.status[0].bvProcessStartedOn;
//			}
//			
//			getStatusValue(selectedObject);
//		}
		
	}
	
	mergeProgressService.getLatestProgressStatus = $scope.getLatestProgressStatus;
	
	homeService.getStausValueForMerge(currentState).then(function(data){
		checkAndLoadData(data.data);
	});
	
	var getStatusValue = function(lastObject){
		
		homeService.getMergeStatusForCompanyUsingStateandTime(lastObject.url, lastObject.bvProcessStartedOnlong)
		.then(function(data)
		{
			checkAndLoadData(data.data);
		});
	}
	
	var checkAndLoadData = function(cmd){
		
		if(cmd.boName == "FailureBO")
		{
			if(!StringUtils.isNullEmpty(cmd.message) && cmd.message.indexOf("COMPANY_MIGRATION_DETAILS_IS_NULL") == -1)
			{
				bvDialog.showMessage(cmd.message);
			}			
		}
		else
		{							
			if(cmd.request.indexOf('GetFile') != -1)
			{
				loadStatusForDocuments(cmd);				
			}
			else
			{
				loadStatus(cmd);				
			}
		}		
	}
	
	var loadStatusForDocuments = function (cmd)
	{
		var selectObject = getUpdatedObject(cmd.request);
				
		selectObject.bvProcessStartedOn = cmd.bvProcessStartedOn;
		selectObject.bvProcessStartedOnlong = cmd.bvProcessStartedOnlong;
		
		selectObject.progress = getDocumentStatus(cmd.request);
	}
	
	function getDocumentStatus(fileRequest)
	{
		fileRequest = fileRequest.substring(fileRequest.lastIndexOf('Downloaded')+11,fileRequest.length);
		
		return calculateProgressVal(fileRequest);
	}
	
	function calculateProgressVal(messageValue)
	{
		var valueArray = messageValue.split("/");
		
		var progressVal = Math.round( (valueArray[0]/ valueArray[1]) * 100 );
		
		return progressVal;
	}
	
	var getSelectedIndex = function (request)
	{
		var selIndex = -1; 
		
		if(StringUtils.isNullEmpty(request))
			return selIndex;
		
		var len = stateArray.length
		
		for (var count = 0; count < len; count++) {
			
			if(request.indexOf(stateArray[count].url) != -1)
			{
				selIndex = count;				
				break;
			}			
		}
		
		return selIndex;
	}
	
	var getUpdatedObject = function(request)
	{		
		var selIndex = getSelectedIndex(request)
		
		var selectObject = $scope.mergeProgress.status[selIndex];
		
		return selectObject;			
	}
	
	var loadStatus = function (cmd)
	{
		var selectObject = getUpdatedObject(cmd.request);
		
		var selectedIndex = getSelectedIndex(cmd.request)
		
		selectObject.bvProcessStartedOn = cmd.bvProcessStartedOn;
		selectObject.bvProcessStartedOnlong = cmd.bvProcessStartedOnlong;	
		
		if(cmd.status == 'COMPLETE')
		{
			selectObject.progress = 100;
		}
		else if(cmd.status == 'COMPLETE_WITH_ERROR')
		{
			selectObject.progress = 100;
		}
		else if(cmd.status == 'BV_ERROR' || cmd.status == 'DD_ERROR')
		{
			selectObject.progress = 20;
		}
		
		if(selectObject.progress == 0)
		{
			currentState = selectObject;
		}
		else
		{
			if(selectedIndex < $scope.mergeProgress.status.length -1)
			{
				currentState = $scope.mergeProgress.status[selectedIndex + 1];				
				currentState.bvProcessStartedOn = cmd.bvProcessStartedOn;
				currentState.bvProcessStartedOnlong = cmd.bvProcessStartedOnlong;
				getStatusValue(currentState)				
			}					
		}
	}	
}



angular
	.module('mergecompany', ['briefcase.service', 'localization.service'])
	.controller('mergeCompanyController', ['$scope', '$state', '$stateParams','localizedMessages','homeService','bvDialog', MergeCompanyController])
	.controller('mergeProgressCtrl', ['$scope', '$state', '$stateParams','localizedMessages','homeService','bvDialog','$timeout','mergeProgressService', MergeProgressController])
	.service('mergeProgressService', [mergeProgressService])
	