function homeInitializeProvider($stateProvider, legacyUserServiceProvider){

    function initializingStates($state){
        var mobileMenuSidebar = function(homeService){
            if(MobileUtil.isPhone()){
                setTimeout(function(){
                    if(homeService.homeContext.expandedMenu != undefined && !homeService.homeContext.expandedMenu) homeService.homeContext.expandedMenu = true;
                },0)
            }
        };
        
        var authorize = function($q, $state, $timeout, homeService){

    		var isFound = false;
    		
    		if( homeService.homeContext.modules == undefined ){
    			isFound = true;
    		}
    		else{
    		//write the authorization code here
    			
    		  homeService.setAllStatesOfModules();
    		  
    		  if(homeService.homeContext.moduleStates == undefined || 
    				  $state.transition == undefined || 
    				  $state.transition._targetState._identifier == undefined){
    			  isFound = true;
    		  }
    		  else if (homeService.homeContext.moduleStates.includes($state.transition._targetState._identifier) ||
    							homeService.homeContext.moduleStates.includes($state.transition._targetState._identifier.name)) {
    				isFound = true;
    			}
    		}
    		
    	      if (!isFound){
    	          $timeout(function() {
    	         //  $state.go('home')
    	        	  window.location.reload();
    	        })
    	        return $q.reject()
    	      }
    	};

        $stateProvider
	        .state('home.viewfullagenda', {
	            url: '/viewfullagenda',
	            resolve: { mobileMenuSidebar : mobileMenuSidebar ,
	            	authorize : authorize},
	            
	            params: {
	            	fileObject: null,
	            	currentViewFullAgendaPath: null,
	            	annotationFromPlink:null
	            	},
	            views: {
	                  'content': {
                          templateUrl: 'modules/meetings/viewfullagenda/viewFullAgenda.tpl.html',
                          controller  : 'viewfullagendaController'
	                  }
	            }
	        });


        if(!_.find($state.get(),{ name: 'home.approvals' })){

            $stateProvider
                .state('home.approvals', {
                    url: '/approvals',
                    resolve: { mobileMenuSidebar : mobileMenuSidebar ,
                    	authorize : authorize},
                    
                    views: {
                          'content': {
                              templateUrl: () => {
                                    if(MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface()){
                                        if(!legacyUserServiceProvider.isNasdaqUI.enable)
                                            return 'modules/legacyuser/mobile/templates/ApprovalsListMobile.tpl.html';
                                        else
                                            return 'modules/approvals/ApprovalsListMobile.tpl.html';
                                    }
                                    else{
                                        if(!legacyUserServiceProvider.isNasdaqUI.enable)
                                            return 'modules/legacyuser/templates/ApprovalsList.tpl.html';
                                        else
                                            return 'modules/approvals/ApprovalsList.tpl.html';
                                    }
                              },
                              controller  : 'ApprovalsListController'
                          }
                    }
                })
        }
        else{
//            if((_.find($state.get(),{ name: 'home.approvals' }).views.content.templateUrl.indexOf('legacyuser') == -1) != legacyUserServiceProvider.isNasdaqUI.enable){
//                setTimeout(function(){
//                    location.reload();
//                },0);
//            }
        };

        if(!_.find($state.get(),{ name: 'home.meetings' })){
            $stateProvider
                .state('home.meetings', {
                    url: '/meetings',
                    resolve: { mobileMenuSidebar : mobileMenuSidebar ,
                    	authorize : authorize},
                    
                    views: {
                          'content': {
                              templateUrl: !legacyUserServiceProvider.isNasdaqUI.enable ? 'modules/legacyuser/templates/MeetingCards.tpl.html' : 'modules/calendar/MeetingCards.tpl.html',
                              controller  : 'CalendarController'
                          }
                    }
                })
        };

       if(!_.find($state.get(),{ name: 'home.meetingDetails' })){
           $stateProvider
               .state('home.meetingDetails', {
                    url: '/meetings/meetingDetails',
                    params: {
                    	meetingDetails: null
                    },
                    resolve: { mobileMenuSidebar : mobileMenuSidebar ,
                    	authorize : authorize},
                     
                    //templateUrl : 'modules/home/htmlStructure/Container.tpl.html',
                    views: {
                          'content': {
                              templateUrl: !legacyUserServiceProvider.isNasdaqUI.enable ? 'modules/legacyuser/templates/Conference.tpl.html' : 'modules/conference/Conference.tpl.html',
//                        	  template: "<div></div>",
                              controller: 'ConferenceController'
                          }
                    }
                })
	   };

       if(!_.find($state.get(),{ name: 'home.surveys' })){
           $stateProvider
                .state('home.surveys', {
                    url: '/surveys',
                    resolve: { mobileMenuSidebar : mobileMenuSidebar,
                    	authorize : authorize},
                  
                    views: {
                          'content': {
                              templateUrl: !legacyUserServiceProvider.isNasdaqUI.enable ? 'modules/legacyuser/templates/Surveys.tpl.html' : 'modules/surveys/Surveys.tpl.html',
                              controller  : 'SurveysController'
                          }
                    }
                })
                .state('home.createSurvey', {
                    url : '/surveys/createSurvey',
                    resolve: { authorize : authorize},
                    params: {
                    	objectBaseUuid: null
                    },
                    views: {
                          'content': {
                              templateUrl: !legacyUserServiceProvider.isNasdaqUI.enable ? 'modules/legacyuser/templates/CreateSurvey.tpl.html' : 'modules/surveys/CreateSurvey.tpl.html',
                              controller  : !legacyUserServiceProvider.isNasdaqUI.enable ? 'CreateSurveyController' : 'CreateSurveyNewController'
                          }
                    }
                })
                .state('home.createCBEQuestionnaire', {
                    url : '/surveys/createCBEQuestionnaire',
                    resolve: { authorize : authorize},
                    params: {
                    	cbeObject: null
                    },
                    views: {
                          'content': {
                              templateUrl: 'modules/surveys/CreateCBEQuestionnaire.tpl.html',
                              controller  : 'CreateCBEQuestionnaireController'
                          }
                    }
                })
                
//                .state('home.createNewSurvey', {
//                    url : '/surveys/createSurvey',
//                    views: {
//                          'content': {
//                              templateUrl: 'modules/surveys/CreateSurvey.tpl.html',
//                              controller  : 'CreateSurveyController'
//                          }
//                    }
//                })
                .state('home.openSurvey', {
                    url: '/surveys/openSurvey',
                    resolve: { authorize : authorize},
                    params: {
                        userBaseUuid: null,
                        surveyBaseUuid: null
                    },
                    views: {
                          'content': { 
                              templateUrl: !legacyUserServiceProvider.isNasdaqUI.enable ? 'modules/legacyuser/templates/OpenSurvey.tpl.html' : 'modules/surveys/OpenSurvey.tpl.html',
                              controller  : 'OpenSurveyController'
                          }
                    }
                })
        };
       
       if(!_.find($state.get(),{ name: 'home.reports' }) && legacyUserServiceProvider.isNasdaqUI.enable){
           $stateProvider
	           .state('home.reports', {
		   			url: '/reports',
		   			resolve: { mobileMenuSidebar : mobileMenuSidebar ,
		   				authorize : authorize},
		   			
		   			views: {
		   			      'content': {
		   			    	  templateUrl: 'modules/reports/reports.dashboard.tpl.html',
		   			    	  controller  : 'ReportsController'
		   			      }
		   			}
		   		})
		   		.state('home.reports.useractivity', {
				    url: '/useractivity',
					resolve: { mobileMenuSidebar : mobileMenuSidebar ,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'useractivityController'
					      }
					}
				})
				.state('home.reports.userchanges', {
				    url: '/userchanges',
					resolve: { mobileMenuSidebar : mobileMenuSidebar,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'userchangesController'
					      }
					}
				})
				.state('home.reports.documentlist', {
				    url: '/documentlist',
					resolve: { mobileMenuSidebar : mobileMenuSidebar ,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'documentlistController'
					      }
					}
				})
				.state('home.reports.documentactivity', {
				    url: '/documentactivity',
					resolve: { mobileMenuSidebar : mobileMenuSidebar ,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'documentActivityController'
					      }
					}
				})
				.state('home.reports.documentpermissions', {
				    url: '/documentpermissions',
					resolve: { mobileMenuSidebar : mobileMenuSidebar ,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'documentPermissionsController'
					      }	
					}
				})
				.state('home.reports.meetinglist', {
				    url: '/meetinglist',
					resolve: { mobileMenuSidebar : mobileMenuSidebar,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'meetinglistController'
					      }
					}
				})
				.state('home.reports.retentionpolicies', {
				    url: '/retentionpolicies',
					resolve: { mobileMenuSidebar : mobileMenuSidebar ,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'retentionpoliciesController'
					      }
					}
				})
				.state('home.reports.memberattendancetracking', {
				    url: '/memberattendancetracking',
					resolve: { mobileMenuSidebar : mobileMenuSidebar ,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'memberattendancetrackingController'
					      }
					}
				})
				.state('home.reports.groupattendancetracking', {
				    url: '/groupattendancetracking',
					resolve: { mobileMenuSidebar : mobileMenuSidebar,
						authorize : authorize},
					
					views: {
					      'content@home': {
					    	  templateUrl: 'modules/reports/report.view.tpl.html',
					    	  controller  : 'groupattendancetrackingController'
					      }
					}
				})
			    .state('home.reports.userbriefcasereport', {
                    url: '/userbriefcasereport',
                    resolve: { mobileMenuSidebar : mobileMenuSidebar ,
                        authorize : authorize},

                    views: {
                          'content@home': {
                              templateUrl: 'modules/reports/report.view.tpl.html',
                              controller  : 'userbriefcasereportController'
                          }
                    }
                })
                .state('home.reports.syncedinformationreport', {
                    url: '/syncedinformationreport',
                    resolve: { mobileMenuSidebar : mobileMenuSidebar ,
                        authorize : authorize},

                    views: {
                          'content@home': {
                              templateUrl: 'modules/reports/report.view.tpl.html',
                              controller  : 'syncedinformationreportController'
                          }
                    }
                })
			   .state('home.reports.passwordexpiry', {
				   url: '/passwordexpiry',
				   resolve: { mobileMenuSidebar : mobileMenuSidebar ,
					   authorize : authorize},

				   views: {
					   'content@home': {
						   templateUrl: 'modules/reports/report.view.tpl.html',
						   controller  : 'passwordexpiryController'
					   }
				   }
			   })
			   .state('home.reports.folderpermissions', {
				   url: '/folderpermissions',
				   resolve: { mobileMenuSidebar : mobileMenuSidebar ,
					   authorize : authorize},

				   views: {
					   'content@home': {
						   templateUrl: 'modules/reports/report.view.tpl.html',
						   controller  : 'folderPermissionsController'
					   }
				   }
			   })
        };
        
        if(!_.find($state.get(),{ name: 'home.directory' }) && legacyUserServiceProvider.isNasdaqUI.enable){
            $stateProvider
 	           .state('home.directory', {
 		   			url: '/directory',
 		   			resolve: { mobileMenuSidebar : mobileMenuSidebar,
 		   			authorize : authorize},
 		   			
 		   			views: {
 		   			      'content': {
 		   			    	  templateUrl: 'modules/directory/directory.tpl.html',
 		   			    	  controller  : 'directoryController'
 		   			      }
 		   			}
 		   		})
         };
         
         if(!_.find($state.get(),{ name: 'home.dashboard' }) && legacyUserServiceProvider.isNasdaqUI.enable){
             $stateProvider
  	           .state('home.dashboard', {
  		   			url: '/dashboard',
  		   			resolve: { mobileMenuSidebar : mobileMenuSidebar,
  		   			authorize : authorize},
  		   			
  		   			views: {
  		   			      'content': {
  		   			    	  templateUrl: 'modules/dashboardNxtv2/dashboardNxtv2.tpl.html',
  		   			    	  controller  : 'dashboardNxtv2Controller'
  		   			      }
  		   			}
  		   		})
          };
          
          if(!_.find($state.get(),{ name: 'home.dashboardv2' }) && legacyUserServiceProvider.isNasdaqUI.enable){
              $stateProvider
   	           .state('home.dashboardv2', {
   		   			url: '/dashboardv2',
   		   			resolve: { mobileMenuSidebar : mobileMenuSidebar },
   		   			views: {
   		   			      'content': {
   		   			    	  templateUrl: 'modules/dashboardNxtv2/dashboardNxtv2.tpl.html',
   		   			    	  controller  : 'dashboardNxtv2Controller'
   		   			      }
   		   			}
   		   		})
           };

    }

    this.$get = function homeInitializeProvider($state) {
        return{
            initializingStates: function(){
                initializingStates($state);
            }
        }
    }
}