attachmentListingController.$inject = ['repositoryService', '$scope', 'context', 'localizedMessages', 'treeService', 'repositoryModelService', 'backUtiltiyService', 'bvDialog', 'selectionStack', '$timeout', '$rootScope'];

function attachmentListingController(repositoryService, $scope, context, localizedMessages, treeService, repositoryModelService, backUtiltiyService, bvDialog, selectionStack, $timeout, $rootScope)
{
    $scope.OK = localizedMessages.get('ADD');
    $scope.CANCEL = localizedMessages.get('CANCEL');

    var userTreeViewPrefKey = context.getModel().userVO.objectBaseUuid + "TREEPREF";
    var userTreeViewPref = localStorage.getItem(userTreeViewPrefKey);
    console.log("USER TREE PREF:",userTreeViewPref);

    if(!userTreeViewPref)
    {
        userTreeViewPref = "LARGE";
        localStorage.setItem(userTreeViewPrefKey, userTreeViewPref);
    }

    $scope.repositoryDisplayObjects = { showTree:true, showFilter:true, disableRightClick:true, onlyFiles:$scope.attachmentObject.onlyFiles, multiSelect: $scope.attachmentObject.multiSelect, displayType:userTreeViewPref, permissionCheck:  $scope.attachmentObject.permissionCheck};

    $scope.showSelectedItems = false;

    $scope.middleBarObject = {};

    var repositoryModel = repositoryModelService.getInstance("FILE_CHOOSER");
    repositoryModel.repositoryList([]);

    selectionStack.clearSelectedItems();

    $scope.allSelectedItems = selectionStack.getAllSelectedItems();
    $scope.$on('selection:updated', function(event,allSelectedItems) {
        $scope.allSelectedItems = allSelectedItems;
        $scope.showSelectedItems = $scope.allSelectedItems.length > 0 ? true : false;
    });
   
    $scope.removeSelectedItem = function(item) {
        item.selected = false;
        selectionStack.updateSelectedItems(item);
    }

    $scope.selectAttachment = function()
    {
    	var selectedObjectList = [];
        var allSelectedItems = selectionStack.getAllSelectedItems();
    	angular.forEach(allSelectedItems, function(businessObjectVO)
    	{
    		  if (businessObjectVO.selected && businessObjectVO.selected == true)
    		  {
    			  if($scope.attachmentObject.onlyFiles || MobileUtil.isAndroid())
				  {
    				  if(!StringUtils.isFolder(businessObjectVO.objectBaseUuid))
    					  selectedObjectList.push(businessObjectVO);
				  }
    			  else
    				  selectedObjectList.push(businessObjectVO);
    		  }
    	});

	    if(!$scope.attachmentObject.multiSelect && allSelectedItems.length > 1)
    	{
	    	bvDialog.showMessage(localizedMessages.get("MULTISELECTIONS_NOT_ALLOWED"));
	    	return;
    	}
        selectionStack.clearSelectedItems();

    	$scope.selectionDone(selectedObjectList);
    }

    $scope.cancelAttachmentDialog = function()
    {
    	var emptyArray = [];
        selectionStack.clearSelectedItems();
	    $scope.selectionDone(emptyArray);

	};

	if('callback' in $scope.attachmentObject && typeof $scope.attachmentObject.callback == 'function'){
	    $scope.attachmentObject.callback($scope.selectAttachment, $scope.cancelAttachmentDialog);
	}

}