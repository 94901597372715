function mediaViewerDialog() {
	return {
		template: "<div class='customMediaSize' style='width:100%; height:100%'><video id='videoPlayer' style='width:98%; height:100%; margin-left:1%' controls autoplay><source id='vidSrc' ></video></div>",
		scope: {
			mediaVo: '='
		},
		link: function (scope) {
			var videoSrc = document.getElementById("vidSrc");

			var mp4Src = "/services/contentviewer?versionUuid=" + scope.mediaVo.objectVersionUuid;
			videoSrc.setAttribute("src", mp4Src);
			videoSrc.setAttribute("type", 'video/mp4; codecs="avc1.4D401E, mp4a.40.2"');
		}
	}
}