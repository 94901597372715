surveyReportsController.$inject =  ['$scope','$filter','localizedMessages', 'commonService','context', 'homeService','bvDialog', 'bvDialogService','surveysService', 'imageService', 'repositoryService','bvSliderInstanceService','$timeout', '$sce'];

function surveyReportsController($scope, $filter, localizedMessages, commonService, context, homeService,bvDialog, bvDialogService, surveysService, imageService, repositoryService, bvSliderInstanceService, $timeout, $sce){
	
  $scope.popupTitle = localizedMessages.get('SURVEY_REPORT_MODAL_TITLE');
	var data = $scope.bvModalControllerInputs.data;
	$scope.surveyDetails = $scope.bvModalControllerInputs.surveyDetails;
	$scope.surveyDetails.attachments = $scope.surveyDetails.attachments || $scope.surveyDetails.attachedObjectsList;
	$scope.description = $scope.surveyDetails.objectDescription || $scope.surveyDetails.description;
	$scope.currentUserVO = $scope.bvModalControllerInputs.currentUserVO;
	$scope.ui = {
	  label: {
	    bar: localizedMessages.get('BAR'),
	    pie: localizedMessages.get('PIE'),
	    printable_reports: localizedMessages.get('PRINTABLE_REPORTS'),
	    attachment: localizedMessages.get('ATTACHMENT'),
	    no_section_attachments: localizedMessages.get('NO_SECTION_ATTACHMENTS'),
	    multiple_choice: localizedMessages.get('MULTIPLE_CHOICE'),
	    open_answer: localizedMessages.get('OPEN_ANSWER'),
	    likert_scale: localizedMessages.get('LIKERT_SCALE'),
	    true_false: localizedMessages.get('TRUE_FALSE'),
	    meets_does_not_meet: localizedMessages.get('MEETS_DOES_NOT_MEET'),
      multiple_choice_multi_response: localizedMessages.get('MULTIPLE_CHOICE_MULTI_RESPONSE'),
      multiple_yes_no: localizedMessages.get('MULTIPLE_YES_NO'),
      tabular_question: localizedMessages.get('TABLE_SUPPORT'),
	    yes_no: localizedMessages.get('YES_NO'),
	    response: localizedMessages.get('RESPONSE'),
	    createdBy: localizedMessages.get('CREATED_BY_LOWERCASE'),
	    complete: localizedMessages.get('COMPLETE'),
	    due: localizedMessages.get('DUE'),
	    priority: localizedMessages.get('PRIORITY'),
	    instructions: localizedMessages.get('INSTRUCTIONS'),
	    description: localizedMessages.get('DESCRIPTION'),
	    dueDate: localizedMessages.get('DUE_DATE_LOWERCASE'),
	    question: localizedMessages.get('QUESTION'),
	    overview: localizedMessages.get('OVERVIEW'),
	    section: localizedMessages.get('SECTION'),
	    of: localizedMessages.get('OF'),
	    responded: localizedMessages.get('RESPONDED'),
	    include: localizedMessages.get('INCLUDE'),
      chartsAndDataTable: localizedMessages.get('CHARTS_AND_DATA_TABLE'),
	    graphs: localizedMessages.get('GRAPHS'),
	    comments: localizedMessages.get('COMMENTS'),
	    signatures: localizedMessages.get('SIGNATURES'),
	    attachments: localizedMessages.get('ATTACHMENTS'),
	    responses: localizedMessages.get('RESPONSES'),
	    name: localizedMessages.get('NAME'),
	    summary: localizedMessages.get('SUMMARY'),
	    signed: localizedMessages.get('SIGNED'),
	    fileFormatUppercase: localizedMessages.get('FILE_FORMAT').toUpperCase(),
	    excelUppercase: localizedMessages.get('EXCEL').toUpperCase(),
	    wordUppercase: localizedMessages.get('WORD').toUpperCase(),
	    printUppercase: localizedMessages.get('PRINT').toUpperCase(),
	    print: localizedMessages.get('PRINT'),
	    download: localizedMessages.get('DOWNLOAD'),
	    cancel: localizedMessages.get('CANCEL'),
	    includeBarChart: localizedMessages.get('INCLUDE_BAR_CHART'),
	    includePieChart: localizedMessages.get('INCLUDE_PIE_CHART'),
	    includeResponses: localizedMessages.get('INCLUDE_RESPONSES'),
	    includeSignatures: localizedMessages.get('INCLUDE_SIGNATURES'),
	    includeComments: localizedMessages.get('INCLUDE_COMMENTS'),
	    includeAttachments: localizedMessages.get('INCLUDE_ATTACHMENTS'),
	    includeNames: localizedMessages.get('INCLUDE_NAMES')
	  }
  };
  
	$scope.questionDisplayNames = {
			'multiplechoice':$scope.ui.label.multiple_choice,
			'yesno':$scope.ui.label.yes_no,
			'openanswer':$scope.ui.label.open_answer,
			'likertscale':$scope.ui.label.likert_scale,
			'truefalse':$scope.ui.label.true_false,
			'meetsornot':$scope.ui.label.meets_does_not_meet,
			'multiplechoicemultiresponse':$scope.ui.label.multiple_choice_multi_response,
			'multiyesno':$scope.ui.label.multiple_yes_no,
			'tabularQuestion':$scope.ui.label.tabular_question
  };
  
	$scope.svgImgRightArrow = "resources/assets/svgs/rightArrow.svg";
	$scope.svgImgDropDown = "resources/assets/svgs/downarrow.svg";
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.svgImgRightArrow = "resources/assets/svgs/rightArrow.svg";
	$scope.svgImgDropDown = "resources/assets/svgs/downarrow.svg";
	$scope.excelReportImg = 'resources/assets/svgs/documents/xls.svg';
  $scope.wordReportImg = 'resources/assets/images/docx_sm_@2X.png';
	$scope.printReportImg = 'resources/assets/svgs/print_icon.svg';
  
  var seqCount = 0;
  $scope.surveyTotalQuestions = 0;
	for(var i=0;i<$scope.surveyDetails.sections.length;i++){
    $scope.surveyTotalQuestions += $scope.surveyDetails.sections[i].questions? $scope.surveyDetails.sections[i].questions.length : 0;
		$scope.surveyDetails.sections[i].attachments = $scope.surveyDetails.sections[i].attachments || $scope.surveyDetails.sections[i].attachedObjectsList;
		for(var j=0; j<$scope.surveyDetails.sections[i].questions.length; j++){
      seqCount = seqCount + 1;
      $scope.surveyDetails.sections[i].questions[j].sequentialSecCount = seqCount;
			$scope.surveyDetails.sections[i].questions[j].attachments = $scope.surveyDetails.sections[i].questions[j].attachments || $scope.surveyDetails.sections[i].questions[j].attachedObjectsList;
		}
  }
  
	$scope.printOptions = {};
  $scope.isAnonymous = $scope.bvModalControllerInputs.surveyDetails.isAnonymous;
	$scope.showBar = true;
	$scope.showPie = true;
	$scope.showComments = true;
	$scope.showSignatures = true;
	$scope.showAttachments = true;
  $scope.showWordReportComments = true;
	$scope.showWordReportSignatures = true;
	$scope.showWordReportAttachments = true;
	$scope.showResponses = true;
	$scope.showName = true;
	$scope.printOptions['showBar'] = $scope.showBar;
	$scope.printOptions['showPie'] = $scope.showPie;
	$scope.printOptions['showComments'] = $scope.showComments;
	$scope.printOptions['showSignatures'] = $scope.showSignatures;
	$scope.printOptions['showAttachments'] = $scope.showAttachments;
	$scope.printOptions['showResponses'] = $scope.showResponses;
	$scope.printOptions['showName'] = $scope.showName;
  $scope.printOptions['isAnonymous'] = $scope.isAnonymous;

	$scope.isXlsSelected = true;
	$scope.isWordSelected = false;
	$scope.isPrintSelected = false;

	$scope.selectXLS = function() {
		$scope.isXlsSelected = true;
		$scope.isWordSelected = false;
		$scope.isPrintSelected = false;
	}
	$scope.selectWord = function() {
		$scope.isXlsSelected = false;
		$scope.isWordSelected = true;
		$scope.isPrintSelected = false;
	}
	$scope.selectPrint = function() {
		$scope.isXlsSelected = false;
		$scope.isWordSelected = false;
		$scope.isPrintSelected = true;
	}

	$scope.toggleShowBarChart = function(){
		$scope.showBar = !$scope.showBar;
		$scope.printOptions['showBar'] = $scope.showBar;
	}
	$scope.toggleShowPieChart = function(){
		$scope.showPie = !$scope.showPie;
		$scope.printOptions['showPie'] = $scope.showPie;
	}
	$scope.toggleShowComments = function(){
		$scope.showComments = !$scope.showComments;
		$scope.printOptions['showComments'] = $scope.showComments;
	}
	$scope.toggleShowSignatures = function(){
		$scope.showSignatures = !$scope.showSignatures;
		$scope.printOptions['showSignatures'] = $scope.showSignatures;
	}
	$scope.toggleShowAttachments = function(){
		$scope.showAttachments = !$scope.showAttachments;
		$scope.printOptions['showAttachments'] = $scope.showAttachments;
	}
  
	$scope.toggleShowResponses = function(){
		$scope.showResponses = !$scope.showResponses;
		$scope.printOptions['showResponses'] = $scope.showResponses;
	}
	$scope.toggleShowName = function(){
		$scope.showName = !$scope.showName;
		$scope.printOptions['showName'] = $scope.showName;
	}
  
  $scope.scrollbarConfig = {
    autoHideScrollbar: true
  }
  $scope.reportTypes = [{
      name: $scope.ui.label.bar_chart,
      value: "barChart"
    },
    {
      name: $scope.ui.label.pie_chart,
      value: "pieChart"
    }
  ];

	$scope.getDocumentThumbnail = function(attachment) {
		return surveysService.getDocumentThumbnail(attachment);
	}
	$scope.showSurveyAttachment = function (attachment) {
	  surveysService.getSurveyAttachment(attachment);
	}
	$scope.getShortLocalizedDate = function (date) {
	  return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
	}
	$scope.getLocalizedDate = function (date) {
		return date != undefined ? moment($filter('date')(date, 'medium')).local().format('lll') : "";
	}
	$scope.getDocIcon = function (attachment) {
	  return imageService.getImageForObject(attachment);
  };

	$scope.openAttachmentForPreview = function(attachment) {
		bvDialog.showDocumentPreview(attachment.objectBaseUuid, attachment);
  };
  
	var bvSliderServiceForAttachments;
	var sliderRenderCallbackForAttachments = function(){
		bvSliderServiceForAttachments = bvSliderInstanceService.instances[$scope.surveyAttachmentsSliderOptions.id];
		bvSliderServiceForAttachments.currentIndex = 0;
		$timeout(function(){
			bvSliderServiceForAttachments.fixButtons();
		});
  }
  
	var bvSliderServiceForSectionAttachments;
	var sliderRenderCallbackForSectionAttachments=function()
	{
		bvSliderServiceForSectionAttachments = bvSliderInstanceService.instances[$scope.surveySectionAttachmentsSliderOptions.id];
		bvSliderServiceForSectionAttachments.currentIndex = 0;
		$timeout(function(){
			bvSliderServiceForSectionAttachments.fixButtons();
		});
  }
  
	var bvSliderServiceForQuestionAttachments;
	var sliderRenderCallbackForQuestionAttachments=function()
	{
		bvSliderServiceForQuestionAttachments = bvSliderInstanceService.instances[$scope.surveyQuestionAttachmentsSliderOptions.id];
		bvSliderServiceForQuestionAttachments.currentIndex = 0;
		$timeout(function(){
			bvSliderServiceForQuestionAttachments.fixButtons();
		});
  }
  
	$scope.surveyAttachmentsSliderOptions = {
	  id: 'SURVEYS_ATTACHMENTS_REPORT',
	  items: [],
	  slideThrough: 1,
	  shownItems: 3,
	  smallButtons: true,
	  renderCallback: sliderRenderCallbackForAttachments
	};
	$scope.surveySectionAttachmentsSliderOptions = {
	  id: 'SURVEYS_SECTIONS_ATTACHMENTS_REPORT',
	  items: [],
	  slideThrough: 1,
	  shownItems: 3,
	  smallButtons: true,
	  renderCallback: sliderRenderCallbackForSectionAttachments
	}
	$scope.surveyQuestionAttachmentsSliderOptions = {
	  id: 'SURVEYS_QUESTIONS_ATTACHMENTS_REPORT',
	  items: [],
	  slideThrough: 1,
	  shownItems: 3,
	  smallButtons: true,
	  renderCallback: sliderRenderCallbackForQuestionAttachments
  }
  
	$scope.surveyAttachmentsSliderOptions.items = $scope.surveyDetails.attachments;
	
	$scope.toggleSectionQuestionAreaForPreview = function (section, previewIndex) {
	  //		console.log(section);
	  if ($scope.clickedSummaryIndex == undefined) {
	    $scope.clickedSummaryIndex = previewIndex;
	    $scope.surveyDetails.sections[$scope.clickedSummaryIndex].isDisplayOpen = true;
	  } else if ($scope.clickedSummaryIndex == previewIndex) {
	    $scope.surveyDetails.sections[$scope.clickedSummaryIndex].isDisplayOpen = !$scope.surveyDetails.sections[$scope.clickedSummaryIndex].isDisplayOpen;
	  } else if ($scope.clickedSummaryIndex != previewIndex) {
	    $scope.surveyDetails.sections[$scope.clickedSummaryIndex].isDisplayOpen = false;
	    $scope.clickedSummaryIndex = previewIndex;
	    $scope.surveyDetails.sections[$scope.clickedSummaryIndex].isDisplayOpen = true;
	  }
	  $scope.surveySectionAttachmentsSliderOptions.items = section.attachments;
  }
  
  $scope.reportSelected = {};
  $scope.reportSelected = $scope.reportTypes[0];
  //    $scope.showBarChart = true;
  //    $scope.showPieChart = false;
  $scope.toggleBarChartDisplay = function (question) {
    question.showBarChart = !question.showBarChart;
  }
  $scope.togglePieChartDisplay = function (question) {
    question.showPieChart = !question.showPieChart;
  }

  var isValueNullOrUndefined = function (value) {
    if (typeof value == 'undefined' || value == undefined || value == "undefined" || value == null || value == "null" || value == "") {
      return true;
    } else {
      return false;
    }
  }
    
  var allSurveyQuestionsList = surveysService.getAllQuestionsList();
			
  $scope.barData = {};
  $scope.pieData = {};
  $scope.horizontalBarData = {};
  
  var responseVOs;
  var questionObjectBaseUuid;
  
  var valuesObject;
  var valuesObjHorBar;
  var valueItem;
  var valueItemArray;
  var valueItemFinalArray;
  var barDataItemArray;
  var barDataItem;
  
  var objForPie;
  var reverseObjForPie;
  var objForHorizontalData;
  var reverseObjForHorizontalData;
  var objForHorizontalBar;
  var reverseObjForHorizontalData;
  var pieDataItemArray;
  var pieDataArray;
  var horizontalbarChartArr;
  var horizontalBarDataItemArray;
  
  var tempArrayToCountNumOfOccurences;
  var questionType = '';
  let question = {};
  $scope.likertScaleValuesMapping  = '';
  var getAllOptionsInCurrentQuestion = function(qBaseUuid){
    for(var i=0;i<allSurveyQuestionsList.length;i++){
      if(allSurveyQuestionsList[i].objectBaseUuid == qBaseUuid){
        question = allSurveyQuestionsList[i];
        questionType = allSurveyQuestionsList[i].questionType;
        if(questionType == 'likertscale'){
          $scope.likertScaleValuesMapping = angular.copy(allSurveyQuestionsList[i].likertMapping);
        }
//        if(questionType == 'multiplechoice'){
//        	for(var j=0; j<question.options.length; j++) {
//        		if(question.options[j].length > 25) {
//        			question.options[j] = question.options[j].substring(0, 20) + '...';
//        		}
//        	}
//        }
        return allSurveyQuestionsList[i].options;
      }
    }	
  }
			
  $scope.respondentsCountForPrint = {};
  $scope.respondentsCountForPrintUtil = {};
  $scope.respondentsSignauresCount = {};
			
  for(var i = 0;i< data.surveyQuestionResponseWrapperList.length;i++){
    valueItemFinalArray = [];
    barDataItemArray = [];
    barDataItem = [];
    pieDataItemArray = [];
    horizontalBarDataItemArray = [];
    pieDataArray = [];
    horizontalbarChartArr = [];
    valuesObject = {};
    valuesObjHorBar = {};
    valueItem = {};
    responseVOs = data.surveyQuestionResponseWrapperList[i].responseVOs;
    tempArrayToCountNumOfOccurences = new Array();
    questionObjectBaseUuid = responseVOs[0].questionBaseUuid;
    var allOptionsInQuestions = getAllOptionsInCurrentQuestion(questionObjectBaseUuid);
    valueItemFinalArray.length = allOptionsInQuestions.length;

    $scope.respondentsCountForPrint[questionObjectBaseUuid] = {};
    $scope.respondentsCountForPrintUtil[questionObjectBaseUuid] = {};
    $scope.respondentsSignauresCount[questionObjectBaseUuid] = [];

    if (questionType != 'openanswer') {
      for (var j = 0; j < responseVOs.length; j++) {
        let responseVO = responseVOs[j]

        if(responseVO.isResponseSigned)
          $scope.respondentsSignauresCount[questionObjectBaseUuid].push(responseVO)

        if (questionType == 'likertscale') {
          if (responseVO.response != "") {
            var key = $scope.likertScaleValuesMapping[responseVO.response];
            var l = 0;
            if (!(key in $scope.respondentsCountForPrint[questionObjectBaseUuid])) {
              $scope.respondentsCountForPrint[questionObjectBaseUuid][key] = []; // Store user details
            }
            $scope.respondentsCountForPrint[questionObjectBaseUuid][key].push(responseVO);
            tempArrayToCountNumOfOccurences.push($scope.likertScaleValuesMapping[responseVO.response]);
          }
        }
        else if (questionType == 'multiyesno' && responseVO.responseList) {

          function stringToBoolean(val){
            switch (val) {
              case 'true':
                return true;
              case 'false':
                return false;
              case 'null':
                return null;
            }
          }

          question.multipleYesNoChoiceValues.forEach(choice => {
            responseVO = angular.copy(responseVO)
            let val = null

            try{
              val = stringToBoolean(responseVO.responseList.find(item => item.split('##')[0] == choice.name).split('##')[1])
            }
            catch(e){}

            responseVO.multiyesnoResponse = val ? localizedMessages.get("YES") : localizedMessages.get("NO")

            if (!(choice.name in $scope.respondentsCountForPrint[questionObjectBaseUuid])) {
              $scope.respondentsCountForPrint[questionObjectBaseUuid][choice.name] = []; // Store user details
            }
            $scope.respondentsCountForPrint[questionObjectBaseUuid][choice.name].push(responseVO)
          })
        }
        else if (questionType == 'tabularQuestion' && responseVO.responseMap) {

          question.tabularQuestionChoiceValues.forEach((choice, i) => {
            responseVO = angular.copy(responseVO)
            let val = null

            try{
              Object.values(responseVO.responseMap).forEach(row => {
                val = row.find(item => item.split('##')[0] == choice.name).split('##')[1]
              })
            }
            catch(e){}

            responseVO.tabularResponse = val ? val : '';

            if (!(choice.name in $scope.respondentsCountForPrint[questionObjectBaseUuid])) {
              $scope.respondentsCountForPrint[questionObjectBaseUuid][choice.name] = []; // Store user details
            }
            $scope.respondentsCountForPrint[questionObjectBaseUuid][choice.name].push(responseVO)
          })
        }
        else {
          if (responseVO.response && responseVO.response != "") {
            var key = responseVO.response;
            if (questionType == 'yesno' || questionType == 'truefalse' || questionType == 'meetsornot') {
                key = key.toLowerCase();
              switch (key) {
                case 'yes':
                  key = localizedMessages.get("YES");
                  break;
                case 'no':
                  key = localizedMessages.get("NO");
                  break;
                case 'true':
                  key = localizedMessages.get("TRUE");
                  break;
                case 'false':
                  key = localizedMessages.get("FALSE");
                  break;
                case 'meet':
                  key = localizedMessages.get("MEETS");
                break;
                case 'meets':
                  key = localizedMessages.get("MEETS");
                  break;
                case 'do not meet':
                  key = localizedMessages.get("DOES_NOT_MEET");
                  break;
                case 'does not meet':
                  key = localizedMessages.get("DOES_NOT_MEET");
                  break;
                default:
                  key = localizedMessages.get(key.toUpperCase().split(' ').join('_'));
              }

              key = StringUtils.capitaliseFirstLetter(key);
            }
            var l = 0;
            responseVO.response = responseVO.response.toLowerCase();
            if (responseVO.response.indexOf(',') > -1 && !(questionType == 'yesno' || questionType == 'truefalse' || questionType == 'meetsornot')) { //responseList (multiple values in response)		
              var dataArray = responseVO.response.split(",");
              if(questionType == 'multiplechoicemultiresponse'){
                dataArray = responseVO.responseList;
              }
              if (dataArray && dataArray.length > 0) {
                for (var dat = 0; dat < dataArray.length; dat++) {
                  key = dataArray[dat];
                  if (!(key in $scope.respondentsCountForPrint[questionObjectBaseUuid])) {
                    $scope.respondentsCountForPrint[questionObjectBaseUuid][key] = []; // Store user details
                  }
                  $scope.respondentsCountForPrint[questionObjectBaseUuid][key].push(responseVO);
                }
              }
              tempArrayToCountNumOfOccurences = tempArrayToCountNumOfOccurences.concat(dataArray);
            } else {
              if (!(key in $scope.respondentsCountForPrint[questionObjectBaseUuid])) {
                $scope.respondentsCountForPrint[questionObjectBaseUuid][key] = []; // Store user details
              }
              if (questionType == 'yesno' || questionType == 'truefalse' || questionType == 'meetsornot') {
                $scope.respondentsCountForPrint[questionObjectBaseUuid][key].push(responseVO);
                if (responseVO.response == 'does not meet' || responseVO.response == 'do not meet' || responseVO.response == 'does_not_meet') {
                  tempArrayToCountNumOfOccurences.push(localizedMessages.get('DOES_NOT_MEET'));
                }
                else if(responseVO.response == 'meets' || responseVO.response == 'meet'){
                  tempArrayToCountNumOfOccurences.push(localizedMessages.get('MEETS'));
                }
                else
                  tempArrayToCountNumOfOccurences.push(localizedMessages.get((responseVO.response).toUpperCase()));

              } else {
                $scope.respondentsCountForPrint[questionObjectBaseUuid][key].push(responseVO);
                tempArrayToCountNumOfOccurences.push(responseVO.response);
              }
            }
          }
        }
      }

      var isNumber = false;
      allOptionsInQuestions.map((key) => {
        var keyValue = key.toLowerCase();
        if (tempArrayToCountNumOfOccurences.indexOf(keyValue) != -1 || tempArrayToCountNumOfOccurences.indexOf(key) != -1) {
          tempArrayToCountNumOfOccurences.forEach(function (item) {
            if (keyValue == item || key == item) {
              //valueItem[key] = (valueItem[key] || 0) + 1;
              if (questionType == 'multiplechoice' && Number(keyValue)) {
                valueItem['a' + key] = (valueItem['a' + key] || 0) + 1;
                isNumber = true;
              } else {
                valueItem[key] = (valueItem[key] || 0) + 1;
              }
            }
          });
        }
      })

      if (questionType == 'multiyesno' || questionType == 'tabularQuestion') {
        allOptionsInQuestions.forEach(option => {
          const value = $scope.respondentsCountForPrint[questionObjectBaseUuid][option]
          if (value)
            $scope.respondentsCountForPrintUtil[questionObjectBaseUuid][option] = value;
        })
      } else {
        for (var z = 0, len = allOptionsInQuestions.length; z < len; z++) {
          var key = allOptionsInQuestions[z];
          var value = $scope.respondentsCountForPrint[questionObjectBaseUuid][key];
          if (!isValueNullOrUndefined(value)) {
            $scope.respondentsCountForPrintUtil[questionObjectBaseUuid][key] = value;
          }
        }
      }
      //$scope.respondentsCountForPrint = angular.copy($scope.respondentsCountForPrintUtil);

      var keys = Object.keys(valueItem);
      if (questionType == 'multiplechoice' && isNumber) {
        for (var keyIndex = 0; keyIndex < keys.length; keyIndex++) {
          keys[keyIndex] = keys[keyIndex].substring(1);
        }
        for (var prop in valueItem) {
          var newProp = prop.substring(1);
          var oldVal = valueItem[prop];
          delete valueItem[prop];
          valueItem[newProp] = oldVal;
        }
      }

      for (var a = 0; a < keys.length; a++) { //Count of answered
        var position = allOptionsInQuestions.indexOf(keys[a]); // For charts in order of question options given
        valueItemArray = [];
        objForPie = {};
        objForHorizontalData = {};
        objForPie['key'] = keys[a];
        objForPie['y'] = valueItem[keys[a]];
        objForHorizontalData['label'] = keys[a];
        objForHorizontalData['value'] = valueItem[keys[a]];
        pieDataArray.push(objForPie);
        horizontalbarChartArr.push(objForHorizontalData);
        valueItemArray.push(keys[a]);
        valueItemArray.push(valueItem[keys[a]]);
        valueItemFinalArray[position] = valueItemArray;
      }
      for (var b = 0; b < allOptionsInQuestions.length; b++) {
        if (keys.indexOf(allOptionsInQuestions[b]) == -1) {
          var position = allOptionsInQuestions.indexOf(allOptionsInQuestions[b]);
          valueItemArray = [];
          objForPie = {};
          objForHorizontalData = {};
          objForPie['key'] = allOptionsInQuestions[b];
          objForPie['y'] = 0;
          objForHorizontalData['label'] = allOptionsInQuestions[b];
          objForHorizontalData['value'] = 0;
          pieDataArray.push(objForPie);
          horizontalbarChartArr.push(objForHorizontalData);
          valueItemArray.push(allOptionsInQuestions[b]);
          valueItemArray.push(0);
          valueItemFinalArray[position] = valueItemArray;
        }
       
      }
    } 
    else{
      var answeredCount = 0;
      var notAnsweredCount = 0;
      $scope.respondentsCountForPrint[questionObjectBaseUuid][localizedMessages.get('OPEN_ANSWER')] = [];
      $scope.respondentsCountForPrintUtil[questionObjectBaseUuid][localizedMessages.get('OPEN_ANSWER')] = [];
      for (var j = 0; j < responseVOs.length; j++) {
        const responseVO = responseVOs[j];
        if(responseVO.response.indexOf("%25") != -1){
          responseVO.response=decodeURIComponent(responseVO.response);
        }
        if (responseVO.response && responseVO.response != '') {
          answeredCount++;
          $scope.respondentsCountForPrint[questionObjectBaseUuid][localizedMessages.get('OPEN_ANSWER')].push(responseVO);
          $scope.respondentsCountForPrintUtil[questionObjectBaseUuid][localizedMessages.get('OPEN_ANSWER')].push(responseVO);
        } else {
          notAnsweredCount++;
        }
      }

      valueItemArray = [];
      var position = allOptionsInQuestions.indexOf('answered');
      objForPie = {};
      objForHorizontalData = {};
      objForPie['key'] = localizedMessages.get('ANSWERED');
      objForPie['y'] = answeredCount;
      objForHorizontalData['label'] = localizedMessages.get('ANSWERED');
      objForHorizontalData['value'] = answeredCount;
      pieDataArray.push(objForPie);
      horizontalbarChartArr.push(objForHorizontalData);
      valueItemArray.push(localizedMessages.get('ANSWERED'));
      valueItemArray.push(answeredCount);
      valueItemFinalArray[position] = valueItemArray;

      valueItemArray = [];
      var position = allOptionsInQuestions.indexOf('not answered');
      objForPie = {};
      objForHorizontalData = {};
      objForPie['key'] = localizedMessages.get('NOT_ANSWERED');
      objForPie['y'] = notAnsweredCount;
      objForHorizontalData['label'] = localizedMessages.get('NOT_ANSWERED');
      objForHorizontalData['value'] = notAnsweredCount;
      pieDataArray.push(objForPie);
      horizontalbarChartArr.push(objForHorizontalData);
      valueItemArray.push(localizedMessages.get('NOT_ANSWERED'));
      valueItemArray.push(notAnsweredCount);
      valueItemFinalArray[position] = valueItemArray;
    }

     if (questionType != 'openanswer'){
      var reversehorizontalbarChartArr = [];
      var reversepieDataArray = [];
      for (var a = 0; a < valueItemFinalArray.length; a++) {
        if(valueItemFinalArray[a]){
          reverseObjForHorizontalData = {};
          reverseObjForPie = {};
          reverseObjForPie['key'] = valueItemFinalArray[a][0];
          reverseObjForPie['y'] = valueItemFinalArray[a][1];
          reversepieDataArray.push(reverseObjForPie);
          reverseObjForHorizontalData['label'] = valueItemFinalArray[a][0];
          reverseObjForHorizontalData['value'] = valueItemFinalArray[a][1];
          reversehorizontalbarChartArr.push(reverseObjForHorizontalData);
        }
      }
      console.log('reversepieDataArray', reversepieDataArray);
      console.log('reversehorizontalbarChartArr', reversehorizontalbarChartArr);
      valuesObject["values"] = valueItemFinalArray;
      barDataItem.push(valuesObject);
      valuesObjHorBar["values"] = reversehorizontalbarChartArr;
      //valuesObjHorBar["key"] = "Respondend";
      barDataItemArray.push(barDataItem);
      pieDataItemArray.push(reversepieDataArray);
      horizontalBarDataItemArray.push(valuesObjHorBar);
      $scope.horizontalBarData[questionObjectBaseUuid] = horizontalBarDataItemArray;
      $scope.barData[questionObjectBaseUuid] = barDataItemArray;
      $scope.pieData[questionObjectBaseUuid] = pieDataItemArray;
     }else{
      valuesObject["values"] = valueItemFinalArray;
      barDataItem.push(valuesObject);
      valuesObjHorBar["values"] = horizontalbarChartArr;
      //valuesObjHorBar["key"] = "Respondend";
      barDataItemArray.push(barDataItem);
      pieDataItemArray.push(pieDataArray);
      horizontalBarDataItemArray.push(valuesObjHorBar);
      $scope.horizontalBarData[questionObjectBaseUuid] = horizontalBarDataItemArray;
      $scope.barData[questionObjectBaseUuid] = barDataItemArray;
      $scope.pieData[questionObjectBaseUuid] = pieDataItemArray;
    }
  }

  console.log($scope.horizontalBarData);
  console.log($scope.barData);
  console.log($scope.pieData);
  surveysService.setQuestionBaseUuidList($scope.surveyDetails);
  
  $scope.respondentsCountForPrintBarCharts = angular.copy($scope.respondentsCountForPrint);

  var format = window.d3.format('d');
  $scope.xFunction = function () {
    return function (d) {
      return d.key;
    };
  };

  $scope.yFunction = function () {
    return function (d) {
      return format(d.y);
    };
  }
  $scope.xFunctionBarChart = function () {
    return function (d) {
      return d.label;
    };
  };

  $scope.yFunctionBarChart = function () {
    return function (d) {
      return d.value;
    };
  }

  $scope.valueFormatFunction = function () {
    return function (d) {
      return format(d);
    }
  }
  $scope.yAxisTickFormatFunction = function () {
    return function (d) {
      return format(d);
    }
  }
  $scope.toolTipContentFunction = function () {
    return function (key, x, y, e, graph) {
      return x + ' - ' + y
    }
  }
  $scope.pieToolTipContentFunction = function () {
    return function (key, y, e, graph) {
      return key + ' - ' + y
    }
  }
  $scope.pieToolTipValueFormatter = function () {
    return function (d) {
      return format(d);
    }
  }
	        
  $scope.respondentsBarChartOpenInfo = {};
  $scope.respondentsPieChartOpenInfo = {};
  for (var propt in $scope.respondentsCountForPrintBarCharts) {
    $scope.respondentsBarChartOpenInfo[propt] = {};
    for (var innerProp in $scope.respondentsCountForPrintBarCharts[propt]) {
      $scope.respondentsBarChartOpenInfo[propt][innerProp] = false
    }
  }
  for (var propt in $scope.respondentsCountForPrint) {
    $scope.respondentsPieChartOpenInfo[propt] = {};
    for (innerProp in $scope.respondentsCountForPrint[propt]) {
      $scope.respondentsPieChartOpenInfo[propt][innerProp] = false
    }
  }
  $scope.toggleRespondentsViewPieCharts = function (key, questionBaseUuid) {
    $scope.respondentsPieChartOpenInfo[questionBaseUuid][key] = !$scope.respondentsPieChartOpenInfo[questionBaseUuid][key];
  }
  $scope.toggleRespondentsViewBarCharts = function (key, questionObBaseUuid) {
    $scope.respondentsBarChartOpenInfo[questionObBaseUuid][key] = !$scope.respondentsBarChartOpenInfo[questionObBaseUuid][key];
  }
  $scope.showSignaturesBar = false;
  $scope.participantsObjBaseUuidList = [];

  var totalResponses = 0;
  for (var i = 0; i < $scope.surveyDetails.participantBaseUuidAndResponseList.length; i++) {
    if ($scope.surveyDetails.participantBaseUuidAndResponseList[i].value == "responded") {
      totalResponses += 1;
      if ($scope.surveyDetails.participantBaseUuidAndResponseList[i].isSigned == true) {
        $scope.participantsObjBaseUuidList.push($scope.surveyDetails.participantBaseUuidAndResponseList[i].objectBaseUuid);
      }
    }
  }
  const responsesLabel = (totalResponses == 1 ? localizedMessages.get('RESPONSE') : localizedMessages.get('RESPONSES'));
  $scope.totalResponses = totalResponses.toString() + " " + responsesLabel;
  $scope.signaturesImg = [];
  $scope.participantSignatureResponseMap = data.participantSignatureResponseMap;
  if (data.participantSignatureResponseMap != undefined) {
    for (var key in data.participantSignatureResponseMap) {
      $scope.signaturesImg.push(data.participantSignatureResponseMap[key]);
    }
  }
	$scope.toggleSignaturesBar = function () {
	  $scope.showSignaturesBar = !$scope.showSignaturesBar;
	}

	$scope.toggleSignaturesCountArrow = function () {
	  $scope.showSignaturesCountArrow = !$scope.showSignaturesCountArrow;
	}
	$scope.toggleSignaturesCountArrowQuestion = function (question) {
	  question.showSignaturesCountArrow = !question.showSignaturesCountArrow;
	}
	
	 $scope.getLocalizedDateOnLocalTimezone = function (date) {
	      // return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
	      return date != undefined ? localizedMessages.getLocaleDateOnLocalTimezone(date, 'lll') : "";
	  };

    $scope.toggleShowWordReportAttachments = function(){
      $scope.showWordReportAttachments = !$scope.showWordReportAttachments;
    }
    $scope.toggleShowWordReportComments = function(){
      $scope.showWordReportComments = !$scope.showWordReportComments;
    }
    $scope.toggleShowWordReportSignatures = function(){
      $scope.showWordReportSignatures = !$scope.showWordReportSignatures;
    }
    // document.getElementById('showWordReportAttachments').click = function(){
    //   $scope.showWordReportSignatures = !$scope.showWordReportSignatures;
    // }
    $scope.downloadWordReport = function () {
      var baseuuid = $scope.surveyDetails.objectBaseUuid;
	    var ecxcompanyid = $scope.currentUserVO.companyBaseUuid;
	    var action = "EXPORT_SURVEY";
      var reportType = "WORD";
	    var ecxportalid = $scope.surveyDetails.portalBaseUuid;
      var includeSignatures = $scope.showWordReportSignatures;
      var includeAttachments = $scope.showWordReportAttachments;
      var includeComments = $scope.showWordReportComments;
	    window.location = "/services/download?&baseuuid=" + encodeURIComponent(baseuuid) + "&ecxcompanyid=" + encodeURIComponent(ecxcompanyid) + "&action=" + encodeURIComponent(action) +
       "&reportType=" + encodeURIComponent(reportType) + "&includeComments=" + encodeURIComponent(includeComments) + "&includeSignatures=" + encodeURIComponent(includeSignatures) + 
       "&includeAttachments=" + encodeURIComponent(includeAttachments) + "&ecxportalid=" + encodeURIComponent(ecxportalid);
	
    }
	 

	$scope.downloadExcelReport = function () {
	  var baseuuid = $scope.surveyDetails.objectBaseUuid;
	  var ecxcompanyid = $scope.currentUserVO.companyBaseUuid;
	  var action = "EXPORT_SURVEY";
    var reportType = "XLS";
	  var ecxportalid = $scope.surveyDetails.portalBaseUuid;
	  window.location = "/services/download?&baseuuid=" + encodeURIComponent(baseuuid) + "&ecxcompanyid=" + encodeURIComponent(ecxcompanyid) + "&action=" + encodeURIComponent(action) + "&reportType=" + encodeURIComponent(reportType) + "&ecxportalid=" + encodeURIComponent(ecxportalid);
	};
	$scope.showPrintPreview = function () {
	  bvDialog.showPrintableReports($scope.surveyDetails, $scope.currentUserVO, $scope.horizontalBarData, $scope.pieData, $scope.respondentsCountForPrint, $scope.printOptions, $scope.signaturesImg, $scope.participantSignatureResponseMap);
  }
  

  console.log($scope.respondentsSignauresCount)
}

angular
  .module('dialogControllers.service')
	.controller('surveyReportsController', surveyReportsController);
