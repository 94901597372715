angular
	.module('dialogControllers.service')
	.controller('preferencesController',preferencesController);

preferencesController.$inject = ['$scope', 'homeService', 'localizedMessages', '$state', 'bvDialogService', 'loginService' , 'context', 'templatesService','repositoryModelService', 'bvDialog', 'permissionService', 'searchIndexingService', 'commonService','calendarService'];

function preferencesController($scope, homeService, localizedMessages, $state, bvDialogService, loginService, context, templatesService,repositoryModelService, bvDialog, permissionService, searchIndexingService,commonService,calendarService)
{
	$scope.isOnPrem = context.getModel().isOnPrem;
	$scope.isModuleCreatorsConfigAllowed = false;
	$scope.isN3aUser = context.getModel().isN3aUser;
	$scope.enableMeetxAdmin = context.getModel().enableMeetxAdmin;
	$scope.isWin = window.navigator.platform.indexOf("Win") != -1;
    $scope.n3aChangePasswordUrl = context.getModel().n3aChangePasswordUrl;
    $scope.n3aEditQuestionAndAnswerUrl = context.getModel().n3aEditQuestionAndAnswerUrl;
    $scope.n3aLinkAccountsUrl = context.getModel().n3aLinkAccountsUrl;
    $scope.isLinkAccountsEnabled = context.getModel().isLinkAccountsEnabled;
    $scope.n3aViewProfileUrl = context.getModel().n3aViewProfileUrl;
    $scope.n3aMfaConfigUrl = context.getModel().companyVO.n3aMfaConfigUrl;
	$scope.popupTitle 	= localizedMessages.get('PREFERENCES');
	$scope.isMeetingCreator = false;
	$scope.isMeetingModuleEnabled = homeService.homeContext.modules.sidebarMenuModules.some(e =>e.name === "MEETINGS");
	$scope.canCurrentUserCreateModule = false;
	$scope.isVirtualMeetingEnabled = context.getModel().companyVO.enableVirtualMeetings;
	$scope.isTeamsVirtualMeetingEnabled = context.getModel().companyVO.enableTeamsVirtualMeetings;

	$scope.ui = {
			label : {
						passwordMatchError      : localizedMessages.get("ERR_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH"),
						challengeQuestionsError : localizedMessages.get("ERR_ANSWERS_ARE_REQUIRED_FOR_ALL_CHALLENGE_QUESTIONS"),
						userIdExistsError       : localizedMessages.get("ERR_USER_ID_ALREADY_EXISTS"),
						enterFieldsError		: localizedMessages.get("ERR_PLEASE_ENTER_FIRST_NAME_LAST_NAME_LOGINID_PASSWORD_CONFIRM_PASSWORD_AND_EMAIL_TO_CONTINUE"),
						invalidRequestError		: localizedMessages.get("ERR_INVALID_REQUEST"),
						expiredRequestError		: localizedMessages.get("ERR_INVALID_OR_EXPIRED_REQUEST"),
						userRegistration		: localizedMessages.get("USER_REGISTRATION"),
						save 					: localizedMessages.get('SAVE'),
						type					: localizedMessages.get('TYPE'),
						name					: localizedMessages.get('NAME'),
						remove					: localizedMessages.get('REMOVE'),
						followingMe				: localizedMessages.get('FOLLOWING_ME'),
						followfollowingMe		: localizedMessages.get('FOLLOW_FOLLOWING_ME'),
						following				: localizedMessages.get('FOLLOWING'),
						password				: localizedMessages.get('PASSWORD'),
						preferences				: localizedMessages.get('PREFERENCES'),
						challengeQuestions		: localizedMessages.get('CHALLENGE_QUESTIONS'),
						briefcaseInstallations	: localizedMessages.get('BRIEFCASE_INSTALLATIONS'),
						mergeCompanyData		: localizedMessages.get('MERGE_COMPANY_DATA'),
						deleteAllMyAnnotations	: localizedMessages.get('DELETE_ALL_MY_ANNOTATIONS'),
						deleteAllAnnotations	: localizedMessages.get('DELETE_ALL_ANNOTATIONS'),
						submit					: localizedMessages.get('SUBMIT'),
						manageBriefcaseInstallations: localizedMessages.get('MANAGE_BRIEFCASE_INSTALLATIONS'),
						remoteId				: localizedMessages.get('REMOTE_ID'),
						hostAddress				: localizedMessages.get('HOST_ADDRESS'),
						hostName				: localizedMessages.get('HOST_NAME'),
						os						: localizedMessages.get('OS'),
						osUser					: localizedMessages.get('OS_USER'),
						installDate				: localizedMessages.get('INSTALL_DATE'),
						purge					: localizedMessages.get('PURGE'),
						addedBy					: localizedMessages.get('ADDED_BY'),
						activatedBriefcases		: localizedMessages.get('ACTIVATED_BRIEFCASES'),
						purgedBriefcases		: localizedMessages.get('PURGED_BRIEFCASES'),
						areYouSureYouWantToDeleteAllYourAnnotation : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_YOUR_ANNOTATION'),
						//areYouSureYouWantToDeleteAllAnnotation : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_ANNOTATION'),
						setLanguage				: localizedMessages.get('SET_LANGUAGE'),
						installEditLink			: localizedMessages.get('INSTALL_EDIT_LINK'),
						installDesktopSuite		: localizedMessages.get('INSTALL_DESKTOP_SUITE'),
						installDesktopClient	: localizedMessages.get('INSTALL_DESKTOP_CLIENT'),
						siteBranding 			: localizedMessages.get('SITE_BRANDING'),
						modulesSettings			: localizedMessages.get('MODULES_SETTINGS'),
						viewProfile				: localizedMessages.get('VIEW_PROFILE'),
						moduleCreators			: localizedMessages.get('MODULE_CREATORS'),
						searchIndexing			: localizedMessages.get('SEARCH_INDEXING'),
						multiFactorAuthentication: localizedMessages.get('MULTI_FACTOR_AUTHENTICATION'),
						linkAccounts			: localizedMessages.get('PREFERENCES_LINK_ACCOUNT'),
						virtualMeetingConfig    : localizedMessages.get('VIRTUAL_MEETING_CONFIGURATION')
			}

	};

  	$scope.html5BackgroundColor = context.getModel().html5BackgroundColor;
  	$scope.isPortalAdminUser = false;
  	$scope.isContentOwner = context.getModel().userVO.isContentOwner;
	$scope.showChallengeQuestion = context.getModel().companyVO.secondTierEnabled;
	  
	$scope.showN3ABasedChallengeQuestions = $scope.isN3aUser == true && $scope.enableMeetxAdmin == false;
	$scope.showBasicChallengeQuestions = $scope.showChallengeQuestion == true && ($scope.enableMeetxAdmin == true || $scope.isN3aUser == false);
	$scope.showN3ABasedPasswordChange = $scope.isN3aUser == true && $scope.enableMeetxAdmin == false;
	$scope.showBasicPasswordChange = $scope.isN3aUser == false || $scope.enableMeetxAdmin == true;
  
	$scope.enableSearchOptionInPreferenceMenu = context.getModel().companyVO.enableSearchInPreferenceMenu;
	$scope.isApp = MobileUtil.isApp();
  	
  	$scope.canDownloadDC = !$scope.isApp && $scope.isN3aUser == false && $scope.isOnPrem == false;
  	var downloadDcVal = context.getModel().browserDesktopClientForUserEnabled;
  	if(downloadDcVal!=null && downloadDcVal!=undefined && (downloadDcVal==false || downloadDcVal=="false"))
  	{
  		$scope.canDownloadDC = false;
  	}

  	$scope.canDownloadNasdaqSuite = false;
    var canDownloadNasdaqSuiteVal = context.getModel().userVO.nasdaqSuiteDownloadLinkAccessible;
    if(canDownloadNasdaqSuiteVal!=null && canDownloadNasdaqSuiteVal!=undefined && (canDownloadNasdaqSuiteVal==true || canDownloadNasdaqSuiteVal=="true"))
    {
        $scope.canDownloadNasdaqSuite = !$scope.isApp && ($scope.isN3aUser == true || $scope.isOnPrem) && $scope.isWin == true;
    }

  	$scope.showDeleteAllMyAnnontations = false;
  	$scope.showDeleteAllAnnontations = false;
  	
  	$scope.mergeAdmin = context.getModel().userVO.ddAdmin;
  	
  	$scope.isCompanyOwner = permissionService.isOwner(context.getModel().companyVO);
	var isUserPortalAdmin = context.getModel().isUserPortalAdmin;
	if(isUserPortalAdmin!=null && isUserPortalAdmin!=undefined && isUserPortalAdmin == "TRUE" 
		&& !StringUtils.isPersonalTeamSpace(context.getModel().portalVO.objectBaseUuid))
	{
		$scope.isPortalAdminUser = true;
	}
	
	
	
	$scope.populateModuleCreators = function()
	{
		$scope.moduleCreators = '';
		homeService.getModuleCreatorsJSON().then(function(data){
			$scope.moduleCreators = data.data;
			if(data.data.name == "isModuleCreatorsPrefAllowed" && data.data.value == "false"){
				$scope.isModuleCreatorsConfigAllowed = false;
			}else{
				$scope.isModuleCreatorsConfigAllowed = true;
			}
		});
	}
	
	$scope.populateModuleCreators();
	
	$scope.moduleCreatorsClick = function(){
		homeService.getModuleCreatorsJSON().then(function(data){
			$scope.moduleCreators = data.data;
			if($scope.isModuleCreatorsConfigAllowed){
				bvDialog.moduleCreators($scope.moduleCreators);
			}
		});
	}
  	var getPasswordPreferences = function()
  	{
  		if(context.homeModel== null || context.homeModel.passwordPreferences == null || context.homeModel.passwordPreferences == undefined)
		{
  			homeService.getPasswordPreferences().then(function(data){
	  			if ( data != null && data != undefined) 
	  			{
	    			context.homeModel.passwordPreferences = data;
	    		}
  			});
		}
  	}
  	var getPreferencesMenuOptions = function()
  	{
  		if(context.homeModel== null || context.homeModel.preferencesMenuOptions == null || context.homeModel.preferencesMenuOptions == undefined)
		{
  			homeService.getPreferencesMenuOptions().then(function(data){
	  			if ( data != null && data != undefined) 
	  			{
	    			context.homeModel.preferencesMenuOptions = data;
	    			setPreferencesMenuOptions();
	    		}
  			});
		}
  	}
	getPasswordPreferences();
	getPreferencesMenuOptions();
	
	var setPreferencesMenuOptions = function()
	{
		var options = context.homeModel.preferencesMenuOptions;

		if(options!= null && options!= undefined &&  options.length > 0)
		{
			for(var i = 0; i<options.length; i++)
			{
				if(options[i].indexOf("delete my annotations")>=0)
				{
					$scope.showDeleteAllMyAnnontations = true;
				}
				if(options[i].indexOf("delete all annotations")>=0)
				{
					$scope.showDeleteAllAnnontations = true;
				}
			}
		}	
		
			/*if(context.homeModel.preferencesMenuOptions.indexOf("delete my annotations")>=0){
				$scope.showDeleteAllMyAnnontations = true;
				console.log("showDeleteAllMyAnnontations");
			}
			if(context.homeModel.preferencesMenuOptions.indexOf("delete all annotations")>=0){
				$scope.showDeleteAllAnnontations = true;
				console.log("showDeleteAllAnnontations");
			}*/
		
	}
	if(context.homeModel!= null && context.homeModel.preferencesMenuOptions != null && context.homeModel.preferencesMenuOptions != undefined){
		setPreferencesMenuOptions();
	}
	
	
	$scope.openChallengeQuestions = function(){bvDialog.changeChallengeQuestions()}
	$scope.openChangePassword = function(){bvDialog.changePassword()}
	$scope.openfollowFollowingMe = function(){bvDialog.followFollowMe()}
	$scope.isFollowFollowMeEnabled  = function(){
		return context.getModel().followFollowMeEnabled;
	}
	$scope.openModulesSettings = function(){bvDialog.modulesSettings()}

	$scope.openVirtualMeetingConfiguration = function(){bvDialog.selectVirtualMeetingConfiguration()}

	$scope.startMerge = function()
	{
		homeService.isDeviceRegisteredForMerge().then(function(data)
		{
			if(data.data.boName == "FailureBO"){
				if(!StringUtils.isNullEmpty(data.data.message)){
					if(data.data.message.startsWith("0"))
					{
						bvDialog.showMessage("Migration is completed");
					}
					else if(data.data.message.startsWith("2"))
					{
						bvDialog.showMessage("Company migration had error. Contact admin");
					}
					else if(data.data.message.startsWith("6"))
					{
						bvDialog.showMessage("Server is not enable for migration. Contact admin");
					}
					else
					{
						bvDialog.showMergeProgress(data.data);
					}
				}
				else{
					bvDialog.showMergeProgress(data.data);
				}				
			}
			else{
				bvDialog.mergeCompanyDialog(data.data);
			}
		});		
	}
	
	$scope.openBriefcaseInstalations = function()
	{
		homeService.getUserVaultInstallations().then(function(data)
		{
			$scope.installations = data.data.installations;
			$scope.removedInstallations = data.data.removedInstallations;

			bvDialog.showBriefcaseInstallations($scope.installations, $scope.removedInstallations);
		});
	}
	

	$scope.removeMyAnnotation = function()
	{
		homeService.deleteAllAnnotationsByCreator()
	}
	
	$scope.removeAllAnnotationInPortal = function()
	{
		homeService.deleteAllAnnotationsInPortal()
	}
	
	$scope.removeAllMyAnnotation = function()
	{
		bvDialog.confirm($scope.ui.label.areYouSureYouWantToDeleteAllYourAnnotation, $scope.removeMyAnnotation)
	}
	
	$scope.removeAllAnnotation = function()
	{
		var alertMsg = localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_ANNOTATION", {"teamSpaceName" : context.getModel().portalVO.name});
		bvDialog.confirm(alertMsg, $scope.removeAllAnnotationInPortal);
	}

	$scope.nativeEditLinkPluginInstaller = function()
    {
		var userAgent = window.navigator.platform;
		window.location = '/services/webEdit.webedit?app=enl&userAgent='+userAgent;
    }

	$scope.nativeDesktopSuiteInstaller = function()
    {
        var userAgent = window.navigator.platform;
        window.location = '/services/webEdit.webedit?app=desktop_suite&userAgent='+userAgent;
    }

	$scope.meetxDesktopClientInstaller = function()
    {
		var userAgent = window.navigator.platform;
		var appName="";
		if (userAgent.indexOf("Win")!=-1) appName="MeetX_windows.exe";
		if (navigator.appVersion.indexOf("Mac")!=-1) appName="MeetX_macos.dmg";
		if(window.location.host.indexOf("boardvantage.com") != -1)
		{
			window.location = 'https://ec.boardvantage.com/dvclient/meetxapp/'+appName;
		}
		else
		{
			window.location = '/dvclient/meetxapp/'+appName;
		}
		
    }
	
	$scope.viewProfile = function()
	{
		var currentUser = context.getModel().userVO;
		var homeController = angular.element(document.querySelector('[home-controler]')).scope();
		homeController.selectUser(currentUser);
	}
	
	$scope.openSiteBranding = function(){bvDialog.siteBranding()};

	$scope.openSearchIndexing = function(){
	    searchIndexingService.showSearchIndexing();
	}
	$scope.virtualMeetingConfiguration = function()
	{
		var bvModal = $scope.bvModalControllerInputs.bvModal;
		$state.go('home.virtualMeetingConfiguration');
		bvModal.close();
	};	
	$scope.isMeetingCreatorApp = function() {
	$scope.config = "false";
    commonService.checkModuleCreatorsConfigPresentJSON("MEETINGS").then(function(data){
	if(data && data.data && data.data.name == "isModuleCreatorsPrefAllowed"){
		$scope.config = data.data.value;
		if($scope.config == "true" || $scope.config == true){
			commonService.getModuleCreatorsForSpecificModuleJSON("MEETINGS").then(function(mod){
				if(mod.data){
					var moduleCreators = angular.fromJson(mod.data);
					if(moduleCreators.users != undefined && moduleCreators.users.length>0){
						for(var i=0;i<moduleCreators.users.length;i++){
							if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
								$scope.canCurrentUserCreateModule = true;
								break;
							}
						}
						if($scope.canCurrentUserCreateModule){
							$scope.isMeetingCreator = true;	
						}
					}
				}
				
			})
		}
	}
})
}(function() {} ());
if(!calendarService.isCurrentUserAllowedToCreateModule()){
	$scope.isMeetingCreator = false;	
}
}