homeController.$inject = [
	'$scope',
	'$rootScope',
	'$transitions',
	'$state',
	'$stateParams',
	'$location',
	'Idle',
	'calendarService',
	'homeService',
	'teamService',
	'conferenceService',
	'presentationService',
	'modelService',
	'localizedMessages',
	'context',
	'permissionService',
	'bvPolling',
	'pollingService',
	'bvPollingProcessor',
	'N3aPollingService',
	'bvDialogService',
	'bvDialog',
	'$templateCache',
	'brand',
	'fileReader',
	'$modal',
	'$timeout',
	'loadRecycle',
	'ResourceService',
	'ipadService',
	'constants',
	'constantsList',
	'templatesService',
	'commonService',
	'homeModel',
	'plinkHandler',
	'documentContextMenu',
	'repositoryService',
	'viewerContextMenu',
	'contextMenuModel',
	'sessionService',
	'bvSliderService',
	'$interval',
	'$window',
	'homeContext',
	'isOnlineCheckServiceForJup',
	'bvDialogMobile',
	'bvSliderInstanceService',
	'companyUserService',
	'$sce',
	'adSearchDialogService',
	'adSearchService',
	'legacyUserService',
	'adSearchQueryBoxService',
	'searchIndexingService',
	'userPanelService',
	'treeService',
	'loadContext',
	'$q',
	'repositoryModelService'
];

function homeController(
	$scope,
	$rootScope,
	$transitions,
	$state,
	$stateParams,
	$location,
	Idle,
	calendarService,
	homeService,
	teamService,
	conferenceService,
	presentationService,
	modelService,
	localizedMessages,
	context,
	permissionService,
	bvPolling,
	pollingService,
	bvPollingProcessor,
	N3aPollingService,
	bvDialogService,
	bvDialog,
	$templateCache,
	brand,
	fileReader,
	modal,
	$timeout,
	loadRecycle,
	ResourceService,
	ipadService,
	constants,
	constantsList,
	templatesService,
	commonService,
	homeModel,
	plinkHandler,
	documentContextMenu,
	repositoryService,
	viewerContextMenu,
	contextMenuModel,
	sessionService,
	bvSliderService,
	$interval,
	$window,
	homeContext,
	isOnlineCheckServiceForJup,
	bvDialogMobile,
	bvSliderInstanceService,
	companyUserService,
	$sce,
	adSearchDialogService,
	adSearchService,
	legacyUserService,
	adSearchQueryBoxService,
	searchIndexingService,
	userPanelService,
	treeService,
	loadContext,
	$q,
	repositoryModelService
) {

	$rootScope.$$listeners.$stateChangeStart = [];
	$rootScope.currentStateValue = $state.$current.name;
	$scope.isN3aUser = context.getModel().isN3aUser;
	$scope.n3aAdminUrl = context.getModel().n3aAdminUrl;
	$scope.isOnPrem = context.getModel().isOnPrem;
	$scope.enableMeetxAdmin = context.getModel().enableMeetxAdmin;
    $scope.useN3aAdmin = context.getModel().isN3aUser && !context.getModel().enableMeetxAdmin;
    $scope.enableMeetXAdminApp = context.getModel().companyVO.enableMeetXAdminApp;
    $scope.onPremAdminUrl = context.getModel().onPremAdminUrl;
    $scope.onpremAdminTabName = "AdminTabName" + Math.random();
	var enableN3aAuthSession = context.getModel().enableN3aAuthSession;


    $scope.shouldDisableSearch = !context.getModel().companyVO.enableBasicSearch;
    if ($scope.shouldDisableSearch) {
        $scope.shouldDisableSearch = (isDesktopClient && !homeContext.onlineStatus.val) || !homeModel.isSearchEnabled.val;
    }

	var checkUserAllowedToCreateSignatures = function () {
		var canCurrentUserCreateModule = false;
		homeService.setIsCurrentUserAllowedToCreateSignature(canCurrentUserCreateModule);
		var config = "false";
		commonService.checkModuleCreatorsConfigPresentJSON("SIGNATURES").then(function (data) {
			if (data && data.data && data.data.name == "isModuleCreatorsPrefAllowed") {
				config = data.data.value;
				if (config == "true" || config == true) {
					commonService.getModuleCreatorsForSpecificModuleJSON("SIGNATURES").then(function (mod) {
						if (mod.data) {
							var moduleCreators = angular.fromJson(mod.data);
							if (moduleCreators.users != undefined && moduleCreators.users.length > 0) {
								for (var i = 0; i < moduleCreators.users.length; i++) {
									if (context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid) {
										canCurrentUserCreateModule = true;
										homeService.setIsCurrentUserAllowedToCreateSignature(canCurrentUserCreateModule);
										break;
									}
								}
							}
						}
					});
				}
				else {
					homeService.setIsCurrentUserAllowedToCreateSignature(true);
				}
			}
		});
	}

	var confirmUnsavedChangesMessage = localizedMessages.get('LEAVE_MODULE_WITH_UNSAVED_CHANGES_CONFIRM_MESSAGE');
	checkUserAllowedToCreateSignatures();
	var ctrlName = 'HomeController';
	$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

		// When leaving from unsaved changes screen - like create approval or meeting etc
		// set the set_current_module_has_unsaved_changes,
		// will check the same status and
		// after confirmation user will be redirected
		if (homeService.get_current_module_has_unsaved_changes()) {
			event.preventDefault();
			bvDialog.confirm(confirmUnsavedChangesMessage,
				function () {
					homeService.set_current_module_has_unsaved_changes(false, ctrlName);
					$scope.tileCheckBox ? $scope.tileCheckBox.show = false : null;
					$state.go(toState.name);
				},
				undefined, localizedMessages.get("EXIT") + " " + localizedMessages.get($state.current.url.split('/')[1].toUpperCase())
			);
		}

		if (MobileUtil.isApp() && MobileUtil.isJupOffline()) {
			var stateName = toState.name;
			if (stateName && (stateName == "home.surveys" || stateName == "home.approvals" || stateName == "home.documents" || (stateName == "home.inbox" && MobileUtil.isDesktopClient()))) {
				event.preventDefault();
			}
		}
	});

//Auto Collapse Left Menu for Meeting Creation in Smaller Screens
	$transitions.onSuccess(criteriaObj, function(transition)
{
    var autoCollapseMenu = function ()
    {
        var mainUrl = $location.$$absUrl
        var meetingCreationUrl = "meetingCreation"
        if(mainUrl.includes(meetingCreationUrl) && screen.width < 1300)
        {
            var homesidebar = angular.element(document.querySelector('.nasdaq .bvhome [home-sidebar]'));
            if(homesidebar)
            {
                if (homeService.homeContext.expandedMenu = true)
                {
                     homeService.homeContext.expandedMenu = false;
                }
            }
        }
   }
   autoCollapseMenu();
});
	const criteriaObj = {
		to: (state) => state.name === 'home.virtualMeetingConfiguration' || state.name === 'home.meetings' || state.name === 'home.newitems'|| state.name === 'home.surveys' || state.name === 'home.approvals' || state.name === 'home.documents' || state.name === 'home.inbox' || state.name === 'home.briefcase' || state.name === 'home.signatures' || state.name === 'home.directory' || state.name === 'home.reports'|| state.name === 'home.email' || state.name === 'home.taskmanagement' || state.name === 'home.dashboard' || state.name === 'home.calendar'
	};

	$transitions.onStart(criteriaObj, function(transition) {
		if(transition.to().name == "home.virtualMeetingConfiguration" 
			&& !context.getModel().companyVO.enableVirtualMeetings 
			&& !context.getModel().companyVO.enableTeamsVirtualMeetings){
			$state.go(transition.from().name);
		}
		if (homeService.get_current_module_has_unsaved_changes()) {
			bvDialog.confirm(confirmUnsavedChangesMessage,
				function () {
					homeService.set_current_module_has_unsaved_changes(false, ctrlName);
					$scope.tileCheckBox ? $scope.tileCheckBox.show = false : null;
					$state.go(transition.to().name);
				},
				undefined, localizedMessages.get("EXIT") + " " + localizedMessages.get($state.current.url.split('/')[1].toUpperCase())
			);
			return false;
		}
		});


	if (!context || !context.isReady()) {
		$state.go('login.auth');
		return;
	}

	if (context && context.getModel()) {
		if (context.getEncryptionKeyError() == 'requireEncryptionKeyForAdmin') {
			var companyObjectbaseUuid = context.getModel().companyVO.objectBaseUuid;
			context.homeModel.companyBaseUuid = companyObjectbaseUuid;
			$state.go('login.submitcompanyencryptionkey');
			return;
		}
		else if (context.getEncryptionKeyError() == 'requireEncryptionKeyForUser') {
			var alertMsg = localizedMessages.get('ERR_ENCR_UNABLE_TO_LOGIN_CONTACT_ADMINISTRATOR');
			bvDialog.showMessage(alertMsg);
			return;
		}
		else if ((context.getModel().cryptoVO != null || context.getModel().cryptoVO != undefined) && context.getSymmetricKey() == null) {
			var data = context.getModel().cryptoVO;
			if (context.getClientPreLoginDecryptKey() == null) {
				var clientPreLoginDecryptKey = X509.getPublicKeyFromCertPEM(constants.get('clientPreLoginCertificate'));
				context.setClientPreLoginDecryptKey(clientPreLoginDecryptKey);
			}

			if (data.publicCertificate != undefined && data.publicCertificate != "" && data.publicCertificate != null
				&& context.getServerPublicKey() == null) {
				data.publicCertificate = "-----BEGIN CERTIFICATE-----" + data.publicCertificate + "-----END CERTIFICATE-----";
				context.setServerPublicKey(X509.getPublicKeyFromCertPEM(data.publicCertificate));
			}
			if (data.intermediateCertificate != undefined && data.intermediateCertificate != "" && data.intermediateCertificate != null) {
				data.intermediateCertificate = "-----BEGIN CERTIFICATE-----" + data.intermediateCertificate + "-----END CERTIFICATE-----";
			}


			data.ssl2 = StringUtils.decodeBoolean(atob(data.ssl2));
			data.login = StringUtils.decodeBoolean(atob(data.login));
			data.chpass = StringUtils.decodeBoolean(atob(data.chpass));
			data.chquest = StringUtils.decodeBoolean(atob(data.chquest));
			data.docUpload = StringUtils.decodeBoolean(atob(data.docUpload));
			data.docDownload = StringUtils.decodeBoolean(atob(data.docDownload));

			if (data.symmetricKey == undefined || data.symmetricKey == "" || data.symmetricKey == null) {
				data.symmetricKey = "";
			}
			else {
				data.symmetricKey = atob(data.symmetricKey);
			}
			context.setSymmetricKey(data.symmetricKey);
			context.getModel().cryptoVO = data.cryptoVO;
		}
	}

	bvPolling.init();

	var cancelTimerAndResumeSession = function () {
		if (homeContext.sessionTimeout) {
			$interval.cancel(homeContext.sessionTimeout);
			homeContext.sessionTimeout = null;
		}
		// the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
		bvDialogService.closeTopOpenDialog();
		sessionService.sessionKeepAlive().then(function () {
			bvPolling.restart();
		});
	}

	var cancelTimerAndLogout = function () {
		try {
			if (homeContext.sessionTimeout) {
				$interval.cancel(homeContext.sessionTimeout);
				homeContext.sessionTimeout = null;
			}
		} finally {
			//$interval.cancel(homeContext.sessionTimeout);
			$state.go('logout');
		}
	}

	/**
	 * Resets the session idle timeout value.
	 */
	$scope.setIdleSessionTimeout = function () {
		Idle.setIdle(MobileUtil.getUserSessionTimeout());
	}

	$scope.sessionTimeoutIdleCountdown = 30;
	var idleCountdown = function () {
		if ($scope.sessionTimeoutIdleCountdown > 1) {
			$scope.sessionTimeoutIdleCountdown--;
			bvDialogService.setShowMessageIdle(localizedMessages.get("SESSION_IDLE_TIMEOUT_MSG", { "countdown": $scope.sessionTimeoutIdleCountdown }));

		} else {
			cancelTimerAndLogout();
		}
	}

	var showMessageIdle = function () {
		if (!homeContext.sessionTimeout) {
			bvDialog.showMessageIdle([
				{ name: localizedMessages.get("CONTINUE"), action: cancelTimerAndResumeSession },
				{ name: localizedMessages.get("LOGOUT"), action: cancelTimerAndLogout }]);
			bvDialogService.setShowMessageIdle(localizedMessages.get("SESSION_IDLE_TIMEOUT_MSG", { "countdown": 30 }));
			$scope.sessionTimeoutIdleCountdown = 30;
			homeContext.sessionTimeout = $interval(idleCountdown, 1000, 0);
		}
	}

	$rootScope.$on('PreemptLogoutWarn', function () {
		showMessageIdle();
	});
	
	

	$scope.$on('IdleStart', function () {
		bvPolling.startIdleCounting();
		if (MobileUtil.isApp() && MobileUtil.isJupOffline()) {
			if (Idle.getIdle() != MobileUtil.getUserSessionTimeout()) {
				Idle.setIdle(MobileUtil.getUserSessionTimeout());
			}
			else {
				showMessageIdle();
			}
		}
	});
	
	$scope.$on('IdleEnd', function() {
		bvPolling.stopIdleCounting();
		if(!bvPolling.isPollingStarted()) {
			bvPolling.start();
		}
	});

	$scope.header = {
		label: {
			settings: localizedMessages.get('SETTINGS'),
			modulesSettings: localizedMessages.get('MODULES_SETTINGS'),
			siteBranding: localizedMessages.get('SITE_BRANDING'),
			inviteUser: localizedMessages.get('INVITE_USER'),
			addGroup: localizedMessages.get('ADD_GROUP'),
			minimizeModules: localizedMessages.get('MINIMIZE_MODULES'),
			zoomIn: localizedMessages.get('ZOOM_IN'),
			zoomOut: localizedMessages.get('ZOOM_OUT'),
			actualSize: localizedMessages.get('ACTUAL_SIZE'),
			logOff: localizedMessages.get('LOG_OFF'),
			logOut: localizedMessages.get('LOG_OUT'),
			welcome: localizedMessages.get('WELCOME'),
			admin: localizedMessages.get('ADMIN'),
			current: localizedMessages.get('CURRENT'),
			preferences: localizedMessages.get('PREFERENCES'),
			viewMyProfile: localizedMessages.get('VIEW_MY_PROFILE'),
			support: localizedMessages.get('SUPPORT'),
			loggedInAs: localizedMessages.get('LOGGED_IN_AS'),
			boardvantage: localizedMessages.get('BOARDVANTAGE2'),
			biometric_enrollment: localizedMessages.get('BIOMETRIC_ENROLLMENT'),
			managePin: localizedMessages.get('MANAGE_PIN')
		}
	};
	calendarService.getAllCalendarEvents(true);

	$scope.changeModule = function (module) {
		//		 event.stopPropagation(); event.preventDefault();
		//		 if(module == 'home.calendar'){
		//			 module = 'home.calendarlight'
		//		 }
		$state.go(module);
	}

	$scope.$on('$stateChangeSuccess', function (event, toState) {
		if (toState.name === 'home.pages') {
			$scope.back = true;
		} else {
			$scope.back = false;
		}
	});

	//ipadService.touchHomeSidebar();

	templatesService.setAllTemplates();

	$scope.middleBarObject = {}

	var modulesMenuExpanded = {};
	modulesMenuExpanded.value = true;
	$scope.modulesMenuExpanded = modulesMenuExpanded;

	var modulesMenuSettingsExpanded = {};
	modulesMenuSettingsExpanded.value = false;
	$scope.modulesMenuSettingsExpanded = modulesMenuSettingsExpanded;

	var userMenuExpanded = {};
	userMenuExpanded.value = false;
	$scope.userMenuExpanded = userMenuExpanded;

	var screenExtended = {};
	screenExtended.value = false;
	$scope.screenExtended = screenExtended;

	var showBadge = {};
	showBadge.value = false + "";
	$scope.showBadge = showBadge;
	$scope.fromOpenItems = false;

	//var HTML5_SUPPORTED_MODULE = "#CALENDAR#SIGNATURES#APPROVALS#MESSAGES#MEETINGS#DIRECTORY#BRIEFCASE#REPOSITORY#SURVEYS#NEWITEMS#WALL#WIKI#OPEN ITEMS#NEWITEMS";
	var HTML5_SUPPORTED_MODULE = constants.get('HTML5_SUPPORTED_MODULE');
	var HTML5_JUP_SYNC_SUPPORTED_MODULES = constants.get('HTML5_JUP_SYNC_SUPPORTED_MODULES');

	var SIDE_BAR_MENU_WIDTH = "340px";
	var SIDE_BAR_MENU_WIDTH_MIN = "58px";
	var SIDE_BAR_MENU_WIDTH_ADMIN = "510px";

	$scope.logoutMenu = [
		{ 'name': localizedMessages.get('ADMIN'), 'state': '#' },
		{ 'name': localizedMessages.get('LOG_OUT'), 'state': 'logout' }
	];

	var footerMenu = [
		{ 'name': localizedMessages.get('PREFERENCES'), 'state': 'home.preferences', 'type': 'preferences' },
		{ 'name': localizedMessages.get('SUPPORT'), 'state': 'home.support', 'type': 'support' },
		//	 	{ 'name': localizedMessages.get('TRASH'), 'state': 'home.recycle', 'type': 'recycle' }
	];

	var topSidebarMenu = [
		{ 'name': !legacyUserService.isNasdaqUI ? localizedMessages.get('MODULES') : localizedMessages.get('MODULES_UPPERCASE'), 'state': 'homeModules', 'active': true }
	];

	//	$scope.showRecycle = false;
	//	$scope.showPreferences = false;

	$scope.middleBarObject = {};
	$scope.iconNextToTitle = 'resources/assets/svgs/backarrow.svg';
	$scope.companyNameString = context.getModel().companyVO.name;

	$scope.SHOW_JUP_STATUS = (MobileUtil.isDesktopClient()) ? true : false;
	$scope.SYNC_ICON_FILL_COLOR = context.getModel().html5TextColor;
	$scope.JUP_STATUS_ICON_FILL_COLOR = context.getModel().html5ModulesColor;
	$scope.JUP_STATUS_ONLINE = localizedMessages.get('AVAILABLE');
	$scope.JUP_STATUS_OFFLINE = localizedMessages.get('NOT_AVAILABLE');
	$scope.JUP_LAST_SYNCED = localizedMessages.get('LAST_SYNCED');
	$scope.JUP_SYNCING = localizedMessages.get('SYNCING');
	$scope.JUP_SYNC_COMPLETE = localizedMessages.get('COMPLETE');
	$scope.JUP_SYNC_SVG_URL = "resources/assets/svgs/jupSyncSpinner.svg";
	$scope.JUP_ONLINE_SVG_URL = "resources/assets/svgs/jupOnline.svg";
	$scope.JUP_OFFLINE_SVG_URL = "resources/assets/svgs/jupOffline.svg";

	$scope.scrollConfigForModuleList = {
		autoHideScrollbar: true,
		theme: 'minimal-dark'
	};

	/////////Start of Conf Code//////////////////////////////////////////////////////////////////////////////////////////
	$scope.showPresentationStartAlert = false;
	$scope.showRunningHostedConference = function () {
		//		if((MobileUtil.isApp() && MobileUtil.isJupOffline())) {
		//			console.log("showRunningHostedConference() - Meeting presenter/passenger functionality is disabled.");
		//			return;
		//		}

		calendarService.getRunningHostedCalendarEventsForMeetingCenterInSyncCommunicationJSON()
			.then(function (data) {
				//console.log("I was hosting" , data);
				if (data && data.data != undefined && data.data.length > 0) {
					var cal_event = data.data[0];
					conferenceService.getConferenceParticipantsJSON(cal_event.objectBaseUuid)
						.then(function (data) {
							var conf = {};
							conf.conf_event = cal_event;
							conf.conf_participants = data.data;
							presentationService.setConf(conf);
							presentationService.showConfDialog();
							$scope.handleRefreshConferencePolling();
						});
				}
			});
	}

	$scope.handleRefreshConferencePolling = function () {
		//		if((MobileUtil.isApp() && MobileUtil.isJupOffline())) {
		//			console.log("Meeting presenter/passenger functionality is disabled.");
		//			return;
		//		}

		calendarService.getCalendarEventsForMeetingCenterInSyncCommunicationJSON().then(function (data) {
			presentationService.setRunningConfs(data.data);
			presentationService.showRunningConfsListDialog();
		});

		var joinedConf = presentationService.getConf();
		if (Object.keys(joinedConf).length != 0) {
			var userID = context.getModel().userVO.objectBaseUuid;
			var confID = joinedConf.conf_event.objectBaseUuid;
			var confGuests = angular.toJson(joinedConf.conf_participants);
			conferenceService.getConferenceParticipantsJSON(confID)
				.then(function (data) {
					presentationService.updateConfParticipants(data.data);
				});

			conferenceService.synchronizeConferenceJSON(userID, confID, confGuests).then(function (data) {
				//				presentationService.updateConfParticipants(data.data.rollcall);
				presentationService.updateConfSyncKeyVal(data.data.synckeyval);
				console.log("in refresh", presentationService.getConf());
			});
		}

	}

	$scope.handleStopConferencePolling = function (sessionPollingVO) {
		leaveIfJoinedConfNotRunning(sessionPollingVO);
	}

	var leaveIfJoinedConfNotRunning = function (sessionPollingVO) {
		// if joinedconf is no more running conf
		var joinedConf = presentationService.getConf();
		if (Object.keys(joinedConf).length != 0 && joinedConf.conf_event != undefined && joinedConf.conf_event.objectBaseUuid === sessionPollingVO.objectUuid) {
			var joinedConf = presentationService.getConf();
			var confSyncVo = joinedConf.conf_sync_vo;
			presentationService.leaveConf(currentUserVO.objectBaseUuid)
				.then(function (data) {
					if (confSyncVo != undefined && confSyncVo != null)
						repositoryService.closeDocumentViewer(confSyncVo);
					$scope.handleRefreshConferencePolling();
				});
		} else {
			$scope.handleRefreshConferencePolling();
		}
	}


	$scope.handleRefreshConferencePolling();
	$scope.showRunningHostedConference();

	// $scope.handleRefreshConferencePolling= function()
	// {
	// 	var confID = $rootScope.CONF.CONF_EVENT.objectBaseUuid;
	// 	//var userID = $rootScope.CONF.CONF_EVENT.host;
	// 	var userID = context.getModel().userVO.objectBaseUuid;
	// 	var confGuests = angular.toJson($rootScope.CONF.CONF_PARTICIPANTS);

	// 	//console.log("HANDLING CONFID:"+confID+" USERID:"+userID+" CONFGUESTS:"+confGuests);
	// 	if(confID!=null && userID!=null && confGuests!=null)
	// 		{
	// 			//alert("ROOTSCOPE.CONF.CONF_PARTICIPANTS:"+$rootScope.CONF.CONF_PARTICIPANTS +"   LENGTH:"+$rootScope.CONF.CONF_PARTICIPANTS.length);
	// 			var tempLength = $rootScope.CONF.CONF_PARTICIPANTS.length;
	// 			if(tempLength==undefined || tempLength==0)
	// 			{
	// 				conferenceService.getConferenceParticipantsJSON(confID).then(function(data)
	// 	 		{
	// 	 			$rootScope.CONF.CONF_PARTICIPANTS = data.data;
	// 	 			confGuests = angular.toJson($rootScope.CONF.CONF_PARTICIPANTS);
	// 	 			getSynechronizeConfJson(userID, confID, confGuests);
	// 	 		});
	// 			}
	// 			else
	// 			{
	// 				getSynechronizeConfJson(userID, confID, confGuests);
	// 			}
	//  	}
	// }

	$scope.handleStartDocumentSharingFromPolling = function () {
		var joinedConf = presentationService.getConf();
		var confID = joinedConf.conf_event.objectBaseUuid;
		conferenceService.getSynchronizedCommunicationVOJSON(confID).then(function (data) {
			presentationService.updateConfSyncVO(data.data);

			var confObject = data.data;
			confObject.isPresentMode = true;
			confObject.isAttendee = true;
			confObject.hostUser = joinedConf.conf_event.host;
			homeContext.confCurrentPageNumber = confObject.pageNumber;
			repositoryService.openPdfViewer(data.data.documentBaseUuid, confObject);
		});
	}

	$scope.handleStopDocumentSharingFromPolling = function () {
		var joinedConf = presentationService.getConf();
		var confID = joinedConf.conf_event.objectBaseUuid;
		var confSyncVo = joinedConf.conf_sync_vo;

		// conferenceService.getSynchronizedCommunicationVOJSON(confID).then(function(data)
		// {
		// 	presentationService.updateConfSyncVO(data.data);
		repositoryService.closeDocumentViewer(confSyncVo);
		// });
	}

	$scope.stopDocumentPresentation = function () {
		var joinedConf = presentationService.getConf();
		var confID = joinedConf.conf_event.objectBaseUuid;
		var documentId = joinedConf.conf_sync_vo.documentBaseUuid;

		var syncCommVO = { "name": "SHARE_DOCUMENT", "objectBaseUuid": confID, "documentBaseUuid": documentId, "value": "STOP_SHARING" };
		conferenceService.shareDocument(angular.toJson(syncCommVO)).then(function (data) {
			presentationService.updateConfSyncVO(data.data);
		});
	}

	// old
	var getSynechronizeConfJson = function (userID, confID, confGuests) {
		//console.log("BEFORE SYNCHRONZIE CONFERENCE: CONFID ",confID);
		//console.log("USERID:",userID);
		//console.log("CONFGUETS",confGuests);
		conferenceService.synchronizeConferenceJSON(userID, confID, confGuests).then(function (data) {
			$scope.CONF.CONF_PARTICIPANTS = data.data.rollcall;
			$scope.CONF.CONF_SYNC_KEY_VAL = data.data.synckeyval;
			$rootScope.CONF.CONF_PARTICIPANTS = $scope.CONF.CONF_PARTICIPANTS;
			$rootScope.CONF.CONF_SYNC_KEY_VAL = $scope.CONF.CONF_SYNC_KEY_VAL;
			$scope.CONF.CONF_IS_CONF_PRESENTER = false;
			var contextObjectBaseUuid = context.getModel().userVO.objectBaseUuid;
			if ($scope.CONF.CONF_SYNC_KEY_VAL != undefined && $scope.CONF.CONF_SYNC_KEY_VAL != null && $scope.CONF.CONF_SYNC_KEY_VAL.length > 0) {
				for (var i = 0; i < $scope.CONF.CONF_SYNC_KEY_VAL.length; i++) {
					if ($scope.CONF.CONF_SYNC_KEY_VAL[i].key == "GRANTDESKTOP") {
						$scope.CONF.CONF_CURRENT_PRESENTER = $scope.CONF.CONF_SYNC_KEY_VAL[i].value;
						//console.log("Current Presenter:"+$scope.CONF.CONF_SYNC_KEY_VAL[i].value);
						if (contextObjectBaseUuid == $scope.CONF.CONF_CURRENT_PRESENTER) {
							//console.log("THIS USER IS NOW PRESENTER");
							$scope.CONF.CONF_IS_CONF_PRESENTER = true;
						}
					}
				}
			}
			//$scope.CONF.CONF_CURRENT_PRESENTER
			console.log("ROOT SCOPE CONF:", $rootScope.CONF);
			//console.log("IS_CONF_HOST:",$rootScope.CONF.CONF_IS_CONF_HOST );
			console.log("IS_CONF_PRESENTER:", $scope.CONF.CONF_IS_CONF_PRESENTER);
			//console.log("ROOT SCOPE IS_CONF_PRESENTER:",$rootScope.CONF.CONF_IS_CONF_PRESENTER );

			//If user is host then
			//Change rootScope value of CONF_IS_CONF_PRESENTER
			//If user is not host then,
			//If rootscope user is not presenter then make him presenter
			//If rootscope user is presenter then remove presenter access

			var confPreDocCntrl = angular.element('#conf-prsnt-producer-cntrl').scope();
			$rootScope.CONF.CONF_IS_CONF_PRESENTER = $scope.CONF.CONF_IS_CONF_PRESENTER;
			confPreDocCntrl.initPresenterWindow();
		});
	}

	// new
	$scope.showRunningConfList = function () {
		//		if(MobileUtil.isApp() && MobileUtil.isJupOffline())
		//		{
		//			return;
		//		}
		calendarService.getCalendarEventsForMeetingCenterInSyncCommunicationJSON().then(function (data) {
			presentationService.setRunningConfs(data.data);
			presentationService.showRunningConfsListDialog();
		});
	}

	//old
	$scope.showLivePresentationOptions = function (flag) {
		if (flag) {
			calendarService.getCalendarEventsForMeetingCenterInSyncCommunicationJSON().then(function (data) {
				var ongoingPresentationVOs = data.data;
				if (ongoingPresentationVOs != undefined && ongoingPresentationVOs != null && ongoingPresentationVOs.length > 0) {
					$scope.showPresentationStartAlert = true;
				}
				else {
					$scope.showPresentationStartAlert = false;
				}
			});
		}
		else {
			$scope.showPresentationStartAlert = false;
			if ($rootScope.CURR_PRESENT_DLG != undefined) {
				$rootScope.CURR_PRESENT_DLG.dismiss();
				$rootScope.CURR_PRESENT_DLG = undefined;
				if ($scope.CONF.CONF_IS_CONF_PRESENTER != undefined && !$scope.CONF.CONF_IS_CONF_PRESENTER) {
					$scope.CONF.CONF_IS_CONF_PRESENTER = undefined;
					var alertMsg = localizedMessages.get('ERR_PRESENTATION_CLOSED_BY_HOST');
					$scope.closePresentationAltDlg = bvDialogService.showMcAlertMessage(alertMsg);
					setTimeout(function () {
						if ($scope.closePresentationAltDlg != undefined) {
							$scope.closePresentationAltDlg.dismiss();
						}
					}, 5000);
				}
			}
		}
	}

	$scope.showCurrentOningPresentations = function () {
		//		if(MobileUtil.isApp() && MobileUtil.isJupOffline())
		//		{
		//			return;
		//		}
		calendarService.getCalendarEventsForMeetingCenterInSyncCommunicationJSON().then(function (data) {
			var ongoingPresentationVOs = data.data;
			console.log("OngoingPresentationVOs : ", ongoingPresentationVOs);
			if (ongoingPresentationVOs != undefined || ongoingPresentationVOs != null || ongoingPresentationVOs.length > 0) {
				bvDialogService.showCurrentLivePresentations(ongoingPresentationVOs).then(function (presentationToShow) {
					if (presentationToShow != null || presentationToShow != undefined) {
						showEventOrPresentationDetails(presentationToShow.objectBaseUuid, presentationToShow.objectVersionUuid, true, false);
					}
				});
			}
			else {
				$scope.showPresentationStartAlert = false;
				if ($rootScope.CURR_PRESENT_DLG != undefined) {
					$rootScope.CURR_PRESENT_DLG.dismiss();
					$rootScope.CURR_PRESENT_DLG = undefined;
				}
				//alert("No live meeting found.");
				var alertMsg = localizedMessages.get('ERR_PRESENTATION_NOT_GOING_ON_CURRENTLY');
				bvDialogService.showMcAlertMessage(alertMsg);
			}
		});
	}

	$scope.showEventsForTheDay = function (eventObj, eventsForDays) {
		var SELECTED_EVENT = eventObj.objectBaseUuid;
		var dateValue = StringUtils.momentL(parseInt(eventObj.startDateGMTMillis)).format('MMMM D, YYYY');
		var EVENTS_LIST = { "DATE": dateValue, "EVENTS": eventsForDays, "SELECTED_EVENT": SELECTED_EVENT }
		$rootScope.EVENTS_LIST = EVENTS_LIST;
		$state.go('home.meetingDetails');
	}

	$scope.handleEventPlink = function (objectBaseUuid) {
		//console.log("IN getEventsForTheDayForPlink-----");
		$rootScope.SELECTED_CALENDAR_STATE_NAME = "home.meetings";
		calendarService.getEventsForTheDayForPlink(objectBaseUuid).then(function (data) {
			var events = data.data;
			//console.log("EventsForPlink:",events);
			if (!events || events.length == 0) {
				bvDialog.showMessage(localizedMessages.get('ACCESS_DENIED'));
			}
			else {
				var listObjects = [];
				var eventPresent = false;
				for (var i in events) {
					var startTime = parseInt(data.data[i].startDateGMTMillis);
					var endTime = data.data[i].endDateGMTMillis;
					var dayOfYear = moment(parseInt(startTime)).format('DDDD');
					var isEventOwner = (data.data[i].isEventOwner == "true") ? true : false;
					var eventObjectBaseUuid = data.data[i].objectBaseUuid;
					var eventObjectVersionUuid = data.data[i].objectVersionUuid;

					if (objectBaseUuid == eventObjectVersionUuid) {
						objectBaseUuid = eventObjectBaseUuid;
						eventPresent = true;
					}
					else if (objectBaseUuid == eventObjectBaseUuid) {
						eventPresent = true;
					}

					listObjects.push({
						objectBaseUuid: eventObjectBaseUuid,
						objectVersionUuid: eventObjectVersionUuid,
						name: data.data[i].name,
						isEventOwner: isEventOwner,
						portal: data.data[i].portal,
						startDateGMTMillis: startTime,
						endDateGMTMillis: endTime,
						data: data.data[i],
						startDate: moment(parseInt(startTime)).format('MMM D, YYYY hh:mm A'),
						endDate: moment(parseInt(endTime)).format('MMM D, YYYY hh:mm A')
					});
				}
				var sort = function (a, b) {
					var r = a.startDateGMTMillis;
					var p = b.startDateGMTMillis;
					if (p > r) { return -1; }
					else if (r > p) { return 1; }
					return 0;
				}
				listObjects.sort(sort);

				if (eventPresent) {
					if (!legacyUserService.isNasdaqUI) {
						var SELECTED_EVENT = objectBaseUuid;
						var dateValue = StringUtils.momentL(parseInt(events[0].startDateGMTMillis)).format('MMMM D, YYYY');
						var EVENTS_LIST = { "DATE": dateValue, "EVENTS": listObjects, "SELECTED_EVENT": SELECTED_EVENT }
						$rootScope.EVENTS_LIST = EVENTS_LIST;
						$state.go('home.meetingDetails');
						setCurrentState("home.meetings");
					}
					else {
						if (homeModel.isBOParentofMeetingMinute) {
							$state.go('home.meetingCreation', {
								objectBaseUuid: objectBaseUuid,
								operation: 'editEvent',
								minutesSelected: 'true',
								loggedUserRole: 'NOT_OWNER'
							})
						} else {
							$state.go('home.openMeeting', {
								objectBaseUuid
							})
						}
					}
				}
				else {
					bvDialog.showMessage(localizedMessages.get('ACCESS_DENIED'));
				}
			}
		});
	}
	//Following method will be called from calenrdar controller
	/*$scope.showEventDetails = function(eventObjectBaseUuid, eventObjectVersionUuid)
	{
		$scope.eventObjectBaseUuid = eventObjectBaseUuid;
		$scope.eventObjectVersionUuid = eventObjectVersionUuid;
		$scope.showSubmit = true;
		showEventOrPresentationDetails(eventObjectBaseUuid, eventObjectVersionUuid, false, false);
	}*/

	$scope.sendEventResponse = function (eee) {
		var objectVersionUuid = $scope.eventObjectVersionUuid;
		var objectBaseUuid = $scope.eventObjectBaseUuid;
		var response = this.response;

		calendarService.setCalendarResponse(objectVersionUuid, objectBaseUuid, response);
		$scope.showSubmit = false;
		var dashboardScope = angular.element(document.querySelector('[ui-view="content"]')).scope();
		if (dashboardScope.fromOpenItems) {
			dashboardScope.fromOpenItems = false;
			dashboardScope.countItems = dashboardScope.countItems - 1;
			for (var i = 0; i < dashboardScope.dashboardList.length; i++) {
				if (dashboardScope.dashboardList[i].objectBaseUuid == objectBaseUuid) {
					dashboardScope.dashboardList.splice(i, 1);
				}
			}
			for (var i = 0; i < dashboardScope.eventList.length; i++) {
				if (dashboardScope.eventList[i].objectBaseUuid == objectBaseUuid) {
					dashboardScope.eventList.splice(i, 1);
				}
			}

		}
	}


	$scope.showEventDetailsForInstantMeeting = function (eventObjectBaseUuid, eventObjectVersionUuid) {
		conferenceService.startConferenceJSON(eventObjectBaseUuid).then(function (data) {
			showEventOrPresentationDetails(eventObjectBaseUuid, eventObjectVersionUuid, false, true);
		});

	}

	var showEventOrPresentationDetails = function (eventObjectBaseUuid, eventObjectVersionUuid, callFromPolling, isInstantMeeting) {
		$scope.CONF = modelService.getInitialConferenceObject();
		calendarService.getCalendarEventAttachments(eventObjectBaseUuid).then(function (data) {
			if (data) {
				$scope.CONF.CONF_ATTACHMENTS = data.data;

				if (data.data != null && data.data != undefined && data.data.length > 0) {
					$scope.CONF.CONF_CONTAINS_ATTACHMENT = true;
				}
				//We might need to combine these two calls together unless there is any specific reason.
				calendarService.loadGuestandResponseForEvent(eventObjectVersionUuid).then(function (data) {
					if (data) {
						$scope.CONF.CONF_EVENT = data.data;

						$scope.CONF.CONF_EVENT.documentVOList = $scope.CONF.CONF_ATTACHMENTS;
						$scope.CONF.CONF_EVENT.fromDate = moment(parseInt($scope.CONF.CONF_EVENT.startDateGMTMillis)).format('MMM D, YYYY hh:mm:ss A');
						$scope.CONF.CONF_EVENT.toDate = moment(parseInt($scope.CONF.CONF_EVENT.endDateGMTMillis)).format('MMM D, YYYY hh:mm:ss A');

						if (data.data.agendaJson != null && data.data.agendaJson != undefined && data.data.agendaJson != "") {
							var tempAgenda = JSON.parse($scope.CONF.CONF_EVENT.agendaJson);
							if (tempAgenda.length > 0) {
								$scope.CONF.CONF_CONTAINS_AGENDA = true;
							}
						}
						var guests = data.data.guests;
						var eventResponseList = data.data.eventResponseList;

						for (var i in guests) {
							for (var k in eventResponseList) {
								if (guests[i].objectBaseUuid == eventResponseList[k].createdByBaseUuid) {
									guests[i].response = eventResponseList[k].response.toLowerCase();
								}
							}
						}
						$scope.CONF.CONF_EVENT.guests = guests;
						$scope.CONF.CONF_GUESTS = guests;

						var contextObjectBaseUuid = context.getModel().userVO.objectBaseUuid;

						//If current user is host then
						if (contextObjectBaseUuid === $scope.CONF.CONF_EVENT.host) {
							$scope.CONF.CONF_IS_CONF_HOST = true;
							if (callFromPolling)//Someone from presenter has started meeting...Make host as non presenter
							{
								$scope.CONF.CONF_IS_CONF_PRESENTER = false;
								$scope.CONF.CONF_CURRENT_PRESENTER = "";
							}
							else {
								$scope.CONF.CONF_IS_CONF_PRESENTER = true;
								$scope.CONF.CONF_CURRENT_PRESENTER = contextObjectBaseUuid;
							}
							$scope.CONF.CONF_UI_PRESENT_BTN_ACTION_TXT = $scope.CONF.CONF_UI_START_BTN_TXT;
							$scope.CONF.CONF_UI_PRESENT_EXIT_BTN_ACTION_TXT = $scope.CONF.CONF_UI_STOP_BTN_TXT;
						}
						else {
							$scope.CONF.CONF_IS_CONF_HOST = false;
							$scope.CONF.CONF_IS_CONF_PRESENTER = false;
							$scope.CONF.CONF_CURRENT_PRESENTER = "";
							$scope.CONF.CONF_UI_PRESENT_BTN_ACTION_TXT = $scope.CONF.CONF_UI_JOIN_BTN_TXT;
							$scope.CONF.CONF_UI_PRESENT_EXIT_BTN_ACTION_TXT = $scope.CONF.CONF_UI_LEAVE_BTN_TXT;
						}

						if (isInstantMeeting) {

							conferenceService.getConferenceParticipantsJSON($scope.CONF.CONF_EVENT.objectBaseUuid).then(function (data) {
								$scope.CONF.CONF_PARTICIPANTS = data.data;
								//console.log("++++++CONF_PARTICIPANTS",$scope.CONF.CONF_PARTICIPANTS);

								$rootScope.CONF = $scope.CONF;
								console.log("Starting Instant Meeting...", data);
								var confPrsntProducerModal = new modal.open({
									windowClass: 'bv-conf-present-doc-dialog',
									templateUrl: 'modules/conference/ConfPrsntProducer.tpl.html',
									controller: 'ConfPrsntProducerCntrl',
									scope: $scope,
								});

								$rootScope.CURR_PRESENT_DLG = confPrsntProducerModal;
							});
						}
						else if (callFromPolling) {
							//Join Presentation
							conferenceService.joinConferenceJSON(eventObjectBaseUuid).then(function (data) {
								//Show meeting
								console.log("JOIN CONFERENCE RETURN OBJ", data);
								conferenceService.getConferenceParticipantsJSON($scope.CONF.CONF_EVENT.objectBaseUuid).then(function (data) {
									$scope.CONF.CONF_PARTICIPANTS = data.data;
									console.log("++++++CONF_PARTICIPANTS", $scope.CONF.CONF_PARTICIPANTS);

									$rootScope.CONF = $scope.CONF;
									var confPrsntProducerModal = new modal.open({
										windowClass: 'bv-conf-present-doc-dialog',
										templateUrl: 'modules/conference/ConfPrsntProducer.tpl.html',
										controller: 'ConfPrsntProducerCntrl',
										scope: $scope,
									});

									$rootScope.CURR_PRESENT_DLG = confPrsntProducerModal;
									//console.log("CURR DLG:",$rootScope.CURR_PRESENT_DLG);
									//$rootScope.IS_UI_SHOWING_PRESENTATION = true;
								});
							});
						}
					}
				});
			}
		});
	}
	/////End of Conf Code////////////////////////////////////////////////////////////////////////////////////////////

	loadContext.getEnabledFeatures();

	$scope.footerClickHandler = function (event, type) {
		switch (type) {
			case 'support':
				//					bvDialogService.showBvModal({
				//						windowClass : 'bv-support',
				//						controller : 'supportController',
				//						bodyTemplate : 'modules/home/Support.tpl.html',
				//						buttons : null
				//					});
				bvDialog.support();
				break;
			case 'recycle':
				bvDialog.recycle();
				//					if(!$scope.showRecycle)
				//						loadRecycle.loadRecyleData();
				//					$scope.showRecycle = !$scope.showRecycle;
				//					if($scope.showRecycle)
				//						$scope.showPreferences= false;
				break;
			case 'preferences':
				if (MobileUtil.isApp() && MobileUtil.isJupOffline()) {
					return;
				}
				bvDialog.preferences();
				//				$scope.showPreferences = !$scope.showPreferences;
				//				if($scope.showPreferences)
				//					$scope.showRecycle= false;

				break;
			case 'viewProfile':
				$scope.selectUser(currentUserVO)
				break;
		}
	}

	//checkForResourceId();

	/*var sidebarMenuModules = [
						   { 'name': 'CALENDAR', 'state': 'home.calendar', 'iconUrl': 'resources/assets/svgs/calendar.svg'},
							   { 'name': 'SIGNATURES', 'state': 'home.signatures', 'iconUrl': 'resources/assets/svgs/signatures.svg' },
							   { 'name': 'APPROVALS', 'state': 'home.approvals', 'iconUrl': 'resources/assets/svgs/approvals.svg' },
							   { 'name': 'MESSAGES', 'state': 'home.messages', 'iconUrl': 'resources/assets/svgs/messages.svg' },
							   { 'name': 'MEETINGS', 'state': 'home.meetings', 'iconUrl': 'resources/assets/svgs/meetings.svg' },
							   { 'name': 'DIRECTORY', 'state': 'home.directory', 'iconUrl': 'resources/assets/svgs/directory.svg' },
							   { 'name': 'BRIEFCASE', 'state': 'home.briefcase', 'iconUrl': 'resources/assets/svgs/briefcase.svg' },
							   { 'name': 'NEWITEMS', 'state': 'home.newitems', 'iconUrl': 'resources/assets/svgs/briefcase.svg' },
							   { 'name': 'REPOSITORY', 'state': 'home.repository', 'iconUrl': 'resources/assets/svgs/repository.svg' },
							   { 'name': 'SURVEYS', 'state': 'home.surveys', 'iconUrl': 'resources/assets/svgs/surveys.svg'}
						];*/


	var sidebarMenuTeams = [
		//		                      	{ 'name': 'TEST', 'state': 'homeSignature', 'iconUrl': '' },
		//		                      	{ 'name': 'TEST', 'state': 'homeApprovals', 'iconUrl': '' },
	];

	$scope.topSidebarMenu = topSidebarMenu;
	//$scope.sidebarMenu = sidebarMenuModules;
	$scope.sidebarMenu = [];
	$scope.footerMenu = footerMenu;
	$scope.showRightClick = false;
	$scope.userAgentMobileClient = false;

	var checkUserAgent = function () {
		//		var standalone = window.navigator.standalone,
		//	    userAgent = window.navigator.userAgent.toLowerCase(),
		//	    ios = /iphone|ipod|ipad/.test( userAgent );
		//
		//	    if()
		//	    	$scope.userAgentMobileClient = true;
		$scope.userAgentMobileClient = MobileUtil.isMobileClient();
		//console.log('scope.userAgentMobileClient:' + $scope.userAgentMobileClient);
	}

	$scope.mobileMenuFlag = { showMenu: false };
	$scope.isExpanded = false;
	$scope.testClick = function (item) {
		var newItemModel = repositoryModelService.getInstance("NEW_ITEM");
		newItemModel.moduleClicked(item.name);
		//$scope.middleBarObject = {};
		if ($scope.showMinimizeModules && homeService.homeContext.expandedMenu) {
			angular.element('[home-sidebar]')[0].style['overflow'] = 'hidden';
			$scope.expandMenu();
		}
		if (item.name == "BRIEFCASE" || item.name == "DOCUMENTS" || item.name == "NEW ITEMS") {
			$scope.mobileRepositoryClicked = true;
		} else {
			$scope.mobileRepositoryClicked = false;
		}
		if (!$scope.isExpanded && (item.name == "DOCUMENTS" && MobileUtil.isPhone())) {
			$scope.expandMenu();
			$scope.isExpanded = true;
		}
		$scope.inMobileInbox = false;
		if (item.name == "INBOX") {
			$scope.inMobileInbox = true;
		} else {
			$scope.inMobileInbox = false;
		}

		$scope.inMobileApprovals = false;
		if (item.name == "APPROVALS") {
			$scope.inMobileApprovals = true;
		} else {
			$scope.inMobileApprovals = false;
		}

		$scope.inMobileSurveys = false;
		if (item.name == "SURVEYS") {
			$scope.inMobileSurveys = true;
		} else {
			$scope.inMobileSurveys = false;
		}

		$scope.inMobileTaskManagement = (item.name === "TASK MANAGEMENT");
		$scope.inMobileEmail = (item.name === "EMAIL");
		
		//changes for MEETX-11798
		var flag=true;
		if(item.state === "home.newitems" || item.state === "home.documents")
		{
			flag=false;
		}
		
		if(flag){
			if(!MobileUtil.isJupOffline()){
			var newItemModel = repositoryModelService.getInstance("NEW_ITEM");
			if(!newItemModel.newItemsLoadedFull()){
			 newItemModel.newItemsLoadedFull(true);
			 getAllLightWeightNewItems();
			}
		}
		}
	}
	checkUserAgent();

	$scope.$parent.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1] || "home");

	$scope.homeClick = function ($event) {
		if ($scope.showRightClick) $scope.showRightClick = false;

		var showTeamSpaceTemp = angular.copy($scope.showTeamSpace);
		var showMenuTemp = angular.copy($scope.mobileMenuFlag.showMenu);

		$timeout(function () {
			if (showTeamSpaceTemp && $scope.showTeamSpace) $scope.showTeamSpace = false;
		}, 100)

		$timeout(function () {
			if (showMenuTemp && $scope.mobileMenuFlag.showMenu) $scope.mobileMenuFlag.showMenu = false;
		}, 100)

		$scope.$broadcast('HOME_CONTROLLER_CLICK');
	}

	$scope.classActive = function (item) {
		return item.active ? 'active' : '';
	}

	bvPolling.start();
	bvPollingProcessor.registerForPolling($scope, '');

	//polling for N3A session validation

	if ($scope.isN3aUser && enableN3aAuthSession) {
		N3aPollingService.startPolling();
	}

	$scope.classActive = function (item) {
		return item.active ? 'active' : '';
	}
	$scope.searchUser = { displayName: '' };
	$scope.searchGroup = { name: '' };

	$scope.startsWith = function (actual, expected) {
		var lowerStr = (actual + "").toLowerCase();
		return lowerStr.indexOf(expected.toLowerCase()) === 0;
	};

	homeService.applyBrandingColors();
	$scope.SVGColors = context.getModel();

	if (!legacyUserService.isNasdaqUI) {
		$scope.maxHeight = 100;
		$scope.maxWidth = 204;

		if (MobileUtil.isMobileClient()) {
			$scope.maxHeight = 100;
			$scope.maxWidth = 200;
		}
	}
	else {
		$scope.maxHeight = 68;
		$scope.maxWidth = 204;

		if (MobileUtil.isMobileClient()) {
			$scope.maxWidth = 214;
		}
	}



	$scope.openPinSetup = function () {
		homeService.pinSetupDummy().then(function (data) {

		});
	}

	////////////////////////////////// start ///////////////////
	// $scope.showUserFilter = false;
	$scope.users = {};

	$scope.getTeamUsers = function (alsoImages) {
		return teamService.getTeamSpaceUsersJSON(context.getModel().portalVO.objectBaseUuid).then(function (data) {
			$scope.users = data;
			// getSpaceOwners();
			if (alsoImages) getUserImages();
			getCurrentUserOnTop();

			// if($scope.users.length > 15)
			// {
			// 	$scope.showUserFilter = true;
			// }
		})
	}

	$scope.searchButtonClick = function () {
		if (!$scope.expandedMenuRight) {
			$scope.expandMenuRight();
		}
	}

	//	$scope.expandedDocumentSearchIcon = false;
	//	$scope.documentSearchButtonClick = function()
	//	{
	//		if($scope.expandedDocumentSearchIcon)
	//		{
	//			document.querySelector('.bv-search-bar').style.width = '35px';
	//			document.querySelector('.bv-search-bar').style.border = 'none';
	//			document.querySelector('.documentSearchInput').style.display = 'none';
	//			$scope.expandedDocumentSearchIcon = false;
	//		}
	//		else
	//		{
	//			//document.querySelector('.bv-search-bar').style.width = '330px';
	//			document.querySelector('.bv-search-bar').style.width = '250px';
	//			document.querySelector('.bv-search-bar').style.border = "1px solid #515151";
	//			document.querySelector('.documentSearchInput').style.display = '';
	//			$scope.expandedDocumentSearchIcon = true;
	//		}
	//	}

	$scope.openPinSetup = function () {
		homeService.pinSetupDummy().then(function (data) {

		});
	}

	$scope.openZoomIn = function () {
		homeService.zoomInDummy().then(function (data) {

		});
	}

	$scope.openZoomOut = function () {
		homeService.zoomOutDummy().then(function (data) {

		});
	}

	$scope.openActualSize = function () {
		homeService.actualSizeDummy().then(function (data) {

		});
	}

	var getCurrentUserOnTop = function () {
		for (var i in $scope.users) {
			if ($scope.users[i].objectBaseUuid == currentUserVO.objectBaseUuid) {
				var current = $scope.users[i];
				$scope.users.splice(i, 1);
				$scope.users.splice(0, 0, current);
				return;
			}
		}
	}

	$scope.userImages = [];
	$scope.getUserThumbnail = function (user) {
		if ($scope.userImages[user.objectBaseUuid]) {
			return "data:image/png;base64," + $scope.userImages[user.objectBaseUuid];
		}
		else {
			return '';
		}
	}

	$scope.getUserThumbnailById = function (userBaseUuid) {
		if ($scope.userImages[userBaseUuid]) {
			return "data:image/png;base64," + $scope.userImages[userBaseUuid];
		}
		else {
			return '';
		}
	}

	var getUserImages = function () {
		var input = "";
		if ($scope.users.length > 0) {
			var c = $scope.users.length;
			if (c <= 10) {
				$scope.users.forEach((user, i) => {
					input +=  $scope.users[i].objectBaseUuid + ";;" + $scope.users[i].objectVersionUuid + ":::"
				});

				teamService.getUserProfileImages(input).
					then(function (data) {
						if (data != '') {
							for (var key in data) {
								if (data.hasOwnProperty(key) && data[key] != "") {
									$scope.userImages[key] = data[key];
								}
							}

						}
				})
			} else {
				while (c >= 0) {
					var batchUsers = [];
					if (c-10 < 0) {
						batchUsers = $scope.users.slice(0,c);
					} else {
						batchUsers = $scope.users.slice(c-10,c);
					}
					c = c-10;
					input = "";
					batchUsers.forEach((user, i) => {
						input +=  batchUsers[i].objectBaseUuid + ";;" + batchUsers[i].objectVersionUuid + ":::"
					});

					teamService.getUserProfileImages(input).
						then(function (data) {
							if (data != '') {
								for (var key in data) {
									if (data.hasOwnProperty(key) && data[key] != "") {
										$scope.userImages[key] = data[key];
									}
								}
							}
					})
				}
			}

		}
	};
	var getUserImagesForCurrentUser = function () {

		teamService.getUserProfileImage(context.getModel().userVO.objectBaseUuid, context.getModel().userVO.objectVersionUuid).
			then(function (data) {
				if (data != '' && data.profileImage != undefined && data.profileImage != '') {
					$scope.userImages[data.objectBaseUuid] = data.profileImage;
				}
			})

	};

	$scope.isFollowing = false;
	$scope.mySubscriptions = {};
	$scope.userSelected = false;
	$scope.selectedUser = {};
	// $scope.spaceGroupOwner = false;
	$scope.enableEdit = false;
	$rootScope.buttonDisabled = false;
	$scope.selectUser = function (user) {
		$rootScope.buttonDisabled = true;
		// if(user.objectBaseUuid == $scope.selectedUser.objectBaseUuid)
		// {
		// 	clearUserSelection();
		// 	return;
		// }

		teamService.getUserDataBybaseUuid(user.objectBaseUuid).
			then(function (data) {
				$scope.selectedUser = data;

				$scope.languages = context.getModel().localeList;

				for (var i in $scope.languages) {
					if ($scope.selectedUser.localeType == $scope.languages[i].value) {
						$scope.selectedUser.localeType = $scope.languages[i].name;
					}
				}

				$scope.userSelected = true;
				//document.querySelector('.teamUserProfile').style.right = document.querySelector('[home-right-sidebar]').style.width;

				// $scope.spaceGroupOwner = false;
				// for(i in $scope.spaceOwners)
				// {
				// 	if($scope.selectedUser.objectBaseUuid == $scope.spaceOwners[i].objectBaseUuid)
				// 	{
				// 		$scope.spaceGroupOwner = true;
				// 		break;
				// 	}
				// }
				teamService.isUserAlreadySubscribed($scope.selectedUser.objectBaseUuid).
					then(function (res) {
						if (!legacyUserService.isNasdaqUI) {
							bvDialog.showUserProfile($scope.selectedUser, res);
						}
						else {
							userPanelService.showUserPanel($scope.selectedUser, res);
						}
					});
			})
	}

	$scope.removeUser = function () {
		teamService.removeUserFromPortal($scope.userToBeRemoved.objectBaseUuid)
			.then(function (data) {
				var alsoImages;
				$scope.getTeamUsers(alsoImages = false);
				bvDialog.showMessage($scope.userToBeRemoved.displayName + localizedMessages.get('USER_REMOVED'));
				clearUserSelection();
			});
	}

	$scope.removeUserConfirmation = function (user) {
		$scope.userToBeRemoved = user;
		bvDialog.confirm(localizedMessages.get('REMOVE_USER_CONFIRMATION_MSG'), $scope.removeUser);
	}

	$scope.removeUserFromGroup = function () {
		teamService.removeUserFromGroup($scope.fromGroupToBeRemoved.objectBaseUuid, $scope.userToBeRemoved.objectBaseUuid)
			.then(function (data) {
				$scope.getGroupUsersForGroup($scope.fromGroupToBeRemoved);
				$scope.enableUserRemoveOption();
				clearUserSelection();
			});
	}


	$scope.removeUserFromGroupConfirmation = function (group, user, e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		$scope.userToBeRemoved = user;
		$scope.fromGroupToBeRemoved = group;
		bvDialog.confirm(localizedMessages.get('REMOVE_USER_FROM_GROUP_CONFIRMATION_MSG'), $scope.removeUserFromGroup);
	}

	//	$scope.removeGroupFromTeamSpace = function()
	//	{
	//		teamService.removeGroupFromTeamSpace($scope.selectedGroup.objectBaseUuid)
	//		.then(function(data)
	//		{
	//			ngDialog.open({
	//				template: '<div style="text-align:center">' + $scope.selectedGroup.name + localizedMessages.get('GROUP_REMOVED_FROM_TEAMSPACE') +  '</div>',
	//				plain: true
	//			});
	//			$scope.getAllGroupsOfPortal();
	//			clearUserSelection();
	//		});
	//	}

	$scope.showUserRemoveOption = false;

	$scope.toggleGroupAdminDropdown = function ($event, group, openStatus) {
		if (openStatus) {
			$event.preventDefault();
			$event.stopPropagation();
		} else {
			$scope.getGroupUsersForGroup(group, openStatus);
		}
	};

	$scope.enableUserRemoveOption = function (group, e) {
		$scope.showUserRemoveOption = true;
	}

	var disableUserRemoveOption = function () {
		$scope.showUserRemoveOption = false;
	}

	//	$scope.removeGroupConfirmation = function(group, e)
	//	{
	//		if (e)
	//		{
	//      		e.preventDefault();
	//      		e.stopPropagation();
	//    	}
	//
	//		$scope.selectedGroup = group;
	//		ngDialog.open({
	//			template: '<div style="text-align:center">' + localizedMessages.get('REMOVE_GROUP_CONFIRMATION_MSG') + $scope.selectedGroup.name + '?' +
	//			'<hr><button class="yesButton" ng-click="closeThisDialog();removeGroupFromTeamSpace()">' +  localizedMessages.get('YES') + '</button>' +
	//			'<button class="noButton" ng-click="closeThisDialog()">' +  localizedMessages.get('NO') + '</button></div>',
	//			plain: true,
	//			scope: $scope
	//		});
	//	}

	$scope.countries = {};

	teamService.getCountryNameValueListJSON()
		.then(function (data) {
			if (data != undefined && data != "") {
				data.splice(0, 1);
				$scope.countries = data;
			}
		})

	$scope.closeUserProfile = function () {
		clearUserSelection();
	}

	var clearUserSelection = function () {
		$scope.selectedUser = {};
		$scope.userSelected = false;
	}

	$scope.isGroupManager = false;

	//	teamService.isGroupManager().
	//	then(function(response)
	//	{
	//		$scope.isGroupManager = response;
	//	});

	$scope.oneAtATime = true;

	$scope.showUsersInSidebar = true;

	if (context.getModel().showUsersInSidebar != undefined &&
		!context.getModel().showUsersInSidebar) {
		$scope.showUsersInSidebar = false;
	}

	$scope.showGroups = false;

	$scope.showGroupAdminButton = function (group) {
		return $scope.isPortalAdminUser || (StringUtils.isTeamSpaceGroup(group.objectBaseUuid) && $scope.isPortalOwner);
	}

	$scope.getAllGroupsOfPortal = function () {
		teamService.getAllGroupsOfPortal(context.getModel().portalVO.objectBaseUuid).
			then(function (data) {
				$scope.tsGroups = $scope.tsGroups.concat(data);
			})

		teamService.getAllTSGroupsForPortal(context.getModel().portalVO.objectBaseUuid).
			then(function (data) {
				$scope.tsGroups = $scope.tsGroups.concat(data);
			})
	}

	$scope.getGroupUsersForGroup = function (group, isOpen) {
		var groupIndex = $scope.tsGroups.indexOf(group);

		disableUserRemoveOption();
		//teamService.getGroupChildren($scope.tsGroups[groupIndex].objectBaseUuid).
		commonService.getGroupUsers($scope.tsGroups[groupIndex].objectBaseUuid).
			then(function (data) {
				$scope.tsGroups[groupIndex].groupUsers = [];
				if (data.data.groupUsers)
					$scope.tsGroups[groupIndex].groupUsers = data.data.groupUsers;
			})
	}

	//	$scope.getGroupUsers = function()
	//	{
	//		for(var i in $scope.tsGroups)
	//		{
	//			if($scope.tsGroups[i].objectBaseUuid == group.objectBaseUuid)
	//			{
	//				$scope.getGroupUsersByGroupIndex(i);
	//				return;
	//			}
	//		}
	//	}

	$scope.addToGroupLbl = localizedMessages.get('ADD_TO_GROUP');
	$scope.removeFromGroupLbl = localizedMessages.get('REMOVE_FROM_GROUP');

	$scope.multiSelect = true;
	$scope.selectUsersLbl = localizedMessages.get("SELECT_USERS")

	// $scope.showUsersForSelection = function(group, e)
	// {
	// 	var index = $scope.tsGroups.indexOf(group);
	// 	if (e)
	// 	{
	//      		e.preventDefault();
	//      		e.stopPropagation();
	//    	}
	//    	$scope.addToGroup = $scope.tsGroups[index];
	//      	//bvDialog.itemListSelection($scope.selectUsersLbl, $scope.users, $scope.addSelectedUserToGroup,$scope.multiSelect, true, false );
	//      	bvDialog.itemListSelection($scope.selectUsersLbl, true, null, $scope.addSelectedUserToGroup);
	// }

	$scope.addSelectedUserToGroup = function (selectedItems) {
		$scope.selectedUsers = selectedItems;
		// if(!keepOpen)
		// 	$scope.userListModal.dismiss('close');

		if ($scope.selectedUsers && $scope.selectedUsers.length != 0) {
			$scope.selectedUsersUuid = [];
			for (var i in $scope.selectedUsers) {
				$scope.selectedUsersUuid.push($scope.selectedUsers[i].objectBaseUuid);
			}

			teamService.addUserToGroup($scope.addToGroup.objectBaseUuid, JSON.stringify($scope.selectedUsersUuid)).
				then(function (data) {
					if (data == null || data == false) {
						bvDialog.showMessage(localizedMessages.get('ADD_USER_TO_GROUP_ERROR'));
					}
					else {
						// ngDialog.open({
						// 	template: '<div style="text-align:center">' + "Users added." + '</div>',
						// 	plain: true
						// });
					}
					$scope.getGroupUsersForGroup($scope.addToGroup);
				})
		}
	}

	$scope.expandedMenuRight = false;
	//	$scope.expandMenuRight = function()
	//	{
	//		$scope.clickedBtn = event.currentTarget.attributes['class'].value;
	//
	//		if($scope.clickedBtn.indexOf("teamSideBarPullButton") >= 0){
	//				$scope.showTeamSidebar =  true;
	//				$scope.expandedMenuRight = true;
	//				return;
	//		}
	//		if(!$scope.teamSidebarVisible){
	//			$scope.showTeamSidebar =  false;
	//			$scope.expandedMenuRight = false;
	//			return;
	//		}
	//		if($scope.expandedMenuRight && !$scope.showModuleSettings)
	//		{
	//			if($scope.showGroups) $scope.showGroupsClicked();
	//			makeTransition('[home-right-sidebar]', true);
	//	 		translate('[home-right-sidebar]',182);
	//
	//	 		makeTransition('.rightMenu', true);
	//	 		translate('.rightMenu',182);
	//
	//	 		makeTransition('.bv-transclude-content', true);
	//		 	translate('.bv-transclude-content',91);
	//		}
	//		else if(!$scope.expandedMenuRight && !$scope.showModuleSettings)
	//		{
	//			makeTransition('[home-right-sidebar]', true);
	//	 		translate('[home-right-sidebar]',-182);
	//
	//	 		makeTransition('.rightMenu', true);
	//	 		translate('.rightMenu',-182);
	//		}
	//
	//	}


	$scope.refreshTeam = function () {
		$scope.users = [];
		var alsoImages;
		$scope.getTeamUsers(alsoImages = true);
		$scope.tsGroups = [];
	}

	$scope.refreshUser = function () {
		$scope.users = [];
		var alsoImages;
		getUserImagesForCurrentUser();
		$scope.tsGroups = [];
	}


	///////////////////////////////// team end /////////////////////


	///////////////////////////// search start //////////////////////

	$scope.searchResults = [];
	$scope.searchString = "";
	$scope.searchLbl = localizedMessages.get("SEARCH");

	//	$scope.getSearchItemThumbnail = function (item) {
	//		if (item.thumbnailVO.imageSmall != undefined) {
	//			return "data:image/png;base64," + item.thumbnailVO.imageSmall;
	//		} else {
	//			return imageService.getImageByExtension(item.extensionType);
	//		}
	//	}
	$scope.searchClosed = true;

	$scope.validateSearchBox = {
		maxlength: 100,
		msg: ''
	};

	$scope.isSearchEnabled = homeModel.isSearchEnabled;
	homeModel.isSearchEnabled.val = context.getModel().companyVO.enableSearch;

	$scope.searchFocus = function (event) {
		if (event.currentTarget === document.activeElement && StringUtils.getBrowser() == 'MSIE') {
			event.currentTarget.parentNode.parentNode.style.borderLeft = '1px solid #A6A7AB';
			event.currentTarget.parentNode.parentNode.style.width = '95%';

			event.currentTarget.onblur = function () {
				event.currentTarget.parentNode.parentNode.style.borderLeft = '';
				event.currentTarget.parentNode.parentNode.style.width = '';
			}
		}
	}

	$scope.getSearchItems = function (e) {

		if (e === undefined || StringUtils.isEnterKey(e)) {

			if (legacyUserService.isNasdaqUI && $scope.isSearchEnabled.val) {

				if (adSearchQueryBoxService.searchString.val) {
					var adSearch = adSearchService.createInstance('ADVANCED').searchObject;
					if (!_.isEmpty(adSearch.filterPanelInstance) && $state.current.name != 'home.adsearch') {
						adSearch.filterPanelInstance.resetRange();
						adSearch.filterPanelInstance.resetTeamspaceSelection();
						adSearch.filterPanelInstance.resetDocumentsDetails();
					}

					if (!_.isEmpty(adSearch.filterPanelInstance) && 'keywordSelection' in adSearch.filterPanelInstance)
						adSearch.filterPanelInstance.addKeyword(adSearchQueryBoxService.searchString.val);

					if (!_.isEmpty(adSearch.filterPanelInstance))
						adSearch.filterPanelInstance.advancedSearchData.offset = 0;

					if ('close' in adSearchDialogService.dialog)
						adSearchDialogService.dialog.close();

					adSearchService.getAdvancedSearchResult(adSearch, adSearchQueryBoxService.searchString.val);
					$state.go('home.adsearch');
				}
			}
			else {
				if (MobileUtil.isApp() && $scope.onlineStatus.val == false) {
					return;
				}

				var searchString = '';

				if (!legacyUserService.isNasdaqUI) {
					searchString = $scope.searchString;
				}
				else {
					searchString = adSearchQueryBoxService.searchString.val;
				}

				if (searchString == "" || $scope.searchClosed == false) return;
				$scope.searchClosed = false;

				homeService.getAllSearchList(searchString).then(function (data) {

					$scope.searchStringIn = angular.copy(searchString);
					$scope.searchResults = angular.copy(data);

                    if($scope.searchResults.length>0)
                    {
						$scope.searchResultsEmpty = false;
					}
					else {
						$scope.searchResultsEmpty = true;
					}

					var searchModal = new modal.open({
						windowClass: 'searchResultsDialog',
						templateUrl: 'modules/home/search/searchResults.template.html',
						scope: $scope,
						controller: 'SearchResultsController',
					});

					searchModal.result.then(function (result) {
						$scope.searchClosed = true;
					}, function () {
						$scope.searchClosed = true;
					});

				});
			}
		}
	}

	///////////////////////////// search end //////////////////////

	$scope.companyLogo = homeService.getCompanyTeamspaceLogo(true, true);

	document.title = context.getModel().portalVO.name + ',' + context.getModel().companyVO.name;
	var allTabInactive = function () {
		for (var i in topSidebarMenu) {
			topSidebarMenu[i].active = false
		}
	}

	$scope.topSidebarMenuClick = function () {

		$scope.showModuleSettings = false;
		if (this.item.state === 'homeModules') {
			// this.$$nextSibling.item.active = false;
			// this.$$nextSibling.$$nextSibling.item.active = false;
			// $scope.sidebarMenu = sidebarMenuModules;
			$scope.modulesButtonClicked();
		}
		else if (this.item.state === 'homeUsers') {
			// this.$$prevSibling.item.active = false;
			// this.$$nextSibling.item.active = false;
			// $scope.sidebarMenu = sidebarMenuTeams;
			$scope.usersButtonClicked();
		}
		else if (this.item.state === 'homeGroups') {
			// this.$$prevSibling.item.active = false;
			// this.$$prevSibling.$$prevSibling.item.active = false;
			// $scope.sidebarMenu = sidebarMenuTeams;
			$scope.groupsButtonClicked();
		}
		allTabInactive();
		this.item.active = true;
		// document.querySelector('.bv-sidebar').style.width = SIDE_BAR_MENU_WIDTH;
	}

	//	 //Get currentUserVO from context
	var currentUserVO = context.getModel().userVO;
	$scope.userIdTitle = currentUserVO.name;
	$scope.currentUserBaseUuid = currentUserVO.objectBaseUuid;
	$scope.isPortalOwner = (context.getModel().isUserPortalAdmin == 'TRUE') ? true : false;
	$scope.isCompanyOwner = permissionService.isOwner(context.getModel().companyVO);
	$scope.isInviteUserEnabled = context.getModel().enableInviteUserFeature;

	//Set current user profile image
	(() => {
		teamService.getUserProfileImage(currentUserVO.objectBaseUuid, currentUserVO.objectVersionUuid).then(data => {
			if (data && data.profileImage && data.profileImage != '') {
				$scope.currentUserImage = "data:image/png;base64," + data.profileImage
			}
		})
	})()

	if ($scope.showUsersInSidebar) {
		if (!StringUtils.isPersonalTeamSpace(context.getModel().portalVO.objectBaseUuid)) {
			///////
			topSidebarMenu.push(
				{ 'name': !legacyUserService.isNasdaqUI ? localizedMessages.get('USERS') : localizedMessages.get('USERS_UPPERCASE'), 'state': 'homeUsers', 'active': false }
			);

			if ($scope.isCompanyOwner || $scope.isPortalOwner) {
				topSidebarMenu.push(
					{ 'name': !legacyUserService.isNasdaqUI ? localizedMessages.get('GROUPS') : localizedMessages.get('GROUPS_UPPERCASE'), 'state': 'homeGroups', 'active': false }
				);
			}
		}
	}

	$scope.portals = {};
	$scope.getPortalsForHTML = function () {
		homeService.getPortalsForHTML(currentUserVO.companyBaseUuid).then(function (data) {
			//console.log('getPortalsForHTML ',data);
			$scope.portals = data;
			var items = [];
			for (var i = 0; i < data.length; i++) {
				var temp = new Object();
				temp.name = data[i].name;
				temp.objectBaseUuid = data[i].objectBaseUuid;
				temp.isPrivate = data[i].isPrivate;
				items.push(temp);
				//items.push({name: data[i].name},{objectBaseUuid: data[i].objectBaseUuid} );
			}
			$scope.items = items;
			var portalVO = context.getModel().portalVO;
			$scope.teamSpace = portalVO.name;
			$scope.isLockedTeamSpace = portalVO.isPrivate;
			$scope.teamSpaceId = portalVO.objectBaseUuid;
			if (!legacyUserService.isNasdaqUI) {
				$scope.refreshTeam();
			} else {
				$scope.refreshUser();
			}

			homeModel.listofPortals = data;

		});
	}
	//method to fetch the linked companies
	$scope.getLinkedCompanies = function () {
		$scope.heightLC = 50;
		$scope.widthLC = 130;
		homeService.getLinkedCompanies().then(function (data) {
			var companyL = [];
			var companyList = data.data;
			for (var i = 0; i < companyList.length; i++) {
				var comp = {};
				comp.companyBaseUuid = companyList[i].companyBaseUuid;
				comp.companyLogo = "data:image/png;base64," + companyList[i].companyLogo;
				comp.companyLogoUrl = companyList[i].companyLogo;
				/*var defer = $q.defer();
				StringUtils.resizeImage(comp.companyLogo, 1.5, 100, defer);
				defer.promise.then(function (newLogo) {
					comp.companyLogo = newLogo;
				});*/
				comp.companyDisplayName = companyList[i].companyDisplayName;
				comp.loginID = companyList[i].loginID;
				companyL.push(comp);
			}
			$scope.linkedCompany = companyL;
			$scope.currentLinkedCompany = context.getModel().companyVO.objectBaseUuid;
		});
	}

	$scope.getPortalsForHTML();

	$scope.getLinkedCompanies();

	$scope.continueUserInvitationEdit = function () {
		bvDialog.inviteUsers();
	}

	$scope.inviteUserDialog = function () {
		if ((MobileUtil.isApp()) && MobileUtil.isJupOffline()) {
			return;
		}
		if (context.getModel().enableN3aSubmitSelfOrdering) {
			bvDialog.userCreateConfirm(localizedMessages.get('CONTINUE'), localizedMessages.get('CANCEL'), $scope.continueUserInvitationEdit, $scope.cancelEdit, localizedMessages.get('CREATE_USER'));
		} else {
			bvDialog.inviteUsers();
		}
	}
	$scope.continueUserCreationEdit = function () {
		var bvModal = new modal.open({
			windowClass: 'createUserDialog',
			templateUrl: 'modules/team/CreateUser.tpl.html',
			scope: $scope,
			controller: 'CreateUserController',
			backdrop: 'static',
			keyboard: false
		});
	}
	$scope.cancelEdit = function ($modalInstance) {
		$modalInstance.dismiss('cancel');
	};

	$scope.createUserDialog = function () {
		if ((MobileUtil.isApp()) && MobileUtil.isJupOffline()) {
			return;
		}

		if (context.getModel().enableN3aSubmitSelfOrdering) {
			bvDialog.userCreateConfirm(localizedMessages.get('CONTINUE'), localizedMessages.get('CANCEL'), $scope.continueUserCreationEdit, $scope.cancelEdit, localizedMessages.get('CREATE_USER'));
		} else {
			var bvModal = new modal.open({
				windowClass: 'createUserDialog',
				templateUrl: 'modules/team/CreateUser.tpl.html',
				scope: $scope,
				controller: 'CreateUserController',
				backdrop: 'static',
				keyboard: false
			});
		}
	}

	//	$scope.openAddGroupDialog = function()
	//	{
	//		if((MobileUtil.isApp()) && MobileUtil.isJupOffline())
	//		{
	//			return;
	//		}
	//		$scope.addGroupDialog = new modal.open({
	//            windowClass : 	'addGroupDialog',
	//            templateUrl:  	'modules/team/AddGroup.tpl.html',
	//            scope:      	$scope,
	//            controller: 	'AddGroupController',
	//		});
	//	}

	$scope.showMinimizeModules = false;

	$scope.minimizeModules = function () {
		$scope.showMinimizeModules = !$scope.showMinimizeModules;
	}

	$scope.logout = function () {
		$state.go('logout');
	}

	$scope.companyName = currentUserVO.companyName;
	//$scope.svgFill = "#515151";

	$scope.testPopUpClick = function () {
		$scope.showRightClick = false;
		//		 var url = "http://boardvantage.com";
		//		 var height = '500';
		//		 var width = '424';

		//		 ngDialog.open({
		// 				template: '<div style="margin: 18px 0px;height:' + height + 'px;width:' + width + 'px"><iframe style="height:100%;width:100%;" src="' + url + '"></iframe></div>',
		// 				plain: true
		// 		  });
	}

	var makeTransition = function (tag, boolean) {
		// if(boolean){
		// 	document.querySelector(tag) ? document.querySelector(tag).classList.add('bv-transition') : ''
		// }
		// else{
		// 	document.querySelector(tag) ? document.querySelector(tag).classList.remove('bv-transition') : ''
		// }
		var element = document.querySelector(tag);
		if (boolean) {
			if (element) {
				var eleClasses = element.className;
				if (eleClasses.indexOf("bv-transition") < 0) {
					element.className = element.className.toString() + ' bv-transition';
				}
			}
		}
		else {
			if (element) {
				element.className = element.className.toString().replace("bv-transition", " ");
			}
		}
	}
	var translate = function (tag, value) {
		if (document.querySelector(tag)) {
			document.querySelector(tag).style['-webkit-transform'] = value ? 'translateX(' + value + 'px)' : '';
			document.querySelector(tag).style['-moz-transform'] = value ? 'translateX(' + value + 'px)' : '';
			document.querySelector(tag).style['-ms-transform'] = value ? 'translateX(' + value + 'px)' : '';
			document.querySelector(tag).style['-o-transform'] = value ? 'translateX(' + value + 'px)' : '';
			document.querySelector(tag).style['transform'] = value ? 'translateX(' + value + 'px)' : '';
		}
	}

	var translateVertical = function (tag, value, zIndex) {
		if (document.querySelector(tag)) {
			document.querySelector(tag).style['-webkit-transform'] = value ? 'translateY(' + value + 'px)' : '';
			document.querySelector(tag).style['-moz-transform'] = value ? 'translateY(' + value + 'px)' : '';
			document.querySelector(tag).style['-ms-transform'] = value ? 'translateY(' + value + 'px)' : '';
			document.querySelector(tag).style['-o-transform'] = value ? 'translateY(' + value + 'px)' : '';
			document.querySelector(tag).style['transform'] = value ? 'translateY(' + value + 'px)' : '';
			document.querySelector(tag).style['z-index'] = zIndex;
		}
	}



	// document.querySelector('[home-sidebar]').addEventListener('transitionend', function( event ) {
	// 	if(event.target.hasAttribute('bv-home-sidebar')){
	// 		$scope.fireAfterTransitionChange(event);
	// 	}

	// 	if($state.current.name == 'home.calendar' || $state.current.name == 'home.meetings' || $state.current.name == 'home.newitems'){
	// 		$timeout(function(){
	// 			bvSliderService.fixScroll();
	// 			//var el = document.querySelector('.parent');
	// 			//smoothScrollH(el,(el.offsetWidth - 9) * 6, 500);
	// 		},0)
	// 	}
	// });

	$scope.flag = false;
	$scope.orientation = false;

	$scope.fireAfterTransitionChange = function (event) {
		if ($scope.clickedBtn && $scope.clickedBtn.indexOf("ModuleSettings") >= 0) {
			makeTransition('[home-sidebar]', false);
			translate('[home-sidebar]', '');

			makeTransition('.bv-module-setting-btn', false);
			translate('.bv-module-setting-btn', '');

			makeTransition('.bv-content', false);
			translate('.bv-content', '');

			makeTransition('.showModuleSettings', false);
			translate('.showModuleSettings', '');
			if ($scope.showModuleSettingsClass) {
				if (event.target.hasAttribute('home-sidebar')) {
					$scope.$apply(function () {
						$scope.showModuleSettingsClass = false;
						$scope.showModuleSettings = false;
					});
				}

			}
			else {
				if (event.target.hasAttribute('home-sidebar')) {
					$scope.$apply(function () {
						$scope.showModuleSettingsClass = true;
					});
				}
			}
		}

		if ($scope.showMinimizeModules || $scope.clickedBtn && $scope.clickedBtn.indexOf("expandMenu") >= 0 || $scope.orientation) {
			angular.element('[home-sidebar]')[0].style['overflow'] = '';

			makeTransition('.nav', false);
			translate('.nav', '');

			makeTransition('[home-sidebar]', false);
			translate('[home-sidebar]', '');

			makeTransition('.bv-menu', false);
			translate('.bv-menu', '');

			makeTransition('.rightMenu', false);
			translate('.rightMenu', '');

			makeTransition('.bv-content', false);
			translate('.bv-content', '');

			makeTransition('.bv-transclude-content', false);
			translate('.bv-transclude-content', '');

			//			 makeTransition('main',false);
			//			 translate('main','');

			//			 if($scope.moveTab)
			//			 {
			//				 makeTransition('.nav', false)
			//				 translate('.nav', '');
			//				 document.querySelector('.nav').style['left'] = '0px';
			//				 $scope.moveTab = false;
			//			 }
			if (homeService.homeContext.expandedMenu) {
				$scope.$apply(function () {
					if (!$scope.modulesMenuSettingsExpanded.value && homeService.homeContext.expandedMenu) {
						//						 $scope.showBadge.value = true + "";
						if (inboxItems.value > 0 || $scope.signaturesCount > 0 || $scope.approvalsCount || pushItems.value > 0 || $scope.surveysCount > 0 || $scope.newitemsCount > 0) {
							$scope.showBadge.value = true + "";
						}
						else {
							$scope.showBadge.value = false + "";
						}
						if (document.querySelector('.bv-topmenu'))
							document.querySelector('.bv-topmenu').style.display = 'none';
					}
					homeService.homeContext.expandedMenu = false;
				});
			}
			else {
				$scope.$apply(function () {
					homeService.homeContext.expandedMenu = true;
					if (!$scope.modulesMenuSettingsExpanded.value && homeService.homeContext.expandedMenu) {
						$scope.showBadge.value = false + "";
						if (document.querySelector('.bv-topmenu'))
							document.querySelector('.bv-topmenu').style.display = 'inline-block';
					}
				});
			}
		}
	}

	//	$scope.orientation = false;
	window.addEventListener('orientationchange', function (event) {
		$scope.clickedBtn = event.currentTarget.attributes['class'].value;
		switch (window.orientation) {
			case 0:
			case 180:
				expandAction();
				$scope.orientation = true;

				break;

			case -90:
			case 90:
				shrinkAction();
				$scope.orientation = true;

				break;
		}

		clearUserSelection();

	});

	$scope.homeContext = homeService.homeContext;
	//MobileUtil.isMobileClient() && !MobileUtil.isPhone() ? homeService.homeContext.expandedMenu = false : null;

	$scope.expandMenu = function () {

		//angular.element('[ui-view="content"]')[0].classList.remove('bv-animated');
		//var element = angular.element('[ui-view="content"]')[0];
		//element.className = element.className.toString().replace("bv-animated"," ");

		//makeTransition('.bv-animated-if', false);
		//makeTransition('.bv-transclude-content', false);
		if (!MobileUtil.isMobileClient()) {
			$scope.show = 'modules';
			allTabInactive();
			$scope.topSidebarMenu[0].active = true;
		}

		$scope.flag = false;

		if (angular.element('#expand-menu-bar-button')[0]) {
			$scope.clickedBtn = angular.element('#expand-menu-bar-button')[0].getAttribute("class");
		}
		//$scope.clickedBtn = angular.element('#expand-menu-bar-button')[0].classList.toString();


		if (homeService.homeContext.expandedMenu && !$scope.showModuleSettings) {
			$scope.showLogoWhenExtended = false;
			//expandAction();
		}
		else if (!homeService.homeContext.expandedMenu && !$scope.showModuleSettings) {
			// document.querySelector('.bv-scrollbar').style['top'] = '55px';
			//shrinkAction();
		} else if (homeService.homeContext.expandedMenu && $scope.showModuleSettings) {
			$scope.expandMenuForModuleSettings();
		}

		clearUserSelection();
		//homeService.homeContext.expandedMenu=!homeService.homeContext.expandedMenu;

		if (homeService.homeContext.expandedMenu) {
			if (!$scope.modulesMenuSettingsExpanded.value && homeService.homeContext.expandedMenu) {
				if (inboxItems.value > 0 || $scope.signaturesCount > 0 || $scope.approvalsCount || pushItems.value > 0 || $scope.surveysCount > 0) {
					$scope.showBadge.value = true + "";
				}
				else {
					$scope.showBadge.value = false + "";
				}
				if (!MobileUtil.isMobileClient()) {
					if (document.querySelector('.bv-topmenu'))
						document.querySelector('.bv-topmenu').style.display = 'none';
				}
			}
			homeService.homeContext.expandedMenu = false;
		}
		else {
			homeService.homeContext.expandedMenu = true;
			if (!$scope.modulesMenuSettingsExpanded.value && homeService.homeContext.expandedMenu) {
				$scope.showBadge.value = false + "";
				if (document.querySelector('.bv-topmenu'))
					document.querySelector('.bv-topmenu').style.display = 'inline-block';
			}
		}

		if($scope.currentState.value == 'home.dashboard'){
			if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
				var evt = document.createEvent('UIEvents');
				evt.initUIEvent('resize', true, false, window, 0);
				$timeout(function () {
				  window.dispatchEvent(evt);
				}, 500);
			   } else {
				$timeout(function () {
				  window.dispatchEvent(new Event('resize'));
				}, 500);

			   }
		}

		//fixSliderScroll();
		$timeout(function () {
			for (var i in bvSliderInstanceService.instances) {
				bvSliderService = bvSliderInstanceService.instances[i];
				bvSliderService.fixScroll();
			}
		});

		localStorage.setItem("expandMenu", homeService.homeContext.expandedMenu);
	}

	$timeout(() => {
		$scope.sidebarAnimation = true;
	})

	homeService.homeContext.expandedMenu = true;

	if (localStorage.getItem("expandMenu") != undefined && !MobileUtil.isPhone())
		homeService.homeContext.expandedMenu = localStorage.getItem("expandMenu") == "true"

	var fixSliderScroll = function () {
		if ($state.current.name == 'home.newitems') {
			var id;
			clearTimeout(id);
			id = setTimeout(function () {
				bvSliderService.fixScroll();
			}, 100);
		}
	}

	function expandAction() {
		makeTransition('[home-sidebar]', true);
		translate('[home-sidebar]', -282);

		makeTransition('.rightMenu', true);
		translate('.rightMenu', 282);

		//		 	makeTransition('.nav', true);
		//		 	translate('.nav',150);

		makeTransition('.bv-content', true);
		translate('.bv-content', -282);

		makeTransition('.bv-menu', true);
		translate('.bv-menu', 282);

		makeTransition('.bv-transclude-content', true);
		translate('.bv-transclude-content', 91);

		if (homeService.homeContext.expandedMenu) {
			$scope.moveTab = true;
			makeTransition('.nav', true)
			translate('.nav', 1);
		}
		else {
			makeTransition('.nav', true);
			translate('.nav', 150);
		}
	}

	function shrinkAction() {
		makeTransition('[home-sidebar]', true);
		translate('[home-sidebar]', 282);

		makeTransition('.bv-menu', true);
		translate('.bv-menu', -282);

		makeTransition('.rightMenu', true);
		translate('.rightMenu', -282);

		makeTransition('.bv-content', true);
		translate('.bv-content', 282);

		//			makeTransition('.nav', true);
		//			translate('.nav',-150);

		if (!$scope.screenExtended.value) {
			makeTransition('.nav', true);
			translate('.nav', -150);
		}
	}

	$scope.showModuleSettings = false;
	$scope.showModuleSettingsClass = false;

	$scope.currentDefault = homeContext.currentDefault;

	$scope.expandMenuForModuleSettings = function () {
		$scope.clickedBtn = 'ModuleSettings';
		$scope.showModuleSettings ? '' : $scope.showModuleSettings = true;

		if (!$scope.showModuleSettingsClass) {
			homeContext.currentDefault.landingModule = initialLandingModule;
			homeContext.currentDefault.jupLandingModule = initialJupLandingModule;
			//$scope.modulesForSetting = angular.copy(homeContext.modules);

			$scope.showModuleSettingsClass = true;
			$scope.show = 'settings';
			$scope.flag = true;
			if (document.querySelector('.bv-topmenu'))
				document.querySelector('.bv-topmenu').style.display = 'none';
			$scope.modulesMenuSettingsExpanded.value = true;
			$scope.showBadge.value = false + "";

			// makeTransition('[bv-home-sidebar]', true);
			// translate('[bv-home-sidebar]',430);

			// makeTransition('.bv-content', true);
			// translate('.bv-content',430);

			// makeTransition('.showModuleSettings', true);
			// translate('.showModuleSettings',-430);
		}
		else {
			$scope.showModuleSettingsClass = false;
			$scope.showModuleSettings = false;
			allTabInactive();
			$scope.topSidebarMenu[0].active = true;
			$scope.show = 'modules';
			$scope.flag = false;

			if (document.querySelector('.bv-topmenu'))
				document.querySelector('.bv-topmenu').style.display = 'inline-block';
			$scope.modulesMenuSettingsExpanded.value = false;

			// makeTransition('[bv-home-sidebar]', true);
			// 	translate('[bv-home-sidebar]',-430);

			// 	makeTransition('.bv-content', true);
			// 	translate('.bv-content',-430);

			// 	makeTransition('.showModuleSettings', true);
			// 	translate('.showModuleSettings',430);

			// 	makeTransition('.bv-menu', true);
			// 	translate('.bv-menu',-430);

		}
		//fixSliderScroll();

		$timeout(function () {
			//fixSliderScroll();
			bvSliderService.fixScroll();
		}, 100);
	}

	$scope.isPortalAdminUser = false;
	$scope.showAdminLink = false;

	var isUserPortalAdmin = context.getModel().isUserPortalAdmin;
	// console.log("company owner :" , permissionService.isOwner(context.getModel().companyVO));
	// console.log("portal owner :" , isUserPortalAdmin);

	// don't show for personal teamspace in any case

	if (!StringUtils.isPersonalTeamSpace(context.getModel().portalVO.objectBaseUuid) &&
		(permissionService.isOwner(context.getModel().companyVO) ||
			(isUserPortalAdmin != null && isUserPortalAdmin != undefined && isUserPortalAdmin == "TRUE"))
	) {
		$scope.isPortalAdminUser = true;
	}

	if ($scope.isPortalAdminUser && !(MobileUtil.isApp())) {
		$scope.showAdminLink = true
	}

	var defaultLandingModule = context.getModel().html5LandingModule;
	if (MobileUtil.isMobileClient())
		defaultLandingModule = context.getModel().jupHtml5LandingModule;

	var defaultJupLandingModule = context.getModel().jupHtml5LandingModule;

	//var defaultLandingModule = "PAGES";
	//alert("Home controller-> MODULE:"+defaultLandingModule+" ISADMIN:"+isUserPortalAdmin);

	if (defaultLandingModule == "INBOX") {
		$scope.inMobileInbox = true;
	}
	if (defaultLandingModule == "APPROVALS") {
		$scope.inMobileApprovals = true;
	}
	if (defaultLandingModule == "SURVEYS") {
		$scope.inMobileSurveys = true;
	}
	//$scope.initialModules = [];
	//$scope.initialModules = homeContext.modules;
	var initialModuleConfig = "";
	var initialLandingModule = defaultLandingModule;
	var initialJupLandingModule = defaultJupLandingModule;
	var availableModules = "" + context.getModel().html5Modules;

	$scope.UI_VISIBLE_TO = localizedMessages.get('VISIBLE_TO');
	$scope.UI_ALL = localizedMessages.get('ALL');
	$scope.UI_APP = localizedMessages.get('APP');
	$scope.UI_ADMINS_ONLY = localizedMessages.get('ADMINS_ONLY');
	$scope.UI_JUP_TXT = localizedMessages.get('MOBILE');
	$scope.UI_LAND_ON = localizedMessages.get('LAND_ON');
	$scope.UI_BROWSER = localizedMessages.get('BROWSER');
	$scope.UI_OK = localizedMessages.get('OK');
	$scope.UI_SAVE = localizedMessages.get('SAVE');
	$scope.UI_RESET = localizedMessages.get('RESET');
	$scope.UI_CANCEL = localizedMessages.get('CANCEL');
	$scope.UI_CREATE = localizedMessages.get('CREATE');
	$scope.UI_INVITE = localizedMessages.get('INVITE');
	$scope.UI_FILTER = localizedMessages.get('FILTER');
	$scope.UI_MODULES_SETTINGS = localizedMessages.get('MODULES_SETTINGS');
	$scope.MATERIALS = localizedMessages.get('MATERIALS_UPPERCASE');

	$scope.MODULES_FOR_JUP_SYNC = "";

	//alert("AVAILABLE MODULES:"+availableModules);
	if (availableModules != null && availableModules != undefined) {
		var html5Modules = availableModules.split(",");

		//TODO: Remove it later after IOS changing.
		if (legacyUserService.isNasdaqUI && html5Modules.includes('CALENDAR#0_1'))
			html5Modules.splice(html5Modules.indexOf('CALENDAR#0_1'), 1);

		//var sidebarMenuModules = [];
		homeContext.modules.sidebarMenuModules = []
		var sidebarMenuModulesForAdmin = [];
		var moduleName = "";
		var moduleState = "";
		var bvCamelCaseName = "";
		var jupState = "";
		var moduleRouterState = "";
		var moduleIconUrl = "";
		var moduleVisibility = "";
		var isAdmin = $scope.isPortalAdminUser;

		for (var k = 0; k < html5Modules.length; k++) {
			var moduleDetails = html5Modules[k];
			moduleName = ""; moduleState = ""; bvCamelCaseName = "";
			if (moduleDetails != null && moduleDetails != "") {
				var moduleAttributes = moduleDetails.split("#");
				if (moduleAttributes != null && moduleAttributes.length == 2) {
					moduleName = moduleAttributes[0];
					var moduleStates = moduleAttributes[1].split("_");
					//moduleState = moduleAttributes[1];
					moduleState = moduleStates[0];

					jupState = (moduleStates.length > 1 && moduleStates[1] != undefined && moduleStates[1] == "1") ? true : false;

					if (moduleName != null && moduleState != undefined) {
						moduleRouterState = "";
						moduleIconUrl = "";
						moduleRouterState = "home." + moduleName.toLowerCase().replace(" ", "");
						moduleIconUrl = "resources/assets/svgs/" + moduleName.toLowerCase().replace(" ", "") + ".svg";

						if (!legacyUserService.isNasdaqUI) {
							if (moduleName == 'SURVEYS' || moduleName == 'NEW ITEMS' || moduleName == 'SIGNATURES') {
								moduleIconUrl = moduleIconUrl.replace('.svg', '_old.svg');
							}
						}

						if (HTML5_SUPPORTED_MODULE.indexOf(moduleName) >= 0) {
							//console.log("moduleName:",moduleName);
							// Refer (ANDJUP-562)i
							//							if(!((MobileUtil.isAndroid() || MobileUtil.isSurface()) && (moduleName === "APPROVALS" || moduleName === "SURVEYS")))
							//							{
							bvCamelCaseName = localizedMessages.get(moduleName.toUpperCase().replace(" ", ""));// toBvCamelCase(moduleName);
							var nameUpperCase = localizedMessages.get(moduleName.toUpperCase().replace(" ", "") + '_UPPERCASE');
							var fontIconName = moduleName.replace(" ", "");
							switch (moduleState) {
								case "0":
									sidebarMenuModulesForAdmin.push(
										{
											name: "" + moduleName,
											camelCaseName: "" + bvCamelCaseName,
											nameUpperCase: nameUpperCase,
											isVisibleForAdmin: false,
											isVisibleForAll: true,
											isVisibleForJup: jupState,
											iconUrl: "" + moduleIconUrl,
											state: "" + moduleRouterState,
											fontIconName: fontIconName
										}
									);
									homeContext.modules.sidebarMenuModules.push(
										{
											name: "" + moduleName,
											camelCaseName: "" + bvCamelCaseName,
											nameUpperCase: nameUpperCase,
											isVisible: true,
											state: "" + moduleRouterState,
											iconUrl: "" + moduleIconUrl,
											fontIconName: fontIconName
										}
									);

									if (HTML5_JUP_SYNC_SUPPORTED_MODULES.indexOf(moduleName) >= 0) {
										$scope.MODULES_FOR_JUP_SYNC = $scope.MODULES_FOR_JUP_SYNC + "#" + moduleName;
									}
									break;
								case "1":
									sidebarMenuModulesForAdmin.push(
										{
											name: "" + moduleName,
											camelCaseName: "" + bvCamelCaseName,
											nameUpperCase: nameUpperCase,
											isVisibleForAdmin: true,
											isVisibleForAll: false,
											isVisibleForJup: jupState,
											iconUrl: "" + moduleIconUrl,
											state: "" + moduleRouterState,
											fontIconName: fontIconName
										}
									);

									if (isAdmin) {
										homeContext.modules.sidebarMenuModules.push(
											{
												name: "" + moduleName,
												camelCaseName: "" + bvCamelCaseName,
												nameUpperCase: nameUpperCase,
												isVisible: true,
												state: "" + moduleRouterState,
												iconUrl: "" + moduleIconUrl,
												fontIconName: fontIconName
											}
										);

										if (HTML5_JUP_SYNC_SUPPORTED_MODULES.indexOf(moduleName) >= 0) {
											$scope.MODULES_FOR_JUP_SYNC = $scope.MODULES_FOR_JUP_SYNC + "#" + moduleName;
										}
									}
									break;
								case "2":
									sidebarMenuModulesForAdmin.push(
										{
											name: "" + moduleName,
											camelCaseName: "" + bvCamelCaseName,
											nameUpperCase: nameUpperCase,
											isVisibleForAdmin: false,
											isVisibleForAll: false,
											isVisibleForJup: jupState,
											iconUrl: "" + moduleIconUrl,
											state: "" + moduleRouterState,
											fontIconName: fontIconName
										}
									);

									break;
							}


							homeService.homeContext.modules = homeContext.modules;
							//							}
						}
					}
				}
			}
		}


		var countItems = {};
		countItems.value = 0;
		$scope.countItems = countItems;
		var chatItems = {};
		chatItems.value = 0;
		$scope.chatItems = chatItems;
		var inboxItems = {};
		inboxItems.value = 0;
		$scope.inboxItems = inboxItems;
		var pushItems = {};
		pushItems.value = 0;
		$scope.pushItems = pushItems;
		var meetingItems = {};
		meetingItems.value = 0;
		$scope.meetingItems = meetingItems;
		var newitemsBadge = {};
		newitemsBadge.value = 0;
		$scope.newitemsBadge = newitemsBadge;
		var briefcaseItems = {};
		briefcaseItems.value = 0;
		$scope.briefcaseItems = briefcaseItems;
		var currentState = {};
		var setCurrentState = function (state) {
			currentState.value = state;
			$scope.currentState = currentState;
		};
		setCurrentState($state.$current.name);

        $scope.$watch(
            function () {
                return homeService.getEmailUnreadCountValue();
            },
            function () {
                $scope.emailUnreadCount = homeService.getEmailUnreadCountValue();
            }
        );

        homeService.getEmailUnreadCountFromApi();
        //homeService.startEmailUnreadCountInterval();

		var getOpenItemsCount = function () {
			homeService.getOpenItemsCount().then(function (data) {
				$scope.countItems.value = data.data;
				//				document.getElementById('openItemsBadge').style.display = "";
				//				homeContext.modules.sidebarMenuModules.push({name:"OPEN ITEMS", isVisible:true, state:"home.dashboard", iconUrl: "resources/assets/svgs/openitems.svg", count: $scope.countItems });
				for (var i = 0; i < homeContext.modules.sidebarMenuModules.length; i++) {
					if (homeContext.modules.sidebarMenuModules[i].state == "home.dashboard") {
						homeContext.modules.sidebarMenuModules[i].count = data.data;
					}
				}

			})
		};

		$scope.getNewMessagesCount = function () {
			homeService.getMyUnreadMessageCount().then(function (data) {
				$scope.chatItems.value = data;
			})
		};

		$scope.getInboxUnreadCount = function () {
			homeService.getInboxUnreadCount().then(function (data) {
				$scope.inboxItems.value = data;
				// if($scope.inboxItems.value > 0 && !homeService.homeContext.expandedMenu)
				// {
				// 	$scope.showBadge.value = true + "";
				// } else {
				// 	$scope.showBadge.value = false + "";
				// }
			})
		}
		$scope.getSentToMePushCount = function () {
			homeService.getSentToMePushCount().then(function (data) {
				$scope.pushItems.value = data;
			});
		}
		//		homeContext.modules.sidebarMenuModules.push({name:"OPEN ITEMS", isVisible:true, state:"home.openitems", iconUrl: "resources/assets/svgs/openitems.svg"});
		getOpenItemsCount();
		$scope.getNewMessagesCount();
		$scope.getInboxUnreadCount();

		if (context.getModel().pushEnabled) {
			$scope.getSentToMePushCount();
		}

		$scope.signaturesCount = 0;
		$scope.getSignaturesCount = function () {
			homeService.getDocumentsForSignaturesCount()
				.then(function (data) {
					$scope.signaturesCount = data;
				});
		}
		$scope.approvalsCount = 0;
		$scope.getApprovalsCount = function () {
			homeService.getApprovalsCount()
				.then(function (data) {
					$scope.approvalsCount = data;
				});
		}

		$scope.surveysCount = 0;
		$scope.getSurveysCount = function () {
			homeService.getSurveysCount()
				.then(function (data) {
					$scope.surveysCount = data;
				});
		}

		$scope.newitemsBadge.value = 0;
		$scope.getNewItemsCountForAllTeamspaces = function () {
			homeService.getNewItemsCountForAllTeamspaces()
				.then(function (data) {
					$scope.newitemsBadge.value = data;
				});
		}

		$scope.briefcaseItems.value = 0;
		$scope.getBriefcaseCount = function () {
			homeService.getBriefcaseCount()
				.then(function (data) {
					$scope.briefcaseItems.value = data;
				});
		}

		$scope.meetingItems.value = 0;
		$scope.getMeetingsCount = function () {
			homeService.getMeetingsCount()
				.then(function (data) {
					$scope.meetingItems.value = data;
				});
		}

		$scope.getSignaturesCount();
		$scope.getApprovalsCount();
		$scope.getSurveysCount();
		$scope.getNewItemsCountForAllTeamspaces();
		$scope.getBriefcaseCount();
		$scope.getMeetingsCount();
		
		//changes for MEETX-11798
		var getAllLightWeightNewItems =function(){
			var niService=repositoryService.getNewItemService();
	    	niService.loadLightWeightNewItemsJson()
	    	.then(function(response)
	    			{
	    				if(response.data.boName == "FailureBO") { return; }
	    				var newItemModel = repositoryModelService.getInstance("NEW_ITEM");
	    				newItemModel.repositoryList(response.data);
	    				newItemModel.newItemsLoadedFull(true);
	    			})
		}
		
		var lightWeightNewItemflag=true;
		if(currentState.value==="home.newitems")
		{  
			var newItemModel = repositoryModelService.getInstance("NEW_ITEM");
		    newItemModel.newItemsLoadedFull(false);
			lightWeightNewItemflag=false;
		}
		else if(currentState.value==="home.documents"){
			lightWeightNewItemflag=false;
		}
		
		if(lightWeightNewItemflag){	
			if(!MobileUtil.isJupOffline()){
			 getAllLightWeightNewItems();
			}
			}
		//console.log(homeContext.modules.sidebarMenuModulesForAdmin);

		//		$scope.countItems = homeService.getOpenItemsCount();
		//		homeContext.modules.sidebarMenuModules.push({name:"OPEN ITEMS", isVisible:true, state:"home.dashboard", iconUrl: "resources/assets/svgs/openitems.svg", count: $scope.countItems });
		//		homeContext.modules.sidebarMenuModules.push({name:"SECURE CHAT", isVisible:true, state:"home.securechat", iconUrl: "resources/assets/svgs/wall.svg" });
		//homeContext.modules.sidebarMenuModules.push({name:"CALENDAR", isVisible:true, state:"home.calendar", iconUrl: "resources/assets/svgs/calendar.svg"});
		//homeContext.modules.sidebarMenuModules.push({name:"PAGES", isVisible:true, state:"home.pages", iconUrl: "resources/assets/svgs/calendar.svg"});
		//homeContext.modules.sidebarMenuModules.push({name:"SECURE CHAT", isVisible:true, state:"home.securechat", iconUrl: "resources/assets/svgs/wall.svg" });
		$scope.sidebarMenu = homeContext.modules;
		if (isAdmin) {
			//$scope.initialModules = angular.copy(sidebarMenuModulesForAdmin);
			homeContext.modules.sidebarMenuModulesForAdmin = angular.copy(sidebarMenuModulesForAdmin);
			//homeContext.modulesApply = $scope.$apply;
			initialModuleConfig = angular.toJson($scope.initialModules);
			homeContext.currentDefault.landingModule = defaultLandingModule;
			homeContext.currentDefault.jupLandingModule = defaultJupLandingModule;
			$scope.modulesForSetting = angular.copy(sidebarMenuModulesForAdmin);
			//console.log("user is admin...Pushing modules,",$scope.modulesForSetting);
		}
	}
	else {
		bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SEETINGS_NO_MODULES_AVAILABLE'));
		//var alertMsg = localizedMessages.get('ERR_MODULE_SEETINGS_NO_MODULES_AVAILABLE');
		//bvDialogService.showBvAlertMessage(alertMsg);
	}

	//	var showAlertForBroswerLandingModule = function()
	//	{
	//		bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_DEFAULT_LANDING_MODULE_NOT_VISIBLE_FOR_ALL'));
	//	}

	//	var showAlertForAppLandingModule = function()
	//	{
	//		bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_DEFAULT_JUP_LANDING_MODULE_NOT_VISIBLE_FOR_ALL'));
	//	}

	$scope.changeModuleVisibility = function (item, itemName, flag) {
		//console.log("ITEM:",item," ITEM-NAME:",itemName," ITEM.IS-VISIBLE-FOR-ALL",item.isVisibleForAll," ITEM-IS-VISIBLE-FOR-ADMIN",item.isVisibleForAdmin);
		if (itemName == "isVisibleForAdmin") {
			if (homeContext.currentDefault.landingModule == item.name) {
				//showAlertForBroswerLandingModule();
			}
			else if (homeContext.currentDefault.jupLandingModule == item.name) {
				//showAlertForAppLandingModule();
			}
			else {
				item.isVisibleForAdmin = flag;
				if (flag && item.isVisibleForAll)
					item.isVisibleForAll = false;
			}
		}
		else if (itemName == "isVisibleForAll") {
			item.isVisibleForAll = flag;
			if (flag && item.isVisibleForAdmin)
				item.isVisibleForAdmin = false;
		}
	}

	$scope.changeLandingModuleForBroswer = function (item) {
		var showErr = false;
		for (var i = 0; i < $scope.modulesForSetting.length; i++) {
			var moduleConfig = $scope.modulesForSetting[i];
			if (moduleConfig.isVisibleForAdmin && (moduleConfig.name == item.name)) {
				showErr = true;
				break;
			}
		}
		if (showErr) {
			showAlertForBroswerLandingModule();
		}
		else {
			homeContext.currentDefault.landingModule = item.name;
		}
	}

	$scope.changeLandingModuleForApp = function (item) {
		var showErr = false;
		for (var i = 0; i < $scope.modulesForSetting.length; i++) {
			var moduleConfig = $scope.modulesForSetting[i];
			if (moduleConfig.isVisibleForAdmin && (moduleConfig.name == item.name)) {
				showErr = true;
				break;
			}
		}
		if (showErr) {
			showAlertForAppLandingModule();
		}
		else {
			homeContext.currentDefault.jupLandingModule = item.name;
		}
	}

	//	var isModuleSettingsChanged = function()
	//	{
	//		var currentModuleConfig = angular.toJson($scope.modulesForSetting);
	//
	//		var changeFlag = (initialModuleConfig!="" && initialModuleConfig!=currentModuleConfig) ? true : false;
	//		if(!changeFlag)
	//		{
	//			changeFlag = (initialLandingModule!="" && initialLandingModule!=homeContext.currentDefault.landingModule) ? true : false;
	//		}
	//		if(!changeFlag)
	//		{
	//			changeFlag = (initialJupLandingModule!="" && initialJupLandingModule!=homeContext.currentDefault.jupLandingModule) ? true : false;
	//		}
	//
	//		return changeFlag;
	//	}
	//	$scope.saveModuleChanges = function()
	//	{
	//		var changeFlag = isModuleSettingsChanged();
	//
	//		if(changeFlag)
	//		{
	//			var moduleMapping = "";
	//			var defaultLandingModule = homeCntext.currentDefault.landingModule;
	//			var defaultJupLandingModule = homeContext.currentDefault.jupLandingModule;
	//
	//			var jupState = "";
	//			var defaultLandingModuleShouldBeVisibleToAll = false;
	//			var defaultJupLandingModuleShouldBeVisibleToAll = false;
	//			for(var i=0; i < $scope.modulesForSetting.length; i++)
	//			{
	//				var moduleConfig = $scope.modulesForSetting[i];
	//				jupState = (moduleConfig.isVisibleForJup) ? "1" : "0";
	//				if(!moduleConfig.isVisibleForAll && !moduleConfig.isVisibleForAdmin)
	//				{
	//					moduleMapping = moduleMapping + moduleConfig.name + "#2_"+jupState+",";
	//				}
	//				else if (moduleConfig.isVisibleForAdmin)
	//				{
	//					moduleMapping = moduleMapping + moduleConfig.name + "#1_"+jupState+",";
	//				}
	//
	//				else if (moduleConfig.isVisibleForAll)
	//				{
	//					if(defaultLandingModule == moduleConfig.name)
	//					{
	//						defaultLandingModuleShouldBeVisibleToAll = true;
	//					}
	//					if(defaultJupLandingModule == moduleConfig.name)
	//					{
	//						defaultJupLandingModuleShouldBeVisibleToAll = true;
	//					}
	//					moduleMapping = moduleMapping + moduleConfig.name + "#0_"+jupState+",";
	//				}
	//			}
	//
	//			if(!defaultLandingModuleShouldBeVisibleToAll)
	//			{
	//				showAlertForBroswerLandingModule();
	//			}
	//			else if(!defaultJupLandingModuleShouldBeVisibleToAll)
	//			{
	//				showAlertForAppLandingModule();
	//			}
	//			else
	//			{
	//				moduleMapping = moduleMapping.substring(0, moduleMapping.length-1);
	//				homeService.setModuleSettingsForPortal(moduleMapping,defaultLandingModule,defaultJupLandingModule).then(function(result)
	//				{
	//					if(result.data == true)
	//					{
	//						/*$scope.initialModules = angular.copy($scope.modulesForSetting);
	//						initialModuleConfig = angular.toJson($scope.initialModules);
	//						alert("initial modules"+initialModuleConfig)
	//						$scope.currentDefault.landingModule = { name : '' +defaultLandingModule };*/
	//						//Refresh the current page here..
	//						//var alertMsg = "Changes saved successfully.";
	//
	//						var alertMsg = localizedMessages.get('ERR_MODULE_SETTINGS_CHANGES_SAVED_SUCCESSFULLY');
	//						bvDialogService.showBvAlertMessage(alertMsg).then(function(result)
	//				        {
	//				        	window.location.reload();
	//				        });
	//					}
	//					else
	//					{
	//						bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_ERROR_IN_SAVING_MODULE_SETTINGS'));
	//						//var alertMsg = localizedMessages.get('ERR_MODULE_SETTINGS_ERROR_IN_SAVING_MODULE_SETTINGS');
	//						//bvDialogService.showBvAlertMessage(alertMsg);
	//						//alert("Error in saving module settings. Details: "+result.data);
	//					}
	//				});
	//			}
	//			//$state.go("home."+defaultLandingModule.toLowerCase());
	//		}
	//		else
	//		{
	//			bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_NO_CHANGES_MADE_TO_MODULE_SETTING'));
	//			//alert("No changes made to module settings.")
	//		}
	//	};

	//	$scope.cancelModuleChangesConfirm = function()
	//	{
	//		if(isModuleSettingsChanged())
	//			bvDialog.confirm(localizedMessages.get("MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED"), $scope.cancelModuleChanges)
	//		else
	//			$scope.cancelModuleChanges();
	//	}

	//	$scope.cancelModuleChanges = function()
	//	{
	//		homeContext.currentDefault.landingModule = initialLandingModule;
	//		homeContext.currentDefault.jupLandingModule = initialJupLandingModule;
	//		$scope.modulesForSetting = angular.copy($scope.initialModules);
	//		$scope.expandMenuForModuleSettings();
	//	};

	$scope.hexToRgb = function (hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	$scope.onChangeTeamSpaceConfirm = function (choice, event) {
		if (homeService.get_current_module_has_unsaved_changes()) {
			event.preventDefault();
			bvDialog.confirm(confirmUnsavedChangesMessage,
				function () {
					homeService.set_current_module_has_unsaved_changes(false, ctrlName);
					$scope.onChangeTeamSpace(choice);
				}
			);
		} else {
			$scope.onChangeTeamSpace(choice);
		}
	}

	$scope.onChangeTeamSpace = function (choice) {
		homeService.switchTeamSpace(choice);
	};

	$scope.onLinkedCompanyChange = function (comp, event) {
		homeService.changeCompanyForNBV(comp.companyBaseUuid, comp.loginID).then(function (data) {
			var loginVO = data;
			if (loginVO && loginVO.errorMsg != '') {
				bvDialog.showMessage(loginVO.errorMsg);
				return;
			}
			var defaultLandingModule = context.getModel().html5LandingModule.toLowerCase().replace(" ", "");
			var defaultJupLandingModule = context.getModel().jupHtml5LandingModule.toLowerCase().replace(" ", "");

			$rootScope.SELECTED_PORTAL_BASEUUID = '';
			context.clearContext();
			companyUserService.clearAll();
			const ctx = loadContext.loadContext();
			if (ctx && angular.isFunction(ctx.then)) {
				ctx.then(function (context) {
					if (!context || !context.isReady())
						$state.go('login.auth');
					else
						$state.go('home.' + (MobileUtil.isApp() ? defaultJupLandingModule : defaultLandingModule), null, { reload: true });
				});
			}
		});
	}

	$scope.status = {
		isopen: false
	};

	$scope.profileMenu = {
		isopen: false
	};

	$scope.linkedCompanyStatus = {
		isopen: false
	};

	$scope.linkedCompanyStatusMobile = {
		isopen: false
	};

	$scope.toggleProfileMenu = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		$scope.profileMenu.isopen = !$scope.profileMenu.isopen;
	};

	$scope.toggleDropdown = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		$scope.status.isopen = !$scope.status.isopen;
	};

	$scope.toggleLinkedCompanyDropdown = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();

		if (!MobileUtil.isMobileClient())
			$scope.linkedCompanyStatus.isopen = !$scope.linkedCompanyStatus.isopen;
		else
			$scope.linkedCompanyStatusMobile.isopen = !$scope.linkedCompanyStatusMobile.isopen;
	};

	$scope.toggleDropdownShowTeamSpace = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		$scope.showTeamSpace = !$scope.showTeamSpace;
	};

	$scope.toggleDropdownShowMobileMenu = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		$scope.mobileMenuFlag.showMenu = !$scope.mobileMenuFlag.showMenu;
	};

	if (context.getModel() != null) {
		$scope.pinSetUpLabel = context.getModel().enableTouchIdLogin == true ? localizedMessages.get('TOUCH_ID_SETUP') : localizedMessages.get('PIN_SETUP');
		$scope.isPinSetUpEnabled = homeService.ipadDetection() && (context.getModel().enableTouchIdLogin || context.getModel().enableIpadEasyLoginPin);
		$scope.isIOSZoomEnabled = homeService.ipadDetection();
	}

	$scope.openSiteBranding = function () {
		bvDialogService.showBvModal({
			windowClass: 'brandingDialog',
			controller: 'brandingController',
			bodyTemplate: templatesService.getUrl('Branding'),
		});
	};

	$scope.goToLandingModule = function () {
		var moduleToGo = defaultLandingModule.toLowerCase();
		moduleToGo = moduleToGo.replace(" ", "");

		if (moduleToGo == "INBOX")
			$scope.inMobileInbox = true;

		if (moduleToGo == "APPROVALS")
			$scope.inMobileApprovals = true;

		if (moduleToGo == "SURVEYS")
			$scope.inMobileSurveys = true;

		$state.go("home." + moduleToGo);
	}
	if (defaultLandingModule != null && defaultLandingModule != undefined && HTML5_SUPPORTED_MODULE.indexOf(defaultLandingModule) >= 0) {
		//console.log("VAL1:",MobileUtil.isMobileClient()," VAL2:",MobileUtil.isDesktopClient(), " VAL3:",MobileUtil.isJupOffline());
		if ((MobileUtil.isApp()) && MobileUtil.isJupOffline()) {

			if ("#MEETINGS#CALENDAR#BRIEFCASE#NEW ITEMS".indexOf(defaultLandingModule) >= 0) {
				$timeout(function () {
					$scope.goToLandingModule();
				}, 500);
			}
			else {
				var stateVal = "";
				for (var i = 0; i < homeContext.modules.sidebarMenuModules.length; i++) {
					var stateName = homeContext.modules.sidebarMenuModules[i].name;
					if ("#MEETINGS#CALENDAR#BRIEFCASE#NEWITEMS".indexOf(stateName) >= 0) {
						stateVal = homeContext.modules.sidebarMenuModules[i].state;
						break;
					}
				}
				//console.log("FIRST AVAILABEL STATE:",stateVal);
				if (stateVal != "") {
					//console.log("----------DONT GO TO ANY STATE");
					$state.go(stateVal);
				}
			}
		}
		else if (!StringUtils.isNullEmpty(homeModel.plink) && (homeModel.plink == "taskmanagement" || homeModel.plink == "email")) {
			$state.go("home." + homeModel.plink);
		}
		else if (!StringUtils.isNullEmpty(homeModel.plink) && MobileUtil.isSurface()) {
			plinkHandler.checkForPermalinkHandler("");
		}
		else if (!StringUtils.isNullEmpty(homeModel.resourceId)) {
			plinkHandler.checkForResourcelinkHandler(defaultLandingModule);
		}
		else {
			//console.log("IN HOME MODEL....PLINK:"+homeModel.plink+ " RESOURCE-ID:"+homeModel.resourceId);
			if (StringUtils.isNullEmpty(homeModel.plink) && StringUtils.isNullEmpty(homeModel.resourceId)) {
				//				$timeout(function(){
				//					$scope.goToLandingModule();
				//				});
			}
			else {
				if (!StringUtils.isNullEmpty(homeModel.plink))
					plinkHandler.checkForPermalinkHandler(defaultLandingModule);
				else if (!StringUtils.isNullEmpty(homeModel.resourceId)) {
					plinkHandler.checkForResourcelinkHandler(defaultLandingModule);
				}
			}
		}
	}
	else {
		alert("Default landing mdoule is not available");
	}

	$scope.show = 'modules';
	$scope.modulesButtonClicked = function () {
		$scope.show = 'modules';
	}
	$scope.usersButtonClicked = function () {
		$scope.showGroups = false;
		$scope.show = 'users';
		$scope.refreshTeam();
	}
	$scope.groupsButtonClicked = function () {
		$scope.show = 'users';
		$scope.showGroups = true;
		if ($scope.tsGroups == undefined || $scope.tsGroups.length == 0) {
			$scope.getAllGroupsOfPortal();
		}
	}

	$scope.showConfirmation = function () {
		setTimeout(function () {
			bvDialog.showMessage(localizedMessages.get('SIGNATURE_COMMIT_CONFIRM'), null, true);
		}, 1000);
	}

	$scope.setupUserSignature = function () {
		$scope.isLoggedInUserSignatureSet = false;
		$scope.selectedUser = context.getModel().userVO;
		$scope.signatureCaller = "repository";

		var bvModal = new modal.open({
			windowClass: 'editSignatureDialog',
			templateUrl: 'modules/team/CreateSignature.tpl.html',
			scope: $scope,
			controller: 'CreateSignatureController',
		});

		bvModal.result.then(function (resultData) {
			$scope.isLoggedInUserSignatureSet = true;
		}, function () {
			$scope.isLoggedInUserSignatureSet = true;
		});
	}

	$scope.setupUserSignatureForMobile = function () {
		if (!MobileUtil.isPhone()) {
			$scope.setupUserSignature();
			return;
		}

		$scope.isLoggedInUserSignatureSet = false;
		$scope.selectedUser = context.getModel().userVO;
		$scope.signatureCaller = "repository";

		var bvModal = new modal.open({
			windowClass: 'editSignatureDialog',
			templateUrl: 'modules/team/CreateSignatureMobile.tpl.html',
			scope: $scope,
			controller: 'CreateSignatureController',
		});

		bvModal.result.then(function (resultData) {
			$scope.isLoggedInUserSignatureSet = true;
		}, function () {
			$scope.isLoggedInUserSignatureSet = true;
		});
	}

	$scope.openNewViewer = function (documentBaseUuid) {
		repositoryService.openPdfViewer(documentBaseUuid);
	}

	$scope.addToActiveDocumentList = function (selectedDocument, doRefresh) {
		if ($scope.activeDocumentList == null || $scope.activeDocumentList == undefined) {
			$scope.activeDocumentList = [];
		}

		if (MobileUtil.isDesktopClient()) {
			$scope.activeDocumentList.push(selectedDocument);
		}
		else {
			var newActiveDoc = new Object;
			newActiveDoc.objectBaseUuid = selectedDocument.objectBaseUuid
			newActiveDoc.name = selectedDocument.name;

			$scope.activeDocumentList.push(newActiveDoc);
		}

		if ($scope.activeDocumentList.length > 1) {
			$scope.activeDocumentName = localizedMessages.get("ACTIVE_WINDOWS");
			document.querySelector('.bv-active-windows').style.display = 'inline-block';
		}
		else if ($scope.activeDocumentList.length == 1) {
			$scope.activeDocumentName = selectedDocument.name;
			document.querySelector('.bv-active-windows').style.display = 'inline-block';
		}
		if (doRefresh) {
			$scope.$apply();
		}
	}

	$scope.reloadDocumentHandler = function () {
		if ($scope.activeDocumentList.length > 1) {
			bvDialog.openActiveWindows();
		}
		else {
			if (MobileUtil.isDesktopClient()) {
				repositoryService.openPdfViewer($scope.activeDocumentList[0]);
			}
			else {
				var docId = $scope.activeDocumentList[0].objectBaseUuid;
				repositoryService.openPdfViewer(docId);
			}
			document.querySelector('.bv-active-windows').style.display = 'none';
			$scope.activeDocumentName = "";
			$scope.activeDocumentList = [];
		}
	}

	$scope.closeActiveWindows = function () {
		if ($scope.activeDocumentList.length > 1) {
			bvDialog.confirm(localizedMessages.get('DO_YOU_WANT_TO_CLOSE_ALL_WINDOWS'), function () {
				document.querySelector('.bv-active-windows').style.display = 'none';
				$scope.activeDocumentName = "";
				$scope.activeDocumentList = [];
			});
		}
		else {
			document.querySelector('.bv-active-windows').style.display = 'none';
			$scope.activeDocumentName = "";
			$scope.activeDocumentList = [];
		}
	}

	$scope.updateActiveDocumentList = function (activeList) {
		$scope.activeDocumentList = activeList;
		if ($scope.activeDocumentList.length === 1) {
			$scope.activeDocumentName = $scope.activeDocumentList[0].name;
		}
		else if ($scope.activeDocumentList.length === 0) {
			$scope.closeActiveWindows();
		}
	}

	$scope.iconGroup = 'resources/assets/svgs/groups.svg';
	$scope.iconUser = 'resources/assets/svgs/users.svg';
	$scope.iconModules = 'resources/assets/svgs/modules.svg';
	$scope.iconModuleSettings = 'resources/assets/svgs/moduleSettingsIcon.svg';

	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';

	$scope.closeIcon = templatesService.getUrl('closeBtn');
	$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';

	var defaultModuleOrientation = context.getModel().defaultModuleOrientation;
	if (defaultModuleOrientation === "Collapsed") {
		$scope.show = 'modules';
		allTabInactive();
		if (inboxItems.value > 0) {
			$scope.showBadge.value = true + "";
		}
		else {
			$scope.showBadge.value = false + "";
		}
		$scope.topSidebarMenu[0].active = true;
		$scope.flag = false;
		//MobileUtil.isMobileClient() && !MobileUtil.isPhone() ? homeService.homeContext.expandedMenu = false : null;
		//$scope.TOP_MENU_DISPLAY = "none";
		//shrinkAction();
		clearUserSelection();
	}
	else {
		$scope.TOP_MENU_DISPLAY = "inline-block";
	}
	$scope.OpenN3AAdmin = function () {
		bvDialogService.openNewWindow($scope.n3aAdminUrl, "N3aAdmin");
	};
	$scope.openAdminAuthentication = function () {
		if (MobileUtil.isApp() && MobileUtil.isJupOffline()) {
			return;
		}
		homeService.isAdminAllowed().then(function (data) {
			if (data == true) {
                if ($scope.enableMeetXAdminApp) {
			        bvDialogService.openNewWindow($scope.onPremAdminUrl, $scope.onpremAdminTabName);
			    }
			    else {
                    var w = 900;
                    var h = 650;
                    var left = (window.innerWidth / 2) - (w / 2);
                    var top = (window.innerHeight / 2) - (h / 2);
                    if (StringUtils.isNullEmpty(serverURL)) {
                        bvDialogService.openNewWindow("/portlet/login.jsp?openFlexModule=showAdmin", "_blank", "toolbar=yes, resizable=no, width=" + w + ", height=" + h + ",left=" + left + ",top=" + top);
                    } else {
                        bvDialogService.openNewWindow(serverURL + "/portlet/login.jsp?openFlexModule=showAdmin", "_blank", "toolbar=yes, resizable=no, width=" + w + ", height=" + h + ",left=" + left + ",top=" + top);
                    }
                }
            }
			else {
				bvDialog.showAdminAuthentication();
			}
		});
	}

	//console.log("JUP STAUS IN HOME CONTEXT: ",MobileUtil.isJupOffline())
	homeService.homeContext.jupOffline = MobileUtil.isJupOffline();

	$scope.startManualSync = function () {
		if (!MobileUtil.isJupOffline()) {
			if (!SYNC_STARTED) {
				var progress = document.querySelector('.progress');
				var dynamic = document.querySelector('.progress .progress-bar');
				var label = document.querySelector('.progress .progress-bar span');
				var jupSyncIconDiv = document.querySelector('.jup-sync-icon-div');
				if (progress != null && progress != undefined) {
					progress.style['display'] = 'block';
					label.innerHTML = '2%';
					dynamic.style['width'] = '2%';
				}
				reSync();
			}
		}
	}

	$scope.startSyncForJup = function () {
		if (MobileUtil.isApp()) {
			homeService.homeContext.isApp = true;
			if (!isOnlineCheckServiceForJup.isStarted()) {
				isOnlineCheckServiceForJup.startStatusCheck();
			}
		}

		if (MobileUtil.isApp()) {
			if (homeContext.startSync == false && MobileUtil.isIOS()) {
				homeContext.startSync = true;
			}

			console.log("homeContext.startSync:", homeContext.startSync);
			if (homeContext.startSync) {
				var isPushEnabled = context.getModel().pushEnabled;

				if (isPushEnabled) {
					$scope.MODULES_FOR_JUP_SYNC = "HOME";
				}
				//console.log("SETTING JUP LCIENT:",true);
				setTimeout(function () {
					var startDate = StringUtils.momentL().subtract(context.getModel().htmlCalendarRangeBackward, 'month').set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format('MMM D, YYYY hh:mm:ss A');
					var endDate = StringUtils.momentL().add(context.getModel().htmlCalendarRangeForward, 'month').set('date', 0).set('hour', 23).set('minute', 59).set('second', 59).format('MMM D, YYYY hh:mm:ss A');
					//This should be hash separated String of all portal ids for the user
					MobileUtil.jupBriefcaseSyncProgress(startDate, endDate, $scope.MODULES_FOR_JUP_SYNC);
				}, 2000)
				homeContext.startSync = false;
			}
			else {
				MobileUtil.showLastSyncedOn("");
			}
		}
	}

	homeContext.startSync = true;
	$scope.startSyncForJup();

	$scope.go_offline_icon = 'resources/assets/svgs/go_offline_icon.svg';
	$scope.connected_icon = 'resources/assets/svgs/connected_icon.svg';
	$scope.not_available_icon = 'resources/assets/svgs/not_available_icon.svg';

	$scope.jup = {
		label: {
			connected: localizedMessages.get('CONNECTED'),
			not_available: localizedMessages.get('NOT_AVAILABLE'),
			go_offline: localizedMessages.get('GO_OFFLINE'),
			last_sync: localizedMessages.get('LAST_SYNC')
		}
	}
	for (var m in $scope.sidebarMenu.sidebarMenuModules) {
		var menu = $scope.sidebarMenu.sidebarMenuModules[m];
		if (menu.state == $scope.currentState.value) {
			$scope.testClick(menu);
		}
	}
	$scope.mainMenu = $scope.sidebarMenu.sidebarMenuModules;
	$scope.isDocuments = $scope.mainMenu.some(el => el.state === "home.documents");
	$scope.isNewItems = $scope.mainMenu.some(el => el.state === "home.newitems");
	$scope.isBreifCase = $scope.mainMenu.some(el => el.state === "home.briefcase");
    var showMaterial = function() {
	if ($scope.isNewItems) {
	  $scope.materialState = 'home.newitems';	  
	}
	else if (!$scope.isNewItems && $scope.isBreifCase) {
		$scope.materialState = 'home.briefcase';
	  }
	else if (!$scope.isNewItems && !$scope.isBreifCase && $scope.isDocuments) {
		$scope.materialState = 'home.documents';
	  }
	};
    showMaterial();
	$scope.briefcaseMobileMenu = homeModel.briefcaseMobileMenu;
	$scope.meetingMobileMenu = homeModel.meetingMobileMenu;

	$scope.inboxMobileMenu = [
		{ name: localizedMessages.get('MARK_ALL_READ'), click: function () { markAllReadConfirm() }, value: "markAllRead", hideForOffline: true },
		{ name: localizedMessages.get('COMPOSE'), click: function () { bvDialogMobile.meobileComposeMessage('compose') }, value: "compose", disableForOffline: true }
	];

	$scope.approvalsMobileMenu = [
		{ name: localizedMessages.get('SORT_BY_NAME'), click: function () { selectApprovalsMenuItem('name') }, value: "name" },
		{ name: localizedMessages.get('SORT_BY_DUE_DATE'), click: function () { selectApprovalsMenuItem('dueDateLong') }, value: "dueDateLong" },
		{ name: localizedMessages.get('SORT_BY_PROGRESS'), click: function () { selectApprovalsMenuItem('progress') }, value: "progress" }
	];

	var markAllReadConfirm = function () {
		var messageController = angular.element(document.querySelector('[messages-controller]')).scope();
		if (messageController)
			messageController.markAllReadConfirm();
	}

	var selectApprovalsMenuItem = function (sort) {
		var approvalsListController = angular.element('.approvalsListDIV').scope();
		if (approvalsListController)
			approvalsListController.selectMenuItem({ value: sort });
	}

	$scope.surveysMobileMenu = [
		{ name: localizedMessages.get('SORT_BY_NAME'), click: function () { selectSurveysMenuItem('name') }, value: "name" },
		{ name: localizedMessages.get('SORT_BY_DUE_DATE'), click: function () { selectSurveysMenuItem('dueDateLong') }, value: "dueDateLong" },
		{ name: localizedMessages.get('SORT_BY_PROGRESS'), click: function () { selectSurveysMenuItem('progress') }, value: "progress" }
	];
	$scope.showCurrentMeetingCard = function () {
		var listViewController = angular.element('.bv-meeting-cards').scope();
		if (listViewController)
			listViewController.showCurrentMeeting();
	}
	var selectSurveysMenuItem = function (sort) {
		var surveyssListController = angular.element('.surveysListDIV').scope();
		if (surveyssListController)
			surveyssListController.selectMenuItem({ value: sort });
	}
	//	if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
	//		homeService.getOnlineStatus();
	//	}

	$scope.onlineStatus = homeContext.onlineStatus;

	$scope.setMobileLastSyncStatus = function (lastedSyncedOnVal) {
		$scope.lastSync = lastedSyncedOnVal;
	}

	/*$scope.lastSync = homeContext.lastSync;

	homeContext.lastSync = function(){
		homeService.getLastSync().then(function(data){
			if(data && data.data){
				$scope.lastSync = data.data.jupSyncLastSyncedOn;
			}
			else{
				$scope.lastSync = localizedMessages.get('SYNC_ERROR');
			}
		});
	}*/

	function reSync() {
		setTimeout(function () {
			var startDate = StringUtils.momentL().subtract(context.getModel().htmlCalendarRangeBackward, 'month').set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format('MMM D, YYYY hh:mm:ss A');
			var endDate = StringUtils.momentL().add(context.getModel().htmlCalendarRangeForward, 'month').set('date', 0).set('hour', 23).set('minute', 59).set('second', 59).format('MMM D, YYYY hh:mm:ss A');
			//This should be hash separated String of all portal ids for the user
			MobileUtil.jupBriefcaseSyncProgress(startDate, endDate, $scope.MODULES_FOR_JUP_SYNC);
		}, 2000)
	}

	function showBiometricEnrollmentDialog() {
		MobileUtil.showBiometricEnrollmentDialog();
	}

	function showManagePinDialog(){
        MobileUtil.showManagePinDialog();
	}

	var searchInMobileDialog = function () {
		if (!$scope.onlineStatus.val) {
			bvDialog.showMessage(localizedMessages.get('MSG_THIS_FEATURE_IS_ONLY_AVAILABLE_ONLINE'));
			return;
		}
		bvDialogMobile.searchMobileDialog();
	}

	$scope.mobileMenu = [
		{ title: localizedMessages.get('SYNC'), name: 'sync', click: reSync },
		{ title: localizedMessages.get('SEARCH'), name: 'search', click: searchInMobileDialog },
		{ title: localizedMessages.get('BIOMETRIC_ENROLLMENT'), name: 'biometric_enrollment', click: showBiometricEnrollmentDialog },
		{title: localizedMessages.get('MANAGE_PIN'), name: 'managePin', click: showManagePinDialog},
		// { title: localizedMessages.get('RECENT'), name: 'recent', click: function(){ }},
		{ title: localizedMessages.get('SUPPORT'), name: 'support', click: function () { bvDialog.support() } },
		{ title: localizedMessages.get('LOG_OFF'), name: 'log_off', click: function () { $state.go('logout') } }
	]

	let isMFAEnabled = context.getModel().companyVO.n3aMfaConfigUrl && context.getModel().companyVO.n3aMfaConfigUrl !== "";
	let secondTierEnabled = context.getModel().companyVO.secondTierEnabled;
	let showSettingsMenu = isMFAEnabled || secondTierEnabled;

	if (showSettingsMenu && !MobileUtil.isJupOffline()) {
		$scope.mobileMenu.splice(2, 0, { title: localizedMessages.get('SETTINGS'), name: 'settings', click: function () { bvDialog.settingsMobileDialog() } });
	}

	//	$scope.goOfflineFill = "#bfbfbf";
	//	$scope.connectedIconFill = "#007fff";
	//	$scope.notAvailableIconFill = "#bfbfbf";
	//
	//	$scope.mobileGoOffline = function(){
	//		//TODO : go offline call
	//		if($scope.goOfflineFill == "#bfbfbf"){
	//			$scope.goOfflineFill = "#007fff";
	//			$scope.connectedIconFill = "#bfbfbf";
	//			$scope.notAvailableIconFill = "#007fff";
	//		}else{
	//			$scope.goOfflineFill = "#bfbfbf";
	//			$scope.connectedIconFill = "#007fff";
	//			$scope.notAvailableIconFill = "#bfbfbf";
	//		}
	//	}

	//file upload
	$scope.onFileSelect = function ($files) {
		var uploadFileObjects = {};
		uploadFileObjects.listofUploadFiles = [];
		for (var i = 0; i < $files.length; i++) {
			var file = $files[i];
			uploadFileObjects.listofUploadFiles.push(file);
		}

		if ($files.length > 0) {
			uploadFileObjects.action = "upload";
			$scope.currentInstance = "DOCUMENTS";
			var treeSelectionModel = treeService.getInstance($scope.currentInstance);
			uploadFileObjects.parentId = treeSelectionModel.getSelectedNode().objectBaseUuid;
			bvDialogService.uploadFile(uploadFileObjects);
		}
	};

	companyUserService.initCompanyTree();
	$scope.MobileUtil = MobileUtil;

	if (!(MobileUtil.isApp() || MobileUtil.isMobileClient())) {
		homeService.logUserAgent();
	}


}
