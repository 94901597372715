function searchIndexingService(bvDialogService, ajaxService, responseValidatorService, bvDialog){

    this.showSearchIndexing = function() {
        bvDialogService.showBvModal({
            windowClass : 'search-indexing',
            controller : 'searchIndexingController',
            bodyTemplate : 'modules/dialogs/searchindexing/searchindexing.tpl.html',
//            inputs : {
//                selectedUser: selectedUser,
//                followUnFollow: followUnFollow
//            },
            buttons : null
        });
    }

    this.searchAddItemsToIndex = function(){
        var request = ajaxService.searchAddItemsToIndex();
        return request.then(function(data) {
            if (responseValidatorService.isSuccess(data.data)) {
                return data.data;
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }

    this.searchRebuildIndex = function(){
        var request = ajaxService.searchRebuildIndex();
        return request.then(function(data) {
            if (responseValidatorService.isSuccess(data.data)) {
                return data.data;
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }

    this.searchDeleteFullTextSearchIndex = function(){
        var request = ajaxService.searchDeleteFullTextSearchIndex();
        return request.then(function(data) {
            if (responseValidatorService.isSuccess(data.data)) {
                return data.data;
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }

    this.searchEnableFullTextSearch = function(){
        var request = ajaxService.searchEnableFullTextSearch();
        return request.then(function(data) {
            if (responseValidatorService.isSuccess(data.data)) {
                return data.data;
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }

    this.searchDisableFullTextSearch = function(){
        var request = ajaxService.searchDisableFullTextSearch();
        return request.then(function(data) {
            if (responseValidatorService.isSuccess(data.data)) {
                return data.data;
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }

    this.searchGetSearchIndexReport = function(){
        var request = ajaxService.searchGetSearchIndexReport();
        return request.then(function(data) {
            if (responseValidatorService.isSuccess(data.data)) {
                return data.data;
            }
            else{
                bvDialog.showMessage(data.data.message);
            }
            return null;
        });
    }
}