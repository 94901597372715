angular
    .module('dialogControllers.service')
   	.controller('createAgendaController', createAgendaController);

createAgendaController.$inject = ['$scope', 'bvDialogService', 'localizedMessages'];

function createAgendaController($scope, bvDialogService, localizedMessages){
	
	$scope.popupTitle 	= localizedMessages.get('CREATE_AGENDA');
	$scope.actionBtnTxt = localizedMessages.get('OK');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.addFolderPlaceholder = localizedMessages.get('CREATE_AGENDA_PLACEHOLDER');
	
	var actionTrue = $scope.bvModalControllerInputs.actionTrue;
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.addFolderName = {}
	
	$scope.action = function() {
		var folderName = $scope.addFolderName.val;
		bvModal.close();
		actionTrue(folderName);
	};
	
}