commonService.$inject = ['$q', 'ajaxService', 'responseValidatorService'];

function commonService($q, ajaxService, responseValidatorService)
{
	var service = {

		getUsers: function() {
			var request = ajaxService.getUsers(true, true, true);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},

		getGroupUsers : function(groupId){
			var request = ajaxService.getGroupChildren(groupId);
			return request.then(function(data){
				return data;
			});
			return null;
		},

		getContacts: function() {
			var request = ajaxService.getUsers(true, false, false);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},

		getDocumentThumbnail: function(objectBaseUuid, objectVersionUuid) {
			var request = ajaxService.getDocumentThumbnail(objectBaseUuid, objectVersionUuid);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},

		cancelVEdit : function(connectionId){
			var request = ajaxService.cancelVEdit(connectionId);
			return request.then(function(response){
				if(response) {
					return response.data;
				}
				return null;
			});
		},

		getSupportInfo: function() {
			var request = ajaxService.getSupportInfo();
			return request.then(function(data) {
				if (data) {
					return data.data;
				}
				return null;
			});
		},

		getModuleCreatorsForSpecificModuleJSON : function(id){
			var request=ajaxService.getModuleCreatorsForSpecificModuleJSON(id);
			return request.then(function(data)
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},

		checkModuleCreatorsConfigPresentJSON : function(id){
			var request=ajaxService.checkModuleCreatorsConfigPresentJSON(id);
			return request.then(function(data)
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		}

	}
	return service;
}