function highlightUsersFactory() {
	var replaceAll = function (input, search, replacement) {
		return input.split(search).join(replacement);
	};

	var parseUserName = function (input) {
		var result = replaceAll(input, '\[\@', '');
		return replaceAll(result, '\@\]', '');
	};
	var highlightPattern = /(?:\[\@)([^\[\@\]]+)(?:\@\])/g;

	var getHighlightedUsers = function (input) {
		if (!input) {
			return null;
		}
		return input.match(highlightPattern);
	};

	return {
		parseUserName: parseUserName,
		getHighlightedUsers: getHighlightedUsers
	}
}