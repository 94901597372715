droppable.$inject = ['$parse', 'dragDropService', 'permissionService'];

function droppable($parse, dragDropService, permissionService) {
    return {
        link: function(scope, element, attrs) {
            // again we need the native object
            var el = element[0];

            el.addEventListener(
            'dragover',
            function(e)
            {

            	var uuid = attrs.id;
            	var bo =  scope[attrs.selectedItem];

            	if (e.preventDefault) e.preventDefault();


            	var itemsToBeMoved = e.dataTransfer.getData('Text');

            	if(!StringUtils.isNullEmpty(attrs.handleDragOver))
        		{
            		var dragParentObject = dragDropService.dragInitiator;

            		if(dragParentObject && dragParentObject.className.indexOf("treeNode") > -1)
        			{
            			e.dataTransfer.dropEffect = 'none';
            			return false;
        			}
        		}

            	if(uuid && uuid != 'repositoryId')
            	{
	            	if(uuid.indexOf('0CODF') != -1){
				        e.dataTransfer.dropEffect = 'copy';
	            	}
				    else{
				    	e.dataTransfer.dropEffect = 'none';
				    }
            	}

                if(uuid != 'repositoryId')
                	this.classList.add('dragOverBackground');               

                return true;
            },
            false
           );

            el.addEventListener(
			    'dragenter',
			    function(e) {

			    	var bo =  scope[attrs.selectedItem];
			    	var uuid = attrs.id;
			    	angular.element('.documentsList').addClass('dragDropEvent');
			    	if(uuid != 'repositoryId')
			    		this.classList.add('dragOverBackground');

			       // console.log("THIS ",this);
			    	
			        return false;
			    },
			    false
			);

			el.addEventListener(
			    'dragleave',
			    function(e) {
			        this.classList.remove('dragOverBackground');
			        return false;
			    },
			    false
			);

			el.addEventListener(
			    'drop',
			    function(e)
			    {
			    	  this.classList.remove('dragOverBackground');
			          // Stops some browsers from redirecting.
				      if (e.stopPropagation) e.stopPropagation();


				      var dropTarget = this;
			          var itemsToBeMoved = e.dataTransfer.getData('Text');

			          var fn =  $parse(scope[attrs.dropHandler]);
			          var fileFn =  $parse(scope[attrs.filesDropHandler]);

			          if(e.dataTransfer.files.length > 0 && (fileFn && (typeof fileFn === 'function')))
			          {
			        	  fileFn(dropTarget, e.dataTransfer.files);
			          }
			          else if (fn && (typeof fn === 'function'))
				      {
				    	  fn(dropTarget, itemsToBeMoved);
				      }
				      else
				      {
		                  e.target.appendChild(document.getElementById(data));
				      }

			          //e.currentTarget.style.background = "none";
			          angular.element('.documentsList').removeClass('dragDropEvent');
			          $('.documentsList').removeClass('dragDropEventInner');
				      e.stopPropagation(); // Stops some browsers from redirecting.
				      e.preventDefault();

			          return false;
		        },
        false
      );			
    }
   }
}
		function dragStart(event) {
				$('.documentsList').addClass('dragDropEventInner');				
			}      
	
		function dragOutFromItem(){
			$('.documentsList').removeClass('dragDropEvent');
		}