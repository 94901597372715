listviewController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'calendarService', 'commonService', 'presentationService', 'localizedMessages', 'ipadService', '$modal', '$templateCache', 'context','modelService','bvDialogService','permissionsService', 'bvSliderInstanceService', '$timeout', 'bvDialog', 'bvDialogMobile', 'homeService', 'toppanelService', 'reactInterface', 'teamService', 'homeModel'];

function listviewController($scope, $rootScope, $state, $stateParams, calendarService, commonService, presentationService, localizedMessages, ipadService, $modal, $templateCache, context, modelService, bvDialogService, permissionsService, bvSliderInstanceService, $timeout, bvDialog, bvDialogMobile, homeService, toppanelService, reactInterface, teamService, homeModel){

	var currentState = $state.$current.name;
	$scope.$parent.currentState.value = currentState;
	//$scope.localTimeZone = moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset());
	$scope.localTimeZone= function(meetingStartDate){
				var numbersOnlyRegex = /^[+-]?\d+$/;
                var tz = moment(meetingStartDate).tz(moment.tz.guess(true)).format('z');
				if(numbersOnlyRegex.test(tz)){
					switch(moment.tz.guess(true)) {
					  case "Asia/Singapore":
					    return 'SGT';
					    break;
					  case "Pacific/Fiji":
						return moment(meetingStartDate).tz(moment.tz.guess(true)).format('UTCZ')+' Fiji';
					    break;
					  default:
						return moment(meetingStartDate).tz(moment.tz.guess(true)).format('UTCZ');
					}
				}else{
					return tz
				}
};
	var bvSliderService;

	var sliderRenderCallback = function(){
		bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptionsCards.id];
		var lastStateName = calendarService.getLastState();
		if(lastStateName === "CONF_EVENT")
		{

		}
		else{
			bvSliderService.currentIndex = 0;
		}
		//bvSliderService.currentIndex = 0;
	}
	$scope.reactInterface = reactInterface;

	$scope.sliderOptionsCards = {
			id: 'MEETING_CARDS',
			items : [],
			shownItems: 3,
			slideThrough: 1,
			renderCallback : sliderRenderCallback
	}

	$scope.scrollListOptions = {
			columns : [
                { title : '' , width : 100, name : 'name' }
			],
			list : calendarService.getAllCalendarEvents()
	    }


	$scope.titleAgenda = localizedMessages.get('AGENDA');

	$scope.tileCheckBox = { title : localizedMessages.get("PUBLISH")  , show : false , active : false };

	var cancelCreateNewMeetingEvent = function()
	{
		$scope.handleLeftIcon();
	}

	var createNewMeetingEvent = function()
	{
		var meetingController = angular.element(document.querySelector('[bvMeetingEditViewDiv]')).scope();
		meetingController.save();
	}

	$scope.meetingSaveCallback = function(meetingObjectBaseUuid)
	{
		$scope.showNewMeetingDialog = false;
		$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
		$scope.meetingObj.editFlag	= false;
		$scope.initCalendar(    );
		//$state.go($rootScope.SELECTED_CALENDAR_STATE_NAME);
	}

	$scope.toggleActive = function()
   	{
   		$scope.meetingObj.isActive = !$scope.meetingObj.isActive

   		$scope.tileCheckBox.active = $scope.meetingObj.isActive;
   	}

	$scope.handleLeftIcon = function()
	{
		$scope.tabs = getTabs();
		$scope.showNewMeetingDialog = false;
		$scope.middleBarObject.title = localizedMessages.get('MEETINGS');
		window.setTimeout(function(){
            bvSliderService.fixScroll();
		}, 100);
		$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
	}

	$scope.clickEvent = function(dt, eventObj,eventsForDay)
	{
		var events = [];
		if(eventsForDay!=null && eventsForDay!=undefined)
		{
			for (var i = 0; i < eventsForDay.length; i++)
			{
			    if(eventsForDay[i].data)
					events.push(eventsForDay[i].data);
			}
		}
		else
		{
			events.push(eventObj.data);
		}
		var SELECTED_EVENT = null;
		var dateValue = StringUtils.momentL(dt).format('ll');
		if(eventObj!=null && eventObj!=undefined && eventObj.data!=undefined)
		{
			SELECTED_EVENT = eventObj.data.objectBaseUuid;
		}
		var EVENTS_LIST = { "DATE":dateValue, "EVENTS":events, "SELECTED_EVENT":SELECTED_EVENT};
		$rootScope.EVENTS_LIST = EVENTS_LIST;
		$state.go('home.meetingDetails');
    }

 	$scope.titleDateFormat = 'MMM D, YYYY';
    $scope.clickEventTimeline = function(eventObj)
    {
        var uniq_date = moment(parseInt(eventObj.startDateGMTMillis)).format($scope.titleDateFormat);
        var EVENTS_LIST = {
                                DATE:uniq_date,
                                EVENTS: $scope.events.eventsMap.get(uniq_date),
                                SELECTED_EVENT : eventObj.objectBaseUuid
                            };
        $rootScope.EVENTS_LIST = EVENTS_LIST;
        $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('YEARLY');
        $state.go('home.meetingDetails');
    }

	$scope.getFormatedTime = function(startDateGMTMillis,endDateGMTMillis)
    {	
		var dateHelper = new DateHelper(context.getModel().userVO);

		return dateHelper.getFormattedRangeTime(startDateGMTMillis, endDateGMTMillis, moment.tz.guess(true))
	}

	$scope.clickDateInMeetingCard = function(uniq_date)
    {
		var EVENTS_LIST = {
								DATE:uniq_date,
								EVENTS: $scope.events.eventsMap.get(uniq_date),
								SELECTED_EVENT :$scope.events.eventsMap.get(uniq_date)[0].objectBaseUuid
							};
		$rootScope.EVENTS_LIST = EVENTS_LIST;
        $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('DAILY');
        $state.go('home.meetingDetails');
	}
	$scope.currentMeetingId;
	
	$scope.clickEditMeeting = function(event, selectedUuid){
		event.stopPropagation();
		var eventName = "editEvent";
		$state.go('home.meetingCreation', {
			objectBaseUuid: selectedUuid,
			operation: eventName
		 })
	}
	$scope.clickEventInMeetingCard = function(uniq_date,selectedUuid,startDate,endDate)
    {
	
      if(MobileUtil.isPhone() || MobileUtil.isIPhone()){
        bvDialogMobile.mobileMeetingDetails($scope.events.eventsMap.get(uniq_date),selectedUuid);
      }
      else{
	  if($scope.currentMeetingId != selectedUuid)
	  {
	  $scope.currentMeetingId = selectedUuid;
	  bvDialog.showMeetingDetailsReact(selectedUuid,startDate,endDate,uniq_date);
				    $timeout(function(){
				    	$('.meeting-details-react:not(:last)').remove();
			    	}, 250);
	  }else{
	  				$('.meeting-details-react').remove();
	  				$scope.currentMeetingId = '';
  			}

       
        
        
			// calendarService.tabs.forEach(tab => tab.active = false);
			// $scope.reactInterface.clickedEventFromMeetX = {
			// 	date: uniq_date,
			// 	eventBaseUuid: selectedUuid,
			// 	clickedDay: uniq_date
			// }
			// calendarService.tabs[1].active = true;

			// var EVENTS_LIST = {
			// 		DATE:uniq_date,
			// 		EVENTS: $scope.events.eventsMap.get(uniq_date),
			// 		SELECTED_EVENT :selectedUuid
			// };
			// $rootScope.EVENTS_LIST = EVENTS_LIST;
	        // $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('DAILY');
	        // $state.go('home.meetingDetails');
	  }
	}
	$(document).click(function(e){
	    // Check if click was triggered on or within #menu_content
	    if( !$(e.target).parents(".nasdaq .bv-scroll-list > .scroll-list-items .scroll-list > li").length > 0 && !$(e.target).parents(".meeting-details-react .modal-dialog").length > 0  && !$(e.target).parents(".events-dialog .modal-dialog .bv-modal-body>div>ul.left-dialog li").length > 0 && !$(e.target).parents("month-calendar").length > 0) {
	    	$('.meeting-details-react').remove();
			$scope.currentMeetingId = '';
	    }else{
	    	
	    	}
		
	    
	});
//	$scope.getLocalizedDate = function(date) {
//		return localizedMessages.getLocaleDate(date);
//	}

	var goToCurrentCard = function()
	{
		var magCurrent = calendarService.getLastState();
		var totalDates = $scope.events.uniq_eventDates.length - 1 ;

		if(magCurrent === "CONF_EVENT")
		{
			calendarService.setLastState("");
		}
		else
		{
			for(var i=0; i<= totalDates;i++)
			{
				//console.log("$scope.events.uniq_eventDates[i]",$scope.events.uniq_eventDates[i], " EVENT_DAY:",$scope.events.EVENT_DAY_VALUE)
				if($scope.events.uniq_eventDates[i] == $scope.events.EVENT_DAY_VALUE)
				{
					if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
						bvSliderService.currentIndex = i;
					}
					else{
						if(i >= (totalDates - $scope.sliderOptionsCards.shownItems))
						{
							bvSliderService.currentIndex = i - 1;
							//$scope.sliderOptionsCards.shownItems = 2;
						}
						else
						{
							bvSliderService.currentIndex = ((i==1) ? i : (i-1));
							//bvSliderService.currentIndex = i;
							//bvSliderService.currentIndex = i + 1;
						}
					}
					break;
				}
			}
		}
		$scope.sliderOptionsCards.items = $scope.events.uniq_eventDates;
		$scope.noMeetings = $scope.sliderOptionsCards.items.length == 0 ? true : false;
		setTimeout(function(){
			bvSliderService.fixScroll();
			bvSliderService.fixButtons();

		},0);
		$scope.msg_no_meetings = localizedMessages.get('MSG_NO_MEETINGS');
		$scope.current = localizedMessages.get("CURRENT");
		$scope.isMobileClient = MobileUtil.isMobileClient();
		//$timeout(function(){bvSliderService.fixScroll()}, 100);
	}

	$scope.showCurrentMeeting = function(){
		goToCurrentCard();
	}

	$scope.initCalendar = function()
	{
//		$scope.middleBarObject.title = localizedMessages.get('MEETINGS');
//		$scope.middleBarObject.showBack = false;
		$scope.showNewMeetingDialog = false;
		$scope.showMeetingPortalName = $rootScope.showMeetingPortalName;
		var selectedMeetingOption = (StringUtils.isNullEmpty(homeModel.selectedMeetingOption) || homeModel.selectedMeetingOption == "ALL")  ? "ALL" : "CURRENT";
		var currentState = $state.$current.name;
		$rootScope.SELECTED_CALENDAR_STATE_NAME = currentState;
		$rootScope.SELECTED_CALENDAR_MODULE_TITLE = StringUtils.capitaliseFirstLetter(currentState.split("home.")[1]);
		//$scope.middleBarObject.title = $rootScope.SELECTED_CALENDAR_MODULE_TITLE;
		$scope.events = calendarService.getAllCalendarEvents(true, function(){
			goToCurrentCard();
			updateRunningStateOfEvents();
		},selectedMeetingOption);

		//$scope.events = calendarService.getAllCalendarEvents();
		$scope.uintToRgb = StringUtils.uintToRgb;
		$scope.teamSpaceName = context.getModel().portalVO.name;
		//console.log("CURRENT STATE:",$scope.currentState)
		//$scope.tabs = getTabs();
		$scope.$parent.currentState.value = currentState;
		//console.log("FIXSCROLL--GO_TO_INIT_CALENDAR");
		//bvSliderService.fixScroll();
		//$scope.goToCurrent();
	}

	// to show orange bar
	$scope.runningConfs = presentationService.getRunningConfs();
    $scope.$on('runningConfs:updated', function(event,runningConfs) {
          $scope.runningConfs = runningConfs;
          updateRunningStateOfEvents();
          console.log("runningConfs:updated" , runningConfs);
    });

    var updateRunningStateOfEvents = function()
    {
    	for(var i in $scope.events.uniq_eventDates)
    	{
    		var uniq_date = $scope.events.uniq_eventDates[i];
    		for(var e in $scope.events.eventsMap.get(uniq_date))
	    	{
   				$scope.events.eventsMap.get(uniq_date)[e].isRunning = false;
   			}
   		}

    	if($scope.runningConfs.length > 0 && Object.keys($scope.events).length != 0)
    	{
    		// loops only for running confs found - which will be less number than actual events
	    	for(var i in $scope.runningConfs)
	    	{
	    		var uniq_date = moment(parseInt($scope.runningConfs[i].startDateGMTMillis)).format($scope.titleDateFormat);
	    		for(e in $scope.events.eventsMap.get(uniq_date))
	    		{
	    			if($scope.runningConfs[i].objectBaseUuid == $scope.events.eventsMap.get(uniq_date)[e].objectBaseUuid)
	    			{
	    				$scope.events.eventsMap.get(uniq_date)[e].isRunning = true;
	    			}
	    		}
	    	}
	    }
    }
    
    $scope.initialLocaleCode = localStorage.getItem('USER_LANG_PREF').toLowerCase();
    if($scope.initialLocaleCode == 'zh-cn'){
    	 $scope.localeWithoutRegionCode = localStorage.getItem('USER_LANG_PREF').toLowerCase();
    }else{
    	 $scope.localeWithoutRegionCode = localStorage.getItem('USER_LANG_PREF').toLowerCase().split(/[_-]+/)[0];
    }
    $scope.getTitleDate = function(uniq_date){
    	//return uniq_date != undefined ? localizedMessages.getLocaleDate(uniq_date, 'LL') : "";
    	//return uniq_date != undefined ? moment($filter('date')(date, 'medium')).format('LL') : "";
				var dateHelper = new DateHelper(context.getModel().userVO);
        return dateHelper.getFormattedLongDate(uniq_date);
    }

	$scope.initCalendar();
	$scope.colors = ["#fc0003", "#f70008", "#f2000d", "#ed0012", "#e80017", "#e3001c", "#de0021", "#d90026", "#d4002b", "#cf0030", "#c90036", "#c4003b", "#bf0040", "#ba0045", "#b5004a", "#b0004f", "#ab0054", "#a60059", "#a1005e", "#9c0063", "#960069", "#91006e", "#8c0073", "#870078", "#82007d", "#7d0082", "#780087", "#73008c", "#6e0091", "#690096", "#63009c", "#5e00a1", "#5900a6", "#5400ab", "#4f00b0", "#4a00b5", "#4500ba", "#4000bf", "#3b00c4", "#3600c9", "#3000cf", "#2b00d4", "#2600d9", "#2100de", "#1c00e3", "#1700e8", "#1200ed", "#0d00f2", "#0800f7", "#0300fc"];
	$scope.carouselIndex = 0;


    var toppanel = toppanelService.getInstance('MEETINGS');
   toppanel.addEvent('currentClk', goToCurrentCard);
   toppanel.addEvent('tabs', calendarService.tabs);
   toppanel.addEvent('selectTab', calendarService.setMeetingsTabToLocalStorage);

}