//Model template to capture data to be pased to appd beacon
var usageModelTemplate = function () {
	this.userDataStrObj = {};
	this.userDataBoolObj = {};
	this.userDataLongObj = {};
	this.userDataDoubleObj = {};
	this.userDataDateObj = {};
}
//Adding a postmessage event listener to capture events from document viewer iframe
function receiveDocViewerBeaconMessage(event) {
	if(event.origin !== window.location.origin) 
		return;
	if(event.data && event.data.PostMessageID){
		var tmp = event.data;
		if(tmp.PostMessageID === "BeaconFromDocViewer"){
			switch (tmp.PostAction) {
				case "CustomAjaxBeacon":
					bvUsageUtils.triggerCustomAjaxBeacon(tmp.entType, tmp.entAction, tmp.entID, tmp.entValue, tmp.URL, tmp.serviceName, tmp.methodName);
					break;
				case "CustomVPageBeacon":
					bvUsageUtils.triggerCustomVPageBeacon(tmp.entType, tmp.entAction, tmp.entID, tmp.entValue);	
					break;
				default:
					break;
			}
		}
	}
	return;
}
window.addEventListener("message", receiveDocViewerBeaconMessage, false);

//Utility to to capture and report data to appd interseptors
var bvUsageUtils = {
	const : bvUsageConst,	
    key: {
		// NOTE - Do not edit these key
        //For Global
        CompanyUUID: "nbv_CompanyUUID",
        CompanyShortName: "nbv_CompanyShortName",
        n3aUserName: "n3a_UserName",
        userActivityID: "nbv_ActivityID",
        //For Ajax
        MethodName: "nbv_MethodName",
        ServiceName: "nbv_ServiceName",
        //For Vpages
        PageName: "nbv_PageName",
        //For Custom content
        EntityType: "nbv_EntityType",
        EntityAction: "nbv_EntityAction",
        EntityID: "nbv_EntityID",
        Value: "nbv_Value",
        NoValue: "No Value"
    },
	//Var to store global key value pairs
	globalUsageMap : {},
	//Template instance 
	usageJsonData : new usageModelTemplate(),
	//Call to set global values
	addToGlobalUsageMap : function (key, value){
		this.globalUsageMap[key] = value;
	},
	//Call to set values on template instance
	addMapForUsage : function (key, value) {
		//This is just a function that creates the jsonObject and assigns it to either the bool or string object
		var classType = typeof(value);
		if (value !== undefined) {
		  if (classType === "boolean") {
				this.usageJsonData.userDataBoolObj[key] = value;
			} else if (classType === "number") {
			   if(this.isInt(value)) {
				this.usageJsonData.userDataLongObj[key] = value;
			   } else {
				this.usageJsonData.userDataDoubleObj[key] = value;
			   }
			}
			else if (classType === "string") {
				this.usageJsonData.userDataStrObj[key] = value;
			} else if(value instanceof Date) {
				this.usageJsonData.userDataDateObj[key] = value.getTime();
			} else {
			   console.log("How'd we get here?");
			}
		}
	},
	//Call to set custom entity actions
	addEntityMapForUsage : function (entType, entAction, entID, entValue){
		this.addMapForUsage("nbv_EntityType", entType);
		this.addMapForUsage("nbv_EntityAction", entAction);
		if (entID !== undefined || entID !== null)
			this.addMapForUsage("nbv_EntityID", entID);
		if (entValue !== undefined || entValue !== null)
			this.addMapForUsage("nbv_Value", entValue);
	},
	//Call to set service and method context
	addServiceContextMapForUsage : function (serviceName, methodName){
		this.addMapForUsage("nbv_ServiceName", serviceName);
		this.addMapForUsage("nbv_MethodName", methodName);
	},
	isInt : function(n) {
		return n % 1 === 0;
	},
	parseContextData : function(queryStr){
		var params = {},
		e,
		a = /\+/g,  // Regex for replacing addition symbol with a space
		r = /([^&=]+)=?([^&]*)/g,
		d = function (s) { return decodeURIComponent(s.replace(a, " ")); };
		while (e = r.exec(queryStr))
			params[d(e[1])] = d(e[2]);
		return params;
	},
	//Function to be called from appd virtual page reporting callback to get beacon data
	getVPageUsageReportData : function () {
		var temp = Object.assign({}, this.usageJsonData);
		this.usageJsonData = new usageModelTemplate();
		for(var key in this.globalUsageMap) {
			temp.userDataStrObj[key] = this.globalUsageMap[key];
		}
		temp.userDataStrObj["nbv_PageName"] = window.location.href.split("#")[1];
		return temp;
	},
	//Function to be called from appd ajax call reporting callback to get beacon data
	getAjaxUsageReportData : function (context) {
		var temp = Object.assign({}, this.usageJsonData);
		this.usageJsonData = new usageModelTemplate();
		try{
			if (context.data && (context.data.indexOf("servicename") > -1)) {
				var payload;
				if(window.URLSearchParams){
					payload = new URLSearchParams(context.data);
					temp.userDataStrObj["nbv_ServiceName"] = payload.get("servicename");
					temp.userDataStrObj["nbv_MethodName"] = payload.get("methodname");
				}
				else {
					// The URLSearchParams API does not work on IE/Edge
					payload = this.parseContextData(context.data);
					temp.userDataStrObj["nbv_ServiceName"] = payload["servicename"];
					temp.userDataStrObj["nbv_MethodName"] = payload["methodname"];
				}
			}
		}
		catch(err){
			console.log(err);
		}
		for(var key in this.globalUsageMap) {
			temp.userDataStrObj[key] = this.globalUsageMap[key];
		}
		return temp;
	},
	//Call to set custom entity data and report it as an virtual page beacon 
	triggerCustomVPageBeacon : function (entType, entAction, entID, entValue){
		if(typeof ADRUM === "undefined")
			return;
		ADRUM.markVirtualPageBegin("html#/customvpagebeacon/donotuse", true); //dummy URL to report custom viewpage beacons on
		this.addEntityMapForUsage(entType, entAction, entID, entValue);
		ADRUM.markVirtualPageEnd();
	},
	//Call to set custom entity data and report it as an ajax call beacon
	triggerCustomAjaxBeacon : function (entType, entAction, entID, entValue, URL, serviceName, methodName){
		if(typeof ADRUM === "undefined")
			return;
		if(!URL)
			URL = "html/customajaxbeacon/donotuse"; //dummy URL to report custom ajax beacons on
		var ajaxT = new ADRUM.events.Ajax();
		ajaxT.url(URL);
		this.addEntityMapForUsage(entType, entAction, entID, entValue);
		if(serviceName)
			this.addServiceContextMapForUsage(serviceName, methodName);
		ajaxT.markSendTime(100);
		ajaxT.markFirstByteTime(200);
		ajaxT.markRespAvailTime(300);
		ajaxT.markRespProcTime(400);
		ADRUM.report(ajaxT);
	},
	//Call to process ',' seperated collection entiry id's as individual beacons
	triggerCustomVPageBeaconAfterParse : function (entType, entAction, entID, entValue){
		var temp = entID.replace('[','').replace(']','').replace('"','').split(',');
		for(var id in temp)
			this.triggerCustomVPageBeacon(entType, entAction, temp[id], entValue);
	}
}