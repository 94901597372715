directoryController.$inject = ['$scope', '$state', 'reactInterface', 'context', 'localizedMessages'];

function directoryController($scope, $state, reactInterface, context, localizedMessages){
	$scope.$parent.currentState.value = $state.$current.name;
	var moduleName = $state.$current.name.split("home.")[1];
	$scope.middleBarObject.title = localizedMessages.get(moduleName.toUpperCase());
	$scope.middleBarObject.showBack = false;
	$scope.reactInterface = reactInterface;
	$scope.tabs = [
			{title : $scope.middleBarObject.title, content : $scope.middleBarObject.title, active: true, rightMenuList:''}
		];
}