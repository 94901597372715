function boardbookDirective($templateCache, homeService, boardbookService){

    var Boardbook = function(element, teamSpaceBaseUuid){
        this.element = element;
        this.isLogo = false;
        this.teamSpaceBaseUuid = teamSpaceBaseUuid;
    }

    Boardbook.prototype = {
        constructor: Boardbook,
        getLogoPromise : function() {
            return homeService.getTeamSpaceLogo(this.teamSpaceBaseUuid);
        },
        getTemplate : function() {
            var template = $templateCache.get('boardbook_emboss');
            if (this.isLogo) {
                template = $templateCache.get('boardbook');
            }
            return template;
        },
        render : function() {
            var thisBoardbook = this;

            this.getLogoPromise().then(function (logo) {
                thisBoardbook.isLogo = (logo.url != '');

                var template = thisBoardbook.getTemplate();

                if (thisBoardbook.isLogo) {
                    template = template.replace("<!--logo-->", '<image x="25.5" y="19.5" width="22.4" height="11" xlink:href="' + logo.url + '"/>');
                }

                thisBoardbook.element.html(template);
            });
        }
    }

    return {
        restrict: 'E',
        link : function($scope, element, attrs){
            var boardbook = new Boardbook(element, $scope.iconForObject.portalBaseUuid);
            boardbookService.addToList(boardbook);
            boardbook.render();
        }
    }
}