ViewerBookmarkController.$inject = ['$scope', 'localizedMessages', 'viewerBookmarkService']

function ViewerBookmarkController($scope, localizedMessages, viewerBookmarkService){

    $scope.actionBtnTxt	= localizedMessages.get('SAVE');
    $scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
    $scope.popupTitle = localizedMessages.get("BOOKMARK_PAGE");
    $scope.PAGE_TITLE_FOR_USE_IN_BOARDBOOK_AGENDA_SECTIONS = localizedMessages.get("PAGE_TITLE_FOR_USE_IN_BOARDBOOK_AGENDA_SECTIONS");

	const bvModal = $scope.bvModalControllerInputs.bvModal;
	const pdfDetails = $scope.bvModalControllerInputs.pdfDetails;
	const pageNumber = $scope.bvModalControllerInputs.pageNumber;

    $scope.bookmarkPageTitle = {}

    viewerBookmarkService.getTaggedPagesForBookmarks(pdfDetails.objectBaseUuid, function(data){
        const bookmark = data.find(bm => bm.pageNumber == pageNumber)

        if(bookmark != undefined)
            $scope.bookmarkPageTitle.val = bookmark.title;
    });
    
    $scope.action = function(){
        const bookmarkIndex = viewerBookmarkService.bookmarksList.findIndex(bm => bm.pageNumber == pageNumber)

        if(bookmarkIndex != -1){
          if($scope.bookmarkPageTitle.val != '')
            viewerBookmarkService.bookmarksList[bookmarkIndex].title = $scope.bookmarkPageTitle.val;
          else
            viewerBookmarkService.bookmarksList.splice(bookmarkIndex, 1)
        }
        else{
          if($scope.bookmarkPageTitle.val != ''){
            const bookmark = {
                documentBaseUuid: pdfDetails.objectBaseUuid,
                pageNumber: pageNumber,
                title: $scope.bookmarkPageTitle.val
            }
            viewerBookmarkService.bookmarksList.push(bookmark)
          }
        }

        viewerBookmarkService.bookmarksList = StringUtils.sortObjectsByKey(viewerBookmarkService.bookmarksList, 'pageNumber')
        viewerBookmarkService.tagPageForBookmkark(JSON.stringify(viewerBookmarkService.bookmarksList));
        bvModal.close();
    }
}