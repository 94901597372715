angular
    .module('surveysAlertService.factory', [])
    .factory('surveysAlertService', surveysAlertService);

surveysAlertService.$inject = ['localizedMessages','bvDialog', 'templatesService', 'bvDialogService','legacyUserService','context','messagesService'];

function surveysAlertService(localizedMessages, bvDialog, templatesService, bvDialogService,legacyUserService,context, messagesService)
{
	var survey = null;
	var selectedUsersToAlert = [];
	var alertDlg = null;

	var service = {
		openAlertDialog : openAlertDialog,
		openComposeAlertWindow : openComposeAlertWindow,
		survey: survey,
		alertDlg : alertDlg,
		userSelectionDone: userSelectionDone,
		selectedUsersToAlert : selectedUsersToAlert,
		cancelAlertAction : cancelAlertAction,
		closeAlertDlg : closeAlertDlg,
	}

	return service;

	function openAlertDialog() {
		var name = service.survey.name;
		var users = service.survey.participantBaseUuidAndResponseList;
		console.log(service.survey);
	    
	    var cancelSelectionForAlertMsg = localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION');

	    if(service.survey.isAnonymous)
	    	{
	    		service.alertDlg = bvDialog.showAlertUsersSurvey(
	    			localizedMessages.get('ALERT') + ": " + name,
	    			localizedMessages.get('USERS'), 
	    			users, 
	    			service.userSelectionDone, 
	    			localizedMessages.get('CANCEL'), 
	    			localizedMessages.get('SEND'), 
	    			cancelSelectionForAlertMsg, 
	    			service.openComposeAlertWindow, 
	    			service.cancelAlertAction,
	    			"surveys",
	    			"anonymous"
	    		); 
	    }
	    else
	    {
	    	service.alertDlg = bvDialog.showAlertUsersSurvey(
	    			localizedMessages.get('ALERT') + ": " + name,
	    			localizedMessages.get('USERS'), 
	    			users, 
	    			service.userSelectionDone, 
	    			localizedMessages.get('CANCEL'), 
	    			localizedMessages.get('SEND'), 
	    			cancelSelectionForAlertMsg, 
	    			service.openComposeAlertWindow, 
	    			service.cancelAlertAction,
	    			"surveys",
	    			""
	    		); 
	    }
	}

	function closeAlertDlg() {
		service.alertDlg.close();
	}

	function userSelectionDone (selectedUsersToAlert)
    {
    	service.selectedUsersToAlert = selectedUsersToAlert;
    }

	function cancelAlertAction()
	{
		if(service.selectedUsersToAlert.length > 0)
		{
			bvDialog.confirm(localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION'), ()=>{
				service.selectedUsersToAlert = [];
				service.closeAlertDlg();
			}, null);
		}
		else
		{
			service.selectedUsersToAlert = [];
			service.closeAlertDlg();
		}
	}

	function openComposeAlertWindow() 
	{
		var selectedUsers = service.selectedUsersToAlert;
		if(service.survey.isAnonymous && selectedUsers.length > 0){
			for(var i=0;i<selectedUsers.length;i++){
				selectedUsers[i].name=localizedMessages.get("ANONYMOUS");
			}
		}
		var name = service.survey.title != undefined ? service.survey.title : service.survey.name;
		if(selectedUsers && selectedUsers.length !=0 && selectedUsers != undefined && selectedUsers != null) {
			service.closeAlertDlg();
			var bvModalInputs = {}
			bvModalInputs.windowClass = 'bv-modal-window-compose-new-message';
			bvModalInputs.inputs = {};
			bvModalInputs.buttons = [{},{}];
			bvModalInputs.inputs.messageType = 'alert_survey';
			
			if(legacyUserService.isNasdaqUI)
				bvModalInputs.inputs.businessObjects = service.survey;
			
			bvModalInputs.inputs.popupTitle = localizedMessages.get("ALERT");
			bvModalInputs.inputs.sharedWithList = selectedUsers;
			bvModalInputs.inputs.name = localizedMessages.get("SURVEY_ALERT_SUBJECT");
			bvModalInputs.inputs.comments = localizedMessages.get("SURVEY_ALERT_COMMENTS") + " - " + name;
			bvModalInputs.inputs.alwaysSecure = context.getModel().companyVO.alwaysSendSecureEmail;
			bvModalInputs.controller = 'ComposeMessageController';
			bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');
			messagesService.isSecureEnabled().then(function (response) {
				bvModalInputs.inputs.secure = response.data;
				bvDialogService.showBvModal(bvModalInputs);
				console.log(bvModalInputs.buttons);
			}, function(failed) {
				bvModalInputs.inputs.secure = true;//defaults to true
				bvDialogService.showBvModal(bvModalInputs);
				console.log("isSecureEnabled call failed");
				console.log(bvModalInputs.buttons);});
			console.log(bvModalInputs.buttons);
		}
		else {
			bvDialog.showMessage(localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER'));
	    	service.alertDlg = bvDialog.showAlertUsersSurvey(
	    			localizedMessages.get('ALERT') + ": " + name,
	    			localizedMessages.get('USERS'), 
	    			users, 
	    			service.userSelectionDone, 
	    			localizedMessages.get('CANCEL'), 
	    			localizedMessages.get('SEND'), 
	    			cancelSelectionForAlertMsg, 
	    			service.openComposeAlertWindow, 
	    			service.cancelAlertAction,
	    			"surveys",
	    			""
				); 
			}
	};
}
