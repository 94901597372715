ApprovalDetailsDirectiveController.$inject = ['$scope', '$state', '$stateParams', 'approvalsService', 'localizedMessages','imageService', 'repositoryService', '$filter', '$timeout', 'context', '$modal', 'bvDialog', 'bvSliderService', 'documentViewerInvokeService', 'homeService'];

function ApprovalDetailsDirectiveController($scope, $state, $stateParams, approvalsService, localizedMessages, imageService, repositoryService, $filter , $timeout, context, modal, bvDialog, bvSliderService, documentViewerInvokeService, homeService){
    $scope.ui = {
        label : {
            instructions : localizedMessages.get('INSTRUCTIONS'),
            due : localizedMessages.get('DUE'),
            priority : localizedMessages.get('PRIORITY'),
            select_a_response : localizedMessages.get('SELECT_A_RESPONSE'),
            submit : localizedMessages.get('SUBMIT'),
            cancel : localizedMessages.get('CANCEL'),
            add_comments: localizedMessages.get('ADD_COMMENTS'),
            err_response_not_selected : localizedMessages.get('ERR_RESPONSE_NOT_SELECTED'),
            err_signature_required : localizedMessages.get('ERR_SIGNATURE_REQUIRED'),
            by : localizedMessages.get('BY'),
            clear_signature : localizedMessages.get('CLEAR_SIGNATURE'),
            signature : localizedMessages.get('SIGNATURE'),
            response : localizedMessages.get('RESPONSE'),
            print: localizedMessages.get('PRINT'),
            noDataMessage : localizedMessages.get("DATA_NOT_AVAILABLE"),
            summary: localizedMessages.get("SUMMARY"),
            attachments: localizedMessages.get("ATTACHMENTS"),
            sign_here: localizedMessages.get("SIGN_HERE"),
            hide_chart: localizedMessages.get("HIDE_CHART"),
            show_chart: localizedMessages.get("SHOW_CHART")
        }
    };

    var ctrlName = 'ApprovalDetailsDirectiveController';
    $scope.currentUserVO = context.getModel().userVO;
    $scope.responseSubmitted=false;
    homeService.set_current_module_has_unsaved_changes(false, ctrlName);
    approvalsService.isApprovalResponseDirty = false;
    $scope.svgFill = "";
    $scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
    $scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
    $scope.commentAddImage = 'resources/assets/svgs/commenting_icon.svg';

    $scope.approval = {};
    $scope.comments = '';
    $scope.selectedApprovalUuid = approvalsService.selectedApprovalUuid;
    $scope.no_approval_attachments =localizedMessages.get('NO_ATTACHMENTS');

    $scope.$parent.approvalsMobileMenu = [];
    if($scope.selectedApprovalUuid == undefined) {
        $state.go('home.approvals');
    }

    $scope.fromOpenItems = approvalsService.fromOpenItems;

    $scope.arrowIcon = 'resources/assets/svgs/grey_arrow_meeting_in_progress.svg';

    $scope.chartState = {
        showPieChart : true
    };

    $scope.hidePanelFlag = [true, true, true, true, true, true, true];

    $scope.getApprovalDetailJSON = function (objectBaseUuid)
    {
        return approvalsService.getApprovalDetailJSON(objectBaseUuid)
        .then(function(data){
            approvalsService.setCurrentSelectedApprovalFromList(data);
            return data;
        });
    };


    $scope.approvalAttachmentsSliderOptions = {
        items: [],
        slideThrough: 1,
        shownItems: 4,
      	smallButtons:  MobileUtil.isAndroid()? false : true,
        fixQuantity: false,
        renderCallback : attachmentSliderRenderCallback
    };

    var attachmentSliderRenderCallback = function(){
		bvSliderService.currentIndex = 0;
		$timeout(function(){
			bvSliderService.fixButtons();
		})
	}

    $scope.getResponseDetails = function()
    {
        $scope.responseDetailsAvailable = false;
        $scope.responseDetails = [];
        $scope.participants = {};
        $scope.response = {};
        $scope.isSigned = false;
        $scope.signature = '';

        // based on owner and share response flag will filter out.
        $scope.filterdResponses = {};

        var temp = {};
        var uniqueUsers = {};
        // preparing data for chart from response list
        var rList = $scope.approval.responseList;
        for(var i=0 ; i<rList.length; i++)
        {
            var k = rList[i].value.trim();
            if(temp[k]!=undefined)
            {
                temp[k] = temp[k] + 1;
            }
            else
            {
                temp[k] = 1;
                $scope.participants[k] = [];
            }

            if(rList[i].responseDateGMTMillis != undefined)
            {
                var comments = "";
                if(rList[i].comments != undefined) {
                    comments = rList[i].comments;
                }
                var signature = "";
                if(rList[i].isSigned == true)
                {
                    signature = rList[i].signature;
                }
                $scope.participants[k].push([rList[i].name, rList[i].inActive, rList[i].responseDateGMTMillis,comments,rList[i].isSigned, signature, rList[i].objectBaseUuid]);
                uniqueUsers[rList[i].name] = i;
            }
            else
            {
                $scope.participants[k].push([rList[i].name]);
            }
            $scope.participants[k].color = $scope.colorFor(k);
        }
        // not responded list
        var rList = [];
        if($scope.approval.respondentList != undefined)
        rList = $scope.approval.respondentList;

        var k = localizedMessages.get("NOT_RESPONDED");
        temp[k] = 0;
        $scope.participants[k] = [];

        if(!$scope.approval.isAnonymous)
    	{
        	for(var i=0 ; i<rList.length; i++)
        	{
        		if(uniqueUsers[rList[i].name] == undefined)
                {
                    temp[k] = temp[k] + 1;
                    $scope.participants[k].push([rList[i].name, rList[i].inActive]);
                }
        	}
        }
        else
    	{ 
        	var anonymousNonRespondents = $scope.approval.totalParticipants - $scope.approval.noOfRespondents;
        	for(var count = 0 ; count < anonymousNonRespondents; count++)
    		{
        		temp[k] = temp[k] + 1;
        		$scope.participants[k].push(" ", false);
    		}
    	}
        $scope.participants[k].color = $scope.colorFor(k);

        //sorting keys to map colors of chart and underline of title on right
        var keys = Object.keys(temp).sort();
        for(i=0; i<keys.length; i++)
        {
            $scope.responseDetails.push({key: keys[i], y: temp[keys[i]]});
        }

        // selecting response if already exist, also setting response object if need to be updated.
        $scope.response.responseObjectBaseUuid = null;
        if($scope.approval.currentApprovalPollQuestionResponse)
        {
            $scope.response.selectedResponse = $scope.approval.currentApprovalPollQuestionResponse.choice;
            $scope.response.comments = $scope.approval.currentApprovalPollQuestionResponse.comments;
            $scope.response.responseObjectBaseUuid = $scope.approval.currentApprovalPollQuestionResponse.objectBaseUuid;
        }
        //alert($scope.response.selectedResponse);
        $scope.responseDetailsAvailable = true;
        
        console.log($scope.responseDetails)
    };

    var amIOwner = function()
    {
        if($scope.approval.ownerList != undefined)
        {
            for(var i in $scope.approval.ownerList)
            {
                if($scope.approval.ownerList[i].objectBaseUuid == $scope.currentUserVO.objectBaseUuid)
                {
                    $scope.approval.amIOwner = true;
                    return;
                }
            }
        }
    }

    var amIInvited = function()
    {
        if($scope.approval.respondentList != undefined)
        {
            for(var i in $scope.approval.respondentList)
            {
                if($scope.approval.respondentList[i].objectBaseUuid == $scope.currentUserVO.objectBaseUuid)
                {
                    $scope.approval.amIInvited = true;
                    return;
                }
            }
        }
    }
    //Change For MEETX-29963
    $scope.getApprovalStatus=function(){
       return $scope.approval.noOfRespondents>0;
    }
     //End Of Change For MEETX-29963
    $scope.filterResponses = function() {
        if($scope.approval.amIOwner || $scope.approval.shareParticipantResponse) {
            $scope.filterdResponses = $scope.participants;
        }
        else {
            $scope.filterdResponses = {};
            angular.forEach($scope.participants, function(responses, responseKey)
            {
                for(var i in responses)
                {
                    var response = responses[i];
                    if(response[6] === $scope.currentUserVO.objectBaseUuid) {
                        $scope.filterdResponses[responseKey] = [response];
                        $scope.filterdResponses[responseKey].color = responses.color;
                    }
                }
            });
        }
    }

    $scope.getApprovalDetails = function()
    {
    	$scope.hasAttachments = true;
        $scope.getApprovalDetailJSON($scope.selectedApprovalUuid).then(function(data)
        {
            $scope.approval = data;
            $scope.approval.amIInvited = false;
            $scope.approval.amIOwner = false;
            $scope.approval.pastDueDate = $scope.approval.dueDateGMTMillis < Date.now();

            amIOwner();
            amIInvited();
            if($scope.middleBarObject != undefined)
            	$scope.middleBarObject.title = $scope.approval.name;

            $scope.approvalAttachmentsSliderOptions.items = $scope.approval.attachments;
            if (($scope.approval != null && $scope.approval != undefined) && 
                ($scope.approval.attachments == undefined || $scope.approval.attachments.length == 0)) 
            {
            	$scope.hasAttachments = false;
                $scope.attachmentsCount = "";
			}else {
                if ($scope.approval.attachments.length > 1)
                    $scope.attachmentsCount = $scope.approval.attachments.length + " " + localizedMessages.get("ATTACHMENTS");
                else
                    $scope.attachmentsCount = $scope.approval.attachments.length + " " + localizedMessages.get("ATTACHMENT");
                
            }
            $scope.getResponseDetails();
            $scope.filterResponses();
        });
    };

    $scope.getApprovalDetails();

    $scope.getDocumentThumbnail = function(attachment)
    {
        if (attachment.documentThumbnail != undefined)
        {
            return "data:image/png;base64," + attachment.documentThumbnail;
        }
        else
        {
            return imageService.getImageByExtension(attachment.extensionType);
        }
    }

    $scope.openAttachmentFile = function (attachment)
    {
        $scope.inApprovalsModule = true;
//        var documentViewerInvokeInstance = DocumentViewerInvokeService.getInstance();
//        repositoryService.module = "APPROVALS";
        //Change For MEETX-29963
        repositoryService.setApprovalStatus($scope.getApprovalStatus());
        repositoryService.openPdfViewer(attachment);
    }

    $scope.editSignature = function()
    {
        $scope.selectedUser = $scope.currentUserVO;
        $scope.isLoggedInUserSignatureSet = false;
        $scope.signatureCaller = "approvals";

        var editSignatureModal = new modal.open({
            windowClass : 	'editSignatureDialog',
            templateUrl :  	'modules/team/CreateSignature.tpl.html',
            scope :      	$scope,
            controller : 	'CreateSignatureController',
            backdrop: 'static',
            keyboard: false
        });

        editSignatureModal.result.then(function(resultData){
        	$scope.isLoggedInUserSignatureSet = true;
        }, function(){
        	$scope.isLoggedInUserSignatureSet = true;
        });

        var intervalId = setInterval(function(){
            if($scope.isLoggedInUserSignatureSet == true)
            {
                clearInterval(intervalId);

                if($scope.loggedInUserSignature != undefined && $scope.loggedInUserSignature != null && $scope.loggedInUserSignature != "")
                {
                	 $scope.signature = $scope.loggedInUserSignature;
                }

                if($scope.loggedInUserInitials != undefined && $scope.loggedInUserInitials != null && $scope.loggedInUserInitials != "")
                {
                    //myInitial = $homeScope.loggedInUserInitials;
                }
                $scope.isSigned = true;
                approvalsService.isApprovalResponseDirty = true;
                homeService.set_current_module_has_unsaved_changes(true, ctrlName);
            }
        }, 500);

    }

    $scope.clearSignature = function()
    {
        $scope.isSigned = false;
        $scope.signature = null;
    }

    $scope.signIt = function()
    {
        // get signature
        //var userObjectBaseUuid = JSON.parse(sessionStorage.getItem('currentUserVO')).objectBaseUuid;
        if(StringUtils.isNullEmpty($scope.response.selectedResponse))
        return;
        if($scope.isSigned) {

        	if(MobileUtil.isAndroid())
        	{//For Android devices, edit signature is disabled for user who already has a signature.
        	   //So do nothing for android devices
         		return;
            }

            $scope.isSigned = false;
            $scope.editSignature();
            return;
        }
        approvalsService.getMySignature()
        .then(function(data)
        {
            $scope.signature = data;
            if($scope.signature == null || $scope.signature.length == 0)
            {
                //	alert("No signature found.");
            	if(MobileUtil.isAndroid())
            	{//For Android,if no signature then confirm to create signature
            		bvDialog.confirm(localizedMessages.get("NO_SIGNATURE_CREATE_SIGNATURE_CONFIRM"), function(){
            			//Cancel this approval and set up user signature
            			approvalsService.isApprovalResponseDirty = false;
                  homeService.set_current_module_has_unsaved_changes(false, ctrlName);
            			//$scope.cancel();
                  Android.setupUserSignature();
            		});

            	}else
                {
            		$scope.editSignature();
                }
                //bvDialog.showMessage(localizedMessages.get(""))
                $scope.isSigned = false;
                return;
            }
            $scope.isSigned = true;
            approvalsService.isApprovalResponseDirty = true;
            homeService.set_current_module_has_unsaved_changes(true, ctrlName);
        });
    }

    $scope.getShortLocalizedDate = function(date) {
        var zone_name =  moment.tz.guess();
        var timezone= " "
        if(zone_name)
        {
            timezone=timezone.concat(moment.tz(zone_name).zoneAbbr());
        }
        return date != undefined ? moment($filter('date')(date, 'medium')).format('lll')+timezone : "";
    }

    $scope.getLocalizedDate = function(date) {
        // return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
        return date != undefined ? localizedMessages.getLocaleDate(date, 'lll') : "";
    }

    $scope.getLocalizedDateOnLocalTimezone = function(date) {
        // return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
        return date != undefined ? localizedMessages.getLocaleDateOnLocalTimezone(date, 'lll zz') : "";
    }

    $scope.openAddCommentsDialog = function() {
        bvDialog.showAddComments(saveComments, $scope.response.comments, 10000);
    }

    var saveComments = function(comments) {
        $scope.response.comments = comments;
        approvalsService.isApprovalResponseDirty = true;
        homeService.set_current_module_has_unsaved_changes(true, ctrlName);
    }

    $scope.colorArray = [ '#6693b0','#f05e27', '#86d1e4', '#e4f9a0', '#97b77f','#9ebddc', '#d6b8da'];
    var colorIndex = -1;
    $scope.colorFor = function(responseType)
    {
        switch(responseType)
        {
            case localizedMessages.get("APPROVE"):
            return '#a5bc4e';
            break;
            case localizedMessages.get("NOT_RESPONDED"):
            return '#e48701';
            break;
            case localizedMessages.get("ABSTAIN"):
            return '#1b95d9';
            break;
            case localizedMessages.get("NOT_APPROVE"):
            return '#caca9e';
            break;
            default:
            colorIndex += 1;
            console.log(responseType + ":" + colorIndex);
            return $scope.colorArray[colorIndex % 7];
            break;
        }
    }

    $scope.selectResponseChoice = function(choice)
    {
        $scope.response.selectedResponse = choice;
        homeService.set_current_module_has_unsaved_changes(true, ctrlName);
        approvalsService.isApprovalResponseDirty = true;
    }
    $scope.submitResponse = function()
    {
        if($scope.response.selectedResponse == undefined || $scope.response.selectedResponse == null || $scope.response.selectedResponse == '')
        {
            bvDialog.showMessage($scope.ui.label.err_response_not_selected);
        }
        else if ($scope.isSigned == false && $scope.approval.signatureRequired == true)
        {
            bvDialog.showMessage($scope.ui.label.err_signature_required);
        }
        else
        {        	
        	$scope.responseSubmitted=true;
            if($scope.response.comments == undefined) $scope.response.comments = "";
            bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Approval, bvUsageUtils.const.Entity.Action.Approval.ApprovalSubmitted, approvalsService.selectedApprovalUuid, 1);
            approvalsService.createApprovalResponse(approvalsService.selectedApprovalUuid,
                $scope.response.responseObjectBaseUuid, $scope.response.selectedResponse,
                $scope.response.comments, $scope.isSigned)
            .then(function(data)
            {
                if(data != null) {
                    homeService.set_current_module_has_unsaved_changes(false, ctrlName);
                    approvalsService.isApprovalResponseDirty = false;
                    if($scope.$parent.fromOpenItems)
                    {
                        $scope.$parent.fromOpenItems = false;
                        $scope.$parent.countItems.value = $scope.$parent.countItems.value -1;
                        $scope.$parent.compileHtml = null;
                        for(var i=0; i < $scope.$parent.dashboardList.length; i++)
                        {
                            if($scope.$parent.dashboardList[i].objectBaseUuid == approvalsService.selectedApprovalUuid)
                            {
                                $scope.$parent.dashboardList.splice(i, 1);
                            }
                        }
                        for(var i=0; i < $scope.$parent.approvalList.length; i++)
                        {
                            if($scope.$parent.approvalList[i].objectBaseUuid == approvalsService.selectedApprovalUuid)
                            {
                                $scope.$parent.approvalList.splice(i, 1);
                            }
                        }
                    }
                    $scope.getApprovalDetails();
                    $state.go('home.approvals');
                }
                else
                {
                	$scope.responseSubmitted=false;
                    bvDialog.showMessage(localizedMessages.get("FAILED_TO_SUBMIT_RESPONSE"));
                }
            });
        }
    }

    $scope.cancel = function()
    {
    	$state.go('home.approvals');
    }


    // Chart
    $scope.xFunction = function ()
    {
        return function (d)
        {
            return d.key;
        };
    };

    $scope.yFunction = function(){
        return function(d)
        {
            return d.y;
        };
    };

    $scope.colorFunction = function()
    {
        return function(d, i)
        {
            var key = "";
            if(d.key != undefined)
            key = d.key;
            else if (d.data.key != undefined)
            key = d.data.key;

            if(key === "")
            return "#000";
            else
            return $scope.participants[key].color;
        };
    };

    $scope.togglePieChart = function()
    {
        $scope.chartState.showPieChart = !$scope.chartState.showPieChart
        approvalsService.approvalOptions = $scope.chartState;
        if(MobileUtil.isAndroid()) {
        	var $chartDiv = $('.chart-div');
        	var originalHeight = $chartDiv.height() != 0? $chartDiv.height() : 80;
        	var pieChartHeight= $scope.chartState.showPieChart == false ? 0 : originalHeight;
        	$chartDiv.css('height', pieChartHeight + '%');
        }

    }
    /* Print related */

    $scope.popupTitle = localizedMessages.get('PRINT');
    if(approvalsService.approvalOptions == undefined ||
        approvalsService.approvalOptions != undefined && !approvalsService.approvalOptions.showPieChart)
    {
        approvalsService.approvalOptions = $scope.chartState;
    }

    $scope.approvalPrintOptions = approvalsService.approvalOptions;

    $scope.printApproval = function() {
        var print = document.querySelector('.print-approval-main');
        console.log(print);
        if(print != undefined) {
            document.querySelector('.print-approval-container .mCSB_container').style.top = 0;
            document.querySelector('.print-approval-container .mCSB_dragger').style.top = 0;
            $(".print-approval-container").print(
            {
                globalStyles: false,
                mediaPrint: true,
                stylesheet: 'resources/css/print.css',
                noPrintSelector: ".no-print",
                iframe: true,
                append: null,
                prepend: null,
                manuallyCopyFormValues: true,
                deferred: $.Deferred(),
                timeout: 250
            });
        }
    }

    $scope.getParticipantsColor = function(response){
        return $scope.participants[response.key].color;
    }

}

angular
.module('ApprovalDetailsDirectiveController.directive', [])
.controller('ApprovalDetailsDirectiveController', ApprovalDetailsDirectiveController)
