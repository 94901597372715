adSearchFilterPanelService.$inject = ['$timeout','homeModel', 'adSearchService', 'localizedMessages'];

function adSearchFilterPanelService($timeout,homeModel, adSearchService, localizedMessages){

    this.portals = [];
    var searchFilterObject = {};

    var m = moment;
    var ml = StringUtils.momentL;
    var title = {
        sinceLastVisit: localizedMessages.get('SINCE_LAST_VISIT'),
        today: localizedMessages.get('TODAY'),
        yesterday: localizedMessages.get('YESTERDAY'),
        last: localizedMessages.get('LAST'),
        days: localizedMessages.get('DAYS'),
        months: localizedMessages.get('MONTHS'),
        pdf: localizedMessages.get('PDF_UPPERCASE'),
        msWord: localizedMessages.get('MS_WORD'),
        msExcel: localizedMessages.get('MS_EXCEL'),
        msPowerpoint: localizedMessages.get('MS_POWERPOINT'),
        others: localizedMessages.get('OTHERS'),
        reachedMsg: localizedMessages.get('YOU_HAVE_REACHED_THE_MAXIMUM_OF_CHARACTERS')
    }

    var SearchFilterObject = function(dateFormat){
        var dateFormat = dateFormat || 'll';

        var Selection = function(max){
            this.input = '';
            this.list = [];
            this.max = max || 5;
            this.validation = null;
            this.setValidation = function(validation){
                if(validation)
                    this.validation = validation;
            }
        }

        this.teamspaceSelection = angular.copy(homeModel.listofPortals);
        this.title = '';
        this.advancedSearchData = {
            offset: 0,
            lastVisit: false,
            startDateMillis: null,
            endDateMillis: null,
            portalBaseUuidList: null,
            phraseSearchText: null,
            exculdeSearchText: null,
            fileTypes: null
        }
        this.rangeSelection = {
            select : '',
            from : 0,
            to : 0,
            datePickFrom : {},
            datePickTo : {},
            lastVisit : false,
            dateFormat: dateFormat
        }

        this.keywordSelection = new Selection();
        this.phraseSelection = new Selection();
        this.excludeSelection = new Selection();

        this.fileSelection = [
            { name: 'doc', title : title.msWord , value : true },
            { name: 'xls', title : title.msExcel , value : true },
            { name: 'ppt', title : title.msPowerpoint , value : true },
            { name: 'pdf', title : title.pdf , value : true },
            { name: 'others', title : title.others , value : true },
        ]

        this.calculateRange();
    }

    var add = function(selection, input, specific){
        if(selection.input || input){

            if(specific){
                if(selection.list.indexOf(input) == -1){
                    selection.list.push(input);
                }
            }
            else{
                var arr = [];
                if(selection.input){
                    arr = _.filter(selection.input.split(' '));
                    arr = _.uniq(selection.list.concat(arr));
                }
                if(input){
                    arr = _.filter(input.split(' '));
                }

                if(arr.length > selection.max)
                    return false;

                if(input){
                    selection.list = arr;
                }
                else{
                    for(var i = 0; i < arr.length; i++){
                        if(selection.list.indexOf(arr[i]) == -1 && selection.list.length < selection.max){
                            selection.list.push(arr[i]);
                        }
                    }
                }
            }

            selection.input = '';

            return true;
        }

        return null;
    }

    var getAll = function(selection, specific){
        var array = [];
        array = angular.copy(selection.list);

        if(specific && selection.input != ''){
            array.push(selection.input);
        }
        else{
            var input = _.uniq(_.filter(selection.input.split(' ')));
            if(input.length > 0)
                array = array.concat(input);
        }

        if(array.length > 0)
            return array;
        return [];
    }

    SearchFilterObject.prototype = {
        constructor : SearchFilterObject,
        calculateRange : function(refresh){

            switch(this.rangeSelection.select){
                case 'new':
                    this.rangeSelection.lastVisit = true;
                break;
                case 'yesterday':
                    this.rangeSelection.lastVisit = false;
                    this.rangeSelection.from = m().add(-1, 'days').startOf('day').valueOf();
                    this.rangeSelection.to = m().add(-1, 'days').endOf('day').valueOf();
                break;
                case 'today':
                    this.rangeSelection.lastVisit = false;
                    this.rangeSelection.from = m().startOf('day').valueOf();
                    this.rangeSelection.to = m().valueOf();
                break;
                case '7days':
                    this.rangeSelection.lastVisit = false;
                    this.rangeSelection.from = m().add(-7, 'days').startOf('day').valueOf();
                    this.rangeSelection.to = m().valueOf();
                break;
                case '30days':
                    this.rangeSelection.lastVisit = false;
                    this.rangeSelection.from = m().add(-30, 'days').startOf('day').valueOf();
                    this.rangeSelection.to = m().valueOf();
                break;
                case '90days':
                    this.rangeSelection.lastVisit = false;
                    this.rangeSelection.from = m().add(-90, 'days').startOf('day').valueOf();
                    this.rangeSelection.to = m().valueOf();
                break;
                case '6months':
                    this.rangeSelection.lastVisit = false;
                    this.rangeSelection.from = m().add(-6, 'month').startOf('day').valueOf();
                    this.rangeSelection.to = m().valueOf();
                break;
                case '12months':
                    this.rangeSelection.lastVisit = false;
                    this.rangeSelection.from = m().add(-1, 'year').startOf('day').valueOf();
                    this.rangeSelection.to = m().valueOf();
                break;
                case 'custom':
                    this.rangeSelection.lastVisit = false; 
                    this.rangeSelection.from = m().valueOf();
                    this.rangeSelection.to = m().valueOf();
                    $timeout(function() {
                  	  angular.element('#date-picker-to').datepicker().datepicker("setDate", new Date());
                  	  angular.element('#date-picker-from').datepicker().datepicker("setDate", new Date());                       
                      angular.element('#date-picker-from').triggerHandler('click');                         
                   }, 500);

                break;
            }

            this.rangeSelection.datePickFrom.startingDate = m(this.rangeSelection.from).format(this.rangeSelection.dateFormat);
            this.rangeSelection.datePickFrom.startingDateLocal = ml(this.rangeSelection.from).format(this.rangeSelection.dateFormat);
            this.rangeSelection.datePickTo.startingDate = m(this.rangeSelection.to).format(this.rangeSelection.dateFormat);
            this.rangeSelection.datePickTo.startingDateLocal = ml(this.rangeSelection.to).format(this.rangeSelection.dateFormat);

            if(refresh)
                adSearchService.recallServer();
        },
        selectTeamSpace: function(space){
            space.selected = !space.selected;

            adSearchService.recallServer();
        },
        removeTeamSpace: function(space){
            space.selected = false;

            adSearchService.recallServer();
        },
        resetRange : function(refresh){
            this.rangeSelection.select = '';
            this.rangeSelection.lastVisit = false;
            this.rangeSelection.from = 0;
            this.rangeSelection.to = 0;
//            this.rangeSelection.datePickFrom = {}
//            this.rangeSelection.datePickTo = {}

            this.calculateRange(refresh);
        },
        resetTeamspaceSelection : function(refresh){
            _.each(this.teamspaceSelection, function(space){
                if(space.selected){
                    space.selected = false;
                }
            });

            if(refresh)
                adSearchService.recallServer();
        },
        resetDocumentsDetails : function(refresh){
            this.keywordSelection.input = '';
            this.keywordSelection.list = [];
            this.phraseSelection.input = '';
            this.phraseSelection.list = [];
            this.excludeSelection.input = '';
            this.excludeSelection.list = [];

            _.each(this.fileSelection, function(o){ o.value = true });

            this.keywordSelection.validation.setValidate(title.reachedMsg, false, false);
            this.phraseSelection.validation.setValidate(title.reachedMsg, false, false);
            this.excludeSelection.validation.setValidate(title.reachedMsg, false, false);

            if(refresh)
                adSearchService.recallServer();
        },
        addKeyword : function(input){
            return add(this.keywordSelection, input);
        },
        addSpecific : function(input){
            return add(this.phraseSelection, input, true);
        },
        addExclude : function(input){
            return add(this.excludeSelection, input);
        },
        updateKeywords: function(string){
            var array = [];
            if(string) array = string.split(' ');
            var oldWord = _.difference(this.keywordSelection.list, array);
            var newWordString = _.difference(array, this.keywordSelection.list).join(' ');

            if(oldWord.length > 0){
                if(oldWord.length == 1){
                    var oldWordIndex = this.keywordSelection.list.indexOf(oldWord[0]);
                    if(newWordString == ''){
                        this.keywordSelection.list.splice(oldWordIndex, 1);
                        this.keywordSelection.validation.setValidate(title.reachedMsg, false, false);
                    }
                    else
                        this.keywordSelection.list[oldWordIndex] = newWordString;
                }
                else{
                    this.keywordSelection.list = array;
                }
            }
            else{
                this.keywordSelection.input = '';
            }

            if(newWordString != '' && this.keywordSelection.list.indexOf(newWordString) == -1)
                this.keywordSelection.input = newWordString;
        },
        generateTitle : function(){
            var middleBarTitle = [];

            var rangeString;
            switch(this.rangeSelection.select){
                case 'new':
                    rangeString = title.sinceLastVisit;
                break;
                case 'yesterday':
                    rangeString = title.yesterday;
                break;
                case 'today':
                    rangeString = title.today;
                break;
                case '7days':
                    rangeString = title.last + ' 7 ' + title.days;
                break;
                case '30days':
                    rangeString = title.last + ' 30 ' + title.days;
                break;
                case '90days':
                    rangeString = title.last + ' 90 ' + title.days;
                break;
                case '6months':
                    rangeString = title.last + ' 6 ' + title.months;
                break;
                case '12months':
                    rangeString = title.last + ' 12 ' + title.months;
                break;
                case 'custom':
                    rangeString = m(this.rangeSelection.from).format('ll') + ' - ' + m(this.rangeSelection.to).format('ll');
                break;
                default:
                    rangeString = null;
                break;
            }

            var teamspaceString = _.map(_.filter(this.teamspaceSelection, { selected : true }), 'name').join('" "');
            var phrasesString = this.getAllPhrases().join('" "');
            var excludesString = this.getAllExcludes().join('" "');

            var unSelectedTypes = _.filter(this.fileSelection, { value: false });

            var filetypesString = '';
            if(unSelectedTypes.length > 0 && unSelectedTypes.length < this.fileSelection.length){
                filetypesString = _.map(_.filter(this.fileSelection, { value: true }), 'title').join('" "');
            }

            rangeString ? middleBarTitle.push('"' + rangeString + '"') : null;
            teamspaceString != '' ? middleBarTitle.push('"' + teamspaceString + '"') : null;
            phrasesString != '' ? middleBarTitle.push('"' + phrasesString + '"') : null;
            excludesString != '' ? middleBarTitle.push('"' + excludesString + '"') : null;
            filetypesString != '' ? middleBarTitle.push('"' + filetypesString + '"') : null;

            this.title = middleBarTitle.join(' : ')
            return this.title;
        },
        getAllKeywords: function(){
            return getAll(this.keywordSelection);
        },
        getAllPhrases: function(){
            return getAll(this.phraseSelection, true);
        },
        getAllExcludes: function(){
            return getAll(this.excludeSelection);
        }
    }

    this.getSearchFilterObject = function(dateFormat){
        if(!(searchFilterObject instanceof SearchFilterObject)){
            searchFilterObject = new SearchFilterObject(dateFormat);
        }
        return searchFilterObject;
    }

}