function replaceHighlightPattern($sce, highlightUsersFactory) {
	return function (input) {
		input = input.replace(/</g, '&lt;').replace(/>/g, '&gt;');
		var matchs = highlightUsersFactory.getHighlightedUsers(input);
		if (matchs) {
			matchs.forEach(function (match) {
				input = input.replace(match, '<span class="highlight">' + highlightUsersFactory.parseUserName(match) + '</span>');
			});
		}
		return $sce.trustAsHtml(input);
	};
};