permissionsController.$inject = ['$scope', 'permissionsService', 'localizedMessages', 'commonService', '$timeout', 'treeService', 'repositoryService', 'bvDialog', 'context', 'constants', 'companyUserService', 'SmartSelection', 'templatesService', 'SortUtility']
function permissionsController(scope, permissionsService, localizedMessages, commonService, $timeout, treeService, repositoryService, bvDialog, context, constants, companyUserService, SmartSelection, templatesService, SortUtility) {


	//			scope.iconUser = 'resources/assets/svgs/users_small.svg';
	scope.leftArrow = 'resources/assets/svgs/backarrow.svg';
	scope.rightArrow = 'resources/assets/svgs/arrow.svg';
	scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	scope.closeIcon = templatesService.getUrl('closeBtn');
	scope.sortColumn = null;
	scope.sortOrder = false;
	scope.sort = function (col, toggle = true) {
		if (col === scope.sortColumn && toggle) {
			scope.sortOrder = !scope.sortOrder;
		} else if (col != scope.sortColumn) {
			scope.sortOrder = false;
			scope.sortColumn = col;
		}
		scope.listUsers = SortUtility.sortByField(scope.listUsers, [col], scope.sortOrder);
	}
	scope.getSortableClass = function (col) {
		if (col === scope.sortColumn) {
			if (scope.sortOrder) {
				return 'sort-up';
			} else {
				return 'sort-down';
			}
		} else {
			return 'sortable';
		}
	}
	scope.userListEnable = false;
	var isUser = {
		currentUser: function (user) {
			return user.objectBaseUuid == context.getModel().userVO.objectBaseUuid;
		}
	}

	scope.itemObjectBaseUuid = scope.bvModalControllerInputs.object.objectBaseUuid;
	scope.objectName = scope.bvModalControllerInputs.object.name;
	scope.objectType = StringUtils.getTypeByObjectBaseUuid(scope.bvModalControllerInputs.object.objectBaseUuid);
	var bvModal = scope.bvModalControllerInputs.bvModal;

	if (scope.itemObjectBaseUuid == undefined) {
		alert(localizedMessages.get('PLEASE_DEFINED'));
	}
	else {

		scope.dialogTitle = localizedMessages.get('PERMISSIONS');
		scope.inheritedPermissionsFromParentFolderLabel = localizedMessages.get('INHERITED_PERMISSIONS_FROM_PARENT_FOLDER');
		scope.explicitPermissionsLabel = localizedMessages.get('EXPLICIT_PERMISSIONS');
		scope.nameLabel = localizedMessages.get('NAME');
		scope.descriptionLabel = localizedMessages.get('DESCRIPTION');
		scope.saveButtonLabel = localizedMessages.get('SAVE');
		scope.colorLabel = localizedMessages.get('COLOR');
		scope.typeLabel = localizedMessages.get('TYPE');
		scope.nameLabel = localizedMessages.get('NAME');
		scope.permissionLabel = localizedMessages.get('PERMISSION');
		scope.popupTitle = localizedMessages.get('PERMISSIONS');
		scope.actionBtnTxt = localizedMessages.get('SAVE');
		scope.closeBtnTxt = localizedMessages.get('CANCEL');
		scope.folder = localizedMessages.get('FOLDER');
		scope.users = localizedMessages.get('USERS');
		scope.addLabel = localizedMessages.get('ADD');

		scope.tooltipOwner = localizedMessages.get('PERMISSION_TOOLTIP_OWN');
		scope.tooltipShare = localizedMessages.get('PERMISSION_TOOLTIP_COLLABORATE');
		scope.tooltipPrint = localizedMessages.get('PERMISSION_TOOLTIP_PRINT');
		scope.tooltipRead = localizedMessages.get('PERMISSION_TOOLTIP_VIEW');
		scope.tooltipDeny = localizedMessages.get('TOOLTIP_DENY');

		var teamspaceTitle = localizedMessages.get('TEAMSPACE');
		var companyTitle = localizedMessages.get('COMPANY');

		scope.folderHierarchyLabel = localizedMessages.get('FOLDER_HIERARCHY');
		scope.applyToSubfoldersAndFilesLabel = localizedMessages.get('APPLY_TO_SUBFOLDERS_AND_FILES');
		scope.dragDropLabel = localizedMessages.get('DRAG_AND_DROP_USER_TO_ADD');

		scope.listUsers = [];

		scope.showApplyToSubFoldersOption = context.getModel().enableApplyToSubFolders; //HTML-2171
		scope.modelCheckbox = {
			//    				showInherit: true,
			//    				showExplicit: true,
			applyToSub: false //HTML-2171
		}

		var options = []
		for (var i in context.getModel().permissionDisplayNames) {
			options.push(context.getModel().permissionDisplayNames[i]);
		}
		scope.selectOptions = [];

		scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['owner'].toUpperCase()), value: context.getModel().permissionDisplayNames['owner'], title: scope.tooltipOwner });
		scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['share'].toUpperCase()), value: context.getModel().permissionDisplayNames['share'], title: scope.tooltipShare });
		scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['read'].toUpperCase()), value: context.getModel().permissionDisplayNames['read'], title: scope.tooltipRead });
		scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['print'].toUpperCase()), value: context.getModel().permissionDisplayNames['print'], title: scope.tooltipPrint });
		scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['deny'].toUpperCase()), value: context.getModel().permissionDisplayNames['deny'], title: scope.tooltipDeny });

		//   			if(StringUtils.isFolder(scope.itemObjectBaseUuid))
		//   				scope.selectOptions.push( { label: localizedMessages.get(context.getModel().permissionDisplayNames['deny'].toUpperCase()), value: context.getModel().permissionDisplayNames['deny'], title:  scope.tooltipDeny  } );

		var selectOptions_ = angular.copy(scope.selectOptions);

		scope.tabsUsersList = [
			{ title: teamspaceTitle, content: teamspaceTitle },
			{ title: companyTitle, content: companyTitle }
		]

		scope.showUserList = false;
		scope.showFolderList = false;
		scope.grouping = true;

		scope.permission = {};

		$timeout(function () {
			$timeout(function () {
				commonService.getUsers().then(function (data) {
					scope.allUsers = data;
				});
			}, 0);
		}, 1000);

		//    			permissionsService.getUsersJSON(true, true, true).then(function(data){
		//    				scope.allUsers = data.data;
		//    			});
		//
		function getUpperCaseName(array) {
			if (array) {
				for (var i = 0; i < array.length; i++) {
					var type = '';
					for (var k = 0; k < array[i].type.length; k++) {
						if (array[i].type[k] == array[i].type[k].toUpperCase()) {
							type = type + array[i].type[k];
						}
					}
					type = constants.get('GET_SHORT_OBJECT_NAME_' + type);
					if (array[i].typeM != type) {
						array[i].typeM = type;
					}
				}
			}
			return array;
		}

		scope.listUsers = [];
		var getPermissionsByObjectBaseUuid = function (objectBaseUuid) {
			permissionsService.getPermissionJSON(objectBaseUuid).then(function (data) {
				scope.listUsers = data.data.permissionACLVOList;
				scope.listUsers = getUpperCaseName(scope.listUsers);
				scope.inheritedListUsers = data.data.inheritedPermissionACLVOList;
				if (scope.inheritedListUsers && scope.inheritedListUsers.length == 0) {
					scope.modelCheckbox.showInherit = false;
				}

				scope.userListEnable = true;
				scope.listUsers.forEach(function (item, i) {
					isUser.currentUser(item) ? (scope.listUsers.splice(0, 0, scope.listUsers[i]), scope.listUsers.splice(i + 1, 1)) : null;
				});
				scope.sort('name');
			});
		}

		getPermissionsByObjectBaseUuid(scope.itemObjectBaseUuid)

		scope.usersBtn = function () {
			scope.showUserList = !scope.showUserList;
		}

		scope.foldersBtn = function () {
			scope.showFolderList = !scope.showFolderList;
		}

		var applyUser = function (name, type, objectBaseUuid) {
			if (scope.listUsers) {
				var existingObj = false;
				scope.listUsers.forEach(function (obj) {
					if (obj.objectBaseUuid == objectBaseUuid) {
						existingObj = true;
					}
				});

				if (!existingObj) {
					scope.listUsers.push({
						name: name,
						type: type,
						objectBaseUuid: objectBaseUuid,
						permission: context.getModel().permissionDisplayNames[context.getModel().defaultDocumentPermission]
					});
					scope.listUsers = getUpperCaseName(scope.listUsers);
					setTimeout(function () {
						angular.element('.bv-table-css.bv-table-4-cols > div')[0].scrollTop = 1000
					}, 0)
				}
				else {
					bvDialog.showMessage(localizedMessages.get("ERR_USER_ALREADY_EXISTS"));
				}
			}
			else {
				bvDialog.showMessage(localizedMessages.get("UNABLE_TO_ADD_USERS"));
			}
		}

		var checkExist = function (objectBaseUuid) {
			var list = scope.listUsers;
			for (var i = 0; i < list.length; i++) {
				if (list[i].objectBaseUuid == objectBaseUuid) {
					return true;
				}
			}
			return false;
		}

		scope.clickOk = function () {
			!checkExist(this.selected.objectBaseUuid) ? applyUser(this.selected.name, this.selected.type, this.selected.objectBaseUuid) : '';
			this.selected = '';
		}

		scope.removeClick = function (user) {
			scope.listUsers.splice(scope.listUsers.indexOf(user), 1);
			smartSelectionUtils.selectInList([user], false);
		}

		scope.action = function () {
			var numberOfEmpty = 0;
			for (var i = 0; i < scope.listUsers.length; i++) {
				if (!('permission' in scope.listUsers[i])) {
					scope.listUsers[i].empty = true;
					numberOfEmpty++;
				}
				else {
					delete scope.listUsers[i].empty;
				}
			}
			if (numberOfEmpty == 0) {
				var aclVOs = scope.listUsers;
				var sub = scope.modelCheckbox.applyToSub;
				var inherit = scope.modelCheckbox.showInherit;
				//    					var showExplicit = scope.modelCheckbox.showExplicit;
				//    					if(!showExplicit){
				//    						aclVOs = [];
				//    					}

				permissionsService.savePermissionJSON(scope.itemObjectBaseUuid, sub, inherit, angular.toJson(aclVOs)).then(function (data) {
					bvModal.close();
				});
			}
		}

		scope.selectedItems = []
		scope.selectUsers = function () {
			scope.addSelectedUser(scope.selectedItems);
		};

		scope.addSelectedUser = function (selectedItems) {
			scope.selectedUsers = selectedItems;
			// $scope.userListModal.dismiss('close');
			if (scope.selectedUsers != undefined && scope.selectedUsers.length > 0) {
				for (var i in scope.selectedUsers) {
					applyUser(scope.selectedUsers[i].name, scope.selectedUsers[i].type, scope.selectedUsers[i].objectBaseUuid)
				}
			}
		};

		scope.instantSelection = function (selectedItems) {
			scope.selectedItems = selectedItems;
		}

		//    			scope.endAnimate = function(){
		//    				scope.showUserListDiv = true;
		//    				scope.$apply();
		//    			}

		//    		    repositoryService.getDocumentRootFolder().then(function(data)
		//	        	{
		//	        		if(data.data.boName != "FailureBO") {
		//	                	rootFolder = data.data;
		//						scope.getDocumentForRepositoryTree(rootFolder);
		//	                }
		//	        	});

		var treeDataModel = treeService.getInstance("FOLDER_ATTACHMENT");

		scope.getDocumentForRepositoryTree = function (rootFolder) {
			scope.folderTreeModel = [rootFolder];

			treeDataModel.getChildrenForTreeNode(rootFolder, repositoryService.getDocumentsJSONForParentBaseUuid);
		};

		scope.getSelectedTreeNode = function (selectedNode) {
			//var treeDataModel = treeService.getInstance("FOLDER_ATTACHMENT");
			treeDataModel.setSelectedNode(selectedNode);

			getPermissionsByObjectBaseUuid(selectedNode.objectBaseUuid);
			scope.objectName = selectedNode.name;
			scope.objectType = StringUtils.getTypeByObjectBaseUuid(selectedNode.objectBaseUuid);
			scope.itemObjectBaseUuid = selectedNode.objectBaseUuid;

			repositoryService.getDocumentsJSONForParentBaseUuid(selectedNode.objectBaseUuid).then(function (data) {
				selectedNode.children = [];

				selectedNode.children = data.data;

			});
		};

		scope.setSelectedTreeNode = function (selectedNode) {
			treeDataModel.setSelectedNode(selectedNode);
			getPermissionsByObjectBaseUuid(selectedNode.objectBaseUuid);
			scope.objectName = selectedNode.name;
			scope.objectType = StringUtils.getTypeByObjectBaseUuid(selectedNode.objectBaseUuid);
			scope.itemObjectBaseUuid = selectedNode.objectBaseUuid;
		}

		scope.toggleDropdown = function ($event, user) {
			$event.preventDefault();
			$event.stopPropagation();

			scope.selectOptions = selectOptions_;

			user.isopen = !user.isopen;
		};

		scope.toggled = function (isopen) {
			if (!isopen) {
				scope.selectOptions = selectOptions_;
			}
		}

		var smartSelectionUtils = {}
		var initialComplete = function (smartSelection) {
			smartSelectionUtils = smartSelection.smartSelectionUtils;

			smartSelectionUtils.selectInList(scope.listUsers, true);
		}

		scope.smartSelectionOptions = {
			initialComplete: initialComplete,
			checkboxDisableFeature: true,
			disableEmptyNodes: true,
			settings: {
				disableUpDownSelection: true
			}
		};

		scope.instantSelection = function (objs) {
			_.forEach(objs, function (item) {
				if (!item.selected) {
					var obj = _.find(scope.listUsers, { objectBaseUuid: item.objectBaseUuid });
					if (obj && !item.checkboxDisabled) {
						_.remove(scope.listUsers, { objectBaseUuid: item.objectBaseUuid });
					}

					smartSelectionUtils.selectInList([item], false);
				}
				else {
					if (_.findIndex(scope.listUsers, { objectBaseUuid: item.objectBaseUuid }) != -1)
						return;

					var user = angular.copy(item);
					user.permission = context.getModel().permissionDisplayNames[context.getModel().defaultDocumentPermission];
					isUser.currentUser(user) ? scope.listUsers.splice(0, 0, user) : scope.listUsers.push(user);
				}
			});
			scope.sort(scope.sortColumn, false);
		}


		scope.getDocumentForRepositoryTree(angular.copy(scope.bvModalControllerInputs.object));
	}

}

angular
	.module('bvPermissions', ['permissions.service', 'localization.service'])
	.controller('permissionsController', permissionsController)
//	.directive('animateIf', function($animate) {
//		  return {
//			    scope: {
//			      'animateIf': '=',
//			      'animateCallback': '&'
//			    },
//			    link: function (scope, element, attrs) {
//				    scope.animateCallback = scope.animateCallback || (function() {});
//				    $animate.enter(element, element.parent())
//
//				    var callback = function(data){
//				    	scope.animateCallback();
//				    	element.off('$animate:close', callback)
//				    }
//
//				    scope.$watch('animateIf', function(value, newVal){
//				    	if(value){
//						    element.on('$animate:close', callback)
//				    	}
//				    })
//
//			    }
//		  }
//	})
