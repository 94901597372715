angular
	.module('dialogControllers.service')
	.controller('createUserSubmitController', createUserSubmitController);

createUserSubmitController.$inject = ['$scope', 'localizedMessages', 'commonService', 'context', 'homeService', 'bvDialogService', 'loginService', 'bvDialog', 'legacyUserService'];

function createUserSubmitController($scope, localizedMessages, commonService, context, homeService, bvDialogService, loginService, bvDialog, legacyUserService){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var isLoginSupport = $scope.bvModalControllerInputs.isLoginSupport != null ? $scope.bvModalControllerInputs.isLoginSupport : false;

	var actionTrue = $scope.bvModalControllerInputs.actionTrue;
	var actionFalse = $scope.bvModalControllerInputs.actionFalse;
	$scope.popupTitle 	= $scope.bvModalControllerInputs.title;
	$scope.msgTitle = "Thank you for adding an additional license(s). We have automatically fulfilled your order and our team will now determine if this order exceeds your company's current contract allotment. If this order is above the current contract allotment, an amendment to your company's contract and additional fees are required and we will send a link to the contract amendment to you or your authorized signatory.";
	$scope.msgDesc = "During the period prior to our receipt of your signed contract amendment: (a) we expressly disclaim any and all liability with respect to this order; (b) the additional license(s) are provided without representation or warranty of any kind, express, implied, or statutory; and (c) use of such additional license(s) is at the customer company's and user's risk. If you do not accept these terms or the terms of the contract amendment, please do not use the additional license(s) and contact your account representative right away to cancel this order. If we do not receive your signed contract amendment within 30 days of the contract amendment being sent, we may terminate the additional license(s) referenced above without notice or liability.";
	$scope.msgFotter = "If this order does not exceed your company's current contract allotment, no contract amendment or additional fees are required. The additional license(s) are available for use in accordance with the terms of your company's current contract.";
	
	if(actionTrue != null && typeof actionTrue  == 'function'){
		$scope.buttons[1].action = function(){
			actionTrue();
			bvModal.close();
		}
	}
	if(actionFalse != null && typeof actionFalse  == 'function'){
		$scope.buttons[0].action = function(){
//			actionFalse($modalInstance);
			bvModal.close();
		}
	}
	
}
