function onDocVEditEnd(editStatus)
{
    var homeModel = angular.element(document.querySelector('body')).injector().get('homeModel');
    homeModel.vEditModal.docDlg.close();
    var docId = homeModel.vEditModal.docId;
    var repositoryController = angular.element(document.querySelector('[repository-controler]')).scope();
    if(repositoryController)
    {
       for(var i =0; i< repositoryController.repositoryList.browseList.length ; i++)
       {
            if(docId === repositoryController.repositoryList.browseList[i].objectBaseUuid)
            {
                var updated = angular.copy(repositoryController.repositoryList.browseList[i]);

                if(editStatus==="EDIT_CANCELLED")
                {
                    updated.conversionStatus = "CONVERTED";
                    updated.swfConversionStatus = "CONVERTED";
                    updated.locked = false;
                }
                else
                {
                    //updated.locked = false;
                    updated.conversionStatus = "CONVERTING";
                    updated.swfConversionStatus = "CONVERTING";
                }
                repositoryController.replaceDocument(updated);
                break;
                // if(repositoryController.repositoryList.browseList[i].conversionStatus==='CONVERTED')
                // {
                //     break;
                // }
                // else
                // {
                //     var updated = angular.copy(repositoryController.repositoryList.browseList[i]);
                //     //updated.locked = false;
                //     updated.conversionStatus = "CONVERTING";
                //     updated.swfConversionStatus = "CONVERTING";
                //     repositoryController.replaceDocument(updated);
                //     break;
                // }
            }
       }
    }
}