function signaturesService($q, ajaxService, responseValidatorService) {
	var service = {

		getDocumentsForSignature: function()
		{	
			var request=ajaxService.getDocumentsForSignature();
			return request.then(function(data) 
			{				
				if(responseValidatorService.isSuccess(data))
				{
					return data;
				}
				return null;
			});
		},
		getDocumentsForSignatureTabs: function(tab)
		{	
			var request=ajaxService.getDocumentsForSignatureTabs(tab);
			return request.then(function(data) 
			{				
				if(responseValidatorService.isSuccess(data))
				{
					return data;
				}
				return null;
			});
		},
		getSignCommitedDocuments: function()
		{	
			var request=ajaxService.getSignCommitedDocuments();
			return request.then(function(data) 
			{				
				if(responseValidatorService.isSuccess(data))
				{
					return data;
				}
				return null;
			});
		},

		getOwnedSignatureDocuments: function()
		{	
			var request=ajaxService.getOwnedSignatureDocuments();
			return request.then(function(data) 
			{				
				if(responseValidatorService.isSuccess(data))
				{
					return data;
				}
				return null;
			});
		}
	}

	return service;
}
angular
	.module('signatures.service', [ 'ajax.service' ])
	.factory('signaturesService',[ '$q', 'ajaxService', 'responseValidatorService', signaturesService ]);