angular
    .module('dialogControllers.service')
	.controller('printApprovalOptionsController', printApprovalOptionsController);

printApprovalOptionsController.$inject = ['$scope', 'bvDialog', 'localizedMessages', 'approvalsService'];

function printApprovalOptionsController($scope, bvDialog, localizedMessages, approvalsService){
	
	$scope.popupTitle 	= localizedMessages.get('PRINT');
	$scope.actionBtnTxt = localizedMessages.get('OK');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.pieChartTxt = localizedMessages.get('PIE_CHART');
	$scope.thumbnailsTxt = localizedMessages.get('THUMBNAILS');
	$scope.wouldLikeToIncludeTxt = localizedMessages.get('WOULD_LIKE_TO_INCLUDE');

	$scope.bvchecktrue 		= 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvcheckfalse 	= 'resources/assets/svgs/bvcheckfalse.svg';
	
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var onSave = $scope.bvModalControllerInputs.onSave;

	$scope.approvalOptions = {
		pieChart : true
	};

	$scope.action = function() 
	{
		approvalsService.approvalOptions = $scope.approvalOptions;
		onSave();
		bvModal.close();
	};
}