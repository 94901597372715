CreatePushController.$inject = ['$scope', '$state', '$stateParams', 'pushService', 'localizedMessages', 'bvDialogService', 'bvDialog', 'contextMenuModel', 'modelService', 'context', 'imageService', '$filter', 'companyUserService', 'templatesService', 'bvDialogMobile'];

function CreatePushController($scope, $state, $stateParams, pushService, localizedMessages, bvDialogService, bvDialog, contextMenuModel, modelService, context, imageService, $filter, companyUserService, templatesService, bvDialogMobile) {
	
	$scope.popupTitle  =   localizedMessages.get('PUSH');
	
	$scope.ui = { label : {
    	cancel		:localizedMessages.get("CANCEL"),
    	push		:localizedMessages.get("PUSH"),
    	comments	:localizedMessages.get("COMMENTS"),
    	expires_on	:localizedMessages.get("EXPIRES_ON"),
    	recipients  :localizedMessages.get("RECIPIENTS"),
    	users		:localizedMessages.get("USERS"),
    	add			:localizedMessages.get("ADD"),
    	objectPushUsersError	:localizedMessages.get("OBJECT_PUSH_USERS_ERROR"),
      }
	};
	
	$scope.rightArrow = 'resources/assets/svgs/arrow.svg';
	$scope.closeIcon = templatesService.getUrl('closeBtn');
	$scope.plusIcon ='resources/assets/svgs/plus_icon.svg';
	
	$scope.showUserList = false;
	$scope.selectedItems = [];
	$scope.selectedUsers = [];
	
	var getLocalizedDate = function(date) {
		return moment($filter('date')(date, 'medium')).format('ll');
	}
	
	$scope.objectPush = modelService.getObjectPushObject();
	
	if($scope.bvModalControllerInputs.objectType != undefined) {
		if($scope.bvModalControllerInputs.objectType == 'ANNOTATION') {
			$scope.selectedDoc = $scope.bvModalControllerInputs.selectedObject;
			$scope.selectedUsers = $scope.bvModalControllerInputs.selectedUsers;
			var documentSignAnnotationMap = $scope.bvModalControllerInputs.selectedUsers;
			var annotationUserList = [];
			for(var i in documentSignAnnotationMap) 
	    	{
	    		for(var j in documentSignAnnotationMap[i])
	    		{
	    			annotationUserList.push(documentSignAnnotationMap[i][j].userBaseUuid);
	    		}
	    	}
		}
		else if($scope.bvModalControllerInputs.objectType == 'RE_PUSH')
		{
			$scope.selectedDoc = $scope.bvModalControllerInputs.selectedObject.pushBO;
			$scope.objectPush.expiryDate = getLocalizedDate($scope.objectPush.expiresOn);
			$scope.objectPush.pushId = $scope.bvModalControllerInputs.selectedObject.pushId;
			$scope.objectPush.comments = $scope.bvModalControllerInputs.selectedObject.comments;
		} 
		else if($scope.bvModalControllerInputs.objectType == 'PUSH_TO_ALL')
		{
			$scope.selectedDoc = $scope.bvModalControllerInputs.selectedObject.pushBO;
			$scope.selectedUsers = $scope.bvModalControllerInputs.selectedObject.objectPushUsers;
			$scope.objectPush.expiryDate = getLocalizedDate($scope.objectPush.expiresOn);
			$scope.objectPush.pushId = $scope.bvModalControllerInputs.selectedObject.pushId;
			$scope.objectPush.comments = $scope.bvModalControllerInputs.selectedObject.comments;
		}
	}
	else {
		$scope.selectedDoc = $scope.bvModalControllerInputs.selectedObject;
	}
	
	$scope.objectPush.objectBaseUuid = $scope.selectedDoc.objectBaseUuid;
	$scope.objectPush.objectVersionUuid = $scope.selectedDoc.objectVersionUuid;
	
	var attachment = $scope.selectedDoc;
	
	var checkFolder = StringUtils.isFolder(attachment.objectBaseUuid);
	$scope.properitesData = [];
	$scope.properitesData.push( {title: localizedMessages.get("NAME"), desc: attachment.name} );        		
	$scope.properitesData.push( {title: localizedMessages.get("TYPE"), desc: localizedMessages.get(StringUtils.getTypeByObjectBaseUuid(attachment.objectBaseUuid).toUpperCase())} );
	if(!checkFolder){
		var fileSize = StringUtils.fileSizeConverter(attachment.fileSize, 0);
		$scope.properitesData.push( {title: localizedMessages.get("FILE_SIZE"), desc: fileSize} );
	}
	$scope.properitesData.push( {title: localizedMessages.get("CREATED_ON"), desc: moment(attachment.originalCreatedOn).format("MMM DD, YYYY HH:MM:SS A")} );
	$scope.properitesData.push( {title: localizedMessages.get("LAST_MODIFIED_ON"), desc: moment(attachment.lastModifiedOnGMTMillis).format("MMM DD, YYYY HH:MM:SS A")} );
	if(attachment.lastModifiedBy != undefined){
		$scope.properitesData.push( {title: localizedMessages.get("MODIFIED_BY"), desc: attachment.lastModifiedBy} );
	}
	
	$scope.objectPush.expiryDate = moment().format("ll");
	
	$scope.timeInterval = (context.getModel() == null || context.getModel() == undefined) ? "15" : context.getModel().calendarEventTimeIncrementValue;
	if($scope.timeInterval == "5")
		$scope.timeInterval = "05";

	var tInterval = parseInt($scope.timeInterval);

	$scope.maxTime = "23:" + (60 - tInterval);
	
	$scope.spanTimeClicked = false;
    $scope.spanTimeClick = function(){    	
    	$scope.spanTimeClicked = true;    	
    };
    
    $scope.showCalender = function()
	{
		var dateFormat = StringUtils.getLocaleDateFormat();
		var monthNamesShort = moment.localeData()._monthsShort;
			
		$( "#datepicker" ).datepicker({
			showPanelButton:true,
			beforeShow:function(){
				$('.ui-datepicker').css('font-size',11);
				$("#ui-datepicker-div").addClass("meeting-datepicker");
				$("#ui-datepicker-div").addClass("bv-push-date");
			},
			showAnim: 'slideDown',
			monthNamesShort: monthNamesShort,
			dateFormat: dateFormat,
			minDate : new Date(),
			onSelect: function(dateText, inst) {
				$scope.startingDateISO = $.datepicker.parseDate(inst.settings.dateFormat || $.datepicker._defaults.dateFormat, dateText, inst.settings);
			}
		});
		
		$( "#datepicker" ).show();
		$("#ui-datepicker-div").css("z-index", 1100);
		$( "#datepicker" ).focus();
	};
	
	$scope.showRecipents = function() {
	      templatesService.setCustomDialogTemplate();
	      $scope.recipentPopupTitle = localizedMessages.get("RECIPIENTS");
	      if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
	    	  bvDialogMobile.itemListSelection($scope.recipentPopupTitle, true, $scope.selectUsers, false, false, null, $scope.instantSelection);
	      }else{
	    	  bvDialog.itemListSelection($scope.recipentPopupTitle, true, $scope.selectUsers, false, false, null, $scope.instantSelection);
	      }
	    }
	
	$scope.setSelectedRecipients = function(selectedItems) {
		$scope.selectedRecipients = selectedItems;
	}
	
	$scope.usersBtn = function(){
		$scope.showUserList = !$scope.showUserList;
	}
	
	$scope.instantSelection = function(selectedItems){
		$scope.selectedItems = selectedItems;
	}
	
	var addGroupUsersInUserList = function(groupId) {
		var users = companyUserService.getGroupUsers(groupId);
		if(users && users.length> 0)
		{
			for(var k in users){
				addUserInUserList(users[k]);
			}
		}
	};
	
	var addUserInUserList = function(user) {
		if(checkRecipientsAlreadyExists(user.objectBaseUuid) == false)
		{	
			if($scope.selectedUsers) {
				$scope.selectedUsers.push(user);
			}
		}
	}
	
	$scope.selectUsers = function(){
		
		if ($scope.selectedItems != undefined && $scope.selectedItems.length > 0) {
			
			for (var i in $scope.selectedItems) {

				if ($scope.selectedItems[i].type == 'TeamSpaceGroup') {
					addGroupUsersInUserList($scope.selectedItems[i].objectBaseUuid);
				} else if ($scope.selectedItems[i].type == 'Group') {
					addGroupUsersInUserList($scope.selectedItems[i].objectBaseUuid);
				} else if ($scope.selectedItems[i].type == 'Portal') {
					addGroupUsersInUserList($scope.selectedItems[i].objectBaseUuid);
				} else {
					addUserInUserList($scope.selectedItems[i]);
				}

			}
		}
    };
    
	var checkRecipientsAlreadyExists = function(objectBaseUuid){
		var existsFlag = false;
		if($scope.selectedUsers && $scope.selectedUsers.length != 0)
		{	
			for(var index in $scope.selectedUsers){
				if($scope.selectedUsers[index].securityUuid != undefined) {
					if($scope.selectedUsers[index].securityUuid == objectBaseUuid){
						existsFlag = true;
						break;
					}
				} else if($scope.selectedUsers[index].objectBaseUuid != undefined) {
					if($scope.selectedUsers[index].objectBaseUuid == objectBaseUuid){
						existsFlag = true;
						break;
					}
				}
				
			}
		}
		else
			existsFlag = false;
		
		return existsFlag;
	};
	
	$scope.removeRecipient = function(index){
		var from 	= index;
		var to		= 0;
		var rest = $scope.selectedUsers.slice((to || from) + 1 || $scope.selectedUsers.length);
		$scope.selectedUsers.length = from < 0 ? $scope.selectedUsers.length + from : from;
		$scope.selectedUsers.push.apply($scope.selectedUsers, rest);
	};
	
	var attachmentType = localizedMessages.get(StringUtils.getTypeByObjectBaseUuid(attachment.objectBaseUuid).toUpperCase());
	if(($scope.selectedUsers == undefined || $scope.selectedUsers.length == 0) && $scope.bvModalControllerInputs.objectType != 'RE_PUSH') {
		if(attachmentType.toLowerCase() == "approval" && attachment.respondentList != undefined){
			for(var i=0; i<attachment.respondentList.length; i++) {
					$scope.selectedUsers.push(attachment.respondentList[i]);
			}
		}
		if(attachmentType.toLowerCase() == "survey"){
			if(attachment.participantCommitList != undefined) {
				for(var i=0; i<attachment.participantCommitList.length; i++) {
						$scope.selectedUsers.push(attachment.participantCommitList[i]);
				}
			}
			if(attachment.participantPendingList != undefined) {
				for(var i=0; i<attachment.participantPendingList.length; i++) {
						$scope.selectedUsers.push(attachment.participantPendingList[i]);
				}
			}
		}
		if(attachmentType.toLowerCase() == "document"){
			if(attachment.signPendingUserMap != undefined){
				for(var userId in attachment.signPendingUserMap) {
					var user = new Object;
					user.name = attachment.signPendingUserMap[userId];
					user.objectBaseUuid = userId;
					$scope.selectedUsers.push(user);
				}
			}
			if(attachment.signCommitUserMap != undefined){
				for(var userId in attachment.signCommitUserMap) {
					var user = new Object;
					var userDetails = attachment.signCommitUserMap[userId]
					user.name = userDetails.substring(0, userDetails.indexOf("##"));
					user.objectBaseUuid = userId;
					$scope.selectedUsers.push(user);
				}
			}
		}
	}

	$scope.getDocumentThumbnail = function() 
 	{
 		var obj = $scope.selectedDoc;
 		if (obj && obj.documentThumbnail != undefined && obj.documentThumbnail != "") 
		{
			return "data:image/png;base64," + obj.documentThumbnail;
		}
		else
		{
			if(!obj || obj.extensionType == undefined)
				return null;
			return imageService.getImageByExtension(obj.extensionType);
		}
	}
	
    var checkValidation = function(){
		if(($scope.selectedUsers == undefined || $scope.selectedUsers.length == 0)){
			$scope.errorMessage = $scope.ui.label.objectPushUsersError;
			return false;
		}
		return true;
	};
    
    var push = function(){
    	
    	if(StringUtils.isNullEmpty($scope.startingDateISO) == false){
    		$scope.objectPush.expiryDate = StringUtils.momentL($scope.startingDateISO).format('ll');
		}
		else{
			var date = moment($scope.objectPush.expiryDate, moment().localeData()._longDateFormat.ll);
			$scope.objectPush.expiryDate = StringUtils.momentL(date._d).format('ll');
		}
    	
    	if(checkValidation()) {
    		for(var i=0; i<$scope.selectedUsers.length; i++) {
    			if($scope.selectedUsers[i].securityUuid != undefined) {
    				$scope.objectPush.selectedUsers.push($scope.selectedUsers[i].securityUuid);
    			} else if($scope.selectedUsers[i].objectBaseUuid) {
    				$scope.objectPush.selectedUsers.push($scope.selectedUsers[i].objectBaseUuid);
    			}
        		
        	}
    		var existingUserUuids = [];
    		if($scope.existingUsers != undefined || $scope.existingUsers != null) {
    			for(var i=0; i<$scope.existingUsers.length; i++) {
    				if($scope.existingUsers[i].action == 'deleted')
    					existingUserUuids.push($scope.existingUsers[i].objectBaseUuid);
            	}
    		}
    		var expiryDayTime = $scope.objectPush.expiryDate + ' ' + '11:59 PM';
    		var expiryDate = StringUtils.momentL(expiryDayTime).utcOffset(moment().utcOffset()).utc().format('ll');
    		var	expiryTime = 	moment(expiryDayTime).utcOffset(moment().utcOffset()).utc().format('hh:mm A');
    		$scope.objectPush.expiryDayTime = expiryDate + " " + expiryTime;
    		pushService.createObjectPush(angular.toJson($scope.objectPush)).then(function(data){
        		cancelEdit();
    		});
    	}
    	else {
    		bvDialog.showMessage($scope.errorMessage);
    	}
    	
    }
    
    var close = function(result) {
    	bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), cancelEdit );
    };
    
    var cancelEdit = function(){
		$scope.bvModalControllerInputs.bvModal.close();
	}
	
	var buttons = $scope.bvModalControllerInputs.buttons;
	
    buttons[0] = {name: $scope.ui.label.cancel, action: close};
    buttons[1] = {name: $scope.ui.label.push, action: push};
}

angular
	.module('createPush', [])
	.controller('CreatePushController', CreatePushController);