function ResourceController($stateParams,resourceService)
{
	console.log($stateParams);
	var resourceid = $stateParams.plink;	
	
	if(resourceid != null)
	{
		//resourceid=Base64.decode(resourceid);
	}
}

function ResourceService()
{
	var _resourceId = "";
	
	this.setResourceId = function(resourceId)
	{
		_resourceId = resourceId;
	}
	
	this.getResourceId = function()
	{
		return _resourceId;
	}
}


angular
	.module('resource.module', [])
	.service('ResourceService', [ResourceService])
	.controller('ResourceController', ['$stateParams','ResourceService',ResourceController]);