function bvOpenSurveyDirective(legacyUserService){
    return {
        restrict: 'CE',
        templateUrl: !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/OpenSurveyDirective.tpl.html' : "modules/surveys/OpenSurveyDirective.tpl.html",
        controller: ['$scope', '$state', '$stateParams', 'surveysService','teamService', 'repositoryService', 'imageService', 'localizedMessages', 'context', '$modal', '$filter', '$modalStack','bvDialog','bvDialogService', 'documentViewerInvokeService', 'bvSliderInstanceService', 'homeService', '$timeout',
        function OpenSurveyController($scope, $state, $stateParams, surveysService, teamService, repositoryService, imageService, localizedMessages, context, $modal, $filter, $modalStack,bvDialog, bvDialogService, documentViewerInvokeService, bvSliderInstanceService,homeService, $timeout) {
        	var currentUserVO = context.getModel().userVO;
        	var ctrlName="OpenSurveyController";
            homeService.set_current_module_has_unsaved_changes(false, ctrlName);
            surveysService.isSurveyResponseDirty = false;
        	$scope.ui = {
                    label : {
                    	all: localizedMessages.get('ALL'),
                    	by: localizedMessages.get('BY'),
                    	complete : localizedMessages.get('COMPLETE'),
                    	due : localizedMessages.get('DUE'),
                    	priority : localizedMessages.get('PRIORITY'),
                    	previous : localizedMessages.get('PREVIOUS'),
                    	back:localizedMessages.get('BACK'),
                    	resume : localizedMessages.get('RESUME'),
                    	next : localizedMessages.get('NEXT'),
                    	finish_later : localizedMessages.get('FINISH_LATER'),
                    	question_attachment : localizedMessages.get('QUESTION_ATTACHMENT'),
                    	caps_complete: localizedMessages.get('CAPS_COMPLETE'),
                    	completed : localizedMessages.get('COMPLETED'),
                    	completed_lower : localizedMessages.get('COMPLETED').toLowerCase(),
                    	section : localizedMessages.get('SECTION'),
                    	sections : localizedMessages.get('SECTIONS'),
                    	questions : localizedMessages.get('QUESTIONS'),
                    	questions_lower : localizedMessages.get('QUESTIONS').toLowerCase(),
                    	attachments : localizedMessages.get('ATTACHMENTS'),
                    	start : localizedMessages.get('START'),
                    	commit : localizedMessages.get('COMMIT'),
                    	no_survey_attachments : localizedMessages.get('NO_SURVEY_ATTACHMENTS'),
                    	send_alert : localizedMessages.get('SEND_ALERT'),
                    	reopen : localizedMessages.get('REOPEN'),
                    	copy: localizedMessages.get('COPY'),
                    	preview : localizedMessages.get('PREVIEW'),
                    	report : localizedMessages.get('REPORT'),
                    	no_section_attachments : localizedMessages.get('NO_SECTION_ATTACHMENTS'),
                    	mandatory : localizedMessages.get('MANDATORY'),
                    	response: localizedMessages.get('SELECT_A_RESPONSE'),
                    	no_question_attachments : localizedMessages.get('NO_QUESTION_ATTACHMENTS'),
                    	notify : localizedMessages.get('NOTIFY'),
                    	send_secure_email : localizedMessages.get('SEND_SECURE_EMAIL'),
                    	please_respond_to_the_survey : localizedMessages.get('PLEASE_RESPOND_TO_THE_SURVEY'),
                    	subject : localizedMessages.get('SUBJECT'),
                    	to : localizedMessages.get('TO'),
                    	alert_for_survey_participants : localizedMessages.get('ALERT_FOR_SURVEY_PARTICIPANTS'),
                    	select_all : localizedMessages.get('SELECT_ALL'),
                    	in_this_case_all_the_survey_questions_and_participants_are_copied: localizedMessages.get('IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_ARE_COPIED'),
                    	title_of_survey : localizedMessages.get('TITLE_OF_SURVEY'),
                    	copy_of_options : localizedMessages.get('COPY_OF_OPTIONS'),
                    	copy_of_questions_and_participants : localizedMessages.get('COPY_OF_QUESTIONS_AND_PARTICIPANTS'),
                    	copy_of_questions_and_participants_and_responses : localizedMessages.get('COPY_OF_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES'),
                    	in_this_case_all_the_survey_questions_and_participants_and_responses_are_copied: localizedMessages.get('IN_THIS_CASE_ALL_THE_SURVEY_QUESTIONS_AND_PARTICIPANTS_AND_RESPONSES_ARE_COPIED'),
                    	copy_of_questions_only : localizedMessages.get('COPY_OF_QUESTIONS_ONLY'),
                    	in_this_case_all_the_questions_are_copied : localizedMessages.get('IN_THIS_CASE_ALL_THE_QUESTIONS_ARE_COPIED'),
                    	printable_reports : localizedMessages.get('PRINTABLE_REPORTS'),
                    	attachment : localizedMessages.get('ATTACHMENT'),
                    	survey_title : localizedMessages.get('SURVEY_TITLE'),
                    	description : localizedMessages.get('DESCRIPTION'),
                    	date : localizedMessages.get('DATE'),
                    	created_by : localizedMessages.get('CREATED_BY'),
                    	section_number : localizedMessages.get('SECTION_NUMBER'),
                    	title : localizedMessages.get('TITLE'),
                    	instructions : localizedMessages.get('INSTRUCTIONS'),
                    	save : localizedMessages.get('SAVE'),
                    	print : localizedMessages.get('PRINT'),
                    	text : localizedMessages.get('TEXT'),
                    	bar : localizedMessages.get('BAR'),
                    	pie : localizedMessages.get('PIE'),
                    	open_surveys : localizedMessages.get('OPEN_SURVEYS'),
                    	created_by_me : localizedMessages.get('CREATED_BY_ME'),
                    	owned_by_me : localizedMessages.get('OWNED_BY_ME'),
                    	closed_surveys : localizedMessages.get('CLOSED_SURVEYS'),
                    	bar_chart : localizedMessages.get('BAR_CHART'),
                    	pie_chart : localizedMessages.get('PIE_CHART'),
                    	submit : localizedMessages.get('SUBMIT'),
                    	cancel : localizedMessages.get('CANCEL'),
                    	signature : localizedMessages.get('SIGNATURE'),
                    	view : localizedMessages.get('VIEW'),
                    	respond : localizedMessages.get('RESPOND'),
                    	resubmit : localizedMessages.get('RESUBMIT'),
                    	survey: localizedMessages.get('SURVEY'),
                    	question: localizedMessages.get('QUESTION'),
                    	of:localizedMessages.get('OF'),
                    	excel:localizedMessages.get('EXCEL'),
                    	sign_here : localizedMessages.get('SIGN_HERE'),
                    	questionsResponded : localizedMessages.get('QUESTIONS_RESPONDED'),
                    	noSections : localizedMessages.get('MSG_NO_SECTIONS'),
                    	overview : localizedMessages.get('OVERVIEW'),
                    	addComments : localizedMessages.get('ADD_COMMENTS'),
                    	backToDetails : localizedMessages.get('BACK_TO_DETAILS'),
                    	saveAndFinishLater : localizedMessages.get("SAVE_AND_FINISH_LATER"),
                    	btnClose : localizedMessages.get("CLOSE"),
                    	editComments : localizedMessages.get("EDIT_COMMENTS")
                    }
                };

        	var bvModal;
        	if($scope.bvModalControllerInputs){
        		$scope.surveyCreationPreviewMode = true;
        		bvModal = $scope.bvModalControllerInputs.bvModal;
            	$scope.surveyDetails = $scope.bvModalControllerInputs.surveyDetails;
            	bvModal.popupTitle = localizedMessages.get("PREVIEW");
            	//$scope.middleBarObject.title = $scope.surveyDetails.title;
        	}
        	else{
        		$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name;
        		$scope.surveyCreationPreviewMode = false;
        		$scope.surveyDetails = surveysService.getSurvey();
            	$scope.selectedSurveyFromList = surveysService.getCurrentSelectedSurveyFromList();
        	}

        	$scope.commentsIcon='resources/assets/svgs/add_comment.svg';
        	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
        	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';

            $scope.getShortLocalizedDate = function(date) {
           		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
        	}
            $scope.getLocalizedDate = function(date) {
           		return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
        	}
    		$scope.openAttachmentForPreview = function(attachment) {
    			bvDialog.showDocumentPreview(attachment.objectBaseUuid, attachment);
    		}
           	$scope.openAttachmentFile = function (attachment)
           	{
           		if(!$scope.surveyCreationPreviewMode){
           			$scope.inSurveysModule = true;
            		var documentViewerInvokeInstance = documentViewerInvokeService.getInstance();
            		documentViewerInvokeInstance.module = "SURVEYS";
            		repositoryService.openPdfViewer(attachment);
           		}
           		else{
           			$scope.openAttachmentForPreview(attachment);
           		}
        	}
        	var bvSliderService;
        	var bvSliderServiceForAttachments;
        	var bvSliderServiceForSectionAttachments;
        	var bvSliderServiceForQuestionAttachments;

        	var sliderRenderCallback = function(){
        		bvSliderService = bvSliderInstanceService.instances[$scope.surveySectionsSliderOptions.id];
        		bvSliderService.currentIndex = 0;
        	}
        	var sliderRenderCallbackForAttachments = function(){
        		bvSliderServiceForAttachments = bvSliderInstanceService.instances[$scope.surveyAttachmentsSliderOptions.id];
        		bvSliderServiceForAttachments.currentIndex = 0;
        	}

        	var sliderRenderCallbackForSectionAttachments = function(){
        		bvSliderServiceForSectionAttachments = bvSliderInstanceService.instances[$scope.surveySectionsAttachmentsSliderOptions.id];
        		bvSliderServiceForSectionAttachments.currentIndex = 0;
        	}
        	var sliderRenderCallbackForQuestionAttachments = function(){
        		bvSliderServiceForQuestionAttachments = bvSliderInstanceService.instances[$scope.surveyQuestionsAttachmentsSliderOptions.id];
        		bvSliderServiceForQuestionAttachments.currentIndex = 0;
        	}
    		$scope.scrollbarConfig = {
    				autoHideScrollbar : true
    			}
        	$scope.surveySectionsSliderOptions = {
        			id: 'SURVEYS_SECTIONS',
        			items : [],
        			shownItems: 3,
        			slideThrough: 1,
        			renderCallback : sliderRenderCallback
        	}
        	$scope.surveyAttachmentsSliderOptions = {
        			id: 'SURVEYS_ATTACHMENTS',
        			items: [],
        			slideThrough: 1,
        			shownItems: 3,
        			smallButtons: MobileUtil.isAndroid()? false : true,
        			renderCallback : sliderRenderCallbackForAttachments
        		};

    		$scope.surveySectionsAttachmentsSliderOptions = {
    				id: 'SURVEYS_SECTIONS_ATTACHMENTS',
        			items: [],
        			slideThrough: 1,
        			shownItems: 3,
        			smallButtons: MobileUtil.isAndroid()? false : true,
        			fixQuantity: true,
        			renderCallback : sliderRenderCallbackForSectionAttachments
    		}
    		$scope.surveyQuestionsAttachmentsSliderOptions = {
    				id: 'SURVEYS_QUESTIONS_ATTACHMENTS',
        			items: [],
        			slideThrough: 1,
        			shownItems: 3,
        			smallButtons: MobileUtil.isAndroid()? false : true,
        			renderCallback : sliderRenderCallbackForQuestionAttachments,

    		}
        	$scope.surveyAttachmentsSliderOptions.items = $scope.surveyDetails.attachments;
        	$scope.surveySectionsSliderOptions.items = $scope.surveyDetails.sections;
        	if($scope.surveyCreationPreviewMode){
        		$scope.sectionCardsObj = surveysService.sectionCards();
            	surveysService.sectionCards($scope.surveySectionsSliderOptions);
        	}

        	$scope.showSurveyOverview = true;
        	$scope.respondedMap = [];

    		$timeout(function(){
				bvSliderService.fixButtons();
				bvSliderServiceForAttachments.fixButtons();
    		},50);
    		$scope.placeHolderValue = localizedMessages.get("ENTER_RESPONSE_HERE");
        	//question response is saved only if there is a change in input.
        	$scope.responseChanged = function(){
        		$scope.reponseChangedValue = true;
        	};
        	$scope.responseChangedOpenAnswer = function()
        	{
        		$("textarea").focus();
        		$scope.reponseChangedValue = true;
        		homeService.set_current_module_has_unsaved_changes(true, "OpenSurveyController");
    			surveysService.isSurveyResponseDirty = true;
        	}

        	var showSurveyOverview = function() {
        		if(!$scope.surveyCreationPreviewMode){
            		var survey = surveysService.getSurvey();
            		surveysService.setQuestionBaseUuidList(survey);
            		surveysService.setAllQuestionsList(survey);
            		var allSurveyQuestionsList = surveysService.getAllQuestionsList();
            		surveysService.getSurveyResponseForUserJSON(survey.objectBaseUuid, angular.toJson(surveysService.getQuestionBaseUuidList()), currentUserVO.objectBaseUuid).then(function(data){
            			$scope.userResponses={};
            			 for(var i=0;i<data.surveyQuestionResponseList.length;i++){
            				 //$scope.qType = '';
        					 for(var j=0;j<allSurveyQuestionsList.length;j++){
        						 if(data.surveyQuestionResponseList[i].questionBaseUuid == allSurveyQuestionsList[j].objectBaseUuid){
        							 data.surveyQuestionResponseList[i].questionType = allSurveyQuestionsList[j].questionType;
        							 break;
        						 }
        					 }
        					 if(data.surveyQuestionResponseList[i].questionType == 'likertscale'){
        						 $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = allSurveyQuestionsList[j].likertMapping[data.surveyQuestionResponseList[i].response];
        					 }
        					 else if(data.surveyQuestionResponseList[i].questionType == 'yesno' || data.surveyQuestionResponseList[i].questionType == 'meetsornot' || data.surveyQuestionResponseList[i].questionType == 'truefalse'){
        						 var locResponse = data.surveyQuestionResponseList[i].response;
        						 if(locResponse == 'does not meet'){
        							 locResponse = localizedMessages.get('DOES_NOT_MEET');
        						 }else{
        							 locResponse = localizedMessages.get(locResponse.toUpperCase());
        						 }
        						 $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = locResponse;
        					 }
        					 else{
        						 $scope.userResponses[data.surveyQuestionResponseList[i].questionBaseUuid] = data.surveyQuestionResponseList[i].response;
        					 }
            			 }
            			 var canEditResponse = true;
            			 bvDialog.userAnswersPreview($scope.userResponses, survey, canEditResponse, currentUserVO.objectBaseUuid,currentUserVO.name);
            		});
        		}
        	};

        	$scope.$parent.surveysMobileMenu = [];
        	$scope.$parent.surveysMobileMenu = [{ name: localizedMessages.get('OVERVIEW'), click: showSurveyOverview , value:"OVERVIEW"}];

        	var rightMenuList=[];
			rightMenuList.push({
    			    title : $scope.ui.label.overview,
    				click : showSurveyOverview,
    				iconUrl: '',
    				svgFill: '#fff'
    			});
        	$scope.$parent.tabs = [ {
        		title : $scope.ui.label.survey,
        		content : 'openSurveys',
        		rightMenuList:rightMenuList
        	} ];

        	$scope.questionsCompletedInSection = {};
        	$scope.surveyProgressStatus;
        	var setSurveyPercentageAndCommitStatus = function(surveyObj){
        		if(!$scope.surveyCreationPreviewMode){
            		var seqCount = 0;
            		surveyObj.description = surveyObj.description || surveyObj.objectDescription;
                	$scope.surveyAttachmentsSliderOptions.items = $scope.surveyDetails.attachments;
                	$scope.surveySectionsSliderOptions.items = $scope.surveyDetails.sections;
            	    $scope.surveyTotalQuestions = 0;
            	    $scope.surveyAnsweredQuestions = 0;
            		for(var i=0;i<surveyObj.sections.length;i++){
            			$scope.surveyTotalQuestions += surveyObj.sections[i].questionsCount;
            			$scope.surveyAnsweredQuestions += surveyObj.sections[i].questionsResponded;
            			$scope.questionsCompletedInSection[surveyObj.sections[i].objectBaseUuid] = Math.round((surveyObj.sections[i].questionsResponded/surveyObj.sections[i].questionsCount)*100);
    	        			for(var j=0;j<surveyObj.sections[i].questions.length;j++){
    	        				seqCount = seqCount + 1;
    	        				surveyObj.sections[i].questions[j].sequentialSecCount = seqCount;
    	        			}
            			}
    				$scope.surveyPercentage=($scope.surveyAnsweredQuestions/$scope.surveyTotalQuestions)*100;
    	    		$scope.hasUserCommitedSurvey = false;
    	    		for(var i=0;i<surveyObj.participantBaseUuidAndResponseList.length;i++){
    	    			if(surveyObj.participantBaseUuidAndResponseList[i].objectBaseUuid == currentUserVO.objectBaseUuid){
    	    				$scope.surveyProgressStatus = surveyObj.participantBaseUuidAndResponseList[i].value;
    	    				if(surveyObj.participantBaseUuidAndResponseList[i].value == 'responded'){
    	            			$scope.hasUserCommitedSurvey = true;
    	            			break;
    	            		}
    	    			}
    	    		}
        		}else{
        			var seqCount = 0;
        			surveyObj.description = surveyObj.description || surveyObj.objectDescription;
        			$scope.surveyAttachmentsSliderOptions.items = $scope.surveyDetails.attachments;
                	$scope.surveySectionsSliderOptions.items = $scope.surveyDetails.sections;
                	$scope.surveyPercentage=0;
    	    		$scope.hasUserCommitedSurvey = false;
            	    $scope.surveyTotalQuestions = 0;
            	    surveyObj.attachments = surveyObj.attachedObjectsList;
            		for(var i=0;i<surveyObj.sections.length;i++){
            			surveyObj.sections[i].questionsResponded = 0;
            			surveyObj.sections[i].questionsCount = surveyObj.sections[i].questions.length;
            			surveyObj.sections[i].attachments = surveyObj.sections[i].attachedObjectsList;
            			$scope.surveyTotalQuestions += surveyObj.sections[i].questionsCount;
	        			for(var j=0;j<surveyObj.sections[i].questions.length;j++){
	        				seqCount = seqCount + 1;
	        				surveyObj.sections[i].questions[j].attachments = surveyObj.sections[i].questions[j].attachedObjectsList;
	        				surveyObj.sections[i].questions[j].sequentialSecCount = seqCount;
	        				}
    	        		}
            		}
        		}
        	//}

    		function setResponseInScopeFromResponseList() {
    			if ($scope.respondedMap != undefined && $scope.respondedMap.length > 0) {
    				for(var i=0;i<$scope.respondedMap.length;i++){
    					if($scope.question.objectBaseUuid == $scope.respondedMap[i].questionBaseUuid){
    						$scope.response = angular.copy($scope.respondedMap[i]);
    						if($scope.question.questionType == 'multiplechoicemultiresponse'){
    							$scope.response.responseList = $scope.respondedMap[i].responseList;
    						}else{
    							$scope.response.responseList = [];
    						}
        					return;
    					}
    				}
    			}
    			$scope.response={};
        		$scope.response.response='';
        		$scope.response.responseList=[];
        		if($scope.question.defaultSelectedResponse && $scope.question.defaultSelectedResponse != ''){
        			$scope.response.response = $scope.question.defaultSelectedResponse;
        			if($scope.question.questionType == 'multiplechoicemultiresponse'){
            			$scope.response.responseList.push($scope.question.defaultSelectedResponse);
            		}
        		}
        		$scope.response.comments="";
    		}

    	    function saveSurveyQuestionResponse(event){
    	    	if(!$scope.surveyCreationPreviewMode){
	        		var questionString = {
	        				'surveyBaseUuid'  	 : $scope.surveyDetails.objectBaseUuid,
	        				'questionBaseUuid'	 : $scope.question.objectBaseUuid,
	        				'comments'		  	 : $scope.response.comments,
	        				'surveyResponseList' : angular.isUndefined(angular.toJson($scope.response.responseList)) ? "null" : angular.toJson($scope.response.responseList),
	        				'surveyResponse'	 : $scope.response.response,
	        				'userBaseUuid'	  	 : currentUserVO.objectBaseUuid,
	        				'objectBaseUuid'  	 : $scope.question.objectBaseUuid,
	        				'currentSection' 	 : $scope.section
	        		};
	        		if($scope.question.questionType != 'multiplechoicemultiresponse'){
	        			$scope.response.responseList = null;
	        		}
	        		if($scope.question.questionType == 'likertscale'){
	        			$scope.response.response = $scope.response.value;
	        		}
	        		surveysService.isSurveyResponseDirty = false;
	        		homeService.set_current_module_has_unsaved_changes(false, ctrlName);
	        		if($scope.question.questionType == 'openanswer'){
	        			questionString.surveyResponse = encodeURIComponent(questionString.surveyResponse);
	        		}
	    			surveysService.saveSurveyResponseJSON($scope.surveyDetails.objectBaseUuid, questionString.questionBaseUuid, questionString.userBaseUuid, questionString.surveyResponse, questionString.surveyResponseList, questionString.comments).then(function(data){
	    				if(!data.boName)
	    				{
	    					var updateProgressBar = true;
	    					for(var i =0;i<$scope.respondedMap.length;i++){
	    						if($scope.respondedMap[i].questionBaseUuid == questionString.questionBaseUuid){
	    							$scope.respondedMap[i] = data.surveyQuestionResponseList[0];
	    							updateProgressBar = false;
	    							break;
	    						}
	    					}
	    					if(updateProgressBar && data.surveyQuestionResponseList[0].response != ''){
	    						$scope.surveyAnsweredQuestions = $scope.surveyAnsweredQuestions + 1;
	    						$scope.surveyPercentage=($scope.surveyAnsweredQuestions/$scope.surveyTotalQuestions)*100;
	    						$scope.section.questionsResponded = $scope.section.questionsResponded + 1 ;
	    						$scope.secQuesCompleted = ($scope.section.questionsResponded/$scope.section.questionsCount) * 100;
	    						for(var i=0;i<surveysService.getSurvey().sections.length;i++){
	    							if(surveysService.getSurvey().sections[i].objectBaseUuid == $scope.section.objectBaseUuid){
	    								surveysService.getSurvey().sections[i].questionsResponded = $scope.section.questionsResponded;
	    							}
	    						}
	    						$scope.respondedMap.push(data.surveyQuestionResponseList[0]);
	    					}
	    					if($scope.currentQuestionIndex == $scope.surveyDetails.sections[$scope.currentSectionIndex].questions.length){
		            			$scope.showSurveyQuestionDIV = false;
		            			$scope.showSectionDetailsDIV = true;
		            			$scope.showSurveyOverview = false;
		            			if($scope.currentSectionIndex==$scope.surveyDetails.sections.length - 1){
	//	            				$scope.currentSectionIndex = 0;
	//	            				$scope.currentQuestionIndex = 0;
	//	            				$scope.showSectionDetails($scope.surveyDetails.sections[$scope.currentSectionIndex],$scope.surveyPreview, $scope.currentSectionIndex);
	//	            				return;
		            				$scope.finishSectionLater();
		            			}
		            			if($scope.currentSectionIndex<$scope.surveyDetails.sections.length-1){
		            				//If resume section show next section on completion of questions
		            				$scope.currentSectionIndex = $scope.currentSectionIndex+1;
		            				$scope.currentQuestionIndex = 0;
		            				$scope.showSectionDetails($scope.surveyDetails.sections[$scope.currentSectionIndex],$scope.surveyPreview, $scope.currentSectionIndex, event);
		            			}
	    					}
	    					$scope.reponseChangedValue = false;
	    				}
	    			});
	    		}
    	    }

        	//When section is clicked.
        	$scope.showSectionDetails = function(section, surveyPreviewFlag, secIndex, event)
        	{
        		if(event != undefined){
        			event.stopPropagation();
        			event.preventDefault();
        		}
        		if(!$scope.surveyCreationPreviewMode){
            		$scope.showSectionDetailsDIV = false;
            		$scope.showSurveyOverview = false;
            		$scope.showSurveyQuestionDIV =false;
            		surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetails.objectBaseUuid, section.objectBaseUuid,currentUserVO.objectBaseUuid).then(function(data){
            			data.surveyQuestionResponseList.forEach(resp => resp.response = decodeURIComponent(resp.response));
            			if(data.surveyQuestionResponseList && data.surveyQuestionResponseList.length > 0){
            				$scope.respondedMap = data.surveyQuestionResponseList;
        				}
            			$scope.currentSectionIndex = secIndex;
                		$scope.section = section;
                		$scope.surveySectionsAttachmentsSliderOptions.items = section.attachments;
                		$scope.secQuesCompleted = (section.questionsResponded/section.questionsCount) * 100;
                		$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name + ": " + StringUtils.capitaliseFirstLetter(localizedMessages.get("SECTION")) + " " + ($scope.currentSectionIndex + 1) +" "+ localizedMessages.get("OF") + " " + $scope.surveyDetails.sections.length;
        				$scope.showSectionDetailsDIV = true;
                		$scope.showSurveyOverview = false;
                		$scope.showSurveyQuestionDIV =false;
                		$timeout(function(){
                			bvSliderServiceForSectionAttachments.fixQuantity();
                			bvSliderServiceForSectionAttachments.fixButtons();
                		},50)

            		});
        		}else{
        			$scope.currentSectionIndex = secIndex;
            		$scope.section = section;
            		$scope.surveySectionsAttachmentsSliderOptions.items = section.attachments;
            		$scope.secQuesCompleted = (section.questionsResponded/section.questionsCount) * 100;
            		//$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name + ": " + StringUtils.capitaliseFirstLetter(localizedMessages.get("SECTION")) + " " + ($scope.currentSectionIndex + 1) +" "+ localizedMessages.get("OF") + " " + $scope.surveyDetails.sections.length;
    				$scope.showSectionDetailsDIV = true;
            		$scope.showSurveyOverview = false;
            		$scope.showSurveyQuestionDIV =false;
            		$timeout(function(){
            			bvSliderServiceForSectionAttachments.fixQuantity();
            			bvSliderServiceForSectionAttachments.fixButtons();
            		},50)
        		}
        	};
        	//When next is clicked on section details screen for questions
        	$scope.showSectionQuestions = function(section, showQuestionIndex, secIndex, isQuestionClicked, respondMap, event)
        	{
        		if(event != undefined){
        			event.stopPropagation();
        			event.preventDefault();
        		}
        		if(!$scope.surveyCreationPreviewMode){
	        		if(isQuestionClicked){  // When 'Edit Response' for a question is clicked
	        			$scope.currentSectionIndex = secIndex;
	        			if(respondMap){
	        				$scope.respondedMap = respondMap;
	        			}
	        		}
	        		$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name + ": " + StringUtils.capitaliseFirstLetter(localizedMessages.get("SECTION")) + " " + ($scope.currentSectionIndex + 1) +" "+ localizedMessages.get("OF") + " " + $scope.surveyDetails.sections.length;;
	        		$scope.showSurveyQuestionDIV = true;
	        		$scope.showSectionDetailsDIV = false;
	        		$scope.showSurveyOverview = false;
	        		$scope.reponseChangedValue = false;
	        		$scope.section = section;
	        		$scope.question = $scope.section.questions[showQuestionIndex];
	        		if($scope.question.defaultSelectedResponse && $scope.question.defaultSelectedResponse != ''){
	        			$scope.reponseChangedValue = true;
	        		}
	        		$scope.secQuesCompleted = ($scope.section.questionsResponded/$scope.section.questionsCount) * 100;
	        		$scope.surveyQuestionsAttachmentsSliderOptions.items = $scope.question.attachments;
	        		setResponseInScopeFromResponseList(); //If response exists put it in scope
	        	    $scope.currentQuestionIndex = showQuestionIndex;
	        		$timeout(function(){
	        		    if(bvSliderServiceForQuestionAttachments)
	        			    bvSliderServiceForQuestionAttachments.fixButtons();
	        		},50)
        		}else{
	        		//$scope.middleBarObject.title = surveysService.getCurrentSelectedSurveyFromList().name;
	        		$scope.showSurveyQuestionDIV = true;
	        		$scope.showSectionDetailsDIV = false;
	        		$scope.showSurveyOverview = false;
	        		$scope.reponseChangedValue = false;
	        		$scope.section = section;
	        		$scope.question = $scope.section.questions[showQuestionIndex];
	        		//$scope.secQuesCompleted = ($scope.section.questionsResponded/$scope.section.questionsCount) * 100;
	        		$scope.surveyQuestionsAttachmentsSliderOptions.items = $scope.question.attachments;
	        		setResponseInScopeFromResponseList(); //If response exists put it in scope
	        	    $scope.currentQuestionIndex = showQuestionIndex;
	        		$timeout(function(){
	        		    if(bvSliderServiceForQuestionAttachments)
	        			    bvSliderServiceForQuestionAttachments.fixButtons();
	        		},50)
        		}
        	}

    	    $scope.showNextQuestion = function(event) {
        		if(event != undefined){
        			event.stopPropagation();
        			event.preventDefault();
        		}
    	    	if(++$scope.currentQuestionIndex < $scope.section.questions.length)
    	    	{
    	    		if($scope.question.defaultSelectedResponse && $scope.question.defaultSelectedResponse != ''){
	        			$scope.reponseChangedValue = true;
	        		}
    	    		if($scope.reponseChangedValue && !$scope.surveyCreationPreviewMode){ //first save previous question response.
    	    			saveSurveyQuestionResponse(event);
    	    		}
    	    		$scope.question = $scope.section.questions[$scope.currentQuestionIndex];//Next show next question
	        		if($scope.question.defaultSelectedResponse && $scope.question.defaultSelectedResponse != ''){
	        			$scope.reponseChangedValue = true;
	        		}
    	    		$scope.surveyQuestionsAttachmentsSliderOptions.items = $scope.question.attachments;
            		$timeout(function(){
            		    if(bvSliderServiceForQuestionAttachments)
            			    bvSliderServiceForQuestionAttachments.fixButtons();
            		},50)
    	    		setResponseInScopeFromResponseList(); //Update response object with newly answered question
    	    	}
    	    }

    	    $scope.showPreviousQuestion = function(event) {
        		if(event != undefined){
        			event.stopPropagation();
        			event.preventDefault();
        		}
    	    	if(--$scope.currentQuestionIndex < $scope.section.questions.length && $scope.currentQuestionIndex >= 0)
    	    	{
    	    		$scope.question = $scope.section.questions[$scope.currentQuestionIndex];
    	    		$scope.surveyQuestionsAttachmentsSliderOptions.items = $scope.question.attachments;
            		$timeout(function(){
            		    if(bvSliderServiceForQuestionAttachments)
            			    bvSliderServiceForQuestionAttachments.fixButtons();
            		},50)
    	    		setResponseInScopeFromResponseList(); //To get the response of previous question find in responseList
    	    	}
    	    }

    		$scope.showPreviousSectionQuestions = function(event) {
        		if(event != undefined){
        			event.stopPropagation();
        			event.preventDefault();
        		}
    			$scope.currentSectionIndex = $scope.currentSectionIndex - 1;
    			if($scope.currentSectionIndex < 0){
    				$scope.finishSectionLater();
    			} else{
    				if(!$scope.surveyCreationPreviewMode){
	            		surveysService.getSurveySectionResponsesForUserJSON($scope.surveyDetails.objectBaseUuid, $scope.surveyDetails.sections[$scope.currentSectionIndex].objectBaseUuid,currentUserVO.objectBaseUuid).then(function(data){
	            			if(data.surveyQuestionResponseList && data.surveyQuestionResponseList.length > 0){
	            				$scope.respondedMap = data.surveyQuestionResponseList;
	        				}
	        				$scope.currentQuestionIndex = $scope.surveyDetails.sections[$scope.currentSectionIndex].questions.length - 1;
	            			$scope.showSectionQuestions($scope.surveyDetails.sections[$scope.currentSectionIndex], $scope.currentQuestionIndex, $scope.currentSectionIndex,false, event);
	                		});
    				}else{
    					$scope.currentQuestionIndex = $scope.surveyDetails.sections[$scope.currentSectionIndex].questions.length - 1;
            			$scope.showSectionQuestions($scope.surveyDetails.sections[$scope.currentSectionIndex], $scope.currentQuestionIndex, $scope.currentSectionIndex,false, event);
    				}
    			}
    		};

    		$scope.completeQuestions = function(event) {
        		if(event != undefined){
        			event.stopPropagation();
        			event.preventDefault();
        		}
    			if($scope.question.defaultSelectedResponse && $scope.question.defaultSelectedResponse != ''){
        			$scope.reponseChangedValue = true;
        		}
    			if($scope.reponseChangedValue && !$scope.surveyCreationPreviewMode){
    				$scope.currentQuestionIndex = $scope.currentQuestionIndex + 1;
    				//if(!$scope.surveyCreationPreviewMode){
    					saveSurveyQuestionResponse(event);
    				//}
    	    	}else
    	    	{
        			if($scope.currentSectionIndex == $scope.surveyDetails.sections.length - 1){
        				$scope.showSurveyQuestionDIV = false;
            			$scope.showSectionDetailsDIV = false;
            			$scope.showSurveyOverview = true;
                		$timeout(function(){
            				bvSliderService.fixButtons();
            				bvSliderServiceForAttachments.fixButtons();
                		},50);
        			}
        			if($scope.currentSectionIndex < $scope.surveyDetails.sections.length - 1){
        				$scope.showSurveyQuestionDIV = false;
            			$scope.showSectionDetailsDIV = true;
            			$scope.showSurveyOverview = false;
        				//If resume section show next section on completion of questions
        				$scope.currentSectionIndex = $scope.currentSectionIndex + 1;
        				$scope.showSectionDetails($scope.surveyDetails.sections[$scope.currentSectionIndex],$scope.surveyCreationPreviewMode, $scope.currentSectionIndex, event);
                		$timeout(function(){
                			bvSliderServiceForSectionAttachments.fixButtons();
                		},50)
        			}
    	    	}
    		}

    	    $scope.finishSectionLater = function() {
    	    	$scope.showSurveyOverview=true;
    	    	$scope.showSectionDetailsDIV = false;
    	    	$scope.showSurveyQuestionDIV = false;
        		$timeout(function(){
    				bvSliderService.fixButtons();
    				bvSliderServiceForAttachments.fixButtons();
        		},50);
    			};

    		$scope.saveAndFinishSectionLater = function() {
    			if(!$scope.surveyCreationPreviewMode){
    				if($scope.reponseChangedValue){
    					saveSurveyQuestionResponse();
    				}
    			}
	    	    	$scope.showSurveyOverview=true;
	    	    	$scope.showSectionDetailsDIV = false;
	    	    	$scope.showSurveyQuestionDIV = false;
        		$timeout(function(){
    				bvSliderService.fixButtons();
    				bvSliderServiceForAttachments.fixButtons();
        		},50);
    		}

    	    $scope.finishQuestionLater = function() {
    	    	if(!$scope.surveyCreationPreviewMode){
	    	    	$scope.showSurveyQuestionDIV = false;
	    			$scope.showSectionDetailsDIV = true;
	    			$scope.showSurveyOverview = false;
		    		saveSurveyQuestionResponse();
		    		$state.go('home.surveys');
    	    		}
    			};

    		$scope.goBackToSurveysListFromSection = function(){
    			if(!$scope.surveyCreationPreviewMode){
    				$state.go('home.surveys');
    			}
    		}
        	if(surveysService.getDetailsForSurveyPreview() != null){
        		var previewDetails = {};
        		angular.copy(surveysService.getDetailsForSurveyPreview(), previewDetails);
        		$scope.surveyDetails = previewDetails.surveyDetailsForPreview;
        		$scope.respondedMap = previewDetails.respondedMap;
        		surveysService.setDetailsForSurveyPreview(null);
        		//setSurveyPercentageAndCommitStatus($scope.surveyDetails);
        		surveysService.setSurvey($scope.surveyDetails);
        		$scope.showSectionQuestions(previewDetails.sectionForSummary, previewDetails.questionIndex, previewDetails.sectionIndex, true);
        	}
        	if($scope.surveyDetails){
        		setSurveyPercentageAndCommitStatus($scope.surveyDetails);
        	}

        	$scope.getDocumentThumbnail = function(attachment) {
           		if (attachment.documentThumbnail != undefined)
           		{
           			return "data:image/png;base64," + attachment.documentThumbnail;
           		}
           		else
           		{
           			return imageService.getImageByExtension(attachment.extensionType);
           		}
        	}

        	$scope.repondBtnClicked = function(){
	        		var	activeSection=0;
	        		$scope.showSectionDetails($scope.surveyDetails.sections[activeSection], $scope.surveyCreationPreviewMode, activeSection);
        	};
        	 $scope.openAddCommentsDialog = function() {
        	        bvDialog.showAddComments(saveComments, $scope.response.comments, 600);
        	    };
        	 var saveComments = function(comments) {
        		if(!$scope.surveyCreationPreviewMode){
        			$scope.response.comments = comments;
        	        $scope.reponseChangedValue = true;
        	        homeService.set_current_module_has_unsaved_changes(true, ctrlName);
        	        surveysService.isSurveyResponseDirty = true;
        		}
        	    };

        	$scope.commitSurvey = function(){
        		if(!$scope.surveyCreationPreviewMode){
            		homeService.set_current_module_has_unsaved_changes(false, ctrlName);
                    surveysService.isSurveyResponseDirty = false;
	        		var allQuestionsResponded = true;
	        		var allMandatoryCommentsAdded = true;
	        		$scope.userResponses = [];
	        		$scope.respondedMapForCommit = {};
	        		surveysService.setQuestionBaseUuidList($scope.surveyDetails);
	        		surveysService.getSurveyResponseForUserJSON($scope.surveyDetails.objectBaseUuid, angular.toJson(surveysService.getQuestionBaseUuidList()), currentUserVO.objectBaseUuid).then(function(data){
	        		if(data.surveyQuestionResponseList && data.surveyQuestionResponseList.length > 0){
	        			$scope.userResponses = data.surveyQuestionResponseList;
	        			for(var i=0;i<$scope.userResponses.length;i++){
	        				$scope.respondedMapForCommit[$scope.userResponses[i].questionBaseUuid] = $scope.userResponses[i];
	        			}
	        		}
	        		for(var i=0;i<$scope.surveyDetails.sections.length;i++){
	        			for(var j=0;j<$scope.surveyDetails.sections[i].questions.length;j++){
	        				if($scope.surveyDetails.sections[i].questions[j].isResponseMandatory == true){
	        					if($scope.respondedMapForCommit[$scope.surveyDetails.sections[i].questions[j].objectBaseUuid] == undefined){
	        						allQuestionsResponded = false;
	        						break;
	        					}
	        				}
	        				if($scope.surveyDetails.sections[i].questions[j].areCommentsMandatory == true){
	        					if($scope.respondedMapForCommit[$scope.surveyDetails.sections[i].questions[j].objectBaseUuid] == undefined ){
	        						allMandatoryCommentsAdded = false;
	        						break;
	        					}
	        					if($scope.respondedMapForCommit[$scope.surveyDetails.sections[i].questions[j].objectBaseUuid] && $scope.respondedMapForCommit[$scope.surveyDetails.sections[i].questions[j].objectBaseUuid].comments == ''){
	        						allMandatoryCommentsAdded = false;
	        						break;
	        					}
	        				}
	        			}
	        		}
	        		if(allQuestionsResponded == false){
	        			bvDialog.showMessage(localizedMessages.get("PLEASE_RESPOND_TO_ALL_MANDATORY_QUESTIONS_BEFORE_COMMITING_SURVEY"));
	        			return;
	        		}
	        		if(allMandatoryCommentsAdded == false){
	        			bvDialog.showMessage(localizedMessages.get("PLEASE_RESPOND_TO_ALL_MANDATORY_COMMENTS_BEFORE_COMMITING_SURVEY"));
	        			return;
	        		}
	        		if($scope.surveyDetails.isSignatureRequired == true || $scope.surveyDetails.isSignatureRequired == "true"){
	        			bvDialog.showSignatureDialog($scope.surveyDetails);
	        		}
	        		else{
	        			$scope.submitSurvey();
	        		}
	        		});
        		}
        	}

        	$scope.cancelSignature  = function(){
        		$scope.signatureModal.dismiss('cancel');
        	}

        	$scope.submitSurvey = function() {
        		if(!$scope.surveyCreationPreviewMode){
            		surveysService.submitSurveyResponseJSON(
            				$scope.surveyDetails.objectBaseUuid, $scope.surveyDetails.isSignatureRequired).then(
            				function(data) {
            					if(data.boName == "SuccessBO"){
            						$state.go('home.surveys');
            					}
            				});
        		}
        	}

//        	if(surveysService.getDetailsForSurveyPreview() != null){
//        		var previewDetails = {};
//        		angular.copy(surveysService.getDetailsForSurveyPreview(), previewDetails);
//        		$scope.surveyDetails = previewDetails.surveyDetailsForPreview;
//        		$scope.respondedMap = previewDetails.respondedMap;
//        		surveysService.setDetailsForSurveyPreview(null);
//        		//setSurveyPercentageAndCommitStatus($scope.surveyDetails);
//        		surveysService.setSurvey($scope.surveyDetails);
//        		$scope.showSectionQuestions(previewDetails.sectionForSummary, previewDetails.questionIndex, previewDetails.sectionIndex, true);
//        	}
        }]
    }
}