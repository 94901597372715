EditProfileController.$inject = ['teamService', '$scope', '$modalInstance', '$modal','localizedMessages','constants','bvDialog', '$timeout','context', 'templatesService'];

function EditProfileController(teamService, $scope, $modalInstance, modal, localizedMessages, constants, bvDialog, $timeout, context, templatesService) {

	//console.log($scope.selectedUser);
	$scope.popupTitle = localizedMessages.get('EDIT_PROFILE');
	$scope.licenseTypes = [];
	$scope.onlyNumbers = /^\d+$/;
	$scope.mobileNumberPattern = /^\d{10}$/;
	$scope.firstNameMaxLength = 250;
	$scope.lastNameMaxLength = 250;
	$scope.loginIDMaxLength = 50;
	$scope.phoneMaxLength = 16;
	$scope.closeIcon = templatesService.getUrl('closeBtn');

	$scope.emailRegEx = constants.get('EMAIL_REGEX');
	$scope.phoneRegEx = constants.get('PHONE_REGEX');
	$scope.nameRegEx = constants.get('NAME_REGEX');
	$scope.commonValueRegEx = constants.get('COMMON_VALUE_REGEX');
	$scope.commaValueRegEx = constants.get('COMMA_VALUE_REGEX');
	$scope.specialCharacterValueRegEx = constants.get('SPECIAL_CHARACTERS_VALUE_REGEX');

	$scope.SHOW_EDIT_PHOTO = true;
	if(MobileUtil.isApp())
	{
		$scope.SHOW_EDIT_PHOTO = false;
	}

	$scope.profileFieldsLabel = {
			licenseType: localizedMessages.get('LICENSE_TYPE'),
       		firstName: localizedMessages.get('FIRST_NAME'),
       		lastName: localizedMessages.get('LAST_NAME'),
       		salutation: localizedMessages.get('SALUTATION'),
       		countryCode: localizedMessages.get('COUNTRY_CODE'),
       		country: localizedMessages.get('COUNTRY'),
       		workPhone: localizedMessages.get('WORK_PHONE'),
       		mobilePhone: localizedMessages.get('MOBILE_PHONE'),
       		homePhone: localizedMessages.get('HOME_PHONE'),
       		primaryEmail: localizedMessages.get('PRIMARY_EMAIL'),
       		bio: localizedMessages.get('BIO'),
       		secondaryEmails: localizedMessages.get("SECONDARY_EMAILS"),
       		jobTitle: localizedMessages.get('JOB_TITLE'),
       		company: localizedMessages.get('COMPANY'),
       		loginID: localizedMessages.get('LOGIN_ID'),
       		editPhoto: localizedMessages.get('EDIT_PHOTO'),
       		language: localizedMessages.get('LANGUAGE')
       };

	$scope.cancelLabel = localizedMessages.get('CANCEL');
	$scope.okLabel = localizedMessages.get('OK');
	$scope.newCustomFieldName = localizedMessages.get('TYPE_CUSTOM_FIELD_NAME');
	$scope.useComma = localizedMessages.get('USE_COMMA_SEPARATE_EMAILS');
	$scope.addCustom = localizedMessages.get('ADD_CUSTOM');
	$scope.requiredFieldsLabel = localizedMessages.get('INDICATES_REQUIRED_FIELD');

	$scope.languages = context.getModel().localeList;


	$scope.countries = {};

	$scope.profile = angular.copy($scope.selectedUser);
	for(var i in $scope.languages)
	{
		if($scope.profile.localeType == $scope.languages[i].name)
		{
			$scope.profile.localeType = $scope.languages[i];
		}
	}
	if($scope.profile.bio == null || $scope.profile.bio == undefined || $scope.profile.bio == 'null')
	{
		$scope.profile.bio = '';
	}

	$scope.close = function(result) {
		$modalInstance.dismiss('cancel');
	};

	$scope.bioEditorOptions = {
       language: 'en',
       height: '191px',
       resize_enabled: false,
       toolbarLocation: 'bottom',
       toolbarCanCollapse:false,
       toolbarStartupExpanded:true,
       removePlugins: 'elementspath',
       toolbar : [
	                  // { name: 'document', 		items: [ 'NewPage', 'Preview','Templates' ] },
	                  // { name: 'clipboard', 		items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
	                  { name: 'basicstyles', 	items: [ 'Bold', 'Italic', 'Underline' ] },
	                  // { name: 'insert', 		items: [ 'Image', 'Table', 'HorizontalRule','Source' ] },
	                  // { name: 'insert', 		items: [ 'Save']},

                  ]
    };

	$scope.cancelConfirmation = function() {
		if($scope.frmEditProfile.$dirty == true || $scope.croppedImage != "") {
			bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
		} else {
			$scope.cancelEdit();
		}
	}
	

	$scope.cancelEdit = function(result) {
		$scope.close();
	}

	$scope.validateEmails = function(emails) {
		var delimiter = ',';
		var filter  = constants.get("EMAIL_REGEX");
		var error = false;
		var aEmails = emails.split(delimiter);

		for(var index = 0; index < aEmails.length; index++) {
			aEmails[index] = (aEmails[index].replace(/^\s+/,'')).replace(/\s+$/,'');
			if(aEmails[index] != '' && aEmails[index].search(filter) == -1)
				error = true;
		}
		if(error == true ) {
			$scope.frmEditProfile.additionalEmails.$setValidity("emails",false);
		} else {
			$scope.frmEditProfile.additionalEmails.$setValidity("emails",true);
		}
	}

	$scope.saveProfile = function(result) {

		if($scope.frmEditProfile.$error.required) {
			bvDialog.showMessage(localizedMessages.get('COMPLETE_REQUIRED_FILEDS'));
			// ngDialog.open({
			// 	template: '<div style="text-align:center">'+ localizedMessages.get('COMPLETE_REQUIRED_FILEDS') + '</div>',
			// 	plain: true
			// });
			return;
		}

		for(var i in $scope.newCustomFields) {
			if($scope.newCustomFields[i].name == "" && $scope.newCustomFields[i].value != "") {
				bvDialog.showMessage(localizedMessages.get('COMPLETE_REQUIRED_FILEDS'));
				return;
			}
		}

		if(!$scope.frmEditProfile.$valid) {
			if(!($scope.profile.mobilePhone == '') && $scope.frmEditProfile.editProfileMobilePhone.$error.pattern){
				bvDialog.showMessage(localizedMessages.get("INVALID_MOBILE_NUMBER"));
			}
			else if(!($scope.profile.workPhone == '') && $scope.frmEditProfile.editProfileWorkPhone.$error.pattern){
				bvDialog.showMessage(localizedMessages.get("INVALID_MOBILE_NUMBER"));
			}
			else if($scope.frmEditProfile.editProfileEmail.$error.pattern){
				bvDialog.showMessage(localizedMessages.get("INVALID_EMAIL"));
			}
			else{
				bvDialog.showMessage(localizedMessages.get('INVALID_DATA_FOUND'));
			}
			// ngDialog.open({
			// 	template: '<div style="text-align:center">'+ localizedMessages.get('INVALID_DATA_FOUND') + '</div>',
			// 	plain: true
			// });
			return;
		}

		var editedProfile = angular.copy($scope.profile);

		if(editedProfile.mobilePhoneCountryName != undefined)
		{
			editedProfile.mobilePhoneCountryName = editedProfile.mobilePhoneCountryName.name
		}


		// Below fields might have & which needs to be encoded otherwise JSON parsing fails on server.
		var bio = '';
		if(editedProfile.bio != undefined && editedProfile.bio != null) {
			bio = editedProfile.bio;
		}
		editedProfile.bio = '';
		editedProfile.localeType = editedProfile.localeType.value;
		editedProfile.customFields = editedProfile.customFields || [];

		for(var i in $scope.newCustomFields) {
			if($scope.newCustomFields[i].name != "") {
				editedProfile.customFields.push($scope.newCustomFields[i]);
			}
		}

		$scope.saveProfilePhoto();

		var jsonString = angular.toJson(editedProfile);
		teamService.editProfile(jsonString , bio).then(function(data) {
			console.log(data);
			if(data == true ) {
				bvDialog.showMessage(localizedMessages.get('PROFILE_CHANGES_SAVED'), null, true);
				// ngDialog.open({
				// 	template: '<div style="text-align:center">'+ localizedMessages.get('PROFILE_CHANGES_SAVED') + '</div>',
				// 	plain: true
				// });
				$modalInstance.close('save');
			} else {
				bvDialog.showMessage(localizedMessages.get('PROFILE_CHANGES_SAVE_FAILED'));
				// ngDialog.open({
				// 	template: '<div style="text-align:center"> '+ localizedMessages.get('PROFILE_CHANGES_SAVE_FAILED') + '</div>',
				// 	plain: true
				// });
			}
		})
	}
	
	teamService.getUserProfileConfigurationsJSON().then(function(data) {
		
		if(!StringUtils.isNullEmpty(data))
		{
			$scope.firstNameMaxLength = data.FIRST_NAME_MAX_LENGTH;
			$scope.lastNameMaxLength = data.LAST_NAME_MAX_LENGTH;
			$scope.loginIDMaxLength = data.LOGIN_ID_MAX_LENGTH;
		}		
	})

	teamService.getCountryNameValueListJSON()
	.then(function(data)
	{
		data.splice(0,1);
		$scope.countries = data;
		for (var i in $scope.countries) {
			if ($scope.countries[i].name == $scope.profile.mobilePhoneCountryName) {
				$scope.profile.mobilePhoneCountryName = $scope.countries[i];
				break;
			}
		}

	})


	teamService.getLicenseTypeDetails().then(function(data) {
		$scope.licenseTypes = data;
	})


	$scope.countryChanged = function(country)
	{
		$scope.profile.mobilePhoneCountryName = country;
		if($scope.profile.mobilePhoneCountryName!= "")
			$scope.profile.mobilePhoneCountryCode = "+" + $scope.profile.mobilePhoneCountryName.value;
		else
			$scope.profile.mobilePhoneCountryCode = "";
	}

	$scope.newCustomFields = [];
	$scope.addNewCustomField = function() {
		$scope.newCustomFields.push({"name": "", "value": ""});

		$timeout(function(){
			var fieldsMain = angular.element(document.querySelector('.fieldsMain'));
			fieldsMain[0].scrollTop = fieldsMain[0].scrollHeight;
		}, 30);

	}

	$scope.removeCustomField = function(customField) {
		var i =	$scope.profile.customFields.indexOf(customField);
		$scope.profile.customFields.splice(i,1);
	};

	$scope.removeNewCustomField = function(customField) {
		var i =	$scope.newCustomFields.indexOf(customField);
		$scope.newCustomFields.splice(i,1);
	};

    $scope.croppedImage = "";
	$scope.editPhoto = function() {
		var bvModal = new modal.open({
			windowClass: 'editPhotoDialog',
			templateUrl: 'modules/team/EditPhoto.tpl.html',
			scope: $scope,
			controller: 'EditPhotoController',
			backdrop: 'static'
		});

		bvModal.result.then(function(croppedImage){
			$scope.croppedImage = croppedImage;
		});
	}

	$scope.saveProfilePhoto = function() {
		teamService.updateUserProfileImage($scope.croppedImage, "medium")
		.then(function(data) {
			teamService.getUserProfileImage($scope.profile.objectBaseUuid, $scope.profile.objectVersionUuid).
			then(function(data)
			{
				if(data != '' && data.profileImage != undefined && data.profileImage != '')
				{
					homeController.userImages[data.objectBaseUuid]=data.profileImage;
				}
			})
		});
	}

	$scope.status = { languagesOpen : false };

	$scope.toggleDropdown = function($event) {
    	$event.preventDefault();
   		$event.stopPropagation();
    	$scope.status.languagesOpen = !$scope.status.languagesOpen;
  	};

	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
	$scope.getUserThumbnail = homeController.getUserThumbnail;
	$scope.userImages =  homeController.userImages;

//	$scope.editSignature = function() {
//		var bvModal = new modal.open({
//            windowClass : 	'editProfileDialog',
//            templateUrl:  	'modules/team/CreateSignature.tpl.html',
//            scope:      	$scope,
//            controller: 	'CreateSignatureController',
//		});
//	}

}

angular
    .module('editProfile', [ 'team.service','createSignature','localization.service','editPhoto','constants.service','context.service'])
    .controller('EditProfileController', EditProfileController);
