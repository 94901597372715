function spinner(){
  return {
    restrict: 'E',
    //replace: true,
    template: function(){
    	var spinnerTemplate =
	   		 '<div ng-show="loadingSpinner.val" id="loading-spinner">'
	   		+'	<div class="spinner-icon"></div>'
	   		+'</div>';

    	return spinnerTemplate;
    },
    controller: 'loadingSpinnerController'
  }
}