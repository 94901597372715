dayviewService.$inject = ['calendarService'];

function dayviewService(calendarService){

    var dayview = {}
    var m = StringUtils.momentL;

    var Dayview = function(inputDate){
        var date = inputDate || new Date();

        this.dayInfo = m(date).format('LL');
        this.day = m(date).format('ll');
        this.currentTime = this.updateCurrentTime();
        this.currentDay = m(date).format('ll') == m().format('ll');
        this.isoDate = m(date).toISOString();
        this.interval;
    }

    Dayview.prototype = {
        constructor: Dayview,
        startInterval: function(sec){
            var sec = sec || 60;
            var self = this;
            self.interval = setInterval(function(){
                self.updateCurrentTime();
            },sec * 1000);
        },
        stopInterval: function(){
            clearInterval(this.interval);
        },
        updateCurrentTime: function(){
             this.currentTime = m('01/01/0000 ' + m().format('hh:mm A')).unix() - m('01/01/0000 ' + m().hour('00').minute('00').format('hh:mm A')).unix();
             return this.currentTime;
        },
        getMomentDate: function(){
            return m(this.isoDate);
        }
    }

    this.createDay = function(date){
        dayview = new Dayview(date);
        return dayview;
    }

    this.getCurrentDayview = function(){
        return dayview;
    }

    this.combineEvents = function(events){
        if(events && events.length && events.length > 1){
            for(var i = 0 ; i < events.length ; i++){
                for(var j = 0 ; j < events.length ; j++){
                    if(events[j].objectBaseUuid != events[i].objectBaseUuid){
                        if((events[j].startTimeUnix >= events[i].startTimeUnix && events[j].startTimeUnix < events[i].endTimeUnix) || (events[j].endTimeUnix > events[i].startTimeUnix && events[j].endTimeUnix <= events[i].endTimeUnix)){
                            if(events[j].combined.indexOf(events[i].objectBaseUuid) == -1){
                                events[j].combined.push(events[i].objectBaseUuid)
                            }
                            if(events[i].combined.indexOf(events[j].objectBaseUuid) == -1){
                                events[i].combined.push(events[j].objectBaseUuid)
                            }
                        }
                    }
                }
            }
        }

        return events;
    }

    this.getMoreDataFoDayEvents = function(events){
        _.each(events, function(event){
            calendarService.loadGuestandResponseForEvent(event.objectBaseUuid).then(function(data){
                if(data.data)
                    event.description = data.data.description;
                    event.allDay = data.data.allDay;
            });
        });
    }
}