function bvSplitter(){
	return {
		restrict: 'A',
		scope: {
			panes : '@bvSplitter'
		},
		link: {
            pre: function(scope, $element) {
            	$element[0].style.opacity = 0
            }
        },
		controller: 'bvSplitterController'
	}
}