angular
	.module('dialogControllers.service')
	.controller('followDialogController', followDialogController)
    .directive('itemsFollowedList', itemSubscribedListing)
    .directive('userFollowedList', userFollowedListing)
    .directive('userFollowedMeList', userFolloweMeListing);

followDialogController.$inject = ['$scope', 'localizedMessages', 'context', 'homeService', 'bvDialog', 'repositoryService', 'teamService', 'repositoryModelService'];

function followDialogController($scope, localizedMessages, context, homeService, bvDialog, repositoryService, teamService, repositoryModelService){

    $scope.popupTitle 								    = localizedMessages.get('FOLLOW_FOLLOWING_ME');
	$scope.itemsFollowed 							    = localizedMessages.get('ITEMS_FOLLOWED');
	$scope.usersFollowed 								= localizedMessages.get('USERS_FOLLOWED');
	$scope.itemsFollowingMe 							= localizedMessages.get("ITEMS_FOLLOWING_ME");
    $scope.notificationSettings 						= localizedMessages.get("NOTIFICATION_SETTINGS");

    $scope.docNotify 								    = localizedMessages.get('DOCUMENT_NOTIY');
	$scope.docDelete 							        = localizedMessages.get('DOCUMENT_DEL');
	$scope.docEdit 								        = localizedMessages.get('DOCUMENT_EDIT');
	$scope.docReplaced 							        = localizedMessages.get("DOCUMENT_REPLACED");
    $scope.docMoved 						            = localizedMessages.get("DOCUMENT_MOVED");
    $scope.docRuleAtt 								    = localizedMessages.get('DOCUMENT_RULE_ATT');
    //$scope.trash 							            = localizedMessages.get("DOCUMENT_TRASH");
    $scope.trashRestore 						        = localizedMessages.get("DOCUMENT_TRASH_RESTORE");
    $scope.checkedOut								    = localizedMessages.get('DOCUMENT_CHECKED_OUT');
    $scope.docReplace 							        = localizedMessages.get('DOCUMENT_REPLACED');

	$scope.folderNotify 							    = localizedMessages.get("FOLDER_NOTIY");
    $scope.folderNew 						            = localizedMessages.get("FOLDER_NEW");
    $scope.uploadMoveNotify 						    = localizedMessages.get("FOLDER_UPLOAD_MOVE");
    $scope.folderUpload 					            = localizedMessages.get("FOLDER_UPLOAD");
    $scope.folderMoved      						    = localizedMessages.get("FOLDER_MOVE");
    $scope.folderDel 						            = localizedMessages.get("FOLDER_DEL");
    $scope.folderRuleAtt 						        = localizedMessages.get("FOLDER_RULE_ATT");

    $scope.userNotify 							        = localizedMessages.get("USER_NOTIFY");
    $scope.userFollowsDoc    						    = localizedMessages.get("USER_FOLLOWS_DOCUMENT");
    $scope.userEditDoc      						    = localizedMessages.get("USER_DOCUMENT_EDIT");
        
    $scope.tabs = [ {
        title : $scope.itemsFollowed,
        content : 'itemsFollowed',
        active: true,
        order: 0
        },
    {
        title : $scope.usersFollowed,
        content : 'usersFollowed',
        order : 1
        },
    {
        title : $scope.itemsFollowingMe,
        content : 'itemsFollowingMe',
        order : 2
    },
    {
        title : $scope.notificationSettings,
        content : 'notificationSettings',
        order : 3
        }
    ];

     $scope.isFolder = function(docID){
            return StringUtils.isFolder(docID);
        }
    $scope.documentNotificationAttrs = [{
        label: $scope.docEdit,
        data: 'edit',
        flag: false
    },
    {
        label: $scope.docDelete,
        data: 'delete',
        flag: false
    },
    {
        label: $scope.docMoved,
        data: 'move',
        flag: false
    },
    {
        label: $scope.docReplace,
        data: 'replace',
        flag: false
    },
    {
        label: $scope.docRuleAtt,
        data: 'rule',
        flag: false
    },
    {
        label: $scope.trashRestore,
        data: 'restoretrash',
        flag: false
    },
    {
        label: $scope.checkedOut,
        data: 'checkout',
        flag: false
    }];

    $scope.folderNotificationAttrs = [{
        label: $scope.folderNew,
        data: 'folderNew',
        flag: false
    },{
        label: $scope.folderUpload,
        data: 'folderUpload',
        flag: false
    },{
        label: $scope.folderMoved,
        data: 'folderMove',
        flag: false
    },
    {
        label: $scope.folderDel,
        data: 'folderDelete',
        flag: false
    },
    {
        label: $scope.folderRuleAtt,
        data: 'folderRule',
        flag: false
    }
    ];

    $scope.userNotificationAttrs = [{
        label: $scope.userFollowsDoc,
        data: 'userfollowdoc',
        flag: false
    },
    {
        label: $scope.userEditDoc,
        data: 'usereditdoc',
        flag: false
    }
    ];

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    $scope.subscriptionType = {};
    $scope.bvModalControllerInputs.closeButton = function(){
        if(!angular.equals($scope.subscriptionType, {}))
             saveSubscriptionTypes();
        bvModal.close();
    }

    var saveSubscriptionTypes = function(){
         var fileType = $scope.subscriptionType.fileType;
         if(fileType !=undefined) {
             for (var i = 0; i < $scope.documentNotificationAttrs.length; i++) {
                 var subType = $scope.documentNotificationAttrs[i];
                 switch (subType.data) {
                     case "edit":
                         fileType.edit = subType.flag;
                         break;
                     case "delete":
                         fileType.delete = subType.flag;
                         break;
                     case "move":
                         fileType.move = subType.flag;
                         break;
                     case "rule":
                         fileType.rule = subType.flag;
                         break;
                     // case "trash":
                     //     fileType.trash = subType.flag;
                     //     break;
                     case "restoretrash":
                         fileType.restoretrash = subType.flag;
                         break;
                     case "checkout":
                         fileType.checkout = subType.flag;
                         break;
                     case "replace":
                         fileType.replace = subType.flag;
                         break;
                 }
             }
         }
        var folderType = $scope.subscriptionType.folderType;
         if(folderType!=undefined) {
             for (var i = 0; i < $scope.folderNotificationAttrs.length; i++) {
                 var folderSubType = $scope.folderNotificationAttrs[i];
                 switch (folderSubType.data) {
                     case "folderMove":
                         folderType.folderMove = folderSubType.flag;
                         break;
                     case "folderDelete":
                         folderType.folderDelete = folderSubType.flag;
                         break;
                     case "folderRule":
                         folderType.folderRule = folderSubType.flag;
                         break;
                     case "folderNew":
                         folderType.folderNew = folderSubType.flag;
                         break;
                     case "folderUpload":
                         folderType.folderUpload = folderSubType.flag;
                         break;
                 }
             }
         }

        var userType = $scope.subscriptionType.userType;
        if(userType!=undefined) {
            for (var i = 0; i < $scope.userNotificationAttrs.length; i++) {
                var userSubType = $scope.userNotificationAttrs[i];
                switch (userSubType.data) {
                    case "userfollowdoc":
                        userType.userfollow = userSubType.flag;
                        break;
                    case "usereditdoc":
                        userType.useredit = userSubType.flag;
                        break;
                }
            }
        }
            var subscriptionTypeJson = angular.toJson($scope.subscriptionType);
            repositoryService.saveSubscriptionTypes(subscriptionTypeJson).then(function(){
                console.log('completed updating subscription type');
            });;

    }

    $scope.loadNotificationSettings = function(){
        if(!angular.equals($scope.subscriptionType, {}))
            return;
        repositoryService.getActiveSubscriptionList().then(function(data){
                 $scope.subscriptionType = data.data;
                var fileType = $scope.subscriptionType.fileType;
                var folderType = $scope.subscriptionType.folderType;
                var userType = $scope.subscriptionType.userType;
                for(var i=0; i<$scope.documentNotificationAttrs.length; i++){
                    var subType = $scope.documentNotificationAttrs[i];
                    switch(subType.data) {
                        case "edit":
                            subType.flag = fileType.edit;
                            break;
                        case "delete":
                            subType.flag = fileType.delete;
                            break;
                        case "move":
                            subType.flag = fileType.move;
                            break;
                        case "rule":
                            subType.flag = fileType.rule;
                            break;
                        // case "trash":
                        //     subType.flag = fileType.trash;
                        //     break;
                        case "restoretrash":
                            subType.flag = fileType.restoretrash;
                            break;
                        case "checkout":
                            subType.flag = fileType.checkout;
                            break;
                        case "replace":
                            subType.flag = fileType.replace;
                            break;
                }
            }

            for(var i=0; i<$scope.folderNotificationAttrs.length; i++){
                    var folderSubType = $scope.folderNotificationAttrs[i];
                    switch(folderSubType.data) {
                        case "folderMove":
                            folderSubType.flag = folderType.folderMove;
                            break;
                        case "folderDelete":
                            folderSubType.flag = folderType.folderDelete;
                            break;
                        case "folderRule":
                            folderSubType.flag = folderType.folderRule;
                            break;
                        case "folderNew":
                            folderSubType.flag = folderType.folderNew;
                            break;
                        case "folderUpload":
                             folderSubType.flag = folderType.folderUpload;
                            break;
                    }
            }

            for(var i=0; i<$scope.userNotificationAttrs.length; i++){
            var userSubType = $scope.userNotificationAttrs[i];
            switch(userSubType.data) {
                        case "userfollowdoc":
                             userSubType.flag = userType.userfollow;
                            break;
                        case "usereditdoc":
                             userSubType.flag = userType.useredit;
                            break;
                }
            }
        });
    }

    $scope.loadNotificationSettings();

    $scope.removeItemsFromItemList = function(index, watchedObjects){
        var objectuuids = [];
            if(watchedObjects instanceof Array){
                for(var i=0; i<watchedObjects.length; i++) {
                    objectuuids.push(watchedObjects[i].objectBaseUuid);
                }
            } else{
                objectuuids.push(watchedObjects.objectBaseUuid);
            }
        var objectuuidsjson = angular.toJson(objectuuids)
        repositoryService.unsubscribe(objectuuidsjson).then(function(){
             $scope.itemFollowedList.splice(index, 1);
             var repositoryModel = repositoryModelService.getInstance('DOCUMENTS');
             var replist = repositoryModel.repositoryList();
             if(replist){
                 var browseList = replist.browseList;
                 var unsubscribedObjectUuid =  watchedObjects.objectBaseUuid;
                 for(var ind=0; ind< browseList.length; ind++){
                    var obj = browseList[ind];
                    if(obj.objectBaseUuid === unsubscribedObjectUuid){
                        obj.isSubscribed = false;
                    }
                 }
             }
        });
    }

    $scope.loadItemSubscribed = function() {
    	repositoryService.getSubscriptions().then(function(data){
            $scope.itemFollowedList = [];
    		var subscriptions = data.data;
    		for(var index=0; index<subscriptions.length; index++) {
                var itemFollowed = {};
                itemFollowed.name = subscriptions[index].watchedObject.name;
                itemFollowed.date = subscriptions[index].dateAdded;
                itemFollowed.addedBy = subscriptions[index].subscriber.name;
                itemFollowed.watchedObject = subscriptions[index].watchedObject;
    			$scope.itemFollowedList.push(itemFollowed);
    		}
    	});
    }

    $scope.removeUserFromUserList = function(index, watchedObjects){
        var objectuuids = [];
            if(watchedObjects instanceof Array){
                for(var i=0; i<watchedObjects.length; i++) {
                    objectuuids.push(watchedObjects[i].objectBaseUuid);
                }
            } else{
                objectuuids.push(watchedObjects.objectBaseUuid);
            }
        var objectuuidsjson = angular.toJson(objectuuids)
        repositoryService.unsubscribe(objectuuidsjson).then(function(){
             $scope.usersFollowedList.splice(index, 1);
        });
    }
   
   $scope.loadUsersFollowed = function() {
    	repositoryService.usersFollowed().then(function(data){
            $scope.usersFollowedList = [];
    		var subscriptions = data.data;
    		for(var index=0; index<subscriptions.length; index++) {
                var userFollowed = {};
                userFollowed.name = subscriptions[index].watchedObject.name;
                userFollowed.date = subscriptions[index].dateAdded;
                userFollowed.watchedObject = subscriptions[index].watchedObject;
                teamService.getUserProfileImage(subscriptions[index].watchedObject.objectBaseUuid, subscriptions[index].watchedObject.objectVersionUuid).
			        then(function(data)
			            {
				            if(data != '' && data.profileImage != undefined && data.profileImage != '')
				            {
					            userFollowed.userImage="data:image/png;base64," + data.profileImage;
				            }else{
                                userFollowed.userImage=undefined;
                            }
			            });
    			        $scope.usersFollowedList.push(userFollowed);
    		}
    	});
    }

    $scope.removeUserFromUserFollowMeList = function(index, subscribers){
        var objectuuids = [];
            if(subscribers instanceof Array){
                for(var i=0; i<subscribers.length; i++) {
                    objectuuids.push(subscribers[i].objectBaseUuid);
                }
            } else{
                objectuuids.push(subscribers.objectBaseUuid);
            }
        var objectuuidsjson = angular.toJson(objectuuids)
        repositoryService.unsubscribeFollowMeUsers(objectuuidsjson).then(function(){
             $scope.usersFollowMeList.splice(index, 1);
        });
    }

    $scope.loadUsersFollowMe = function() {
    	repositoryService.loadUsersFollowingMe().then(function(data){
            $scope.usersFollowMeList = [];
    		var subscriptions = data.data;
    		for(var index=0; index<subscriptions.length; index++) {
                var userFollowMe = {};
                userFollowMe.name = subscriptions[index].subscriber.name;
                userFollowMe.date = subscriptions[index].dateAdded;
                userFollowMe.subscriber = subscriptions[index].subscriber;
                teamService.getUserProfileImage(subscriptions[index].subscriber.objectBaseUuid, subscriptions[index].subscriber.objectVersionUuid).
			        then(function(data)
			            {
				            if(data != '' && data.profileImage != undefined && data.profileImage != '')
				            {
					            userFollowMe.userImage="data:image/png;base64," + data.profileImage;
				            }else{
                                userFollowMe.userImage=undefined;
                            }
			            });
    			        $scope.usersFollowMeList.push(userFollowMe);
    		}
    	});
    }

    // var loadUsersFollowed = function(){
    //     repositoryService.usersFollowed().then(function(data){
    //         var loadUsersFollowed = data.data;
    // 		for(var index=0; index<loadUsersFollowed.length; index++) {
    // 			$scope.itemFollowedList.push(loadUsersFollowed[index].subscriber);
    // 		}
    //     });
    // }

    // $scope.selectedTab = function(tab){
    //     tab.
    // }

    $scope.topRepositoryPanel = {
            templateUrl: 'modules/dialogs/followers/toppanel/followtoppanel.template.html',
            events : [
                { name: 'name',         value : localizedMessages.get('NAME_UPPERCASE')},
                { name: 'type',          value : localizedMessages.get('TYPE_UPPERCASE')},
                { name: 'addedBy',          value : localizedMessages.get('ADDED_BY_FOLLOW')},
                { name: 'date',          value : localizedMessages.get('DATE_ADDED')},
                { name: 'nameClk',      value : function(){ $scope.selectMenuItem({ value: "name" }) }},
                { name: 'dateClk',    value : function(){ $scope.selectMenuItem({ value: "progress" })}},
                { name: 'tabs',         value : $scope.tabs}
            ]
        }

    // homeService.getSubscriptions().then(function(data)
    //     {
    //         if(data)
    //             $scope.subscriptions = data;
    //     }
    // );
}

function itemSubscribedListing(){
    return{
        restrict: 'A',
        templateUrl: 'modules/home/ItemsFollowedList.tpl.html'
    }
}

function userFollowedListing(){
    return{
        restrict: 'A',
        templateUrl: 'modules/home/userFollowedList.tpl.html'
    }
}

function userFolloweMeListing(){
    return{
        restrict: 'A',
        templateUrl: 'modules/home/userFolloweMeList.tpl.html'
    }
}