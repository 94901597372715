function UserProfileController($scope, localizedMessages, context, teamService, bvDialog, bvDialogService,modal)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;

	$scope.selectedUser = $scope.bvModalControllerInputs.selectedUser;
	$scope.popupTitle = $scope.selectedUser.displayName;

	var currentUserVO = context.getModel().userVO;
	$scope.currentUserBaseUuid = currentUserVO.objectBaseUuid;
	$scope.bioLbl = localizedMessages.get("BIO");
	
	$scope.showEditBtns = true;
	if(MobileUtil.isApp() && MobileUtil.isJupOffline())
	{
		$scope.showEditBtns = false;
	}
	
	$scope.profileFields = [
		{displayField: localizedMessages.get('SALUTATION'), valueField: 'title'},
		{displayField: localizedMessages.get('JOB_TITLE'), valueField: 'jobTitle'},
		{displayField: localizedMessages.get('COMPANY'), valueField: 'companyName'},
		{displayField: localizedMessages.get('COUNTRY'), valueField: 'mobilePhoneCountryName'},
		{displayField: localizedMessages.get('COUNTRY_CODE'), valueField: 'mobilePhoneCountryCode'},
		{displayField: localizedMessages.get('WORK_PHONE'), valueField: 'workPhone'},
		{displayField: localizedMessages.get('MOBILE_PHONE'), valueField: 'mobilePhone'},
		{displayField: localizedMessages.get('PRIMARY_EMAIL'), valueField: 'email'},
		{displayField: localizedMessages.get('SECONDARY_EMAILS'), valueField: 'additionalEmailAddresses'},
		{displayField: localizedMessages.get('LANGUAGE'), valueField: 'localeType'}		
	];

	$scope.signatureLbl = localizedMessages.get("SIGNATURE");
	$scope.editSignature = function()
	{
		var editSignatureModal = new modal.open({
            windowClass : 	'editSignatureDialog',
            templateUrl :  	'modules/team/CreateSignature.tpl.html',
            scope :      	$scope,
            controller : 	'CreateSignatureController',
            backdrop: 'static',
            keyboard: false
		});
	}

	$scope.editProfileLbl = localizedMessages.get("EDIT_PROFILE");
	$scope.editProfile = function()
	{
		$scope.enableEdit = !$scope.enableEdit;

		var editProfileModal = new modal.open({
            windowClass : 	'editProfileDialog',
            templateUrl :  	'modules/team/EditProfile.tpl.html',
            scope :      	$scope,
            controller : 	'EditProfileController',
            backdrop :      'static',
            keyboard: false
		});

		editProfileModal.result.then(function(data)
		{
			teamService.getUserDataBybaseUuid($scope.selectedUser.objectBaseUuid).
			then(function(data)
			{
				$scope.selectedUser = data;
				$scope.languages = context.getModel().localeList;
				for(var i in $scope.languages)
				{
					if($scope.selectedUser.localeType == $scope.languages[i].value) 
					{
						$scope.selectedUser.localeType = $scope.languages[i].name;
					}
				}
			})
		});
	}
	
	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
	$scope.getUserThumbnail = homeController.getUserThumbnail;
	$scope.userImages =  homeController.userImages;

	$scope.followUnfollow = $scope.bvModalControllerInputs.followUnFollow;
	$scope.showFollowButton = true;
	if($scope.selectedUser.objectBaseUuid === currentUserVO.objectBaseUuid){
		$scope.showFollowButton=false;
	}
	$scope.isFollowFollowMeEnabled  = function(){
		return context.getModel().followFollowMeEnabled;
	}
	$scope.followUnfollowClick = function(){
		console.log($scope.selectedUser);
		var selectedUserUuid = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu($scope.selectedUser.objectBaseUuid);
		if($scope.followUnfollow){
			teamService.unSubscribeUser(selectedUserUuid).
			then(function(data){
				$scope.followUnfollow = !$scope.followUnfollow;
			});
		}else{
			teamService.subscribeUser(selectedUserUuid).
			then(function(data){
				$scope.followUnfollow = !$scope.followUnfollow;
			});
		}   
	}
}

angular
	.module('UserProfileController.controller', [])
	.controller('UserProfileController', ['$scope','localizedMessages','context','teamService','bvDialog', 'bvDialogService','$modal', UserProfileController]);