function RecycleController($scope, repositoryService, localizedMessages, $state, SortUtility, repositoryModelService, loadRecycle, bvDialog) 
{	
		var bvModal 		= $scope.bvModalControllerInputs.bvModal;
		var actionButton 	= $scope.bvModalControllerInputs.buttons[0];
		
		$scope.popupTitle 	= localizedMessages.get('TRASH');
		actionButton.name 	= localizedMessages.get('EMPTY_TRASH');
		
		actionButton.action = function(){
			bvDialog.confirm(localizedMessages.get('RECYCLE_BIN_ALERT_ARE_YOU_SURE_TO_DELETE'), function(){
				repositoryService.emptyTrash().then(function(data){	
					loadRecycle.loadRecyleData();
	        	});
				bvModal.close();
			});
		}
		
		loadRecycle.loadRecyleData();
		$scope.parentBaseUuid = "recycle";
		$scope.hideCheckBox = true;
		$scope.hideBy = true;
		
		$scope.dragStartHandler = function(event, element)
		{
			//var selectedObjects =  getSelectedItemsBaseUuid();
			var selectedItem = event.currentTarget;
			
			var documentsToJSONMove  = [];
			
			var selectedObjects = [selectedItem.id];
			documentsToJSONMove = angular.toJson(selectedObjects);
			
			var dragDropObject = new Object();
			dragDropObject.dragInitiator = "recycle";
			dragDropObject.allDraggedItems = documentsToJSONMove;
			
			event.dataTransfer.setData('Text', angular.toJson(dragDropObject));
		}

		$scope.tabs = [];
    	$scope.tabEditable = true;
    	$scope.showTree = false;
    	
    	$scope.titles = [
    	                 	localizedMessages.get("TYPE"),
    	                 	localizedMessages.get("NAME"),
    	                 	localizedMessages.get("LAST_MODIFIED_ON")
    	                ]
    	
    	$scope.scrollListOptions = {
//    		filter: { 
//    					show : true,
//    					title : localizedMessages.get("FILTER")
//    		},
    		columns : [
	    		           	{ title : localizedMessages.get("TYPE"), width : 17, name : 'type' },
	    		           	{ title : localizedMessages.get("NAME"), width : 53, name : 'name' },
	    		           	{ title : localizedMessages.get("LAST_MODIFIED_ON") , width : 30, name : 'lastMod' }
    		],
    		data : loadRecycle.getRecyleData(),
    		showColumns : true
    	}
}

function LoadRecycle(repositoryService, repositoryModelService, localizedMessages, context)
{
	var objectData = {}
	
	var service = {
		loadRecyleData : function(){
			repositoryService.loadRecycleBin().then(function(data)
	    	{	
				for(var i = 0 ; i < data.data.length ; i++){
					data.data[i].type = localizedMessages.get(StringUtils.getTypeByObjectBaseUuid(data.data[i].objectBaseUuid).toUpperCase());
	    			data.data[i].lastMod = moment(data.data[i].lastModifiedOnGMTMillis).format("lll");
	    			delete data.data[i].extensionType;
	    		}
				var repositoryModel = repositoryModelService.getInstance("TRASH");
				repositoryModel.repositoryList(data.data);
	    	});
		},
		loadRecyleDataPromise : function(){
			return repositoryService.loadRecycleBin();
		},
		loadRecyleDataByPortalUuid : function(){
			var portalBaseUuid = context.getModel().portalVO.objectBaseUuid;
			repositoryService.loadRecycleBin().then(function(data)
	    	{	
				var list = []
				for(var i = 0 ; i < data.data.length ; i++){
					data.data[i].type = localizedMessages.get(StringUtils.getTypeByObjectBaseUuid(data.data[i].objectBaseUuid).toUpperCase());
	    			data.data[i].lastMod = moment(data.data[i].lastModified).format("lll");
	    			if(portalBaseUuid == data.data[i].portalBaseUuid){
	    				list.push(data.data[i])
	    			}
	    		}
	    		 
				objectData.listOfDocuments = list;
	    	});
		},
		getRecyleData : function(){
			return objectData;
		}
	}
		
	return service;
}

angular
	.module('recycle',[])
	.service('loadRecycle', ['repositoryService','repositoryModelService', 'localizedMessages', 'context',LoadRecycle])
	.controller('RecycleController', ['$scope','repositoryService', 'localizedMessages','$state','SortUtility','repositoryModelService','loadRecycle', 'bvDialog', RecycleController]);
	