function input($timeout, $compile){
	return {
    	restrict: 'E',
        link: function ( scope, element, attrs ) {
        	if(attrs.placeholder && !attrs.placeholderIeDisabled && StringUtils.getBrowser() == 'MSIE'){
        		var input = element[0];
        		var parent = element[0].parentElement;
        		var newLabel;
        		var template;

        		if(input.getAttribute('ng-show')){
        			template = '<label ng-show="' + (input.getAttribute('ng-show') || '') + '"></label>';
        			newLabel = $compile(template)(element.scope())[0];
        		}
        		else if(input.getAttribute('ng-hide')){
        			template = '<label ng-hide="' + (input.getAttribute('ng-hide') || '') + '"></label>';
        			newLabel = $compile(template)(element.scope())[0];
        		}
        		else if(input.getAttribute('ng-if')){
        			template = '<label ng-if="' + (input.getAttribute('ng-if') || '') + '"></label>';
        			newLabel = $compile(template)(element.scope())[0];
        		}
        		else{
        			newLabel = document.createElement('label')
        		}

        		var newSpan = document.createElement('span');

        		newSpan.innerHTML = attrs.placeholder;
        		
        		newLabel.classList.add('bv-placeholder-label');
        		newLabel.appendChild(newSpan);
        		parent.insertBefore(newLabel, input.nextSibling);
        		newLabel.appendChild(input);

        		scope.$watch(attrs.ngModel, function(data){
        			if(data){
        				newSpan.style.display = 'none';
        				newSpan.style.top = '0px';
        			}
        			else{
        				newSpan.style.display = 'inline';
        				newSpan.style.top = '0px';
        			}
        		});
        		$timeout(function(){
        			input.removeAttribute('placeholder');
        		},0);
        	}
        }
    };
}