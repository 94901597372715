function bvSortingPanel(){
	return {
		restrict: 'A',
		transclude: true,
		scope: {
			data: '=bvSortingPanel'
		},
		link: function bvSortingPanelLink(scope, element, attrs, ctrl, transclude){
            transclude(scope, function(clone, scope) {
                element.append(clone);
            });
        },
		controller: 'bvSortingPanelController'
	}
}