angular
    .module('dialogControllers.service')
    .controller('createFileFromBOController', CreateFileFromBOController);   

CreateFileFromBOController.$inject = ['$scope', 'localizedMessages', 'repositoryService'];

function CreateFileFromBOController($scope, localizedMessages, repositoryService)
{
	$scope.actionBtnTxt	= localizedMessages.get("OK");
	$scope.closeBtnTxt 	= localizedMessages.get("CANCEL");
	$scope.popupTitle = localizedMessages.get('CREATE_NEW_FILE');
	
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	
	var businessObject = $scope.bvModalControllerInputs.businessObject;
	
	var nameStr = businessObject.name;
    var index = nameStr.indexOf(".");
    
    if(index != -1)
	{
    	var date = new Date();    	
    	var formatDate = moment(date).format('MM-DD-YYYY');
    	
    	nameStr = nameStr.substr(0, index) + " version "+ businessObject.versionNumber + " " + formatDate;		
	}
	
	$scope.addFileName = {};
	
	$scope.addFileName.val = nameStr;
	
	$scope.action = function() {
		
		var newName = $scope.addFileName.val;
    	var oldExtension = businessObject.extensionType;
    	           	
    	var validName = StringUtils.validDocumentExtension(newName, oldExtension);
    		
    	repositoryService.createNewFile(businessObject.objectBaseUuid, businessObject.objectVersionUuid, validName).then(function(data)
    	{
    		$scope.listOfVersionTracker = data.data;
    		$scope.bvModalControllerInputs.selectionDone();
    		bvModal.close();
    	});
    	
    	$scope.close = function() {
    		$scope.bvModalControllerInputs.selectionDone();
    	    bvModal.close();
    	};
    };
}