SearchResultsController.$inject = ['homeService', '$scope', '$modalInstance', '$modal','localizedMessages','imageService', 'repositoryService', 'repositoryModelService','documentContextMenu', 'bvDialogService', '$filter', 'templatesService'];

function SearchResultsController(homeService, $scope, $modalInstance, modal, localizedMessages, imageService, repositoryService, repositoryModelService, documentContextMenu, bvDialogService, $filter, templatesService) {

	$scope.popupTitle = localizedMessages.get('SEARCH_RESULTS');
	$scope.searchLable = localizedMessages.get("SEARCH");
	$scope.searchResultForTS = localizedMessages.get("SEARCH_RESULTS_FOR_TS");

	$scope.typeColumn = localizedMessages.get("TYPE")
	$scope.nameColumn = localizedMessages.get("NAME");
	$scope.lastModifiedOnColumn = localizedMessages.get("LAST_MODIFIED_ON");
	$scope.closeIcon = templatesService.getUrl('closeBtn');
	$scope.emptyMsg=localizedMessages.get("SEARCH_RESULTS_EMPTY");

	//$scope.searchResultsEmpty;


	$scope.handleRowClick = function(searchResult)
	{
		var object = searchResult.searchItem;
    	var objectBaseUuid = object.objectBaseUuid;

    	if (StringUtils.isFolder(objectBaseUuid))
    	{
    		$modalInstance.dismiss('cancel');
    		bvDialogService.openFolderView(object);
    	}
    	else
    	{
    		$modalInstance.dismiss('cancel');
    		repositoryService.openPdfViewer(object);
    	}
	};

	$scope.getSearchItems = function()
	{
		homeService.getAllSearchList($scope.searchStringIn)
		.then(function(data)
		{
			$scope.searchResults = angular.copy(data);
			if($scope.searchResults.length>0)
			{
				$scope.searchResultsEmpty=false;
			}
			else
			{
				$scope.searchResultsEmpty=true;
			}
		});
	}

	$scope.getItemType = function(item)
	{
		var objectBaseUuid = item.objectBaseUuid;

		if(StringUtils.isFolder(objectBaseUuid))
		{
			return localizedMessages.get("FOLDER");
		}
		else if(StringUtils.isDocument(objectBaseUuid))
		{
			return localizedMessages.get("DOCUMENT")
		}
		else if(StringUtils.isBoardbook(objectBaseUuid))
		{
			return localizedMessages.get("BOARDBOOK");
		}
		else if(StringUtils.isCollate(objectBaseUuid))
		{
			return localizedMessages.get("COLLATE");
		}
		else
		{
			return '';
		}
	}

	$scope.close = function(result)
	{
		$modalInstance.dismiss('cancel');
	};

	$scope.getLocalizedDate = function(date) {
       return moment($filter('date')(date, 'medium')).format('lll');
    }
}