angular
    .module('dialogControllers.service')
	.controller('manageSurveysController', manageSurveysController);

manageSurveysController.$inject =  [ '$scope', '$filter', '$state', 'localizedMessages', 'commonService','context', 'homeService','bvDialog', 'bvDialogService','teamService', 'surveysService','surveysAlertService', 'companyUserService','$sce'];

function manageSurveysController($scope,$filter, $state, localizedMessages, commonService, context, homeService,bvDialog, bvDialogService, teamService, surveysService, surveysAlertService, companyUserService, $sce){
	$scope.popupTitle = localizedMessages.get("MANAGE_SURVEYS");
	var currentUserVO = context.getModel().userVO;
	$scope.CURRENT_OPTION = "UI_ADD_RESPONDENTS";
	var bvModal;
	$scope.surveysToPublish = [];
	$scope.toAddRespondentsSurveyList = [];
	$scope.removeUsersList = [];
	bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.surveys = $scope.bvModalControllerInputs.surveysList;
	$scope.SURVEYS_LIST_PUBLISH = [];
	$scope.SURVEYS_LIST_ADD_RESPONDENTS = angular.copy($scope.surveys);
	$scope.SURVEYS_LIST_REMOVE_RESPONDENTS = angular.copy($scope.surveys);
	$scope.bvchecktrue 		= 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvcheckfalse 	= 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
	$scope.SHOW_CUSTOM_USERS = "SHOW_CUSTOM_USERS";
	$scope.ui = {
			label : {
				publishUnpublish : localizedMessages.get("PUBLISH"),
				addRespondents : localizedMessages.get("ADD_RESPONDENTS"),
				removeRespondents : localizedMessages.get("REMOVE_RESPONDENTS"),
				dueOn : localizedMessages.get("DUE_ON"),
				btnCancel : localizedMessages.get('CANCEL'),
				btnSave : localizedMessages.get('SAVE'),
				publish : localizedMessages.get('PUBLISH')
			}
	};
	$scope.getShortLocalizedDate = function(date) {
		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
}
	$scope.scrollbarConfig = {
			autoHideScrollbar : true
		}
	$scope.cancelChanges = function(){
		bvModal.close();
	}
	$scope.exitManageSurveys = function(){
		bvModal.close();
	}
	for(var i=0;i<$scope.surveys.length;i++){
		if(!$scope.surveys[i].isPublished && $scope.surveys[i].participantBaseUuidAndResponseList.length > 0)
			$scope.SURVEYS_LIST_PUBLISH.push($scope.surveys[i]);
	}
	
	$scope.validateChangesForPublish = function(){
		return _.isEqual($scope.surveysListToPublish_, $scope.surveysListToPublish );
	}
	$scope.validateChangesForAddRespondents = function(){
		return (_.isEqual($scope.toAddRespondentsSurveyList_, $scope.toAddRespondentsSurveyList) && _.isEqual($scope.toAddParticipantsList_, $scope.toAddParticipantsList));
	}
	$scope.validateChangesForRemoveRespondents = function(){
		if(!$scope.toRemoveRespondentsSurvey){
			return true;
		}
		else{
			var toRemoveCopy = angular.copy($scope.toRemoveRespondentsSurvey);
			var toRemoveCopyParticipants = angular.copy($scope.toRemoveParticipantsList);
			delete toRemoveCopy.$$hashKey;
			delete toRemoveCopyParticipants.$$hashKey;
			return (_.isEqual($scope.toRemoveRespondentsSurvey_ ,toRemoveCopy) && _.isEqual($scope.toRemoveParticipantsList_,toRemoveCopyParticipants));
		}
	}
	var proceedToNextTab = function(){
		$scope.CURRENT_OPTION = $scope.TEMP_TAB_VAL;
	}
	$scope.changeTab = function (tabVal)
	{
		var hasAnythingChanged = false;
		$scope.TEMP_TAB_VAL = tabVal;
		switch($scope.CURRENT_OPTION)
		{
			case 'UI_PUBLISH_UNPUBLISH' 		: 	hasAnythingChanged = $scope.validateChangesForPublish(); 
													break;
			case 'UI_ADD_RESPONDENTS' 			: 	hasAnythingChanged = $scope.validateChangesForAddRespondents(); 
													break;
			case 'UI_REMOVE_RESPONDENTS'		: 	hasAnythingChanged = $scope.validateChangesForRemoveRespondents();
													break;
		}
		if(!hasAnythingChanged)
		{
			bvDialog.confirm(localizedMessages.get('LEAVE_MODULE_WITH_UNSAVED_CHANGES_CONFIRM_MESSAGE'), proceedToNextTab);
		}
		else
		{
			proceedToNextTab();
		}
	}
	$scope.surveysListToPublish = [];
	$scope.surveysListToPublish_ = angular.copy($scope.surveysListToPublish);
	$scope.bvcheckTrueOrFalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.publishSurveyCheck = function(survey){
		survey.isPublished = !survey.isPublished;
		if(survey.isPublished){
			$scope.surveysListToPublish.push(survey.objectBaseUuid);
		}
		else{
			if($scope.surveysListToPublish.length > 0){
				if($scope.surveysListToPublish.indexOf(survey.objectBaseUuid) != -1){
					$scope.surveysListToPublish.splice($scope.surveysListToPublish.indexOf(survey.objectBaseUuid), 1);
				}
			}
		}
	}
	var goToThisSurvey = function(){
		surveysService.getSurveyDetails($scope.currentSurvey.objectBaseUuid).then(function(data) {
			surveysService.setSurvey(data);
			if(!$scope.currentSurvey.isPublished){
				bvModal.close();
				surveysService.setIsSurveyPublished(false);
			    $state.go('home.createSurvey');
			}else{
				surveysService.setCurrentSelectedSurveyFromList(data)
				bvDialog.surveyAdminPreview($scope.currentSurvey);
			}
		});
	}
	$scope.goToThisSurvey = function(survey){
		$scope.currentSurvey = survey;
		bvDialog.confirm(localizedMessages.get("LEAVE_MODULE_WITH_UNSAVED_CHANGES_CONFIRM_MESSAGE"), goToThisSurvey);
	}
	$scope.toAddParticipantsList = [];
	$scope.toAddRespondentsSurveyList_ = angular.copy($scope.toAddRespondentsSurveyList);
	$scope.toAddParticipantsList_ = angular.copy($scope.toAddParticipantsList);
	$scope.toRemoveRespondentsSurvey;
	//$scope.toRemoveRespondentsSurvey.selected = true;
	//$scope.toRemoveParticipantsList = {};
	
	$scope.toRemoveParticipantsList = surveysService.toRemoveParticipantsList();
	//surveysService.toRemoveParticipantsList($scope.toRemoveRespondentsSurvey.participantBaseUuidAndResponseList);
	
	//$scope.toRemoveParticipantsList.list = $scope.SURVEYS_LIST_REMOVE_RESPONDENTS[0].participantBaseUuidAndResponseList;
	$scope.toRemoveRespondentsSurvey_ = angular.copy($scope.toRemoveRespondentsSurvey);
	$scope.toRemoveParticipantsList_ = angular.copy($scope.toRemoveParticipantsList);
	
	$scope.changeSurveyOptionSelectionToAddResp = function(survey){
		survey.selected = !survey.selected;
		if(survey.selected){
			$scope.toAddRespondentsSurveyList.push(survey.objectBaseUuid);
		}else{
			if($scope.toAddRespondentsSurveyList.length > 0){
				if($scope.toAddRespondentsSurveyList.indexOf(survey.objectBaseUuid) != -1){
					$scope.toAddRespondentsSurveyList.splice($scope.toAddRespondentsSurveyList.indexOf(survey.objectBaseUuid), 1);
				}
			}
		}
	}
	$scope.changeSurveyOptionSelectionToRemoveResp = function(survey){
		if($scope.toRemoveRespondentsSurvey){
			$scope.toRemoveRespondentsSurvey.selected = false;
			$scope.removeUsersList = [];
		}
		survey.selected = !survey.selected;
		if(survey.selected){
			$scope.toRemoveRespondentsSurvey = survey;
			//$scope.toRemoveParticipantsList = survey.participantBaseUuidAndResponseList;
			surveysService.toRemoveParticipantsList(angular.copy(survey.participantBaseUuidAndResponseList));
		}else{
			$scope.toRemoveParticipantsList = [];
			$scope.toRemoveRespondentsSurvey = undefined;
		}
	}
	//$scope.toAddParticipantsList = [];
	$scope.addUserInAttendeesList = function(user){
		$scope.toAddParticipantsList.push(user);
	}
	$scope.addGroupUsersInAttendeesList = function(groupId){
		var users = companyUserService.getGroupUsers(groupId);
		if(users && users.length> 0)
		{
			for(var k in users){
				$scope.addUserInAttendeesList(users[k]);
			}
		}
//		if(saveFromAttendees) {
//			saveAttendees();
//		}
	};
	$scope.setSelectedUsers = function(selectedItems)
	{
		$scope.selectedItemsList = selectedItems;
		if($scope.selectedItemsList.length == 0){
			$scope.toAddParticipantsList = [];
		}
		if($scope.selectedItemsList != undefined && $scope.selectedItemsList.length > 0)
		{
			for(var i in $scope.selectedItemsList) {

				if($scope.selectedItemsList[i].type == 'TeamSpaceGroup'){
					$scope.addGroupUsersInAttendeesList($scope.selectedItemsList[i].objectBaseUuid);
				}
				else if($scope.selectedItemsList[i].type == 'Group'){
					$scope.addGroupUsersInAttendeesList($scope.selectedItemsList[i].objectBaseUuid);
				}
				else if($scope.selectedItemsList[i].type == 'Portal'){
					$scope.addGroupUsersInAttendeesList($scope.selectedItemsList[i].objectBaseUuid);
				}
				else{
					$scope.addUserInAttendeesList($scope.selectedItemsList[i]);
				}
			}
		}
		//$scope.toAddParticipantsList = selectedItems;
	};
	$scope.removeUsersList = [];
	$scope.setRemovedUsers = function(selectedItem)
	{
		$scope.removeUsersList.push(selectedItem);
//		for(var i=0; i < $scope.toRemoveParticipantsList.list.length; i++)
//		{
//		   	if($scope.toRemoveParticipantsList.list[i].objectBaseUuid == selectedItem.objectBaseUuid)
//			{
//				$scope.toRemoveParticipantsList.list.splice(i,1);
//				//console.log("LENGTH AFTER:",$scope.EVENTS_FOR_REMOVE_ATTND[i].USERS.length);
//				break;
//			}
//		}
	}
	var savePublish = function(){
		surveysService.publishSurveys(angular.toJson($scope.surveysListToPublish)).then(function(data){
			if(data.boName == "SuccessBO"){
				$scope.exitManageSurveys();
			}
		});
	}
	var saveAddRespondents = function(){
		var listUuid = [];
		for(var i=0;i<$scope.toAddParticipantsList.length;i++){
			listUuid.push($scope.toAddParticipantsList[i].objectBaseUuid);
		}
		if(listUuid.length > 0){
			surveysService.addParticipantsToSurveys(angular.toJson($scope.toAddRespondentsSurveyList), angular.toJson(listUuid)).then(function(data){
				if(data.boName == "SuccessBO"){
					$scope.exitManageSurveys();
				}
			});
		}
	}
	
	var saveRemoveRespondents = function(){
		var listUuid = [];
		for(var i=0;i<$scope.removeUsersList.length;i++){
			listUuid.push($scope.removeUsersList[i].objectBaseUuid);
		}
		surveysService.deleteSurveyParticipants($scope.toRemoveRespondentsSurvey.objectBaseUuid, angular.toJson(listUuid)).then(function(data){
			if(data.boName == "SuccessBO"){
				$scope.exitManageSurveys();
			}
		});
	}
	
	$scope.save = function(){
		if($scope.CURRENT_OPTION == 'UI_PUBLISH_UNPUBLISH'){
			savePublish();
		}
		else if($scope.CURRENT_OPTION == 'UI_ADD_RESPONDENTS' && $scope.toAddRespondentsSurveyList.length > 0 && $scope.toAddParticipantsList.length > 0){
			saveAddRespondents();
		}
		else if($scope.CURRENT_OPTION == 'UI_REMOVE_RESPONDENTS' && $scope.toRemoveRespondentsSurvey){
			if($scope.toRemoveParticipantsList.list.length > 0){
				saveRemoveRespondents();
			}
			else{
				bvDialog.showMessage(localizedMessages.get('MINIMIM_RESPONDANTS'));
				return;
			}
		}
		else{
			$scope.exitManageSurveys();
		}
	}
}