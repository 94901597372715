function validateService(){

    var Validate = function(maxlength, msg){
        this.maxlength = maxlength || 0;
        this.trigger = false;
        this.msg = msg || '';
        this.disabled = false;
    }

    Validate.prototype = {
        construction: Validate,
        setValidate: function(msg, trigger, disabled){
            this.trigger = trigger;
            this.msg = msg;
            this.disabled = disabled;
        }
    }

    this.createValidate = function(maxlength, msg){
        return new Validate(maxlength, msg);
    }

}