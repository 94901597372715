function RegisterStatusCategory()
{
	var service =
	{
		DOCUMENT : "DOCUMENT",
		FOLDER : "FOLDER",
		REMINDER : "REMINDER",
		CONFERENCE : "CONFERENCE",
		CALENDAR : "CALENDAR",
		// SECURE_CHAT : "SECURE_CHAT",
		SHARED_WITH_ME : "SHARED_WITH_ME",
		VAULT_SYNC : "VAULT_SYNC",
		SIGNATURE_COMMIT : "SIGNATURE_COMMIT",
		NEW_ITEMS : "NEW_ITEMS",
		OPEN_APPROVAL : "OPEN_APPROVAL",
		CLOSE_APPROVAL : "CLOSE_APPROVAL",
		REOPEN_SURVEY : "REOPEN_SURVEY",
		CLOSE_SURVEY : "CLOSE_SURVEY",
		COMMIT_SURVEY : "COMMIT_SURVEY",
		PUSH_CREATED : "PUSH_CREATED",
		COMPANY_MERGE_STATUS : "COMPANY_MERGE_STATUS",
		MEETX_DESKTOP_SYNC : "MEETX_DESKTOP_SYNC",
		MEETING_EDIT : "MEETING_EDIT"
	}

	return service;
}