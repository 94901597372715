angular
    .module('dialogControllers.service')
	.controller('showMeetingDetailsController', showMeetingDetailsController);

showMeetingDetailsController.$inject = ['$scope', 'context', 'localizedMessages', 'calendarService'];

function showMeetingDetailsController($scope, context, localizedMessages, calendarService)
{
	$scope.SELECTED_EVENT = $scope.bvModalControllerInputs.selectedEvent;
	$scope.popupTitle               =   localizedMessages.get('DETAILS');
	$scope.UI_DIAL_IN_TXT		    = 	localizedMessages.get('DIAL_IN');
	$scope.UI_NOTES_TXT 		    =   localizedMessages.get('NOTES');
	$scope.UI_DESCRIPTION_TXT  	    =   localizedMessages.get('DESCRIPTION');
	$scope.UI_CONFERENCE_TXT  	    =   localizedMessages.get('CONFERENCE');
	$scope.UI_CODE_TXT  		    =   localizedMessages.get('CODE');
	$scope.UI_LOCATION_TXT  	    =   localizedMessages.get('LOCATION');
	$scope.UI_MEETING_ROOM_TXT      =   localizedMessages.get('MEETING_ROOM');
	$scope.UI_ADDRESS_TXT  		    =   localizedMessages.get('ADDRESS');
	$scope.UI_ATTENDANCE_STAUTS_TXT =   localizedMessages.get('ATTENDANCE_STATUS');
	$scope.UI_ACCEPT_TXT  		    =   localizedMessages.get('ACCEPT');
	$scope.UI_DECLINE_TXT  		    =   localizedMessages.get('DECLINE');
	$scope.UI_TENTATIVE_TXT  	    =   localizedMessages.get('TENTATIVE');

	$scope.init = function(guests) 
	{
		//console.log("GUESTS:",guests);
		var userId = context.getModel().userVO.objectBaseUuid;
		if(guests!=undefined && guests!=null && guests.length >0)
		{
			for(var i in guests)
			{
				if(userId == guests[i].objectBaseUuid)
				{
					$scope.CURRENT_USER = guests[i];
					break;
				}
			}
		}
	}
	
	var confGuests = $scope.SELECTED_EVENT.guests;
	$scope.SELECTED_EVENT.description = decodeURIComponent($scope.SELECTED_EVENT.description)
	$scope.init(confGuests);

	$scope.submitEventResponse = function(userResponse)
	{
    	var objectVersionUuid = $scope.SELECTED_EVENT.objectVersionUuid;
    	var belongsToCalendarUuid = $scope.SELECTED_EVENT.belongsToCalendarUuid;
    	var eventResponse = userResponse.toUpperCase(); 
    	calendarService.setCalendarResponse(objectVersionUuid, belongsToCalendarUuid, eventResponse).then(function(data)
	    {
			if(data && data.data && data.data.guests)
			{
				var eventResponseList = data.data.eventResponseList;
				var userId = context.getModel().userVO.objectBaseUuid;
				var guests = data.data.guests;
				for(var i in guests)
				{
					for(var k in eventResponseList)
					{
						if(guests[i].objectBaseUuid == eventResponseList[k].createdByBaseUuid)
						{
							guests[i].response = eventResponseList[k].response.toLowerCase();
						}
						if(userId == guests[i].objectBaseUuid)
						{
							$scope.CURRENT_USER = guests[i];
						}
					}
				}
				
				var confController = angular.element('#bv-conference-tpl').scope();
				
				if(StringUtils.isNullEmpty(confController))
				{
					confController = angular.element('.bv-mobile-meeting-details').scope();
				}
				
				confController.SELECTED_EVENT.guests = guests;
				confController.SELECTED_EVENT.objectVersionUuid = data.data.objectVersionUuid;
				calendarService.getAllCalendarEvents(true);
				var homeController = angular.element(document.querySelector('[home-controler]')).scope();
		   	 	if(homeController)
		   	 		homeController.getMeetingsCount();
			}
	    });
    }

    $scope.close = function() {
   		var bvModal = $scope.bvModalControllerInputs.bvModal;
    	bvModal.close();
		// refreshing running conf list - may have changed response for running
		var homeController = angular.element(document.querySelector('[home-controler]')).scope();
       	if(homeController) homeController.handleRefreshConferencePolling();
    }
}