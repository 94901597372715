bvSplitterPaneController.$inject = ['$scope', '$element', '$attrs', '$timeout', 'bvSplitterService'];

function bvSplitterPaneController($scope, $el, $attrs, $timeout, bvSplitterService){
    $timeout(function(){
        bvSplitterService.pane.forEach(function(item, i){
            if(item.name == $attrs.bvSplitterPane.replace(/ /g, '')) bvSplitterService.pane.splice(i,1);
        });
        bvSplitterService.pane.push({
            name: $attrs.bvSplitterPane.replace(/ /g, ''),
            el: $el[0],
            rule: $attrs.bvSplitterRule,
            min: $attrs.bvSplitterMinWidth ? parseInt($attrs.bvSplitterMinWidth) : 150,
            left: $el[0].getBoundingClientRect().left,
            right: $el[0].getBoundingClientRect().right,
        });
    });
}