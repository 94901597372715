function pushService($q, ajaxService, responseValidatorService, localizedMessages) {
	var service = {
			createObjectPush: function(objectPushJson) {
				var request = ajaxService.createObjectPush(objectPushJson);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
			getObjectPushesCreatedByMe: function() {
				var request = ajaxService.getObjectPushesCreatedByMe();
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
			getObjectPushesSentToMe: function() {
				var request = ajaxService.getObjectPushesSentToMe();
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
			getObjectPushesByPushId: function(pushId) {
				var request = ajaxService.getObjectPushesByPushId(pushId);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
			deleteObjectPushCreatedByMe: function(pushId) {
				var request = ajaxService.deleteObjectPushCreatedByMe(pushId);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
			deleteObjectPushSentToMe: function(pushId) {
				var request = ajaxService.deleteObjectPushSentToMe(pushId);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
			deleteObjectPushFromUsers: function(pushId, userUuids) {
				var request = ajaxService.deleteObjectPushFromUsers(pushId, userUuids);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
	}
	
	return service;
}
angular
	.module('pushService.factory', [])
	.factory('pushService',[ '$q', 'ajaxService', 'responseValidatorService','localizedMessages', pushService ]);