cacheService.$inject = ['$state', '$templateCache', '$http'];

function cacheService($state, $templateCache, $http){
	var service = {
			preloadData: function()
			{
				var url;
				var states = $state.get();
			    for(var i in states)
			    {
			    	if ('views' in states[i] && states[i].views != null && 'content' in states[i].views){
			    		url = states[i].views.content.templateUrl;
				    }
			    	if(url){
			    		$http.get(url, {cache: $templateCache});
			    	}
			    }

			    var listUrls =
						    	[
							    	 'resources/assets/svgs/leftCalendarArrow.svg',
							    	 'resources/assets/svgs/rightCalendarArrow.svg',
							    	 'modules/conference/ConfPeople.tpl.html',
							    	 'modules/conference/ConfDetails.tpl.html',
							    	 'resources/assets/svgs/backarrow.svg'
						    	]

			    for(var i = 0 ; i < listUrls.length ; i++){
			    	$http.get(listUrls[i], {cache: $templateCache});
			    }
			}
	}

	return service;
}
