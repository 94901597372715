angular
    .module('dialogControllers.service')
    .controller('showApprovalSettingsController', showApprovalSettingsController);

showApprovalSettingsController.$inject =  ['$scope', 'context', 'localizedMessages', 'approvalsService', 'commonService', 'bvDialog'];

function showApprovalSettingsController($scope, context, localizedMessages, approvalsService, commonService, bvDialog)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.approvals = $scope.bvModalControllerInputs.approvals;
	$scope.refreshData = $scope.bvModalControllerInputs.refreshData;
	$scope.bvModalControllerInputs.promise.func = function(data){
		$scope.approvals = data;
		$scope.init();
	}
	
	function getObjectBaseUuids(objects){
		return _.map(objects, function(o,i){ return o.objectBaseUuid});
	}
	
	$scope.popupTitle  =   localizedMessages.get('MANAGE_APPROVALS');
	$scope.UI_SAVE    = localizedMessages.get('SAVE');
	$scope.UI_CANCEL    = localizedMessages.get('CANCEL');
	$scope.UI_ADJUST_TIME_TXT  =   localizedMessages.get('ADJUST_TIME');
	$scope.UI_ADD_ATTENDEES_TXT  =   localizedMessages.get('ADD_RESPONDENTS');
	$scope.UI_ACTIVE_INACTIVE  =   localizedMessages.get('PUBLISH_UNPUBLISH');
	$scope.UI_REMOVE_ATTENDEES_TXT  =   localizedMessages.get('REMOVE_RESPONDENTS');
	$scope.UI_ACTIVE  =   localizedMessages.get('PUBLISH');
	$scope.UI_IN_ACTIVE  =   localizedMessages.get('UNPUBLISH');
	$scope.UI_ERR_EDIT_MEETINGS_MEETING_NOT_SELECTED = localizedMessages.get('ERR_EDIT_MEETINGS_MEETING_NOT_SELECTED');
	$scope.UI_MSG_EDIT_MEETINGS_CHANGES_NO_CHANGES = localizedMessages.get('MSG_EDIT_MEETINGS_CHANGES_NO_CHANGES');
	$scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM = localizedMessages.get('MSG_EDIT_MEETINGS_CONFIRM_CHANGES_ON_TAB_CHANGE');
	$scope.UI_MSG_EDIT_MEETINGS_CANCEL_CHANGES  = localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED');
	
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
	
	$scope.MEETING_USERS = {};
	
	$scope.init = function() 
	{
		$scope.ADDED_ATTENDEES = [];
		$scope.ADDED_ATTENDEES_ = [];
		$scope.CURRENT_TAB = !($scope.CURRENT_TAB)?'addAttendees':$scope.CURRENT_TAB;
		
		$scope.changesMade = false;
		$scope.initialData = {};
		$scope.previousData = {};
		
		$scope.EVENTS_FOR_ADD_ATTND = angular.copy(_.filter($scope.approvals, function(item){
			return item.dueDateLong > (new Date()).getTime() && item.closed == false;
		}));
		
		$scope.EVENTS_FOR_REMOVE_ATTND = angular.copy(_.filter($scope.approvals, function(item){
			return item.dueDateLong > (new Date()).getTime() && item.closed == false;
		}));
		
		$scope.EVENTS_FOR_TIME = angular.copy(_.filter($scope.approvals, function(item){
			return item.dueDateLong > (new Date()).getTime() && item.closed == false;
		}));
		
		$scope.initialData.EVENTS_FOR_ADD_ATTND = angular.copy($scope.EVENTS_FOR_ADD_ATTND);
		$scope.initialData.EVENTS_FOR_TIME = angular.copy($scope.EVENTS_FOR_TIME);
		$scope.initialData.EVENTS_FOR_REMOVE_ATTND = angular.copy($scope.EVENTS_FOR_REMOVE_ATTND);
		
		$scope.previousData = angular.copy($scope.initialData); 
		$scope.checkBoxChecked = true;
	}
	
	$scope.tabVal;
	$scope.changeTab = function (tabVal)
	{	
		$scope.tabVal = tabVal;		
		$scope.itemCheckBox = true;
		$scope.checkBoxChecked = true;
		
		
		switch($scope.CURRENT_TAB)
		{
			case 'activeInactive':				
				if(!angular.equals($scope.previousData.EVENTS_FOR_TIME, $scope.EVENTS_FOR_TIME))
					bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveTempChangesOnTabChange, $scope.removeTempChangesOnTabChange);
				else{
					$scope.CURRENT_TAB = tabVal;
				}				
				break;
			case 'addAttendees':				
				if(_.filter($scope.EVENTS_FOR_ADD_ATTND, {selected: true}).length > 0){
					if(!angular.equals($scope.previousData.EVENTS_FOR_ADD_ATTND, $scope.EVENTS_FOR_ADD_ATTND) || !angular.equals($scope.previousData.ADDED_ATTENDEES, $scope.ADDED_ATTENDEES))
						bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveTempChangesOnTabChange, $scope.removeTempChangesOnTabChange);
					else
						$scope.CURRENT_TAB = tabVal;
				}
				else{
					$scope.EVENTS_FOR_ADD_ATTND = angular.copy($scope.previousData.EVENTS_FOR_ADD_ATTND);
					_.each($scope.ADDED_ATTENDEES, function(item){
						//$scope.smartSelectionOptions.selectInList([item], false);
					});
					$scope.ADDED_ATTENDEES = [];
					$scope.CURRENT_TAB = tabVal;
				}
				break;
			case 'removeAttendees':				
				if(_.filter($scope.EVENTS_FOR_REMOVE_ATTND, function(o,i){if(o.attendeeList && o.attendeeList && $scope.previousData.EVENTS_FOR_REMOVE_ATTND[i].attendeeList) return o.attendeeList.length != $scope.previousData.EVENTS_FOR_REMOVE_ATTND[i].attendeeList.length}).length > 0){
					bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveTempChangesOnTabChange, $scope.removeTempChangesOnTabChange);
	 			}
				else if(!angular.equals($scope.previousData.EVENTS_FOR_REMOVE_ATTND, $scope.EVENTS_FOR_REMOVE_ATTND))
					bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveTempChangesOnTabChange, $scope.removeTempChangesOnTabChange);
				else
					$scope.CURRENT_TAB = tabVal;
				
				break;
		}
		
	}

	$scope.saveTempChangesOnTabChange = function()
	{
		switch($scope.CURRENT_TAB)
		{
			case 'activeInactive':
				$scope.CURRENT_TAB = $scope.tabVal;
				$scope.previousData.EVENTS_FOR_TIME = angular.copy($scope.EVENTS_FOR_TIME);
				$scope.changesMade = true;
				$scope.saveChangesOnTabChanges();
				break;
			case 'addAttendees':
				$scope.CURRENT_TAB = $scope.tabVal;
				$scope.previousData.EVENTS_FOR_ADD_ATTND = angular.copy($scope.EVENTS_FOR_ADD_ATTND);
				$scope.previousData.ADDED_ATTENDEES = angular.copy($scope.ADDED_ATTENDEES);
				$scope.changesMade = true;
				$scope.saveChangesOnTabChanges();
				break;
			case 'removeAttendees':
				$scope.CURRENT_TAB = $scope.tabVal;
				$scope.previousData.EVENTS_FOR_REMOVE_ATTND = angular.copy($scope.EVENTS_FOR_REMOVE_ATTND);
				$scope.changesMade = true;
				$scope.saveChangesOnTabChanges();
				break;
		}
		
	}

	$scope.removeTempChangesOnTabChange = function()
	{
		switch($scope.CURRENT_TAB)
		{
			case 'activeInactive':
				$scope.CURRENT_TAB = $scope.tabVal;
				$scope.EVENTS_FOR_TIME = angular.copy($scope.previousData.EVENTS_FOR_TIME);
				break;
			case 'addAttendees':
				$scope.CURRENT_TAB = $scope.tabVal;
				$scope.EVENTS_FOR_ADD_ATTND = angular.copy($scope.previousData.EVENTS_FOR_ADD_ATTND);
				_.each($scope.ADDED_ATTENDEES, function(item){
					//$scope.smartSelectionOptions.selectInList([item], false);
				});
				_.each($scope.previousData.ADDED_ATTENDEES, function(item){
					//$scope.smartSelectionOptions.selectInList([item], true);
				});
				$scope.ADDED_ATTENDEES = angular.copy($scope.previousData.ADDED_ATTENDEES);
				break;
			case 'removeAttendees':
				$scope.CURRENT_TAB = $scope.tabVal;
				$scope.EVENTS_FOR_REMOVE_ATTND = angular.copy($scope.previousData.EVENTS_FOR_REMOVE_ATTND);
				$scope.MEETING_USERS.ITEMS = [];
				break;
		}
		
	}
			
	$scope.changeEventSelection = function (calEvent)
	{
		
	/*	if (!calEvent) {
			$scope.checkBoxChecked = true;
        } else {
        	$scope.checkBoxChecked = false;
        }*/
		calEvent.selected = !calEvent.selected;
		if($scope.CURRENT_TAB == 'addAttendees' && calEvent.selected){
			addAttendeeToList(calEvent);
        }
		if ($scope.CURRENT_TAB == 'removeAttendees' && calEvent.selected) {
			addAttendeesToRemovalList(calEvent);
		}
		if ($scope.CURRENT_TAB == 'removeAttendees' && !calEvent.selected) {
			removeDeselectedAttendeeFromList(calEvent);
		}
	}
	
	$scope.changeToActive = function (calEvent, isActive)
	{				
		$scope.itemCheckBox = false;
		$scope.checkBoxChecked = false;
		if($scope.CURRENT_TAB == 'activeInactive')
		{
			calEvent.isActive = isActive;			
		}
		
	}
	
	$scope.changeEventOptionSelection = function(approval)
	{	
		_.each($scope.EVENTS_FOR_REMOVE_ATTND, function(item){
			item.selected = false;
		});
		
		approval.selected = true;
		
		if(!approval.attendeeList){
			approvalsService.getApprovalDetailJSON(approval.objectBaseUuid).then(function(data){
				if(data){
					approval.attendeeList = approvalsService.createAttendeeList(approval, data).attendeeList;
				}
				else{
					approval.attendeeList = [];
				}
				
				_.find($scope.initialData.EVENTS_FOR_REMOVE_ATTND, { objectBaseUuid : approval.objectBaseUuid }).attendeeList = angular.copy(approval.attendeeList);
				_.find($scope.previousData.EVENTS_FOR_REMOVE_ATTND, { objectBaseUuid : approval.objectBaseUuid }).attendeeList = angular.copy(approval.attendeeList);
				
				$scope.MEETING_USERS.ITEMS = approval.attendeeList;
			});
		}
		else{
			$scope.MEETING_USERS.ITEMS = approval.attendeeList;
		}
	}

//	$scope.removeOwnerAndGetOtherUsers = function(approval)
//	{
//		approvalsService.getApprovalDetailJSON(approval.objectBaseUuid).then(function(data){
//			if(data){
//				var approval = approvalsService.createAttendeeList(approval, data);
//				$scope.MEETING_USERS.ITEMS = angular.copy(approval.attendeeList);
//			}
//		});
//	}
    function addAttendeeToList(calEvent){
        var currentUserId = context.getModel().userVO.objectBaseUuid;
        approvalsService.getApprovalDetailJSON(calEvent.objectBaseUuid).then(function(data){
            if(data){
                var approval = approvalsService.createAttendeeList(calEvent, data);
                _.remove(approval.attendeeList, { objectBaseUuid : currentUserId });
                $scope.MEETING_USERS.ITEMS = _.uniqBy($scope.MEETING_USERS.ITEMS.concat(approval.attendeeList), 'objectBaseUuid');

                var _approval = _.find($scope.initialData.EVENTS_FOR_REMOVE_ATTND, { objectBaseUuid : calEvent.objectBaseUuid });
                _approval.attendeeList = angular.copy(approval.attendeeList);
            }
        });
    }

    function addAttendeesToRemovalList(calEvent){
		var currentUserId = context.getModel().userVO.objectBaseUuid;
		approvalsService.getApprovalDetailJSON(calEvent.objectBaseUuid).then(
				function(data) {
					if (data) {
						var approval = approvalsService.createAttendeeList(
								calEvent, data);
						_.remove(approval.attendeeList, {
							objectBaseUuid : currentUserId
						});
						if($scope.MEETING_USERS.ITEMS != undefined){
						$scope.MEETING_USERS.ITEMS = _.uniqBy(
								$scope.MEETING_USERS.ITEMS
										.concat(approval.attendeeList),
								'objectBaseUuid');
						if(!$scope.calendarAttendeeMap){
							$scope.calendarAttendeeMap =new Map();
						}
						var obj = {};
	                    var valObj = approval.attendeeList;
	                    obj.id=angular.copy(valObj);
	                    obj[calEvent.objectBaseUuid] = angular.copy(valObj);
						$scope.calendarAttendeeMap.set(calEvent.objectBaseUuid,obj);
						}
						else{
							$scope.MEETING_USERS.ITEMS = [];
							$scope.MEETING_USERS.ITEMS = _.uniqBy(
									$scope.MEETING_USERS.ITEMS
											.concat(approval.attendeeList),
									'objectBaseUuid');
							if(!$scope.calendarAttendeeMap){
								$scope.calendarAttendeeMap =new Map();
							}
							var obj = {};
		                    var valObj = angular.copy(approval.attendeeList);
		                    obj.id=valObj;
		                    obj[calEvent.objectBaseUuid] = valObj;
		                    $scope.calendarAttendeeMap.set(calEvent.objectBaseUuid,obj)
						}
					}
				});
		}
    function removeDeselectedAttendeeFromList(calEvent){
		if( $scope.calendarAttendeeMap != undefined){
			  $scope.previousMap = $scope.calendarAttendeeMap;
			  $scope.calendarAttendeeMap =new Map();
			  
		for (let [key, value] of $scope.previousMap) {
			var obj = $scope.previousMap.get(key);
			if(!obj[calEvent.objectBaseUuid]) {
				$scope.calendarAttendeeMap.set(key,value);


			}
		}

		 
		 if($scope.calendarAttendeeMap.size === 0){
			 $scope.MEETING_USERS.ITEMS =[];
		 }
		 else{

			for (let [key, value] of $scope.calendarAttendeeMap) {
				$scope.MEETING_USERS.ITEMS =[];
				var obj =  $scope.calendarAttendeeMap.get(key);
				 $scope.MEETING_USERS.ITEMS = _.uniqBy(
							$scope.MEETING_USERS.ITEMS
									.concat(obj.id),
							'objectBaseUuid');

			}
		}
		}
	}

	$scope.getDisplayTime = function (calEvent)
	{
		if(calEvent!=undefined)
		{
			if(parseInt(calEvent.dueDateLong) < 32503708800000)
				return localizedMessages.get('DUE_ON') + " " + moment(parseInt(calEvent.dueDateLong)).format('ll');
			else
				return localizedMessages.get('NO') + " " + localizedMessages.get('DUE_DATE');
		}
	}

	$scope.setRemovedUsers = function(selectedItem){
		$scope.itemCheckBox = false;
		$scope.checkBoxChecked = false;
 	for (var i = 0; i < $scope.EVENTS_FOR_REMOVE_ATTND.length; i++) {
			if ($scope.EVENTS_FOR_REMOVE_ATTND[i].selected) {

				for (var j = 0; j < $scope.EVENTS_FOR_REMOVE_ATTND[i].attendeeList.length; j++) {
					if ($scope.EVENTS_FOR_REMOVE_ATTND[i].attendeeList[j].objectBaseUuid == selectedItem.objectBaseUuid) {
						$scope.EVENTS_FOR_REMOVE_ATTND[i].attendeeList.splice(
								j, 1);
						break;
					}
				}
				break;
			}
		}
	 }
	
	$scope.itemCheckBox = true;
	$scope.setSelectedUsers = function(selectedItems, calEvent)
	{
		//$scope.isActive = !$scope.isActive;
		$scope.itemCheckBox = false;
		_.each(selectedItems, function(item){
			if(item.type == 'User'){
				if(item.selected){
					if(_.findIndex($scope.ADDED_ATTENDEES, {objectBaseUuid: item.objectBaseUuid}) == -1){
						$scope.ADDED_ATTENDEES.push(angular.copy(item));
					}
				}
				else{
					_.remove($scope.ADDED_ATTENDEES, {objectBaseUuid: item.objectBaseUuid});
				}
			}
		});
		
		/*if($scope.isActive){			
			$scope.itemCheckBox = false;
		}else{			
			$scope.itemCheckBox = true;
		}*/
	};

	$scope.close = function()
	{
		$scope.cancelConfChanges();
	}

	$scope.exitEditMeetings = function ()
	{
		bvModal.close();
	}

	$scope.cancelConfChanges = function()
	{
		if($scope.finalCheckForChanges())
		{
			bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CANCEL_CHANGES, $scope.exitEditMeetings, null);
		}
		else
		{
			$scope.exitEditMeetings();
		}
	}

	$scope.saveConfChanges = function()
	{
		if($scope.finalCheckForChanges())
		{
			//bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveChanges, null);
			$scope.saveChanges();
		}
		else
		{
			$scope.exitEditMeetings();
		}
	}

 	$scope.finalCheckForChanges = function()
 	{

 	
 		if(!$scope.changesMade){
 			
 			if(_.filter($scope.EVENTS_FOR_ADD_ATTND, {selected : true}).length > 0){
 				$scope.changesMade = true;
 			}
 			else if(!angular.equals($scope.previousData.EVENTS_FOR_TIME, $scope.EVENTS_FOR_TIME)){
 				$scope.changesMade = true;
 			}
 			if(_.filter($scope.EVENTS_FOR_REMOVE_ATTND, function(approval){ return approval.attendeeList ? true : false }).length > 0){
                $scope.changesMade = true;
 			}
// 			if(_.filter($scope.EVENTS_FOR_REMOVE_ATTND, function(o,i){
// 			    if(o.attendeeList)
// 			        return o.attendeeList.length != $scope.initialData.EVENTS_FOR_REMOVE_ATTND[i].attendeeList.length}).length > 0){
// 				$scope.changesMade = true;
// 			}
 			
 		}
 		
 		return $scope.changesMade;
 	}


	$scope.saveChanges = function()
	{
		var approvalsIdsForAddition = [];
		var userIdsForAddition = [];
		if($scope.ADDED_ATTENDEES.length > 0 && _.filter($scope.EVENTS_FOR_ADD_ATTND, {selected : true}).length > 0){
			approvalsIdsForAddition = getObjectBaseUuids(_.filter($scope.EVENTS_FOR_ADD_ATTND, {selected : true}));
			userIdsForAddition = getObjectBaseUuids($scope.ADDED_ATTENDEES);
		}
		
		var activeInactiveApprovals = _.filter($scope.EVENTS_FOR_TIME, function(o,i){
			return o.isActive != $scope.initialData.EVENTS_FOR_TIME[i].isActive;
		});
	
		var activeApprovals = getObjectBaseUuids(_.filter(activeInactiveApprovals, {isActive: true}));
		var inactiveApprovals = getObjectBaseUuids(_.filter(activeInactiveApprovals, {isActive: false}));
	
//		var approvalsForRemoval = _.filter($scope.EVENTS_FOR_REMOVE_ATTND, function(o,i){
//			if(o.attendeeList) return o.attendeeList.length != $scope.initialData.EVENTS_FOR_REMOVE_ATTND[i].attendeeList.length
//		});
		var approvalsForRemoval = _.filter($scope.EVENTS_FOR_REMOVE_ATTND, { selected : true });

		var approvalsWithUsersForRemoval = [];
		if(approvalsForRemoval.length > 0){
			
			_.each(approvalsForRemoval, function(item){
				var initialApproval = _.filter($scope.initialData.EVENTS_FOR_REMOVE_ATTND, { objectBaseUuid: item.objectBaseUuid})[0];
				var attendeeList ={};
				attendeeList.id=[];
				attendeeList = $scope.calendarAttendeeMap.get(item.objectBaseUuid);
				if(attendeeList===undefined) {
					attendeeList ={};
				    attendeeList.id=[];
				}

             	var removedListUuids = _.difference(getObjectBaseUuids(attendeeList.id), getObjectBaseUuids($scope.MEETING_USERS.ITEMS))
				if(removedListUuids.length > 0){
					approvalsWithUsersForRemoval.push(item.objectBaseUuid + "_" + removedListUuids.join('#'));
				}
			});
			
		}

		approvalsService.saveApprovalsChanges(
				angular.toJson(approvalsIdsForAddition),
				angular.toJson(userIdsForAddition),
				angular.toJson(approvalsWithUsersForRemoval),
				angular.toJson(inactiveApprovals),
				angular.toJson(activeApprovals)).then(function(result){

					if($scope.refreshData && typeof $scope.refreshData == 'function'){
						//fix for MEETX-26572 refresh with default parameters 
						$scope.refreshData("OWN",0,25,'asc','name');
					}
					$scope.exitEditMeetings();
        });
	}
	
	$scope.saveChangesOnTabChanges = function()
	{
		var approvalsIdsForAddition = [];
		var userIdsForAddition = [];
		if(_.filter($scope.EVENTS_FOR_ADD_ATTND, {selected : true}).length > 0){
			approvalsIdsForAddition = getObjectBaseUuids(_.filter($scope.EVENTS_FOR_ADD_ATTND, {selected : true}));
			userIdsForAddition = getObjectBaseUuids($scope.ADDED_ATTENDEES);
		}
		
		var activeInactiveApprovals = _.filter($scope.EVENTS_FOR_TIME, function(o,i){
			return o.isActive != $scope.initialData.EVENTS_FOR_TIME[i].isActive;
		});
	
		var activeApprovals = getObjectBaseUuids(_.filter(activeInactiveApprovals, {isActive: true}));
		var inactiveApprovals = getObjectBaseUuids(_.filter(activeInactiveApprovals, {isActive: false}));
	
//		var approvalsForRemoval = _.filter($scope.EVENTS_FOR_REMOVE_ATTND, function(o,i){
//			if(o.attendeeList) return o.attendeeList.length != $scope.initialData.EVENTS_FOR_REMOVE_ATTND[i].attendeeList.length
//		});
		var approvalsForRemoval = _.filter($scope.EVENTS_FOR_REMOVE_ATTND, { selected : true });

		var approvalsWithUsersForRemoval = [];
		if(approvalsForRemoval.length > 0){
			
			_.each(approvalsForRemoval, function(item){
				var initialApproval = _.filter($scope.initialData.EVENTS_FOR_REMOVE_ATTND, { objectBaseUuid: item.objectBaseUuid})[0];
				var attendeeList ={};
				attendeeList.id=[];
				attendeeList = $scope.calendarAttendeeMap.get(item.objectBaseUuid);
				if(attendeeList===undefined) {
					attendeeList ={};
				    attendeeList.id=[];
				}

				var removedListUuids = _.difference(getObjectBaseUuids(attendeeList.id), getObjectBaseUuids($scope.MEETING_USERS.ITEMS))
				if(removedListUuids.length > 0){
					approvalsWithUsersForRemoval.push(item.objectBaseUuid + "_" + removedListUuids.join('#'));
				}
			});
			
		}

		approvalsService.saveApprovalsChanges(
				angular.toJson(approvalsIdsForAddition),
				angular.toJson(userIdsForAddition),
				angular.toJson(approvalsWithUsersForRemoval),
				angular.toJson(inactiveApprovals),
				angular.toJson(activeApprovals)).then(function(result){

					if($scope.refreshData && typeof $scope.refreshData == 'function'){
						//fix for MEETX-26572 refresh with default parameters 
							$scope.refreshData("OWN",0,25,'asc','name');
						
					}
					//$scope.exitEditMeetings();
        });
	}
	
	$scope.smartSelectionOptions = {
		checkboxDisableFeature: true,
		disableEmptyNodes: true
	};

	if($scope.bvModalControllerInputs.approvals){
		$scope.init();
	}
	
}
