function syncedinformationreportConfig($stateProvider, $locationProvider) {
}

syncedinformationreportController.$inject = ['$scope', '$state', '$timeout', 'context', 'syncedinformationreportService', 'localizedMessages', 'filtercriteriaService', 'toppanelService'];

function syncedinformationreportController($scope, $state, $timeout, context, syncedinformationreportService, localizedMessages, filtercriteriaService, toppanelService) {
	$scope.getSyncedinformationreportReportUrl = 'getSyncedInformationReport';
	$scope.reportFilterObject;
	$scope.syncedinformationreportReportData = [];
	$scope.clickHidePanel = true;
	$scope.noContentMsg = localizedMessages.get('REPORTS_NO_CONTENT_MSG');
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.stateName = $state.$current.name.split("home.reports.")[1];
	$scope.reportTemplateUrl = 'modules/reports/' + $scope.stateName + '/' + $scope.stateName + '.report.tpl.html';
	$scope.middleBarObject.title = localizedMessages.get('SYNCEDINFORMATIONREPORT');
	$scope.showingResultsText = localizedMessages.get('SHOWING_RESULTS');
	$scope.outOfText = localizedMessages.get('OUT_OF');
	$scope.downloadMsg = localizedMessages.get('DOWNLOAD_REPORT_MSG');
	$scope.middleBarObject.showBack = true;
	$scope.title = {
	    allReports: localizedMessages.get('ALL_REPORTS')
    };
	$scope.paginationObj = {};
	var isFetchingReportData = false;
	var isFilterDataReady;
	var tzid = moment.tz.guess();
	$scope.isScrollBusy = false;
	$scope.downloadReportURL = '/api/reports/exportSyncedInformationReport';
	$scope.exceededLimit = false;
	var rightMenuList=[];
	$scope.$on('initStarted', function(event, data) {
		isFilterDataReady = false;
		console.log("Message: " + data);
	});
	$scope.$on('initFinished', function(event, data) {
		isFilterDataReady = true;
		console.log("Message: " + data);
	});
	var rightMenuObj = {
        title: localizedMessages.get('DOWNLOAD_REPORT'),
        anchorLink: $scope.downloadReportURL,
        iconUrl: ''
    };

	$scope.tabs = [
        {
            title: $scope.middleBarObject.title,
            content: $scope.middleBarObject.title,
            active: true,
            rightMenuList: rightMenuList
        }
	];

	$scope.postData = {
        "pageNumber": 1,
        "size": 50,
        "timezone": tzid,
        "sortField": "firstName",
        "sortOrder": "asc",
        "filterCriteria" : {
            "teamspaces": [],
            "startDate": null,
            "endDate": null,
            "username": null
        }
    };

	$scope.topSyncedinformationreportPanel = {
        templateUrl: 'modules/reports/toppanel/toppanel.tpl.html',
        name: 'SYNCEDINFORMATIONREPORT',
        events: [
            {
                name: 'firstName',
                value: localizedMessages.get('FIRST_NAME_UPPERCASE')
            },
            {
                name: 'lastName',
                value: localizedMessages.get('LAST_NAME_UPPERCASE')
            },
            {
                name: 'teamspace',
                value: localizedMessages.get('TEAMSPACE_UPPERCASE')
            },
            {
                name: 'groupsUppercase',
                value: localizedMessages.get('GROUPS_UPPERCASE')
            },
            {
                name: 'contentSize',
                value: localizedMessages.get('CONTENT_SIZE')
            },
            {
                name: 'columnSortedClk',
                value: function (titleName) {
                    $scope.sortReportField({value: titleName})
                }
            },
            {
                name: 'sortField',
                value: $scope.postData.sortField
            }
        ]
    }

	//* Data Sets convert to Array
	$scope.getSetAsArr = function (set) {
	    var arr = [];
	    set.forEach(function (value) {
	        arr.push(value);
	    });
	    return arr;
	};

	//* Data Sets values convert to Array
	$scope.getSetValAsArr = function (set) {
	    var arr = [];
	    set.forEach(function (value) {
	        arr.push(value.ID);
	    });
	    return arr;
	};



	$scope.getSyncedinformationreportReport = function (postData, getSyncedinformationreportReportUrl) {
		if (isFilterDataReady) {
			$scope.postData.pageNumber = 1;
			isFetchingReportData = true;
			syncedinformationreportService.getSyncedinformationreportReport(postData, getSyncedinformationreportReportUrl).then(function (response) {
				if (response) {
					isFetchingReportData = false;
					$scope.isScrollBusy = false;
					$scope.syncedinformationreportReportData = response.content;
					$scope.postData.pageNumber = response.number;
					$scope.paginationObj.pageNumber = response.number;
					$scope.paginationObj.numberOfElements = response.numberOfElements;
					$scope.paginationObj.size = response.size;
					$scope.paginationObj.isLastPage = response.last;
					$scope.paginationObj.totalPages = response.totalPages;
					$scope.paginationObj.totalElements = response.totalElements;
					if (response.content.length == 0) {
						$scope.tabs[0].rightMenuList.length = 0;
					} else {
						$scope.tabs[0].rightMenuList[0] = rightMenuObj;
					}
				}
			});
		}
	};
	$scope.init = function () {
		$scope.updatePostData();
		$scope.getSyncedinformationreportReport($scope.postData, $scope.getSyncedinformationreportReportUrl);
	}
	$scope.$on('initFinished', function (event, data) {
		$scope.init();
		console.log("Message: " + data);
	});

	$scope.sortReportField = function (sortItem) {
		var toppanel = toppanelService.getInstance('SYNCEDINFORMATIONREPORT');
		$scope.postData.pageNumber = 1;
		if ($scope.postData.sortField === sortItem.value) {
			$scope.postData.sortOrder = ($scope.postData.sortOrder == 'desc') ? 'asc': 'desc';
		} else {
			$scope.postData.sortOrder = 'desc';
		}
		$scope.postData.sortField = sortItem.value;
		toppanel.addEvent('sortField', $scope.postData.sortField);
		toppanel.addEvent('sortOrder', $scope.postData.sortOrder);
		$scope.updatePostData();
		$scope.getSyncedinformationreportReport($scope.postData, $scope.getSyncedinformationreportReportUrl);
	}

	$scope.loadMore = function () {
		$scope.isScrollBusy = true;
		if (($scope.paginationObj.pageNumber - 1) * $scope.paginationObj.size + $scope.paginationObj.numberOfElements >= 1000) {
			$scope.exceededLimit = true;
		} else if (!$scope.paginationObj.isLastPage && !isFetchingReportData) {
			$scope.postData.pageNumber = $scope.paginationObj.pageNumber + 1;
			syncedinformationreportService.getSyncedinformationreportReport($scope.postData, $scope.getSyncedinformationreportReportUrl).then(function (response) {
				if (response) {
					isFetchingReportData = false;
					var items = response.content;
				    for (var i = 0; i < items.length; i++) {
						$scope.syncedinformationreportReportData.push(items[i]);
					}
					$scope.paginationObj.pageNumber = response.number;
					$scope.paginationObj.numberOfElements = response.numberOfElements;
					$scope.paginationObj.size = response.size;
					$scope.paginationObj.isLastPage = response.last;
					$scope.paginationObj.totalPages = response.totalPages;
					$scope.paginationObj.totalElements = response.totalElements;
					$scope.isScrollBusy = false;
				}
			});
		}
    };

	$scope.closeToaster = function () {
		$scope.exceededLimit = false;
	}

	$scope.handleLeftIcon = function () {
        $state.go('home.reports');
    }

	$scope.updatePostData = function () {
		$scope.postData.filterCriteria.startDate = filtercriteriaService.reportFilterObject.rangeSelection.startDate;
		$scope.postData.filterCriteria.endDate = filtercriteriaService.reportFilterObject.rangeSelection.endDate;
		$scope.postData.filterCriteria.teamspaces = $scope.getSetValAsArr(filtercriteriaService.reportFilterObject.selectedBoardTeamspaces);
	}

	$scope.$on('filterObjectChanged', function (event, data) {
		if (isFilterDataReady) {
			$scope.reportFilterObject = filtercriteriaService.reportFilterObject;
			$scope.updatePostData();
			$scope.getSyncedinformationreportReport($scope.postData, $scope.getSyncedinformationreportReportUrl);
		}
	});
}