function DocumentMenuController($scope, $window, repositoryService, documentContextMenu, contextMenuModel, contextMenuHandler, bvDialog, localizedMessages, homeService)
{
    /*var resizeId;
    var w = angular.element($window);
    w.bind('resize', function () {
        clearTimeout(resizeId);
        resizeId = setTimeout(doneResizing, 250);
    });

    function doneResizing()
    {
        try
        {
            var editFrame = document.getElementById("vEditframe");
            if(editFrame)
            {
                var editFrameCanvas = editFrame.contentWindow.document.getElementById("BVEditorCanvasScaleID");
                if(editFrameCanvas)
                {
                    var initHt = parseInt(window.innerHeight);
                    var initWt = parseInt(window.innerWidth);
                    var canvasHt = editFrameCanvas.style.height;
                    var canvasWt = editFrameCanvas.style.width;
                    canvasHt = canvasHt.replace("px","");
                    canvasWt = canvasWt.replace("px","");
                    console.log("CANVAST-HT:",canvasHt," CANVAS-WT:",canvasWt, " INIT-HT:",initHt, " INIT-WT:",initWt);
                    var dlg = document.querySelector('.vEditDialog .modal-dialog');
                    if(dlg)
                    {
                        var tmpHt = Math.round((parseInt(canvasHt)/initHt)*100);
                        var tmpWt = Math.round((parseInt(canvasWt)/initWt)*100);
                        console.log("PER-HT:",tmpHt," PER-WT:",tmpWt);
                       // dlg.style.height = tmpHt + "%";
                       // dlg.style.width = tmpWt + "%";
                        //dlg.style.height = canvasHt;
                        //dlg.style.width = canvasWt;
                    }
                }
            }
        }
        catch(error)
        {

        }
        console.log("WXXXX---",editFrame);
    }*/

    var showContextMenu = function()
    {
        var contextMenu = documentContextMenu.getContextMenu(contextMenuModel.multiple, contextMenuModel.selectedObject, contextMenuModel.parentObject, contextMenuModel.contextMenuCheckOptions);
        var newContextMenu = [];

        var sep = { title:"SEPERATOR",  iconUrl: 'SEPERATOR', svgFill: '', type:'SEPERATOR'};

        for (var i = 0, len = contextMenu.length; i < len; i++) {
        	newContextMenu.push(contextMenu[i]);

        	if(i < contextMenu.length - 1  && contextMenu[i].group != contextMenu[i+1].group)
    		{
        		newContextMenu.push(sep);
    		}
        }

        $scope.contextMenuOption = newContextMenu;

        var factor;
        MobileUtil.isMobileClient() ? factor = 48 : factor = 30;

        var length = $scope.contextMenuOption.filter(function(a){
        	if(a.type != 'SEPERATOR'){return true;}
        }).length;

        var separatorValue = ($scope.contextMenuOption.length - length) * 5;

        var point = documentContextMenu.getPosition(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedObject, length, factor, 210, separatorValue);

        var event = contextMenuModel.event;

        if(MobileUtil.isMobileClient() && !MobileUtil.isSurface()){
            !homeService.homeContext.expandedMenu ? point.x = point.x - 300 :  point.x = point.x - 40;
        }
        else{
            point.x = point.x;
            point.y = point.y;
        }


        //    	if(point.y <= 0)
        //    	{
        //    		point.y = 20;
        //    	}
        $scope.currentInstance = contextMenuModel.currentInstance;
        if(contextMenuModel.currentInstance=="FOLDER_LISTING")
        $scope.menuStyle = {'top': event.offsetY + "px",'left': event.offsetX + "px"};
        else
        {
            //$scope.menuStyle = {'top': point.y + "px",'left': point.x + "px"};
            $scope.menuParentCallback("MENU_STYLE",[point]);
            var arrow_point = documentContextMenu.getArrowPosition(contextMenuModel.xPos, contextMenuModel.yPos, length, factor, 210, separatorValue);
            if(MobileUtil.isMobileClient() && !MobileUtil.isSurface()){
                !homeService.homeContext.expandedMenu ? arrow_point.x = arrow_point.x - 300 :  arrow_point.x = arrow_point.x - 40;
            }
            else{
                point.x = point.x;
                point.y = point.y;
            }
            $scope.menuParentCallback("MENU_ARROW_STYLE",arrow_point) ;
        }
    }

    var collate = function (businessObject){
    	var validmediatype=true;
    	if(businessObject instanceof Array)
    		{
    			businessObject.forEach(function(item){
        		if(StringUtils.isMultiMediaVO(item.objectBaseUuid))
        			{
        			 		bvDialog.showMessage(localizedMessages.get('COLLATE_UNSUPPORTED_FILE_ERROR'), null, true);
        			 		validmediatype=false;
        			 		return;
        			}           
            });    		
    		}
    	
    	if(validmediatype)
    		{
    			$scope.menuParentCallback("COLLATE",[businessObject]);    		
    		}
        
    };

    var editCollate = function(businessObject){
        $scope.menuParentCallback("EDIT_COLLATE",[businessObject]);
    }

    var boardbook = function(businessObject){
        $scope.menuParentCallback("BOARDBOOK",[businessObject]);
    }

    var editBoardbook = function(businessObject){
        $scope.menuParentCallback("EDIT_BOARDBOOK",[businessObject]);
    }

    var openDocument = function (document)
    {
        $scope.menuParentCallback("OPEN_DOCUMENT",[document]);
    };

    var moveDocument = function (document, parentComponent)
    {
        $scope.menuParentCallback("MOVE_DOCUMENT",[document,parentComponent]);
    };

    var recycleDocuments = function (documents)
    {
        var cofirmMsgForRecycle = localizedMessages.get('MSG_ARE_YOU_SURE_TO_MOVE_FILE_TO_TRASH');
        var cofirmMsgForAssociated = localizedMessages.get('SELECTED_DOCUMENTS_ASSOCIATED');
        var cofirmMsgForAssociatedDocument = localizedMessages.get('DOCUMENT_IS_ASSOCIATED');

        var recycle = function ()
        {
            contextMenuHandler.deleteBusinessObject(documents).then(function(data)
            {
                $scope.menuParentCallback("DELETE",[data.data]);
            });
        }

        var associationCheck = function(){
            var objectBaseUuids = [];

            if(documents instanceof Array){
                for(var i in documents){
                    objectBaseUuids.push(documents[i].objectBaseUuid);
                }
            }
            else{
                objectBaseUuids.push(documents.objectBaseUuid);
                cofirmMsgForAssociated = "'" + documents.name + "'" + cofirmMsgForAssociatedDocument;
            }

            repositoryService.checkRelationBeforeDeleteJSON(angular.toJson(objectBaseUuids)).then(function(data){
                var comments = '';
                if(data && data.length > 0){
                    data.forEach(function(item){
                        comments = comments + " " + item.comments;
                    })
                    bvDialog.confirm(cofirmMsgForAssociated + "(" + comments + "). " + cofirmMsgForRecycle, recycle, null);
                }
                else{
                    bvDialog.confirm(cofirmMsgForRecycle, recycle, null);
                }
            })
        }

        associationCheck();

    }


    var makeAgendaTemplateDefault = function(file) {
        repositoryService.makeAgendaTemplateDefaultForCurrentPortal(file.objectBaseUuid).then(function(data){
            $scope.menuParentCallback("MAKE_DEFAULT");
        });
    }

    var removeFromFavorites = function (documents)
    {
        var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(documents);

        repositoryService.removeFromBriefCase(documentsToJSON).then(function(data)
        {
            if(data.data)
            {
                $scope.menuParentCallback("REMOVE_FROM_FAVORITES", documents);
                $scope.noFavorites = data.data.length == 0 ? true : false;
                $scope.msg_no_favorites = localizedMessages.get('MSG_COMMON_NO_DOCUMENTS');
            }

        });
    }

    var removeFromAllBriefCasesConfirm = function ()
    {
        var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu($scope.selectedDocs);
        repositoryService.removeDocumentsFromUsersFavoriteJSON(documentsToJSON).then(function(data)
        {
            if(data != undefined || data != null)
            {
                if(data == true) {
                    bvDialog.showMessage(localizedMessages.get("MSG_REMOVE_SUCCESSFUL"), null, true);
                } else if(data == false) {
                    bvDialog.showMessage(localizedMessages.get("MSG_DOC_NOT_FOUND_IN_BRIEFCASE"));
                }
            }

        });
    }

    var removeFromAllBriefCases = function (documents)
    {
        var cofirmMsg = localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DOCUMENT');
        $scope.selectedDocs = documents;
        bvDialog.confirm(cofirmMsg, removeFromAllBriefCasesConfirm);
    }

    $scope.openContextMenu = function(type)
    {
        switch(type)
        {
            case 'agendaBuilder':
            case 'edit': contextMenuHandler.editFile(contextMenuModel.selectedObject);break;
            case 'checkin': contextMenuHandler.checkin(contextMenuModel.selectedObject, contextMenuModel.parentFolder);break;
            case 'checkout': contextMenuHandler.checkout(contextMenuModel.selectedObject);break;
            case 'cancelcheckout': contextMenuHandler.cancelCheckout(contextMenuModel.selectedObject);break;
            case 'agenda': contextMenuHandler.createAgenda(contextMenuModel.selectedObject);break;
            case 'open': openDocument(contextMenuModel.selectedObject);break;
            case 'viewas': contextMenuHandler.openViewAsMenu(contextMenuModel.selectedObject);break;
            case 'pdf': contextMenuHandler.openPdf(contextMenuModel.selectedObject);break;
            case 'rename': bvDialog.rename(contextMenuModel.selectedObject, function(data)
            {
                $scope.menuParentCallback("RENAME",[data]);
            });break;
            case 'move': moveDocument(contextMenuModel.selectedObject, contextMenuModel.parentObject);break;
            case 'present': contextMenuHandler.openDocumentPresenter(contextMenuModel.selectedObject);break;
            case 'collate': collate(contextMenuModel.selectedObject);break;
            case 'editcollate': editCollate(contextMenuModel.selectedObject);break;
            case 'permission': contextMenuHandler.openPermission(contextMenuModel.selectedObject);break;
            case 'addToFavorites': contextMenuHandler.addToFavorites(contextMenuModel.selectedObject);break;
            case 'addToUserFavorites': contextMenuHandler.addToUserFavorites(contextMenuModel.selectedObject);break;
            case 'boardbook': boardbook(contextMenuModel.selectedObject);break;
            case 'editboardbook': editBoardbook(contextMenuModel.selectedObject);break;
            case 'delete': recycleDocuments(contextMenuModel.selectedObject); break;
            case 'deleteAllAnnotations': contextMenuHandler.deleteAllAnnotationsInDocument(contextMenuModel.selectedObject); break;
            case 'share': contextMenuHandler.shareDocuments(contextMenuModel.selectedObject);break;
            case 'send': contextMenuHandler.meobileComposeMessage(contextMenuModel.selectedObject);break;
            case 'addToNewItems': contextMenuHandler.addToNewItems(contextMenuModel.selectedObject);break;
            case 'download': contextMenuHandler.download(contextMenuModel.selectedObject);break;
            case 'versionTracker': contextMenuHandler.versionTracker(contextMenuModel.selectedObject);break;
            case 'copy': contextMenuHandler.copy(contextMenuModel.selectedObject, contextMenuModel.parentFolder);break;
            case 'cut': contextMenuHandler.cut(contextMenuModel.selectedObject, contextMenuModel.parentFolder);break;
            case 'paste': contextMenuHandler.paste(contextMenuModel.selectedObject, contextMenuModel.parentFolder);break;
            case 'properties':contextMenuHandler.properties(contextMenuModel.selectedObject);break;
            case 'removeFromBriefCase': contextMenuHandler.removeFromBriefCase(contextMenuModel.selectedObject);break;
            case 'removeFromAllBriefCases': removeFromAllBriefCases(contextMenuModel.selectedObject);break;
            case 'removeFromNewItems':contextMenuHandler.removeFromNewItems(contextMenuModel.selectedObject);break;
            //case 'createShortCut':contextMenuHandler.createShortCut(contextMenuModel.selectedObject, contextMenuModel.parentFolder);break; HTML-1211
            case 'unhidefolder':contextMenuHandler.unHideFolder(contextMenuModel.selectedObject).then(function(data)
            {
                $scope.menuParentCallback("HIDE",[false]);
            });break;
            case 'hidefolder':contextMenuHandler.hideFolder(contextMenuModel.selectedObject).then(function(data)
            {
                $scope.menuParentCallback("HIDE",[true]);
            });break;
            case 'rules':contextMenuHandler.rules(contextMenuModel.selectedObject);break;
            case 'setFollowers':contextMenuHandler.setFollowers(contextMenuModel.selectedObject);break;
            case 'copylink':contextMenuHandler.copylink(contextMenuModel.selectedObject);break;
            case 'push':contextMenuHandler.push(contextMenuModel.selectedObject);break;
            case 'replace':contextMenuHandler.replaceFile(contextMenuModel.selectedObject, contextMenuModel.parentFolder);break;
            case 'auditTrail':contextMenuHandler.auditTrail(contextMenuModel.selectedObject);break;
            //    		case 'secureMessage':secureMessage();break;
            case 'follow' : contextMenuHandler.follow(contextMenuModel.selectedObject, contextMenuModel.contextMenuCheckOptions);break;
            case 'stopFollow' : contextMenuHandler.stopFollow(contextMenuModel.selectedObject, contextMenuModel.contextMenuCheckOptions);break;
            case 'createAgendaForFolder' : contextMenuHandler.createAgendaForFolder(contextMenuModel.selectedObject, contextMenuModel.parentFolder); break;
            case 'makeAgendaTemplateDefault' : makeAgendaTemplateDefault(contextMenuModel.selectedObject); break;
            case 'openFolderInDocumentModule' : contextMenuHandler.openFolderInDocumentModule(contextMenuModel.selectedObject); break;
           // case 'makePdfCompliant':contextMenuHandler.makePdfCompliant(contextMenuModel.selectedObject,contextMenuModel.parentFolder); break;
        }
    }

    showContextMenu(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedContextItem, contextMenuModel.event);
}