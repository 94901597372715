function RunningConfListController($scope, $state, modal, context, homeContext, modelService, conferenceService, presentationService, calendarService, localizedMessages, bvDialog, bvDialogService, teamService, $timeout, repositoryService, bvDialogMobile)
{
    $scope.UI_MEETING_IN_PROGRESS_SELECT_TO_JOIN_TXT = localizedMessages.get("MEETING_IN_PROGRESS_SELECT_TO_JOIN");
    $scope.UI_ACTIVE_USER_SESSION_ERROR = localizedMessages.get("ACTIVE_USER_SESSION_ERROR");
    $scope.UI_HOST_TXT = localizedMessages.get('HOST');
    $scope.UI_PRESENTER_TXT = localizedMessages.get("PRESENTER");
    $scope.UI_CANCEL_TXT = localizedMessages.get("CANCEL");
    $scope.UI_JOIN_TXT = localizedMessages.get("JOIN");
    $scope.UI_LEAVE_TXT = localizedMessages.get("LEAVE");
    $scope.UI_WAITING_ON_PRESENTER_TXT = localizedMessages.get("WAITING_ON_PRESENTER");
    $scope.UI_END_TXT = localizedMessages.get("END");

    //presentationService.showingRunningConfsDialog();
    var currentUserVO = context.getModel().userVO;

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    var calEvent = $scope.bvModalControllerInputs.calEvent;

    $scope.attendingHostedEvent = false;
    if(calEvent != undefined && calEvent.host == currentUserVO.objectBaseUuid)
    $scope.attendingHostedEvent = true;

    $scope.joinedConf = {};
    $scope.joinedConf = presentationService.getConf();

    if($scope.attendingHostedEvent)
    $scope.joinedConf.conf_event = calEvent;

    $scope.$on('conf:updated', function(event,conf) {
        $scope.joinedConf = conf;
        if(!$scope.hasJoinedAnyConf()) {
            $scope.statusTitle = $scope.UI_MEETING_IN_PROGRESS_SELECT_TO_JOIN_TXT;
        }
    });

    $scope.$on('confParticipants:updated', function(event,conf) {
        $scope.joinedConf.conf_participants = conf.conf_participants;
        amIPresentorNow();
    });

    $scope.confs = presentationService.getRunningConfs();
    if($scope.attendingHostedEvent)
    $scope.confs = [calEvent]

    $scope.$on('runningConfs:updated', function(event,runningConfs)
    {
        if($scope.attendingHostedEvent && Object.keys($scope.joinedConf).length !=0)
        {
            $scope.confs = [calEvent]
        }
        else
        {
            $scope.confs = runningConfs;
            updateRollCall();
        }

        if($scope.confs.length == 0) {
            // leave if was in any meeting before
            var confSyncVo = $scope.joinedConf.conf_sync_vo;
            if(confSyncVo != undefined && confSyncVo != null)
            {
                repositoryService.closeDocumentViewer(confSyncVo);
            }

            $scope.leaveConf();
            $scope.close();

        }
    });

    var updateRollCall = function()
    {
        angular.forEach($scope.confs, function(conf){
            conferenceService.synchronizeConferenceJSON(currentUserVO.objectBaseUuid,conf.objectBaseUuid,angular.toJson([]))
            .then(function(data)
            {
                if(data.data != undefined) {
                    conf.sync_key_val = data.data.synckeyval;
                    conf.rollcall = data.data.rollcall;
                }

                for(var i in conf.rollcall) {
                    if(conf.rollcall[i].isHost) conf.hostName = conf.rollcall[i].name.replace(" (Host)", "");
                }
                conf.presentorName = conf.hostName;
                homeContext.confPresenterName = conf.hostName;

                for(var i in conf.rollcall)
                {
                    if(conf.rollcall[i].isPresenter) {
                    		conf.presentorName = conf.rollcall[i].name.replace(" (Host)", "");
                    		homeContext.confPresenterName = conf.rollcall[i].name.replace(" (Host)", "");
                    	}
                }

                // joining same conf if refreshed after join
                if(!presentationService.alreadyInConf())
                {
                    for(var j in conf.sync_key_val)
                    {
                        if(conf.sync_key_val[j].value === 'PARTICIPANT'
                        && conf.sync_key_val[j].userId === currentUserVO.objectBaseUuid)
                        {
                            $scope.joinConf(conf);
                        }
                    }
                }
            });
        });
    }

    updateRollCall();

    var updateConfIfIWasPresenting = function()
    {
        angular.forEach($scope.confs, function(conf)
        {
            conferenceService.getConferenceParticipantsJSON(conf.objectBaseUuid).then(function(data)
            {
                var participants = data.data;
                for(var i in participants)
                {
                    if(participants[i].isPresenter === true &&
                        participants[i].objectBaseUuid === currentUserVO.objectBaseUuid)
                    {
                        conferenceService.synchronizeConferenceJSON(currentUserVO.objectBaseUuid,conf.objectBaseUuid,angular.toJson([]))
                        .then(function(data)
                        {
                            if(data.data != undefined)
                            {
                                presentationService.setConf({
                                    conf_event : conf,
                                    conf_sync_key_val : data.data.synckeyval,
                                    rollcall : data.data.rollcall
                                });

                                conferenceService.getSynchronizedCommunicationVOJSON(conf.objectBaseUuid).then(function(data)
                                {
                                    presentationService.updateConfSyncVO(data.data);
                                    // this update will force to check amIPresntor
                                    presentationService.updateConfParticipants(participants);
                                });
                            }
                        });
                    }
                }
            });
        });
    };

    updateConfIfIWasPresenting();

   
    //check error message thrown from bvJsonServlet here, exception object with message attribute is set to runningconfs after refresh polling  
    if($scope.confs && $scope.confs.message) {
    	if($scope.confs.message === "Not an authorized user"){
    		$scope.statusTitle =$scope.UI_ACTIVE_USER_SESSION_ERROR;
    	} else {
    	    $scope.statusTitle = $scope.UI_MEETING_IN_PROGRESS_SELECT_TO_JOIN_TXT;
    	}
    } else {
        $scope.statusTitle = $scope.UI_MEETING_IN_PROGRESS_SELECT_TO_JOIN_TXT;
    }

    $scope.getDisplayTime = function (calEvent)
    {
        if(calEvent!=undefined)
        {
            var startTime = moment(parseInt(calEvent.startDateGMTMillis)).format('LT');
            var endTime = moment(parseInt(calEvent.endDateGMTMillis)).format('LT');
            return startTime + " - " + endTime;
        }
    }

    var amIPresentorNow = function()
    {
         var participants = $scope.joinedConf.conf_participants;
         for (var i in participants) {
         	if(participants[i].isPresenter == true) {
         		 var presentorName = participants[i].name.replace(" (Host)", "");
                  var currentPresentor = angular.copy(participants[i]);
                  currentPresentor.name = presentorName;
                  presentationService.updateConfCurrentPresentor(currentPresentor);
         	}
             if (participants[i].isPresenter == true && participants[i].objectBaseUuid == currentUserVO.objectBaseUuid) {
                 if (MobileUtil.isAndroid() || MobileUtil.isIPhone()) {
                     bvDialogMobile.showMobileConfDialog();
                 } else {
                     presentationService.showConfDialog();
                 }
             } 
         }
      }

        $scope.joinConf = function(conf_event)
        {
            if(presentationService.alreadyInConf())
            {
                console.log("looks like you are in conference already, so not joining.")
                return;
            }

            conferenceService.joinConferenceJSON(conf_event.objectBaseUuid).then(function(data)
            {
                //Show meeting
                //console.log("JOIN CONFERENCE RETURN OBJ",data);
                //conf_event.documentVOList = data.data;
                presentationService.setConfEvent(conf_event);
                conferenceService.getConferenceParticipantsJSON(conf_event.objectBaseUuid).then(function(data)
                {
                    presentationService.updateConfParticipants(data.data);
                    $scope.joinedConf = presentationService.getConf();
                    $scope.statusTitle = $scope.UI_WAITING_ON_PRESENTER_TXT;
                });
                angular.element(document.querySelector('.bv-running-conf-list-dialog')).css({'z-index': 95});
            });
        }

        $scope.hasJoinedAnyConf = function()
        {
            return Object.keys($scope.joinedConf).length != 0;
        }

        $scope.leaveConf = function(fromTemplate)
        {
          if(fromTemplate){
            bvDialog.confirm(localizedMessages.get("MSG_PRESENTATION_LEAVE"), () => {
              presentationService.leaveConf(currentUserVO.objectBaseUuid);
              $scope.statusTitle = $scope.UI_MEETING_IN_PROGRESS_SELECT_TO_JOIN_TXT;
            });
          }
          else{
            presentationService.leaveConf(currentUserVO.objectBaseUuid);
            $scope.statusTitle = $scope.UI_MEETING_IN_PROGRESS_SELECT_TO_JOIN_TXT;
          }
        }

        if(!$scope.attendingHostedEvent && calEvent != undefined && Object.keys(calEvent).length !=0)
        {
            $scope.joinConf(calEvent);
        }

        $scope.close = function()
        {
            presentationService.closingRunningConfsDialog();
            bvModal.close();
        }

        $scope.manageBackDrop = function() {
            presentationService.manageBackDrop();
        }
    }

angular
.module('conference.module')
.controller('RunningConfListController', ['$scope', '$state', '$modal', 'context', 'homeContext', 'modelService', 'conferenceService', 'presentationService', 'calendarService', 'localizedMessages', 'bvDialog', 'bvDialogService', 'teamService', '$timeout', 'repositoryService','bvDialogMobile', RunningConfListController ])
;
