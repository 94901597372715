function colorPickerBackground(context){
	return {
	    restrict: 'A',
		controller: ['$scope', '$state', 'brand', '$element', 'homeService', function(scope,state, brand, el, homeService){
			var backgroundColor = context.getModel().html5BackgroundColor;
			var colorVal = "004570";
			if(backgroundColor != null)
			{
				colorVal = backgroundColor.replace('#','').trim();
			}
			$(el).val(colorVal);
			$(el).ColorPickerSliders({
				color: colorVal,
		    	flat: true,
		    	connectedinput: $(el),
		    	order: {
		    	hsl: 1
		    	},
		    	onchange: function(container, color) {
		            $('input#leftColor').val(color.tiny.toHexString());
		            brand.color($('input#leftColor').val(), $('input#rightColor').val());
		            context.getModel().html5BackgroundColor = "#" + color.tiny.toHexString();
		            setTimeout(function(){
		            	scope.$apply();
		            },0);
		    	}
		    });
		}]
	}
}