function userbriefcasereportConfig($stateProvider, $locationProvider){

}

userbriefcasereportController.$inject = ['$scope', '$state', '$timeout', 'context', 'userbriefcasereportService', 'localizedMessages', 'filtercriteriaService', 'toppanelService'];

function userbriefcasereportController($scope, $state, $timeout, context, userbriefcasereportService, localizedMessages, filtercriteriaService, toppanelService){
	$scope.getUserbriefcasereportReportUrl = 'getUserBriefcaseReport';
	$scope.reportFilterObject;
	$scope.userbriefcasereportReportData = [];
	$scope.clickHidePanel = true;
	$scope.noContentMsg = localizedMessages.get('REPORTS_NO_CONTENT_MSG');
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.stateName = $state.$current.name.split("home.reports.")[1];
	$scope.reportTemplateUrl = 'modules/reports/'+ $scope.stateName +'/'+ $scope.stateName +'.report.tpl.html';
	$scope.middleBarObject.title = localizedMessages.get('USERBRIEFCASEREPORT');
	$scope.showingResultsText = localizedMessages.get('SHOWING_RESULTS');
	$scope.outOfText = localizedMessages.get('OUT_OF');
	$scope.totalText = localizedMessages.get('TOTAL');
	$scope.downloadMsg = localizedMessages.get('DOWNLOAD_REPORT_MSG');
	$scope.middleBarObject.showBack = true;
	$scope.title = { allReports : localizedMessages.get('ALL_REPORTS') };
	$scope.paginationObj = {};
	var isFetchingReportData = false;
	var isFilterDataReady;
	var tzid = moment.tz.guess();
	$scope.isScrollBusy = false;
	$scope.downloadReportURL = '/api/reports/exportUserBriefcaseReport';
	$scope.exceededLimit = false;
	var rightMenuList=[];
	$scope.$on('initStarted', function(event, data){
		isFilterDataReady = false;
		console.log("Message: "+data);
	});
	$scope.$on('initFinished', function(event, data){
		isFilterDataReady = true;
		console.log("Message: "+data);
	});
	var rightMenuObj = {
    title : localizedMessages.get('DOWNLOAD_REPORT'),
    anchorLink : $scope.downloadReportURL,
    iconUrl: ''
  };

	$scope.tabs = [
    {title : $scope.middleBarObject.title, content : $scope.middleBarObject.title, active: true, rightMenuList: rightMenuList}
	];

	$scope.postData = {
		    "pageNumber": 1,
		    "size": 50,
		    "timezone": tzid,
		    "sortField": "dateAdded",
		    "sortOrder": "desc",
		    "filterCriteria" : {
		        "users": [],
                "startDate": null,
                "endDate": null,
                "username": null
		    }
		};

	$scope.topUserbriefcasereportPanel = {
            templateUrl: 'modules/reports/toppanel/toppanel.tpl.html',
            name: 'USERBRIEFCASEREPORT',
            events : [
                { name: 'dateAdded', value : localizedMessages.get('DATE_ADDED')},
                { name: 'fileName', value : localizedMessages.get('FILE_NAME_UPPERCASE')},
                { name: 'fileSize', value : localizedMessages.get('FILE_SIZE_UPPERCASE')},
                { name: 'columnSortedClk',  value : function(titleName){ $scope.sortReportField({ value: titleName }) }},
                { name: 'sortField',    	value : $scope.postData.sortField}
            ]
        }

	//* Data Sets convert to Array
	$scope.getSetAsArr = function (set) {
	    var arr = [];
	    set.forEach(function (value) {
	        arr.push(value);
	    });
	    return arr;
	};

	//* Data Sets values convert to Array
	$scope.getSetValAsArr = function (set) {
	    var arr = [];
	    set.forEach(function (value) {
	        arr.push(value.ID);
	    });
	    return arr;
	};



	$scope.getUserbriefcasereportReport = function(postData, getUserbriefcasereportReportUrl){
		if(isFilterDataReady){
			$scope.postData.pageNumber = 1;
			isFetchingReportData = true;
			userbriefcasereportService.getUserbriefcasereportReport(postData, getUserbriefcasereportReportUrl).then(function(response) {
				if(response){
					isFetchingReportData = false;
					$scope.isScrollBusy = false;

					$scope.userbriefcasereportReportData = response.content;
					$scope.postData.pageNumber = response.number;
					$scope.paginationObj.pageNumber = response.number;
					$scope.paginationObj.numberOfElements = response.numberOfElements;
					$scope.paginationObj.size = response.size;
					$scope.paginationObj.isLastPage = response.last;
					$scope.paginationObj.totalPages = response.totalPages;
					$scope.paginationObj.totalElements = response.totalElements;
					if(response.content.length == 0){
						$scope.tabs[0].rightMenuList.length = 0;
					}else{
						$scope.tabs[0].rightMenuList[0] = rightMenuObj;
					}
					$scope.totalFileSize = response.metadata;
				}
			});
		}
	};
	$scope.init = function(){
		$scope.updatePostData();
		$scope.getUserbriefcasereportReport($scope.postData, $scope.getUserbriefcasereportReportUrl);
	}
	$scope.$on('initFinished', function(event, data){
		$scope.init();
		console.log("Message: "+data);
	});

	$scope.sortReportField = function(sortItem){
		var toppanel = toppanelService.getInstance('USERBRIEFCASEREPORT');
		$scope.postData.pageNumber = 1;
		if($scope.postData.sortField === sortItem.value){
			$scope.postData.sortOrder = ($scope.postData.sortOrder == 'desc') ? 'asc': 'desc';
		}else{
			$scope.postData.sortOrder = 'desc';
		}
		$scope.postData.sortField = sortItem.value;
		toppanel.addEvent('sortField', $scope.postData.sortField);
		toppanel.addEvent('sortOrder', $scope.postData.sortOrder);
		$scope.updatePostData();
		$scope.getUserbriefcasereportReport($scope.postData, $scope.getUserbriefcasereportReportUrl);
	}

	$scope.loadMore = function(){
		$scope.isScrollBusy = true;
		if(($scope.paginationObj.pageNumber-1)*$scope.paginationObj.size+$scope.paginationObj.numberOfElements >= 1000){
			$scope.exceededLimit = true;
		}else if(!$scope.paginationObj.isLastPage && !isFetchingReportData){
			$scope.postData.pageNumber = $scope.paginationObj.pageNumber+1;
			userbriefcasereportService.getUserbriefcasereportReport($scope.postData, $scope.getUserbriefcasereportReportUrl).then(function(response) {
				if(response){
					isFetchingReportData = false;
					var items = response.content;
				    for (var i = 0; i < items.length; i++) {
						$scope.userbriefcasereportReportData.push(items[i]);
					 }
					$scope.paginationObj.pageNumber = response.number;
					$scope.paginationObj.numberOfElements = response.numberOfElements;
					$scope.paginationObj.size = response.size;
					$scope.paginationObj.isLastPage = response.last;
					$scope.paginationObj.totalPages = response.totalPages;
					$scope.paginationObj.totalElements = response.totalElements;
					$scope.isScrollBusy = false;
				}
			});
		}
    };
	$scope.closeToaster = function(){
		$scope.exceededLimit = false;
	}

	$scope.handleLeftIcon = function(){
        $state.go('home.reports');
    }
	$scope.updatePostData = function(){
		$scope.postData.filterCriteria.startDate = filtercriteriaService.reportFilterObject.rangeSelection.startDate;
		$scope.postData.filterCriteria.endDate = filtercriteriaService.reportFilterObject.rangeSelection.endDate;
		$scope.postData.filterCriteria.users = $scope.getSetAsArr(filtercriteriaService.reportFilterObject.selectedUsers);

	}

	$scope.$on('filterObjectChanged', function(event, data) {
		if(isFilterDataReady){
			$scope.reportFilterObject = filtercriteriaService.reportFilterObject;
			$scope.updatePostData();
			$scope.getUserbriefcasereportReport($scope.postData, $scope.getUserbriefcasereportReportUrl);
		    console.log("Message: " + data);
		}
	});

	$scope.translateDate = StringUtils.translateDate;
}