inputSelection.$inject = ['$timeout'];

function inputSelection($timeout){
	return {
		restrict: 'A',
		scope: {
			bvInputSelection : '='
		},
		link: function($scope, $element)
		{
			if($scope.bvInputSelection){
				$scope.$watch($element[0].value, function(data){
					$timeout(function(){
						$element[0].setSelectionRange(0, $element[0].value.length)
						$scope.bvInputSelection = false
					},0);
				})
			}
		}
	}
}