angular
	.module('dialogControllers.service')
	.controller('virtualMeetingConfigurationController', virtualMeetingConfigurationController);

virtualMeetingConfigurationController.$inject = [ '$scope', 'localizedMessages', 'homeService', 'context', 'bvDialog', 'templatesService'];

function virtualMeetingConfigurationController($scope, localizedMessages, homeService, context, bvDialog, templatesService) {

	$scope.closeIcon = templatesService.getUrl('closeBtn');

	$scope.popupTitle 	= localizedMessages.get('VIRTUAL_MEETING_CONFIGURATION');
	$scope.actionBtnTxt = localizedMessages.get('OK');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.ui = {
		button : {
			connect					: localizedMessages.get('CONNECT')
		}
	}

	$scope.MeetingTypes = ["zoom", "webex", "teams"]



	var ConfigurationAreas = [];

	$scope.MeetingTypes.forEach(function(item) {
		
	});



	// $scope.user = {};
	var bvModal = $scope.bvModalControllerInputs.bvModal;

	$scope.action = function()
	{
		console.log('Virtual Meeting Configuration');
		bvModal.close();
	}

	$scope.openConnect = function(id)
	{
		console.log('OpenConnect');
		$scope.MeetingTypes.forEach(function(item) {
			if (item != id) {
				MeetingAreaDiv(item).addClass("faded");
			}
		});

		setTimeout(function(){ 
			$scope.MeetingTypes.forEach(function(item) {
				if (item != id) {
					MeetingAreaDiv(item).addClass("hidden");
				}
			});
			MeetingAreaDiv(id).addClass("opened"); 
		}, 1000);

	}

	$scope.closeConnect = function(id)
	{
		console.log('CloseConnect');
		MeetingAreaDiv(id).addClass("closing");
		MeetingAreaDiv(id).removeClass("opened");

		setTimeout(function(){ 
			$scope.MeetingTypes.forEach(function(item) {
			if (item != id) {
				MeetingAreaDiv(item).removeClass("hidden");
				MeetingAreaDiv(item).removeClass("faded");
			}
			MeetingAreaDiv(id).removeClass("closing");
		}); 
		}, 1000);
		
		
		

	}

	function MeetingAreaDiv(item) {
		return angular.element('.virtual-meeting-configuration-' + item)
	}

}