manageTemplatesService.$inject = ['$q', 'ajaxService'];

function manageTemplatesService($q, ajaxService) {
    var service={

        getAllDigitalAgendaTemplates: function(portalBaseUuid){
            var request = ajaxService.getAllDigitalAgendaTemplates(portalBaseUuid);
            return request.then(function(response) {
                if (response) {					
                    return response.data;
                }
                return null;
            });
        },
        deleteDigitalAgendaTemplate: function(agendaTemplateId){
            var request = ajaxService.deleteDigitalAgendaTemplate(agendaTemplateId);
            return request.then(function(response) {
                if (response) {					
                    return response.data;
                }
                return null;
            });
        }
    }
    return service;
}
	