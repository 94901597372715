bvItemList.$inject = ['imageService', 'localizedMessages'];

function bvItemList(imageService, localizedMessages) {
	return {
		restrict: 'A',
		scope: {
		  showRenderSpinner: '=',
		  showTsTreeGrid: '=',
		  selectionDone: '=',
		  showUserOptions: '=',
		  showOptionsButtonsForUsers: '=',
		  items: '=',
		  instantSelection: '=',
		  showRemoveInsteadOfCheckOption: '=',
		  hideCompanyTab : '=',
		  smartSelection: '='
		},
		templateUrl: 'directives/bvitemlist/bvItemList.template.html',
		controller: 'itemListController'
	}
}

//showUserOptions:
	// SHOW_CURRENT_TS_USERS : showCurrentTsUsers,
	// SHOW_CURRENT_TS_USERS_AND_GRP : showCurrentTsUsersAndGrp,
	// SHOW_ALL_USERS : showAllUsers,
	// SHOW_CUSTOM_USERS : showCustomUsers

