function eventBusService() {
	/**
	 * Returns the subscription which can be used to unsubscribe
	 **/
	var subscribe = function(eventName, callback) {
		if (window.EventBus && window.EventBus.subscribe) {
            return window.EventBus.subscribe(eventName, callback);
        }
		else
			return null;
	};

	var publish = function(eventName, arg) {
		if (window.EventBus && window.EventBus.publish)
			window.EventBus.publish(eventName, arg);
	};

	return {
		subscribe : subscribe,
		publish : publish
	}
}