angular
  .module("taskmanagement", ["react"])
  .directive("taskManagement", [
    "reactDirective",
    function(reactDirective) {
      return reactDirective(task.TaskManagement, [
        "value",   
        "reactInterface",
        "controllerInterface"
      ]);
    }
  ])
  .controller("TaskManagementController", TaskManagementController);
