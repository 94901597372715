function dashboardNxtv2Service ($q, ajaxService, responseValidatorService){
	
	var getDashboard = function(request){		
		var dashboard = {
				id:1,
				layout:'',
				cards:[
					{card:'CustomContentCard', permission:''},
					{card:'CalendarCard', permission:''},
					{card:'MeetingsCard', permission:''},
					{card:'NewItemsCard', permission:''}]
				};
		
		return dashboard;
	};
		
	return {
		getDashboard : getDashboard
	};
};

angular
	.module('dashboardNxtv2.service', [ 'ajax.service' ])
	.service('dashboardNxtv2Service', ['$q','ajaxService', 'responseValidatorService', dashboardNxtv2Service]);