function bvSliderInstanceService($injector){
	this.instances = {}

	this.addInstance = function(id){
		if(id && !this.instances[id]){
			this.instances[id] = $injector.instantiate(SliderService);
		}
	}

	this.clear = function(){
		this.instances = {}
	}
}