saveSurveyTemplateFromContextMenuController.$inject = ['$scope', 'context', 'localizedMessages', 'surveysService', 'bvDialog'];

function saveSurveyTemplateFromContextMenuController($scope, context, localizedMessages, surveysService, bvDialog){

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    var surveyBaseUuid = $scope.bvModalControllerInputs.surveyBaseUuid;
    $scope.templateName = $scope.bvModalControllerInputs.templateName
    
    $scope.ui = {
    	    label: {}
    	}
    $scope.ui.label.name_of_template = localizedMessages.get("NAME_OF_TEMPLATE");
    $scope.ui.label.cancel = localizedMessages.get("CANCEL");
    $scope.ui.label.save = localizedMessages.get("SAVE");
    
    $scope.changeTemplateName = function(name) {
    	$scope.templateName = name;
    }
    
    $scope.cancel = function() {
    	bvModal.close();
    }
    
    $scope.saveTemplate = function(){
    	surveysService.createSurveyTemplate(surveyBaseUuid, $scope.templateName).then(data => {
    	    if(data.boName == "SuccessBO")
                bvDialog.showMessage(localizedMessages.get("TEMPLATE_SAVED"), null, true)
    	})
    	$scope.cancel();
    }
    
}