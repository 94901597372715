/*
  @since 09.22.2021
 * @author Ulf Lindbäck
*/
// Requirements: moment.js need to be loaded (with locales and timezones)

// NBV currently accepts two types of date formats:
//
// Examples of accepted symbols for Day, Month, Year  format:
// input: 'DD/MM/YY', 'DD/MM/YYYY', 'DD.MM.YY', 'DD.MM.YYYY', 'DD-MMM-YYYY', 'YYYY-MM-DD'
// the output can be either: 
// 		'DD MMMM YYYY' (long date),
// 		'DD MMM YYYY' (abbreviated date),
//		'DD/MM/YYYY' (short date),
//		DD MMM (day and month long)
//	
//
// Examples of accepted symbols for Month, Day, Year  format:
// input: Can be any format that not applies to Day, Month, Year. This format will always be the default one.
// the output can be either: 
// 		'MMMM DD, YYYY' (long date),
// 		'MMM DD, YYYY' (abbreviated date),
//		'MM/DD/YYYY' (short date),
//		MMM DD (day and month long)
//		

// There are two types of time formats: 12 and 24
//
// Examples of accepted symbols for the 12 hours format:
// input: hh:mm => output: 01:10 PM
// input: h:mm => output: 1:10 PM
// input: h:mm A => output: 1:10 PM
// input: h:mm a => output: 1:10 pm
// 
// If any of the inputs above do not apply, 
// the format will always return the 24 hours: HH:mm

// Example of usage:
// var formatter = new DateTimeFormatUtility().setDateTime("2019-01-23 15:44").setFormat("YYYY-MM-DD", "HH:mm")
// formatter.formattedDateTime() // gives: 2019-01-23 15:44
// formatter.dateFormat() // gives: YYYY-MM-DD

// or more simple if just one output is needed:
// new DateTimeFormatUtility().setDateTime("2019-01-23 15:44").setFormat("YYYY-MM-DD", "HH:mm").formattedDateTime(); // gives: 2019-01-23 15:44

// Example of how to use instead of current: dateTimeFormatUtility.getMeetingsFormattedTime(startDateGMTMillis, endDateGMTMillis, timeFormat, moment.tz.guess(true));
// var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat); // .setTimezone( moment.tz.guess(true)) (default behaviour)
// var result = f.setUtcDateTime(startDateGMTMillis).formattedTime() + ' - ' + f.setUtcDateTime(endDateGMTMillis).formattedTime();

function DateTimeFormatUtility() {
	var savedMoment;
	var dateFmt = 'MM/DD/YYYY'; // default date
	var timeFmt = 'h:mm A'; // default time

	var alternativeLongDate = ['DD/MM/YY', 'DD/MM/YYYY', 'DD.MM.YY', 'DD.MM.YYYY', 'DD-MMM-YYYY', 'YYYY-MM-DD']; // Note, last YYYY-MM-DD is not predefined NBV format, but added in case OnPrem customers would set it in DB

	var isDayMonthYearFormat = function(dateFormat) {
		return alternativeLongDate.indexOf(dateFormat) > -1;
	};

	var translateDateFormats = function(dateFmt) {
		var longDate = 'MMMM DD, YYYY';
		var abbrDate = 'MMM DD, YYYY';
		var shortDate = 'MM/DD/YYYY';
		var monthLongDay = 'MMM DD'

		if (isDayMonthYearFormat(dateFmt)) {
			longDate = 'DD MMMM YYYY';
			abbrDate = 'DD MMM YYYY';
			shortDate = 'DD/MM/YYYY';
			monthLongDay = 'DD MMM';
		}

		return {
			longDate: longDate,
			abbrDate: abbrDate,
			shortDate: shortDate,
			monthLongDay: monthLongDay
		};
	};

	var translateTimeFormat = function(timeFormat, abbreviated) {
		var formatParts = timeFormat.split(':');
		var is12hoursFormat = formatParts[0].includes('h');

		if (is12hoursFormat) {
			var tail = formatParts[1];
			var meridiem = tail.split(' ')[1];
			var hours = abbreviated ? 'h' : 'hh';
			if(meridiem) {
					return hours + ':mm' + ' ' + meridiem;
			} else {
					return hours + ':mm A'
			}
		}

		return 'HH:mm';
	}

	///
	/// Setters
	///

	// keepTime defaults to true, if false, it will change current time to specified timezone
	this.setTimezone = function (timezone, keepTime) {
		var keepTimeTmp = true;
		
		if (keepTime !== undefined && keepTime === false) {
				keepTimeTmp = keepTime;
		}

		savedMoment.tz(timezone, keepTimeTmp);
		return this;
	};


	this.setLocale = function (locale) {
		savedMoment.locale(locale);
		return this;
	};

	// Set time/date to a moment object
	// Currently uses timezone of new moment if exists, otherwise falls back to timezone of original moment object
	// Currently uses locale of new moment if not 'en', otherwise locale of orignal moment.
	this.setMoment = function (m) {
		var tmpTz;
		var tmpLocale = 'en';
		if (savedMoment) {
			if (savedMoment.tz()) {
					// console.log("using tz of original moment:" + savedMoment.tz())
					tmpTz = savedMoment.tz();
			}
			tmpLocale = savedMoment.locale();
		}
		if (m.tz()) {
			// console.log("using tz of new moment: " + m.tz());
			tmpTz = m.tz();
		}
		if (m.locale() != 'en') {
			tmpLocale = m.locale();
		}
		if (!tmpTz) {
			tmpTz = moment.tz.guess();
			// console.log("guessing tz:  " + tmpTz);
		}
		savedMoment = m.clone();
		this.setTimezone(tmpTz); // Keeps time
		this.setLocale(tmpLocale);
		return this;
	};

	// Set time/date using a JS Date, string or milliseconds (Date().getTime())
	this.setDateTime = function (value) {
		// Do we need to switch moment to en locale first? Probably not since we default the locale created to 'en'
		return this.setMoment(new moment(value));
	};

	this.setUtcDateTime = function (value) {
		// Save original tz of saved moment
		var tmpTz = savedMoment.tz();
		// Set original tz of moment to UTC to keep time
	savedMoment.tz('UTC');
		// Create new moment of utc time
		this.setMoment(moment.utc(value));
		// Reset original tz
		savedMoment.tz(tmpTz);
		return this;
	};

	// Set time/date using a JS Date, string or milliseconds (Date().getTime())
	this.setUnixTimestamp = function (value) {
		return this.setMoment(new moment(value * 1000));
	};

	// sets date/time format of user
	this.setFormat = function (dateFormat, timeFormat) {
		if (!timeFormat) {
			throw new SyntaxError('Time and date formats should be passed as 2 arguments');
		}
		dateFmt = dateFormat;
		timeFmt = timeFormat;
		return this;
	};

	///
	/// Getters
	///
	// Get copy of moment object, changing it won't change formatter
	this.toMoment = function () {
		return savedMoment.clone();
	};

	// Get a Date object from formatter
	this.toDate = function () {
		return savedMoment.toDate();
	};

	// Get date/time using current formats
	this.formattedDate = function () {
		return savedMoment.format(dateFmt);
	};

	// Get date/time using current formats
	this.formattedLongDate = function () {
		var longDateFormat = translateDateFormats(dateFmt).longDate;
		return savedMoment.format(longDateFormat);
	};

	this.formattedAbbrDate = function () {
		var abbrDateFormat = translateDateFormats(dateFmt).abbrDate;
		return savedMoment.format(abbrDateFormat);
	};

	this.formattedShortDate = function () {
		var shortDateFormat = translateDateFormats(dateFmt).shortDate;
		return savedMoment.format(shortDateFormat);
	}

	this.formattedDayOfWeekLongDate = function () {
		return `${this.dayLong()}, ${this.formattedLongDate()}`;
	};

	this.formattedDayOfWeekAbbrDate = function () {
		return `${this.dayLong()}, ${this.formattedAbbrDate()}`;
	};

	this.formattedTime = function (abbreviated=true) {
		var timeFormat = translateTimeFormat(timeFmt, abbreviated);
		return savedMoment.format(timeFormat);
	};

	this.formattedDateTime = function () {
		return savedMoment.format(dateFmt + ' ' + timeFmt);
	};

	this.formattedDateTimeTz = function () {
		return savedMoment.format(dateFmt + ' ' + timeFmt + ' zz');
	};

	this.year = function () {
		return savedMoment.year();
	};

	// month gives 1-12, to get zero based month, use monthZeroBased
	this.month = function () {
		return savedMoment.month() + 1;
	};

	// monthZeroBased gives months 0-11
	this.monthZeroBased = function () {
		return savedMoment.month();
	};

	this.monthLong = function () {
		return savedMoment.format('MMM');
	};

	this.monthLongWithDay = function () {
		var monthLongDayFmt = translateDateFormats(dateFmt).monthLongDay;
		return savedMoment.format(monthLongDayFmt)
	};

	this.dayLong = function () {
		return savedMoment.format('dddd');
	};

	this.day = function () {
		return savedMoment.date();
	};

	this.hour = function () {
		return savedMoment.hours();
	};

	this.minute = function () {
		return savedMoment.minutes();
	};

	this.second = function () {
		return savedMoment.seconds();
	};

	this.iso = function () {
		return savedMoment.toISOString();
	};

	// get timestamp (JS Date.getTime();)
	this.timestamp = function () {
		return savedMoment.valueOf();
	};

	// get  seconds since the Unix epoch
	this.epoch = function () {
		return savedMoment.unix();
	};

	this.timeFormat = function () {
		return timeFmt;
	};

	this.translatedTimeFormat = function (abbreviated) {
		return translateTimeFormat(timeFmt, abbreviated);
	};

	this.is12hoursFormat = function () {
		var formatParts = timeFmt.split(':');
		return formatParts[0].includes('h');
	};

	this.dateFormat = function () {
		return dateFmt;
	};

	this.dateFormats = function () {
		return translateDateFormats(dateFmt);
	};

	this.dateTimeFormat = function () {
		return this.dateFormat() + ' ' + this.timeFormat();
	};

	this.dateFormatTz = function () {
		return this.dateFormat() + ' ' + this.timezone();
	};

	this.dateTimeFormatTz = function () {
		return this.dateTimeFormat() + ' ' + this.timezone();
	};

	// eg EST, America/New_York
	this.timezone = function () {
		return savedMoment.tz();
	};
	// e.g. -06:00
	this.timezoneOffset = function () {
		return savedMoment.format('Z');
	};

	// e.g. -300
	this.timezoneOffsetMinutes = function () {
		return savedMoment.utcOffset();
	};

	// Initiate moment with current date/time
	this.setMoment(new moment());
	return this;
}
