previewSurveyService.$inject = ['bvDialogService', 'localizedMessages'];

function previewSurveyService(bvDialogService, localizedMessages){

    this.dialog = {}

    this.showPreviewDialog = function (survey){
        this.dialog = bvDialogService.showBvModal({
            windowClass : 'preview-survey-dialog',
            controller : 'previewSurveyController',
            buttons      : null,
            bodyTemplate : 'modules/surveys/dialogs/previewSurvey.tpl.html',
            inputs : {
                survey: survey,
                preview: true
            }
        });
    }

    this.showOverviewDialog = function (survey){
        this.dialog = bvDialogService.showBvModal({
            windowClass : 'preview-survey-dialog',
            controller : 'previewSurveyController',
            buttons      : null,
            bodyTemplate : 'modules/surveys/dialogs/overviewSurvey.tpl.html',
            inputs : {
                survey: survey,
                overview: true
            }
        });
    }
}