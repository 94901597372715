function factoryBranding(legacyUserService){
	var color = {}
	var colorMenu = {}
	color.value = "#CCC";
	return {
		color: function(backgroundColor, textColor){

			var cssBackground =
			[
			 	'.bvBackgroundColor',
//			 	'.nav-tabs > li.active > bt',
			 	'.bvhome footer',
//			 	'.bv-menu-box',
//			 	'.ui-dialog-buttonpane button',
//			 	'.bv-modal-blue-header',
//			 	'.bv-inbox-btn-blue',
			 	'.newItemsMoreBtn'
			];

            if(!legacyUserService.isNasdaqUI)
			    cssBackground.push('.nav-tabs > li.active > bt');

			var cssColorText =
			[
			 '.bvTextColor',
			 '.bvhome .bv-topmenu li bt ',
			 '.bvhome .rightMenu li bt .iconText',
//			 '.nav-tabs > li.active > bt',
//			 '.ui-dialog-buttonpane button',
//			 '.bv-inbox-btn-blue',
			 '.newItemsMoreBtn',
			];

            if(!legacyUserService.isNasdaqUI)
			    cssColorText.push('.nav-tabs > li.active > bt');

			backgroundColor = backgroundColor.replace('#','').trim();
			textColor = textColor.replace('#','').trim();
			StringUtils.cssChange(cssBackground, 'background-color:#' + backgroundColor + ' !important;');
			StringUtils.cssChange(cssColorText, 'color:#' + textColor + ' !important;');
			var svgs = angular.element('.middleBarIconSvg');
			if(svgs.length != 0 && textColor!=undefined)
			{
				for (var i = 0 ; i < svgs.length ; i++) {
					var svg = svgs[i];
					svg.setAttribute('fill','#' + textColor);
				}
			}
		
		},
		colorMenu: function(menuColor){
			var cssColorMenu =
				[
				 '.bvMenuColor',
				 '.bvhome .bv-scrollbar .bv-menu li bt .iconText',
				 '.bv-menu-box .boardvantage',
				];
			const expandButton = [
				'.bv-menu-box .expand-button .rect1',
				'.bv-menu-box .expand-button .rect2',
				'.bv-menu-box .expand-button .rect3'
			]
			menuColor = menuColor.replace('#','').trim();
			StringUtils.cssChange(cssColorMenu, 'color:#' + menuColor + ';');
			StringUtils.cssChange(expandButton, 'background-color:#' + menuColor + ' !important;');
			var svgs = angular.element('.menuIconSvg');
			if(svgs.length != 0)
			{
				for (var i = 0 ; i < svgs.length ; i++) {
					var svg = svgs[i];
					svg.setAttribute('fill','#' + menuColor);
				}
			}
		}

	}
}