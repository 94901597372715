constants.$inject = ['constantsList'];

function constants(constantsList) {

  var handleNotFound = function (msg, msgKey) {
    return msg || '?' + msgKey + '?';
  };

  return {
    get : function (msgKey) {
      var msg =  constantsList[msgKey];
      if (msg) {
        return msg;
      } else {
        return handleNotFound(msg, msgKey);
      }
    }
  };
}