angular.module('localization.service', ['i18N.messages'])
.factory('localizedMessages', ['$interpolate', '$window', '$http', 'I18N.MESSAGES', function ($interpolate, $window, $http, i18nmessages) {

  var bvLocale = {

      localeFileUrl:undefined,
      localeData: undefined,
      localeVal: undefined,

      setBvLocaleData: function(data) {
    	  bvLocale.localeData = data.localeData;
    	  bvLocale.localeVal = data.initialLocale;
    	  moment.locale(bvLocale.localeVal);
      },

      getBvLocaleString: function(key)
      {
          var result = '';
          if ((bvLocale.localeData != undefined))
          {
              result = bvLocale.localeData[key];
              //console.log("KEY:",key," VALUE:",result);
          }
          return result;
      },

      get : function (msgKey, interpolateParams)
      {
          var result = bvLocale.getBvLocaleString(msgKey);
          if(result && result!='')
          {
              while(result.indexOf("\\{\\{") != -1) {
                  result = result.replace("\\{\\{", "{{");
                  result = result.replace("\\}\\}","}}");
              }
              return $interpolate(result)(interpolateParams);
          }
          else
          {
            console.log("VALUE NOT FOUND FOR KEY",msgKey);
            var msg =  i18nmessages[msgKey];
            if (msg)
            {
                return $interpolate(msg)(interpolateParams);
            }
            else
            {
                return msg || '?' + msgKey + '?';
                //return handleNotFound(msg, msgKey);
            }
          }
      },

      getLocaleDate : function(date, format)
      {
        if(format == undefined || format == "" || format == null) {
            format = 'll';
        }

        return moment(date).locale(bvLocale.localeVal).format(format);
      },

      getLocaleDateOnLocalTimezone : function(date, format)
      {
        if(format == undefined || format == "" || format == null) {
            format = 'll';
        }

        var timezone = moment.tz.guess();
        return moment(date).tz(timezone).locale(bvLocale.localeVal).format(format);
      },

      getLocale : function()
      {
          return bvLocale.localeVal;
      }
  };
  	return bvLocale;
}]);
