adSearchFilterPanelController.$inject = ['$scope', '$timeout', 'localizedMessages', 'adSearchFilterPanelService', 'adSearchService', 'advancedSearchService', 'adSearchQueryBoxService', 'context'];

function adSearchFilterPanelController($scope, $timeout, localizedMessages, adSearchFilterPanelService, adSearchService, advancedSearchService, adSearchQueryBoxService, context){
    $scope.title = {
        refineResults : localizedMessages.get('REFINE_RESULTS'),
        dateRange : localizedMessages.get('DATE_RANGE'),
        teamSpaces : localizedMessages.get('TEAM_SPACES'),
        documentsDetail : localizedMessages.get('DOCUMENTS_DETAILS'),
        reset: localizedMessages.get('RESET'),
        sinceLastVisit: localizedMessages.get('SINCE_LAST_VISIT'),
        yesterday: localizedMessages.get('YESTERDAY'),
        today: localizedMessages.get('TODAY'),
        last: localizedMessages.get('LAST'),
        days: localizedMessages.get('DAYS'),
        months: localizedMessages.get('MONTHS'),
        customDateRange: localizedMessages.get('CUSTOM_DATE_RANGE'),
        from: localizedMessages.get('FROM'),
        to: localizedMessages.get('TO'),
        allTeamSpaces: localizedMessages.get('ALL_TEAM_SPACES'),
        selectedTeamSpaces: localizedMessages.get('SELECTED_TEAM_SPACES'),
        searchTeamSpaces: localizedMessages.get('SEARCH_TEAM_SPACES'),
        appliedTeamSpaces: localizedMessages.get('APPLIED_TEAM_SPACES'),
        appliedKeywords: localizedMessages.get('APPLIED_KEYWORDS'),
        appliedExcludedKeywords: localizedMessages.get('APPLIED_EXCLUDED_KEYWORDS'),
        appliedPhrases: localizedMessages.get('APPLIED_PHRASES'),
        keywords: localizedMessages.get('KEYWORDS'),
        specificPhrase: localizedMessages.get('SPECIFIC_PHRASE'),
        exclude: localizedMessages.get('EXCLUDE'),
        searchFilesWithTheseWords: localizedMessages.get('SEARCH_FILES_WITH_THESE_WORDS'),
        searchFilesWithThisPhrase: localizedMessages.get('SEARCH_FILES_WITH_THIS_PHRASE'),
        excludeFilesContainingTheseWords: localizedMessages.get('EXCLUDE_FILES_CONTAINING_THESE_WORDS'),
        fileType: localizedMessages.get('FILE_TYPE'),
        pdf: localizedMessages.get('PDF_UPPERCASE'),
        msWord: localizedMessages.get('MS_WORD'),
        msExcel: localizedMessages.get('MS_EXCEL'),
        msPowerpoint: localizedMessages.get('MS_POWERPOINT'),
        others: localizedMessages.get('OTHERS'),
        reachedMsg: localizedMessages.get('YOU_HAVE_REACHED_THE_MAXIMUM_OF_CHARACTERS'),
        reachedMsgMaxKeywords: localizedMessages.get('YOU_HAVE_REACHED_THE_MAXIMUM_OF_5_KEYWORDS'),
        reachedMsgMaxExludedKeywords: localizedMessages.get('YOU_HAVE_REACHED_THE_MAXIMUM_OF_5_EXCLUDED_KEYWORDS'),
        reachedMsgMaxPhrases: localizedMessages.get('YOU_HAVE_REACHED_THE_MAXIMUM_OF_5_PHRASES')
    }

    $scope.validateKeyword = {
        maxlength: 100,
        msg: $scope.title.reachedMsg
    };

    $scope.validatePhrase = angular.copy($scope.validateKeyword);
    $scope.validateExclude = angular.copy($scope.validateKeyword);

    var adSearch = adSearchService.getInstance('ADVANCED');
    $scope.searchFilterObject = adSearchFilterPanelService.getSearchFilterObject('L');

    var sfo = $scope.searchFilterObject;
    adSearch.setFilterPanel(sfo);

    sfo.addKeyword(adSearch.searchString);

    sfo.fileSelection = context.getModel().companyVO.advanceSearchFileTypes.split(',').map(function(o){
        var titl = '';
        switch(o){
            case 'doc':     titl = $scope.title.msWord; break;
            case 'xls':     titl = $scope.title.msExcel; break;
            case 'ppt':     titl = $scope.title.msPowerpoint; break;
            case 'pdf':     titl = $scope.title.pdf; break;
            case 'others':  titl = $scope.title.others; break;
        }

        return { name: o, title : titl != '' ? titl : o, value : true }
    });

    $scope.dateRangeShow = false;
    $scope.dateRange = function(){
        $scope.dateRangeShow = !$scope.dateRangeShow;
    }

    $scope.toggleDropdown = function($event) {
		$event.preventDefault();
	    $event.stopPropagation();
	    $scope.status.isopen = !$scope.status.isopen;
  	};

  	$scope.keydownKeywords = function(e){
  	    if(StringUtils.isEnterKey(e)){
  	        var addedKeyword = sfo.addKeyword();
  	        if(addedKeyword === false)
  	            sfo.keywordSelection.validation.setValidate($scope.title.reachedMsgMaxKeywords, true, false);
  	        else if(addedKeyword === true){
                adSearchService.recallServer();
  	            if(sfo.keywordSelection.list.length >= sfo.keywordSelection.max)
                    sfo.keywordSelection.validation.setValidate($scope.title.reachedMsgMaxKeywords, true, true);
            }
  	    }
  	}

  	$scope.keydownSpecific = function(e){
  	    if(StringUtils.isEnterKey(e)){
            var addedSpecific = sfo.addSpecific(e.target.value);
            if(addedSpecific === false)
                sfo.phraseSelection.validation.setValidate($scope.title.reachedMsgMaxPhrases, true, false);
            else if(addedSpecific === true){
                adSearchService.recallServer();
                if(sfo.phraseSelection.list.length >= sfo.phraseSelection.max)
                    sfo.phraseSelection.validation.setValidate($scope.title.reachedMsgMaxPhrases, true, true);
            }
        }
  	}

  	$scope.keydownExclude = function(e){
  	    if(StringUtils.isEnterKey(e)){
            var addedExclude = sfo.addExclude();
            if(addedExclude === false)
                sfo.excludeSelection.validation.setValidate($scope.title.reachedMsgMaxExludedKeywords, true, false);
            else if(addedExclude === true){
                adSearchService.recallServer();
                if(sfo.excludeSelection.list.length >= sfo.excludeSelection.max)
                    sfo.excludeSelection.validation.setValidate($scope.title.reachedMsgMaxExludedKeywords, true, true);
            }
  	    }
  	}

  	$scope.clickHidePanel = function(){
        adSearch.filterPanel.show = !adSearch.filterPanel.show;
  	}

  	$scope.resetAll = function(){
        sfo.resetRange();
        sfo.resetTeamspaceSelection();
        sfo.resetDocumentsDetails();

        adSearch.filterPanelInstance.updateKeywords(adSearchQueryBoxService.searchString.val);
        adSearchService.recallServer();
        $scope.selectedTeamSpaces = isSelectedAnyTeamSpace();
  	}

    //* Filter triggers
    $scope.dateChangeFrom = function(){
        var rangeSelection = sfo.rangeSelection;
        rangeSelection.from = StringUtils.momentL(rangeSelection.datePickFrom.startingDateLocal).valueOf();

        adSearchService.recallServer();
    }
    $scope.dateChangeTo = function(){
        var rangeSelection = sfo.rangeSelection;
        rangeSelection.to = StringUtils.momentL(rangeSelection.datePickTo.startingDateLocal).valueOf();

        adSearchService.recallServer();
    }

    $scope.addKeyword = function(){
        adSearchQueryBoxService.setString(_.uniq(sfo.getAllKeywords()).join(' '));
    }

    $scope.removeKeyword = function(keyword){
        var index = sfo.keywordSelection.list.indexOf(keyword);
        if(index != -1){
            sfo.keywordSelection.list.splice(index, 1);
            adSearchQueryBoxService.setString(sfo.getAllKeywords().join(' '));
        }

        sfo.keywordSelection.validation.setValidate($scope.title.reachedMsg, false, false);

        adSearchService.recallServer();
    }

    $scope.removePhrase = function(phrase){
        var index = sfo.phraseSelection.list.indexOf(phrase);
        if(index != -1){
            sfo.phraseSelection.list.splice(index, 1);
        }

        sfo.phraseSelection.validation.setValidate($scope.title.reachedMsg, false, false);

        adSearchService.recallServer();
    }

    $scope.removeExclude = function(exclude){
        var index = sfo.excludeSelection.list.indexOf(exclude);
        if(index != -1){
            sfo.excludeSelection.list.splice(index, 1);
        }

        sfo.excludeSelection.validation.setValidate($scope.title.reachedMsg, false, false);

        adSearchService.recallServer();
    }

    $scope.addFileType = function(){
        adSearchService.recallServer();
    }

    var isSelectedAnyTeamSpace = function(){
        return $scope.selectedTeamSpaces = !!_.find(sfo.teamspaceSelection, { selected : true });
    }

    $scope.resetTeamspaceSelection =function(){
        sfo.resetTeamspaceSelection(true);
        $scope.selectedTeamSpaces = isSelectedAnyTeamSpace();
    }

    $scope.selectTeamSpace = function(space){
        sfo.selectTeamSpace(space);
        $scope.selectedTeamSpaces = isSelectedAnyTeamSpace();
    }
}