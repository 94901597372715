var TaskManagementController = [
  "$scope",
  "$state",
  "reactInterface",
  "localizedMessages",
  function($scope, $state, reactInterface, localizedMessages) {
    var taskFormIntent, projectFormIntent, backButtonIntent;
    
    $scope.openTaskForm = function() {
      taskFormIntent();
    };
    $scope.openProjectForm = function() {
      projectFormIntent();
    };
    $scope.backButtonHandler = function() {
      backButtonIntent();
    };

    const addTaskItem = {
      title: "+ " + localizedMessages.get('Add_Task'),
      click: $scope.openTaskForm,
      iconUrl: "",
      svgFill: "#fff"
    };

    const addProjectItem = {
      title: "+ "  + localizedMessages.get('Add_Project'),
      click: $scope.openProjectForm,
      iconUrl: "",
      svgFill: "#fff"
    };

    const backButton = {
      title: localizedMessages.get('BACK'),
      click: $scope.backButtonHandler,
      iconUrl: "",
      svgFill: "#fff"
    };

    var rightMenuList = [addTaskItem, addProjectItem, backButton];

    $scope.svgFill = '#FFF';
    $scope.middleBarObject.showBack = false;
    $scope.middleBarObject.title = localizedMessages.get('TASKMANAGEMENT');

    $scope.tabs = [
      {
        title: localizedMessages.get('TASKMANAGEMENT'),
        content: "taskManagement",
        rightMenuList: rightMenuList,
        order: 0
      }
    ];

    $scope.accessControl = function(values) {
      let tabs = [];
      let mobileMenu = [];
      const { canAddTasks, canAddProjects, shouldRenderBackButton } = values;
      if (canAddTasks) {
        tabs = [addTaskItem];
        mobileMenu.push({name: localizedMessages.get('Add_Task'), click: $scope.openTaskForm, value: "add_task"});
      }
      if (canAddProjects) {
        tabs.push(addProjectItem);
        mobileMenu.push({name: localizedMessages.get('Add_Project'), click: $scope.openProjectForm, value: "add_project"});
      }
      if (shouldRenderBackButton) {
        $scope.middleBarObject.showBack = true;
      } else {
        $scope.middleBarObject.showBack = false;
      }


      $scope.tabs = [
        {
          title: localizedMessages.get('TASKMANAGEMENT'),
          content: "taskManagement",
          rightMenuList: tabs,
          order: 0
        }
      ];

      $scope.taskManagementMobileMenu = mobileMenu;
      
    };
    
    $scope.handleLeftIcon = function(){
      console.log('i am clicking a left button');
      $scope.backButtonHandler();
    }
    
    
    $scope.$parent.currentState.value = $state.$current.name;
    $scope.reactInterface = reactInterface;
	
	console.log("the plink value", reactInterface.getPermalinkData());
	
    $scope.controllerInterface = {
      bindTaskFormIntent: function(fn) {
        taskFormIntent = fn;
      },
      bindProjectFormIntent: function(fn) {
        projectFormIntent = fn;
      },
      bindBackButton: function(fn) {
        backButtonIntent = fn;
      },
      bindAccessControl: function(fn) {
        return $scope.accessControl;
      }
    };
  }
];
