angular.module('userRegistration.service',['ajax.service'])
.factory('userRegistrationService',  [ '$q', 'ajaxService', 'responseValidatorService', function($q, ajaxService, responseValidatorService) {

	// Is the current user authenticated?
	var _isAuthenticated = function(user) {	
		var authenticated = responseValidatorService.isSuccess(user);
		return authenticated;
	}
	
	var userLoginDetails = {};
	
	var service = {
		securityquestion:'',
	    username:'',
	    userPlink:'',
	    challengeQuestionsList: [],
		
		getUserData : function(plink) {
			this.userPlink = plink;
			var request = ajaxService.getUserData(plink);
			return request.then(function(response){
				if(response.data){
					return response.data;
				}
			});
		},

		setUserPlink : function(plink) {
			this.userPlink = plink;
		},

		getUserPlink : function() {
			return this.userPlink;
		},

		setChallengeQuestionsList : function(QList) {
			console.log("Setting list:",QList);
			this.challengeQuestionsList = QList;
		},

		getChallengeQuestionsList : function() {
			return this.challengeQuestionsList;
		},
		
		getCountryNameValueList : function(){
			var request = ajaxService.getCountryNameValueList();
			return request.then(function(response){
				if(response.data){
					return response.data;
				}
			});
		},
		
		checkUserByLoginId: function(loginId) {
			var request = ajaxService.checkUserByLoginId(loginId);
			return request.then(function(response){
				if(response.data){
					return response.data;
				}
				return null;
			});
		},

		getChallengeQuestion: function(plink, locale) {
			var request = ajaxService.getChallengeQuestion(plink, locale);
			return request.then(function(response){
				if(response.data){
					return response.data;
				}
				return null;
			});
		},
		
		registerUser: function(loginVO) {
			var request = ajaxService.registerUser(loginVO);
			return request.then(function(response){
				if(response.data)
					{
						return response.data;
					}
				return null;
			});
		},
		
		getEmailRegExp : function() {
			var request = ajaxService.getEmailRegExp();
			return request.then(function(response){
				if(response.data)
					{
						return response.data;
					}
			});
		},
		
		getPasswordPreferencesForPlink : function(plink) {
			var request = ajaxService.getPasswordPreferencesForPlink(plink);
			return request.then(function(response){
				if(response.data)
					{
						return response.data;
					}
			});
		},
		
		setUserDetails : function(loginDetails) {
			userLoginDetails = loginDetails;
		},
		
		getUserDetails : function() {
			return userLoginDetails;
		},
		
		invalidate: function() {
			var request = ajaxService.invalidate(); 
			return request.then(function(response) {
				if(response.data)
				{
			    	return response.data;
				}
			  	return null;
			});
		}

	};
	return service;
}]);