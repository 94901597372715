function bvSlider(){
    return {
        restrict: 'A',
        transclude: true,
        scope: {
            data: '=bvSlider'
        },
        replace: true,
        templateUrl: 'directives/bvslider/BvSlider.template.html',
        controller: 'bvSliderController'
    }
}
