topPanel.$inject = ['$http', '$templateCache', '$compile', 'toppanelService'];

function topPanel($http, $templateCache, $compile, toppanelService){
	return {
		restrict: 'E',
		scope: {
		    object: "="
		},
		link: function(scope, element, attrs){
		    if(scope.object){

		        var toppanel = toppanelService.createInstance(scope.object.name);
		        toppanel.setObject(scope.object);
		        toppanel.setScope(scope);

                var templateUrl = toppanel.getObject().templateUrl;
                var template = $templateCache.get(templateUrl);

                var render = function(template){
                    element.html(template);
                    $compile(element.contents())(scope);
                }

                if(template){
                    render(template);
                }
                else{
                    $http.get(templateUrl).then(function(response) {
                        $templateCache.put(templateUrl, response.data);
                        template = $templateCache.get(templateUrl);
                        render(template);
                    });
                }

                toppanel.updateEvents();
            }
		}
	}
}