angular.module('meetingDateRange', [])
		.service('meetingDateRangeService', meetingDateRangeService);

function meetingDateRangeService() {
	
	var meetingRangeObject = {};
	var m = moment;
    var ml = StringUtils.momentL;
    
	var MeetingDateRangeObject = function(dateFormat) {
		var dateFormat = dateFormat || 'll';
		this.rangeSelection = {
			select : '',
			from : 0,
			to : 0,
			datePickFrom : {},
			datePickTo : {},
			lastVisit : false,
			dateFormat : dateFormat
		}
		
		this.calculateRange();
	}
	
	MeetingDateRangeObject.prototype = {
	        constructor : MeetingDateRangeObject,
	        calculateRange : function(){
                
	        	this.rangeSelection.from = m().valueOf();
	        	this.rangeSelection.to = m().valueOf();
                this.rangeSelection.datePickFrom.startingDate = m(this.rangeSelection.from).format(this.rangeSelection.dateFormat);
                this.rangeSelection.datePickFrom.startingDateLocal = ml(this.rangeSelection.from).format(this.rangeSelection.dateFormat);
                this.rangeSelection.datePickTo.startingDate = m(this.rangeSelection.to).format(this.rangeSelection.dateFormat);
                this.rangeSelection.datePickTo.startingDateLocal = ml(this.rangeSelection.to).format(this.rangeSelection.dateFormat);
                
               // this.rangeSelection.lastVisit = false;
//                this.rangeSelection.datePickFrom.instance.datePickerResetMinMaxDate();
//                this.rangeSelection.datePickTo.instance.datePickerResetMinMaxDate();
//                this.rangeSelection.from = m().valueOf();
//                this.rangeSelection.to = m().valueOf();
	        }
	}
	
	this.getMeetingRangeObject = function(dateFormat){
        if(!(meetingRangeObject instanceof MeetingDateRangeObject)){
        	meetingRangeObject = new MeetingDateRangeObject(dateFormat);
        }
        return meetingRangeObject;
    }
}