angular
	.module('dialogControllers.service')
	.controller('supportController', supportController);

supportController.$inject = ['$scope', 'localizedMessages', 'commonService', 'context', 'homeService', 'bvDialogService', 'loginService', 'bvDialog', 'legacyUserService'];

function supportController($scope, localizedMessages, commonService, context, homeService, bvDialogService, loginService, bvDialog, legacyUserService){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var isLoginSupport = $scope.bvModalControllerInputs.isLoginSupport != null ? $scope.bvModalControllerInputs.isLoginSupport : false;

	$scope.emailLbl = localizedMessages.get("EMAIL");
	$scope.versionLbl = localizedMessages.get("VERSION");
	$scope.phoneLbl = localizedMessages.get("PHONE");
	$scope.buildLbl = localizedMessages.get("BUILD");
	$scope.serverLbl = localizedMessages.get("SERVER");
	$scope.platformLbl = localizedMessages.get("PLATFORM");
	$scope.supportCenterLbl = localizedMessages.get("SUPPORT_CENTER");
	$scope.knowledgeCenterLbl = localizedMessages.get("KNOWLEDGE_CENTER");
	$scope.hideSupportLink=true;
	$scope.isDesktopClient = MobileUtil.isDesktopClient();
	$scope.isEnhancedAuthenticationEnabled = loginService.getEnhancedAuthenticationEnabled();
	

	$scope.linkClicked=function(email)
	{
		if(email)
			window.location.href="mailto:" + email;
	}

	if(!isLoginSupport){
		$scope.enableParatureSupportForUser = context.getModel().enableParatureSupportForUser;
		$scope.popupTitle = localizedMessages.get('SUPPORT');
		commonService.getSupportInfo().then (function(data)
				{
					$scope.supportValues = data;
					$scope.numbers = $scope.supportValues[0].split(',');
					$scope.emails =  $scope.supportValues[1].split(',');
					for (var i=0; i<$scope.numbers.length; i++)
					{
						var index = $scope.numbers[i].indexOf(' ');
						if(index==0)
						{
							$scope.numbers[i] = localizedMessages.get("SHOW_COUNTRY_CODE") + $scope.numbers[i].substr(1, $scope.numbers[i].length);
						}
					}
					if(data.length> 6){
						//Runtime Diagnostic values are present
						$scope.serverVersion = $scope.supportValues[3];
						$scope.vmName = $scope.supportValues[4];
						$scope.vmVendor = $scope.supportValues[5];
						$scope.vmVersion = $scope.supportValues[6];
					}
				});
	}
	else{

		loginService.getAppSupportInfo().then(function(data){
			$scope.supportValues = data.data;
//				$scope.supportValues = {				//Sample support response
//				"Phone" : "(650) 330-0857, (877) 330-8051, (877) 330-8055",
//				"Email"	: "ecsupport1@BoardVantage.com,bvsupport1@BoardVantage.com",
//				"Version" : "12.0-hc1502",
//				"Build": "61221",
//				"Platform" : "Platform",
//				"Server" : "Server",
//				"ServerResponse" : "[\"(650) 330-0857, (877) 330-8051, (877) 330-8055\",\"ecsupport1@BoardVantage.com,bvsupport1@BoardVantage.com\",\"13.0-mbp-2241\"]"
//		};
			$scope.url = ""
			$scope.numbers = $scope.supportValues['Phone'] != "" ? $scope.supportValues['Phone'].split(',') : "";
			$scope.emails =  $scope.supportValues['Email'] != "" ? $scope.supportValues['Email'].split(',') : "";

			var servResp = $scope.supportValues['ServerResponse'];

			if(servResp != '' && servResp != undefined){
				servResp = angular.fromJson(servResp);
				if(servResp[0] != "" && servResp[0] != undefined){
					$scope.numbers = servResp[0].split(',');
					for (var i=0; i<$scope.numbers.length; i++)
					{
						var index = $scope.numbers[i].indexOf(' ');
						if(index==0)
						{
							$scope.numbers[i] = localizedMessages.get("SHOW_COUNTRY_CODE") + $scope.numbers[i].substr(1, $scope.numbers[i].length);
						}
					}
				}
				if(servResp[1] != "" && servResp[1] != undefined)
					$scope.emails =  servResp[1].split(',');
				$scope.server = servResp[2];
				}
		});

		$scope.popupTitle = localizedMessages.get('INFORMATION');
	}

	$scope.resetAllData = function(){
		localStorage.removeItem("username");
		localStorage.removeItem("sectierauthcookie");
		localStorage.removeItem("smsauthcookie");
		loginService.resetAllAppData().then(function(data){
			if(data.boName == "SuccessBO"){
				bvDialog.showMessage(localizedMessages.get("RESET_APP_DATA_SUCCESS"));
			}
		});
	}

	$scope.goToSupportCenter = function()
	{
		$scope.enableSFSupportForUser = context.getModel().enableSFSupportForUser;
		
		if(!context.getModel().userVO.email){
			bvDialog.showMessage("Email cannot be empty to access support link.");
			return;
		}
		
		if($scope.enableSFSupportForUser){
			bvDialogService.openNewWindow("/portlet/samlConnect.jsp", "_blank");
			return;
		}
		
		if(MobileUtil.isSurface())
		{
			// This call just let Surface App know that Support Center link is clicked and need to show Support Center in web browser
			homeService.getSecurePassThroughRefIDJson();
	    }
		else
		{
			var nAgt = window.navigator.userAgent;
			var isMSIE = ( nAgt != null && nAgt.indexOf( "MSIE" ) != -1 );
			var url = "/portlet/blank.html";
			var windowName = "Support Center";
			var dynamicHtml =
			"<html><head><title>BoardVantage</title></head>"+
			"<body onLoad='document.Customer_Support.submit();'>"+
				"<form name='Customer_Support' method='post' action='/parature/support.php'>"+
					"<input type='hidden' name='fname' value='" + context.getModel().userVO.firstName  + "'>"+
					"<input type='hidden' name='lname' value='" + context.getModel().userVO.lastName + "'>"+
					"<input type='hidden' name='email' value='" + context.getModel().userVO.email + "'>"+
					"<input type='hidden' name='company' value='" + context.getModel().companyVO.name + "'>"+
				"</form>"+
			"</body>"+
			"</html>";
			if(isMSIE)
				windowName = "Support";
			var win  = null;
			var win = window.open(url, windowName);
			win.document.write(dynamicHtml);
			win.document.close();
			win.focus();
			return win;
		}
		/*var paratureURL = context.getModel().paratureURL;
		var paratureInstanceId = context.getModel().paratureInstanceId;

		homeService.getSecurePassThroughRefIDJson()
    	.then(function(data)
		{
    		var refId = data.data;
    		if(!StringUtils.isNullEmpty(refId))
    		{

    			var url = "/portlet/blank.html";
    			var windowName = "Support Center";
    			var dynamicHtml =
    				"<html><head><title>BoardVantage</title></head>"+
					"<body onLoad='document.Customer_Support.submit();'>"+
					"<form name='Customer_Support' method='post' action='" + paratureURL + "'>"+
					"<input type='hidden' name='refID' value='" + refId + "'>"+
					"<input type='hidden' name='instanceID' value='" + paratureInstanceId + "'>"+
					"</form>"+
					"</body>"+
					"</html>";
    			if(isMSIE)
    				windowName = "Support";
    			var win  = null;
    			var win = window.open(url, windowName);
    			win.document.write(dynamicHtml);
    			win.document.close();
    			win.focus();
    			return win;

    		}
		});*/
	}

    if(legacyUserService.isNasdaqUI)
   	    $scope.knowledgeCenterUrl = context.getModel().companyVO.knowledgeCenterUrl;

   	$scope.isNasdaqUI = legacyUserService.isNasdaqUI;
   	if(context.getModel()!=null)
   	{
   		$scope.enableSupportLink = context.getModel().enableSupportLink;
   	}
}
