ContextMenuHandler.$inject = ['bvDialogService','$modal', 'commonService','repositoryService','context','localizedMessages','conferenceService','copyPasteService', 'bvDialog', 'templatesService', 'urlService','newItemsService','bvDialogMobile', 'homeModel','repositoryModelService', 'messagesService', '$state', 'homeService', 'toppanelService', 'annotationHandlingService','Upload'];

function ContextMenuHandler(bvDialogService, modal, commonService, repositoryService, context, localizedMessages, conferenceService, CopyPasteService, bvDialog, templatesService, urlService, newItemsService, bvDialogMobile, homeModel, repositoryModelService, messagesService, $state, homeService, toppanelService, annotationHandlingService,Upload)
{

	this.editFile = function(documentObject)
    {
		if(documentObject.checkedOutBy != null && documentObject.checkedOutBy != '') 
    	{
			showCheckedOutAlert(documentObject);
    		return;
    	} 
		var docId = documentObject.id ? documentObject.id : documentObject.objectBaseUuid;
        var editType = context.getModel().editNative;
        var isDocBoardBook = false;
        if(docId)
        {
            isDocBoardBook = StringUtils.isBoardbook(docId);
        }
        if((editType && editType === "virtualEdit") && !isDocBoardBook)
        {
            if(docId)
            {

                var uid = context.getModel().userVO.objectBaseUuid;
                repositoryService.checkConnectionAvailable(uid).then(function(data)
                {
                    //console.log("CONNECTION FLAG:",data);
                    if(data && data == "true")
                    {
                        var blocale = MobileUtil.getBLocale();
                        var ht = MobileUtil.getHeight();
                        var wt = MobileUtil.getWidth();

                        //console.log("CONNECTION STATUS:",data);
                        repositoryService.startVirtualEdit(docId, blocale, ht, wt).then(function(data)
                        {
                            var payload = data.payload;
                            repositoryService.getConnectionToken(docId, payload).then(function(data)
                            {
                                //console.log("TOKEN-DATA:",data)
                                var connectionId = "";
                                if(data && data.CONNECTION_ID && data.CONNECTION_ID!=undefined)
                                {
                                    try
                                    {
                                        connectionId = data.CONNECTION_ID;
                                        connectionId = connectionId.trim();
                                    }
                                    catch(err)
                                    {
                                        connectionId = "";
                                        console.log("ERROR:",err);
                                    }
                                }

                                if(connectionId!="" && connectionId!=null)
                                {
                                    var EDIT_URL = "/bvedit/#/client/"+connectionId;

                                    homeModel.vEditModal = {};
                                    homeModel.vEditModal.docId = docId;
                                    homeModel.vEditModal.connectionId = connectionId;

                                 	var lblExit = localizedMessages.get('EXIT');

                                	//homeModel.vEditModal = new modal.open({
                                	homeModel.vEditModal.docDlg = new modal.open({
                                    	//template: "<iframe id=\"vEditframe\" src=\""+EDIT_URL+"\" style=\"width:100%; height:100%; border:1px solid#ccc;\"></iframe>",
                                    	template:   "<div class=\"vEditContainerDiv\" style=\"height:100%;width:100%;\">"+
                                                "<div class=\"veditHeaderMenuDiv\" style=\"\">"+
                                                    "<ul class=\"veditHeaderMenu\">"+
                                                        //"<li ng-click=\"vEditFullScreen()\" style=\"border-right: 1px solid #FFFFFF;\">FullScreen</li>"+
                                                        //"<li ng-if=\"!IS_FULLSCREEN\" ng-click=\"vEditFullScreen()\" style=\"border-right: 1px solid #FFFFFF;\">FullScreen</li>"+
                                                        //"<li ng-if=\"IS_FULLSCREEN\" ng-click=\"exitVEditFullScreen()\" style=\"border-right: 1px solid #FFFFFF;\">Exit FullScreen</li>"+
                                                        //"<li ng-if=\"!IS_FULLSCREEN\" ng-click=\"vEditInNewWindow()\" style=\"border-right: 1px solid #FFFFFF;\">New Window</li>"+
                                                        //"<li ng-if=\"!IS_FULLSCREEN\" ng-click=\"closeVEdit()\">Close</li>"+
                                                        "<li class=\"veditHeaderSubMenuFullScreen\" ng-click=\"vEditFullScreen()\" style=\"border-right: 1px solid #FFFFFF;\">FullScreen</li>"+
                                                        "<li class=\"veditHeaderSubMenuExitFullScreen\" ng-click=\"exitVEditFullScreen()\" style=\"border-right: 0px solid #FFFFFF;\">Exit FullScreen</li>"+
                                                        //"<li class=\"veditHeaderSubMenuFullScreen\" ng-click=\"vEditInNewWindow()\" style=\"border-right: 1px solid #FFFFFF;\">New Window</li>"+
                                                        "<li class=\"veditHeaderSubMenuFullScreen\" ng-click=\"confirmExitVEdit()\">"+lblExit+"</li>"+
                                                    "</ul>"+
                                                "</div>"+
                                                "<div style=\"height:100%;width:100%;\">"+
                                                    "<iframe id=\"vEditframe\" src=\""+EDIT_URL+"\" style=\"width:100%; height:100%;border:0px;\"></iframe>"+
                                                "</div>"
                                                +"</div>"
                                                ,

                                    	windowClass: "vEditDialog",
                                    	backdrop: 'static',
                                    	//controller: ['$scope', '$document', '$timeout', '$modalInstance', 'mediaModelService', 'viewerContextMenu', function($scope, $document, $timeout, $modalInstance, mediaModelService, viewerContextMenu)
                                    	controller: ['$scope', '$timeout', 'bvDialog', 'localizedMessages', function($scope, $timeout, bvDialog, localizedMessages)
                                    	{
                                        	//$scope.IS_FULLSCREEN = false;
                                        	$scope.EXIT_ALERT_MSG = localizedMessages.get("MSG_EXIT_VEDIT");
                                        	$timeout(function(){
                                            	var editFrame = document.querySelector('#vEditframe');
                                            	editFrame.contentWindow.focus();
                                        	}, true);

                                        	$scope.exitVEdit = function()
                                        	{
                                            	try
                                            	{
                                                	var connId = homeModel.vEditModal.connectionId;
                                                	console.log("CONN_ID:",connId);
                                                	homeModel.vEditModal.docDlg.close();
                                                	commonService.cancelVEdit(connId);
                                            	}
                                            	catch(err)
                                            	{
                                                	console.log("Error while closing vedit window. Error:",err);
                                           		}
                                            	finally
                                            	{
                                                	homeModel.vEditModal = {};
                                            	}
                                        	}

                                        	$scope.confirmExitVEdit = function()
                                        	{
                                            	bvDialog.confirmNoBackdrop($scope.EXIT_ALERT_MSG, $scope.exitVEdit, $scope.cancelVEditExit);
                                        	}

                                        	$scope.cancelVEditExit = function()
                                        	{
                                            	console.log("NO: NOT EXITING");
                                            	$timeout(function(){
                                                	var editFrame = document.querySelector('#vEditframe');
                                                	editFrame.contentWindow.focus();
                                            	}, true);
                                        	}

                                        	$scope.vEditFullScreen = function()
                                        	{
                                            	$scope.IS_FULLSCREEN = true;
                                            	//var editDlg = document.querySelector('.vEditDialog .modal-content');
                                            	var editDlg = document.querySelector('.vEditContainerDiv');
                                            	//var editDlg = document.documentElement;
                                            	//var editDlg = document.getElementById("vEditframe").contentWindow.document.querySelector('.client-view-content');
                                            	//console.log("EDIT-FRAME:",editDlg);
                                            	if(editDlg)
                                            	{
                                                	if (editDlg.requestFullscreen) {
                                                    	editDlg.requestFullscreen();
                                                	} else if (editDlg.msRequestFullScreen) {
                                                    	editDlg.msRequestFullscreen();
                                                	} else if (editDlg.mozRequestFullScreen) {
                                                    	editDlg.mozRequestFullScreen();
                                                	} else if (editDlg.webkitRequestFullscreen) {
                                                    	editDlg.webkitRequestFullscreen();
                                                	}
                                                	var editFrame = document.querySelector('#vEditframe');
                                                	if(editFrame)
                                                	{
                                                    	editFrame.contentWindow.focus();
                                                	}
                                                	//editDlg.contentWindow.focus();
                                            	}
                                        	}

                                        	$scope.exitVEditFullScreen = function()
                                        	{
                                            	$scope.IS_FULLSCREEN = false;
                                            	//var editDlg = document.querySelector('.vEditDialog .modal-content');
                                            	//var editDlg = document.querySelector('.vEditContainerDiv');
                                            	var editDlg = document;
                                            	//console.log("EDIT-FRAME:",editDlg);
                                            	if(editDlg)
                                            	{
                                               		if (editDlg.exitFullscreen) {
                                                    	editDlg.exitFullscreen();
                                                	} else if (editDlg.msExitFullscreen) {
                                                    	editDlg.msExitFullscreen();
                                                	} else if (editDlg.mozCancelFullScreen) {
                                                    	editDlg.mozCancelFullScreen();
                                                	} else if (editDlg.webkitCancelFullScreen) {
                                                    	editDlg.webkitCancelFullScreen();
                                                	}
                                                	var editFrame = document.querySelector('#vEditframe');
                                                	if(editFrame)
                                                	{
                                                    	editFrame.contentWindow.focus();
                                                	}
                                            	}
                                        	}

                                        	$scope.vEditInNewWindow = function()
                                        	{
                                            	try
                                            	{
                                                	window.open(EDIT_URL);
                                                	homeModel.vEditModal.docDlg.close();
                                            	}
                                            	catch(err)
                                            	{
                                                	console.log("Error while closing vedit window. Error:",err);
                                            	}
                                            	finally
                                            	{
                                                	homeModel.vEditModal = {};
                                            	}
                                        	}
                                    	}]
                                	});
                                }
                                else
                                {
                                    var alertMsg = localizedMessages.get('MSG_VIRTUAL_EDIT_INTERNAL_ERROR');
                                    bvDialog.showMessage(alertMsg);
                                }
                            });
                        });
                    }
                    else
                    {
                        var alertMsg = localizedMessages.get('MSG_VIRTUAL_EDIT_CONNECTION_NOT_AVAILABLE');
                        bvDialog.showMessage(alertMsg);
                    }
                });
            }
        }
        else
        {
        	if(documentObject.isStampingComplete=="true"){
        		showAlertForSignStampedDoc(documentObject);
        		//return;
        	}
        	else{
        		 repositoryService.sendNativeEditFile().then(function(data)
            {
             var bvEditJsonVO = data.data;
             var token = context.getModel().token;
             var baseuuid =  documentObject.id ? documentObject.id : documentObject.objectBaseUuid;
             var runtimeExec = context.getModel().userVO.runtimeExec;
             var editOption = 'editandlink';
             var appArguments = "codeBase=/dvclient/,code=com.boardvantage.dv.applet.core.SingleContentProcessor.class,jsessionid="+bvEditJsonVO.jsessionid;
             appArguments +=",token="+token+",useruuid="+bvEditJsonVO.useruuid+",companyuuid="+bvEditJsonVO.companyuuid+",processName="+bvEditJsonVO.processName+",editOption="+editOption+",baseuuid="+baseuuid+",portalid="+bvEditJsonVO.portalid+"";
             appArguments +=",runtimeExec="+runtimeExec+",serverName="+bvEditJsonVO.serverName;// server
             appArguments +=",serverDomain="+bvEditJsonVO.hostName+",ECXLBCookie="+bvEditJsonVO.ecxlbCookie;
             appArguments +=",authHeader="+localStorage.requestCsrfToken;
             repositoryService.sendAlertToSubscribedObject(baseuuid).then(function(data){
                console.log('Alert send during file edit');
            });
             launchCustomProtocol("bvedit://"+appArguments+"",launchBVECXVaultApp);
            })
        	}
        }
    }

    this.createAgenda = function(documentObject)
    {
        repositoryService.createAgenda(documentObject);
    }

    this.uploadFolder = function(documentObject)
    {
        repositoryService.sendNativeEditFile().then(function(data)
        {
            var bvEditJsonVO = data.data;
            var token = context.getModel().token;
            //			var baseuuid =  documentObject.objectBaseUuid;
            var runtimeExec = context.getModel().userVO.runtimeExec;
            var editOption = 'editandlink';
            var appArguments = "codeBase=/dvclient/,code=com.boardvantage.dv.applet.core.SingleContentProcessor.class,jsessionid="+bvEditJsonVO.jsessionid;
            appArguments +=",token="+token+",useruuid="+bvEditJsonVO.useruuid+",companyuuid="+bvEditJsonVO.companyuuid+",processName=upload,editOption="+editOption+",baseuuid="+documentObject+",portalid="+bvEditJsonVO.portalid+"";
            appArguments +=",runtimeExec="+runtimeExec+",serverName="+bvEditJsonVO.serverName;
            appArguments +=",serverDomain="+bvEditJsonVO.hostName+",ECXLBCookie="+bvEditJsonVO.ecxlbCookie;
            launchCustomProtocol("bvedit://"+appArguments+"",launchBVECXVaultApp);
        })
    }

	var launchCustomProtocol = function(url, callback)
    {
    	/*detectBrowserVersion();
    	if (browserName == "Microsoft Internet Explorer") {
    			callback(url);
    	} else if ((browserName == "Firefox") || (browserName == "Safari")) {
    		callback(url);
    	} else if (browserName == "Chrome") {*/

        callback(url);

           // Supports older chrome versions
    		/*try {
    			window.location.href = url;
    		} catch (ex) {
    			alert(ex);
    		}*/
    	//}
    }

    var getProtocol = function (){
        window.alert("Edit and Link App is not installed on your computer. Please go to Preferences Menu to download and install.");
    }

    var launchBVECXVaultApp = function(url) {
        var ifrm = document.createElement("iframe");
        var userAgent = window.navigator.platform;
        if (userAgent.indexOf("Win") != -1)
        {
            ifrm.setAttribute("src", url);
        }
        else
        {
            ifrm.setAttribute("src", "/portlet/LoadENL.html?enlUrl="+url);
        }
        ifrm.style.display = "none";
        ifrm.onerror = getProtocol;
        document.body.appendChild(ifrm);
    }

    var nVer = window.navigator.appVersion;
    var nAgt = window.navigator.userAgent;
    var browserName = window.navigator.appName;
    var fullVersion = '' + parseFloat(window.navigator.appVersion);
    var majorVersion = parseInt(window.navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    var detectBrowserVersion = function()
    {
        // In Opera, the true version is after "Opera" or after "Version"

        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent

        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"

        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"

        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox"

        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent

        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }

        // trim the fullVersion string at semicolon/space if present

        if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        //MSIE 11 has changed the User agent string with new standard. Following is to detect IE11 onwards
        //Sample User Agent String : Mozilla/5.0 (Windows NT 6.3; Trident/7.0; .NET4.0E; .NET4.0C; rv:11.0) like Gecko
        if(browserName == "Netscape" && ((verOffset = nAgt.indexOf("rv:")) != -1)) {
            browserName = "Microsoft Internet Explorer";
            majorVersion = parseInt(nAgt.substring(verOffset + 3, nAgt.lastIndexOf(')')),10);
        }
    }

    this.openFile = function(businessObject)
    {
    	repositoryService.openPdfViewer(businessObject);
    };
    
    this.openViewAsMenu = function(businessObject)
    {
        var selectUsersLabel = localizedMessages.get("SELECT_USERS")
        bvDialog.itemListSelection(selectUsersLabel, false, function(selectedUsers){

            if(selectedUsers.length > 0 && selectedUsers[0] != undefined && selectedUsers[0] != null)
            {
                var selectedDoc = angular.copy(businessObject);
                selectedDoc.viewAsUserId = selectedUsers[0].objectBaseUuid;
                selectedDoc.viewAsUserName = selectedUsers[0].name;

                repositoryService.openPdfViewer(selectedDoc);
            }

        }, "SHOW_CURRENT_TS_USERS", true);
    };

//	this.renameSelectedItem = function(businessObject)
//    {
//       // $scope.bvinputTitle = localizedMessages.get('RENAME');
//        //$scope.repositoryDisplayObjects.text = $scope.selectedObjectFromContextMenu.name;
//
//		var request = bvDialogService.showRename(businessObject);
//
//		return request.then(function(renamedText)
//        {
//            console.log("Renamed text:",renamedText)
//            return renamedText;
//        }
//		,function(message, exception){
//			console.log("PDF load error: " + message);
//			return "Erroi";
//		});
//
//    };


    var onDocumentMove = function()
    {
        if(StringUtils.isNullEmpty(folders) || folders.length == 0)
        {
            $modalInstance.dismiss('cancel');
            return "";
        }

        targetBaseUuid = folders[0].objectBaseUuid;

        if(selectedObjects && selectedObjects.length > 0)
        {
            documentsToJSONMove = angular.toJson(selectedObjects);
        }
        else
        {
            documentsToJSONMove = angular.toJson([selectedObjects.objectBaseUuid]);
        }

        var request = repositoryService.moveDocuments(treeSelectionModel.getSelectedNode().objectBaseUuid, targetBaseUuid , documentsToJSONMove, "");

        return request.then(function(data)
        {
            $modalInstance.close(data.data);
        })
    }

    this.openFolderChooser = function(businessObjects)
    {
        bvDialog.openFolderChooser(localizedMessages.get("MOVE"), onDocumentMove, businessObjects);
    };


    this.openPermission = function(businessObject)
    {
        bvDialog.permissions(businessObject);
    };

    this.addToFavorites = function(businessObjects)
    {
        var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(businessObjects);

        if(documentsToJSON != null)
        {
            repositoryService.addToBriefCase(documentsToJSON).then(function(data){
                bvDialog.showMessage(localizedMessages.get("SUCCESS_MSG_ADDED_TO_BRIEFCASE"), null, true);
            });
            
            var repositoryModel = repositoryModelService.getInstance("FAVORITES");
        	//for (var m in businessObjects)
    		//{	
            if(!repositoryModel.repositoryList().browseList){
            	repositoryModel.repositoryList().browseList = [];
            }
        		repositoryModel.repositoryList().browseList.push(businessObjects);
    		//}
            this.clearSelection(businessObjects);
        }
    };
    
    
    this.removeFromBriefCase = function (businessObjects)
    {
        var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(businessObjects);

        repositoryService.removeFromBriefCase(documentsToJSON).then(function(data)
        {
            if(data.data)
            {
                var event = new Event('removeFromBriefCaseEvent', { bubbles: true, cancelable: false })
                document.body.dispatchEvent(event);   
                var favoritesModel = repositoryModelService.getInstance("FAVORITES");
                favoritesModel.repositoryList(data.data);
                repositoryModel.repositoryList(data.data);
                
                $scope.menuParentCallback("REMOVE_FROM_FAVORITES", businessObjects);
                $scope.noFavorites = data.data.length == 0 ? true : false;
                $scope.msg_no_favorites = localizedMessages.get('MSG_COMMON_NO_DOCUMENTS');
            }

        });
        this.clearSelection(businessObjects);
    }
 
    this.removeFromAllBriefCases = function (documents)
    {
        bvDialog.confirm(localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DOCUMENT'), function ()
        	    {
            var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(documents);
            repositoryService.removeDocumentsFromUsersFavoriteJSON(documentsToJSON).then(function(data)
            {
                if(data != undefined || data != null)
                {
                    if(data == true) {
                        bvDialog.showMessage(localizedMessages.get("MSG_REMOVE_SUCCESSFUL"), null, true);
                    } else if(data == false) {
                        bvDialog.showMessage(localizedMessages.get("MSG_DOC_NOT_FOUND_IN_BRIEFCASE"));
                    }
                }

            });
        },null);
    }
    

    
    this.removeFromAllBriefCasesConfirm = function (selectedDocs)
    {
        var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(selectedDocs);
        repositoryService.removeDocumentsFromUsersFavoriteJSON(documentsToJSON).then(function(data)
        {
            if(data != undefined || data != null)
            {
                if(data == true) {
                    bvDialog.showMessage(localizedMessages.get("MSG_REMOVE_SUCCESSFUL"), null, true);
                } else if(data == false) {
                    bvDialog.showMessage(localizedMessages.get("MSG_DOC_NOT_FOUND_IN_BRIEFCASE"));
                }
            }

        });
    }
    
    this.removeFromAllBriefCases = function (documents)
    {
        var cofirmMsg = localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DOCUMENT');
        bvDialog.confirm(cofirmMsg, this.removeFromAllBriefCasesConfirm(documents));
    }
    

    this.checkout = function(businessObject)
    {
    	if(businessObject.checkedOutBy != null && businessObject.checkedOutBy != '') 
    	{
    		showCheckedOutAlert(businessObject);
    		return;
    	}
  
    	if(businessObject.objectBaseUuid != null)
        {
    		var objectBaseUuid = businessObject.objectBaseUuid;
    		var fileItemVerid = businessObject.objectVersionUuid;
    		var userid = context.getModel().userVO.objectBaseUuid;
    		var displayName = context.getModel().userVO.name;
    		repositoryService.checkInCheckOut(objectBaseUuid, true, false).then(function(data){
    			window.location = "/services/download?versionUuid=" + fileItemVerid+ "&baseuuid=" + objectBaseUuid;
    			businessObject.checkedOutBy = userid;
    			businessObject.checkedOutByName = displayName;
            });
        }
    };
    
    this.checkin = function(businessObject, parentFolder)
    {
    	if(businessObject.objectBaseUuid != null)
        {
    		var objectBaseUuid = businessObject.objectBaseUuid;
    		var uploadFileObject = { action:'checkin', baseId:objectBaseUuid, parentId:parentFolder.objectBaseUuid, multipleUpload:false, docName: businessObject.name };
            bvDialogService.checkinFile(uploadFileObject);
//            repositoryService.sendDocCheckedInNotification(objectBaseUuid);
        }
    };
    
    this.cancelCheckout = function(businessObject, parentForder)
    {
    	if(businessObject.objectBaseUuid != null)
    	{
    		var objectBaseUuid = businessObject.objectBaseUuid;
    		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_CHECKED_OUT_DOC_ALERT'), function(){
    			repositoryService.sendDocCancelCheckedOutNotification(objectBaseUuid);
    			repositoryService.checkInCheckOut(objectBaseUuid, false, true).then(function(data){
    				businessObject.checkedOutBy = '';
    			});
    		});
    	}
    }
    
    this.addToUserFavorites = function(selectedDocument)
    {
        bvDialog.addToUserFavorites(selectedDocument);
    };

    this.copy = function(businessObjects, parentFolder)
    {
        CopyPasteService.currentAction = CopyPasteService.COPY;
        CopyPasteService.copiedObjects = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(businessObjects);
        CopyPasteService.copiedParentObejectVO = parentFolder.objectBaseUuid;

        repositoryService.copyDocOrFolder(CopyPasteService.copiedObjects, CopyPasteService.copiedParentObejectVO).then(function(data)
        {

        });
    };

    this.copylink = function(businessObject)
    {
       // bvDialog.copyLink(urlService.geCopyLinkURL(businessObject.objectBaseUuid));
    	bvDialog.copyLink(urlService.geCopyLinkOriginalURL(businessObject.objectBaseUuid));
        // if(businessObject && businessObject.objectBaseUuid)
        // {
        // 	bvDialog.showMessage(urlService.geCopyLinkURL(businessObject.objectBaseUuid), 'bv-copy-link-confirm');
        //   	}
    };

    this.cut = function(businessObjects, parentFolder)
    {
        CopyPasteService.currentAction=CopyPasteService.CUT;
        CopyPasteService.copiedObjects = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(businessObjects);
        CopyPasteService.copiedParentObejectVO = parentFolder.objectBaseUuid;

        repositoryService.cutDocOrFolder(CopyPasteService.copiedObjects, CopyPasteService.copiedParentObejectVO).then(function(data)
        {

        });
    };

    this.paste = function(businessObjects,parentFolder)
    {
    	CopyPasteService.targetObject = parentFolder.objectBaseUuid;
    	CopyPasteService.pasteTarget = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(businessObjects);
    	var pasteTarget = angular.fromJson(CopyPasteService.pasteTarget);
        var copiedObjects = angular.fromJson(CopyPasteService.copiedObjects);

        if(copiedObjects != null)
        {
            var len = copiedObjects.length;

            for (var i = 0; i < len; i++)
            {
                if(copiedObjects[i] == parentFolder.objectBaseUuid)
                {
                    bvDialog.showMessage(localizedMessages.get("CAN_NOT_PASTE_INSIDE_ITSELF", {"objectName" : parentFolder.name}));
                    return;
                }
            }
            if(CopyPasteService.targetObject != pasteTarget)
            {
                CopyPasteService.currentAction=CopyPasteService.PASTE;
                repositoryService.pasteDocOrFolder(pasteTarget).then(function(data)
                {
                    CopyPasteService.currentAction = "";
                });
        	}
            else
            {
            CopyPasteService.currentAction=CopyPasteService.PASTE;
            repositoryService.pasteDocOrFolder(CopyPasteService.targetObject).then(function(data)
            {
            	angular.element('repositoryList').scope().getDocumentsForParentBaseUuid(parentFolder.objectBaseUuid);
                CopyPasteService.currentAction = "";
            });
            }     
        }
    }

    this.deleteBusinessObject = function(businessObjects)
    {
        var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(businessObjects);

        var request = repositoryService.recycleListofBaseUuids(documentsToJSON);

        return request.then(function(data)
        {
            if(!StringUtils.isNullEmpty(data.data) &&  !StringUtils.isNullEmpty(data.data.invalidFileNames))
            {
                var invalidFileNames =  data.data.invalidFileNames;
                var alertMsg = localizedMessages.get('ERR_LACK_OF_PREVILEGE_TO_MOVE_TO_TRASH') + " \n \r "
                var len = invalidFileNames.length

                for (var i = 0; i < len; i++)
                {
                    alertMsg = alertMsg +" "+(i+1)+" "+invalidFileNames[i] +" \n \r";
                }

                bvDialog.showMessage(alertMsg);
            }

            return request;
        }
        ,function(message, exception){
            console.log("Error in Deleting Document" + message);
            return "Erroi";
        });
    };

    this.shareDocuments = function(businessObjects)
    {
        if(businessObjects != null)
        {
            //			bvDialogService.showShareDocuments(businessObjects);
            var bvModalInputs = {}
            bvModalInputs.windowClass = 'bv-modal-window-compose-new-message';
            bvModalInputs.inputs = {};
            bvModalInputs.buttons = [{},{}];
            bvModalInputs.inputs.messageType = 'compose';
            bvModalInputs.inputs.popupTitle = localizedMessages.get("NOTIFY");
            bvModalInputs.inputs.businessObjects = businessObjects;
            bvModalInputs.inputs.alwaysSecure = context.getModel().companyVO.alwaysSendSecureEmail;
            bvModalInputs.controller = 'ComposeMessageController';
            bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');

    		messagesService.isSecureEnabled().then(function(response)
    		{
    			bvModalInputs.inputs.secure = response.data;
    			bvDialogService.showBvModal(bvModalInputs);
    		});

        }
    }

    this.meobileComposeMessage = function(businessObjects)
    {
        bvDialogMobile.meobileComposeMessage('compose',businessObjects);
    }

    this.addToNewItems = function(businessObjects)
    {
        if(businessObjects != null)
        {
            bvDialog.addToNewItems(businessObjects);
            this.clearSelection(businessObjects);
        }
    };

    this.openDocumentPresenter = function (selectedDocument)
    {
        if(selectedDocument!=undefined || selectedDocument!=null)
        {
            commonService.getUsers().then (function(data)
            {
                var bvUsers = (data==null) ? null : data;
                if(bvUsers!=null || bvUsers!=undefined)
                {
                    var popupTitle = localizedMessages.get('SELECT_USERS');
                    popupTitle = (popupTitle==null || popupTitle==undefined || popupTitle == "") ? "Select Users" : popupTitle ;
                    bvDialogService.showUserSelection(bvUsers, popupTitle).then(function(conferenceParticipants)
                    {
                        if(conferenceParticipants.length == 0)
                        {
                            var alertMsg = localizedMessages.get('ERR_USERS_NOT_SELECTED_FOR_PRESENTATION');
                            bvDialog.showMessage(alertMsg);
                        }
                        else
                        {
                            //var docObjectBaseId = $scope.selectedObjectFromContextMenu.objectBaseUuid;
                            var selectedDocuments = [];
                            selectedDocuments.push(selectedDocument);

                            console.log("SELECTED DOCUMENT......",selectedDocument);

                            var d = new Date();
                            var startDateVO = { "minute": d.getMinutes(), "month": d.getMonth() , "dayOfMonth": d.getDate(), "gmtMilliSeconds": d.getTime() ,  "hour": d.getHours() , "millisecond":"0", "year":d.getFullYear(), "second":"0"};
                            var endDateVO   = { "minute": d.getMinutes(), "month": d.getMonth() , "dayOfMonth": d.getDate(), "gmtMilliSeconds": d.getTime() ,  "hour": d.getHours() , "millisecond":"0", "year":d.getFullYear(), "second":"0"};

                            conferenceService.createDocumentConference(
                                angular.toJson(selectedDocuments), angular.toJson(conferenceParticipants), angular.toJson(startDateVO), angular.toJson(endDateVO)
                            ).then(function(data)
                            {
                                var cvo = data.data;
                                var homeController = angular.element(document.querySelector('[home-controler]')).scope();
                                homeController.showEventDetailsForInstantMeeting(cvo.objectBaseUuid, cvo.objectVersionUuid);
                            });
                        }
                    });
                }
                else
                {
                    var alertMsg = localizedMessages.get('ERR_USERS_NOT_AVAILABLE_FOR_SELECTION');
                    bvDialog.showMessage(alertMsg);
                }
            });
        }
        else
        {
            alert("Document is not selected");
            console.log("Document is not selected from context menu.");
        }
    };

    this.download = function(selectedDocument){
    	if(selectedDocument.checkedOutBy != null && selectedDocument.checkedOutBy != '') 
    	{
    		showCheckedOutAlert(selectedDocument);
    		return;
    	} else {
    		var objectBaseUuid = selectedDocument.objectBaseUuid;

    		var fileItemVerid = selectedDocument.objectVersionUuid;

    			/*repositoryService.sendNativeEditFile().then(function(data)
    			{
    				var bvEditJsonVO = data.data;
    				var token = context.getModel().token;
    				var runtimeExec = context.getModel().userVO.runtimeExec;
    				var editOption = 'editandlink';
    				var appArguments = "codeBase=/dvclient/,code=com.boardvantage.dv.applet.core.SingleContentProcessor.class,jsessionid="+bvEditJsonVO.jsessionid;
    				appArguments +=",token="+token+",useruuid="+bvEditJsonVO.useruuid+",companyuuid="+bvEditJsonVO.companyuuid+",processName=download,editOption="+editOption+",baseuuid="+objectBaseUuid+",portalid="+bvEditJsonVO.portalid+"";
    				appArguments +=",runtimeExec="+runtimeExec+",serverName="+bvEditJsonVO.serverName;
    				appArguments +=",serverDomain="+bvEditJsonVO.hostName+",ECXLBCookie="+bvEditJsonVO.ecxlbCookie;
    				launchCustomProtocol("bvedit://"+appArguments+"",launchBVECXVaultApp);
    				})*/

    		window.location = "/services/download?versionUuid=" + fileItemVerid+ "&baseuuid=" + objectBaseUuid;
    	}
	};

	this.openPdf=function(selectedDocument)
	{
		var fileItemVerid = selectedDocument.objectVersionUuid;
		var portalID=selectedDocument.portalBaseUuid;
		var token = context.getModel().companyVO;
		var companyID=token.objectBaseUuid;
		var fileName = selectedDocument.name.substr(0,selectedDocument.name.lastIndexOf("."))+".pdf";
		console.log(token);
		///this.openDocumentasPdf(fileItemVerid);
		repositoryService.openDocumentasPdf(fileItemVerid,fileName);

		//window.location="/services/viewdocument/"+selectedDocument.name+"?versionUuid="+fileItemVerid+"&ecxportalid="+portalID+"&ecxcompanyid="+companyID;
		//window.open("/services/viewdocument/"+selectedDocument.name+"?versionUuid="+fileItemVerid+"&ecxportalid="+portalID+"&ecxcompanyid="+companyID,"_blank","height=800,width=800");

	};

	this.versionTracker = function(selectedDocument)
    {
		bvDialog.showVersionTracker(selectedDocument);
    };

    this.properties = function(selectedDocument)
    {
    	bvDialog.showProperties(selectedDocument);
    };

    //HTML-1211
    /*this.createShortCut = function(selectedDocument, parentFolder)
    {
    	var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(selectedDocument);

    	repositoryService.createShortCut(parentFolder.objectBaseUuid, documentsToJSON).then(function(data)
    	{
    		if(data.data)
    		{
    			repositoryModel.repositoryList(data.data);
    		}
    	});
    }*/

    this.unHideFolder = function(selectedDocument, parentFolder)
    {
    	return repositoryService.setHideFolder(selectedDocument.objectBaseUuid, false)
    }

    this.hideFolder = function(selectedDocument, parentFolder)
    {
    	return repositoryService.setHideFolder(selectedDocument.objectBaseUuid, true);
    }

    this.removeFromNewItems = function(selectedDocument)
    {
        var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(selectedDocument);

        repositoryService.removeFromNewItems(documentsToJSON).then(function(data)
        {
            if(data.data)
            {
                var newItemModel = repositoryModelService.getInstance("NEW_ITEM");                           
                _.remove(newItemModel.repositoryList().browseList, item => documentsToJSON.includes(item.objectBaseUuid));               
            }
        });
    }

    this.uploadFile = function(action, selectedDocument, parentFolder)
    {
        var uploadFileObject = {action:action, baseId:selectedDocument.objectBaseUuid, parentId:parentFolder.objectBaseUuid, multipleUpload:true };
        bvDialogService.uploadFile(uploadFileObject);
    };

/* this.makePdfCompliant=function(document,parentFolder){
    	
    	const fields = {
                csrfToken: context.getModel().csrfToken,
                action: "makePdfCompliant",
                totalFiles: 1,
                objectbaseuuid: document.objectBaseUuid,
                uploadNumber: 0,
                parentbaseuuid: parentFolder.objectBaseUuid,
                permissions: []
              }
    	
    	var invokeUtility=function(){
    		console.log("Running util for" + document.name);
    		//repositoryService.makePdfDocumentCompliant(document.objectBaseUuid);
    		Upload.upload({
                url : '/services/fileupload',
                fields : fields
    		})
    	}
    	var confirmationMessage=function(){
    		var objectBaseUuids = [];
    		bvDialog.confirm("Are you sure you want to make the document compliant? "+ document.name, invokeUtility, null);
    	}
    	
    	confirmationMessage();
    	
    }*/

    this.replaceFile = function(selectedDocument, parentFolder)
    {
        var uploadFileObject = { action:'replace', baseId:selectedDocument.objectBaseUuid, parentId:parentFolder.objectBaseUuid, multipleUpload:false };
        if(context.getModel().companyVO.enableVersionBasedAnnotations && context.getModel().companyVO.isPortalOwner){
          repositoryService.getAnnotationCountForAllVersionsOfTheDocument(selectedDocument.objectBaseUuid).then(data => {
            if(data && data[selectedDocument.objectBaseUuid] && data[selectedDocument.objectBaseUuid].length && data[selectedDocument.objectBaseUuid].length > 0 )
              annotationHandlingService.openDialog(uploadFileObject)
            else
              bvDialogService.replaceFile(uploadFileObject);
          })
        }
        else
          bvDialogService.replaceFile(uploadFileObject);
    };

    this.auditTrail = function(selectedDocument)
    {
        bvDialog.showAuditTrail(selectedDocument);
    };

    this.deleteAllAnnotationsInDocument = function(selectedDocument, isReturnRequired)
    {
        bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_ANNOTATIOS_FROM_THE_DOCUMENT"),
            function() {
                repositoryService.deleteAllAnnotationsInDocument(selectedDocument.objectBaseUuid);
                if(isReturnRequired)
                {
                    var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                    homeScope.viewerDeleteAnnots = true;
                }
            },
            function() {
                if(isReturnRequired)
                {
                    var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                    homeScope.viewerDeleteAnnots = false;
                }
            }
        );
    }

    this.rules = function(selectedDocument)
    {
        bvDialog.showRules(selectedDocument);//.objectBaseUuid);
    };

    this.setFollowers = function(selectedDocument)
    {
        repositoryService.getFollowers(selectedDocument.objectBaseUuid).then(function(data){
                var subscribedUsers = [];
                var subscriptions = data.data;
                for(var index=0; index<subscriptions.length; index++){
                    var subscribedUser = {};
                    subscribedUser.objectBaseUuid = subscriptions[index].subscriber.objectBaseUuid;
                    subscribedUser.name = subscriptions[index].subscriber.name;
                    subscribedUsers.push(subscribedUser);
                }
            bvDialog.showSetFollowers(selectedDocument.name, selectedDocument.objectBaseUuid, subscribedUsers);
        });
    };

    this.push = function(selectedDocument)
    {
    	bvDialog.showPush(selectedDocument);
    };

    this.follow = function(selectedDocument, contextMenuCheckOptions) {
    	selectedDocument.isSubscribed=true;
        var selectedDocUuids = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(selectedDocument);
        repositoryService.subscribe(selectedDocUuids);
        var alertMsg = localizedMessages.get('YOU_ARE_FOLLOWING')+ ' ' +selectedDocument.name;
        contextMenuCheckOptions.alreadySubscribed = true;
        bvDialog.showMessage(alertMsg);
    }

    this.stopFollow = function(selectedDocument, contextMenuCheckOptions) {
    	selectedDocument.isSubscribed=false;
        var selectedDocUuids = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(selectedDocument);
        repositoryService.unsubscribe(selectedDocUuids);
        var alertMsg = localizedMessages.get('YOU_ARE_NOT_FOLLOWING')+ ' ' +selectedDocument.name;
        contextMenuCheckOptions.alreadySubscribed = false;
        bvDialog.showMessage(alertMsg);
    }

    this.clearSelection = function(documentList)
    {
        if( !(Object.prototype.toString.call( documentList ) === "[object Array]"))
        documentList = [documentList]

        angular.forEach(documentList, function(document)
        {
            if (document.selected && document.selected == true)
            {
                document.selected = false;
            }
        });

        const toppanel = toppanelService.getInstance('REPOSITORY');
        if(toppanel) toppanel.addEvent('checkboxValue', false);
    }

    this.createAgendaForFolder = function(folder, parent) {
        // repositoryService.createAgendaForFolder(folder.objectBaseUuid, parent.objectBaseUuid).then(function(data){
        //     console.log(data);
        // });
        bvDialog.showFolderAgendaCreator(folder.objectBaseUuid, parent.objectBaseUuid);
    }

    this.openFolderInDocumentModule = function(documentObject) {
        if(context.getModel().portalVO.objectBaseUuid != documentObject.portalBaseUuid){
            homeService.switchTeamSpace({ objectBaseUuid : documentObject.portalBaseUuid }, function(){
                $state.go('home.documents', { folderBaseUuid: documentObject.objectBaseUuid }, { reload: true});
            });
        }
        else
            $state.go('home.documents', { folderBaseUuid: documentObject.objectBaseUuid });
    }

    var showCheckedOutAlert = function(documentObject) {
    	var subscribe = function() {
    		repositoryService.addDocCheckInOutAlertRelationShipType(documentObject.objectBaseUuid);
    	}

    	var close = function(){
            $scope.bvModalControllerInputs.bvModal.close();
        }
    	bvDialog.confirm(localizedMessages.get('MSG_CHECKED_OUT_DOC_ALERT'), subscribe, close);
    }
    
	
    
    var showAlertForSignStampedDoc = function(documentObject) { 
    var sendNativeEditFile = function() {
  		  repositoryService.sendNativeEditFile().then(function(data)
     {
  		             var bvEditJsonVO = data.data;
  		             var token = context.getModel().token;
  		             var baseuuid =  documentObject.id ? documentObject.id : documentObject.objectBaseUuid;
  		             var runtimeExec = context.getModel().userVO.runtimeExec;
  		             var editOption = 'editandlink';
  		             var appArguments = "codeBase=/dvclient/,code=com.boardvantage.dv.applet.core.SingleContentProcessor.class,jsessionid="+bvEditJsonVO.jsessionid;
  		             appArguments +=",token="+token+",useruuid="+bvEditJsonVO.useruuid+",companyuuid="+bvEditJsonVO.companyuuid+",processName="+bvEditJsonVO.processName+",editOption="+editOption+",baseuuid="+baseuuid+",portalid="+bvEditJsonVO.portalid+"";
  		             appArguments +=",runtimeExec="+runtimeExec+",serverName="+bvEditJsonVO.serverName;//server
  		             appArguments +=",serverDomain="+bvEditJsonVO.hostName+",ECXLBCookie="+bvEditJsonVO.ecxlbCookie;
  		             appArguments +=",authHeader="+localStorage.requestCsrfToken;
  		             repositoryService.sendAlertToSubscribedObject(baseuuid).then(function(data){
  		                console.log('Alert send during file edit');
  		            });
  		             launchCustomProtocol("bvedit://"+appArguments+"",launchBVECXVaultApp);
  		            })
  	}
    	//bvDialog.confirmDialogForStampedSign(localizedMessages.get('MSG_STAMPED_SIGNATURE'),sendNativeEditFile);
     //bvDialog.confirmDialog
    bvDialog.confirmDialogForStampedSign(localizedMessages.get('MSG_STAMPED_SIGNATURE'),sendNativeEditFile,undefined," Edit Alert");
	}
}
