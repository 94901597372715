function isViewerOpen(){
	return {
		restrict: 'A',
		link: function($scope){
			$scope.$watch(function(){
				return $('.viewerDialog') && $('.viewerDialog').hasClass('ui-dialog-content') && $('.viewerDialog').dialog('isOpen')
			},
			function(val){
				$scope.isViewerOpen = val;
			})
		}
	}
}
