function UserRegistrationController($scope, $state, $stateParams, userRegistrationService, localizedMessages, loginService, context) {

	console.log("STATE PARAMS:",$stateParams);
	//console.log("userRegPlinkOBJ:",userRegPlink);
	var inputPlink = $stateParams.plink;
	console.log("USER PLINK:",inputPlink);
	$scope.ui = {
					label :
						{
							txtFirstName			: localizedMessages.get('FIRST_NAME'),
							txtLastName			    : localizedMessages.get('LAST_NAME'),
							txtSalutation			: localizedMessages.get('SALUTATION'),
							txtLoginId				: localizedMessages.get('LOGIN_ID'),
							txtPassword				: localizedMessages.get('PASSWORD'),
							txtConfirmPassword		: localizedMessages.get('CONFIRM_PASSWORD'),
							txtWorkPhone			: localizedMessages.get('WORK_PHONE'),
							txtMobilePhone  		: localizedMessages.get('MOBILE_PHONE'),
							passwordMatchError      : localizedMessages.get('ERR_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH'),
							challengeQuestionsError : localizedMessages.get('ERR_ANSWERS_ARE_REQUIRED_FOR_ALL_CHALLENGE_QUESTIONS'),
							userIdExistsError       : localizedMessages.get('ERR_USER_ID_ALREADY_EXISTS'),
							enterFieldsError		: localizedMessages.get('ERR_PLEASE_ENTER_FIRST_NAME_LAST_NAME_LOGINID_PASSWORD_CONFIRM_PASSWORD_AND_EMAIL_TO_CONTINUE'),
							invalidRequestError		: localizedMessages.get('ERR_INVALID_REQUEST'),
							expiredRequestError		: localizedMessages.get('ERR_INVALID_OR_EXPIRED_REQUEST'),
							pwdFieldError           : localizedMessages.get('ERR_MIN_REQUIRED_PWD_LEGNTH_IS'),
							pwdSpecialCharError		: localizedMessages.get('ERR_INVALID_PASSWORD_CHARS'),
							userRegistration		: localizedMessages.get('USER_REGISTRATION'),
							challengeQuestions		: localizedMessages.get('CHALLENGE_QUESTIONS'),
							txtContinue             : localizedMessages.get('CONTINUE'),
							txtSubmit               : localizedMessages.get('SUBMIT'),
							txtBack                 : localizedMessages.get('BACK'),
							txtCountryListAndPrefix : localizedMessages.get('MSG_COUNTRY_NAME_AND_PHONE_PREFIX'),
							txtAnswer1              : localizedMessages.get('ANSWER1'),
							txtAnswer2              : localizedMessages.get('ANSWER2'),
							txtAnswer3              : localizedMessages.get('ANSWER3'),
							support1_PART1          : localizedMessages.get('CLICK_HERE_TO_VIEW_THE_BOARDVANTAGE_USAGE_POLICY'),
							support2                : localizedMessages.get('USAGE_POLICY'),
							support3                : localizedMessages.get('CONTACT_US'),
							challengeQuestionsAnswerMinLengthError : localizedMessages.get('ERR_MIN_REQUIRED_CQ_ANSWER_LEGNTH_IS'),
							challengeQuestionsShouldNotBeSameError : localizedMessages.get('ERR_CQ_SHOULD_NOT_BE_SAME')
					 	}
				};

	console.log("UI_CONSTANTS:",$scope.ui);

	$scope.error = {};
  	$scope.user = {};

  	$scope.SHOW_USER_REG1 = true;
  	$scope.SHOW_USER_REG2 = false;
  	$scope.firstNameMaxLength = 250;
  	$scope.lastNameMaxLength = 250;
  	$scope.loginIdMaxLength = 50;

  	var loginJSON;
  	var questionAnswerList;
  	var objectBaseUuid;

  	if(inputPlink)
  	{
	 	//console.log("In Params Plink:",plink);
	  	userRegistrationService.getUserData(inputPlink).then(function(data){
	  		//console.log("USER-DATA:",data);
	  		if(data.objectBaseUuid)
	  		{
	  			$scope.plink = inputPlink;
	  			objectBaseUuid = data.objectBaseUuid;
	  			$scope.user.email = data.email;

	  			userRegistrationService.getCountryNameValueList().then(function(data){
	  				if(data)
	  				{
	  					var j = 0;
	  					$scope.countriesList = data;
	  					for(var i=0; i < data.length; i++)
	  					{
	  						if(data[i].name=="United States of America")
	  						{
	  							j = i;
	  							break;
	  						}
	  					}
	  					$scope.countries = $scope.countriesList[j];

					  		//TODO : email validation
					  	userRegistrationService.getEmailRegExp().then(function(data)
					  	{
					  		var emailRegExp = data;
					  		//TODO : password validation
						  	userRegistrationService.getPasswordPreferencesForPlink(inputPlink).then(function(data)
						  	{
						  		var passPref = data;
						  		var pwdMinLength = 1;
						  		var challengeQAnswerMinLength = 1;
						  		console.log("PASS-PREF:",passPref);
						  		if(passPref)
						  		{
						  			if(passPref.passwordMinLength)
						  			{
							  			try
							  			{
							  				pwdMinLength = parseInt(passPref.passwordMinLength);
							  			}
							  			catch(error) {}
						  			}
						  			if(passPref.challengeQMinLength)
						  			{
							  			try
							  			{
							  				challengeQAnswerMinLength = parseInt(passPref.challengeQMinLength);
							  			}
							  			catch(error) {}
						  			}
						  		}
						  		$scope.MIN_PWD_LENGTH = pwdMinLength;
						  		$scope.MIN_CQ_LENGTH =  challengeQAnswerMinLength;
						  	});
					  	});
	  				}
	  			});
	  		}
	  		else
	  		{
	  			$scope.error.exception = $scope.ui.label.expiredRequestError;
	  		}
	  	});
	  	$scope.populateCountryCode = function(countries) {
	  		//var country = $scope.countries
	  		$scope.countries = countries;
	  		$scope.user.phoneNumber = "+" + countries.value;
	  	};
  	}
  	else
  	{
  		$scope.error.exception = $scope.ui.label.invalidRequestError;
  	}


  	$scope.changeCQuestion = function(qNo, question)
  	{
  		switch(qNo)
  		{
  			case 1: $scope.cQuestion1.question = angular.copy(question); break;
  			case 2: $scope.cQuestion2.question = angular.copy(question); break;
  			case 3: $scope.cQuestion3.question = angular.copy(question); break;
  		}
  	}

    $scope.userRegistrationContinue = function()
    {
    	if($scope.user.firstName === undefined || $scope.user.lastName === undefined || $scope.user.loginid === undefined ||
    			$scope.user.password === undefined || $scope.user.confirmPassword === undefined || $scope.user.email === undefined)
    	{
    		$scope.error.exception = $scope.ui.label.enterFieldsError;
    		return;
    	}
    	if($scope.user.password != $scope.user.confirmPassword){
    		$scope.error.exception = $scope.ui.label.passwordMatchError;
    		return;
    	}
    	if($scope.user.password.indexOf('<')> -1 || $scope.user.password.indexOf('>')> -1){
    		$scope.error.exception = $scope.ui.label.pwdSpecialCharError;
	    	return;
    	}
    	if($scope.user.password.length < $scope.MIN_PWD_LENGTH)
    	{
    		$scope.error.exception = $scope.ui.label.pwdFieldError + $scope.MIN_PWD_LENGTH;
    		return;
    	}
    	else
    	{
    		var pNum = $scope.user.phoneNumber;
    		loginJSON = {
					'firstName' 			: $scope.user.firstName,
					'lastName' 				: $scope.user.lastName,
					'loginid' 				: $scope.user.loginid,
					'password' 				: $scope.user.password,
					'email'   				: $scope.user.email,
					'companyName' 			: $scope.user.companyName,
					'jobTitle'				: $scope.user.jobTitle,
					'mobilePhone'			: $scope.user.mobilePhone,
					'workPhone'				: $scope.user.workPhone,
					'plink'					: inputPlink,
					'uid'					: objectBaseUuid,
					'mobilePhoneCountryCode': "+" + $scope.countries.value,
					'mobilePhoneCountryName': $scope.countries.name
				};
    		userRegistrationService.setUserDetails(loginJSON);

    		userRegistrationService.checkUserByLoginId($scope.user.loginid).then(function(data)
    		{
    			if(data === "true" || data === true)
    			{
    				$scope.error.exception = $scope.ui.label.userIdExistsError;
    			}
    			else
    			{
    				var plinkForChallengeQuestion = userRegistrationService.getUserPlink();
    				console.log("GET CHALLENGE QUESTION PLINK:",plinkForChallengeQuestion);
    				userRegistrationService.getChallengeQuestion(plinkForChallengeQuestion, localizedMessages.getLocale()).then(function(data)
    				{

    					console.log("Challenge Questsion for Company:",data);
    					var challengeQuestionsList = [];
    					for(var i in data)
    					{
    						challengeQuestionsList.push({'question':data[i]});
    					}
	   					userRegistrationService.setChallengeQuestionsList(challengeQuestionsList);

	   					$scope.challengeQuestionsList = userRegistrationService.getChallengeQuestionsList();
						console.log("In UserRegistrationControllerChalleqQList:",$scope.challengeQuestionsList);
						if($scope.challengeQuestionsList.length>=3)
						{
							$scope.cQuestion1 = angular.copy($scope.challengeQuestionsList[0]);
							$scope.cQuestion2 = angular.copy($scope.challengeQuestionsList[1]);
							$scope.cQuestion3 = angular.copy($scope.challengeQuestionsList[2]);
						}
						$scope.error.exception = "";
						$scope.SHOW_USER_REG1 = false;
  						$scope.SHOW_USER_REG2 = true;
  						//$state.go('registration');
						//$state.go('challengequestions');
					}, function(x) {
						$scope.error.exception = "Error while getting security questions.";
					});
    			}
    		},function(x) {
    			$scope.error.exception = x.data;
				//alert(x.data);
			});
    	}
    };

	$scope.cancelChallengeQuestionsForm = function() {
		$scope.error.exception = "";
		$scope.SHOW_USER_REG1 = true;
  		$scope.SHOW_USER_REG2 = false;
  	};

  	$scope.submitUserRegistration = function()
  	{
  		if($scope.user.challengeAnswer1 === undefined || $scope.user.challengeAnswer2 === undefined || $scope.user.challengeAnswer3 === undefined)
  		{
  			$scope.error.exception = $scope.ui.label.challengeQuestionsError;
  		}
  		else if($scope.user.challengeAnswer1.length < $scope.MIN_CQ_LENGTH || $scope.user.challengeAnswer2.length < $scope.MIN_CQ_LENGTH
  			 || $scope.user.challengeAnswer3.length < $scope.MIN_CQ_LENGTH)
  		{
  			$scope.error.exception = $scope.ui.label.challengeQuestionsAnswerMinLengthError + $scope.MIN_CQ_LENGTH;
  		}
  		else if (($scope.cQuestion1.question === $scope.cQuestion2.question)
  					|| ($scope.cQuestion1.question === $scope.cQuestion3.question)
  					|| ($scope.cQuestion2.question === $scope.cQuestion3.question) )
  		{
  			$scope.error.exception = $scope.ui.label.challengeQuestionsShouldNotBeSameError;
  		}
  		else
  		{
    		loginJSON = userRegistrationService.getUserDetails();
    		questionAnswerList =  [
    		                      {label:$scope.cQuestion1.question, val:$scope.user.challengeAnswer1},
   			                      {label:$scope.cQuestion2.question, val:$scope.user.challengeAnswer2},
   			                      {label:$scope.cQuestion3.question, val:$scope.user.challengeAnswer3}
   			                      ];
   			loginJSON['questionAndAnswerList'] = questionAnswerList;
    		var loginVO = angular.toJson(loginJSON);

    		userRegistrationService.registerUser(loginVO).then(function(data)
		    {
		    	//console.log("USER REGISTRAIONT DATA:",data);
	    		if(data.username)
	    		{
					if(data.certificateRequired === true)
	        		{
		        		if(data.validWindow === true)
		        		{
		        			loginService.loginContext({validWindow : data.validWindow});
						    loginService.loginContext({bvSessionId : data.bvSessionId});
		        			$state.go('login.installcertificate');
		        		}
			        	else
			        	{
			        		$state.go('login.certificaterequired');
			        	}
	        		}
		        	else
		        	{
		        		if(data.pinRequired === true)
		        		{
		        			context.homeModel.userName = data.username;
			        		$state.go('login.pinsetup');
		        		}
		        		else
		        		{
			        		$state.go("home.documents");
		        		}
		        	}
				}
				else
				{
					$scope.error.exception = data.message;
				}
		    }, function(x) {
		       alert(x.data);
		    });
  		}
  	};

}

angular
	.module('userRegistration',['userRegistration.service', 'localization.service'])
	.controller('UserRegistrationController', ['$scope', '$state', '$stateParams', 'userRegistrationService', 'localizedMessages', 'loginService', 'context', UserRegistrationController ]);
