angular
	.module('dialogControllers.service')
	.controller('lockDeviceController', lockDeviceController);


lockDeviceController.$inject = ['$scope', 'localizedMessages', 'commonService', 'context', 'homeService', 'bvDialogService', 'loginService', 'homeModel', '$state', 'bvDialog'];

function lockDeviceController($scope, localizedMessages, commonService, context, homeService, bvDialogService, loginService,homeModel,$state, bvDialog)
{
	$scope.popupTitle = localizedMessages.get('REGISTER_DEVICE');
	
	$scope.ui = { locklabel : { 
		
		lockDevice : localizedMessages.get('ENTER_CODE_REGISTER'),
		confirm : localizedMessages.get('CONFIRM_IDENTITY'),
		lockCode : localizedMessages.get('CODE'),
		submit 	: localizedMessages.get("SUBMIT"),
		cancel : localizedMessages.get("CANCEL")
		}
	}
	$scope.user = {};

	$scope.submitCode = function()
	{
		if (!StringUtils.isNullEmpty($scope.user.code1) && !StringUtils.isNullEmpty($scope.user.code2))
	    {
			homeModel.lockCode = $scope.user.code1.trim()+$scope.user.code2.trim();	
		
			var hostAddress = "";
			var hostName = ""; 
			var operatingSystem = StringUtils.getOSName();
			loginService.registerClientCertificateInDevice(hostAddress, hostName, operatingSystem, homeModel.lockCode).then(function(data)
			{
				if(data!= null && !data.hasOwnProperty("exceptionName") && !data.hasOwnProperty("exceptionCode" ))
				{
//					 $scope.certStatus = loginService.certStatus(localizedMessages.get('CERTIFICATE_INSTALLED'), false);
//	        		 $state.go('login.installdesktopcertificate');
//        			 $timeout(function(){
//        				 bvDialogService.showBvAlertMessage(localizedMessages.get("DEVICE_REGISTERED_SUCCESSFULLY"));
//    			     }, 2000);
        			 bvDialog.showMessage(localizedMessages.get("DEVICE_REGISTERED_SUCCESSFULLY"));
				}
				else if(data.message && data.message.indexOf("INVALID_CSR_CODE") != -1)
				{
					bvDialog.showMessage(localizedMessages.get("INVALID_CERTIFICATE_REQUEST_CODE"));
				}
				else
				{
					bvDialog.showMessage(localizedMessages.get("ERR_REGISTERING_DEVICE"));
				}
			});
	    }
	}
	
}