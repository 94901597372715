CreateSurveyController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'calendarService', 'localizedMessages', 'modelService','$modal','imageService','companyUserService','surveysService','surveysAlertService','context','bvDialog','bvDialogService','$sce','$timeout','homeService', 'templatesService', 'reactInterface'];

function CreateSurveyController($scope, $rootScope, $state, $stateParams, calendarService, localizedMessages, modelService, modal,imageService,companyUserService,surveysService,surveysAlertService, context,bvDialog,bvDialogService,$sce,$timeout,homeService, templatesService, reactInterface)
{
	var ctrlName="CreateSurveyController";
	$scope.middleBarObject.title = localizedMessages.get("SURVEY");
	$scope.middleBarObject.showBack = false;
    $scope.handleLeftIcon = function()
	{
    	$state.go('home.surveys');
	}
	$scope.ui = {
	    label: {}
	}
	$scope.ui.label.headingAttachments = localizedMessages.get("ATTACHMENTS");
	$scope.ui.label.allowMultipleChoice = localizedMessages.get("ALLOW_MULTIPLE_CHOICE");
	$scope.ui.label.multipleChoice = localizedMessages.get("MULTIPLE_CHOICE");
	$scope.ui.label.openAnswer = localizedMessages.get("OPEN_ANSWER");
	$scope.ui.label.likertScale = localizedMessages.get("LIKERT_SCALE");
	$scope.ui.label.addChoice = localizedMessages.get("ADD_CHOICE");
	$scope.ui.label.replyOptions = localizedMessages.get("REPLY_OPTIONS");
	$scope.ui.label.replyMandatory = localizedMessages.get("REPLY_MANDATORY");
	$scope.ui.label.notifyOnResponse = localizedMessages.get("NOTIFY_ME_ON_RESPONSE");
	$scope.ui.label.commentMandatory = localizedMessages.get("COMMENT_MANDATORY");
	$scope.ui.label.allowComment = localizedMessages.get("ALLOW_COMMENT");
	$scope.ui.label.by = localizedMessages.get("BY");
	$scope.ui.label.headingUsers = localizedMessages.get("USERS");
	$scope.ui.label.title = localizedMessages.get("TITLE");
	$scope.ui.label.indicates_req_field = localizedMessages.get("INDICATES_REQUIRED_FIELD");
	$scope.ui.label.instructions = localizedMessages.get("INSTRUCTIONS");
	$scope.ui.label.priority = localizedMessages.get("PRIORITY");
	$scope.ui.label.sort = localizedMessages.get("SORT_QUESTIONS");
	$scope.ui.label.signatureRequired = localizedMessages.get("SIGNATURE_REQUIRED");
	$scope.ui.label.dueDate = localizedMessages.get("DUE_DATE");
	$scope.ui.label.headingDetails = localizedMessages.get("DETAILS");
	$scope.ui.questionManager=localizedMessages.get("QUESTION_MANAGER");
	$scope.ui.surveyDetails=localizedMessages.get("SURVEY_DETAILS");
	$scope.ui.publish=localizedMessages.get("PUBLISH");
	$scope.ui.label.description=localizedMessages.get("DESCRIPTION");
	$scope.ui.characters=localizedMessages.get("CHARACTERS");
	$scope.ui.label.btnAdd=localizedMessages.get("ADD");
	$scope.ui.label.btnDelete=localizedMessages.get("DELETE");
	$scope.ui.label.btnBack = localizedMessages.get("BACK");
	$scope.ui.label.btnNext = localizedMessages.get("NEXT");
	$scope.ui.save=localizedMessages.get("SAVE");
	$scope.ui.cancel=localizedMessages.get("CANCEL");
	$scope.ui.signature_required=localizedMessages.get("SIGNATURE_REQUIRED");
	$scope.ui.due_date=localizedMessages.get("DUE_DATE");
	$scope.ui.priority=localizedMessages.get("PRIORITY");
	$scope.ui.label.high=localizedMessages.get("HIGH");
	$scope.ui.label.normal=localizedMessages.get("NORMAL");
	$scope.ui.label.medium=StringUtils.capitaliseFirstLetter(localizedMessages.get("MEDIUM"));
	$scope.ui.label.low=localizedMessages.get("LOW");
	$scope.ui.seqNumber=localizedMessages.get("ALLOW_SEQUENTIAL_Number");
	$scope.ui.label.responseType=localizedMessages.get("RESPONSE_TYPE");
	$scope.ui.owners=localizedMessages.get("OWNERS");
	$scope.ui.label.owner=localizedMessages.get("OWNER");
	$scope.ui.label.viewer=localizedMessages.get("VIEWER");
	$scope.ui.label.respondent=localizedMessages.get("RESPONDENT");
	$scope.ui.attachments=localizedMessages.get("ATTACHMENTS");
	$scope.ui.label.allowSequentialNumbering = localizedMessages.get("ALLOW_SEQUENTIAL_NUMBERING");
	$scope.ui.label.btnClose = localizedMessages.get("CLOSE");
	$scope.ui.label.btnCancel = localizedMessages.get("CANCEL");
	$scope.ui.label.btnSave = localizedMessages.get("SAVE");
	$scope.ui.label.namedResponses = localizedMessages.get("NAMED_RESPONSES");
	$scope.ui.label.anonymous = localizedMessages.get("ANONYMOUS");
	$scope.ui.label.cancelConfirmMsg = localizedMessages.get("MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED");
	$scope.ui.label.addNewSection = localizedMessages.get("ADD_NEW_SECTION");
	$scope.ui.label.addNewQuestion = localizedMessages.get("ADD_NEW_QUESTION");
	$scope.ui.label.section = StringUtils.capitaliseFirstLetter(localizedMessages.get("SECTION"));
	$scope.ui.label.question = StringUtils.capitaliseFirstLetter(localizedMessages.get("QUESTION"));
	$scope.ui.label.typeOfQuestions = localizedMessages.get("TYPE_OF_QUESTIONS");
	$scope.ui.label.default = localizedMessages.get("DEFAULT");
	$scope.ui.label.multiple_choice = localizedMessages.get('MULTIPLE_CHOICE');
	$scope.ui.label.open_answer = localizedMessages.get('OPEN_ANSWER');
	$scope.ui.label.likert_scale = localizedMessages.get('LIKERT_SCALE');
	$scope.ui.label.true_false = localizedMessages.get('TRUE_FALSE');
	$scope.ui.label.meets_does_not_meet = localizedMessages.get('MEETS_DOES_NOT_MEET');
	$scope.ui.label.multiple_choice_multi_response = localizedMessages.get('MULTIPLE_CHOICE_MULTI_RESPONSE');
	$scope.ui.label.yes_no = localizedMessages.get('YES_NO');
	$scope.ui.label.section_must_have_question = localizedMessages.get('MSG_A_SECTION_MUST_HAVE_1_QUESTION_TO_CONTINUE');
	
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';
	$scope.closeIcon = templatesService.getUrl('closeBtn');
	
	$scope.attachmentChooserFor = 'details';
	$scope.canEditSurveyFields = true;
	$scope.inCreateSurvey = surveysService.getSurvey() == null ? true : false; //Toggle between edit and create survey
	//$scope.svgFill = '#FFF';
    $scope.surveyObject={};
    $scope.surveyObject.attendeesList = [];
    $scope.openedBox = "";
    $scope.copyQuestion={};
    $scope.copySurveyPublish={};
    $scope.isSaving=false;
	$scope.questionDisplayNames = {
			'multiplechoice':$scope.ui.label.multiple_choice,
			'yesno':$scope.ui.label.yes_no,
			'openanswer':$scope.ui.label.open_answer,
			'likertscale':$scope.ui.label.likert_scale,
			'truefalse':$scope.ui.label.true_false,
			'meetsornot':$scope.ui.label.meets_does_not_meet,
			'multiplechoicemultiresponse':$scope.ui.label.multiple_choice_multi_response
	};
    homeService.set_current_module_has_unsaved_changes(true, "CreateSurveyController");
    
    $scope.reactInterface = reactInterface;
    
  //Options for Main Editor
	$scope.editorOptions = {
           language: 'en',
           resize_enabled : true,
           enterMode : CKEDITOR.ENTER_BR,
           height:"70px",
           toolbarCanCollapse:false,
           toolbarStartupExpanded:false,
           removePlugins: 'elementspath',
           toolbar : [
			               { name: 'clipboard', 	items: [ 'Undo', 'Redo' ] },
			               { name: 'basicstyles', 	items: [ 'Bold', 'Italic','Underline' ] },
			               { name: 'color', 		items: [ 'TextColor' ] },
			               { name: 'paragraph',     items: [ 'NumberedList', 'BulletedList'] },
			               { name: 'justify', 		items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ] },
			               { name: 'styles', 		items: [ 'Format', 'Font', 'FontSize' ] }
			           ],
		   extraPlugins : 'colorbutton,colordialog,font,link,basicstyles,toolbar,justify,wordcount',
		   entities: false,
		   wordcount: {
               showParagraphs: false,
               showWordCount: false,
               showCharCount: true,
               countSpacesAsChars:true,
               countHTML:false,
               maxWordCount: -1,
               maxCharCount: 1000
           },
    };

	// Set focus and blur listeners for all editors to be created.
	CKEDITOR.on('instanceReady', function(e) {
		var editor = e.editor;
	    editor.on('focus', function() {
	        console.log('editor focused');
	    });

	    editor.on('change', function() {
	    	console.log('editor changed');
	    	$scope.editorWordCount = editor.getData().replace(/<[^>]*>|\s/g, '').length;
	    });
	    e.stop(); // Stop the event in case the listener is inserted twice
	});

	$scope.dropDownValues = [ {
		key : localizedMessages.get("YES_NO"),
		value : 'yesno'
	}, {
		key : localizedMessages.get("MEETS_DOES_NOT_MEET"),
		value : "meetsornot"
	}, {
		key : localizedMessages.get("TRUE_FALSE"),
		value : "truefalse"
	},
		{
			key:$scope.ui.label.multipleChoice,
			value:"multipleChoice"
		},
		{
			key:$scope.ui.label.openAnswer,
			value:"openAnswer"
		},
		{
			key:$scope.ui.label.likertScale,
			value:"likertScale"
		}
	];
	$scope.yesItem={name:localizedMessages.get("YES"),defaultOption:false};
	$scope.noItem={name:localizedMessages.get("NO"),defaultOption:false};
	$scope.meetsItem={name:localizedMessages.get("MEETS"),defaultOption:false};
	$scope.doesnotmeetItem={name:localizedMessages.get("DOES_NOT_MEET"),defaultOption:false};
	$scope.trueItem={name:localizedMessages.get("SURVEY_TRUE"),defaultOption:false};
	$scope.falseItem={name:localizedMessages.get("SURVEY_FALSE"),defaultOption:false};
	
	
	$scope.selectedDropDown = $scope.dropDownValues[0];
	$scope.mandatoryReply = {
		mandatoryReply : false
	};
	$scope.mandatoryReplyItem = {
		itemName : "mandatoryReply",
		checked : false
	};
	$scope.allowComment = {
		allowComment : false
	};
	$scope.allowCommentItem = {
		itemName : "allowComment",
		state : "notChecked"
	};
	$scope.commentMandatory = {
		commentMandatory : false
	};
	$scope.commentMandatoryItem = {
		itemName : "commentMandatory",
		state : "disabled"
	};
	$scope.responseTypeList = [ {
		itemName : localizedMessages.get('NAMED_RESPONSES'),
		checked : true,
		isAnonymous : false
	}, {
		itemName : localizedMessages.get('ANONYMOUS'),
		checked : false,
		isAnonymous : true
	} ];
	$scope.signatureItem = {
		itemName : "signature",
		checked : false
	};
	$scope.sequentalNumberingItem = {
		itemName : "seqNumber",
		checked : false
	};
	$scope.surveyPriorityList = [ $scope.ui.label.high, $scope.ui.label.medium,
			$scope.ui.label.low ];
	$scope.allowMultipleChoice = {
		allowMultipleChoice : false
	};
	$scope.allowMultipleChoiceItem = {
		itemName : "allowMultipleChoice",
		checked : false
	};

	$scope.dropDownItem = {
		itemName : "dropdown",
		checked : true
	};
	$scope.multipleChoiceItem = {
		itemName : "multiplechoice",
		checked : false
	};
	$scope.openAnswerItem = {
		itemName : "openanswer",
		checked : false
	};
	$scope.likertScaleItem = {
		itemName : "likertscale",
		checked : false
	};
	 
	var areDuplicateItemsInObject = function(objectOfItems){
		var arrayOfItems = [];
		for(var i in objectOfItems){
			arrayOfItems.push(objectOfItems[i].option);
		}
		var arrayOfItems_ = angular.copy(arrayOfItems);
		if(arrayOfItems_.length > 1)
			for (var i=0; i<arrayOfItems_.length; i++) {
				for (var j=i+1; j<arrayOfItems_.length; j++) {
					if (arrayOfItems_[i].toLowerCase()==arrayOfItems_[j].toLowerCase()) 
						return true;
					}	
				}
        return false;
	}


//	$scope.$watch(attrs.uiSortable, function(newVal, oldVal){
//		console.log(newVal);
//		console.log(oldVal);
//	});
	$scope.surveyDetailsChanged = false;
	$scope.editSurveyCopy={};
	




//	$scope.$watch('surveyObject', function(newVal, oldVal){
//		if (newVal !== oldVal) {
//			$scope.surveyDetailsChanged = true;
//			if(angular.equals($scope.surveyObject, $scope.editSurveyCopy))
//			{
//				console.log("same objects");
//				//homeService.set_current_module_has_unsaved_changes(false, "CreateSurveyController");
//			}
//			else
//			{
//				console.log("saving");
//				console.log($scope.surveyObject.isClosing);
//				if($scope.surveyObject.isClosing)
//				{
//				//	homeService.set_current_module_has_unsaved_changes(false,"CreateSurveyController");
//				}
//				else if(!$scope.isSaving)
//				{
//				//	homeService.set_current_module_has_unsaved_changes(true, "CreateSurveyController");
//				}
//				else
//				{
//				//	homeService.set_current_module_has_unsaved_changes(false, "CreateSurveyController");
//				}
//			}
//
//			console.log("changed");
//		}
//	}, true);
	$scope.multipleChoiceOptionsInitialize = [ {
		option : localizedMessages.get("CHOICE1")
	}, {
		option : localizedMessages.get("CHOICE2")
	}, {
		option : localizedMessages.get("CHOICE3")
	}, {
		option : localizedMessages.get("CHOICE4")
	}, {
		option : localizedMessages.get("CHOICE5")
	} ];
	
	$scope.likertScaleOptionsInitialize = [ {
		option : localizedMessages.get("STRONGLY_AGREE"),
		value : 10
	}, {
		option : localizedMessages.get("AGREE"),
		value : 5
	}, {
		option : localizedMessages.get("NO_OPINION"),
		value : 0
	}, {
		option : localizedMessages.get("DISAGREE"),
		value : -5
	}, {
		option : localizedMessages.get("STRONGLY_DISAGREE"),
		value : -10
	} ];
	$scope.multipleChoiceOptions = [ {
		option : localizedMessages.get("CHOICE1")
	}, {
		option : localizedMessages.get("CHOICE2")
	}, {
		option : localizedMessages.get("CHOICE3")
	}, {
		option : localizedMessages.get("CHOICE4")
	}, {
		option : localizedMessages.get("CHOICE5")
	} ];
	
	$scope.likertScaleOptions = [ {
		option : localizedMessages.get("STRONGLY_AGREE"),
		value : 10
	}, {
		option : localizedMessages.get("AGREE"),
		value : 5
	}, {
		option : localizedMessages.get("NO_OPINION"),
		value : 0
	}, {
		option : localizedMessages.get("DISAGREE"),
		value : -5
	}, {
		option : localizedMessages.get("STRONGLY_DISAGREE"),
		value : -10
	} ];
    $scope.addChoice=function()
    {
    	if($scope.multipleChoiceOptions.length<7)
    	{	
    		var newObj={option:""};
    		$scope.multipleChoiceOptions.push(newObj);
    	}
    };
    $scope.addLikertChoice=function()
    {
  	  var newObj={option:"",value:""}
  	  if($scope.likertScaleOptions.length<20)
  	  {
  		  $scope.likertScaleOptions.push(newObj);
  	  }
    };
    $scope.selectMultipleDefaultOption=function(index)
    {
    	$scope.multipleChoiceOptions[index].defaultOption=true;
    	for(var i=0;i<$scope.multipleChoiceOptions.length;i++)
    	{
    		if(index!=i)
    		{
    			$scope.multipleChoiceOptions[i].defaultOption=false;
    		}
    	}
    }
    $scope.selectLikertScaleDefaultOption=function(index)
    {
    	$scope.likertScaleOptions[index].defaultOption=true;
    	for(var i=0;i<$scope.likertScaleOptions.length;i++)
    	{
    		if(index!=i)
    		{
    			$scope.likertScaleOptions[i].defaultOption=false;
    		}
    	}
    }
    $scope.selectDefaultOptionYesNo=function(itemname)
    {
    	if(itemname=="yes")
    	{
    		$scope.yesItem.defaultOption=true;
    		$scope.noItem.defaultOption=false;
    	}
    	else 
    	{
    		$scope.yesItem.defaultOption=false;
    		$scope.noItem.defaultOption=true;
    	}
    }
    $scope.selectDefaultOptionsMeets=function(itemname)
    {
    	if(itemname=="meets")
    	{
    		$scope.meetsItem.defaultOption=true;
    		$scope.doesnotmeetItem.defaultOption=false;
    	}
    	else
    	{
    		$scope.meetsItem.defaultOption=false;
    		$scope.doesnotmeetItem.defaultOption=true;
    	}
    }
    $scope.selectDefaultOptionTrueFalse=function(itemname)
    {
    	if(itemname=='true')
    	{
    		$scope.trueItem.defaultOption=true;
    		$scope.falseItem.defaultOption=false;
    	}
    	else
    	{
    		$scope.trueItem.defaultOption=false;
    		$scope.falseItem.defaultOption=true;
    	}
    }
    $scope.deselectMultipleChoice=function(index)
    {
    	$scope.multipleChoiceOptions[index].defaultOption=false;
    }
    $scope.deselectLikertScale=function(index)
    {
    	$scope.likertScaleOptions[index].defaultOption=false;
    }
    $scope.deselectDropItemDefaultOption=function(name)
    {
    	if(name=='yes')
    		{
    			$scope.yesItem.defaultOption=false;
    		}
    	else if (name=='no')
    		{
    			$scope.noItem.defaultOption=false;
    		}
    	else if (name=="meets")
    		{
    			$scope.meetsItem.defaultOption=false;
    		}
    	else if(name=="doesnotmeet")
    		{
    			$scope.doesnotmeetItem.defaultOption=false;
    		}
    	else if(name=='true')
    		{
    			$scope.trueItem.defaultOption=false;
    		}
    	else if(name=='false')
    		{
    			$scope.falseItem.defaultOption=false;
    		}
    }
    $scope.clearDefaultOption=function()
    {
    	console.log("clear default option");
    	$scope.yesItem.defaultOption=false;
    	$scope.noItem.defaultOption=false;
    	$scope.meetsItem.defaultOption=false;
    	$scope.doesnotmeetItem.defaultOption=false;
    	$scope.trueItem.defaultOption=false;
    	$scope.falseItem.defaultOption=false;
    	
    	for(var i=0;i<$scope.multipleChoiceOptions.length;i++)
    		{
    			$scope.multipleChoiceOptions[i].defaultOption=false;
    		}
    	for(var i=0; i<$scope.likertScaleOptions;i++)
    		{
    			$scope.likertScaleOptions[i].defaultOption=false;
    		}
    	
    }
    
    $scope.removeMultipleChoice=function(index)
    {
    	if($scope.multipleChoiceOptions.length>2)
    	{		
    		$scope.multipleChoiceOptions.splice(index,1);
    	}
    };
    $scope.removeLikertScaleChoice=function(index)
    {
      if($scope.likertScaleOptions.length>3)
      {	  
    	  $scope.likertScaleOptions.splice(index,1);
      }
    };
	$scope.initSurveyDetails = function(){
		$scope.openedBox = '';
		var toEditSurvey = surveysService.getSurvey();
		if(toEditSurvey == null){
			$scope.tabs[1].disabled = true;
			$scope.tabs[2].disabled = true;
			$scope.surveyObject.attachmentsList=[];
			$scope.surveyObject.signatureRequired = true;
			$scope.surveyObject.allowSequentialNumbering = true;
			$scope.surveyObject.priority = StringUtils.capitaliseFirstLetter($scope.ui.label.medium);
			$scope.surveyObject.dueDate = moment(new Date()).format('ll');
			$scope.surveyObject.dueDateLocal = StringUtils.momentL(new Date()).format('ll');
			$scope.surveyObject.attachmentsList = [];
			$scope.surveyObject.participantBaseUuidAndResponseList = [];
			$scope.surveyObject.notify = false;
			$scope.surveyObject.isAnonymous = false;
			$scope.isAnonymousCopy = false;
			$scope.surveyObject.sendAlert = false;
			$scope.surveyObject.isClosed = false;
			$scope.surveyObject.isPublished = false;
			$scope.surveyObject.instructions = '';
			$scope.surveyObject.sections = [];
			$scope.surveyObject.isClosing=false;
			angular.copy($scope.surveyObject,$scope.editSurveyCopy)
		}
		if(toEditSurvey != null)
		{
			$scope.tabs[1].disabled = false;
			$scope.tabs[2].disabled = false;
			$scope.surveyObject = toEditSurvey;
			surveysService.setSurvey(toEditSurvey);
			$scope.surveyObject.objectBaseUuid = toEditSurvey.objectBaseUuid;
			$scope.surveyObject.attachmentsList = toEditSurvey.attachedObjectsList;
			$scope.surveyObject.signatureRequired = toEditSurvey.isSignatureRequired;
			$scope.surveyObject.instructions = toEditSurvey.description;
			$scope.surveyObject.dueDate = toEditSurvey.dueDateVO == null ? null : moment(toEditSurvey.dueDateVO.gmtMilliSeconds).format('ll');
			$scope.surveyObject.dueDateLocal = toEditSurvey.dueDateVO == null ? null : StringUtils.momentL(toEditSurvey.dueDateVO.gmtMilliSeconds).format('ll');
			$scope.surveyObject.startingDate = toEditSurvey.dueDateVO == null ? null : moment(toEditSurvey.dueDateVO.gmtMilliSeconds).format('ll');
            $scope.surveyObject.startingDateLocal = toEditSurvey.dueDateVO == null ? null : StringUtils.momentL(toEditSurvey.dueDateVO.gmtMilliSeconds).format('ll');
			$scope.surveyObject.notify = toEditSurvey.notify;
			$scope.surveyObject.sendAlert = toEditSurvey.sendAlert;
			$scope.surveyObject.isClosed = toEditSurvey.isClosed;
			$scope.surveyObject.isPublished = toEditSurvey.isPublished;
			angular.copy($scope.surveyObject,$scope.copySurveyPublish);
			$scope.surveyObject.allowSequentialNumbering = toEditSurvey.isSequentialNumberingRequired;
			$scope.surveyObject.priority =  StringUtils.capitaliseFirstLetter(toEditSurvey.priority);
			$scope.surveyObject.title = toEditSurvey.name;
			$scope.surveyObject.attendeesList = [];
			$scope.surveyObject.isClosing=false;
			$scope.isAnonymousCopy = $scope.surveyObject.isAnonymous;
			var toCheckRespondents = [];
			if(toEditSurvey.owners && toEditSurvey.owners.length > 0)
				for(var i = 0;i < toEditSurvey.owners.length; i++){
					toEditSurvey.owners[i].isOwner = true;
					toCheckRespondents.push(toEditSurvey.owners[i].objectBaseUuid);
				}
			$scope.surveyObject.attendeesList = angular.copy(toEditSurvey.owners);
			if($scope.surveyObject.participantBaseUuidAndResponseList && $scope.surveyObject.participantBaseUuidAndResponseList.length > 0)
				for(var i=0;i<$scope.surveyObject.participantBaseUuidAndResponseList.length;i++){
					var ind = toCheckRespondents.indexOf($scope.surveyObject.participantBaseUuidAndResponseList[i].objectBaseUuid);
					if( ind == -1){
						$scope.surveyObject.participantBaseUuidAndResponseList[i].isRespondent = true;
						$scope.surveyObject.attendeesList.push($scope.surveyObject.participantBaseUuidAndResponseList[i]);
					}else{
						$scope.surveyObject.attendeesList[ind].isRespondent = true;
					}
				}
			$scope.surveyObject.participantBaseUuidAndResponseList = toEditSurvey.participantBaseUuidAndResponseList;
			$scope.surveyObject.isAnonymous = toEditSurvey.isAnonymous;
			if($scope.surveyObject.isAnonymous){
				$scope.responseTypeList[1].checked = true;
				$scope.responseTypeList[0].checked = false;
			}else{
				$scope.responseTypeList[1].checked = false;
				$scope.responseTypeList[0].checked = true;
			}
			$scope.surveyObject.sections= toEditSurvey.sections;
			angular.copy($scope.surveyObject,$scope.editSurveyCopy);
		}
		if($scope.clickedIndex != undefined && $scope.surveyObject.sections && $scope.surveyObject.sections.length > 0){
			$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = false;
			$scope.surveyObject.sections[$scope.clickedIndex].style = {'height' : 0 + 'px'};
		}
	}
	$scope.toggleSignatureRequired = function() {
		if (!$scope.surveyObject.signatureRequired)
			$scope.surveyObject.signatureRequired = true;
		else
			$scope.surveyObject.signatureRequired = false;
	}
	
	$scope.toggleAllowSequentialNumbering = function() {
		if (!$scope.surveyObject.allowSequentialNumbering)
			$scope.surveyObject.allowSequentialNumbering = true;
		else
			$scope.surveyObject.allowSequentialNumbering = false;
	}
	$scope.isAnonymousCopy = false;
	$scope.uncheckedAnoyClicked = function() {
		$scope.responseTypeList[1].checked = !$scope.responseTypeList[1].checked;
		$scope.responseTypeList[0].checked = false;
		$scope.responseTypeList[0].isAnonymous = true;
		$scope.isAnonymousCopy = true;
		//$scope.surveyObject.isAnonymous =true;
		$scope.responseTypeList[1].isAnonymous = false;
	}
	$scope.uncheckedNamedRespClicked = function() {
		$scope.responseTypeList[0].checked = !$scope.responseTypeList[0].checked;
		$scope.responseTypeList[1].checked = false;
		$scope.isAnonymousCopy = false;
		$scope.responseTypeList[0].isAnonymous = false;
		//$scope.surveyObject.isAnonymous =false;
		$scope.responseTypeList[1].isAnonymous = true;
	}
	
    $scope.changeQuestionType=function(item)
    {
    	$scope.questionTypeName = item.itemName;
//    	$scope.questionChanged = true;
    	$scope.allowCommentItem.state = "notChecked";
    	$scope.allowComment.allowComment = false;
    	$scope.commentMandatoryItem.state = "disabled";
    	$scope.commentMandatory.commentMandatory = false;
    	$scope.showMultipleorLikertScaleDiv = true;
    	
    	console.log(item.itemName);
    	if(item.itemName=="yesno" || item.itemName=="meetsornot" || item.itemName=="truefalse")
    	{
    		$scope.dropDownItem.checked = true;
    		$scope.multipleChoiceItem.checked = false;
    		$scope.openAnswerItem.checked = false;
    		$scope.likertScaleItem.checked = false;
    		$scope.questionObj.questionType = item.itemName;
    		$scope.showMultipleorLikertScaleDiv = true;
    	}
    	
    	/*if(item.itemName == $scope.dropDownItem.itemName)
    	{
    		$scope.dropDownItem.checked = true;
    		$scope.multipleChoiceItem.checked = false;
    		$scope.openAnswerItem.checked = false;
    		$scope.likertScaleItem.checked = false;
//    		$scope.questionObj.questionType = $scope.dropDownItem.itemName;
    	}
    	*/	
    	else if(item.itemName == $scope.multipleChoiceItem.itemName)
    	{
    		 console.log("multiple choice changed");
    		$scope.dropDownItem.checked = false;
    		$scope.multipleChoiceItem.checked = true;
    		$scope.openAnswerItem.checked = false;
    		$scope.likertScaleItem.checked = false;
    		$scope.questionObj.questionType = $scope.multipleChoiceItem.itemName;
    		$scope.showMultipleorLikertScaleDiv = false;
    	}
    	else if(item.itemName == $scope.openAnswerItem.itemName)
    	{
    		$scope.dropDownItem.checked = false;
    		$scope.multipleChoiceItem.checked = false;
    		$scope.openAnswerItem.checked = true;
    		$scope.likertScaleItem.checked = false;
    		$scope.questionObj.questionType = $scope.openAnswerItem.itemName;
    		$scope.allowCommentItem.state = "disabled";
    		$scope.commentMandatoryItem.state = "disabled";
    			
    	}
    	else
    	{
    		$scope.dropDownItem.checked = false;
    		$scope.multipleChoiceItem.checked = false;
    		$scope.openAnswerItem.checked = false;
    		$scope.likertScaleItem.checked = true;
    		$scope.questionObj.questionType = $scope.likertScaleItem.itemName;
    		$scope.showMultipleorLikertScaleDiv = false;
    	}
    	console.log($scope.questionObj.questionType);
    	
    };
    $scope.changeMandatoryReply = function(item,flag)
    {
    	$scope.mandatoryReplyItem.checked = flag;
    	$scope.mandatoryReply.mandatoryReply = $scope.mandatoryReplyItem.checked;
    	
    }
    $scope.changeAllowComment = function(item,flag)
    {
    	if(flag=="checked")
    	{	
    		$scope.allowComment.allowComment=true;
    		$scope.allowCommentItem.state="checked";
    		$scope.commentMandatoryItem.state="notChecked";
    	}
    	else
    	{
    		$scope.allowComment.allowComment=false;
    		$scope.allowCommentItem.state="notChecked";
    		$scope.commentMandatoryItem.state="disabled";
    		
    	}
    }
    $scope.changeMandatoryComment=function(item,flag)
    {
    	if(flag=="checked")
    	{
    		$scope.commentMandatoryItem.state="checked";
    		$scope.commentMandatory.commentMandatory=true;
    	}
    	else
    	{
    		$scope.commentMandatoryItem.state="notChecked";
    		$scope.commentMandatory.commentMandatory=false;
    	}
    }
    $scope.dropDownChanged=function(item)
    {
    	$scope.selectedDropDown = item;
    	console.log(item.value);
    	
    	if(item.value=="yesno" || item.value=="meetsornot" || item.value=="truefalse")
    	{
    		console.log("item value");
    		console.log(item.value);
    		$scope.dropDownItem.checked=true;
    		$scope.dropDownItem.itemName=item.value;
    		$scope.changeQuestionType($scope.dropDownItem);
    		
    	}
    	
    	if(item.value=="multipleChoice")
    	{
    		console.log("multiple choice clicked");
    		$scope.multipleChoiceItem.checked=true;
    		$scope.changeQuestionType($scope.multipleChoiceItem);
    		if($scope.isNewQuestion)
    			$scope.multipleChoiceOptions = angular.copy($scope.multipleChoiceOptionsInitialize);
    		//$scope.questionObj.questionType="multiplechoice";
    		console.log($scope.questionObj.questionType);
    	}
    	else if(item.value=="likertScale")
    	{
    		$scope.likertScaleItem.checked=true;
    		$scope.changeQuestionType($scope.likertScaleItem);
    		if($scope.isNewQuestion)
    			$scope.likertScaleOptions = angular.copy($scope.likertScaleOptionsInitialize);
    		//$scope.questionObj.questionType="likertscale"
    		
    		
    	}	
    	else if(item.value=="openAnswer")
    	{
    		$scope.openAnswerItem.checked=true;
    		$scope.changeQuestionType($scope.openAnswerItem);
    		
    	}	
    }
    $scope.changeAllowMultipleChoice=function(item,flag)
    {
    	$scope.allowMultipleChoiceItem.checked=flag;
    	if($scope.allowMultipleChoiceItem.checked)
    	{
    		$scope.allowMultipleChoice.allowMultipleChoice=true;
    		$scope.questionTypeName = "multiplechoicemultiresponse";
    	}
    	else
    	{
    		$scope.allowMultipleChoice.allowMultipleChoice=false;
    		$scope.questionTypeName = "multiplechoice";
    	}
    }
	 $scope.$parent.title=localizedMessages.get("NEW_SURVEY");
	 $scope.middleBarObject.title =localizedMessages.get("SURVEYS");
	 var toggleActive = function()
		{
		 	$scope.tabs[2].rightMenuList[1].svgFill= '#fff';
		 	if($scope.copySurveyPublish.isPublished)
		 	{
		 		return;
		 	}
		 	$scope.surveyObject.isPublished = !$scope.surveyObject.isPublished;
			if($scope.surveyObject.isPublished){
				$scope.publishBtnChanged = true;
				$scope.enableSaveBtnInSummary = $scope.publishBtnChanged;
				$scope.tabs[2].rightMenuList[1].iconUrl = $scope.bvchecktrue;
			}else{
				$scope.publishBtnChanged = false;
				$scope.enableSaveBtnInSummary = $scope.publishBtnChanged;
				$scope.tabs[2].rightMenuList[1].iconUrl = $scope.bvcheckfalse;
			}
			if($scope.surveyObject.isPublished){
				if(!$scope.surveyObject.sections || !$scope.surveyObject.sections.length > 0){
					bvDialog.showMessage(localizedMessages.get("CANNOT_PUBLISH_SURVEY_NO_SECTION_CREATED"));
					$scope.surveyObject.isPublished = false;
					$scope.tabs[2].rightMenuList[1].iconUrl = $scope.bvcheckfalse;
					return;
				}
				if($scope.surveyObject.sections && $scope.surveyObject.sections.length > 0){
					var isQuestionPresent = true;
					for(var i = 0; i<$scope.surveyObject.sections.length; i++){
						if(!($scope.surveyObject.sections[i].questions && $scope.surveyObject.sections[i].questions.length > 0)){
							isQuestionPresent = false;
							break;
						}
					}
					if(!isQuestionPresent){
						bvDialog.showMessage(localizedMessages.get("CANNOT_PUBLISH_ATLEAST_ONE_QUESTION_TO_BE_ADDED"));
						$scope.surveyObject.isPublished = false;
						$scope.tabs[2].rightMenuList[1].iconUrl = $scope.bvcheckfalse;
						return;
					}
				}
				if($scope.surveyObject.attendeesList && $scope.surveyObject.attendeesList.length > 0){
					var isRespondentPresent = false;
					for(var i = 0;i<$scope.surveyObject.attendeesList.length;i++){
						if($scope.surveyObject.attendeesList[i].isRespondent){
							isRespondentPresent = true;
							break;
						}
					}
					if(!isRespondentPresent){
						bvDialog.showMessage(localizedMessages.get("CANNOT_PUBLISH_NO_RESPONDENT"));
						$scope.surveyObject.isPublished = false;
						$scope.tabs[2].rightMenuList[1].iconUrl = $scope.bvcheckfalse;
						return;
					}
				}
			}
		 }
		if($scope.surveyObject && $scope.surveyObject.isPublished){
			$scope.publishIcon = $scope.bvchecktrue;
		}else{
			$scope.publishIcon =  $scope.bvcheckfalse;
		}
		
	 var showRespondentView = function(){
		 var minQuestionsMet = false;
		 if(surveysService.getSurvey().sections && surveysService.getSurvey().sections.length > 0){
			 for(var i=0;i<surveysService.getSurvey().sections.length;i++){
				 if(surveysService.getSurvey().sections[i].questions && surveysService.getSurvey().sections[i].questions.length>0){
					 minQuestionsMet = true;
				 }else{
					 minQuestionsMet = false;
					 break;
				 }
			 }
		 }
		 if(minQuestionsMet){
			 bvDialog.showRespondentView(localizedMessages.get("PREVIEW"), surveysService.getSurvey());
		 }else{
			 bvDialog.showMessage(localizedMessages.get('ADD_MIN_SECTION_QUESTIONS_PREVIEW'));
		 }
	 }
	 var questionMngrRightMenuList = [
	                                  	{
											title : localizedMessages.get("PREVIEW"),
											iconUrl : '',
											click : showRespondentView
	                                  	}
	                                  ];
	 var summaryRightMenuList = [
	                                  	{
											title : localizedMessages.get("PREVIEW"),
											iconUrl : '',
											click : showRespondentView
	                                  	},
	                                  	{
											title : localizedMessages.get("PUBLISH"),
											click : toggleActive,
											iconUrl : $scope.publishIcon,
											svgFill: '#fff'
	                                  	}
	                                  ];

	 $scope.tabs = 
		 [ {
		title : localizedMessages.get("DETAILS"),
		content : ''
	}, {
		title : localizedMessages.get("QUESTION_MANAGER"),
		content : '',
		rightMenuList: questionMngrRightMenuList
	}, {
		title : localizedMessages.get("SUMMARY"),
		content : '',
		rightMenuList: summaryRightMenuList
	} ];
	  	$scope.tabs[0].active = true;
	  	$scope.sectionObj_;
//	  	$scope.initSurveyDetails();
	  	$scope.showSectionCreateBox = function(){
	  		$scope.sectionObj = {};
	  		$scope.sectionObj.sectionName='';
	  		$scope.sectionObj.count = surveysService.getSurvey() && surveysService.getSurvey().sections ? surveysService.getSurvey().sections.length + 1 : 1;
	  		$scope.sectionObj.instructions = "";
	  		$scope.sectionObj.attachments = [];
	  		$scope.openedBox = "firstSectionCreateBox";
	  		$scope.sectionObj_ = angular.copy($scope.sectionObj);
	  		if(surveysService.getSurvey() && surveysService.getSurvey().sections && surveysService.getSurvey().sections.length >0){
	  			$scope.openedBox = '';
	  		}
	  	}
		var confirmForSendingAlert = function(savedSurvey) {
			var alert_msg = localizedMessages.get("SEND_SURVEY_ALERT");
			bvDialog.confirm(alert_msg, function() {
				surveysAlertService.survey= savedSurvey;
				surveysAlertService.openAlertDialog()
			});
			$state.go('home.surveys');
		}
	  	$scope.saveSurveyDetails = function(){
			$scope.showTitleError = false;
			$scope.isSaving=false;
			var survey = {};
			
			if ($scope.surveyObject.title != null) {
//				var htmlTagError = localizedMessages.get("HTML_TAG_ERROR");
//				var areTagsPresent = surveysService.checkHTMLTagErrorInSurveyObj($scope.surveyObject);
//				if(areTagsPresent){
//					bvDialog.showMessage(htmlTagError);
//					return;
//				}
				survey.name = $scope.surveyObject.title;
				var date = new Date($scope.surveyObject.startingDateLocal);
				survey.dueDate = date.getTime();
				if($scope.surveyObject.objectBaseUuid != '' && $scope.surveyObject.objectBaseUuid != undefined)
					survey.objectBaseUuid = $scope.surveyObject.objectBaseUuid;
				survey.priority = $scope.surveyObject.priority;
				survey.isSignatureRequired = $scope.surveyObject.signatureRequired;
				survey.isSequentialNumberingRequired = $scope.surveyObject.allowSequentialNumbering;
				survey.description = $scope.surveyObject.instructions != undefined ? $scope.surveyObject.instructions : '';
				$scope.surveyObject.isAnonymous = $scope.isAnonymousCopy;
				survey.isAnonymous = $scope.surveyObject.isAnonymous;
				survey.isSurveyTemplate = false;
				if(survey.isAnonymous)
					survey.isSignatureRequired = false;
				if($scope.notify != undefined){
					survey.notify = $scope.notify;
				}
				else{
					survey.notify = false;
				}
				survey.sendAlert = $scope.surveyObject.sendAlert;
				survey.isClosed = $scope.surveyObject.isClosed;
				survey.isPublished = $scope.surveyObject.isPublished;
				var attachmentBaseUuids=[];
				if($scope.surveyObject.attachmentsList && $scope.surveyObject.attachmentsList.length > 0){
					for(var i=0;i<$scope.surveyObject.attachmentsList.length;i++){
						attachmentBaseUuids.push($scope.surveyObject.attachmentsList[i].objectBaseUuid);
					}
				}
				$scope.surveyObject.attachmentBaseUuids = attachmentBaseUuids;
				var participants = [];
				var surveyOwners = [];
				if ($scope.surveyObject.attendeesList != null)
					for (var i in $scope.surveyObject.attendeesList) {
						var item = $scope.surveyObject.attendeesList[i];
						if (item.isOwner) {
							surveyOwners.push(item.objectBaseUuid);
						}
						if (item.isRespondent) {
							var newObj={objectBaseUuid:item.objectBaseUuid,isLocked:false,value:"notResponded"};
							participants.push(newObj);
						}
					}
				if (surveyOwners.indexOf(context.getModel().userVO.objectBaseUuid) == -1) {
					surveyOwners.push(context.getModel().userVO.objectBaseUuid);
				}
				if(participants.length<1)
				{
					bvDialog.showMessage(localizedMessages.get('MINIMIM_RESPONDANTS'));
					return;
				}
				$scope.surveyObject.participantBaseUuidAndResponseList = participants;
				survey.participantBaseUuidAndResponseList = participants;
				survey.documentObjectBaseUuidList = $scope.surveyObject.attachmentBaseUuids;
				//survey.sections = $scope.surveyObject.sections;
				surveysService.createOrUpdateSurvey(null,angular.toJson(survey),angular.toJson(surveyOwners)).then(function(data){
					if(!data.boName){
						surveysService.setSurvey(data);
						if($scope.cancelSurveyClicked){
							$state.go('home.surveys');
						}
						if($scope.tabs[2].active){
							if($scope.cancelSurveyClicked || $scope.cancelSurveySummaryClicked){
								$scope.cancelEdit();
							}else {
								if(data.isPublished){
								confirmForSendingAlert(data);
								}
								if(!data.isPublished){
									$state.go('home.surveys');
									}
							}
						}
							else{
							$scope.tabs[1].active = true;
							if(surveysService.getSurvey() == null)
								{
									$scope.showSectionCreateBox();
									surveysService.setSurvey(data);
									//$scope.initSurveyDetails();
								}else{
									surveysService.setSurvey(data);
								}
						}
					}
					else{
						if(data.boName == "FailureBO" && data.message.indexOf('does not have own permissions on the following documents') > -1){
							var message = data.message;
							var filesStr = message.substring(message.indexOf("["), message.indexOf("]") + 1);
							bvDialog.showMessage(localizedMessages.get("ERR_YOU_DO_NOT_HAVE_PERMISSIONS_ON") + ":" + filesStr);
						}
					}
				});
			} else {
					$scope.showTitleError = true;
					bvDialog.showMessage(localizedMessages.get('ERR_SURVEY_TITLE_CANNOT_BE_BLANK'));
					return;
			}
			$scope.isSaving=true;
			homeService.set_current_module_has_unsaved_changes(false, "CreateSurveyController");
			
	  	}
	  	$scope.addNewSectionIconClicked = function(index){
	  		var addNewSectionIconClickedGo = function(){
	  			$scope.openedBox = 'addNewSection';
		  		$scope.attachmentChooserFor = 'sectionAttachments';
		  		$scope.sectionObj = {};
		  		$scope.sectionObj.count = index;
		  		$scope.sectionObj.sectionName="";
		  		$scope.sectionObj.instructions="";
		  		$scope.sectionObj.attachments=[];
		  		if($scope.surveyObject.sections && $scope.surveyObject.sections.length >0){
		  			var section = _.find($scope.surveyObject.sections, {isDisplayOpen : true});
		  			if(section){
		  				section.isDisplayOpen = false;
		  			}
//		  			$scope.sectionObj.offsetTop = $scope.surveyObject.sections[$scope.surveyObject.sections.length - 1].offsetTop + 55;
//		  			if($scope.clickedIndex != undefined){
//		  				$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = false;
//		  				$scope.surveyObject.sections[$scope.clickedIndex].style = {'height' : 0 + 'px'};
//		  			}
		  		}
		  		$scope.sectionObj_ = angular.copy($scope.sectionObj);
	  		}
//	  		if($scope.openedBox == 'addNewSection'){
//	  			$scope.cancelSectionShow();
//	  		}
//	  		else{
	  		
	  		if(_.filter($scope.surveyObject.sections, {isDisplayOpen : true}).length > 0 && _.find($scope.surveyObject.sections, {isDisplayOpen : true}).questions === undefined){
	  			bvDialog.confirm($scope.ui.label.cancelConfirmMsg, function(){
	  				
	  				_.remove($scope.surveyObject.sections, {isDisplayOpen : true});
	  				$scope.openedBox = '';
	  				index--;
	  				addNewSectionIconClickedGo();
	  				
	  			});	  			
	  		}
	  		else{
	  			addNewSectionIconClickedGo();
	  		}
	  			
	  	}
	  	
	  	$scope.questionObj = {};
	  	$scope.questionObj_ = {};
	  	//$scope.sectionObj;
	  	var questionHeight;
	  	$scope.addNewQuestionIconClicked = function(index) {
	  		$scope.isNewQuestion = true;
	  		$scope.openedBox = 'questionCreateOrEditBox';
//	  		$scope.multipleChoiceOptions = angular.copy($scope.likertScaleOptionsInitialize);
//	  		$scope.likertScaleOptions = angular.copy($scope.likertScaleOptionsInitialize);
	  		var sec = surveysService.getSurveySection();
//	  		if(!questionHeight){
//	  			questionHeight = 42;
//	  		}
//	  		if(sec && sec.questions && sec.questions.length > 0){
//	  			sec.style = {'height' :( questionHeight * sec.questions.length) + 'px'};
//	  			sec.isDisplayOpen = true;
//	  		}
//	  		else{
//	  			sec.style = {'height' : 0 + 'px'};
//	  			sec.isDisplayOpen = true;
//	  		}
	  		
	  		$scope.noItem.defaultOption=false;
    		$scope.yesItem.defaultOption=false;
    		$scope.trueItem.defaultOption=false;
			$scope.falseItem.defaultOption = false;
			$scope.meetsItem.defaultOption=false;
			$scope.doesnotmeetItem.defaultOption=false;
			
	  		$scope.dropDownItem.itemName = "dropdown";
	  		$scope.dropDownItem.checked = true;
	  		$scope.selectedDropDown = $scope.dropDownValues[0];
	  		$scope.attachmentChooserFor = 'questionAttachments';
	  		$scope.questionObj = {};
	  		$scope.questionObj.questionType = $scope.selectedDropDown.value;
	  		$scope.questionObj.count = surveysService.getSurvey().sections[index].questions != undefined && surveysService.getSurvey().sections[index].questions.length > 0 ? surveysService.getSurvey().sections[index].questions.length + 1 : 1;
	  		$scope.questionObj.name="";
	  		$scope.questionObj.instructions="";
	  		$scope.questionObj.attachments = [];
	  		$scope.questionObj.name=surveysService.getSurvey().name + " " + localizedMessages.get("QUESTION");
	  		$scope.mandatoryReply = {
	  				mandatoryReply : false
	  			};
	  			$scope.mandatoryReplyItem = {
	  				itemName : "mandatoryReply",
	  				checked : false
	  			};
	  			$scope.allowComment = {
	  				allowComment : false
	  			};
	  			$scope.allowCommentItem = {
	  				itemName : "allowComment",
	  				state : "notChecked"
	  			};
	  			$scope.commentMandatory = {
	  				commentMandatory : false
	  			};
	  			$scope.commentMandatoryItem = {
	  				itemName : "commentMandatory",
	  				state : "disabled"
	  			};
	  			$scope.allowMultipleChoice = {
	  					allowMultipleChoice : false
	  				};
	  				$scope.allowMultipleChoiceItem = {
	  					itemName : "allowMultipleChoice",
	  					checked : false
	  				};

	  				$scope.multipleChoiceItem = {
	  					itemName : "multiplechoice",
	  					checked : false
	  				};
	  				$scope.openAnswerItem = {
	  					itemName : "openanswer",
	  					checked : false
	  				};
	  				$scope.likertScaleItem = {
	  					itemName : "likertscale",
	  					checked : false
	  				};
//			$timeout(function(){
//				if(!$scope.sectionObj.questions)
//		  			angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("scrollTo", $scope.sectionObj.offsetTop);
//				else{
//		  			angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("scrollTo", $scope.sectionObj.offsetTop + (questionHeight* $scope.sectionObj.questions.length));
//				}
//			},400);
			
			$scope.questionObj_ = angular.copy($scope.questionObj);
	  	}
	  	$scope.cancelQuestionToToggle = function(event, toggleCallBack, prevSection, currentSec, index){
	  		var cancelQuestionShowCallBack = function(){
	  			console.log("cancel question toggle");
	  			console.log($scope.copyQuestion.questionType);
	  			$scope.openedBox = "";
	  			$scope.questionTypeName = '';
		  		setSectionHeight(currentSec, questionHeight);
		  		//angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("scrollTo", currentSec.offsetTop);
	  			if(toggleCallBack){
	  				if(prevSection){
	  					prevSection.isDisplayOpen = false;
	  					prevSection.style = {'height' : ''};
	  					$scope.questionObj.questionType=$scope.copyQuestion.questionType;
	  					
	  					$scope.multipleChoiceOptions = [ {
							option : localizedMessages.get("CHOICE1")
						}, {
							option : localizedMessages.get("CHOICE2")
						}, {
							option : localizedMessages.get("CHOICE3")
						}, {
							option : localizedMessages.get("CHOICE4")
						}, {
							option : localizedMessages.get("CHOICE5")
						} ];
	  					
	  					$scope.likertScaleOptions = [ {
	    	  				option : localizedMessages.get("STRONGLY_AGREE"),
	    	  				value : 10
	    	  			}, {
	    	  				option : localizedMessages.get("AGREE"),
	    	  				value : 5
	    	  			}, {
	    	  				option : localizedMessages.get("NO_OPINION"),
	    	  				value : 0
	    	  			}, {
	    	  				option : localizedMessages.get("DISAGREE"),
	    	  				value : -5
	    	  			}, {
	    	  				option : localizedMessages.get("STRONGLY_DISAGREE"),
	    	  				value : -10
	    	  			} ];
	  					
	  					console.log($scope.copyQuestion.questionType);
	  				}
	  				console.log($scope.copyQuestion.questionType);
	  				toggleCallBack(event, currentSec, index);
		  		}
	  		}
	  		var questionObjCopy = angular.copy($scope.questionObj);
	  		delete questionObjCopy.$$hashKey;
	  		var questionNotModified = _.isEqual($scope.questionObj_, questionObjCopy);
	  		if(surveysService.getIsSurveyPublished() == true && (!prevSection.questions || prevSection.questions.length == 0)){
	  			bvDialog.showMessage(localizedMessages.get("ATLEAST_ONE_QUESTION_AND_SECTION_FOR_PUBLISHED_SURVEYS"));
	  			return;
	  		}
	  		if(!questionNotModified){
	  			bvDialog.confirm($scope.ui.label.cancelConfirmMsg, cancelQuestionShowCallBack);
	  		}
	  		else{
	  			cancelQuestionShowCallBack();
	  		}
	  		
	  		$scope.questionObj = {}
	  	}
	  	$scope.cancelQuestion = function(section, index, currentSection, callBackToViewSection){
	  		console.log("cancel question");
	  		var cancelQuestionShowCallBack = function(){
	  			$scope.openedBox = "";
	  			$scope.questionTypeName = '';
  				setSectionHeight(section, questionHeight);
//		  		angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("scrollTo", section.offsetTop);
	  			if(callBackToViewSection){
	  				$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = false;
					$scope.surveyObject.sections[$scope.clickedIndex].style = {'height' : ''};
					$scope.questionObj.questionType=$scope.copyQuestion.questionType;
					
					$scope.multipleChoiceOptions = [ {
						option : localizedMessages.get("CHOICE1")
					}, {
						option : localizedMessages.get("CHOICE2")
					}, {
						option : localizedMessages.get("CHOICE3")
					}, {
						option : localizedMessages.get("CHOICE4")
					}, {
						option : localizedMessages.get("CHOICE5")
					} ];
					
					$scope.likertScaleOptions = [ {
    	  				option : localizedMessages.get("STRONGLY_AGREE"),
    	  				value : 10
    	  			}, {
    	  				option : localizedMessages.get("AGREE"),
    	  				value : 5
    	  			}, {
    	  				option : localizedMessages.get("NO_OPINION"),
    	  				value : 0
    	  			}, {
    	  				option : localizedMessages.get("DISAGREE"),
    	  				value : -5
    	  			}, {
    	  				option : localizedMessages.get("STRONGLY_DISAGREE"),
    	  				value : -10
    	  			} ];
					
					callBackToViewSection(index, currentSection);
		  		}
	  			
	  			_.each($scope.surveyObject.sections, function(sec){
	  				if(sec.isDisplayOpen){
	  					sec.isDisplayOpen = false;
	  				}
	  			});
	  		}
	  		var questionObjCopy = angular.copy($scope.questionObj);
	  		delete questionObjCopy.$$hashKey;
	  		var questionNotModified = _.isEqual($scope.questionObj_, questionObjCopy);
	  		if(!section)
	  		{
	  			section = surveysService.getSurveySection();
	  		}
	  		if(surveysService.getIsSurveyPublished() == true && section && (!section.questions || section.questions.length == 0)){
	  			bvDialog.showMessage(localizedMessages.get("ATLEAST_ONE_QUESTION_AND_SECTION_FOR_PUBLISHED_SURVEYS"));
	  			return;
	  		}
	  		if(!questionNotModified){
	  			bvDialog.confirm($scope.ui.label.cancelConfirmMsg, function(){
	  				if(section.questions === undefined){
			  			bvDialog.showContinue($scope.ui.label.section_must_have_question, localizedMessages.get('CONTINUE'), function(){});
			  		}
	  				else{
	  					cancelQuestionShowCallBack();
	  				}
	  			});
	  		}
	  		else{
	  			if(section.questions === undefined){
		  			bvDialog.showContinue($scope.ui.label.section_must_have_question, localizedMessages.get('CONTINUE'), function(){});
		  		}
	  			else{
	  				cancelQuestionShowCallBack();
	  			}
	  		}
	  	}
	  	
	  	$scope.cancelNewSecToViewOld = function(index, question, viewQuestionCallBack){
	  		console.log("new sec to view old");
	  		var cancelQuestionShowCallBack = function(){
	  			$scope.openedBox = "";
	  			$scope.questionTypeName = '';
	  			if(viewQuestionCallBack){
	  				viewQuestionCallBack(index, question);
		  		}
	  		}
	  		var questionObjCopy = angular.copy($scope.questionObj);
	  		delete questionObjCopy.$$hashKey;
	  		var questionNotModified = _.isEqual($scope.questionObj_, questionObjCopy);
	  		if(!questionNotModified){
	  			bvDialog.confirm($scope.ui.label.cancelConfirmMsg, cancelQuestionShowCallBack);
	  		}
	  		else{
	  			cancelQuestionShowCallBack();
	  		}
	  	}
	  	
	  	$scope.cancelSectionShowToToggle = function(event, toggleCallBack, prevSection, currentSec, index){
	  		console.log("cancel section to toggle");
	  		var cancelSectionShowCallBack = function(){
	  			$scope.openedBox = "";
	  			$scope.attachmentChooserFor = 'details';
//		  		if(prevSection){
//		  			prevSection.isDisplayOpen = false;
//		  		}
	  			if(toggleCallBack && currentSec && index){
	  				toggleCallBack(event, currentSec, index);
		  		}
	  		}
	  		var sectionObjCopy = angular.copy($scope.sectionObj);
	  		delete sectionObjCopy.$$hashKey;
	  		var sectionNotModified = _.isEqual($scope.sectionObj_, sectionObjCopy);
	  		if(!sectionNotModified){
	  			bvDialog.confirm($scope.ui.label.cancelConfirmMsg, cancelSectionShowCallBack);
	  		}
	  		else
	  			cancelSectionShowCallBack();
	  	}
	  	
	  	$scope.cancelSectionShow = function(section, index, currentSection, callback){
	  		var cancelSectionShowCallBack = function(){
	  			$scope.openedBox = "";
	  			$scope.attachmentChooserFor = 'details';
//		  		if(section){
//		  			section.isDisplayOpen = false;
//		  		}
	  			if(callback !== undefined  && index !== undefined && currentSection !== undefined){
		  			callback(index, currentSection);
		  		}
	  			
	  			_.each($scope.surveyObject.sections, function(sec){
	  				if(sec.isDisplayOpen){
	  					sec.isDisplayOpen = false;
	  				}
	  			});
	  		}
	  		var sectionObjCopy = angular.copy($scope.sectionObj);
	  		delete sectionObjCopy.$$hashKey;
	  		var sectionNotModified = _.isEqual($scope.sectionObj_, sectionObjCopy);
	  		if(!sectionNotModified){
	  			bvDialog.confirm($scope.ui.label.cancelConfirmMsg, cancelSectionShowCallBack);
	  		}
	  		else
	  			cancelSectionShowCallBack();
	  	}
	  	
	  	$scope.saveSection = function(){
	  		var isEditSection = false;
	  		if($scope.sectionObj && $scope.sectionObj.sectionName != '' && $scope.sectionObj.sectionName != undefined){
//				var areTagsPresent = surveysService.checkHTMLTagErrorInSurveySectionObj($scope.sectionObj);
//				if(areTagsPresent){
//					bvDialog.showMessage(localizedMessages.get("HTML_TAG_ERROR"));
//					return;
//				}
	  			if($scope.sectionObj.objectBaseUuid != undefined)
	  				isEditSection = true;
	  			else
	  				isEditSection = false;
	  			var sectionVO = {};
	  			sectionVO.sectionName = $scope.sectionObj.sectionName;
	  			
		  		if($scope.sectionObj.objectBaseUuid != undefined)
		  			sectionVO.objectBaseUuid = $scope.sectionObj.objectBaseUuid;
		  		
		  		sectionVO.documentObjectBaseUuidList=[];
		  		if($scope.sectionObj.attachments){
			  		for(var i=0;i<$scope.sectionObj.attachments.length;i++){
		    			sectionVO.documentObjectBaseUuidList.push($scope.sectionObj.attachments[i].objectBaseUuid);
			  		}
		  		}
		  		sectionVO.instructions =  $scope.sectionObj.instructions != undefined ? $scope.sectionObj.instructions : "";
	    		var surveySection = sectionVO;
	    		surveySection.isDisplayOpen = false;
	    		var modelSurvey = surveysService.getSurvey();
	    		if(!modelSurvey.sections){
	    			modelSurvey.sections = [];
	    		}
	    		if(isEditSection){
	    			surveySection.questions = $scope.sectionObj.questions;
	    			modelSurvey.sections[$scope.sectionObj.index] = surveySection;
	    			
			    	surveysService.saveSurveySection(surveysService.getSurvey().objectBaseUuid,angular.toJson(sectionVO)).then(function(data)
	    	    	{
	    				if(data && data != "" && data.boName != "FailureBO"){
	    					modelSurvey.sections[$scope.sectionObj.index] = data;
	    					modelSurvey.sections[$scope.sectionObj.index].questions = $scope.sectionObj.questions;
	    					$scope.openedBox = "";
	    				}
	    	    	});
	    			
	    		}
    			else{
    				modelSurvey.sections.push(surveySection);
    			}
	    		surveysService.setSurvey(modelSurvey);
	    		surveysService.setSurveySection(surveySection);
//	    		$scope.clickedIndex = surveysService.getSurvey().sections.length - 1;
	    		//$scope.initSurveyDetails();
	    		if(!isEditSection){
	    			$scope.clickedIndex = surveysService.getSurvey().sections.length - 1;
	    			//surveySection.isDisplayOpen = false;
	    			$scope.addNewQuestionIconClicked($scope.clickedIndex);
	    		}
		  		
//		    	surveysService.saveSurveySection(surveysService.getSurvey().objectBaseUuid,angular.toJson(sectionVO)).then(function(data)
//		    	    	{
//		    				if(data && data != "" && data.boName != "FailureBO"){
//		    					$scope.openedBox = "";
//			    	    		var surveySection={};
//			    	    		surveySection = data;
//			    	    		surveySection.isDisplayOpen = false;
//			    	    		var modelSurvey = surveysService.getSurvey();
//			    	    		if(!modelSurvey.sections){
//			    	    			modelSurvey.sections = [];
//			    	    		}
//			    	    		if(isEditSection){
//			    	    			surveySection.questions = $scope.sectionObj.questions;
//			    	    			modelSurvey.sections[$scope.sectionObj.index] = surveySection;
//			    	    		}
//		    	    			else{
//		    	    				modelSurvey.sections.push(surveySection);
//		    	    			}
//			    	    		surveysService.setSurvey(modelSurvey);
//			    	    		surveysService.setSurveySection(surveySection);
////			    	    		$scope.clickedIndex = surveysService.getSurvey().sections.length - 1;
//			    	    		//$scope.initSurveyDetails();
//			    	    		if(!isEditSection){
//			    	    			$scope.clickedIndex = surveysService.getSurvey().sections.length - 1;
//			    	    			//surveySection.isDisplayOpen = false;
//			    	    			$scope.addNewQuestionIconClicked($scope.clickedIndex);
//			    	    		}
////			    	    		var dialog = bvDialog.showMessage("Section saved");
////					  			$timeout(function(){
////				    	  			dialog.close();
////								},1000);
//		    				}
//		    	    	});
	  		}
	    	else{
	    		bvDialog.showMessage(localizedMessages.get("SECTION_NAME_CANNOT_BE_EMPTY"));
	    	}
	    }
	  	$scope.saveQuestion = function(){
	  		if($scope.questionObj.instructions != '' && $scope.questionObj.instructions != undefined){
//	  			var areTagsPresent = surveysService.checkHTMLTagErrorInSurveyQuestionObj($scope.questionObj);
//				if(areTagsPresent){
//					bvDialog.showMessage(localizedMessages.get("HTML_TAG_ERROR"));
//					return;
//				}
		  		var questionIndex = $scope.questionObj.count - 1;
		  		var isEditQuestion = false;
		  		var saveDefaultResponse;
		  		var regExpr=/,|;/;
		  		if($scope.questionObj.objectBaseUuid != undefined)
		  			isEditQuestion = true;
		  		
		    	  if($scope.mandatoryReply.mandatoryReply)
		    		  $scope.questionObj.isResponseMandatory = true;
		    	  else
		    		  $scope.questionObj.isResponseMandatory = false;
		    	  if($scope.allowComment.allowComment)
		    		  $scope.questionObj.areCommentsAllowed = true;
		    	  else
		    		  $scope.questionObj.areCommentsAllowed = false;
		    	  if($scope.commentMandatory.commentMandatory)
		    		  $scope.questionObj.areCommentsMandatory = true;
		    	  else
		    		  $scope.questionObj.areCommentsMandatory = false;
		    	  
		    	  $scope.questionObj.questionType = $scope.questionTypeName;
		    	  $scope.questionObj.comments = $scope.questionObj.instructions;
		    	  $scope.questionObj.options = [];
		    	  if($scope.dropDownItem.checked){
		    		  $scope.questionObj.questionType=$scope.selectedDropDown.value; 
		    		  
		    		  if($scope.questionObj.questionType=="yesno")
		    		  	{
		    			  if($scope.yesItem.defaultOption)
			    		  {
			    			  saveDefaultResponse = 'yes';
			    		  }
			    		  else if($scope.noItem.defaultOption)
			    		  {
			    			  saveDefaultResponse= 'no';
			    		  }
		    			}
		    		  if($scope.questionObj.questionType=="meetsornot") 
		    		  {
		    			   if($scope.meetsItem.defaultOption)
			    		  {
			    			  saveDefaultResponse='meets';
			    		  }
			    		  else if($scope.doesnotmeetItem.defaultOption)
			    		  {
			    			  saveDefaultResponse='does not meet';
			    		  }
		    		  }
		    			 if($scope.questionObj.questionType=="truefalse")
		    			{
		    				  if($scope.trueItem.defaultOption)
				    		  {
				    			  	saveDefaultResponse='true';
				    		  }
				    		  else if($scope.falseItem.defaultOption)
				    		  {
				    			  saveDefaultResponse='false';
				    		  }
		    			}
		    	  }
		    	  
		    	  if($scope.questionObj.questionType=="multiplechoice" || $scope.questionObj.questionType=="multiplechoicemultiresponse")
		    	  {
		    		  for(var i=0;i<$scope.multipleChoiceOptions.length;i++)
		    		  {
		    			  if($scope.multipleChoiceOptions[i].defaultOption)
		    			  {
		    				  saveDefaultResponse=$scope.multipleChoiceOptions[i].option;
		    			  }
		    		  }
		    		  var areDuplicatesMultipleChoice = areDuplicateItemsInObject($scope.multipleChoiceOptions);
	    			     if (areDuplicatesMultipleChoice == true) {
	    			    	 bvDialog.showMessage(localizedMessages.get("UNIQUE_OPTIONS"));
	    			    	 $scope.questionObj.questionType="multiplechoice";
	    			    	 return;
	    			     }
		    		for(var i=0;i<$scope.multipleChoiceOptions.length;i++)
		    		{
		    			if($scope.multipleChoiceOptions[i].option == ''){
	    				  bvDialog.showMessage(localizedMessages.get("CHOICE_CANNOT_BE_EMPTY"));
	    				  $scope.questionObj.questionType="multiplechoice";
	    				  return;
		    				}  
		    			  if(regExpr.test($scope.multipleChoiceOptions[i].option))
		    			  {
		    				  bvDialog.showMessage(localizedMessages.get("SPECIAL_CHARACTERS_MULTIPLE_CHOICE"));
		    				  $scope.questionObj.questionType="multiplechoice";
		    				  return;
		    			  }
		    		}
		    		  var newstr=JSON.stringify($scope.multipleChoiceOptions);
		    		  $scope.questionObj.options.push($scope.multipleChoiceOptions);
		    		  $scope.questionObj.multipleChoiceValues=[];
		    		  for(var i=0;i<$scope.multipleChoiceOptions.length;i++)
		    		  {
		    			  var newObj={name:$scope.multipleChoiceOptions[i].option,value:false,isLocked:false};
		    			  $scope.questionObj.multipleChoiceValues.push(newObj);
		    		  }
		    		  if($scope.allowMultipleChoice.allowMultipleChoice)
		    		  {
		    			  $scope.questionObj.questionType="multiplechoicemultiresponse";
		    		  }  
		    	  }
		    	  if($scope.questionObj.questionType=="likertscale")
		    	  {
		    		  var areDuplicatesLikertScale = areDuplicateItemsInObject($scope.likertScaleOptions);
	    			     if (areDuplicatesLikertScale == true) {
	    			    	 bvDialog.showMessage(localizedMessages.get("UNIQUE_OPTIONS"));
	    			    	 return;
	    			     }
		    		  for(var i=0;i<$scope.likertScaleOptions.length;i++)
		    		  {
		    			  if($scope.likertScaleOptions[i].option == ''){
		    				  bvDialog.showMessage(localizedMessages.get("CHOICE_CANNOT_BE_EMPTY"));
		    				  return;
		    			  }
//		    			  if($scope.likertScaleOptions[i].value == ''){
//		    				  bvDialog.showMessage(localizedMessages.get("CHOICE_VALUE_CANNOT_BE_EMPTY"));
//		    				  return;
//		    			  }
		    			  if(Number($scope.likertScaleOptions[i].value) !== parseInt(Number($scope.likertScaleOptions[i].value))){
		    				  bvDialog.showMessage(localizedMessages.get("CHOICE_VALUE_SHOULD_BE_NUMBER"));
		    				  return;
		    			  }
		    			  if(regExpr.test($scope.likertScaleOptions[i].option))
		    			  {
		    				  bvDialog.showMessage(localizedMessages.get("SPECIAL_CHARACTERS_MULTIPLE_CHOICE"));
		    				  return;
		    			  }
		    		  }
		    		  
		    		  $scope.questionObj.likertScaleValues=[];
		    		  $scope.questionObj.options.push($scope.likertScaleOptions);
		    		  
		    		  for(var i=0;i<$scope.likertScaleOptions.length;i++)
		    		  {
		    			  if($scope.likertScaleOptions[i].defaultOption)
		    				{
		    				  saveDefaultResponse=$scope.likertScaleOptions[i].value;
		    				}
		    			  var newObj={name:$scope.likertScaleOptions[i].option,value:$scope.likertScaleOptions[i].value,isLocked:false,defaultOption:false};
		    			  $scope.questionObj.likertScaleValues.push(newObj);
		    		  }
		    	  }
		    	  
		    	  var description = $scope.questionObj.name;
		    	  if(!$scope.sectionObj.questions){
		    		  $scope.sectionObj.questions = [];
		    	  }
		    	  $scope.questionObj.documentObjectBaseUuidList = [];
		    	  for(var i=0;i<$scope.questionObj.attachments.length;i++){
			    		$scope.questionObj.documentObjectBaseUuidList.push($scope.questionObj.attachments[i].objectBaseUuid);
		    	  }
		    	 $scope.toSaveQuestion = {};
		    	 $scope.toSaveQuestion.name = $scope.questionObj.name;
		    	 $scope.toSaveQuestion.questionType = $scope.questionObj.questionType;
		    	 $scope.toSaveQuestion.isResponseMandatory = $scope.questionObj.isResponseMandatory;
		    	 $scope.toSaveQuestion.areCommentsAllowed = $scope.questionObj.areCommentsAllowed;
		    	 $scope.toSaveQuestion.areCommentsMandatory = $scope.questionObj.areCommentsMandatory;
		    	 $scope.toSaveQuestion.likertScaleValues = $scope.questionObj.likertScaleValues;
		    	 $scope.toSaveQuestion.documentObjectBaseUuidList = $scope.questionObj.documentObjectBaseUuidList;
		    	 $scope.toSaveQuestion.multipleChoiceValues = $scope.questionObj.multipleChoiceValues;
		    	 $scope.toSaveQuestion.defaultSelectedResponse=saveDefaultResponse;
		    	
		    	 console.log($scope.toSaveQuestion.defaultSelectedResponse);
		    	 if(isEditQuestion)
		    		 $scope.toSaveQuestion.objectBaseUuid = $scope.questionObj.objectBaseUuid;
		    	 $scope.toSaveQuestion.comments = $scope.questionObj.instructions != undefined ? $scope.questionObj.instructions : '';
		  		
		    	 function createOrUpdateQuestionForSection() {
				  		surveysService.createOrUpdateQuestionForSection(surveysService.getSurvey().objectBaseUuid,surveysService.getSurveySection().objectBaseUuid,angular.toJson($scope.toSaveQuestion)).then(function(data)
				    			{
				  					//$scope.openedBox = "";
				  					if(data && data != "" && data.boName != "FailureBO"){
				  						$scope.questionTypeName = '';
					    	  			$scope.questionObj=data;
					    	  			$scope.questionObj.attachments = data.attachedObjectsList;
					    	  			var section=surveysService.getSurveySection();
					    	  			if(!section.questions){
					    	  				section.questions = [];
					    	  			}if(!isEditQuestion){
					    	  				section.questions.push($scope.questionObj);
					    	  				//section.isDisplayOpen = true;
					    	  				setSectionHeight(section, questionHeight);
					    	  				//section.style = {'height' :( questionHeight * section.questions.length) + 'px'};
					    	  				$scope.addNewQuestionIconClicked($scope.clickedIndex);
//								  			$timeout(function(){
//							    	  			angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("scrollTo", section.offsetTop + (questionHeight* section.questions.length));
//											},400);
					    	  			}
					    	  			else{
					    	  				section.questions[questionIndex] = $scope.questionObj;
					    	  				$scope.surveyObject.sections[$scope.clickedIndex].questions[questionIndex] = data;
					    	  				setSectionHeight(section, questionHeight);
					    	  				
					    	  				$scope.openedBox = '';
					    	  				
					    	  				//section.style = {'height' :( questionHeight * section.questions.length) + 42+ 'px'};
//								  			$timeout(function(){
//							    	  			angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("scrollTo", section.offsetTop);
//											},400);
					    	  			}
					    	  			surveysService.setSurveySection(section);
					    	  			$scope.clearDefaultOption();
					    	  			
					    	  			$scope.multipleChoiceOptions = [ {
					    	  				option : localizedMessages.get("CHOICE1")
					    	  			}, {
					    	  				option : localizedMessages.get("CHOICE2")
					    	  			}, {
					    	  				option : localizedMessages.get("CHOICE3")
					    	  			}, {
					    	  				option : localizedMessages.get("CHOICE4")
					    	  			}, {
					    	  				option : localizedMessages.get("CHOICE5")
					    	  			} ];
					    	  			
					    	  			$scope.likertScaleOptions = [ {
					    	  				option : localizedMessages.get("STRONGLY_AGREE"),
					    	  				value : 10
					    	  			}, {
					    	  				option : localizedMessages.get("AGREE"),
					    	  				value : 5
					    	  			}, {
					    	  				option : localizedMessages.get("NO_OPINION"),
					    	  				value : 0
					    	  			}, {
					    	  				option : localizedMessages.get("DISAGREE"),
					    	  				value : -5
					    	  			}, {
					    	  				option : localizedMessages.get("STRONGLY_DISAGREE"),
					    	  				value : -10
					    	  			} ];
					    	  			
					    	  			//questionHeight = angular.element(ev.target).closest('[ng-repeat]').find('.questionBox')[0].getBoundingClientRect().height;
				  					}else if(data && data != "" && data.boName == "FailureBO"){
				  						if(data.message && data.message.contains('Duplicate Entry for')){
					  						var splitMessage = data.message.split(":");
					  						var section=surveysService.getSurveySection();
					  						//section.isDisplayOpen = true;
					  						$scope.openedBox = 'questionCreateOrEditBox';
					  						var errorMes = localizedMessages.get("ERR_DUPLICATE_ENTRIES_SURVEY", {"a" : $scope.questionDisplayNames[$scope.questionObj.questionType], "b" : splitMessage[1]});
					  						bvDialog.showMessage(errorMes);
					  						return;
				  						}
				  					}
//				    	    		var dialog = bvDialog.showMessage("Queston saved");
//						  			$timeout(function(){
//					    	  			dialog.close();
//									},1000);
				    		    });
		    	 }
		    	 
		    	 
		    	 if("objectBaseUuid" in surveysService.getSurveySection()){
		    		 createOrUpdateQuestionForSection();
		    	 }
		    	 else{
		    		 surveysService.saveSurveySection(surveysService.getSurvey().objectBaseUuid,angular.toJson(surveysService.getSurveySection())).then(function(data)
			    		 {
		    				if(data && data != "" && data.boName != "FailureBO"){
		    					//$scope.openedBox = "";
			    	    		var surveySection={};
			    	    		surveySection = data;
			    	    		surveySection.isDisplayOpen = false;
			    	    		var modelSurvey = surveysService.getSurvey();
//			    	    		if(!modelSurvey.sections){
//			    	    			modelSurvey.sections = [];
//			    	    		}
//			    	    		if(isEditSection){
//			    	    			surveySection.questions = $scope.sectionObj.questions;
//			    	    			modelSurvey.sections[$scope.sectionObj.index] = surveySection;
//			    	    		}
//		    	    			else{
			    	    		_.remove(modelSurvey.sections, surveysService.getSurveySection());
		    	    			modelSurvey.sections.push(surveySection);
//		    	    			}
			    	    		surveysService.setSurvey(modelSurvey);
			    	    		surveysService.setSurveySection(surveySection);
	//					    	    		$scope.clickedIndex = surveysService.getSurvey().sections.length - 1;
			    	    		//$scope.initSurveyDetails();
			    	    		//if(!isEditSection){
			    	    		$scope.clickedIndex = surveysService.getSurvey().sections.length - 1;
			    	    		surveySection.isDisplayOpen = true;
			    	    			//surveySection.isDisplayOpen = false;
			    	    			//$scope.addNewQuestionIconClicked($scope.clickedIndex);
			    	    		//}
			    	    		
			    	    		createOrUpdateQuestionForSection();
	//					    	    		var dialog = bvDialog.showMessage("Section saved");
	//							  			$timeout(function(){
	//						    	  			dialog.close();
	//										},1000);
		    				}
		    	    	 });
			  	}


	  		}else{
	  			bvDialog.showMessage(localizedMessages.get("QUESTION_NAME_CANNOT_BE_EMPTY"));
	  		}
	  	}
	  	$scope.eqitQuestionDefaultOption=function(question)
	  	{
	  		if(question.questionType=="yesno")
	  		{
	  			if(question.defaultSelectedResponse=="yes" || question.defaultSelectedResponse=="Yes")
	  			{
	  				$scope.yesItem.defaultOption=true;
	  				console.log("yes");
	  				
	  			}
	  			else if(question.defaultSelectedResponse=="no" || question.defaultSelectedResponse=="No")
	  			{
	  				$scope.noItem.defaultOption=true;
	  			}
	  		}
	  		else if(question.questionType=="meetsornot")
	  		{
	  			if(question.defaultSelectedResponse=="meets" ||question.defaultSelectedResponse=="Meets")
	  			{
	  				$scope.meetsItem.defaultOption=true;
	  			}
	  			else if (question.defaultSelectedResponse=='does not meet' ||question.defaultSelectedResponse=='Does Not Meet')
	  			{
	  					$scope.doesnotmeetItem.defaultOption=true;
	  			}
	  		}	
	  		else if(question.questionType=="truefalse")
	  		{
	  			if(question.defaultSelectedResponse=="true" || question.defaultSelectedResponse=="True")
	  			{
	  				$scope.trueItem.defaultOption=true;
	  			}
	  			else if(question.defaultSelectedResponse=="false" || question.defaultSelectedResponse=="False")
	  			{
	  				$scope.falseItem.defaultOption=true;
	  			}
	  			
	  		}
	  		else if(question.questionType=="multiplechoice" || question.questionType=="multiplechoicemultiresponse")
	  		{
	  			for(var i=0;i<$scope.multipleChoiceOptions.length;i++)
	  			{
	  				if($scope.multipleChoiceOptions[i].option==question.defaultSelectedResponse)
	  				{
	  					$scope.multipleChoiceOptions[i].defaultOption=true;
	  				}	
	  			}	
	  		}
	  		else if(question.questionType=="likertscale")
	  		{
	  			for(var i=0;i<$scope.likertScaleOptions.length;i++)
	  				{
	  					if(question.defaultSelectedResponse==$scope.likertScaleOptions[i].value)
	  					{
	  						$scope.likertScaleOptions[i].defaultOption=true;
	  					}
	  				}
	  		}
	  	}
		   $scope.checkDropDownItem=function(itemType)
		   {
			   var index=-1;
			   for(var i=0;i<$scope.dropDownValues.length;i++)
			   { 
				   if($scope.dropDownValues[i].value==itemType)
				   {
					   return i;
				   }
			   }
			   if(itemType=="multipleChoice"|| itemType==$scope.multipleChoiceItem.itemName || itemType=="multiplechoicemultiresponse")
		    	{
		    		return 3;
		    	}
		    	else if(itemType=="likertScale" || itemType==$scope.likertScaleItem.itemName)
		    	{
		    		
		    		return 5;
		    		
		    	}	
		    	else if(itemType=="openAnswer" || itemType==$scope.openAnswerItem.itemName)
		    	{
		    		return 4;
		    		
		    	}	
			   
			   
			   return index;
		   }
	  	$scope.questionObj = {};
	  	
	  	var viewQuestion = function(index,question){
	  		console.log(question);
	  		
	  		$scope.yesItem.defaultOption = false;
	  		$scope.noItem.defaultOption = false;
	  		$scope.meetsItem.defaultOption = false;
	  		$scope.doesnotmeetItem.defaultOption = false;
	  		$scope.falseItem.defaultOption = false;
	  		$scope.trueItem.defaultOption = false;
	  		
	  		$scope.isNewQuestion = false;
	  		$scope.openedBox = "questionCreateOrEditBox";
	  		$scope.attachmentChooserFor = 'questionAttachments';
	  		$scope.questionObj = {};
	  		$scope.questionObj.questionType = angular.copy(question.questionType);
	  		$scope.questionTypeName = $scope.questionObj.questionType;
	  		$scope.questionObj  = angular.copy(question);
	  		$scope.questionObj.count = index != undefined ? index + 1 : 1;
	  		$scope.questionObj.name = question.name;
	  		$scope.questionObj.instructions = question.comments;
	  		$scope.questionObj.attachments = question.attachedObjectsList == undefined ? new Array() : question.attachedObjectsList;
	  		$scope.questionObj.attachedObjectsList = $scope.questionObj.attachments;
	  		var surveyBaseUuid=surveysService.getSurvey().objectBaseUuid;
	    	surveysService.setSurveySectionQuestion($scope.questionObj);
	    	$scope.sectionCount=surveysService.getSurvey().sections.length - 1;
	    	$scope.questionCount=index;
	    	$scope.questionObj.model=$scope.questionObj.comments;
	    	$scope.showMultipleorLikertScaleDiv=true;
	    	angular.copy($scope.questionObj,$scope.copyQuestion);
	    	var indexDropDown=$scope.checkDropDownItem($scope.questionObj.questionType);
	    	console.log($scope.questionObj.questionType);
	    	console.log($scope.copyQuestion.questionType);
//	    	$scope.multipleChoiceOptions=[{option:"Choice1"},{option:"Choice2"},{option:"Choice3"},{option:"Choice4"},{option:"Choice5"}];
//		    $scope.likertScaleOptions=[{option:"Strongly Agree",value:10},{option:"Agree",value:5},
//			                                 {option:"No Opinion",value:0},{option:"Disagree",value:-5},{option:"Strongly Disagree",value:-10}];
	    	
	    	
//	    	if($scope.clickedIndex !== undefined){
//	    		$scope.surveyObject.sections[$scope.clickedIndex].style = {'height' : ''}
//	    	}
	    	//slideUpSectionAnimation($scope.sectionObj, true);
	    	$scope.selectedDropDown = $scope.dropDownValues[indexDropDown];
	    	
	    	if(indexDropDown!=-1 && indexDropDown<3)
	    	{
	    		
	    		$scope.questionObj.questionType=$scope.selectedDropDown.value;
	    		$scope.multipleChoiceItem.checked=false;
	    		$scope.openAnswerItem.checked=false;
	    		$scope.dropDownItem.checked=true;
	    		$scope.likertScaleItem.checked=false;
	    	}
	    	else if("multiplechoicemultiresponse" == $scope.questionObj.questionType)
	    	{
	    		$scope.questionObj.questionType="multiplechoice";
	    		$scope.allowMultipleChoice.allowMultipleChoice=true;
	    		$scope.allowMultipleChoiceItem.checked=true;
	    		
	    		$scope.multipleChoiceOptions=[];
	    		for(var i=0;i<$scope.questionObj.multipleChoiceValues.length;i++)
	    		{
	    			var newObj={option:$scope.questionObj.multipleChoiceValues[i].name,defaultOption:false};
	    			$scope.multipleChoiceOptions.push(newObj);
	    		}
	    		$scope.multipleChoiceItem.checked=true;
	    		$scope.openAnswerItem.checked=false;
	    		$scope.dropDownItem.checked=false;
	    		$scope.likertScaleItem.checked=false;
	    		$scope.showMultipleorLikertScaleDiv=false;
	    	}
	    	
	    	else if($scope.questionObj.questionType == $scope.multipleChoiceItem.itemName)
	    	{
	    		$scope.questionObj.questionType=$scope.multipleChoiceItem.itemName;
	    		$scope.multipleChoiceItem.checked=true;
	    		$scope.openAnswerItem.checked=false;
	    		$scope.dropDownItem.checked=false;
	    		$scope.likertScaleItem.checked=false;
	    		$scope.allowMultipleChoiceItem.checked=false;
	    		$scope.allowMultipleChoice.allowMultipleChoice=false;
	    		
	    		$scope.multipleChoiceOptions=[];
	    		for(var i=0;i<$scope.questionObj.multipleChoiceValues.length;i++)
	    		{
	    			var newObj={option:$scope.questionObj.multipleChoiceValues[i].name,defaultOption:false};
	    			$scope.multipleChoiceOptions.push(newObj);
	    		}
	    		
	    		$scope.showMultipleorLikertScaleDiv=false;
	    	}
	    	else if($scope.questionObj.questionType == $scope.openAnswerItem.itemName)
	    	{
	    		$scope.questionObj.questionType=$scope.openAnswerItem.itemName;
	    		$scope.multipleChoiceItem.checked=false;
	    		$scope.openAnswerItem.checked=true;
	    		$scope.dropDownItem.checked=false;
	    		$scope.likertScaleItem.checked=false;
	    	}
	    	else if ($scope.questionObj.questionType == $scope.likertScaleItem.itemName)
	    	{
	    		$scope.questionObj.questionType=$scope.likertScaleItem.itemName;
	    		$scope.multipleChoiceItem.checked=false;
	    		$scope.openAnswerItem.checked=false;
	    		$scope.dropDownItem.checked=false;
	    		$scope.likertScaleItem.checked=true;
	    		
	    		$scope.likertScaleOptions=[];
	    		for(var i=0;i<$scope.questionObj.likertScaleValues.length;i++)
	    		{
	    			var newObj={option:$scope.questionObj.likertScaleValues[i].name,value:$scope.questionObj.likertScaleValues[i].value,defaultOption:false};
	    			$scope.likertScaleOptions.push(newObj);
	    		}
	    		
	    		$scope.showMultipleorLikertScaleDiv=false;
	    	}
	    	
	    	$scope.mandatoryReply.mandatoryReply = $scope.questionObj.isResponseMandatory;
	    	if($scope.mandatoryReply.mandatoryReply)
	    	{
	    		$scope.mandatoryReplyItem.checked = true;
	    	}
	    	else
	    	{
	    		$scope.mandatoryReplyItem.checked = false;
	    		$scope.allowCommentItem.state = "notChecked";
	    		$scope.commentMandatoryItem.state = "disabled";
	    		$scope.commentMandatory.commentMandatory = false;
	    		$scope.allowComment.allowComment = false;
	    		
	    		
	    	}
	    	$scope.allowComment.allowComment = $scope.questionObj.areCommentsAllowed;
	    	if($scope.allowComment.allowComment)
	    	{
	    		$scope.allowCommentItem.state = "checked";
	    		$scope.commentMandatoryItem.state = "notChecked";
	    	}
	    	else
	    	{
	    		$scope.allowCommentItem.state="notChecked";
	    		$scope.commentMandatoryItem.state="disabled";
	    		
	    		if($scope.questionObj.questionType == $scope.openAnswerItem.itemName)
	    		{
	    			$scope.allowCommentItem.state="disabled";
	    		}
	    	}
	    	$scope.commentMandatory.commentMandatory = $scope.questionObj.areCommentsMandatory;
	    	if($scope.commentMandatory.commentMandatory)
	    	{
	    		$scope.commentMandatoryItem.state = "checked";
	    		
	    	}
	    	else
	    	{
	    		$scope.commentMandatoryItem.state = "notChecked";
	    		if($scope.questionObj.questionType == $scope.openAnswerItem.itemName)
	    		{
	    			$scope.commentMandatoryItem.state = "disabled";
	    		}
	    	}
	    	$scope.questionObjectBaseUuid = $scope.questionObj.objectBaseUuid;
	    	angular.copy($scope.questionObj, $scope.questionObj_);
	    	$scope.eqitQuestionDefaultOption(question);
	  	}
	  	$scope.showSectionQuestion = function(index, question){
//	  		if($scope.questionObj.objectBaseUuid == question.objectBaseUuid){
//	  			$scope.openedBox = '';
//	  			$scope.questionObj = {};
//	  		}
//	  		else{
		  		if($scope.openedBox == 'questionCreateOrEditBox'){
		  			$scope.cancelNewSecToViewOld(index, question, viewQuestion);
		  		}else{
		  			viewQuestion(index, question);
		  		}
//	  		}
	  	}
	  	
	  	$scope.enableSaveBtnInSummary = $scope.publishBtnChanged;
	  	$scope.toggleNotifyOnResponse = function(){
//	  		if($scope.copySurveyPublish.isPublished)
//	  		{
//	  			return;
//	  		}	
	  		$scope.notify = !$scope.surveyObject.notify;
//	  		if($scope.notify != $scope.surveyObject.notify){
//	  			$scope.enableSaveBtnInSummary = true;
//	  		}else{
//	  			$scope.enableSaveBtnInSummary = false || $scope.publishBtnChanged;
//	  		}
	  	}
	  	
//	  	function slideUpSectionAnimation(section, openingNew){
//	  		angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("disable");
//	  		var container = document.querySelector('#idSurveyQuestionManager > div > .mCustomScrollbar .mCSB_container');
//	  		container.addEventListener("transitionend", endTransition, false);
//	  		var el = document.createElement('div');
//	  		el.style.height = '300px';
//  			el.classList.add('help-block');
//	  	
//  			if(openingNew){
//	  			container.appendChild(el);
//	  		}
//	  		
//	  		function endTransition(ev){
//  				if(container.querySelector('.help-block')){
//  					container.removeChild(el);
//  					container.removeEventListener("transitionend", endTransition, false);
//				}
//  				$timeout(function(){
//					angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("update");	
//				},100)
//  			}
//	  		
//  			container.style.top = "-" + (section.offsetTop - 15) + "px";
//	  	}
	  	
	  	var viewSection = function(index, section, openingNew){
	  	//	slideUpSectionAnimation(section, openingNew);
	  		$scope.openedBox = 'editSectionBox';
	  		$scope.attachmentChooserFor = 'sectionAttachments';
	  		$scope.sectionObj = {};
	  		surveysService.setSurveySection(section);
	  		angular.copy(section, $scope.sectionObj);
	  		$scope.sectionObj.index = index;
	  		$scope.sectionObj.count = index+1;
	  		$scope.sectionObj.attachments = $scope.sectionObj.attachedObjectsList;
	  	//	section.isDisplayOpen = true;
	  		//section.style = {'height' : ''};
	  		$scope.clickedIndex = index;
	  		$scope.sectionObj_ = angular.copy($scope.sectionObj);
	  		
	  	}

	  	$scope.showSection = function(index,section, ev){
	  		ev.stopPropagation();
	  		ev.preventDefault();
	  		
	  		setSectionHeight(section);
	  		
	  		if($scope.clickedIndex == undefined){   
	  			viewSection(index, section, true);
	  		}
	  		else if($scope.clickedIndex == index && $scope.openedBox == 'editSectionBox'){ 
	  			$scope.cancelSectionShow(section);
	  		}
	  		else if($scope.clickedIndex != index && $scope.openedBox == 'editSectionBox'){ 
				$scope.previousSection = $scope.surveyObject.sections[$scope.clickedIndex];
				$scope.cancelSectionShow($scope.previousSection, index, section, viewSection);
	  		}
	  		else if($scope.openedBox == 'questionCreateOrEditBox'){
	  				$scope.cancelQuestion($scope.surveyObject.sections[$scope.clickedIndex], index, section, viewSection);
	  		}
	  		else if($scope.openedBox == 'addNewSection'){
	  			$scope.cancelSectionShow(null, index, section, viewSection);
	  		}
  			else{
  				//$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = false;
				//$scope.surveyObject.sections[$scope.clickedIndex].style = {'height' : ''};
	  			viewSection(index, section, true);
  			}
	  		
	  		//section.isDisplayOpen = !section.isDisplayOpen;
	  	}
	  	
	  	var setSectionHeight = function(sec, height){
	  		if(!height)
	  			height = angular.element('[ng-repeat] .sectionBox')[0].getBoundingClientRect().height;
	  		
	  		var gap = 2;
	  		
	  		if(sec){
		  		if(!sec.isDisplayOpen){
		  			sec.style = {'height' : ''};
		  		}
		  		else{
					if(sec.questions && sec.questions.length > 0){
						sec.style = {'height' :((height + gap) * sec.questions.length) + (height + gap) + 'px'};
					}
		  			else{
		  				sec.style = {'height' : (height + gap) + 'px'};
		  			}
		  		}
	  		}
	  	}
  		var toggleSectionSuccess = function(ev, section, index) {
  			$scope.openedBox = '';
  			if(ev && angular.element(ev.target).closest('[ng-repeat]').find('.questionBox')[0]){
  				questionHeight = angular.element(ev.target).closest('[ng-repeat]').find('.questionBox')[0].getBoundingClientRect().height;
  			}
  			if($scope.clickedIndex == undefined){ 
  				$scope.sectionObj = section;
  				$scope.sectionObj.isDisplayOpen = true;
  			}
  			else if($scope.clickedIndex == index){
  				section.isDisplayOpen = !section.isDisplayOpen;
  				$scope.sectionObj = section;
  			}
  			else if($scope.clickedIndex != index){
  				$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = false;
 // 				$scope.surveyObject.sections[$scope.clickedIndex].style = {'height' : ''};
  				$scope.sectionObj = $scope.surveyObject.sections[index];
  				$scope.sectionObj.isDisplayOpen = true;
  			}
  			$scope.clickedIndex = index;
  			setSectionHeight($scope.sectionObj, questionHeight);
//				$timeout(function(){
//	  			angular.element('#idSurveyQuestionManager > div > .mCustomScrollbar').mCustomScrollbar("scrollTo", $scope.sectionObj.offsetTop - 15);
//	  			},300);
				surveysService.setSurveySection($scope.sectionObj);
  		}
  		
	  	$scope.toggleSectionQuestionArea = function(section, index, ev){
	  		
	  		if($scope.clickedIndex == index && $scope.openedBox == 'editSectionBox'){ 
	  			$scope.cancelSectionShow(section);
	  		}
	  		else if($scope.clickedIndex != index && $scope.openedBox == 'editSectionBox'){ 
				$scope.previousSection = $scope.surveyObject.sections[$scope.clickedIndex];
				$scope.cancelSectionShow($scope.previousSection, index, section, viewSection);
	  		}
	  		else if($scope.openedBox == 'questionCreateOrEditBox'){
	  				$scope.cancelQuestion($scope.surveyObject.sections[$scope.clickedIndex], index, section, viewSection);
	  		}
	  		else if($scope.openedBox == 'addNewSection'){
	  			$scope.cancelSectionShow(null, index, section, viewSection);
	  		}
  			else{
	  			viewSection(index, section, true);
  			}
	  		
	  		if($scope.openedBox == ''){
	  			$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = false;
	  			
	  		}
	  		else{
	  			_.each($scope.surveyObject.sections, function(sec){
	  				if(sec.isDisplayOpen){
	  					sec.isDisplayOpen = false;
	  				}
	  			});
	  			
	  			$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = true;
	  		}
	  			
	  		setSectionHeight(section);
	  		
//	  		if($scope.openedBox == 'questionCreateOrEditBox'){
//	  			$scope.cancelQuestionToToggle(ev, toggleSectionSuccess, $scope.surveyObject.sections[$scope.clickedIndex], section, index);
//	  			//$scope.surveyObject.sections[$scope.clickedIndex].isDisplayOpen = false;
//	  		}
//	  		else if($scope.openedBox == 'editSectionBox'){
//	  			$scope.cancelSectionShowToToggle(ev, toggleSectionSuccess, $scope.surveyObject.sections[$scope.clickedIndex], section, index);
//	  		}
//	  		else if($scope.openedBox == 'addNewSection'){
//	  			$scope.cancelSectionShowToToggle(ev, toggleSectionSuccess,null, section, index);
//	  		}
	  		
	  		//toggleSectionSuccess(ev, section, index);
	  	}
	  	
	  	$scope.toggleSectionQuestionAreaForSummary = function(sectionSummary, summaryIndex){
	  		if($scope.clickedSummaryIndex == undefined){
	  			$scope.clickedSummaryIndex = summaryIndex;
	  			sectionSummary.isDisplayOpen = true;
	  		} else if($scope.clickedSummaryIndex == summaryIndex){
	  			sectionSummary.isDisplayOpen = !sectionSummary.isDisplayOpen;
	  		} else if($scope.clickedSummaryIndex != summaryIndex){
	  			$scope.sectionsForSummary[$scope.clickedSummaryIndex].isDisplayOpen = false;
	  			sectionSummary.isDisplayOpen = true;
	  			$scope.clickedSummaryIndex = summaryIndex;
	  		}
	  	}
	  	$scope.isSurveyDetailsSummaryOpen = true;
	  	$scope.toggleSurveyDetailsSummary = function() {
	  		$scope.isSurveyDetailsSummaryOpen = !$scope.isSurveyDetailsSummaryOpen;
	  	}
	  	
	  	$scope.clickedTab = function(){
	  		alert("Hello");
	  	}
	  	$scope.backToSurveyDetails = function(){
	  		$scope.tabs[0].active = true;
	  	}
	  	var goToSummaryCheck = function(){
	  		$scope.tabs[2].active = true;
	  		$scope.notify = $scope.surveyObject.notify;
	  		$scope.tabs[2].rightMenuList[1].svgFill= '#fff';
			if($scope.surveyObject.isPublished){
				$scope.tabs[2].rightMenuList[1].iconUrl = $scope.bvchecktrue;
			}else{
				$scope.tabs[2].rightMenuList[1].iconUrl = $scope.bvcheckfalse;
			}
			$scope.sectionsForSummary = [];
			angular.copy($scope.surveyObject.sections, $scope.sectionsForSummary);
			if($scope.sectionsForSummary.length > 0)
				for(var i=0;i<$scope.sectionsForSummary.length;i++){
					$scope.sectionsForSummary[i].isDisplayOpen = false;
				}
	  	}
	  	$scope.goToSummary = function(){
//	  		$scope.initSurveyDetails();
//	  		if(surveysService.getIsSurveyPublished() == true && $scope.openedBox == 'questionCreateOrEditBox'){
//	  			bvDialog.showMessage("All sections should have atleast one question for published surveys");
//	  			return;
//	  			$scope.tabs[2].active = false;
//	  			$scope.tabs[1].active = true;
//	  		}else{
	  			goToSummaryCheck();
//	  		}
	  	}
	  	$scope.svgImgRightArrow = "resources/assets/svgs/rightArrow.svg";
	  	$scope.svgImgDropDown = "resources/assets/svgs/downarrow.svg";
	  	
	  	$scope.sortTxt = localizedMessages.get("SORT_QUESTIONS");
		
	   $scope.updateSurveyQuestionsSortOrder=function()
	   {
		   var surveyModel=surveysService.getSurvey();
		   var sectionObject = {};
		   angular.copy(surveysService.getSurveySection(), sectionObject);
		   var surveyBaseUuid=surveyModel.objectBaseUuid;
		   var sectionObjToSave={};
		   sectionObjToSave.sectionName = sectionObject.sectionName != undefined ? sectionObject.sectionName : '';
		   sectionObjToSave.instructions = sectionObject.instructions != undefined ? sectionObject.instructions : '';
		   sectionObjToSave.documentObjectBaseUuidList = sectionObject.documentObjectBaseUuidList;
		   sectionObjToSave.objectBaseUuid = sectionObject.objectBaseUuid;
		   sectionObjToSave.questions = [];
		   
		   for(var i=0;i<sectionObject.questions.length;i++)
		   {
			   var question = {};
			   question.comments=sectionObject.questions[i].comments != undefined ? sectionObject.questions[i].comments : '';
			   question.objectBaseUuid = sectionObject.questions[i].objectBaseUuid;
			   question.name = sectionObject.questions[i].name != undefined ? sectionObject.questions[i].name : '';
			   question.questionType = sectionObject.questions[i].questionType;
			   question.isResponseMandatory = sectionObject.questions[i].isResponseMandatory;
			   question.areCommentsAllowed = sectionObject.questions[i].areCommentsAllowed;
			   question.areCommentsMandatory = sectionObject.questions[i].areCommentsMandatory;
			   question.likertScaleValues = sectionObject.questions[i].likertScaleValues;
			   question.documentObjectBaseUuidList = sectionObject.questions[i].documentObjectBaseUuidList;
			   question.multipleChoiceValues = sectionObject.questions[i].multipleChoiceValues;
			   sectionObjToSave.questions.push(question);
		   }
		   surveysService.updateSurveyQuestionsSortOrder(surveyBaseUuid,angular.toJson(sectionObjToSave)).then(function(data){
			   if(data.boName == "SuccessBO"){
				   surveysService.setSurveySection(sectionObjToSave);
			   }
		   });
	   }
	  	
		$scope.dragStartHandler = function(event, element, dragDropService)
		{
			var selectedItem = event.currentTarget;
			var dragDropObject = new Object();
			dragDropObject.dragInitiator = "surveyQuestion";
			dragDropObject.allDraggedItems = selectedItem;

			event.dataTransfer.setData('Text', angular.toJson(dragDropObject));
		}
		$scope.handleDragOver = function(target, dragDropJSONObject, dragDropService)
		{

		}
		$scope.handleDrop = function(target, dragDropJSONObject, dragDropService)
		{
			var dragDropObject = angular.fromJson(dragDropJSONObject);
		}
		
		$scope.scrollbarConfig = {
				autoHideScrollbar : true,
				advanced: {
					autoScrollOnFocus: false
				}
		}
		$scope.questionScrollbarConfig = {
				autoHideScrollbar : false,
				scrollInertia: 0
		}
		$scope.questionAttachmentsScrollbarConfig = {
				autoHideScrollbar : true
		}
		$scope.sectionScrollbarConfig = {
				autoHideScrollbar : true
		}
	  	$scope.goBackToQuestionDetails = function(){
	  		$scope.tabs[1].active = true;
	  	}
	  	var deleteSection = function(){
	  		var surveyModel=surveysService.getSurvey();
			var sectionObject=surveyModel.sections[$scope.toDeleteSectionIndex];
			surveysService.deleteSection(surveyModel.objectBaseUuid,sectionObject.objectBaseUuid).then(function(data){
				if(data.boName == 'SuccessBO'){
					surveyModel.sections.splice($scope.toDeleteSectionIndex,1);
					if($scope.toDeleteSectionIndex == $scope.clickedIndex){
						$scope.clickedIndex = undefined;
					}
					if(surveyModel.sections.length == 0){
						surveyModel.sections = undefined;
					}
					surveysService.setSurvey(surveyModel);
				}
			});
	  	}
	  	$scope.deleteSectionClicked = function(indexOfSection, name){
	  			$scope.toDeleteSectionIndex = indexOfSection;
				var forError = angular.copy(surveysService.getSurvey().sections);
				forError.splice($scope.toDeleteSectionIndex, 1);
				if(forError.length == 0 && surveysService.getSurvey().isPublished){
					bvDialog.showMessage(localizedMessages.get("MSG_MIN_SINGLE_SECTION_REQ"));
				}
	  			else{
	  				bvDialog.confirm(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_DELETE_SECTION")+ " : " + name + "?", deleteSection);
	  			}
	  	}
	  	var deleteQuestion = function(){
	  		var surveyModel=surveysService.getSurvey();
			var sectionObject=surveysService.getSurveySection();
			var questionObject = sectionObject.questions[$scope.toDeleteQuestionIndex];
			surveysService.deleteQuestion(surveyModel.objectBaseUuid,questionObject.objectBaseUuid).then(function(data){
				if(data.boName == 'SuccessBO'){
					sectionObject.questions.splice($scope.toDeleteQuestionIndex,1);
					setSectionHeight(sectionObject);
//					sectionObject.style = {'height' :( questionHeight * sectionObject.questions.length) + 42 + 'px'};
					surveysService.setSurvey(surveyModel);
					surveysService.setSurveySection(sectionObject);
				}
			});
	  	}
	  	$scope.deleteQuestionClicked = function(sectionIndexToDel, questionIndex, name){
	  		var surveyModel=surveysService.getSurvey();
	  		var sectionObject=surveysService.getSurveySection();
	  		$scope.toDeleteQuestionIndex = questionIndex;
	  		$scope.toDeleteQuestionSectionIndex = sectionIndexToDel;
	  		var forError = angular.copy(sectionObject);
			forError.questions.splice($scope.toDeleteQuestionIndex, 1);
			if(forError.questions.length == 0){
				bvDialog.showContinue($scope.ui.label.section_must_have_question, localizedMessages.get('CONTINUE'), function(){});
			}else{
				bvDialog.confirmSupportHTMLTags(localizedMessages.get("ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION")+": "+name + "?", deleteQuestion);
			}
	  	}
	  	
		$scope.checkAttendeeAlreadyExists = function(objectBaseUuid) {
			try {
					var ownersList = $scope.surveyObject.ownerList != undefined ? $scope.surveyObject.ownerList : new Array();;
					var respondentList = $scope.surveyObject.participantBaseUuidList != undefined ? $scope.surveyObject.participantBaseUuidList : new Array(); 
					//var viewerList = $scope.surveyObject.viewerList != undefined ? $scope.surveyObject.viewerList : new Array();;
					$scope.surveyObject.attendeesList = ownersList.concat(respondentList);

				for (var index in $scope.surveyObject.attendeesList) {
					if ($scope.surveyObject.attendeesList[index].objectBaseUuid == objectBaseUuid) {
						return true;
					}
				}
			} catch (err) {
				console.log(err.message);
				return false;
			}

			return false;
		}
		$scope.addUserInAttendeesList = function(attendee) {
			var attendeeObj = {
				objectBaseUuid : attendee.objectBaseUuid,
				name : attendee.name || attendee.displayName,
				isRespondent : attendee.isRespondent,
				isOwner : attendee.isOwner
			};

			if ($scope.surveyObject && $scope.checkAttendeeAlreadyExists(attendeeObj.objectBaseUuid) == false)
				$scope.surveyObject.attendeesList.push(attendeeObj);
		}
		$scope.addGroupUsersInAttendeesList = function(groupId) {
			var users = companyUserService.getGroupUsers(groupId);
			if(users && users.length> 0)
			{
				for(var k in users){
					$scope.addUserInAttendeesList(users[k].objectBaseUuid, users[k].name);
				}
			}
		};
		$scope.addSelectedUser = function(selectedItems) {
			$scope.selectedUsers = selectedItems;
			if ($scope.selectedUsers != undefined
					&& $scope.selectedUsers.length > 0) {
				for (var i in $scope.selectedUsers) {

					if ($scope.selectedUsers[i].type == 'TeamSpaceGroup') {
						$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
					} else if ($scope.selectedUsers[i].type == 'Group') {
						$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
					} else if ($scope.selectedUsers[i].type == 'Portal') {
						$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid);
					} else {
						$scope.addUserInAttendeesList($scope.selectedUsers[i]);
					}

				}
			}
		};
		var addCreatorAsAttendees = function() {
			var creator = context.getModel().userVO;
			creator.isOwner = true;
			$scope.addSelectedUser([ creator ]);
		};
		if ($scope.inCreateSurvey) {
			addCreatorAsAttendees();
		}
		$scope.showSurveyUsersOptionDialog = function() {
			$scope.popupTitle = localizedMessages.get("USERS");
			//bvDialog.showSurveyUsersOption($scope.surveyObject);
			bvDialog.showUserManagementToolkitSurveys($scope.surveyObject);
		};
		$scope.removeAttendee = function(index) {
			var from = index;
			var to = 0;
			var rest = $scope.surveyObject.attendeesList.slice((to || from) + 1 || $scope.surveyObject.attendeesList.length);
			$scope.surveyObject.attendeesList.length = from < 0 ? $scope.surveyObject.attendeesList.length + from : from;
			$scope.surveyObject.attendeesList.push.apply($scope.surveyObject.attendeesList, rest);
		};
		var onSelection = function(selectedNodes) {
			try {
				if (selectedNodes != undefined && selectedNodes.length > 0) {
					for (var i in selectedNodes) {
						var attachment = selectedNodes[i];

						if (!$scope.checkFileAlreadyExists(attachment.objectBaseUuid)) {
							//True: Attachment is not a folder.
							if (attachment.boName.indexOf('Folder') == -1)
								{
									if($scope.attachmentChooserFor == 'sectionAttachments')
										$scope.sectionObj.attachments.push(attachment);
									if($scope.attachmentChooserFor == 'questionAttachments')
										$scope.questionObj.attachments.push(attachment);
									if($scope.attachmentChooserFor == 'details'){
										$scope.surveyObject.attachmentsList.push(attachment);
									}
								}
						}

					}
				}
			} catch (err) {
				console.log(err.message);
			}
			$scope.sliderOptions.items = $scope.surveyObject;
		};

		$scope.sliderOptions = {
			items : [],
			slideThrough : 1,
			smallButtons : true
		}
		var removeAppropriateAttachment = function(attachObjects, index){
			var from = index;
			var to = 0;
			
			var rest = attachObjects.slice((to || from) + 1
					|| attachObjects.length);
			attachObjects.length = from < 0 ? attachObjects.length
					+ from
					: from;
			attachObjects.push.apply(
					attachObjects, rest);
		}
		$scope.deleteAttachment = function(index) {
			if($scope.attachmentChooserFor == 'sectionAttachments')
				removeAppropriateAttachment($scope.sectionObj.attachments, index);
			if($scope.attachmentChooserFor == 'questionAttachments')
				removeAppropriateAttachment($scope.questionObj.attachments, index);
			if($scope.attachmentChooserFor == 'details')
				removeAppropriateAttachment($scope.surveyObject.attachmentsList, index);
		}
		$scope.getDocIcon = function(attachment) {
			return imageService.getImageForObject(attachment);
		};
		$scope.openAttachmentForPreview = function(attachment) {
			bvDialog.showDocumentPreview(attachment.objectBaseUuid, attachment);
		};
		$scope.checkFileAlreadyExists = function(uuid) {
			var attachList = [];
			if($scope.attachmentChooserFor == 'sectionAttachments')
				attachList = $scope.sectionObj.attachments;
			if($scope.attachmentChooserFor == 'questionAttachments')
				attachList = $scope.questionObj.attachments;
			if($scope.attachmentChooserFor == 'details')
				attachList = $scope.surveyObject.attachmentsList;
			try {
				for (var i in attachList) {
					var attachment = attachList[i];
					if (attachment.objectBaseUuid == uuid)
						return true;
				}
			} catch (err) {

			}

			return false;
		}
		$scope.documentSortableOptions = {
				handle : '> .drag-handle',
				update : function(e, ui) {
					//console.log(ui.item);
				}
			};
		$scope.questionsSortableOptions = {
				handle : '> .drag-handle',				
				stop : function(e, ui) {
					if("dropindex" in ui.item.sortable)
					{
						surveysService.setSurveySection ($scope.surveyObject.sections[$scope.clickedIndex]);
						$scope.updateSurveyQuestionsSortOrder();
					}					
				}
			};
		$scope.sectionsSortableOptions = {
				handle : '> .drag-handle',
				update : function(e, ui) {
					//console.log(ui.item);
				}
			};
		$scope.getDocumentThumbnail = function(attachment) {
			if (attachment.documentThumbnail != undefined) {
				return "data:image/png;base64," + attachment.documentThumbnail;
			} else {
				if (attachment.boName == "FolderBO")
					attachment.extensionType = "folderAttachment";
				return imageService.getImageByExtension(attachment.extensionType);
			}
		};
		$scope.openAttachmentChooser = function(attachmentsFor) {
			$scope.attachmentChooserFor = attachmentsFor;
			bvDialog.attachDocument(onSelection);
		};
		$scope.cancelEdit = function() {
			$state.go('home.surveys');
		}
		$scope.cancelSurveyClicked = false;
		$scope.cancelSurveySummaryClicked = false;
		
		var saveBeforeClosing = function() {
			$scope.saveSurveyDetails();
		}
		var cancelNoSave = function(){
			$scope.isSaving=true;
			$scope.surveyObject.isClosing=true;
			$scope.cancelEdit()
			console.log("cancel save");
		}
		
		$scope.cancelCreateSurvey = function() {
			homeService.set_current_module_has_unsaved_changes(false, "CreateSurveyController");
			$scope.cancelSurveyClicked = true;
			$scope.surveyObject.isClosing=true;
			var confirmBox = true;
			bvDialog.confirm(localizedMessages.get("MSG_WOULD_YOU_LIKE_TO_SAVE_YOUR_CHANGES_BEFORE_CLOSING?"),saveBeforeClosing, cancelNoSave, confirmBox, localizedMessages.get("EXIT") + " " + localizedMessages.get("SURVEY"));
				
		}
		$scope.cancelSurveySummary = function() {
			$scope.cancelSurveySummaryClicked = true;
			var confirmBox = true;
			bvDialog.confirm(localizedMessages.get("MSG_WOULD_YOU_LIKE_TO_SAVE_YOUR_CHANGES_BEFORE_CLOSING?"),saveBeforeClosing,cancelNoSave, confirmBox);
		}
		$scope.cancelQuestionDetailTab = function() {
			$scope.attachmentChooserFor = 'details';
			$scope.tabs[0].active = true;
			$scope.tabs[1].active = false;
		}
		$scope.cancelQuestionDetails = function() {
			bvDialog.confirm(localizedMessages.get("WOULD_YOU_LIKE_TO_CLOSE"), $scope.cancelEdit);
		}
//		$scope.showDueDateCalender = function() {
//			var dateFormat = StringUtils.getLocaleDateFormat();
//			var monthNamesShort = moment.localeData()._monthsShort;
//
//			$("#datepickerDueDate").datepicker({
//				showPanelButton : true,
//				beforeShow : function() {
//					$('.ui-datepicker').css('font-size', 11);
//				},
//				showAnim : 'slideDown',
//				monthNamesShort: monthNamesShort,
//				dateFormat : dateFormat,
//				onSelect: function(dateText, inst) {
//				    var parsedDate = $.datepicker.parseDate(inst.settings.dateFormat || $.datepicker._defaults.dateFormat, dateText, inst.settings);
//					$scope.surveyObject.dueDate = moment(parsedDate).format('ll');
//                	$scope.surveyObject.dueDateLocal = StringUtils.momentL(parsedDate).format('ll');
//                },
//				minDate : new Date()
//			});
//
//			$("#datepickerDueDate").show();
//			$("#datepickerDueDate").focus();
//			$("#datepickerDueDate").datepicker();
//		};

}

var createSurveyModule = angular
    .module('CreateSurvey', ['surveys.service', 'localization.service', 'ngSanitize'])
    .controller('CreateSurveyController', CreateSurveyController)
    .filter('surveyUnsafe',function($sce){
        return function(html) {
        	return $sce.trustAsHtml(html);
        };
    });