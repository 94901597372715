saveSurveyTemplateController.$inject = ['$scope', 'context', 'localizedMessages', 'surveysService', 'bvDialog'];

function saveSurveyTemplateController($scope, context, localizedMessages, surveysService, bvDialog){

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    var survey = $scope.bvModalControllerInputs.survey;
    var surveyOwnersUuids = $scope.bvModalControllerInputs.surveyOwnersUuids;
    var templateSavedCallback = $scope.bvModalControllerInputs.templateSavedCallback;
    $scope.templateName = survey.name;
    $scope.ui = {
    	    label: {}
    	}
    $scope.ui.label.name_of_template = localizedMessages.get("NAME_OF_TEMPLATE");
    $scope.ui.label.cancel = localizedMessages.get("CANCEL");
    $scope.ui.label.save = localizedMessages.get("SAVE");
    
    $scope.changeTemplateName = function(name) {
    	$scope.templateName = name;
    }
    
    $scope.cancel = function() {
    	bvModal.close();
    }
    
    $scope.saveTemplate = function(){
    	survey.objectBaseUuid = "";
    	survey.name = $scope.templateName;
    	survey.isSurveyTemplate = true;
    	survey.sections.map(
    			(section,i) => {
    				section.objectBaseUuid = "";
    				section.questions.map(
    		    			(question,i) => {
    		    				question.objectBaseUuid = "";
    		            })
            })
    	surveysService.createOrUpdateSurvey(null, JSON.stringify(survey), JSON.stringify(surveyOwnersUuids)).then(data => {
    	    const survey_ = data
    	    if(templateSavedCallback && typeof templateSavedCallback == "function") {
    	    	templateSavedCallback();
    	    }
    	    if(survey_ && survey_.objectBaseUuid)
                bvDialog.showMessage(localizedMessages.get("TEMPLATE_SAVED"))
    	})
    	$scope.cancel();
    }
    
}