landingPageController.$inject = ['$scope', '$state', 'reactInterface', 'localizedMessages']

function landingPageController($scope, $state, reactInterface, localizedMessages) {
    $scope.svgFill = '#FFF';
    $scope.middleBarObject.showBack = false;
    $scope.middleBarObject.title = localizedMessages.get("MINUTES");
    $scope.reactInterface = reactInterface;

    const openMinutesManager = () => {
        console.log("Minutes manager call")
    }

    const openActionItems = () => {
        console.log("Action Items call")
    }

    const openMinutesSettings = () => {
        console.log("Minutes Settings call")
        $state.go("home.minutesSettings")
    }

    $scope.tabs = [{
        title : localizedMessages.get("MINUTES"),
        content : 'minutesLandingPage',
        active: true,
        rightMenuList:[
//            {
//                title : localizedMessages.get("MINUTES_MANAGER"),
//                click : openMinutesManager
//            },
//            {
//                title : localizedMessages.get("MINUTES_ACTION_ITEMS"),
//                click : openActionItems
//            },
            {
                title : localizedMessages.get("MINUTES_SETTINGS"),
                click : openMinutesSettings
            }
        ]
    }];
}