angular
    .module('dialogControllers.service')
    .controller('editEmailWizardController', EditEmailWizardController);

EditEmailWizardController.$inject = ['$scope', '$rootScope', 'localizedMessages','context','teamService','bvDialog', 'bvDialogService','$modal'];

function EditEmailWizardController($scope, $rootScope, localizedMessages, context, teamService, bvDialog, bvDialogService,modal)
{
    $scope.isEmailScreen = true; //default
    $scope.emailWarning = "";
    $scope.emailWizardLabels  = {
        "ENTER_NEW_PRIMARY_EMAIL":localizedMessages.get('ENTER_NEW_PRIMARY_EMAIL'),
        "TOKEN":localizedMessages.get('TOKEN'),
        "LOGIN_PASSWORD":localizedMessages.get('LOGIN_PASSWORD'),
        "EMAIL_SENT_MESSAGE": localizedMessages.get('EMAIL_SENT_MESSAGE')
    }

    $scope.email = {
        newValue:'',
        token:'',
        password:''
    };



    $scope.validateEmail = function (oldEmail) {
        var validatedResult = {isValid: false, message: ''};

        this.validateSyntax = function(email) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            validatedResult.isValid = re.test(email);
            if (!validatedResult.isValid) {
                validatedResult.message = localizedMessages.get('INVALID_EMAIL_ADDRESSES');
            }
            return validatedResult;
        }
        this.validateDuplicate = function (oldEmail, newValue) {
            validatedResult.isValid = !(oldEmail.toUpperCase() === newValue.toUpperCase());
            if (!validatedResult.isValid) {
                validatedResult.message = localizedMessages.get('NEW_EMAIL_SAME_AS_EXISTING');
            }
        }
        this.validateSyntax($scope.email.newValue);
        if(validatedResult.isValid) {
            this.validateDuplicate(oldEmail, $scope.email.newValue);
        }
        return validatedResult;
    }

    $scope.onNext = function(emailDialog, oldEmail) {
        var validationResult = $scope.validateEmail(oldEmail);
        if (validationResult.isValid) {
            $scope.emailWarning = "";
            teamService.initEmailToken($scope.email.newValue).then(function (data) {
                if (data == true) {
                    $scope.isEmailScreen = false;
                    $scope.buttons[2] = {
                        name: localizedMessages.get('SAVE'), action: function () {
                            if ($scope.validateTokenAndPassword()) {
                                $scope.saveProfile();
                                $scope.close();
                            }
                        }
                    };
                } else {
                    bvDialog.showMessage(localizedMessages.get('FAILED_TO_SEND_VERIFICATION_TOKEN'), null, true);
                }
            });
        } else {
            $scope.emailWarning = validationResult.message;
        }
    }

    $scope.validateTokenAndPassword = function () {
        if(!($scope.email.password!="" && $scope.email.token!="")) {
            $scope.emailWarning = localizedMessages.get('TOKEN_AND_PASSWORD_CANNOT_BE_EMPTY')
            return false;
        }
        return true;
    }

    $scope.saveProfile = function() {
        $scope.emailWarning = "";
            var payloadData = {
                EmailVerificationToken: $scope.email.token,
                Pwd: $scope.email.password,
                EmailAddress: $scope.email.newValue
            };
            var jsonPayload = angular.toJson(payloadData);
            teamService.completeEmailVerification(jsonPayload).then(function (data) {
                if (data == true) {
                    $rootScope.$broadcast('primaryEmailUpdated', {
                        data: $scope.email.newValue
                    });
                } else {
                    bvDialog.showMessage(localizedMessages.get('SAVING_NEW_EMAIL_FAILED'), null, true);
                }

            });
    }

}