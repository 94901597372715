angular.
    module('challengeQuestions.module', []).
    factory('challengeQuestionsService', [ 'loginService', 'homeService', 'context', 'localizedMessages', ChallengeQuestionsService]).
    controller('challengeQuestionsComponentController', ['loginService', 'homeService', 'context', 'localizedMessages', 'bvDialogService', ChallengeQuestionsComponentController]).
    component('challengeQuestions', {
        templateUrl: 'modules/challengequestions/ChallengeQuestions.tpl.html', 
        controller: ChallengeQuestionsComponentController,
        bindings: {
            userObjectBaseUuid: '<',
            onAnswersChanged: '&',
            isLoggedIn: '<'
        }
    });

function ChallengeQuestionsService (loginService, homeService, context, localizedMessages) {
    var service = {

        encryptIfNeeded: (answers, model) => {
            var encryptedQuestions = answers;

            if(model != null && model.encryptionkeycertificate != null && model.encryptionkeycertificate != undefined
                    && model.encryptionkeycertificate.ssl2 == true && model.encryptionkeycertificate.chquest == true) {
                encryptedQuestions = answers.map(item =>  {
                    return {
                        question: CryptoHelper.asymmetricEncrypt(item.question,model.serverPublicKey),
                        answer: CryptoHelper.asymmetricEncrypt(item.answer,model.serverPublicKey)
                    }
                })
            }

            return encryptedQuestions;
        },

        validate: (answers) => {

            if(context.homeModel== null || context.homeModel.passwordPreferences == null || context.homeModel.passwordPreferences == undefined)
    		{
    			loginService.getPasswordPreferences().then(function(data){
    	    		if ( data != null)
    	    		{
    	    			context.homeModel.passwordPreferences = data;
    	    		}
    	    	});
    		}

            if( service.areThereMissingAnswers(answers) ){
                return localizedMessages.get('ERR_ANSWERS_ARE_REQUIRED_FOR_ALL_CHALLENGE_QUESTIONS');
            }
            else if( service.areChosenQuestionsTheSame(answers) ){
                return localizedMessages.get('ERR_CQ_SHOULD_NOT_BE_SAME');
            }
            else if(context.homeModel.passwordPreferences && context.homeModel.passwordPreferences.isPasswordPolicyEnaled)
            {
                var isUniqueAnswerEnabled = context.homeModel.passwordPreferences.isUniqueAnswersEnabled;
                var challengeQMinLength = context.homeModel.passwordPreferences.challengeQMinLength;
                if( service.areThereAnswersWithInvalidLength(answers, challengeQMinLength) )
                {
                    return localizedMessages.get('PLEASE_ANSWER_THE_QUESTION_WITH_MINIMUM') + challengeQMinLength + localizedMessages.get('CHARACTERS');
                }
                if(isUniqueAnswerEnabled)
                {
                    if( service.areAnswersNotUnique(answers) )
                    {
                        return localizedMessages.get('PLEASE_CHOOSE_UNIQUE_ANSWERS_FOR_EACH_QUESTION');
                    }
                }
            }

            return null;
        },

        areChosenQuestionsTheSame: (answers) => {
            var countOf = {};
            for( var i = 0; i < answers.length; i++ ) {
                var item = answers[i];
                if( item.question == null ) {
                    continue;
                }
                countOf[item.question] = ( countOf[item.question] || 0 ) + 1;
            }
            for(var question of Object.keys(countOf)) {
                if( countOf[question] > 1 ) {
                    return true;
                }
            }
            return false;
        },

        areThereMissingAnswers: (answers) => {
            for(var item of answers) {
                if( !item.answer ) {
                    return true;
                }
            }
            return false;
        },

        areThereAnswersWithInvalidLength: (answers, challengeQMinLength) => {
            for(var item of answers) {
                if( !StringUtils.challengeQuestLengthValidation(item.answer, challengeQMinLength) ) {
                    return true;
                }
            }
            return false;
        },

        areAnswersNotUnique: (answers) => {
            var countOf = {};
            for( var i = 0; i < answers.length; i++ ) {
                var item = answers[i];
                if( item.answer == null ) {
                    continue;
                }
                countOf[item.answer] = ( countOf[item.answer] || 0 ) + 1;
            }
            for(var answer of Object.keys(countOf)) {
                if( countOf[answer] > 1 ) {
                    return true;
                }
            }
            return false;
        }
    };

    return service;
}

function ChallengeQuestionsComponentController (loginService, homeService, context, localizedMessages, bvDialogService) {
    var ctrl = this;

    ctrl.challengeQuestionsList = [];
    ctrl.challengeQuestionsToSet = [];
    ctrl.configurableQuestionsCount = 3; // default
    ctrl.isLoggedIn = false;

    ctrl.initializeChallengeQuestions = function () {
        ctrl.challengeQuestionsList = [];
        ctrl.challengeQuestionsToSet = [];
        ctrl.configurableQuestionsCount = 3; // default

        loginService.getConfigurableQuestionsCount(ctrl.userObjectBaseUuid).then(function(countData){
            ctrl.configurableQuestionsCount = countData;

            loginService.getSecurityQuestion(ctrl.userObjectBaseUuid, localizedMessages.getLocale()).then(function(questionsData){
                if( questionsData ) {
                    var availableQuestions = questionsData || [];

                    ctrl.challengeQuestionsList = availableQuestions.map(question => question.text);

                    if( ctrl.isLoggedIn == false || !ctrl.isLoggedIn ) {
                        var amountOfQuestionsToSet = Math.min( availableQuestions.length, ctrl.configurableQuestionsCount );
                        for( var i = 0; i < amountOfQuestionsToSet; i++ ) {
                            ctrl.challengeQuestionsToSet.push({question: null, answer: null})
                        }
                        return;
                    }

                    homeService.getUserQuestions(ctrl.userObjectBaseUuid).then(function(userQuestionsData){
                        var userQuestions = userQuestionsData || [];
                        var amountOfQuestionsToSet = Math.max( userQuestions.length, ctrl.configurableQuestionsCount );

                        for( var i = 0; i < amountOfQuestionsToSet; i++ ) {
                            var question = i < userQuestions.length ? userQuestions[i] : null;
                            ctrl.challengeQuestionsToSet.push({question: question, answer: null});
                        }
                    });
                }
            });

        });
    };

    ctrl.$onChanges = function (changes) {
        ctrl.initializeChallengeQuestions();
    }

    ctrl.notifyAnswersChanged = function() {
        ctrl.onAnswersChanged( {answers: [...ctrl.challengeQuestionsToSet]} );
    }

    ctrl.answerChanged = function() {
        ctrl.notifyAnswersChanged();
    }

    ctrl.selectQuestion = function(challengeQuestionIndex, questionToSetIndex) {
        ctrl.challengeQuestionsToSet[questionToSetIndex].question =
            ctrl.challengeQuestionsList[challengeQuestionIndex];

        ctrl.notifyAnswersChanged();
    }
}