AddToNewItemsController.$inject = ['newItemsService','$scope','localizedMessages','imageService','bvDialog', '$modal','commonService','bvDialogService','permissionService','repositoryModelService', 'templatesService'];

function AddToNewItemsController(newItemsService, $scope, localizedMessages, imageService, bvDialog, modal, commonService, bvDialogService, permissionService, repositoryModelService, templatesService){

	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var fileAttachments = $scope.bvModalControllerInputs.fileAttachments;
	
	$scope.popupTitle = localizedMessages.get("ADD_TO_NEW_ITEMS");
	$scope.closeIcon = templatesService.getUrl('closeBtn');
	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';
	
	$scope.ui = { label : {
			attachments : localizedMessages.get("ATTACHMENTS"),
			send : localizedMessages.get("SEND"),
			recipentsTitle : localizedMessages.get("SEARCH_FOR_RECIPENTS"),
			to : localizedMessages.get("TO"),
			expiry : localizedMessages.get("EXPIRY"),
			expiresIn: localizedMessages.get("EXPIRES_IN"),
			months: localizedMessages.get("MONTHS"),
			addToNewItems : localizedMessages.get("ADD_TO_NEW_ITEMS")
		}
	};
		
	$scope.documentSortableOptions = {
		handle: '> .drag-handle',
        update: function(e, ui)
        {
        	//console.log(ui.item);
        }
    };	

	// var getThumbnail = function(file) 
	// {
	// 	if(file.documentThumbnail == undefined) 
	// 	{
	// 		commonService.getDocumentThumbnail(file.objectBaseUuid,file.objectVersionUuid)
	// 		.then(function(data) 
	// 		{
	// 			if(data != "")
	// 				file.documentThumbnail = data;
	// 		}); 
	// 	}
	// }
	var showNotAllowedAttachmentMessage = function(notAllowedAttachments) {
		var notAllowedAttachmentNames = "";
		for(var i in notAllowedAttachments) {
			if(notAllowedAttachmentNames != "") notAllowedAttachmentNames += ", ";
			notAllowedAttachmentNames += notAllowedAttachments[i].name; 
			console.log(notAllowedAttachments[i].name);
		}
		var alertMsg = localizedMessages.get('NO_ADD_TO_NEW_ITEM_PERMISSION') + ": " + notAllowedAttachmentNames + '.';
		bvDialog.showMessage(alertMsg);
	}

	var removeNotAllowedAttachments = function(fileAttachments) {
		var notAllowedAttachments = [];
        for(var i=0; i<fileAttachments.length; i++){
			if(!(permissionService.isOwner(fileAttachments[i]))){
				notAllowedAttachments.push(fileAttachments[i]);
				fileAttachments.splice(i, 1);
				i--;
			} 
        }
		return notAllowedAttachments;
	}

	$scope.fileAttachments = [];

	if(fileAttachments instanceof Array)
	{
		var notAllowedAttachments = removeNotAllowedAttachments(fileAttachments);
		$scope.fileAttachments = fileAttachments;
	}
	else
	{
		var notAllowedAttachments = removeNotAllowedAttachments([fileAttachments]);
		$scope.fileAttachments.push(fileAttachments);
	}
 
	// for(i in $scope.fileAttachments) 
	// {
	// 	getThumbnail($scope.fileAttachments[i]);
	// }
	
	$scope.cancelConfirmation = function() {
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.close);
	}
	
    $scope.close = function(result) {
       bvModal.close();
    };

    $scope.send = function() 
    {
    	if($scope.fileAttachments.length==0)
    	{
    		return;
		}
		
		var documentsToJSON = StringUtils.getBaseUuidArrayJSONforSelectedContextMenu($scope.fileAttachments);
    	newItemsService.addToNewItems(documentsToJSON);
    	
    	var repositoryModel = repositoryModelService.getInstance("NEW_ITEM");
    	
    	if (repositoryModel.repositoryList().browseList == undefined)
		{    		
    		var attachmentArray =[];
    		attachmentArray.push($scope.fileAttachments[0]);
    		repositoryModel.repositoryList(attachmentArray);
    		$scope.close();	
			return;
		}
    	
    	for (var m in $scope.fileAttachments)
		{	   		
    		repositoryModel.repositoryList().browseList.push($scope.fileAttachments[m]);
		}
    	
		$scope.close();	
    };

	$scope.openAttachmentChooser = function()
	{
		openFileChooser();
	};

    var openFileChooser = function()
    {
    	bvDialog.attachDocument(selectedFiles);
    };
    console.log("after opening file chooser");
    

	var isFileIncluded = function(selectedNode) 
	{
		for (var i=0; i<$scope.fileAttachments.length; i++) 
		{
			if($scope.fileAttachments[i].objectBaseUuid == selectedNode.objectBaseUuid) {
				return true;
			}
		}
		return false;
	}
	
    var selectedFiles = function(selectedNodes) 
    {
    	var notAllowedAttachments = removeNotAllowedAttachments(selectedNodes);

    	for(var i=0; i<selectedNodes.length; i++) 
    	{
            if(!isFileIncluded(selectedNodes[i]))
            {
              	$scope.fileAttachments.push(selectedNodes[i]);
               	//getThumbnail(selectedNodes[i]);
            }
        }

        if(notAllowedAttachments.length !=0) {
        	showNotAllowedAttachmentMessage(notAllowedAttachments);
        }
    }
    
    $scope.removeFileAttachment = function(index) 
    {
		$scope.fileAttachments.splice(index,1);
	};

    ///////Attachment thumbnails ////

	$scope.getDocumentThumbnail = function(attachment) 
	{
		return imageService.getImageForObject(attachment);
	}
    ///////////////

    $scope.expiryList = newItemsService.getExpiryOptions();
    $scope.selectedExpiry = $scope.expiryList[0];
    
    $scope.expiryChange = function(expiry) 
    {
    	$scope.selectedExpiry = expiry;
    }
//    $scope.defaultExpiry = "";
//    newItemsService.getConfiguredTimeLimitForNewItemsToExpire()
//    .then(function(response) { 
//    	$scope.defaultExpiry = response.data;
//    	console.log($scope.defaultExpiry);
//    });

	$scope.previewAttachment = function(docId,docObject) {
   		bvDialog.showDocumentPreview(docId,docObject);
    }

    /////
    var buttons = $scope.bvModalControllerInputs.buttons;
	
    buttons[0] = {name: localizedMessages.get("CANCEL"), action: $scope.cancelConfirmation};
    buttons[1] = {name: localizedMessages.get("SAVE"), action:$scope.send};
    
    
}

angular
  .module('addToNewItems', ['newitems.service','image.service','ui.sortable'])
  .controller('AddToNewItemsController', AddToNewItemsController)
  .filter('unsafe',function($sce) {
    return function(text) {
      return $sce.trustAsHtml(text);
    };
  });