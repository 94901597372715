function validateDirective($timeout, $compile, validateService){
    return {
        restrict: 'A',
        scope: {
            validate: '=',
            validateObject: '='
        },
        transclude: true,
        replace: false,
        link: function($scope, $el, $attr, ctrl, transclude){

            $scope.validate = validateService.createValidate($scope.validate.maxlength, $scope.validate.msg);

            if($scope.validateObject && typeof $scope.validateObject.setValidation == 'function')
                $scope.validateObject.setValidation($scope.validate);

            var wrapper = angular.element('<div class="validate"></div>');
            var span = angular.element('<span>{{ validate.msg }}</span>');
            $el.wrap(wrapper);
            $compile(span.contents())($scope);

            transclude($scope, function (clone) {
                $el.append(clone);
                $el.parent().append(span);
            });

            $el.bind('keydown', function(e){
                 if(!StringUtils.isEnterKey(e)){
                    if($scope.validate && $scope.validate.maxlength){
                        $timeout(function(){
                            if($el.val().length >= $scope.validate.maxlength){
                                $scope.validate.trigger = true;
                            }
                            else{
                                $scope.validate.trigger = false;
                            }
                        });
                    }
                 }
            });

            if($scope.validate){
                if($scope.validate.maxlength)
                    $el.attr('maxlength', $scope.validate.maxlength);
            }
            else{
                $scope.validate = {
                    trigger: false
                }
            }

            $scope.$watch('validate.trigger', function(val){
                if(val){
                    $el.parent().addClass('triggered');
                }
                else{
                    $el.parent().removeClass('triggered');
                }
            });
        }
    }
}