groupattendancetrackingService.$inject = ['$q', 'ajaxService'];

function groupattendancetrackingService($q, ajaxService) {

	var service = {
			getGroupattendancetrackingReport: function(postData, restUrl){
				var request = ajaxService.getGroupattendancetrackingReport(postData, restUrl);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			}
			
	}
	return service;
}
	