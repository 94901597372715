function groupattendancetrackingConfig($stateProvider, $locationProvider) {

}

groupattendancetrackingController.$inject = [ '$scope', '$state', '$timeout','context', 'groupattendancetrackingService', 'localizedMessages','filtercriteriaService', 'toppanelService' ];

function groupattendancetrackingController($scope, $state, $timeout, context, groupattendancetrackingService, localizedMessages, filtercriteriaService, toppanelService) {
	$scope.getGroupattendancetrackingReportUrl = 'getGroupAttendance';
	$scope.reportFilterObject;
	$scope.groupattendancetrackingReportData = [];
	$scope.clickHidePanel = true;
	$scope.noContentMsg = localizedMessages.get('REPORTS_NO_CONTENT_MSG');
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.stateName = $state.$current.name.split("home.reports.")[1];
	$scope.reportTemplateUrl = 'modules/reports/' + $scope.stateName + '/'
			+ $scope.stateName + '.report.tpl.html';
	$scope.middleBarObject.title = localizedMessages.get('ATTENDACE_TRACKING');
	$scope.showingResultsText = localizedMessages.get('SHOWING_RESULTS');
	$scope.outOfText = localizedMessages.get('OUT_OF');
	$scope.downloadMsg = localizedMessages.get('DOWNLOAD_REPORT_MSG');
	$scope.middleBarObject.showBack = true;
	$scope.title = {
		allReports : localizedMessages.get('ALL_REPORTS')
	};
	$scope.paginationObj = {};
	var isFetchingReportData = false;
	var isFilterDataReady;
	var tzid = moment.tz.guess();
	$scope.isScrollBusy = false;
	$scope.downloadReportURL = '/api/reports/exportGroupAttendance';
	$scope.exceededLimit = false;
	var rightMenuList = [];
	$scope.$on('initStarted', function(event, data) {
		isFilterDataReady = false;
		console.log("Message: " + data);
	});
	$scope.$on('initFinished', function(event, data) {
		isFilterDataReady = true;
		console.log("Message: " + data);
	});
	var rightMenuObj = {
		title : localizedMessages.get('DOWNLOAD_REPORT'),
		anchorLink : $scope.downloadReportURL,
		iconUrl : ''
	};

	$scope.tabs = [ {
		title : $scope.middleBarObject.title,
		content : $scope.middleBarObject.title,
		active : true,
		rightMenuList : rightMenuList
	} ];

	$scope.postData = {
		"pageNumber" : 1,
		"size" : 50,
		"timezone": tzid,
		"sortField" : "username",
		"sortOrder" : "desc",
		"filterCriteria" : {
			 "teamspaces": [],
			 "groups": [],
			"users" : [],
			"startDate" : null,
			"endDate" : null,
			"username" : null
		}
	};

	$scope.topGroupattendancetrackingPanel = {
		templateUrl : 'modules/reports/toppanel/toppanel.tpl.html',
		name : 'GROUPATTENDANCETRACKING',
		events : [ {
			name : 'startDate',
			value : localizedMessages.get('START_DATE_UPPERCASE')
		}, {
			name : 'meetingName',
			value : localizedMessages.get('MEETING_TITLE_UPPERCASE')
		}, {
			name : 'attendance',
			value : localizedMessages.get('ATTENDANCE_UPPERCASE')
		}, {
			name : 'columnSortedClk',
			value : function(titleName) {
				$scope.sortReportField({
					value : titleName
				})
			}
		}, {
			name : 'sortField',
			value : $scope.postData.sortField
		} ]
	}

	// * Data Sets convert to Array
	$scope.getSetAsArr = function(set) {
		var arr = [];
		set.forEach(function(value) {
			arr.push(value);
		});
		return arr;
	};

	// * Data Sets values convert to Array
	$scope.getSetValAsArr = function(set) {
		var arr = [];
		set.forEach(function(value) {
			arr.push(value.ID);
		});
		return arr;
	};

	$scope.getRow = function(rows, cloumns) {
		var row = new Array();
		cloumns.forEach( key=> {
			if(rows[key]){
				row.push(rows[key]);
			}else{
				row.push("-");
			}
		});
		return row;
	};
	

	
	$scope.getGroupattendancetrackingReport = function(postData, getGroupattendancetrackingReportUrl) {
		if (isFilterDataReady) {
			$scope.postData.pageNumber = 1;
			isFetchingReportData = true;
			groupattendancetrackingService.getGroupattendancetrackingReport(postData,getGroupattendancetrackingReportUrl).then(function(response) {
					if (response) {
						isFetchingReportData = false;
						$scope.isScrollBusy = false;
						$scope.groupattendancetrackingReportData = response.content;
						var content = response.content;
						var metadata = response.metadata;
						var columns = metadata.columns;
						var data = [];
						$scope.rows = [];
						$scope.cols =[];						
						var columnKeys = new Array();
						
						columns.forEach(column => {
							columnKeys.push(column.objectId);
							$scope.cols.push(column.objectName)
						})
						
						content.forEach(c=>{
							const row = $scope.getRow(c, columnKeys);
							$scope.rows.push(row);
						});
						
						$scope.postData.pageNumber = response.number;
						$scope.paginationObj.pageNumber = response.number;
						$scope.paginationObj.numberOfElements = response.numberOfElements;
						$scope.paginationObj.size = response.size;
						$scope.paginationObj.isLastPage = response.last;
						$scope.paginationObj.totalPages = response.totalPages;
						$scope.paginationObj.totalElements = response.totalElements;
						if (response.content.length == 0) {
							$scope.tabs[0].rightMenuList.length = 0;
						} else {
							$scope.tabs[0].rightMenuList[0] = rightMenuObj;
						}
					}
				}
			);
		}
	};

	$scope.init = function() {
		$scope.updatePostData();
		$scope.getGroupattendancetrackingReport($scope.postData, $scope.getGroupattendancetrackingReportUrl);
	}
	$scope.$on('initFinished', function(event, data) {
		$scope.init();
		console.log("Message: " + data);
	});

	$scope.sortReportField = function(sortItem) {
		var toppanel = toppanelService.getInstance('GROUPATTENDANCETRACKING');
		$scope.postData.pageNumber = 1;
		if ($scope.postData.sortField === sortItem.value) {
			$scope.postData.sortOrder = ($scope.postData.sortOrder == 'desc') ? 'asc': 'desc';
		} else {
			$scope.postData.sortOrder = 'desc';
		}
		$scope.postData.sortField = sortItem.value;
		toppanel.addEvent('sortField', $scope.postData.sortField);
		toppanel.addEvent('sortOrder', $scope.postData.sortOrder);
		$scope.updatePostData();
		$scope.getGroupattendancetrackingReport($scope.postData,
				$scope.getGroupattendancetrackingReportUrl);
	}
	
	$scope.closeToaster = function() {
		$scope.exceededLimit = false;
	}

	$scope.handleLeftIcon = function() {
		$state.go('home.reports');
	}
	$scope.updatePostData = function() {
		$scope.postData.filterCriteria.teamspaces = $scope.getSetValAsArr(filtercriteriaService.reportFilterObject.selectedBoardTeamspaces);
		$scope.postData.filterCriteria.groups = $scope.getSetAsArr(filtercriteriaService.reportFilterObject.selectedGroups);
		$scope.postData.filterCriteria.startDate = filtercriteriaService.reportFilterObject.rangeSelection.startDate;
		$scope.postData.filterCriteria.endDate = filtercriteriaService.reportFilterObject.rangeSelection.endDate;
		$scope.postData.filterCriteria.users = $scope.getSetAsArr(filtercriteriaService.reportFilterObject.selectedUsers);
		

	}
	$scope.columnSortedClkfn = function(sortItem){		
			$scope.sortReportField({
				value : sortItem
			})
		
	}
	$scope.$on('filterObjectChanged', function(event, data) {
						if (isFilterDataReady) {
							$scope.reportFilterObject = filtercriteriaService.reportFilterObject;
							$scope.updatePostData();
							$scope.getGroupattendancetrackingReport(
									$scope.postData,
									$scope.getGroupattendancetrackingReportUrl);
							console.log("Message: " + data);
						}
					});
	
	//$scope.init();
}



function fixedColumn(){
	return {	    
	    link: function(scope, element, attrs, ctrl) {
	    
	      element.bind('scroll', function() {
	    	  console.log('scroll event triggered');
	    	  
	    	  angular.element('.group-attend-table tbody').css('left', - angular.element('.group-attend-table tbody').scrollLeft()); //fix the thead relative to the body scrolling
	    	  angular.element('.group-attend-table tbody tr th:nth-child(1)').css('left', angular.element('.group-attend-table tbody').scrollLeft()); //fix the first column of tdbody
	    	  angular.element('.group-attend-table tbody tr td:nth-child(1)').css('left', angular.element('.group-attend-table tbody').scrollLeft()); //fix the first column of tdbody
	    	  
	      });

	    }
	  };
}