angular
    .module('dialogControllers.service')
	.controller('showMeetingAttendeesController', showMeetingAttendeesController);

showMeetingAttendeesController.$inject = ['$scope', 'context', 'localizedMessages', 'teamService'];

function showMeetingAttendeesController($scope, context, localizedMessages, teamService)
{
	$scope.SELECTED_EVENT = $scope.bvModalControllerInputs.selectedEvent;
	$scope.popupTitle               =   localizedMessages.get('ATTENDEES');
	$scope.UI_CREATED_BY    		=   localizedMessages.get('CREATED_BY');
	$scope.UI_DECLINED_TXT  		=   localizedMessages.get('DECLINED');
	$scope.UI_ACCEPTED_TXT  		=   localizedMessages.get('ACCEPTED');
	$scope.UI_TENTATIVE_TXT  	    =   localizedMessages.get('TENTATIVE');
	$scope.UI_NOT_RESPONDED_TXT     =   localizedMessages.get('NOT_RESPONDED');
	//console.log("SELECETD EVENT:",$scope.SELECTED_EVENT);
	 
	 $scope.getUserThumbnail = function(user) {
		if($scope.userImages[user.objectBaseUuid])
		{
			return "data:image/png;base64," + $scope.userImages[user.objectBaseUuid];
		}
		else
		{
			return '';
		}
	}
	 
	$scope.init = function(eventsGuests) 
	{
		$scope.CURRENT_ATTENDEE_OPTION = 'accepted';
		var guests = {}
		guests.acceptedGuests = [];
		guests.declinedGuests = [];
		guests.tentativeGuests = [];
		guests.notRespondedGuests = [];
		var response = "";
	
		if(eventsGuests!=undefined && eventsGuests!=null && eventsGuests.length >0)
		{
			for(var i in eventsGuests)
			{
				$scope.userImages = []
				teamService.getUserProfileImage(eventsGuests[i].objectBaseUuid, eventsGuests[i].objectVersionUuid).
				then(function(data)
				{
					if(data != '' && data.profileImage != undefined && data.profileImage != '')
					{
						$scope.userImages[data.objectBaseUuid]=data.profileImage;
					}
				})
				
				response = eventsGuests[i].response;
				switch(response)
				{
					case 'accepted' 	: guests.acceptedGuests.push({'guestName' : eventsGuests[i].displayName, 'objectBaseUuid' : eventsGuests[i].objectBaseUuid }); break;
					case 'declined'		: guests.declinedGuests.push({'guestName' : eventsGuests[i].displayName, 'objectBaseUuid' : eventsGuests[i].objectBaseUuid }); break;
					case 'tentative'	: guests.tentativeGuests.push({'guestName' : eventsGuests[i].displayName, 'objectBaseUuid' : eventsGuests[i].objectBaseUuid }); break;
					case 'not_responded': guests.notRespondedGuests.push({'guestName' : eventsGuests[i].displayName, 'objectBaseUuid' : eventsGuests[i].objectBaseUuid }); break;
				}
			}
		}
		$scope.acceptedGuests = guests.acceptedGuests;
		$scope.declinedGuests = guests.declinedGuests;
		$scope.tentativeGuests = guests.tentativeGuests;
		$scope.notRespondedGuests = guests.notRespondedGuests;
		
		$scope.guests = guests;

		$scope.scrollListOptions = {
									filter      : { show : true, title : localizedMessages.get("FILTER"), search : {} },
									columns     : [{ title : '', width : 100, name : 'guestName' },],
									showColumns : false,
									data        : { list : guests.acceptedGuests }
							  }
	}

	$scope.changeOption = function (optionVal)
	{
		$scope.CURRENT_ATTENDEE_OPTION = optionVal;
		switch(optionVal)
		{
			case 'accepted' 	: $scope.scrollListOptions.data = { list : $scope.acceptedGuests }; break;
			case 'declined'		: $scope.scrollListOptions.data = { list : $scope.declinedGuests }; break;
			case 'tentative'	: $scope.scrollListOptions.data = { list : $scope.tentativeGuests }; break;
			case 'notResponded' : $scope.scrollListOptions.data = { list : $scope.notRespondedGuests }; break;
		}
		//$scope.CURRENT_USERS = acceptedGuests;
	}

	var confGuests = $scope.SELECTED_EVENT.guests;
	//var confGuests = JSON.parse(scope.content);
	$scope.init(confGuests);
}