angular
    .module('dialogControllers.service')
	.controller('cbeAdminPreviewController', cbeAdminPreviewController);

cbeAdminPreviewController.$inject =  [ '$scope', '$filter', '$state', 'localizedMessages', 'context', '$sce', 'teamService', 'surveysService', 'bvDialog', 'surveysAlertService'];

function cbeAdminPreviewController($scope,$filter, $state, localizedMessages, context, $sce, teamService, surveysService, bvDialog, surveysAlertService){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.cbeQuestionnaire = $scope.bvModalControllerInputs.cbeQuestionnaire;

	if($scope.cbeQuestionnaire.cbeStatus == 'Sent to CBE'){
		$scope.cbeProgressStatus = localizedMessages.get('SENT_TO_NASDAQ'); 
	}else{
		$scope.cbeProgressStatus = $scope.cbeQuestionnaire.cbeStatus;
	}
	$scope.popupTitle 	= localizedMessages.get('QUESTIONNAIRE_STATUS') + " - " + $scope.cbeProgressStatus;
	$scope.scrollbarConfig = {
			autoHideScrollbar : true,
			advanced: {
				autoScrollOnFocus: false
			}
	}
	$scope.userLbl = localizedMessages.get('USERS_UPPERCASE');
	$scope.responseLbl = localizedMessages.get('STATUS_UPPERCASE');
	$scope.dueLbl = localizedMessages.get("DUE");
	$scope.archiveLbl = localizedMessages.get("ARCHIVE");
	$scope.sendalertLbl = localizedMessages.get("SEND_ALERT");
	$scope.publishLbl = localizedMessages.get("PUBLISH");
	$scope.editLbl = localizedMessages.get("EDIT");
	$scope.participants = [];
	$scope.cbeQuestionnaire.participantBaseUuidAndResponseList.forEach(user => {
		var participant = {};
		participant.name = user.name;
		if($scope.cbeQuestionnaire.cbeStatus == "Active") {
			if(user.value.toLowerCase() == "in review") {
				participant.response = localizedMessages.get("IN_PROGRESS");
			} 
			else if(user.value.toLowerCase()=="completed") {
				participant.response = localizedMessages.get("COMPLETED");
			} 
			else
			{
				participant.response = localizedMessages.get("NOT_RESPONDED");
			}
		}
		else if($scope.cbeQuestionnaire.cbeStatus == "Completed") {
			participant.response = localizedMessages.get("COMPLETED");
		}
//		else if($scope.cbeQuestionnaire.cbeStatus == "Ready for Distribution") {
//			participant.response = localizedMessages.get("COMPLETED");
//		}
		
		teamService.getUserProfileImageByObjectBaseUuid(user.objectBaseUuid).
        then(function(data)
            {
	            if(data != '' && data.profileImage != undefined && data.profileImage != '')
	            {
	            	participant.userImage="data:image/png;base64," + data.profileImage;
	            }else{
	            	participant.userImage=undefined;
                }
            });
		$scope.participants.push(participant);
    });
	
	$scope.close = function(){
		bvModal.close();
	}
	
	$scope.getShortLocalizedDate = function(date) {
		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
	}
	$scope.archive = function() {
		surveysService.closeOrReopenSurveyJSON($scope.cbeQuestionnaire.objectBaseUuid, true).then(function(data){
			
			var result = data;

    		if(!result || result === undefined || result.boName === "FailureBO"){
    			bvDialog.showMessage(localizedMessages.get("ERR_ENCR_DURING_ARCHIVE"), null, true);
    		} else {
    			bvDialog.showMessage(localizedMessages.get("ARCHIVED_SUCCESSFUL"), null, true);
    			$scope.close();
    			$state.reload();
    		}
		});
	}
	
	$scope.sendAlert = function() {
		surveysAlertService.survey = $scope.cbeQuestionnaire;
		surveysAlertService.openAlertDialog();
		$scope.close();
	}
	$scope.publish = function() {
		surveysService.publishCBESurvey($scope.cbeQuestionnaire.objectBaseUuid).then(function(data){
					
			var result = data;

    		if(!result || result === undefined || result.boName === "FailureBO"){
    			bvDialog.showMessage(localizedMessages.get("ERR_ENCR_DURING_PUBLISH"), null, true);
    		} else {
    			bvDialog.showMessage(localizedMessages.get("PUBLISHED_SUCCESSFUL"), null, true);
    			$scope.close();
    			$state.reload();
    		}
		});
	}
	$scope.edit = function() {
//		$state.go('home.createCBEQuestionnaire', { surveyBaseUuid: $scope.cbeQuestionnaire.objectBaseUuid});
		$state.go('home.createCBEQuestionnaire', { cbeObject: $scope.cbeQuestionnaire});
		$scope.close();
	}
}