function colorPickerFont(context){
	return {
	    restrict: 'A',
		controller: ['$scope', '$state', 'brand', '$element', 'homeService', function(scope,state, brand, el, homeService){
			var textColor = context.getModel().html5TextColor;
			var colorVal = "ffffff";
			if (textColor != null)
			{
				colorVal = textColor.replace('#','').trim();
			}
			$(el).val(colorVal);
		    $(el).ColorPickerSliders({
		    	color: colorVal,
		    	connectedinput: $(el),
		    	flat: true,
		    	order: {
		    	hsl: 1
		    	},
		    	onchange: function(container, color) {
		            $('input#rightColor').val(color.tiny.toHexString());
		            brand.color($('input#leftColor').val(), $('input#rightColor').val());
		            context.getModel().html5TextColor ="#" +  color.tiny.toHexString();
		        }
		    });
		}]
	}
}