CreateUserController.$inject = ['teamService', '$scope', '$modalInstance', '$modal','localizedMessages','constants', 'bvDialog','homeService', 'templatesService','context'];

function CreateUserController(teamService, $scope, $modalInstance, modal, localizedMessages, constants, bvDialog, homeService, templatesService, context) {

	$scope.emailRegEx = constants.get('EMAIL_REGEX');
	$scope.nameRegEx = constants.get('NAME_REGEX');
	$scope.commonValueRegEx = constants.get('COMMON_VALUE_REGEX');
	//console.log($scope.selectedUser);
	$scope.popupTitle = localizedMessages.get('CREATE_USER');
	$scope.firstNameMaxLength = 250;
	$scope.lastNameMaxLength = 250;
	$scope.loginIDMaxLength = 50;	

	$scope.closeIcon = templatesService.getUrl('closeBtn');
    var passwordPreferences = {};
	$scope.profileFieldsLabel = {
			licenseType: localizedMessages.get('LICENSE_TYPE'),
       		firstName: localizedMessages.get('FIRST_NAME'),
       		lastName: localizedMessages.get('LAST_NAME'),
       		loginID: localizedMessages.get('LOGIN_ID'),
       		emailAddress: localizedMessages.get('EMAIL_ADDRESS'),
       		password: localizedMessages.get('PASSWORD'),
       		confirmPassword: localizedMessages.get('CONFIRM_PASSWORD')
       };
	
	teamService.getUserProfileConfigurationsJSON().then(function(data) {
		
		if(!StringUtils.isNullEmpty(data))
		{
			$scope.firstNameMaxLength = data.FIRST_NAME_MAX_LENGTH;
			$scope.lastNameMaxLength = data.LAST_NAME_MAX_LENGTH;
			$scope.loginIDMaxLength = data.LOGIN_ID_MAX_LENGTH;
		}		
	})
       
    homeService.getPasswordPreferences().then(function(data){
		if ( data != null) 
		{
			passwordPreferences = data;
		}
	});

	$scope.cancelLable = localizedMessages.get('CANCEL');
	$scope.okLable = localizedMessages.get('OK');

	$scope.requiredFieldsLable = localizedMessages.get('INDICATES_REQUIRED_FIELD');


	$scope.close = function(result) {
		$modalInstance.dismiss('cancel');
	};

	$scope.cancelConfirmation = function() {
		if($scope.frmCreateUser.$dirty == true) {
			bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
		} else {
			$scope.cancelEdit();
		}
	}

	$scope.cancelEdit = function(result) {
		$scope.close();
	}

	$scope.createUser = function()
	{
		if($scope.frmCreateUser.$error.required) {
			bvDialog.showMessage(localizedMessages.get('COMPLETE_REQUIRED_FILEDS'));
			// ngDialog.open({
			// 	template: '<div style="text-align:center">'+ localizedMessages.get('COMPLETE_REQUIRED_FILEDS') + '</div>',
			// 	plain: true
			// });
			return;
		}

		if(!$scope.frmCreateUser.$valid) {
			bvDialog.showMessage(localizedMessages.get('INVALID_DATA_FOUND'));
			// ngDialog.open({
			// 	template: '<div style="text-align:center">'+ localizedMessages.get('INVALID_DATA_FOUND') + '</div>',
			// 	plain: true
			// });
			return;
		}

		if($scope.user.password != $scope.user.confirmPassword) {
			bvDialog.showMessage(localizedMessages.get('CONFIRM_PASSWORD_NOTMATCH'));

			// ngDialog.open({
			// 	template: '<div style="text-align:center">'+ localizedMessages.get('CONFIRM_PASSWORD_NOTMATCH') + '</div>',
			// 	plain: true
			// });
			return;
		}
		
		var passwordValid = false;
		if(passwordPreferences && passwordPreferences.isPasswordPolicyEnaled)
		{
	    	passwordValid = StringUtils.passwordValidation(passwordPreferences, $scope.user.password);
		}
	    else if(passwordPreferences && !passwordPreferences.isPasswordPolicyEnaled)
		{
	    	passwordValid = StringUtils.passwordGlobalValidation(passwordPreferences, $scope.user.password);
		}
	    if(!passwordValid)
    	{
	    	bvDialog.showMessage($scope.getErrorLabelForChangePassword(), 'bv-create-user-dialog');
	    	return;
    	}
		teamService.createUser($scope.user.firstName, $scope.user.lastName, $scope.user.loginID, $scope.user.password, $scope.user.emailAddress, $scope.user.licenseType).
		then(function(data) {
			//console.log(data);
			if(data == null) {
				bvDialog.showMessage(localizedMessages.get("CREATE_USER_FAILED"));
			}
			else if(data.errMessage ) 
			{
				if(data.errMessage == "LoginIDexists")
				{
					bvDialog.showMessage("Error: " +  localizedMessages.get("ERR_LOGIN_ID_EXISTS"));
				} 
				else if(data.errMessage == "Emailexists") 
				{
					bvDialog.showMessage("Error: " + localizedMessages.get("ERR_EMAIL_EXISTS"));
				}
				else 
				{
					bvDialog.showMessage("Error: " + data.errMessage);
				}
			} 
			else if(data.boName!== 'undefined' && data.boName == "FailureBO") 
			{
				bvDialog.showMessage("Error: " + data.message);
			}
			else {				
				bvDialog.showMessage(localizedMessages.get("CREATE_USER_SUCCESS"), null, true);
				$scope.close();
			}
			$scope.getTeamUsers(alsoImages = false);
		})
	}
	
	$scope.getErrorLabelForChangePassword = function()
	{
		var errorTxt = "";
		var flag = false;
		errorTxt = localizedMessages.get("THE_PASSWORD_YOU_ARE_CREATING_MUST_FULFILL_THE_FOLLOWING_REQUIREMENTS")+"\n";
		var i = 1;
		if(!passwordPreferences.isPasswordPolicyEnaled)
		{
			if(passwordPreferences.passwordSystemMinLength)
			{
			  errorTxt = localizedMessages.get("THE_PASSWORD_YOU_ARE_CREATING_MUST_BE_AT_LEAST") + " " + passwordPreferences.passwordSystemMinLength
			  + " " + localizedMessages.get("CHARACTERS_LONG"); 
			}
		}
		else
		{
		  if(passwordPreferences.passwordMinLength)
		  {
			errorTxt += i +  " "+localizedMessages.get("AT_LEAST") + " " + passwordPreferences.passwordMinLength + " " +  
			localizedMessages.get("CHARACTERS_LONG")+"\n"; 
			flag = true; 
			i = i+1;
		  }
		  if(passwordPreferences.passwordMinAlphaCharacters && parseInt(passwordPreferences.passwordMinAlphaCharacters) > 0)
	 	  {
			errorTxt += i +  " " + localizedMessages.get("CONTAIN_AT_LEAST") + " " + passwordPreferences.passwordMinAlphaCharacters 
			+ " " + localizedMessages.get("LETTERS")+"\n";
			flag = true;
			i = i+1;
		  }
		  if(passwordPreferences.passwordMinNumbers && parseInt(passwordPreferences.passwordMinNumbers) > 0)
		  {
			errorTxt += i +  " " + localizedMessages.get("CONTAIN_AT_LEAST") + " " + passwordPreferences.passwordMinNumbers 
			+ " " +  localizedMessages.get("NUMBERS")+"\n";
			flag = true;
			i = i+1;
		  }
		  if(passwordPreferences.passwordMinNonAlphaCharacters && parseInt(passwordPreferences.passwordMinNonAlphaCharacters) > 0)
		  {
			errorTxt += i +  " " + localizedMessages.get("CONTAIN_AT_LEAST") + " "  + passwordPreferences.passwordMinNonAlphaCharacters
			+ " " +  localizedMessages.get("SPECIAL_CHARACTERS_WITH_EXAMPLE")+"\n";
			flag = true; 
			i = i+1;
		  }
		  if(passwordPreferences.isUpperAndLowerEnabled)
		  {
			errorTxt += i +  " "  + localizedMessages.get("CONTAIN_AT_LEAST_ONE_UPPER_CASE_AND_ONE_LOWER_CASE_CHARACTER")+"\n";
			flag = true;
			i = i+1;
		  }
		  if(!flag)
			errorTxt = "";
		}
		return errorTxt;
	};

	$scope.user = {
		licenseType: ""
	}

	$scope.licenseChanged = function(licenseType) {
		$scope.user.licenseType = licenseType;
	}

	teamService.getLicenseTypeDetails().then(function(data) {
		$scope.licenseTypes = data;
		$scope.user.licenseType = $scope.licenseTypes[0];
	})
}

angular
    .module('createUser', [ 'team.service','localization.service','constants.service'])
    .controller('CreateUserController', CreateUserController);