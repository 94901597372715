function manageTemplatesConfig($stateProvider, $locationProvider){	
}
manageTemplatesController.$inject = ['$scope', '$document', '$state','bvDialog', 'reactInterface', 'context', 'localizedMessages','repositoryService','manageTemplatesService','SortUtility', 'commonService','legacyUserService' ];

function manageTemplatesController($scope, $document , $state, bvDialog, reactInterface, context, localizedMessages, repositoryService, manageTemplatesService, SortUtility, commonService, legacyUserService){

  var portalId = context.getModel().portalVO.objectBaseUuid;
 
  $scope.AGENDA_TEMPLATES = localizedMessages.get('AGENDA_TEMPLATES');
  $scope.NO_AGENDA_TEMPLATES_AVAILABLE = localizedMessages.get('NO_AGENDA_TEMPLATES_AVAILABLE');
  $scope.CREATE_NEW_AGENDA_TEMPLATE = localizedMessages.get('CREATE_NEW_AGENDA_TEMPLATE');
  $scope.AGENDA_TEMPLATE = localizedMessages.get('AGENDA_TEMPLATE');
  $scope.EDIT_BY = localizedMessages.get('EDIT_BY');
  $scope.EDIT_DATE = localizedMessages.get('EDIT_DATE');
  $scope.EDIT = localizedMessages.get('EDIT');
  $scope.DELETE = localizedMessages.get('DELETE');


 $scope.checkEntitlementAndLoadTemplates = function() {
  let canCurrentUserCreateModule = false;
  commonService.checkModuleCreatorsConfigPresentJSON("MEETINGS").then(function(data){
    if(data && data.data && data.data.name == "isModuleCreatorsPrefAllowed"){
      config = data.data.value;
      if(config == "true" || config == true){
        commonService.getModuleCreatorsForSpecificModuleJSON("MEETINGS").then(function(mod){
          if(mod.data){
            var moduleCreators = angular.fromJson(mod.data);
            if(moduleCreators.users != undefined && moduleCreators.users.length>0){
              for(var i=0;i<moduleCreators.users.length;i++){
                if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
                  canCurrentUserCreateModule = true;
                  break;
                }
              }
              canCurrentUserCreateModule = canCurrentUserCreateModule && (legacyUserService.isNasdaqUI && context.getModel().companyVO.enableAgendaBuilder);
					    !canCurrentUserCreateModule ? $state.go('home.meetings') :$scope.getAllDigitalAgendaTemplates(portalId);              
        
            } else $state.go('home.meetings');
          } else {
            $state.go('home.meetings');
          }
        });
      }
      else{
        canCurrentUserCreateModule = (legacyUserService.isNasdaqUI && context.getModel().companyVO.enableAgendaBuilder);
			  !canCurrentUserCreateModule ? $state.go('home.meetings') : $scope.getAllDigitalAgendaTemplates(portalId);
      }
    } else {
      $state.go('home.meetings');
    }
  });

 } 

	$scope.reactInterface = reactInterface;
    $scope.middleBarObject.showBack = true;
    $scope.middleBarObject.title = localizedMessages.get("MEETINGS") + ' | ' + localizedMessages.get("AGENDA_TEMPLATES");
	$scope.tabs = [{
        title : localizedMessages.get("AGENDA_TEMPLATES"),
        content : 'manageTemplates',
        active: true,
        rightMenuList:[]
    }];
    $scope.createAgendaTemplatesLandingPage = function() {
		console.log("createAgendaTemplate Landing Page")
		$state.go('home.createAgendaTemplate')
    }
    $scope.handleLeftIcon = function()
    {
    	$scope.middleBarObject.showBack = false;
    	$state.go('home.meetings');	
    }
    $scope.getCreateDate = function(createdOn)
    {
      var dateHelper = new DateHelper(context.getModel().userVO);
      
      return dateHelper.getForrmattedAbbrDate(createdOn);
    }

  $scope.getAllDigitalAgendaTemplates = function(portalBaseUuid){
     manageTemplatesService.getAllDigitalAgendaTemplates(portalBaseUuid).then(function(response)
     {
       if(response)
       {
        $scope.digitalAgendaTemplates = response;
        $scope.sortedDigitalAgendaTemplates = SortUtility.sortByField($scope.digitalAgendaTemplates, ['createdOn'], true);
       }
     	}); 
  };

  $scope.deleteDigitalAgendaTemplate = function(agendaTemplateId){
    var agendaTemplateId = agendaTemplateId;
    bvDialog.confirm(localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_AGENDA_TEMPLATE'), function(){
     manageTemplatesService.deleteDigitalAgendaTemplate(agendaTemplateId).then(function(response)
        {
         var res = JSON.parse(response);
       if(res.boName === "SuccessBO")
        {
        $scope.getAllDigitalAgendaTemplates(portalId);
       }
       });	
   }); 
    }
    
    var showTemplateMenu;
    $scope.showOptions = function(event, template){
      if (template == showTemplateMenu) {
        template.dp = !template.dp;
      }
      else {
        if (showTemplateMenu) showTemplateMenu.dp = false;
        template.dp = true;
      }
      showTemplateMenu = template
      event.stopPropagation();
    };
    window.onclick = function(){
      if (showTemplateMenu)  showTemplateMenu.dp = false;
      showTemplateMenu = null;
      $scope.$apply();
    };

  $scope.init = function(){
  $scope.checkEntitlementAndLoadTemplates();
  }
	$scope.init();
}

