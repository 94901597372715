angular
    .module('dialogControllers.service')
    .controller('folderChooserController', FolderChooserController);

FolderChooserController.$inject = ['$scope', 'localizedMessages', 'repositoryService', 'treeService', 'permissionService', 'bvDialog'];

function FolderChooserController($scope, localizedMessages, repositoryService, treeService, permissionService, bvDialog)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	
	$scope.actionBtnTxt	= localizedMessages.get("OK");
	$scope.closeBtnTxt 	= localizedMessages.get("CANCEL");
	
	var treeDataModel = treeService.getInstance("FOLDER_ATTACHMENT");
	
	var items = $scope.bvModalControllerInputs.items;
	
	$scope.popupTitle = $scope.bvModalControllerInputs.popupTitle;
	
	var getDocumentRootFolder = function () 
    {
    	repositoryService.getDocumentRootFolder().then(function(data)
    	{
            $scope.getDocumentForRepositoryTree(data.data);     
    	});
    };
    
    $scope.getDocumentForRepositoryTree = function (rootFolder) 
    {
    	$scope.folderTreeModel = [rootFolder];
    	
    	treeDataModel.getChildrenForTreeNode(rootFolder, repositoryService.findFoldersAndSearchObjectsForParentUuid);        	
    };
    
    $scope.getSelectedTreeNode = function(selectedNode)
    {
    	treeDataModel.setSelectedNode(selectedNode);
    	
    	repositoryService.findFoldersAndSearchObjectsForParentUuid(selectedNode.objectBaseUuid).then(function(data)
    	{
    		selectedNode.children = [];
    		
    		selectedNode.children = data.data;
    	
    	});
    };

	$scope.action = function() {
		var object = treeDataModel.getSelectedNode();
		if($scope.bvModalControllerInputs.restore){
			if(permissionService.isOwner(object) || permissionService.isEdit(object) || permissionService.isShareAllowed(object)){
				$scope.bvModalControllerInputs.selectionDone(object, items, $scope.bvModalControllerInputs.parentContainer);
				bvModal.close();
			}
			else{
				bvDialog.showMessage(localizedMessages.get("NO_RESTORE_PERMISSION_MSG"));
			}
		}
		else{
			$scope.bvModalControllerInputs.selectionDone(object, items, $scope.bvModalControllerInputs.parentContainer);
			bvModal.close();
		}
		
    }

    $scope.close = function() {
	    //$scope.selectionDone(false);
	    bvModal.close();
	};

	getDocumentRootFolder();	
}