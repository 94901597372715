ConferenceController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$modal', 'context', 'modelService', 'conferenceService', 'presentationService', 'calendarService', 'imageService', 'localizedMessages','repositoryService', 'bvDialog', 'bvDialogService','contextMenuSetting','commonService','bvSliderInstanceService', '$timeout', 'homeService', 'meetingsService', 'legacyUserService'];

function ConferenceController($scope, $rootScope, $state, $stateParams, modal, context, modelService, conferenceService, presentationService, calendarService, imageService, localizedMessages, repositoryService, bvDialog, bvDialogService, contextMenuSetting, commonService, bvSliderInstanceService, $timeout, homeService, meetingsService, legacyUserService) {

	//console.log("EVENT_LIST:",$rootScope.EVENTS_LIST);
	$scope.currentUser = context.getModel().userVO;
	var ctrlName = 'ConferenceController';

	$scope.MEETING_OBJECT = modelService.getMeetingCenterObject();
    $scope.UI_COLLAN_TXT  	   = ":";
    $scope.UI_DETAILS_TXT  	   = localizedMessages.get('DETAILS');
    $scope.UI_YOU_ARE_TXT  	   = localizedMessages.get('YOU_ARE');
    $scope.UI_AND_TXT  	       = localizedMessages.get('AND');
    $scope.UI_HOST_TXT  	   = localizedMessages.get('HOST');
    $scope.UI_EDIT  	   	   = localizedMessages.get('EDIT');
    $scope.UI_COPY  	   	   = localizedMessages.get('COPY');
    $scope.UI_CANCEL   	   	   = localizedMessages.get('CANCEL');
    $scope.UI_USERS 	   	   = localizedMessages.get('USERS');
    $scope.UI_SEND 	   	   	   = localizedMessages.get('SEND');
    $scope.UI_ALERT  	       = localizedMessages.get('ALERT');
    $scope.UI_DELETE           = localizedMessages.get('DELETE');
    $scope.UI_PRESENTER_TXT    = localizedMessages.get('PRESENTER');
    $scope.UI_ATTENDEE_TXT     = localizedMessages.get('ATTENDEE');
    $scope.UI_OWNER_TXT        = localizedMessages.get('OWNER');
    $scope.UI_ATTENDEES_TXT    = localizedMessages.get('ATTENDEES');
    $scope.UI_LAST_UPDATED_TXT = localizedMessages.get('LAST_UPDATED');
    $scope.UI_MATERIALS_TXT    = localizedMessages.get('MATERIALS');
    $scope.UI_PRESENT_BTN_ACTION_TXT = localizedMessages.get('PRESENT');
    $scope.UI_START_TXT = legacyUserService.isNasdaqUI ? localizedMessages.get('START_MEETING') : localizedMessages.get('START');
    $scope.UI_JOIN_TXT = localizedMessages.get('JOIN');
    $scope.UI_END_TXT = localizedMessages.get('END');
    $scope.ERR_EVENT_DETAILS   = localizedMessages.get('ERR_PRESENTATION_EVENT_DETIALS_NOT_AVAILABLE');
    
    

    var bvSliderService;
    	

//	$scope.reactInterface = reactInterface;
	var initialize = function ()
	{
		//console.log("EVENT_LIST:",$scope.EVENTS_LIST);

		//Jan 1, 2014 08:00:00 AM
		//Jan 2, 2014 08:00:00 PM
		console.log("calendarService.isCurrentUserAllowedToCreateModule(): "+calendarService.isCurrentUserAllowedToCreateModule());
		$scope.isUserAllowedToCreateMeetingLeg = calendarService.isCurrentUserAllowedToCreateModule();
		console.log("isUserAllowedToCreateMeetingLeg : "+$scope.isUserAllowedToCreateMeetingLeg);
		var sliderRenderCallback = function(){
			if(!legacyUserService.isNasdaqUI){
				bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptions.id]
				bvSliderService.currentIndex = 0;
			}
		}

		$scope.sliderOptions = {
			id: 'CONFERENCE',
			items: [],
			slideThrough: 1,
			shownItems: 2,
			renderCallback : sliderRenderCallback
		};

		$scope.CREAT_OR_EDIT_FLAG = false;
		$rootScope.USERS_IMAGES = $scope.userImages;
		$scope.middleBarObject.showBack = true;
		$scope.EVENTS_LIST = $rootScope.EVENTS_LIST;
		$scope.CONF_CURRENT_UI_CONTENT = "";
		$scope.showNewOrEditMeeting = false;
		if(!legacyUserService.isNasdaqUI){
			$scope.middleBarObject.title =  ($scope.EVENTS_LIST!=undefined && $scope.EVENTS_LIST.DATE!=undefined) ? $scope.EVENTS_LIST.DATE : "";
		}

		$scope.tileCheckBox = { title : localizedMessages.get("PUBLISH") , show : false , active : false };
		$scope.tileCheckBox.show = $scope.showNewOrEditMeeting;
		var manageMeetingObject = { title:localizedMessages.get('MANAGE_MEETINGS'), click: showMeetingSettings, iconUrl: '', stateName: 'ManageMeetings'};
//		var copyMeetings = { title: localizedMessages.get('COPY_MEETINGS'), click: showCopyMeetings, iconUrl: '', stateName: 'CopyMeetings'}
		$scope.svgFill = '#FFF';
		var rightMenu =   [
					//{ title:localizedMessages.get('EDIT_MEETINGS'), click: showMeetingSettings, iconUrl: ''},
//					{ title:localizedMessages.get('MANAGE_MEETINGS'), click: showMeetingSettings, iconUrl: '', stateName: 'ManageMeetings'}
					// { title:localizedMessages.get('NEW_MEETING'), click: createEvent, iconUrl: '', stateName: 'NewMeeting'}];
					//{ title:localizedMessages.get('CURRENT'), click: goToLatestSlide, iconUrl: ''}
					];
		var rightMenuNewMeetingView = [];
		$scope.isEditEnabled = false;
//		if(calendarService.isCurrentUserAllowedToCreateModule() == true){
//        rightMenu.push(copyMeetings);
        rightMenu.push(manageMeetingObject);
//		}
		$scope.newMeetingMenuObject = { title:localizedMessages.get('NEW_MEETING'), click: createEvent, iconUrl: '', stateName: 'NewMeeting'};
		if(!MobileUtil.isSurface() && !MobileUtil.isAndroid()) {
			if(calendarService.isCurrentUserAllowedToCreateModule() == true){
				rightMenu.push($scope.newMeetingMenuObject);
				rightMenuNewMeetingView.push($scope.newMeetingMenuObject);
			}
			$scope.isEditEnabled = true;
		}

		$scope.rightMenuDefault = rightMenu;
		$scope.iconConf = 'resources/assets/svgs/bvMeetingEventListIcon.svg';
		//$scope.CONF = $rootScope.CONF;
		if($scope.EVENTS_LIST!=undefined && $scope.EVENTS_LIST!=null && $scope.EVENTS_LIST.EVENTS.length>0)
		{
			var showDefaultSelectedEvent = true;

			for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
			{
			    if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == $scope.EVENTS_LIST.SELECTED_EVENT)
				{
					showDefaultSelectedEvent = false;
                    $scope.getEventDetails($scope.EVENTS_LIST.EVENTS[i]);
					$scope.EVENTS_LIST.EVENTS[i].selected = true;
				}
				else
				{
					$scope.EVENTS_LIST.EVENTS[i].selected = false;
				}
			}
			if(showDefaultSelectedEvent)
			{
				showDefaultSelectedEvent = false;
				$scope.EVENTS_LIST.EVENTS[0].selected = true;
				$scope.getEventDetails($scope.EVENTS_LIST.EVENTS[0]);
			}
			updateRunningStateOfEvents();
		}
//		else
//		{
//			// var tabs = [
//			// 					{ title:localizedMessages.get('DAILY'),   content:'cards', 	  rightMenuDefault:$scope.rightMenuDefault,    func: goToDaily, active: true },
//			// 					{ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuDefault:rightMenu, 		 func: goToMonthly },
//			// 					{ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuDefault:$scope.rightMenuDefault,    func: goToTimeLine}
//			// 				];
//			//var rightMenuMonthly =   [{ title:localizedMessages.get('SAVE'), click: updateEvent,         svgFill: '#fff', iconUrl: ''}];
//
//			$scope.rightMenu = rightMenu;
//			$scope.tabs = $scope.getTabArray();
//			//$scope.tabs = tabs;
//		}

        $scope.rightMenu = rightMenu;
        $scope.rightMenuNewMeetingView = rightMenuNewMeetingView;
		$scope.tabs = $scope.getTabArray();
	}

	$scope.changeSelectedEvent = function (item)
    {
    	//console.log("changeSelectedEvent:",event);

    	if(!item.selected)
		{
		    if($scope.SELECTED_EVENT)
                $scope.SELECTED_EVENT.lastUpdatedUiVal = '';

            $scope.EVENT_DT_UI_TXT = $scope.UI_YOU_ARE_TXT;
            $scope.eventDtTxt = '';
			// if($scope.TABS_BEFORE_RIGHT_CLICK !=undefined)
   //  		{
   //  			$scope.tabs = $scope.TABS_BEFORE_RIGHT_CLICK ;
   //  		}

    		for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
			{
			    if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == item.objectBaseUuid)
				{
					$scope.EVENTS_LIST.EVENTS[i].selected = true;
				}
				else
				{
					$scope.EVENTS_LIST.EVENTS[i].selected = false;
				}
			}
    		$scope.getEventDetails(item);
       }

    }

    $scope.getEventDetails = function (item)
    {
    	if(!item)
    	{
    		$scope.rightMenu = $scope.rightMenuDefault;
			$scope.tabs = $scope.getTabArray();
			return;
    	}
    	
    	var isEventOwner = item.isEventOwner;
    	//console.log("Get selectedEvent:",item);
    	if(!$scope.CLICK_ON_AGENDA)
    	{
    		$scope.CONF_CURRENT_UI_CONTENT = "";
    	   	//console.log("STRUCTURE11:",$scope.CONF_CURRENT_UI_CONTENT);
    	}
    	$scope.CLICK_ON_AGENDA = false;

    	//if($scope.SELECTED_EVENT==undefined || $scope.SELECTED_EVENT.objectBaseUuid!=item.objectBaseUuid)
    	//{
    		//$scope.SELECTED_EVENT = item;
    		//calendarService.getCalendarEventAttachments(item.objectBaseUuid).then(function(data)
			//{
				//if(data)
				//{
				 	//item.documentVOList = data.data ;
				 	//console.log("ATTACHMENTS:",data.data);
				 	//NOW--var itemDocuments = data.data;
				 	calendarService.loadGuestandResponseForEvent(item.objectBaseUuid).then(function(data)
					{
						if(data)
						{
							item = data.data;
							///////console.log("EVENT:",data.data);
							console.log("GUEST RESPONSE EVENT:",data.data);
							var guests = data.data.guests;
							var eventResponseList = data.data.eventResponseList;

							//bvSliderService.currentIndex = 0;

							for(var i=0; i < guests.length; i++)
							{
								for(var k=0; k < eventResponseList.length; k++)
								{
									if(guests[i].objectBaseUuid == eventResponseList[k].createdByBaseUuid)
									{
										guests[i].response = eventResponseList[k].response.toLowerCase();
									}
								 }
							}
							item.guests = guests;
							//NOW--item.documentVOList = itemDocuments;
							if(isEventOwner==null || isEventOwner==undefined)
							{
								item.isEventOwner = item.isEventOwner;
							}
							else
							{
								item.isEventOwner = isEventOwner;
							}
							//NOW--$scope.sliderOptions.items = itemDocuments;
							//NOW--$scope.noMeetingAttachments = itemDocuments.length == 0 ? true : false;
							$scope.sliderOptions.items = item.documentVOList;
							$scope.noMeetingAttachments = item.documentVOList == null || item.documentVOList.length == 0 ? true : false;
							$scope.msg_no_meeting_attachments = localizedMessages.get('MSG_NO_MEETING_ATTACHMENTS');

							//console.log("PARENT:",$scope.$parent);

							// $scope.tabs = [
						 //               { title:'Details', rightMenuDefault:rightMenuDefault}
							// ];
							$scope.tabs = $scope.getTabsForEventView(item);

							getLastUpdateTime(item);

							$scope.SELECTED_EVENT = item;
							// console.log("CURRENT SELECTED ITEM:",item);
							//return item;
							if(!legacyUserService.isNasdaqUI){
								$timeout(function(){
									bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptions.id]
									bvSliderService.currentIndex = 0;
									bvSliderService.fixButtons();
								});
							}
							
							if (item.isUnread){
								calendarService.updateEventInvitiesReadOn(item.objectBaseUuid).then(function(data)
								{
					    			homeService.getMeetingsCount()
					    			.then(function(data)
					    			{
					    				$scope.meetingItems.value = data;
					    			});
								});
							}
						}
					});
				//}
			//});
    	//}
    }

    var clearRightClickSelection = function()
    {
    	if($scope.TABS_BEFORE_RIGHT_CLICK!=undefined)
    	{
    		$scope.tabs = $scope.TABS_BEFORE_RIGHT_CLICK;
    	}
    	var eventSelected = false;
    	for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
		{
			//console.log("EVENTS_ID:",$scope.EVENTS_LIST.EVENTS[i].objectBaseUuid," SELECTED EVENT:",$scope.SELECTED_EVENT.objectBaseUuid);
		    if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == $scope.SELECTED_EVENT.objectBaseUuid)
			{
				$scope.EVENTS_LIST.EVENTS[i].selected = true;
				eventSelected = true;
			}
			else
			{
				$scope.EVENTS_LIST.EVENTS[i].selected = false;
			}
		}
		if(!eventSelected && $scope.EVENTS_LIST.EVENTS.length > 0)
		{
			$scope.EVENTS_LIST.EVENTS[0].selected = true;
		}
    }

    $scope.toggleEventOptionDropdown = function($event, isEventSelected)
    {
    	if(isEventSelected)
        {
        	$event.preventDefault();
        	$event.stopPropagation();
        }
    };

    $scope.showUserForAlert = function (calEvent)
    {
    	//console.log("calEvent:",calEvent);
    	$scope.SELECTED_USERS_TO_ALERT = [];
    	var usersToAlert = angular.copy($scope.SELECTED_EVENT.guests);
    	var selectUserForALertMsg = localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER');
    	var cancelSelectionForAlertMsg = localizedMessages.get('MSG_ALERT_MEETINGS_CANCEL_ALERT_AFTER_SELECTION');
    	//bvDialog.itemListSelection($scope.UI_ALERT, usersToAlert, alertUserSelectionDone,  true, true, true, $scope.UI_SEND, null, true, cancelSelectionForAlertMsg);
    	$scope.ALERT_DLG = bvDialog.showAlertUsers($scope.UI_ALERT, $scope.UI_USERS, usersToAlert, userSelectionDone, $scope.UI_CANCEL, $scope.UI_SEND, cancelSelectionForAlertMsg, saveAlertAction, cancelAlertAction);
    	//bvDialog.itemListSelection($scope.UI_ALERT, $scope.SELECTED_EVENT.guests,alertUserSelectionDone, true, true);
    }

    var closeAlertDlg = function()
	{
		console.log("IN HERE:",$scope.ALERT_DLG)
		$scope.ALERT_DLG.close();
	}

	var userSelectionDone = function (selectedUsers)
    {
    	$scope.SELECTED_USERS_TO_ALERT = selectedUsers;
    }

	var saveAlertAction = function()
	{
		if($scope.SELECTED_USERS_TO_ALERT.length <= 0)
		{
			bvDialog.showMessage(localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER'));
		}
		else
		{
			alertUserSelectionDone($scope.SELECTED_USERS_TO_ALERT);
			closeAlertDlg();
		}
	}

	var cancelAlertAction = function()
	{
		if($scope.SELECTED_USERS_TO_ALERT.length > 0)
		{
			bvDialog.confirm(localizedMessages.get('MSG_ALERT_MEETINGS_CANCEL_ALERT_AFTER_SELECTION'), closeAlertDlg, null);
		}
		else
		{
			closeAlertDlg();
		}
	}

	var alertUserSelectionDone = function (selectedUsersToAlert)
    {
    	if(selectedUsersToAlert)
    	{
    		//console.log("SENDING ALERT for USERS: ",selectedUsersToAlert)
	    	var userIds = [];
	        for (var i = 0; i < selectedUsersToAlert.length; i++)
			{
			   	userIds.push(selectedUsersToAlert[i].objectBaseUuid);
			}
	    	calendarService.sendEventAlert( $scope.SELECTED_EVENT.objectBaseUuid,angular.toJson(userIds));
	    }
    }

    $scope.refreshEventsAfterTimeChange = function (eventsAfterTimeChange)
    {
    	/*var sort = function(a,b)
		{
	    	var r = a.startDateGMTMillis;
		   	var p = b.startDateGMTMillis;
		   	if(p>r){return -1;}
		   	else if(r>p){return 1;}
		   	return 0;
		}*/
		//eventsAfterTimeChange.sort(sort);
		eventsAfterTimeChange.sort(BV_SORT_FUNC_MEETING('startDateGMTMillis', {
			    name: 'createdOnGMTMillis',
			    primer: parseInt,
			    reverse: false
		}));
		if($scope.TABS_BEFORE_RIGHT_CLICK!=undefined)
		{
			$scope.tabs = $scope.TABS_BEFORE_RIGHT_CLICK;
		}
		$scope.EVENTS_LIST.EVENTS = eventsAfterTimeChange;
		if($scope.TABS_BEFORE_RIGHT_CLICK!=undefined)
    	{
    		$scope.tabs = $scope.TABS_BEFORE_RIGHT_CLICK;
    	}
    	var eventSelected = false;
    	var selecedItemAfterTimeChange = null;

		for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
		{
			//console.log("EVENTS_ID:",$scope.EVENTS_LIST.EVENTS[i].objectBaseUuid," SELECTED EVENT:",$scope.SELECTED_EVENT.objectBaseUuid);
		    if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == $scope.SELECTED_EVENT.objectBaseUuid)
			{
				$scope.EVENTS_LIST.EVENTS[i].selected = true;
				eventSelected = true;
				selecedItemAfterTimeChange = $scope.EVENTS_LIST.EVENTS[i];
			}
			else
			{
				$scope.EVENTS_LIST.EVENTS[i].selected = false;
			}

		    $scope.EVENTS_LIST.EVENTS[i].isActive = ($scope.EVENTS_LIST.EVENTS[i].isActive == "true")?true:false
		}
		if(!eventSelected && $scope.EVENTS_LIST.EVENTS.length > 0)
		{
			$scope.EVENTS_LIST.EVENTS[0].selected = true;
			selecedItemAfterTimeChange = $scope.EVENTS_LIST.EVENTS[0];
		}
		if(selecedItemAfterTimeChange!=null)
		{
			//$scope.getEventDetails($scope.SELECTED_EVENT);
			$scope.getEventDetails(selecedItemAfterTimeChange);
		}
		else
		{
			$scope.SELECTED_EVENT = null;
		}
    }

    $scope.refreshSelectedEventAfterAttendeeChange = function()
    {
    	$scope.getEventDetails($scope.SELECTED_EVENT);
		clearRightClickSelection();
    }

    $scope.getDisplayTime = function (calEvent)
	{
		if(calEvent!=undefined)
		{
			var startTime = moment(parseInt(calEvent.startDateGMTMillis)).format('LT');
			var endTime = moment(parseInt(calEvent.endDateGMTMillis)).format('LT');
			return startTime + " - " + endTime;
		}
	}

	function getLastUpdateTime(item){
        try
        {
            var dtStr = item.lastModifiedOnGMT + " GMT";
            var lcDate = new Date(dtStr);
            var lastUpdatedVal = moment(lcDate).format('ll');//lcDate.toLocaleString();
            item.lastUpdatedUiVal = lastUpdatedVal;
        }
        catch(err)
        {
            item.lastUpdatedUiVal =  moment(item.lastModified).format('ll');
        }

        return item;
    }


	var goToLatestSlide = function()
	{

	}

	var createEvent = function ()
	{
		if(MobileUtil.isApp() && MobileUtil.isJupOffline())
		{
			return;
		}

		//var rightMenuMonthly =   [{ title:localizedMessages.get('SAVE'), click: updateEvent,         svgFill: '#fff', iconUrl: ''}];
		// var tabs = [
		// 		{ title:localizedMessages.get('DAILY'),   content:'cards', 	  rightMenuDefault:$scope.rightMenuDefault,    func: goToDaily },
		// 		{ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuDefault:rightMenuMonthly, 		 func: goToMonthly, active: true },
		// 		{ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuDefault:$scope.rightMenuDefault,    func: goToTimeLine}
		// ];
		// $scope.tabs = tabs;
		// var rightMenu =   [
		// 					{ title:localizedMessages.get('CANCEL'), click: cancelCreateEvent,         svgFill: '#fff', iconUrl: ''},
		// 					{ title:localizedMessages.get('SAVE'), click: updateEvent,         svgFill: '#fff', iconUrl: ''}];
		var rightMenu =   [];

		$scope.rightMenu = rightMenu;
		$scope.tabs = $scope.getTabArray();

		$scope.MEETING_OBJECT = modelService.getMeetingCenterObject();
		if($scope.EVENTS_LIST!=undefined && $scope.EVENTS_LIST.DATE!=undefined)
		{
			$scope.MEETING_OBJECT.startingDate= moment($scope.EVENTS_LIST.DATE).format('ll');
			$scope.MEETING_OBJECT.startingDateLocal= $scope.EVENTS_LIST.DATE;
			var timeNow = new Date();
			timeNow.setMilliseconds(Math.round(timeNow.getMilliseconds() / 1000) * 1000);
    		timeNow.setSeconds(Math.round(timeNow.getSeconds() / 60) * 60);
    		timeNow.setMinutes(Math.round(timeNow.getMinutes() / 30) * 30);
			$scope.MEETING_OBJECT.startingTime          = moment(timeNow).format('hh:mm A');
			$scope.MEETING_OBJECT.startingTimeLocal     = StringUtils.momentL(timeNow).format('hh:mm A');
			timeNow.setMinutes(timeNow.getMinutes() + 30);
			$scope.MEETING_OBJECT.endingTime            = moment(timeNow).format('hh:mm A');
			$scope.MEETING_OBJECT.endingTimeLocal       = StringUtils.momentL(timeNow).format('hh:mm A');
		}
		var attendeeObj = {
			objectBaseUuid		: context.getModel().userVO.objectBaseUuid,
			name				: context.getModel().userVO.name,
			isPresenter			:false,
			isOwner				:true,
			isOnBehalf			:true,
			response			:'accepted',
		};
		$scope.MEETING_OBJECT.attendeesList.push(attendeeObj);

		$scope.showNewOrEditMeeting = true;

		$scope.tileCheckBox.show = $scope.showNewOrEditMeeting;

		$scope.tileCheckBox.active = $scope.MEETING_OBJECT.isActive;

		$scope.middleBarObject.showBack = false;

		$scope.MEETING_OBJECT.host = $scope.currentUser.objectBaseUuid;
	}

	var updateEvent = function ()
	{
		if($scope.CREAT_OR_EDIT_FLAG)
		{
			return;
		}
		else
		{
			$scope.CREAT_OR_EDIT_FLAG = true;
		}
		//console.log("MEETING OBJECT:",$scope.MEETING_OBJECT);
		$scope.middleBarObject.showBack = true;
		var meetingController = angular.element(document.querySelector('[bvMeetingEditViewDiv]')).scope();
		meetingController.save();
    }

    var cancelCreateEvent = function()
    {
    	$scope.middleBarObject.showBack = true;
    	$scope.CREAT_OR_EDIT_FLAG = false;
    	//$scope.close();
    }

//    $scope.MEETING_OBJECT = meetingreactInterface.callReact.getMeeting()
    
	//var editEvent = function ()
	$scope.editEvent = function (calEvent, eventSelected)
	{
		if(eventSelected && calEvent.isEventOwner)
		{
			homeService.set_current_module_has_unsaved_changes(true, ctrlName);
			$scope.SELECTED_EVENT_ATTACHMENTS_BEFORE_EDIT = angular.copy($scope.SELECTED_EVENT.documentVOList);
			$scope.MEETING_OBJECT = modelService.getMeetingCenterObject();
			$scope.MEETING_OBJECT.editFlag              = true;
			$scope.MEETING_OBJECT.meetingTitle          = $scope.SELECTED_EVENT.name;
			$scope.MEETING_OBJECT.meetingObjectBaseUuid = $scope.SELECTED_EVENT.objectBaseUuid;
			$scope.MEETING_OBJECT.startingDate          = moment($scope.SELECTED_EVENT.startDateGMTMillis).format('ll');
			$scope.MEETING_OBJECT.startingDateLocal     = StringUtils.momentL($scope.SELECTED_EVENT.startDateGMTMillis).format('ll');
			
			$scope.MEETING_OBJECT.endingDate          	= moment($scope.SELECTED_EVENT.endDateGMTMillis).format('ll');
			$scope.MEETING_OBJECT.endingDateLocal    	= StringUtils.momentL($scope.SELECTED_EVENT.endDateGMTMillis).format('ll');

			$scope.MEETING_OBJECT.startingTime          = moment($scope.SELECTED_EVENT.startDateGMTMillis).format('hh:mm A');
			$scope.MEETING_OBJECT.endingTime            = moment($scope.SELECTED_EVENT.endDateGMTMillis).format('hh:mm A');

			$scope.MEETING_OBJECT.startingTimeLocal     = StringUtils.momentL($scope.SELECTED_EVENT.startDateGMTMillis).format('hh:mm A');
			$scope.MEETING_OBJECT.endingTimeLocal       = StringUtils.momentL($scope.SELECTED_EVENT.endDateGMTMillis).format('hh:mm A');

			$scope.MEETING_OBJECT.meetingAddress        = ($scope.SELECTED_EVENT.address==undefined || $scope.SELECTED_EVENT.address==null) ? "" : $scope.SELECTED_EVENT.address;
			$scope.MEETING_OBJECT.meetingDetails        = ($scope.SELECTED_EVENT.description==undefined || $scope.SELECTED_EVENT.description==null) ? "" : $scope.SELECTED_EVENT.description;
			$scope.MEETING_OBJECT.meetingCallInNumber   = ($scope.SELECTED_EVENT.conferenceNumber==undefined || $scope.SELECTED_EVENT.conferenceNumber==null) ? "" : $scope.SELECTED_EVENT.conferenceNumber;
			$scope.MEETING_OBJECT.attendeesList         = getAttendeesList();
			$scope.MEETING_OBJECT.attachmentsList       = $scope.SELECTED_EVENT.documentVOList;
			$scope.MEETING_OBJECT.isActive				= $scope.SELECTED_EVENT.isActive;
			$scope.MEETING_OBJECT.allDay				= $scope.SELECTED_EVENT.allDay;
			$scope.MEETING_OBJECT.location				= $scope.SELECTED_EVENT.location;
			$scope.MEETING_OBJECT.codeNumber			= $scope.SELECTED_EVENT.codeNumber || '';
			$scope.MEETING_OBJECT.guests				= $scope.SELECTED_EVENT.guests;
			$scope.MEETING_OBJECT.isCreator				= $scope.SELECTED_EVENT.isCreator;
			$scope.MEETING_OBJECT.createdByBaseUuid		= $scope.SELECTED_EVENT.createdByBaseUuid;
			$scope.MEETING_OBJECT.host 					= $scope.SELECTED_EVENT.host;
			// var rightMenu =   [
			// 				{ title:localizedMessages.get('CANCEL'), click: cancelEvent,  svgFill: '#fff', iconUrl: ''},
			// 				{ title:localizedMessages.get('DONE'),   click: updateEvent,  svgFill: '#fff', iconUrl: ''}
			// 			];
			var rightMenu =   [];
			$scope.rightMenu = rightMenu;
			$scope.tabs = $scope.getTabArray();
			$scope.showNewOrEditMeeting = true;
			$scope.middleBarObject.showBack = false;

			$scope.tileCheckBox.show = $scope.showNewOrEditMeeting;
			$scope.tileCheckBox.active = $scope.SELECTED_EVENT.isActive;
			//console.log("SELECTED EVENT BEFORE:",$scope.SELECTED_EVENT);
		}
		//meetingSaveUpdateCallback" meeting-obj="MEETING_OBJECT
	}
	
	$scope.copyEvent = function (calEvent, eventSelected)
	{
		if(eventSelected && calEvent.isEventOwner)
		{
			$scope.SELECTED_EVENT_ATTACHMENTS_BEFORE_EDIT = angular.copy($scope.SELECTED_EVENT.documentVOList);
			$scope.SELECTED_EVENT.isActive              = false;
			$scope.MEETING_OBJECT = modelService.getMeetingCenterObject();
			$scope.MEETING_OBJECT.editFlag              = false;
			$scope.MEETING_OBJECT.meetingTitle          = $scope.SELECTED_EVENT.name;
			$scope.MEETING_OBJECT.startingDate          = moment($scope.SELECTED_EVENT.startDateGMTMillis).format('ll');
			$scope.MEETING_OBJECT.startingDateLocal     = StringUtils.momentL($scope.SELECTED_EVENT.startDateGMTMillis).format('ll');
			
			$scope.MEETING_OBJECT.endingDate          	= moment($scope.SELECTED_EVENT.endDateGMTMillis).format('ll');
			$scope.MEETING_OBJECT.endingDateLocal    	= StringUtils.momentL($scope.SELECTED_EVENT.endDateGMTMillis).format('ll');

			$scope.MEETING_OBJECT.startingTime          = moment($scope.SELECTED_EVENT.startDateGMTMillis).format('hh:mm A');
			$scope.MEETING_OBJECT.endingTime            = moment($scope.SELECTED_EVENT.endDateGMTMillis).format('hh:mm A');

			$scope.MEETING_OBJECT.startingTimeLocal     = StringUtils.momentL($scope.SELECTED_EVENT.startDateGMTMillis).format('hh:mm A');
			$scope.MEETING_OBJECT.endingTimeLocal       = StringUtils.momentL($scope.SELECTED_EVENT.endDateGMTMillis).format('hh:mm A');

			$scope.MEETING_OBJECT.meetingAddress        = ($scope.SELECTED_EVENT.address==undefined || $scope.SELECTED_EVENT.address==null) ? "" : $scope.SELECTED_EVENT.address;
			$scope.MEETING_OBJECT.meetingDetails        = ($scope.SELECTED_EVENT.description==undefined || $scope.SELECTED_EVENT.description==null) ? "" : $scope.SELECTED_EVENT.description;
			$scope.MEETING_OBJECT.meetingCallInNumber   = ($scope.SELECTED_EVENT.conferenceNumber==undefined || $scope.SELECTED_EVENT.conferenceNumber==null) ? "" : $scope.SELECTED_EVENT.conferenceNumber;
			$scope.MEETING_OBJECT.attendeesList         = getAttendeesList();
			$scope.MEETING_OBJECT.attachmentsList       = $scope.SELECTED_EVENT.documentVOList;
			$scope.MEETING_OBJECT.allDay				= $scope.SELECTED_EVENT.allDay;
			$scope.MEETING_OBJECT.location				= $scope.SELECTED_EVENT.location;
			$scope.MEETING_OBJECT.codeNumber			= $scope.SELECTED_EVENT.codeNumber || '';
			$scope.MEETING_OBJECT.guests				= $scope.SELECTED_EVENT.guests;
			$scope.MEETING_OBJECT.isCreator				= $scope.SELECTED_EVENT.isCreator;
			$scope.MEETING_OBJECT.createdByBaseUuid		= $scope.SELECTED_EVENT.createdByBaseUuid;
			$scope.MEETING_OBJECT.host 					= $scope.SELECTED_EVENT.host;
			$scope.MEETING_OBJECT.isActive				= $scope.SELECTED_EVENT.isActive;

			var rightMenu =   [];
			$scope.rightMenu = rightMenu;
			$scope.tabs = $scope.getTabArray();
			$scope.showNewOrEditMeeting = true;
			$scope.middleBarObject.showBack = false;

			$scope.tileCheckBox.show = $scope.showNewOrEditMeeting;
			$scope.tileCheckBox.active = $scope.SELECTED_EVENT.isActive;
			
			meetingsService.initializeDatepicker($scope.MEETING_OBJECT);
			$scope.MEETING_OBJECT.autoOpen = true;
		}
	}

	// var cancelEvent = function ()
	// {
	// 	$scope.CREAT_OR_EDIT_FLAG = false;
	// 	$scope.middleBarObject.showBack = true;
	// 	console.log("MMMMM TRUE");
	// 	$scope.SELECTED_EVENT.documentVOList = $scope.SELECTED_EVENT_ATTACHMENTS_BEFORE_EDIT;
	// 	//$scope.close();
	// }

	$scope.toggleActive = function()
	{
		$scope.MEETING_OBJECT.isActive = !$scope.MEETING_OBJECT.isActive;

		$scope.tileCheckBox.active = $scope.MEETING_OBJECT.isActive;
	}

	var getAttendeesList = function()
	{
		var attendeesList = [];
		var AttendeeObj = function(objectBaseUuid, displayName, response, isAttend, isPresenter, isOwner, isOnBehalf){
			
			return {
				objectBaseUuid		: objectBaseUuid,
				name				: displayName,
				isAttend			: isAttend || false,
				isPresenter			: isPresenter || false,
				isOwner				: isOwner || false,
				isOnBehalf			: isOnBehalf || false,
				response			: response,
				//encodedUserThumbnail:profileImage
			}
		}
		
		//console.log("SELECTED_EVENT:",$scope.SELECTED_EVENT);
		for(var k = 0; k < $scope.SELECTED_EVENT.guests.length; k++)
		{
			//Guest object
			var guestObj = $scope.SELECTED_EVENT.guests[k];
			var attendeeObj = new AttendeeObj(guestObj.objectBaseUuid, guestObj.displayName, guestObj.response, true);
			//Check if guest obj contains in presenters
			if($scope.SELECTED_EVENT.presenters != undefined && $scope.SELECTED_EVENT.presenters.indexOf(guestObj.objectBaseUuid) > -1){
				attendeeObj.isPresenter = true;
			}

			attendeesList.push(attendeeObj);
		}
		
		//Check if guest obj contains in owners
		if($scope.SELECTED_EVENT.owners != undefined && $scope.SELECTED_EVENT.owners.length > 0){

			_.each($scope.SELECTED_EVENT.owners, function(owner){
				var user = _.find(attendeesList, { objectBaseUuid : owner.objectBaseUuid });
				
				if(user){
					user.isOwner = true;
				}
				else{
					var attendeeObj = new AttendeeObj(owner.objectBaseUuid, owner.displayName, owner.response, null, null, true);
					attendeesList.push(attendeeObj);
				}
			});
//			for(m in $scope.SELECTED_EVENT.owners){
//				if($scope.SELECTED_EVENT.owners[m].objectBaseUuid == attendeeObj.objectBaseUuid){
//					attendeeObj.isOwner = true;
//					break;
//				}
//				
//				if(!_.find($scope.SELECTED_EVENT.guests, { objectBaseUuid : $scope.SELECTED_EVENT.owners[m].objectBaseUuid })){
//					attendeesList.push($scope.SELECTED_EVENT.owners[m]);
//				}
//			}
		}
		attendeesList.sort(function(a, b){
			return a.name.localeCompare(b.name);
		   
		});
		console.log(attendeesList);
		return attendeesList;
	}

	$scope.meetingCancelCallback = function ()
	{
		$scope.tabs = $scope.getTabsForEventView($scope.SELECTED_EVENT);
		$scope.middleBarObject.showBack = true;
		$scope.showNewOrEditMeeting = false;

		$scope.tileCheckBox.show = $scope.showNewOrEditMeeting;
	}

	$scope.meetingSaveUpdateCallback = function (updatedCalendarEvent)
	{
		//TODO - After edit if event date changes then remove event from selection.
		$scope.CREAT_OR_EDIT_FLAG = false;
		var editedDate = moment(parseInt(updatedCalendarEvent.startDateGMTMillis)).format('ll');
		//console.log("editedDate:",editedDate, " scopeDT:",$scope.EVENTS_LIST.DATE);

		$scope.middleBarObject.title =  ($scope.EVENTS_LIST!=undefined && $scope.EVENTS_LIST.DATE!=undefined) ? $scope.EVENTS_LIST.DATE : "";
		$scope.showNewOrEditMeeting = false;

		$scope.tileCheckBox.show = $scope.showNewOrEditMeeting;

		$scope.middleBarObject.showBack = true;
		var eventBelongsToDate = true;
		if(editedDate!=$scope.EVENTS_LIST.DATE)
		{
			//Event doenn't belong to date...remove it...
			var getTabs = false;
			for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
			{
		    	if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == updatedCalendarEvent.objectBaseUuid)
				{
					$scope.EVENTS_LIST.EVENTS.splice(i,1);
					//console.log("EVENTS:",$scope.EVENTS_LIST.EVENTS)
					if($scope.EVENTS_LIST.EVENTS.length > 0)
					{
						i = (i>=$scope.EVENTS_LIST.EVENTS.length) ? i-1 : i;
						//console.log("I:",i);
						$scope.EVENTS_LIST.EVENTS[i].selected = true;
						$scope.getEventDetails($scope.EVENTS_LIST.EVENTS[i]);
						getTabs = true;
					}
					else
					{
						$scope.SELECTED_EVENT = null;

					}
					break;
				}
			}
			if(!getTabs)
			{
				var rightMenu = [];
				if(!MobileUtil.isSurface() && !MobileUtil.isAndroid()) {
					if(calendarService.isCurrentUserAllowedToCreateModule() == true)
						rightMenu.push($scope.newMeetingMenuObject);
				}

				$scope.rightMenu = rightMenu;
				$scope.tabs = $scope.getTabArray();
			}
		}
		else
		{
			var guests = updatedCalendarEvent.guests;
			var eventResponseList = updatedCalendarEvent.eventResponseList;
			guests.forEach(guest => {
				eventResponseList.forEach(event => {
					if(guest.objectBaseUuid == event.createdByBaseUuid)
					{
						guest.response = event.response.toLowerCase();
					}
				})
			})
			updatedCalendarEvent.guests = guests;
			var mm = moment.utc(updatedCalendarEvent.lastModifiedOnGMT, "'MMM D, YYYY hh:mm:ss A'");
			updatedCalendarEvent.createdOnGMTMillis = parseInt(mm.valueOf());

			//updatedCalendarEvent.startDateGMTMillis = parseInt(updatedCalendarEvent.startDateGMTMillis);

			if($scope.MEETING_OBJECT!=undefined && $scope.MEETING_OBJECT.editFlag!=undefined && !$scope.MEETING_OBJECT.editFlag)
			{
				$scope.EVENTS_LIST.EVENTS.push(updatedCalendarEvent);
			}
			for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
			{
			    if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == updatedCalendarEvent.objectBaseUuid)
				{
					if(updatedCalendarEvent.isEventOwner==undefined)
					{
						updatedCalendarEvent.isEventOwner = $scope.EVENTS_LIST.EVENTS[i].isEventOwner;
					}
					$scope.EVENTS_LIST.EVENTS[i] = updatedCalendarEvent;
					$scope.EVENTS_LIST.EVENTS[i].selected = true;
				}
				else
				{
					$scope.EVENTS_LIST.EVENTS[i].selected = false;
				}
			}
			// var sort = function(a,b)
			// {
			//    	var r = a.startDateGMTMillis;
			//    	var p = b.startDateGMTMillis;
			//    	if(p>r){return -1;}
			//    	else if(r>p){return 1;}
			//    	return 0;
			// }
			// $scope.EVENTS_LIST.EVENTS.sort(sort);
			$scope.EVENTS_LIST.EVENTS.sort(BV_SORT_FUNC_MEETING('startDateGMTMillis', {
			    name: 'createdOnGMTMillis',
			    primer: parseInt,
			    reverse: false
			}));

			//Returned date doesn't have all the details
			$scope.getEventDetails(updatedCalendarEvent);
			//$scope.SELECTED_EVENT = updatedCalendarEvent;
			//console.log("UPDATED CALENDAR EVENT AFTER EDIT:",updatedCalendarEvent);
			$scope.tabs = $scope.getTabsForEventView($scope.SELECTED_EVENT);
		}

	}

 	var handleJoinAction = function ()
 	{
 		var objectBaseUuid = ($scope.SELECTED_EVENT!=undefined) ? $scope.SELECTED_EVENT.objectBaseUuid : null;
 		if(objectBaseUuid!=null && objectBaseUuid!="")
 		{
 			conferenceService.joinConferenceJSON(objectBaseUuid).then(function(data)
			{
				openPresentationView(null);
			});
 		}
 		else
	 	{
	 		bvDialogService.showMcAlertMessage(""+$scope.ERR_EVENT_DETAILS);
	 	}
 	}

	var handlePresentAction = function ()
 	{
 		var objectBaseUuid = ($scope.SELECTED_EVENT!=undefined) ? $scope.SELECTED_EVENT.objectBaseUuid : null;
 		if(objectBaseUuid!=null && objectBaseUuid!="")
 		{
 			conferenceService.getConferenceParticipantsJSON(objectBaseUuid).then(function(data)
	 		{
	 			//$scope.CONF.CONF_PARTICIPANTS = data.data;
		 		conferenceService.startConferenceJSON(objectBaseUuid).then(function(data)
		 		{
		 			//$scope.CONF.CONF_ATTACHMENTS = data.data;
		 			openPresentationView($scope.SELECTED_EVENT.documentVOList); // data.data);
		 		});
	 		});
	 	}
	 	else
	 	{
	 		bvDialogService.showMcAlertMessage(""+$scope.ERR_EVENT_DETAILS);
	 	}
 	}

 	var openPresentationView = function (attachments)
 	{
 		var objectBaseUuid = ($scope.SELECTED_EVENT!=undefined) ? $scope.SELECTED_EVENT.objectBaseUuid : null;
 		conferenceService.getConferenceParticipantsJSON(objectBaseUuid).then(function(data)
	 	{
	 		var CONF = modelService.getInitialConferenceObject();
	 		var item = $scope.SELECTED_EVENT;
	 		//console.log("SELECTED EVENT ITEM:",item);
	 		CONF.CONF_EVENT = item;
	 		CONF.CONF_PARTICIPANTS = data.data;

	 		CONF.CONF_ATTACHMENTS = (attachments!=null) ? attachments : item.documentVOList;
	 		CONF.CONF_CONTAINS_ATTACHMENT = (CONF.CONF_ATTACHMENTS!=undefined && CONF.CONF_ATTACHMENTS!=null && CONF.CONF_ATTACHMENTS.length > 0) ? true : false;

	 		//CONF.CONF_CONTAINS_AGENDA = (item.agendaJson!=undefined && item.agendaJson.length > 0) ? true : false;

	 		var contextObjectBaseUuid = context.getModel().userVO.objectBaseUuid;
			//If current user is host then
			if(contextObjectBaseUuid === item.host)
		 	{
		 		CONF.CONF_IS_CONF_HOST = true;
		 		CONF.CONF_IS_CONF_PRESENTER = true;
		 		CONF.CONF_CURRENT_PRESENTER = contextObjectBaseUuid;
		 		CONF.CONF_UI_PRESENT_EXIT_BTN_ACTION_TXT = CONF.CONF_UI_STOP_BTN_TXT;
		 	}
		 	else
		 	{
		 		if(item.presenters != undefined && item.presenters.indexOf(contextObjectBaseUuid) > -1)
				{
					CONF.CONF_IS_CONF_PRESENTER = true;
					CONF.CONF_CURRENT_PRESENTER = contextObjectBaseUuid;
					CONF.CONF_UI_PRESENT_EXIT_BTN_ACTION_TXT = CONF.CONF_UI_STOP_BTN_TXT;
				}
				else
				{
					CONF.CONF_IS_CONF_PRESENTER = false;
					CONF.CONF_CURRENT_PRESENTER = "";
					CONF.CONF_UI_PRESENT_EXIT_BTN_ACTION_TXT = CONF.CONF_UI_LEAVE_BTN_TXT;
		 		}
		 		CONF.CONF_IS_CONF_HOST = false;
			}

		 	$rootScope.CONF = CONF;

		 	$rootScope.CURR_PRESENT_DLG = new modal.open({
	 			windowClass  : 'bv-conf-present-doc-dialog',
				templateUrl  : 'modules/conference/ConfPrsntProducer.tpl.html',
				controller   : 'ConfPrsntProducerCntrl',
	        	scope: $scope,
			});
	 	});
 	}

	//var recycleEvent = function ()
    $scope.recycle = function ()
    {
    	var objectBaseUuid = $scope.SELECTED_EVENT.objectBaseUuid;
    	
    	calendarService.recycleIntive(objectBaseUuid).then(function(data)
    	{
    		for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
			{
		    	if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == $scope.SELECTED_EVENT.objectBaseUuid)
				{
					$scope.EVENTS_LIST.EVENTS.splice(i,1);
					//console.log("EVENTS:",$scope.EVENTS_LIST.EVENTS)
					if($scope.EVENTS_LIST.EVENTS.length > 0)
					{
						i = (i>=$scope.EVENTS_LIST.EVENTS.length) ? i-1 : i;
						//console.log("I:",i);
						$scope.EVENTS_LIST.EVENTS[i].selected = true;
						$scope.getEventDetails($scope.EVENTS_LIST.EVENTS[i]);
					}
					else
					{
						$scope.SELECTED_EVENT = null;
						var rightMenu = [];
						if(!MobileUtil.isSurface() && !MobileUtil.isAndroid() && calendarService.isCurrentUserAllowedToCreateModule() == true) {
							rightMenu.push($scope.newMeetingMenuObject);
						}

						$scope.rightMenu = rightMenu;
						$scope.tabs = $scope.getTabArray();
						if(!legacyUserService.isNasdaqUI){
							bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptions.id];
							bvSliderService.fixScroll();
						}
					}
					break;
				}
			}
    		
    	});
    }
    
    $scope.openDeletionAlertDialog = function() {
		var name = $scope.SELECTED_EVENT.name;
		var users = $scope.SELECTED_EVENT.guests;
		$scope.selectedUsersToAlert = [];
	    
	    var cancelSelectionForAlertMsg = localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION');
	    	$scope.alertDlg = bvDialog.showAlertUsers(
	    			localizedMessages.get('ALERT') + ": " + name,
	    			localizedMessages.get('USERS'), 
	    			users, 
	    			userSelectDone, 
	    			localizedMessages.get('CANCEL'), 
	    			localizedMessages.get('SEND'), 
	    			cancelSelectionForAlertMsg, 
	    			openComposeDeletionAlertWindow, 
	    			cancelDeletionAlertAction
	    		); 
	}
    
    var userSelectDone = function (selectedUsersToAlert)
    {
    	$scope.selectedUsersToAlert = selectedUsersToAlert;
    }
    
    var cancelDeletionAlertAction = function()
	{
		if($scope.selectedUsersToAlert.length > 0)
		{
			bvDialog.confirm(localizedMessages.get('MSG_ALERT_CANCEL_ALERT_AFTER_SELECTION'), closeAlertDlgM, null);
		}
		else
		{
			closeAlertDlgM();
			$scope.recycle();
		}
		
	}
    
    var closeAlertDlgM = function() {
		$scope.alertDlg.close();
	}
    
    var openComposeDeletionAlertWindow = function() 
	{
		var selectedUsers = $scope.selectedUsersToAlert;
			var userIds = [];
			var name = $scope.SELECTED_EVENT.title != undefined ? $scope.SELECTED_EVENT.title : $scope.SELECTED_EVENT.name;
			if(selectedUsers && selectedUsers.length !=0 && selectedUsers != undefined && selectedUsers != null) {
				for(var i=0;i<selectedUsers.length;i++){
					userIds.push(selectedUsers[i].objectBaseUuid);
				}
			}
			calendarService.deleteAndSendAlertForMeetingCenterCalendarEvent($scope.SELECTED_EVENT.objectBaseUuid,angular.toJson(userIds)).then(function(data) 
			{
				for (var i = 0; i < $scope.EVENTS_LIST.EVENTS.length; i++)
				{
			    	if($scope.EVENTS_LIST.EVENTS[i].objectBaseUuid == $scope.SELECTED_EVENT.objectBaseUuid)
					{
						$scope.EVENTS_LIST.EVENTS.splice(i,1);
						//console.log("EVENTS:",$scope.EVENTS_LIST.EVENTS)
						if($scope.EVENTS_LIST.EVENTS.length > 0)
						{
							i = (i>=$scope.EVENTS_LIST.EVENTS.length) ? i-1 : i;
							//console.log("I:",i);
							$scope.EVENTS_LIST.EVENTS[i].selected = true;
							$scope.getEventDetails($scope.EVENTS_LIST.EVENTS[i]);
						}
						else
						{
							$scope.SELECTED_EVENT = null;
							var rightMenu = [];
							if(!MobileUtil.isSurface() && !MobileUtil.isAndroid() && calendarService.isCurrentUserAllowedToCreateModule() == true) {
								rightMenu.push($scope.newMeetingMenuObject);
							}

							$scope.rightMenu = rightMenu;
							$scope.tabs = $scope.getTabArray();
							if(!legacyUserService.isNasdaqUI){
								bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptions.id];
								bvSliderService.fixScroll();
							}
						}
						break;
					}
				}
			});
		// }
	};
	

    $scope.recycleEvent = function ()
 	{
    	var objectBaseUuid = $scope.SELECTED_EVENT.objectBaseUuid;
    	var confirmDeleteMsg = localizedMessages.get('MSG_ARE_YOU_SURE_TO_DELETE_THIS_MEETING');
    	if(objectBaseUuid!=undefined)
    	{
    		//MEETX-2904 comment out as it is Jan release.
    		bvDialog.confirm(confirmDeleteMsg, $scope.recycleAlert, null);
    		if(legacyUserService.isNasdaqUI)
    			$state.go('home.meetings')
//    		bvDialog.confirm(confirmDeleteMsg, $scope.recycle, null);
    	}
    };
    
    $scope.recycleAlert = function()
    {
    	var recycleAlertMsg = localizedMessages.get('SEND_MEETING_RECYCLE_ALERT');
    	bvDialog.confirm(recycleAlertMsg, $scope.openDeletionAlertDialog, $scope.recycle);
    }

    $scope.handleLeftIcon = function()
	{
		$scope.close();
	}

	$scope.close = function(result)
  	{
  		/*if($scope.CONF!=undefined && $scope.CONF.CONF_IS_CONF_IN_PROGRESS!=undefined && $scope.CONF.CONF_IS_CONF_IN_PROGRESS)
  		{
  			$scope.CONF.CONF_IS_CONF_IN_PROGRESS = false;
  		}*/

  		// if($scope.showNewOrEditMeeting)
  		// {
  		// 	$scope.tabs = $scope.getTabsForEventView($scope.SELECTED_EVENT);
  		// 	$scope.showNewOrEditMeeting = false;
  		// 	$scope.middleBarObject.showBack = true;
  		// }
  		// else
  		// {
  		// 	//$scope.middleBarObject.showBack = false;
  		// 	$state.go($rootScope.SELECTED_CALENDAR_STATE_NAME);
  		// }
  		calendarService.setLastState("CONF_EVENT");
  		$state.go($rootScope.SELECTED_CALENDAR_STATE_NAME);
    	//$modalInstance.dismiss('cancel');
  	};

    $scope.EVENT_DT_UI_TXT = $scope.UI_YOU_ARE_TXT;

  	$scope.getTabsForEventView = function (item)
  	{
  		//var rightMenu =   [{ title:localizedMessages.get('MEETING'), click: createEvent,         svgFill: '#fff', iconUrl: ''}];
		var rightMenu = angular.copy($scope.rightMenuDefault);
		var userId = context.getModel().userVO.objectBaseUuid;

		var eventDtTxt = $scope.UI_ATTENDEE_TXT;
		//$scope.EVENT_DT_UI_TXT = "";
		//$scope.EVENT_DT_UI_TXT = $scope.UI_ATTENDEE_TXT;
		if(item!=undefined)
		{
			//COMMENTED FOR PRESENTER. DO NOT REMOVE
			/*var possiblePresenters = item.presenters;
			if(item.documentVOList!=undefined && item.documentVOList!=null && item.documentVOList.length>0)
			{
				if((possiblePresenters!=undefined && possiblePresenters!=null && (possiblePresenters.indexOf(userId)>=0)) || item.isEventOwner)
				{
					rightMenu.push({ title:localizedMessages.get('PRESENT'), click: handlePresentAction, iconUrl: '', svgFill: '#fff'});
					eventDtTxt = " "+$scope.UI_AND_TXT + " " + $scope.UI_PRESENTER_TXT;
				}
				else
				{
					rightMenu.push({ title:localizedMessages.get('JOIN'),    click: handleJoinAction, iconUrl: '', svgFill: '#fff'});
					eventDtTxt = $scope.UI_ATTENDEE_TXT + " " + eventDtTxt;
				}
			}*/
			if(item.isEventOwner)
			{
				//eventDtTxt = $scope.UI_HOST_TXT + eventDtTxt;
				eventDtTxt = $scope.UI_OWNER_TXT;
				//rightMenu.push({ title:localizedMessages.get('EDIT'),    click: editEvent,           svgFill: '#fff', iconUrl: ''});
				// rightMenu.push({ title:localizedMessages.get('DELETE'),  click: recycleEvent,        svgFill: '#fff', iconUrl: ''});
			}

		}

		$scope.eventDtTxt = eventDtTxt;
        if(!legacyUserService.isNasdaqUI){
            $scope.EVENT_DT_UI_TXT = $scope.UI_YOU_ARE_TXT+": "+eventDtTxt;
		}
		else{
		    $scope.EVENT_DT_UI_TXT = $scope.UI_YOU_ARE_TXT;
		}

		$scope.rightMenu = rightMenu;
		
		if($stateParams.meetingDetails){
			var rightMenuNew = angular.copy($scope.rightMenuNewMeetingView);
			$scope.rightMenu = rightMenuNew;
			return $scope.getTabNewListView();
		}
		return $scope.getTabArray();
  	}
  	
  	$scope.getTabNewListView = function()
  	{
  		var tabs = [];
		tabs.push({ title:localizedMessages.get('MEETING_VIEW'),   content:'',    rightMenuList:$scope.rightMenuNewMeetingView});
		return tabs;
  	}

  	$scope.getTabArray = function()
  	{
		/*var monthlyActive = false;
		var yearlyActive = false;
		var dailyActive = false;
		if($rootScope.SELECTED_TAB_FROM_CONF!=undefined)
		{
			switch ($rootScope.SELECTED_TAB_FROM_CONF)
			{
				case ""+localizedMessages.get('DAILY')   :  dailyActive=true;   break;
				case ""+localizedMessages.get('MONTHLY') :  monthlyActive=true; break;
				case ""+localizedMessages.get('YEARLY')  :  yearlyActive=true;  break;
			}
		}
		else
		{
			$rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('DAILY');
			dailyActive = true;
		}*/

		var tabs = [];
		tabs.push({ title:localizedMessages.get('MEETING_VIEW'),   content:'',    rightMenuList:$scope.rightMenu});

		/*switch ($rootScope.SELECTED_TAB_FROM_CONF)
		{
			case ""+localizedMessages.get('DAILY')   :
														tabs.push({ title:localizedMessages.get('DAILY'),   content:'cards',    rightMenuList:$scope.rightMenu,         func: goToDaily,    active: dailyActive});
														tabs.push({ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuList:$scope.rightMenuDefault,  func: goToMonthly,  active: monthlyActive});
														tabs.push({ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuList:$scope.rightMenuDefault,  func: goToTimeLine, active: yearlyActive});
														break;
			case ""+localizedMessages.get('MONTHLY') :
														tabs.push({ title:localizedMessages.get('DAILY'),   content:'cards',    rightMenuList:$scope.rightMenuDefault,  func: goToDaily,    active: dailyActive });
														tabs.push({ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuList:$scope.rightMenu,         func: goToMonthly,  active: monthlyActive});
														tabs.push({ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuList:$scope.rightMenuDefault,  func: goToTimeLine, active: yearlyActive});
														break;
			case ""+localizedMessages.get('YEARLY')  :
														tabs.push({ title:localizedMessages.get('DAILY'),   content:'cards',    rightMenuList:$scope.rightMenuDefault,  func: goToDaily,    active: dailyActive });
														tabs.push({ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuList:$scope.rightMenuDefault,  func: goToMonthly,  active: monthlyActive});
														tabs.push({ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuList:$scope.rightMenu,         func: goToTimeLine, active: yearlyActive });
														break;
		}*/
		return tabs;
  	}

	$scope.openAttachmentFile = function (attachment)
	{
		repositoryService.openPdfViewer(attachment);
	}

	$scope.getDocumentThumbnail = function(attachment)
 	{
 		return imageService.getThumbnailForObject(attachment, "large");
	}

	$scope.isCurrentEventSelected = function(item)
    {
    	return ( $scope.SELECTED_EVENT!=undefined && item.objectBaseUuid == $scope.SELECTED_EVENT.objectBaseUuid) ? true : false;
    }

    //$scope.showMeetingSettings = function(event, eventSelected)
    var showMeetingSettings = function()
    {
   		var eventsForToday = $scope.EVENTS_LIST.EVENTS;
   		if(eventsForToday && eventsForToday.length>0)
	   	{
	   		var eventsForSettings = [];
	   		for(var i=0;i<eventsForToday.length;i++)
			{
				if(eventsForToday[i].isEventOwner)
				{
					eventsForSettings.push(eventsForToday[i])
				}
			}
			bvDialog.showMeetingSettings(eventsForSettings, $scope.EVENTS_LIST.DATE);
		}
    }

    $scope.showConfAttendee = function()
    {
        if(!legacyUserService.isNasdaqUI)
    	    bvDialog.showMeetingAttendees($scope.SELECTED_EVENT);
        else
    	    bvDialog.showMeetingDetailsPanel($scope.SELECTED_EVENT, false);
    }

    $scope.showConfDetails = function()
    {
        if(!legacyUserService.isNasdaqUI)
    	    bvDialog.showMeetingDetails($scope.SELECTED_EVENT);
        else
    	    bvDialog.showMeetingDetailsPanel($scope.SELECTED_EVENT, true);
    }

    $scope.startEvent = function(calEvent)
    {
    	if(MobileUtil.isApp() && MobileUtil.isJupOffline())
    	{
    		return;
    	}
    	if(calEvent.objectBaseUuid == $scope.SELECTED_EVENT.objectBaseUuid)
    	{
    		conferenceService.getConferenceParticipantsJSON(calEvent.objectBaseUuid).then(function(data)
	 		{
	 			var conf = {};
	 			conf.conf_event = $scope.SELECTED_EVENT;
	 			conf.conf_participants = data.data;
				presentationService.setConf(conf);
				conferenceService.startConferenceJSON(calEvent.objectBaseUuid).then(function(data)
		 		{
	 				presentationService.showConfDialog();
//	 				if(cb typeof "function") cb()
	 			});
			});
	 	};
    }

    $scope.notJoinedAnyOtherConf = function() {
    	return Object.keys($scope.conf).length == 0;
    }
    // if has already joined another conf

    $scope.conf = presentationService.getConf();
    $scope.$on('conf:updated', function(event,conf) {
    	$scope.conf = conf;
    	console.log("attending conf update", conf);
    });

    // To show join button and orange bars

    var updateRunningStateOfEvents = function()
    {
    	if($scope.EVENTS_LIST != undefined && $scope.EVENTS_LIST != null && $scope.EVENTS_LIST != undefined && Object.keys($scope.EVENTS_LIST).length != 0)
		{
			for(var e in $scope.EVENTS_LIST.EVENTS)
    		{
				$scope.EVENTS_LIST.EVENTS[e].isRunning = false;
			}
		}
    	if($scope.runningConfs.length > 0 && $scope.EVENTS_LIST != null && $scope.EVENTS_LIST != undefined && Object.keys($scope.EVENTS_LIST).length != 0)
    	{
    		for(var i in $scope.runningConfs)
    		{
    			for(var e in $scope.EVENTS_LIST.EVENTS)
    			{
    				if($scope.runningConfs[i].objectBaseUuid == $scope.EVENTS_LIST.EVENTS[e].objectBaseUuid)
    				{
    					$scope.EVENTS_LIST.EVENTS[e].isRunning = true;
    				}
    			}
    		}
    	}
    }

	$scope.runningConfs = presentationService.getRunningConfs();
    $scope.$on('runningConfs:updated', function(event,runningConfs) {
          $scope.runningConfs = runningConfs;
          updateRunningStateOfEvents();
          console.log("runningConfs:updated" , runningConfs);
   });

    $scope.joinConf = function(calEvent) {
    	presentationService.showRunningConfsListDialog(calEvent);
    }

    //console.log("USER IMAGES:",$scope.userImages);
	initialize();
	
	if($stateParams.meetingDetails){
    	console.log('$stateParams.meetingObject');
    	console.log($stateParams.meetingDetails);
		var calEvent = $stateParams.meetingDetails.calEvent
		var activeMeeting = $stateParams.meetingDetails.activeMeeting
    	var eventSelected = $stateParams.meetingDetails.eventSelected
    	var eventName = $stateParams.meetingDetails.event
    	$scope.SELECTED_EVENT = $stateParams.meetingDetails.activeMeeting
    	if(eventName == 'editEvent'){
    		$scope.editEvent(activeMeeting, eventSelected)
//    		$scope.tabs = $scope.getTabNewListView();
    	}else if(eventName == 'copyEvent'){
    		$scope.copyEvent(activeMeeting, eventSelected)
    	}else if(eventName == 'deleteEvent'){
    		$scope.recycleEvent()
    	}else if(eventName == 'alertEvent'){
    		$scope.showUserForAlert()
		}
    }

//    function showCopyMeetings(){
//        var eventsForToday = $scope.EVENTS_LIST.EVENTS;
//        if(eventsForToday && eventsForToday.length>0)
//        {
//            var eventsForSettings = [];
//            for(var i=0;i<eventsForToday.length;i++)
//            {
//                if(eventsForToday[i].isEventOwner)
//                {
//                    eventsForSettings.push(eventsForToday[i])
//                }
//            }
//            bvDialog.showMeetingSettings(eventsForSettings, $scope.EVENTS_LIST.DATE, 'copyMeetings');
//        }
//    }

}

angular
	.module('conference.module',['localization.service','conference.service','calendar.service','confprsntproducer.module','ui.tree','repository.service','team.service'])
	.controller('ConferenceController',ConferenceController);
