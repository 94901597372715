angular
    .module('dialogControllers.service')
	.controller('modulesSettingsController',  modulesSettingsController);

modulesSettingsController.$inject = ['$scope', 'localizedMessages', 'bvDialog', 'homeContext', 'homeService', 'bvDialogService'];

function modulesSettingsController($scope, localizedMessages, bvDialog, homeContext, homeService, bvDialogService){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	
	$scope.popupTitle 	= localizedMessages.get('MODULES_SETTINGS');
	$scope.actionBtnTxt = localizedMessages.get('SAVE');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.UI_VISIBLE_TO = localizedMessages.get('VISIBLE_TO');
	$scope.UI_ALL        = localizedMessages.get('ALL');
	$scope.UI_APP        = localizedMessages.get('APP');
	$scope.UI_ADMINS_ONLY 	 = localizedMessages.get('ADMINS_ONLY');
	$scope.UI_LAND_ON    = localizedMessages.get('LAND_ON');
	$scope.UI_BROWSER    = localizedMessages.get('BROWSER');
	
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
	
	$scope.modulesForSetting = angular.copy(homeContext.modules.sidebarMenuModulesForAdmin);
	$scope.currentDefault =  angular.copy(homeContext.currentDefault);
	
	var showAlertForBroswerLandingModule = function()
	{
		bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_DEFAULT_LANDING_MODULE_NOT_VISIBLE_FOR_ALL'));
	}
	
	var updateSidebarModules = function(modules){
		var sidebarMenuModules = angular.copy(modules);
		var i = 0
		while (i < sidebarMenuModules.length) {
			sidebarMenuModules[i].isVisible = true
			if(MobileUtil.isApp()){
				if(!sidebarMenuModules[i].isVisibleForJup){
					sidebarMenuModules.splice(i,1)
				}
				else{
					delete(sidebarMenuModules[i].isVisibleForAdmin)
					delete(sidebarMenuModules[i].isVisibleForAll)
					delete(sidebarMenuModules[i].isVisibleForJup)
					i++
				}
			}
			else{
				if(!sidebarMenuModules[i].isVisibleForAdmin && !sidebarMenuModules[i].isVisibleForAll){
					sidebarMenuModules.splice(i,1)
				}
				else{
					delete(sidebarMenuModules[i].isVisibleForAdmin)
					delete(sidebarMenuModules[i].isVisibleForAll)
					delete(sidebarMenuModules[i].isVisibleForJup)
					i++
				}
			}
		}
		
		homeContext.modules.sidebarMenuModules = angular.copy(sidebarMenuModules)
	}
	
	$scope.sortableOptions = {
		// "cursor": "move",
		handle: '> .drag-handle',
		stop: function(e, ui)
        {
			updateSidebarModules($scope.modulesForSetting)
        }
	};
	
	var isModuleSettingsChanged = function() 
	{
		var currentModuleConfig = angular.toJson($scope.modulesForSetting);
		var initialModuleConfig = angular.toJson(homeContext.modules.sidebarMenuModulesForAdmin);

		var changeFlag = (initialModuleConfig != currentModuleConfig) ? true : false;
		if(!changeFlag)
		{
			changeFlag = (homeContext.currentDefault.landingModule != $scope.currentDefault.landingModule) ? true : false;
		}
		if(!changeFlag)
		{
			changeFlag = (homeContext.currentDefault.jupLandingModule != $scope.currentDefault.jupLandingModule) ? true : false;
		}

		return changeFlag;
	}
	
	$scope.changeModuleVisibility = function (item, itemName, flag)
	{
		//console.log("ITEM:",item," ITEM-NAME:",itemName," ITEM.IS-VISIBLE-FOR-ALL",item.isVisibleForAll," ITEM-IS-VISIBLE-FOR-ADMIN",item.isVisibleForAdmin);
		if(itemName == "isVisibleForAdmin")
		{
			if(homeContext.currentDefault.landingModule == item.name)
			{
				showAlertForBroswerLandingModule();
			}
			else if(homeContext.currentDefault.jupLandingModule == item.name)
			{
				showAlertForAppLandingModule();
			}
			else
			{
				item.isVisibleForAdmin = flag ;
				if(flag && item.isVisibleForAll)
					item.isVisibleForAll = false;
			}
		}
		else if(itemName == "isVisibleForAll")
		{
			item.isVisibleForAll = flag;
			if(flag && item.isVisibleForAdmin)
				item.isVisibleForAdmin = false;
		}
		
		updateSidebarModules($scope.modulesForSetting)
	}

	$scope.changeLandingModuleForBroswer = function (item)
	{
		var showErr = false;
		for(var i=0; i < $scope.modulesForSetting.length; i++)
		{
			var moduleConfig = $scope.modulesForSetting[i];
			if (moduleConfig.isVisibleForAdmin && (moduleConfig.name == item.name))
			{
				showErr = true;
				break;
			}
		}
		if(showErr)
		{
			showAlertForBroswerLandingModule();
		}
		else
		{
			$scope.currentDefault.landingModule = item.name;
		}
	}

	$scope.changeLandingModuleForApp = function (item)
	{
		var showErr = false;
		for(var i=0; i < $scope.modulesForSetting.length; i++)
		{
			var moduleConfig = $scope.modulesForSetting[i];
			if (moduleConfig.isVisibleForAdmin && (moduleConfig.name == item.name))
			{
				showErr = true;
				break;
			}
		}
		if(showErr)
		{
			showAlertForAppLandingModule();
		}
		else
		{
			$scope.currentDefault.jupLandingModule = item.name;
		}
	}
	
	$scope.action = function()
	{
		var changeFlag = isModuleSettingsChanged();

		if(changeFlag)
		{
			var moduleMapping = "";
			var defaultLandingModule = $scope.currentDefault.landingModule;
			var defaultJupLandingModule = $scope.currentDefault.jupLandingModule;

			var jupState = "";
			var defaultLandingModuleShouldBeVisibleToAll = false;
			var defaultJupLandingModuleShouldBeVisibleToAll = false;
			for(var i=0; i < $scope.modulesForSetting.length; i++)
			{
				var moduleConfig = $scope.modulesForSetting[i];
				jupState = (moduleConfig.isVisibleForJup) ? "1" : "0";
				if(!moduleConfig.isVisibleForAll && !moduleConfig.isVisibleForAdmin)
				{
					moduleMapping = moduleMapping + moduleConfig.name + "#2_"+jupState+",";
				}
				else if (moduleConfig.isVisibleForAdmin)
				{
					moduleMapping = moduleMapping + moduleConfig.name + "#1_"+jupState+",";
				}
				
				else if (moduleConfig.isVisibleForAll)
				{
					if(defaultLandingModule == moduleConfig.name)
					{
						defaultLandingModuleShouldBeVisibleToAll = true;
					}
					if(defaultJupLandingModule == moduleConfig.name)
					{
						defaultJupLandingModuleShouldBeVisibleToAll = true;
					}
					moduleMapping = moduleMapping + moduleConfig.name + "#0_"+jupState+",";
				}
			}

			if(!defaultLandingModuleShouldBeVisibleToAll)
			{
				showAlertForBroswerLandingModule();
			}
			else if(!defaultJupLandingModuleShouldBeVisibleToAll)
			{
				showAlertForAppLandingModule();
			}
			else
			{	
				moduleMapping = moduleMapping.substring(0, moduleMapping.length-1);
				homeService.setModuleSettingsForPortal(moduleMapping,defaultLandingModule,defaultJupLandingModule).then(function(result)
				{
					if(result.data == true)
					{
						homeContext.modules.sidebarMenuModulesForAdmin = angular.copy($scope.modulesForSetting);
						homeContext.currentDefault = angular.copy($scope.currentDefault);
						
						bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_CHANGES_SAVED_SUCCESSFULLY'), null, true);
						bvModal.close()
					}
					else
					{
						bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_ERROR_IN_SAVING_MODULE_SETTINGS'));
					}
				});
			}
		}
		else
		{
			bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_NO_CHANGES_MADE_TO_MODULE_SETTING'));
			bvModal.close();
		}
	};
	
	var showAlertForBroswerLandingModule = function()
	{
		bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_DEFAULT_LANDING_MODULE_NOT_VISIBLE_FOR_ALL'));
	}

	var showAlertForAppLandingModule = function()
	{
		bvDialog.showMessage(localizedMessages.get('ERR_MODULE_SETTINGS_DEFAULT_JUP_LANDING_MODULE_NOT_VISIBLE_FOR_ALL'));
	}
	
	var cancelModuleChanges = function(){
		
		updateSidebarModules(homeContext.modules.sidebarMenuModulesForAdmin)
		bvModal.close()
	}
	$scope.close = function(){
		if(isModuleSettingsChanged()){
			bvDialog.confirm(localizedMessages.get("MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED"), cancelModuleChanges)
		}
		else{
			cancelModuleChanges()
		}
	}
}