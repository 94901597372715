function imageService() {

	var service = {
		getThumbnailForObject: getThumbnailForObject,
		getImageForObject: getImageForObject,
		getImageByExtension: getImageByExtension,
		//	getImageByExtensionForFooterMenu: getImageByExtensionForFooterMenu,
		shouldRenderAsPdf: shouldRenderAsPdf,
		getFontIconByName: getFontIconByName
	}

	return service;

	/////

	function getThumbnailForObject(object, size) {
		if (object.documentThumbnail != undefined) return "data:image/png;base64," + object.documentThumbnail;
		else if (object.encodedThumbnail != undefined) return "data:image/png;base64," + object.encodedThumbnail;
		else return this.getImageForObject(object, size);
	}

	function getImageForObject(object, size) {
		if (object.boName == "FolderBO") object.extensionType = "folderAttachment";
		else if (StringUtils.isBoardbook(object.objectBaseUuid)) object.extensionType = "boardbook";
		else if (StringUtils.isCollate(object.objectBaseUuid)) object.extensionType = "collate";

		if (object.extensionType == undefined || object.extensionType == null) return null;
		else return this.getImageByExtension(object.extensionType, size);
	}

	function getImageByExtension(type, size) {
		var type = type.toLowerCase();
		var LARGE = "large";
		if (StringUtils.isMediaObject(type)) {
			type = "mediaObject";
		}
		var imagePath = "";
		switch (type) {
			case 'docx':
				imagePath = "resources/assets/svgs/documents/docx.svg";
				break;
			case "doc":
				imagePath = "resources/assets/svgs/documents/doc.svg";
				break;
			case "txt":
				imagePath = "resources/assets/svgs/documents/txt.svg";
				break;
			case "xls":
				imagePath = "resources/assets/svgs/documents/xls.svg";
				break;
			case "xlsm":
			case "xlsx":
				imagePath = "resources/assets/svgs/documents/xlsx.svg";
				break;
			case "gif":
				imagePath = "resources/assets/svgs/documents/gif.svg";
				break;
			case "mediaObject":
				imagePath = "resources/assets/svgs/documents/media.svg";
				break;
			case "ppt":
				imagePath = "resources/assets/svgs/documents/ppt.svg";
				break;
			case "pptx":
				imagePath = "resources/assets/svgs/documents/pptx.svg";
				break;
			case "potx":
				imagePath = "resources/assets/svgs/documents/potx.svg";
				break;
			case "ppsx":
				imagePath = "resources/assets/svgs/documents/ppsx.svg";
				break;
			case 'pdf':
				imagePath = "resources/assets/svgs/documents/pdf.svg";
				break;
			case 'png':
				imagePath = "resources/assets/svgs/documents/png.svg";
				break;
			case "bmp":
				imagePath = "resources/assets/svgs/documents/bmp.svg";
				break;
			case "jpeg":
			case 'jpg':
				imagePath = "resources/assets/svgs/documents/jpg.svg";
				break;
			case 'folder':
				imagePath = "resources/assets/svgs/documents/folder.svg";
				break;
			case 'hiddenfolder':
				imagePath = "resources/assets/svgs/documents/hidden_folder.svg";
				break;
			case 'group':
				imagePath = "resources/assets/svgs/documents/group_icon.svg";
				break;
			case 'company':
				imagePath = "resources/assets/svgs/documents/company_icon.svg";
				break;
			case 'user':
				imagePath = "resources/assets/svgs/documents/user_icon.svg";
				break;
			case 'teamspacegroup':
				imagePath = "resources/assets/svgs/documents/teamgroup_icon.svg";
				break;
			case 'portal':
				imagePath = "resources/assets/svgs/documents/teamspace_icon.svg";
				break;
			case 'folderattachment':
				imagePath = "resources/assets/images/BVLiveryFolder.png";
				break;
			case 'approval':
				imagePath = "resources/assets/svgs/documents/approvals.svg";
				break;
			//            case 'signature':
			//            	imagePath = "resources/assets/images/signature.png";
			//            	break;
			case 'survey':
				imagePath = "resources/assets/svgs/documents/survey.svg";
				break;
			//            case 'calendarevent':
			//            	imagePath = "resources/assets/images/cal-invite.png";
			//            	break;
			case 'plainapproval':
				imagePath = "resources/assets/svgs/documents/approvals.svg";
				break;
			case 'plainsurvey':
				imagePath = "resources/assets/svgs/documents/survey.svg";
				break;
			case 'collate':
				imagePath = "resources/assets/svgs/documents/collate.svg";
				//if(size && size == LARGE) imagePath = "resources/assets/images/collate_lg_2x.png";
				break;
			case 'boardbook':
				imagePath = "resources/assets/svgs/boardbook.svg";
				break;
			case 'boardbook_emboss':
				imagePath = "resources/assets/svgs/boardbook_emboss.svg";
				break;
			case 'onlinegenda':
				imagePath = "resources/assets/svgs/documents/agenda.svg";
				break;
			case 'ona':
			case 'html':
				imagePath = "resources/assets/svgs/documents/html.svg";
				//if(size && size == LARGE) imagePath = "resources/assets/images/html_lg_@2X.png";
				break;
			default:
				imagePath = "resources/assets/images/BVLiveryDefaultThumbnailUnknown.png";
				break;
		}
		return imagePath;
	}

	function getFontIconByName(name) {

		var FontIcon = function (fontName, className, unicode) {
			this.fontName = fontName || '';
			this.className = className || '';
			this.unicode = unicode || '';
		}

		FontIcon.prototype = {
			constructor: FontIcon,
			getFontName: function () {
				return this.fontName;
			},
			getClassName: function () {
				return this.className;
			},
			getUnicode: function () {
				return this.unicode;
			},
			setFontName: function (fontName) {
				this.fontName = fontName;
				return this.fontName;
			},
			setClassName: function (className) {
				this.className = className;
				return this.className;
			},
			setUnicode: function (unicode) {
				this.unicode = unicode;
				return this.unicode;
			},
			isEmpty: function () {
				if (this.fontName == '' && (this.unicode == '' || this.className == ''))
					return true;
				else
					return false;
			}
		}

		var fontIcon = new FontIcon();

		switch (name) {
			//            case 'MEETINGS':
			//                fontIcon.name = 'ss-standard';
			//                fontIcon.unicode = '&#x2709;';
			//        	    break;
			case 'CALENDAR':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-calendar');
				fontIcon.setUnicode('&#x1F4C5;');
				break;
			//            case 'NEWITEMS':
			//                fontIcon.setFontName('ss-standard');
			//                fontIcon.setClassName('ss-folder');
			//                fontIcon.setUnicode('&#x1F4C1;');
			//        	    break;
			case 'BRIEFCASE':
				fontIcon.setFontName('ss-symbolicons-block');
				fontIcon.setClassName('ss-briefcase');
				fontIcon.setUnicode('&#x1F4BC;');
				break;
			//            case 'SIGNATURES':
			//                fontIcon.setFontName('ss-glyphish-filled');
			//                fontIcon.setClassName('ss-write');
			//                fontIcon.setUnicode('&#x270E;');
			//        	    break;
			case 'APPROVALS':
				fontIcon.setFontName('ss-symbolicons-block');
				fontIcon.setClassName('ss-check');
				fontIcon.setUnicode('&#x2713;');
				break;
			//            case 'SURVEYS':
			//                fontIcon.setFontName('ss-glyphish-filled');
			//                fontIcon.setClassName('ss-like');
			//                fontIcon.setUnicode('&#x1F44D;');
			//        	    break;
			case 'DOCUMENTS':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-folder');
				fontIcon.setUnicode('&#x1F4C1;');
				break;
			case 'DASHBOARD':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-grid');
				fontIcon.setUnicode('');
				break;
			case 'INBOX':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-mail');
				fontIcon.setUnicode('&#x2709;');
				break;
			case 'EMAIL':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-mail');
				fontIcon.setUnicode('&#x2709;');
				break;
			case 'FOLDER':
				fontIcon.setFontName('ss-glyphish-filled');
				//fontIcon.setClassName('ss-folder');
				fontIcon.setUnicode('&#x1F4C1;');
				break;
			case 'REPORTS':
				fontIcon.setFontName('ss-glyphish-filled');
				fontIcon.setClassName('ss-files');
				fontIcon.setUnicode('&#xEC00;');
				break;
			case 'DIRECTORY':
				fontIcon.setFontName('ss-glyphish-filled');
				fontIcon.setClassName('ss-users');
				fontIcon.setUnicode('&#x1F465;');
				break;
			case 'TRASH':
				fontIcon.setFontName('ss-glyphish-filled');
				fontIcon.setClassName('ss-trash');
				fontIcon.setUnicode('&#x1F5D1;');
				break;
			case 'NAVIGATE LEFT':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-navigateleft');
				fontIcon.setUnicode('&#x25C5;');
				break;
			case 'NAVIGATE RIGHT':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-navigateright');
				fontIcon.setUnicode('&#x27A1;');
				break;
			case 'NAVIGATE DOWN':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-navigatedown');
				fontIcon.setUnicode('&#xF501;');
				break;
			case 'SEARCH':
				fontIcon.setFontName('ss-standard');
				//fontIcon.setClassName('ss-search');
				fontIcon.setUnicode('&#x1F50E;');
				break;
			case 'SEARCH_BLOCK':
				fontIcon.setFontName('ss-symbolicons-block');
				//fontIcon.setClassName('ss-search');
				fontIcon.setUnicode('&#x1F50D;');
				break;
			case 'REFRESH':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-refresh');
				fontIcon.setUnicode('&#x21BB;');
				break;
			case 'DELETE':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-delete');
				fontIcon.setUnicode('&#x2421;');
				break;
			case 'CHECK':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-check');
				fontIcon.setUnicode('&#x2713;');
				break;
			case 'STAR':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-star');
				fontIcon.setUnicode('&#x22C6;');
				break;
			case 'BAN':
				fontIcon.setFontName('ss-symbolicons-block');
				fontIcon.setClassName('ss-ban');
				fontIcon.setUnicode('&#x1F6AB;');
				break;
			case 'USER':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-user');
				fontIcon.setUnicode('&#x1F464;');
				break;
			case 'INFO':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-info');
				fontIcon.setUnicode('&#x2139;');
				break;
			case 'EDIT':
				fontIcon.setFontName('ss-standard');
				fontIcon.setClassName('ss-write');
				fontIcon.setUnicode('&#x270E;');
				break;

			default:
		}

		return fontIcon;
	}

	//	function getImageByExtensionForFooterMenu(type) {
	//		var imagePath = "";
	//		switch(type) {
	//			case "calendar_root":
	//				imagePath = "resources/assets/images/calendar-root.png";
	//			break;
	//			case "calendar":
	//				imagePath = "resources/assets/images/calendar.png";
	//			break;
	//			// case "desktop_sharing_small":
	//			// 	imagePath = "resources/assets/images/.png";
	//			// break;
	//			case "folder_icon":
	//				imagePath = "resources/assets/images/folder_icon.png";
	//			break;
	//			case "discussion":
	//				imagePath = "resources/assets/images/discussion.png";
	//			break;
	//			case "uicon":
	//				imagePath = "resources/assets/images/uicon.png";
	//			break;
	//			case "approvals_approvalslisticon":
	//				imagePath = "resources/assets/images/approvals_approvalslisticon.png";
	//			break;
	//			case "context_bbk":
	//				imagePath = "resources/assets/images/bb_listview.png";
	//			break;
	//			case "siconcollate":
	//				imagePath = "resources/assets/images/collate_listiview.png";
	//			break;
	//			case "agenda":
	//				imagePath = "resources/assets/images/agenda_icon.png";
	//			break;
	//			case "siconwiki":
	//				imagePath = "resources/assets/images/siconwiki.png";
	//			break;
	//			case "copy_or_password_protected_pdf":
	//				imagePath = "resources/assets/images/copy_or_password_protected_pdf.png";
	//			break;
	//			case "copy_or_corrupted_documents":
	//				imagePath = "resources/assets/images/copy_or_corrupted_documents.png";
	//			break;
	//			case "doc":
	//				imagePath = "resources/assets/images/sicondoc.png";
	//			break;
	//			case "docx":
	//				imagePath = "resources/assets/images/sicondoc.png";
	//			break;
	//			case "rtf":
	//				imagePath = "resources/assets/images/sicondoc.png";
	//			break;
	//			case "ppt":
	//				imagePath = "resources/assets/images/siconppt.png";
	//			break;
	//			case "pps":
	//				imagePath = "resources/assets/images/siconppt.png";
	//			break;
	//			case "pptx":
	//				imagePath = "resources/assets/images/siconppt.png";
	//			break;
	//			case "potx":
	//				imagePath = "resources/assets/images/siconppt.png";
	//			break;
	//			case "ppsx":
	//				imagePath = "resources/assets/images/siconppt.png";
	//			break;
	//			case "xls":
	//				imagePath = "resources/assets/images/siconxls.png";
	//			break;
	//			case "xlsx":
	//				imagePath = "resources/assets/images/siconxls.png";
	//			break;
	//			case "xlsb":
	//				imagePath = "resources/assets/images/siconxls.png";
	//			break;
	//			case "xltx":
	//				imagePath = "resources/assets/images/siconxls.png";
	//			break;
	//			case "txt":
	//				imagePath = "resources/assets/images/sicontxt.gif";
	//			break;
	//			case "log":
	//				imagePath = "resources/assets/images/sicontxt.gif";
	//			break;
	//			case "sql":
	//				imagePath = "resources/assets/images/sicontxt.gif";
	//			break;
	//			case "java":
	//				imagePath = "resources/assets/images/sicontxt.gif";
	//			break;
	//			case "png":
	//				imagePath = "resources/assets/images/siconimage.png";
	//			break;
	//			case "jpg":
	//				imagePath = "resources/assets/images/siconimage.png";
	//			break;
	//			case "jpeg":
	//				imagePath = "resources/assets/images/siconimage.png";
	//			break;
	//			case "gif":
	//				imagePath = "resources/assets/images/siconimage.png";
	//			break;
	//			case "bmp":
	//				imagePath = "resources/assets/images/siconimage.png";
	//			break;
	//			case "tif":
	//				imagePath = "resources/assets/images/siconimage.png";
	//			break;
	//			case "tiff":
	//				imagePath = "resources/assets/images/siconimage.png";
	//			break;
	//			case "pdf":
	//				imagePath = "resources/assets/images/siconpdf.png";
	//			break;
	//			case "html":
	//				imagePath = "resources/assets/images/htmlIcon.png";
	//			break;
	//			case "htm":
	//				imagePath = "resources/assets/images/htmlIcon.png";
	//			break;
	//			case "avi":
	//				imagePath = "resources/assets/images/media_avi.png";
	//			break;
	//			case "wmv":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "asf":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "wma":
	//				imagePath = "resources/assets/images/siconaudio.png";
	//			break;
	//			case "wav":
	//				imagePath = "resources/assets/images/siconaudio.png";
	//			break;
	//			case "divx":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "mov":
	//				imagePath = "resources/assets/images/media_mov.png";
	//			break;
	//			case "qt":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "dv":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "aac":
	//				imagePath = "resources/assets/images/siconaudio.png";
	//			break;
	//			case "mp4":
	//				imagePath = "resources/assets/images/media_mp4.png";
	//			break;
	//			case "m4v":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "flv":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "h.264":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "mpeg":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "mpg":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "vob":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "3gp":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "3g2":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "dvd":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "mp3":
	//				imagePath = "resources/assets/images/media_mp3.png";
	//			break;
	//			case "rm":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "rmvb":
	//				imagePath = "resources/assets/images/siconmov.png";
	//			break;
	//			case "ra":
	//				imagePath = "resources/assets/images/siconaudio.png";
	//			break;
	//			case "swf":
	//				imagePath = "resources/assets/images/siconswf.png";
	//			break;
	//			case "exe":
	//				imagePath = "resources/assets/images/siconexe.png";
	//			break;
	//			case "zip":
	//				imagePath = "resources/assets/images/siconzip.png";
	//			break;
	//			case "jar":
	//				imagePath = "resources/assets/images/siconzip.png";
	//			break;
	//			case "rar":
	//				imagePath = "resources/assets/images/siconzip.png";
	//			break;
	//			//					case "dtv":
	//				//						imagePath = "resources/assets/images/.png";
	//			//						break;
	//			case "wiki":
	//				imagePath = "resources/assets/images/siconwiki.png";
	//			break;
	//			case "col":
	//				imagePath = "resources/assets/images/collate_listiview.png";
	//			break;
	//			case "bbk":
	//				imagePath = "resources/assets/images/bb_listview.png";
	//			break;
	//			case "dynamic_col":
	//				imagePath = "resources/assets/images/dynamic_collate_listiview.png";
	//			break;
	//			case "dynamic_bbk":
	//				imagePath = "resources/assets/images/dynamic_bb_listiview.png";
	//			break;
	//			default :
	//				imagePath = "resources/assets/images/sicondef.png";
	//			break;
	//		}
	//		return imagePath;
	//	}

	function shouldRenderAsPdf(type) {
		var type = type.toLowerCase();
		var answer = false;
		switch (type) {
			case "docx":
			case "doc":
			case "txt":
			case "xls":
			case "xlsx":
			case "xlsm":
			case "ppt":
			case "pptx":
			case "pdf":
				answer = true;
				break;
			default:
				answer = false;
				break;
		}
		return answer;
	}
};
