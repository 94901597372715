discussionsService.$inject = ['$q','repositoryService'];
function discussionsService($q, repositoryService) {
    var service= {
    		getPortalData: function(){
    			var request = repositoryService.getPortalData();
    			return request.then(function(response)
                {
                    if(response)
                    {
                        return response;
                    }
                    return null;
                });
    		},
    		getUserList: function(){
    			var request = repositoryService.getUserList();
    			return request.then(function(response)
                {
                    if(response)
                    {
                        return response;
                    }
                    return null;
                });
    		},
    		getFeedsForDiscussion: function(portalId,eventFilter,offset){
    			var request = repositoryService.getFeedsForDiscussion(portalId,eventFilter,offset);
    			return request.then(function(response)
                {
                    if(response)
                    {
                        return response;
                    }
                    return null;
                });
    		},
    		postToWall: function (objectBaseUuid, postTextHTML, attachmentBoIds, usersIds) {
    			var request = repositoryService.postToWall(objectBaseUuid, postTextHTML, attachmentBoIds, usersIds);
    			return request.then(function(response)
                {
                    if(response)
                    {
                        return response;
                    }
                    return null;
                });
    		},
    		postReplyComment: function (objectBaseUuid, feedId, portalBaseUuid, replyCommentHTML, usersIds, documentObjectBaseUuid) {
    			var request = repositoryService.postReplyComment(objectBaseUuid, feedId, portalBaseUuid, replyCommentHTML, usersIds, documentObjectBaseUuid);
    			return request.then(function(response)
                {
                    if(response)
                    {
                        return response;
                    }
                    return null;
                });
    		},
    		deletePost: function(objectBaseUuid){
    			var request = repositoryService.deletePost(objectBaseUuid);
    			return request.then(function(response)
                {
                    if(response)
                    {
                        return response;
                    }
                    return null;
                });
    		},
    		editPost: function (objectBaseUuid, editedHtml, updatedAttachmentBoIds, usersIds, documentObjectBaseUuid) {
    			var request = repositoryService.editPost(objectBaseUuid, editedHtml, updatedAttachmentBoIds, usersIds, documentObjectBaseUuid);
			    return request.then(function (response) {
				    if (response) {
					    return response;
				    }
				    return null;
			    });
    		},
    		getPostComments: function (objectBaseUuid) {
    			var request = repositoryService.getPostComments(objectBaseUuid);
    			return request.then(function (response) {
    				if (response) {
    					return response;
    				}
    				return null;
    			});
    		}
    		
    }
    return service;
}
angular
.module('discussions.service',[])
.factory('discussionsService', discussionsService)