angular
    .module('dialogControllers.service')
	.controller('propertiesController', PropertiesController);	

PropertiesController.$inject = ['homeService','$scope','context','localizedMessages', 'imageService', 'permissionsService', 'repositoryService', 'presentationService','commonService','teamService','bvDialog', 'SortUtility', '$filter'];

function PropertiesController(homeService, $scope, context, localizedMessages, imageService, permissionsService, repositoryService, presentationService, commonService, teamService, bvDialog, SortUtility, $filter)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var initialBO = $scope.bvModalControllerInputs.businessObject;
	//$scope.businessObject = businessObject;
	$scope.init = function() 
	{
		//console.log("INITIAL BO:",businessObject);
		$scope.CURRENT_OPTION = 'UI_DETAILS';
		$scope.popupTitle = localizedMessages.get('PROPERTIES');
		$scope.UI_TYPE_TXT = localizedMessages.get('TYPE');
		$scope.UI_NAME_TXT = localizedMessages.get('NAME');
		$scope.UI_DETAILS_TXT = localizedMessages.get('DETAILS');
		$scope.UI_LOCATION_TXT = localizedMessages.get('LOCATION');
		$scope.UI_FAVORITES_TXT = localizedMessages.get('BRIEFCASE');
		$scope.UI_PERMISSION_TXT = localizedMessages.get('PERMISSION');
		$scope.UI_PERMISSIONS_TXT = localizedMessages.get('PERMISSIONS');
		$scope.UI_USERS_TXT = localizedMessages.get('USERS');
		getDocumentProperties();
		
		/*if(initialBO.isPdfACompliant!=undefined && initialBO.isPdfACompliant!=null && initialBO.isPdfACompliant==="false"){
			$scope.SHOW_FAVORITES = false;
		}*/
		
	}
	$scope.changePropOption = function (optionVal)
	{
		$scope.CURRENT_OPTION = optionVal;
		// switch($scope.CURRENT_OPTION)
		// {
		// 	case 'UI_DETAILS' 	   : getAllVersionsForSelectedDocument(); break;
		// 	case 'UI_PERMISSIONS'  : getDocumentPermissions(); break;
		// 	case 'UI_FAVORITES'	   : getFavorites(); break;
		// }
	}
	
	$scope.sortColumn = null;
    $scope.sortOrder = false;
    $scope.sort = function(col, toggle = true){
    	if(col===$scope.sortColumn && toggle){
    		$scope.sortOrder = !$scope.sortOrder;
    	}else if(col!=$scope.sortColumn){
    		$scope.sortOrder = false;
    		$scope.sortColumn = col;
    	}
    	$scope.DOC_PERMISSIONS = SortUtility.sortByField($scope.DOC_PERMISSIONS, [col], $scope.sortOrder);
    }
    $scope.getSortableClass = function(col){
    	if(col===$scope.sortColumn){
    		if($scope.sortOrder){
    			return 'sort-up';
    		}else{
    			return 'sort-down';
    		}
    	}else{
    		return 'sortable';
    	}
    }
	
	var getDocumentProperties = function() 
    {
    	homeService.getDocPropJSONByBaseUuid(initialBO.objectBaseUuid).then(function(data)
    	{
    		console.log("PROP-DATA:",data);
    		var businessObject = data.data;
    		var properitesData = [];
    		var checkFolder = StringUtils.isFolder(businessObject.objectBaseUuid);
    		properitesData.push( {title: localizedMessages.get("NAME"), desc: businessObject.name} );        		
    		properitesData.push( {title: localizedMessages.get("TYPE"), desc: localizedMessages.get(StringUtils.getTypeByObjectBaseUuid(businessObject.objectBaseUuid).toUpperCase())} );
    		if(!checkFolder){
    			var fileSize = StringUtils.fileSizeConverter(businessObject.fileSize, 0);
    			properitesData.push( {title: localizedMessages.get("FILE_SIZE"), desc: fileSize} );
    		}
    		properitesData.push( {title: localizedMessages.get("CREATED_ON"), desc: moment($filter('date')(businessObject.originalCreatedOn, 'medium')).format('lll')});
    		properitesData.push( {title: localizedMessages.get("LAST_MODIFIED_ON"), desc: moment($filter('date')(businessObject.lastModifiedOnGMTMillis, 'medium')).format('lll')});
    		properitesData.push( {title: localizedMessages.get("MODIFIED_BY"), desc: businessObject.lastModifiedBy} );
    		if(!StringUtils.isFolder(businessObject.objectBaseUuid))
    			properitesData.push({title:localizedMessages.get("VERSION_ID"), desc:businessObject.versionNumber});  
    		properitesData.push( {title: localizedMessages.get("CREATED_BY"), desc: businessObject.originalCreatedBy} );
    		properitesData.push( {title: localizedMessages.get("ID"), desc: businessObject.objectBaseUuid} );
    		properitesData.push( {title: localizedMessages.get("VERSION"), desc: businessObject.objectVersionUuid} );    
    		properitesData.push( {title: localizedMessages.get("CREATED_TEAMSPACE"), desc: businessObject.createdTeamSpace} );      	
    		/*if(StringUtils.isFolder(businessObject.objectBaseUuid))
    			properitesData.push( {title:localizedMessages.get("HIDDENFOLDER"), desc:(businessObject.isHidden)?localizedMessages.get('YES'):localizedMessages.get('NO')} );
    		*/
    		//console.log("PROPERTIES DATA:",properitesData);
    		var thumbnail = businessObject.documentThumbnail;
    		var extensionType = businessObject.extensionType;
    		
    		$scope.DOC_THUMBNAIL = (thumbnail && thumbnail!="") ? ("data:image/png;base64," + thumbnail) : ( (extensionType == undefined) ? null : imageService.getImageByExtension(extensionType) ); 
    		$scope.DOC_DETAILS = properitesData;
    		var parentPath = businessObject.parentPath;
    		$scope.PARENT_PATH = (parentPath) ? parentPath.replace(/>/g , "|") : "";

    		$scope.DOC_PERMISSIONS = JSON.parse(businessObject.objectPermissions);
    		$scope.sort('name');
    		$scope.DOC_FAV_USERS = JSON.parse(businessObject.objectFavorites);

    		var isContentOwner = context.getModel().userVO.isContentOwner;
    		var contextObjectBaseUuid = context.getModel().userVO.objectBaseUuid;
    		var portalcontextObjectBaseUuid = context.getModel().portalVO.objectBaseUuid;	    
    		$scope.SHOW_FAVORITES = false;
    		
    		for(var i=0;i<$scope.DOC_PERMISSIONS.length;i++)
    		{
    			console.log("1: ",$scope.DOC_PERMISSIONS[i].objectBaseUuid," 2:",contextObjectBaseUuid, " PERM:",$scope.DOC_PERMISSIONS[i].permission);
    			$scope.DOC_PERMISSIONS[i].localePermission = localizedMessages.get($scope.DOC_PERMISSIONS[i].permission.toUpperCase());
    			if(($scope.DOC_PERMISSIONS[i].objectBaseUuid == contextObjectBaseUuid && $scope.DOC_PERMISSIONS[i].permission=="Own") || ($scope.DOC_PERMISSIONS[i].objectBaseUuid == portalcontextObjectBaseUuid && $scope.DOC_PERMISSIONS[i].permission=="Own"))
                {
    				/*if(initialBO.isPdfACompliant!=undefined && initialBO.isPdfACompliant!=null && initialBO.isPdfACompliant==="false"){
    					$scope.SHOW_FAVORITES = false;
    				}
    				else*/
    				{
    		            $scope.SHOW_FAVORITES = true;
    				}
						     if(businessObject.extensionType=="folderAttachment")
    		                {
    			             $scope.SHOW_FAVORITES = false;
    		                 }
    	         }
    			else if(isContentOwner == true)
    			{
    				/*if(initialBO.isPdfACompliant!=undefined && initialBO.isPdfACompliant!=null && initialBO.isPdfACompliant==="false"){
    					$scope.SHOW_FAVORITES = false;
    				}
    				else*/
    				{
    					$scope.SHOW_FAVORITES = true;
    				}
    			}
    		}	
    		teamService.getGroupsAndTeamspaces(contextObjectBaseUuid).then(function(data){   			
				$scope.USERS_GROUP_TG = data;			
				for(var i=0;i<$scope.DOC_PERMISSIONS.length;i++){
					for(var j=0;j<$scope.USERS_GROUP_TG.length;j++){
						console.log("data :" ,$scope.USERS_GROUP_TG[j]);
						if(($scope.DOC_PERMISSIONS[i].objectBaseUuid == $scope.USERS_GROUP_TG[j].objectBaseUuid) && $scope.DOC_PERMISSIONS[i].permission=="Own"){
							/*if(initialBO.isPdfACompliant!=undefined && initialBO.isPdfACompliant!=null && initialBO.isPdfACompliant==="false")
							{
		    					$scope.SHOW_FAVORITES = false;
		    				}
		    				else*/
		    				{
		    					$scope.SHOW_FAVORITES = true;
		    				}
				    		break;
				    	}
					}
				}	
		    });
    		
    		if(businessObject.extensionType=="folderAttachment")
    		{
    			$scope.SHOW_FAVORITES = false;
    		}
    		
    		$scope.businessObject = businessObject;
    	});
    };
    
    $scope.showUsersDialog = function()
    {
    	$scope.popupTitle = localizedMessages.get("USERS");
		// commonService.getUsers().then(function(data)
		// {
		// 	$scope.users = data;
		// 	bvDialog.itemListSelection($scope.popupTitle, false, $scope.users, $scope.addSelectedUsers, false);
		// });
		bvDialog.itemListSelection($scope.popupTitle, true, $scope.addSelectedUsers);
    };
    
    $scope.addSelectedUsers = function(selectedItems)
    {
		//console.log("SELECTED USERS: ",selectedItems);
		if(initialBO.isPresentMode)
		{
			presentationService.manageBackDrop();
		}

		if(!selectedItems)
			return;
		var usersObjectBaseUuids = [];
		for (var i = 0; i < selectedItems.length; i++) 
		{
			usersObjectBaseUuids.push(selectedItems[i].objectBaseUuid);
		}
		//console.log("USER BEFORE SENDING:",usersObjectBaseUuids);
		if(usersObjectBaseUuids.length > 0)
		{
			repositoryService.addDocumentToSelectedUsersFavorite(initialBO.objectBaseUuid, angular.toJson(usersObjectBaseUuids)).then(function(data)
	    	{
	    		var newFavs = data.data; 
	    		//console.log("NEW_FAVS:",newFavs);
				$scope.DOC_FAV_USERS = newFavs;
			});
		}		
	};

	$scope.deleteDocFromUserFavorites = function(user)
	{
		repositoryService.removeDocumentFromSelectedUsersFavorite(initialBO.objectBaseUuid, user.objectBaseUuid).then(function(data)
    	{
			for (var i = 0; i < $scope.DOC_FAV_USERS.length; i++) 
			{
			   	if($scope.DOC_FAV_USERS[i].objectBaseUuid == user.objectBaseUuid)
				{
					$scope.DOC_FAV_USERS.splice(i,1);
					break;
				}
			}
		});
	}

    $scope.getDocumentThumbnail = function() 
 	{
 		var obj = $scope.businessObject;
 		if (obj && obj.documentThumbnail != undefined && obj.documentThumbnail != "") 
		{
			return "data:image/png;base64," + obj.documentThumbnail;
		}
		else
		{
			//if(attachment.boName == "FolderBO") attachment.extensionType = "folderAttachment";
			if(!obj || obj.extensionType == undefined)
				return null;
			return imageService.getImageByExtension(obj.extensionType);
		}
	}

    $scope.init();
}
