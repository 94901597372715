setFollowersController.$inject = ['$scope', 'localizedMessages', 'context', 'bvDialog', 'repositoryService', 'repositoryModelService', 'templatesService'];

function setFollowersController($scope, localizedMessages, context, bvDialog, repositoryService, repositoryModelService, templatesService){
	$scope.iconUser = 'resources/assets/svgs/users_small.svg';
	$scope.rightArrow = 'resources/assets/svgs/arrow.svg';
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
    $scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
    $scope.closeIcon = templatesService.getUrl('closeBtn');
    
    var subscribedUsers =  $scope.bvModalControllerInputs.object;
    var docName = $scope.bvModalControllerInputs.docName;
    var docId = $scope.bvModalControllerInputs.docId;
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	
	$scope.currUserFollow = false;
	$scope.currUserDeleted = false;

	$scope.ui = {
		setFollowers 			: localizedMessages.get('SET_FOLLOWERS_FOR'),
		actionBtnTxt 			: localizedMessages.get('OK'),
		closeBtnTxt 			: localizedMessages.get('CANCEL'),
		users 					: localizedMessages.get('USERS'),
		addLabel				: localizedMessages.get('ADD')
	}
	
	$scope.popupTitle = $scope.ui.setFollowers + docName;
	$scope.actionBtnTxt = $scope.ui.actionBtnTxt;
	$scope.closeBtnTxt = $scope.ui.closeBtnTxt;
	$scope.addLabel = $scope.ui.addLabel;
	$scope.showUserList = false;
	
	$scope.usersBtn = function(){
		$scope.showUserList = !$scope.showUserList;
	}
	
	var isUser = {
		currentUser : function(user){
			return user.objectBaseUuid == context.getModel().userVO.objectBaseUuid;
		}
	}
	
	var fix = {
		deleteBtns : function(){
			_.forEach($scope.tableOption.data, function(item){
				if(isUser.owner(item)){
					item.deleteBtnDisabled = true;
				}
				else{
					item.deleteBtnDisabled = false;
				}
			});
		},
		oneOwner : function(){
			var isOneOwner = isUser.oneOwner();
			_.each($scope.tableOption.data, function(item){
				if(isOneOwner){
					item.isOwner ? item.isOwnerDisabled = true : null;
				}
				else{
					item.isOwnerDisabled = false;
				}
				smartSelectionUtils.disableEnableCheckbox(item, item.isOwner);
			});
			fix.selections();
		},
		selections: function(){
			_.each($scope.tableOption.data, function(user){
				if(user.isViewer){
					user.isRespondentDisabled = true;
					user.isOwnerDisabled = true;
				}
				else{
					user.isRespondentDisabled = false;
					!isUser.oneOwner() ? user.isOwnerDisabled = false : null;
				}
				
				if(user.isRespondent || user.isOwner){
					user.isViewerDisabled = true;
				}
				else{
					user.isViewerDisabled = false;
				}
			});
		}
	}
	
	$scope.tableOption = {
		columns : [
 		           	{ 
 		           		title: localizedMessages.get("FOLLOWERS"),
 		           		name: 'name',
 		           		width: 90,
 		           		sorting: true,
 		           		unsortFirstItem : true,
 		           		rows: {
 		           			map: 'name'
 		           		}
 		           	}
		],
		data : [],
		filter: {
			show: true,
			placeholder: localizedMessages.get('FILTER')
		},
		showColumns : false,
		deleteBtn: {
			enable: true,
			click: function(item){
				_.remove($scope.tableOption.data, {objectBaseUuid: item.objectBaseUuid});
				smartSelectionUtils.selectInList([item], false);
				if(item.objectBaseUuid === context.getModel().userVO.objectBaseUuid ){
					$scope.currUserFollow = false;
					$scope.currUserDeleted = true;
				}
				
				// fix.oneOwner();
			}
		}
	}

	var smartSelectionUtils = {}
	var initialComplete = function(smartSelection){
	    smartSelectionUtils = smartSelection.smartSelectionUtils;
		smartSelectionUtils.selectInList(subscribedUsers, true);
		subscribedUsers.forEach(function(item){
			if(isUser.currentUser(item)){
				$scope.currUserFollow = true;
			}
			$scope.tableOption.data = angular.copy(subscribedUsers);
		});
	}
	
	$scope.smartSelectionOptions = {
		initialComplete: initialComplete,
		checkboxDisableFeature: true,
		disableEmptyNodes: true
	};
	
	$scope.instantSelection = function(objs){
		_.forEach(objs, function(item){
			if(!item.selected){
			    _.remove($scope.tableOption.data, {objectBaseUuid: item.objectBaseUuid});
				smartSelectionUtils.selectInList([item], false);
			}
			else{
				if(_.findIndex($scope.tableOption.data, { objectBaseUuid : item.objectBaseUuid }) != -1)
					return;
	
				if(item.type == 'User'){
					var user = angular.copy(item);
					
					if(isUser.currentUser(user)){
						$scope.tableOption.data.splice(0, 0, user);
						$scope.currUserFollow = true;
					}else{
						$scope.tableOption.data.push(user);
					}
				}
			}
		});
	}
	
	var enDisFollowCurrUser = function(){
		var repositoryModel = repositoryModelService.getInstance('DOCUMENTS');
        var replist = repositoryModel.repositoryList();
        if(replist){
            var browseList = replist.browseList;
            for(var ind=0; ind< browseList.length; ind++){
               var obj = browseList[ind];
               if(obj.objectBaseUuid === docId){
                   obj.isSubscribed = $scope.currUserFollow;
               }
            }
        }
	}
	
	$scope.action = function(){
			subscribedUsers = $scope.tableOption.data;
			var userList = [];
			if($scope.currUserDeleted){
				enDisFollowCurrUser();
			}
			for(var index=0; index<subscribedUsers.length; index++){
				userList[index] = subscribedUsers[index].objectBaseUuid;
				if(subscribedUsers[index].objectBaseUuid === context.getModel().userVO.objectBaseUuid){
					enDisFollowCurrUser();
				}
			}
			var userListJson = angular.toJson(userList)
			saveFollowers(userListJson);
			bvModal.close();
	}
	
	var saveFollowers = function(subscribedUsers){
		repositoryService.saveFollowers(docId, subscribedUsers).then(function(){
			console.log('Followers are saved');
		});
	}
	
}

angular
	.module('dialogControllers.service')
	.controller('setFollowersController', setFollowersController)
