function viewFullAgendaDirective(){
    return {
        restrict: 'E',
        scope: {
            callbacks: '=',
            openEvent: '='
        },
        templateUrl: 'modules/meetings/viewfullagenda/viewFullAgenda.tpl.html',
        controller: 'viewfullagendaController'
    }
}