function datepickNewFormatDirective($timeout, $rootScope, context, $state){

  var Datepicker = function(date, className_, format, minDateObject, maxDateObject, callback, keepBlankIfNull) {
      this.minDateObject = minDateObject || null;
      this.maxDateObject = maxDateObject || null;
      this.format = format || $rootScope.formateValue; 
      this.date = date ? moment(date).format(this.format) : moment().format(this.format);
      this.localDate = date || StringUtils.momentL().format(this.format);
      this.className = className_ || '';
      this.callback = callback || null;

      if (date) {
          this.dateToShow = this.date;
      }
      else {
          if (keepBlankIfNull) {
              this.dateToShow = '';
          }
          else {
              this.dateToShow = this.date;
          }
      }
  }

  Datepicker.prototype = {
      constructor: Datepicker,
      assign : function(element, object){
          var dateFormat = this.format;//StringUtils.getLocaleDateFormat(this.format);
          var monthNamesShort = moment.localeData()._monthsShort;
          var self = this;
          if(object){
              object.startingDate = this.date;
              object.startingDateLocal = this.localDate;
              object.dateToShow = this.dateToShow;

              var datePickerOptions = {
                  showPanelButton:true,
                  beforeShow:function(){
                      if(self.className)
                          angular.element('.ui-datepicker').addClass(self.className);
                  },
                  showAnim: 'slideDown',
                  monthNamesShort: monthNamesShort,
                  dateFormat: dateFormat,
                  minDate: this.minDate,
                  maxDate: this.maxDate,
                  onSelect: function(dateText, inst) {
                      var parsedDate = $.datepicker.parseDate(inst.settings.dateFormat || $.datepicker._defaults.dateFormat, dateText, inst.settings);
                      if(object){
                          object.startingDate = moment(parsedDate).format("MM/DD/YYYY");
                          object.startingDateLocal = StringUtils.momentL(parsedDate).format("MM/DD/YYYY");
                          object.dateToShow = moment(parsedDate).format(self.format);
                      }

                      if(self.minDateObject && 'instance' in self.minDateObject)
                          self.minDateObject.instance.element.datepicker('option', 'minDate', parsedDate);
                      if(self.maxDateObject && 'instance' in self.maxDateObject)
                          self.maxDateObject.instance.element.datepicker('option', 'maxDate', parsedDate);

                      if(self.callback && typeof self.callback == 'function')
                          self.callback();
                  }
              }

              if (initialLocale != "en-us") {
                  var datePickerLocale = initialLocale.substring(0, 2);
                  datePickerOptions = jQuery.extend(datePickerOptions, $.datepicker.regional[datePickerLocale]);
              }

              var dp = element.datepicker(datePickerOptions);

              self.element = dp;
              object.instance = self;
          }
      },
      show : function(element){
          element.show();
          element.focus();
      },
      getDate: function(){
          return this.date;
      },
      datePickerResetMinMaxDate: function(){
          this.element.datepicker('option', 'minDate', null);
          this.element.datepicker('option', 'maxDate', null);
      }
  }

  return {
      restrict: 'E',
      replace: true,
      template: '<input readonly ng-model="object.dateToShow" />',
      controller: 'datepickNewFormatController',
      scope: {
          object: '=',
          autoOpen: '=',
          format: '=',
          minDateObject: '=minDate',
          maxDateObject: '=maxDate',
          callback: '=onSelect',
          keepBlankIfNull: '=keepBlankIfNull'
      },
      link : function($scope, element){
          $.datepicker.parseDate = function(format, value) {
              let momentObject = moment(value, format);
              if (!momentObject.isValid()) {
                  momentObject = moment().format(format);
              }
              return momentObject.toDate();
          };
          $.datepicker.formatDate = function (format, value) {
              let momentObject = moment(value);
              if (!momentObject.isValid()) {
                  momentObject = moment();
              }

              return momentObject.format(format);
          };

//            if($scope.minDate)
//                var minDate = moment($scope.minDate.startingDateLocal).toDate();
//            if($scope.maxDate)
//                var maxDate = moment($scope.maxDate.startingDateLocal).toDate();

          var datepicker;
          if($scope.object && 'startingDateLocal' in $scope.object){
              datepicker = new Datepicker($scope.object.startingDateLocal, $scope.object.customClass, $scope.format, $scope.minDateObject, $scope.maxDateObject, $scope.callback, $scope.keepBlankIfNull);
          }
          else{
              datepicker = new Datepicker(null, null, $scope.format, $scope.minDateObject, $scope.maxDateObject, $scope.callback, $scope.keepBlankIfNull);
          }

          datepicker.assign(element, $scope.object);

          element.bind('click', function(){
              datepicker.show(element);
          });

          if($scope.autoOpen && $scope.autoOpen === true || $scope.object && 'autoOpen' in $scope.object && $scope.object.autoOpen === true){
              $timeout(function(){
                  datepicker.show(element);
              });
          }
      }
     
  }
 
}

datepickNewFormatController.$inject = ['$scope', '$rootScope', 'localizedMessages', '$state', 'context'];
function datepickNewFormatController($scope, $rootScope, localizedMessages, $state, context ){
    var dateHelper = new DateHelper(context.getModel().userVO);
    var dateFormats = dateHelper.getDateFormats();
    $rootScope.formateValue = dateFormats.shortDate;		
}