angular
    .module('dialogControllers.service')
	.controller('copyLinkController', copyLinkController);

copyLinkController.$inject = ['$scope', 'localizedMessages', 'clipboard', '$timeout'];

function copyLinkController($scope, localizedMessages, clipboard, $timeout)
{
	$scope.popupTitle 	= localizedMessages.get('COPYLINK');
	var link = $scope.bvModalControllerInputs.link;
	
	$scope.documentName = {}
	$scope.documentName.val = link;
	$scope.documentLinkPlaceHolder = link;
	$scope.selection = true;
	$scope.copyLabel = localizedMessages.get('COPY');

	$scope.copyStatus = "";
	$scope.success = function () 
	{
        $scope.copyStatus = localizedMessages.get("LINK_COPIED");
        $timeout(function()
        {
        	var bvModal = $scope.bvModalControllerInputs.bvModal;
			bvModal.close();
        },1000);
    };

    $scope.fail = function (err) 
    {
        $scope.copyStatus = localizedMessages.get("FAILED_TO_COPY_LINK");
		angular.element('#link_to_copy').select();
        // console.log(err);
    };
}