dayviewController.$inject = ['$scope', 'dayviewService', 'calendarService', 'toppanelService', '$timeout', '$element', 'bvDialog', 'localizedMessages'];

function dayviewController($scope, dayviewService, calendarService, toppanelService, $timeout, $element, bvDialog, localizedMessages){

    $scope.dayview = dayviewService.createDay();
    $scope.events = calendarService.getAllCalendarEvents();
    dayviewService.combineEvents($scope.events.listEventsByDay[$scope.dayview.day]);
    dayviewService.getMoreDataFoDayEvents($scope.events.listEventsByDay[$scope.dayview.day]);

    $scope.title = {
        allDay: localizedMessages.get('ALL-DAY')
    }

    var secondHeight = 0.01386;

    $scope.getHeightEvent = function(startTimeUnix, endTimeUnix){
        return ((endTimeUnix - startTimeUnix) * secondHeight).toFixed();
    }
    $scope.getMarginTopEvent = function(startTimeUnix, timeUnix){
        return ((startTimeUnix - timeUnix) * secondHeight).toFixed() + 'px';
    }
    $scope.getTopCurrentTime = function(currentTime){
        return (currentTime * secondHeight).toFixed();
    }
    $scope.getWidthEvent = function(combined){
        return (100 / (combined.length + 1)) + '%';
    }

    var toppanel = toppanelService.getInstance('MEETINGS');
    
    var next = function(){
        var nextDay = $scope.dayview.getMomentDate().add(1, 'day');
        $scope.dayview = dayviewService.createDay(nextDay);
        dayviewService.combineEvents($scope.events.listEventsByDay[$scope.dayview.day]);
        dayviewService.getMoreDataFoDayEvents($scope.events.listEventsByDay[$scope.dayview.day]);
        toppanel.addEvent('dayTitle', $scope.dayview.dayInfo);
    }

    var prev = function(){
        var prevDay = $scope.dayview.getMomentDate().add(-1, 'day');
        $scope.dayview = dayviewService.createDay(prevDay);
        dayviewService.combineEvents($scope.events.listEventsByDay[$scope.dayview.day]);
        dayviewService.getMoreDataFoDayEvents($scope.events.listEventsByDay[$scope.dayview.day]);
        toppanel.addEvent('dayTitle', $scope.dayview.dayInfo);
    }

    var today = function(){
        $scope.dayview = dayviewService.createDay();
        dayviewService.combineEvents($scope.events.listEventsByDay[$scope.dayview.day]);
        dayviewService.getMoreDataFoDayEvents($scope.events.listEventsByDay[$scope.dayview.day]);
        toppanel.addEvent('dayTitle', $scope.dayview.dayInfo);
    }

    toppanel.addEvent('dayToday', today);
    toppanel.addEvent('prev', prev);
    toppanel.addEvent('next', next);
    toppanel.addEvent('dayTitle', $scope.dayview.dayInfo);
    toppanel.addEvent('currentDayTitle', $scope.dayview.dayInfo);

    $scope.hours = [];
    for(var i = 0 ; i <= 24 ; i++){
        $scope.hours.push({
            title       : moment().hour(i).format('h A'),
            time        : moment().hour(i).minute('00').format('hh:mm A'),
            timeUnix    : i == 24 ? null : moment('01/01/0000 ' + moment().hour(i).minute('00').format('hh:mm A')).unix()
        })
    }

    $scope.clickEvent = function(event){
        //var isEventOwner = event.isEventOwner;

        bvDialog.showMeetingDetailsPanel(event, true, !MobileUtil.isMobileClient());
    }

    $scope.dayview.startInterval();

    $timeout(function(){
        if(MobileUtil.isMobileClient())
            $element.find('.events').scrollTop($scope.getTopCurrentTime($scope.dayview.currentTime) - 230);
        else
            $element.find('.events .mCSB_container')[0].style.top = '-' + ($scope.getTopCurrentTime($scope.dayview.currentTime) - 230) + 'px';
    });

}