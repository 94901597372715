angular
    .module('dialogControllers.service')
    .controller('itemListController', itemListController);

itemListController.$inject = ['$scope', '$filter', 'context', 'localizedMessages', 'bvDialog', 'commonService', 'templatesService', 'filterFilter', 'companyUserService', 'SmartSelection', 'legacyUserService'];

function itemListController($scope, $filter, context, localizedMessages, bvDialog, commonService, templatesService, filterFilter, companyUserService, SmartSelection, legacyUserService)
{
	var showTreeGrid = false;
	var userDataChoice = "SHOW_CURRENT_TS_USERS";
	var existingSelectedUsers = [];
	var bvModal = null;
	$scope.showOptBtnForUser = false;
	$scope.TREE_FLTR_DATA = [];
	$scope.SHOW_TREE_FLTR = false;
//	if($scope.hideCompanyTab == undefined)
//		$scope.hideCompanyTab = false;
	$scope.showNonRespondantsBox=false;
	$scope.isGreyout=false;

	$scope.init = function()
	{
		//$scope.items = [];
		$scope.showCheckAll = false;
		$scope.allItemsChecked = false;
		$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
		$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
		$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';
		$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
		$scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
		$scope.tsGridFilterOptions = {name: ""};
		$scope.treeFilterText = "";
		$scope.customGridFilterOptions = {name: ""};
		$scope.groupOrder=['Portal','Group','TeamSpaceGroup','User'];
		$scope.expandedNodes = [];
		$scope.CHECK_ALL_HEADING = "";
		$scope.selected = {};
		$scope.selectedItems = [];

		$scope.multiSelectFlag = true;
		$scope.showTeamSpaceSelection = true;

		if($scope.bvModalControllerInputs)
		{
			bvModal = $scope.bvModalControllerInputs.bvModal;
			$scope.actionBtnTxt	= $scope.bvModalControllerInputs.actionBtnTxt || localizedMessages.get("OK");
			$scope.showConfirmBeforeCancel = $scope.bvModalControllerInputs.showConfirmBeforeCancel || false;
			$scope.showCheckAll = $scope.bvModalControllerInputs.showCheckAll || false;
			$scope.CHECK_ALL_HEADING = $scope.bvModalControllerInputs.chkAllHeading || "";
			$scope.confirmMessageForCancel = $scope.bvModalControllerInputs.confirmMessageForCancel;
			$scope.instantSelection = $scope.bvModalControllerInputs.instantSelection;
			$scope.popupTitle = $scope.bvModalControllerInputs.popupTitle;
			$scope.selectionDone = $scope.bvModalControllerInputs.selectionDone;
			$scope.mobileActionButton	= $scope.bvModalControllerInputs.mobileActionButton || false;
			$scope.mobileActionButtonImagePath = $scope.bvModalControllerInputs.mobileActionButtonImagePath || "";
			showTreeGrid = $scope.bvModalControllerInputs.showTsTreeGrid;
			userDataChoice = $scope.bvModalControllerInputs.showUserOptions;
			$scope.fromModule=$scope.bvModalControllerInputs.fromModule;
			$scope.isAnonymous=$scope.bvModalControllerInputs.isAnonymous;
			$scope.smartSelection = $scope.bvModalControllerInputs.smartSelectionOptions;

			console.log($scope.fromModule);
			console.log($scope.isAnonymous);
			if(userDataChoice == "SHOW_CURRENT_TS_USERS" || userDataChoice == "SHOW_CUSTOM_USERS")
			{	
				var optBtns = $scope.bvModalControllerInputs.showOptionsButtonsForUsers;
				$scope.multiSelectFlag = (optBtns!=undefined && optBtns) ? false : true;
				$scope.showOptBtnForUser = $scope.bvModalControllerInputs.showOptionsButtonsForUsers;
			}	
		}
		else
		{
			//$scope.showRemoveInsteadOfCheckOption = $scope.showRemoveInsteadOfCheckOption || false;
			$scope.showCheckAll = $scope.showCheckAll || false;
			$scope.CHECK_ALL_HEADING = $scope.chkAllHeading || "";
			showTreeGrid = $scope.showTsTreeGrid;
			userDataChoice = $scope.showUserOptions;
			if(userDataChoice == "SHOW_CURRENT_TS_USERS" || userDataChoice == "SHOW_CUSTOM_USERS")
			{	
				$scope.multiSelectFlag = ($scope.showOptionsButtonsForUsers!=undefined && $scope.showOptionsButtonsForUsers) ? false: true;
				$scope.showOptBtnForUser = $scope.showOptionsButtonsForUsers;
			}	
		}	

		if(showTreeGrid) // Show ts-tree view
		{
			$scope.showTsTreeGrid = true;
			$scope.showTeamSpaceSelection = true;	
			
			if($scope.dataTree && $scope.dataTree.length>0)
			{	
				$scope.expandedNodes = [$scope.dataTree[0]];
			}
			if($scope.hideCompanyTab == true && userDataChoice == 'SHOW_CURRENT_TS_USERS'){
				var currentTsUsers = filterFilter($scope.dataListItems, {type:'User'});
				$scope.dataListItems = angular.copy(currentTsUsers);
			}
		}
		else // show custom view
		{
			$scope.showTsTreeGrid = false;
			if($scope.bvModalControllerInputs && $scope.bvModalControllerInputs.noItemsMsg) {
				$scope.noItemsMsg = $scope.bvModalControllerInputs.noItemsMsg;
			}

			console.log("USER DATA CHOICE:",userDataChoice);
			//showUserOptions: 
				// SHOW_CURRENT_TS_USERS : showCurrentTsUsers,
				// SHOW_CURRENT_TS_USERS_AND_GRP : showCurrentTsUsersAndGrp,
				// SHOW_ALL_USERS : showAllUsers,
				// SHOW_CUSTOM_USERS : showCustomUsers
			switch(userDataChoice)
			{
				case "SHOW_CURRENT_TS_USERS" : 
					var currentTsUsers = filterFilter($scope.dataListItems, {type:'User'});
					$scope.items = angular.copy(currentTsUsers);
					break;
				case "SHOW_CURRENT_TS_USERS_AND_GRP" : 
					$scope.items = angular.copy($scope.dataListItems);
					break;
				case "SHOW_ALL_USERS" : 
					var currentTsUsers = filterFilter($scope.dataListItems, {type:'User'});
					$scope.items = angular.copy(currentTsUsers);
					break;
				case "SHOW_CUSTOM_USERS" : 
					if($scope.bvModalControllerInputs && $scope.bvModalControllerInputs.items)
					{
						$scope.items = angular.copy($scope.bvModalControllerInputs.items);
					}	
					else
					{
						//$scope.items = angular.copy($scope.items);
					}
					break;									

			}
			if($scope.items) {
				$scope.showNoItemsMessage = (($scope.items.length == null || $scope.items.length == 0) && $scope.noItemsMsg != null);
			}
		}

		if(existingSelectedUsers && existingSelectedUsers.length > 0)
		{
			/*var listItems = $scope.dataListItems.filter(function (el) {
				  return $.inArray(el.objectBaseUuid, existingSelectedUsers) > -1;
				});

			for(var i=0; i<listItems.length; i++)
			{
				listItems[i].selected=true;
				if($scope.selectedItems)
				{
					$scope.selectedItems.push(listItems[i]);	
				}
				else
				{
					$scope.selectedItems = [];
					$scope.selectedItems.push(listItems[i]);	
				}
			}*/

			var nodesArray = getFlattenNodesArray($scope.expandedNodes[0]);
			var listItems = nodesArray.filter(function (el) {
				  return $.inArray(el.objectBaseUuid, existingSelectedUsers) > -1;
			});

			for(var i=0; i<listItems.length; i++)
			{
				listItems[i].selected=true;
				if($scope.selectedItems)
				{
					$scope.selectedItems.push(listItems[i]);	
				}
				else
				{
					$scope.selectedItems = [];
					$scope.selectedItems.push(listItems[i]);	
				}
			}
		}

		console.log($scope.items);
		$scope.okLbl = localizedMessages.get("OK");
		$scope.cancelLbl = localizedMessages.get("CANCEL");
		$scope.closeBtnTxt 	= localizedMessages.get("CANCEL");
		$scope.filterLbl = localizedMessages.get("FILTER");
		$scope.LBL_CHECK_ALL = localizedMessages.get("CHECK_ALL");
		$scope.LBL_COMPANY = localizedMessages.get("COMPANY");
		$scope.LBL_TEAM_SPACE = localizedMessages.get("TEAM_SPACE");
		$scope.LBL_NON_RESPONDANTS=localizedMessages.get("ALERT_NON_RESPONDANTS");
	};

 	$scope.dataTree = angular.copy(companyUserService.getCompanyTree());
 	$scope.expandedNodes = [$scope.dataTree[0]];
 	$scope.dataListItems = angular.copy(companyUserService.getPortalGroupsAndUsers());
 	$scope.allCompanyUsers = angular.copy(companyUserService.getAllCompanyUsers());
 	//$scope.allCompanyUsers = angular.copy(companyUserService.getPortalGroupsAndUsers());
 	$scope.init();	

	$scope.save = function() 
	{
		console.log($scope.selectedItems);
		$scope.selectionDone($scope.selectedItems);
    };

    $scope.cancel = function() 
    {
	    $scope.selectionDone(false);
	};

	$scope.action = function() 
	{
		console.log($scope.selectedItems);
		$scope.selectionDone($scope.selectedItems);
		if(bvModal)
			bvModal.close();
    };

    $scope.close = function() 
    {
    	if($scope.showConfirmBeforeCancel)
    	{
    		if($scope.selectedItems.length > 0)
    		{
    			bvDialog.confirm($scope.confirmMessageForCancel, $scope.setSelectionAndCloseModal, $scope.afterNoToCancel);
    		}
    		else
    		{
    			$scope.setSelectionAndCloseModal();
    		}
    	}
    	else
    	{	
	    	$scope.setSelectionAndCloseModal();
	    }
	};

	$scope.afterNoToCancel = function()
	{

	}

	$scope.setSelectionAndCloseModal = function() 
	{
		$scope.selectionDone(false);
		if(bvModal)
	    	bvModal.close();
	}

	$scope.changeTreeItemSelection = function (event, node, flag)
	{
		if(!$scope.smartSelection){
			node.selected = flag;
			//var treeSelectedItems = [];
			var selectChildNodes = function(node)
		  	{
		  		node.forEach(function(nodeEle) 
		  		{
		  			nodeEle.selected = flag;
		  			// if(nodeEle.type == "User")
		  			// {
		  			// 	treeSelectedItems.push(nodeEle);
		  			// 	//$scope.selectedItems.push(nodeEle);
		  			// }
		  			if(nodeEle.children && nodeEle.children.length>0)
		  			{
		  				selectChildNodes(nodeEle.children);
		  			}
		  		});
		  	}	
		  	selectChildNodes(node.children);
		  	if(node.type=="Company" && node.children.length>0)
		  	{
		  		if(node.selected)
		  		{	
		  			node.children.forEach(function(nodeEle)
		  			{
		  				$scope.selectedItems.push(nodeEle);
		  			});
		  		}
		  		else
		  		{
	  				node.children.forEach(function(nodeEle)
		  			{
		  				var selectedIndex = $scope.selectedItems.indexOf(nodeEle);
						$scope.selectedItems.splice(selectedIndex,1);
		  			});	
		  		}
		  	}
		  	else
		  	{
		  		if(node.selected)
		  		{
		  			$scope.selectedItems.push(node);
		  		}
		  		else
		  		{
		  			var selectedIndex = $scope.selectedItems.indexOf(node);
					$scope.selectedItems.splice(selectedIndex,1);
		  		}
		  	}
		  	
		}
		else{
			$scope.selectedItems = smartSelectionUtils.selectInList([node], !node.selected);
		}
		
	  	if($scope.instantSelection)
		{
  			$scope.instantSelection($scope.selectedItems);
		}
	  	
	}

	$scope.changeSelection = function (event, item, flag)
	{
		if($scope.checkboxDisableFeature === true && ( item.checkboxDisabled === true || item.disabled === true ))
			return;
		
		item.selected = flag;
		$scope.selectionChange(event, item);
	}

	$scope.changeCustomGridSelection = function (event, item, flag)
	{
		if($scope.isGreyout)
		{
			return;
		}
		item.selected = !item.selected;
		//item.selected = flag;
		$scope.selectionChange(event, item);
	}

	$scope.selectAllItems = function(flag)
	{
		if($scope.isGreyout)
		{
			return;
		}
		$scope.allItemsChecked = flag;
		if(flag)
		{	
			for(var i=0;i<$scope.items.length;i++)
			{
				$scope.items[i].selected = flag;
			}
			$scope.selectedItems = angular.copy($scope.items);
		}
		else
		{	
			for(var i=0;i<$scope.items.length;i++)
			{
				$scope.items[i].selected = flag;
			}
			$scope.selectedItems = [];
		}	
		if($scope.instantSelection)
		{
			$scope.instantSelection($scope.selectedItems);
		}	
	}
	$scope.checkUsersExists=function(item,list)
	{
		for(var i=0;i<list.length;i++)
			{
				if(list[i].objectBaseUuid==item.objectBaseUuid)
					{
						return i;
					}
			}
		return -1;
	}
	$scope.selectAllNonRespondents=function(flag)
	{
		$scope.showNonRespondantsBox=flag;
		
		if($scope.fromModule=="surveys")
		{
			if(!$scope.isAnonymous)
			{
				for(var i=0;i<$scope.items.length;i++)
				{
					if($scope.items[i].value=="notResponded" || $scope.items[i].value=="inProgress")
						{
							if(flag)
							{
								$scope.items[i].selected = flag;
								var selectedIndex = $scope.selectedItems.indexOf($scope.items[i]);
								if($scope.checkUsersExists($scope.items[i],$scope.selectedItems)==-1)
								{
								$scope.selectedItems.push($scope.items[i]);
								}
							}else{
								$scope.items[i].selected = flag;
								var index=$scope.checkUsersExists($scope.items[i],$scope.selectedItems);
								if(index!=-1)
									$scope.selectedItems.splice(index,1);
							}
					}
				}
			}
			else
			{
				$scope.isGreyout=flag;
				if(flag)
				{
					
					$scope.selectedItems=[];
					$scope.allItemsChecked=false;
					for(var i=0;i<$scope.items.length;i++)
					{
						$scope.items[i].selected = false;
						
						if($scope.items[i].value=="notResponded" || $scope.items[i].value=="inProgress")
							{
								if($scope.checkUsersExists($scope.items[i],$scope.selectedItems)==-1)
								{
									$scope.selectedItems.push($scope.items[i]);
								}
							}
					}
				}
				else
				{
					$scope.selectedItems=[];
				}
			}
		}
		if($scope.instantSelection)
		{
			$scope.instantSelection($scope.selectedItems);
		}
	}

	$scope.selectionChange = function(event,item)
	{
		//console.log("ITEM:",item);
		if(!item.selected)
		{
		//change as part of MEETX-22504 (NBV Alert function picks wrong users if "select all" used)
		if($scope.selectedItems!=undefined)
		{
		for(var i=0;i<$scope.selectedItems.length;i++)
			{
				if(item.objectBaseUuid==$scope.selectedItems[i].objectBaseUuid)
				{
					$scope.selectedItems.splice(i,1);
					break;
				}
			}
		}
		}
		else
		{
			if(!showTreeGrid)
			{
				if($scope.multiSelectFlag)
				{
					$scope.selectedItems.push(item);
				}
				else
				{
					if($scope.selectedItems.length > 0)
					{
						$scope.selectedItems[0].selected = false;
					}	
					$scope.selectedItems = [];
					$scope.selectedItems.push(item);
				}
			} 
			else
			{
				$scope.selectedItems.push(item);
			}
		}
		//console.log("SELECTED-ITEMES:",$scope.selectedItems);
		if($scope.instantSelection)
		{
			if($scope.smartSelection){
				$scope.selectedItems = smartSelectionUtils.selectInList([item], item.selected);
			}
			//console.log("SELECTED-ITEMES:",$scope.selectedItems);
			$scope.instantSelection($scope.selectedItems);
		}
		
		//event.stopPropagation();
	}

	$scope.removeItem = function(customItem)
	{
		for(var i=0;i<$scope.items.length;i++)
		{
			if(customItem.objectBaseUuid==$scope.items[i].objectBaseUuid)
			{
				var itemTobeRemoved = $scope.items[i];
				$scope.items.splice(i,1);
				$scope.instantSelection(itemTobeRemoved);
				break;
			}
		}	
	};

	$scope.checkFilter = function(filterValue)
	{
		if(filterValue)
		{
			var filterValueTrm =  filterValue.trim();
			if(filterValueTrm==="")
			{
				$scope.SHOW_TREE_FLTR = false;
				$scope.TREE_FLTR_DATA = [];
			}	
			else
			{
				$scope.SHOW_TREE_FLTR = true;
				$scope.TREE_FLTR_DATA = $filter('filter')($scope.allCompanyUsers, filterValue);
			}	
		}
		else
		{
			$scope.SHOW_TREE_FLTR = false;
			$scope.TREE_FLTR_DATA = [];
		}
	}

	templatesService.setBootstrapDialogTemplate();

	function getFlattenNodesArray(input)
	{
		if(input.children && input.children.length > 0)
		{
			var output = [];
			output.push(input);
			for(var i=0; i<input.children.length; i++)
			{
				output = output.concat(getFlattenNodesArray(input.children[i]));
			}

			return output;
		}
		else
		{
			var output = [];
			output.push(input);
			return output;
		}
	}

	if($scope.smartSelection){

		var settings = "settings" in $scope.smartSelection ? $scope.smartSelection.settings : undefined;
		
		if(showTreeGrid){
			var smartSelectionUtils = new SmartSelection($scope.dataTree, $scope.dataListItems, settings);
		} else {
			var smartSelectionUtils = new SmartSelection($scope.dataTree, $scope.items, settings);	
		}
		
		$scope.smartSelection.smartSelectionUtils = smartSelectionUtils;

		if($scope.smartSelection.initialComplete !== undefined && typeof $scope.smartSelection.initialComplete == "function"){
			$scope.smartSelection.initialComplete($scope.smartSelection);
		}
	
		if($scope.smartSelection.disableEmptyNodes){
			smartSelectionUtils.selectTeamSpaceList(smartSelectionUtils.disableEmptyNodes($scope.dataTree));
		}
		
		if($scope.smartSelection.checkboxDisableFeature){
			$scope.checkboxDisableFeature = $scope.smartSelection.checkboxDisableFeature;
		}
	}
	
//	if($scope.smartSelection.disableFeature){
//		smartSelectionUtils.disableEmptyNodes($scope.dataTree);
//	}
	
	
}
