function loadingSpinner(){
	var spinner = {}

    this.$get = function (){

    	return {
	    	start : function(){
	    		spinner.val = true;
	    	},
	    	complete : function(){
	    		spinner.val = false;
	    	},
	    	spinner : function(){
	    		return spinner;
	    	}
    	}
    }
}