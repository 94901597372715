angular
    .module('dialogControllers.service')
	.controller('moduleCreatorsController',  moduleCreatorsController);

moduleCreatorsController.$inject = ['$scope', 'localizedMessages', 'bvDialog', 'commonService', '$timeout', 'homeContext', 'homeService', 'bvDialogService', 'constants', 'templatesService'];

function moduleCreatorsController($scope, localizedMessages, bvDialog, commonService, $timeout, homeContext, homeService, bvDialogService, constants, templatesService){
	$scope.leftArrow = 'resources/assets/svgs/backarrow.svg';
	$scope.rightArrow = 'resources/assets/svgs/arrow.svg';
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
    $scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
    $scope.closeIcon = templatesService.getUrl('closeBtn');
    var bvModal = $scope.bvModalControllerInputs.bvModal;
    
    $scope.toSaveModuleCreatorsObj = [];
    $scope.toRemoveModuleCreatorsObj = [];
	$scope.moduleCreators = $scope.bvModalControllerInputs.moduleCreatorsList;
	
	if($scope.moduleCreators != "" && $scope.moduleCreators != undefined){
		$scope.moduleCreators = angular.fromJson($scope.moduleCreators);
		$scope.toSaveModuleCreatorsObj = angular.copy($scope.moduleCreators);
		$scope.toRemoveModuleCreatorsObj = angular.copy($scope.moduleCreators);
	}
	for(var i=0;i<$scope.moduleCreators.length;i++){
		$scope.moduleCreators[i].name = localizedMessages.get(($scope.moduleCreators[i].id).toUpperCase().replace(" ", ""));
	}
	for(var i=0;i<$scope.toSaveModuleCreatorsObj.length;i++){
		$scope.toSaveModuleCreatorsObj[i].users = [];
		$scope.toRemoveModuleCreatorsObj[i].users= [];
	}
	                     
    
    $scope.showOnlyUsers = 'SHOW_CURRENT_TS_USERS';
    
    $scope.dialogTitle 										= localizedMessages.get('MODULE_CREATORS');
    $scope.popupTitle 										= localizedMessages.get('MODULE_CREATORS');
    $scope.closeBtnTxt 										= localizedMessages.get('CANCEL');
    $scope.saveButtonLabel 									= localizedMessages.get('SAVE');
    $scope.actionBtnTxt 									= localizedMessages.get('SAVE');
    $scope.users											= localizedMessages.get('USERS');
    $scope.creators											= localizedMessages.get('CREATORS');
    $scope.moduleHeading								    = localizedMessages.get('MODULE');
    $scope.add												= localizedMessages.get('ADD');
    $scope.cancelConfirmMsg 								= localizedMessages.get("MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED");
   
	$scope.clickedModule = $scope.moduleCreators[0];
    $scope.clickedModule.users = $scope.moduleCreators[0].users;
	$scope.clickedModuleIndex = 0;
    $scope.showUserList = false;
    $scope.selectedItems = [];
    
	$scope.moduleClick = function(index){
		$scope.clickedModuleIndex = index;
		$scope.clickedModule = $scope.moduleCreators[$scope.clickedModuleIndex];
	}
	var exitModuleCreators = function() {
		if(bvModal){
			bvModal.close();
		}
	}
    $scope.save= function(){
		homeService.assignModuleCreators(angular.toJson($scope.toSaveModuleCreatorsObj), angular.toJson($scope.toRemoveModuleCreatorsObj)).then(function(data){
			if(data && data.data){
				if((angular.fromJson(data.data)).boName == "SuccessBO")
					exitModuleCreators();
			}
		});
    };
	$scope.cancelEdit = function() {
		bvModal.close();
	}
    $scope.cancelChanges = function(){
    	bvDialog.confirm($scope.cancelConfirmMsg, $scope.cancelEdit);
    }
	$scope.usersBtn = function(){
		$scope.showUserList = !$scope.showUserList;
	};
	
	$scope.showModuleCreatorPerms = function(module, index){
		$scope.clickedModule = module;
		$scope.selectedModuleIndex = index;
	};
	
//	$scope.removeClick = function(user, index){
//		$scope.clickedModule.users.splice(index,1)
//	};
	
	$scope.selectUsers = function(clickedModuleIndex){
		$scope.addSelectedUser($scope.selectedItems, clickedModuleIndex);
    };
    
	function getUpperCaseName(array){
		if(array){
			for(var i = 0 ; i < array.length ; i++){
				var type = '';
				for(var k = 0 ; k < array[i].type.length ; k++)
				{
					if(array[i].type[k] == array[i].type[k].toUpperCase()){
						type = type + array[i].type[k];
					}
				}
				type = constants.get('GET_SHORT_OBJECT_NAME_' + type);
				if(array[i].typeM != type){
					array[i].typeM = type;
				}
			}
		}
		return array;
	};
	
	var applyUser = function(name, type, objectBaseUuid, clickedModuleIndex){
		if($scope.clickedModule.users){
			var existingObj = false;
			$scope.clickedModule.users.forEach(function(obj){
				if(obj.objectBaseUuid == objectBaseUuid){
					existingObj = true;
				}
			});
			
			if(!existingObj){
				$scope.clickedModule.users.push({
					name : name,
					type: type,
					objectBaseUuid: objectBaseUuid
				});
				$scope.clickedModule.users = getUpperCaseName($scope.clickedModule.users);
				if($scope.toRemoveModuleCreatorsObj[clickedModuleIndex].users.length > 0 && $scope.toRemoveModuleCreatorsObj[clickedModuleIndex].users.indexOf(objectBaseUuid) > -1){
					$scope.toRemoveModuleCreatorsObj[clickedModuleIndex].users.splice($scope.toRemoveModuleCreatorsObj[clickedModuleIndex].users.indexOf(objectBaseUuid) , 1);
				}else{
					$scope.toSaveModuleCreatorsObj[clickedModuleIndex].users.push(objectBaseUuid);
				}
			}
		}
		else{
			bvDialog.showMessage(localizedMessages.get("UNABLE_TO_ADD_USERS"));
		}
	};
	
	var checkExist = function(objectBaseUuid){
		var list = $scope.clickedModule.users;
		for(var i = 0 ; i < list.length ; i++){
			if(list[i].objectBaseUuid == objectBaseUuid){
				return true;
			}
		}
		return false;
	};
	
	$scope.addSelectedUser = function(selectedItems, clickedModuleIndex){
		$scope.selectedUsers = selectedItems;
		if($scope.selectedUsers!=undefined && $scope.selectedUsers.length >0 )
		{
			for(var i in $scope.selectedUsers) {
				applyUser($scope.selectedUsers[i].name, $scope.selectedUsers[i].type, $scope.selectedUsers[i].objectBaseUuid, clickedModuleIndex)
			}
		}
	};
    $scope.close = function()
	{
    	$scope.cancelChanges();
	}
	$scope.instantSelection = function(selectedItems){
		$scope.selectedItems = selectedItems;
	};
	$scope.removeUser = function(clickedModule, uuid, clickedModuleIndex, index){
		if($scope.toSaveModuleCreatorsObj[clickedModuleIndex].users.length > 0 && $scope.toSaveModuleCreatorsObj[clickedModuleIndex].users.indexOf(uuid) != -1){
				$scope.toSaveModuleCreatorsObj[clickedModuleIndex].users.splice($scope.toSaveModuleCreatorsObj[clickedModuleIndex].users.indexOf(uuid),1);
				$scope.moduleCreators[clickedModuleIndex].users.splice(index, 1);
			}
		else{
			$scope.toRemoveModuleCreatorsObj[clickedModuleIndex].users.push(uuid);
			$scope.moduleCreators[clickedModuleIndex].users.splice(index, 1);
			}	
	};
}