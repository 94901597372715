attachmentsController.$inject = ['$scope', 'localizedMessages', 'templatesService', '$timeout', '$rootScope'];

function attachmentsController($scope, localizedMessages, templatesService, $timeout, $rootScope) {

    var bvModal             = $scope.bvModalControllerInputs.bvModal;
    var onSelectedDone      = $scope.bvModalControllerInputs.onSelectedDone;
    var onlyFiles           = $scope.bvModalControllerInputs.onlyFiles;
    var enableMultiSelect   = $scope.bvModalControllerInputs.enableMultiSelect;
    var popupTitle          = $scope.bvModalControllerInputs.popupTitle;
    var permissionCheck     = $scope.bvModalControllerInputs.permissionChec;

    $scope.actionBtnTxt = localizedMessages.get('ADD');
    $scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
    $scope.popupTitle = localizedMessages.get("ATTACHMENTS_TITLE");
    $scope.enableActionBtn = false;
    $scope.selectedItemsCount = false;

    if(StringUtils.isNullEmpty(onlyFiles))
        onlyFiles = true;

    if(StringUtils.isNullEmpty(enableMultiSelect))
        enableMultiSelect = true;

    $scope.attachTitle =  popupTitle;

    $scope.closeIcon = templatesService.getUrl('closeBtn');

    $scope.bvModalControllerInputs.closeButton = function(result) {
        if($scope.cancel && typeof $scope.cancel == 'function')
            $scope.cancel();
        bvModal.close();
    };

    var callback = function(action, cancel){
        $scope.action = action;
        $scope.cancel = cancel;
    }

    $scope.attachmentObject ={ popupTitle:popupTitle, multiSelect: enableMultiSelect, onlyFiles: onlyFiles, permissionCheck: permissionCheck, callback: callback};

    $scope.selectedFiles = function(data) {
        var noOfFiles = data.length
        for (var i = 0; i<noOfFiles;i++)
        {
            if(data[i].conversionStatus!="CONVERTED" && data[i].boName != 'FolderBO')
            {
                data.splice(i,1);
                noOfFiles -= 1;
                i--;
            }
        }

        onSelectedDone(data);
        bvModal.close();
    };

    $scope.$on('selection:updated', function(event,allSelectedItems) {
        $scope.allSelectedItems = allSelectedItems;
        $scope.selectedItemsCount = $scope.allSelectedItems.length > 0 ? true : false;
        $scope.enableActionBtn = $scope.selectedItemsCount;
    });
   
}