 bvDialogMobile.$inject = ['bvDialogService', 'templatesService', '$modal', 'localizedMessages', 'legacyUserService'];

function bvDialogMobile(bvDialogService, templatesService, modal, localizedMessages, legacyUserService){
	
	var service = {
			
			mobileInfo : function(){
				bvDialogService.showBvModal({
					windowClass : 'bv-support',
					buttons : null,
					controller : 'supportController',
					bodyTemplate : 'modules/login/Support.tpl.html',
					inputs	:{
						isLoginSupport : true
					}
				});
			},
			
			lockDevice : function(){
				bvDialogService.showBvModal({
					windowClass : 'bv-support',
					buttons : null,
					controller : 'lockDeviceController',
					bodyTemplate : 'modules/login/LockDevice.tpl.html',
					inputs	:{
						isLoginSupport : true
					}
				});
			},
			
			showMeetingDetails : function(selectedEvent){
				bvDialogService.showBvModal({
					windowClass  : 'bv-conf-mobile-details',
					buttons      : null,
					controller   : 'showMeetingDetailsController',
					bodyTemplate : 'modules/conference/ConfMobileDetails.tpl.html',
					inputs       :  {
										selectedEvent: selectedEvent
									}
				});
			},

			showMeetingAttendees : function(selectedEvent){
				bvDialogService.showBvModal({
					windowClass  :'bv-conf-mobile-attendee',
					buttons      : null,
					controller   : 'showMeetingAttendeesController',
					bodyTemplate :'modules/conference/ConfMobilePeople.tpl.html',
					inputs       :  {
										selectedEvent: selectedEvent
									}
				});
			},
			
			searchMobileDialog : function(){
				bvDialogService.showBvModal({
					windowClass : 'bv-search-results-mobile',
					backdropClass: 'bv-search-results-mobile-backdrop',
					controller : 'searchResultsMobileController',
					bodyTemplate : 'modules/home/search/searchResultsMobile.template.html',
					buttons : null
				});
			},

			mobileMeetingDetails : function(events, selectedUuid) {
				bvDialogService.showBvModal({
					windowClass : 'bv-mobile-meeting-details',
					controller : 'meetingMobileDetailsController',
					bodyTemplate : !legacyUserService.isNasdaqUI ? 'modules/meetings/meetingMobileDetails.tpl.html' : 'modules/meetings/meetingMobileDetailsReact.tpl.html',
					buttons      : null,
					inputs : {			
						events : events,
						selectedUuid : selectedUuid
					},
					showClose : false,
					showBack : true
				});
			},
			
			showMobileConfDialog : function() {
				bvDialogService.showBvModal({
					windowClass : 'bv-event-presentation',
					buttons : null,
					showClose : false,
					keyboard : false,
					backdrop: 'static',
					controller : 'EventPresentationController',
					bodyTemplate : 'modules/conference/EventMobilePresentation.tpl.html'
				});
			},
			
			showMobileEventAttendees : function(){
				bvDialogService.showBvModal({
					windowClass  :'bv-event-presentation',
					buttons      : null,
					controller   : 'EventPresentationController',
					bodyTemplate :'modules/conference/EventMobilePeople.tpl.html',
				});
			},
					
			meobileComposeMessage : function(messageType,businessObjects) {
				bvDialogService.showBvModal({
					windowClass : 'bv-mobile-compose-message',
//					backdropClass: 'bv-mobile-compose-message-backdrop',
					controller : 'ComposeMessageController',
					bodyTemplate : templatesService.getUrl('ComposeMessageMobile'), //'modules/meetings/mobileComposeMessage.tpl.html',
					buttons      : null,
					inputs : {	
						backdrop:false,
						messageType : messageType,
						mobileActionButton: true,
						businessObjects: businessObjects,
						mobileActionButtonImagePath: 'resources/assets/images/android_icons_send_white_2x.png'
					},
					showClose : true
				});
			},

			mobileOpenMessage : function(selectedMessage) {
				bvDialogService.showBvModal({
					windowClass : 'bv-mobile-compose-message',
//					backdropClass: 'bv-mobile-compose-message-backdrop',
					controller : 'OpenMessageMobileController',
					bodyTemplate : templatesService.getUrl('OpenMessageMobile'), //'modules/meetings/OpenMessageMobile.tpl.html',
					buttons      : null,
					inputs : {
						backdrop:false,
						selectedMessage : selectedMessage,
						showMobileMenu: !MobileUtil.isSurface() ? true : null
					},
					showClose : !MobileUtil.isSurface() ? false : null,
					templateCustomButtonsUrl : MobileUtil.isSurface() ? templatesService.getUrl('OpenMessageMobileCustomButtons') : null,
					showBack : !MobileUtil.isSurface() ? true : null
				});
			},
			
			// itemListSelection : function(popupTitle, items, selectionDone, multiSelectFlag, grouping, showCheckAll, actionBtnTxt, instantSelection, showConfirmBeforeCancel, confirmMessageForCancel ){
			// 	bvDialogService.showBvModal({
			// 		windowClass : 'bv-item-list-dialog-mobile',
			// 		controller : 'itemListController',
			// 		bodyTemplate : 'directives/bvitemlist/bvItemList.template.html',
			// 		inputs : {
			// 			popupTitle: popupTitle,
			// 			items: items,
			// 			selectionDone: selectionDone,
			// 			instantSelection: instantSelection,
			// 			multiSelectFlag: multiSelectFlag,
			// 			grouping: grouping,
			// 			showCheckAll : showCheckAll,
			// 			actionBtnTxt: actionBtnTxt,
			// 			showConfirmBeforeCancel : showConfirmBeforeCancel,
			// 			confirmMessageForCancel : confirmMessageForCancel,
			// 			mobileActionButton: true,
			// 			mobileActionButtonImagePath: 'resources/assets/images/android_icons_add_white_2x.png'
			// 		},
			// 		buttons : null					
			// 	});
			// },

			//showUserOptions: 
			// SHOW_CURRENT_TS_USERS : showCurrentTsUsers,
			// SHOW_CURRENT_TS_USERS_AND_GRP : showCurrentTsUsersAndGrp,
			// SHOW_ALL_USERS : showAllUsers,
			// SHOW_CUSTOM_USERS : showCustomUsers

			itemListSelection : function(popupTitle, showTsTreeGrid, selectionDone, showUserOptions, showOptionsButtonsForUsers, items, instantSelection,  actionBtnTxt, showConfirmBeforeCancel, confirmMessageForCancel ){
				bvDialogService.showBvModal({
					windowClass : 'bv-item-list-dialog-mobile',
					controller : 'itemListController',
					bodyTemplate : 'directives/bvitemlist/bvItemList.template.html',
					inputs : {
						popupTitle: popupTitle,
						showTsTreeGrid: showTsTreeGrid,
						selectionDone: selectionDone,
						showUserOptions: showUserOptions,
						showOptionsButtonsForUsers: showOptionsButtonsForUsers,
						items: items,
						instantSelection: instantSelection,
						actionBtnTxt: actionBtnTxt,
						showConfirmBeforeCancel : showConfirmBeforeCancel,
						confirmMessageForCancel : confirmMessageForCancel,
						mobileActionButton: true,
						mobileActionButtonImagePath: 'resources/assets/images/android_icons_add_white_2x.png'
					},
					buttons : null					
				});
			},

			attachDocument: function (onSelectedDone, onlyFiles, enableMultiSelect, popupTitle, permissionCheck)
			{
				var showMove = new modal.open({
		            windowClass : 	'bv-modal-window-new-mobile-attachment',
		   	        templateUrl:  	!legacyUserService.isNasdaqUI ? 'modules/legacyuser/mobile/templates/AttachmentsMobile.tpl.html' : 'modules/dialogs/AttachmentsMobile.tpl.html',
		            controller: function ($scope, $modalInstance, backUtiltiyService, repositoryModelService, repositoryService) {	                	  
		            	
		            	$scope.middleBarObject = {};
		            	var backUtiltiy  = backUtiltiyService.getInstance("FILE_CHOOSER");
		            	var repositoryModel = repositoryModelService.getInstance("FILE_CHOOSER");

		            	$scope.getDocumentsForParentBaseUuid = function (baseUuid)
		                {
		                	repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
		                	{
		                		repositoryModel.repositoryList(data.data);
		                	});
		                };
		                
		                $scope.middleBarObject = backUtiltiy;
		                $scope.middleBarObject.showBack = false;
		                
		            	$scope.back = function()
		            	{
		            		var folderObject = backUtiltiy.popBackStack();

		            		if(folderObject)
		            		{
		            			sessionStorage.setItem("FILE_CHOOSER-lastSelectedFolder-"+ $scope.$root.SELECTED_PORTAL_BASEUUID, JSON.stringify(folderObject));
		            			$scope.getDocumentsForParentBaseUuid(folderObject.objectBaseUuid);
		            		}
		            	}
		            	
		            	$scope.backIcon = 'resources/assets/svgs/backarrow.svg';
		            	
		            	
		            	if(StringUtils.isNullEmpty(popupTitle))
		            		popupTitle = localizedMessages.get("ATTACHMENTS_TITLE");
		            	
		            	if(StringUtils.isNullEmpty(onlyFiles))
		            		onlyFiles = true;
		            	
		            	if(StringUtils.isNullEmpty(enableMultiSelect))
		            		enableMultiSelect = true;
		            	
		            	$scope.attachTitle =  popupTitle;
		            	
		                $scope.closeIcon = templatesService.getUrl('closeBtn');
		                
		                $scope.attachmentObject ={ popupTitle:popupTitle, multiSelect: enableMultiSelect, onlyFiles: onlyFiles, permissionCheck: permissionCheck};
		                
		                $scope.getAttachments = function() {	                	
							var fileChooser = angular.element(document.querySelector('[bv-repository-list]')).scope()
        		        	if(fileChooser && fileChooser.selectAttachment) {
        		        		fileChooser.selectAttachment();
        		        	}
        		        	else {
								fileChooser = angular.element(document.querySelector('.attachment-list')).scope()
	        		        	if(fileChooser && fileChooser.selectAttachment)
	        		        		fileChooser.selectAttachment();
        		        	}
        		        }

        		        $scope.selectedFiles = function(data) {	
        		        	onSelectedDone(data);
	        		        $modalInstance.close(data);
		                }.bind(this);
		                
		                $scope.close = function(result) {
		                	//Function.prototype.apply.bind($scope.selectedFiles);
		                	//Function.prototype.call($scope.selectedFiles)
		                	//$scope.selectedFiles();
//		                	var data = Function.bind($scope.selectedFiles);
//		                	data.call(this)
		                    $modalInstance.dismiss('cancel');
		                };
		            }

				});
				return showMove.result;
			},
			
			eventsDialog : function(event, cell, index){
				bvDialogService.showBvModal({
					windowClass  : 'bv-events bv-events-mobile',
					buttons      : null,
					controller   : 'eventsDialogController',
					bodyTemplate : 'modules/calendar/EventsDialog.tpl.html',
					inputs       :  {
						event: event,
						cell: cell,
						index: index
					},
					backdropClass: 'bv-events-backdrop',
					showHeader : false,
					showClose : true
				});
			},
			
			documentViewerActions :function(businessObject) {
				bvDialogService.showBvModal({
					windowClass : 'bv-mobile-documentviewer-actions',
					controller : 'documentViewerActionsController',
					bodyTemplate : 'modules/repository/DocumentViewerActions.tpl.html',
					buttons      : null,
					showClose : true,
					inputs : {
						businessObject : businessObject
					}
				});
			},
			
			annotaionsListDialog :function(pdfDetails, documentDrawingAnnotationMap, documentTextAnnotationMap, documentPinAnnotationMap) {
				bvDialogService.showBvModal({
					windowClass : 'bv-mobile-documentviewer-actions',
					controller : 'annotationsListController',
					bodyTemplate : 'modules/repository/AnnotationsList.tpl.html',
					buttons      : null,
					showClose : false,
					showBack : true,
					inputs : {
						pdfDetails : pdfDetails, 
						documentDrawingAnnotationMap : documentDrawingAnnotationMap, 
						documentTextAnnotationMap : documentTextAnnotationMap, 
						documentPinAnnotationMap : documentPinAnnotationMap					
					}
				});
			},

			retriveUser :function(type) {
				bvDialogService.showBvModal({
					windowClass : 'bv-mobile-retrive-user',
					controller : 'LoginController',
					bodyTemplate : 'modules/login/RetriveUser.tpl.html',
					buttons      : null,
					inputs : {
						type : type
					}
				});
			},
			
			retrivePassword :function(type) {
				bvDialogService.showBvModal({
					windowClass : 'bv-mobile-retrive-password',
					controller : 'LoginController',
					bodyTemplate : 'modules/login/RetrivePassword.tpl.html',
					buttons      : null,
					inputs : {
						type : type
					}
				});
			},
			
		}

		return service;
	
}

angular
	.module('bvDialogMobile.factory', [])
	.factory('bvDialogMobile', bvDialogMobile)
	.directive('stopEvent', function () {
		return {
		restrict: 'A',
			link: function (scope, element, attr) {
				if(MobileUtil.isMobileClient()){
					element.on(attr.stopEvent, function (e) {
						e.stopPropagation();
					});
				}
			}
		};
	});
	