meetingCreationController.$inject = ['$q', '$scope', 'reactInterface', 'localizedMessages', '$stateParams', 'ajaxService', 'viewfullagendaService']

function meetingCreationController($q, $scope, reactInterface, localizedMessages, $stateParams, ajaxService, viewfullagendaService) {
  var loadGuestandResponseForEvent = function(objectBaseUuid)
	{
		var request=ajaxService.loadGuestandResponseForEventJSON(objectBaseUuid);
		return request.then(function(data)
		{
			if(data)
			{
				$scope.middleBarObject.title = data.data.name;
			}
		});
	}
  $scope.svgFill = '#FFF';
  $scope.middleBarObject.showBack = false;
  $scope.middleBarObject.title = localizedMessages.get("MEETINGS");

  $scope.tabs = [{
    title : localizedMessages.get("MEETINGS"),
    content : 'meetingCreation',
    active: true,
    rightMenuList:[]
  }];

  console.log("creation", $stateParams)

  $scope.reactInterface = reactInterface
  $scope.objectBaseUuid = $stateParams.objectBaseUuid
  $scope.operation = $stateParams.operation
  $scope.day = $stateParams.day
  $scope.minutesSelected = $stateParams.minutesSelected
  $scope.loggedUserRole = $stateParams.loggedUserRole

  if($stateParams.operation === 'editEvent'){
	  loadGuestandResponseForEvent($stateParams.objectBaseUuid);  
	  viewfullagendaService.setAgendaMeetingId($stateParams.objectBaseUuid);
  }
  $scope.$on('currentEditMeeting', function($event, meetingDetails){
	  $scope.middleBarObject.title = meetingDetails.name;
	  viewfullagendaService.setAgendaMeetingId(meetingDetails.objectBaseUuid);
  });
}