angular
    .module('dialogControllers.service')
    .controller('versionTrackerController', VersionTrackerController);

VersionTrackerController.$inject = ['repositoryService', '$scope', 'bvDialogService', 'localizedMessages', 'bvDialog'];

function VersionTrackerController(repositoryService, $scope, bvDialogService, localizedMessages, bvDialog)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var alertMsg = localizedMessages.get('SELECT_A_VERSION');
	$scope.bvoptionfalse = 'resources/assets/svgs/bvoptionfalse.svg';
	$scope.bvoptiontrue = 'resources/assets/svgs/bvoptiontrue.svg';

	$scope.popupTitle = localizedMessages.get('VERSION_TRACKER_FOR') + ": " +$scope.bvModalControllerInputs.businessObject.name;
	$scope.UI_CREATE_NEW_FILE = localizedMessages.get('CREATE_NEW_FILE');
	$scope.UI_MAKE_CURRENT_VERSION = localizedMessages.get('MAKE_CURRENT_VERSION');
	$scope.UI_VERSION = localizedMessages.get('VERSION');
	$scope.UI_COMMENTS = localizedMessages.get('COMMENTS');
	$scope.UI_MODIFIED_BY = localizedMessages.get('MODIFIED_BY');
	$scope.UI_DATE_MODIFIED = localizedMessages.get('DATE_MODIFIED');
	$scope.SELECTED_VTO = null;

	var getAllVersionsForSelectedDocument = function (baseUuid)
    {
    	repositoryService.getAllVersions(baseUuid).then(function(data)
    	{
    		setVersionData(data.data);
    	});
    };

    var setVersionData = function(VTOs)
    {
    	$scope.SELECTED_VTO = null;
    	for(var i=0; i< VTOs.length; i++)
    	{
    		if(VTOs[i].lastModifiedOnGMTMillis)
    		{
    			VTOs[i].modifiedOn = moment(parseInt(VTOs[i].lastModifiedOnGMTMillis)).format('lll');
    			VTOs[i].selected = false;
    			VTOs[i].vtoIndex = i;
			}
    	}
    	$scope.VTOS = VTOs;
    }

    $scope.changeVersionSelection = function(VTO)
    {
    	if($scope.SELECTED_VTO!=null)
    	{
    		$scope.SELECTED_VTO.selected = false;
    	}
    	VTO.selected = true;
    	$scope.SELECTED_VTO = VTO;
    }

    $scope.action = function()
    {
		$scope.bvModalControllerInputs.selectionDone(treeDataModel.getSelectedNode(), items);
    };

    $scope.makeLatestVersion = function ()
    {
    	if($scope.SELECTED_VTO!=null)
    	{
    		if($scope.SELECTED_VTO.vtoIndex == 0)
    		{
    			bvDialog.showMessage(localizedMessages.get("SELECTED_FILE_IS_THE_CURRENT_VERSION"));
    		}
    		else
    		{
    			var objectBaseUuid = $scope.SELECTED_VTO.objectBaseUuid;
	        	var versionUuid    = $scope.SELECTED_VTO.objectVersionUuid;

	        	repositoryService.makeLatestVersion(objectBaseUuid, versionUuid).then(function(data)
	        	{
	        		setVersionData(data.data);
	        	});
    		}
    	}
    	else
    	{
    		bvDialog.showMessage(alertMsg);
    	}
    };

    $scope.createNewFile = function()
    {
		if($scope.SELECTED_VTO==null)
		{
    		bvDialog.showMessage(alertMsg);
    		return;
		}
		bvDialog.createFileFromBO($scope.SELECTED_VTO, fileCreationComplete);
    };

    var fileCreationComplete = function()
    {
    	bvModal.close();
    }
    $scope.versionClicked=function(obj,index)
    {
    	//console.log("version clicked");
    	//console.log(obj);

    	console.log(index);
    	if(index==1)
    	{
    		repositoryService.openPdfViewer(obj.objectVersionUuid);
    	}
    	else
    	{
    		var params = {"versionUuid" : obj.objectVersionUuid}
    		$scope.openPdf("/services/viewdocument", "height=800,width=800", "test", params);
    	}


    	bvModal.close();
    };
    $scope.openPdf=function(url, windowoption, name, params)
    {
	 // get the pdf with a GET when it's running on Desktop app
	
	 if( MobileUtil.isDesktopClient() ) { 
		var queryString = Object.keys(params).map((key) => {
			return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
			}).join('&');
	
		var newWindow = window.open(url + "?" + queryString, name, windowoption);
		newWindow.print();
		return;
	 }
	
	 // get the pdf with a POST, for all the rest	 
		
	 var form = document.createElement("form");
     form.setAttribute("method", "post");
     form.setAttribute("action", url);
     form.setAttribute("target", name);
     for (var i in params)
     {
       if (params.hasOwnProperty(i))
       {
         var input = document.createElement('input');
         input.type = 'hidden';
         input.name = i;
         input.value = params[i];
         form.appendChild(input);
       }
     }
     document.body.appendChild(form);
     window.open("/portlet/blank.html", name, windowoption);
     form.submit();
     document.body.removeChild(form);
    };

    getAllVersionsForSelectedDocument($scope.bvModalControllerInputs.businessObject.objectBaseUuid);
}
