sessionTimeoutService.$inject = ['Idle'];

function sessionTimeoutService(Idle) {
	var service = {
		start: function() {
			if(MobileUtil.isApp() && MobileUtil.isJupOffline())
			{
				if(Idle.getIdle()!= MobileUtil.getUserSessionTimeout())
				{
					Idle.setIdle(MobileUtil.getUserSessionTimeout());
				}
			}
			else
			{
				if(Idle.getIdle()!=28)
				{
					Idle.setIdle(28);
				}
			}
			if(!Idle.running())
			{
				Idle.setTimeout(false);
				Idle.watch();
			}
			else
			{
				//console.log("IDLE RUNNING....");
			}
		},
		isIdle: function() {
			this.start();
			return Idle.idling();
		},
		restartIdle: function() { //invoke only from bvPolling.service.js
			Idle.watch();//This resets the Idle.
			this.start();//this will set the Idle secs based on browser/JUP
		}
	};
	return service;
}