function orientable(){
    return {
    	scope: {
    		angle: "=",
    		margin:"=",
    		maxHeight:"=",
    		maxWidth:"="
    	},
        link: function(scope, element, attrs) {

        	element.addClass('hide');
        	function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

        	    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        	    return { width: srcWidth*ratio, height: srcHeight*ratio };
        	 }
        	scope.origWidth;
        	scope.origHeight;
            element.bind("load" , function(e){

                // success, "onload" catched
                // now we can do specific stuff:
            	scope.origWidth=this.naturalWidth;
            	scope.origHeight=this.naturalHeight;

            	var elem=calculateAspectRatioFit(this.naturalWidth, this.naturalHeight, scope.maxWidth, scope.maxHeight);
            	element.css({'width':elem.width,'height':elem.height})

            	if(scope.angle!=0)
            	{
            		var r = 'rotate(' + scope.angle + 'deg)';
            		element.css({
            			'-moz-transform': r,
            			'-webkit-transform': r,
            			'-o-transform': r,
            			'-ms-transform': r
            		});
    				var elem=calculateAspectRatioFit(scope.origHeight, scope.origWidth, scope.maxWidth, scope.maxHeight);
    				element.css({'width':elem.height,'height':elem.width});
    				var calculateTop=(scope.maxHeight-elem.width)/2;
    				element.css({'position':'relative','top':calculateTop});

    				var m=scope.margin+"px";
    				if(m!='0px')
    				{

    					var elem=calculateAspectRatioFit(scope.origHeight,scope.origWidth,scope.maxWidth-scope.margin,scope.maxHeight-scope.margin);
    					element.css({'width':elem.height,'height':elem.width});
    					var calculateTop=(scope.maxHeight-elem.width)/2;
        				element.css({'position':'relative','top':calculateTop});
    				}
            	}
            	else
    			{
    				element.css({
    					'-moz-transform': '',
    					'-webkit-transform': '',
    					'-o-transform': '',
    					'-ms-transform': ''
    				});

    				var elem=calculateAspectRatioFit(scope.origWidth,scope.origHeight,scope.maxWidth, scope.maxHeight);
                	element.css({'width':elem.width,'height':elem.height});
                	element.css({'position':'','top':''});
            		if(elem.height<scope.maxHeight)
                	{
                		var calculateTop = (scope.maxHeight - elem.height) / 2
                		element.css({'position':'relative','top':calculateTop});
                	}

                	var m=scope.margin+"px";
                	if(m!='0px')
                	{
                		var elem=calculateAspectRatioFit(scope.origWidth,scope.origHeight,scope.maxWidth-scope.margin,scope.maxHeight-scope.margin);
                		element.css({'width':elem.width,'height':elem.height});
                		var calculateTop=(scope.maxHeight-elem.height)/2;
                		element.css({'position':'relative','top':calculateTop});
                	}
    			}

            	element.removeClass('hide');

            });
            scope.$watchCollection('[angle,margin,maxHeight,maxWidth]',function(selectedAngle,oldValues)
            		{
            			if(scope.angle!=0)
            			{
            				var r = 'rotate(' + scope.angle + 'deg)';
            				element.css({
            					'-moz-transform': r,
            					'-webkit-transform': r,
            					'-o-transform': r,
            					'-ms-transform': r
            				});
            				var elem=calculateAspectRatioFit(scope.origHeight,scope.origWidth,scope.maxWidth,scope.maxHeight);
            				element.css({'width':elem.height,'height':elem.width});
            				var calculateTop=(scope.maxHeight-elem.width)/2;
            				element.css({'position':'relative','top':calculateTop});

            				var m=scope.margin+"px";
            				if(m!='0px')
            				{

            					var elem=calculateAspectRatioFit(scope.origHeight,scope.origWidth,scope.maxWidth-scope.margin,scope.maxHeight-scope.margin);
            					element.css({'width':elem.height,'height':elem.width});
            					var calculateTop=(scope.maxHeight-elem.width)/2;
                				element.css({'position':'relative','top':calculateTop});
            				}
            			}
            			else
            			{
            				element.css({
            					'-moz-transform': '',
            					'-webkit-transform': '',
            					'-o-transform': '',
            					'-ms-transform': ''
            				});
            				var elem=calculateAspectRatioFit(scope.origWidth,scope.origHeight,scope.maxWidth,scope.maxHeight);
                        	element.css({'width':elem.width,'height':elem.height});
                        	element.css({'position':'','top':''});

                    		if(elem.height<scope.maxHeight)
                        	{
                        		var calculateTop=(scope.maxHeight-elem.height)/2
                        		element.css({'position':'relative','top':calculateTop});
                        	}

                        	var m=scope.margin+"px"
                        	if(m!='0px')
                        	{
                        		var elem=calculateAspectRatioFit(scope.origWidth,scope.origHeight,scope.maxWidth-scope.margin,scope.maxHeight-scope.margin);
                        		element.css({'width':elem.width,'height':elem.height});

                    			if(elem.height<scope.maxHeight)
                            	{
                            		var calculateTop=(scope.maxHeight-elem.height)/2
                            		element.css({'position':'relative','top':calculateTop});
                            	}
                        	}
            			}

            		});
        }
    }
}