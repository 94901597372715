angular
	.module('dialogControllers.service')
	.controller('surveyPrintableReportsController', surveyPrintableReportsController)
	.filter('surveyUnsafe', function ($sce) {
		return function (html) {
			return $sce.trustAsHtml(html);
		};
	});

surveyPrintableReportsController.$inject = ['$scope', '$filter', 'localizedMessages', 'commonService', 'context', 'homeService', 'bvDialog', 'bvDialogService', 'surveysService', '$sce'];


function surveyPrintableReportsController($scope, $filter, localizedMessages, commonService, context, homeService, bvDialog, bvDialogService, surveysService, $sce) {
	$scope.popupTitle = localizedMessages.get('PRINTABLE_REPORTS');
	$scope.surveyDetails = $scope.bvModalControllerInputs.surveyDetails;
	$scope.currentUserVO = $scope.bvModalControllerInputs.currentUserVO;
	$scope.horizontalBarData = $scope.bvModalControllerInputs.barData;
	$scope.pieData = $scope.bvModalControllerInputs.pieData;
	$scope.printOptions = $scope.bvModalControllerInputs.printOptions;
	$scope.respondentsCountForPrint = $scope.bvModalControllerInputs.respondentsCountForPrint;
	$scope.signaturesImg = $scope.bvModalControllerInputs.signaturesImg;
	$scope.participantSignatureResponseMap = $scope.bvModalControllerInputs.participantSignatureResponseMap;
	$scope.excelReportImg = '/resources/assets/svgs/documents/xls.svg';
	$scope.wordReportImg = 'resources/assets/images/docx_sm_@2X.png';
	$scope.printImg = '';
	$scope.ui = {
		label: {
			save: localizedMessages.get('SAVE'),
			print: localizedMessages.get('PRINT'),
			text: localizedMessages.get('TEXT'),
			bar: localizedMessages.get('BAR'),
			pie: localizedMessages.get('PIE'),
			excel: localizedMessages.get('EXCEL'),
			survey_title: localizedMessages.get('SURVEY_TITLE'),
			date: localizedMessages.get('DATE'),
			created_by: localizedMessages.get('CREATED_BY'),
			section_number: localizedMessages.get('SECTION_NUMBER'),
			title: localizedMessages.get('TITLE'),
			bar_chart: localizedMessages.get('BAR_CHART'),
			pie_chart: localizedMessages.get('PIE_CHART'),
			printable_reports: localizedMessages.get('PRINTABLE_REPORTS'),
			attachment: localizedMessages.get('ATTACHMENT'),
			no_section_attachments: localizedMessages.get('NO_SECTION_ATTACHMENTS'),
			multiple_choice: localizedMessages.get('MULTIPLE_CHOICE'),
			open_answer: localizedMessages.get('OPEN_ANSWER'),
			likert_scale: localizedMessages.get('LIKERT_SCALE'),
			true_false: localizedMessages.get('TRUE_FALSE'),
			meets_does_not_meet: localizedMessages.get('MEETS_DOES_NOT_MEET'),
			multiple_choice_multi_response: localizedMessages.get('MULTIPLE_CHOICE_MULTI_RESPONSE'),
			yes_no: localizedMessages.get('YES_NO'),
			response: localizedMessages.get('RESPONSE'),
			by: localizedMessages.get('BY'),
			complete: localizedMessages.get('COMPLETE'),
			due: localizedMessages.get('DUE'),
			priority: localizedMessages.get('PRIORITY'),
			instructions: localizedMessages.get('INSTRUCTIONS'),
			description: localizedMessages.get('DESCRIPTION'),
			dueDate: localizedMessages.get('DUE_DATE'),
			question: localizedMessages.get('QUESTION'),
			overview: localizedMessages.get('OVERVIEW'),
			section: localizedMessages.get('SECTION'),
			of: localizedMessages.get('OF'),
			responded: localizedMessages.get('RESPONDED'),
			include: localizedMessages.get('INCLUDE'),
			graphs: localizedMessages.get('GRAPHS'),
			comments: localizedMessages.get('COMMENTS'),
			signatures: localizedMessages.get('SIGNATURES'),
			attachments: localizedMessages.get('ATTACHMENTS'),
			responses: localizedMessages.get('RESPONSES'),
			name: localizedMessages.get('NAME'),
			summary: localizedMessages.get('SUMMARY'),
			signed: localizedMessages.get('SIGNED')

		}
	}
	$scope.questionTypeMapping = {};
	surveysService.setQuestionBaseUuidList($scope.surveyDetails);
	var description = $scope.surveyDetails.description;
	$scope.surveyDetails.description = description.replace(/&nbsp;/g, ' ');

	var seqCount = 0;
	$scope.surveyTotalQuestions = 0;
	for (var i = 0; i < $scope.surveyDetails.sections.length; i++) {
		$scope.surveyTotalQuestions += $scope.surveyDetails.sections[i].questions ? $scope.surveyDetails.sections[i].questions.length : 0;
		for (var j = 0; j < $scope.surveyDetails.sections[i].questions.length; j++) {
			seqCount = seqCount + 1;
			$scope.surveyDetails.sections[i].questions[j].sequentialSecCount = seqCount;
			$scope.questionTypeMapping[$scope.surveyDetails.sections[i].questions[j].objectBaseUuid] = $scope.surveyDetails.sections[i].questions[j].questionType;
		}
	}
	$scope.getShortLocalizedDate = function (date) {
		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
	}
	$scope.getLocalizedDate = function (date) {
		return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
	}
	$scope.scrollbarConfig = {
		autoHideScrollbar: true
	}
	$scope.xFunction = function () {
		return function (d) {
			return d.key;
		};
	};

	$scope.yFunction = function () {
		return function (d) {
			return d.y;
		};
	}
	$scope.xFunctionBarChart = function () {
		return function (d) {
			return d.label;
		};
	};

	$scope.yFunctionBarChart = function () {
		return function (d) {
			return d.value;
		};
	}
	$scope.saveCurrentReport = function () {
		//		var pdf = new jsPDF('p','pt','letter');
		//		var specialElementHandlers = {
		//				'.printableReportsDIV': function (element, renderer) {
		//					return true;
		//		        }
		//		    };
		//		    pdf.addHTML($('.printableReportsDIV').first(), function() {
		//		        pdf.save("test.pdf");
		//		    });
		//		    var fileBytes = btoa(pdf.output()); 
		var parentFolderSelect = function (selectedFolder) {
			$scope.downloadedReportName = surveysService.getSurvey().name;
			surveysService.saveSurveyReportJSON("", surveysService.getSurvey().objectBaseUuid, selectedFolder.objectBaseUuid, $scope.downloadedReportName).then(function (data) {
				if (data.boName == "SuccessBO" || data.boName == "FailureBO") {

				}
			});
		}
		bvDialog.openFolderChooser(localizedMessages.get("SAVE_TO"), parentFolderSelect, null);
	};
	$scope.printCurrentReport = function () {
		$('.reportScrollTopPosition').mCustomScrollbar('scrollTo', ['top', null]);
		document.querySelector('.printableReportsDIV  .mCSB_container').style.top = 0;
		document.querySelector('.printableReportsDIV  .mCSB_dragger').style.top = 0;
		$(".printableReportsDIV").print(
			{
				globalStyles: false,
				mediaPrint: true,
				stylesheet: 'resources/css/print.css',
				noPrintSelector: ".no-print",
				iframe: true,
				append: null,
				prepend: null,
				manuallyCopyFormValues: true,
				deferred: $.Deferred(),
				timeout: 250
			});
	};
	var format = window.d3.format('d');
	$scope.xFunction = function () {
		return function (d) {
			return d.key;
		};
	};

	$scope.yFunction = function () {
		return function (d) {
			return format(d.y);
		};
	}
	$scope.xFunctionBarChart = function () {
		return function (d) {
			return d.label;
		};
	};

	$scope.yFunctionBarChart = function () {
		return function (d) {
			return d.value;
		};
	}
	$scope.valueFormatFunction = function () {
		return function (d) {
			return format(d);
		}
	}
	$scope.yAxisTickFormatFunction = function () {
		return function (d) {
			return format(d);
		}
	}
	$scope.toolTipContentFunction = function () {
		return function (key, x, y, e, graph) {
			return x + ' - ' + y
		}
	}
	$scope.pieToolTipContentFunction = function () {
		return function (key, y, e, graph) {
			return key + ' - ' + y
		}
	}
	$scope.pieToolTipValueFormatter = function () {
		return function (d) {
			return format(d);
		}
	}
	
	$scope.getLocalizedDateOnLocalTimezone = function (date) {
	      // return date != undefined ? moment($filter('date')(date, 'medium')).format('lll') : "";
	      return date != undefined ? localizedMessages.getLocaleDateOnLocalTimezone(date, 'lll') : "";
	  };
	$scope.showTextReportBoolean = true;
	$scope.showTextReport = function () {
		$scope.showTextReportBoolean = true;
		$scope.showBarChartReportBoolean = false;
		$scope.showPieChartReportBoolean = false;
	};
	$scope.showBarChartReport = function () {
		$scope.showBarChartReportBoolean = true;
		$scope.showTextReportBoolean = false;
		$scope.showPieChartReportBoolean = false;
	};
	$scope.showPieChartReport = function () {
		$scope.showPieChartReportBoolean = true;
		$scope.showBarChartReportBoolean = false;
		$scope.showTextReportBoolean = false;
	};
}