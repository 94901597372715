function ContextMenuSetting()
{
	this.getPosition = function(x, y, selectedContextItem, length, factor, width, separatorValue)
	{
		var point = new Point();

		var appY = window.innerHeight;
		var appX = window.innerWidth;

		if(!separatorValue) separatorValue = 0;

		var height = length * factor + separatorValue;
		var surfaceZoomRatio = window.screen.height > 1800 ? 2 : 1.5;

		if(MobileUtil.isMobileClient()){
			if ((x + width) > appX - 20)
				point.x = appX - width;
			else
				point.x = x + 55;

			point.y = y - 25;
		}
		else{
			if ((x + width) > appX - 20)
				point.x = appX - (width + 40);
			else
				point.x = x + 20;

			point.y = y - 30;
		}

		if(height > 551){
			height = 551;
		}

		if(MobileUtil.isSurface() || MobileUtil.isMobileClient()){
			if(MobileUtil.isSurface()){
				if ((x + width) > appX - width)
					point.x = appX - width * 2 ;
				else
					point.x = x + 30;

				if (y + height > appY - height / surfaceZoomRatio + 40){
					point.y = appY - height * surfaceZoomRatio - 30;
				}
			}
			else{
				if (point.y + height > appY - 40)
				{
					point.y = appY - height - 20;
				}
			}
		}
		else{
			if (point.y + height > appY - 20)
			{	

				if(5 < length){
					point.y = appY - height + 15;

				}else if( length == 5 || length == 4 || length == 3){
					point.y = appY - height - 10;

				}else if (length == 2){
					point.y = appY - height - 15;

				}else if (length == 1){									
						point.y = appY - height - 20;
										
				}
			
			}
		}

		//point.y = point.y / 1.5;

		//console.log("X "+point.x+"  Y :"+point.y);

		return point;
	}
	this.getArrowPosition = function(x, y, length, factor, width, separatorValue) {
		var point = new Point();

		var appY = window.innerHeight - 40;
		var appX = window.innerWidth;

		if(!separatorValue) separatorValue = 0;

		var height = length * factor + separatorValue;
		var surfaceZoomRatio = window.screen.height > 1800 ? 2 : 1.5;

		if(MobileUtil.isMobileClient() && !MobileUtil.isSurface()){
			point.x = x + 11;
			point.y = y - 20;

			if ((x + width) > appX - 20)
				point.x = appX - (width + 8);
			else
				point.x = x + 47;

			if(y > appY) {
				point.y = appY - 20;
			}
		}
		else if(MobileUtil.isSurface()){
			point.y = y - 25;

			if ((x + width) > appX - width)
				point.x = appX - width * 2 - 11 ;
			else
				point.x = x + 19;

			if (y + height > appY - height / surfaceZoomRatio + 40){
				point.y = y - 20;
				if(y > appY - 40){
					point.y = y - 90;
				}

			}
		}
		else{
			point.x = x + 11;
			point.y = y - 20;

			if ((x + width) > appX - 20)
				point.x = appX - (width + 49);

			if(y + 15 > appY + 30) {
				point.y = appY;
			}
		}


		//console.log("X "+point.x+"  Y :"+point.y);

		return point;
	}

	function Point()
	{
		var x;
		var y;
	}

}
