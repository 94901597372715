adSearchDirective.$inject = ['$compile', '$templateCache'];

function adSearchDirective($compile, $templateCache){
    return {
        restrict: 'E',
        scope: {
              searchObject: '=',
              topPanel: '='
        },
        controller: 'adSearchController',
        link: function($scope, el, attrs){
            if($scope.searchObject){
                var template = $templateCache.get('modules/advancedsearch/adsearch/adSearch.tpl.html');

                if($scope.searchObject.advanced){
                    template = $templateCache.get('modules/advancedsearch/adsearch/adSearchAdvanced.tpl.html');
                }

                el.html(template);
                $compile(el.contents())($scope);
            }
        }
    }
}