function  monthviewService(){

    var m = StringUtils.momentL;

    var Monthview = function(inputDate){
        var date = inputDate || new Date();

        var generateMonthdays = function(date){
            var monthdays = [];
            var lastWeek = m(date).endOf('month').isoWeekday(1);

            for(var i=1 ; i <= 7 ; i++){
                var startOfMonth = m(date).startOf('month');

                var column = {
                    title: m().isoWeekday(i).format('dddd').toUpperCase(),
                    currentDay: m(date).isoWeekday(i).format('ll') == m().format('ll'),
                    weekend: i == 6 || i == 7,
                    sixWeeks: false,
                    days: []
                }

                var day = startOfMonth.isoWeekday(i).endOf('day');
                column.days.push(
                    {
                        title: day.format('D'),
                        day: day.format('ll'),
                        current: day.month() == m(date).month(),
                        currentDay: day.format('ll') == m().format('ll'),
                        clicked: false
                    }
                )

                while(day < lastWeek){
                    day = startOfMonth.isoWeekday(i).add(1, 'week');
                    column.days.push(
                        {
                            title: day.format('D'),
                            day: day.format('ll'),
                            current: day.month() == m(date).month(),
                            currentDay: day.format('ll') == m().format('ll')
                        }
                   );
                }

                column.sixWeeks = column.days.length == 6;

                monthdays.push(column);
            }

            return monthdays;
        }

        this.monthdays =  generateMonthdays(date);
        this.monthInfo = m(date).format('MMMM YYYY');
        this.isoDate = m(date).toISOString();
    }

    Monthview.prototype = {
        constructor: Monthview,
        getMomentDate: function(){
            return m(this.isoDate);
        }
    }

    this.createMonth = function(date){
        return new Monthview(date);
    }

}