detailsPanelController.$inject = ['$scope', 'localizedMessages', 'context', 'calendarService', '$rootScope', '$state', 'dayviewService', 'weekviewService'];

function detailsPanelController($scope, localizedMessages, context, calendarService, $rootScope, $state, dayviewService, weekviewService){

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    $scope.selectedEvent = $scope.bvModalControllerInputs.selectedEvent;
    var selectDetailsTab = $scope.bvModalControllerInputs.selectDetailsTab;
    $scope.showFullEventBtn = $scope.bvModalControllerInputs.showFullEventBtn;

    $scope.popupTitle = $scope.selectedEvent.name;
    $scope.selectedEvent.description = decodeURIComponent($scope.selectedEvent.description);

    var status = {
        accepted: 'accepted',
        declined: 'declined',
        tentative: 'tentative'
    }

    var guests = $scope.selectedEvent.guests;
    var currentUser = _.find(guests, { objectBaseUuid : context.getModel().userVO.objectBaseUuid });

    $scope.title = {
        conference:                             localizedMessages.get("CONFERENCE_UPPERCASE"),
        location:                               localizedMessages.get("LOCATION_UPPERCASE"),
        notes:                                  localizedMessages.get("NOTES_UPPERCASE"),
        attendanceStatus:                       localizedMessages.get("ATTENDANCE_STATUS_UPPERCASE"),
        dialIn:                                 localizedMessages.get('DIAL_IN'),
        description:                            localizedMessages.get('DESCRIPTION'),
        code:                                   localizedMessages.get('CODE'),
        meetingRoom:                            localizedMessages.get('MEETING_ROOM'),
        address:                                localizedMessages.get('ADDRESS'),
        accepted:                               localizedMessages.get("ACCEPTED_UPPERCASE"),
        tentative:                              localizedMessages.get("TENTATIVE_UPPERCASE"),
        declined:                               localizedMessages.get("DECLINED_UPPERCASE"),
        noResponse:                             localizedMessages.get("NO_RESPONSE_UPPERCASE"),
        typeToSearchForAttendees:               localizedMessages.get("TYPE_TO_SEARCH_FOR_ATTENDEES"),
        noAttendeesHaveAcceptedAtThisTime:      localizedMessages.get("NO_ATTENDEES_HAVE_ACCEPTED_AT_THIS_TIME"),
        noAttendeesHaveTentativeAtThisTime:     localizedMessages.get("NO_ATTENDEES_HAVE_TENTATIVE_AT_THIS_TIME"),
        noAttendeesHaveDeclinedAtThisTime:      localizedMessages.get("NO_ATTENDEES_HAVE_DECLINED_AT_THIS_TIME"),
        noAttendeesHaveNoResponseAtThisTime:    localizedMessages.get("NO_ATTENDEES_HAVE_RESPONSE_AT_THIS_TIME"),
        viewFullEvent:                          localizedMessages.get("VIEW_FULL_EVENT")
    }

    $scope.tabs = [
        { title: "DETAILS", active: selectDetailsTab},
        { title: "ATTENDEES", active: !selectDetailsTab}
	];

    $scope.topDetailsPanel = {
        templateUrl: 'modules/meetings/detailspanel/toppanel/toppanel.tpl.html',
        events : [
            { name: 'tabs', value : $scope.tabs}
        ]
    }

    $scope.submitEventResponse = function(tab)
	{
    	var objectVersionUuid = $scope.selectedEvent.objectVersionUuid;
    	var belongsToCalendarUuid = $scope.selectedEvent.belongsToCalendarUuid;
    	var eventResponse = tab.name.toUpperCase();
    	calendarService.setCalendarResponse(objectVersionUuid, belongsToCalendarUuid, eventResponse).then(function(data)
	    {
			if(data && data.data && data.data.guests)
			{
				var eventResponseList = data.data.eventResponseList;
				var userId = context.getModel().userVO.objectBaseUuid;
				var guests = data.data.guests;
				for(var i in guests)
				{
					for(var k in eventResponseList)
					{
						if(guests[i].objectBaseUuid == eventResponseList[k].createdByBaseUuid)
						{
							guests[i].response = eventResponseList[k].response.toLowerCase();
						}
						if(userId == guests[i].objectBaseUuid)
						{
							$scope.CURRENT_USER = guests[i];
						}
					}
				}

				$scope.selectedEvent.guests = guests;
				$scope.selectedEvent.objectVersionUuid = data.data.objectVersionUuid;
				calendarService.getAllCalendarEvents(true, function(events){
				    var dayview = dayviewService.getCurrentDayview();
				    dayviewService.combineEvents(events.listEventsByDay[dayview.day]);
                    dayviewService.getMoreDataFoDayEvents(events.listEventsByDay[dayview.day]);

                    var weekview = weekviewService.getCurrentWeek();
                    _.each(weekview.weekdays, function(weekday){
                        dayviewService.combineEvents(events.listEventsByDay[weekday.day]);
                        dayviewService.getMoreDataFoDayEvents(events.listEventsByDay[weekday.day]);
                    });

				});
				var homeController = angular.element(document.querySelector('[home-controler]')).scope();
		   	 	if(homeController)
		   	 		homeController.getMeetingsCount();
			}
	    });
    }
    
    var isEventOwner = $scope.selectedEvent.isEventOwner;

    calendarService.loadGuestandResponseForEvent($scope.selectedEvent.objectBaseUuid).then(function(data){

        if(data){
            var item = data.data;
            var guests = data.data.guests;
            var eventResponseList = data.data.eventResponseList;

            _.each(guests, function(i)
            {
                _.each(eventResponseList, function(k)
                {
                    if(i.objectBaseUuid == k.createdByBaseUuid)
                    {
                        i.response = k.response.toLowerCase();
                    }
                 });
            });

            item.guests = guests;

            if(isEventOwner==null || isEventOwner==undefined)
            {
                item.isEventOwner = item.isEventOwner;
            }
            else
            {
                item.isEventOwner = isEventOwner;
            }

            $scope.selectedEvent.guests = guests;
            var currentUser = _.find(guests, { objectBaseUuid : context.getModel().userVO.objectBaseUuid });
            $scope.tabsStatus = [
                { title: localizedMessages.get("ACCEPT"), active: currentUser.response == status.accepted, name: status.accepted },
                { title: localizedMessages.get("DECLINE"), active: currentUser.response == status.declined, name: status.declined },
                { title: localizedMessages.get("TENTATIVE"), active: currentUser.response == status.tentative, name: status.tentative }
        	];
            //bvDialog.showMeetingDetailsPanel(item, true, true);
        }

    });

	$scope.showFullEventBtnClick = function(){
	    var uniq_date = StringUtils.momentL($scope.selectedEvent.startDateGMTMillis).format('ll');
	    var events = calendarService.getAllCalendarEvents().eventsMap.get(uniq_date);
	     var EVENTS_LIST = {
                DATE: uniq_date,
                EVENTS: events,
                SELECTED_EVENT: $scope.selectedEvent.objectBaseUuid
            };
        $rootScope.EVENTS_LIST = EVENTS_LIST;
        $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('DAILY');
        $state.go('home.meetingDetails');
        bvModal.close();
	}
}