angular
    .module('dialogControllers.service')
	.controller('showMeetingAttendeesSettingController', showMeetingAttendeesSettingController);

showMeetingAttendeesSettingController.$inject = ['$scope', 'context', 'localizedMessages','companyUserService', 'bvDialog','commonService'];

function showMeetingAttendeesSettingController($scope, context, localizedMessages, companyUserService, bvDialog, commonService){
	var bvModal;
	bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.meetingCenterObject = $scope.bvModalControllerInputs.meetingCenterObject;
	$scope.users = angular.copy($scope.bvModalControllerInputs.users);
	$scope.currentUserUuid = context.getModel().userVO.objectBaseUuid;
	
	$scope.ADDED_ATTENDEES = [];
	$scope.FINAL_ADDED_ATTENDEES = [];
	
	$scope.ui = { label : {
		acceptOnBehalf			:localizedMessages.get("ACCEPT_ON_BEHALF"),
    	addAttendees			:localizedMessages.get("ADD_ATTENDEES"),
    	markAsOwner 			:localizedMessages.get("MARK_AS_OWNER"),
    	presenter				:localizedMessages.get("PRESENTER"),
    	cancel					:localizedMessages.get("CANCEL"),
    	ok						:localizedMessages.get("OK"),
      }
	};
	
	$scope.popupTitle  =   localizedMessages.get('ATTENDEES');
	
	$scope.bvchecktrue 		= 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvcheckfalse 	= 'resources/assets/svgs/bvcheckfalse.svg';
	
	$scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM = localizedMessages.get('MSG_EDIT_MEETINGS_CONFIRM_CHANGES_ON_TAB_CHANGE');
	$scope.UI_MSG_EDIT_MEETINGS_CANCEL_CHANGES  = localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED');
	
	$scope.ATTENDEES = angular.copy($scope.meetingCenterObject.attendeesList);
	$scope.FINAL_ATTENDEES = angular.copy($scope.meetingCenterObject.attendeesList);
	
	$scope.CURRENT_OPTION = 'UI_ADD_ATTENDEES';
	$scope.toggleOwner = function(item, booleanVal){
    	item.isOwner = booleanVal;
    };
    $scope.toggleOwnerCheckAll = function(booleanVal){
    	$scope.ownerCheckAll = booleanVal;
    	if(booleanVal) {
    		for(var i=0; i<$scope.ATTENDEES.length; i++) {
        		$scope.ATTENDEES[i].isOwner = true;
        	}
    	} 
    	else {
    		for(var i=0; i<$scope.ATTENDEES.length; i++) {
        		$scope.ATTENDEES[i].isOwner = false;
        	}
    	}
    }
    $scope.toggleOnBehalf = function(item, booleanVal){
    	item.isOnBehalf = booleanVal;
    	if(booleanVal){
    		item.response = 'accepted';
    	} else {
    		if(item.isOwner) {
    			item.response = "accepted";
    		}
    		if($scope.meetingCenterObject.guests != undefined && $scope.meetingCenterObject.guests.length!=0){
    			for(var i=0; i<$scope.meetingCenterObject.guests[i]; i++) {
    				if($scope.meetingCenterObject.guests[i].objectBaseUuid == item.objectBaseUuid) {
    					item.response = $scope.meetingCenterObject.guests[i].response;
    				}
    			}
    			
    		} else {
    			item.response = 'not_responded';
    		}
    		
    	}
    		
    }
    $scope.toggleOnBehalfCheckAll = function(booleanVal){
    	$scope.onBehalfCheckAll = booleanVal;
    	if(booleanVal) {
    		for(var i=0; i<$scope.ATTENDEES.length; i++) {
        		$scope.ATTENDEES[i].isOnBehalf = true;
        		$scope.ATTENDEES[i].response = 'accepted';
        	}
    	} 
    	else {
    		for(var i=0; i<$scope.ATTENDEES.length; i++) {
        		$scope.ATTENDEES[i].isOnBehalf = false;
        		if($scope.meetingCenterObject.guests != undefined && $scope.meetingCenterObject.guests.length!=0){
        			for(var j=0; j<$scope.meetingCenterObject.guests[j]; j++) {
        				if($scope.meetingCenterObject.guests[j].objectBaseUuid == $scope.ATTENDEES[j].objectBaseUuid) {
        					$scope.ATTENDEES[i].response = $scope.meetingCenterObject.guests[j].response;
        				}
        			}
        			
        		} else {
        			$scope.ATTENDEES[i].response = 'not_responded';
        		}
        	}
    	}
    }
    $scope.togglePresenter = function(item, booleanVal){
    	item.isPresenter = booleanVal;
    }
    $scope.togglePresenterCheckAll = function(booleanVal){
    	$scope.presenterCheckAll = booleanVal;
    	if(booleanVal) {
    		for(var i=0; i<$scope.ATTENDEES.length; i++) {
        		$scope.ATTENDEES[i].isPresenter = true;
        	}
    	} 
    	else {
    		for(var i=0; i<$scope.ATTENDEES.length; i++) {
        		$scope.ATTENDEES[i].isPresenter = false;
        	}
    	}
    }
    
	var exitEditMeetings = function() {
		bvModal.close();
	}
	
    $scope.save = function() {
    	if($scope.CURRENT_OPTION == 'UI_ADD_ATTENDEES' && $scope.validateChangesForAddAttendees()) {
    		$scope.saveTempChanges($scope.CURRENT_OPTION, true);
    		saveAttendees();
    	}
    	else {
    		$scope.saveTempChanges($scope.CURRENT_OPTION);
    		saveAttendees();
    	}
    }
    
    var saveAttendees = function() {
    	var meetingController = angular.element('#idNewMeetingCreation').scope();
    	meetingController.meetingCenterObject.attendeesList = angular.copy($scope.FINAL_ATTENDEES);
    	exitEditMeetings();
    }
    $scope.cancelChanges = function()
	{
		var confirmMsg = $scope.UI_MSG_EDIT_MEETINGS_CANCEL_CHANGES;
		bvDialog.confirm(confirmMsg, exitEditMeetings, null);
	}
    $scope.close = function()
	{
    	$scope.cancelChanges();
	}
    
    $scope.addSelectedUser = function(selectedItems, saveFromAttendees){
		$scope.selectedUsers = selectedItems;
		if($scope.selectedUsers!=undefined && $scope.selectedUsers.length >0 )
		{
			for(var i in $scope.selectedUsers) {

				if($scope.selectedUsers[i].type == 'TeamSpaceGroup'){
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid, saveFromAttendees);
				}
				else if($scope.selectedUsers[i].type == 'Group'){
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid, saveFromAttendees);
				}
				else if($scope.selectedUsers[i].type == 'Portal'){
					$scope.addGroupUsersInAttendeesList($scope.selectedUsers[i].objectBaseUuid, saveFromAttendees);
				}
				else{
					$scope.addUserInAttendeesList($scope.selectedUsers[i].objectBaseUuid, $scope.selectedUsers[i].name);
				}
			}
		}
	};
	$scope.addGroupUsersInAttendeesList = function(groupId, saveFromAttendees){
		var users = companyUserService.getGroupUsers(groupId);
		if(users && users.length> 0)
		{
			for(var k in users){
				$scope.addUserInAttendeesList(users[k].objectBaseUuid, users[k].name);
			}
		}
		if(saveFromAttendees) {
			saveAttendees();
		}
	};

	$scope.addUserInAttendeesList = function(uuid,name){
		//var profileImage = $scope.$parent.userImages[$scope.selectedUsers[i].objectBaseUuid];
		var attendeeObj = {
				objectBaseUuid		:uuid,
				name				:name,
				isPresenter			:false,
				isOwner				:false,
				isOnBehalf			:false,
				response			:'not_responded',
				//encodedUserThumbnail:profileImage
			};

		if($scope.checkAttendeeAlreadyExists(attendeeObj.objectBaseUuid) == false)
		{	
			if($scope.FINAL_ATTENDEES) {
				$scope.ATTENDEES.push(attendeeObj);
				$scope.FINAL_ATTENDEES = angular.copy($scope.ATTENDEES);
			}
		}
	};
	$scope.checkAttendeeAlreadyExists = function(objectBaseUuid){
		var existsFlag = false;
		if($scope.FINAL_ATTENDEES && $scope.FINAL_ATTENDEES.length != 0)
		{	
			for(var index in $scope.FINAL_ATTENDEES){
				if($scope.FINAL_ATTENDEES[index].objectBaseUuid == objectBaseUuid){
					existsFlag = true;
					break;
				}
			}
		}
		else
			existsFlag = false;
		
		return existsFlag;
	};
	
	$scope.setSelectedUsers = function(selectedItems)
	{
		$scope.ADDED_ATTENDEES = selectedItems;
	};
	
	$scope.changeAttendeeOption = function (tabVal)
	{
		var everythingValid = false;
		$scope.TEMP_TAB_VAL = tabVal;
		var showDlg = false;
		switch($scope.CURRENT_OPTION)
		{
			case 'UI_ADD_ATTENDEES' 		: 	everythingValid = $scope.validateChangesForAddAttendees(); 
										break;
			case 'UI_MARK_AS_OWNER' 		: 	everythingValid = $scope.validateChangesForMarkAsOwner(); 
										break;
			case 'UI_ACCEPT_ON_BEHALF'		: 	everythingValid = $scope.validateChangesForAcceptOnBehalf();
										break;
			case 'UI_PRESENTER'				: 	everythingValid = $scope.validateChangesForPresenter(); 
										break;
		}
		if(everythingValid)
		{
			bvDialog.confirm($scope.UI_MSG_EDIT_MEETINGS_CHANGES_CONFIRM, $scope.saveTempChangesOnTabChange, $scope.removeTempChangesOnTabChange);
		}
		else
		{
			$scope.CURRENT_OPTION = $scope.TEMP_TAB_VAL;
		}
	}
	$scope.saveTempChangesOnTabChange = function ()
	{
		var previousOption = $scope.CURRENT_OPTION;
		$scope.CURRENT_OPTION = $scope.TEMP_TAB_VAL;
		$scope.saveTempChanges(previousOption);
	}
	$scope.saveTempChanges = function (tabName, saveFromAttendees)
	{
		switch(tabName)
		{
			case 'UI_ADD_ATTENDEES' 	: 	$scope.FINAL_ADDED_ATTENDEES = angular.copy($scope.ADDED_ATTENDEES); 
											$scope.addSelectedUser($scope.FINAL_ADDED_ATTENDEES, saveFromAttendees);
											break;
			case 'UI_MARK_AS_OWNER'		: 	$scope.FINAL_ATTENDEES = angular.copy($scope.ATTENDEES);
											break;
			case 'UI_ACCEPT_ON_BEHALF'	: 	$scope.FINAL_ATTENDEES = angular.copy($scope.ATTENDEES);
											break;
			case 'UI_PRESENTER'	    	:   $scope.FINAL_ATTENDEES = angular.copy($scope.ATTENDEES);
											break;
		}
	}
	$scope.removeTempChangesOnTabChange = function ()
	{
		var previousTab = $scope.CURRENT_OPTION;
		$scope.CURRENT_OPTION = $scope.TEMP_TAB_VAL;
		switch(previousTab)
		{
			case 'UI_ADD_ATTENDEES' 		: 	$scope.ADDED_ATTENDEES = angular.copy($scope.FINAL_ADDED_ATTENDEES);
												break;
			case 'UI_MARK_AS_OWNER'		: 		$scope.ATTENDEES = angular.copy($scope.FINAL_ATTENDEES);
												$scope.ownerCheckAll = false;
												break;
			case 'UI_ACCEPT_ON_BEHALF'	: 		$scope.ATTENDEES = angular.copy($scope.FINAL_ATTENDEES);
												$scope.onBehalfCheckAll = false;
												break;
			case 'UI_PRESENTER'			: 		$scope.ATTENDEES = angular.copy($scope.FINAL_ATTENDEES);
												$scope.presenterCheckAll = false;
												break;
		}
	}
	$scope.validateChangesForAddAttendees = function()
	{
		if(angular.equals($scope.ADDED_ATTENDEES, $scope.FINAL_ADDED_ATTENDEES)) {
			return false;
		}
		return true;
	}
	$scope.validateChangesForMarkAsOwner =  function()
	{
		for(var i=0; i<$scope.ATTENDEES.length; i++){
			if($scope.ATTENDEES[i].isOwner != $scope.FINAL_ATTENDEES[i].isOwner){
				return true;
			}
		}
		return false;
	}
	$scope.validateChangesForAcceptOnBehalf =  function()
	{
		for(var i=0; i<$scope.ATTENDEES.length; i++){
			if($scope.ATTENDEES[i].isOnBehalf != $scope.FINAL_ATTENDEES[i].isOnBehalf){
				return true;
			}
		}
		return false;
	}
	$scope.validateChangesForPresenter =  function()
	{
		for(var i=0; i<$scope.ATTENDEES.length; i++){
			if($scope.ATTENDEES[i].isPresenter != $scope.FINAL_ATTENDEES[i].isPresenter){
				return true;
			}
		}
		return false;
	}
}
