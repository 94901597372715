FollowersController.$inject = ['homeService','$scope','$modalInstance', 'localizedMessages', '$modal', 'commonService', 'repositoryService', 'objectBaseUuid'];

function FollowersController(homeService, $scope, $modalInstance, localizedMessages, modal, commonService, repositoryService, objectBaseUuid)
{
	$scope.ui =
    {
         label :
         {
        	 USER_SELECTOR_TITLE : localizedMessages.get('SET_FOLLOWERS_FOR'),
        	 SET_FOLLOWERS_MSG : localizedMessages.get('SET_FOLLOWERS_MSG')
         }
    };

	$scope.showFollowersInfo = true;
	$scope.showFilter = true;
	$scope.multiSelect = true;
	$scope.selectedUserList = [];
	$scope.businessObject;
	$scope.selectedDocBaseUuid = objectBaseUuid;

    $scope.columns =[
                     {displayName: '', field:'', width: 27,
                         cellTemplate: '<div ng-Dblclick="add(row.entity)"> <img name="User" style="width:23px; height:25px; margin-left:2px; margin-top:2px; vertical-align: -webkit-baseline-middle;" ng-src="{{getIconByType(row.entity)}}"></div>'
                       },
                       {displayName:'Name', field:'name',
                    	   cellTemplate: '<div  style="margin:5px 0 0 4px;" ng-Dblclick="add(row.entity)" ng-bind="row.getProperty(col.field)"></div>'}
                     ];

    $scope.selectedUserColumns =[
                       {displayName: '', field:'', width: 27,
                            cellTemplate: "<div> <img name='User' style='width:23px; height:25px; margin-left:2px; vertical-align: -webkit-baseline-middle;' ng-src='{{getIconByType(row.entity)}}'></div>"
                       },
                       {displayName:'Name', field:'name'},
                       {displayName:'', filed:'', width: 27,
                    	   cellTemplate: "<div> <img name='Delete' class='rulesDeleteUserBtn' ng-click='deleteUser(row.entity)'></div>"}
                     ];


    var getAllVersionsForSelectedDocument = function (baseUuid)
    {
    	homeService.getBusinessObjectVOJSONByBaseuuid(baseUuid).then(function(data)
			{
				$scope.businessObject = data.data;
			});
    };

    var getUsers = function()
	{
    	commonService.getUsers().then(function(data){
    		$scope.users = data;
    	});
	}


    var loadFollowers = function() {
    	getUsers();
    	repositoryService.getSubscriptionsForObjectId($scope.selectedDocBaseUuid).then(function(data){
    		var subscriptions = data.data;
    		for(var index=0; index<subscriptions.length; index++) {
    			$scope.selectedUserList.push(subscriptions[index].subscriber);
    		}
    	});
    }


    $scope.saveUserChanges = function(type)
    {
    	if(type=='Save') {
    		if($scope.selectedUserList.length > 0){
    			var selectedUsersUuid = [];
        		for (var index=0; index<$scope.selectedUserList.length; index++){
        			selectedUsersUuid.push($scope.selectedUserList[index].objectBaseUuid);
        		}
        		var selectedUsersUuidJson = angular.toJson(selectedUsersUuid);
        		repositoryService.saveSubsciptions($scope.selectedDocBaseUuid, selectedUsersUuidJson);
    		}
    	}
    	$scope.selectedUserList = [];
    	$modalInstance.dismiss('cancel');
    }

    $scope.addSelectedUser = function(selectedItem)
	{
    	if(selectedItem instanceof Array) {
    		for (var i=0; i<selectedItem.length; i++) {
    			var index = $scope.selectedUserList.indexOf(selectedItem[i]);
            	if(index == -1) {
            		$scope.selectedUserList.push(selectedItem[i]);
            	}
    		}
    	} else {
    		var index = $scope.selectedUserList.indexOf(selectedItem);
        	if(index == -1) {
        		$scope.selectedUserList.push(selectedItem);
        	}
    	}
	}

    $scope.deleteUser = function(selectedItem)
    {
    	var index = $scope.selectedUserList.indexOf(selectedItem);
    	if(index > -1) {
    		$scope.selectedUserList.splice(index, 1);
    	}
    }

    loadFollowers();
    getAllVersionsForSelectedDocument($scope.selectedDocBaseUuid);
}