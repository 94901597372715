annotationHandlingController.$inject = ['$scope', 'localizedMessages', 'bvDialogService'];

function annotationHandlingController($scope, localizedMessages, bvDialogService){

  const bvModal = $scope.bvModalControllerInputs.bvModal
  const uploadFileObject = $scope.bvModalControllerInputs.uploadFileObject

  $scope.popupTitle 	= localizedMessages.get('DOCUMENT_ANNOTAION_HANDLING');
  $scope.actionBtnTxt = localizedMessages.get('REPLACE');
  $scope.closeBtnTxt 	= localizedMessages.get('CANCEL');

  $scope.label = {
    warning: localizedMessages.get('WARNING'),
    replacingExistingDocument : localizedMessages.get('MSG_YOU_ARE_REPLACING_AN_EXISTING_DOCUMENT'),
    howYouHandle : localizedMessages.get('MSG_BEFORE_REPLACING_THIS_DOCUMENT'),
    keepButtonTitle: localizedMessages.get('MSG_KEEP_ANNOTATIONS_ON_EXISTING_DOCUMENT'),
    keepButtonText: localizedMessages.get('MSG_THE_EXISTING_DOCUMENT_WILL_BE_RETAINED'),
    moveButtonTitle: localizedMessages.get('MSG_MOVE_ANNOTATIONS_TO_UPDATED_DOCUMENT'),
    moveButtonText: localizedMessages.get('MSG_ANNOTATIONS_WILL_BE_MOVED'),
    purgeButtonTitle: localizedMessages.get('PURGE_ANNOTATIONS'),
    purgeButtonText: localizedMessages.get('MSG_ANNOTATIONS_MADE_ON_THE_EXISTING'),
    annotationsMayAppear: localizedMessages.get('MSG_ANNOTATIONS_MAY_APPEAR_OUT_OF_CONTEXT')
  }

  $scope.enableActionBtn = false

  $scope.onSelect = function(action) {
    $scope.selectedAction = action
    $scope.enableActionBtn = true
  }

  $scope.action = function() {
    uploadFileObject.uploadComplete = function(){
      bvModal.close();
    }
    uploadFileObject.annotationChoice = $scope.selectedAction
    bvDialogService.replaceFile(uploadFileObject);
  }

  $scope.bvModalControllerInputs.closeButton = function(){
      bvModal.close();
  }
}