function PushController($scope, $state, $filter, $stateParams, pushService, localizedMessages, bvDialogService, bvDialog, repositoryService, contextMenuModel, homeService, context, surveysService, approvalsService) {
	
	$scope.$parent.currentState.value = $state.$current.name;
	
	$scope.ui = { label : {
    	comments		:localizedMessages.get("COMMENTS"),
    	pushed_on		:localizedMessages.get("PUSHED_ON"),
    	expires_on		:localizedMessages.get("EXPIRES_ON"),
    	deletePush	  	:localizedMessages.get("DELETE"),
    	re_push			:localizedMessages.get("RE_PUSH"),
    	push_by			:localizedMessages.get("PUSH_BY"),
      }
	};
	
	$scope.scrollConfig = {
        autoHideScrollbar: true,
        theme: 'minimal-dark'
    };
	
	$scope.middleBarObject.title =  localizedMessages.get('HOME');	
	
	var loadData = function(docVO) {
		if(StringUtils.isApprovalPollQuestion(docVO.objectBaseUuid)) {
			docVO.progressBar = (docVO.noOfRespondents/docVO.totalParticipants)*100;
			docVO.type = 'APPROVAL';
			if(docVO.totalParticipants != undefined && docVO.totalParticipants > 0)
			{
				docVO.hasParticipants = true;
			}
		}
		else if(StringUtils.isDocument(docVO.objectBaseUuid)) {
			if(docVO.signPendingUserMap)
			{
				var signPendingUserCount = Object.keys(docVO.signPendingUserMap).length;
				docVO.totalUsers = signPendingUserCount;
				docVO.progressBar = 0;
			}

			if(docVO.signCommitUserMap)
			{
				var signCommitUserCount = Object.keys(docVO.signCommitUserMap).length;
				if(signCommitUserCount > 0)
				{
					docVO.totalUsers = docVO.totalUsers + signCommitUserCount;
					var precentCommit = signCommitUserCount / docVO.totalUsers * 100;
					docVO.progressBar = precentCommit;	
				}
			}
			if(docVO.totalUsers != undefined && docVO.totalUsers > 0) 
			{
				docVO.hasParticipants = true;
				docVO.type = 'SIGNATURE';
			} else {
				docVO.type = 'DOCUMENT';
			}
			
		} 
		else if(StringUtils.isSurvey(docVO.objectBaseUuid)) {
			docVO.progressBar = (docVO.noOfRespondents/docVO.totalParticipants)*100;
			docVO.type = 'SURVEY';
			if(docVO.totalParticipants != undefined &&docVO.totalParticipants > 0)
			{
				docVO.hasParticipants = true;
			}
		}
		else if(StringUtils.isBoardbook(docVO.objectBaseUuid)) {
			docVO.type = 'BOARDBOOK';
		}
	}
	
	$scope.objectPushesCreatedBymeList = [];
	$scope.objectPushesSentTomeList = [];
	
	$scope.getObjectPushesCreatedByMe = function() {
		pushService.getObjectPushesCreatedByMe().then(function(data){
    		$scope.objectPushesCreatedByme = data;
    		for(var i=0; i<$scope.objectPushesCreatedByme.length; i++) {
    			loadData($scope.objectPushesCreatedByme[i].pushBO);
    		}
    		$scope.objectPushesCreatedBymeList = $scope.objectPushesCreatedByme;
		});
	}
	
	$scope.getObjectPushesSentToMe = function() {
		if(!SYNC_STARTED)
		{
			pushService.getObjectPushesSentToMe().then(function(data){
	    		$scope.objectPushesSentTome = data;
	    		for(var i=0; i<$scope.objectPushesSentTome.length; i++) {
	    			loadData($scope.objectPushesSentTome[i].pushBO);
	    		}
	    		$scope.objectPushesSentTomeList = $scope.objectPushesSentTome;
			});
		}
	}
	
	var getSentToMePushCount = function(){
		homeService.getSentToMePushCount().then(function(data){
			$scope.pushItems.value = data;
			if($scope.pushItems.value > 0 && !$scope.expandedMenu)
	    	{
	    		$scope.showBadge.value = true + "";
	    	} else {
	    		$scope.showBadge.value = false + "";
	    	}
		});
	}
	
	var filterDocuments = function(currentList, type)
	{
		var listOfBusinessObject = [];
		
		for (var i = 0, len = currentList.length; i < len; i++) 
		{
			var docVO = currentList[i].pushBO;
			
			if(docVO.type == type)
			{
				listOfBusinessObject.push(currentList[i]);
			}
		}
		
		return listOfBusinessObject;
	}
	
	var showAll = function(){
		if($scope.tabs[0].active){
			$scope.objectPushesSentTome = $scope.objectPushesSentTomeList;
		}
		if($scope.tabs[1].active){
			$scope.objectPushesCreatedByme = $scope.objectPushesCreatedBymeList;
		}
	}
	var showApproval = function(){
		if($scope.tabs[0].active){
			$scope.objectPushesSentTome = filterDocuments($scope.objectPushesSentTomeList, 'APPROVAL')
		}
		if($scope.tabs[1].active){
			$scope.objectPushesCreatedByme = filterDocuments($scope.objectPushesCreatedBymeList, 'APPROVAL')
		}
	}
	var showBoardBook = function(){
		if($scope.tabs[0].active){
			$scope.objectPushesSentTome = filterDocuments($scope.objectPushesSentTomeList, 'BOARDBOOK')
		}
		if($scope.tabs[1].active){
			$scope.objectPushesCreatedByme = filterDocuments($scope.objectPushesCreatedBymeList, 'BOARDBOOK')
		}
	}
	var showSignature = function(){
		if($scope.tabs[0].active){
			$scope.objectPushesSentTome = filterDocuments($scope.objectPushesSentTomeList, 'SIGNATURE')
		}
		if($scope.tabs[1].active){
			$scope.objectPushesCreatedByme = filterDocuments($scope.objectPushesCreatedBymeList, 'SIGNATURE')
		}
	}
	var showSurvey = function(){
		if($scope.tabs[0].active){
			$scope.objectPushesSentTome = filterDocuments($scope.objectPushesSentTomeList, 'SURVEY')
		}
		if($scope.tabs[1].active){
			$scope.objectPushesCreatedByme = filterDocuments($scope.objectPushesCreatedBymeList, 'SURVEY')
		}
	}
	
	var options = [];
		
	options.push({name:localizedMessages.get("ALL"), value: "showAll"});
	options.push({name:localizedMessages.get("APPROVAL"), value: "showApproval"});
	options.push({name:localizedMessages.get("BOARDBOOK"), value: "showBoardBook"});
	options.push({name:localizedMessages.get("SIGNATURE"), value: "showSignature"});
	options.push({name:localizedMessages.get("SURVEY"), value: "showSurvey"});
	
	var settingOption = { title:localizedMessages.get('SHOW'),click:'', iconUrl: '', svgFill: '#fff', dropDownMenu:options};
	
	var pushRightMenuList = [settingOption];
	
	$scope.tabs = [
		               { title:localizedMessages.get("DASHBOARD"), content:'', func: $scope.getObjectPushesSentToMe, active: true, rightMenuList:pushRightMenuList},
		               { title:localizedMessages.get("PUSHED_BY_ME"), content:'', func: $scope.getObjectPushesCreatedByMe, active: false, rightMenuList:pushRightMenuList},
	               ];
	
	$scope.showPushMenu = function(event, push)
    {
		$scope.showRightClick = false;
		if (event)
		{
			event.preventDefault();
      		event.stopPropagation();
      		$scope.handleRightClick(event.pageX, event.pageY, push, event);
    	}    	
    }
       
    $scope.handleRightClick = function (xPos, yPos, push, event)
    {
    	contextMenuModel.parentObject = $scope.currentInstance;
    	$scope.rightClickDispatcher(xPos, yPos, push, event)
    }
        
	$scope.showRightClick = false;

	$scope.rightClickDispatcher = function (xPos, yPos, selectedContextItem, event)
	{

		if(selectedContextItem === undefined || selectedContextItem == null)
			return;

			$scope.selectedObjectFromContextMenu = "";

			contextMenuModel.xPos = xPos;
			contextMenuModel.yPos = yPos;
			contextMenuModel.event = event;
			contextMenuModel.currentInstance = $scope.currentInstance;
			contextMenuModel.selectedObject = selectedContextItem;
			contextMenuModel.contextMenuCheckOptions = {};
			contextMenuModel.multiple = false;
			
			$scope.showRightClick = true;
	};
        
	$scope.$watch('showRightClick', function(oldValue, newValue){
		console.log(oldValue, newValue);	
	}, true);
       
    $scope.$on('HOME_CONTROLLER_CLICK', function(event){ 
    	if($scope.showRightClick)
        	$scope.showRightClick = false;
    });
	
	$scope.menuCallback = function(type, param){
    	switch (type)
    	{
            case 'MENU_STYLE':
            	$scope.menuStyle = {'top': param[0].y + "px",'left': param[0].x + "px"};
                break;
            case 'MENU_ARROW_STYLE':
                $scope.menuLeftArrowStyle = {'top': param.y + "px", 'left': param.x + "px"};
                break;
            case 'VIEW':
            	$scope.showPushDetails(contextMenuModel.selectedObject);
            	break;
            case 'DELETE':
            	deletePushConfirm();
            	break;
            case 'PUSH':
            	rePush(contextMenuModel.selectedObject);
            	break;
            case 'PUSH_TO_ALL':
            	pushToAll(contextMenuModel.selectedObject);
			default:
				break;						
		}
    }
	
	$scope.showPushDetails = function(push) {
		if($scope.tabs[1].active)
		{
			pushService.getObjectPushesByPushId(push.pushId).then(function(data){
				loadData(data[0].pushBO);
				bvDialog.showPushDetails(data[0]);
			});
		}
		else 
			bvDialog.showPushDetailsToUser(push, true);
	}
	
	$scope.getLocalizedDate = function(date) {
		return moment($filter('date')(date, 'medium')).format('lll');
	}
	
	var rePush = function(objectPush) {
		bvDialog.showPush(objectPush, 'RE_PUSH');
	}
	
	var pushToAll = function(objectPush) {
		bvDialog.showPush(objectPush, 'PUSH_TO_ALL');
	}
	
	$scope.selectMenuItem = function(ob)
	{		
		if(ob.value == "showAll")
		{
			showAll();
		}
		else if(ob.value == "showApproval")
		{
			showApproval();
		}
		else if(ob.value == "showBoardBook")
		{
			showBoardBook();
		}
		else if(ob.value == "showSignature")
		{
			showSignature();
		}
		else if(ob.value == "showSurvey")
		{
			showSurvey();
		}
	}
	
	
	var deletePushConfirm = function()
	{
		if(contextMenuModel.selectedObject.createdbyBaseUuid == context.getModel().userVO.objectBaseUuid && $scope.tabs[1].active) {
			bvDialog.showRemovePush(contextMenuModel.selectedObject);
		} 
		else {
			bvDialog.confirm( localizedMessages.get('MSG_DELETE_PUSH_BY_RECIPIENT_CONFIRMATION'), deletePushByRecipient);
		}
		
	};
	
	var deletePushByRecipient = function()
	{
		pushService.deleteObjectPushSentToMe(contextMenuModel.selectedObject.pushId).then(function(data){
			$scope.getObjectPushesSentToMe();
			getSentToMePushCount();
		});
	}
	
	var showSurveyDetail = function(objectBaseUuid)
	{
		$scope.showSurveyOverview = true;
		$scope.showSectionDetailsDIV = false;
		surveysService.getSurveyDetailsJSON(objectBaseUuid).then(function(data) {
			$state.go('home.openSurvey');
			$scope.bvModalControllerInputs.bvModal.close();
			surveysService.setSurvey(data);
		    var survey = surveysService.getSurvey();
		    surveysService.setCurrentSelectedSurveyFromList(survey); //For survey report
			surveysService.setParticipantBaseUuidList(survey);		//For survey report
			surveysService.setQuestionBaseUuidList(survey);
			surveysService.setAllQuestionsList(survey);
		    sessionStorage.setItem('surveyBaseUuid',survey.objectBaseUuid);
		});
	};
	var showApprovalDetail = function(objectBaseUuid) {
		approvalsService.selectedApprovalUuid = objectBaseUuid;
		$state.go('home.approval');
		$scope.bvModalControllerInputs.bvModal.close();
	};
	
	$scope.openAttachment = function(attachment) {
		if(attachment.boName.toLowerCase() == "folderbo") {
			bvDialogService.openFolderView(attachment);
		} 
		else if(attachment.boName.toLowerCase() == "documentbo"){
			repositoryService.openViewer(attachment, undefined, true);
		} 
		else if(attachment.type.toLowerCase() == "survey"){
			showSurveyDetail(attachment.objectBaseUuid);
		}
		else if(attachment.type.toLowerCase() == "approval"){
			showApprovalDetail(attachment.objectBaseUuid);
		}
	}
	
}

function PushDetailController($scope, $state, $filter, $stateParams, pushService, localizedMessages, repositoryService, imageService, bvDialogService, surveysService, approvalsService, bvDialog) {
	
	$scope.ui = { label : {
    	comments	:localizedMessages.get("COMMENTS"),
    	expires_on		:localizedMessages.get("EXPIRES_ON"),
    	details		:localizedMessages.get("DETAILS"),
    	progress		:localizedMessages.get("PROGRESS"),
    	analyse		:localizedMessages.get("ANALYSE"),
      }
	};
	
	$scope.UI_USER = localizedMessages.get('USER');
	$scope.UI_STATUS = localizedMessages.get('STATUS');
	$scope.UI_DATE = localizedMessages.get('DATE');
	$scope.UI_PENDING = localizedMessages.get('PENDING');
	$scope.UI_COMMITED = localizedMessages.get('COMMITED');
	$scope.UI_PROGRESS = localizedMessages.get('PROGRESS');
	$scope.UI_PUSHED_ON = localizedMessages.get('PUSHED_ON');
	$scope.UI_SYNCED_ON = localizedMessages.get('SYNCED_ON');
	$scope.UI_PUSHED_VERSION = localizedMessages.get('PUSHED_VERSION');
	$scope.UI_SYNCED_VERSION = localizedMessages.get('SYNCED_VERSION');
	$scope.UI_PUSH_TO_ALL = localizedMessages.get('PUSH_TO_ALL');
	$scope.UI_RE_PUSH = localizedMessages.get('RE_PUSH');
	
	$scope.push = $scope.bvModalControllerInputs.selectedObject;
	
	var selectedBO = $scope.push.pushBO;
	
	if($scope.bvModalControllerInputs.fromRecipient != undefined && $scope.bvModalControllerInputs.fromRecipient) {
		$scope.popupTitle = selectedBO.name
	}
	else {
		$scope.popupTitle = selectedBO.name + ' - ' + localizedMessages.get('VERSION') + ': ' +selectedBO.versionNumber;
	}
	
	var checkFolder = StringUtils.isFolder(selectedBO.objectBaseUuid);
	var attachmentType = localizedMessages.get(StringUtils.getTypeByObjectBaseUuid(selectedBO.objectBaseUuid).toUpperCase());
	$scope.properitesData = [];
	$scope.properitesData.push( {title: localizedMessages.get("NAME"), desc: selectedBO.name} );        		
	$scope.properitesData.push( {title: localizedMessages.get("TYPE"), desc: attachmentType} );
	if(!checkFolder){
		var fileSize = StringUtils.fileSizeConverter(selectedBO.fileSize, 0);
		$scope.properitesData.push( {title: localizedMessages.get("FILE_SIZE"), desc: fileSize} );
	}
	$scope.properitesData.push( {title: localizedMessages.get("CREATED_ON"), desc: moment(selectedBO.originalCreatedOn).format("MMM DD, YYYY HH:MM:SS A")} );
	$scope.properitesData.push( {title: localizedMessages.get("LAST_MODIFIED_ON"), desc: moment(selectedBO.lastModifiedOnGMTMillis).format("MMM DD, YYYY HH:MM:SS A")} );
	if(selectedBO.lastModifiedBy != undefined){
		$scope.properitesData.push( {title: localizedMessages.get("MODIFIED_BY"), desc: selectedBO.lastModifiedBy} );
	}
	
	$scope.CURRENT_OPTION = "UI_ANALYSE";
	
	$scope.changeAnalyseOption = function (tabVal)
	{
		$scope.CURRENT_OPTION = tabVal;
	}
	
	var fileName = selectedBO.name;
	
	$scope.statusList = [];
	
	$scope.progressBar = selectedBO.progressBar;
	
	var commitedList = [];
	var pendingList = [];
	
	$scope.rePush = function() {
		bvDialog.showPush($scope.push, 'RE_PUSH');
	}
	
	$scope.pushToAll = function() {
		bvDialog.showPush($scope.push, 'PUSH_TO_ALL');
	}

	var buildSignatureStatusList = function()
	{
		for(var userId in pendingList)
		{
			var signObj = new Object;
			signObj.user = pendingList[userId];
			signObj.status = $scope.UI_PENDING;
			signObj.commitedOn = "";

			$scope.statusList.push(signObj);
		}

		for(var userId in commitedList)
		{
			var signObj = new Object;
			var userDetails = commitedList[userId];
			var commitedOn = userDetails.substring(userDetails.indexOf("##") + 2);

			signObj.user = userDetails.substring(0, userDetails.indexOf("##"));
			signObj.commitedOn = moment(commitedOn).format("lll");
			signObj.status = $scope.UI_COMMITED;
			
			$scope.statusList.push(signObj);
		}
	}
	
	var buildSurveyOrApprovalStatusList = function()
	{
		for(var index in pendingList)
		{
			var signObj = new Object;
			signObj.user = pendingList[index].name;
			signObj.status = $scope.UI_PENDING;
			signObj.commitedOn = "";

			$scope.statusList.push(signObj);
		}

		for(var index in commitedList)
		{
			var signObj = new Object;
			signObj.user = commitedList[index].name;
			signObj.status = $scope.UI_COMMITED;
			signObj.commitedOn = moment(commitedList[index].responseDate).format("lll");
			
			$scope.statusList.push(signObj);
		}
	}
	
	if(selectedBO.type == 'SIGNATURE') {
		commitedList = selectedBO.signCommitUserMap;
		pendingList = selectedBO.signPendingUserMap;
		buildSignatureStatusList();
	}
	else if(selectedBO.type == 'SURVEY' || selectedBO.type == 'APPROVAL') {
		commitedList = selectedBO.participantCommitList;
		pendingList = selectedBO.participantPendingList;
		buildSurveyOrApprovalStatusList();
	}
	
	$scope.getDocumentThumbnail = function(attachment) {
		return imageService.getThumbnailForObject(attachment);
	}
	
	var showSurvey = function(objectBaseUuid)
	{
		$scope.showSurveyOverview = true;
		$scope.showSectionDetailsDIV = false;
		surveysService.getSurveyDetailsJSON(objectBaseUuid).then(function(data) {
			$state.go('home.openSurvey');
			$scope.bvModalControllerInputs.bvModal.close();
			surveysService.setSurvey(data);
		    var survey = surveysService.getSurvey();
		    surveysService.setCurrentSelectedSurveyFromList(survey); //For survey report
			surveysService.setParticipantBaseUuidList(survey);		//For survey report
			surveysService.setQuestionBaseUuidList(survey);
			surveysService.setAllQuestionsList(survey);
		    sessionStorage.setItem('surveyBaseUuid',survey.objectBaseUuid);
		});
	};
	var showApproval = function(objectBaseUuid) {
		approvalsService.selectedApprovalUuid = objectBaseUuid;
		$state.go('home.approval');
		$scope.bvModalControllerInputs.bvModal.close();
	};
	
	$scope.openAttachment = function(attachment) {
		if(attachment.boName.toLowerCase() == "folderbo") {
			bvDialogService.openFolderView(attachment);
		} 
		else if(attachment.boName.toLowerCase() == "documentbo"){
			repositoryService.openViewer(attachment, undefined, true);
		} 
		else if(attachmentType.toLowerCase() == "survey"){
			showSurvey(attachment.objectBaseUuid);
		}
		else if(attachmentType.toLowerCase() == "approval"){
			showApproval(attachment.objectBaseUuid);
		}
	}
	
	$scope.getLocalizedDate = function(date) {
		return moment($filter('date')(date, 'medium')).format('lll');
	}
		
};

function PushRemoveController($scope, $state, $filter, $stateParams, pushService, localizedMessages, bvDialogService, bvDialog)
{
	var selectedPush = $scope.bvModalControllerInputs.selectedObject;
	$scope.popupTitle  =   localizedMessages.get('RECIPIENTS');
	
	$scope.ui = { label : {
    	select_all	:localizedMessages.get("SELECT_ALL"),
    	recipients  :localizedMessages.get("RECIPIENTS"),
    	cancel	:localizedMessages.get("CANCEL"),
    	remove	:localizedMessages.get("REMOVE"),
      }
	};
	
	$scope.selectAll = false;
	
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	
	$scope.recipients = selectedPush.objectPushUsers;
	
	var close = function(result) {
    	bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), cancelEdit );
    };
    
    var cancelEdit = function(){
		$scope.bvModalControllerInputs.bvModal.close();
	}
	
	var deletePush = function() 
	{
		var pushController = angular.element(document.querySelector('[push-controller]')).scope();
    	
//		if($scope.selectAll) 
//		{
//			pushService.deleteObjectPushCreatedByMe(selectedPush.pushId).then(function(data){
//				if(pushController)
//		    	{
//		    		if(pushController.tabs[0].active) {
//		    			pushController.getObjectPushesSentToMe();
//					}
//					else if(pushController.tabs[1].active) {
//						pushController.getObjectPushesCreatedByMe();
//					}
//		    		pushController.getSentToMePushCount();
//		    	}
//				cancelEdit();
//			});
//		} 
//		else {
			var securityUuids = [];
			for(var i=0; i<$scope.recipients.length; i++)
			{
				if($scope.recipients[i].deleted) {
					securityUuids.push($scope.recipients[i].securityUuid);
				}
			}
			
			pushService.deleteObjectPushFromUsers(selectedPush.pushId, angular.toJson(securityUuids)).then(function(data){
				if(pushController)
		    	{
		    		if(pushController.tabs[0].active) {
		    			pushController.getObjectPushesSentToMe();
					}
					else if(pushController.tabs[1].active) {
						pushController.getObjectPushesCreatedByMe();
					}
		    		pushController.getSentToMePushCount();
		    		
		    	}
				cancelEdit();
			});
//		}
		
	}
	
	$scope.toggleSelectAll = function(value)
	{
		$scope.selectAll = value;
		if(value) {
			for(var i=0; i<$scope.recipients.length; i++)
			{
				$scope.recipients[i].deleted = true;
			}
		} else {
			for(var i=0; i<$scope.recipients.length; i++)
			{
				$scope.recipients[i].deleted = false;
			}
		}
	}
	
	$scope.toggleRecipient = function(recipient, value)
	{
		recipient.deleted = value;
	}
	
	var buttons = $scope.bvModalControllerInputs.buttons;
	buttons[0] = {name: $scope.ui.label.cancel, action: close};
    buttons[1] = {name: $scope.ui.label.remove, action: deletePush};
}

function PushMenuController($scope, pushContextMenu, contextMenuModel, bvDialog, localizedMessages, homeService)
{
	var showContextMenu = function()
	{
		$scope.contextMenuOption = pushContextMenu.getContextMenu(contextMenuModel.multiple, contextMenuModel.selectedObject, contextMenuModel.parentObject, contextMenuModel.contextMenuCheckOptions);
		console.log($scope.contextMenuOption);
		var point = pushContextMenu.getPosition(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedObject, $scope.contextMenuOption.length, 31, 180);
	
    	var event = contextMenuModel.event;

    	!homeService.homeContext.expandedMenu ? point.x = point.x - 300 :  point.x;
    	$scope.currentInstance = contextMenuModel.currentInstance;
        
        $scope.menuParentCallback("MENU_STYLE",[point]);
      	var arrow_point = pushContextMenu.getArrowPosition(contextMenuModel.xPos, contextMenuModel.yPos,$scope.contextMenuOption.length, 31, 180);
      	!homeService.homeContext.expandedMenu ? arrow_point.x = arrow_point.x - 300 :  arrow_point.x;
        $scope.menuParentCallback("MENU_ARROW_STYLE",arrow_point) ;
	}
 	
 	$scope.openContextMenu = function(type)
    {
    	switch(type)
    	{
    		case 'view':  $scope.menuParentCallback("VIEW",[]);break;
            case 'delete': $scope.menuParentCallback("DELETE",[]);break;
            case 'push': $scope.menuParentCallback("PUSH",[]);break;
            case 'pushToAll':$scope.menuParentCallback("PUSH_TO_ALL",[]);break;
    	}	    	
    }
 	
	showContextMenu(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedContextItem, contextMenuModel.event);
}


function PushContextMenu(localizedMessages, contextMenuSetting, context, homeModel, homeContext, pushService) 
{
	var contextMenuOption = {};
	var pushContextMenu = [];
	
	contextMenuOption['view'] 	= { title:localizedMessages.get('VIEW'), iconUrl: '', svgFill: '#fff', type: 'view'};
	contextMenuOption['delete'] = { title:localizedMessages.get('REMOVE'), iconUrl: '', svgFill: '#fff', type: 'delete'};
	contextMenuOption['push'] = { title:localizedMessages.get('RE_PUSH'), iconUrl: '', svgFill: '#fff', type: 'push'};
	contextMenuOption['pushToAll'] = { title:localizedMessages.get('PUSH_TO_ALL'), iconUrl: '', svgFill: '#fff', type: 'pushToAll'};
	
	var pushController = angular.element(document.querySelector('[push-controller]')).scope();
	
	this.getContextMenu = function(isMultipleSelect, pushObject, parentContainer, contextMenuCheckOptions)
	{
		pushContextMenu = [];

		if(MobileUtil.isAndroid() || MobileUtil.isIPhone())
		{

		}
		else 
		{
			var currentUserVO = context.getModel().userVO;
			var isCreator = pushObject.creator.objectBaseUuid == currentUserVO.objectBaseUuid;
			pushContextMenu.push(contextMenuOption['view']);
			if((isCreator) && !MobileUtil.isSurface()) {
				if(pushController && pushController.tabs[1].active) {
					pushContextMenu.push(contextMenuOption['push']);
					pushContextMenu.push(contextMenuOption['pushToAll']);
				}
			}
			pushContextMenu.push(contextMenuOption['delete']);
		  	return pushContextMenu;
		}	  
	}

	this.getPosition = function(xPos, yPos, selectedContextItem, length, factor, width)
	{
		return contextMenuSetting.getPosition(xPos, yPos, selectedContextItem, length, factor, width)
	}

	this.getArrowPosition = function(xPos, yPos, length, factor, width)
	{
		return contextMenuSetting.getArrowPosition(xPos, yPos, length, factor, width)
	}
};

function PushMenu(){
    return{
        restrict: 'A',
        scope:{
        	menuStyle: '=',
        	menuParentCallback:'='
        },
        templateUrl: 'directives/contextmenu/ContextMenu.tpl.html',
        controller: 'pushMenuController'
    }
};

function ContextMenuModel()
{
	this.selectedObject = {};
	this.parentObject = {};
	this.parentFolder = {};
	this.xPos = "";
	this.yPos = "";
	this.event = {};
	this.contextMenuCheckOptions = {};
	this.multiple = false;
	this.currentInstance = "";
};


angular
	.module('push', [])
	.controller('PushController', ['$scope', '$state', '$filter', '$stateParams', 'pushService', 'localizedMessages', 'bvDialogService', 'bvDialog', 'repositoryService', 'contextMenuModel','homeService', 'context', 'surveysService', 'approvalsService',PushController])
	.controller('PushDetailController', ['$scope', '$state', '$filter', '$stateParams', 'pushService', 'localizedMessages', 'repositoryService', 'imageService', 'bvDialogService', 'surveysService', 'approvalsService', 'bvDialog', PushDetailController])
	.controller('PushRemoveController', ['$scope', '$state', '$filter', '$stateParams', 'pushService', 'localizedMessages', 'bvDialogService', 'bvDialog', PushRemoveController])
	.controller('pushMenuController', ['$scope', 'pushContextMenu','contextMenuModel', 'bvDialog', 'localizedMessages', 'homeService', PushMenuController])
    .directive('pushMenu', PushMenu)
    .service('pushContextMenu', ['localizedMessages', 'contextMenuSetting', 'context', 'homeModel','homeContext', 'pushService', PushContextMenu])
    .service('contextMenuModel', ContextMenuModel)