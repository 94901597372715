var StringUtils = {
		
	capitaliseFirstLetter : function(string)
	{
	    return string.charAt(0).toUpperCase() + string.slice(1);
	},
	
//	toBvCamelCase : function(inputStr) {
//	    var outputStr = "";
//	    inputStr.split(" ").forEach(function (el, idx) {
//	        var add = el.toLowerCase();
//	        outputStr += add[0].toUpperCase() + add.slice(1) + " ";
//	    });
//	    return outputStr;
//	},
	
	isCalendarEvent : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0DOCE:') === 0 || objectBaseUuid.indexOf('1DOCE:') === 0));
	},
	
	isWikiPage : function(objectBaseUuid){
		return (objectBaseUuid && objectBaseUuid.indexOf('0DOWK') === 0);
	},
	
	isFolder : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0CODF:') === 0 || objectBaseUuid.indexOf('0COMD:') === 0 || objectBaseUuid.indexOf('1CODF:') === 0));
	},
	
	isCollate : function(objectBaseUuid)
	{
		 return (objectBaseUuid && (objectBaseUuid.indexOf('0DODC:') === 0 || objectBaseUuid.indexOf('1DODC:') === 0));
	},
	
	isBoardbook : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0DODB:') === 0 || objectBaseUuid.indexOf('1DODB:') === 0))
	},
	
	isMultiMediaVO : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0DOMM:') === 0 || objectBaseUuid.indexOf('1DOMM:') === 0));
	},
	
	isImageVO : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0DOIM:') === 0 || objectBaseUuid.indexOf('1DOIM:') === 0));
	},
	
	isApprovalPollQuestion : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0DOAA:') === 0);
	},
	
	isApprovalPollQuestion : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0DOAA:') === 0);
	},
	
	isApprovalShareContainer : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0COAS:') === 0);
	},
	
	isSharedContainer : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0COSX:') === 0);
	},

	isSharedAnnotationContainer : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0CODS:') === 0));
	},
	isSharedAgendaAnnotationContainer : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0COZA:') === 0));
	},
	isSharedSignatureContainer : function(objectBaseUuid)
    {
        return (objectBaseUuid && (objectBaseUuid.indexOf('0COSG:') === 0));
    },
	isDocument : function(objectBaseUuid)
	{
		return (objectBaseUuid && (objectBaseUuid.indexOf('0DODD:') === 0 || objectBaseUuid.indexOf('1DODD:') === 0));
	},
	
	isRepositoryObject : function(objectBaseUuid)
	{
		return (this.isDocument(objectBaseUuid) || this.isMultiMediaVO(objectBaseUuid) || this.isImageVO(objectBaseUuid) || this.isBoardbook(objectBaseUuid) || this.isCollate(objectBaseUuid));
	},

	isSurvey : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0COZS:') === 0);
	},
	
	isSurveyShareContainer : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0COZC:') === 0);
	},
	
	isTeamSpaceGroup :function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0COGT:') === 0) ;
	},
	
	isCompanyGroup : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0GRUP:') === 0);
	},
	
	isPersonalTeamSpace : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0PRFL:') === 0);
	},

	isTeamSpace : function(objectBaseUuid)
	{
		return (objectBaseUuid && objectBaseUuid.indexOf('0PRTL:') === 0);
	},

	getExtensionType : function(filename)
	{
	    var ext = filename.split('.').pop();
	    if(ext == filename) return "";
	    return ext;
	},
	
	replaceNotSupportCharWithDash: function(strToBeReplaced)
    {
        if (StringUtils.isNullEmpty(strToBeReplaced))
            return strToBeReplaced;

        var specialChar = "\/<>\"";
        for (var j = 0; j < specialChar.length; j++)
        {            
            strToBeReplaced = strToBeReplaced.split(specialChar.charAt(j)).join('_');
        }
        return strToBeReplaced;
    },        
    isNullEmpty : function(value)
	{
		return (value == null || value.length === 0);
	},
	
	uintToRgb : function(color){
		var r = (color >> 16) & 0xff;
		var g = (color >> 8) & 0xff;
		var b = color & 0xff;
	
		return r + "," + g + "," + b;
	},
	
	rgbToHex : function(r, g, b) {
		r = parseInt(r);
		g = parseInt(g);
		b = parseInt(b);
	    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
	},
	
	hexToUint : function(hex) {
		hex = hex.split('#')[1];
	    var bigint = parseInt(hex, 16);
	    var r = (bigint >> 16) & 255;
	    var g = (bigint >> 8) & 255;
	    var b = bigint & 255;
	
	    var color = r;
	    color = (color << 8) + g;
	    color = (color << 8) + b;
	
	    return color;
	},
	
	validDocumentExtension : function(newName, oldExtension)
	{
		var lastIndex = newName.lastIndexOf(".");
	   	var newExtension = lastIndex != -1 ? newName.substr(lastIndex + 1) : "";
	   	var filename = newName;
	   	if(oldExtension != newExtension)
	   	{
	   		if(lastIndex == -1)
	   		{
	   			filename = newName + "." + oldExtension;
	   		}
	   		else
	   		{
	   			filename = newName.substr(0, lastIndex + 1) + oldExtension;
	   		}
	   	}
	   	if(newName.indexOf(oldExtension)>-1)
	   	{
	   		var lastIndex2 = newName.lastIndexOf(oldExtension);
	   		filename = newName.substr(0, lastIndex2) + oldExtension;
	   	}
	   	else
	   	{
	   		filename = newName + "." + oldExtension;
	   	}	
	
	   	return filename;
	},
	
	/**
	 * Return List of objectBaseUuids from List of BusinessObjectVOs
	 *
	 */
	
	getBaseUuidsFromBusinessObjects : function(listOfBusinessObjects)
	{
		var listOfBaseUuids = [];
	
		if( listOfBusinessObjects != null && listOfBusinessObjects.length > 0 )
		{
			angular.forEach(listOfBusinessObjects, function(businessObjectVO)
	    	{
				listOfBaseUuids.push(businessObjectVO.objectBaseUuid)
	    	});
		}
	
		return listOfBaseUuids;
	},
	
	getBaseUuidArrayJSONforSelectedContextMenu : function(objectOrArray)
	{
		var businessObjectToJSON = null;
	
		if(objectOrArray instanceof Array)
		{
			businessObjectToJSON = angular.toJson(StringUtils.getBaseUuidsFromBusinessObjects(objectOrArray));
		}
		else
		{
			businessObjectToJSON = angular.toJson([objectOrArray.objectBaseUuid]);
		}
	
		return businessObjectToJSON;
	},
	
	skipFileExtention : function(businessObject)
	{
		var str = "";
	
		if(businessObject)
		{
			var extType = "";
	
			var strTitile = businessObject.name;
	
			extType = "." + businessObject.extensionType;
	
			str = strTitile.replace(extType, "");
		}
	
		return str
	},
	
	getBrowser : function()
	{
			var browser = navigator.userAgent.toLowerCase();
			if ((navigator.vendor) && (navigator.vendor.toLowerCase().indexOf('apple') != -1) && (browser.indexOf('safari') != -1))
			{
				return 'Safari';
			}
			else if ((browser.indexOf('msie') != -1) || (browser.indexOf('trident') != -1))
			{
				return 'MSIE';
			}
			else if ((browser.indexOf('mozilla') != -1) || (browser.indexOf('firefox') != -1))
			{
				return 'Netscape Family';
			}
			else
			{
				return '?';
			}
	},
	
	getVersionBrowser : function()
	{
	    var ua= navigator.userAgent, tem,
	    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	    if(/trident/i.test(M[1]))
	    {
	        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
	        return 'IE '+(tem[1] || '');
	    }
	    if(M[1]=== 'Chrome')
	    {
	        tem= ua.match(/\bOPR\/(\d+)/);
	        if(tem!= null) return 'Opera '+tem[1];
	    }
	    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
	    if((tem= ua.match(/version\/(\d+)/i))!= null)
		{
	    	M.splice(1, 1, tem[1]);
		}
	    return M.join(' ');
	},
	
	isMediaObject : function(extension)
	{
		var supportedMediaExtensions = ['avi','wmv','asf','wma','wav','divx','xdiv','mov','qt','dv','aac','h.264','mpeg','mpg','vob','3gp','3g2','dvd','dv','mp3','rm','rmvb','ra','flv','m4v','mp4','webm'];
		if(supportedMediaExtensions.indexOf(extension) != -1)
		{
			return true;
		}
		return false;
	},
	
	replaceInName : function(name)
	{
		if(name != null)
		{
			var reg = new  RegExp("<|>");
			while (reg.test(name))
			{
				name = name.replace( /<|>/, "_");
			}
		}
		return name;
	},
	
	getTypeByObjectBaseUuid : function(objectBaseUuid)
	{
		var type = '';
		if(objectBaseUuid)
		{
			switch(0)
			{
				case (objectBaseUuid.indexOf('0CODF:')):
					type = "Folder";
					break;
			    case (objectBaseUuid.indexOf('0COAL:')):
			    	type = "Album";
			    	break;
			    case (objectBaseUuid.indexOf('0COMF:')):
			    	type = "MessageFolder";
			    	break;
			    case (objectBaseUuid.indexOf('0COXH:')):
			    	type = "DiscussionHolder";
			    break;
			    case (objectBaseUuid.indexOf('0DODD:')):
			    	type = "Document";
			    break;
			    case (objectBaseUuid.indexOf('0DODC:')):
			    	type = "Collate";
			    break;
			    case (objectBaseUuid.indexOf('0DODB:')):
			    	type = "BoardBook";
			    break;
			    case (objectBaseUuid.indexOf('0DOIM:')):
			    	type = "Image";
			    break;
			    case (objectBaseUuid.indexOf('0DOMM:')):
			    	type = "MultiMedia";
			    break;
			    case (objectBaseUuid.indexOf('0DODA:')):
			    	type = "Agenda";
			    break;
			    case (objectBaseUuid.indexOf('FlipBook:')):
			    	type = "FlipBook";
			    break;
			    case (objectBaseUuid.indexOf('0DODV:')):
			    	type = "DesktopShareVideo";
			    break;
			    case (objectBaseUuid.indexOf('0DOXP:')):
			    	type = "Topic";
			    break;
			    case (objectBaseUuid.indexOf('0DRXR:')):
			    	type = "TeamSpacePost";
			    break;
			    case (objectBaseUuid.indexOf('0DDXR:')):
			    	type = "DocumentPost";
			    break;
			    case (objectBaseUuid.indexOf('0DOXX:')):
			    	type = "ConferencePost";
			    break;
			    case (objectBaseUuid.indexOf('0D0XC:')):
			    	type = "CalendarPost";
			    break;
			    case (objectBaseUuid.indexOf('0DNXR:')):
			    	type = "AnnotationPost";
			    break;
			    case (objectBaseUuid.indexOf('0DAXR:')):
			    	type = "Annotation";
			    break;
			    case (objectBaseUuid.indexOf('0COZS:')):
			    	type = "Survey";
			    break;
			    case (objectBaseUuid.indexOf('0DOAA:')):
			    	type = "Approval";
			    break;
	
				default :
					type = "Document";
					break;
			}
		}
	
		return type;
	},
	
	getNumberAlphaCharacters : function(testString)
	{
		var legalChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
		var n = 0;
		if(testString)
		{
		 	for ( var i = 0; i < testString.length; i++ )
		    {
		    	if ( legalChars.indexOf( testString.charAt( i ) ) >= 0 )
		        {
		    		n++;
		        }
		    }
		}
	 	return n;
	},
	
	getNumberNonAlphaChars : function(testString)
	{
		var legalChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var n = 0;
		if(testString)
		{
		 	for ( var i = 0; i < testString.length; i++ )
		    {
		    	if ( legalChars.indexOf( testString.charAt( i ) ) < 0 )
		    	{
		    		n++;
		    	}
		    }
		}
	 	return n;
	},
	
	getNumberNumbers : function(testString)
	{
		var legalChars = "0123456789";
		var n = 0;
		if(testString)
		{
	     	for ( var i= 0; i < testString.length; i++ )
	        {
	        	if ( legalChars.indexOf( testString.charAt( i ) ) >= 0 )
	        	{
	        		n++;
	        	}
	        }
		}
	 	return n;
	},
	
	getIsUpperAndLower : function(testString)
	{
		if(testString)
		{
			var pattern =  /(.*[A-Z]+.*[a-z]+.*)|(.*[a-z]+.*[A-Z]+.*)/;
			return pattern.test(testString);
		}
		return false;
	},
	
	challengeQuestLengthValidation : function(answer, challengeQMinLength)
	{
		if(answer && challengeQMinLength)
		{
			return answer.length >= challengeQMinLength;
		}
		return false;
	},
	
	passwordValidation : function(passwordPreferences, newpwd)
	{
		var passwordMinLength = parseInt(passwordPreferences.passwordMinLength);
		var passwordMinAlphaCharacters = parseInt(passwordPreferences.passwordMinAlphaCharacters);
		var passwordMinNonAlphaCharacters = parseInt(passwordPreferences.passwordMinNonAlphaCharacters);
		var passwordMinNumbers = parseInt(passwordPreferences.passwordMinNumbers);
		var isUpperAndLowerEnabled = passwordPreferences.isUpperAndLowerEnabled;
	
		var valid = true;
		var isUpperAndLower = true;
		var numberOfAlphaCharacters = 0;
		var numberOfNonAlphaCharacters = 0;
		var noNumbers = 0;
		numberOfAlphaCharacters = StringUtils.getNumberAlphaCharacters(newpwd);
		noNumbers = StringUtils.getNumberNumbers(newpwd);
		numberOfNonAlphaCharacters = StringUtils.getNumberNonAlphaChars(newpwd);
	
		if(isUpperAndLowerEnabled)
		{
			isUpperAndLower = StringUtils.getIsUpperAndLower(newpwd);
		}
	
		if(newpwd.length < passwordMinLength)
		{
			valid = false;
		}
		if(numberOfAlphaCharacters < passwordMinAlphaCharacters)
		{
			valid = false;
		}
		if(noNumbers < passwordMinNumbers)
		{
			valid = false;
		}
		if(numberOfNonAlphaCharacters < passwordMinNonAlphaCharacters)
		{
			valid = false;
		}
		if(!isUpperAndLower){
			valid = false;
		}
		return valid;
	},
	
	filterBOForSelectedObjectBaseUuid : function(currentList, objectBaseUuid)
	{
		var listOfBusinessObject = [];
	
		for (var i = 0, len = currentList.length; i < len; i++)
		{
			var docVO = currentList[i];
	
			if(docVO.portalBaseUuid == objectBaseUuid)
			{
				if(docVO.signPendingUserMap)
				{
					var signPendingUserCount = Object.keys(docVO.signPendingUserMap).length;
					docVO.totalUsers = signPendingUserCount;
					docVO.progressBar = 0;
				}
	
				if(docVO.signCommitUserMap)
				{
					var signCommitUserCount = Object.keys(docVO.signCommitUserMap).length;
					if(signCommitUserCount > 0)
					{
						docVO.totalUsers = docVO.totalUsers + signCommitUserCount;
						var precentCommit = signCommitUserCount / docVO.totalUsers * 100;
						docVO.progressBar = precentCommit;
					}
				}
	
				listOfBusinessObject.push(docVO);
			}
		}
	
		return listOfBusinessObject;
	},
	
	passwordGlobalValidation : function(passwordPreferences, newpwd)
	{
		var valid = true;
		var passwordMinLength = parseInt(passwordPreferences.passwordSystemMinLength);
		if(newpwd.length < passwordMinLength)
		{
			valid = false;
		}
		return valid;
	},
	
	decodeBoolean : function(str)
	{
		return str.lastIndexOf("false") == -1 ? true : false;
	},

	translateDate : function (dateStringInEnglish, formatInEnglish) {
        let newMoment = moment(dateStringInEnglish, formatInEnglish, 'en');
        return newMoment.locale(moment.locale()).format('LL');
	},

	momentL : function(arg) {
		var loc = moment.locale();
		moment.locale('en-us');
		var n = new moment(arg);
		moment.locale(loc)
		return n;
	},
	
	getLocaleDateFormat : function(format){
		var regexD=/d+/i
		var regexM=/m+/i
		var regexY=/y+/i

	    var dateFormat;
	    if(format)
    		dateFormat = moment.localeData()._longDateFormat[format];
        else
    		dateFormat = moment.localeData()._longDateFormat.ll;

		dateFormat = dateFormat.replace(regexD, "dd");
		dateFormat = dateFormat.replace(regexM, "mm");
		dateFormat = dateFormat.replace(regexY, "yy");
	
		return dateFormat;
	},
	
	ieIframeFix : function(){
		var iframe = document.querySelector('iframe');
	
		return {
			enable: function(){
				if(iframe && StringUtils.getBrowser() == 'MSIE'){
					iframe.disabled = false;
				}
			},
			disable: function(){
				if(iframe && StringUtils.getBrowser() == 'MSIE'){
					iframe.disabled = true;
				}
			}
	
		}
	},
	
	capitalizeFirstLetter : function(string) {
	    return string.charAt(0).toUpperCase() + string.slice(1);
	},
	
	cssPath : function(el) {
	    if (!(el instanceof Element)) return;
	    var path = [];
	    while (el.nodeType === Node.ELEMENT_NODE) {
	        var selector = el.nodeName.toLowerCase();
	        if (el.id) {
	            selector += '#' + el.id;
	        } else {
	            var sib = el, nth = 1;
	            while (sib.nodeType === Node.ELEMENT_NODE && (sib = sib.previousSibling) && nth++);
	            selector += ":nth-child("+nth+")";
	        }
	        path.unshift(selector);
	        el = el.parentNode;
	    }
	    return path.join(" > ");
	},
	
	hashCode : function(string){
		var hash = 0, i, chr, len;
		if (string.length === 0) return hash;
		for (var i = 0, len = string.length; i < len; i++) {
			chr   = string.charCodeAt(i);
			hash  = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	},
	
	findDeep : function(obj, predicate){
		var res = [];
		
		function recursion(obj){
		   
		   _.forEach(obj, function(item){
		      if(typeof item == "object" && (item.length > 0 || item.length == undefined)){
		    	 if(item.length){
		    		 res = _.concat(res, _.filter(item, predicate));
		    	 }
		    	 else{
		    		 res = _.concat(res, _.filter([item], predicate));
		    	 }
		    	 
		    	 recursion(item);
		      }
		   });
		  
		}
		
		recursion(obj);
		
		return res;
	},

	resizeImage : function(url, prop, width, defer){
        /// set size proportional to image
        var proportion = prop || 0.5;

        var img = document.createElement('img');
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");

        img.onload = function(){
            canvas.width = width || img.width * proportion;
            canvas.height = canvas.width * (img.height / img.width);

            if(prop >= 1){
                ctx.drawImage(img, 0, 0, img.width * proportion, img.height * proportion,
                0, 0, canvas.width, canvas.height);
            }
            else{
                /// step 1 - resize to 50%
                var oc = document.createElement('canvas'),
                octx = oc.getContext('2d');

                oc.width = img.width * proportion;
                oc.height = img.height * proportion;
                octx.drawImage(img, 0, 0, oc.width, oc.height);

                /// step 2 - resize 50% of step 1
                octx.drawImage(oc, 0, 0, oc.width * proportion, oc.height * proportion);

                /// step 3, resize to final size

                ctx.drawImage(img, 0, 0, img.width * proportion, img.height * proportion,
                0, 0, canvas.width, canvas.height);
            }

            if(defer)
                defer.resolve(canvas.toDataURL());
        }

        img.src = url;
	},

	cssChange : function(selector,rules,contxt)
    {
        var context=contxt||document,stylesheet;

        if(typeof context.styleSheets=='object')
        {
          if(context.styleSheets.length)
          {
            stylesheet=context.styleSheets[context.styleSheets.length-1];
          }
          if(context.styleSheets.length)
          {
            if(context.createStyleSheet)
            {
              stylesheet=context.createStyleSheet();
            }
            else
            {
              context.getElementsByTagName('head')[0].appendChild(context.createElement('style'));
              stylesheet=context.styleSheets[context.styleSheets.length-1];
            }
          }
          if(stylesheet.addRule)
          {
            for(var i=0;i<selector.length;++i)
            {
              stylesheet.addRule(selector[i],rules);
            }
          }
          else
          {
             for(var i=0;i<selector.length;++i)
            {
              stylesheet.insertRule(selector[i] + ' { ' + rules + ' }', stylesheet.cssRules.length);
            }
          }
        }
    },

    fileSizeConverter : function(bytes, precision){
    	var decimalConv = 1000;
    	var binaryConv = 1024;
    		if(bytes == 0) return 0;
    		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
    		if (typeof precision === 'undefined') precision = 1;
    		var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'],
    			number = Math.floor(Math.log(bytes) / Math.log(binaryConv));
    		return (bytes / Math.pow(binaryConv, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
    },

    sorting : function(data, key, direction){
        var sort = function(a,b){
            var r,p;

            if(key === 'lastModifiedOnGMTMillis'){
                r = new Date(a[key]).getTime();
                p = new Date(b[key]).getTime();
            }
            else{
                r = a[key].toLowerCase();
                p = b[key].toLowerCase();
            }

            if(p>r){return -1;}
            else if(r>p){return 1;}
                return 0;
        }
        if(direction){
            return data.sort(sort);
        }
        else{
            return data.sort(sort).reverse();
        }
    },

    sortObjectsByKey : function(data, key){
        var sort = (a,b) => {
            if(b[key] > a[key])
              return -1
            else if(a[key] > b[key])
              return 1
            else
              return 0
        }
        return data.sort(sort);
    },

    isEnterKey : function(e){
        if(!e)
            return null;

        if(e.keyCode == 13)
            return true;
        else
            return false;
    },

    isRightCharacter : function(e){
        switch (e.keyCode) {
            case 9: return false; // Tab
            case 12: return false; // Clear
            case 13: return false; // Enter
            case 16: return false; // Shift
            case 17: return false; // Ctrl
            case 18: return false; // Alt
            case 19: return false; // Pause/Break
            case 20: return false; // Caps Lock
            case 27: return false; // Escape
            case 33: return false; // PageUp
            case 34: return false; // PageDown
            case 35: return false; // End
            case 36: return false; // Home
            case 37: return false; // Left
            case 38: return false; // Up
            case 39: return false; // Right
            case 40: return false; // Down
            case 40: return false; // Down

            // Mac CMD Key
            case 91: return false; // Safari, Chrome
            case 93: return false; // Safari, Chrome
            case 224: return false; // Firefox
            break;
            default:
              return true;
            break;
        }
    },

    getOSName : function(){
        if(navigator.platform.toUpperCase().indexOf('WIN')>=0)
        var OSName="Unknown OS";
        if(navigator.platform.toUpperCase().indexOf("WIN")!=-1) OSName="Windows";
        if(navigator.platform.toUpperCase().indexOf("MAC")!=-1) OSName="MacOS";
        if(navigator.platform.toUpperCase().indexOf("X11")!=-1) OSName="UNIX";
        if(navigator.platform.toUpperCase().indexOf("LINUX")!=-1) OSName="Linux";
        return OSName;
    },

    getRandomValue: function(){
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }

        return s4() + s4();
    },

    isContentOwner: function(document) {
      if(!document)
        return null

      const inputPermissions = ['CREATE','DELETE','READ','EDIT','GRANT','SAVE'];
      const filePermissions = document.permissions;

      if(filePermissions != null && filePermissions != undefined && filePermissions.length > 0){
        for(var i=0; i<inputPermissions.length; i++){
          var perm = inputPermissions[i];
          if($.inArray(perm,filePermissions) === -1){
            return false;
          }
        }
      }
      else{
        return false;
      }

      return true;
    }
}