reminderIconRenderer.$inject = ['imageService']

function reminderIconRenderer(imageService){
    return {
        restrict: 'A',
            scope: {
    			iconForReminder: '=',
    	    },
        template: '<div ng-include="src" style="width: 78px;margin-left: 10px;" class="bv-surveys-image"></div>',
    	link: function (scope, el, attrs) {
		    scope.$watch('iconForReminder', function(iconForReminder){
			    var extensionType = scope.iconForReminder.extensionType
                scope.src = imageService.getImageByExtension(extensionType);
                return;
		    });
        }
    };
}