advancedSearchController.$inject = ['$scope', 'adSearchService', 'localizedMessages', 'advancedSearchService', '$state', 'adSearchQueryBoxService'];

function advancedSearchController($scope, adSearchService, localizedMessages, advancedSearchService, $state, adSearchQueryBoxService){
    $scope.searchObject = adSearchService.getInstance('ADVANCED');
    $scope.searchObject.advanced = true;
    $scope.searchObject.filterPanel.enable = true;

    if(adSearchQueryBoxService.searchString.val == '' && $scope.searchObject.searchString != '')
        adSearchQueryBoxService.searchString.val = $scope.searchObject.searchString;

    var previousState = $scope.$parent.currentState.value;
    $scope.$parent.currentState.value = $state.$current.name;

    $scope.tabs = [
        { title: localizedMessages.get('ADVANCED_SEARCH'),   content:'', active: true }
    ];

    $scope.title = {
        exit: localizedMessages.get('EXIT'),
        search: localizedMessages.get('SEARCH')
    }

    $scope.middleBarObject = advancedSearchService.middleBarObject;
    $scope.middleBarObject.showBack = true;

    $scope.handleLeftIcon = function(){
        adSearchQueryBoxService.searchString.val = '';
        $state.go(previousState);
    }
}