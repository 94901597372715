touchStart.$inject = ['$timeout'];

function touchStart($timeout){
	return {
		restrict: 'A',
		scope: {
			tStart : '&touchStart',
			tEnd : '&touchEnd'
		},
		link: function($scope, $element){

		    $element.bind('touchstart', function(){
		        if($scope.tStart && typeof $scope.tStart == 'function'){
                    $scope.tStart();
                }
		    });

            $element.bind('touchend', function(){
                if($scope.tEnd && typeof $scope.tEnd == 'function'){
                    $scope.tEnd();
                }
            });

		}
	}
}