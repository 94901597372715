function createAgendaTemplateConfig($stateProvider, $locationProvider){
	
}
createAgendaTemplateController.$inject = ['$scope', '$state', 'homeService','reactInterface', 'bvDialog', '$stateParams', 'localizedMessages', 'repositoryService', 'commonService','legacyUserService','context'];

function createAgendaTemplateController($scope, $state, homeService, reactInterface, bvDialog, $stateParams, localizedMessages, repositoryService, commonService, legacyUserService, context){
	
    $scope.reactInterface = reactInterface;
	$scope.operation = $stateParams.operation;
	$scope.templateName = $stateParams.templateName;
	$scope.templateID = $stateParams.templateID;
    $scope.middleBarObject.showBack = true;
    var templateName = $scope.operation === "edit" ? $scope.templateName : localizedMessages.get("CREATE_AGENDA_TEMPLATE");

    $scope.checkEntitlement = function() {
      debugger;
      let canCurrentUserCreateModule = false;
      commonService.checkModuleCreatorsConfigPresentJSON("MEETINGS").then(function(data){
        if(data && data.data && data.data.name == "isModuleCreatorsPrefAllowed"){
          config = data.data.value;
          if(config == "true" || config == true){
            commonService.getModuleCreatorsForSpecificModuleJSON("MEETINGS").then(function(mod){
              if(mod.data){
                var moduleCreators = angular.fromJson(mod.data);
                if(moduleCreators.users != undefined && moduleCreators.users.length>0){
                  for(var i=0;i<moduleCreators.users.length;i++){
                    if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
                      canCurrentUserCreateModule = true;
                      break;
                    }
                  }
                  canCurrentUserCreateModule = canCurrentUserCreateModule && (legacyUserService.isNasdaqUI && context.getModel().companyVO.enableAgendaBuilder);
                  !canCurrentUserCreateModule ? $state.go('home.meetings') :'';              
            
                } else $state.go('home.meetings');
              } else {
                $state.go('home.meetings');
              }
            });
          }
          else{
            canCurrentUserCreateModule = (legacyUserService.isNasdaqUI && context.getModel().companyVO.enableAgendaBuilder);
            !canCurrentUserCreateModule ? $state.go('home.meetings') : '';
          }
        } else {
          $state.go('home.meetings');
        }
      });

    }
    
    
  $scope.middleBarObject.title = localizedMessages.get("MEETINGS") + ' | ' + templateName;
	$scope.tabs = [{
        title : localizedMessages.get("MANAGE_TEMPLATES"),
        content : 'manageTemplates',
        active: true,
        rightMenuList:[]
    }];
    $scope.handleLeftIcon = function(){
        if(homeService.get_current_module_has_unsaved_changes()){
            event.preventDefault();
            bvDialog.confirm(localizedMessages.get('MSG_EXIT_VEDIT'), function(){
                reactInterface.set_meeting_module_has_unsaved_changes(false, 'createAgendaTemplateController');
                $state.go('home.manageTemplates');
              }); 
        }
        else {
            $state.go('home.manageTemplates');
        }
       		
	}

  $scope.init = function(){
    $scope.checkEntitlement();
  }
	$scope.init();

}

