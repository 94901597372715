templatesService.$inject = ['$templateCache', 'legacyUserService'];

function templatesService($templateCache, legacyUserService){
	var service = {
		setAllTemplates: function(){
			$templateCache.put("template/datepicker/day.html",
				    '<div class="bv-height-100 bv-calendar" calendar-load-data rows-var="rows">\n' +
				    '	<div class="bv-calendar-title">{{title}}</div>' +
					'	<div ng-show="showWeeks" class="text-center showWeeks">\n' +
				    '		<div ng-repeat="label in labels track by $index" class="text-center">' +
					'			<small aria-label="{{label.full}}">{{label.abbr}}</small>' +
				    '		</div>' +
				    '	</div>\n' +
//				    '	<div class="bv-height-100 bv-width-100">\n' +
				    '   	<div class="row" ng-repeat="row in rows track by $index">\n' +
				    '      		<div class="bv-calendar-cell" ng-repeat="dt in row track by dt.date" class="text-center" role="gridcell" id="{{dt.uid}}" ng-class="{\'current\': dt.current,\'hover\': events.list[dt.dateIndex].length > 0}" ng-click="clickEvent(dt,null,events.list[dt.dateIndex])">\n' +
//				    '				<div class="bv-height-100 bv-width-100 bv-day" ng-class="{\'current\': dt.current,\'hover\': events.list[dt.dateIndex].length > 0}" ng-disabled="dt.disabled" tabindex="-1">' +
//				    '					<div class="bv-height-100 bv-width-100" slided-element="bv-calendar-module" showing-element=".showTest" ng-click="clickEvent(dt,null,events.list[dt.dateIndex])">' +
//					'						<div ng-class="{\'text-muted\': dt.secondary}">' +
					'							<div class="bv-calendar-day-number"><span ng-class="dt.current ? \'currentUnderline\' : \'noUnderline\'">{{ dt.label }}<span></div>' +
					'							<event class="event bv-scrollbar">' +
					'								<div ng-repeat="event in events.list[dt.dateIndex]">' +
					'									<div class="bv-transition-opacity" ng-init="init()"></div>' +
					'									<span class="time bv-transition-opacity">{{ event.startDate }}</span>' +
					'									<span ng-click="clickEvent(dt,event,events.list[dt.dateIndex])">{{ event.name }}</span>' +
					'								</div>\n' +
					'							</event>\n' +
//					'						</div>\n' +
//					'					</div>' +
//				    '				</div>' +
				    '      		</div>\n' +
				    '   	</div>\n' +
//				    '	</div>\n' +
				    '</div>\n');

			 $templateCache.put("template/tabs/tab.html",
					    "<li ng-class=\"{active: active, disabled: disabled}\">\n" +
					    "  <bt href ng-click=\"select()\" tab-heading-transclude>{{heading}}</bt>\n" +
					    "</li>\n");
		},
		getUrl: function(name){
		    var templateUrl;

			switch(name)
        	{
    			case 'Permissions':
    				templateUrl = 'modules/permissions/Permissions.tpl.html';
    				break;
    			case 'Branding':
    				templateUrl = 'directives/branding/branding.template.html';
    				break;
    			case 'CreateApproval':
    				templateUrl = 'modules/home/CreateApproval.tpl.html';
    				break;
    			case 'ComposeMessage':
    				templateUrl = 'modules/messages/ComposeMessage.tpl.html';
    				break;
    			case 'ForwardMessage':
    				templateUrl = 'modules/messages/ForwardMessage.tpl.html';
    				break;
    			case 'ComposeMessageMobile':
    				templateUrl = 'modules/messages/ComposeMessageMobile.tpl.html';
    				break;
    			case 'OpenMessageMobile':
    				templateUrl = 'modules/messages/OpenMessageMobile.tpl.html';
    				break;
    			case 'OpenMessageMobileCustomButtons':
    				templateUrl = 'modules/messages/OpenMessageCustomButtons.tpl.html';
    				break;
                case 'closeBtn':
                    if(!legacyUserService.isNasdaqUI)
                        templateUrl = 'resources/assets/svgs/closeDialog_old.svg';
                    else
                        templateUrl = 'resources/assets/svgs/closeDialog.svg';
                    break;
				case 'SendMeetingNotificationDialog':
					templateUrl = 'modules/calendar/SendNotificationDialog.tpl.html';
    				break;
        	}
			return templateUrl;
		},
		setCustomDialogTemplate: function(){
			$templateCache.put("template/modal/window.html",
				    "<div modal-render=\"{{$isRendered}}\" tabindex=\"-1\" role=\"dialog\" class=\"modal\"\n" +
				    "    modal-animation-class=\"fade\"\n" +
				    "    modal-in-class=\"in\"\n" +
				    "	ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\">\n" +
				    "    <div class=\"modal-dialog\" ng-class=\"size ? 'modal-' + size : ''\"><div class=\"modal-content\" modal-transclude></div></div>\n" +
				    "</div>\n" +
				    "");
		},
		setBootstrapDialogTemplate: function(){
			 $templateCache.put("template/modal/window.html",
					    "<div modal-render=\"{{$isRendered}}\" tabindex=\"-1\" role=\"dialog\" class=\"modal\"\n" +
					    "    modal-animation-class=\"fade\"\n" +
					    "    modal-in-class=\"in\"\n" +
					    "	ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\" ng-click=\"close($event)\">\n" +
					    "    <div class=\"modal-dialog\" ng-class=\"size ? 'modal-' + size : ''\"><div class=\"modal-content\" modal-transclude></div></div>\n" +
					    "</div>\n" +
					    "");
		}

	}
	return service;
}