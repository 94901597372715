loginFooter.$inject = ['loginService', 'localizedMessages', 'bvDialogService', 'context', 'constants']

function loginFooter(loginService, localizedMessages, bvDialogService, context, constants){
    return {
        restrict: 'A',
        templateUrl: 'modules/login/htmlStructure/loginFooter.template.html',
        link: function(scope, element, attrs) {
            scope.ui = { label : {
                ver : localizedMessages.get('VER'),
                support1_PART1 : localizedMessages.get('CLICK_HERE_TO_VIEW_THE_BOARDVANTAGE_USAGE_POLICY'),
                support2 : localizedMessages.get('USAGE_POLICY'),
                support3 : localizedMessages.get('CONTACT_US')
                }
            };
            scope.clientrevision = constants.get('CLIENT_REVISION');
            scope.showUsagePolicy = function ()
            {
                var alertMsg = localizedMessages.get('ERR_ACCESS_RESTRICTED_TO_USERS') + "\n" + localizedMessages.get("ERR_UNAUTHORIZED_TO_PROSECUTION");
                bvDialogService.showBvAlertMessage(alertMsg);
            }

            loginService.getConfigValueForSupportInfo().then(function(data)
            {
                if(data)
                {
                    scope.browserReleasedVersion = data.BROWSER_RELEASED_VERSION;
                    scope.supportContactUrl = data.SUPPORT_CONTACT_URL;
                    loginService.setEnhancedAuthenticationEnabled(data.SHOW_REGISTER_DEVICE);
                }
            });

            scope.loginLogo = scope.$parent.loginLogo;
            scope.isLoginStaging = loginService.isLoginStaging;
	    	scope.$watch(function(){return loginService.isLoginStaging;}, function(value) {
	            scope.isLoginStaging = value;
	        });
        }
    }
}