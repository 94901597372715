DocumentContextMenu.$inject = ['permissionService','permissionsService','localizedMessages', 'copyPasteService','contextMenuSetting','context', 'homeModel','homeContext','repositoryModelService', 'repositoryService', 'adSearchService'];

function DocumentContextMenu(permissionService, permissionsService, localizedMessages, CopyPasteService, contextMenuSetting, context, homeModel, homeContext, repositoryModelService, repositoryService, adSearchService)
{

    var contextMenuOption = {};
    var documentContextMenu = [];
    //Change for MEETX-29963
    var contextMenuApprovalStatus=false;

    contextMenuOption['open']           = { title:localizedMessages.get('OPEN'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'open', group:1};
    contextMenuOption['viewas']           = { title:localizedMessages.get('VIEWAS'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'viewas', group:1};
    contextMenuOption['pdf']            = { title:localizedMessages.get('PDF'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'pdf', group:1};
    contextMenuOption['agenda']         = { title:localizedMessages.get('AGENDA'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'agenda', group:1};
    contextMenuOption['rename']         = { title:localizedMessages.get('RENAME'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'rename', group:1};
    contextMenuOption['auditTrail']     = { title:localizedMessages.get('AUDIT_TRAIL'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'auditTrail', group:1};
    contextMenuOption['present']        = { title:localizedMessages.get('PRESENT'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'present', group:1};
    contextMenuOption['editcollate']    = { title:localizedMessages.get('EDIT_COLLATE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'editcollate', group:1};
    contextMenuOption['permission']     = { title:localizedMessages.get('PERMISSIONS'), iconUrl: 'resources/assets/svgs/RightMenuOptions.svg', svgFill: '#fff', type:'permission', group:1};

    contextMenuOption['edit']           = { title:localizedMessages.get('EDIT'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'edit', group:2};
    contextMenuOption['agendaBuilder']  = { title:localizedMessages.get('AGENDA_BUILDER'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'edit', group:2};
    contextMenuOption['boardbook']      = { title:localizedMessages.get('BOARDBOOK'), iconUrl: 'resources/assets/svgs/RightMenuOptions.svg', svgFill: '#fff', type:'boardbook', group:2};
    contextMenuOption['collate']        = { title:localizedMessages.get('COLLATE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'collate', group:2};
    contextMenuOption['editboardbook']  = { title:localizedMessages.get('EDIT_BOARDBOOK'), iconUrl: 'resources/assets/svgs/RightMenuOptions.svg', svgFill: '#fff', type:'editboardbook', group:2};
    contextMenuOption['checkin']        = { title:localizedMessages.get('CHECK_IN'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'checkin', group:2};
    contextMenuOption['checkout']       = { title:localizedMessages.get('CHECK_OUT'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'checkout', group:2};
    contextMenuOption['cancelcheckout'] = { title:localizedMessages.get('CANCEL_CHECK_OUT'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'cancelcheckout', group:2};
    contextMenuOption['share']          = { title:localizedMessages.get('NOTIFY'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff',type:'share', group:1};
    contextMenuOption['push']           = { title:localizedMessages.get('PUSH'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff',type:'push', group:1};
    contextMenuOption['hidefolder']     = { title:localizedMessages.get('HIDEFOLDER'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'hidefolder', group:1};
    contextMenuOption['unhidefolder']   = { title:localizedMessages.get('UNHIDEFOLDER'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'unhidefolder', group:1};
    contextMenuOption['versionTracker'] = { title:localizedMessages.get('VERSION_TRACKER'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'versionTracker', group:1};
    contextMenuOption['replace']        = { title:localizedMessages.get('REPLACE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'replace', group:1};
    contextMenuOption['send']           = { title:localizedMessages.get('SEND'),  iconUrl: '', svgFill: '#fff',type:'send', group:1};
    //	contextMenuOption['secureMessage']  = { title:localizedMessages.get('SECURE_MESSAGE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff',type:'secureMessage'};

    contextMenuOption['move']           = { title:localizedMessages.get('MOVE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'move', group:3};
    contextMenuOption['download']       = { title:localizedMessages.get('DOWNLOAD'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'download', group:3};
    contextMenuOption['delete']         = { title:localizedMessages.get('DELETE'), iconUrl: 'resources/assets/svgs/RightMenuOptions.svg', svgFill: '#fff', type:'delete', group:3};
   // contextMenuOption['makePdfCompliant'] = { title:localizedMessages.get('MAKE_PDF_COMPLIANT'), iconUrl: 'resources/assets/svgs/RightMenuOptions.svg', svgFill: '#fff', type:'makePdfCompliant', group:3};
    contextMenuOption['copy']           = { title:localizedMessages.get('COPY'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'copy', group:3};
    contextMenuOption['cut']            = { title:localizedMessages.get('CUT'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'cut', group:3};
    contextMenuOption['paste']          = { title:localizedMessages.get('PASTE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'paste', group:3};
    //HTML-1211	//contextMenuOption['createShortCut']     = { title:localizedMessages.get('CREATESHORTCUT'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'createShortCut'};
    contextMenuOption['copylink']       = { title:localizedMessages.get('COPYLINK'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'copylink', group:3};
    //	contextMenuOption['setFollowers']     = { title:localizedMessages.get('SETFOLLOWERS'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'setFollowers'};
    //	contextMenuOption['follow']     = { title:localizedMessages.get('FOLLOW'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'follow'};
    //	contextMenuOption['stopFollow']     = { title:localizedMessages.get('STOP_FOLLOW'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'stopFollow'};

    contextMenuOption['removeFromBriefCase']        = { title:localizedMessages.get('REMOVEFROMMYBRIEFCASE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'removeFromBriefCase', group:4};
    contextMenuOption['removeFromAllBriefCases']    = { title:localizedMessages.get('REMOVEFROMALLBRIEFCASES'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'removeFromAllBriefCases', group:4};
    contextMenuOption['removeFromNewItems']         = { title:localizedMessages.get('REMOVEFROMNEWITEMS'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'removeFromNewItems', group:4};
    contextMenuOption['deleteAllAnnotations']       = { title:localizedMessages.get('DELETE_ALL_ANNOTATIONS'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'deleteAllAnnotations', group:4};
    contextMenuOption['createAgendaForFolder']      = { title:localizedMessages.get("CREATE_AGENDA"),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'createAgendaForFolder', group:4};
    contextMenuOption['makeAgendaTemplateDefault']  = { title: localizedMessages.get("MAKE_DEFAULT"), iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'makeAgendaTemplateDefault', group:4};
    contextMenuOption['addToFavorites']             = { title:localizedMessages.get('ADD_TO_MY_BRIEFCASE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'addToFavorites', group:4};
    contextMenuOption['addToUserFavorites']         = { title:localizedMessages.get('ADD_TO_USER_BRIEFCASE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'addToUserFavorites', group:4};
    contextMenuOption['addToNewItems']              = { title:localizedMessages.get('ADD_TO_NEW_ITEMS'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'addToNewItems', group:4};

 	contextMenuOption['setFollowers']               = { title:localizedMessages.get('SETFOLLOWERS'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'setFollowers', group:5};
    contextMenuOption['follow']                     = { title:localizedMessages.get('FOLLOW'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'follow', group:5};
    contextMenuOption['stopFollow']                 = { title:localizedMessages.get('STOP_FOLLOW'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'stopFollow', group:5};
    contextMenuOption['properties']                 = { title:localizedMessages.get('PROPERTIES'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'properties', group:5};
    contextMenuOption['rules']                      = { title:localizedMessages.get('RULES'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'rules', group:5};
    contextMenuOption['openFolderInDocumentModule'] = { title:localizedMessages.get('OPEN_FOLDER_IN_DOCUMENT_MODULE'),  iconUrl: 'resources/assets/svgs/RightMenuUploadFiles.svg', svgFill: '#fff', type:'openFolderInDocumentModule', group:5};
    var checkAndAddEdit = function(documentContextMenu, businessObject, isFolder)
    {
        if(isFolder || MobileUtil.isSurface())
        return;

        //if(businessObject.isConvertableFmt && businessObject.extensionType != "pdf" && !StringUtils.isImageVO(businessObject.objectBaseUuid) && !StringUtils.isMultiMediaVO(businessObject.objectBaseUuid) && !businessObject.locked)
        if(businessObject.isConvertableFmt
            && !StringUtils.isImageVO(businessObject.objectBaseUuid)
            && !StringUtils.isMultiMediaVO(businessObject.objectBaseUuid)
            && (businessObject.conversionStatus && businessObject.conversionStatus!="CONVERTING"))
        {
            var editType = context.getModel().editNative;
            if(editType && editType === "virtualEdit")
            {
                if(businessObject.extensionType == "doc" || businessObject.extensionType == "docx"
                    || businessObject.extensionType == "ppt" || businessObject.extensionType == "pptx"
                    || businessObject.extensionType == "xls" || businessObject.extensionType == "xlsx")
                {
                    addEditOption(true, documentContextMenu, businessObject);
                }
            }
            else
            {
                addEditOption(false, documentContextMenu, businessObject);
            }
        }
        /*if((businessObject.extensionType === "doc" || businessObject.extensionType === "docx") && businessObject.isConvertableFmt && !businessObject.locked)
        {
            documentContextMenu.push(contextMenuOption['agenda']);
        }*/
    }
    var addEditOption = function(isVirtualEdit, documentContextMenu, businessObject)
    {
        if(businessObject.locked)
        {
            if(businessObject.lockCreatedByBaseUuid && businessObject.lockCreatedByBaseUuid === context.getModel().userVO.objectBaseUuid)
            {
                if(isVirtualEdit)
                {
                    documentContextMenu.push(contextMenuOption['agendaBuilder']);
                }
                else
                {
                    //Change For MEETX-29963
                    if(!contextMenuApprovalStatus)
                    {
                            documentContextMenu.push(contextMenuOption['edit']);    
                    }
                   
                }
            }
        }
        else if(businessObject.extensionType == "pdf" )
        {
            if(!StringUtils.isBoardbook(businessObject.objectBaseUuid) && !StringUtils.isCollate(businessObject.objectBaseUuid))
            {
                if(isVirtualEdit)
                {
                    documentContextMenu.push(contextMenuOption['agendaBuilder']);
                }
            }
        }
        else
        {
            if(isVirtualEdit)
            {
                documentContextMenu.push(contextMenuOption['agendaBuilder']);
            }
            else
            {
                //Change For MEETX-29963
                if(!contextMenuApprovalStatus)
                {
                        documentContextMenu.push(contextMenuOption['edit']);    
                }
            }
        }
    }

    var addReplace = function(documentContextMenu, businessObject)
    {
        if(!StringUtils.isCollate(businessObject.objectBaseUuid) && !StringUtils.isBoardbook(businessObject.objectBaseUuid) && !MobileUtil.isSurface() && !MobileUtil.isDesktopClient())
        {
            documentContextMenu.push(contextMenuOption['replace']);
        }
    }

    var removeItem = function(type)
    {
        for (var i = 0, len = documentContextMenu.length; i < len; i++) {
            if (documentContextMenu[i].type === type)
            {
                documentContextMenu.splice(i, 1);
                break;
            }
        }
    }
    
    var removeItemFromContextMenu = function(documentContextMenu,type)
    {
        for (var i = 0, len = documentContextMenu.length; i < len; i++) {
            if (documentContextMenu[i].type === type)
            {
                documentContextMenu.splice(i, 1);
                break;
            }
        }
    }

    /**
    * This method is for removeing the option if the root node of the tree is selected.
    */
    var removeParentRootOptions = function()
    {
        removeItem('share');
        removeItem('download');
        removeItem('delete');
        removeItem('move');
        removeItem('collate');
        //HTML-1211	//removeItem('createShortCut');
        removeItem('addToFavorites');
        removeItem('addToMultipleBriefcase');
        removeItem('removeFromAllBriefcases');
        removeItem('addToNewItems');
        removeItem('deleteNewItem')
        removeItem('addToNews');
        removeItem('addToEstimates');
        removeItem('addToVideos');
        removeItem('shortcutdelete');
        removeItem('editcollate');
        removeItem('editboardbook');
        removeItem('cut');
        removeItem('hidefolder');
    }

    var checkForNewItem = function(documentContextMenu, businessObject)
    {
        var newItemModel = repositoryModelService.getInstance("NEW_ITEM");

        for (var m in newItemModel.repositoryList().browseList)
        {
            var newItemDoc = newItemModel.repositoryList().browseList[m];
            if(newItemDoc.objectBaseUuid == businessObject.objectBaseUuid)
            {            	            	
	                if(!(MobileUtil.isSurface()||MobileUtil.isAndroid()))
	                {
	                    documentContextMenu.push(contextMenuOption['removeFromNewItems']);
	                }
	                return;            	   
            }
        }
        if(!MobileUtil.isSurface())
        {
            documentContextMenu.push(contextMenuOption['addToNewItems']);
        }
    }

    var checkForFavorites = function(documentContextMenu, businessObject)
    {
    	var favoritesmodel = repositoryModelService.getInstance("FAVORITES");
    	for (var m in favoritesmodel.repositoryList().browseList)
        {
            var favoritesDoc = favoritesmodel.repositoryList().browseList[m];
            if(favoritesDoc.objectBaseUuid == businessObject.objectBaseUuid)
            {
                if(!StringUtils.isFolder(businessObject.objectBaseUuid))
                {
                    documentContextMenu.push(contextMenuOption['removeFromBriefCase']);
                }
                return;
            }
        }
        if(!StringUtils.isFolder(businessObject.objectBaseUuid))
        {
            documentContextMenu.push(contextMenuOption['addToFavorites']);
        }
    }
    var addOwnerOptions = function(documentContextMenu, businessObject, parentContainer, folderObject)
    {
        if(parentContainer != "VIEWER" && (StringUtils.isBoardbook(businessObject.objectBaseUuid) || StringUtils.isCollate(businessObject.objectBaseUuid)) && !MobileUtil.isSurface())
        {
            documentContextMenu.push(contextMenuOption['viewas']);
        }

        var editType = context.getModel().editNative;

        addShareOption(documentContextMenu);

        if((permissionService.isOwner(businessObject) || permissionService.isShareAllowed(businessObject)) && context.getModel().pushEnabled)
        {
        	var isProfile = context.getModel().portalVO.objectBaseUuid.slice(0, '0PRFL'.length) == '0PRFL';
			  	if(businessObject.isDynamicBoardBook && !isProfile)
			  	{
			  		documentContextMenu.push(contextMenuOption['push']);
			  	}
        }

        if(folderObject)
        {
            if(!MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['rename']);
                documentContextMenu.push(contextMenuOption['permission']);
            }

            if(!(MobileUtil.isSurface()||MobileUtil.isAndroid()))
            {
                if(businessObject.isHidden == "true")
                documentContextMenu.push(contextMenuOption['unhidefolder']);
                else
                documentContextMenu.push(contextMenuOption['hidefolder']);
            }
            addCollateOptions(documentContextMenu, businessObject);

            if(!MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['cut']);
                documentContextMenu.push(contextMenuOption['delete']);
                if(context.getModel().showUploadFolder)
                {
                    documentContextMenu.push(contextMenuOption['download']);
                }
                documentContextMenu.push(contextMenuOption['move']);
                documentContextMenu.push(contextMenuOption['copylink']);
                documentContextMenu.push(contextMenuOption['rules']);
            }
            //HTML-1211	// documentContextMenu.push(contextMenuOption['createShortCut']);
            if(context.getModel().folderAgendaBuilderEnabled)
                documentContextMenu.push(contextMenuOption['createAgendaForFolder']);

        }
        else
        {
            if(parentContainer != "VIEWER" && !MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['rename']);
            }
            //Change For MEETX-29963
            if(parentContainer != "FAVORITES" && !contextMenuApprovalStatus)
            {
                addReplace(documentContextMenu, businessObject);
            }
            if(!MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['pdf']);
                if(context.getModel().auditEnabled)
                {
                	documentContextMenu.push(contextMenuOption['auditTrail']);
                }
                documentContextMenu.push(contextMenuOption['versionTracker']);
                documentContextMenu.push(contextMenuOption['permission']);
            }

            if(editType && editType != "virtualEdit")
            {
                checkAndAddEdit(documentContextMenu, businessObject, folderObject);
            }

            if(parentContainer != "VIEWER" && parentContainer != "FAVORITES")
            {
                if(editType && editType === "virtualEdit")
                {
                    checkAndAddEdit(documentContextMenu, businessObject, folderObject);
                }
                addBoardbookOptions(documentContextMenu , businessObject);
                addCollateOptions(documentContextMenu, businessObject);
            }

            if(parentContainer != "FAVORITES"  && !MobileUtil.isSurface())
            {
                if(parentContainer != "VIEWER")
                {
                    documentContextMenu.push(contextMenuOption['cut']);
                }
                documentContextMenu.push(contextMenuOption['copy']);
            }

            if(parentContainer != "VIEWER" && parentContainer != "FAVORITES"  && !MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['delete']);
            }

            if(parentContainer != "VIEWER" && parentContainer != "FAVORITES"  && !MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['move']);
            }
            if(!MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['download']);
                documentContextMenu.push(contextMenuOption['copylink']);
            }

            /*if(parentContainer != "FAVORITES")
            {
                documentContextMenu.push(contextMenuOption['addToFavorites']);
            }*/
            if(!folderObject && context.getModel().browserBriefcaseForUserEnabled && parentContainer != "FAVORITES" && !MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['addToUserFavorites']);
            }

            checkForNewItem(documentContextMenu, businessObject);
            checkForFavorites(documentContextMenu, businessObject);

            if(!folderObject && context.getModel().browserBriefcaseForUserEnabled && parentContainer != "FAVORITES" && !MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['removeFromAllBriefCases']);
            }

            if(!StringUtils.isMultiMediaVO(businessObject.objectBaseUuid))
            {
                documentContextMenu.push(contextMenuOption['deleteAllAnnotations']);
            }

            //			  if(parentContainer != "FAVORITES")
            //			  {
            //			  	//documentContextMenu.push(contextMenuOption['addToNewItems']);
            //    		  }

            //COMMENTED FOR PRESENTER. DO NOT REMOVE
            //documentContextMenu.push(contextMenuOption['present']);

            //			  if(parentContainer != "NEWITEMS")
            //    		  {
            //				  documentContextMenu.push(contextMenuOption['addToNewItems']);
            //    		  }

            //HTML-1211
            /*
            if(parentContainer == "REPOSITORY")
            {
            documentContextMenu.push(contextMenuOption['createShortCut']);
        }*/
            if(!MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['rules']);
            }
        }
    }

    var addBoardbookOptions = function(documentContextMenu, businessObject)
    {
        if(MobileUtil.isSurface())
        {
            return;
        }

        if(StringUtils.isBoardbook(businessObject.objectBaseUuid))
        {
            documentContextMenu.push(contextMenuOption['editboardbook']);
        }
        else
        {
            if(businessObject.isConvertableFmt && businessObject.extensionType != "pdf" && !StringUtils.isImageVO(businessObject.objectBaseUuid) && !StringUtils.isMultiMediaVO(businessObject.objectBaseUuid))
            {
                documentContextMenu.push(contextMenuOption['boardbook']);
            }
        }
    }

    var addCollateOptions = function(documentContextMenu, businessObject)
    {
        if(MobileUtil.isSurface())
        {
            return;
        }

        if(StringUtils.isCollate(businessObject.objectBaseUuid))
        {
            documentContextMenu.push(contextMenuOption['editcollate']);
        }
        else
        {
            if(!StringUtils.isMultiMediaVO(businessObject.objectBaseUuid))
            {
                documentContextMenu.push(contextMenuOption['collate']);
            }
        }
    }

    function addShareOption(documentContextMenu) {
        var currentPortalUuid = context.getModel().portalVO.objectBaseUuid;
        var userIsPortalOwner = false;
        var userIsContentOwner = false;
        var portalPrivileges = context.getModel().userVO.portalPrivileges || [];
        for( var portalPrivilege of portalPrivileges ) {
            if( portalPrivilege.portalBaseUuid === currentPortalUuid ) {
                userIsPortalOwner = portalPrivilege.portalOwner;
                userIsContentOwner = portalPrivilege.contentOwner;
            }
        }

        var showShareOption = (
            !context.getModel().companyVO.readOnlyEmail === true ||
            userIsContentOwner === true ||
            userIsPortalOwner === true
        );

        if( showShareOption == true ) {
            documentContextMenu.push(contextMenuOption['share']);
        }
    }
    var addShareOptions = function(documentContextMenu, businessObject, parentContainer, folderObject)
    {
        var editType = context.getModel().editNative;
        if(editType && editType != "virtualEdit")
        {   
            checkAndAddEdit(documentContextMenu, businessObject, folderObject);
        }

        addShareOption(documentContextMenu);

        if(folderObject)
        {
            if(!MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['rename']);
            }
            addCollateOptions(documentContextMenu, businessObject);
            if(context.getModel().showUploadFolder && !MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['download']);
                documentContextMenu.push(contextMenuOption['copylink']);
            }
            //HTML-1211	//documentContextMenu.push(contextMenuOption['createShortCut']);
        }
        else
        {
            if(parentContainer != "VIEWER" && !MobileUtil.isSurface())
            {
                documentContextMenu.push(contextMenuOption['rename']);
            }
            //Change For MEETX-29963
            if(parentContainer != "FAVORITES" && !contextMenuApprovalStatus)
            {
                addReplace(documentContextMenu, businessObject);
            }
            if(!context.getModel().disablePDFViewForDocuments && !MobileUtil.isSurface())
            {
            	documentContextMenu.push(contextMenuOption['pdf']);
            }
            if(!MobileUtil.isSurface())
            {
            	documentContextMenu.push(contextMenuOption['versionTracker']);
            }

            if(parentContainer != "VIEWER" && parentContainer != "FAVORITES")
            {
                if(editType && editType === "virtualEdit")
                {
                    checkAndAddEdit(documentContextMenu, businessObject, folderObject );
                }
                addBoardbookOptions(documentContextMenu, businessObject);
                addCollateOptions(documentContextMenu, businessObject);
            }
            if(!MobileUtil.isSurface())
            {
            	documentContextMenu.push(contextMenuOption['copy']);
            	documentContextMenu.push(contextMenuOption['download']);
            	documentContextMenu.push(contextMenuOption['copylink']);
            }
            /*if(parentContainer != "FAVORITES")
            {
                documentContextMenu.push(contextMenuOption['addToFavorites']);
            }*/
            checkForFavorites(documentContextMenu, businessObject);
        }

    }

//    var addReadOptions = function(documentContextMenu, businessObject, parentContainer)
//    {
//        if(folderObject)
//        {
//            documentContextMenu.push(contextMenuOption['rules']);
//        }
//    }
    this.getContextMenu = function(isMultipleSelect, businessObject, parentContainer, contextMenuCheckOptions)
    {
        var documentContextMenu = [];
         //change for MEETX-29963
        contextMenuApprovalStatus=contextMenuCheckOptions.approvalStatus;
        if(MobileUtil.isAndroid() || MobileUtil.isIPhone())
        {
            documentContextMenu.push(contextMenuOption['open']);
            if(!StringUtils.isFolder(businessObject.objectBaseUuid))
            {
                /*if(parentContainer != "FAVORITES")
                documentContextMenu.push(contextMenuOption['addToFavorites']);
                else
                documentContextMenu.push(contextMenuOption['removeFromBriefCase']);*/
            	checkForFavorites(documentContextMenu, businessObject);
            }

            documentContextMenu.push(contextMenuOption['send']);
            documentContextMenu.push(contextMenuOption['properties']);

            return documentContextMenu;
        }

        if(parentContainer === "PRESENTER" || parentContainer === "ATTENDEE")
        {
            if(parentContainer === "PRESENTER" && (permissionService.isOwner(businessObject) || permissionService.isShareAllowed(businessObject)))
            {
                addShareOption(documentContextMenu);
            }

            if(permissionService.isOwner(businessObject))
            {
                documentContextMenu.push(contextMenuOption['deleteAllAnnotations']);
            }

            documentContextMenu.push(contextMenuOption['properties']);

            return documentContextMenu;
        }
        else if(parentContainer === "APPROVALS")
        {
            if(permissionService.isPrintAllowed(businessObject))
            {
                if(!MobileUtil.isSurface() && !context.getModel().disablePDFViewForDocuments)
                {
                    documentContextMenu.push(contextMenuOption['pdf']);
                }
                //documentContextMenu.push(contextMenuOption['addToFavorites']);
                checkForFavorites(documentContextMenu, businessObject);
            }
            else if(permissionService.isRead(businessObject))
            {
                //documentContextMenu.push(contextMenuOption['addToFavorites']);
            	checkForFavorites(documentContextMenu, businessObject);
            }

            documentContextMenu.push(contextMenuOption['properties']);
            return documentContextMenu;
        }
        else if(parentContainer === "AGENDA_TEMPLATES")
        {
            documentContextMenu.push(contextMenuOption['open']);
            if(permissionService.isOwner(businessObject))
            {
                documentContextMenu.push(contextMenuOption['edit']);
                documentContextMenu.push(contextMenuOption['rename']);
                documentContextMenu.push(contextMenuOption['delete']);
            }
            if(businessObject.objectBaseUuid !== repositoryService.getDefaultAgendaTemplateBaseUuid() && (context.getModel().isUserPortalAdmin === 'TRUE' || permissionService.isOwner(context.getModel().companyVO))) {
                documentContextMenu.push(contextMenuOption['makeAgendaTemplateDefault']);
            }
            return documentContextMenu;
        }

        if(isMultipleSelect == false)
        {
            var folderObject = StringUtils.isFolder(businessObject.objectBaseUuid);
            //Changes As part Of NBV-175
            if(permissionService.isOwner(businessObject) && 
            (
           (businessObject.scanStatus === 'INFECTED_UNREPAIRED' )||
           (businessObject.scanStatus === 'INFECTED_REPLACED' )||
           (businessObject.scanStatus  === "ERROR_ON_SERVER_WRITE") ||
           (businessObject.scanStatus  === "ERROR_INPUT_STREAM_OPEN") ||
           (businessObject.scanStatus  === "ERROR_INVALID_INPUT") ||
           (businessObject.scanStatus  === "ERROR_SECURE_SOCKET_CREATION") ||
           (businessObject.scanStatus  === "IP_UNRESOLVED") ||
           (businessObject.scanStatus  === "ERROR_SOCKET_COMMUNICATION") ||
           (businessObject.scanStatus  === "ERROR_ON_SERVER_READ") ||
           (businessObject.scanStatus  === "ERROR_READ_WRITE_TIMEOUT") ||
           (businessObject.scanStatus  === "ERROR_SERVER_TOO_BUSY") ||
           (businessObject.scanStatus  === "MAX_TRIES_REACHED") ||
           (businessObject.scanStatus  === "ERROR_OUTPUT_STREAM_OPEN") ||
           (businessObject.scanStatus  === "ERROR_FILE_INPUT_STREAM_OPEN") ||
           (businessObject.scanStatus  === "ERROR_FILE_READ") ||
           (businessObject.scanStatus  === "ERROR_FILE_INPUT_STREAM_CLOSE") ||
           (businessObject.scanStatus  === "INTERNAL_SERVER_ERROR") ||
           (businessObject.scanStatus  === "FILE_ACCESS_FAILED") ||
           (businessObject.scanStatus  === "FILE_SIZE_TOO_LARGE") ||
           (businessObject.scanStatus  === "NO_AV_LICENSE")||
           (businessObject.scanStatus  === "RESOURCE_UNAVAILABLE")
            ))
            {
                documentContextMenu.push(contextMenuOption['delete']);
                return documentContextMenu;
            }

            if(parentContainer != "VIEWER" && !folderObject && parentContainer !== "SURVEYS")
            {
                documentContextMenu.push(contextMenuOption['open']);
            }
            
            if(context.getModel().checkInOutEnabled && !MobileUtil.isApp() && !StringUtils.isBoardbook(businessObject.objectBaseUuid) && !StringUtils.isCollate(businessObject.objectBaseUuid)) 
            {
            	
            	if(businessObject.checkedOutBy != null && businessObject.checkedOutBy != '')
            	{
            		if(permissionService.isOwner(businessObject)) 
        			{
        				documentContextMenu.push(contextMenuOption['cancelcheckout']);
        			}
            		if(businessObject.checkedOutBy.indexOf(context.getModel().userVO.objectBaseUuid) != -1)
            		{
            			documentContextMenu.push(contextMenuOption['checkin']);
            		} 
            	}else 
                {
            		if(!folderObject && permissionService.isCollaborate(businessObject)){
            			documentContextMenu.push(contextMenuOption['checkout']);
            		}
                }
            }

            if(permissionService.isOwner(businessObject))
            {   
                 //Change For MEETX-29963
                addOwnerOptions(documentContextMenu, businessObject, parentContainer, folderObject);
            }
            else if(permissionService.isShareAllowed(businessObject))
            {
                 //Change For MEETX-29963
                addShareOptions(documentContextMenu, businessObject, parentContainer, folderObject);
            }
            else if(permissionService.isPrintAllowed(businessObject))
            {
                if(!folderObject && !MobileUtil.isSurface())
                {
                    if(!context.getModel().disablePDFViewForDocuments)
                    {
                    	documentContextMenu.push(contextMenuOption['pdf']);
                    }
                   // documentContextMenu.push(contextMenuOption['addToFavorites']);
                    checkForFavorites(documentContextMenu, businessObject);
                }
            }
            else if(permissionService.isRead(businessObject))
            {
            	if(!folderObject)
                {
            		//documentContextMenu.push(contextMenuOption['addToFavorites']);
            		checkForFavorites(documentContextMenu, businessObject);
                }
            }

            if(CopyPasteService.currentAction === CopyPasteService.COPY || CopyPasteService.currentAction === CopyPasteService.CUT)
            {
                if(parentContainer != "VIEWER")
                {
                    documentContextMenu.push(contextMenuOption['paste']);
                }
            }

            if(parentContainer == "FAVORITES" && !documentContextMenu.includes(contextMenuOption['removeFromBriefCase']))
            {
                documentContextMenu.push(contextMenuOption['removeFromBriefCase']);
            }
            else if(parentContainer == "NEWITEMS")
            {
                if(!(MobileUtil.isSurface()||MobileUtil.isAndroid()))
                {
                    documentContextMenu.push(contextMenuOption['removeFromNewItems']);
                }
            }

            var rootFolder = context.homeModel().rootFolderForDocuments;

            if(rootFolder && rootFolder.objectBaseUuid == businessObject.objectBaseUuid)
            {
                removeParentRootOptions();
            }

            if((parentContainer === "DOCUMENTS" || parentContainer === "DOCUMENTS_TREE") && context.getModel().followFollowMeEnabled){
                if(contextMenuCheckOptions.alreadySubscribed){
                    documentContextMenu.push(contextMenuOption['stopFollow']);
                }else{
                    documentContextMenu.push(contextMenuOption['follow']);
                }
            }
            if((parentContainer === "DOCUMENTS" || parentContainer === "DOCUMENTS_TREE") && context.getModel().followFollowMeEnabled){
            //    permissionsService.isSetFollowersEnabled(businessObject.objectBaseUuid).then(function(data){
                 console.log('Inside set followers'+contextMenuCheckOptions.setFollowers);
                 if(contextMenuCheckOptions.setFollowers)
                        documentContextMenu.push(contextMenuOption['setFollowers']);
             // });
             //documentContextMenu.push(contextMenuOption['setFollowers']);
            }
            documentContextMenu.push(contextMenuOption['properties']);
            //        	  documentContextMenu.push(contextMenuOption['auditTrail']);

            //        	  else if(permissionService.isEdit(businessObject))
            //        	  {
            //        		  removeItem('auditTrail');
            //        	  }
            //        	  else if(permissionService.isPrintAllowed(businessObject))
            //        	  {
            //        		  removeItem('auditTrail');
            //        	  }
            //        	  else if(permissionService.isRead(businessObject))
            //        	  {
            //        		  removeItem('auditTrail');
            //        	  }
            //        	  else if(this.parentDocument is Vote)
            //        	  {
            //        		  documentContextMenu.remove(contextMenuOption['auditTrail']);
            //        	  }
            if(!folderObject )
            {
                if(businessObject.conversionStatus != "CONVERTED" || businessObject.swfConversionStatus == "CONVERSION_ERROR")
                {
                    removeItem('collate');
                    removeItem('editcollate');
                    removeItem('editboardbook');
                    removeItem("boardbook");
                }
            }
            
           
              /*  if(businessObject.conversionStatus == "CONVERSION_ERROR" || businessObject.swfConversionStatus == "CONVERSION_ERROR"){
                	if( businessObject.isPdfACompliant != undefined && businessObject.isPdfACompliant != null && businessObject.isPdfACompliant==="false"){
                		if(!MobileUtil.isSurface() && !MobileUtil.isDesktopClient()){
	                		if(permissionService.isOwner(businessObject) && context.getModel().enablePdfAComplianceConversion){
	                			documentContextMenu.push(contextMenuOption['makePdfCompliant']);
	                		}
                		}
                		removeItemFromContextMenu(documentContextMenu,'collate');
                		removeItemFromContextMenu(documentContextMenu,'editcollate');
                		removeItemFromContextMenu(documentContextMenu,'editboardbook');
                		removeItemFromContextMenu(documentContextMenu,"boardbook");
                		removeItemFromContextMenu(documentContextMenu,"rename");
                		removeItemFromContextMenu(documentContextMenu,"pdf");
                        
                        
                		removeItemFromContextMenu(documentContextMenu,"permission");
                		removeItemFromContextMenu(documentContextMenu,"copy");
                		removeItemFromContextMenu(documentContextMenu,"cut");
                		removeItemFromContextMenu(documentContextMenu,"paste");
                		removeItemFromContextMenu(documentContextMenu,"move");
                		removeItemFromContextMenu(documentContextMenu,"download");
                		removeItemFromContextMenu(documentContextMenu,"copylink");
                		removeItemFromContextMenu(documentContextMenu,"addToNewItems");
                		removeItemFromContextMenu(documentContextMenu,"addToFavorites");
                		removeItemFromContextMenu(documentContextMenu,"addToUserFavorites");
                		removeItemFromContextMenu(documentContextMenu,"removeFromBriefCase");
                		removeItemFromContextMenu(documentContextMenu,"removeFromAllBriefCases");
                		removeItemFromContextMenu(documentContextMenu,"deleteAllAnnotations");
                		removeItemFromContextMenu(documentContextMenu,"follow");
                		removeItemFromContextMenu(documentContextMenu,"setFollowers");
                		removeItemFromContextMenu(documentContextMenu,"checkin");
                		removeItemFromContextMenu(documentContextMenu,"checkout");
                		removeItemFromContextMenu(documentContextMenu,"open");
                		removeItemFromContextMenu(documentContextMenu,"viewas");
                		removeItemFromContextMenu(documentContextMenu,"rules");
                	}
                	
                	
                }*/

        }
        else
        {
            if(parentContainer != "FAVORITES")
            {
                documentContextMenu.push(contextMenuOption['collate']);
            }

            if(parentContainer == "FAVORITES" && !documentContextMenu.includes(contextMenuOption['removeFromBriefCase']) )
            {
                documentContextMenu.push(contextMenuOption['removeFromBriefCase']);
            }
            else if(parentContainer == "NEWITEMS")
            {
                if(!(MobileUtil.isSurface()||MobileUtil.isAndroid()))
                {
                    documentContextMenu.push(contextMenuOption['removeFromNewItems']);
                }
            }

            if(!isFolderInSelectedObject(businessObject))
            {
                if(isOwnerOfAllObjects(businessObject)) {
                    if(!MobileUtil.isSurface())
                    {
                        documentContextMenu.push(contextMenuOption['addToNewItems']);
                    }
                    if(context.getModel().browserBriefcaseForUserEnabled && parentContainer != "FAVORITES" && !MobileUtil.isSurface())
                    {
                        documentContextMenu.push(contextMenuOption['addToUserFavorites']);
                        documentContextMenu.push(contextMenuOption['removeFromAllBriefCases']);
                    }
                }
                if(parentContainer != "FAVORITES")
                {
                    //documentContextMenu.push(contextMenuOption['addToFavorites']);
                	checkForFavorites(documentContextMenu, businessObject);
                }

            }

            if(parentContainer == "FAVORITES" && !documentContextMenu.includes(contextMenuOption['removeFromBriefCase']))
            {
                removeItem('addToFavorites');
            }

            var isOwnerOfAll = true;
            for (var i = 0 ; i < businessObject.length ; i++)
            {
                if (!permissionService.isOwner(businessObject[i]))
                {
                    isOwnerOfAll = false;
                    break;
                }
            }

            documentContextMenu.push(contextMenuOption['copy']);

            if(isOwnerOfAll)
            {
                if(parentContainer != "VIEWER")
                {
                    documentContextMenu.push(contextMenuOption['cut']);
                    if(parentContainer != "FAVORITES"){
                    documentContextMenu.push(contextMenuOption['delete']);
                    }
                }
            }

            for(var i=0; i<businessObject.length; i++)
            {
                if(businessObject[i].conversionStatus!="CONVERTED")
                {
                    removeItem('collate');
                    removeItem('editcollate');
                    removeItem('editboardbook');
                    removeItem("boardbook");

                }
                if(businessObject[i].swfConversionStatus=="CONVERSION_ERROR")
                {
                    removeItem('collate');
                    removeItem('editcollate');
                    removeItem('editboardbook');
                    removeItem("boardbook");
                }
            }

        }
        var isProfile = context.getModel().portalVO.objectBaseUuid.slice(0, '0PRFL'.length) == '0PRFL';
        if(isProfile) {
            removeItem('addToNewItems');
        }

        if(MobileUtil.isSurface()){
            removeItem('collate');
            removeItem('editcollate');
        }

        if(parentContainer === "ADVANCED_SEARCH")
        {
            var adSearch = adSearchService.getInstance('ADVANCED');
            if(!_.isEmpty(adSearch.searchFolder) && !adSearch.searchFolder.containsHiddenFolder){
                documentContextMenu.splice(0, 0, contextMenuOption['openFolderInDocumentModule']);
            }

            _.remove(documentContextMenu, { type: 'rename'})
            _.remove(documentContextMenu, { type: 'auditTrail'})
            _.remove(documentContextMenu, { type: 'permission'})
            _.remove(documentContextMenu, { type: 'rules'})
            _.remove(documentContextMenu, { type: 'move'})
            _.remove(documentContextMenu, { type: 'delete'})
            _.remove(documentContextMenu, { type: 'copy'})
            _.remove(documentContextMenu, { type: 'cut'})
            _.remove(documentContextMenu, { type: 'collate'})
            _.remove(documentContextMenu, { type: 'replace'})
        }

        console.log(businessObject);
        //removeItem("addToFavorites");
        //removeItem("addToUserFavorites")
        //change for MEETX-29963
        contextMenuApprovalStatus=false;
        return documentContextMenu;
    }

    var isOwnerOfAllObjects = function(businessObjects)
    {
        var isOwnerOfAll = true;
        var len = businessObjects.length
        for (var i = 0 ; i < len ; i++)
        {
            if (!permissionService.isOwner(businessObjects[i]))
            {
                isOwnerOfAll = false;
                break;
            }
        }
        return isOwnerOfAll;
    }

    var isFolderInSelectedObject = function(businessObjects)
    {
        var isFolderSelected = false;
        var len = businessObjects.length
        for (var i = 0 ; i < len ; i++)
        {
            if (StringUtils.isFolder(businessObjects[i].objectBaseUuid))
            {
                isFolderSelected = true;
                break;
            }
        }
        return isFolderSelected;
    }

    this.getPosition = function(xPos, yPos, selectedContextItem, length, factor, width, separatorValue)
    {
        return contextMenuSetting.getPosition(xPos, yPos, selectedContextItem, length, factor, width, separatorValue)
    }

    this.getArrowPosition = function(xPos, yPos, length, factor, width)
    {
        return contextMenuSetting.getArrowPosition(xPos, yPos, length, factor, width)
    }
}