angular
    .module('dialogControllers.service')
	.controller('adminAuthenticationController', adminAuthenticationController);

adminAuthenticationController.$inject = ['$scope', 'context', 'localizedMessages', 'homeService', 'constants', 'bvDialog', 'bvDialogService'];

function adminAuthenticationController($scope, context, localizedMessages, homeService, constants, bvDialog, bvDialogService)
{
	$scope.ui = {
			label : {
						administratorAuthentication      		: localizedMessages.get("ADMINISTRATOR_AUTHENTICATION"),
						selectMethod 					 		: localizedMessages.get('SELECT_METHOD'),
						emailAuthentication				 		: localizedMessages.get('EMAIL_AUTHENTICATION'),
						mobileAuthentication				 	: localizedMessages.get('MOBILE_AUTHENTICATION'),
						administratorAuthenticationEmailMethod	: localizedMessages.get('ADMINISTRATOR_AUTHENTICATION_EMAIL_METHOD'),
						administratorAuthenticationSmsMethod	: localizedMessages.get('ADMINISTRATOR_AUTHENTICATION_SMS_METHOD'),
						submit									: localizedMessages.get('SUBMIT'),
						proceed									: localizedMessages.get('PROCEED'),
						enterAuthenticationCodeYouJustReceived	: localizedMessages.get('ENTER_AUTHENTICATION_CODE_YOU_JUST_RECEIVED')
			}

	};
	$scope.actionBtnTxt 							= localizedMessages.get('SUBMIT');
	$scope.closeBtnTxt 								= localizedMessages.get('CANCEL');	
	
	$scope.user = {};
	$scope.adminAuthFirstTier = true;
	$scope.user.authType = "";
	$scope.isAuthTypeEmail=true;
	$scope.user.hideMobileMethod = false;
	$scope.user.showError = false;
	$scope.attemptCount = 0;
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	
	$scope.initUserData = function() 
	{
		var mobilePhone = context.getModel().userVO.mobilePhone;
		var email = context.getModel().userVO.email;
		$scope.user.authType = "SECOND_FACTOR_BY_EMAIL";
		$scope.user.emailMask = "xxx" + email.substring(email.indexOf('@'), email.length) ;
		if(mobilePhone)
		{
			$scope.user.mobileMask = "xxx-xxx-" + mobilePhone.substring(mobilePhone.length - 4, mobilePhone.length);
		}
		else
		{
			$scope.user.hideMobileMethod = true;
			$scope.user.mobileMask = "xxx-xxx-xxxx";
		}
	}
	
	$scope.initUserData();
	
	$scope.action = function()
	{
		if($scope.adminAuthFirstTier)
		{
			$scope.adminAuthFirstTier = false;
			$scope.actionBtnTxt = localizedMessages.get('PROCEED');
			homeService.sendSecondFactorAuthCode(constants.get($scope.user.authType));
			
		}
		else
		{
			$scope.user.showError = false;
			if($scope.user.authCode)
				homeService.isSecondFactorCookieSetAfterVerification($scope.user.authCode).then(function(data) 
	    		{
					if(data == true)
					{
						 var w = 900;
		    			 var h = 650;
		    			 var left = (window.innerWidth/2)-(w/2);
		    			 var top = (window.innerHeight/2)-(h/2);
		    			 
		    			if(StringUtils.isNullEmpty(serverURL))
	    				{
	    					bvDialogService.openNewWindow("/portlet/login.jsp?openFlexModule=showAdmin","_blank","toolbar=yes, resizable=no, width=" + w + ", height="+h+",left="+  left + ",top=" + top);
	    				}
		    			else
	    				{		    					
	    					bvDialogService.openNewWindow(serverURL+"/portlet/login.jsp?openFlexModule=showAdmin","_blank","toolbar=yes, resizable=no, width=" + w + ", height="+h+",left="+  left + ",top=" + top);
	    				}		    			 
		    			bvModal.close(); 
					}
					else
					{
						if($scope.attemptCount >= 2)
						{
							bvDialog.showMessage(localizedMessages.get('AUTHENTICATION_FAILED'))
							bvModal.close(); 
						}
						else
						{
							$scope.attemptCount = $scope.attemptCount + 1;
							$scope.user.showError = true;
							//$scope.errorMsg = $scope.errorMsg1 + " " + $scope.attemptCount + " " + $scope.errorMsg2; 
							$scope.errorMsg = localizedMessages.get("ERR_SYSTEM_ADMIN_LOGIN", {"attemptCount" : $scope.attemptCount}); 
						}
					}
						
			    });
		}
		
	}
}
