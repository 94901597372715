EditPhotoController.$inject = [ 'teamService', '$scope', '$modalInstance', '$modal','localizedMessages', 'templatesService', 'bvDialog'];

function EditPhotoController(teamService, $scope, $modalInstance, modal, localizedMessages, templatesService, bvDialog) {

	//console.log($scope.selectedUser);
	$scope.popupTitle = localizedMessages.get('EDIT_PHOTO');

	$scope.cancelLable = localizedMessages.get('CANCEL');
	$scope.okLable = localizedMessages.get('OK');
	$scope.uploadPhotoLable = localizedMessages.get('UPLOAD_PHOTO');

	$scope.closeIcon = templatesService.getUrl('closeBtn');

	$scope.profile = angular.copy($scope.selectedUser);

	$scope.profilePhoto='';
	$scope.profilePhotoCropped='';

	$scope.close = function(result) {
		$modalInstance.dismiss('cancel');
	};

	$scope.cancelConfirmation = function() {
		if($scope.frmEditPhoto.$dirty == true) {
			bvDialog.confirm(localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit());
		} else {
			$scope.cancelEdit();
		}
	};

	$scope.cancelEdit = function(result) {
		$scope.close();
	};
	
	$scope.savePhoto = function(result) {

		var i = $scope.profilePhotoCropped.indexOf(',');
    	var croppedImage = $scope.profilePhotoCropped.substr(i+1,
    		$scope.profilePhotoCropped.length);

    	$modalInstance.close(croppedImage);
	};

	$scope.handlePhotoSelection = function(file) {
		// var file = evt.currentTarget.files[0];
		var allowedExtensions = new RegExp("\.jpg|\.jpeg|\.png","i");
	    if(!allowedExtensions.exec(file.name))
	    {
	    	bvDialog.showMessage('Please upload file having extensions .jpeg,.jpg and .png only.');
	        return false;
	    }
		var reader = new FileReader();
		reader.onload = function (evt) {
			$scope.$apply(function($scope){
				$scope.profilePhoto = evt.target.result;
			});
		};
		reader.readAsDataURL(file);
	};

	var getCurrentProfilePhoto = function() {
		$scope.profilePhoto = homeController.getUserThumbnail($scope.profile);
	};

	var homeController = angular.element(document.querySelector('[home-controler]')).scope();

	getCurrentProfilePhoto();
}

angular
    .module('editPhoto', [ 'team.service',,'localization.service','ngImgCrop' ])
    .controller('EditPhotoController', EditPhotoController);
