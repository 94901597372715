angular
	.module('dialogControllers.service')
	.controller('restoringProgressDialogController', restoringProgressDialogController);

restoringProgressDialogController.$inject = ['$scope', 'localizedMessages', 'repositoryService', '$timeout', 'loadRecycle'];

function restoringProgressDialogController($scope, localizedMessages, repositoryService, $timeout, loadRecycle){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	
	$scope.popupTitle = localizedMessages.get('RESTORING_FILES');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.ui = {
			overallProgress: localizedMessages.get('OVERALL_PROGRESS'),
			restoring: localizedMessages.get('RESTORING')
	}
	var targetFolder = $scope.bvModalControllerInputs.targetFolder;
	var selectedObjects = $scope.bvModalControllerInputs.selectedObjects;
	var step = parseInt(100 / selectedObjects.length);
	
	$scope.selectedObjects = selectedObjects;
	
	$scope.overallProgress = 0;
	$scope.oneProgress = 0;
	
	$scope.index = 0;
	
	var restoreFile = function(){
		if($scope.index < selectedObjects.length){
			var documentsToJSONMove = angular.toJson([selectedObjects[$scope.index].objectBaseUuid]);
			$scope.oneProgress = 0;
			$scope.fileName = selectedObjects[$scope.index].name;
			repositoryService.restoreFromRecycleBinJSON(targetFolder.objectBaseUuid, documentsToJSONMove).then(function(data){
				if(data){
					if(JSON.parse(data.data).success == "OK"){
						$scope.overallProgress = $scope.overallProgress + step;
						$scope.oneProgress = 100;
					}
				}
				$scope.index++
				$timeout(function(){restoreFile()});
			});
		}
		else{
			$scope.overallProgress = 100;
			$timeout(function(){loadRecycle.loadRecyleData()},600);
			$timeout(function(){bvModal.close()},1500);
		}
	}
	
	restoreFile();
	
	$scope.close = function(){
		$scope.index = selectedObjects.length;
		bvModal.close();
	}
	
}