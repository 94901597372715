function meetingsService($q, ajaxService, responseValidatorService) {
	var service = {
			
			getDocumentEncodedThumbnail:function(objectBaseUuid,objectVersionUuid,size)
			{
				var request = ajaxService.getDocumentEncodedThumbnail(objectBaseUuid,objectVersionUuid,size);
				return request.then(function(data){
					return data;
				});
				return "";
			},
			
			getUserProfileImage: function(objectBaseUuid, objectVersionUuid) {
				var request = ajaxService.getUserProfileImage(objectBaseUuid, objectVersionUuid);
				return request.then(function(data){
					return data;
				});
				return "";	
			},
			
			getUserProfileImageById: function(objectBaseUuid) {
				var request = ajaxService.getUserProfileImageByObjectBaseUuid(objectBaseUuid);
				return request.then(function(data){
					return data;
				});
				return "";	
			},
			
			
			setCalendarAttendance: function(calendarEventBaseUuid, attendanceJsonList) {
				var request = ajaxService.setCalendarAttendance(calendarEventBaseUuid, attendanceJsonList);
				return request.then(function(data){
					return data;
				});
				return "";	
			},
			
			loadGuestandResponseForEventWithoutAttachmentsJSON: function(objectBaseUuid) {
				var request = ajaxService.loadGuestandResponseForEventWithoutAttachmentsJSON(objectBaseUuid);
				return request.then(function(data){
					return data;
				});
				return "";	
			},
		
			getCalenderEventTimeIncrementValue : function(){
				var request = ajaxService.getConfigurationValue('int.calendar_event_time_increment_value');
				return request.then(function(data){
					return data;
				});				
			},		
			
			createCalendarEvent : function(meetingTitle,details,address,callingNumber,startDateTime,endDateTime,attachmentJson,attendeesJson,isActive,codeNumber,location,allDay,host,conferenceLink,eventTimeZone){				
				var request = ajaxService.createCalendarEvent(meetingTitle,details,address,callingNumber,startDateTime,endDateTime,attachmentJson,attendeesJson,isActive,codeNumber,location,allDay,host,conferenceLink,eventTimeZone);
				return request.then(function(data){
					return data;
				});
				return "";
			},
			editAndNotifyCalendarEvent : function(objectBaseUuid,meetingTitle, details, address, callingNumber, startDateTime, endDateTime, attachmentJson, attendeesJson,isActive,codeNumber,location,allDay,host,conferenceLink,eventTimeZone){
				var request = ajaxService.editAndNotifyCalendarEvent(objectBaseUuid,meetingTitle,details,address,callingNumber,startDateTime,endDateTime,attachmentJson,attendeesJson,isActive,codeNumber,location,allDay,host,conferenceLink,eventTimeZone);
				return request.then(function(data){
					return data;
				});
				return "";
			},
			initializeDatepicker : function(meetingCenterObject){
				var dateFormat = StringUtils.getLocaleDateFormat();
				var monthNamesShort = moment.localeData()._monthsShort;

				$( "#datepickerStarting" ).datepicker({
					showPanelButton:true,
					beforeShow:function(){
						$('.ui-datepicker').css('font-size',11);
						$("#ui-datepicker-div").addClass("meeting-datepicker");
						//$('.ui-state-default').css('background:none;');
						//$('.meeting-datepicker .ui-state-default').addClass('meeting-datepicker-ui-state-default');

					},
					showAnim: 'slideDown',
					monthNamesShort: monthNamesShort,
					dateFormat: dateFormat,
					onSelect: function(dateText, inst) {
						var parcedDate = $.datepicker.parseDate(inst.settings.dateFormat || $.datepicker._defaults.dateFormat, dateText, inst.settings);
						if(meetingCenterObject){
						    meetingCenterObject.startingDate = moment(parcedDate).format('ll');
						    meetingCenterObject.startingDateLocal = StringUtils.momentL(parcedDate).format('ll');
						}
					}
				});

				$( "#datepickerStarting" ).show();
				$( "#datepickerStarting" ).focus();
			}
	}

	return service;
}
angular
	.module('meetings.service', [ 'ajax.service', 'meetingAlertService.factory' ])
	.factory('meetingsService',[ '$q', 'ajaxService', 'responseValidatorService', meetingsService ]);