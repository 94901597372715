CreateSurveyNewController.$inject = ['$scope', '$timeout','$stateParams', 'localizedMessages', 'reactInterface', 'surveysService', 'context', 'bvDialog', 'previewSurveyService', 'saveSurveyTemplateService'];

function CreateSurveyNewController($scope,$timeout, $stateParams, localizedMessages, reactInterface, surveysService, context, bvDialog, previewSurveyService, saveSurveyTemplateService)
{
    $scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
    $scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
    
    $scope.reactInterface = reactInterface;
    $scope.surveyBaseUuid = $stateParams.objectBaseUuid;

    $scope.isPublished = false;
    
    $scope.surveyObject = surveysService.getSurvey();
    surveysService.setIsSurveyPublished(false);
//    $scope.surveyObject.isPublished = false;

    $scope.middleBarObject.showBack = true;
    $scope.handleLeftIcon = () => {
        const { backButton } = reactInterface.callReact
        backButton()
    }

    const preview = () => {
        const { getSurvey } = reactInterface.callReact;

        if(getSurvey && typeof getSurvey == "function") {
            const survey = getSurvey()

            if(!survey.sections 
                    || survey.sections.length == 0 
                    || !survey.sections.find(section => section.questions.length > 0 && section.questions.find(question => question.comments.length > 0 && question.questionType))) {
                bvDialog.showMessage(localizedMessages.get("ADD_MIN_SECTION_QUESTIONS_PREVIEW"))
            }
            else {
                previewSurveyService.showPreviewDialog(survey)
            }
        }
    }

    $scope.saveAsTemplate = function() {
        const { getSurvey, templateSavedCallback } = reactInterface.callReact;
    	if(getSurvey && typeof getSurvey == "function") {
    		var survey = getSurvey();
    		if(!survey.sections || survey.sections.length == 0 || !survey.sections.find(section => section.questions.length > 0))
                bvDialog.showMessage(localizedMessages.get("ADD_MIN_SECTION_QUESTIONS_TEMPLATE"))
            else{
                if(survey) {
                    survey.isSurveyTemplate = true;
                    if(survey.objectBaseUuid) {
                        survey.objectBaseUuid = "";
                    }

                    if(survey.name) {
                        survey.name = localizedMessages.get("TEMPLATE") + "-" + survey.name;
                    }

                    survey.attendeesList = [];
                    survey.objectBaseUuid = "";
                    var surveyOwnersUuids = [];
                    surveyOwnersUuids.push(context.getModel().userVO.objectBaseUuid);
                    saveSurveyTemplateService.saveSurveyTemplate(survey, surveyOwnersUuids, templateSavedCallback);
                }
            }
    	}
    	
    }
    
    var publish = function() {
        const { setPublished } = reactInterface.callReact
    	if(setPublished && typeof setPublished == "function") {
    		if(setPublished())
    		    $scope.tabs[0].rightMenuList[2].iconUrl = $scope.bvchecktrue
            else
    		    $scope.tabs[0].rightMenuList[2].iconUrl = $scope.bvcheckfalse
    	}
    	
    }
    
    var setDefaultPublishCheckbox = function() {
        const { isSurveyPublished } = reactInterface.callReact
    	if(isSurveyPublished && typeof isSurveyPublished == "function") {
    		if(isSurveyPublished())
    		    $scope.tabs[0].rightMenuList[2].iconUrl = $scope.bvchecktrue
            else
    		    $scope.tabs[0].rightMenuList[2].iconUrl = $scope.bvcheckfalse
    	}
    	
    }
   
    var init = function () {
    	$timeout(setDefaultPublishCheckbox, 3000); 	
    }
   
    init();
    
    $scope.tabs = [{
        title : localizedMessages.get("SURVEY"),
        content : 'openSurveys',
        active: true,
        rightMenuList:[
            {
                title : localizedMessages.get("PREVIEW_SURVEY"),
                iconUrl : '',
                click : preview,
                additionalClass: 'preview-survey-menu-item'
            }
        ]
    }]
    
}

angular
    .module('CreateNewSurvey', ['surveys.service', 'localization.service', 'react.interface', 'saveSurveyTemplate'])
    .controller('CreateSurveyNewController', CreateSurveyNewController)