CreateSignatureController.$inject = [ 'teamService','$scope', '$modalInstance', '$modal','localizedMessages','bvDialog', '$timeout', '$window', 'templatesService'];
function CreateSignatureController(teamService, $scope, $modalInstance, modal, localizedMessages, bvDialog, $timeout, $window, templatesService) {

    $scope.currentSignatureLbl = localizedMessages.get('CURRENT_SIGNATURE');
    $scope.currentInitialsLbl = localizedMessages.get('CURRENT_INITIALS');
    $scope.newSignatureLbl = localizedMessages.get('NEW_SIGNATURE');
    $scope.newInitialsLbl = localizedMessages.get('NEW_INITIALS');
    $scope.styleLbl =localizedMessages.get('STYLE');
    $scope.changeStyleLbl = localizedMessages.get('CHANGE_STYLE');
    $scope.resetLbl = localizedMessages.get('RESET');
    $scope.drawSignatureLbl = localizedMessages.get('DRAW_SIGNATURE');
    $scope.autoGeneratedLbl = localizedMessages.get('AUTO_GENERATED');
    $scope.cancelLbl = localizedMessages.get('CANCEL');
    $scope.okLbl = localizedMessages.get('OK');

	$scope.closeIcon = templatesService.getUrl('closeBtn');

	$scope.popupTitle = localizedMessages.get('SIGNATURE');
    var clickY;
    var clickX;
    var paint;
    var lineCap = 'round';
    var lineWidth = 3;
    var strokeStyle = "#000000";

//    $scope.sign;

    var getXPosition = function($event) {
        if($event.offsetX!==undefined){
          return $event.offsetX;
        } else if($event.layerX!==undefined){
            return $event.layerX;
        } else {
            return $event.originalEvent.layerX;
        }
    }

     var getYPosition = function($event) {
        if($event.offsetY!==undefined){
            return $event.offsetY;
        } else if($event.layerY!==undefined){
            return $event.layerY;
        } else {
            return $event.originalEvent.layerY;
        }
    }

    $scope.mouseDown = function($event) {
        paint = true;

        clickX = getXPosition($event);
        clickY = getYPosition($event);
    };

    $scope.touchDown = function($event) {
    	paint = true;
    	if($event.originalEvent != undefined && $event.originalEvent.touches.length !=0) {
    		$event.originalEvent.touches[0].offsetX = $event.originalEvent.touches[0].pageX - $event.target.offsetLeft;
    		$event.originalEvent.touches[0].offsetY = $event.originalEvent.touches[0].pageY - $event.target.offsetTop - 50;
    	}
    	clickX = getXPosition($event.originalEvent.touches[0]);
        clickY = getYPosition($event.originalEvent.touches[0]);
    }

    $scope.mouseUp = function($event) {
        paint = false;
    };

    $scope.touchMove = function($event) {
        if (paint && !$scope.useAuto) {
            var ctx = $event.target.getContext('2d');
            ctx.lineCap = lineCap;
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = strokeStyle;

            ctx.beginPath();

            ctx.moveTo(clickX, clickY);

            if($event.originalEvent != undefined && $event.originalEvent.touches.length !=0) {
        		$event.originalEvent.touches[0].offsetX = $event.originalEvent.touches[0].pageX - $event.target.offsetLeft;
        		$event.originalEvent.touches[0].offsetY = $event.originalEvent.touches[0].pageY - $event.target.offsetTop - 50;
        	}

            ctx.lineTo(getXPosition($event.originalEvent.touches[0]), getYPosition($event.originalEvent.touches[0]));

            ctx.stroke();
            ctx.closePath();

           clickX = getXPosition($event.originalEvent.touches[0]);
           clickY = getYPosition($event.originalEvent.touches[0]);
//            $scope.sign = $event.target.toDataURL();
            $event.target.$dirty = true;
            $scope.frmSignature.$setDirty(true);
        }
        //console.log($event);
    };


    $scope.mouseMove = function($event) {
        if (paint && !$scope.useAuto) {
            var ctx = $event.target.getContext('2d');
            ctx.lineCap = lineCap;
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = strokeStyle;

            ctx.beginPath();

            ctx.moveTo(clickX, clickY);
            ctx.lineTo(getXPosition($event), getYPosition($event));

            ctx.stroke();
            ctx.closePath();

           clickX = getXPosition($event);
           clickY = getYPosition($event);
//            $scope.sign = $event.target.toDataURL();
            $event.target.$dirty = true;
            $scope.frmSignature.$setDirty(true);
        }
        //console.log($event);
    };

    $scope.clear = function(id) {
    	var element = document.getElementById(id);
    	element.$dirty = false;
    	var ctx = element.getContext('2d');
    	ctx.clearRect(0, 0, element.width, element.height);
    };

    //////////

    $scope.currentSignatureAndInitials = {};
    $scope.getUserSignatureAndInitials = function() {
    	teamService.getUserSignatureAndInitials().
    	then(function(data) {
			$scope.currentSignatureAndInitials = data;
			console.log(data);
		})
    }

    $scope.getCurrentSignature = function() {
    	if($scope.currentSignatureAndInitials.Signature) {
			return "data:image/png;base64," + $scope.currentSignatureAndInitials.Signature;
		}
		else {
			return '';
		}
    }

    $scope.getCurrentInitials = function() {
    	if($scope.currentSignatureAndInitials.Initials) {
			return "data:image/png;base64," + $scope.currentSignatureAndInitials.Initials;
		}
		else {
			return '';
		}
    }

    $scope.getUserSignatureAndInitials($scope.selectedUser.objectBaseUuid);

    $scope.cancelConfirmation = function() {
		if($scope.frmSignature.$dirty == true) {
            bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.closeSign );
			// ngDialog.open({
			// 	template: '<div style="text-align:center">' + localizedMessages.get('CANCEL_SIGNATURE_CHANGES_CONFIRMATION_MSG') +
			// 	'<hr><button class="yesButton" ng-click="closeThisDialog();closeSign();">' + localizedMessages.get('YES') + '</button>' +
			// 	'<button class="noButton" ng-click="closeThisDialog()">' + localizedMessages.get('NO') + '</button></div>',
			// 	plain: true,
			// 	scope: $scope
			// });
		} else {
			$scope.closeSign();
		}
	}

	$scope.closeSign = function() {
		$modalInstance.dismiss('cancel');
        if(MobileUtil.isAndroid()) {
			console.log("Closing Signature Dialog.");
			Android.closeSignatureDialog();
		}
	}

    $scope.isDirty = function (id) {
        var element = document.getElementById(id);
        return element != null && element != undefined ? element.$dirty : false;
    }

	$scope.saveSignatures = function(signID, initialsID) {
		var signature = "";
		var initials = "";

        //checking if no sign and initials exist before than both need to be saved

        if(($scope.getCurrentSignature() == "" || $scope.getCurrentInitials() == "")
            && (!$scope.isDirty(signID) || !$scope.isDirty(initialsID))) {
            bvDialog.showMessage(localizedMessages.get("DRAW_SIGNATURE_AND_INITIAL_BOTH"));
            return;
        }

		var signElement = document.getElementById(signID);
    	if($scope.isDirty(signID)) {
    		signature = signElement.toDataURL();
    	    var i = signature.indexOf(',');
    	    signature = signature.substr(i+1, signature.length);
    	}
		var initialsElement = document.getElementById(initialsID);
		if($scope.isDirty(initialsID)) {
			initials = initialsElement.toDataURL();
    	    var i = initials.indexOf(',');
    	    initials = initials.substr(i+1, initials.length);
		}
        console.log(signature);
        console.log(initials);
        if($scope.signatureCaller != undefined && $scope.signatureCaller != null && ($scope.signatureCaller === "repository" || $scope.signatureCaller === "approvals" || $scope.signatureCaller === "surveys" ))
        {
            $scope.$parent.loggedInUserSignature = signature;
            $scope.$parent.loggedInUserInitials = initials;
        }

    	teamService.createUserSignatureAndInitials(signature, initials).
    	then(function(data) {
            console.log(data);

            if(MobileUtil.isAndroid()) {
				console.log("Saving Signature.");
				Android.saveSignature();
			}

            if($scope.signatureCaller == undefined || $scope.signatureCaller == null || ($scope.signatureCaller != "repository" && $scope.signatureCaller != "approvals"))
            {
                var message = localizedMessages.get('SIGNATURE_SAVED');

                if(data.message) { message = localizedMessages.get('SIGNATURE_SAVE_FAILED');}

                bvDialog.showMessage(message, null, true);
                // ngDialog.open({
                //     template: '<div style="text-align:center"> ' +  message + '</div>',
                //     plain: true,
                //     scope: $scope
                // });

            }
		});

        $scope.closeSign();
	}

    $scope.useAuto = false;

    var autoSignFormat = [
        {fontFamily : 'edwardian script itc', fontSize : '45'},
        {fontFamily : 'Brush Script MT', fontSize : '45'},
        {fontFamily : 'Curlz MT', fontSize : '40'},
        {fontFamily : 'Lucida Calligraphy', fontSize : '27'},
        {fontFamily : 'Lucida Handwriting', fontSize : '27'},
        {fontFamily : 'Matura MT Script Capitals', fontSize: '30'},
        {fontFamily : 'Mistral', fontSize : '50'}
    ];

    $scope.signIndex = -1;

    $scope.showNextAutoGenerated = function(signID, initialsID)
    {
        if($scope.signIndex < autoSignFormat.length - 1 ) {
            $scope.signIndex = $scope.signIndex + 1;
        } else {
            $scope.signIndex = 0;
        }
        $scope.showAutoGenerated(signID, initialsID);
    }

    $scope.showPrevAutoGenerated = function(signID, initialsID)
    {
        if($scope.signIndex == 0 ) {
            $scope.signIndex = autoSignFormat.length - 1;
        } else {
            $scope.signIndex = $scope.signIndex - 1;
        }
        $scope.showAutoGenerated(signID, initialsID);
    }

    $scope.showAutoGenerated = function(signID, initialsID) {
        var x;
        var y;
        var textWidth;
        var fontSize;

        var element = document.getElementById(signID);
        var ctx = element.getContext('2d');
        fontSize = autoSignFormat[$scope.signIndex].fontSize;
        textWidth = element.width;
        x = element.width / 2;

        ctx.fillStyle = strokeStyle;
        ctx.textAlign = 'center';
        var text = $scope.selectedUser.displayName;

        while(textWidth >= element.width - 10) {
            ctx.font =  fontSize + 'pt ' + autoSignFormat[$scope.signIndex].fontFamily;
            y = (element.height / 2) + (fontSize / 2);
            ctx.clearRect(0, 0, element.width, element.height);
            ctx.fillText(text, x, y);
            var metrics = ctx.measureText(text);
            textWidth = metrics.width;
            fontSize = fontSize - 1;
        }

        element.$dirty = true;

        element = document.getElementById(initialsID);
        ctx = element.getContext('2d');

        fontSize = autoSignFormat[$scope.signIndex].fontSize;
        textWidth = element.width;
        x = element.width / 2;

        ctx.fillStyle = strokeStyle;
        ctx.textAlign = 'center';
        var text = $scope.selectedUser.firstName.charAt(0) + $scope.selectedUser.lastName.charAt(0);

        while(textWidth >= element.width - 10) {
            ctx.font =  fontSize + 'pt ' + autoSignFormat[$scope.signIndex].fontFamily;
            y = (element.height / 2) + (fontSize / 2);
            ctx.clearRect(0, 0, element.width, element.height);
            ctx.fillText(text, x, y);
            var metrics = ctx.measureText(text);
            textWidth = metrics.width;
            fontSize = fontSize - 1;
        }

        element.$dirty = true;
        $scope.frmSignature.$setDirty(true);

        // if($scope.signIndex < autoSignFormat.length - 1 ) {
        //     $scope.signIndex = $scope.signIndex + 1;
        // } else {
        //     $scope.signIndex = 0;
        // }
    }

    $scope.toggleMode = function(signID, initialsID) {
        if($scope.useAuto) {
            $scope.useAuto = false;
            $scope.clear(signID);
            $scope.clear(initialsID);
        } else {
            $scope.useAuto = true;
            $scope.showNextAutoGenerated(signID,initialsID);
        }
    }

    // for mobile only

    if(MobileUtil.isPhone()) {
        var autoSignFormat = [
            {fontFamily : 'edwardian script itc', fontSize : '65'},
            {fontFamily : 'Brush Script MT', fontSize : '65'},
            {fontFamily : 'Curlz MT', fontSize : '60'},
            {fontFamily : 'Lucida Calligraphy', fontSize : '47'},
            {fontFamily : 'Lucida Handwriting', fontSize : '47'},
            {fontFamily : 'Matura MT Script Capitals', fontSize: '50'},
            {fontFamily : 'Mistral', fontSize : '70'}
        ];
    }

    $scope.clearLbl = localizedMessages.get('CLEAR');
    $scope.saveLbl = localizedMessages.get('SAVE');
    $scope.noSignature = localizedMessages.get("NO_CURRENT_SIGNATURE");
    $scope.noInitial = localizedMessages.get("NO_CURRENT_INITIAL");
    $scope.rotateDeviceMsg = localizedMessages.get("ROTATE_DEVICE_TO_DRAW");
    $scope.drawNewSignatureHere = localizedMessages.get("DRAW_NEW_SIGNATURE_HERE");
    $scope.drawNewInitialHere = localizedMessages.get("DRAW_NEW_INITIAL_HERE");
    $scope.nextLbl = localizedMessages.get("NEXT");
    $scope.createNewSignatureLbl = localizedMessages.get("CREATE_NEW_SIGNATURE");

    $scope.backIcon = 'resources/assets/svgs/backarrow.svg';


    $scope.onCreateNew = false;
    $scope.onSignature = false;

    $scope.createNewSignature = function() {
        $scope.onCreateNew = true;
        $scope.onSignature = true;
        $scope.popupTitle = $scope.newSignatureLbl;

        $timeout(function(){
            $scope.matchSignCanvasSize();
        }, 200);
    }

    $scope.goToInitCreation = function() {
        $scope.onSignature = false;
        $scope.popupTitle = $scope.newInitialsLbl;
        $timeout(function(){
            $scope.matchInitCanvasSize();
        }, 200);
    }

    $scope.matchSignCanvasSize = function() {
        var sign_canvas = document.querySelector('#sign');
        fitCanvas(sign_canvas);
    }

    $scope.matchInitCanvasSize = function() {
        var init_canvas = document.querySelector('#initials');
        fitCanvas(init_canvas);
    }

    var fitCanvas = function (canvas) {
        var ctx = canvas.getContext('2d');
        var current_data = null;
        if(canvas.width != 0 && canvas.height !=0 )
            current_data = ctx.getImageData(0, 0, canvas.width, canvas.height);

        // Make it visually fill the positioned parent
        canvas.style.width ='100%';
        canvas.style.height='100%';
        // ...then set the internal size to match

        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        if(current_data != null)
            ctx.putImageData(current_data, 0, 0);
    }

    $scope.back = function(result) {
        $scope.createNewSignature();
    };

    $scope.getWindowOrientation = function () {
    	if($window.screen.orientation)
		{
    		return $window.screen.orientation.type;
    	}
    	else
		{
    		return $window.screen.msOrientation;
		}

    };

    $scope.$watch($scope.getWindowOrientation, function (newValue, oldValue) {
        if(newValue.indexOf("landscape") > -1 && $scope.onCreateNew)
        {
            if($scope.onSignature)
                $scope.matchSignCanvasSize();
            else
                $scope.matchInitCanvasSize();
        }
    }, true);
}

angular
    .module('createSignature', ['team.service','localization.service','ngTouchNglar'])
    .controller('CreateSignatureController', CreateSignatureController)
    .controller('CreateSignatureController2', CreateSignatureController2)