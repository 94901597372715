function SliderService(){
	this.currentIndex = 0;
	this.showIcon = {};
	this.element = {};
	this.shownItems = 3;
	this.inprogress = false;
	this.fixQuantityFlag = false;
	this.numberOfItems = 0;
	this.callBack = function(){};
	this.scope = {};

	return {
		currentIndex 	: this.currentIndex,
		showIcon	 	: this.showIcon,
		element			: this.element,
		shownItems		: this.shownItems,
		inprogress		: this.inprogress,
		fixQuantityFlag	: this.fixQuantityFlag,
		numberOfItems	: this.numberOfItems,
		callBack		: this.callBack,
		scope			: this.scope,

		getScrollWidthElement : function(el){
			var w = el.offsetWidth;
			var sW = el.scrollWidth;
			var nE = el.children.length;

			var pL = parseFloat(window.getComputedStyle(el).paddingLeft);
			var pR = parseFloat(window.getComputedStyle(el).paddingRight);

			return (sW - (pL + pR))/nE;
		},

		getPositionElement : function(el){
			var swE = this.getScrollWidthElement(el);
			var p = 0;
			if(swE)
			{
				var nE = el.children.length;
				var slE = el.scrollLeft;

				for(var i = 0 ; i <= nE ; i++)
				{
					if(slE > (i * swE) - swE/2)
					{
						p = i;
					}
				}
				this.currentIndex = p;
			}
			return p;
		},

		fixScroll : function(smooth){
			var self = this;
			if(this.element[0])
			{
				var el = this.element[0].children[0];
				var offsetWidth = this.getScrollWidthElement(el);
				var currentIndex = this.currentIndex;

				if(smooth){
					//setTimeout(function(){
						SmoothScroll.smoothScrollH(el, offsetWidth * currentIndex, 3, null, false).then(function(){
							//bvSliderService.fixButtons($scope);
							self.fixButtons(self.scope);
						});
					//},0)
				}
				else{
					this.element[0].children[0].scrollLeft = offsetWidth * this.currentIndex;
				}
				//				.then(function(){
	//					//this.fixButtons();
	//					//bvSliderService.fixButtons($scope);
	//				});

			}
		},

		fixButtons : function(applyScope){
			var el = this.element[0].children[0];
			this.showIcon.showLeftIcon = this.currentIndex > 0 ? true : false;
			this.showIcon.showRightIcon = this.currentIndex < this.numberOfItems - 1 ? true : false;
			if(el.scrollLeft <= 1){
				this.showIcon.showLeftIcon = false;
			}
			else{
				this.showIcon.showLeftIcon = true;
			}
			if(el.offsetWidth + el.scrollLeft >= el.scrollWidth - 1){
				this.showIcon.showRightIcon = false;
			}
			else{
				this.showIcon.showRightIcon = true;
			}
			if(applyScope){ applyScope.$apply() }
		},

		fixQuantity : function(menuExpanded){
			if(this.element[0] && this.fixQuantityFlag)
			{
				var itemWidth = 195;
				var el = this.element[0].children[0];
				if(menuExpanded == undefined || menuExpanded == null) {
					this.shownItems = parseInt(this.element[0].offsetWidth/itemWidth);
				} else {
					if(menuExpanded) {
						this.shownItems = parseInt((this.element[0].offsetWidth-282)/itemWidth);
					} else {
						this.shownItems = parseInt((this.element[0].offsetWidth+282)/itemWidth);
					}
				}

				for(var i = 0 ; i < el.children.length ; i++){
					el.children[i].style.width = parseInt(100/this.shownItems) + '%';
				}
	//			if(this.shownItems > el.children.length){
	//				this.fixButtons();
	//			}
			}
		}
	}

}