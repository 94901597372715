angular
    .module('dialogControllers.service')
    .controller('addCommentsController', addCommentsController);

addCommentsController.$inject = ['$scope', 'bvDialog', 'localizedMessages'];

function addCommentsController($scope, bvDialog, localizedMessages){
	
	$scope.popupTitle 	= localizedMessages.get('COMMENTS');
	$scope.actionBtnTxt = localizedMessages.get('SAVE');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.commentsPlaceHolder = localizedMessages.get('COMMENTS');	

	var bvModal = $scope.bvModalControllerInputs.bvModal;

	var onSave = $scope.bvModalControllerInputs.onSave;
	$scope.input = {
		comments: "",
		limit: 0
	}
	if($scope.bvModalControllerInputs.comments != undefined)
		$scope.input.comments = $scope.bvModalControllerInputs.comments;

	if($scope.bvModalControllerInputs.limit != undefined)
		$scope.input.limit = $scope.bvModalControllerInputs.limit;

	$scope.action = function() 
	{
		if($scope.input.limit !=0 && 
			$scope.input.comments.length > $scope.input.limit)
			return;

		onSave($scope.input.comments);
		bvModal.close();
	};
}