angular
    .module('dialogControllers.service')
	.controller('renameController',  renameController);

renameController.$inject = ['$scope', 'localizedMessages', 'repositoryService','repositoryModelService','context','bvDialog'];

function renameController($scope, localizedMessages, repositoryService, repositoryModelService,context,bvDialog){

	$scope.popupTitle 	= localizedMessages.get('RENAME');
	$scope.actionBtnTxt = localizedMessages.get('SAVE');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.maxFilenameLength = context.getModel().maxFileUploadFileNameLength;

	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var object = $scope.bvModalControllerInputs.object;
	var actionTrue = $scope.bvModalControllerInputs.actionTrue;
	if(object.boName && object.boName=="DocumentBO"){
		$scope.popupTitle 	= localizedMessages.get('RENAME')+' '+localizedMessages.get('DOCUMENT');
		$scope.documentNameLable = localizedMessages.get('DOCUMENT_NAME_UPPERCASE').toLowerCase();
	}
	$scope.enableActionBtn = ($scope.bvModalControllerInputs.enableActionValidation) ? !$scope.bvModalControllerInputs.enableActionValidation : true;

	$scope.addFolderName = {}
	$scope.addFolderName.val = object.name;
	$scope.addFolderPlaceholder = object.name;
	$scope.parentObjectBaseUuid = object.objectBaseUuid;
	$scope.selection = true;

	$scope.listViewOfFolder = function(parentObjBaseUuid){
		repositoryService.findOriginOfCurrentFolder(parentObjBaseUuid).then(function(response){
			if(response){
				
				var validName = $scope.addFolderName.val;
				var isDocument=false;
		        var parentObjBaseUuid = object.objectBaseUuid;
		        
		        
		        if(!StringUtils.isNullEmpty(object.extensionType))
		    	{
		    		var oldExtension = object.extensionType;
		    		//validName = StringUtils.validDocumentExtension(validName, oldExtension);
		    		/*var nameWithoutExtn = validName.split(".");
		    		validName = nameWithoutExtn[0]+"."+oldExtension;*/
		    		var lastWordIndex = validName.lastIndexOf('.');
		    		var lastWord = validName.substr(lastWordIndex + 1);
		    		if(oldExtension === lastWord){
		    			validName=validName.substr(0, validName.lastIndexOf('.'));
		    		}
		    		validName=validName+"."+oldExtension;
		    	}
				var listOfFolder = response.data;
				//console.log(response, 'folder response');
		        var fileExist = false;
		        var folderExist = false;
		        
		        	for (var j = 0; j < listOfFolder.length; j++) {
		        		if (listOfFolder[j].name.toLowerCase() == validName.toLowerCase()) {
								
								if(parentObjBaseUuid == listOfFolder[j].objectBaseUuid) {
									continue;
								}
								if(listOfFolder[j].boName == "FolderBO")
								{
									folderExist=true;
									break;
								}
								else{
									fileExist=true;
									break;
								}
		    					
		    				}
		    		}
		       
				if(fileExist){
					bvDialog.showMessage(localizedMessages.get("FILE_NAME_EXISTS"));
				}
				else if(folderExist)
					{
					bvDialog.showMessage(localizedMessages.get("FOLDER_NAME_EXISTS"));
					}
				else{
			    	var newName = StringUtils.replaceNotSupportCharWithDash(validName);
			        repositoryService.rename(object.objectBaseUuid, newName).then(function(data)
			        {
			        	//console.log("Renamed Document Name : "+validName);
			        	bvModal.close();
			        	actionTrue(data.data);
			        }); 
				}
			}
			
			
		} );
		
	}
	
	//Rename - save button function.
	$scope.action = function() {
		var validName = $scope.addFolderName.val;
	    if(StringUtils.isNullEmpty(validName))
		 return;
  	$scope.listViewOfFolder($scope.parentObjectBaseUuid);
};
	
/*
 * var reg=/\+|:|"|<|>|>|\?|\//; if(reg.test(validName)) { console.log("Special
 * Characters are not allowed");
 * bvDialog.showMessage(localizedMessages.get("SPECIAL_CHARACTERS_NOT_ALLOWED"));
 * 
 * return; }
 */
 
       
	
	$scope.$watch('addFolderName.val', function(folderName) {
		if(folderName && folderName != '') {
			$scope.enableActionBtn = true;
		} else
		{
			$scope.enableActionBtn = false;
		}
	});
}
