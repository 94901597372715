angular
    .module('dialogControllers.service')
	.controller('ShowMeetingTrackAttendeesController', ShowMeetingTrackAttendeesController);

ShowMeetingTrackAttendeesController.$inject = ['$scope', 'context', 'localizedMessages', 'teamService', 'meetingsService'];

function ShowMeetingTrackAttendeesController($scope, context, localizedMessages, teamService, meetingsService)
{
	$scope.selectedEventId = $scope.bvModalControllerInputs.selectedEvent;
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.guests = [];
	$scope.eventAttendanceList = [];
	$scope.attendanceRefValues = [];
	$scope.popupTitle   = localizedMessages.get('TRACK_ATTENDANCE_UPPERCASE');
	$scope.searchPlaceholder = localizedMessages.get('SEARCH_TO_REFINE');
	$scope.UI_SAVE      = localizedMessages.get('SAVE');
	$scope.UI_CANCEL    = localizedMessages.get('CANCEL');
	var homeController = angular.element(document.querySelector('[home-controler]')).scope();
	$scope.getUserThumbnail = homeController.getUserThumbnail;
	$scope.userImages =  homeController.userImages;

    $scope.getLocalizedAttendanceStatus = function (status) {
        return localizedMessages.get('ATTENDANCE_' + status);
    }

	$scope.getAttendanceStatus = function(objectBaseUuid){
		const user = $scope.eventAttendanceList.find( r => {
			return r.createdBy === objectBaseUuid
		});

		return $scope.getLocalizedAttendanceStatus(user.response);
	}
	
	$scope.getAttendanceStatusChange = function(objectBaseUuid, status, statusText){
		const user = $scope.eventAttendanceList.find( r => {
			return r.createdBy === objectBaseUuid
		});
		user.response = status;
		user.attendanceText = statusText;
		return $scope.eventAttendanceList;
	}
	$scope.saveAttendanceStatus = function(calendarEventBaseUuid, attendanceListObject){
		var attendanceJsonList = JSON.stringify(attendanceListObject);
		meetingsService.setCalendarAttendance(calendarEventBaseUuid, attendanceJsonList).then(
				function(response) {
					if(response.data.status == "success"){
						bvModal.close();
					}
				});
	}
	$scope.cancelChanges = function(){
		bvModal.close();
	}
	$scope.init = function(selectedEventId) 
	{
		meetingsService.loadGuestandResponseForEventWithoutAttachmentsJSON(selectedEventId).
		then(function(response)
		{
			$scope.guests = response.data.guests;
			$scope.eventAttendanceList = response.data.eventAttendanceList;
			$scope.attendanceRefValues = response.data.attendanceRefValues;
		})
	}
	$scope.init($scope.selectedEventId);
}