angular
    .module('dialogControllers.service')
    .controller('signatureDialogController', signatureDialogController);

signatureDialogController.$inject = ['$scope', 'bvDialog', 'localizedMessages', 'surveysService', 'context', '$modal', '$state'];

function signatureDialogController($scope, bvDialog, localizedMessages, surveysService, context, modal, $state){
	
	$scope.popupTitle 	= localizedMessages.get('SIGNATURE');
	$scope.actionBtnTxt = localizedMessages.get('COMMIT');
	$scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
	$scope.commentsPlaceHolder = localizedMessages.get('COMMENTS');	
	$scope.signHere = localizedMessages.get('SIGN_HERE');
	$scope.clear_signature = localizedMessages.get('CLEAR_SIGNATURE');
	$scope.signature = localizedMessages.get('SIGNATURE');

	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var surveyDetails = $scope.bvModalControllerInputs.businessObject;
	$scope.isSigned = false;
	$scope.signature = '';
	$scope.currentUserVO = context.getModel().userVO;
//	var onSave = $scope.bvModalControllerInputs.onSave;
	$scope.input = {
		comments: "",
		limit: 0
	}

	$scope.action = function() 
	{
		if($scope.signature == '' || $scope.signature == undefined){
			bvDialog.showMessage(localizedMessages.get('SIGNATURE_IS_REQUIRED_TO_COMMIT'));
			return;
		}
		surveysService.submitSurveyResponseJSON(
				surveyDetails.objectBaseUuid, $scope.isSigned).then(
				function(data) {
					if(data.boName == "SuccessBO"){
						bvModal.close();
						$state.go('home.surveys');
					}
//					if($scope.$parent.fromOpenItems)
//        			{
//	        			$scope.$parent.fromOpenItems = false;
//	        			$scope.$parent.countItems = $scope.$parent.countItems -1;
//        				$scope.$parent.compileHtml = null;
//        				for(var i=0; i < $scope.$parent.dashboardList.length; i++)
//        				{
//        					if($scope.$parent.dashboardList[i].objectBaseUuid == $scope.surveyDetails.objectBaseUuid)
//        					{
//        						$scope.$parent.dashboardList.splice(i, 1);
//        					}
//        				}
//        				for(var i=0; i < $scope.$parent.surveyList.length; i++)
//        				{
//        					if($scope.$parent.surveyList[i].objectBaseUuid == $scope.surveyDetails.objectBaseUuid)
//        					{
//        						$scope.$parent.surveyList.splice(i, 1);
//        					}
//        				}
//        				
//        			}
				});
	};
    $scope.editSignature = function()
    {
        $scope.selectedUser = $scope.currentUserVO;
        $scope.isLoggedInUserSignatureSet = false;
        $scope.signatureCaller = "surveys";

        var editSignatureModal = new modal.open({
            windowClass : 	'editSignatureDialog',
            templateUrl :  	'modules/team/CreateSignature.tpl.html',
            scope :      	$scope,
            controller : 	'CreateSignatureController',
            backdrop: 'static',
            keyboard: false
        });

        editSignatureModal.result.then(function(resultData){
        	$scope.isLoggedInUserSignatureSet = true;
        }, function(){
        	$scope.isLoggedInUserSignatureSet = true;
        });

        var intervalId = setInterval(function(){
            if($scope.isLoggedInUserSignatureSet == true)
            {
                clearInterval(intervalId);
                $scope.enableActionBtn = false;
                if($scope.loggedInUserSignature != undefined && $scope.loggedInUserSignature != null && $scope.loggedInUserSignature != "")
                {
                	 $scope.signature = $scope.loggedInUserSignature;
                	 $scope.enableActionBtn = true;
                }

                if($scope.loggedInUserInitials != undefined && $scope.loggedInUserInitials != null && $scope.loggedInUserInitials != "")
                {
                    //myInitial = $homeScope.loggedInUserInitials;
                }
                $scope.isSigned = true;
                
//                approvalsService.isApprovalResponseDirty = true;
//                homeService.set_current_module_has_unsaved_changes(true, ctrlName);
            }
        }, 500);

    }
    $scope.clearSignature = function()
    {
        $scope.isSigned = false;
        $scope.signature = null;
        $scope.enableActionBtn = false;
    }
    $scope.signIt = function()
    {
        // get signature
        //var userObjectBaseUuid = JSON.parse(sessionStorage.getItem('currentUserVO')).objectBaseUuid;
        if($scope.isSigned) {

        	if(MobileUtil.isAndroid())
        	{//For Android devices, edit signature is disabled for user who already has a signature.
        	   //So do nothing for android devices
         		return;
            }

            $scope.isSigned = false;
            $scope.editSignature();
            return;
        }
        surveysService.getMySignature()
        .then(function(data)
        {
            $scope.signature = data;
            if($scope.signature == null || $scope.signature.length == 0)
            {
                //	alert("No signature found.");
            	if(MobileUtil.isAndroid())
            	{//For Android,if no signature then confirm to create signature
            		bvDialog.confirm(localizedMessages.get("NO_SIGNATURE_CREATE_SIGNATURE_CONFIRM"), function(){
            			//Cancel this approval and set up user signature
//            			approvalsService.isApprovalResponseDirty = false;
//            	        homeService.set_current_module_has_unsaved_changes(false, ctrlName);
            			$scope.cancel();
    	                Android.setupUserSignature();
            		});

            	}else
                {
            		$scope.editSignature();
                }
                //bvDialog.showMessage(localizedMessages.get(""))
                $scope.isSigned = false;
                return;
            }
            $scope.isSigned = true;
            $scope.enableActionBtn = true;
//            approvalsService.isApprovalResponseDirty = true;
//            homeService.set_current_module_has_unsaved_changes(true, ctrlName);
        });
    }
}