CreateCBEQuestionnaireController.$inject = ['$scope', '$stateParams', 'localizedMessages', 'reactInterface', 'context', 'bvDialog'];

function CreateCBEQuestionnaireController($scope, $stateParams, localizedMessages, reactInterface, context, bvDialog)
{   
    $scope.reactInterface = reactInterface;
    $scope.middleBarObject.showBack = true;
//    $scope.reactInterface.cbeid = $stateParams.surveyBaseUuid;
    $scope.reactInterface.cbeObject = $stateParams.cbeObject;
    $scope.handleLeftIcon = () => {
        const { backButton } = reactInterface.callReact
        backButton()
    }
    $scope.tabs = [{
        title : localizedMessages.get("SURVEY"),
        content : 'openSurveys',
        active: true,
        rightMenuList:[]
    }]
}

angular
    .module('CBEQuestionnaire', ['localization.service', 'react.interface'])
    .controller('CreateCBEQuestionnaireController', CreateCBEQuestionnaireController)