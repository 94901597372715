n3aAuthController.$inject = ['$scope', '$element', '$window', '$timeout', 'bvLoadingSpinner', '$state', '$templateCache'];

function n3aAuthController($scope, $element, $window, $timeout, bvLoadingSpinner, $state, $templateCache){
	var bvModal = $scope.bvModalControllerInputs.bvModal;
    var n3aUserMigrationStatus = $scope.bvModalControllerInputs.n3aUserMigrationStatus;
    var goLanding = $scope.bvModalControllerInputs.callback;
    var errorPage = $templateCache.get('modules/dialogs/n3aauth/n3aAuthError.tpl.html');

    $window.addEventListener('message', function(e) {
        var message = e.data;

        if(message && message == "skip"){
            bvModal.close();
            goLanding();
        }
        
        if(message && message == "logout"){
            bvModal.close();
            $state.go('logout');
        }

        if(message && message.indexOf("navigate") != -1){
            bvModal.close();
            $window.location.href = message.split("navigate:")[1];
        }
    });

    if(typeof goLanding != 'function'){
        goLanding = function(){
            $state.go('home');
        }
    }

    var n3aUrl = n3aUserMigrationStatus.AnnouncementURL;
    $scope.showClose = n3aUserMigrationStatus.CanCloseNotification;

    $scope.bvModalControllerInputs.closeButton = function(){
        goLanding();
        bvModal.close();
    }

    var iframe = document.createElement('iframe');
    iframe.src = n3aUrl;
    iframe.onload = function(){
         $timeout(function(){
    //         var logoutButton = $element.find('.n3a-box iframe')[0].contentWindow.document.querySelector('.link-button');
    //         var upgradeButton = $element.find('.n3a-box iframe')[0].contentWindow.document.querySelector('.button-primary');
    //         var laterButton = $element.find('.n3a-box iframe')[0].contentWindow.document.querySelector('.button-secondary');

    //         if(logoutButton)
    //             logoutButton.onclick = function(event){
    //                 event.preventDefault();
    //                 event.stopPropagation();
    //                 bvModal.close();
    //                 $state.go('login.auth');
    //             }

    //         if(upgradeButton)
    //             upgradeButton.onclick = function(event){
    //                 event.preventDefault();
    //                 event.stopPropagation();
    //                 $window.location.href = 'https://account.nasdaq.com';
    //             }

    //         if(laterButton)
    //             laterButton.onclick = function(event){
    //                 event.preventDefault();
    //                 event.stopPropagation();
    //                 bvModal.close();
    //                 goLanding();
    //             }

             bvLoadingSpinner.complete();
        });
    }

    iframe.onerror = function(){
        $timeout(function(){
            $scope.showClose = true;
            $element.find('.n3a-box').html(errorPage);
        });
    }

    $timeout(function(){
       $element.find('.n3a-box').html(iframe);
    }, 200);

    bvLoadingSpinner.start();

}