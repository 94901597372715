angular
	.module('home',[
        'homeLayoutBuilder',
        'templatesService',
        'calendar.service',
        'team.service',
        'localization.service',
        'model.service',
        'signatures',
        'approvals',
        'messages',
        'newitems',
        'meetings',
        'briefcase',
        'repository',
        'surveys',
        'reports',
        'composeMessage',
        'addToNewItems',
        'ui.bootstrap',
        'ui.sortable',
        'iconRenderer',
        'dragDrop',
        'rightClick',
//        'textAngular',
        'calendar',
        'calendarLight',
        'permission.service',
        'bvpolling.service',
        'polling.service',
        'n3aPolling.service',
        'branding',
        'bvpolling.processor',
        'commonFilters',
        'editProfile',
        'createUser',
        'openitems',
        'createSignature',
        'inviteUser',
        'recycle',
        'search',
        'resource.module',
        'repositoryListing.module',
        'ipad.service',
        'constants.service',
        'common.service',
        'addfolder',
        'repository.service',
        'documentMenu.module',
        'angular.filter',
        'context.service',
        'activeWindows',
        'CreateSurvey',
        'CreateNewSurvey',
        'CBEQuestionnaire',
        'CreateApproval',
        'ngScrollbars',
        'push',
        'createPush',
        'SmartSelection',
        'mergecompany',
        'advancedSearch',
        'discussions',
        'toppanel',
        'adSearchQueryBox',
        'legacyUser',
        'checkbox',
        'trash',
        'taskmanagement',
        'surveys.react',
        'meetings.react',
        'cbe.react',
        'email',
        'directory',
		
		'react.interface',

        'UserProfileController.controller',

        'bvDialogMobile.factory',
        'pushService.factory',

        'attachmentListing.directive',
        'bvFocus.directive',
        'bvFocusTouchFix.directive',
        'bvGrabItem.directive',
        'bvHoverBackground.directive',
        'boardbook.directive',
        'bvItemList.directive',
        'bvScrollList.directive',
        'bvScrollListTable.directive',
        'bvSlider.directive',
        'bvSortingPanel.directive',
        'bvSplitter.directive',
        'bvTooltip.directive',
        'bvUserSelectionList.directive',
        'bvUserSelector.directive',
        'datepick.directive',
        'dialogArrow.directive',
        'disableDoubleClick.directive',
        'inputSelection.directive',
        'isViewerOpen.directive',
        'bvUserWithPhoto.directive',
        'teamSideBar.directive',
        'saveSurveyTemplateFromContextMenu',
        'dashboardNxt',
        'dashboardNxtv2',
        'eventBus.service'
    ])
	.provider('homeInitialize', homeInitializeProvider)
	.config(configHomeController)
	.service('plinkHandler', plinkHandlerService)
	.controller('HomeController', homeController)
	.service('viewerContextMenu', viewerContextMenu)
	.factory('homeService', homeService)
    .factory("fileReader", fileReader)
    .service('homeContext', homeContext)