function loginService($q, ajaxService, responseValidatorService) {
	var loginContext = {}
	var templateUrl = {}
	var loginLogo = {};
	loginLogo.url = "";
	var certStatus = {};
	var enhancedAuthenticationEnabled = false;
	var minJavaRequiredVersion="";

	var securityQuestion = {}

	// Is the current user authenticated?
	var _isAuthenticated = function(user) {
		var authenticated = responseValidatorService.isSuccess(user);
		return authenticated;
	}

	// The public API of the service
	var service = {
		isLoginStaging: true,
		n3aLoginUrl:'',
		securityquestion:'',
	    username:'',

		// Attempt to authenticate a user by the given email and password
		login: function(username, password, symmetricKey, timeStamp, secondTierCookie, secondTierSmsCookie) {
            var self = this;

			var request = ajaxService.login(username, password, symmetricKey,timeStamp, secondTierCookie, secondTierSmsCookie, service.getRedirectUrl()); 			
		    return request.then(function(response) {
		        if ( _isAuthenticated(response.data) ) {
		        	 self.securityquestion = response.data.securityQuestion;
		        	 self.username = response.data.username;
		        	 return response.data;
		        }
		        return response.data;
		    });
		},

		loginSms: function(username, smscode) {
			var request = ajaxService.loginSms(username, smscode, service.getRedirectUrl());
			return request.then(function(response) {
				if(!response || !response.data)
				{
					return null;
				}
		    	return response.data;
			});
		},

		loginStaging: function() {
			var request = ajaxService.getN3aDomainName();
			return request.then(function(response) {
				if(response && response.data.isN3ALoginUrl)
				{
					service.n3aLoginUrl = response.data.loginUrl;
				}else if(!response.data.isN3ALoginUrl)
				{
					service.isLoginStaging = response.data.isN3ALoginUrl;
				}
				return response.data;
			});
		},
		
		forgotUserId: function(lastname, email) {
			var request = ajaxService.forgotUserId(lastname, email, service.getRedirectUrl());
			return request.then(function(response) {
			    if (response && response.data) {
			    	return response.data;
			    }
			    return null;
			});
		},

		forgotPassword: function(id, lastn, emailaddr) {
			var request = ajaxService.forgotPassword(id, lastn, emailaddr, service.getRedirectUrl());
			return request.then(function(response) {
			    if (response && response.data) {
			    	return response.data;
			    }
			    return null;
			});
		},

		checkRedirectSiteURL: function(id) {
			var request = ajaxService.checkRedirectSiteURL(id);
			return request.then(function(response) {
			    if (response && response.data) {
			    	return response.data;
			    }
			    return null;
			});

		},
		getSecurityQuestion: function(uuid, locale) {
			var request = ajaxService.getSecurityQuestion(uuid, locale, service.getRedirectUrl());
			return request.then(function(response) {
			    if ( _isAuthenticated(response.data) ) {
			    	return response.data;
			    }
			    return null;
			});

		},

		getChallengeQuestion: function(plink, locale) {
			var request = ajaxService.getChallengeQuestion(plink, locale);
			return request.then(function(response) {
			    if (response && response.data) {
			    	return response.data;
			    }
			    return null;
			});
		},

		changeUserPassword: function(companyBaseUuid, currentPassword, newPassword) {
			var request = ajaxService.changeUserPassword(companyBaseUuid, currentPassword, newPassword, service.getRedirectUrl());
			return request.then(function(response) {
				if (response && response.data) {
			    	return response.data;
			    }
				return null;
			});
		},

		changeChallengeQuestionsAnswer: function(objectBaseuuid, companyBaseUuid, questionAndAnswerList) {
			var request = ajaxService.changeChallengeQuestionsAnswer(objectBaseuuid,companyBaseUuid, questionAndAnswerList, service.getRedirectUrl());
			return request.then(function(response) {
				if (response && response.data) {
			    	return response.data;
			    }
			    return null;
			});
		},

		loginSecurityQuestion: function(username, securityquestion, securityanswer, rememberSecondTier) {
			var request = ajaxService.loginSecurityQuestion(username,securityquestion, securityanswer, rememberSecondTier, service.getRedirectUrl());
		    return request.then(function(response) {
		        if (response.data && response.data) {
		        	return response.data;
		        }
		        return null;
		    });
		},

		//Third factor authentication using sms
		loginThirdFactoruthentication: function(username, smscode) {
			var request = ajaxService.loginThirdFactoruthentication(username, smscode, service.getRedirectUrl());
		    return request.then(function(response) {
		        if ( _isAuthenticated(response.data) ) {
		        	return response.data;
		        }
		        return null;
		    });
		},

		invalidate: function() {
			var request = ajaxService.invalidate(service.getRedirectUrl());
			return request.then(function(response) {
			    return response.data;
			});
		},

		generateLoginKeyPair: function(username) {
			var request = ajaxService.generateLoginKeyPairForHtmlClient(username, service.getRedirectUrl());
			return request.then(function(response) {
			    return response.data;
			});
		},

		getMyPasswordPreferences: function() {
			var request = ajaxService.getMyPasswordPreferences(service.getRedirectUrl());
			return request.then(function(response) {
			    return response.data;
			});

		},

		loginContext: function(update) {
			if(update)
			{
				for(var i in update)
				{
					for(var j in loginContext)
					{
						if(i == j)
						{
							loginContext[i] = update[i];
						}
					}
					if(loginContext)
					{
						loginContext[i] = update[i];
					}
				}
			}
			return loginContext;
		},

		clearLoginContext: function(){
			loginContext = {};
		},

		templateUrl: function(update) {
			if(update){
				templateUrl.val = update;
			}
			return templateUrl.val;
		},

		getHandShakeToken: function(userBaseUuid, certificate) {
			var request = ajaxService.getHandShakeTokenJSON(userBaseUuid, certificate, service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		setUpCompanyMasterKey: function(companyMasterKey, companyId) {
			var request = ajaxService.setUpCompanyMasterKey(companyMasterKey, companyId, service.getRedirectUrl());
			return request.then(function(response) {
			    	return response;
			});
		},

		uploadCompanyEncryptionKey: function(encryptionCompanyKey, companyId) {
			var request = ajaxService.uploadCompanyEncryptionKey(encryptionCompanyKey, companyId, service.getRedirectUrl());
			return request.then(function(response) {
			    	return response;
			});
		},

		getClientCertificate : function(hostAddress, hostName, operatingSystem)
		{
			var request = ajaxService.getClientCertificate(hostAddress, hostName, operatingSystem);
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		registerClientCertificateInDevice : function(hostAddress, hostName, operatingSystem, code)
		{
			var request = ajaxService.registerClientCertificateInDevice(hostAddress, hostName, operatingSystem, code);
			return request.then(function(response) {
				if(response != null && response.data != null)
				{
					return response.data;
				}
			  	return null;
			});
		},

		verifyCertificate: function(username, objectBaseUuid, certificate, token) {
			var request = ajaxService.verifyCertificateJSON(username, objectBaseUuid, certificate, token, service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		forgotPin : function() {
			var request = ajaxService.forgotPin(service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		setupPin : function(username, pin) {
			var request = ajaxService.setupPin(username, pin, service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		pinLogin : function(username, pin) {
			var request = ajaxService.pinLogin(username, pin, service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		getBvBtDevices : function() {
			var request = ajaxService.getBvBtDevices(service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		getBvBtAuthenticatedUsers : function(peripheralUUID, peripheralName) {
			var request = ajaxService.getBvBtAuthenticatedUsers(peripheralUUID, peripheralName, service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		getSystemAnnouncements : function(langCode) {
			var request = ajaxService.getSystemAnnouncements(langCode, service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},
		checkLockDeviceCode : function(userBaseUuid, code) {
			var request = ajaxService.checkLockDeviceCode(userBaseUuid, code);
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return null;
			});
		},

		isShowRememberMeForFirstTier : function() {
			var request = ajaxService.isShowRememberMeForFirstTier(service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return false;
			});
		},
		getCompanyLogoByShortName : function() {
			var request = ajaxService.getCompanyLogoByShortName(service.getRedirectUrl());
			request.then(function(response){
				if(responseValidatorService.isSuccess(response.data))
				{
					if(response.data)
					{
						loginLogo.url = "data:image/png;base64," + response.data;
					}
					else
					{
						loginLogo.url = "";
					}
				}
			});
			return loginLogo;
		},
		getConfigValueForSupportInfo:function() {
			var request = ajaxService.getConfigValueForSupportInfo(service.getRedirectUrl());
			return request.then(function(response) {
				if(responseValidatorService.isSuccess(response.data))
				{
			    	return response.data;
				}
			  	return false;
			});
		},

		resetAllAppData : function(){
			var request = ajaxService.resetAllAppDataJSON();
			return request.then(function(response){
				if(response) {
					return response.data;
				}
				return null;
			});
		},

		getAppSupportInfo: function() {
			var request = ajaxService.getAppSuportJSON();
			return request.then(function(data) {
				if (data) {
					return data.data;
				}
				return null;
			});
		},

		getQuestions: function(){
			return securityQuestion;
		},

		setQuestions: function(data){
			securityQuestion.list = data;
			securityQuestion.selectedQuestions = angular.copy(data);

		},

		validateClientCertificateService : function() {
			var certificate = '';
			var request = ajaxService.verifyCertificate(service.getRedirectUrl());
			return request.then(function(response) {
				if(!response || !response.data)
					return null;
				else
					return response;
			});
		},

		getRedirectUrl: function()
		{
			var url = (service.loginContext().redirectUrl ? service.loginContext().redirectUrl : "");
			return url;
		},

//		sendSecondFactorAuthCode : function(mode)
//		{
//			var request = ajaxService.sendSecondFactorAuthCode(mode);
//			return request.then(function(data) {
//				if (data) {
//					return data.data;
//				}
//				return null;
//			});
//		},
//
//		isSecondFactorCookieSetAfterVerification : function(authCode)
//		{
//			var request = ajaxService.isSecondFactorCookieSetAfterVerification(authCode);
//			return request.then(function(data) {
//				if (data) {
//					return data.data;
//				}
//				return null;
//			});
//		},
		validateBrowserSupportForHtmlClient : function()
		{
			var request = ajaxService.validateBrowserSupportForHtmlClient(service.getRedirectUrl());
			return request.then(function(data) {
				if (data) {
					return data.data;
				}
				return null;
			});
		},
		validateBrowserTypeAndVersionNumber : function()
		{
			var request = ajaxService.validateBrowserTypeAndVersionNumber(service.getRedirectUrl());
			return request.then(function(response) {
				if (response && response.data) {
					return response.data;
				}
				return null;
			});
		},
		validateIsDesktopClientCertificateInstalled : function()
		{
			var request = ajaxService.validateIsDesktopClientCertificateInstalled();
			return request.then(function(data) {
				if (data) {
					return data.data;
				}
				return null;
			});
		},
		getBriefcasePurgedDesktop: function(){
			var request = ajaxService.getBriefcasePurgedDesktop();
			return request;
		},
		setCertStatus: function(name,val){
			certStatus.name = service.loginContext().userFullName;
			certStatus.val = false;
			if(name){
				certStatus.name = name;
				certStatus.val = val;
			}
		},

		getCertStatus: function() {
			return certStatus;
		},

		setEnhancedAuthenticationEnabled: function(val) {
			if(val=="true")
			{
				enhancedAuthenticationEnabled = val;
			}
		},

		getEnhancedAuthenticationEnabled: function()
		{
			return enhancedAuthenticationEnabled;
		},

		//minJavaRequiredVersion
		setMinJavaRequiredVersion: function(val) {
			if(val != "")
			{
				minJavaRequiredVersion = val;
			}
		},

		getMinJavaRequiredVersion: function()
		{
			return minJavaRequiredVersion;
		},
		
		sxGSTJSON: function(pinValue)
		{
			var request = ajaxService.sxGSTJSON(pinValue);
			return request.then(function(data) {
				if (data) {
					return data.data;
				}
				return null;
			});
		},

		getConfigurableQuestionsCount: function(userBaseUuid)
		{
		    var request = ajaxService.getConfigurableQuestionsCount(userBaseUuid);
		    return request.then(function(data) {
		        return data.data;
		    })
		    return null;
		}

	};
	return service;
}

function loginContextService(){
	this.announcementsHelper = {}
}

angular.module('login.service',['ajax.service'])
	.factory('loginService',  [ '$q', 'ajaxService', 'responseValidatorService', loginService])
	.service('loginContextService', loginContextService)
