BVPolling.$inject = ['$timeout','context', 'pollingService','bvPollingProcessor','$rootScope','sessionTimeoutService','sessionService', '$state', '$window'];

function BVPolling($timeout, context, pollingService, bvPollingProcessor,$rootScope, sessionTimeoutService, sessionService, $state, $window)
{
	var lastSetOfPollingMessages = [];
	var log = [];
	var accessFlag = false;
	var bvPollingStarted = false;
	var sessionIdle = false;
	var userUnauthorized = false;
	var sessionPollingUserUnauthorizedErrorCounter = 0;
	var sessionPollingDelay=1000; //default
	
	var idleStartTime=0;
	var pauseIdleCheck=false;
	
	this.startIdleCounting = function() {
		idleStartTime=new Date().getTime();
	}
	
	this.stopIdleCounting = function() {
		idleStartTime = 0;
	}
	
	var setPauseIdleCheck = function(bool) {
		pauseIdleCheck = bool;
	}
	
	$rootScope.online = navigator.onLine;
    $window.addEventListener("offline", function() {
      $rootScope.$apply(function() {
    	  console.log("GOING OFFLINE");
    	  stop();
    	  $rootScope.online = false;
      });
    }, false);

    $window.addEventListener("online", function() {
      $rootScope.$apply(function() {
    	  console.log("GOING ONLINE");
    	//starting this after 65s if no service is already started/running.Note that we are making polling freq to once in 65 secs if found Idle
    	  $timeout(checkAndStart, 65000); 
      });
    }, false);

	this.init = function()
	{
		accessFlag = false;
	    bvPollingStarted = false;
		sessionIdle = false;
	}

	this.start = function()
	{
		accessFlag = true;
		sessionIdle = false;
		// start watching when the app runs. also starts the $keepalive service by default.
		sessionTimeoutService.start();
		if(!bvPollingStarted)
		{
			getPollingMessage();
			bvPollingStarted = true;
		}
    }

    this.stopPolling = function()
    {
    	if(bvPollingStarted)
    	{
    		sessionIdle = true;
    		stop();
    	}
    }

    this.isPollingStarted = function()
    {
    	return bvPollingStarted;
    }

    var stop = function()
	{
		accessFlag = false;
		bvPollingStarted = false;
	}

	this.restart = function()
	{
		//this.init();
		accessFlag = false;
	    sessionIdle = false;
		this.start();
	}

	var checkAndStart = function()
	{
		console.log("Restarting the polling:bvPollingStarted::"+bvPollingStarted);
		accessFlag = true;
		sessionIdle = false;
		sessionTimeoutService.start();
		if(!bvPollingStarted)
		{
			getPollingMessage();
		}
	}
	
	var getIdleSecs = function() { //get Idle secs
		if(idleStartTime!=0) {
			return (new Date().getTime() - idleStartTime)/1000;
		} else {
			return idleStartTime;
		}
		
	}

	this.getIdleSecs = function() {
		return getIdleSecs();
	}

	//Restart the Idle in exceptional cases like, document viewer mouse scroll - MEETX-39577
	this.restartIdle = function() {
		accessFlag = true;
		sessionIdle = false;
		idleStartTime = 0;

		sessionTimeoutService.restartIdle();
		if(!bvPollingStarted)
		{
			getPollingMessage();
			bvPollingStarted = true;
		}
	}

    var getPollingMessage = function()
    {
    		//console.log("Polling...");
	    	var lastSetOfPollingJSON = angular.isUndefined(angular.toJson(lastSetOfPollingMessages)) ? "" : angular.toJson(lastSetOfPollingMessages)
//	    	if(!Idle.running())
//	    	{
//	    		Idle.setIdle(28);
//	    		Idle.watch();
//	    	}
	        
	    	//console.log("getPollingMessage accessFlag " + accessFlag);
	    	if((getIdleSecs()>600 && !pauseIdleCheck) && !MobileUtil.isApp()) {
	    		//Delay the session polling when IdleSecs is more than 600. Mobile Apps are excluded
	    		sessionPollingDelay=60000; //During Idle time & pauseIdleCheck=false increasing session polling to 1 min
	    	} else {
	    		sessionPollingDelay=1000; //restoring the delay
		    }
		    accessFlag = !sessionTimeoutService.isIdle();
		    	
	    	bvPollingStarted = true;
	    	pollingService.getSessionPollingMessages(encodeURIComponent(lastSetOfPollingJSON), accessFlag, encodeURIComponent(log)).then(function(data)
	    	{
	    		try
	        	{
		    		var result = data.data;

		    		if(!result || result === undefined || result.boName === "FailureBO")
		    		{
		    			lastSetOfPollingMessages = [];
		    			accessFlag = true;
		    			if(result.exceptionCode == 19)
		    			{
		    				if(!sessionTimeoutService.isIdle())
		    				{
		    					sessionService.sessionKeepAlive().then(function()
		    					{
		    						checkAndStart();
		    			        });
		    				} else {
		    					sessionIdle = true;
			    				$rootScope.$emit('PreemptLogoutWarn');
		    				}

		    			}
		    			else if(result.exceptionCode == 20){
                            $state.go('logout');
						}
						else if(result.exceptionCode == 0 && result.message === "Not an authorized user") { // incase of error stop the session polling call
							userUnauthorized = true;
							return;
						}
		    		}
		    		else
		    		{
						sessionPollingUserUnauthorizedErrorCounter = 0;
		    			lastSetOfPollingMessages = result;
		    			if(lastSetOfPollingMessages.length > 0)
						{
		    				bvPollingProcessor.setIdleCheckCallback(setPauseIdleCheck);
		    				bvPollingProcessor.processPollingList(lastSetOfPollingMessages);
						}
		    			accessFlag = false;
		    		}
				}
	        	catch(err)
		    	{
		    		console.log("POLLING-ERROR:"+err);
		    		// message.innerHTML = "Input " + err;
		    	}
		    	finally
		    	{
					if (!userUnauthorized) {
						startPolling();
					} else {
						/**
						 * In case of an error like CSRF prevention or invalid token, the polling from browser happens once every second, inundating the servers.
						 * when the error response from api is unauthorized user error, retry 10 times, each time increasing the interval by 5s and check the same response is received then logout the user.
						 */
						if (sessionPollingUserUnauthorizedErrorCounter <= 10) {
							sessionPollingUserUnauthorizedErrorCounter = sessionPollingUserUnauthorizedErrorCounter + 1;
							$timeout(startPolling, sessionPollingUserUnauthorizedErrorCounter * 5000);
						} else {
							$state.go('logout');
						}
					}
		    		
		    	}
	    });
	    
    }

    var startPolling = function()
    {
    	if(context && context.isReady() && !sessionIdle) {
    		$timeout(getPollingMessage, sessionPollingDelay);
    	} else {
    		stop();
    	}

    }
}
