function ngTap(){
	return {
		restrict: 'A',
		controller: function($scope, $element, $attrs, $parse, $state) {
		    var tapped;
		    tapped = false;

		    var settingsFeedback = {
		    	type : $attrs.feedbackType || null,
		    	cssClass: $attrs.feedbackClass || 'bv-button-feedback',
		    	transition: $attrs.feedbackTime || 350,
		    }

  		  	var feedbackElement = document.createElement('feedback');
		    feedbackElement.classList.add(settingsFeedback.cssClass);
	  		if(settingsFeedback.type == 1){
	  			feedbackElement.classList.add(settingsFeedback.cssClass + '-type-1')
	  		}

  		  	if(settingsFeedback.type == 2){
  		  		feedbackElement.classList.add(settingsFeedback.cssClass + '-type-2')

	  		  	var style = window.getComputedStyle($element[0]);
	  		  	var width = parseInt(style.getPropertyValue('width'));

	  		  	var margin = function(){
	  		  		var arr = [];
	  		  		style.getPropertyValue('margin').split(' ').forEach(function(v){
	  		  			arr.push(parseInt(v));
	  		  		})
	  		  		return arr;
	  		  	}();

	  		  	$element[0].style.position = 'relative';
	  		  	$element[0].style.overflow = 'hidden';
	  		  	feedbackElement.style.height = width * 2 +'px';
	  		  	feedbackElement.style.width = width * 2 +'px';

  		  	}

  			if(settingsFeedback.type == 3){
  		  		feedbackElement.classList.add(settingsFeedback.cssClass + '-type-3')

  		  		var style = window.getComputedStyle($element[0]);
	  		  	var width = parseInt(style.getPropertyValue('width'));

	  		  	var margin = function(){
	  		  		var arr = [];
	  		  		style.getPropertyValue('margin').split(' ').forEach(function(v){
	  		  			arr.push(parseInt(v));
	  		  		})
	  		  		return arr;
	  		  	}();

	  		  	$element[0].style.position = 'relative';
	  		  	$element[0].style.overflow = 'hidden';
	  		  	feedbackElement.style.height = width +'px';
	  		  	feedbackElement.style.width = width +'px';
	  		    feedbackElement.style.top = '-' + (width / 2 - $element[0].offsetHeight / 2) +'px';
  			}

  		  	if(settingsFeedback.type != null && MobileUtil.isAndroid() || MobileUtil.isIPhone()){
  		  		$element.append(feedbackElement);
  		  	}

		    if($element[0])
		    {
		    	var eleClasses = $element[0].getAttribute("class");
//				    	if(eleClasses && eleClasses.indexOf("bv-tapped-btn")<0)
//				    		$element[0].className = eleClasses + ' bv-tapped-btn';
		    }

		    $element.bind("click", function(event) {
		      if (!tapped) {
	    		 $scope.$apply(function () {
			        	$parse($attrs.ngTap)($scope, {$event: event});
	              });
		      }
		    });
		    $element.bind("touchstart", function(event) {
		      //tapped = true;
		      //return $scope.$apply($attrs["ngTap"]);
		    	//if(!$element.children('.bv-button-feedback').hasClass('bv-button-feedback-tapped')){
		    	//	console.log('aa')
		    	//	$element.children('.bv-button-feedback').removeClass('bv-button-feedback-tapped-done');

		    	//}
		      tapped = true;
		    });
		    $element.bind("touchmove", function(event) {
		      tapped = false;
		      event.stopImmediatePropagation();
		    });
		    $element.bind("touchend", function(event) {

		      if (tapped && StringUtils.getBrowser() != 'MSIE') {

		    		if(settingsFeedback.type == 2){
				    	  var offsetTop = event.currentTarget.getBoundingClientRect().top;
				    	  var offsetLeft = event.currentTarget.getBoundingClientRect().left;
				    	  var clientY = event.originalEvent.changedTouches[0].clientY;
				    	  var clientX = event.originalEvent.changedTouches[0].clientX;

				    	  feedbackElement.style.top = (clientY - offsetTop - width) + 'px';
				    	  feedbackElement.style.left = (clientX - offsetLeft - width) + 'px';
		    		}

	    		 setTimeout(function(){
	    			 feedbackElement.style.transition = settingsFeedback.transition + 'ms all';
			    	  if(feedbackElement.className.indexOf(settingsFeedback.cssClass + '-tapped') != -1){
			    		  feedbackElement.classList.remove(settingsFeedback.cssClass + '-tapped');
			    	  }
			    	  if(feedbackElement.className.indexOf(settingsFeedback.cssClass + '-tapped-done') != -1){
			    		  feedbackElement.classList.remove(settingsFeedback.cssClass + '-tapped-done');
			    	  }
			    	  feedbackElement.classList.add(settingsFeedback.cssClass + '-tapped');

			    	  feedbackElement.addEventListener("transitionend", function(){
			    		  feedbackElement.style.transition = null;
			    		  //feedbackElement.classList.add(settingsFeedback.cssClass + '-tapped-done');
			    		  feedbackElement.classList.remove(settingsFeedback.cssClass + '-tapped');
			    		  setTimeout(function(){
			    			  feedbackElement.classList.remove(settingsFeedback.cssClass + '-tapped-done');
			    			  feedbackElement.style.transition = null;
			    		  },0)

			    	  })
	    		 },0);



		    	   setTimeout(function(){
		    		   $scope.$apply(function () {
				        	$parse($attrs.ngTap)($scope, {$event: event});
		    		   });

		    		   if($attrs.uiSref){
		    			   $state.go($attrs.uiSref);
		    		   }
		    		   else if($attrs.href){
			    		   window.location = event.currentTarget.getAttribute('href');
			    	   }
		    	   },20)

		      }
		    });
		}
	}
}
