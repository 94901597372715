modelService.$inject = ['localizedMessages'];

function modelService(localizedMessages)
{

	//var UI_ATTENDEES_TXT  		= 	localizedMessages.get('ATTENDEES');
	//var UI_ATTACHMENTS_TXT  	= 	localizedMessages.get('ATTACHMENTS_TITLE');
	var UI_ATTENDEES_TXT  				= 	localizedMessages.get("USERS");
	var UI_ATTACHMENTS_TXT  			= 	localizedMessages.get('MATERIALS');

	var UI_EDIT_BTN_TXT   				=	localizedMessages.get('EDIT');
	var UI_DELETE_BTN_TXT 				= 	localizedMessages.get('DELETE');
	var UI_START_BTN_TXT 				= 	localizedMessages.get('PRESENT');
	var UI_STOP_BTN_TXT 				= 	localizedMessages.get('STOP_PRESENTATION');
	var UI_JOIN_BTN_TXT 				= 	localizedMessages.get('JOIN_PRESENTATION');
	var UI_LEAVE_BTN_TXT 				= 	localizedMessages.get('LEAVE_PRESENTATION');
	var UI_AGENDA_TXT 					= 	localizedMessages.get('AGENDA');
	var UI_MAKE_PRESENTER_TXT			=   localizedMessages.get('MAKE_PRESENTER');

	var UI_FROM_TXT						=   localizedMessages.get('FROM');
	var UI_TO_TXT						=   localizedMessages.get('TO');
	var UI_LOCATION_TXT					=   localizedMessages.get('LOCATION');
	var UI_DIAL_CODE_TXT				=   localizedMessages.get('DIAL_CODE');
	var UI_YOUR_RESPONSE				=   localizedMessages.get('YOUR_RESPONSE');
	var UI_RESPONSE_SUBMIT_TXT  		=   localizedMessages.get('SUBMIT');

	var UI_TOOLTIP_DECLINED_TXT  		=   localizedMessages.get('DECLINED');
	var UI_TOOLTIP_ACCEPTED_TXT  		=   localizedMessages.get('ACCEPTED');
	var UI_TOOLTIP_TENTATIVE_TXT  		=   localizedMessages.get('TENTATIVE');
	var UI_TOOLTIP_NOT_RESPONDED_TXT  	=   localizedMessages.get('NOT_RESPONDED');


	var service = {

		getInitialConferenceObject : function() {
			var conference = {
				//'CONF':
				//	{
						'CONF_CONTAINS_AGENDA'                		: false,
						'CONF_CONTAINS_ATTACHMENT'                	: false,
						'CONF_IS_CONF_IN_PROGRESS'            		: false,
						'CONF_IS_CONF_HOST'							: false,
						'CONF_IS_CONF_PRESENTER'					: false,
						'CONF_UI_AGENDA_TXT'               			: UI_AGENDA_TXT,
						'CONF_UI_ATTENDEES_TXT'               		: UI_ATTENDEES_TXT,
						'CONF_UI_ATTACHMENTS_TXT'               	: UI_ATTACHMENTS_TXT,
						'CONF_UI_EDIT_BTN_TXT'                   	: UI_EDIT_BTN_TXT,
						'CONF_UI_DELETE_BTN_TXT'					: UI_DELETE_BTN_TXT,
						'CONF_UI_MAKE_PRESENTER_BTN_TXT'			: UI_MAKE_PRESENTER_TXT,
						'CONF_UI_PRESENT_BTN_ACTION_TXT'			: "",
						'CONF_UI_PRESENT_EXIT_BTN_ACTION_TXT'      	: "",
						'CONF_UI_START_BTN_TXT'                   	: UI_START_BTN_TXT,
						'CONF_UI_JOIN_BTN_TXT'						: UI_JOIN_BTN_TXT,
						'CONF_UI_STOP_BTN_TXT'                   	: UI_STOP_BTN_TXT,
						'CONF_UI_LEAVE_BTN_TXT'						: UI_LEAVE_BTN_TXT,
						'CONF_UI_FROM_TXT'							: UI_FROM_TXT,
						'CONF_UI_TO_TXT'							: UI_TO_TXT,
						'CONF_UI_LOCATION_TXT'						: UI_LOCATION_TXT,
						'CONF_UI_DIAL_CODE_TXT'						: UI_DIAL_CODE_TXT,
						'CONF_UI_YOUR_RESPONSE'						: UI_YOUR_RESPONSE,
						'CONF_UI_RESPONSE_SUBMIT_TXT'				: UI_RESPONSE_SUBMIT_TXT,
						'CONF_UI_TOOLTIP_DECLINED_TXT'				: UI_TOOLTIP_DECLINED_TXT,
						'CONF_UI_TOOLTIP_ACCEPTED_TXT'				: UI_TOOLTIP_ACCEPTED_TXT,
						'CONF_UI_TOOLTIP_TENTATIVE_TXT'				: UI_TOOLTIP_TENTATIVE_TXT,
						'CONF_UI_TOOLTIP_NOT_RESPONDED_TXT'			: UI_TOOLTIP_NOT_RESPONDED_TXT,
						'CONF_ATTACHMENTS'							: {},
						'CONF_GUESTS'								: {},
						'CONF_EVENT'								: {},
						'CONF_PARTICIPANTS'							: {},
						'CONF_SYNC_KEY_VAL'							: {},
						'CONF_CURRENT_DOC_BASE_ID'					: "",
						'CONF_CURRENT_DOC_VERSION_ID'				: "",
						'CONF_CURRENT_DOC_PDF_URL'					: "",

					//}
			};
			return conference;
		},

		getMeetingCenterObject : function() {
			var meetingCenterObject =
			{
					meetingTitle 				: '',
					startingDate 				: '',
					startingTime				: '',
					endingTime					: '',
					meetingCallInNumber			: '',
					meetingDetails				: '',
					meetingAddress				: '',
					attendeesList				: [],
					attachmentsList				: [],
					editFlag					: false,
					meetingObjectBaseUuid		: '',
					isActive					: false,
					location					: '',
					codeNumber					: '',
					allDay						: false
			};
			return meetingCenterObject;
		},

		getObjectPushObject : function() {
			var objectPush =
			{
					pushId						: '',
					objectBaseUuid				: '',
					objectVersionUuid			: '',
					expiryDate					: '',
					expiryTime					: '',
					expiryDayTime				: '',
					comments					: '',
					selectedUsers				: [],
					action						: '',
			};
			return objectPush;
		}


	}
	return service;
}