companyUserService.$inject = [ 'ajaxService', 'context','responseValidatorService','utilsService'];

function companyUserService(ajaxService, context, responseValidatorService, utilsService)
{
	var _companyTreeData = null;
	var _currentPortalUserData = null;
	var _allCompanyUsers = null;

	var getPortalUsersData = function() {
		var portalGrpUsrData = [];
		if(_companyTreeData)
		{
			var teamspaceId = context.getModel().portalVO.objectBaseUuid;
			if(_companyTreeData[0] && _companyTreeData[0].children)
			{
				angular.forEach(_companyTreeData[0].children, function(treenode)
				{
				   	if(treenode.objectBaseUuid === teamspaceId)
				   	{
				   		var parentNode = {};
				   		parentNode.name = treenode.name;
				   		parentNode.objectBaseUuid = treenode.objectBaseUuid;
				   		parentNode.type =  treenode.type;
				   		parentNode.custom = treenode.customData;
		        		portalGrpUsrData.push(parentNode);
		        		var nodes = treenode.children;
		        		nodes.forEach(function(nodeEle)
				  		{
		        			var childNode = {};
		        			childNode.name = nodeEle.name;
		        			childNode.objectBaseUuid = nodeEle.objectBaseUuid;
		        			childNode.type =  nodeEle.type;
		        			childNode.custom = nodeEle.customData;
				  			portalGrpUsrData.push(childNode);
				  		});
				    };
				});
			}
		}
		_currentPortalUserData = portalGrpUsrData;
		return portalGrpUsrData;
	}

	var getAllUsersInGroup = function(groupId){
		var portalUsers = [];
		var getNodes = function(tsTreeNode) {
   			var tsTreeNodeChilds = tsTreeNode.children;
   			if(tsTreeNodeChilds)
   			{
	    		tsTreeNodeChilds.forEach(function(nodeEle)
		  		{
		  			if(nodeEle.type === "User")
		  			{
		  				var isAdd = true;
		  				angular.forEach(portalUsers, function(portalUser) {
				   			if(portalUser.objectBaseUuid === nodeEle.objectBaseUuid)
				   			{
				   				isAdd = false;
				   				return;
				    		}
						});
		  				if(isAdd)
		  				{
		  					portalUsers.push({name:nodeEle.name, objectBaseUuid:nodeEle.objectBaseUuid, type:nodeEle.type});
		  				}
		  			}
		  			else if(nodeEle.children && nodeEle.children.length > 0)
		  			{
		  				getNodes(nodeEle);
		  			}
		  		});
	    	}
   		}

		if(_companyTreeData)
		{
			if(_companyTreeData[0] && _companyTreeData[0].children)
			{
				angular.forEach(_companyTreeData[0].children, function(treenode)
				{
				   	if(treenode.objectBaseUuid === groupId)
				   	{
				   		getNodes(treenode);
				   		return portalUsers;
				    }
				    else
				    {
				    	angular.forEach(treenode.children, function (treenodeChild) {
			    			if(treenodeChild.objectBaseUuid === groupId)
						   	{
						   		getNodes(treenodeChild);
						   		return portalUsers;
						    }
				    	});
				    }
				});
			}
		}
		return portalUsers;
	}

	var service = {

		initCompanyTree: function() {
			if(_companyTreeData)
				return;
			var request = ajaxService.getCompanyTree();
			return request.then(function(response) {
				if (response) {
					var treeData = response.data.COMPANY_TREE;
					var treeDataForFilter = [];
					treeDataForFilter[0] = angular.copy(treeData);
					_companyTreeData = treeDataForFilter;
					_currentPortalUserData = getPortalUsersData();
					_allCompanyUsers = response.data.USER_LIST;
					utilsService.setCompanyTree(response);
					return _companyTreeData;
				}
				return null;
			});
		},

		getCompanyTree: function() {
			this.injectParentsLists();
			return _companyTreeData;
		},
		getPortalGroupsAndUsers : function() {
			if(!_currentPortalUserData)
			{
				_currentPortalUserData = getPortalUsersData();
			}
			return _currentPortalUserData;
		},
		getPortalGroups : function() {
		    return _.filter(this.getPortalGroupsAndUsers(), function(item){
		        return StringUtils.isTeamSpaceGroup(item.objectBaseUuid) || StringUtils.isPersonalTeamSpace(item.objectBaseUuid) || StringUtils.isTeamSpace(item.objectBaseUuid) || StringUtils.isCompanyGroup(item.objectBaseUuid);
		    });
		},
		getGroupUsers : function(groupId)
		{
			return getAllUsersInGroup(groupId);
		},
		getAllCompanyUsers : function() {
			if(!_allCompanyUsers)
			{
				getPortalUsersData();
			}
			return _allCompanyUsers;
		},
		clearAll : function() {
			_companyTreeData = null;
			_currentPortalUserData = null;
			_allCompanyUsers = null;
		},
		clearCurrent : function() {
			_currentPortalUserData = null;
		},
		injectParentsLists: function(){

			function getChildrens(list, parentObject){
				_.forEach(list, function(item){
					parentObject ? item.parent = parentObject : null;
					if(!_.isEmpty(item.children)){
						getChildrens(item.children, item);
					}
				});
			}

			getChildrens(_companyTreeData);
		}
	}
	return service;
}