function configSurveysController($stateProvider, $locationProvider){
}


SurveysController.$inject = ['$scope','$rootScope' ,'$state', '$stateParams', 'surveysService', 'localizedMessages', 'context', '$modal', '$modalStack', 'bvDialog','bvDialogService', 'teamService', 'commonService', 'contextMenuModel', 'surveysAlertService', '$filter','SortUtility','homeService', 'legacyUserService', 'previewSurveyService', 'saveSurveyTemplateService', 'saveSurveyTemplateFromContextMenuService'];
function SurveysController($scope,$rootScope, $state, $stateParams, surveysService, localizedMessages, context,$modal, $modalStack, bvDialog, bvDialogService,teamService, commonService, contextMenuModel, surveysAlertService, $filter,SortUtility,homeService, legacyUserService, previewSurveyService, saveSurveyTemplateService, saveSurveyTemplateFromContextMenuService) {
$scope.ui = {
        label : {
        	edit : localizedMessages.get('EDIT'),
        	start : localizedMessages.get('START'),
        	complete : localizedMessages.get('COMPLETE'),
        	resume : localizedMessages.get('RESUME'),
        	review : localizedMessages.get('REVIEW'),
        	view : localizedMessages.get('VIEW'),
        	closed : localizedMessages.get('CLOSED'),
        	closedUppercase : localizedMessages.get('CLOSED_UPPERCASE'),
        	dueOn : localizedMessages.get('DUE_ON'),
        	response_type : localizedMessages.get('RESPONSE_TYPE'),
        	priority : localizedMessages.get('PRIORITY'),
        	portal : localizedMessages.get('PORTAL'),
        	open : localizedMessages.get('OPEN'),
        	openUppercase : localizedMessages.get('OPEN_UPPERCASE'),
        	send_alert : localizedMessages.get('SEND_ALERT'),
        	reopen : localizedMessages.get('REOPEN'),
        	copy: localizedMessages.get('COPY'),
        	saveAsTemplate: localizedMessages.get('SAVE_AS_TEMPLATE'),
        	preFillSurvey: localizedMessages.get('PRE_FILL_SURVEY'),
        	template: localizedMessages.get('TEMPLATE'),
        	preview : localizedMessages.get('PREVIEW'),
        	report : localizedMessages.get('REPORT'),
        	add : localizedMessages.get('ADD'),
        	move_survey_to_closed_category : localizedMessages.get('MOVE_SURVEY_TO_CLOSED_CATEGORY'),
        	participants : localizedMessages.get('PARTICIPANTS'),
        	date: localizedMessages.get('DATE'),
        	anonymous : localizedMessages.get('ANONYMOUS'),
        	named_responses : localizedMessages.get('NAMED_RESPONSES'),
        	survey_description : localizedMessages.get('SURVEY_DESCRIPTION'),
        	remove_participant : localizedMessages.get('REMOVE_PARTICIPANT'),
        	lock_user : localizedMessages.get('LOCK_USER'),
        	unlock_user : localizedMessages.get('UNLOCK_USER'),
        	delete_survey : localizedMessages.get('DELETE_SURVEY'),
        	ok : localizedMessages.get('OK'),
        	are_you_sure_you_want_to_remove_participant : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_PARTICIPANT'),
        	are_you_sure_you_want_to_lock_user : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_LOCK_USER'),
        	are_you_sure_you_want_to_unlock_user : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_UNLOCK_USER'),
        	are_you_sure_you_want_to_delete_survey : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_DELETE_SURVEY'),
        	response : localizedMessages.get('RESPONSE'),
        	survey : localizedMessages.get('SURVEY'),
        	new_survey: localizedMessages.get('NEW_SURVEY'),
        	open_surveys : localizedMessages.get('OPEN_SURVEYS'),
        	created_by_me : localizedMessages.get('CREATED_BY_ME'),
        	owned_by_me : localizedMessages.get('OWNED_BY_ME'),
        	own : localizedMessages.get('OWN'),
        	ownUppercase : localizedMessages.get('OWN_UPPERCASE'),
        	closed_surveys : localizedMessages.get('CLOSED_SURVEYS'),
        	deleteString : localizedMessages.get('DELETE_STRING'),
        	name : localizedMessages.get('NAME'),
        	status : localizedMessages.get('STATUS'),
        	progress : localizedMessages.get('PROGRESS'),
        	lock_all : localizedMessages.get('LOCK_ALL'),
        	multiple_choice : localizedMessages.get('MULTIPLE_CHOICE'),
        	open_answer : localizedMessages.get('OPEN_ANSWER'),
        	likert_scale : localizedMessages.get('LIKERT_SCALE'),
        	true_false : localizedMessages.get('TRUE_FALSE'),
        	meets_does_not_meet : localizedMessages.get('MEETS_DOES_NOT_MEET'),
        	multiple_choice_multi_response : localizedMessages.get('MULTIPLE_CHOICE_MULTI_RESPONSE'),
        	yes_no : localizedMessages.get('YES_NO'),
        	are_you_sure_you_want_to_move_survey_to_closed_category : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_MOVE_SURVEY_TO_CLOSED_CATEGORY'),
        	are_you_sure_you_want_to_remove_survey_from_closed_category : localizedMessages.get('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SURVEY_FROM_CLOSED_CATEGORY'),
        	close_survey : localizedMessages.get('CLOSE_SURVEY'),
        	remove_from_close_survey : localizedMessages.get('REMOVE_FROM_CLOSED_CATEGORY'),
        	options : localizedMessages.get('OPTIONS'),
        	push : localizedMessages.get('PUSH'),
        	overview : localizedMessages.get('OVERVIEW'),
        	respond : localizedMessages.get('RESPOND'),
        	manageSurveys : localizedMessages.get('MANAGE_SURVEYS'),
            surveyStatusNotStarted : localizedMessages.get('NOT_STARTED'),
        	surveyStatusInProgress : localizedMessages.get('IN_PROGRESS'),
			surveyStatusCompleted  : localizedMessages.get('COMPLETED'),
			surveyStatusSubmitted : localizedMessages.get('SUBMITTED'),
			surveyStatusNotSubmitted : localizedMessages.get('NOT_SUBMITTED'),
        	all  : localizedMessages.get('ALL'),
        	CBELabel :localizedMessages.get('ENGAUGE'),
        }
    };
$scope.$parent.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
$scope.$parent.currentState.value = $state.$current.name;
$scope.getShortLocalizedDate = function(date) {
		return date != undefined ? moment($filter('date')(date, 'medium')).format('ll') : "";
}

$scope.$parent.inMobileSurveys = true;

$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';
$scope.startIcon 		='resources/assets/svgs/survey_complete_icon.svg';
$scope.notStartedIcon='resources/assets/svgs/survey_not_started_icon.svg';
$scope.surveyStartedIcon='resources/assets/svgs/survey_in_progress_icon.svg';
$scope.surveyLockedIcon = 'resources/assets/svgs/lock-symbol-for-interface.svg';
$scope.surveyCompleteIcon = 'resources/assets/svgs/survey_complete_icon.svg';
var currentUserVO = context.getModel().userVO;

/*******************Pagination changes********************/
	$scope.pageSize = "25";
	$scope.sortColumn = 'name';
	$scope.sortDirection = 'asc';
	$scope.isScrollBusy = false;


	$scope.openFullyLoaded = false;
	$scope.closedFullyLoaded = false;
	$scope.ownedFullyLoaded = false;
	
	$scope.openCurrentPageNo = 0;
	$scope.closedCurrentPageNo = 0;
	$scope.ownedCurrentPageNo = 0;

	$scope.showScrollForOpenItems = false;
	$scope.showScrollForClosedItems = false;
	$scope.showScrollForOwnedItems = false;

	$scope.isOpenStatusSortClicked = false;
	$scope.isClosedStatusSortClicked = false;
	$scope.isOwnedStatusSortClicked = false;
	
	$scope.scrollbarConfig = {
		autoHideScrollbar: true,
		theme: 'minimal-dark',
		callbacks: {
			onScroll: function () {
				if (this.mcs.topPct >= 80) {
					$scope.loadMore();
				}
			}
		}
	}
	$scope.loadMore = function () {
		if($scope.isScrollBusy == false){
			$scope.isScrollBusy = true;
			if ($scope.tabs[0].active) {		
				if (!($scope.isOpenStatusSortClicked || $scope.openFullyLoaded))
					$scope.getMyOpenSurveys('open', ++$scope.openCurrentPageNo);
			}
			else if ($scope.tabs[1].active) {
				if (!($scope.isClosedStatusSortClicked || $scope.closedFullyLoaded))
					$scope.getMyClosedSurveys('closed', ++$scope.closedCurrentPageNo);
			}
			else if ($scope.tabs[2].active) {
				if (!($scope.isOwnedStatusSortClicked || $scope.ownedFullyLoaded))
					$scope.getMyOwnedSurveys('owned', ++$scope.ownedCurrentPageNo);
			}
			$scope.isScrollBusy = false;
		}
	}
	var resetScopeVariablesOnSort = function (tabType) {
		switch (tabType) {
			case 'openSurveys':
				$scope.openFullyLoaded = false;
				$scope.openCurrentPageNo = 0;
				$scope.allSurveysList = [];
				break;
			case 'closedSurveys':
				$scope.closedFullyLoaded = false;
				$scope.closedCurrentPageNo = 0;
				$scope.closedSurveys = [];
				break;
			case 'ownedByMe':
				$scope.ownedFullyLoaded = false;
				$scope.ownedCurrentPageNo = 0;
				$scope.ownedByMe = [];
				break;
		}
	}
	var resetScroll = function (contDivId) {
		var elm = document.getElementById(contDivId);
		elm.scrollTop = 0;
	}
	var reloadOnSort = function (tabType, tabValue, elementId, pageNum) {
		resetScroll(elementId);
		resetScopeVariablesOnSort(tabType);		
		switch (tabType) {
			case 'openSurveys':
				$scope.getMyOpenSurveys(tabValue, pageNum);
				break;
			case 'closedSurveys':
				$scope.getMyClosedSurveys(tabValue, pageNum);
				break;
			case 'ownedByMe':
				$scope.getMyOwnedSurveys(tabValue, pageNum);
				break;
		}
	}
	var sortOnProgressCallBack = function () {
		if($scope.tabs[0].active)
			$scope.allSurveysList = SortUtility.sortByField($scope.allSurveysList, ['percentageUsersResponded', 'name', 'dueDateVO.gmtMilliSeconds'], $scope.reverseSort);
		else if($scope.tabs[1].active)
			$scope.closedSurveys = SortUtility.sortByField($scope.closedSurveys, ['percentageUsersResponded', 'name', 'dueDateVO.gmtMilliSeconds'], $scope.reverseSort);	
		else if($scope.tabs[2].active)
			$scope.ownedByMe = SortUtility.sortByField($scope.ownedByMe, ['percentageUsersResponded', 'name', 'dueDateVO.gmtMilliSeconds'], $scope.reverseSort);
		$scope.reverseSort = !$scope.reverseSort;
	}
/*********************************************************/

var createSurvey = function() {
//	var w = 840;
//	var h = 520;
///*
//	var left = (window.innerWidth / 2) - (w / 2);
//	var top = (window.innerHeight / 2) - (h / 2);
//	var new_window = window.open(
//			"/portlet/login.jsp?openFlexModule=createSurvey", "_blank",
//			"toolbar=yes, resizable=no, width=" + w + ", height=" + h
//					+ ",left=" + left + ",top=" + top);
//	new_window.onbeforeunload = function() {
//		surveysService.getMySurveyListJSON().then(
//				function(data) {
//					sessionStorage.removeItem('surveyBaseUuid');
//					$scope.allSurveysList = data;
//					if ($scope.allSurveysList != undefined) {
//						// Sort and group by portalBaseUuid to display
//						// surveys
//						// grouped by portals;
//						surveysService.sortOn($scope.allSurveysList,
//								'portalBaseUuid');
//						$scope.groups = surveysService.groupBy(
//								'portalBaseUuid', 'portalName',
//								$scope.allSurveysList);
//					}
//				});
//	};
//	*/
	surveysService.setSurvey(null);
	surveysService.setIsSurveyPublished(false);

    $state.go('home.createSurvey');
};

var createCBEQuestionnaire = function() {
	$state.go('home.createCBEQuestionnaire', { surveyBaseUuid: null});
}

$rootScope.$on('$stateChangeSuccess', 
		function(event, toState, toParams, fromState, fromParams){
	console.log("from state="+JSON.stringify(fromState));
	console.log("to state="+JSON.stringify(toState));
	console.log(fromParams);
	console.log(toParams);
	console.log(toState);
		if(toState.name=="home.createSurvey" && fromState.name=="home.surveys")
		{
				console.log("true createSurvey");
				//surveysService.setSelectedTab(2);
		}
		else if(fromState.name=="home.createSurvey" && toState.name=="home.surveys")
		{
			//surveysService.setSelectedTab(2);
		}
		else if(fromState.name=="home.surveys" && toState.name=="home.openSurvey")
		{
			
		}
		else if(toState.name=="home.surveys" && fromState.name=="home.openSurvey")
		{
			
		}
		else if(toState.name=="home.surveys" && fromState.name=="home.surveys")
		{
			
		}
		else
		{
			surveysService.setSelectedTab(0);
		}
})


var getDisplayOptionsForSurveys = function(){
	var options = [];
	options.push({name:localizedMessages.get("SORT_BY_NAME"), value: "name"});
	options.push({name:localizedMessages.get("SORT_BY_DUE_DATE"), value: "dueDateLong"});
	options.push({name:localizedMessages.get("SORT_BY_PROGRESS"), value: "progress"});
	options.push({name:localizedMessages.get("SORT_BY_TYPE"), value: "type"});
	return options;
}

var getDisplayOptionsForSurveyCreation = function(){
	var options = [];
	options.push({name:localizedMessages.get("SURVEY_BUILDER"), value: "surveyBuilder"});
	options.push({name:localizedMessages.get("ENGAUGE_QUESTIONNAIRE"), value: "cbeQuestionnaire"});
	return options;
}

var getSurveysListConsideringNullDueDate = function (surveysList) {
	for (var i = 0; i < surveysList.length; i++) {
		if (surveysList[i].dueDateVO == null) {
			surveysList[i].dueDateVO = {
				gmtMilliSeconds: -1
			}
		}
	}
	
	return surveysList;
}

$scope.reverseSort = false;
$scope.selectMenuItem = function(ob) {
	bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Survey, bvUsageUtils.const.Entity.Action.Survey.MenuAction, ob.value);
	if(ob.value == "name") {
		$scope.sortColumn="name";
		$scope.sortDirection = ($scope.sortDirection == 'asc') ? 'desc' : 'asc';
		if($scope.tabs[0].active){
			if($scope.isOpenStatusSortClicked || $scope.openFullyLoaded || MobileUtil.isAndroid())
				$scope.allSurveysList = SortUtility.sortByField($scope.allSurveysList, ['name', 'dueDateVO.gmtMilliSeconds', 'percentageUsersResponded'], $scope.reverseSort);
			else
				reloadOnSort($scope.tabs[0].content, 'open', 'open-list', 0);
		}
		else if($scope.tabs[1].active){
			if($scope.isClosedStatusSortClicked || $scope.closedFullyLoaded)
				$scope.closedSurveys = SortUtility.sortByField($scope.closedSurveys, ['name', 'dueDateVO.gmtMilliSeconds', 'percentageUsersResponded'], $scope.reverseSort);
			else
				reloadOnSort($scope.tabs[1].content, 'closed', 'closed-list', 0);
		}
		else if($scope.tabs[2].active){
			if($scope.isOwnedStatusSortClicked || $scope.ownedFullyLoaded)
				$scope.ownedByMe = SortUtility.sortByField($scope.ownedByMe, ['name', 'dueDateVO.gmtMilliSeconds', 'percentageUsersResponded'], $scope.reverseSort);
			else
				reloadOnSort($scope.tabs[2].content, 'owned', 'owned-list', 0);
		}
		$scope.reverseSort = !$scope.reverseSort;
	}
	else if(ob.value == "dueDateLong") {
		$scope.sortColumn="dueDateLong";
		$scope.sortDirection = ($scope.sortDirection == 'asc') ? 'desc' : 'asc';

		if($scope.tabs[0].active){
			if($scope.isOpenStatusSortClicked || $scope.openFullyLoaded || MobileUtil.isAndroid())
				$scope.allSurveysList = SortUtility.sortByField(getSurveysListConsideringNullDueDate($scope.allSurveysList), ['dueDateVO.gmtMilliSeconds', 'name', 'percentageUsersResponded'], $scope.reverseSort);
				else 
				reloadOnSort($scope.tabs[0].content, 'open', 'open-list', 0);
		}
		else if($scope.tabs[1].active){
			if($scope.isClosedStatusSortClicked || $scope.closedFullyLoaded)
				$scope.closedSurveys = SortUtility.sortByField(getSurveysListConsideringNullDueDate($scope.closedSurveys), ['dueDateVO.gmtMilliSeconds', 'name', 'percentageUsersResponded'], $scope.reverseSort);
			else 
				reloadOnSort($scope.tabs[1].content, 'closed', 'closed-list', 0);
		}
		else if($scope.tabs[2].active){
			if($scope.isOwnedStatusSortClicked || $scope.ownedFullyLoaded)
				$scope.ownedByMe = SortUtility.sortByField(getSurveysListConsideringNullDueDate($scope.ownedByMe), ['dueDateVO.gmtMilliSeconds', 'name', 'percentageUsersResponded'], $scope.reverseSort);
			else
				reloadOnSort($scope.tabs[2].content, 'owned', 'owned-list', 0);
		}
		$scope.reverseSort = !$scope.reverseSort;
	}
	else if(ob.value == "progress")	{
		$scope.sortColumn="progress";
		if($scope.tabs[0].active){
			if(!($scope.isOpenStatusSortClicked || $scope.openFullyLoaded)) {
				$scope.isOpenStatusSortClicked = true;
				reloadOnSort($scope.tabs[0].content, undefined, 'open-list', 0);				
			}
			else {
				$scope.allSurveysList = SortUtility.sortByField($scope.allSurveysList, ['percentageUsersResponded', 'name', 'dueDateVO.gmtMilliSeconds'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
			}
		}				
		else if($scope.tabs[1].active){
			if(!($scope.isClosedStatusSortClicked || $scope.closedFullyLoaded)) {
				$scope.isClosedStatusSortClicked = true;
				reloadOnSort($scope.tabs[1].content, undefined, 'closed-list', 0);
			}
			else {
				$scope.closedSurveys = SortUtility.sortByField($scope.closedSurveys, ['percentageUsersResponded', 'name', 'dueDateVO.gmtMilliSeconds'], $scope.reverseSort);	
				$scope.reverseSort = !$scope.reverseSort;
			}
		}
		else if($scope.tabs[2].active){
			if(!($scope.isOwnedStatusSortClicked || $scope.ownedFullyLoaded)) {
				$scope.isOwnedStatusSortClicked = true;
				reloadOnSort($scope.tabs[2].content, undefined, 'owned-list', 0);
			}
			else {
				$scope.ownedByMe = SortUtility.sortByField($scope.ownedByMe, ['percentageUsersResponded', 'name', 'dueDateVO.gmtMilliSeconds'], $scope.reverseSort);
				$scope.reverseSort = !$scope.reverseSort;
			}
		}
	} 
	else if(ob.value == "type") {
		$scope.sortColumn="type";
		$scope.sortDirection = ($scope.sortDirection == 'asc') ? 'desc' : 'asc';
		if($scope.tabs[0].active){
			if($scope.isOpenStatusSortClicked || $scope.openFullyLoaded)
				$scope.allSurveysList = SortUtility.sortByField($scope.allSurveysList, ['cbeQuestionaireType', 'name', 'dueDateVO.gmtMilliSeconds', 'percentageUsersResponded'], $scope.reverseSort);
			else 
				reloadOnSort($scope.tabs[0].content, 'open', 'open-list', 0);
		}
		else if($scope.tabs[1].active){
			if($scope.isClosedStatusSortClicked || $scope.closedFullyLoaded)
				$scope.closedSurveys = SortUtility.sortByField($scope.closedSurveys, ['cbeQuestionaireType', 'name', 'dueDateVO.gmtMilliSeconds', 'percentageUsersResponded'], $scope.reverseSort);
			else 
				reloadOnSort($scope.tabs[1].content, 'closed', 'closed-list', 0);
		}
		else if($scope.tabs[2].active){
			if($scope.isOwnedStatusSortClicked || $scope.ownedFullyLoaded)
				$scope.ownedByMe = SortUtility.sortByField($scope.ownedByMe, ['cbeQuestionaireType', 'name', 'dueDateVO.gmtMilliSeconds', 'percentageUsersResponded'], $scope.reverseSort);
			else 
				reloadOnSort($scope.tabs[2].content, 'owned', 'owned-list', 0);
		}
		$scope.reverseSort = !$scope.reverseSort;
	}
	else if(ob.value == "surveyBuilder")
	{
		createSurvey();
	}
	else if(ob.value == "cbeQuestionnaire")
	{
		createCBEQuestionnaire();
	}
}

var manageSurveys = function(){
	surveysService.getMyOwnedSurveyListJSON().then(function(data){
		var ownedByMeSurveysList = data;
		if(ownedByMeSurveysList && ownedByMeSurveysList.length > 0){
			bvDialog.openManageSurveys(ownedByMeSurveysList);
		}
		else{
			bvDialog.showMessage(localizedMessages.get("NO_SURVEYS_TO_MANAGE"));
		}
	});
}

var optionsSurveyItem = { 
 		title:localizedMessages.get('OPTIONS'),
 		click:()=>{}, iconUrl: '', 
 		svgFill: '#fff', 
 		dropDownMenu:getDisplayOptionsForSurveys()
		};

var rightMenuList = [];

var manageSurveyItem = {
		title : $scope.ui.label.manageSurveys,
		click : manageSurveys,
		iconUrl : '',
		stateName: 'ManageSurveys'
};
var createSurveyItem = {
		title : $scope.ui.label.new_survey,
		click : createSurvey,
		iconUrl : '',
		stateName: 'NewSurvey'
	};

var optionsCreateSurveyItem = { 
 		title : $scope.ui.label.new_survey,
 		click : ()=>{}, iconUrl: '', 
 		svgFill : '#fff', 
 		dropDownMenu:getDisplayOptionsForSurveyCreation()
		};

var createSurveyList = [ {
	title : $scope.ui.label.survey,
	click : createSurvey,
	iconUrl : '',
	svgFill: '#fff'
} ]

var canCurrentUserCreateModule = false;
var canCurrentUserCreateCBE = context.getModel().enableCBEQuestionaire;
surveysService.setIsCurrentUserAllowedToCreateModule(canCurrentUserCreateModule);
var config = "false";


//if(context.getModel().isUserPortalAdmin == "true" || context.getModel().isUserPortalAdmin == true || context.getModel().isUserPortalAdmin == "TRUE"){
//	rightMenuList.push(createSurveyItem);
//	surveysService.setIsCurrentUserAllowedToCreateModule(true);
//}
//else{
	commonService.checkModuleCreatorsConfigPresentJSON("SURVEYS").then(function(data){
		if(data && data.data && data.data.name == "isModuleCreatorsPrefAllowed"){
			config = data.data.value;
			if(config == "true" || config == true){
				commonService.getModuleCreatorsForSpecificModuleJSON("SURVEYS").then(function(mod){
					if(mod.data){
						var moduleCreators = angular.fromJson(mod.data);
						if(moduleCreators.users != undefined && moduleCreators.users.length>0){
							for(var i=0;i<moduleCreators.users.length;i++){
								if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
									canCurrentUserCreateModule = true;
									surveysService.setIsCurrentUserAllowedToCreateModule(canCurrentUserCreateModule);
									break;
								}
							}
							if(canCurrentUserCreateModule){
								rightMenuList.push(manageSurveyItem);
								
								if(!MobileUtil.isAndroid() && !MobileUtil.isIPhone() && !MobileUtil.isSurface() && canCurrentUserCreateCBE) {
									rightMenuList.push(optionsCreateSurveyItem);
								} else {
									rightMenuList.push(createSurveyItem);
								}
								
							}
						}
					}
					rightMenuList.push(optionsSurveyItem);
				});
			}
			else{
				rightMenuList.push(manageSurveyItem); 
				if(canCurrentUserCreateCBE) {
					rightMenuList.push(optionsCreateSurveyItem);
				} else {
					rightMenuList.push(createSurveyItem);
				}
				rightMenuList.push(optionsSurveyItem);
				surveysService.setIsCurrentUserAllowedToCreateModule(canCurrentUserCreateModule);
			}
		}
	});
//}
$scope.middleBarObject.showBack = false;
$scope.tabs = [ {
	title : !legacyUserService.isNasdaqUI ? $scope.ui.label.open : $scope.ui.label.openUppercase,
	content : 'openSurveys',
	rightMenuList:rightMenuList,
	order:0
},
{
	title : !legacyUserService.isNasdaqUI ? $scope.ui.label.closed : $scope.ui.label.closedUppercase,
	content : 'closedSurveys',
	rightMenuList:rightMenuList,
	order : 1
},
 {
	title : !legacyUserService.isNasdaqUI ? $scope.ui.label.own : $scope.ui.label.ownUppercase,
	content : 'ownedByMe',
	rightMenuList:rightMenuList,
	order : 2
}
 ];




$scope.msg_no_surveys = localizedMessages.get('MSG_NO_APPROVALS');

var tabNum = surveysService.getSelectedTab();
$scope.tabs[tabNum].active = true;
//$scope.tabs[0].active = true;

var currentUserVO = context.getModel().userVO;
$scope.selectedContextItem = "";
$scope.middleBarObject.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
//Context menu items on list of surveys
var viewSurvey = function()
{
	$scope.showSurvey(contextMenuModel.selectedObject);
};

var viewSurveyStatus = function()	//'Preview' right click event
{
	$scope.viewSurveyStatusClicked(contextMenuModel.selectedObject.objectBaseUuid);
};

var editSurvey = function()			//'Edit' right click event
{
	$scope.editSurvey(contextMenuModel.selectedObject.objectBaseUuid);
	
};

$scope.viewSurveyStatusClicked = function(objectBaseUuid)
{
	surveysService.getSurveyDetails(objectBaseUuid).then(function(data) {
		surveysService.setSurvey(data);
		var survey = surveysService.getSurvey();

		let activeTab = 0;
		let currentPage = 0;

		for (let i = 0; i < $scope.tabs.length; i++) {
			if ($scope.tabs[i].active) {
				activeTab = i;
				break;
			}
		}

		if (activeTab == 0) {
			currentPage = $scope.openCurrentPageNo;
		}
		else if (activeTab == 1) {
			currentPage = $scope.closedCurrentPageNo;
		}
		else if (activeTab == 2) {
			currentPage = $scope.ownedCurrentPageNo;
		}

		bvDialog.surveyAdminPreview(survey, $scope.getMyOpenSurveys, $scope.getMyClosedSurveys, $scope.getMyOwnedSurveys, activeTab, currentPage);
		});
}
$scope.cbeAdminPreview = function(survey)
{
	surveysService.getSurveyDetails(survey.objectBaseUuid).then(function(data){
		bvDialog.cbeAdminPreview(data);
	});
}
var publishCBE = function(){
	
}
var deleteSurvey = function()  	//'Delete' right click event
{
		$scope.proceedDeleteSurvey = function(){
			surveysService.deleteSurveyJSON(contextMenuModel.selectedObject.objectBaseUuid).then(function(data){
    		if(data.boName == "SuccessBO"){
    			for(var i=0;i<$scope.tabs.length;i++){
    				if($scope.tabs[i].active == true){
    					surveysService.setSelectedTab(i);
    					break;
    				}
    			}
    			$state.reload();
    		}
			});
		}
		bvDialog.confirm($scope.ui.label.are_you_sure_you_want_to_delete_survey+" "+ contextMenuModel.selectedObject.name, $scope.proceedDeleteSurvey);
};

var createPush = function()
{
	bvDialog.showPush(contextMenuModel.selectedObject);
};

var attachRespondedPercentageAndUserResponse = function(genericSurveysList){
	if(genericSurveysList != undefined && genericSurveysList.length>0){
		var count = 0;
		for(var i=0;i<genericSurveysList.length;i++){  //Attach if current user is owner
			count = 0;
			//var isOwner = currentUserVO.isContentOwner;
			var isOwner = false;
			genericSurveysList[i].isCurrentUserOwner = isOwner;
			for(var k=0;k<genericSurveysList[i].owners.length;k++){
				if(currentUserVO.objectBaseUuid == genericSurveysList[i].owners[k].objectBaseUuid){
					isOwner = true;
					genericSurveysList[i].isCurrentUserOwner = isOwner;
					break;
				}
			}
			surveysService.setIsCurrentLoggedInUserOwner(isOwner);
			if(genericSurveysList[i].participantBaseUuidAndResponseList != undefined && genericSurveysList[i].participantBaseUuidAndResponseList.length > 0){
				for(var j=0;j<genericSurveysList[i].participantBaseUuidAndResponseList.length;j++){
					if(genericSurveysList[i].participantBaseUuidAndResponseList[j].value == 'responded'){
						count++; //Get number of responded for percentage
					}
					if(genericSurveysList[i].participantBaseUuidAndResponseList[j].objectBaseUuid == currentUserVO.objectBaseUuid){
						genericSurveysList[i].userRespondedStatus = genericSurveysList[i].participantBaseUuidAndResponseList[j].respondedStatus;
						genericSurveysList[i].isUserResponseSubmitted = genericSurveysList[i].participantBaseUuidAndResponseList[j].value == 'responded';
						genericSurveysList[i].isMandatoryQuestionsPending = genericSurveysList[i].participantBaseUuidAndResponseList[j].isMandatoryQuestionsPending;
						genericSurveysList[i].userLockStatus = genericSurveysList[i].participantBaseUuidAndResponseList[j].isLocked;//Get current user response status
					}
				}
				//genericSurveysList[i].percentageUsersResponded = parseInt((count/genericSurveysList[i].participantBaseUuidAndResponseList.length) *100);
				const participants = genericSurveysList[i].participantBaseUuidAndResponseList;
				genericSurveysList[i].percentageUsersResponded = parseInt(participants.map(user => parseInt(user.respondedStatus)).reduce((number, sum) => sum = sum + number) / participants.length);
			}else{
				genericSurveysList[i].percentageUsersResponded = 0;
			}
			genericSurveysList[i].percentageLabel = localizedMessages.get("PERCENTAGE_OF_USERS_RESPONDED", {"percentage" : Math.round(genericSurveysList[i].percentageUsersResponded)});
		}
	}
}

	//Owners can edit survey
	$scope.editSurvey = function(objectBaseUuid){
	    if(legacyUserService.isNasdaqUI)
	        $state.go('home.createSurvey', { objectBaseUuid });
        else
            surveysService.getSurveyDetails(objectBaseUuid).then(function(data) {
                surveysService.setSurvey(data);
                var survey = surveysService.getSurvey();
                surveysService.setIsSurveyPublished(false);
                $state.go('home.createSurvey');
            })
		
	}
	$scope.respondSurvey = function(survey){
		for(var i=0;i<$scope.tabs.length;i++){
			if($scope.tabs[i].active == true){
				surveysService.setSelectedTab(i);
				break;
			}
		}
		surveysService.setCurrentSelectedSurveyFromList(survey); //For survey report
		surveysService.setParticipantBaseUuidList(survey);		//For survey report
		if(legacyUserService.isNasdaqUI)
            $state.go('home.openSurvey', { surveyBaseUuid: survey.objectBaseUuid });
        else
            surveysService.getSurveyDetailsJSON(survey.objectBaseUuid).then(function(data) {
                $state.go('home.openSurvey');
                surveysService.setSurvey(data);
                survey = surveysService.getSurvey();
                surveysService.setQuestionBaseUuidList(survey);
                surveysService.setAllQuestionsList(survey);
            });
		
	}
	$scope.respondCBE = function(cbeQuestionnaire){
		bvDialog.showRespondentCBEView(cbeQuestionnaire)
	}
	
	$scope.showSurveyOnOwnTab = function(survey)
	{
		//var isOwner = currentUserVO.isContentOwner;
		if(!survey.isCBE){ 
			$scope.showSurvey(survey)
		} else {
			$scope.cbeAdminPreview(survey);
		}
	};
	$scope.showSurveyOnOpenTab = function(survey)
	{
		//var isOwner = currentUserVO.isContentOwner;
		if(!survey.isCBE){ 
			$scope.showSurvey(survey)
		} else{
			bvDialogService.openNewWindow(survey.cbeLink);
		}
	};
	//Showing each survey details when clicked.
	$scope.showSurvey = function(survey)
	{
		//var isOwner = currentUserVO.isContentOwner;
		if(survey.isCBE) {
			$scope.cbeAdminPreview(survey);
		} else {
			
		var isOwner = false;
		var isCurrentUserRespondent = false;
		var isCurrentUserLocked = false;
		var isClosed = survey.isClosed;
		
		for(var i=0;i<survey.owners.length;i++){
			if(currentUserVO.objectBaseUuid == survey.owners[i].objectBaseUuid){
				isOwner = true;
				break;
			}
		}
		for(var i=0;i<survey.participantBaseUuidAndResponseList.length;i++){
			if(survey.participantBaseUuidAndResponseList[i].objectBaseUuid == context.getModel().userVO.objectBaseUuid){
				isCurrentUserRespondent = true;
				isCurrentUserLocked = survey.participantBaseUuidAndResponseList[i].isLocked;
				break;
			}
		}
		surveysService.setIsCurrentLoggedInUserOwner(isOwner);
		
		if(MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface() )
		{//For Mobile devices show Respond survey
			$scope.respondSurvey(survey);
			return;
		}
		
		if(isOwner && !survey.isPublished){
			$scope.editSurvey(survey.objectBaseUuid);
		}
		else if(isOwner && (survey.isPublished || isCurrentUserLocked)){
			surveysService.setCurrentSelectedSurveyFromList(survey);
			$scope.viewSurveyStatusClicked(survey.objectBaseUuid);
		}
		else if(isCurrentUserLocked){
			surveysService.getSurveyDetailsJSON(survey.objectBaseUuid).then(function(data) {
				surveysService.setSurvey(data);
				$scope.showSurveyOverview(survey);
				});
		}
		else{
			$scope.respondSurvey(survey);
		}
		}
	};
	
	$scope.showSurveyOverview = function(selectedObject){
		var isUserLocked = selectedObject.userLockStatus;

		if(!legacyUserService.isNasdaqUI){
			surveysService.setQuestionBaseUuidList(surveysService.getSurvey());
			surveysService.setAllQuestionsList(surveysService.getSurvey());
			var allSurveyQuestionsList = surveysService.getAllQuestionsList();
			surveysService.getSurveyResponseForUserJSON(surveysService.getSurvey().objectBaseUuid, angular.toJson(surveysService.getQuestionBaseUuidList()), currentUserVO.objectBaseUuid).then(function(data1){
				$scope.userResponses={};
					for(var i=0;i<data1.surveyQuestionResponseList.length;i++){
						//$scope.qType = '';
						for(var j=0;j<allSurveyQuestionsList.length;j++){
							if(data1.surveyQuestionResponseList[i].questionBaseUuid == allSurveyQuestionsList[j].objectBaseUuid){
								//$scope.qType = allSurveyQuestionsList[j].questionType;
								data1.surveyQuestionResponseList[i].questionType = allSurveyQuestionsList[j].questionType;
								break;
							}
						}
						if(data1.surveyQuestionResponseList[i].questionType == 'likertscale'){
							$scope.userResponses[data1.surveyQuestionResponseList[i].questionBaseUuid] = allSurveyQuestionsList[j].likertMapping[data1.surveyQuestionResponseList[i].response];
						}else if(data1.surveyQuestionResponseList[i].questionType == 'yesno' || data1.surveyQuestionResponseList[i].questionType == 'meetsornot' || data1.surveyQuestionResponseList[i].questionType == 'truefalse'){
							var locResponse = data1.surveyQuestionResponseList[i].response;
							if(locResponse == 'does not meet'){
								locResponse = localizedMessages.get('DOES_NOT_MEET');
							}else{
								locResponse = localizedMessages.get(locResponse.toUpperCase());
							}
							$scope.userResponses[data1.surveyQuestionResponseList[i].questionBaseUuid] = locResponse;
						}
						else{
							$scope.userResponses[data1.surveyQuestionResponseList[i].questionBaseUuid] = data1.surveyQuestionResponseList[i].response;
						}
					}
					$scope.canEditResponse = true && !isUserLocked;
					//bvDialog.userAnswersPreview($scope.userResponses, surveysService.getSurvey(), $scope.canEditResponse);
					var objBaseUuid=context.getModel().userVO.objectBaseUuid;
					var userName=context.getModel().userVO.name;
					for(var i=0;i<surveysService.getSurvey().sections.length;i++){
						surveysService.getSurvey().sections[i].attachments = surveysService.getSurvey().sections[i].attachments || surveysService.getSurvey().sections[i].attachedObjectsList;
						var count = 0;
						var secCount = 0;
						for(var j=0; j<surveysService.getSurvey().sections[i].questions.length; j++){
							surveysService.getSurvey().sections[i].questionsResponded = count;
							surveysService.getSurvey().sections[i].questionsCount = surveysService.getSurvey().sections[i].questions.length;
							surveysService.getSurvey().sections[i].questions[j].attachments = surveysService.getSurvey().sections[i].questions[j].attachments || surveysService.getSurvey().sections[i].questions[j].attachedObjectsList;
							surveysService.getSurvey().sections[i].questions[j].sequentialSecCount = secCount + 1;
							//&& $scope.userResponses[surveysService.getSurvey().sections[i].questions[j].objectBaseUuid] != ''
							if($scope.userResponses[surveysService.getSurvey().sections[i].questions[j].objectBaseUuid] != undefined && $scope.userResponses[surveysService.getSurvey().sections[i].questions[j].objectBaseUuid] != ''){
								surveysService.getSurvey().sections[i].questionsResponded = ++count;
							}
						}
					}
					bvDialog.userAnswersPreview($scope.userResponses, surveysService.getSurvey(), $scope.canEditResponse, objBaseUuid,userName);
			});
		}
		else{
			if(!isUserLocked){
				previewSurveyService.showOverviewDialog(selectedObject)
			}
		}
	}
	
	$scope.viewSurveyStatus = function(selectedContextItem){
		bvDialog.surveyAdminPreview(contextMenuModel.selectedObject);
	};

	var alertSurvey = function() {
		surveysAlertService.survey = contextMenuModel.selectedObject;
		surveysAlertService.openAlertDialog();
	}
//	$scope.responseStatusMapping = {
//		'started':localizedMessages.get('STARTED'),
//		'notStarted':localizedMessages.get('NOT_STARTED'),
//		'completed':localizedMessages.get('COMPLETED')
//	}
	$scope.lockedLbl = localizedMessages.get("CANNOT_RESPOND");

	//Showing list of all surveys.
	$scope.noOpenSurveys = false;
	$scope.getMyOpenSurveys = function(category, page){
		$scope.middleBarObject.title = localizedMessages.get("SURVEYS");
		surveysService.setSelectedTab(0);
		if (MobileUtil.isApp() || $scope.isOpenStatusSortClicked || category == undefined){
			surveysService.getMySurveyListJSON().then(function(data){			
				$scope.allSurveysList = data;
				// checking if user is creator of the survey
				data.forEach(user => user.isSurveyCreator = (user.createdByBaseUuid === currentUserVO.objectBaseUuid))
				if($scope.allSurveysList == undefined || $scope.allSurveysList.length == 0)
					$scope.noOpenSurveys = true;
				else
					attachRespondedPercentageAndUserResponse($scope.allSurveysList);
				if($scope.sortColumn == "progress")
					sortOnProgressCallBack();
				//Below code to be reviewed - Might not be necessary
				var userSelected=context.getModel().userVO;
				console.log(userSelected);
				$scope.surveyStatus = [];
				for(var i=0;i<$scope.allSurveysList.length;i++)
				{
					for(var j=0;j<$scope.allSurveysList[i].participantBaseUuidAndResponseList.length;j++)
					{	
						if($scope.allSurveysList[i].participantBaseUuidAndResponseList[j].objectBaseUuid==userSelected.objectBaseUuid)
						{
							if($scope.allSurveysList[i].participantBaseUuidAndResponseList[j].value=="notResponded")
							{
								$scope.allSurveysList[i]["icon"]=$scope.notStartedIcon;
								$scope.allSurveysList[i]["isComplete"]="notStarted";
							}
							else if($scope.allSurveysList[i].participantBaseUuidAndResponseList[j].value=="responded")
							{
								$scope.allSurveysList[i]["icon"]=$scope.surveyCompleteIcon;
								$scope.allSurveysList[i]["isComplete"]="completed";
							}
							else
							{
								$scope.allSurveysList[i]["icon"]=$scope.surveyStartedIcon;
								$scope.allSurveysList[i]["isComplete"]="started";
							}
						}
						
					}
				}
			});
		}
		else {
			resetScopeVariablesOnSort('closedSurveys');
			resetScopeVariablesOnSort('ownedByMe');
			surveysService.getSurveyWithPagination(category, page, $scope.pageSize, $scope.sortColumn, $scope.sortDirection).then(function(data){
				if(page > 0 && data.length == 0){
					$scope.openFullyLoaded = true;
					return;
				}
				if(data.length > 0)
					attachRespondedPercentageAndUserResponse(data);
				// checking if user is creator of the survey
				data.forEach(user => user.isSurveyCreator = (user.createdByBaseUuid === currentUserVO.objectBaseUuid))
				if(page == 0) {
					$scope.allSurveysList = [];
					$scope.allSurveysList = data;
				}
				else {
					$scope.allSurveysList = $scope.allSurveysList.concat(data);
				}
				if(data.length < $scope.pageSize)
					$scope.openFullyLoaded = true;
				if(data.length >= $scope.pageSize)
					$scope.showScrollForOpenItems = true;
				$scope.noOpenSurveys = $scope.allSurveysList.length == 0 ? true : false;
			});
		}
	}
	
	
	$scope.noClosedSurveys = false;
	$scope.getMyClosedSurveys = function(category, page) {
		surveysService.setSelectedTab(1);
		if (MobileUtil.isApp() || $scope.isClosedStatusSortClicked  || category == undefined) {
			surveysService.getMyClosedSurveyListJSON().then(function(data){
				$scope.closedSurveys = data;
				// checking if user is creator of the survey
				data.forEach(user => user.isSurveyCreator = (user.createdByBaseUuid === currentUserVO.objectBaseUuid))
				if($scope.closedSurveys == undefined || $scope.closedSurveys.length == 0)
					$scope.noClosedSurveys = true;
				else
					attachRespondedPercentageAndUserResponse($scope.closedSurveys);
				if($scope.sortColumn == "progress")
					sortOnProgressCallBack();
				//Below code to be reviewed - Might not be necessary
				$scope.groups = surveysService.groupBy( 'portalBaseUuid', 'portalName', $scope.closedSurveys );
			});
		}
		else {
			resetScopeVariablesOnSort('openSurveys');
			resetScopeVariablesOnSort('ownedByMe');
			surveysService.getSurveyWithPagination(category, page, $scope.pageSize, $scope.sortColumn, $scope.sortDirection).then(function(data){
				if(page > 0 && data.length == 0){
					$scope.closedFullyLoaded = true;
					return;
				}
				if(data.length > 0)
					attachRespondedPercentageAndUserResponse(data);
				// checking if user is creator of the survey
				data.forEach(user => user.isSurveyCreator = (user.createdByBaseUuid === currentUserVO.objectBaseUuid))
				if(page == 0) {
					$scope.closedSurveys = [];
					$scope.closedSurveys = data;
				}
				else {
					$scope.closedSurveys = $scope.closedSurveys.concat(data);
				}
				if(data.length < $scope.pageSize)
					$scope.closedFullyLoaded = true;
				if(data.length >= $scope.pageSize)
					$scope.showScrollForClosedItems = true;
				$scope.noClosedSurveys = $scope.closedSurveys.length == 0 ? true : false;
			});
		}
	}

	
	$scope.noOwnedByMeSurveys = false;
	$scope.getMyOwnedSurveys = function(category, page) {
		surveysService.setSelectedTab(2);
		if (MobileUtil.isApp() || $scope.isOwnedStatusSortClicked || category == undefined) {
			surveysService.getMyOwnedSurveyListJSON().then(function(data){
				$scope.ownedByMe = data;
				// checking if user is creator of the survey
				data.forEach(user => user.isSurveyCreator = (user.createdByBaseUuid === currentUserVO.objectBaseUuid))
				if($scope.ownedByMe == undefined || $scope.ownedByMe.length == 0)
					$scope.noOwnedByMeSurveys = true;
				else
					attachRespondedPercentageAndUserResponse($scope.ownedByMe);
				if($scope.sortColumn == "progress")
					sortOnProgressCallBack();
				//Below code to be reviewed - Might not be necessary
				$scope.groups = surveysService.groupBy( 'portalBaseUuid', 'portalName', $scope.ownedByMe );
			});
		}
		else {
			resetScopeVariablesOnSort('openSurveys');
			resetScopeVariablesOnSort('closedSurveys');
			surveysService.getSurveyWithPagination(category, page, $scope.pageSize, $scope.sortColumn, $scope.sortDirection).then(function(data){
				if(page > 0 && data.length == 0){
					$scope.ownedFullyLoaded = true;
					return;
				}
				if(data.length > 0)
					attachRespondedPercentageAndUserResponse(data);
				// checking if user is creator of the survey
				data.forEach(user => user.isSurveyCreator = (user.createdByBaseUuid === currentUserVO.objectBaseUuid))
				if(page == 0) {
					$scope.ownedByMe = [];
					$scope.ownedByMe = data;
				}
				else {
					$scope.ownedByMe = $scope.ownedByMe.concat(data);			
				}
				if(data.length < $scope.pageSize)
					$scope.ownedFullyLoaded = true;
				if(data.length >= $scope.pageSize)
					$scope.showScrollForOwnedItems = true;
				$scope.noOwnedByMeSurveys = $scope.ownedByMe.length == 0 ? true : false;
			});
		}
	}
	
	 $scope.currentInstance = "SURVEYS";

		$scope.showSurveysMenu = function(event, survey)
	    {
			$scope.showSurveysRightClick = false;
			if (event)
			{
				event.preventDefault();
	      		event.stopPropagation();
	      		$scope.handleRightClick(event.pageX, event.pageY, survey, event);
	    	}    	
	    }
	       
	    $scope.handleRightClick = function (xPos, yPos, survey, event)
	    {
	    	if(MobileUtil.isAndroid()) {
	    		return false;
	    	}
	    	contextMenuModel.parentObject = $scope.currentInstance;
	    	$scope.rightClickDispatcher(xPos, yPos, survey, event)
	    }
	        
		$scope.showSurveysRightClick = false;

		$scope.rightClickDispatcher = function (xPos, yPos, selectedContextItem, event)
		{

			//console.log("XPos="+xPos);
			//console.log("YPos="+yPos);
			if(selectedContextItem === undefined || selectedContextItem == null)
				return;

			surveysService.getSurveyDetails(selectedContextItem.objectBaseUuid)
			.then(function(data){
				$scope.selectedObjectFromContextMenu = "";
				surveysService.setSurvey(data);
				contextMenuModel.xPos = xPos;
				contextMenuModel.yPos = yPos;
				contextMenuModel.event = event;
				contextMenuModel.currentInstance = $scope.currentInstance;
				contextMenuModel.selectedObject = selectedContextItem;//TODO
				contextMenuModel.contextMenuCheckOptions = {};
				contextMenuModel.multiple = false;
				
				$scope.showSurveysRightClick = true;
		});
		};
    	var copySurvey = function(copySurveyObject){
				surveysService.setSurvey(copySurveyObject);
	    		$scope.copySurveyTitle=copySurveyObject.name+"-" + $scope.ui.label.copy;
	    		$scope.copySurveyRadio='copyQuestionAnswers';
	    		bvDialog.copySurvey($scope.copySurveyTitle, $scope.copySurveyRadio, contextMenuModel.selectedObject.objectBaseUuid);
    		};
    		
    		var createSurveyTemplate = function(survey){
        		if(survey) {
        			$scope.templateName = localizedMessages.get("TEMPLATE") + "-" + survey.name;
        			saveSurveyTemplateFromContextMenuService.saveSurveyTemplate(survey.objectBaseUuid, $scope.templateName)
        		}
    		};
    		
	        
    		var preFill = function(surveyObject){
				surveysService.setSurvey(surveyObject);
				var recipients = surveyObject.participantBaseUuidAndResponseList;
				bvDialog.selectRecipientsForPrefillSurvey(recipients, surveyObject.objectBaseUuid);
    		};
    		
        	var reopenSurvey = function(){
        		$scope.proceed = function(){
        	  		var objectBaseUuid = contextMenuModel.selectedObject.objectBaseUuid;
            		surveysService.getSurveyDetails(objectBaseUuid).then(function(data) {
            			surveysService.setSurvey(data);
            			surveysService.setIsSurveyPublished(true);
            		    $state.go('home.createSurvey', { objectBaseUuid });
            		});
        		}
        		bvDialog.confirm(localizedMessages.get('EDITS_TO_QUESTIONS_OR_SECTIONS_WILL_REQUIRE_ANYONE_WHO_HAS_RESPONDED_TO_RECOMMIT_THEIR_ANSWERERS_WOULD_YOU_LIKE_TO_PROCEED'), $scope.proceed, null);
        	};
        	var showSurveyReport = function(){
        		surveysService.getSurveyDetails(contextMenuModel.selectedObject.objectBaseUuid).then(function(data) {
        			surveysService.setSurvey(data);
        			surveysService.setAllQuestionsList(data);
            		surveysService.getSurveyReportUserResponseJSON(contextMenuModel.selectedObject.objectBaseUuid).then(function(data1) {
            			if(data1.exceptionCode == "0" || data1.exceptionCode == 0){
            	    		bvDialog.showMessage(localizedMessages.get("ATLEAST_ONE_PARTICIPANT_HAVE_TO_COMMIT_TO_VIEW_SURVEY_REPORT"));
            	    		return;
            			}else{
            				bvDialog.showSurveyReports(surveysService.getSurvey(), data1, context.getModel().userVO);
            				}
            		});
        		});
        	}
		$scope.$watch('showSurveysRightClick', function(oldValue, newValue){
			console.log(oldValue, newValue);	
		}, true);
	       
	    $scope.$on('HOME_CONTROLLER_CLICK', function(event){ 
	    	if($scope.showSurveysRightClick)
	        	$scope.showSurveysRightClick = false;
	    });
	 	$scope.menuCallback = function(type, param){
	    	switch (type)
	    	{
	            case 'MENU_STYLE':
	            	$scope.menuStyle = {'top': param[0].y + "px",'left': param[0].x + "px"};
	                break;
	            case 'MENU_ARROW_STYLE':
	                $scope.menuLeftArrowStyle = {'top': param.y + "px", 'left': param.x + "px"};
	                break;
	            case 'OVERVIEW':
	            	$scope.showSurveyOverview(contextMenuModel.selectedObject);
	            	break;
	            case 'RESPOND':
	            	$scope.respondSurvey(contextMenuModel.selectedObject);
	            	break;
	           	case 'EDIT':
	           		editSurvey();
	            	break;
	           	case 'COPY':
	           		copySurvey(contextMenuModel.selectedObject);
	            	break;
	           	case 'SAVE_AS_TEMPLATE':
	           		createSurveyTemplate(contextMenuModel.selectedObject);
	            	break;
	           	case 'PRE_FILL_SURVEY':
	           		preFill(contextMenuModel.selectedObject);
	            	break;
	           	case 'STATUS':
	           		viewSurveyStatus();
	            	break;
	            case 'REPORT':
	           		showSurveyReport();
	            	break;
	            case 'DELETE':
	            	deleteSurvey(contextMenuModel.selectedObject.objectBaseUuid);
	            	break;
	            case 'ALERT':
	            	surveysAlertService.openAlertDialog();
	            	break;
	            case 'REOPEN':
	            	reopenSurvey();
	            	break;
	            case 'PUSH':
	            	createPush();
	            	break;
				default:
					break;						
			}
	    }
	 	
	 	
		if(surveysService.getSurveyOpenFromInbox()){
				surveysService.getSurveyDetails(surveysService.getSurveyOpenFromInbox().objectBaseUuid)
				.then(function(data){
					if(data.isCBE === true) {
						surveysService.setSelectedTab(0);
					} else {
						surveysService.setSurvey(data);
						$scope.respondSurvey(data);
					}
			});
			surveysService.setSurveyOpenFromInbox();	
		}
		
	 	

	    $scope.topRepositoryPanel = {
            templateUrl: 'modules/surveys/toppanel/toppanel.template.html',
            events : [
                { name: 'name',         value : localizedMessages.get('NAME_UPPERCASE')},
                { name: 'due',          value : localizedMessages.get('DUE_UPPERCASE')},
                { name: 'status',       value : localizedMessages.get('STATUS_UPPERCASE')},
                { name: 'type',         value : localizedMessages.get('TYPE_UPPERCASE')},
                { name: 'nameClk',      value : function(){ $scope.selectMenuItem({ value: "name" }) }},
                { name: 'dueClk',       value : function(){ $scope.selectMenuItem({ value: "dueDateLong" }) }},
                { name: 'statusClk',    value : function(){ $scope.selectMenuItem({ value: "progress" })}},
				{ name: 'typeClk',      value : function(){ $scope.selectMenuItem({ value: "type" })}},
				{ name: 'tabs',         value : $scope.tabs}
            ]
        }

		//Init call on page load
		$scope.initSurveyTab = function(tab) {
			var entID = bvUsageUtils.const.Entity.ID.Survey.Open;
			switch (tab) {
				case 'openSurveys':
					entID = bvUsageUtils.const.Entity.ID.Survey.Open;
					$scope.getMyOpenSurveys('open','0');
					break;
				case 'closedSurveys':
					entID = bvUsageUtils.const.Entity.ID.Survey.Closed;
					$scope.getMyClosedSurveys('closed','0');
					break;
				case 'ownedByMe':
					entID = bvUsageUtils.const.Entity.ID.Survey.Own;
					$scope.getMyOwnedSurveys('owned','0');
					break;
			}
			bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Survey, bvUsageUtils.const.Entity.Action.Survey.SelectedTab, entID);
		}
	}
SurveysMenuController.$inject = ['$scope', 'surveysContextMenu','contextMenuModel', 'bvDialog', 'localizedMessages', 'homeService', 'surveysAlertService']; 

function SurveysMenuController($scope, surveysContextMenu, contextMenuModel, bvDialog, localizedMessages, homeService, surveysAlertService)
{
	var showContextMenu = function()
	{
		$scope.contextMenuOption = surveysContextMenu.getContextMenu(contextMenuModel.multiple, contextMenuModel.selectedObject, contextMenuModel.parentObject, contextMenuModel.contextMenuCheckOptions);
		console.log($scope.contextMenuOption);
		var point = surveysContextMenu.getPosition(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedObject, _.filter($scope.contextMenuOption, function(o){return o.type != "SEPERATOR" }).length, 31, 210);
	
    	var event = contextMenuModel.event;
		
    	$scope.currentInstance = contextMenuModel.currentInstance;
        
        $scope.menuParentCallback("MENU_STYLE",[point]);
      	var arrow_point = surveysContextMenu.getArrowPosition(contextMenuModel.xPos, contextMenuModel.yPos,$scope.contextMenuOption.length, 31, 210);
        $scope.menuParentCallback("MENU_ARROW_STYLE",arrow_point) ;
	}
 	
 	$scope.openContextMenu = function(type)
    {
    	switch(type)
    	{
    		case 'overview': $scope.menuParentCallback("OVERVIEW", []);break;
    		case 'respond':  $scope.menuParentCallback("RESPOND",[]);break;
    		case 'edit': $scope.menuParentCallback("EDIT",[]);break;
    		case 'copy': $scope.menuParentCallback("COPY",[]);break;
    		case 'saveAsTemplate': $scope.menuParentCallback("SAVE_AS_TEMPLATE",[]);break;
    		case 'preFillSurvey': $scope.menuParentCallback("PRE_FILL_SURVEY",[]);break;
    		case 'status' : $scope.menuParentCallback("STATUS", []);break;
    		case 'report': $scope.menuParentCallback("REPORT",[]);break;
            case 'delete': $scope.menuParentCallback("DELETE",[]);break;
            case 'alert' : $scope.menuParentCallback("ALERT", []);break;
            case 'reopen' : $scope.menuParentCallback("REOPEN", []);break;
            case 'push' : $scope.menuParentCallback("PUSH", []);break;
    	}	    	
    }
	showContextMenu(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedContextItem, contextMenuModel.event);
}

SurveysContextMenu.$inject = ['localizedMessages', 'contextMenuSetting', 'context', 'homeModel','homeContext', 'surveysService', 'surveysAlertService', 'legacyUserService'];

function SurveysContextMenu(localizedMessages, contextMenuSetting, context, homeModel, homeContext, surveysService, surveysAlertService, legacyUserService) 
{
	var contextMenuOption = {};
	var sureysContextMenu = [];
	
	contextMenuOption['overview'] 	= { title:localizedMessages.get('OVERVIEW'), iconUrl: '', svgFill: '#fff', type: 'overview'};
	contextMenuOption['respond'] 	= { title:localizedMessages.get('RESPOND'), iconUrl: '', svgFill: '#fff', type: 'respond'};
	contextMenuOption['edit'] 	= { title:localizedMessages.get('EDIT'), iconUrl: '', svgFill: '#fff', type: 'edit'};
	contextMenuOption['copy'] 	= { title:localizedMessages.get('COPY_SURVEY'), iconUrl: '', svgFill: '#fff', type: 'copy'};
	contextMenuOption['saveAsTemplate'] 	= { title:localizedMessages.get('SAVE_AS_TEMPLATE'), iconUrl: '', svgFill: '#fff', type: 'saveAsTemplate'};
	contextMenuOption['preFillSurvey'] 	= { title:localizedMessages.get('PRE_FILL_SURVEY'), iconUrl: '', svgFill: '#fff', type: 'preFillSurvey'};
	contextMenuOption['status'] = { title:localizedMessages.get('STATUS'), iconUrl: '', svgFill: '#fff', type: 'status'};
	contextMenuOption['report'] 	= { title:localizedMessages.get('REPORT'), iconUrl: '', svgFill: '#fff', type: 'report'};
	contextMenuOption['delete'] = { title:localizedMessages.get('DELETE'), iconUrl: '', svgFill: '#fff', type: 'delete'};
	contextMenuOption['alert'] = { title:localizedMessages.get('SEND_ALERT'), iconUrl: '', svgFill: '#fff', type: 'alert'};
	contextMenuOption['reopen'] = { title:localizedMessages.get('REOPEN_SURVEY'), iconUrl: '', svgFill: '#fff', type: 'reopen'};
	contextMenuOption['push'] = { title:localizedMessages.get('PUSH'), iconUrl: '', svgFill: '#fff', type: 'push'};
	contextMenuOption['separator1'] = { title:localizedMessages.get('PUSH'), iconUrl: '', svgFill: '#fff', type: 'SEPERATOR'};
	contextMenuOption['separator2'] = { title:localizedMessages.get('PUSH'), iconUrl: '', svgFill: '#fff', type: 'SEPERATOR'};
	this.getContextMenu = function(isMultipleSelect, businessObject, parentContainer, contextMenuCheckOptions)
	{
		var surveysContextMenu = [];

		if(MobileUtil.isAndroid() || MobileUtil.isIPhone())
		{

		} 
		else 
		{
			surveysService.selectedSurveyUuid = businessObject.objectBaseUuid;
			surveysService.setCurrentSelectedSurveyFromList(businessObject);
//			surveysService.survey = businessObject;
			surveysAlertService.survey = businessObject;

			var currentUserVO = context.getModel().userVO;
			var isOwner = false;
			var ownerList = businessObject.owners;
			for(var i in ownerList) {
				if(ownerList[i].objectBaseUuid == currentUserVO.objectBaseUuid) {
					isOwner = true;
					break;
				}
			}
			var isCurrentUserRespondent = false;
			var isCurrentUserLocked = false;
			for(var i=0;i<businessObject.participantBaseUuidAndResponseList.length;i++){
				if(businessObject.participantBaseUuidAndResponseList[i].objectBaseUuid == context.getModel().userVO.objectBaseUuid){
					isCurrentUserRespondent = true;
					isCurrentUserLocked = businessObject.participantBaseUuidAndResponseList[i].isLocked;
					break;
				}
			}
			var pastDueDate = businessObject.dueDateGMTMillis < Date.now();
			if(!businessObject.isCBE){
				if(businessObject.isPublished && isCurrentUserRespondent && !isCurrentUserLocked && !businessObject.isClosed) surveysContextMenu.push(contextMenuOption['respond']);

				if((isOwner) && !MobileUtil.isSurface() && !businessObject.isPublished) surveysContextMenu.push(contextMenuOption['edit']);//TODO//TODO
				if((isOwner) && !MobileUtil.isSurface() && businessObject.isPublished) surveysContextMenu.push(contextMenuOption['status']);

				if(businessObject.isPublished && isCurrentUserRespondent) surveysContextMenu.push(contextMenuOption['overview']);
				if((isOwner && !MobileUtil.isSurface())) surveysContextMenu.push(contextMenuOption['delete']);
				
				if((isOwner && !MobileUtil.isSurface()) && !pastDueDate && businessObject.isPublished)
				{
//					surveysContextMenu.push(contextMenuOption['separator1']);
					surveysContextMenu.push(contextMenuOption['alert']);
//					surveysContextMenu.push(contextMenuOption['separator2']);
				}
				if(!MobileUtil.isSurface() && (isOwner) && businessObject.isPublished) surveysContextMenu.push(contextMenuOption['report']);
				if((isOwner && !MobileUtil.isSurface())&&businessObject.isPublished) surveysContextMenu.push(contextMenuOption['copy']);
				if(legacyUserService.isNasdaqUI) {
					if((isOwner && !MobileUtil.isSurface())) surveysContextMenu.push(contextMenuOption['saveAsTemplate']);
					if((isOwner && !MobileUtil.isSurface())&&businessObject.isPublished&&!businessObject.isAnonymous&& !businessObject.isClosed) surveysContextMenu.push(contextMenuOption['preFillSurvey']);
				}
				
				if((isOwner && !MobileUtil.isSurface()) && businessObject.isPublished) surveysContextMenu.push(contextMenuOption['reopen']);
				if((isOwner) && !pastDueDate && context.getModel().pushEnabled) surveysContextMenu.push(contextMenuOption['push']);

				
				
	//TODO
				
				

			
				if((isOwner || currentUserVO.isContentOwner) && !isCurrentUserLocked && !pastDueDate && context.getModel().pushEnabled) surveysContextMenu.push(contextMenuOption['push']);

			}
			if(businessObject.isCBE){
				   if(businessObject.createdByBaseUuid == currentUserVO.objectBaseUuid){
				      if((isOwner && !MobileUtil.isSurface())) surveysContextMenu.push(contextMenuOption['delete']);
				   }
			}
		  	return surveysContextMenu;
		}	  
	}

	this.getPosition = function(xPos, yPos, selectedContextItem, length, factor, width)
	{
		return contextMenuSetting.getPosition(xPos, yPos, selectedContextItem, length, factor, width)
	}

	this.getArrowPosition = function(xPos, yPos, length, factor, width)
	{
		return contextMenuSetting.getArrowPosition(xPos, yPos, length, factor, width)
	}
};

function SurveysMenu(){
    return{
        restrict: 'A',
        scope:{
        	menuStyle: '=',
        	menuParentCallback:'='
        },
        templateUrl: 'directives/contextmenu/ContextMenu.tpl.html',
        controller: 'surveysMenuController'
    }
}

function ContextMenuModel()
{
	this.selectedObject = {};
	this.parentObject = {};
	this.parentFolder = {};
	this.xPos = "";
	this.yPos = "";
	this.event = {};
	this.contextMenuCheckOptions = {};
	this.multiple = false;
	this.currentInstance = "";
}

//For survey details screen.
function OpenSurveyController($scope, $state, $stateParams, surveysService, repositoryService, imageService, localizedMessages, context, $modal, $modalStack,bvDialog, bvDialogService, teamService, commonService, surveysAlertService, homeService, reactInterface) {
	var bvModal;
	var ctrlName = "OpenSurveyController";

    $scope.tabs = [{
        title : localizedMessages.get("SURVEY"),
        content : 'openSurveys',
        rightMenuList:[]
    }]

	$scope.$parent.inMobileSurveys = false;

	if($scope.bvModalControllerInputs){
		$scope.surveyCreationPreviewMode = true;
		bvModal = $scope.bvModalControllerInputs.bvModal;
    	$scope.surveyDetails = $scope.bvModalControllerInputs.surveyDetails;
    	$scope.popupTitle = localizedMessages.get("PREVIEW");
	}else{
		$scope.middleBarObject.title = localizedMessages.get("SURVEY");
		$scope.middleBarObject.showBack = true;
		$scope.svgFill = '#FFF';
	}
    $scope.handleLeftIcon = function()
	{
        if(reactInterface.backButton && typeof reactInterface.backButton == "function")
            reactInterface.backButton()
        else{
            if(surveysService.isSurveyResponseDirty) {
                bvDialog.confirm(localizedMessages.get("LEAVE_EDITED_SURVEY_WITHOUT_SUBMIT_MSG"),
                    function(){
                        surveysService.isSurveyResponseDirty = false;
                        homeService.set_current_module_has_unsaved_changes(false, ctrlName);
                        $state.go('home.surveys');
                    }
                );
            } else {
                $state.go('home.surveys');
            }
        }
	}
    
//	$scope.surveyDetails = surveysService.getSurvey();
	$scope.userBaseUuid = $stateParams.userBaseUuid;
	$scope.surveyBaseUuid = $stateParams.surveyBaseUuid;
	$scope.reactInterface = reactInterface;
}
var backButtonStack = [];
var surveysModule = angular
	.module('surveys',['surveys.service', 'localization.service','ui.bootstrap.tpls','ui.bootstrap.carousel', 'ui.bootstrap.accordion', 'ui.bootstrap.tooltip', 'ui.bootstrap.modal','ui.bootstrap.dropdown','nvd3ChartDirectives', 'ngGrid', 'bvOpenSurvey', 'team.service', 'previewSurvey', 'saveSurveyTemplateFromContextMenu', 'infinite-scroll'])
	.config(configSurveysController)
	.controller('OpenSurveyController', ['$scope', '$state', '$stateParams', 'surveysService', 'repositoryService', 'imageService', 'localizedMessages', 'context', '$modal', '$modalStack','bvDialog','bvDialogService', 'teamService','commonService', 'surveysAlertService', 'homeService', 'reactInterface', OpenSurveyController])
	.controller('surveysMenuController', SurveysMenuController)
	.controller('SurveysController', SurveysController)
    .directive('surveysMenu', SurveysMenu)
    .service('surveysContextMenu', SurveysContextMenu)
    .service('contextMenuModel', ContextMenuModel);
