function InviteUserController(teamService, $scope,localizedMessages, bvDialog, bvDialogService, $timeout, constants, context) {

	var bvModal = $scope.bvModalControllerInputs.bvModal;

	$scope.actionBtnTxt	= localizedMessages.get("OK");
	$scope.closeBtnTxt 	= localizedMessages.get("CANCEL");

	$scope.inviteEmailsPlaceHolder = localizedMessages.get('INVITE_EMAILS_PLACE_HOLDER');
	$scope.inviteCaption = localizedMessages.get('INVITE');
	$scope.popupTitle = localizedMessages.get('INVITE_USER');
	$scope.emailAddressesTitle = localizedMessages.get('EMAIL_ADDRESS');
	$scope.emailInputGuideLable = localizedMessages.get('INVITE_EMAILS_INPUT_HELP');
	$scope.chhoseUserFromListLable = localizedMessages.get('SELECT_USER');
	$scope.orLable = localizedMessages.get("OR");
	$scope.fromListLable = localizedMessages.get("FROM_LIST");
	// $scope.okLable = localizedMessages.get('OK');
	// $scope.cancelLable = localizedMessages.get('CANCEL');

	$scope.existingCompanyUsers = [];
	$scope.existingCompanyUsersColumns = [{ displayName:'Name', field: 'name' }]
	$scope.multiSelect = true;

	$scope.inviteEmails = {
		text: '',
		errorColor: '#EEE'
	}

	var scrollDownEmails = function() {
		$timeout(function(){
			var textArea = angular.element(document.querySelector('.inviteEmail'));
			textArea[0].scrollTop = textArea[0].scrollHeight;
		},50);
	}
	// $scope.cancelInviteUser = function()
	$scope.close = function()
	{
		bvModal.close();
	}

	$scope.getCompanyUsersJSON = function()
	{
		if($scope.existingCompanyUsers.length!=0) return;

		teamService.getCompanyUsersJSON()
		.then(function(response)
		{
			$scope.existingCompanyUsers = response;
			console.log($scope.existingCompanyUsers);
		});
	}

	// $scope.inviteUsers = function()
	$scope.action = function()
	{
		// no emails provided and no user selected but
		if($scope.inviteEmails.text.trim() == '')
		{
			bvDialog.showMessage(localizedMessages.get('INVITE_EMAILS_NOT_PROVIDED'));
			return;
		}

		var delimiter = ",";
		var filter  = constants.get("EMAIL_REGEX");
		var error = false;
		var emails = $scope.inviteEmails.text.replace(/(\r\n|\n|\r)/gm,"").split(delimiter);
		var invalid_emails = "";

		var inviteUsersString = '';

		for(var index in emails) {
			var email = emails[index];
			var valid_email = '';
			if(email.charAt(0) == '[' && email.charAt(email.length-1) == ']') {
				valid_email = email.substring(1, email.length - 1);
			} else if(email != '' && email.search(filter) == -1) {
				error = true;
				if(invalid_emails != "") invalid_emails += ", ";
				invalid_emails += email;
			} else {
				valid_email = email;
			}

			if(inviteUsersString != '')
				inviteUsersString += ",";

			if(valid_email != '')
				inviteUsersString += valid_email;
		}

		if(error == true ) {
			bvDialog.showMessage(localizedMessages.get("INVALID_EMAIL_ADDRESSES") + ": " + invalid_emails);
			return;
		}

		$scope.inviteUser(inviteUsersString);
		bvModal.close();
	}

	$scope.inviteUser = function(inviteUsersString)
	{
		teamService.inviteUser(inviteUsersString)
		.then(function(response)
		{
			handleResponseForSubmit(response)
			var homeController = angular.element(document.querySelector('[home-controler]')).scope();
			var alsoImages = false;
        	homeController.getTeamUsers(alsoImages);
		})
	}
	
	var responseFromServer = "";
	
	function handleResponseForSubmit(response)
	{		
		responseFromServer = response;
		if(response && response != '')
		{
			var existingEmails = "";
			if(responseFromServer.length > 5)
			{
				responseFromServer.splice(5);
				existingEmails = responseFromServer.join(" ") + " ....";
			}
			else{
				existingEmails = responseFromServer.join(" ");
			}
			
			bvDialog.showMessage(localizedMessages.get("INVITE_EMAIL_ALREADY_EXISTS") + " " + existingEmails, "invite-user-width" );
			
//			if(context.getModel().enableN3aSubmitSelfOrdering){
//				bvDialog.userCreateConfirm(localizedMessages.get('OK'),$scope.cancelEdit,localizedMessages.get('INVITE_USER'));
//			}			
		}
		else
		{
//			if(context.getModel().enableN3aSubmitSelfOrdering){
//				bvDialog.userCreateConfirm(localizedMessages.get('OK'),$scope.cancelEdit,localizedMessages.get('INVITE_USER'));
//			}
//			else
//			{
				bvDialog.showMessage(localizedMessages.get("INVITE_USERS_SUCCESS"), null, true);
//			}
			$scope.inviteEmails.text = '';
		}
	}
	
	$scope.cancelEdit = function()
	{
//		if(responseFromServer && responseFromServer != '')
//		{
//			var existingEmails = responseFromServer.join();
//			bvDialog.showMessage(localizedMessages.get("INVITE_EMAIL_ALREADY_EXISTS") + " " + existingEmails );
//		}		
	}

	$scope.openUserSelection = function() {

		$scope.selectUsersLbl = localizedMessages.get("SELECT_USER")
		bvDialog.inviteUserSelection($scope.selectUsersLbl, $scope.existingCompanyUsers, $scope.onUserSelection);

		// bvModal = new modal.open({
  //           windowClass : 	'inviteUserSelectionDialog',
  //           templateUrl:  	'modules/team/InviteUserSelection.tpl.html',
  //           scope:      	$scope,
  //           // controller: 	'InviteUserController',
  //           backdrop: 		false,
		// });
	}

	$scope.onUserSelection = function(selectedUsers) {
		if(selectedUsers != false)
		{
			for(var i in selectedUsers)
			{
				var emails = $scope.inviteEmails.text.trim();
				if(emails != "" && emails.substring(emails.length-1)!=',') $scope.inviteEmails.text += ",\n";
				$scope.inviteEmails.text += "[" + selectedUsers[i].loginID + "]";
			}

			scrollDownEmails();
		}
	}

	$scope.inviteEmailChange = function($event) {
		if($event.keyCode == 188) {
			$scope.inviteEmails.text = $scope.inviteEmails.text + "\n";
			scrollDownEmails();
		}
	}

	$scope.getCompanyUsersJSON();
}


angular.module('inviteUser', [ 'team.service','localization.service','constants.service' ]).controller(
		'InviteUserController',
		[ 'teamService', '$scope','localizedMessages','bvDialog', 'bvDialogService','$timeout','constants','context',
				InviteUserController ]);
