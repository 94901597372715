MediaController.$inject = [ 'homeService', '$scope', '$modalInstance', '$modal', 'commonService', 'mediaModelService', 'documentContextMenu', 'repositoryService', 'localizedMessages'];

function MediaController(homeService, $scope, $modalInstance, modal, commonService, mediaModelService, documentContextMenu, repositoryService, localizedMessages)
{
	var mediaModelObject = mediaModelService.getInstance();
	$scope.multiMediaVO = mediaModelObject.businessObject;

	$scope.mediaTitle = $scope.multiMediaVO.name;
	$scope.morelabel = localizedMessages.get("MORE");
	$scope.UI_PRESENTERMODE_TXT = localizedMessages.get('PRESENTER_MODE');
	$scope.closelabel = localizedMessages.get("CLOSE");
	$scope.minimizelabel = localizedMessages.get("MINIMIZE");

	var contextMenu = null;

    function getMediaPoster()
    {
    	var mediaPoster = commonService.getDocumentThumbnail($scope.multiMediaVO.objectBaseUuid, $scope.multiMediaVO.objectVersionUuid);
    	return "data:image/png;base64," + mediaPoster;
    }

    $scope.close = function(result)
	{
		$scope.closeContextMenu();
		$modalInstance.dismiss('cancel');
	};

	$scope.minimize = function(result)
	{
		var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
		homeScope.addToActiveDocumentList($scope.multiMediaVO, false);
		$scope.closeContextMenu();
		$modalInstance.dismiss('cancel');
	};

	$scope.showContextMenu = function()
	{
		var contextMenuCheckOptions = {
				alreadySubscribed : false
		}

		if(contextMenu)
		{
			$scope.closeContextMenu();
			return;
		}

    	repositoryService.isAlreadySubscribed(mediaModelObject.businessObject).then(function(data)
    	{
    		contextMenuCheckOptions.alreadySubscribed = data.data;
    		mediaModelObject.businessObject.selectedOption = true;
    		mediaModelObject.contextMenuOptions = documentContextMenu.getContextMenu(false, mediaModelObject.businessObject, "VIEWER", contextMenuCheckOptions);


			contextMenu = modal.open({
				template: "<div class='media-context-menu-div'>"+
	    					"<ul data-role='menuListView' data-inset='true' id='menuList'>"+
	      						"<li ng-repeat='menu in contextMenuOption' >"+
	        						"<a href='' ng-click='openContextMenu(menu.type)'>{{menu.title}}</a>"+
	      						"</li>"+
	    					"</ul>"+
	  					"</div>",
				windowClass: "media-context-menu",
				backdrop: false,
				controller: function($scope, $modalInstance, mediaModelService, viewerContextMenu)
				{
					var mediaModelObject = mediaModelService.getInstance();
					$scope.contextMenuOption = mediaModelObject.contextMenuOptions;

					$scope.openContextMenu = function(type)
					{
						contextMenu.close();
						contextMenu = undefined;
						$modalInstance.dismiss('cancel');
						viewerContextMenu.openContextMenu(type, mediaModelObject.businessObject);
					};

					$scope.closeMenu = function(type)
					{
						$modalInstance.dismiss('cancel');
					}
				}
			});
    	});
	};

	$scope.closeContextMenu = function()
	{
		if(contextMenu)
		{
			contextMenu.close();
			contextMenu = undefined;
			return;
		}
	}
}