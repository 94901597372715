//inject dependencies
discussionsController.$inject = ['$q', '$scope', 'discussionsService', 'SortUtility', '$timeout', 'localizedMessages'];
function discussionsController($q, $scope, discussionsService, SortUtility, $timeout, localizedMessages) {
	$scope.discussionHeading = localizedMessages.get("DISCUSSIONS");
	$scope.viewPreviousComments = localizedMessages.get("VIEW_PREVIOUS_CCOMMENTS");
	$scope.placeHolder = localizedMessages.get("TYPE_TO_ADD_COMMENT");
	$scope.submitDiscussion = localizedMessages.get("SUBMIT");
	$scope.cancelDiscussion = localizedMessages.get("CANCEL");
	var userData = null;
	var portalData = null;
	var userList = null;
	var peopleList = [];
	var attachmentBoIds = "";
	var defaultThumbnail = 'images/avatar92.jpg';
	var defaultCommentsLimit = 3;
	var dateSortField = 'lastModified';
	$scope.showLoader = false;
	$scope.errorMessage = "";
	$scope.showError = false;
	$scope.attachments = [];
	$scope.replyCommentStore = {};
	$scope.childReplyCommentStore = {};
	$scope.editTypeaheadStorage = {};
	$scope.newPostStore = {}
	$scope.feeds = [];
	$scope.showViewMorePosts = false;
	var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
	initialize();

	//startsWith search user custom filter
	$scope.startsWith = function (actual, expected) {
		var lowerStr = (actual + "").toLowerCase();
		return lowerStr.indexOf(expected.toLowerCase()) === 0;
	}

	//Tag Users
	$scope.searchPeople = function (term) {
		if (userList != '') {
			peopleList = [];
			angular.forEach(userList, function (item) {
				if (item.name.toUpperCase().indexOf(term.toUpperCase()) >= 0) {
					item.imageUrl = !item.imageUrl ? getUserThumbnailByBaseId(item.objectBaseUuid) : item.imageUrl;
					peopleList.push(item);
				}
			});
			$scope.searchText = term;
			$scope.people = peopleList;
			return $q.when(peopleList);
		}
	};

	//Get user highlight text and save user.objectBaseUuid to storage
	$scope.getPeopleTextRaw = function (item, usersStorageName, objectBaseUuid) {
		var store = objectBaseUuid == null ? $scope[usersStorageName] : $scope[usersStorageName][objectBaseUuid];
		if (!store) {
			throw new Error('Invalid store:' + usersStorageName);
		}

		if (!store.usersIds) {
			store.usersIds = [];
		}
		store.usersIds.push(item);
		return '[@' + item.name + '@]';
	};


	// Add comment
	$scope.postComment = function () {
		if ($scope.newPostStore && $scope.newPostStore.input) {
			var usersIds = getUsersIdsFromStorage($scope.newPostStore.usersIds, $scope.newPostStore.input);
			var postTextHTML = encodePostString($scope.newPostStore.input);
			var request = discussionsService.postToWall($scope.pdfDetails.objectBaseUuid, postTextHTML, attachmentBoIds, usersIds);
			$scope.showLoader = true;
			request.then(function (response) {
				if (response && response.data) {
					$scope.feeds.unshift({
						objectBaseUuid: response.data.objectBaseUuid,
						feedId: "",
						portalBaseUuid: response.data.portalBaseUuid,
						description: response.data.description,
						createdBy: userData.displayName,
						createdOnHumanTime: response.data.createdOnHumanTime + " ago",
						createdOn: response.data.createdOn,
						createdByBaseUuid: response.data.createdByBaseUuid,
						userEncodedThumbnail: getUserThumbnailByBaseId(response.data.createdByBaseUuid),
						encodedThumbnailsList: response.data.encodedThumbnailsList,
						attachmentBOs: response.data.attachmentBOs,
						permissions: response.data.permissions,
						needToLimit: false
					});
					$scope.newPostStore = {};
					$scope.$broadcast('scrollTo', { id: 'user-comment' + $scope.convertToIdTag(response.data.objectBaseUuid) });
				}
			}).catch(function (error) {
				console.log(error);
			}).finally(function () {
				$timeout(function() {
					$scope.showLoader = false;
				}, 0);
			});
		}
	}

	// Reply comment
	$scope.replyComment = function (objectBaseUuid, feedObjectBaseUuid, feedId, portalBaseUuid, isChild) {
		var store = isChild ? $scope.childReplyCommentStore : $scope.replyCommentStore;
		if (store[objectBaseUuid] && store[objectBaseUuid].input) {
			$scope.showLoader = true;
			var usersIds = getUsersIdsFromStorage(store[objectBaseUuid].usersIds, store[objectBaseUuid].input);
			var htmlText = encodePostString(store[objectBaseUuid].input);
			discussionsService.postReplyComment(feedObjectBaseUuid, feedId, portalBaseUuid, htmlText, usersIds, $scope.pdfDetails.objectBaseUuid).then(function (response) {
				if (response.data.message == undefined) {
					$scope.$broadcast(isChild ? 'initiateReplySubmit' : 'replyCommentSubmit');
					var childObject = {
						description: decodeURIComponent(response.data.description),
						createdBy: userData.displayName,
						createdOnHumanTime: response.data.createdOnHumanTime + " ago",
						createdOn: response.data.createdOn,
						createdByBaseUuid: response.data.createdByBaseUuid,
						userEncodedThumbnail: getUserThumbnailByBaseId(response.data.createdByBaseUuid),
						objectBaseUuid: response.data.objectBaseUuid
					};

					var feed = getFeedById(feedObjectBaseUuid);
					if (feed) {
						if (feed.children == undefined) {
							feed.children = [];
						}
						feed.children.push(childObject);
						// move scroll to reply comment
						//$scope.$broadcast('scrollTo', { id: 'reply-message' + $scope.convertToIdTag(childObject.objectBaseUuid) });
					}
				} else {
					$scope.errorMessage = response.data.message;
					$scope.showError = true;
				}
				store[objectBaseUuid] = null;

			}).catch(function (error) {
				console.log(error);
			}).finally(function () {
				$timeout(function() {
					$scope.showLoader = false;
				}, 0);
			});
		}
	}

	// Delete comment
	$scope.deleteComment = function (objectBaseUuid) {
		$scope.showLoader = true;
		var request = discussionsService.deletePost(objectBaseUuid);
		request.then(function (response) {
			if (response.data.message == undefined) {
				var feed = getFeedById(objectBaseUuid);
				if (feed) {
					var index = $scope.feeds.indexOf(feed);
					if (index > -1) {
						$scope.feeds.splice(index, 1);
					}
				}
			}
			else {
				$scope.errorMessage = response.data.message;
				$scope.showError = true;
			}
		}).catch(function (error) {
			console.log(error);
		}).finally(function () {
			$timeout(function() {
				$scope.showLoader = false;
			}, 0);
		});
	}

	// Delete reply comment
	$scope.deleteReplyComment = function (objectBaseUuid, feedObjectBaseUuid) {
		$scope.showLoader = true;
		var request = discussionsService.deletePost(objectBaseUuid);
		request.then(function (response) {
			if (response.data.message == undefined) {
				var feed = getFeedById(feedObjectBaseUuid);
				if (feed && feed.children) {
					var item = feed.children.getItemByProperty('objectBaseUuid', objectBaseUuid);
					if (item) {
						var index = feed.children.indexOf(item);
						if (index > -1) {
							feed.children.splice(index, 1);
						}
					}
				}
			}
			else {
				$scope.errorMessage = response.data.message;
				$scope.showError = true;
			}
		}).catch(function (error) {
			console.log(error);
		}).finally(function () {
			$timeout(function() {
				$scope.showLoader = false;
			}, 0);
		});
	}

	//Show edit comment panel
	$scope.showEditComment = function (comment, objectBaseUuid) {
		comment.editMode = true;
		if (!$scope.editTypeaheadStorage[objectBaseUuid]) {
			$scope.editTypeaheadStorage[objectBaseUuid] = {};
		}
		$scope.editTypeaheadStorage[objectBaseUuid].input = angular.copy(comment.description);
	}

	function encodePostString(data)
	{
		return btoa(unescape(encodeURIComponent(data)));
	}
	
	function postEditCommentRequest(objectBaseUuid, updatedAttachmentBoIds) {
		var usersIds = getUsersIdsFromStorage($scope.editTypeaheadStorage[objectBaseUuid].usersIds, $scope.editTypeaheadStorage[objectBaseUuid].input);
		var postTextHtml = encodePostString($scope.editTypeaheadStorage[objectBaseUuid].input);
		return discussionsService.editPost(objectBaseUuid, postTextHtml, updatedAttachmentBoIds, usersIds, $scope.pdfDetails.objectBaseUuid);
	}

	//Edit comment 
	$scope.editComment = function (objectBaseUuid, updatedAttachmentBoIds) {
		if (!$scope.editTypeaheadStorage[objectBaseUuid] || !$scope.editTypeaheadStorage[objectBaseUuid].input) {
			return;
		}
		var request = postEditCommentRequest(objectBaseUuid, updatedAttachmentBoIds);
		$scope.showLoader = true;
		request.then(function (response) {
			if (response && response.data) {
				$scope.$apply(function () {
					var feed = getFeedById(objectBaseUuid);
					if (feed) {
						feed.editMode = false;
						feed.description = response.data.description;
						feed.createdOnHumanTime = response.data.createdOnHumanTime + " ago";
						feed.lastModified = response.data.lastModified;
						$scope.editTypeaheadStorage[objectBaseUuid] = {};

						var feedIndex = $scope.feeds.indexOf(feed);
						if (feedIndex > -1) {
							// move edited feed to top
							$scope.feeds.move(feedIndex, 0);
							$scope.$broadcast('scrollTo', { id: 'user-comment' + $scope.convertToIdTag(response.data.objectBaseUuid) });
						}
					}
				});
			}
		}).catch(function (error) {
			console.log(error);
		}).finally(function () {
			$timeout(function() {
				$scope.showLoader = false;
			}, 0);
		});
	};

	$scope.editReplyComment = function (feedObjectBaseUuid, objectBaseUuid, updatedAttachmentBoIds) {
		if (!$scope.editTypeaheadStorage[objectBaseUuid] || !$scope.editTypeaheadStorage[objectBaseUuid].input) {
			return;
		}
		var request = postEditCommentRequest(objectBaseUuid, updatedAttachmentBoIds);
		$scope.showLoader = true;
		request.then(function (response) {
			if (response && response.data) {
				$scope.$apply(function () {
					var feed = getFeedById(feedObjectBaseUuid);
					if (feed) {
						var child = feed.children.getItemByProperty('objectBaseUuid', objectBaseUuid);
						if (child) {
							child.editMode = false;
							child.description = response.data.description;
							child.createdOnHumanTime = response.data.createdOnHumanTime + " ago";
							child.lastModified = response.data.lastModified;
							$scope.editTypeaheadStorage[objectBaseUuid] = {};

							var childIndex = feed.children.indexOf(child);
							if (childIndex > -1) {
								feed.children.move(childIndex, feed.children.length - 1);
								// move scroll to reply comment
								//$scope.$broadcast('scrollTo', { id: 'reply-message' + $scope.convertToIdTag(objectBaseUuid) });
							}
						}
					}
				});
			}
		}).catch(function (error) {
			console.log(error);
		}).finally(function () {
			$timeout(function() {
				$scope.showLoader = false;
			}, 0);
		});
	}

	//Convert object objectBaseUuid to html id tag.For example: id="user-comment0PRTLGXNXUC-4D7E77E16DE7411EAF200364E0CC5DBC""
	$scope.convertToIdTag = function (objectBaseUuid) {
		return objectBaseUuid.split(':').join('');
	}

	//Get reply comments for post
	$scope.getPostComments = function (feed) {
		$scope.showLoader = true;
		discussionsService.getPostComments(feed.objectBaseUuid).then(function (response) {
			if (response && response.data && angular.isArray(response.data)) {
				$scope.$apply(function () {
					feed.needToLimit = false;
					response.data.forEach(function (child) {
						var item = feed.children.getItemByProperty('objectBaseUuid', child.objectBaseUuid);
						if (!item) {
							feed.children.unshift(child);
						}
					});
					feed.children = angular.copy(SortUtility.sortByField(feed.children, dateSortField));
					feed.children.forEach(function (child) {
						child.userEncodedThumbnail = getUserThumbnailByBaseId(child.createdByBaseUuid);
					});
				});
			}
		}).finally(function () {
			$timeout(function() {
				$scope.showLoader = false;
			}, 0);
		});
	}

	//Get more posts
	$scope.getMorePosts = function () {
		$scope.showLoader = true;
		getFeedsForDiscussion($scope.showFeedsOffset).then(function (response) {
			if (response) {
				var newfeeds = convertFeedsToContract(response.data.feeds);
				newfeeds.forEach(function (item) {
					$scope.feeds.push(item);
				});
				if (newfeeds[0]) {
					$scope.$broadcast('scrollTo', { id: 'user-comment' + $scope.convertToIdTag(newfeeds[0].objectBaseUuid) });
				}
				$scope.showViewMorePosts = response.data.hasMoreItems;
				$scope.showFeedsOffset = response.data.offset;
			}
		}).finally(function () {
			$timeout(function() {
				$scope.showLoader = false;
			}, 0);
		});
	}

	function getFeedsForDiscussion(offset) {
		var position = !offset ? -1 : offset;
		if($scope.pdfDetails.objectBaseUuid!=null && $scope.pdfDetails.objectBaseUuid!="")
		return discussionsService.getFeedsForDiscussion($scope.pdfDetails.objectBaseUuid, 'DOC_COMMENT', position);
		console.log("DOC_COMMENT");
		console.log($scope.pdfDetails.objectBaseUuid);
		console.log(position);
	}

	function validateUsers(usersIds, input) {
		return usersIds.filter(function (item) {
			return input.indexOf(item.name) !== -1;
		});
	}

	function getUsersIdsFromStorage(storage, input) {
		if (!storage || !input) {
			return [];
		}
		var users = validateUsers(storage, input);
		var ids = users.map(function (item) { return item.objectBaseUuid; });
		return ids.unique();
	}

	function getFeedById(objectBaseUuid) {
		return $scope.feeds.getItemByProperty('objectBaseUuid', objectBaseUuid);
	}

	function getUserThumbnailByBaseId(objectBaseUuid) {
		if (homeScope && homeScope.getUserThumbnailById instanceof Function) {
			var thumbnail = homeScope.getUserThumbnailById(objectBaseUuid);
			return !thumbnail ? defaultThumbnail : thumbnail;
		}
		return defaultThumbnail;
	};

	function convertFeedsToContract(feedsDTO) {
		if (feedsDTO.length === 0) {
			return [];
		}

		var feeds = angular.copy(SortUtility.sortByField(feedsDTO, dateSortField, true));
		feeds.forEach(function (feed, key) {
			feed.userEncodedThumbnail = getUserThumbnailByBaseId(feed.createdByBaseUuid);
			feed.children = angular.copy(SortUtility.sortByField(feed.children, dateSortField));
			feed.children.forEach(function (child) {
				child.userEncodedThumbnail = getUserThumbnailByBaseId(child.createdByBaseUuid);
			});
			feed.needToLimit = feed.repliesCount > defaultCommentsLimit;
		});
		return feeds;
	}

	function initialize() {
		$scope.showLoader = true;
		$q.all([
			discussionsService.getPortalData().then(function (response) {
				if (response) {
					var userJson = JSON.stringify(response.userVO);
					var portalJson = JSON.stringify(response.portalVO);
					userData = response.userVO;
					portalData = response.portalVO;
					return userData, portalData;
				}
			}),
			discussionsService.getUserList().then(function (response) {
				if (response) {
					var companyTreeJson = JSON.stringify(response.data.USER_LIST);
					userList = JSON.parse(companyTreeJson);
					return userList;
				}
			})
		])
		.then(function () {
			getFeedsForDiscussion().then(function (response) {
				if (response) {
					$scope.feeds = convertFeedsToContract(response.data.feeds);
					$scope.showViewMorePosts = response.data.hasMoreItems;
					$scope.showFeedsOffset = response.data.offset;
					
				}
			}).finally(function () {
				$timeout(function() {
					$scope.showLoader = false;
				}, 0);
			});
		});
	};

	$scope.$watch('feeds', function (newVal, oldVal) {
		$timeout(function () {
			$scope.$broadcast('rendering-done');
		}, 0);
	},true);

	if (!Array.prototype.move) {
		Object.defineProperty(Array.prototype, "move", {
			enumerable: false,
			value: function (oldIndex, newIndex) {
				if (newIndex >= this.length) {
					var k = newIndex - this.length;
					while ((k--) + 1) {
						this.push(undefined);
					}
				}
				this.splice(newIndex, 0, this.splice(oldIndex, 1)[0]);
				return this;
			}
	  });
	}
	if (!Array.prototype.getItemByProperty) {
		Object.defineProperty(Array.prototype, "getItemByProperty", {
			enumerable: false,
			value: function (propertyName, propertyValue) {
				var arr = this.filter(function (item) {
					return item[propertyName] === propertyValue;
				});
				return arr && arr.length > 0 ? arr[0] : null;
			}
		});
	}
	if (!Array.prototype.unique) {
		Object.defineProperty(Array.prototype, "unique", {
			enumerable: false,
			value: function () {
				var obj = {}, result = [];
				for (var i = 0, l = this.length; i < l; ++i) {
					if (obj.hasOwnProperty(this[i])) {
						continue;
					}
					result.push(this[i]);
					obj[this[i]] = 1;
				}
				return result;
			}
		});
	}   
}