SortUtility.$inject = ['$filter', 'localizedMessages'];

function SortUtility($filter,localizedMessages) {

	//var defaultSortByItem = [{name:localizedMessages.get('NAME'), value:"name"},{name:localizedMessages.get('DATE_MODIFIED'), value:"lastModifiedOnGMTMillis"},{name:localizedMessages.get('SIZE'), value:"fileSize"}];
	var defaultSortByItem = [{name:localizedMessages.get('NAME'), value:"name"},{name:localizedMessages.get('DATE_MODIFIED'), value:"lastModifiedOnGMTMillis"}, {name:localizedMessages.get('TYPE'), value:"extensionType"}];
	var reminderSortItem = [{name:localizedMessages.get('NAME'), value:"name"},{name:localizedMessages.get('DATE_MODIFIED'), value:"lastModifiedOnGMTMillis"} ];

	this.getDefaultSortByItem = function()
	{
		return defaultSortByItem;
	};

	var orderBy = $filter('orderBy');

	this.sortByField = function(dataList, sortField,reverse)
	{
		var _reverse = (reverse==null)?false:reverse;
		return orderBy(dataList, sortField, _reverse);
	};

}
