searchIndexingController.$inject = ['$scope', 'localizedMessages', 'searchIndexingService', 'context', 'responseValidatorService', 'homeModel'];

function searchIndexingController($scope, localizedMessages, searchIndexingService, context, responseValidatorService, homeModel){

    var bvModal = $scope.bvModalControllerInputs.bvModal;

    $scope.popupTitle 	= localizedMessages.get('SEARCH_INDEXING');

    $scope.title = {
        engineStatus : localizedMessages.get('ENGINE_STATUS'),
        indexStatus : localizedMessages.get('INDEX_STATUS'),
        indexableItems : localizedMessages.get('INDEXABLE_ITEMS'),
        checkStatus : localizedMessages.get('CHECK_STATUS'),
        removeIndex : localizedMessages.get('REMOVE_INDEX'),
        file : localizedMessages.get('FILE'),
        folder : localizedMessages.get('FOLDER'),
        totals : localizedMessages.get('TOTALS'),
        type : localizedMessages.get('TYPE_UPPERCASE'),
        total : localizedMessages.get('TOTAL_UPPERCASE'),
        indexed : localizedMessages.get('INDEXED'),
        nonIndexed : localizedMessages.get('NON_INDEXED'),
        rebuildIndex : localizedMessages.get('REBUILD_INDEX'),
        updateIndex : localizedMessages.get('UPDATE_INDEX'),
        available : localizedMessages.get('AVAILABLE'),
        notAvailable : localizedMessages.get('NOT_AVAILABLE'),
        found : localizedMessages.get('FOUND'),
        notFound : localizedMessages.get('NOT_FOUND'),
        enableSearch : localizedMessages.get('ENABLE_SEARCH'),
        disableSearch : localizedMessages.get('DISABLE_SEARCH'),
        inProgress : localizedMessages.get('IN_PROGRESS_UPPERCASE'),
		indexCreationInProgress : localizedMessages.get('IN_PROGRESS')
    }

    $scope.checkStatus = {
        isSearchEngineAvailable: false,
        isSearchIndexAvailable: false
    }

    $scope.indexableItems = {
        file: {
            total: 0,
            inProgress: 0,
            indexed: 0,
            nonIndexed: 0
        },
        folder: {
            total: 0,
            inProgress: 0,
            indexed: 0,
            nonIndexed: 0
        },
        totals: {
            total: 0,
            inProgress: 0,
            indexed: 0,
            nonIndexed: 0
        }
    }

    $scope.searchEnabled = homeModel.isSearchEnabled;

    $scope.enableDisableSearch = function(){
        if(homeModel.isSearchEnabled.val){
            searchIndexingService.searchDisableFullTextSearch().then(function(data){
                if(responseValidatorService.isSuccess(data)){
                    homeModel.isSearchEnabled.val = false;
                }
            });
        }
        else{
            searchIndexingService.searchEnableFullTextSearch().then(function(data){
                if(responseValidatorService.isSuccess(data)){
                    homeModel.isSearchEnabled.val = true;
                }
            });
        }
    }

    $scope.checkStatusClk = function(){
    }
    $scope.removeIndexClk = function(){
        searchIndexingService.searchDeleteFullTextSearchIndex().then(function(data){
        });
    }
    $scope.updateIndexClk = function(){
        searchIndexingService.searchAddItemsToIndex().then(function(data){
        });
    }
    $scope.rebuildIndexClk = function(){
        searchIndexingService.searchRebuildIndex().then(function(data){
        });
    }

    var getReport = function(){
		 searchIndexingService.searchGetSearchIndexReport().then(function(data){
            if(data){
                var metadataUploaded = 0;
                var contentUploaded = 0;
				$scope.indexableItems.file.nonIndexed = 0;
				$scope.indexableItems.file.inProgress = 0;
                _.each(data.searchResultMap.file, function(o){
                     switch(o.status){
                        case 'failed': $scope.indexableItems.file.nonIndexed = o.count; break;
                        case 'inProcessing': $scope.indexableItems.file.inProgress = o.count; break;
                        case 'metadataUploaded': metadataUploaded = o.count; break;
                        case 'contentUploaded': contentUploaded = o.count; break;
                        case 'notProcessed': break;
                     }
                });

                $scope.indexableItems.file.indexed = metadataUploaded + contentUploaded;
                metadataUploaded = 0;
                contentUploaded = 0;
				$scope.indexableItems.folder.nonIndexed = 0;
				$scope.indexableItems.folder.inProgress = 0;
                _.each(data.searchResultMap.folder, function(o){
                     switch(o.status){
                        case 'failed': $scope.indexableItems.folder.nonIndexed = o.count; break;
                        case 'inProcessing': $scope.indexableItems.folder.inProgress = o.count; break;
                        case 'metadataUploaded': metadataUploaded = o.count; break;
                        case 'contentUploaded': contentUploaded = o.count; break;
                        case 'notProcessed': break;
                     }
                });

                $scope.indexableItems.folder.indexed = metadataUploaded + contentUploaded;

                $scope.indexableItems.file.total = $scope.indexableItems.file.indexed + $scope.indexableItems.file.nonIndexed + $scope.indexableItems.file.inProgress;
                $scope.indexableItems.folder.total = $scope.indexableItems.folder.indexed + $scope.indexableItems.folder.nonIndexed + $scope.indexableItems.folder.inProgress;

                $scope.indexableItems.totals.total = $scope.indexableItems.file.total + $scope.indexableItems.folder.total;
                $scope.indexableItems.totals.inProgress = $scope.indexableItems.file.inProgress + $scope.indexableItems.folder.inProgress;
                $scope.indexableItems.totals.indexed = $scope.indexableItems.file.indexed + $scope.indexableItems.folder.indexed;
                $scope.indexableItems.totals.nonIndexed = $scope.indexableItems.file.nonIndexed + $scope.indexableItems.folder.nonIndexed;

                $scope.checkStatus.isSearchEngineAvailable = data.isSearchEngineAvailable;
                $scope.checkStatus.isSearchIndexAvailable = data.isSearchIndexAvailable;

				if(data.isSearchEngineAvailable){
				   if(data.isSearchIndexAvailable){
					  $scope.resetSearchIndexCreationVal($scope.title.notFound, false);
				   }else{
					  if(data.isSearchIndexCreationInProgress){
						$scope.resetSearchIndexCreationVal($scope.title.indexCreationInProgress, true);
					  }else{
						$scope.resetSearchIndexCreationVal($scope.title.notFound, false);
					}
				   }
				}
            }
        });
    }

    $scope.resetSearchIndexCreationVal = function(title, flag){
        $scope.title.indexNotFound = title;
		$scope.isSearchIndexCreationInProgress = flag;
    }
    
	$scope.resetSearchIndexCreationVal($scope.title.notFound, false);
    getReport();

    var interval = setInterval(function(){
        getReport();
    }, 5000);

    $scope.bvModalControllerInputs.closeButton = function(){
        clearInterval(interval);
        bvModal.close();
    }
}