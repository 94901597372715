function confirmController($scope, localizedMessages){

	$scope.actionBtnTxt	= localizedMessages.get('YES');
	$scope.closeBtnTxt 	= localizedMessages.get('NO');

	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var actionTrue = $scope.bvModalControllerInputs.actionTrue;
	var actionFalse = $scope.bvModalControllerInputs.actionFalse;
	$scope.htmlText = $scope.bvModalControllerInputs.htmlText;

	if(actionTrue != null && typeof actionTrue  == 'function'){
		$scope.action = function(){
			actionTrue();
			bvModal.close();
		}
	}

	if(actionFalse != null && typeof actionFalse  == 'function'){
		$scope.close = function(){
			actionFalse();
			bvModal.close();
		}
		$scope.actionNo = function(){
			actionFalse();
			bvModal.close();
		}
	}

	$scope.questionText = $scope.bvModalControllerInputs.questionText;
}

function confirmBankIdController($scope, localizedMessages){

	$scope.actionBtnTxt	= localizedMessages.get('YES');
	$scope.closeBtnTxt 	= localizedMessages.get('NO');

	var bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.htmlText = $scope.bvModalControllerInputs.htmlText;
	$scope.questionText = $scope.bvModalControllerInputs.questionText;
	
	var actionTrue = $scope.bvModalControllerInputs.submit;
	var actionFalse = $scope.bvModalControllerInputs.cancel;

	if(actionTrue != null && typeof actionTrue  == 'function'){
		$scope.submit = function(){
			actionTrue($('#txtBankIdPin').val());
			bvModal.close();
		}
	}
	
	if(actionFalse != null && typeof actionFalse  == 'function'){
		$scope.close = function(){
			actionFalse();
			bvModal.close();
		}
	}
}

function showMessageController($scope){

	$scope.questionText = $scope.bvModalControllerInputs.questionText;
	$scope.htmlText = $scope.bvModalControllerInputs.htmlText;
}

function showMessageIdleController($scope, bvDialogService){

	$scope.questionText = bvDialogService.getShowMessageIdle();
}

function installJavaController($scope, context, localizedMessages, bvDialog){
	
	$scope.ui = {
			label : {
						doYouWantToInstallJava					: localizedMessages.get('DO_YOU_WANT_TO_INSTALL_JAVA'),
						unSupportCertificate					: localizedMessages.get('UN_SUPPORT_CERTIFICATE'),
						javaNotInstalledMsg						: localizedMessages.get('JAVA_NOT_INSTALLED_MSG'),
						installJava								: localizedMessages.get('INSTALL_JAVA'),
			}

	}
	var bvModal = $scope.bvModalControllerInputs.bvModal;;
	$scope.actionBtnTxt 							= localizedMessages.get('YES');
	$scope.closeBtnTxt 								= localizedMessages.get('NO');
	$scope.popupTitle = localizedMessages.get('INSTALL_JAVA');
	$scope.action = function(){
		deployJava.installLatestJRE();
	}
	$scope.close = function(e){
		bvModal.close(); 
	}
}

function unSupportCertificateController($scope, context, localizedMessages, bvDialog, loginService){
	
	$scope.ui = {
			label : {
						unSupportCertificate					: localizedMessages.get('UN_SUPPORT_CERTIFICATE')
			}

	}
	var bvModal = $scope.bvModalControllerInputs.bvModal;;
	$scope.popupTitle = localizedMessages.get('CERTIFICATE_NOT_SUPPORTED');	
	loginService.clearLoginContext();
}

angular
	.module('dialogControllers.service',['approvals.service','angular-clipboard'])
	.controller('confirmController', ['$scope', 'localizedMessages', confirmController])
	.controller('confirmBankIdController', ['$scope', 'localizedMessages', confirmBankIdController])
	.controller('showMessageController', ['$scope', showMessageController])
	.controller('showMessageIdleController', ['$scope', 'bvDialogService', showMessageIdleController])
	.controller('installJavaController', ['$scope', 'context', 'localizedMessages', 'bvDialog', installJavaController])
	.controller('unSupportCertificateController', ['$scope', 'context', 'localizedMessages', 'bvDialog','loginService', unSupportCertificateController])	

	.directive('bvRadioDisable', function(){
	  return {
		    scope:{
		      enable: "="
		    },
		    link: function($scope, $el, $attrs){
		      if(!$scope.enable){
		        $el[0].setAttribute('disabled','');
		      }else{
		        $el[0].removeAttribute('disabled');
		      }
		    } 
		  }
		})
    