angular
	.module('dialogControllers.service')
	.controller('challengeQuestionController', challengeQuestionController);

challengeQuestionController.$inject = [ '$scope', 'localizedMessages', 'context', 'homeService', 'bvDialog', 'bvDialogService', 'challengeQuestionsService'];

function challengeQuestionController($scope, localizedMessages, context, homeService, bvDialog, bvDialogService, challengeQuestionsService) {

	$scope.popupTitle 								= localizedMessages.get('CHALLENGE_QUESTIONS');
	$scope.actionBtnTxt 							= localizedMessages.get('OK');
	$scope.closeBtnTxt 								= localizedMessages.get('CANCEL');
	$scope.answerTxt 								= localizedMessages.get("ANSWER")

	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var isMobile = $scope.bvModalControllerInputs.isMobile != null ? $scope.bvModalControllerInputs.isMobile : false;
	$scope.mobileActionButton	= isMobile;
	$scope.mobileActionButtonImagePath = $scope.bvModalControllerInputs.mobileActionButtonImagePath || "";
	
	$scope.mobileActionButtonImagePath = 'resources/assets/images/icon_save.png';

	$scope.currentUserObjectBaseUuid = context.getModel().userVO.objectBaseUuid;

	$scope.answerList = [];

	$scope.answersChanged = (answers) => {
		$scope.answerList = answers;
	}

	$scope.action = () => {
		var message = challengeQuestionsService.validate($scope.answerList);
		if( message != null ) {
		    bvDialogService.showBvAlertMessage(message);
		    return;
		}

		var encryptedAnswers = [...$scope.answerList];

		encryptedAnswers = challengeQuestionsService.encryptIfNeeded(encryptedAnswers, context.homeModel);

		var userPortal = context.getModel().portalVO.objectBaseUuid;
		var mappedAnswers = encryptedAnswers.map(item => { return {label: item.question, val: item.answer}; });
		homeService.editChallengeQuestions(angular.toJson(mappedAnswers), userPortal);
		bvModal.close();
    }

	$scope.isOpen = [false, false, false];
}