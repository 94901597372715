angular
    .module('dialogControllers.service')
	.controller('eventsDialogController', eventsDialogController);
	
eventsDialogController.$inject = ['$scope', '$element', 'localizedMessages', '$rootScope', '$state', '$window', 'dialogArrowService', '$timeout', 'bvDialogMobile'];

function eventsDialogController($scope, $element, localizedMessages, $rootScope, $state, $window, dialogArrowService, $timeout, bvDialogMobile){
	var event = $scope.bvModalControllerInputs.event;
	var numberOfEvents = $scope.bvModalControllerInputs.cell.events.length || 0;
	var bvModal = $scope.bvModalControllerInputs.bvModal;

	//$element[0].parentElement.parentElement.style.marginTop = event.pageY - 200 - (numberOfEvents > 5 ? 300 : (numberOfEvents * 60))  + 'px';
	
	dialogArrowService.event = event;
	
	if(!MobileUtil.isAndroid() && !MobileUtil.isIPhone()){
		
		var width = $window.innerWidth;
		var height = $window.innerHeight;
		
		if(MobileUtil.isSurface()){
			var zoom = parseFloat(parseInt((document.querySelector('body > div').currentStyle).zoom) / 100) || 1;
			 event.pageX =  event.pageX / zoom
			 event.pageY =  event.pageY / zoom;
			 width = width / zoom;
		}
		
		if(event.pageX + 390 < width){
			$scope.arrowLeft = true;
			$element[0].parentElement.parentElement.style.left = event.pageX + 30 + 'px';
		}
		else{
			$scope.arrowLeft = false;
			$element[0].parentElement.parentElement.style.left = event.pageX - 360 + 'px';
		}
		
		var headerTop = 310;
		var delta = 0;
		if((event.pageY - headerTop) < (height - headerTop)/3){
			delta = 100;
		}
		else{
			delta = 300;
		}
	
		if(MobileUtil.isSurface()){
			if((event.pageY - headerTop) < (height - headerTop)/3 - 100){
				delta = 100;
			}
			else{
				delta = 300;
			}
			
			$element[0].parentElement.parentElement.style.top = (event.pageY - delta) + 'px';
		}
		else{
			$element[0].parentElement.parentElement.style.top = (event.pageY - delta - 150) + 'px';
		}
	}
	
	$scope.dateTitle = moment($scope.bvModalControllerInputs.cell.date).format('LL');
	$scope.scrollListOptions = {
		columns : [
    		           	{ title : '' , width : 100, name : 'name' }
		],
		data : $scope.bvModalControllerInputs.cell
    }
	
//	$timeout(function(){
//		$timeout(function(){
//			var heightOfItem = 80;
//			var offsetHeight = $element[0].children[0].children[0].children[2].children[1].offsetHeight;
//			var numberOfItems = $element[0].children[0].children[0].children[2].children[1].children[0].children.length;
//			var value = (offsetHeight/heightOfItem).toString();
//			var fraction = value.substr(value.indexOf('.'));
//			var int = parseInt(value);
//			
//			if(numberOfItems * heightOfItem > offsetHeight){
//				if(fraction > 0.5){
//					$element[0].parentNode.style.height = 'calc(45% + ' + (heightOfItem - (offsetHeight - (heightOfItem * int))) + 'px)';
//				}
//				else{
//					$element[0].parentNode.style.height = 'calc(45% - ' + (offsetHeight - (heightOfItem * int)) + 'px)';
//				}
//			}
//		},0)
//	},0);
	
	$scope.getLocalizedDate = function(date) {
		return localizedMessages.getLocaleDate(date);
	}

	$scope.getDisplayTime = function (calEvent)
	{
		if(calEvent!=undefined)
		{
			var startTime = moment(parseInt(calEvent.startDateGMTMillis)).format('LT');
			var endTime = moment(parseInt(calEvent.endDateGMTMillis)).format('LT');
			return startTime + " - " + endTime;
		}
	}

	$scope.clickEvent = function(eventObj){
		if(MobileUtil.isAndroid() ||  MobileUtil.isIPhone()){
			bvDialogMobile.mobileMeetingDetails($scope.bvModalControllerInputs.cell.events, eventObj.data.objectBaseUuid);
		}
		else{
			var dateValue = StringUtils.momentL($scope.bvModalControllerInputs.cell.date).format('ll');
			var SELECTED_EVENT = eventObj.data.objectBaseUuid;
			var EVENTS_LIST = { "DATE" : dateValue, "EVENTS" : $scope.bvModalControllerInputs.cell.events,  "SELECTED_EVENT" : SELECTED_EVENT};
			$rootScope.EVENTS_LIST = EVENTS_LIST;
			$state.go('home.meetingDetails');
			bvModal.close();
		}
	}
}