prefillSurveyController.$inject =  [ '$scope','$state', 'localizedMessages', 'commonService','context', 'homeService','bvDialog', 'bvDialogService','surveysService'];

function prefillSurveyController($scope, $state, localizedMessages, commonService, context, homeService,bvDialog, bvDialogService, surveysService){
	$scope.popupTitle = localizedMessages.get('SELECT_RECIPIENT');
	var bvModal;
	bvModal = $scope.bvModalControllerInputs.bvModal;
	
	$scope.actionBtnTxt = localizedMessages.get('SELECT_RECIPIENT');
    $scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
    $scope.respondedTxt = localizedMessages.get('RESPONDED');
    
	$scope.ui = {
			label : {
            	cancel : localizedMessages.get('CANCEL'),
			}
	}
	$scope.UI_FILTER = localizedMessages.get('SEARCH_TO_REFINE');
	$scope.surveyObjectBaseUuid = $scope.bvModalControllerInputs.surveyObjectBaseUuid;
	$scope.recipients = $scope.bvModalControllerInputs.recipients;
	$scope.searchUser = {name: ''};
	$scope.selectedUserUuid="";
	
	$scope.selectUser = function(user){
		surveysService.setPreFillingUserBaseUuid(user.objectBaseUuid);
		$scope.selectedUserUuid=user.objectBaseUuid
	}
	
	$scope.action = function() {
		bvModal.close();
		$state.go('home.openSurvey', {userBaseUuid: surveysService.preFillingUserBaseUuid, surveyBaseUuid: $scope.surveyObjectBaseUuid});
	}
}