function ContextMenuModel()
{
    this.selectedObject = {};
    this.parentObject = {};
    this.parentFolder = {};
    this.xPos = "";
    this.yPos = "";
    this.event = {};
    this.contextMenuCheckOptions = {};
    this.multiple = false;
    this.currentInstance = "";
}