bvUserSelectionList.$inject = ['imageService'];

function bvUserSelectionList(imageService) {
	return {
		restrict: 'A',
		scope: {
		  users: '=',
		  selectionDone: '=',
		  columns: '=',
		  multiSelectFlag: '=',
		  showCustomList: '=',
		},
		templateUrl: 'directives/bvuserselectionlist/bvUserSelectionList.template.html',
		controller: function($scope, localizedMessages)
		{

     		$scope.selectedUsers = [];
 			$scope.filterOptions = {filterText: "", userExternalFilter: true};
 			$scope.filterLbl = localizedMessages.get("FILTER");
 			$scope.cancelLbl = localizedMessages.get("CANCEL");
 			$scope.okLbl = localizedMessages.get("OK");

			$scope.save = function()
			{
				clearSelection();
				$scope.selectionDone($scope.selectedUsers);
		    };

		    $scope.cancel = function()
		    {
		    	clearSelection();
		    	$scope.selectionDone(false);
			};

			var clearSelection = function()
			{
				for(var i in $scope.selectedUsers)
				{
					$scope.selectedUsers[i].selected = false;
				}
			}

			$scope.userSelectionChange = function(user)
			{
				if(user.selected)
				{
					var selectedIndex = $scope.selectedUsers.indexOf(user);
					$scope.selectedUsers.splice(selectedIndex,1);

					var userIndex = $scope.users.indexOf(user);
					$scope.users[userIndex].selected = false;
				}
				else
				{
					var userIndex = $scope.users.indexOf(user);
					$scope.users[userIndex].selected = true;

					if($scope.multiSelectFlag)
					{
						$scope.selectedUsers.push($scope.users[userIndex]);
					}
					else
					{
						if($scope.selectedUsers.length >= 1)
							$scope.selectedUsers[0].selected = false;

						$scope.selectedUsers[0] = $scope.users[userIndex];
					}
				}
			}

			$scope.userSortableOptions = {
				placeholder: "portalUserListItem",
				connectWith: ".userSortableList",
				items: "> .portalUserListItem"
				// stop: function(e, ui)
				// {
				// 	console.log(ui.item.scope());
				// }
			};
		}
	}
}