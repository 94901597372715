N3aPollingService.$inject = ['$timeout','context', 'ajaxService', '$interval'];

function N3aPollingService($timeout, context, ajaxService, $interval)
{
    var n3aSession;
    var pollingTime = 13*1000;
	var service = {
			init: function(){
				pollingTime = context.getModel().n3aSessionValidationInterval * 60000;
			},
			startPolling: function(){
				service.init();
				n3aSession = $interval(function(){
					service.getN3aSession().then(function(response) {
						if(response == false){
							service.stopPolling();
							service.triggerN3aSessionLogout();
						}
					});
				}, pollingTime);
			},
			triggerN3aSessionLogout: function(){
				document.location.href = '/ssoauth/ssologout';
			},
			stopPolling: function(){
				$interval.cancel(n3aSession);
			},
			getN3aSession: function(){
				var request = ajaxService.getN3aSession();
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			}
	}
	return service;
}