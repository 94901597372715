function DocumentMenu(){
    return{
        restrict: 'A',
        scope:{
        	menuStyle: '=',
        	menuParentCallback:'='
        },
        templateUrl: 'directives/contextmenu/ContextMenu.tpl.html',
        controller: 'documentMenuController'
    }
}