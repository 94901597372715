repositoryService.$inject = [ '$q', 'ajaxService','utilsService', 'responseValidatorService', 'context', '$modal', 'mediaModelService', 'localizedMessages', 'bvDialog', 'bvDialogService', 'agendaModelService', 'presentationService', '$modalStack','$rootScope', 'legacyUserService', 'approvalsService', '$state'];

function repositoryService($q, ajaxService,utilsService, responseValidatorService, context, modal, mediaModelService, localizedMessages, bvDialog, bvDialogService, agendaModelService, presentationService, $modalStack, $rootScope, legacyUserService, approvalsService, $state) {
    var templateUrl = {}
    var openFolder = {}
    var isAgendaViewer = false;
    var isAgendaPreview = false;
    
    var defaultAgendaTemplateBaseUuid = "";
    var overrideIncrementalFetchFlag = false;
    //Change For MEETX-29963
    var approvalStatus=false;

    var convertPermissions = function(permissions, objectbaseuuid) {
        var convertedPemissions = []
        if (permissions != undefined) {
            for (var i = 0, len = permissions.length; i < len; i++) {
                var convertePemission = {
                    uuid: objectbaseuuid,
                    permission: permissions[i].permission,
                    isReadPermissionThroughShortcut: false,
                    name: permissions[i].name,
                    objectBaseUuid: permissions[i].objectBaseUuid,
                    isInHerit: false,
                    type: permissions[i].type,
                    versionNumber: 0,
                    showInBriefCase: false,
                    locked: false,
                    isShortCut: false,
                    isFavorite: false,
                    hasWorkflow: false,
                    typeM: convertTypeMUser(permissions[i].type),
                    isopen: false
               }
               convertedPemissions.push(convertePemission);
            }
        }
        return convertedPemissions
    }

    var convertTypeMUser = function(type) {
        if (type == "User") return "U"
        if (type == "Portal") return "TS"
        if (type == "Group") return "G"
    }

    var service = {
        templateUrl: function(update) {
            if(update){
                templateUrl.val = update;
            }
            return templateUrl.val;
        },
        getUserList: function(){
        	var companyTree = utilsService.getCompanyTree();
        	if(companyTree != undefined && companyTree !== ''){
        		return  $q.resolve(companyTree);
        	}

			var request = ajaxService.getCompanyTree();
			return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
		},
		getPortalData: function(){
			if(context.getModel() !== undefined){
				var	data = context.getModel();
				return $q.resolve(data);
			}

			var request = ajaxService.getPortalData();
			return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
		},
		getFeedsForDiscussion: function(portalId,eventFilter,offset)
		{					
			var request=ajaxService.getFeedsForDiscussion(portalId,eventFilter,offset);
			return request.then(function(data) 
			{
				return data;
			});
			
			return null;
		},
        getPostComments: function(parentBaseUuid)
        {
            var request=ajaxService.getPostComments(parentBaseUuid);
            return request.then(function(data) 
            {
                return data;
            });

            return null;
        },
		postToWall: function (wallBaseUuid, postTextHTML, attachmentBoIds, usersIds) {
			var request = ajaxService.postToWall(wallBaseUuid, postTextHTML, attachmentBoIds, usersIds);
			return request.then(function(data)
		            {
		                if(data)
		                {
		                    return data;
		                }
		                return null;
		            });
        },
		postReplyComment: function (objectBaseUuid, feedId, portalBaseUuid, replyCommentHTML, usersIds, wallBaseUuid)
		{
			var request = ajaxService.postReplyComment(objectBaseUuid, feedId, portalBaseUuid, replyCommentHTML, usersIds, wallBaseUuid);
			return request.then(function(data){
				return data;
			});
			return null;
		},
        deletePost:function(objectBaseUuid) {
        	var request = ajaxService.deletePost(objectBaseUuid);
			return request.then(function(data){
				return data;
			});
			return null;
        },
        editPost: function (objectBaseUuid, editedHtml, updatedAttachmentBoIds, usersIds, wallBaseUuid) {
        	var request = ajaxService.editPost(objectBaseUuid, editedHtml, updatedAttachmentBoIds, usersIds, wallBaseUuid);
	        return request.then(function (data) {
		        return data;
	        });
	        return null;
        },
        createDocumentRules: function(documentBaseuuid, alertNewVersionUsers, alertSecurityChangeUsers, isFolder, isAlertNewVersionSelected, isAlertSecurityChangeSelected, isAlertDeleteDocumentSelected, deleteDocumentDays){
            var request=ajaxService.createDocumentRules(documentBaseuuid, alertNewVersionUsers, alertSecurityChangeUsers, isFolder, isAlertNewVersionSelected, isAlertSecurityChangeSelected, isAlertDeleteDocumentSelected, deleteDocumentDays);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        sendNativeEditFile: function(){
            var request=ajaxService.sendNativeEditFile();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        loadRulesForDocumentOrFolder: function(documentBaseUuid){
            var request=ajaxService.loadRulesForDocumentOrFolder(documentBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        getSubscriptionsForObjectId: function(objectBaseuuid){
            var request=ajaxService.getSubscriptionsForObjectId(objectBaseuuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getDocumentPageInfoAsJSON: function(objectBaseUuid){
        	if(!MobileUtil.isJupOffline()){        		
        		ajaxService.getFileViewedAuditOnClicking(objectBaseUuid);
        	}
            var request=ajaxService.getDocumentPageInfoAsJSON(objectBaseUuid);
            return request.then(function(response)
            {
                if(response)
                {
                    return response;
                }
                return null;
            });
        },
        getDocumentPageInfoAsJSONForVersion: function(versionBaseUuid){
            var request=ajaxService.getDocumentPageInfoAsJSONForVersion(versionBaseUuid);
            return request.then(function(response)
            {
                if(response)
                {
                    return response;
                }
                return null;
            });
        },
         sendAlertToSubscribedObject: function(objectBaseuuid){
            var request=ajaxService.sendAlertToSubscribedObject(objectBaseuuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getSubscriptions: function(){
            var request=ajaxService.getSubscriptions();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        saveSubscriptionTypes: function(subscriptionType){
            var request=ajaxService.saveSubscriptionTypes(subscriptionType);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getActiveSubscriptionList: function(){
            var request=ajaxService.getActiveSubscriptionList();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        loadUsersFollowingMe: function(){
            var request=ajaxService.loadUsersFollowingMe();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        usersFollowed: function(){
            var request=ajaxService.usersFollowed();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        saveSubsciptions: function(objectBaseuuid, securityBaseuuids){
            var request=ajaxService.saveSubsciptions(objectBaseuuid, securityBaseuuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        subscribe: function(selectedDocUuids){
            var request=ajaxService.subscribe(selectedDocUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        unsubscribe: function(subscriptionUuids){
            var request=ajaxService.unsubscribe(subscriptionUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        unsubscribeFollowMeUsers: function(subscriptionUuids){
            var request=ajaxService.unsubscribeFollowMeUsers(subscriptionUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        isAlreadySubscribed: function(selectedItems){
            var objectuuids = [];
            if(selectedItems instanceof Array){
                for(var i=0; i<selectedItems.length; i++) {
                    objectuuids.push(selectedItems[i].objectBaseUuid);
                }
            } else{
                objectuuids.push(selectedItems.objectBaseUuid);
            }

            var objectuuidsjson = angular.toJson(objectuuids)
            var request=ajaxService.isAlreadySubscribed(objectuuidsjson);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        getDocumentsJSONForParentBaseUuid: function(parentBaseUuid)
        {
            var request=ajaxService.getDocumentsJSONForParentBaseUuid(parentBaseUuid);
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    //angular.copy(data,list);
                    return data;
                }
                return null;
            });
        },
        getDocumentsJSONForBriefCase: function()
        {
            var request=ajaxService.getDocumentsJSONForBriefCase();
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    //angular.copy(data,list);
                    return data;
                }
                return null;
            });
        },
        getDocumentsJSONForNewItems: function()
        {
            var request=ajaxService.getDocumentsJSONForNewItems();
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    //angular.copy(data,list);
                    return data;
                }
                return null;
            });
        },
        getFollowers: function(objectBaseUuid)
        {
            var request=ajaxService.getFollowers(objectBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        saveFollowers: function(objectBaseUuid, subscriberBaseUuids)
        {
            var request=ajaxService.saveFollowers(objectBaseUuid, subscriberBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getFoldersAndDocumentsUsingHashSeparatedUuidsJSON: function(parentBaseUuid)
        {
            var request=ajaxService.getFoldersAndDocumentsUsingHashSeparatedUuidsJSON(parentBaseUuid);
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    //angular.copy(data,list);
                    return data;
                }
                return null;
            });
        },
        createCollatedDocumentJSON: function(collateJSON)
        {
            var request = ajaxService.createCollatedDocumentJSON(collateJSON);
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        createBoardbookJSON: function(boardbookJSON)
        {
            var request = ajaxService.createBoardbookJSON(boardbookJSON);
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        createFolder: function(parentBaseUuid, newFolderName, includeListOfPermissions, listOfPermissions)
        {
            var listOfPermissionsToPass;
            if (includeListOfPermissions) {
                listOfPermissionsToPass = JSON.stringify(convertPermissions(listOfPermissions));
            }

            var request=ajaxService.createFolder(
                parentBaseUuid,
                newFolderName,
                includeListOfPermissions,
                listOfPermissionsToPass);
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getDocumentRootFolder: function()
        {
            var request=ajaxService.getDocumentRootFolder();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        removeDocumentFromSelectedUsersFavorite: function(docObjectBaseUuid, usersObjectBaseUuid)
        {
            var request=ajaxService.removeDocumentFromSelectedUsersFavorite(docObjectBaseUuid, usersObjectBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        addDocumentToSelectedUsersFavorite: function(docObjectBaseUuid, usersObjectBaseUuids)
        {
            var request=ajaxService.addDocumentToSelectedUsersFavorite(docObjectBaseUuid, usersObjectBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        moveDocuments: function(fromFolderBaseUuid, toFolderBaseUuid, documentBaseUuid, moveFrom)
        {
            var request=ajaxService.moveDocuments(fromFolderBaseUuid, toFolderBaseUuid, documentBaseUuid, moveFrom);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        rename: function(objectBaseUuid, newName)
        {
            var request=ajaxService.rename(objectBaseUuid, newName);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        recycleListofBaseUuids: function(documentBaseUuids)
        {
            var request=ajaxService.recycleListofBaseUuids(documentBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        restoreFromRecycleBinJSON: function(toFolderBaseUuid, documentBaseUuids)
        {
            var request=ajaxService.restoreFromRecycleBinJSON(toFolderBaseUuid, documentBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        findFoldersAndSearchObjectsForParentUuid:function(parentBaseUuid)
        {
            var request=ajaxService.findFoldersAndSearchObjectsForParentUuid(parentBaseUuid);

            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }

                return null;
            });
        },

        findOriginOfCurrentFolder:function(parentBaseUuid)
        {
            var request=ajaxService.findOriginOfCurrentFolder(parentBaseUuid);

            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }

                return null;
            });
        },

        getFavoritesRootFolder: function()
        {
            var request=ajaxService.getFavoritesRootFolder();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        addToBriefCase: function(listofBaseUuids)
        {
            var request=ajaxService.addToBriefCase(listofBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getAllVersions: function(baseUuid)
        {
            var request=ajaxService.getAllVersions(baseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        makeLatestVersion: function(objectBaseUuid, versionUuid)
        {
            var request=ajaxService.makeLatestVersion(objectBaseUuid, versionUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        createNewFile: function(objectBaseUuid, objectVersionUuid, fileName)
        {
            var request=ajaxService.createNewFile(objectBaseUuid, objectVersionUuid, fileName);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        cutDocOrFolder: function(listofBaseUuids, parentBaseUuid)
        {
            var request=ajaxService.cutDocOrFolder(listofBaseUuids, parentBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        copyDocOrFolder: function(listofBaseUuids, parentBaseUuid)
        {
            var request=ajaxService.copyDocOrFolder(listofBaseUuids, parentBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        pasteDocOrFolder: function(targetFolderBaseUuid)
        {
            var request=ajaxService.pasteDocOrFolder(targetFolderBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getNewItemsRootFolder: function()
        {
            var request=ajaxService.getNewItemsRootFolder();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        emptyTrash: function()
        {
            var request=ajaxService.emptyTrash();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        loadRecycleBin: function()
        {
            var request=ajaxService.loadRecycleBin();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        removeFromBriefCase: function(documentBaseUuids)
        {
            var request=ajaxService.removeFromBriefCase(documentBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        createShortCut: function(parentBaseUuid, documentBaseUuids)
        {
            var request=ajaxService.createShortCut(parentBaseUuid, documentBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        removeFromNewItems: function(documentBaseUuids)
        {
            var request=ajaxService.removeFromNewItems(documentBaseUuids);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        setHideFolder: function(folderBaseUuid, hideFolder)
        {
            var request=ajaxService.setHideFolder(folderBaseUuid, hideFolder);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getCreatedByMeApprovalsJSON: function()
        {
            var request=ajaxService.getCreatedByMeApprovalsJSON();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getCreatedByMeSurveysJSON: function()
        {
            var request=ajaxService.getCreatedByMeSurveysJSON();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getNodeChildrenForCollationJSON:function(parentObjectBaseUuid)
        {
            var request=ajaxService.getNodeChildrenForCollationJSON(parentObjectBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getCollateJSONForBaseUuid:function(objectBaseUuid)
        {
            var request=ajaxService.getCollateJSONForBaseUuid(objectBaseUuid);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },

        editCollatedDocument:function(collateJSON)
        {
            var request=ajaxService.editCollatedDocument(collateJSON);
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getConfigForUploadFiles : function()
        {
            var request=ajaxService.getConfigForUploadFiles();
            return request.then(function(data)
            {
                if(data)
                {
                    return data;
                }
                return null;
            });
        },
        getMediaObjectContents : function(objectBaseUuid)
        {
            var request = ajaxService.getMediaContent(objectBaseUuid);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        getParentNode : function(objectBaseUuid)
        {
            var request = ajaxService.getParentNode(objectBaseUuid);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        addToCollateChildrenJSON : function(documentBaseUuids)
        {
            var request = ajaxService.addToCollateChildrenJSON(documentBaseUuids);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },
        checkPdfNonCompliantAndMergeableStatusForHistoricalDocuments : function(objectBaseUuidList)
          {
                   var request = ajaxService.checkPdfNonCompliantAndMergeableStatusForHistoricalDocuments(objectBaseUuidList);
                   return request.then(function(data) {
                     if (data) {
                       return data.data;
                     }
                     return null;
            });
            },
        deleteAllAnnotationsInDocument : function(objectBaseUuid) {
            var request = ajaxService.deleteAllAnnotationsInDocument(objectBaseUuid);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        getAuditTrailListJSON : function(objectVersionUuid) {
            var request = ajaxService.getAuditTrailListJSON(objectVersionUuid);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        addToMultipleFavoritesJSON : function(userBaseUuids, documentBaseUuids) {
            var request = ajaxService.addToMultipleFavoritesJSON(userBaseUuids, documentBaseUuids);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },
        removeDocumentsFromUsersFavoriteJSON : function(objectBaseUuids) {
            var request = ajaxService.removeDocumentsFromUsersFavoriteJSON(objectBaseUuids);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },
        openNativeMobileViewer: function(objectBaseUuid, fileName)
        {
            console.log("In openNativeMobileViewer method - calling server");
            var request=ajaxService.openNativeMobileViewer(objectBaseUuid, fileName);
            return request.then(function(data)
            {
                var list=[];
                if(data)
                {
                    //angular.copy(data,list);
                    return data;
                }
                return null;
            });
        },

        openApprovalViewer: function(objectBaseUuid)
        {
            if(MobileUtil.isIOS())
            {
                var request=ajaxService.openNativeApproval(objectBaseUuid);
                return request.then(function(data)
                {
                    var list=[];
                    if(data)
                    {
                        return data;
                    }
                    return null;
                });
            }
            else
            {
                approvalsService.selectedApprovalUuid = objectBaseUuid;
                $state.go('home.approval');
            }

        },

        openSurveyViewer: function(objectBaseUuid)
        {
            ajaxService.getSurveyDetails(objectBaseUuid).then(function(data){
                if(data.data.isClosed){
                    bvDialog.showMessage(localizedMessages.get('THIS_SURVEY_HAS_BEEN_CLOSED_BY_THE_OWNER.FURTHER_DETAILS_ARE_AVAILABLE_IN_THE_SURVEY_MODULE.'));
                }
                else if(data.data.participantBaseUuidAndResponseList.length > 0 && data.data.participantBaseUuidAndResponseList[0].isLocked){
                    bvDialog.showMessage(localizedMessages.get('THIS_SURVEY_HAS_BEEN_LOCKED'));
                }
                else{
                    if(MobileUtil.isIOS())
                    {
                        var request=ajaxService.openNativeSurvey(objectBaseUuid);
                        return request.then(function(surveyData)
                        {
                            var list=[];
                            if(surveyData)
                            {
                                return surveyData;
                            }
                            return null;
                        });
                    }
                    else{                    
                       if(data && data.data.isCBE === true)
                          $state.go('home.surveys');
                        else
                          $state.go('home.openSurvey', { surveyBaseUuid: objectBaseUuid });
                    }
                }
            })
        },

        openViewerForSearch: function(documentBaseuuid)
        {
            overrideIncrementalFetchFlag = true;
            var dialogId = documentBaseuuid;
            dialogId = btoa(dialogId);
            if($('#viewerDialog_'+dialogId).hasClass('ui-dialog-content') && $('#viewerDialog_'+dialogId).dialog('isOpen'))
            {
                $("#viewerDialog_"+dialogId).dialog("close");
            }
            setTimeout(function()
                    {
                        service.openPdfViewer(documentBaseuuid);
                    }, 1000);
        },
//        isAgendPreviewMode:function(bool){
//        	return bool;
//        },
        
        openPdfViewerVersion: function(documentId){
          if(documentId)
            ajaxService.getBusinessObjectVOJSONByBaseuuid(documentId).then(data => {
              if(data){
                const result = data.data;
                if(result.boName === "FailureBO" && (result.exceptionName === "ACLException" || result.exceptionName === "ObjectNotFoundException")){
                    var messageKey = (resultData.exceptionName === "ACLException" ? 'ERR_DOCUMENT_ACCESS_DENIED' : (resultData.exceptionName === "ObjectNotFoundException" ? 'DOCUMENT_HAS_BEEN_REMOVED' : ''));
                    setTimeout(function(){
                        bvDialog.showMessage(localizedMessages.get(messageKey))
                    }, 0, messageKey)
                }
                else{
                  result.versionedOpen = true
                  service.openViewer(result)
                }
              }
            })
        },
        //Change For MEETX-29963
        setApprovalStatus:function(currentApprovalStatus)
        {
        approvalStatus=currentApprovalStatus;
        return approvalStatus;
        },
        getApprovalStatus:function()
        {
        return approvalStatus;
        },
        //End Of Change For MEETX-29963
        openPdfViewer: function(documentVO, conferenceObject, openFromDialog,annotationFromPlink)
        {
            var customLink = "";
            var supportsCustomLinks = context.getModel().companyVO.supportsCustomLinks;
        	
            if(typeof documentVO == 'string')
            {	
                if(documentVO.split('#').length>1){
                    customLink = documentVO.split('#')[1];
                    documentVO = documentVO.split('#')[0];
                }
            	var request=ajaxService.getBusinessObjectVOJSONByBaseuuid(documentVO);
                return request.then(function(data)
                {
                    if(data)
                    {
                        var resultData = data.data;
                        if(resultData.boName === "FailureBO")
                        {
                            if(resultData.exceptionName === "ACLException" || resultData.exceptionName === "ObjectNotFoundException")
                            {
                                if(!(conferenceObject && conferenceObject.isPresentMode && conferenceObject.isAttendee))
                                {
                                    var messageKey = (resultData.exceptionName === "ACLException" ? 'ERR_DOCUMENT_ACCESS_DENIED' : (resultData.exceptionName === "ObjectNotFoundException" ? 'DOCUMENT_HAS_BEEN_REMOVED' : ''));
                                    setTimeout(function(){
                                        bvDialog.showMessage(localizedMessages.get(messageKey));
                                    }, 0, messageKey);
                                }
                            }
                        }
                        else
                        {
                            if(supportsCustomLinks && customLink !=""){
                                resultData.supportsCustomLinks = supportsCustomLinks;
                                resultData.customLink = customLink;
                            }
                            service.openViewer(resultData, conferenceObject,openFromDialog,annotationFromPlink);
                        }
                    }
                    return null;
                });
            }
            else
            {
                service.openViewer(documentVO, conferenceObject,openFromDialog);
            }
        },
        openFolderContent: function(documentVO,conferenceObject)
        {
        	
            if(typeof documentVO == 'string')
            {
                var request=ajaxService.getBusinessObjectVOJSONByBaseuuid(documentVO);
                return request.then(function(data)
                {
                    if(data)
                    {
                        var resultData = data.data;
                        if(resultData.boName === "FailureBO")
                        {
                            if(resultData.exceptionName === "ACLException" || resultData.exceptionName === "ObjectNotFoundException")
                            {
                                if(!(conferenceObject && conferenceObject.isPresentMode && conferenceObject.isAttendee))
                                {
                                    let messages = {
                                        ACLException:'ERR_FOLDER_ACCESS_DENIED',
                                        ObjectNotFoundException: 'FOLDER_HAS_BEEN_REMOVED'
                                    }
                                    var messageKey = messages[resultData.exceptionName] || ''
                                    setTimeout(function(){
                                        bvDialog.showMessage(localizedMessages.get(messageKey));
                                    }, 0);
                                }
                            }
                        }
                        else
                        {
                        	bvDialogService.openFolderView(resultData, undefined, undefined, true);
                        }
                    }
                    return null;
                });
            }
            else
            {
            	bvDialogService.openFolderView(documentVO, undefined, undefined, true);
            }
        },
        
        //Agenda Preview starts
        openAgendaPdfViewer: function(documentVO, agendaPreview, conferenceObject, openFromDialog,annotationFromPlink)
        {
        	var inItEmptyDocDetails = new Object;
        	inItEmptyDocDetails.fileSize= 0;
        	inItEmptyDocDetails.isDynamicBoardBook= true;
        	inItEmptyDocDetails.isCollatedDocument= true;
        	inItEmptyDocDetails.isImage= false;
        	inItEmptyDocDetails.isMultimedia= false;
        	inItEmptyDocDetails.versionNumber= 0;
        	inItEmptyDocDetails.documentVersion= 0;
        	inItEmptyDocDetails.isSubscribed= false;
        	inItEmptyDocDetails.permissions= ["READ"];
        	inItEmptyDocDetails.objectBaseUuid= "";
        	inItEmptyDocDetails.objectVersionUuid= "";
        	inItEmptyDocDetails.name= "";
        	inItEmptyDocDetails.boName= "FailureBO";
        	inItEmptyDocDetails.isJUPOffline= false;
        	inItEmptyDocDetails.incrementalFetch= false;
        	inItEmptyDocDetails.prevPageLimit= "0";
        	inItEmptyDocDetails.nextPageLimit= "0";
        	inItEmptyDocDetails.loggedInUser= "";
        	inItEmptyDocDetails.enableDiscussions= false;
        	inItEmptyDocDetails.enablePageTagging= false;
        	inItEmptyDocDetails.numPages= 1;
        	inItEmptyDocDetails.currentPage= 1;
        	inItEmptyDocDetails.fileName= "";
        	inItEmptyDocDetails.loadingLabel= localizedMessages.get('LOADING');
        	inItEmptyDocDetails.moreLabel= "More";
        	inItEmptyDocDetails.isMobileClient= false;
        	inItEmptyDocDetails.isAndroid= false;
        	inItEmptyDocDetails.isSurface= false;
        	inItEmptyDocDetails.isDesktopClient= false;
        	inItEmptyDocDetails.conference= {};
        	inItEmptyDocDetails.pushEnabled= false;
        	inItEmptyDocDetails.agendaPreview= true;
        	inItEmptyDocDetails.isViewAsMode= false;
        	inItEmptyDocDetails.viewAsUserId= "";
        	inItEmptyDocDetails.pdfFullUrl= "";
        	inItEmptyDocDetails.optionAnnotationsEnabled= false;
        	inItEmptyDocDetails.allowSharedAnnotation= false;
        	inItEmptyDocDetails.allowedAnnotationTypes= ["TEXT"]
        	
        	sessionStorage.setItem("inItEmptyDocDetails", JSON.stringify(inItEmptyDocDetails));
        	
        	$('.PdfViewerWrap').hide();
        	
            if(typeof documentVO == 'string' && documentVO !== 'ERR_NO_ATTACHMENTS')
            {
	                var request=ajaxService.getBusinessObjectVOJSONByBaseuuid(documentVO);
	                return request.then(function(data)
	                {
	                    if(data)
	                    {
	                        var resultData = data.data;
	                        if(resultData.boName === "FailureBO")
	                        {
	                            if(resultData.exceptionName === "ACLException")
	                            {
	                            	service.setAgendaPreview(true);
	                                service.setAgendaViewer(true);
	                                var agendaPreviewIframe = $("#AgendaPreview");
	                                if(agendaPreview){
	                                	agendaPreviewIframe.attr('name', 'ERR_DOCUMENT_ACCESS_DENIED');
	                                }else{
	                                	agendaPreviewIframe.attr('src', "component/pdfviewer/viewer.tpl.html?p=ERR_DOCUMENT_ACCESS_DENIED").attr('name', 'ERR_DOCUMENT_ACCESS_DENIED');
	                                }
	                            }
	                        }
	                        else
	                        {
	                            service.openAgnedaPreviewViewer(resultData, agendaPreview, conferenceObject, openFromDialog,annotationFromPlink);
	                        }
	                    }
	                    return null;
	                    });
	            }else
	            {
	            	service.setAgendaPreview(true);
	                service.setAgendaViewer(true);
	                var agendaPreviewIframe = $("#AgendaPreview");
	                if(agendaPreview){
	                	agendaPreviewIframe.attr('name', 'ERR_NO_ATTACHMENTS');
	                }else{
	                	agendaPreviewIframe.attr('src', "component/pdfviewer/viewer.tpl.html?p=ERR_DOCUMENT_ACCESS_DENIED").attr('name', 'ERR_NO_ATTACHMENTS');
	                }
	            }
        },
        
        openAgnedaPreviewViewer: function(documentVO, agendaPreview, sharedObject, openFromDialog,annotationFromPlink)
        {
        	 //Changes for NBV-175
        	if((documentVO.conversionStatus === "CONVERTING") ||
    	       (documentVO.scanStatus === "ERROR_ON_SERVER_WRITE") ||
               (documentVO.scanStatus === "ERROR_INPUT_STREAM_OPEN") ||
               (documentVO.scanStatus === "ERROR_INVALID_INPUT") ||
               (documentVO.scanStatus === "ERROR_SECURE_SOCKET_CREATION") ||
               (documentVO.scanStatus === "IP_UNRESOLVED") ||
               (documentVO.scanStatus === "ERROR_SOCKET_COMMUNICATION") ||
               (documentVO.scanStatus === "ERROR_ON_SERVER_READ") ||
               (documentVO.scanStatus === "ERROR_READ_WRITE_TIMEOUT") ||
               (documentVO.scanStatus === "ERROR_SERVER_TOO_BUSY") ||
               (documentVO.scanStatus === "MAX_TRIES_REACHED") ||
               (documentVO.scanStatus === "ERROR_OUTPUT_STREAM_OPEN") ||
               (documentVO.scanStatus === "ERROR_FILE_INPUT_STREAM_OPEN") ||
               (documentVO.scanStatus === "ERROR_FILE_READ") ||
               (documentVO.scanStatus === "ERROR_FILE_INPUT_STREAM_CLOSE") ||
               (documentVO.scanStatus === "INTERNAL_SERVER_ERROR") ||
               (documentVO.scanStatus === "FILE_ACCESS_FAILED") ||
               (documentVO.scanStatus === "FILE_SIZE_TOO_LARGE") ||
               (documentVO.scanStatus === "NO_AV_LICENSE")||
               (documentVO.scanStatus === "RESOURCE_UNAVAILABLE")
        	)
        	{
                setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('DOCUMENT_CONVERSION_PROMPT'));
                }, 0);

                return;
            }
            else if(documentVO.conversionStatus === "CONVERSION_ERROR")
            {
                setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('DOCUMENT_CONVERSION_ERROR_PROMPT'));
                }, 0);

                return;
            }
            var conferenceObject = sharedObject && sharedObject.name === 'SHARE_DOCUMENT'? sharedObject : {};

            if(StringUtils.isFolder(documentVO.objectBaseUuid))
            {
                var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                homeScope.folderBaseUuid = documentVO.objectBaseUuid;
                homeScope.currentInstance = "FOLDER_LISTING";
                homeScope.folderBarObject = {showBack: false, title: 'Folder Attachment'};

                var folderModal = new modal.open({
                    windowClass: 'folderViewDialog',
                    templateUrl:  'modules/shared/FolderView.tpl.html',
                    scope:  homeScope,
                    controller: 'folderViewerController'
                });
            }
            else if(StringUtils.isMultiMediaVO(documentVO.objectBaseUuid))
            {
                if(MobileUtil.isAndroid() || MobileUtil.isIOS())
                {
                    this.openNativeMobileViewer(documentVO.objectBaseUuid, documentVO.name);
                }
                else
                {
                    var mediaModelObject = mediaModelService.getInstance();
                    mediaModelObject.businessObject = documentVO;

                    var mediaModal = new modal.open({
                        windowClass : 	'mediaDialog',
                        templateUrl:  	'directives/mediaviewer/mediaViewer.template.html',
                        controller: 	'MediaController'
                    });
                }
            }
            else
            {
                var isMobileClient = MobileUtil.isMobileClient();
                var isAndroid = MobileUtil.isAndroid();
                var isSurface = MobileUtil.isSurface();
                var isDesktopClient= MobileUtil.isDesktopClient();
                console.log("Is iOSDevice:" + MobileUtil.isIOS())
                if(isAndroid || MobileUtil.isIOS())
                {
                	if(conferenceObject && conferenceObject.isPresentMode) {
                		isAndroid = false;
                	} else {
                		 console.log("Calling native mobile viewer");
                         this.openNativeMobileViewer(documentVO.objectBaseUuid, documentVO.name);
                         return;
                	}
                }
                if(isSurface)
                {//For surface setting isMobileClient false, as it needs most of the browser functionality.
                    isMobileClient = false;
                }

                var pdfDetId = documentVO.objectBaseUuid;
                pdfDetId = btoa(pdfDetId);

                if(conferenceObject && conferenceObject.isPresentMode && conferenceObject.isAttendee)
                {
                    if($('#viewerDialog_'+pdfDetId) && $('#viewerDialog_'+pdfDetId).hasClass('ui-dialog-content') && $('#viewerDialog_'+pdfDetId).dialog('isOpen'))
                    {
                        document.getElementById("viewerIframe_"+pdfDetId).contentWindow.updateConfAttendeePage(conferenceObject);
                        return;
                    }
                    else if($('.viewerDialog') && $('.viewerDialog').hasClass('ui-dialog-content') && $('.viewerDialog').dialog('isOpen'))
                    {
                        var iframeObj = $(".viewerDialog iframe")[0];
                        iframeObj.contentWindow.closeDocumentViewer(true);
                    }
                }

                var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                if (homeScope) {
                    var activeDocumentList = homeScope.activeDocumentList;
                    if(activeDocumentList && activeDocumentList.length > 0)
                    {
                        var initialLength = activeDocumentList.length;
                        activeDocumentList = activeDocumentList.filter(function (el) {
                            return el.objectBaseUuid !== documentVO.objectBaseUuid;
                        });

                        var finalLength = activeDocumentList.length;
                        if(initialLength !== finalLength)
                        {
                            homeScope.updateActiveDocumentList(activeDocumentList);
                        }
                    }
                }

                var modelObj = context.getModel();
                var pdfDetails = new Object;
                pdfDetails = angular.copy(documentVO);
                pdfDetails.isJUPOffline = MobileUtil.isJupOffline();
                if(overrideIncrementalFetchFlag){
                	pdfDetails.incrementalFetch = false;
                }else{
                	pdfDetails.incrementalFetch = pdfDetails.isJUPOffline ? false : modelObj.incrementalHtmlViewer;
                }
                overrideIncrementalFetchFlag = false;
                pdfDetails.enableWatermarkStamping = modelObj.enableWatermarkStamping;
                pdfDetails.prevPageLimit = modelObj.prevPageLimit;
                pdfDetails.nextPageLimit = modelObj.nextPageLimit;
                pdfDetails.loggedInUser = modelObj.userVO.objectBaseUuid;
                pdfDetails.enableDiscussions = modelObj.enableDiscussionsForHtml;
                pdfDetails.enablePageTagging = modelObj.enablePageTagging;
                pdfDetails.objectBaseUuid = documentVO.objectBaseUuid;
                pdfDetails.numPages = 1;
                pdfDetails.currentPage = 1;
                pdfDetails.extensionType = documentVO.extensionType;
                pdfDetails.fileName = documentVO.name.substring( 0, documentVO.name.lastIndexOf("."));
                pdfDetails.loadingLabel = localizedMessages.get('LOADING');
                pdfDetails.moreLabel = localizedMessages.get('MORE');
                pdfDetails.isMobileClient = isMobileClient;
                pdfDetails.isAndroid = isAndroid;
                pdfDetails.isSurface = isSurface;
                pdfDetails.isDesktopClient = isDesktopClient;
                pdfDetails.conference = conferenceObject;
                pdfDetails.pushEnabled = context.getModel().pushEnabled;
                pdfDetails.agendaPreview = true;
                if(conferenceObject && conferenceObject.hostUser === pdfDetails.loggedInUser)
                {
                    pdfDetails.conference.isHost = true;
                }

                pdfDetails.isViewAsMode = false;
                var contentReqParams = "getPageAsPdf&objectBaseUuid=" + documentVO.objectBaseUuid;

                if(pdfDetails.viewAsUserId != null && pdfDetails.viewAsUserId != "")
                {
                    pdfDetails.isViewAsMode = true;
                    contentReqParams = "getPDFContentViewAs&objectBaseUuid=" + documentVO.objectBaseUuid + "&viewAsUser=" + pdfDetails.viewAsUserId;
                }
                else
                {
                    pdfDetails.viewAsUserId = "";
                }
 
                
                var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/services/html?servicename=BvJsonDocumentService&methodname=" + contentReqParams;
                pdfDetails.pdfFullUrl = pdfFullUrl;
                if(pdfDetails.incrementalFetch) {
                	 pdfDetails.pdfFullUrl = window.location.protocol + "//" + window.location.host + "/html/resources/lib/Bv_Blank.pdf";
                }
                if (pdfDetails != null && pdfDetails.objectBaseUuid != null)
                {
                    var self = this;
                    service.setAgendaPreview(true);
                    service.setAgendaViewer(true);
                    this.getFoldersAndDocumentsUsingHashSeparatedUuidsJSON(pdfDetails.objectBaseUuid).then(function(data)
                    {
                        var objectBaseUuid = null;
                        if (pdfDetails != null && pdfDetails.objectBaseUuid != null)
                        {
                            objectBaseUuid = pdfDetails.objectBaseUuid;
                        }
                        pdfDetails.optionAnnotationsEnabled = data.data[0].annotationOptionAnnotationsEnabled;
                        pdfDetails.allowSharedAnnotation = data.data[0].annotationPublicPrivateOptionEnabled;
                        pdfDetails.allowedAnnotationTypes = data.data[0].annotationTypesAllowed;
                        pdfDetails.permissions = data.data[0].permissions;
                        pdfDetails.annotationFromPlink=annotationFromPlink;
                        var agendaPreviewIframe = $("#AgendaPreview");
                        if(agendaPreview){
                        	agendaPreviewIframe.attr('name', pdfDetId);
                        }else{
                        	agendaPreviewIframe.attr('src', "component/pdfviewer/viewer.tpl.html?p="+pdfDetId).attr('name', pdfDetId);
                        }
                        sessionStorage.setItem("repositoryPdfDetails"+pdfDetId, JSON.stringify(pdfDetails));
                    });
                }
            }
        },
        //Agenda Preview ends
        
        
        openAgendaPdf: function(documentVO, conferenceObject, openFromDialog)
        {    if(typeof documentVO == 'string')
            {
                var request=ajaxService.getBusinessObjectVOJSONByBaseuuid(documentVO);
                return request.then(function(data)
                {
                    if(data)
                    {
                        var resultData = data.data;
                        if(resultData.boName === "FailureBO")
                        {
                            if(resultData.exceptionName === "ACLException")
                            {
                                if(!(conferenceObject && conferenceObject.isPresentMode && conferenceObject.isAttendee))
                                {
                                    setTimeout(function(){
                                        bvDialog.showMessage(localizedMessages.get('ERR_DOCUMENT_ACCESS_DENIED'));
                                    }, 0);
                                }
                            }
                        }
                        else
                        {
                            service.openAgnedaPdfViewer(resultData, conferenceObject,openFromDialog);
                        }
                    }
                    return null;
                });
            }
            else
            {
                service.openAgnedaPdfViewer(documentVO, conferenceObject,openFromDialog);
            }
            
            
        },
        
        openViewer: function(documentVO, sharedObject, openFromDialog,annotationFromPlink)
        {
           //change for NBV-175
            if((documentVO.conversionStatus!=undefined && documentVO.conversionStatus === "CONVERTING") ||
    	       (documentVO.scanStatus === "ERROR_ON_SERVER_WRITE") ||
               (documentVO.scanStatus === "ERROR_INPUT_STREAM_OPEN") ||
               (documentVO.scanStatus === "ERROR_INVALID_INPUT") ||
               (documentVO.scanStatus === "ERROR_SECURE_SOCKET_CREATION") ||
               (documentVO.scanStatus === "IP_UNRESOLVED") ||
               (documentVO.scanStatus === "ERROR_SOCKET_COMMUNICATION") ||
               (documentVO.scanStatus === "ERROR_ON_SERVER_READ") ||
               (documentVO.scanStatus === "ERROR_READ_WRITE_TIMEOUT") ||
               (documentVO.scanStatus === "ERROR_SERVER_TOO_BUSY") ||
               (documentVO.scanStatus === "MAX_TRIES_REACHED") ||
               (documentVO.scanStatus === "ERROR_OUTPUT_STREAM_OPEN") ||
               (documentVO.scanStatus === "ERROR_FILE_INPUT_STREAM_OPEN") ||
               (documentVO.scanStatus === "ERROR_FILE_READ") ||
               (documentVO.scanStatus === "ERROR_FILE_INPUT_STREAM_CLOSE") ||
               (documentVO.scanStatus === "INTERNAL_SERVER_ERROR") ||
               (documentVO.scanStatus === "FILE_ACCESS_FAILED") ||
               (documentVO.scanStatus === "FILE_SIZE_TOO_LARGE") ||
               (documentVO.scanStatus === "NO_AV_LICENSE")||
               (documentVO.scanStatus === "RESOURCE_UNAVAILABLE")
            )
            {
                setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('DOCUMENT_CONVERSION_PROMPT'));
                }, 0);

                return;
            }
             else if(angular.isDefined(documentVO.conversionStatus) && documentVO.conversionStatus === "CONVERSION_ERROR" && documentVO.isPasswordProtected!=undefined && documentVO.isPasswordProtected)
            {
            	setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('PPF_DOCUMENT_CONVERSION_PROMPT'));
                }, 0);

                return;
            }
             else if(angular.isDefined(documentVO.pdfConversionStatus) && documentVO.pdfConversionStatus === "CONVERSION_ERROR" && documentVO.isPasswordProtected!=undefined && documentVO.isPasswordProtected)
            {
            	setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('PPF_DOCUMENT_CONVERSION_PROMPT'));
                }, 0);

                return;
            }
            /* else if( documentVO.conversionStatus!=undefined && documentVO.conversionStatus === "CONVERSION_ERROR" && documentVO.isPdfACompliant!=undefined && documentVO.isPdfACompliant!=null && documentVO.isPdfACompliant==="false")
            {
            	console.log("documeny is non-compliant");
            	if($state.current.name === "home.approval" || $state.current.name === "home.openSurvey" || $state.current.name === "home.createSurvey"){
				 setTimeout(function(){
                     bvDialog.showMessage(localizedMessages.get('NC_DOCUMENT_CONVERSION_ERROR_PROMPT_SURVEY_APPROVAL'));
                 }, 0);
				}
				else{
				 setTimeout(function(){
                     bvDialog.showMessage(localizedMessages.get('NC_DOCUMENT_CONVERSION_ERROR_PROMPT'));
                 }, 0);
				}		
            	 return;
            }
            else if(documentVO.isPdfACompliant!=undefined && documentVO.isPdfACompliant!=null && documentVO.isPdfACompliant==="false" && documentVO.pdfConversionStatus === "CONVERSION_ERROR")
            {
            	console.log("documeny is compliant but still in converting state");
            	if($state.current.name === "home.approval" || $state.current.name === "home.openSurvey" || $state.current.name === "home.createSurvey"){
				 setTimeout(function(){
                     bvDialog.showMessage(localizedMessages.get('NC_DOCUMENT_CONVERSION_ERROR_PROMPT_SURVEY_APPROVAL'));
                 }, 0);
				}
				else{
				 setTimeout(function(){
                     bvDialog.showMessage(localizedMessages.get('NC_DOCUMENT_CONVERSION_ERROR_PROMPT'));
                 }, 0);
				}		
            	 return;
            }
           else if(documentVO.isPdfACompliant!=undefined && documentVO.isPdfACompliant!=null && documentVO.isPdfACompliant==="true" && documentVO.pdfConversionStatus === "CONVERTING")
            {
            	console.log("documeny is compliant but still in converting state");
            	if($state.current.name === "home.approval" || $state.current.name === "home.openSurvey" || $state.current.name === "home.createSurvey"){
				 setTimeout(function(){
                     bvDialog.showMessage(localizedMessages.get('NC_DOCUMENT_CONVERSION_ERROR_PROMPT_SURVEY_APPROVAL'));
                 }, 0);
				}
				else{
				 setTimeout(function(){
                     bvDialog.showMessage(localizedMessages.get('NC_DOCUMENT_CONVERSION_ERROR_PROMPT'));
                 }, 0);
				}	
            	 return;
            }*/
            else if(documentVO.conversionStatus === "CONVERSION_ERROR")
            {
                setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('DOCUMENT_CONVERSION_ERROR_PROMPT'));
                }, 0);

                return;
            }

            var conferenceObject = sharedObject && sharedObject.name === 'SHARE_DOCUMENT'? sharedObject : {};
            if(StringUtils.isFolder(documentVO.objectBaseUuid))
            {
                var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                homeScope.folderBaseUuid = documentVO.objectBaseUuid;
                homeScope.currentInstance = "FOLDER_LISTING";
                homeScope.folderBarObject = {showBack: false, title: 'Folder Attachment'};

                var folderModal = new modal.open({
                    windowClass: 'folderViewDialog',
                    templateUrl:  'modules/shared/FolderView.tpl.html',
                    scope:  homeScope,
                    controller: 'folderViewerController'
                });
            }
            else if(StringUtils.isMultiMediaVO(documentVO.objectBaseUuid))
            {
                if(MobileUtil.isAndroid() || MobileUtil.isIOS())
                {
                    this.openNativeMobileViewer(documentVO.objectBaseUuid, documentVO.name);
                }
                else
                {
                    var mediaModelObject = mediaModelService.getInstance();
                    mediaModelObject.businessObject = documentVO;

                    var mediaModal = new modal.open({
                        windowClass : 	'mediaDialog',
                        templateUrl:  	'directives/mediaviewer/mediaViewer.template.html',
                        controller: 	'MediaController'
                    });
                }
            }
            else
            {
                var isMobileClient = MobileUtil.isMobileClient();
                var isAndroid = MobileUtil.isAndroid();
                var isSurface = MobileUtil.isSurface();
                var isDesktopClient= MobileUtil.isDesktopClient();
                console.log("Is iOSDevice:" + MobileUtil.isIOS())
                if(isAndroid || MobileUtil.isIOS())
                {
                	if(conferenceObject && conferenceObject.isPresentMode) {
                		isAndroid = false;
                	} else {
                		 console.log("Calling native mobile viewer");
                         this.openNativeMobileViewer(documentVO.objectBaseUuid, documentVO.name);
                         return;
                	}
                }
                if(isSurface)
                {//For surface setting isMobileClient false, as it needs most of the browser functionality.
                    isMobileClient = false;
                }

                let pdfDetId = btoa(documentVO.objectBaseUuid);
                if(documentVO.versionedOpen)
                  pdfDetId = btoa(documentVO.objectVersionUuid);

                if(conferenceObject && conferenceObject.isPresentMode && conferenceObject.isAttendee)
                {
                    if($('#viewerDialog_'+pdfDetId) && $('#viewerDialog_'+pdfDetId).hasClass('ui-dialog-content') && $('#viewerDialog_'+pdfDetId).dialog('isOpen'))
                    {
                        document.getElementById("viewerIframe_"+pdfDetId).contentWindow.updateConfAttendeePage(conferenceObject);
                        return;
                    }
                    else if($('.viewerDialog') && $('.viewerDialog').hasClass('ui-dialog-content') && $('.viewerDialog').dialog('isOpen'))
                    {
                        var iframeObj = $(".viewerDialog iframe")[0];
                        iframeObj.contentWindow.closeDocumentViewer(true);
                    }
                }

                var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                if (homeScope) {
                    var activeDocumentList = homeScope.activeDocumentList;
                    if(activeDocumentList && activeDocumentList.length > 0)
                    {
                        var initialLength = activeDocumentList.length;
                        activeDocumentList = activeDocumentList.filter(function (el) {
                            return el.objectBaseUuid !== documentVO.objectBaseUuid;
                        });

                        var finalLength = activeDocumentList.length;
                        if(initialLength !== finalLength)
                        {
                            homeScope.updateActiveDocumentList(activeDocumentList);
                        }
                    }
                }

                var modelObj = context.getModel();
                var pdfDetails = new Object;
                pdfDetails = angular.copy(documentVO);
                pdfDetails.isJUPOffline = MobileUtil.isJupOffline();
                if(overrideIncrementalFetchFlag)
                    pdfDetails.incrementalFetch = false;
                else
                    pdfDetails.incrementalFetch = pdfDetails.isJUPOffline ? false : modelObj.incrementalHtmlViewer;
                overrideIncrementalFetchFlag = false;
                pdfDetails.enableWatermarkStamping = modelObj.enableWatermarkStamping;
                pdfDetails.prevPageLimit = modelObj.prevPageLimit;
                pdfDetails.nextPageLimit = modelObj.nextPageLimit;
                pdfDetails.loggedInUser = modelObj.userVO.objectBaseUuid;
                pdfDetails.enableDiscussions = modelObj.enableDiscussionsForHtml;
                pdfDetails.enablePageTagging = modelObj.enablePageTagging;
                pdfDetails.objectBaseUuid = documentVO.objectBaseUuid;
                pdfDetails.objectVersionUuid = documentVO.objectVersionUuid;
                pdfDetails.numPages = 1;
                pdfDetails.currentPage = 1;
                pdfDetails.extensionType = documentVO.extensionType;
                pdfDetails.fileName = documentVO.name.substring( 0, documentVO.name.lastIndexOf("."));
                pdfDetails.loadingLabel = localizedMessages.get('LOADING');
                pdfDetails.moreLabel = localizedMessages.get('MORE');
                pdfDetails.isMobileClient = isMobileClient;
                pdfDetails.isAndroid = isAndroid;
                pdfDetails.isSurface = isSurface;
                pdfDetails.isDesktopClient = isDesktopClient;
                pdfDetails.conference = conferenceObject;
                pdfDetails.pushEnabled = context.getModel().pushEnabled;
                /**Added as part of MEETX-23483*/
                pdfDetails.agendaPreview = false
                /**End of Change for MEETX-23483*/
                if(conferenceObject && conferenceObject.hostUser === pdfDetails.loggedInUser)
                {
                    pdfDetails.conference.isHost = true;
                }
                pdfDetails.annotationFromPlink=annotationFromPlink;
                if(context.getModel().companyVO.enableVersionBasedAnnotations && !pdfDetails.isJUPOffline)
                  pdfDetails.versionsEnabled = true

                pdfDetails.isViewAsMode = false;
                var contentReqParams = "getPageAsPdf&objectBaseUuid=" + (pdfDetails.versionsEnabled ? documentVO.objectVersionUuid : documentVO.objectBaseUuid);

                if(pdfDetails.viewAsUserId != null && pdfDetails.viewAsUserId != "")
                {
                    pdfDetails.isViewAsMode = true;
                    contentReqParams = "getPDFContentViewAs&objectBaseUuid=" + (pdfDetails.versionsEnabled ? documentVO.objectVersionUuid : documentVO.objectBaseUuid) + "&viewAsUser=" + pdfDetails.viewAsUserId;
                }
                else
                {
                    pdfDetails.viewAsUserId = "";
                }
                
                bvUsageUtils.triggerCustomVPageBeacon(bvUsageUtils.const.Entity.Type.Document, bvUsageUtils.const.Entity.Action.Document.ViewDocument, pdfDetails.versionsEnabled ? documentVO.objectVersionUuid : documentVO.objectBaseUuid, 1);
                
                var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/services/html?servicename=BvJsonDocumentService&methodname=" + contentReqParams;
                pdfDetails.pdfFullUrl = pdfFullUrl;

                if(pdfDetails.incrementalFetch) {
               	 pdfDetails.pdfFullUrl = window.location.protocol + "//" + window.location.host + "/html/resources/lib/Bv_Blank.pdf";
               }
                if (pdfDetails != null && pdfDetails.objectBaseUuid != null)
                {
                    var self = this;
                    service.setAgendaViewer(false);
                    service.setAgendaPreview(false);
                    this.getFoldersAndDocumentsUsingHashSeparatedUuidsJSON(pdfDetails.objectBaseUuid).then(function(data)
                    {
                        var objectBaseUuid = null;
                        if (pdfDetails != null && pdfDetails.objectBaseUuid != null)
                        {
                            objectBaseUuid = pdfDetails.objectBaseUuid;
                        }

                        pdfDetails.optionAnnotationsEnabled = data.data[0].annotationOptionAnnotationsEnabled;
                        pdfDetails.allowSharedAnnotation = data.data[0].annotationPublicPrivateOptionEnabled;
                        pdfDetails.allowedAnnotationTypes = data.data[0].annotationTypesAllowed;
                        pdfDetails.permissions = data.data[0].permissions;

                        pdfDetails.signatureWellDefaultWidth = data.data[0].signatureWellDefaultWidth;
                        pdfDetails.signatureWellDefaultHeight = data.data[0].signatureWellDefaultHeight;
                        pdfDetails.signatureWellMinWidth = data.data[0].signatureWellMinWidth;
                        pdfDetails.signatureWellMinHeight = data.data[0].signatureWellMinHeight;
                        pdfDetails.initialsWellDefaultWidth = data.data[0].initialsWellDefaultWidth;
                        pdfDetails.initialsWellMinWidth = data.data[0].initialsWellMinWidth;
                        pdfDetails.isDynamicBoardBook = data.data[0].isDynamicBoardBook;

                        var approvalsListController = angular.element('.approval-main').scope();

                        sessionStorage.setItem("repositoryPdfDetails"+pdfDetId, JSON.stringify(pdfDetails));

                        var contentHeight = $(window).height();
                        var contentWidth = "98%";

                        var viewerClass = "viewerDialogClass";
                        if(pdfDetails.isMobileClient)
                        {
                            viewerClass = "viewerMobileDialogClass";
                        }

                        if($('#viewerDialog_'+pdfDetId).hasClass('ui-dialog-content') && $('#viewerDialog_'+pdfDetId).dialog('isOpen'))
                        {
                           return;
                        }

                        var divTag = document.createElement("div");
                        divTag.id = "viewerDialog_"+pdfDetId;
                        divTag.setAttribute("style", "display:none");
                        divTag.className = "viewerDialog";
                        if(pdfDetails.conference && pdfDetails.conference.isPresentMode)
                        {
                            divTag.classList.add("viewerPresentMode");
                        }

                        var iframeTag = document.createElement("iframe");
                        iframeTag.id = "viewerIframe_"+pdfDetId;
                        iframeTag.setAttribute("src", "");
                        divTag.appendChild(iframeTag);
                        iframeTag.setAttribute("webkitAllowFullScreen", true);
                        iframeTag.setAttribute("mozallowfullscreen", true);
                        iframeTag.setAttribute("allowFullScreen", true);
                        //iframe options for fullscreen -- please do not delete this
                        //webkitAllowFullScreen mozallowfullscreen allowFullScreen

                        var parentNode = document.getElementById("bvContainer").parentNode;
                        parentNode.appendChild(divTag);

                        $('#viewerDialog_'+pdfDetId+' .ui-dialog-titlebar .ui-dialog-title').html(pdfDetails.fileName + "." + pdfDetails.extensionType);

                        $.ui.dialog.prototype._focusTabbable = $.noop;
                        $("#viewerDialog_"+pdfDetId).dialog({
                            title : pdfDetails.fileName + "." + pdfDetails.extensionType,
                            modal : true,
                            height : contentHeight,
                            width : contentWidth,
                            resizable : false,
                            dialogClass: viewerClass,
                            open : function(ev, ui)
                            {
                                bvDialogService.addOpenViewerDialog("#viewerDialog_"+pdfDetId);
                                if(!legacyUserService.isNasdaqUI)
                                    $("#viewerIframe_"+pdfDetId).attr('src', "component/pdfviewer/viewer_old.tpl.html?p="+pdfDetId).attr('style', 'width:100% !important; height:100%; border:0;');
                                else
                                    $("#viewerIframe_"+pdfDetId).attr('src', "component/pdfviewer/viewer.tpl.html?p="+pdfDetId).attr('style', 'width:100% !important; height:100%; border:0;');
                               
                                //allow zomming on web page
                                if(isMobileClient && isAndroid)
                                {
                                    var viewportmeta = document.querySelector('meta[name="viewport"]');
                                    if (viewportmeta)
                                    {
                                        viewportmeta.content = 'width=device-width, maximum-scale=2.0, initial-scale=1.0';
                                    }
                                }
                            },
                            close : function(ev, ui)
                            {
                                if(isMobileClient && isAndroid)
                                {
                                    Android.showHideViewerToolbar(false);
                                    Android.updateOpenDocumentList();

                                    //switch zoom back to default
                                    var viewportmeta = document.querySelector('meta[name="viewport"]');
                                    if (viewportmeta)
                                    {
                                        viewportmeta.content = 'width=device-width, maximum-scale=1.0, initial-scale=1.0';
                                    }
                                }
                                document.getElementById("viewerIframe_"+pdfDetId).removeAttribute("src");
                                sessionStorage.removeItem("repositoryPdfDetails"+pdfDetId);
                                $(this).dialog("destroy");
                                bvDialogService.removeOpenViewerDialog("#viewerDialog_"+pdfDetId);
                                try {
                                    parentNode.removeChild(document.getElementById("viewerDialog_"+pdfDetId));
                                    if(self.sharedWithUsersDataList)
                                    {
                                       // ajaxService.sendNotificationToAnnotationShareesJSON(pdfDetails.objectBaseUuid, JSON.stringify(self.sharedWithUsersDataList));
                                        ajaxService.sendNotificationToAnnotationShareesWithAnnotationMap(pdfDetails.objectBaseUuid, JSON.stringify(self.sharedWithUsersDataList),JSON.stringify(self.updateShareeAnnotationMapData));                                 
                                    	self.sharedWithUsersDataList = null;
                                        self.updateShareeAnnotationMapData = null;
                                    }
                                } catch(err) {
                                    console.log(err.message);
                                }

                                //change for Meetx-29963
                                service.setApprovalStatus(false);
                            }
                        });

                        $("#viewerDialog_"+pdfDetId).attr('style', '');
                        if(openFromDialog) {
			            	$(".viewerDialogClass").css("z-index", 1060);
			            }
                        $(".ui-dialog-titlebar").hide();
                        

                        //window.open("component/pdfviewer/Viewer.tpl.html","_blank",'width=1056, height=816,menubar=no');
                    });
                }
            }

        },
        
        openAgnedaPdfViewer: function(documentVO, sharedObject, openFromDialog)
        {
            if(documentVO.conversionStatus === "CONVERTING")
            {
                setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('DOCUMENT_CONVERSION_PROMPT'));
                }, 0);

                return;
            }
            else if(documentVO.conversionStatus === "CONVERSION_ERROR")
            {
                setTimeout(function(){
                    bvDialog.showMessage(localizedMessages.get('DOCUMENT_CONVERSION_ERROR_PROMPT'));
                }, 0);

                return;
            }

            var conferenceObject = sharedObject && sharedObject.name === 'SHARE_DOCUMENT'? sharedObject : {};

            if(StringUtils.isFolder(documentVO.objectBaseUuid))
            {
                var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                homeScope.folderBaseUuid = documentVO.objectBaseUuid;
                homeScope.currentInstance = "FOLDER_LISTING";
                homeScope.folderBarObject = {showBack: false, title: 'Folder Attachment'};

                var folderModal = new modal.open({
                    windowClass: 'folderViewDialog',
                    templateUrl:  'modules/shared/FolderView.tpl.html',
                    scope:  homeScope,
                    controller: 'folderViewerController'
                });
            }
            else if(StringUtils.isMultiMediaVO(documentVO.objectBaseUuid))
            {
                if(MobileUtil.isAndroid() || MobileUtil.isIOS())
                {
                    this.openNativeMobileViewer(documentVO.objectBaseUuid, documentVO.name);
                }
                else
                {
                    var mediaModelObject = mediaModelService.getInstance();
                    mediaModelObject.businessObject = documentVO;

                    var mediaModal = new modal.open({
                        windowClass : 	'mediaDialog',
                        templateUrl:  	'directives/mediaviewer/mediaViewer.template.html',
                        controller: 	'MediaController'
                    });
                }
            }
            else
            {
                var isMobileClient = MobileUtil.isMobileClient();
                var isAndroid = MobileUtil.isAndroid();
                var isSurface = MobileUtil.isSurface();
                var isDesktopClient= MobileUtil.isDesktopClient();
                console.log("Is iOSDevice:" + MobileUtil.isIOS())
                if(isAndroid || MobileUtil.isIOS())
                {
                	if(conferenceObject && conferenceObject.isPresentMode) {
                		isAndroid = false;
                	} else {
                		 console.log("Calling native mobile viewer");
                         this.openNativeMobileViewer(documentVO.objectBaseUuid, documentVO.name);
                         return;
                	}
                }
                if(isSurface)
                {//For surface setting isMobileClient false, as it needs most of the browser functionality.
                    isMobileClient = false;
                }

                var pdfDetId = documentVO.objectBaseUuid;
                pdfDetId = btoa(pdfDetId);

                if(conferenceObject && conferenceObject.isPresentMode && conferenceObject.isAttendee)
                {
                    if($('#viewerDialog_'+pdfDetId) && $('#viewerDialog_'+pdfDetId).hasClass('ui-dialog-content') && $('#viewerDialog_'+pdfDetId).dialog('isOpen'))
                    {
                        document.getElementById("viewerIframe_"+pdfDetId).contentWindow.updateConfAttendeePage(conferenceObject);
                        return;
                    }
                    else if($('.viewerDialog') && $('.viewerDialog').hasClass('ui-dialog-content') && $('.viewerDialog').dialog('isOpen'))
                    {
                        var iframeObj = $(".viewerDialog iframe")[0];
                        iframeObj.contentWindow.closeDocumentViewer(true);
                    }
                }

                var homeScope = angular.element(document.querySelector('[home-controler]')).scope();
                if (homeScope) {
                    var activeDocumentList = homeScope.activeDocumentList;
                    if(activeDocumentList && activeDocumentList.length > 0)
                    {
                        var initialLength = activeDocumentList.length;
                        activeDocumentList = activeDocumentList.filter(function (el) {
                            return el.objectBaseUuid !== documentVO.objectBaseUuid;
                        });

                        var finalLength = activeDocumentList.length;
                        if(initialLength !== finalLength)
                        {
                            homeScope.updateActiveDocumentList(activeDocumentList);
                        }
                    }
                }

                var modelObj = context.getModel();
                var pdfDetails = new Object;
                pdfDetails = angular.copy(documentVO);
                pdfDetails.isJUPOffline = MobileUtil.isJupOffline();
                if(overrideIncrementalFetchFlag)
                    pdfDetails.incrementalFetch = false;
                else
                    pdfDetails.incrementalFetch = pdfDetails.isJUPOffline ? false : modelObj.incrementalHtmlViewer;
                overrideIncrementalFetchFlag = false;
                pdfDetails.enableWatermarkStamping = modelObj.enableWatermarkStamping;
                pdfDetails.prevPageLimit = modelObj.prevPageLimit;
                pdfDetails.nextPageLimit = modelObj.nextPageLimit;
                pdfDetails.loggedInUser = modelObj.userVO.objectBaseUuid;
                pdfDetails.enableDiscussions = modelObj.enableDiscussionsForHtml;
                pdfDetails.enablePageTagging = modelObj.enablePageTagging;
                pdfDetails.objectBaseUuid = documentVO.objectBaseUuid;
                pdfDetails.numPages = 1;
                pdfDetails.currentPage = 1;
                pdfDetails.extensionType = documentVO.extensionType;
                pdfDetails.fileName = documentVO.name.substring( 0, documentVO.name.lastIndexOf("."));
                pdfDetails.loadingLabel = localizedMessages.get('LOADING');
                pdfDetails.moreLabel = localizedMessages.get('MORE');
                pdfDetails.isMobileClient = isMobileClient;
                pdfDetails.isAndroid = isAndroid;
                pdfDetails.isSurface = isSurface;
                pdfDetails.isDesktopClient = isDesktopClient;
                pdfDetails.conference = conferenceObject;
                pdfDetails.pushEnabled = context.getModel().pushEnabled;
                if(conferenceObject && conferenceObject.hostUser === pdfDetails.loggedInUser)
                {
                    pdfDetails.conference.isHost = true;
                }

                pdfDetails.isViewAsMode = false;
                var contentReqParams = "getPageAsPdf&objectBaseUuid=" + documentVO.objectBaseUuid;

                if(pdfDetails.viewAsUserId != null && pdfDetails.viewAsUserId != "")
                {
                    pdfDetails.isViewAsMode = true;
                    contentReqParams = "getPDFContentViewAs&objectBaseUuid=" + documentVO.objectBaseUuid + "&viewAsUser=" + pdfDetails.viewAsUserId;
                }
                else
                {
                    pdfDetails.viewAsUserId = "";
                }
 
                
                var pdfFullUrl = window.location.protocol + "//" + window.location.host + "/services/html?servicename=BvJsonDocumentService&methodname=" + contentReqParams;
                pdfDetails.pdfFullUrl = pdfFullUrl;
                if(pdfDetails.incrementalFetch) {
               	 pdfDetails.pdfFullUrl = window.location.protocol + "//" + window.location.host + "/html/resources/lib/Bv_Blank.pdf";
               }
                if (pdfDetails != null && pdfDetails.objectBaseUuid != null)
                {
                    var self = this;
                    service.setAgendaViewer(true);
                    service.setAgendaPreview(false);
                    this.getFoldersAndDocumentsUsingHashSeparatedUuidsJSON(pdfDetails.objectBaseUuid).then(function(data)
                    {
                        var objectBaseUuid = null;
                        if (pdfDetails != null && pdfDetails.objectBaseUuid != null)
                        {
                            objectBaseUuid = pdfDetails.objectBaseUuid;
                        }

                        pdfDetails.optionAnnotationsEnabled = data.data[0].annotationOptionAnnotationsEnabled;
                        pdfDetails.allowSharedAnnotation = data.data[0].annotationPublicPrivateOptionEnabled;
                        pdfDetails.allowedAnnotationTypes = data.data[0].annotationTypesAllowed;
                        pdfDetails.permissions = data.data[0].permissions;
                        var approvalsListController = angular.element('.approval-main').scope();

                        sessionStorage.setItem("repositoryPdfDetails"+pdfDetId, JSON.stringify(pdfDetails));

                        var contentHeight = $(window).height();
                        var contentWidth = "98%";

                        var viewerClass = "viewerDialogClass";
                        if(pdfDetails.isMobileClient)
                        {
                            viewerClass = "viewerMobileDialogClass";
                        }

                        if($('#viewerDialog_'+pdfDetId).hasClass('ui-dialog-content') && $('#viewerDialog_'+pdfDetId).dialog('isOpen'))
                        {
                           return;
                        }
                      
                       
                        var divTag = document.createElement("div");
                        divTag.id = "PdfViewerWrap_"+pdfDetId;
                        divTag.setAttribute("style", "");
                        divTag.className = "PdfViewerWrap";
                        if(pdfDetails.conference && pdfDetails.conference.isPresentMode)
                        {
                            divTag.classList.add("viewerPresentMode");
                        }

                        var iframeTag = document.createElement("iframe");
                        iframeTag.id = "viewerIframe_"+pdfDetId;
                        iframeTag.setAttribute("src", "");
                        divTag.appendChild(iframeTag);
                        iframeTag.setAttribute("webkitAllowFullScreen", true);
                        iframeTag.setAttribute("mozallowfullscreen", true);
                        iframeTag.setAttribute("allowFullScreen", true);
                        //iframe options for fullscreen -- please do not delete this
                        //webkitAllowFullScreen mozallowfullscreen allowFullScreen
                        $('.PdfViewerWrap').remove();
                        var parentNode = document.getElementById("AgendaPdfViewer").parentNode;
                        //document.getElementById("documentMessage").hide();
                        parentNode.appendChild(divTag);

                        $('#viewerDialog_'+pdfDetId+' .ui-dialog-titlebar .ui-dialog-title').html(pdfDetails.fileName + "." + pdfDetails.extensionType);

                        $.ui.dialog.prototype._focusTabbable = $.noop;
                        
                        if(!legacyUserService.isNasdaqUI)
                            $("#viewerIframe_"+pdfDetId).attr('src', "component/pdfviewer/viewer_old.tpl.html?p="+pdfDetId).attr('style', 'width:100% !important; height:100%; border:0;');
                        else
                            $("#viewerIframe_"+pdfDetId).attr('src', "component/pdfviewer/viewer.tpl.html?p="+pdfDetId).attr('style', 'width:100% !important; height:100%; border:0;');

                        $("#PdfViewerWrap_"+pdfDetId).attr('style', '');
                        if(openFromDialog) {
			            	$(".viewerDialogClass").css("z-index", 1060);
			            }
                        $(".ui-dialog-titlebar").hide();
                        

                        //window.open("component/pdfviewer/Viewer.tpl.html","_blank",'width=1056, height=816,menubar=no');
                    });
                }
            }

        },

        setAgendaViewer: function(val){        	
        		isAgendaViewer = val;        	
		},
		isAgendaViewer: function(){
        	return isAgendaViewer;
		},
		setAgendaPreview: function(val){        	
			isAgendaPreview = val;        	
		},
		isAgendaPreview: function(){
	    	return isAgendaPreview;
		},
	
        closeDocumentViewer: function(confSyncVo)
        {
            var dialogId = confSyncVo.documentBaseUuid;
            dialogId = btoa(dialogId);

            if(!($modalStack && $modalStack.getTop() && ($modalStack.getTop().value.modalDomEl.hasClass("bv-running-conf-list-dialog") || $modalStack.getTop().value.modalDomEl.hasClass("bv-event-presentation"))))
            {
                bvDialogService.closeTopOpenDialog();
            }
            presentationService.manageBackDrop();
            if($('#viewerDialog_'+dialogId).hasClass('ui-dialog-content') && $('#viewerDialog_'+dialogId).dialog('isOpen'))
            {
                document.getElementById("viewerIframe_"+dialogId).contentWindow.closeDocumentViewer(true);
            }
        },

        updateSharedAnnotObjList: function(sharedWithUsersMap)
        {
            this.sharedWithUsersDataList = Object.keys(sharedWithUsersMap);
        },
        maintainAnnotationToShareeMap:function(annot,sharedWithUsersMap){
        	if(this.updateShareeAnnotationMapData==null){
        		this.updateShareeAnnotationMapData = new Map();
        		this.updateShareeAnnotationMapData.set(annot,sharedWithUsersMap);
        	}
        	else{
        		this.updateShareeAnnotationMapData.set(annot,sharedWithUsersMap);
        	}
        },
        createAgenda: function(documentVO)
        {
            var agendaModelObject = agendaModelService.getInstance();
            agendaModelObject.businessObject = documentVO;

            var dialogId = documentVO.objectBaseUuid;
            dialogId = btoa(dialogId);
            agendaModelObject.sourceUrl = "/services/createAgenda/index?documentVersionId="+dialogId;

            var agendaModal = new modal.open({
                windowClass : 	'agendaDialog',
                templateUrl:  	'modules/repository/AgendaFileChooser.tpl.html',
                controller: 	'AgendaFileChooserController'
            });
        },

        openPdfURL: function(pdfUrl, windowoption, name, params, fileName)
        {
            // get the pdf with a GET when it's running on Desktop app
           
            if( MobileUtil.isDesktopClient() ) { 
               var queryString = Object.keys(params).map((key) => {
                   return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                }).join('&');
           
               var newWindow = window.open(pdfUrl + "?" + queryString, name, windowoption);
               newWindow.print();
               return;
            }
           
            // get the pdf with a POST, for all the rest
            $.ajax({
            	  cache: false,
            	  type : 'POST',
            	  datatype : 'application/pdf',
            	  url: pdfUrl,
            	  headers: {
            		    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            	  },
                  data: params,
            	  xhrFields: {
            	    responseType: 'blob'
            	  },
            	  success: function(response){

                    var linkelem = document.createElement('a');
                    try {
                        var blob = new Blob([response], { type: 'application/pdf' });                        

    
                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                            //   IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                            window.navigator.msSaveBlob(blob, fileName);
                        } else {
                            var URL = window.URL || window.webkitURL;
                            var downloadUrl = URL.createObjectURL(blob);
    
                            if (fileName) { 
                                // use HTML5 a[download] attribute to specify filename
                                var a = document.createElement("a");
    
                                // safari doesn't support this yet
                                if (typeof a.download === 'undefined') {
                                    window.location = downloadUrl;
                                } else {
                                    a.href = downloadUrl;
                                    a.download = fileName;
                                    document.body.appendChild(a);
                                    a.click();
                                }
                            } else {
                                window.location = downloadUrl;
                            }

                            setTimeout(() => {
                                URL.revokeObjectURL(blob);
                                if (typeof a.download !== 'undefined') {
                                    document.body.removeChild(a);
                                } 

                            }, 1000);

                          
                        }   
    
                    } catch (ex) {
                        console.log(ex);
                    }     
            	  }
            	});
        },

        openDocumentasPdf:function(objectVersionUuid,fileName)
        {
            var params = {"versionUuid" : objectVersionUuid}
            this.openPdfURL("/services/viewdocument/"+fileName, "height=800,width=800", "test", params, fileName);
        },

        startVirtualEdit:function(objectBaseuuid, bLocale, frameHt, frameWt)
        {
            var request = ajaxService.startVirtualEdit(objectBaseuuid, bLocale, frameHt, frameWt);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        getConnectionToken:function(id, payload)
        {
            var request = ajaxService.getConnectionToken(id, payload);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        checkConnectionAvailable:function(uid)
        {
            var request = ajaxService.checkConnectionAvailable(uid);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        saveAgendaLinks:function(objectBaseuuid, agendaLinksJson)
        {
            var request = ajaxService.saveAgendaLinks(objectBaseuuid, agendaLinksJson);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        checkRelationBeforeDeleteJSON: function(objectBaseUuids)
        {
            var request = ajaxService.checkRelationBeforeDeleteJSON(objectBaseUuids);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        createAgendaForFolder : function(objectBaseUuid, parentObjectBaseUuid, style, templateObjectBaseUuid)
        {
            var request = ajaxService.createAgendaForFolder(objectBaseUuid, parentObjectBaseUuid, style, templateObjectBaseUuid);
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        getAgendaTemplatesFolderUuidForCurrentPortal : function() {
            var request = ajaxService.getAgendaTemplatesFolderUuidForCurrentPortal();
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        findAllAgendaTemplatesForCurrentPortal : function() {
            var request = ajaxService.findAllAgendaTemplatesForCurrentPortal();
            return request.then(function(data) {
                if (data) {
                    return data.data;
                }
                return null;
            });
        },

        makeAgendaTemplateDefaultForCurrentPortal : function(objectBaseUuid) {
            var request = ajaxService.makeAgendaTemplateDefaultForCurrentPortal(objectBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data;
                }
                return null;
            });
        },

        setDefaultAgendaTemplateBaseUuid : function(objectBaseUuid) {
            defaultAgendaTemplateBaseUuid = objectBaseUuid;
            $rootScope.$broadcast('defaultAgendaTemplateBaseUuid:updated', defaultAgendaTemplateBaseUuid);
        },

        getDefaultAgendaTemplateBaseUuid : function() {
            return defaultAgendaTemplateBaseUuid;
        },

        getDefaultAgendaTemplateForCurrentPortal : function() {
            var request = ajaxService.getDefaultAgendaTemplateForCurrentPortal();
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data.data;
                }
                return null;
            });
        },
        
        checkInCheckOut : function(documentVo, checkInOut, cancelCheckOut) {
            var request = ajaxService.checkInCheckOut(documentVo, checkInOut, cancelCheckOut);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data.data;
                }
                return null;
            });
        },
        
        addDocCheckInOutAlertRelationShipType: function(documentBaseUuid) {
        	var request = ajaxService.addDocCheckInOutAlertRelationShipType(documentBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data.data;
                }
                return null;
            });
        },
        sendDocCheckedInNotification: function(documentBaseUuid) {
        	var request = ajaxService.sendDocCheckedInNotification(documentBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data.data;
                }
                return null;
            });
        },
        sendDocCancelCheckedOutNotification: function(documentBaseUuid) {
        	var request = ajaxService.sendDocCancelCheckedOutNotification(documentBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data.data;
                }
                return null;
            });
        },
        getTopic: function(parentBaseUuid){
            var request = ajaxService.getTopic(parentBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data.data;
                }
                return null;
            });
        },
        checkSubscription: function(parentBaseUuid){
            var request = ajaxService.checkSubscription(parentBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data)) {
                    return data.data;
                }
                return null;
            });
        },
        getDocumentOrFolderParentObjectIds: function(documentBaseUuid){
            var request = ajaxService.getDocumentOrFolderParentObjectIds(documentBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data.data)) {
                    return data.data;
                }
                return null;
            });
        },
        setOpenFolder: function(objectBaseUuid, open){
            openFolder.objectBaseUuid = objectBaseUuid;
            openFolder.open = open;
        },
        getOpenFolder: function(){
            if(!_.isEmpty(openFolder))
                return openFolder;
            else
                return null;
        },
        toggleIncrementalFetchConfig: function(configValue){
            var request = ajaxService.toggleIncrementalFetchConfig(configValue);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data.data)) {
                    return data.data;
                }
                return null;
            });
        },
        getAnnotationCountForAllVersionsOfTheDocument: function(objectBaseUuid){
            var request = ajaxService.getAnnotationCountForAllVersionsOfTheDocument(objectBaseUuid);
            return request.then(function(data) {
                if (responseValidatorService.isSuccess(data.data)) {
                    return data.data;
                }
                return null;
            });
        },	
        loadLightWeightNewItemsJson: function(objectBaseUuid){
        	var objectBaseUuidArray=[];
        	if (objectBaseUuid !=undefined)
        	{
        		objectBaseUuidArray=StringUtils.getBaseUuidArrayJSONforSelectedContextMenu(objectBaseUuid);
        	}
        	
			var request=ajaxService.loadLightWeightNewItemsJson(objectBaseUuidArray);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
	   getNewItemService: function(){
		    var request=service;
		    return request;
	   }
    }
    return service;
}

function DocumentViewerInvokeService()
{
    var documentViewerInvokeInstance = {};
    documentViewerInvokeInstance.module = null;

    this.getInstance = function()
    {
        return documentViewerInvokeInstance;
    };
}

angular
.module('repository.service',['ajax.service', 'media.viewer', 'localization.service', 'agenda.builder', 'conference.service'])
.factory('repositoryService', repositoryService)
.service('documentViewerInvokeService', [DocumentViewerInvokeService]);
