eventsdialogService.$inject = ['bvDialogService'];

function eventsdialogService(bvDialogService){

    var currentDay = {}

    this.dialog = {}

    this.showDialog = function (events, day, browserEvent, clickedDay){

        currentDay = day;

        this.dialog = bvDialogService.showBvModal({
            windowClass : 'events-dialog',
            backdropClass: 'events-dialog',
            controller : 'eventsdialogController',
            buttons      : null,
            showClose : false,
            showHeader : false,
            keyboard : false,
            bodyTemplate : 'modules/meetings/dialogs/eventsdialog/eventsdialog.tpl.html',
            inputs : {
                events: events,
                day: day,
                browserEvent: browserEvent,
                clickedDay: clickedDay
            }
        });

        this.dialog.result.finally(function(ttt){
            currentDay.clicked = false;;
        })
    }

}