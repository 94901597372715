function weekviewService(){

    var weekview = {}
    var m = StringUtils.momentL;

    var Weekview = function(inputDate){
        var date = inputDate || new Date();

        var generateWeekdays = function(date){
            var weekdays = [];
            for(var i=1 ; i <= 7 ; i++){
                weekdays.push({
                    title: m().isoWeekday(i).format('dddd').toUpperCase() + ' ' + m(date).isoWeekday(i).format('M') + '/' +  m(date).isoWeekday(i).format('D'),
                    currentDay: m(date).isoWeekday(i).format('ll') == m().format('ll') ? true : false,
                    day: m(date).isoWeekday(i).format('ll')
                });
            }
            return weekdays;
        }

        this.weekdays =  generateWeekdays(date);
        this.weekInfo = m(date).isoWeekday(1).format('MMMM')
                        + ' ' + m(date).isoWeekday(1).format('DD')
                        + '-' + m(date).isoWeekday(7).format('DD')
                        + ' ' + m(date).isoWeekday(1).format('YYYY');

        this.currentTime = this.updateCurrentTime();
        this.currentWeek = _.find(this.weekdays, { currentDay : true });
        this.isoDate = m(date).toISOString();
        this.interval;
    }

    Weekview.prototype = {
        constructor: Weekview,
        startInterval: function(sec){
            var sec = sec || 60;
            var self = this;
            self.interval = setInterval(function(){
                self.updateCurrentTime();
            },sec * 1000);
        },
        stopInterval: function(){
            clearInterval(this.interval);
        },
        updateCurrentTime: function(){
             this.currentTime = m('01/01/0000 ' + m().format('hh:mm A')).unix() - m('01/01/0000 ' + m().hour('00').minute('00').format('hh:mm A')).unix();
             return this.currentTime;
        },
        getMomentDate: function(){
            return m(this.isoDate);
        }
    }

    this.createWeek = function(date){
        weekview = new Weekview(date);
        return weekview;
    }

    this.getCurrentWeek = function(){
        return weekview;
    }

}