weekviewController.$inject = ['$scope', 'weekviewService', 'toppanelService', 'calendarService', '$state', '$rootScope', 'localizedMessages', '$element', '$timeout', 'bvDialog', 'dayviewService'];

function weekviewController($scope, weekviewService, toppanelService, calendarService, $state, $rootScope, localizedMessages, $element, $timeout, bvDialog, dayviewService){

    $scope.weekview = weekviewService.createWeek();
    $scope.events = calendarService.getAllCalendarEvents();

    $scope.title = {
        allDay: localizedMessages.get('ALL-DAY')
    }

    _.each($scope.weekview.weekdays, function(weekday){
        dayviewService.combineEvents($scope.events.listEventsByDay[weekday.day]);
        dayviewService.getMoreDataFoDayEvents($scope.events.listEventsByDay[weekday.day]);
    });

    var secondHeight = 0.01386;

    $scope.getHeightEvent = function(startTimeUnix, endTimeUnix){
        return ((endTimeUnix - startTimeUnix) * secondHeight).toFixed();
    }
    $scope.getMarginTopEvent = function(startTimeUnix, timeUnix){
        return ((startTimeUnix - timeUnix) * secondHeight).toFixed() + 'px';
    }
    $scope.getTopCurrentTime = function(currentTime){
        return (currentTime * secondHeight).toFixed();
    }
    $scope.getWidthEvent = function(combined){
        return (100 / (combined.length + 1)) + '%';
    }

    var toppanel = toppanelService.getInstance('MEETINGS');

    var next = function(){
        var nextWeek = $scope.weekview.getMomentDate().isoWeekday(8);
        $scope.weekview = weekviewService.createWeek(nextWeek);
        toppanel.addEvent('weekTitle', $scope.weekview.weekInfo);
    }

    var prev = function(){
        var prevWeek = $scope.weekview.getMomentDate().isoWeekday(-1);
        $scope.weekview = weekviewService.createWeek(prevWeek);
        toppanel.addEvent('weekTitle', $scope.weekview.weekInfo);
    }

    var today = function(){
        $scope.weekview = weekviewService.createWeek();
        toppanel.addEvent('weekTitle', $scope.weekview.weekInfo);
    }

    toppanel.addEvent('weekToday', today);
    toppanel.addEvent('prev', prev);
    toppanel.addEvent('next', next);
    toppanel.addEvent('weekTitle', $scope.weekview.weekInfo);

    $scope.hours = [];
    for(var i = 0 ; i <= 24 ; i++){
        $scope.hours.push({
            title       : moment().hour(i).format('h A'),
            time        : moment().hour(i).minute('00').format('hh:mm A'),
            timeUnix    : i == 24 ? null : moment('01/01/0000 ' + moment().hour(i).minute('00').format('hh:mm A')).unix()
        })
    }

    $scope.clickEvent = function(event){
        //var isEventOwner = event.isEventOwner;
        bvDialog.showMeetingDetailsPanel(event, true, !MobileUtil.isMobileClient());
    }

    $scope.weekview.startInterval();

    $scope.MobileUtil = MobileUtil;

    $timeout(function(){
        if(MobileUtil.isMobileClient())
            $element.find('.events').scrollTop($scope.getTopCurrentTime($scope.weekview.currentTime) - 230);
        else
            $element.find('.events .mCSB_container')[0].style.top = '-' + ($scope.getTopCurrentTime($scope.weekview.currentTime) - 230) + 'px';
    });
}