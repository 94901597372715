function ipadService(){
    var xStart;
    var yStart;
    var scrollTop;
    var scrollDown;

    var touchstart = function(e){
       var ele = document.querySelector('.bv-transclude-content');
       xStart = e.touches[0].screenX;
       yStart = e.touches[0].screenY;
       scrollTop = ele.scrollTop;
       scrollDown = ele.scrollHeight - ele.offsetHeight;
    }

    var touchmove = function(e){
        if(scrollTop == 0 && yStart - e.touches[0].screenY <= 0){
            e.preventDefault();
        }
        if(scrollDown == scrollTop && yStart - e.touches[0].screenY >= 0){
            e.preventDefault();
        }
    }

	var service = {
		touchContent : function(bool){
			if(bool == true){
				setTimeout(function(){
					var ele = document.querySelector('.bv-transclude-content');
					ele.addEventListener('touchstart', touchstart);
					ele.addEventListener('touchmove', touchmove);
				},500);
			}
			else{
				var ele = document.querySelector('.bv-transclude-content');
				if(ele) {
					ele.removeEventListener('touchstart', touchstart);
					ele.removeEventListener('touchmove', touchmove);
				}
			}
		},
		touchHomeSidebar : function(){
//			setTimeout(function(){
//				var ele = document.querySelector('[home-sidebar] .bv-scrollbar');
//				if(ele) {
//					ele.addEventListener('touchstart',function(e) {
//				    	xStart = e.touches[0].screenX;
//				    	yStart = e.touches[0].screenY;
//				    	scrollTop = ele.scrollTop;
//						scrollDown = ele.scrollHeight - ele.offsetHeight;
//						console.log(scrollTop, yStart);
//						if(scrollTop == 0){
//						ele.scrollTop = scrollTop + 1;
//						}
//						else if(scrollTop == scrollDown){
//							ele.scrollTop = scrollDown -1;
//						}
//						console.log(ele.scrollTop);
//					});
//
//					ele.addEventListener('touchmove',function(e) {
//						if(scrollTop == 0 && yStart - e.touches[0].screenY <= 0){
//							e.preventDefault();
//						}
//						if(scrollDown == scrollTop && yStart - e.touches[0].screenY >= 0){
//							e.preventDefault();
//						}
//					});
//				}
//			},500);
		},
		touchPortlets: function(){
			setTimeout(function(){
				var elements = [];
				var eles = document.querySelectorAll('[compile="module.html"].bv-scrollbar');
				for(var i = 0; i < eles.length; i++){
					elements.push({item: eles[i]});

					eles[i].addEventListener('touchstart',function(e) {
						for(var k = 0; k < elements.length; k++){
							if(this == elements[k].item){
								elements[k].scrollTop = this.scrollTop;
								elements[k].scrollDown = this.scrollHeight - this.offsetHeight;
								elements[k].xStart = e.touches[0].screenX;
								elements[k].yStart = e.touches[0].screenY;
							}
						}

					});

					eles[i].addEventListener('touchmove',function(e) {
						for(var k = 0; k < elements.length; k++){
							if(this == elements[k].item){
								console.log(elements[k].scrollTop, elements[k].scrollDown);
								if(elements[k].scrollTop == 0 && elements[k].yStart - e.touches[0].screenY <= 0){
									e.preventDefault();
								}
								if(elements[k].scrollDown == elements[k].scrollTop && elements[k].yStart - e.touches[0].screenY >= 0){
									e.preventDefault();
								}
							}
						}
					});
				}
			},3000);
		},
		touchOnscroll: function(){
//			setTimeout(function(){
//				elements = [];
//				eles = document.querySelectorAll('.bv-transclude-content');
//				for(var i = 0; i < eles.length; i++){
//					//ell = eles[0].children[0].children;
//					//ell = eles[0].children[0].children[0].children[0].children[0].children;
//					ell =  eles[0].children[1].children[0].children[0].children[0].children;
//					eles[i].onscroll = function(e) {
//
//						//this.childNodes[0].children[0].children[1].style['background'] = '#ccc'
////
////						var width = ell[0].offsetWidth - 100;
////						for(var m = 0 ; m < ell.length ; m++){
////
////							if(m * width < this.scrollLeft && this.scrollLeft  < (m + 1) * width){
////								var position = (800 + 2*Math.round(parseFloat((this.scrollLeft - m*width)/width)*100))/1000;
////								var position2 = (1000 + 2*Math.round(1 - parseFloat((this.scrollLeft - m*width)/width)*100))/1000;
////								//var position = (900 + Math.round(parseFloat(1 - Math.abs((this.scrollLeft - m*275)/275*2 - 1))*100))/1000;
////								console.log(position);
////								if(position > 0.9){
////									ell[m+2].style['z-index'] = '1000';
////								}
////								else{
////									ell[m+2].style['z-index'] = ell.length - m - 2;
////
////								}
////								ell[m+2].style['-webkit-transform'] = 'scale3d(' + position + ',' + position + ',1)';
////								ell[m+1].style['-webkit-transform'] = 'scale3d(' + position2 + ',' + position2 + ',1)';
////							}
////						}
//
//					};
//				}
//			},1000);
		}
	};
	return service;
}
