function PermissionsService(
	$q, 
	ajaxService, 
	$modal, 
	responseValidatorService, 
	templatesService,
	bvDialogService
){
	
	var service = {

			getUsersJSON: function(getusers, getgroup, getportal) 
			{
				var request=ajaxService.getUsersJSON(getusers, getgroup, getportal);
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
			
			getPermissionJSON: function(objectBaseUuid) 
			{
				var request=ajaxService.getPermissionJSON(objectBaseUuid);
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
			getComputedPermissionJSON: function(objectBaseUuid) 
			{
				var request=ajaxService.getComputedPermissionJSON(objectBaseUuid);
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
			getPermissionFromProperties:function(objectBaseUuid)
	 		{
	 			var request=ajaxService.getDocPropJSONByBaseUuid(objectBaseUuid);
	 			return request.then(function(data)
	 			{
	 				var list=[];
	 				if(data)
	 				{
	 					return JSON.parse(data.data.objectPermissions);
	 				}

	 				return list;
	 			});
	 		},
			
			getPortalGroupsJSON: function(){
				var request=ajaxService.getPortalGroupsJSON();
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
			
			getCurrentRootJSON: function(){
				var request=ajaxService.getCurrentRootJSON();
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
			
			getUsersAndGroupsJSON: function(){
				var request=ajaxService.getUsersAndGroupsJSON();
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
									
			getChildrenJSON: function(objectBaseUuid) 
			{
				var request=ajaxService.getChildrenJSON(objectBaseUuid);
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
			
			savePermissionJSON: function(objectBaseUuid, sub, inherit, aclVOsJson) 
			{
				var request=ajaxService.savePermissionJSON(objectBaseUuid, sub, inherit, aclVOsJson);
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			},
			
			isSetFollowersEnabled: function(objectBaseUuid) 
			{
				var request=ajaxService.isSetFollowersEnabled(objectBaseUuid);
				return request.then(function(data)
				{
					var list=[];
					if(data)
					{
						return data;
					}
					return null;
				});
			}
			
			
			
	}

	return service;
}
angular
	.module('permissions.service', [
	    'ajax.service', 
	    'ui.bootstrap' 
	])
	.factory('permissionsService',[ 
	    '$q', 
	    'ajaxService', 
	    '$modal', 
	    'responseValidatorService', 
	    'templatesService',
	    'bvDialogService',
	PermissionsService 
	]);