function sharedService($q, ajaxService, responseValidatorService) {
	var service = {
		getInboxJSON: function(offset) {
			var request=ajaxService.getInboxJSON(offset);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},

		getSentJSON: function(offset) {
			var request=ajaxService.getSentJSON(offset);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},

		markItemRead: function(objectBaseUuid) {
			ajaxService.markItemRead(objectBaseUuid);
		},

		getSharedContainerWithAttachmentsJSON: function(shareContainerObjectBaseUuid) {
			var request=ajaxService.getSharedContainerWithAttachmentsJSON(shareContainerObjectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			})
		},

		pullBackSentItem: function(shareContainerObjectBaseUuid) {
			ajaxService.pullBackSentItem(shareContainerObjectBaseUuid);
		}
	}
	return service;
}
angular
	.module('shared.service', [ 'ajax.service' ])
	.factory('sharedService',[ '$q', 'ajaxService', 'responseValidatorService', sharedService ]);