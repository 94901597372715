angular
    .module('dialogControllers.service')
	.controller('openCBEController', openCBEController);

openCBEController.$inject =  [ '$scope', '$filter', '$state', 'localizedMessages', 'context', '$sce'];

function openCBEController($scope,$filter, $state, localizedMessages, context, $sce){
	$scope.popupTitle 	= localizedMessages.get('ENGAUGE_QUESTIONNAIRE');
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var cbeQuestionnaire = $scope.bvModalControllerInputs.cbeQuestionnaire;
	$scope.cbeQuestionnaireLink = localizedMessages.get('CBE_QUESTIONNAIRE_LINK');
}