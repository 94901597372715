function colorPickerMenu(context){
	return {
	    restrict: 'A',
		controller: ['$scope', '$state', 'brand', '$element', 'homeService', function(scope,state, brand, el, homeService){
			var modulesColor = context.getModel().html5ModulesColor;
			var colorVal = "004570";
			if (modulesColor != null)
			{
				colorVal = modulesColor.replace('#','').trim();
			}
			$(el).val(colorVal);
		    $(el).ColorPickerSliders({
		    	color: colorVal,
		    	connectedinput: $(el),
		    	flat: true,
		    	order: {
		    	hsl: 1
		    	},
		    	onchange: function(container, color) {
		            $('input#middleColor').val(color.tiny.toHexString());
		            brand.colorMenu(color.tiny.toHexString());
		            context.getModel().html5ModulesColor = "#" + color.tiny.toHexString();
		            setTimeout(function(){
		            	scope.$apply();
		            },0);
		        }
		    });
		}]
	}
}