carousel3dSlide.$inject = [];
function carousel3dSlide() {
        var carousel3dSlide = {
            require: '^carousel3d',
            restrict: 'AE',
            template: '<div class=\"slide-3d\" ng-click=\"carousel3d.slideClicked($index)\" ng-swipe-left=\"carousel3d.goPrev()\" ng-swipe-right=\"carousel3d.goNext()\" ng-transclude></div>',
            replace: true,
            transclude: true,
            link: linkFunc
        };

        // == Directive link
        function linkFunc(scope, element, attrs, ctrl, transclude) {
            scope.carousel3d = ctrl;
        }
        return carousel3dSlide;
}

// == Directive 3d
carousel3d.$inject = ['$timeout'];
function carousel3d($timeout) {

        var carousel3d = {
            restrict: 'AE',
            template: '' +
            '<div class=\"carousel-3d-container\" ng-switch="vm.isLoading">' +
            '   <div class="carousel-3d-loader" ng-switch-when=\"true\">' +
            '       <div class=\"carousel-3d-loader-circle\" style=\"-webkit-transform:scale(0.75)\"><div><div></div><div></div></div></div>' +
            '       <div class="carousel-3d-loader-percentage">{{ vm.percentLoaded }}</div>' +
            '   </div>' +
            '   <div ng-switch-when="false" ng-switch="vm.isSuccessful">' +
            '       <div class=\"carousel-3d\" ng-switch-when=\"true\" ng-show="vm.isRendered" ng-transclude>' +
            '       </div>' +
            '       <p ng-switch-when=\"false\" class="carousel-3d-loader-error">There was a problem during load</p>' +
            '       <div ng-if="vm.controls" class="carousel-3d-controls">' +
            '           <div class="carousel-3d-next arrow-left ss-icon ss-standard" ng-click=\"vm.goPrev()\"></div>' +
            '           <div class="carousel-3d-prev arrow-right ss-icon ss-standard" ng-click=\"vm.goNext()\"></div>' +
            '       </div>' +
            '   </div>' +
            '</div>',
            replace: true,
            scope: {
                model: '=ngModel',
                options: '=',
                onSelectedClick: '&',
                onSlideChange: '&',
                onLastSlide: '&',
                onBeforeChange: '&'
            },
            controller: 'Carousel3dController as vm',
            bindToController: true,
            transclude: true,
            compile: compileFunc,
            link: linkFunc
        };

        // ==
        // == Directive Compile
        // =======================================
        //compileFunc.$inject = ['element', 'attributes', '$attrs'];

        function compileFunc(element, attributes) {

            return (linkFunc);
        }


        function linkFunc(scope, element, attrs, ctrl, transclude) {
        }

        return carousel3d;
}