 

function utilsService()
{
	var companyTree='';
	  
	var service = {
		getCompanyTree: function() {
			 return  companyTree;
		},
		setCompanyTree: function(obj) {
			  companyTree = obj;
		},
		
	}
	return service;
}