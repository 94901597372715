angular
    .module('dialogControllers.service')
	.controller('bvDocPreviewController',  bvDocPreviewController);

bvDocPreviewController.$inject = ['$scope', 'localizedMessages'];

function bvDocPreviewController($scope, localizedMessages)
{
	//var docId = $scope.bvModalControllerInputs.documentId;
	$scope.DOC_OBJ = $scope.bvModalControllerInputs.docObj;
	$scope.UI_DOCUMENT_NAME = "" + $scope.DOC_OBJ.name;	
	$scope.popupTitle = localizedMessages.get('DOCUMENT_PREVIEW') + ": " + $scope.UI_DOCUMENT_NAME ;
	$scope.previewNotAvailable = localizedMessages.get('NO_PREVIEW_AVAILABLE');
	
	$scope.getDocumentThumbnail = function() 
 	{
 		if( $scope.DOC_OBJ.documentThumbnail  == "") 
 		{
 			return null;
 		}
 		else if ($scope.DOC_OBJ.documentThumbnail != undefined) 
		{
			return "data:image/png;base64," + $scope.DOC_OBJ.documentThumbnail;
		}
		else if ($scope.DOC_OBJ.encodedThumbnail != undefined) 
		{
			return "data:image/png;base64," + $scope.DOC_OBJ.encodedThumbnail;
		}
		else
		{
			return null;
		}
	}
}