saveSurveyTemplateService.$inject = ['bvDialogService', 'localizedMessages'];

function saveSurveyTemplateService(bvDialogService, localizedMessages){

    this.dialog = {}
    
    this.saveSurveyTemplate = function (survey, surveyOwnersUuids, templateSavedCallback){
        this.dialog = bvDialogService.showBvModal({
        	windowClass : 'bv-copy-survey-modal',
			controller : 'saveSurveyTemplateController',
			bodyTemplate : 'modules/surveys/SaveSurveyTemplate.tpl.html',
			inputs : {
				survey : survey,
				surveyOwnersUuids : surveyOwnersUuids,
				templateSavedCallback : templateSavedCallback
			 },
			buttons: null
        });
    }
}