rightClick.$inject = ['$document'];

function rightClick($document) {

	return {
        scope: {
    			onRightClick: '&',
    			selectedItem: '=',
    			disableRightClick: '='
    			},
    	link: function(scope, element, attrs) {

    			if(scope.disableRightClick)
    				return;

    	    		element.bind('contextmenu', function(event, element)
    	    	 {
    	            scope.$apply(function()
    	            {
    	            	var fn = scope.onRightClick();

    	            	var currentSelectedIndex = -1;

    	            	if(attrs.selectedIndex)
    	            		currentSelectedIndex = attrs.selectedIndex;

    	            	if ('undefined' !== typeof fn)
    	                {
    	                	event.preventDefault();
    	                	fn(event.pageX, event.pageY, scope.selectedItem, event, currentSelectedIndex);

    	                	event.stopPropagation();
    	                }
    	    		});
    	    	 })
    	      }
	}
}
